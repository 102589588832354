import {ListGoals} from '@utils/goal-owners';
import {GoalTypeTag} from '@ui/molecules/goal/editable-title/style';
import {Flex, FlexRow, FlexRowSpaceBetween} from '@ui/style/styles';
import {IGroup, MeasurementType} from '@hooks';
import {goalDueDate} from '@utils/date';
import {ProgressTracker} from '@ui/molecules/progress-tracker';
import styled from 'styled-components';
import {capitalizeGoalType} from '@utils';
import {Avatars} from '@ui/molecules/avatars';
import {NoBorderButton} from '@pages/dashboard/reporting/reporting-pages/reporting-overview/styles';
import {useState} from 'react';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {CopyLinkIcon} from '@ui/atoms/icons/copy-link';
import {Body2, Body1} from '@ui/atoms/typography';
import {Headline3} from '@ui/atoms/typography';

interface ITitle {
  title: string;
}

const Title = ({title}: ITitle) => {
  return <Headline3>{title}</Headline3>;
};

const GoalTag = ({goalType, groups}: {goalType: string; groups?: IGroup[]}) => {
  return groups?.length ? (
    <FlexRow>
      {groups.map((group) => (
        <GoalTypeTag className="mr-3">
          {capitalizeGoalType(group?.name || '')}
        </GoalTypeTag>
      ))}
    </FlexRow>
  ) : (
    <GoalTypeTag>{goalType && goalType}</GoalTypeTag>
  );
};

const Avatar = ({goal}: {goal: any}) => {
  return (
    <Avatars
      items={ListGoals(goal)}
      total={goal.keyResults?.length > 0 ? ListGoals(goal).length : 1}
    />
  );
};

const CopyNotification = styled.div`
  background: #ffffff;
  width: 116px;
  height: 48px;
  display: flex;
  align-items: center;
  position: absolute;
  top: -10px;
  right: 200px;
  justify-content: center;
  box-shadow: 0px 2px 5px rgba(172, 172, 190, 0.24);
  border-radius: 7px;
  @media (max-width: 800px) {
    right: 110px;
    top: -70px;
  }
`;
const GoalPreferenceWrapper = styled.div`
  padding: 4px 8px;
  min-height: 30px;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #5f5f8c;
  background: #f6f6fe;
  border-radius: 6px;
  @media (max-width: 500px) {
    text-align: center;
  }
`;

const MetaWrapper = styled.div`
  background: #fafafa;
  border: 1px solid #ededf2;
  margin-top: 14px;
  border-radius: 10px;
  padding: 16px;
  .tag {
    background: #ffffff;
    margin-left: 5px;

    border: 1px solid #cecede;
    border-radius: 8px;
    padding: 2px 8px;
  }
`;

const CopyLink = () => {
  const [copied, setCopied] = useState(false);
  const copyLink = async () => {
    await navigator.clipboard.writeText(window.location.href);

    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };

  return (
    <>
      <Flex
        onClick={() => copyLink()}
        style={{cursor: 'pointer', position: 'relative'}}>
        {copied && (
          <CopyNotification>
            <Body1>Copied!</Body1>
          </CopyNotification>
        )}

        <CopyLinkIcon />
        <Body2
          kind="purple300"
          weight="semibold"
          style={{fontSize: '12px', marginLeft: '10px'}}>
          Copy Link
        </Body2>
      </Flex>
    </>
  );
};

const ProgressWrapper = styled.div`
  width: 200px;
`;

export const GoalSection = ({
  goal,
  excludeCopyLink,
}: {
  goal: any;
  excludeCopyLink?: boolean;
}) => {
  function isGoalOnTrack() {
    if (!goal || !goal.isKpi) return null;

    if (goal.meta && goal.meta.type === 'positive') {
      return goal.currentValue > goal.targetValue ? 'on_track' : 'at_risk';
    } else if (goal.meta && goal.meta.type === 'negative') {
      return goal.currentValue < goal.targetValue ? 'on_track' : 'at_risk';
    }
  }

  function getGoalPerformance() {
    return goal ? goal.performance : null;
  }

  const goal_ = {
    id: goal?.id,
    progress: {
      due: goalDueDate(goal?.endDate),
      percent: goal?.isKpi ? goal?.currentValue : goal?.percentageProgress,
      state: `${goal?.percentageProgress}%`,
      targetValue: goal?.targetValue,
      // @ts-ignore
      type: isGoalOnTrack() || getGoalPerformance(),
      status: goal?.status,
      endDate: goal?.endDate,
    },
    name: goal?.name,
    group: goal.group,
    goalType:
      goal?.goalType.toLowerCase() === 'group'
        ? 'group'
        : goal?.goalType === 'company'
        ? 'Company-wide'
        : goal?.goalType,

    createdBy: goal?.createdBy,
  };
  const [expandDescription, setExpandDescription] = useState(false);

  const meta =
    goal.meta?.description?.length > 0 || goal?.meta?.tags?.length > 0;

  const description = goal?.meta?.description;

  const tags = goal?.meta?.tags;

  const isDescriptionLong = description && description.length > 150;

  const formatDescription =
    isDescriptionLong && !expandDescription
      ? `${description.slice(0, 150)}...`
      : description;

  const handleKPIPreference = (preference: string) => {
    switch (preference) {
      case 'sum':
        return 'Current value is displayed as the sum of all values entered.';
      case 'average':
        return ' Current value is displayed as the average of all values entered.';
      case 'latest':
        return ' Current value is displayed as the latest of all values entered.';
      default:
        return ' Current value is displayed as the sum of all values entered.';
    }
  };

  return (
    <div>
      <VerticalSpacer size="10px" />
      <Flex justifyContent="space-between" alignItems="center">
        <GoalTag goalType={goal_.goalType} groups={goal_.group} />
        {!excludeCopyLink && <CopyLink />}
      </Flex>
      <VerticalSpacer size="12px" />
      <Title title={goal.name} />
      <VerticalSpacer size="18px" />

      <Flex justifyContent="space-between" alignItems="center">
        <ProgressWrapper>
          <ProgressTracker
            {...goal_.progress}
            currency={
              goal.measurement?.unit.toUpperCase() === MeasurementType.CURRENCY
                ? goal.measurement?.symbol
                : ''
            }
            variant="insight"
            unit={goal.measurement?.unit}
            targetValue={goal?.targetValue}
            isGoalKpi={goal?.isKpi}
            showFullValue
          />
        </ProgressWrapper>
        <Avatar goal={goal} />
      </Flex>
      {goal?.isKpi && (
        <>
          <VerticalSpacer size="16px" />
          <GoalPreferenceWrapper>
            {handleKPIPreference(goal?.measurement?.preference)}
          </GoalPreferenceWrapper>
        </>
      )}

      {meta && (
        <MetaWrapper>
          <FlexRowSpaceBetween>
            {tags?.length > 0 &&
              tags.map((tag: string) => (
                <FlexRow>
                  <Body1 weight="bold">#</Body1>
                  <div className="tag">
                    <Body2 weight="semibold">{tag}</Body2>
                  </div>
                </FlexRow>
              ))}
            {isDescriptionLong && (
              <NoBorderButton
                onClick={() => setExpandDescription(!expandDescription)}>
                <Body2 weight="semibold" kind="purple300">
                  {expandDescription ? 'Collapse' : 'Expand'}
                </Body2>
              </NoBorderButton>
            )}
          </FlexRowSpaceBetween>
          {tags?.length > 0 && description && <VerticalSpacer size="12px" />}

          {description && <Body2>{formatDescription}</Body2>}
        </MetaWrapper>
      )}
    </div>
  );
};
