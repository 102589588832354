import {action} from 'mobx';
import {activateNotification} from '../../ui/molecules/notification/activate-notification';
import {parseErrorContent} from '../../utils/error-handling';
import {ResetPasswordFormData} from './reset-password-interface';
import {NewPasswordRequest} from './reset-password-request';

export class ResetPasswordController {
  constructor(private readonly request: NewPasswordRequest) {}

  @action
  async resetPassword(data: ResetPasswordFormData, token: string) {
    try {
      await this.request.resetPassword(data, token);
      return true;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }
}
