import {AxiosResponse} from 'axios';
import {authStore} from '../../../../../../store/stores/auth-store';
import {BaseRequest} from '../../../../../../configs/request';
import {groupFormData, groupResponse} from './admin-group-interface';

export interface groupRequest extends BaseRequest {
  createGroup(data: groupFormData): Promise<groupResponse>;
  editGroup(data: groupFormData, id?: string): Promise<groupResponse>;
  fetchGroup(id?: string): Promise<groupResponse>;
  fetchGroups(): Promise<groupResponse>;
  fetchGroupsMembers(): Promise<any>;
  deleteGroup(id?: string): Promise<groupResponse>;
  fetchGroupMembers(id?: string): Promise<groupResponse>;
  addGroupMember(data: any, id?: string): Promise<any>;
  removeGroupMember(data: any, id?: string): Promise<any>;
  fetchSpecGroup(id: string, include: string): Promise<any>;
}

export class groupRequestImpl extends BaseRequest implements groupRequest {
  private groupTypeEndpoint: string = '/group-type';
  private groupEndpoint: string = '/groups';

  async createGroup<groupResponse>(data: groupFormData) {
    const {data: responseData} = await this.request.post<
      groupFormData,
      AxiosResponse<groupResponse>
    >(this.groupEndpoint, data, {
      headers: {
        Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
      },
    });
    return responseData;
  }

  async editGroup<groupResponse>(data: groupFormData, id: string) {
    const {data: responseData} = await this.request.patch<
      groupFormData,
      AxiosResponse<groupResponse>
    >(`${this.groupEndpoint}/${id}`, data, {
      headers: {
        Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
      },
    });
    return responseData;
  }

  async deleteGroup(id: string) {
    const {data: responseData} = await this.request.delete(
      `${this.groupEndpoint}/${id}`,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return responseData;
  }

  async fetchGroup(id: string) {
    const {data: responseData} = await this.request.get<
      any,
      AxiosResponse<groupResponse>
    >(`${this.groupTypeEndpoint}/${id}?include=groups`, {
      headers: {
        Authorization:
          authStore.auth === null && !!localStorage.tempAccessToken
            ? `Bearer ${localStorage.tempAccessToken}`
            : `Bearer ${authStore.auth?.tokens.access.token}`,
      },
    });
    return responseData;
  }
  async fetchGroups() {
    const {data: responseData} = await this.request.get<
      any,
      AxiosResponse<groupResponse>
    >(`${this.groupEndpoint}?paginate=false`, {
      headers: {
        Authorization:
          authStore.auth === null && !!localStorage.tempAccessToken
            ? `Bearer ${localStorage.tempAccessToken}`
            : `Bearer ${authStore.auth?.tokens.access.token}`,
      },
    });
    return responseData;
  }
  async fetchGroupsMembers() {
    const {data: responseData} = await this.request.get<
      any,
      AxiosResponse<groupResponse>
    >(`${this.groupEndpoint}/members`, {
      headers: {
        Authorization:
          authStore.auth === null && !!localStorage.tempAccessToken
            ? `Bearer ${localStorage.tempAccessToken}`
            : `Bearer ${authStore.auth?.tokens.access.token}`,
      },
    });
    return responseData;
  }

  async fetchSpecGroup(id: string, include: string) {
    const {data: response} = await this.request.get<
      any,
      AxiosResponse<groupResponse>
    >(`${this.groupEndpoint}/${id}?include=${include}`, {
      headers: {
        Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
      },
    });
    return response;
  }
  async fetchGroupMembers(id: string) {
    const res = await this.request.get<any, AxiosResponse<groupResponse>>(
      `${this.groupEndpoint}/${id}/member`,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    const responseData = res.data;
    return responseData;
  }
  async addGroupMember(data: any, id: string) {
    const res = await this.request.post<any, AxiosResponse<any>>(
      `${this.groupEndpoint}/${id}/member`,
      data,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    const responseData = res.data;
    return responseData;
  }
  async removeGroupMember(data: any, id?: string) {
    const res = await this.request.post<any, AxiosResponse<any>>(
      `${this.groupEndpoint}/${id}/member`,
      data,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    const responseData = res.data;
    return responseData;
  }
}
