import styled from 'styled-components';

export const CascadeKeyResultWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .form {
    width: 40%;

    margin-bottom: 30px;
    @media (min-width: 1100px) and (max-width: 1320px) {
      width: 50%;
    }
    @media (min-width: 768px) and (max-width: 1100px) {
      width: 70%;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;
export const Hr = styled.div`
  height: 1px;
  background: #ededf2;
  width: 100%;
  margin-bottom: 24px;
`;

export const AddCascadeKeyResultWrapper = styled.div`
  border: 1px solid #ededf2;
  border-radius: 10px;
  background-color: white;
  padding: 16px;
`;
export const TextFieldWrapper = styled.div<{state?: string}>`
  border: ${({state}) =>
    state === 'error' ? '1px solid #D52A2A' : '1px solid #cecede'};
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 12px;
`;
export const List = styled.li`
  list-style-type: disc;
  margin: 0px 0px 10px 16px;

  .number {
    padding: 4px 8px;
    border: 1px solid #ededf2;
    border-radius: 6px;
    font-weight: 600;
    font-size: 14px;
  }
`;
