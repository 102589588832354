import React, {FC, useMemo} from 'react';
import {InsightCard} from '../../dashboard/insight-card';
import {Insight} from '../interface';
import {formatConfigDate, comparisonConfigDate} from '../insight-options';
import {useInsightActionItemHook} from './action-items-insight-hook';

interface ActionItemCompletionRateProps extends Insight {
  showRangeType?: boolean;
}

export const ActionItemCompleted: FC<ActionItemCompletionRateProps> = ({
  ...rest
}) => {
  const config = rest.config;

  const formatDate = useMemo(() => formatConfigDate(config), [config]);

  const comparisonDate = useMemo(
    () => comparisonConfigDate(config, formatDate.startDate),
    [config, formatDate.startDate],
  );

  const {summary} = useInsightActionItemHook({
    members: config.users || [],
    sections: config.list || [],
    date: formatDate,
    comparisonDate: comparisonDate,
  });

  return (
    <InsightCard
      {...rest}
      value={summary.completed.current}
      percentProgress={summary.completed.change}
    />
  );
};
