import {IMeetingResponse} from '@hooks';
import {authStore} from '@store/stores/auth-store';
import {Body2} from '@ui/atoms/typography';
import {SectionCard} from '@ui/layouts/section-card';
import {SearchBox2} from '@ui/molecules/search-box';
import {database} from '@utils/firebase-request';
import {cloudinary} from '@assets/images/cloudinary';
import {onValue, ref} from 'firebase/database';
import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {EmptyPlaceholder} from '@ui/atoms/empty-placeholder';
import {FlexRow, FlexRowCenter, FlexRowSpaceBetween} from '@ui/style/styles';
import {ItemLoader} from '@ui/organisms/item-loader';
import {CustomMarkdown} from '@ui/atoms/typography/markdown';
import {HorizontalSpacer} from '@ui/atoms/spacer';
import styled from 'styled-components';
import {Linkify} from '@utils/linkify-string';
import {CustomColorIcon} from '@ui/atoms/color-icons';
import dayjs from 'dayjs';
import {getTime} from '@utils/date';
import {timezones} from '@constants';
import {SecondaryPlusIcon} from '@ui/atoms/icons/plus';
import {CSVLink} from 'react-csv';
import {userName} from '@utils/user-name';
import {useStoreContext} from '@store/store-context';
import {SecondaryEyeOffIcon} from '@ui/atoms/icons/eye-off';
import {SortByFilter} from '@pages/dashboard/reporting/reporting-pages/admin-analytics/analytics-pages/analytics-status/status-component/sort-by-filter';
const {emptyGoals} = cloudinary;

interface INotes {
  meetings: IMeetingResponse[];
  userId: string;
}

export const Wrapper = styled.div<{disabled?: boolean}>`
  border: 1px solid #cecede;
  border-radius: 10px;
  .input {
    padding: 2px 0px;
    overflow: auto;
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 8px 16px;
    border-top: 1px solid #cecede;
  }
`;

export const Notes: FC<INotes> = ({meetings, userId}) => {
  const [searchValue, setSearchValue] = useState('');
  const {
    usersStore: {users},
  } = useStoreContext();

  const [notes, setNotes] = useState<
    {
      note: string;
      shareNote: boolean;
      time: string;
      reminder: string[];
      startDate: string;
      createdAt: string;
      updatedBy: string;
    }[]
  >([]);

  const emptyPlaceholder = {
    image: {
      alt: 'alt text',
      shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
      src: emptyGoals,
      style: {height: '30%', width: '30%'},
    },
    imports: true,
    action: (
      <FlexRowCenter>
        <FlexRow as={'a'} href={`/create-meeting-notes?participant=${userId}`}>
          <SecondaryPlusIcon />
          <Body2 weight="bold" kind="purple300" className="ml-1">
            Create Note
          </Body2>
        </FlexRow>
      </FlexRowCenter>
    ),

    subtitle: 'View all your meeting notes here.',
    title: 'No records found',
  };

  const [isLoading, setIsLoading] = useState(false);

  const notesPath = `${process.env.REACT_APP_DEV_ENV}/${authStore.auth.user.workspace.id}/meeting`;

  const [sortByFilter, setSortByFilter] = useState('desc');

  const fetchData = useCallback(() => {
    setIsLoading(true);
    let notes: any = [];
    const meetingIds = meetings.map((meeting) => meeting.id);

    const handleSnapshot = (snapshot: any, id: string) => {
      if (snapshot.exists()) {
        const _notes = snapshot.val()?.notes;

        const topics = snapshot.val()?.topics;

        const computedTopics: {id: string; isPrivate: string}[] = topics
          ? Object.values(topics)
          : [];

        const meeting = meetings.find((meeting) => meeting.id === id);

        const {targetHour, targetMinute} = getTime(meeting?.time || '');

        const getTimeZone = timezones.find(
          (timezone) => timezone.value === authStore.auth.user.timezone,
        );

        const updatedDate = dayjs(meeting?.startDate)
          .set('hour', targetHour)
          .set('minute', targetMinute)
          .tz(getTimeZone?.label || '', true);

        for (let value in _notes) {
          const note = _notes[value];

          const findTopic = computedTopics.find(
            (topic: any) => topic.id === note?.topic,
          );

          notes = [
            ...notes,
            {
              ...note,
              startDate: meeting?.startDate,
              reminder: meeting?.reminder,
              shareNote: note?.topic ? !findTopic?.isPrivate || false : true,
              time: updatedDate,
            },
          ];
        }

        notes.sort(
          (
            a: {createdAt: string | number | Date},
            b: {createdAt: string | number | Date},
          ) => {
            const aDate = new Date(a.createdAt).valueOf();
            const bDate = new Date(b.createdAt).valueOf();

            if (sortByFilter === 'desc') {
              return aDate - bDate;
            }
            return bDate - aDate;
          },
        );

        setNotes(notes);
      } else {
      }
    };

    meetingIds.forEach((id) => {
      const databaseRef = ref(database, `${notesPath}/${id}`);
      onValue(databaseRef, (value) => handleSnapshot(value, id));
    });

    setIsLoading(false);
  }, [meetings, notesPath, sortByFilter]);

  const computedNotes = useMemo(() => {
    const allNotes = searchValue
      ? notes.filter((note) => note.note.toLowerCase().includes(searchValue))
      : notes;

    return allNotes.sort(
      (a, b) =>
        new Date(a.updatedBy).getTime() - new Date(b.updatedBy).getTime(),
    );
  }, [notes, searchValue]);

  const user = users.find((_user) => _user.id === userId);

  const updateCSVData = useCallback(() => {
    return [
      ['user', 'note', 'date', 'Author'],
      ...computedNotes.map((note) => [
        userName(user),
        note.note,
        dayjs(note.createdAt).format('DD MMM. YYYY'),
        userName(users.find((_user) => _user.id === note.updatedBy)),
      ]),
    ];
  }, [computedNotes, user, users]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortByFilter]);

  return (
    <div>
      <SectionCard
        contentStyling={{padding: '16px'}}
        CustomHeaderTitle={
          <div className="w-full">
            <div className="pb-4 mb-4 border-b border-b-[#ededf2]">
              <Body2 weight="bold">Summary ({computedNotes.length})</Body2>
            </div>

            <FlexRowSpaceBetween className="mb-3">
              <SortByFilter value={sortByFilter} onChange={setSortByFilter} />
              <CSVLink
                data={updateCSVData()}
                filename={`${userName(user)}-notes-${dayjs().format()}`}>
                <Body2 weight="bold" kind="green400">
                  Export
                </Body2>
              </CSVLink>
            </FlexRowSpaceBetween>

            <SearchBox2
              value={searchValue}
              placeholder="Search"
              handleCancel={() => setSearchValue('')}
              onChange={(event) => setSearchValue(event.target.value)}
            />
          </div>
        }>
        <div>
          {!computedNotes.length && !isLoading ? (
            <EmptyPlaceholder {...emptyPlaceholder} />
          ) : null}
          {isLoading && (
            <FlexRowCenter>
              <ItemLoader />
            </FlexRowCenter>
          )}

          {!isLoading && computedNotes.length ? (
            <div>
              {computedNotes.map((note, idx) => (
                <div key={idx} className="mb-4">
                  <SectionCard
                    title=""
                    contentStyling={{padding: '24px'}}
                    CustomHeaderTitle={
                      <div>
                        <FlexRow>
                          <Body2 kind="textBody">
                            {dayjs(note.startDate).format('D MMM, YYYY')}
                          </Body2>

                          {note?.time && !!note.reminder?.length && (
                            <>
                              <HorizontalSpacer size="10px" />
                              <CustomColorIcon
                                height="8px"
                                width="8px"
                                margin={7}
                                color="#D9D9D9"
                              />
                              <Body2 kind="textBody">
                                {' '}
                                {dayjs(note.time).format('hh:mm a')}{' '}
                              </Body2>
                            </>
                          )}
                        </FlexRow>
                      </div>
                    }>
                    <div>
                      <Wrapper disabled={false}>
                        <div className="input">
                          <div
                            className={`p-4 min-h-[120px] h-[190px] max-h-[350px] rounded-[10px] `}>
                            <CustomMarkdown
                              text={
                                note.updatedBy === authStore.auth.user.id ||
                                note.shareNote
                                  ? `${Linkify(note.note)}`
                                  : 'This note is hidden'
                              }
                            />
                          </div>
                        </div>
                        {!note.shareNote && (
                          <div className="footer">
                            <FlexRow>
                              <Body2 weight="semibold">Private</Body2>
                              <HorizontalSpacer size="8px" />

                              <SecondaryEyeOffIcon
                                style={{stroke: '#5E5E73'}}
                              />
                            </FlexRow>
                          </div>
                        )}
                      </Wrapper>
                    </div>
                  </SectionCard>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </SectionCard>
    </div>
  );
};
