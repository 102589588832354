import {useCallback, useState, useMemo} from 'react';
import {useStoreContext} from '@store/store-context';
import nextId from 'react-id-generator';
import {activateNotification} from '@ui/molecules/notification/activate-notification';
import {MeasurementType} from '@hooks';
import {parseCurrencyValue} from '@constants';
import {beamMasteryTasks} from '@utils/firebase-request';
import {useSearchParams} from 'react-router-dom';
import {GoalsController} from '@api/goals-api/goals-controller';
import {transformOptions} from '@pages/dashboard/reporting/reporting-pages/reporting-overview/components/kpi-board';
import {GoalsRequestImpl} from '@api/goals-api/goals-request';
import {useNavigate} from 'react-router';
export interface CascadeProps {
  id: string;
  objective: {name: string; id: string};
  keyresult: {name: string; id: string; [x: string]: any};
  error?: string | null;
  contribution: string;
}

export const useCascadeKeyResult = () => {
  const {
    groupStore: {groups},
    groupTypeStore: {groupType},
  } = useStoreContext();

  const transformKeyResult = (keyResult: any) => ({
    label: {
      keyResultName: keyResult.name,
      assigneeName:
        keyResult.assignee &&
        keyResult.assignee.hasOwnProperty('firstName') &&
        keyResult.assignee.hasOwnProperty('lastName')
          ? `${keyResult.assignee?.firstName} ${keyResult.assignee?.lastName}`
          : keyResult.assignee?.email,
      target: keyResult.targetValue,
      measurement: keyResult.measurement?.unit,
      symbol: keyResult.measurement?.symbol,
      assigneeAvatar: {
        src:
          keyResult.assignee?.avatar && keyResult.assignee?.avatar.url
            ? keyResult.assignee?.avatar.url
            : '',
        name:
          keyResult.assignee &&
          keyResult.assignee.hasOwnProperty('firstName') &&
          keyResult.assignee.hasOwnProperty('lastName')
            ? `${keyResult.assignee?.firstName} ${keyResult.assignee?.lastName}`
            : keyResult.assignee?.email,
        id: keyResult.assignee?.id,
      },

      id: keyResult.id,
    },
    value: keyResult.id,
  });

  const [searchParams] = useSearchParams();

  const editAlignment = searchParams.get('edit');
  const goalId = searchParams.get('goalId');

  const request = useMemo(() => new GoalsRequestImpl(), []);

  const controller = useMemo(() => new GoalsController(request), [request]);

  const emptyCascade = {
    id: nextId('cascade'),
    objective: {name: '', id: ''},
    keyresult: {name: '', id: ''},

    contribution: '',
  };

  const [cascadedKeyResults, setCascadeKeyResults] = useState<CascadeProps[]>([
    emptyCascade,
  ]);

  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const [selectedObjective, setObjective] = useState<string | undefined>();

  const [save, setSave] = useState(!!editAlignment);

  const [selectedKeyResults, setKeyResult] = useState<any>();

  const addKeyResult = () => {
    setCascadeKeyResults((prev) => [...prev, emptyCascade]);
  };

  const initGoals = useCallback(
    async (pageNumber?: number, filter?: string) => {
      const request = new GoalsRequestImpl();
      const controller = new GoalsController(request);

      const response = await controller.fetchGoals(pageNumber, filter);

      const excludeKpi = response.goals.filter(
        (goal: any) =>
          !goal.isKpi &&
          goal.alignedGoals?.length === 0 &&
          goal.status !== 'completed',
      );

      const alignGoalArr = excludeKpi.map((goal: any) =>
        transformOptions(goal, groups, groupType),
      );

      return {
        options: [...alignGoalArr, {value: 'custom', label: 'custom'}],
        goals: excludeKpi,
      };
    },
    [groups, groupType],
  );

  const fetchSpecificGoal = useCallback(
    async (id: string, goals: any[]) => {
      const response: any = await controller.fetchGoalById(id);

      if (response) {
        setObjective(id);

        const getParentKr = response.keyResults.find(
          (kr: any) => kr.id === editAlignment,
        );

        setKeyResult(transformKeyResult(getParentKr));

        setCascadeKeyResults(
          getParentKr?.alignedKeyResults.map((kr: any) => {
            const goal = goals.find((_goal) => _goal.id === kr.goal);

            return {
              id: nextId('cascade'),
              objective: {name: goal?.name, id: goal?.id},
              keyresult: {
                name: kr.name,

                ...transformKeyResult(kr).label,
              },

              contribution: kr.keyResultAlignment?.value,
            };
          }),
        );
      }
    },
    [controller, editAlignment],
  );

  const onChangeKeyResult = (id: string, field: string, value: any) => {
    const keyResult = cascadedKeyResults.map((keyResult) =>
      keyResult.id === id ? {...keyResult, [field]: value} : keyResult,
    );

    let sum = 0;

    keyResult.forEach((keyresult) => {
      sum = Number(keyresult.contribution) + sum;
    });

    keyResult[keyResult.length - 1].error =
      sum > (Number(selectedKeyResults?.label.target) || 0)
        ? 'Total contribution cannot be greater than key result target'
        : null;

    setCascadeKeyResults(keyResult);
  };

  const disableSaveButton = () => {
    let disable = false;

    const iterateObject = (data: any) => {
      for (const key in data) {
        const value = data[key];
        const excludeFields = ['src'];

        const error = data['error'];
        if (error && error !== null) {
          disable = true;
          return true;
        }
        if (typeof value === 'object' && value !== null) {
          if (iterateObject(value)) {
            disable = true;
            return true;
          }
        } else if (
          typeof value === 'string' &&
          !value &&
          !excludeFields.includes(key)
        ) {
          disable = true;
          return true;
        }
      }
      disable = false;
      return false;
    };

    cascadedKeyResults.forEach((keyResult) => {
      iterateObject(keyResult);
    });

    return disable;
  };
  const disableCascadeKeyResults =
    !save || !selectedObjective || !selectedKeyResults;
  const [loading, setLoading] = useState(false);

  const cascadeKeyResults = async (goals: any) => {
    setLoading(true);
    const request = new GoalsRequestImpl();
    const controller = new GoalsController(request);

    const keyResults = goals.flatMap((goal: any) => goal.keyResults);

    const parentKeyResult = keyResults?.find(
      (keyresult: any) => keyresult.id === selectedKeyResults?.value,
    );

    const _parentData = {
      ...parentKeyResult,
      alignmentTarget: selectedKeyResults?.label?.target,
    };

    await controller.updateKeyResult(_parentData, selectedKeyResults?.value);

    const updateKeyResult = async (id: string, contribution: string) => {
      const childKeyResult = keyResults?.find(
        (keyresult: any) => keyresult.id === id,
      );

      const _childData = {
        ...childKeyResult,
        keyResultAlignment: {
          keyResult: selectedKeyResults?.value,
          value: Number(contribution),
        },
      };
      const res = await controller.updateKeyResult(_childData, id);
      if (!res) {
        return Promise.reject('Something went wrong');
      }
      beamMasteryTasks('cascade_keyresult', true);

      return res;
    };

    const promises = cascadedKeyResults.map((keyresult) =>
      updateKeyResult(keyresult.keyresult.id, keyresult.contribution),
    );

    Promise.all(promises)
      .then((results) => {
        activateNotification({
          title: 'Success',
          kind: 'success',
          content: 'Your keyresult has been cascaded',
        });

        navigate(-1);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getSymbol = (measurement: string, value: string, symbol?: string) => {
    if (measurement?.toUpperCase() === MeasurementType.PERCENTAGE) {
      return `${value}%`;
    }
    if (measurement?.toUpperCase() === MeasurementType.CURRENCY) {
      return `${parseCurrencyValue(symbol || '')} ${value}`;
    }
    return value;
  };

  const deleteKeyResult = (id: string) => {
    const objectives = cascadedKeyResults.filter(
      (keyResult) => keyResult.id !== id,
    );

    if (!objectives.length) {
      return setCascadeKeyResults([emptyCascade]);
    }

    setCascadeKeyResults(objectives);
  };
  return {
    initGoals,
    transformKeyResult,
    cascadedKeyResults,
    selectedKeyResults,
    setKeyResult,
    save,
    setSave,
    disableCascadeKeyResults,
    getSymbol,
    disableSaveButton,
    onChangeKeyResult,
    selectedObjective,
    setObjective,
    cascadeKeyResults,
    fetchSpecificGoal,
    goalId,
    loading,
    editAlignment,
    open,
    setOpen,
    deleteKeyResult,
    addKeyResult,
  };
};
