import {useState} from 'react';
import {useNavigate, useParams} from 'react-router';
import nextId from 'react-id-generator';

import {
  HorizontalSpacer,
  VerticalSpacer,
} from '../../../../../../../ui/atoms/spacer';
import {LockIcon} from '@ui/atoms/icons/lock';
import {FlexRowSpaceBetween, FlexRow} from '@ui/style/styles';
import {Body2} from '../../../../../../../ui/atoms/typography';
import {Checkbox} from '@ui/atoms/checkbox';
import PageLayout from '@ui/templates/page-template/page-layout';
import {SelectField} from '../../../../../../../ui/molecules/select/selectfield';
import {AddQuestionPage} from './question';
import {AddMoreAction} from '@ui/molecules/add-more';
import useCreateTemplateHook from './create-template-hook';
import {NoBorderButton} from '@pages/dashboard/reporting/reporting-pages/reporting-overview/styles';
import {CancelIcon} from '@ui/atoms/icons';
import {TemplateWrapper, Info} from './create-template-style';
import {TextField} from '../../../../../../../ui/molecules/field/textfield';
import {Stack} from '../../../admin-workspace/workspace-performance/admin-culture-competence/performance-settings-style';
import {useStoreContext} from '@store/store-context';
import {observer} from 'mobx-react-lite';
import {
  DelimitingWrapper,
  HintBlock,
  Wrapper,
  WrappedBlock,
} from './create-template-style';

function CreatePerformanceTemplate() {
  const navigate = useNavigate();
  const params = useParams();

  const [questionPage] = useState(false);

  const [showHint] = useState(true);
  const [addQuestions, setAddQuestions] = useState(false);

  const {
    authStore: {auth},
  } = useStoreContext();

  const {
    selectedValues,
    handleUpdateValues,
    selectedCompetencies,
    updateSpecificCompetence,
    deleteSpecificCompetence,
    deleteSpecificValue,
    handleUpdateCompetencies,
  } = useCreateTemplateHook();

  const companyValues = auth?.user?.workspace?.settings?.companyValues?.values;

  const SelectionView = ({
    value,
    handleCancel,
  }: {
    value: string;
    handleCancel: () => void;
  }) => {
    return (
      <WrappedBlock>
        <div className="border-div">
          <Body2 weight="bold">{value}</Body2>
          <NoBorderButton onClick={() => setAddQuestions(true)}>
            <Body2 weight="bold" kind="purple300">
              + Create questions
            </Body2>
          </NoBorderButton>
        </div>
        <NoBorderButton onClick={handleCancel}>
          <CancelIcon />
        </NoBorderButton>
      </WrappedBlock>
    );
  };

  const InfoBlock = (term: string) => {
    return (
      <Info>
        <Body2 kind="textBody" align="center">
          {term} you select would display here
        </Body2>
      </Info>
    );
  };

  if (addQuestions) {
    return <AddQuestionPage onClose={() => setAddQuestions(false)} />;
  }

  return (
    <>
      <>
        <PageLayout
          title={params?.id ? 'Edit Template' : 'Create new question template'}
          onClick={() => navigate(-1)}>
          <VerticalSpacer size="20px" />
          <DelimitingWrapper>
            {!questionPage && (
              <Wrapper>
                {showHint && (
                  <>
                    <HintBlock>
                      To customize your questions or add custom metrics, please{' '}
                      <a href="mailto:Hi@peopebeam.co">contact support.</a>
                    </HintBlock>
                    <VerticalSpacer size="25px" />
                  </>
                )}
                <div>
                  <Stack alignItem="center">
                    <Body2 weight="semibold">Template name</Body2>
                    <HorizontalSpacer size="0px" />
                  </Stack>
                  <VerticalSpacer size="8px" />
                  <Body2 kind="textBody">
                    Provide a descriptive name for this question template
                  </Body2>
                  <VerticalSpacer size="8px" />
                  <TextField
                    placeholder="E.g Q1 review questions"
                    inputStyle={{borderRadius: '10px'}}
                    onChange={(e) => {}}
                  />
                </div>

                <Body2 weight="bold">Values</Body2>
                <VerticalSpacer size="14px" />

                <TemplateWrapper>
                  <div className="body">
                    <Body2 weight="bold">
                      Select a value then add a question
                    </Body2>
                    <VerticalSpacer size="10px" />

                    <SelectField
                      placeholder="Which value(s) do you want to review?"
                      borderRadius="10px"
                      margin
                      onChange={(data: {value: string; label: string}) => {
                        handleUpdateValues({
                          value: data?.value,
                          templates: [],
                          id: nextId('value'),
                        });
                      }}
                      options={companyValues.map((value: string) => {
                        return {
                          label: value,
                          value: value,
                        };
                      })}
                    />
                  </div>
                  {selectedValues.length > 0 ? (
                    selectedValues.map((value) => (
                      <div className="footer">
                        <SelectionView
                          value={value.value}
                          handleCancel={() => deleteSpecificValue(value.id)}
                        />
                      </div>
                    ))
                  ) : (
                    <div className="footer">{InfoBlock('Values')}</div>
                  )}
                </TemplateWrapper>

                <VerticalSpacer size="25px" />
                <Body2 weight="bold">Competencies</Body2>
                <VerticalSpacer size="14px" />
                <TemplateWrapper>
                  <div className="body">
                    <Body2 weight="bold">
                      Select a competence then add a question
                    </Body2>
                    <VerticalSpacer size="10px" />
                    <SelectField
                      placeholder="Which competence(s) do you want to review?"
                      borderRadius="10px"
                      margin
                      onChange={(data: {value: string; label: string}) => {
                        handleUpdateCompetencies({
                          competence: data?.value,
                          templates: [],
                          useObjectives: false,
                          id: nextId('competence'),
                        });
                      }}
                      options={companyValues.map((value: string) => {
                        return {
                          label: value,
                          value: value,
                        };
                      })}
                    />
                    <FlexRow />
                  </div>

                  {selectedCompetencies.length > 0 ? (
                    selectedCompetencies.map((value) => (
                      <div className="footer">
                        <SelectionView
                          value={value.competence}
                          handleCancel={() =>
                            deleteSpecificCompetence(value.id)
                          }
                        />
                        <FlexRow style={{marginTop: '12px'}}>
                          <Checkbox
                            checked={value.useObjectives}
                            onChange={(event) =>
                              updateSpecificCompetence(
                                value.id,
                                'useObjectives',
                                event.target.checked,
                              )
                            }
                          />
                          <Body2 kind="textBody" style={{marginLeft: '8px'}}>
                            Use objectives to automatically rate performance.
                          </Body2>
                        </FlexRow>
                      </div>
                    ))
                  ) : (
                    <div className="footer">{InfoBlock('Competencies')}</div>
                  )}
                </TemplateWrapper>

                <div
                  style={{
                    width: '100%',
                    background: '#EDEDF2',
                    margin: '24px 0px',
                    height: '1px',
                  }}></div>

                <TemplateWrapper>
                  <div className="body">
                    <FlexRowSpaceBetween>
                      <FlexRow>
                        <Body2 weight="bold">
                          Manager overall assessment
                          <span style={{fontWeight: 400, color: '#5F5F8C'}}>
                            {'  '}(optional)
                          </span>
                        </Body2>
                      </FlexRow>

                      <LockIcon />
                    </FlexRowSpaceBetween>
                    <VerticalSpacer size="10px" />
                    <SelectField
                      placeholder="Which competence(s) do you want to review?"
                      borderRadius="10px"
                      margin
                      options={[]}
                    />
                  </div>
                  <div className="footer">
                    <AddMoreAction
                      onClick={() => {}}
                      disabled={true}
                      title={`Add question review`}
                    />
                  </div>
                </TemplateWrapper>
                <VerticalSpacer size="25px" />
              </Wrapper>
            )}
          </DelimitingWrapper>
        </PageLayout>
      </>
    </>
  );
}

export default observer(CreatePerformanceTemplate);
