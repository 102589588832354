import {useMemo} from 'react';
import {useFirebaseFetch} from './query-hook';

export const useTags = (
  path: string,
  defaultOption?: {tag: string; disabled: boolean; key: string}[],
) => {
  const {data: tags} = useFirebaseFetch(path);

  const computedTags = useMemo(() => {
    const options: {
      tag: any;
      disabled?: boolean;
      key: string;
    }[] = defaultOption ? defaultOption : [];

    if (tags) {
      options.push(
        ...Object.entries(tags).map(([key, tag]: any) => ({tag, key})),
      );
    }
    return options;
  }, [defaultOption, tags]);

  return {
    tags: computedTags,
  };
};
