import {ContentBox, ModalWrapper} from '../admin-workspace-styled';
import {Headline2, Body1} from '@ui/atoms/typography';
import {useState} from 'react';
import {SetupWorkspaceRequestImpl} from '@pages/admin/setup-workspace/setup-workspace-request';
import {SetupWorkspaceController} from '@pages/admin/setup-workspace/setup-workspace-controller';
import {observer} from 'mobx-react';
import {activateNotification} from '@ui/molecules/notification/activate-notification';
import {SwitchToggle} from '@ui/molecules/toggle-switch/toggle-switch';
import {generateRandomDigits} from '@utils/generate-random-numbers';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {Modal} from '@ui/molecules/modal';
import {ModalCard} from '@ui/layouts/modal-card';
import {BackAction} from '@ui/molecules/back-action';
import {CancelIcon} from '@ui/atoms/icons';
import {useStoreContext} from '@store/store-context';
import {Button} from '@ui/atoms/button';
import {useWorkspaceUseCase} from '@hooks/workspace-usecase';
import {FlexRowEnd} from '@ui/style/styles';
import {setFirebaseData} from '@utils/firebase-handler';

export const AdminFeedback = observer(() => {
  const {
    authStore: {featureLimitStatus, auth},
  } = useStoreContext();

  const [isFeedbackEnabled, setFeedbackStatus] = useState(
    auth?.user?.workspace?.settings.feedback,
  );
  const [showModal, setShowModal] = useState<'enabled' | 'disabled' | ''>('');
  const {isEngagementActive} = useWorkspaceUseCase();

  const handleUpdateFeedback = async (status: 'enabled' | 'disabled') => {
    const request = new SetupWorkspaceRequestImpl();
    const controller = new SetupWorkspaceController(request);

    const response = await controller.workspaceEdit(
      auth?.user?.workspace?.id,
      {
        settings: {
          ...auth?.user?.workspace?.settings,
          feedback: status,
        },
      },
      auth?.tokens?.access?.token,
    );
    response &&
      activateNotification({
        title: `Feedback ${status}`,
        content: `Settings updated`,
        kind: 'success',
      });

    response && setFirebaseData(`updates/settings`, generateRandomDigits(16));
  };

  return (
    <>
      <ContentBox style={{marginTop: '6px'}}>
        <div
          className="flex"
          onClick={() => featureLimitStatus?.limited || null}>
          <div className="column">
            <Body1 kind="textDark" weight="bold">
              Request & receive feedback
            </Body1>
          </div>
          <FlexRowEnd>
            <SwitchToggle
              disabled={!isEngagementActive}
              checked={isFeedbackEnabled === 'enabled' && isEngagementActive}
              onChange={() => {
                const status =
                  isFeedbackEnabled === 'enabled' ? 'disabled' : 'enabled';
                setShowModal(status);
              }}
            />
          </FlexRowEnd>
        </div>
      </ContentBox>

      <Modal open={!!showModal} onClose={() => {}}>
        <ModalWrapper>
          <BackAction
            width="md"
            icon={<CancelIcon />}
            onClick={() => setShowModal('')}
            title="Close"
          />
          <VerticalSpacer size="16px" />
          <ModalCard align="center" title="">
            <div>
              <Headline2 align="center">
                {showModal === 'enabled' ? 'Enable' : 'Disable'} Enable
                feedback?
              </Headline2>

              <VerticalSpacer size="16px" />

              <Body1 kind="textBody" align="center">
                {showModal === 'enabled'
                  ? `Feedback will be added for workspace interactions, including feedback requests, responses, sentiments and commendations. `
                  : showModal === 'disabled' &&
                    `This will be remove feedback used for workspace interactions, including feedback requests, responses, sentiments and commendations. `}
              </Body1>
              <VerticalSpacer size="12px" />
              <Body1 kind="textBody" align="center">
                Click{' '}
                <span style={{fontWeight: 700, color: '#242424'}}>
                  “confirm”
                </span>{' '}
                to continue.
              </Body1>
              <VerticalSpacer size="24px" />

              <Button
                onClick={() => {
                  setFeedbackStatus(showModal);
                  showModal && handleUpdateFeedback(showModal);

                  setShowModal('');
                }}
                width="full">
                Confirm
              </Button>
              <VerticalSpacer size="64px" />
            </div>
          </ModalCard>
        </ModalWrapper>
      </Modal>
    </>
  );
});
