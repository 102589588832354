import {FC} from 'react';
import styled from 'styled-components';
import {months} from '../../performance-date-dropdown/performance-date-dropdown';
import {PerformanceMetric} from '../performance-metric-box';
import {PerformanceIllustrationMonthScoreBox} from '../performance-month-score-box';
import {Avatars} from '@ui/molecules/avatars';

export type Rating =
  | 'lowValuesHighProductivity'
  | 'highValuesHighProductivity'
  | 'lowValuesLowProductivity'
  | 'highValuesLowProductivity';

interface IFeedbackIllustration {
  rating: Rating;
  selectedMonths: number[];
  year: number;
  value?: any;
  text?: any;
  avatar?: any;
  avatars: {
    name: string;
    src: string;
    id: string;
  }[];
  managerViewResult?: boolean;
  id?: any;
  competence?: any;
  name?: any;
  quadrantData: any;
}
// Styled comp.
const Wrapper = styled.div`
  position: relative;
  padding-right: 22px;
`;
const IllustrationWrapper = styled.div`
  width: 100%;
  height: 480px;
  diplay: flex;
  flex-direction: column;
`;
const TopWrapper = styled.div`
  display: flex;
  height: 50%;
`;
const BottomWrapper = styled.div`
  display: flex;
  height: 50%;
`;
const LowValueHighProductivity = styled.div`
  position: relative;
  background: #ffe3c8;
  width: 50%;
  height: auto;
  margin: 2px;
  border-top-left-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const HighValueHighProductivity = styled.div`
  position: relative;
  background: #d3eee3;
  width: 50%;
  height: auto;
  margin: 2px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const LowValueLowProductivity = styled.div`
  position: relative;
  background: #f7e4e4;
  width: 50%;
  height: auto;
  margin: 2px;
  border-bottom-left-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const HighValueLowProductivity = styled.div`
  position: relative;
  background: #ffe3c8;
  width: 50%;
  height: auto;
  margin: 2px;
  border-bottom-right-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const XAxis = styled.div`
  color: #5f5f8c;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  & p {
    margin-right: 16px;
  }
`;
const YAxis = styled.div`
  color: #5f5f8c;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 0;
  & p {
    margin-top: 16px;
    writing-mode: vertical-rl;
  }
`;
const CardWrapper = styled.div`
  position: absolute;
  bottom: 65%;
  height: 66px;
  left: 45%;
`;

const xSvg = (
  <svg
    width="633"
    height="8"
    viewBox="0 0 633 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M632.354 4.35355C632.549 4.15829 632.549 3.84171 632.354 3.64645L629.172 0.464466C628.976 0.269204 628.66 0.269204 628.464 0.464466C628.269 0.659728 628.269 0.976311 628.464 1.17157L631.293 4L628.464 6.82843C628.269 7.02369 628.269 7.34027 628.464 7.53553C628.66 7.7308 628.976 7.7308 629.172 7.53553L632.354 4.35355ZM0 4.5H632V3.5H0V4.5Z"
      fill="#CECEDE"
    />
  </svg>
);
const ySvg = (
  <svg
    width="8"
    height="384"
    viewBox="0 0 8 384"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.35355 0.646454C4.15829 0.451202 3.84171 0.451202 3.64645 0.646454L0.464466 3.82843C0.269204 4.02371 0.269204 4.3403 0.464466 4.53555C0.659728 4.7308 0.976311 4.7308 1.17157 4.53555L4 1.70712L6.82843 4.53555C7.02369 4.7308 7.34027 4.7308 7.53553 4.53555C7.7308 4.3403 7.7308 4.02371 7.53553 3.82843L4.35355 0.646454ZM4.5 384L4.5 1.00003L3.5 1.00003L3.5 384H4.5Z"
      fill="#CECEDE"
    />
  </svg>
);

export const FeedbackIllustration: FC<IFeedbackIllustration> = ({
  rating,
  selectedMonths,
  name,
  value,
  competence,
  text,
  managerViewResult,
  avatar,
  avatars,
  id,
  year,
  quadrantData,
}) => {
  const date = `${months[selectedMonths[0] - 1]}, ${year}`;
  const renderAvatars = <Avatars items={avatars} total={avatars.length} />;
  return (
    <Wrapper>
      <XAxis>
        <p>Values</p> {xSvg}
      </XAxis>
      <IllustrationWrapper>
        <TopWrapper>
          <LowValueHighProductivity style={{position: 'relative'}}>
            {quadrantData !== null &&
              selectedMonths.length > 0 &&
              rating === 'lowValuesHighProductivity' && (
                <PerformanceIllustrationMonthScoreBox
                  date={date}
                  score={quadrantData.weightedScore}
                />
              )}
            {value === '-ve' && competence === '+ve' && (
              <CardWrapper>{renderAvatars}</CardWrapper>
            )}
            <PerformanceMetric
              color="orange"
              firstArrowProps={{direction: 'down', color: 'red'}}
              secondArrowProps={{direction: 'up', color: 'green'}}
            />
          </LowValueHighProductivity>
          <HighValueHighProductivity>
            {quadrantData !== null &&
              selectedMonths.length > 0 &&
              rating === 'highValuesHighProductivity' && (
                <PerformanceIllustrationMonthScoreBox
                  date={date}
                  score={quadrantData.weightedScore}
                />
              )}
            {value === '+ve' && competence === '+ve' && (
              <CardWrapper>{renderAvatars}</CardWrapper>
            )}

            <PerformanceMetric
              color="green"
              firstArrowProps={{direction: 'up', color: 'green'}}
              secondArrowProps={{direction: 'up', color: 'green'}}
            />
          </HighValueHighProductivity>
        </TopWrapper>
        <BottomWrapper>
          <LowValueLowProductivity>
            {quadrantData !== null &&
              selectedMonths.length > 0 &&
              rating === 'lowValuesLowProductivity' && (
                <PerformanceIllustrationMonthScoreBox
                  date={date}
                  score={quadrantData.weightedScore}
                />
              )}
            {value === '-ve' && competence === '-ve' && (
              <CardWrapper>{renderAvatars}</CardWrapper>
            )}

            <PerformanceMetric
              color="red"
              firstArrowProps={{direction: 'down', color: 'red'}}
              secondArrowProps={{direction: 'down', color: 'red'}}
            />
          </LowValueLowProductivity>
          <HighValueLowProductivity>
            {quadrantData !== null &&
              selectedMonths.length > 0 &&
              rating === 'highValuesLowProductivity' && (
                <PerformanceIllustrationMonthScoreBox
                  date={date}
                  score={quadrantData.weightedScore}
                />
              )}
            {value === '+ve' && competence === '-ve' && (
              <CardWrapper>{renderAvatars}</CardWrapper>
            )}

            <PerformanceMetric
              color="orange"
              firstArrowProps={{direction: 'up', color: 'green'}}
              secondArrowProps={{direction: 'down', color: 'red'}}
            />
          </HighValueLowProductivity>
        </BottomWrapper>
      </IllustrationWrapper>
      <YAxis>
        {ySvg} <p>Competence</p>
      </YAxis>
    </Wrapper>
  );
};
FeedbackIllustration.displayName = 'FeedbackIllustration';
