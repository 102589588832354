import React from 'react';

export default function TabSwitch({child1, child2}: any) {
  return (
    <>
      {child1 && <div>{child1}</div>}
      {child2 && <div>{child2}</div>}
    </>
  );
}
