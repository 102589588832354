import PageLayout from '@ui/templates/page-template/page-layout';
import styled from 'styled-components';
import {Controller} from 'react-hook-form';
import {Label} from '@ui/atoms/label';

import {VerticalSpacer} from '@ui/atoms/spacer';
import {observer} from 'mobx-react-lite';
import {TextField} from '@ui/molecules/field/textfield';
import {useFetchHook} from '@hooks/query-hook';
import {GoalsSelectField} from '@ui/molecules/select/goal-select-field/goal-select-field';
import {Button} from '@ui/atoms/button';
import {SelectField} from '@ui/molecules/select/selectfield';
import {UserSelectField} from '@ui/molecules/select/user';
import {useWorkspaceUseCase} from '@hooks/workspace-usecase';
import useRequestFeedback from './request-feedback-hook';
import {useNavigate} from 'react-router';
import {useStoreContext} from '@store/store-context';

const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .form {
    width: 40%;

    margin-bottom: 30px;
    @media (min-width: 1100px) and (max-width: 1320px) {
      width: 40%;
    }
    @media (min-width: 768px) and (max-width: 1100px) {
      width: 70%;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;
const RequestFeedback = () => {
  const navigate = useNavigate();
  const {
    groupStore: {groups},
    groupTypeStore: {groupType},
  } = useStoreContext();
  const {isPerformanceActive} = useWorkspaceUseCase();
  const {
    handleFormValueChange,
    users,
    isSubmitting,
    errors,
    handleSubmit,
    onSubmit,
    feedbackFor,
    initGoals,
    isDisabled,
    control,
    feedbackForOptions,
  } = useRequestFeedback();

  const {data} = useFetchHook(
    ['feedback-init-goals', groups, groupType],
    () => initGoals(1, 'networkLevel'),
    {
      enabled:
        groups?.length > 0 && groupType?.length > 0 && isPerformanceActive,
    },
  );

  return (
    <PageLayout
      title="Request feedback"
      guideUrl="https://peoplebeam.myhcpage.com/en-us/"
      onClick={() => navigate(-1)}>
      <FormWrapper>
        <div className="form">
          <form action="" onSubmit={handleSubmit(onSubmit)}>
            <Label>Who should write you feedback?</Label>
            <VerticalSpacer size="8px" />
            <Controller
              control={control}
              name="user"
              render={({onBlur, value}) => (
                <UserSelectField
                  onBlur={onBlur}
                  name="user"
                  value={value}
                  helper={errors.user?.message || ''}
                  state={!!errors.user ? 'error' : 'default'}
                  onChange={(data: {value: string}) =>
                    handleFormValueChange(data.value, 'user')
                  }
                  placeholder="Select member"
                  options={users}
                  fieldNotFoundPlaceHolder={(searchTerm?: string) =>
                    `Oops! Seems there is no ${searchTerm} found in this workspace`
                  }
                />
              )}
            />
            <Label>Request feedback for?</Label>
            <VerticalSpacer size="8px" />
            <Controller
              control={control}
              name="feedbackFor"
              render={({onBlur, value}) =>
                isPerformanceActive ? (
                  <SelectField
                    onBlur={onBlur}
                    name="feedbackFor"
                    value={value}
                    borderRadius={10}
                    helper={errors.feedbackFor?.message || ''}
                    state={!!errors.feedbackFor ? 'error' : 'default'}
                    onChange={(data: {value: string}) => {
                      handleFormValueChange(data?.value, 'feedbackFor');
                      handleFormValueChange(null, 'subjectName');
                    }}
                    placeholder="Select "
                    options={feedbackForOptions}
                    fieldNotFoundPlaceHolder={(searchTerm?: string) =>
                      `Oops! Seems there is no ${searchTerm} found`
                    }
                  />
                ) : (
                  <></>
                )
              }
            />

            {feedbackFor === 'goal' && data && (
              <Controller
                control={control}
                name="subjectId"
                render={({onBlur, value}) => (
                  <>
                    <GoalsSelectField
                      placeholder={`Search goal`}
                      value={value}
                      //   ref={UserSelect3Ref}
                      options={data}
                      onChange={(data: {value: string; label: string}) => {
                        handleFormValueChange(data.label, 'subjectName');
                        handleFormValueChange(data.value, 'subjectId');
                      }}
                    />
                  </>
                )}
              />
            )}

            {feedbackFor === 'custom' && (
              <Controller
                control={control}
                name="subjectName"
                render={({onBlur, value}) => (
                  <>
                    <TextField
                      helper={errors.subjectName?.message || ''}
                      maxLength={45}
                      state={!!errors.subjectName ? 'error' : 'default'}
                      onChange={(event) =>
                        handleFormValueChange(event.target.value, 'subjectName')
                      }
                      placeholder="Please provide a feedback subject (max. 45 characters)."
                      inputStyle={{borderRadius: 10}}
                    />
                  </>
                )}
              />
            )}

            <Controller
              control={control}
              name="subjectName"
              render={({onBlur, value}) => <></>}
            />

            <VerticalSpacer size="16px" />
            <Button
              width="full"
              type="submit"
              data-form-action={true}
              isLoading={isSubmitting}
              disabled={isSubmitting || isDisabled}
              aria-busy={isSubmitting}>
              Send request
            </Button>
          </form>
        </div>
      </FormWrapper>
    </PageLayout>
  );
};
export default observer(RequestFeedback);
