import {ITaskRequestImpl} from './tasks-requests';
import {parseErrorContent} from '@utils/error-handling';
import {activateNotification} from '@ui/molecules/notification/activate-notification';

export class TaskController {
  constructor(private readonly request: ITaskRequestImpl) {}

  async getUserTaskList(userId: string) {
    try {
      const response = this.request.getUserTaskList(userId);
      return response;
    } catch (error) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(error),
        kind: 'error',
      });
    }
  }
}
