import {useCallback, useEffect, useState} from 'react';
import {loadImage} from './load_image';
import {useMounted} from './mounted';

export const Status = {
  LOADING: 'loading',
  LOADED: 'loaded',
  FAILED: 'failed',
};

// TODO: implement the caching with service-worker to store the image
const cache = new Map();

// TODO: implement IntersectionObserver with image lazyload https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
// TODO: implement the status with useReducer approach
export function useImage(src: string) {
  const cachedImg = cache.get(src);
  const initialState = cachedImg ? Status.LOADED : Status.LOADING;
  const [status, setStatus] = useState(initialState);
  const mounted = useMounted();

  const handleImageLoad = useCallback(async () => {
    try {
      const image = await loadImage(src);
      if (!mounted.current) return;

      cache.set(src, image);
      setStatus(Status.LOADED);
    } catch (error) {
      if (!mounted.current) return;

      cache.delete(src);
      setStatus(Status.FAILED);
    }
  }, [mounted, src]);

  useEffect(() => {
    if (!src || status === Status.LOADED) return;
    handleImageLoad();
  }, [src, status, handleImageLoad]);

  return [status, cachedImg];
}
