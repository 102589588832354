import styled from 'styled-components';
import {getColorFromTheme} from '@ui/ui-utils';
import {Body1} from '../typography';

const Wrapper = styled.div`
  background: ${getColorFromTheme('backgroundLight')};
  padding: 14px 16px;
  border: 1px solid #cecede;
  border-radius: 10px;
`;

export const KeyResultContext = ({text}: {text: string}) => {
  return (
    <Wrapper>
      <Body1 kind="textBody">{text}</Body1>
    </Wrapper>
  );
};
