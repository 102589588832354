import styled from 'styled-components';
import {useDropDown} from '@hooks';
import {observer} from 'mobx-react-lite';
import {Body2, Body1} from '@ui/atoms/typography';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {Flex, FlexRow, FlexRowCenter} from '@ui/style/styles';
import {SearchBox2} from '@ui/molecules/search-box';
import {ColorIconGreen, CustomColorIcon} from '@ui/atoms/color-icons';
import dayjs from 'dayjs';
import {useMemo, useState} from 'react';
import {SelectField} from '@ui/molecules/select/selectfield';
import {FiChevronDown} from 'react-icons/fi';
import {useStoreContext} from '@store/store-context';
import {getColorFromTheme} from '@ui/ui-utils';
import {Button} from '@ui/atoms/button';
import {ReviewCycleProps} from '@api/cc-api/cc-request';
import {ItemLoaderLine} from '@ui/organisms/item-loader-line';

export const FilterWrapper = styled.div`
  display: block;
  grid-gap: 12px;
  border: 1px solid ${getColorFromTheme('borderDark')};
  position: absolute;
  z-index: 4;
  width: 100%;
  margin-top: 9px;

  padding: 16px;
  background: white;
  border-radius: 10px;
  box-shadow: 1px 1px 5px 4px rgba(0, 0, 0, 0.05);
`;
export const FilterWrapperReviewCycle = styled.div`
  display: block;
  border: 1px solid ${getColorFromTheme('borderDark')};
  position: absolute;
  z-index: 4;
  width: 320px;
  right: 14px;

  padding: 8px;
  background: white;
  border-radius: 10px;
  box-shadow: 1px 1px 5px 4px rgba(0, 0, 0, 0.05);
  .options {
    overflow-y: auto;
    max-height: 232px;

    margin-top: 16px;
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
`;

export const DropdownOptions = styled.button`
  border: 1px solid ${getColorFromTheme('borderDark')};
  padding: 12px 16px;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  &:disabled {
    cursor: not-allowed;
  }
`;
export const ReviewCycleOptions = styled.button`
  border: 1px solid ${getColorFromTheme('borderDark')};
  padding: 16px;
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  &:disabled {
    cursor: not-allowed;
  }
`;
const DropdownItem = styled.div`
  padding: 6px 8px;
  &:hover {
    background: #f6f6f8;
    cursor: pointer;
    border-radius: 8px;
    .header {
      font-weight: 500;
      color: #242424;
    }
  }
`;

interface IFilter {
  disabled?: boolean;
  isLoading?: boolean;
  status?: boolean;
  showOnlyEnded?: boolean;
  reviewCycleOptions?: Array<ReviewCycleProps>;
}
const CycleFilter = ({
  disabled,
  reviewCycleOptions,
  isLoading,
  showOnlyEnded,
}: IFilter) => {
  const {
    ccStore: {
      cycleFilterOptions: {status, reviewCycle},
      updateCurrentReviewCycle,
      updatecycleFilterOptions,
    },
  } = useStoreContext();

  const {handleOpen, handleClose, ref, open} = useDropDown();

  const [values, setValues] = useState({
    status: showOnlyEnded ? {label: 'Ended', value: 'ended'} : status,
    reviewCycle,
  });

  const handleStatus = (
    status: 'active' | 'upcoming' | 'archieved' | 'ended' | 'disabled' | 'all',
  ) => {
    switch (status) {
      case 'active':
        return <ColorIconGreen />;
      case 'upcoming':
        return (
          <CustomColorIcon
            color="#BFBFD4"
            width="8px"
            height="8px"
            margin={0}
          />
        );
      case 'archieved':
        return (
          <CustomColorIcon
            color="#BFBFD4"
            width="8px"
            height="8px"
            margin={0}
          />
        );
      case 'ended':
        return (
          <CustomColorIcon
            color="#F39C9A"
            width="8px"
            height="8px"
            margin={0}
          />
        );
      case 'disabled':
        return null;

      default:
    }
  };

  const statusOptions = useMemo(() => {
    const options = [
      {
        value: 'active',
        label: 'Active',
      },
      {
        value: 'upcoming',
        label: 'Upcoming',
      },
      {
        value: 'ended',
        label: 'Ended',
      },
      {
        value: '',
        label: 'All',
      },
    ];

    if (showOnlyEnded) {
      return [options[2]];
    }
    return options;
  }, [showOnlyEnded]);

  const handleChange = (
    field: keyof typeof values,
    value: {label: string; value: string},
  ) => {
    if (field === 'status') {
      updatecycleFilterOptions({status: value, reviewCycle});
    }
    setValues({
      ...values,
      [field]: value,
    });
  };

  const handleApply = () => {
    updatecycleFilterOptions(values);

    const selectedOption = reviewCycleOptions?.find(
      (option) => option.id === values.reviewCycle.value,
    );

    updateCurrentReviewCycle(selectedOption);

    handleClose();
  };

  return (
    <Wrapper ref={ref}>
      <DropdownOptions onClick={handleOpen} disabled={disabled}>
        <FlexRow>
          {handleStatus(
            disabled || !reviewCycle.label ? 'disabled' : (status.value as any),
          )}
          {disabled || !reviewCycle.label ? (
            <Body2 kind={disabled ? 'textMuted' : 'textDark'} weight="semibold">
              Select cycle
            </Body2>
          ) : (
            <Body2 weight="bold" style={{marginLeft: '4px'}}>
              {reviewCycle.label}
            </Body2>
          )}
        </FlexRow>
        <FiChevronDown color={disabled ? '#BFBFD4' : '#242424'} />{' '}
      </DropdownOptions>{' '}
      {open && (
        <FilterWrapper>
          <Body1 weight="semibold">Status</Body1>
          <VerticalSpacer size="12px" />
          <SelectField
            placeholder="Select here"
            defaultValue={values.status.value}
            onChange={(data: {label: string; value: string}) => {
              handleChange('status', data);
            }}
            options={statusOptions}
            borderRadius="10px"
          />
          <Body1 weight="semibold">Review cycle</Body1>
          <VerticalSpacer size="12px" />

          {isLoading ? (
            <>
              <FlexRowCenter>
                <ItemLoaderLine />
              </FlexRowCenter>
              <VerticalSpacer size="12px" />
            </>
          ) : (
            <ReviewCycleDropdown
              disabled={false}
              options={reviewCycleOptions}
              value={values.reviewCycle}
              onChange={(data: {label: string; value: string}) =>
                handleChange('reviewCycle', data)
              }
              reviewCycle={reviewCycle}
            />
          )}

          <Footer>
            <Button
              width="full"
              kind="primary"
              onClick={handleApply}
              disabled={
                status.value === values.status.value &&
                reviewCycle.value === values.reviewCycle.value
              }
              style={{padding: '12px 24px', marginRight: '8px'}}>
              Apply
            </Button>
            <Button
              kind="secondary"
              width="full"
              style={{padding: '12px 24px'}}
              onClick={handleClose}>
              Close
            </Button>
          </Footer>
        </FilterWrapper>
      )}
    </Wrapper>
  );
};

const ReviewCycleDropdown = ({
  disabled,
  options,
  value,
  onChange,
  reviewCycle,
}: {
  disabled: boolean;
  options?: any[];
  value: {label: string; value: string};
  onChange: (data: {label: string; value: string}) => void;
  reviewCycle: {label: string; value: string};
}) => {
  const {handleOpen, ref, handleClose, open} = useDropDown();
  const handleChange = (option: {label: string; value: string}) => {
    onChange(option);
    handleClose();
  };
  return (
    <>
      <ReviewCycleOptions onClick={handleOpen} disabled={disabled}>
        <Flex>
          {disabled || !value.label ? (
            <Body1 kind="textBody">Select here</Body1>
          ) : (
            <Body1>{value.label}</Body1>
          )}
        </Flex>
        <FiChevronDown color={disabled ? '#BFBFD4' : '#242424'} />
      </ReviewCycleOptions>{' '}
      {open && (
        <>
          <FilterWrapperReviewCycle ref={ref}>
            <SearchBox2 placeholder="Search reviews" />
            <div className="options">
              {options &&
                options.map((option) => (
                  <DropdownItem>
                    <FlexRow
                      onClick={() =>
                        handleChange({
                          label: option.cycleName,
                          value: option.id,
                        })
                      }>
                      <div>
                        <Body2 kind="textBody" className="header">
                          {option.cycleName}
                        </Body2>
                        <FlexRow>
                          <Body2
                            style={{fontSize: '10px', marginTop: '5px'}}
                            kind="textBody"
                            weight="semibold">
                            (
                            {dayjs(option.milestones.startDate).format(
                              'D MMM YYYY',
                            )}{' '}
                            to{' '}
                            {dayjs(option.milestones.endDate).format(
                              'D MMM YYYY',
                            )}
                            ){' '}
                            <span style={{fontWeight: 400}}>
                              {' '}
                              -{' '}
                              {option.reviewType === '360'
                                ? '360 review'
                                : 'Self & Manager review'}
                            </span>
                          </Body2>
                        </FlexRow>
                      </div>
                    </FlexRow>
                  </DropdownItem>
                ))}
            </div>
          </FilterWrapperReviewCycle>
        </>
      )}
    </>
  );
};

export default observer(CycleFilter);
