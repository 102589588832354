import styled from 'styled-components';
import {getColorFromTheme} from '@ui/ui-utils';

export const LoadingWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Wrapper = styled.div`
  .header {
    padding: 18px 16px 24px;
    width: 100%;

    display: flex;
    align-items: center;
  }
  .body {
    .analytics {
      margin: 0px 0px 32px;
      background-color: white;
      padding: 24px;
      border: 1px solid #ededf2;
      border-radius: 10px;
      .analytics-head {
        padding-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .analytics-body {
        padding: 24px 32px;
        border: 1px solid #ededf2;
        border-radius: 10px;
      }
      .footer {
        border-top: 1px solid #ededf2;
        padding: 12px 16px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .value {
          background: #f6f6f8;
          border-radius: 8px;
          margin-left: 6px;
          padding: 4px 8px;
          @media (max-width: 500px) {
            margin-top: 5px;
          }
        }
      }
    }

    .table {
      width: 300px;
      border: 1px solid #ededf2;
      border-radius: 10px;
      background-color: white;
      .table-desc {
        overflow-x: hidden;
        border: 4px solid red;
        padding: 16px 24px 24px;
      }
    }
  }
`;
export const FeedbackCard = styled.div`
  padding: 8px 16px;
  gap: 16px;

  width: 283px;
  display: flex;
  margin-top: 16px;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ededf2;
  border-radius: 10px;
  .number {
    background: #ededf2;
    border-radius: 8px;
    padding: 6px;
  }
`;

export const TableContainer = styled.div`
  border-top: 1px solid #ededf2;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  -ms-overflow-style: none;

  overflow-x: scroll;
  scrollbar-width: none;
  div::-webkit-scrollbar {
    display: none;
  }
`;

export const ItemLoaderWrapper = styled.div`
  padding: 28px 12px 32px 22px;
  justify-content: center;
  width: 100%;
  border-top: 1px solid #ededf2;
`;

export const ItemWrapperContainer = styled.div``;

export const ItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 21% 20% 13% 12% 13% 12% 8% 2%;
  padding: 16px;
  border-top: 1px solid ${getColorFromTheme('borderLight')};
  align-items: center;
  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const SecondRowWrapper = styled.div`
  width: 100%;

  min-width: 850px;
  overflow-x: scroll;
  display: grid;
  @media (max-width: 800px) {
    max-width: 100%;
    overflow-x: auto;
    display: grid;
    min-width: 900px;
    div.header {
      min-width: 900px;
    }
  }
`;

export const HeadlineWrapper = styled.div`
  display: grid;
  width: 100%;

  grid-template-columns: 20% 18% 12% 14% 14% 11% 5%;
  padding-left: 16px;
`;

export const HeadlineWrapperContainer = styled.div`
  display: block;
  width: 100%;
  padding: 16px 16px 12px 0px;
  background-color: ${getColorFromTheme('backgroundLight')};
  white-space: nowrap;
  height: 52px;
`;
export const SettlementWrapper = styled.div<{bg: string; svgColor: string}>`
  padding: 4px 8px;
  width: 34px;
  height: 26px;

  background: ${({bg}) => bg};
  border-radius: 8px;
  svg {
    path {
      stroke: ${({svgColor}) => svgColor};
    }
  }
`;
