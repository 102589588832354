import React, {memo} from 'react';

export const ChevronGoalIcon = memo(() => {
  return (
    <svg
      width="12"
      height="7"
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.64645 6.14645L0.853553 1.35355C0.538571 1.03857 0.761654 0.5 1.20711 0.5L10.7929 0.5C11.2383 0.5 11.4614 1.03857 11.1464 1.35355L6.35355 6.14645C6.15829 6.34171 5.84171 6.34171 5.64645 6.14645Z"
        fill="#1E1E2F"
      />
    </svg>
  );
});
