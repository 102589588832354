import styled from 'styled-components';
import {memo, ReactChild} from 'react';

const OverlayWrapper = styled.div`
  z-index: 3;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  min-height: 1px;
  width: 100%;
  position: fixed;
  background: rgba(39, 50, 64, 0.6);
  will-change: opacity;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`;

export const Overlay = memo(({children}: {children: ReactChild}) => {
  return <OverlayWrapper>{children}</OverlayWrapper>;
});

Overlay.displayName = 'Overlay';
