import {observer} from 'mobx-react';
import {useCallback, useEffect} from 'react';
import {Navigate, Route, Routes, useNavigate} from 'react-router';
import {useLocation} from 'react-router-dom';
import {adminSettingsPage} from '@pages/dashboard/admin/admin-components/admin-people/admin-member/admin-member-components/admin-settings/admin-settings-page';
import {installCreateGoalPage} from '../pages/create-goal/create-goal-install';
import {WorkspaceAPIController} from '@api/workspaces-api/workspaces-api-controller';
import {WorkspaceAPIRequestImpl} from '@api/workspaces-api/workspaces-api-request';
import {importManager} from '../pages/import-goals/import-manager';
import ViewUserFeedback from '@pages/dashboard/reporting/reporting-pages/admin-analytics/analytics-pages/analytics-feedback/view-user-feedback/view-user-feedback';
import {useNetworkState} from '@hooks/network-indicator';
import MakeReview from '../pages/dashboard/culture-competence/make-review/make-review';
import CreateReviewFrameworks from '@pages/dashboard/admin/admin-components/admin-workspace/workspace-performance/admin-culture-competence/create-review-framework/create-review-frameworks';
import CreateCategories from '@pages/dashboard/admin/admin-components/admin-workspace/workspace-performance/admin-culture-competence/create-review-categories/create-categories';
import {adminWorkspacePage} from '../pages/dashboard/admin/admin-components/admin-workspace/admin-workspace-page';
import {WorkspaceInviteForm} from '../pages/dashboard/admin/admin-components/invite/workspace-invite-form';
import {CompanyPDFReport} from '../pages/dashboard/reporting/reporting-pages/reporting-export/pdf-templates/reporting-export-pdf';
import {memberprofilePage} from '../pages/dashboard/member-profile/member-profile-page';
import {useStoreContext} from '../store/store-context';
import {RedirectTo} from './create-routes';
import RequestFeedback from '@pages/feedback/request-feedback/request-feedback';
import {CheckinsComments} from '@pages/dashboard/checkins/checkins-comments/checkins-comments';
import CreateFeedback from '@pages/feedback/create-feedback/create-feedback';
import {CreateAlignment} from '@pages/create-alignment/create-alignment';
import {useUrlQuery} from '@hooks/use-url-query';
import {ManageGroups} from '@pages/dashboard/groups/group-drawer';
import {PreviewReviewCycle} from '@pages/dashboard/admin/admin-components/admin-performance/performance-framework/review-cycle/preview-review-cyle/preview-review-cycle';
import {trackPage, trackTime} from '@utils/mix-panel';
import {ErrorBoundary} from '@pages/error/error-boundary';
import {Insights} from '../pages/dashboard/you/you-components/insights/insights';
import {useDirectOptionHook} from '@pages/dashboard/reporting/reporting-pages/reporting-overview/hooks/direct-options.hook';
import {CreateMeeting} from '@pages/1:1s/create-meetings';
import CreateValues from '@pages/dashboard/admin/admin-components/admin-workspace/workspace-performance/admin-culture-competence/create-values/create-values';
import CreateReviewCycle from '../pages/dashboard/admin/admin-components/admin-performance/performance-framework/review-cycle/create-review-cycle';
import {createDashboardRoutes} from './header';
import {usePostHog} from 'posthog-js/react';
import {MasteryPage} from '@pages/dashboard/get-started/mastery-page';
import {CascadeKeyResult} from '@pages/cascade-keyresults/cascade-keyresults';
import {ManagerEditGoal} from '../pages/dashboard/goals/manager-edit-goal/manager-edit-goal';
import CreatePerformanceTemplate from '../pages/dashboard/admin/admin-components/admin-performance/performance-framework/create-template/create-template';
import {installCreateQuestionPage} from '../pages/dashboard/company-legacy/company-components/company-checkins/company-checkins-questions/components/create-questions/create-question.install';
import {ViewUserCheckins} from '../pages/dashboard/company-legacy/company-components/company-checkins/company-direct-reports/view-user-checkins/view-user-checkins';
import {useWorkspaceUseCase} from '@hooks/workspace-usecase';
import {MeetingNotes} from '@pages/1:1s/meeting-notes';
import {useGCalenderHook} from '@hooks/gcalender';
import {initializePendo} from '@utils/pendo-initialize';
import ScrollToTop from '@utils/scrollToTop';
import {ViewMeetingsPage} from '@pages/1:1s/view-meetings/view-meetings-page';
import {ViewTeamMeetings} from '@pages/1:1s/meetings/view-meeting';
import {useOneTimeFetch} from '@hooks/query-hook';
import {useFirebaseAppListeners} from '@hooks/app-updates';

export const createHomeRoutes = () => {
  const {DashboardsRoutes} = createDashboardRoutes();
  const {Page: CreateGoal} = installCreateGoalPage();
  const {Page: CreateQuestion} = installCreateQuestionPage();

  const {Page: ImportManager} = importManager();
  const {Page: AdminWorkspacePage} = adminWorkspacePage();

  const {Page: MemberPage} = memberprofilePage();
  const {Page: AdminSettingsPage} = adminSettingsPage();

  const HomeRoutes = observer(() => {
    const {
      billingsStore: {setNumSeats},
      authStore: {auth, setFeatureLimitStatus},
      integrationStore: {isGapiLoaded},
      checkinsStore: {setUserFilter, userFilter},
    } = useStoreContext();

    const posthog = usePostHog();
    const navigate = useNavigate();
    const location = useLocation();

    const {fetchGoogleToken} = useGCalenderHook();

    useEffect(() => {
      if (isGapiLoaded) {
        fetchGoogleToken();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isGapiLoaded]);

    useEffect(() => {
      if (auth.user) {
        posthog?.identify(auth.user.id, {
          email: auth.user.email,
          workspace: auth.user.workspace,
        });

        posthog?.capture('$pageview');
        posthog?.capture('$pageleave');

        posthog?.group('workspace', auth.user.workspace.id);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.user]);

    useUrlQuery();

    // handle application offline and online state
    const {handleOnline, handleOffline} = useNetworkState();

    useEffect(() => {
      window.addEventListener('online', (event) => {
        handleOnline();
      });
      window.addEventListener('offline', handleOffline);

      return () => {
        window.removeEventListener('online', handleOnline);
        window.removeEventListener('offline', handleOffline);
      };
    });

    useFirebaseAppListeners();

    const initPendo = useCallback(() => {
      initializePendo({
        userId: auth.user.id,
        workspaceName: auth.user.workspace.name,
        workspaceId: auth.user.workspace.id,
        firstName: auth.user.firstName,
        lastName: auth.user.lastName,
        email: auth.user.email,
        billingStatus: auth.user.workspace.billing.status,
        role: auth.user.role,
      });
    }, [auth.user]);

    const setupPosthog = useCallback(() => {
      if (auth.user) {
        posthog?.identify(auth.user.id, {
          email: auth.user.email,
          workspace: auth.user.workspace,
        });
        posthog?.capture('$pageview');
        posthog?.capture('$pageleave');
        posthog?.group('workspace', auth.user.workspace.id);
      }
    }, [auth.user, posthog]);

    const handleVisibilityChange = useCallback(() => {
      if (document.hidden) {
        trackPage('page_exit');
      } else {
        trackPage('page_viewed');
        trackTime('page_exit');
      }
    }, []);

    useEffect(() => {
      initPendo();
      setupPosthog();

      window.addEventListener('online', handleOnline);
      window.addEventListener('offline', handleOffline);
      document.addEventListener('visibilitychange', handleVisibilityChange);

      return () => {
        window.removeEventListener('online', handleOnline);
        window.removeEventListener('offline', handleOffline);
        document.removeEventListener(
          'visibilitychange',
          handleVisibilityChange,
        );
      };
    }, [
      initPendo,
      setupPosthog,
      handleOnline,
      handleOffline,
      handleVisibilityChange,
    ]);

    // fetch workspace limit status
    const fetchFeatureLimitStatus = useCallback(async () => {
      const request = new WorkspaceAPIRequestImpl();
      const controller = new WorkspaceAPIController(request);
      const response: any = await controller.fetchFeatureLimitStatus();

      response && setFeatureLimitStatus(response);
      response && setNumSeats(response.currentStatus.seats);
    }, [setFeatureLimitStatus, setNumSeats]);

    useOneTimeFetch(['featureLimitStatus'], () => fetchFeatureLimitStatus());

    const {directReportsTeamOption} = useDirectOptionHook();

    useEffect(() => {
      setUserFilter({
        ...userFilter,
        selectedTeam: directReportsTeamOption[0],
      });

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [directReportsTeamOption]);

    const {
      isPerformanceActive,
      isEngagementActive,
      isOneOnOneEnabled,
      isCheckinsEnabled,
      isFeedBackEnabled,
    } = useWorkspaceUseCase();

    // Handle redirect logic
    useEffect(() => {
      const queryParams = new URLSearchParams(location.search);
      const redirectPath = queryParams.get('redirect');

      if (redirectPath) {
        if (auth.user) {
          navigate(redirectPath);
        } else {
          localStorage.setItem('redirectPath', redirectPath);
        }
      }
    }, [auth.user, location.search, navigate]);

    useEffect(() => {
      const storedRedirectPath = localStorage.getItem('redirectPath');
      if (storedRedirectPath && auth.user) {
        localStorage.removeItem('redirectPath');
        navigate(storedRedirectPath);
      }
    }, [auth.user, navigate]);

    return (
      <ErrorBoundary>
        <ScrollToTop />
        <Routes>
          <Route path="/*" element={<DashboardsRoutes />} />

          {isPerformanceActive && (
            <>
              <Route path="create-goal/*" element={<CreateGoal />} />
              <Route path="import-goal" element={<ImportManager />} />
              <Route path="create-goal/goal-success" element={<CreateGoal />} />
              <Route path="create-alignment" element={<CreateAlignment />} />

              <Route path="cascade-keyresult" element={<CascadeKeyResult />} />

              <Route path="edit-goal" element={<CreateGoal />} />
              <Route
                path="explore-templates"
                element={<CreateGoal exploreTemplates />}
              />
            </>
          )}

          {isEngagementActive && (
            <>
              {isCheckinsEnabled && (
                <>
                  <Route
                    path="/view-checkins/:checkinsId/:type"
                    element={<ViewUserCheckins />}
                  />
                  <Route path="/create-question" element={<CreateQuestion />} />
                </>
              )}
              {isFeedBackEnabled && (
                <>
                  <Route
                    path="request-feedback"
                    element={<RequestFeedback />}
                  />
                  <Route path="write-feedback" element={<CreateFeedback />} />

                  <Route
                    path="view-user-feedback/:id"
                    element={<ViewUserFeedback />}
                  />
                </>
              )}
              {isOneOnOneEnabled && (
                <>
                  <Route path="create-1:1/*" element={<CreateMeeting />} />
                  <Route
                    path="create-meeting-notes/*"
                    element={<MeetingNotes />}
                  />
                  <Route
                    path="view-meetings/:id"
                    element={<ViewMeetingsPage />}
                  />

                  <Route
                    path="view-grouped-meetings"
                    element={<ViewTeamMeetings />}
                  />
                </>
              )}
            </>
          )}

          <Route path="/edit-profile/:id" element={<AdminSettingsPage />} />

          <Route
            path="invite-user/invite-success"
            element={<WorkspaceInviteForm />}
          />

          <Route path="invite-user" element={<WorkspaceInviteForm />} />

          <Route
            path="make-review"
            element={
              auth?.user?.workspace?.settings?.ccStatus === 'enabled' ? (
                <MakeReview />
              ) : (
                <RedirectTo redirectTo="/" />
              )
            }
          />

          <Route
            path="make-review-company"
            element={
              auth?.user?.workspace?.settings?.ccStatus === 'enabled' ? (
                <MakeReview tab="company" />
              ) : (
                <RedirectTo redirectTo="/" />
              )
            }
          />

          <Route
            path="create-review-cycle"
            element={
              auth?.user.workspace.settings?.ccStatus === 'enabled' ? (
                <CreateReviewCycle />
              ) : (
                <RedirectTo redirectTo="/" />
              )
            }
          />
          <Route
            path="preview-review-cycle"
            element={
              auth?.user.workspace.settings?.ccStatus === 'enabled' ? (
                <PreviewReviewCycle />
              ) : (
                <RedirectTo redirectTo="/" />
              )
            }
          />

          <Route
            path="edit-review-cycle/:id"
            element={
              auth?.user.workspace.settings?.ccStatus === 'enabled' ? (
                <CreateReviewCycle />
              ) : (
                <RedirectTo redirectTo="/" />
              )
            }
          />

          <Route
            path="create-performance-template"
            element={
              auth?.user.workspace.settings?.ccStatus === 'enabled' ? (
                <CreatePerformanceTemplate />
              ) : (
                <RedirectTo redirectTo="/" />
              )
            }
          />

          <Route
            path="create-performance-template/:id"
            element={
              auth?.user.workspace.settings?.ccStatus === 'enabled' ? (
                <CreatePerformanceTemplate />
              ) : (
                <RedirectTo redirectTo="/" />
              )
            }
          />

          <Route path="workspace-settings/*" element={<AdminWorkspacePage />} />

          <Route path="beam-mastery" element={<MasteryPage />} />

          <Route
            path="create-review-frameworks/:id"
            element={<CreateReviewFrameworks />}
          />
          <Route
            path="create-review-frameworks"
            element={<CreateReviewFrameworks />}
          />
          <Route
            path="create-review-categories"
            element={<CreateCategories />}
          />

          <Route path="create-review-values" element={<CreateValues />} />

          <Route path="edit/*" element={<MemberPage />} />

          <Route path="company/reports/pdf" element={<CompanyPDFReport />} />

          <Route path="*" element={<Navigate to="/" replace />} />

          <Route path="*" element={<div>Error page 404</div>} />
        </Routes>
        <Insights />
        <ManagerEditGoal />
        <CheckinsComments />
        <ManageGroups />
      </ErrorBoundary>
    );
  });

  return {
    HomeRoutes,
  };
};
