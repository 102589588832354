import {useMemo, forwardRef, Ref, ReactNode, useState} from 'react';
// import {motion} from 'framer-motion';
import ReactSelect, {
  components,
  IndicatorProps,
  OptionProps,
  PlaceholderProps,
} from 'react-select';
import {MagnifyingGlassIcon} from '../../../atoms/icons';
import {IndicatorWrapper} from '../option-select/styled';
import {flagList} from '../../../assets/flags';
import {Body1} from '../../../atoms/typography/body1';
import {Image} from '../../../atoms/image';
import {phoneCodes} from '../../../../utils/countries';
import {getStyles} from '../option-select/styled';
import styled from 'styled-components';
import {SharedProps} from '../shared';

const CustomBody1 = styled(Body1)`
  width: max-content;
`;

const {
  Placeholder: SelectPlaceholder,
  Option: SelectOption,
  DropdownIndicator: SelectDropdownIndicator,
} = components;

export interface TOption {
  value: string;
  label: string | ReactNode;
}

export interface SelectFieldProps extends SharedProps<TOption> {
  variant?: number;
}
interface IOption {
  label: string;
}

const FilterFlags: any = (flag: string) => {
  const flags: any = flagList;
  const res: any = Object.keys(flags)
    .filter((key) => key === flag)
    .reduce((obj: any, key: any) => {
      return Object.assign(obj, {
        [key]: flags[key],
      });
    }, {});

  return Object.values(res)?.[0];
};

const filterOptionSelectOptions = (
  option: {data: IOption},
  rawInput: string,
) => {
  if (rawInput) {
    const label = option.data.label.toLowerCase();
    return rawInput
      .toLowerCase()
      .split(' ')
      .every((word) => label.includes(word));
  }
  return true;
};

const Placeholder = (props: PlaceholderProps<TOption, false>) => {
  return (
    <SelectPlaceholder {...props}>
      <CustomBody1 kind="textBody">{props.children}</CustomBody1>
    </SelectPlaceholder>
  );
};

const Option = (props: OptionProps<TOption, false>) => {
  return (
    <SelectOption {...props}>
      {phoneCodes
        .filter((country) => country.name === props.label)
        .map((country) => (
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div style={{marginTop: '3px', marginRight: '3px'}}>
                <Image
                  width="24px"
                  height={'18px'}
                  {...FilterFlags(country?.code.toLowerCase())}
                />
              </div>
              <Body1
                style={{
                  fontWeight: props.isSelected || props.isFocused ? 500 : 400,
                  marginTop: '2.5px',
                  marginLeft: '3px',
                  fontSize: '16px',
                }}
                kind={
                  props.isSelected || props.isFocused ? 'purple300' : 'textBody'
                }>
                {country.name}
              </Body1>
            </div>
            <Body1
              style={{
                fontWeight: props.isSelected || props.isFocused ? 500 : 400,
                marginTop: '2.5px',
                fontSize: '16px',
              }}
              kind={
                props.isSelected || props.isFocused ? 'purple300' : 'textBody'
              }>
              {country.dial_code}
            </Body1>
          </div>
        ))}
    </SelectOption>
  );
};

const DropdownIndicator = (props: IndicatorProps<TOption, false>) => {
  return (
    <SelectDropdownIndicator {...props}>
      <IndicatorWrapper>
        <MagnifyingGlassIcon />
      </IndicatorWrapper>
    </SelectDropdownIndicator>
  );
};
export const SelectFieldSecondary = forwardRef(
  (
    {
      label,
      placeholder,
      state,
      helper,
      defaultValue,
      value,
      options,
      onChange,
      type,
      variant,
      ReactSelectStylingProperties,
      ...rest
    }: SelectFieldProps,
    ref: Ref<ReactSelect<TOption>>,
  ) => {
    const analyticsStyles = useMemo(() => getStyles<TOption, false>(), []);
    const [, setSearchTerm] = useState<string>('');

    const filterOption = (
      option: {
        data: IOption;
      },
      rawInput: string,
    ) => {
      setSearchTerm(rawInput);
      return filterOptionSelectOptions(option, rawInput);
    };
    return (
      <ReactSelect
        {...rest}
        isClearable={false}
        backspaceRemovesValue={false}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        menuIsOpen
        filterOption={filterOption}
        isSearchable
        noOptionsMessage={() => 'No country found'}
        value={value}
        components={{
          DropdownIndicator: DropdownIndicator,
          IndicatorSeparator: null,
          Placeholder,
          Option,
        }}
        options={options}
        tabSelectsValue={false}
        placeholder="Search"
        onChange={onChange}
        ref={ref}
        maxMenuHeight={240}
        styles={analyticsStyles}
        theme={(theme) => ({
          ...theme,
          borderRadius: 7,
          spacing: {
            baseUnit: 6,
            controlHeight: 48,
            menuGutter: 6,
          },
        })}
      />
    );
  },
);
