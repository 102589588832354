import styled from 'styled-components';
import {getColorFromTheme} from '../../ui-utils';
import {Headline4} from '../../atoms/typography/headline4';

export const Wrapper = styled.nav`
  display: block;
  position: absolute;
  right: 0;
  width: 312px;
  top: 120%;
  border-radius: 10px;
  border: solid 1px ${getColorFromTheme('borderLight')};
  background-color: ${getColorFromTheme('white')};

  box-shadow: ${({theme}) => theme.elevation.level1};
  z-index: 3;
`;

export const Footer = styled.footer`
  width: 100%;
  border-top: solid 1px ${getColorFromTheme('borderLight')};
  background-color: ${getColorFromTheme('backgroundLight')};
  padding-top: 16px;
  padding-bottom: 24px;
`;

export const Container = styled.div`
  width: 100%;
  padding: 24px 24px 16px;
  min-height: 320px;
`;

export const Designation = styled(Headline4)`
  vertical-align: middle;
  display: flex;
`;

export const LoaderWrapper = styled.div`
  align-items: center;
  justify-content: center;
  min-height: 300px;
  display: flex;
`;
