import React from 'react';
import styled from 'styled-components';
import {Body2} from '../../atoms/typography';
import {VerticalSpacer} from '../../atoms/spacer';
import {SearchBox} from '../search-box';
import {Button} from '../../atoms/button';
const Wrapper = styled.div<{height: any; width?: string}>`
  border-radius: 6px 6px;
  max-height: ${({height}) => (height ? `${height}px` : '428px')};
  z-index: 5;
  background: white;
  border: 1px solid #dcdce2;
  position: relative;
  width: ${({width}) => (width ? width : '400px')};
  overflow: hidden !important;
`;

const CardHeader = styled.div`
  border-bottom: 1px solid rgba(206, 206, 222, 1);
  padding: 16px 10px;
`;
const CardBody = styled.div<{height: any}>`
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #f6f6f8;
  }

  &::-webkit-scrollbar-thumb {
    background: #dcdce2;
    border-radius: 100px;
  }
  overflow-y: scroll;
  padding: 12px 10px;
  overflow: auto;
  max-height: ${({height}: any) => (height === 622 ? `450px` : '250px')};
`;
const Footer = styled.div`
  border-width: 0px 1px 1px 1px;
  border-color: rgba(206, 206, 222, 1);
  border-radius: 0px 0px 6px 6px;
  border-style: solid;
  padding: 12px 10px;
  display: flex;
  z-index: 1000;
  background: white;

  justify-content: space-between;
  position: relative;
`;
interface CardProps {
  title: string;
  placeholder: string;
  children: any;
  height?: any;
  loading?: boolean;
  disabled?: any;
  onSubmit?: (e: any) => void;
  handleSearch?: (e: any) => void;
  onClickCancel: (e: any) => void;
  width?: string;
  disableHeader?: boolean;
  disableFooter?: boolean;
}
export function DropdownCard({
  title,
  placeholder,
  children,
  onSubmit,
  disabled,
  height,
  loading,
  width,
  handleSearch,
  onClickCancel,
  disableFooter,
  disableHeader,
}: CardProps) {
  return (
    <>
      <Wrapper height={height} width={width}>
        {disableHeader ? null : (
          <CardHeader>
            <Body2
              onClick={onClickCancel}
              style={{
                fontWeight: 500,
                display: 'flex',
                cursor: 'pointer',
                alignItems: 'center',
              }}>
              {title}
            </Body2>
          </CardHeader>
        )}
        <VerticalSpacer size="12px" />
        <div style={{padding: '0px 22px'}}>
          <SearchBox placeholder={placeholder} onChange={handleSearch} />
          {disableHeader ? null : (
            <div
              style={{
                borderBottom: '1px solid rgba(237, 237, 242, 1)',
                height: '20px',
                margin: '5px 10px',
              }}></div>
          )}
        </div>
        <CardBody height={height}>{children}</CardBody>
      </Wrapper>
      {disableFooter ? null : (
        <Footer>
          <Button
            kind="secondary"
            style={{
              width: '79px',
              height: '40px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              overflow: 'hidden',
            }}
            onClick={onClickCancel}>
            Cancel
          </Button>
          <Button
            width="sm"
            kind="primary"
            onClick={onSubmit}
            isLoading={loading}
            disabled={disabled}
            style={{
              height: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              overflowY: 'hidden',
            }}>
            Save Changes
          </Button>
        </Footer>
      )}
    </>
  );
}
