import {CSSProperties, memo, ReactNode} from 'react';
import styled from 'styled-components';
import {prop} from 'styled-tools';
import {Body1} from '../typography/body1';
import {Headline3} from '../typography/headline3';
import {VerticalSpacer} from '../spacer';
import {FlexRowCenter} from '@ui/style/styles';

export interface EmptyPlaceholderProps {
  title: string;
  size?: string;
  imports?: boolean;
  imgPosition?: 'top' | 'bottom';
  subtitle: string;
  style?: CSSProperties;
  variant?: 'bold' | 'semibold' | 'default';
  image: {
    src: string;
    srcset?: string;
    alt: string;
    shadow?: string;
  };
  action?: ReactNode;
}

const Wrapper = styled.figure`
  margin: 92px auto;
  text-align: center;
  width: auto;
  max-width: 380px;
`;

const Image = styled.img<{shadow?: string}>`
  filter: ${prop(
    'shadow',
    'drop-shadow(-10px 44px 28px rgba(19, 17, 17, 0.12))',
  )};
`;

export const EmptyPlaceholder = memo(function ({
  title,
  subtitle,
  size,
  image,
  action,
  variant,
  imgPosition,
  style,
  imports,
}: EmptyPlaceholderProps) {
  return (
    <Wrapper style={style}>
      {(!imgPosition || imgPosition === 'top') && (
        <FlexRowCenter>
          <Image {...image} alt={image.alt} />
        </FlexRowCenter>
      )}

      <VerticalSpacer size="24px" />
      {size === 'empty' ? (
        <Body1 align="center" weight={variant ? variant : 'bold'}>
          {title}
        </Body1>
      ) : (
        <Headline3 align="center" style={{fontWeight: imports ? 500 : 600}}>
          {title}
        </Headline3>
      )}

      <VerticalSpacer size="8px" />

      <Body1 align="center" kind="textBody">
        {subtitle}
      </Body1>
      <VerticalSpacer size="16px" />
      {action}
      {imgPosition === 'bottom' && (
        <FlexRowCenter>
          <Image {...image} alt={image.alt} />
        </FlexRowCenter>
      )}
    </Wrapper>
  );
});

EmptyPlaceholder.displayName = 'EmptyPlaceholder';
