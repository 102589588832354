import {authStore} from '@store/stores/auth-store';
import {FIREBASE_BASE_URL} from '@utils/firebase-request';
import dayjs from 'dayjs';
import {getDatabase, ref, get} from 'firebase/database';

import {useEffect} from 'react';
import {
  DEFAULT_KEY_RESULT_CONFIG,
  DEFAULT_OBJECTIVES_CONFIG,
  DEFAULT_OBJECTIVES_STATUS_CONFIG,
} from '../edit-insight/edit-insight-hook';
import {capitalize} from '@utils';
import {cfv} from '@utils/framework';
import {updateFirebaseData, writeFirebaseData} from '@utils/firebase-handler';

export const useObjectiveInsightHook = () => {
  const createObjectivesInsights = () => {
    const commonFields = {
      createdBy: '',
      updatedAt: dayjs().format(),
      createdAt: dayjs().format(),
      updatedBy: '',
      type: 'objectives',
    };

    const actionItems = [
      {
        name: `${capitalize(cfv().g_oal)} completion`,
        id: 'objective-completion',
        config: DEFAULT_OBJECTIVES_CONFIG,
        ...commonFields,
      },
      {
        name: `${capitalize(cfv().g_oal)} status`,
        id: 'objective-status',
        config: DEFAULT_OBJECTIVES_STATUS_CONFIG,
        ...commonFields,
      },
      {
        name: `${capitalize(cfv().k_r)} performance`,
        id: 'key-results-performance',
        config: {...DEFAULT_KEY_RESULT_CONFIG},
        ...commonFields,
      },
    ];

    actionItems.forEach((item) => {
      writeFirebaseData('insights', item);
    });
  };

  useEffect(() => {
    const db = getDatabase();
    const actionItemsRef = ref(
      db,
      `${FIREBASE_BASE_URL}/${authStore.auth.user.workspace.id}/insights`,
    );

    get(actionItemsRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();

          const insights = Object.keys(data).map((insight) => ({
            ...data[insight],
            key: insight,
          }));

          const isActionItemsIncluded = insights.some(
            (insight) => insight.type === 'objectives',
          );
          const isKeyResultPerformanceIncluded = insights.some(
            (insight) => insight.id === 'key-results-performance',
          );

          if (isKeyResultPerformanceIncluded) {
            const keyResultPerformance = insights.find(
              (insight) => insight.id === 'key-results-performance',
            );

            if (!!keyResultPerformance?.config?.comparison.value) {
              updateFirebaseData(`insights/${keyResultPerformance.key}`, {
                ...keyResultPerformance,
                config: {
                  ...keyResultPerformance.config,
                  comparison: {
                    ...keyResultPerformance.config.comparison,
                    value: '',
                  },
                },
              });
            }
          }
          if (!isActionItemsIncluded) {
            createObjectivesInsights();
          }
        } else {
          createObjectivesInsights();
        }
      })
      .catch((error) => {});
  }, []);

  return {};
};
