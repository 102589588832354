import {YouCalendar} from '@pages/dashboard/company-legacy/shared-components/calendars/calendar-you-variant';
import {useStoreContext} from '@store/store-context';
import {Button} from '@ui/atoms/button';
import {Body2} from '@ui/atoms/typography';
import {DropdownCustom} from '@ui/molecules/dropdown-custom';
import {FlexRowSpaceBetween} from '@ui/style/styles';
import dayjs from 'dayjs';
import React from 'react';
import {FiChevronDown} from 'react-icons/fi';

const CalendarDropdown = () => {
  const {
    checkinsStore: {
      workspaceCheckins,
      configuration,
      setCheckinsDate,
      checkinsDates,
    },
  } = useStoreContext();

  return (
    <DropdownCustom
      collapseOnClick={true}
      menu={(handleClose: () => void) => (
        <>
          <YouCalendar
            disabled={Object.keys(workspaceCheckins).length === 0}
            startYear={
              configuration?.createdAt
                ? new Date(configuration?.createdAt).getFullYear()
                : new Date().getFullYear()
            }
            onClickWeek={(date) => {
              handleClose();
              setCheckinsDate({
                starts: date.start,
                ends: date.end,
                weekOfYear: date.weekOfYear,
                month: date?.month ? date?.month : checkinsDates?.month,
                year: date?.year ? date?.year : checkinsDates?.year,
                date: date?.date ? date?.date : checkinsDates?.date,
              });
            }}
          />
        </>
      )}
      customComponent={(handleOpen: (event: any) => void, open: boolean) => (
        <>
          <div>
            <Button
              kind="secondary"
              onClick={handleOpen}
              className="flex items-center justify-between"
              style={{
                minWidth: '125px',
                height: '38px',
                overflow: 'hidden',
                padding: '6px 8px',
              }}>
              <FlexRowSpaceBetween>
                <Body2 weight="bold">
                  {dayjs(checkinsDates.starts).format('MMM. DD')} -{' '}
                  {dayjs(checkinsDates.ends).format('MMM. DD')}
                </Body2>
                <FiChevronDown
                  style={{margin: '0px 5px 0px 0px'}}
                  aria-hidden="true"
                />
              </FlexRowSpaceBetween>
            </Button>
          </div>
        </>
      )}
      customDropdownWrapperStyles={{
        top: '130%',
        width: '200px',
        background: 'white',
        padding: 0,
        left: 0,
      }}
    />
  );
};

export default CalendarDropdown;
