import {IntegrationBody, Wrapper} from '../../components/app-body';
import {cfv} from '@utils/framework';
import {JiraIcon} from '@ui/atoms/icons/apps/jira';

export const JiraBody = {
  image: <JiraIcon />,
  title: 'Jira',
  description: `Connect the work that your team is doing in Jira to support your OKRs by linking items to Beam’s ${
    cfv(undefined, true).g_oals
  } and automatically update your ${cfv(undefined, true).k_rs}. `,
};
export const Jira = () => {
  return (
    <Wrapper>
      <IntegrationBody {...JiraBody} />
    </Wrapper>
  );
};
