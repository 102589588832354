import {useCallback, useEffect, useMemo, useState} from 'react';
import {useFirebaseFetch} from './query-hook';
import {useStoreContext} from '@store/store-context';
import {
  removeFirebaseData,
  updateFirebaseData,
  writeFirebaseData,
} from '@utils/firebase-handler';
import dayjs from 'dayjs';
import {get, ref} from 'firebase/database';
import {db} from '@utils/firebase-request';

export const useSections = () => {
  const {data: sections} = useFirebaseFetch(`sections`);

  const [showRenameSectionModal, setRenameSectionModal] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const {
    authStore: {auth},
  } = useStoreContext();

  const computeSections = useMemo(() => {
    if (sections) {
      return Object.entries(sections).map(([key, section]: any) => ({
        ...section,
        key,
      }));
    }
    return [];
  }, [sections]);

  const canCreateSection = auth.user.role === 'admin' || auth.user.isReviewer;

  const [sectionTitle, setSectionTitle] = useState('');

  const findSectionSlug = (key: string) => {
    return computeSections.find((section) => section.name === key)?.slug;
  };

  const handleCreateSection = useCallback(
    async (title?: string) => {
      await writeFirebaseData('sections', {
        createdBy: auth.user.id,
        createdAt: dayjs().format(),
        name: title || sectionTitle,
        slug: title || sectionTitle,
      });
    },
    [auth.user.id, sectionTitle],
  );

  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    const createInboxSection = () => {
      handleCreateSection('Inbox');
      setIsUpdated(true);
    };

    const fetchAndUpdateSections = async () => {
      const actionItemsPath = `${process.env.REACT_APP_DEV_ENV}/${auth.user.workspace.id}/sections/`;

      const databaseRef = ref(db, actionItemsPath);

      try {
        get(databaseRef).then((snapshot) => {
          if (!snapshot.exists()) {
            createInboxSection();
          } else {
            const sections = snapshot.val();

            const allSection = Object.entries(sections).map(
              ([key, section]: any) => ({
                ...section,
                key,
              }),
            );

            if (allSection) {
              const findInbox = allSection.find(
                (section) => section.slug.toLowerCase() === 'inbox',
              );
              if (!findInbox) {
                createInboxSection();
              }
            }
          }
        });
      } catch (error) {}
    };

    if (!isUpdated) {
      fetchAndUpdateSections();
    }
  }, [auth.user.workspace.id, handleCreateSection, isUpdated]);

  const findSectionFollowers = (key: string) => {
    return (
      computeSections.find((section) => section.name === key)?.subscribers || []
    );
  };

  const findSection = (key: string) => {
    return computeSections.find((section) => section.key === key);
  };

  const handleEditSection = async (id: string, name: string) => {
    const editedSection = findSection(id);

    await updateFirebaseData(`sections/${id}`, {
      ...editedSection,
      name,
    });
  };

  const handleFollowSection = async (id: string, userId: string) => {
    const editedSection = findSection(id);

    await updateFirebaseData(`sections/${id}`, {
      ...editedSection,
      subscribers: editedSection?.subscribers
        ? [...editedSection.subscribers, userId]
        : [userId],
    });
  };

  const handleRemoveSubscriber = async (id: string, userId: string) => {
    const editedSection = findSection(id);

    await updateFirebaseData(`sections/${id}`, {
      ...editedSection,
      subscribers: editedSection?.subscribers.filter(
        (user: string) => user !== userId,
      ),
    });
  };

  const handleDeleteSection = async (id: string) => {
    await removeFirebaseData(`sections/${id}`);
  };

  return {
    handleCreateSection,
    setSectionTitle,
    findSectionSlug,
    findSection,
    canCreateSection,
    handleEditSection,
    handleDeleteSection,
    computeSections,
    showRenameSectionModal,
    setShowDeleteModal,
    showDeleteModal,
    setRenameSectionModal,
    sectionTitle,
    handleFollowSection,
    handleRemoveSubscriber,
    findSectionFollowers,
  };
};
