import ReactSelect, {
  components,
  OptionsType,
  ValueType,
  ActionMeta,
  Props,
  IndicatorProps,
  OptionProps,
  ControlProps,
} from 'react-select';
import {MagnifyingGlassIcon} from '../../../atoms/icons/magnifying-glass';
import {IndicatorWrapper, getStyles, ControlWrapper, LeadNote} from './styled';
import {Body2, CTA} from '../../../atoms/typography';
import {forwardRef, Ref, useMemo, useState} from 'react';
import {GreenWhitePlusIcon} from '../../../atoms/icons/greenWhitePlus';
import {HorizontalSpacer} from '../../../atoms/spacer';

interface IOption {
  label: string;
}

const filterOptionSelectOptions = (
  option: {data: IOption},
  rawInput: string,
) => {
  if (rawInput) {
    const label = option.data.label.toLowerCase();
    return rawInput
      .toLowerCase()
      .split(' ')
      .every((word) => label.includes(word));
  }
  return true;
};

const {
  DropdownIndicator: SelectDropdownIndicator,
  Option: SelectOption,
  Control: SelectControl,
} = components;

export interface TOption {
  value: string;
  disabled?: boolean;
  label: string;
}

export interface SelectFieldProps extends Props<TOption> {
  leadingNote?: string;
  options: OptionsType<TOption>;
  onChange?: (
    value: ValueType<TOption, false>,
    action: ActionMeta<TOption>,
  ) => void;
}

const DropdownIndicator = (props: IndicatorProps<TOption, false>) => {
  return (
    <SelectDropdownIndicator {...props}>
      <IndicatorWrapper>
        <MagnifyingGlassIcon />
      </IndicatorWrapper>
    </SelectDropdownIndicator>
  );
};

const Control = (props: ControlProps<TOption, false>) => {
  if (props.selectProps.excludeSearchBox) {
    return null;
  }
  return (
    <ControlWrapper>
      {props.selectProps?.leadingNote && (
        <LeadNote>{props.selectProps.leadingNote}</LeadNote>
      )}
      <SelectControl {...props} />
    </ControlWrapper>
  );
};

const Option = (props: OptionProps<TOption, false>) => {
  return (
    <SelectOption {...props}>
      {props.label === 'Custom question' ||
      props.label === 'Custom timeline' ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={() => {
            //
          }}>
          {props.data?.disabled && (
            <span style={{margin: '3px 4px 0px 0px'}}>
              {props.selectProps.disabledIcon}
            </span>
          )}
          <GreenWhitePlusIcon /> <HorizontalSpacer size="16px" />{' '}
          <CTA kind="textDark" style={{fontWeight: 600}}>
            {props.selectProps.customOptionName ||
              'Create your custom question'}
          </CTA>
        </div>
      ) : (
        <Body2 kind="textBody" style={{padding: '4px 8px'}}>
          {props.data?.disabled && (
            <span style={{margin: '3px 4px 0px 0px'}}>
              {props.selectProps.disabledIcon}
            </span>
          )}
          {props.label}
        </Body2>
      )}
    </SelectOption>
  );
};

export const OptionSelect = forwardRef(
  (
    {
      options,
      onChange,
      customOptionName,
      value,
      maxHeight,
      fieldNotFoundPlaceHolder,
      excludeSearchBox,
      ...rest
    }: SelectFieldProps,
    ref: Ref<ReactSelect<TOption>>,
  ) => {
    const styles = useMemo(() => getStyles<TOption, false>(), []);

    const [searchTerm, setSearchTerm] = useState<string>('');

    const filterOption = (
      option: {
        data: IOption;
      },
      rawInput: string,
    ) => {
      setSearchTerm(rawInput);
      return filterOptionSelectOptions(option, rawInput);
    };
    return (
      <ReactSelect
        {...rest}
        isClearable={false}
        backspaceRemovesValue={false}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        menuIsOpen
        filterOption={filterOption}
        customOptionName={customOptionName}
        isSearchable
        excludeSearchBox={excludeSearchBox}
        value={value}
        noOptionsMessage={
          fieldNotFoundPlaceHolder
            ? () => fieldNotFoundPlaceHolder(searchTerm)
            : () => `No members found`
        }
        tabSelectsValue={false}
        placeholder="Search"
        onChange={onChange}
        ref={ref}
        maxMenuHeight={maxHeight || 240}
        components={{
          DropdownIndicator,
          Option,
          Control,
          IndicatorSeparator: null,
        }}
        options={options}
        styles={styles}
        theme={(theme) => ({
          ...theme,
          borderRadius: 7,
          spacing: {
            baseUnit: 6,
            controlHeight: 40,
            menuGutter: 6,
          },
        })}
      />
    );
  },
);
