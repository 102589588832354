import {observer} from 'mobx-react-lite';
import {WorkLifeDiv} from '../my_checkins-style';
import {Body2, Headline3, Body1} from '@ui/atoms/typography';
import {useStoreContext} from '@store/store-context';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {TextField} from '@ui/molecules/field/textfield';
import {TextTag2} from '@ui/atoms/tag';

export const MoreAboutWorkLife = observer(
  ({
    value,
    handleFormValueChange,
    isReadOnly,
    debounceUpdateQuestions,
  }: {
    value: {
      questions: {
        question: string;
        questionId: string;
        optional: boolean;
        response?: null | string;
        target: {type: string; ids: string[]};
      }[];
      id: string;
    };
    handleFormValueChange: (value: any, field: string) => void;
    debounceUpdateQuestions: (value: any) => void;
    isReadOnly: boolean;
  }) => {
    const {
      groupStore: {groups},
      checkinsStore: {
        workspaceCheckins,

        requiredUserQuestions,
      },
    } = useStoreContext();

    const parseTarget = (target: {type: string; ids: string[]}) => {
      switch (target.type) {
        case 'individual':
          return 'Individual';
        case 'group':
          return `Group: ${target.ids
            ?.map((id: string) => groups.find((grp) => grp.id === id)?.name)
            .join(', ')}`;
        default:
          return 'General';
      }
    };

    return (
      <>
        {value?.questions?.length > 0 && (
          <WorkLifeDiv className="bg-white">
            <div className="headerDiv">
              <div className="titleDiv">
                <div>
                  <Headline3 kind="textDark">Questions {'  '}</Headline3>
                  <VerticalSpacer size="5px" />
                  <Body1 kind="textBody">
                    Address your manager’s inquiries
                  </Body1>
                </div>

                <span />
              </div>
            </div>

            <div className="contentDiv">
              {value?.questions?.map((question, index) => (
                <div key={question?.question + index}>
                  <TextTag2>{parseTarget(question?.target)}</TextTag2>
                  <VerticalSpacer size="8px" />
                  <TextField
                    placeholder={
                      workspaceCheckins?.isReviewed ||
                      workspaceCheckins?.isSubmitted
                        ? ''
                        : 'Your response here'
                    }
                    label={
                      (
                        <div>
                          <div style={{display: 'flex'}}>
                            {' '}
                            <Body2 style={{fontWeight: 600}} kind="textDark">
                              {`${question?.question} `}{' '}
                              <span className="required">
                                {!question.optional ? '*' : ''}
                              </span>
                            </Body2>
                          </div>
                          <VerticalSpacer size="8px" />
                        </div>
                      ) as any
                    }
                    value={question?.response as string | undefined}
                    readOnly={isReadOnly}
                    disabled={workspaceCheckins?.isReviewed || isReadOnly}
                    helper={
                      requiredUserQuestions &&
                      requiredUserQuestions?.size > 0 &&
                      requiredUserQuestions?.get(question.questionId)?.error
                        ?.length > 1
                        ? requiredUserQuestions?.get(question.questionId)?.error
                        : ''
                    }
                    state={
                      requiredUserQuestions &&
                      requiredUserQuestions?.size > 0 &&
                      requiredUserQuestions?.get(question.questionId)?.error
                        ?.length > 1
                        ? 'error'
                        : 'default'
                    }
                    multiple
                    onChange={(e) => {
                      if (!(question as any)?.optional) {
                        const getId = requiredUserQuestions?.get(
                          question.questionId,
                        );
                        getId.error = '';
                        getId.response = e?.target?.value;
                        requiredUserQuestions?.set(question.questionId, getId);
                      }
                      const parseQ = (
                        questions: {
                          question: string;
                          questionId: string;
                          response?: null | string;
                          target: {type: string; ids: string[]};
                        }[],
                        index: number,
                        response?: null | string,
                      ) => {
                        let newQ = questions;
                        newQ[index].response = response;
                        return newQ;
                      };
                      handleFormValueChange(
                        {
                          ...value,
                          questions: parseQ(
                            value.questions,
                            index,
                            e?.target?.value,
                          ),
                        },
                        'questions',
                      );

                      debounceUpdateQuestions({
                        questions: {
                          ...value,
                          questions: parseQ(
                            value.questions,
                            index,
                            e?.target?.value,
                          ),
                        },
                        questionId: value.id,
                      });
                    }}
                  />
                </div>
              ))}
            </div>
          </WorkLifeDiv>
        )}
      </>
    );
  },
);
