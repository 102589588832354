import styled from 'styled-components';
import {useMemo} from 'react';
import {cfv} from '@utils/framework';
import {FlexRowSpaceBetween, FlexRow} from '@ui/style/styles';

import {Body1, Microcopy, Body2} from '@ui/atoms/typography';
import {capitalize} from '@utils';

const InnerWrapper = styled.div`
  padding: 24px;
  background: #ffffff;
  border: 1px solid #ededf2;
  min-width: 49%;
  width: 100%;
  width: 408px;
  border-radius: 10px;
  @media (max-width: 500px) {
    margin-bottom: 10px;
  }
`;
const StateWrapper = ({
  title,
  value,
  percentageValue,
  showBorder,
}: {
  title: string;
  value: number;
  percentageValue: number;
  showBorder?: boolean;
  color: string;
}) => {
  return (
    <FlexRowSpaceBetween
      style={{
        borderBottom: showBorder ? '1px solid #ededf2' : '',
        padding: '16px 0',
      }}>
      <Body2 style={{fontSize: '11pt'}}>{title}</Body2>
      <FlexRow>
        <Body2 weight="semibold" style={{marginTop: '2px', fontSize: '12pt'}}>
          {value}
        </Body2>
        <Microcopy kind="textBody" weight="default" style={{fontSize: '11pt'}}>
          ({percentageValue}%)
        </Microcopy>
      </FlexRow>
    </FlexRowSpaceBetween>
  );
};
interface ICount {
  count: number;
  percent: number;
}
const ObjectivePerformance = ({
  lessThan50,
  lessThan75,
  lessThan100,
  HundredPercent,
}: {
  lessThan50: ICount;
  lessThan75: ICount;
  lessThan100: ICount;
  HundredPercent: ICount;
}) => {
  const totalValue = useMemo(
    () =>
      lessThan50.count +
      lessThan75.count +
      lessThan100.count +
      HundredPercent.count,
    [
      lessThan50.count,
      lessThan75.count,
      lessThan100.count,
      HundredPercent.count,
    ],
  );

  return (
    <InnerWrapper>
      <Body1 style={{fontWeight: 700, fontSize: '13pt'}}>
        <span style={{margin: '0 4px'}}> {capitalize(cfv().g_oal)} </span>{' '}
        overview ( <span style={{marginLeft: '0 1px'}}> {totalValue}</span>)
      </Body1>

      <div style={{width: '100%'}}>
        <StateWrapper
          title={'< 50% complete'}
          value={lessThan50.count}
          percentageValue={lessThan50.percent}
          showBorder
          color="#DCDCFF"
        />
        <StateWrapper
          title={'50% to 74% complete'}
          value={lessThan75.count}
          percentageValue={lessThan75.percent}
          showBorder
          color="#585ADF"
        />
        <StateWrapper
          title={'75% to 99% complete'}
          value={lessThan100.count}
          showBorder
          percentageValue={lessThan100.percent}
          color="#3436A2"
        />
        <StateWrapper
          title={'100% complete'}
          value={HundredPercent.count}
          percentageValue={HundredPercent.percent}
          color="#28284D"
        />
      </div>
    </InnerWrapper>
  );
};

export default ObjectivePerformance;
