import {PlusSVG} from '@ui/atoms/svg';
import {Body2} from '@ui/atoms/typography';
import {DropdownCustom} from '@ui/molecules/dropdown-custom';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {FlexRow} from '@ui/style/styles';
import React, {useMemo} from 'react';

interface AddFilterProps {
  disableAdditionalFilter?: boolean;
  filters: string[];
  updateFilters: (value: string) => void;
}
export const AddFilter = ({filters, updateFilters}: AddFilterProps) => {
  const computeAdditionalFilters = useMemo(() => {
    return [
      {
        value: 'list',
        label: 'List',
      },
      {
        value: 'people',
        label: 'People',
      },
    ];
  }, []);

  const getDropdownFilters = computeAdditionalFilters?.filter(
    (filter) => !filters.includes(filter.value),
  );

  const filterIsDisabled = !getDropdownFilters?.length;

  return (
    <div>
      <div className="p-4 ">
        <DropdownCustom
          collapseOnClick={true}
          menu={(handleClose: () => void) => (
            <div>
              <div>
                {getDropdownFilters?.map((filter) => (
                  <DropdownItem
                    key={filter.value}
                    onClick={() => {
                      handleClose();

                      updateFilters(filter.value);
                    }}>
                    {filter.label}
                  </DropdownItem>
                ))}
              </div>
            </div>
          )}
          customComponent={(
            handleOpen: (event: any) => void,
            open: boolean,
          ) => (
            <FlexRow
              className={
                filterIsDisabled ? 'cursor-not-allowed' : 'cursor-pointer'
              }
              onClick={(event) =>
                !filterIsDisabled && (handleOpen(event) as any)
              }>
              <PlusSVG
                fill={filterIsDisabled ? '#BFBFD4' : undefined}
                style={{width: '18px', height: '18px'}}
              />
              <Body2
                weight="bold"
                kind={filterIsDisabled ? 'textMuted' : 'textDark'}
                className="ml-2">
                Add filter
              </Body2>
            </FlexRow>
          )}
          customDropdownWrapperStyles={{
            top: '130%',
            left: 0,
            width: '190px',
          }}
        />
      </div>
    </div>
  );
};
