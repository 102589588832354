import {observer} from 'mobx-react';
import {lazy} from 'react';

const PasswordConfirmationPageComp = lazy(() =>
  import('./password-confirmation-page').then((module) => ({
    default: module.PasswordConfirmationPage,
  })),
);

export const installPasswordConfirmationPage = () => {
  const Page = observer(() => <PasswordConfirmationPageComp />);
  return {
    Page,
  };
};
