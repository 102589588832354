import styled, {CSSProperties} from 'styled-components';
import {useDropDown} from '../../../hooks';
import {memo, ReactNode} from 'react';
import {HorizontalDots} from '@ui/atoms/icons/horizontal-dots';
import {getColorFromTheme} from '../../ui-utils';

const Wrapper = styled.div`
  position: relative;
`;

const SecondaryWrapper = styled.div`
  @media (max-width: 500px) {
    position: relative;
  }
`;

const DropdownIcon = styled.button`
  border: none;
  background: none;
  outline: none;
  svg {
    path {
      &:disabled {
        fill: #bfbfd4;
      }
    }
  }
`;

const DropdownWrapper = styled.div`
  display: block;
  position: absolute;
  min-width: 200px;
  max-width: 306px;
  width: auto;
  // top: 100%;
  border-radius: 8px;
  // right: 100%;
  border: solid 1px ${getColorFromTheme('borderDark')};
  background-color: ${getColorFromTheme('white')};
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: ${({theme}) => theme.elevation.level2};
  z-index: 2;
  padding: 16px 8px;

  @media (max-width: 500px) {
    min-width: 100px;
    right: -27%;
  }
`;

export interface DropdownVerticalProps {
  menu: any; //(handleClose: () => void) => ReactNode;
  collapseOnClick?: boolean;
  disabled?: boolean;
  customIcon?: ReactNode;
  dropdownWrapperStyle?: CSSProperties;
}

export const DropdownVertical = memo(function ({
  menu,
  collapseOnClick,
  disabled,
  customIcon,
  dropdownWrapperStyle,
}: DropdownVerticalProps) {
  const {handleOpen, ref, open, handleClose} = useDropDown();

  return (
    <Wrapper ref={ref}>
      <DropdownIcon disabled={disabled} onClick={handleOpen}>
        {customIcon || <HorizontalDots />}
      </DropdownIcon>
      {open && (
        <DropdownWrapper style={dropdownWrapperStyle}>
          {collapseOnClick ? menu(handleClose) : menu}
        </DropdownWrapper>
      )}
    </Wrapper>
  );
});

const SecondaryDropdownWrapper = styled.div`
  display: block;
  position: absolute;
  min-width: 200px;
  max-width: 306px;
  width: auto;

  border-radius: 8px;
  right: 10%;
  border: solid 1px ${getColorFromTheme('borderDark')};
  background-color: ${getColorFromTheme('white')};
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: ${({theme}) => theme.elevation.level2};
  z-index: 2;
  padding: 16px 8px;

  @media (max-width: 500px) {
    min-width: 100px;
    right: -27%;
  }
`;

export const DropdownVerticalSecondary = memo(function ({
  menu,
  collapseOnClick,
  customIcon,
  dropdownWrapperStyle,
}: DropdownVerticalProps) {
  const {handleOpen, ref, open, handleClose} = useDropDown();

  return (
    <SecondaryWrapper ref={ref}>
      <DropdownIcon onClick={handleOpen}>
        {customIcon || <HorizontalDots />}
      </DropdownIcon>
      {open && (
        <SecondaryDropdownWrapper style={dropdownWrapperStyle}>
          {collapseOnClick ? menu(handleClose) : menu}
        </SecondaryDropdownWrapper>
      )}
    </SecondaryWrapper>
  );
});

DropdownVertical.displayName = 'DropdownVertical';
