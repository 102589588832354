import {AxiosResponse} from 'axios';
import {
  ForgotPasswordFormData,
  ForgotPasswordResponse,
} from './forgot-password-interface';
import {BaseRequest} from '../../configs/request';

export interface ForgotPasswordRequest extends BaseRequest {
  url: string;
  forgotPassword(data: ForgotPasswordFormData): Promise<ForgotPasswordResponse>;
}

export class ForgotPasswordRequestImpl
  extends BaseRequest
  implements ForgotPasswordRequest {
  url: string = '/auth/forgot-password';
  async forgotPassword<SetupWorkspaceResponse>(data: ForgotPasswordFormData) {
    const {data: responseData} = await this.request.post<
      ForgotPasswordFormData,
      AxiosResponse<SetupWorkspaceResponse>
    >(this.url, data);
    return responseData;
  }
}
