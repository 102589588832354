import {useStoreContext} from '../../../store/store-context';
import {up} from 'styled-breakpoints';
import TipsCard, {HelpCenter, ResourcesGuides} from './tips-card';
import Tasks from './tasks/tasks';
import {motion} from 'framer-motion';
import {BeamMastery} from './beam-mastery';
import {ItemLoader} from '@ui/organisms/item-loader';
import {VerticalSpacer} from '../../../ui/atoms/spacer';
import {MobileWrapper} from '@ui/molecules/workspace/logo-toggle/logo-toggle';
import {useFirebaseFetch} from '@hooks/query-hook';
import styled from 'styled-components';
import {BillingType} from '@hooks';
import {HomeFeedbackWidget} from './feedback/feedback';
import {GoalsWidget} from './goals/goals';
import {useWorkspaceUseCase} from '@hooks/workspace-usecase';
import {HomePulseWidget} from './pulse/pulse';
import {observer} from 'mobx-react';
import {FlexRowSpaceBetween} from '@ui/style/styles';
import {Body1, Headline2} from '@ui/atoms/typography';
import {HomeActionItemsWidget} from './action-items/actionItems';

const Wrapper = styled.div`
  width: 100%;
`;

const CardWrapper = styled(motion.div)`
  ${up('md')} {
    width: 100%;
  }
`;

export const Home = observer(() => {
  const {
    authStore: {auth},
    billingsStore: {billingSubscriptions},
  } = useStoreContext();

  const isFreePlan = auth.user.workspace.billing.plan === BillingType.FREE;

  const trailHasEnded =
    auth.user.workspace.billing.trialCompleted &&
    isFreePlan &&
    !billingSubscriptions?.data?.length;

  const subscriptionHasEnded =
    isFreePlan && !!billingSubscriptions?.data?.length;

  const workspaceIsAccessible = !trailHasEnded && !subscriptionHasEnded;

  const {data: mastery, isLoading} = useFirebaseFetch(
    `users/${auth.user.id}/onboarding/mastery`,
  );

  const canDismiss = mastery?.isDismissed && mastery?.tier === 'mastery';

  const isManagerOrAdmin = auth.user.role === 'admin' || auth.user.isReviewer;

  const {
    isEngagementActive,
    isPerformanceActive,
    isCheckinsEnabled,
    isActionItemEnabled,
  } = useWorkspaceUseCase();

  const isFeedbackEnabled =
    auth.user.workspace.settings.feedback === 'enabled' &&
    isEngagementActive &&
    isManagerOrAdmin;

  const isGoalEnabled = isPerformanceActive;

  return (
    <>
      <FlexRowSpaceBetween className="mb-6">
        <div className="">
          <Headline2>Home</Headline2>
          <div className="w-[80%] mt-1">
            <Body1 kind="textBody">
              Quick snapshot of team analytics, resources for skill improvement
              and access to support.
            </Body1>
          </div>
        </div>
      </FlexRowSpaceBetween>

      <Wrapper>
        <div className="grid lg:grid-cols-[69%,29%] grid-cols-1  gap-6">
          <CardWrapper
            transition={{
              duration: 5,
              delay: 0.3,
              ease: [0.5, 0.71, 1, 1.5],
            }}>
            {isLoading && (
              <div className="w-full h-full border border-borderLight rounded-[10px] flex items-center justify-center">
                <ItemLoader />
              </div>
            )}

            {!isLoading && (
              <>
                {workspaceIsAccessible ? (
                  <>
                    {isCheckinsEnabled && (
                      <>
                        <HomePulseWidget />
                      </>
                    )}

                    {isGoalEnabled && (
                      <>
                        <GoalsWidget />
                        <VerticalSpacer size="24px" />
                      </>
                    )}

                    {isActionItemEnabled && (
                      <>
                        <HomeActionItemsWidget />
                        <VerticalSpacer size="24px" />
                      </>
                    )}

                    {isFeedbackEnabled && <HomeFeedbackWidget />}
                  </>
                ) : (
                  <>
                    <Tasks
                      masteryExist={false}
                      hasTrailEnded={trailHasEnded}
                      hasSubscriptionEnded={subscriptionHasEnded}
                    />
                  </>
                )}
              </>
            )}
          </CardWrapper>

          <div className="col-span-1 bg-[#F6F6F8] border border-[#E1E1EA] rounded-lg">
            <MobileWrapper>
              <VerticalSpacer size="10px" />
            </MobileWrapper>

            {workspaceIsAccessible && (
              <>
                {!canDismiss && (
                  <div>
                    <BeamMastery mastery={mastery} />
                    <VerticalSpacer size="24px" />
                  </div>
                )}
              </>
            )}

            <div>
              {' '}
              <ResourcesGuides />
              <VerticalSpacer size="24px" />
            </div>

            <div>
              <TipsCard />
              <VerticalSpacer size="24px" />
            </div>
            <div>
              <HelpCenter />
            </div>

            <div>
              <MobileWrapper>
                <VerticalSpacer size="24px" />
              </MobileWrapper>
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
});
