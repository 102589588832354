import styled from 'styled-components';
import {down} from 'styled-breakpoints';
import {getColorFromTheme} from '../../ui-utils';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  z-index: 0;
`;

export const NavWrapper = styled.nav<{enableScroll?: boolean}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px ${getColorFromTheme('borderLight')};
  position: relative;
  z-index: 0;
  overflow-x: ${({enableScroll}) => (enableScroll ? 'auto' : undefined)};
  &::-webkit-scrollbar {
    display: none;
  }

  ${down('md')} {
    position: relative;
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;
