const isFloat = (value: string): boolean => {
  const inFloat = parseFloat(value);
  return inFloat % 1 !== 0;
};

export const toNumber = (value: string): number => {
  // check if its floating value
  if (isFloat(value)) {
    return parseFloat(value);
  }

  return parseInt(value);
};
