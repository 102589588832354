import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import {
  getNumberOfWeeksInYear,
  getWeekOfMonthFromWeekOfYear,
  getWeekOfYear,
  getWeekRange,
} from '../utils/date';

dayjs.extend(isBetween);
dayjs.extend(weekOfYear);

//Current Year
export const INITIAL_YEAR = Number(dayjs().format('YYYY'));
// Current Month
export const INITIAL_MONTH = Number(dayjs().format('M'));

export const useCheckinsCalendar = (
  year: number = INITIAL_YEAR,
  month: number = INITIAL_MONTH,
  thedate: Date = new Date(),
) => {
  /// Refurbished Checkins Calendar

  // Today
  const TODAY = dayjs().format('YYYY-MM-DD');

  const createWeeksForCurrentMonth = (year: number, month: number) => {
    const numberOfWeeksInYear = getNumberOfWeeksInYear(year);

    return [...Array(numberOfWeeksInYear)].map((week: any, index: number) => {
      // const numberOfDaysInMonth = getNumberOfDaysInMonth(year, month);

      const currentWeekNumber = index + 1;
      const dateStart = getWeekRange(currentWeekNumber, year).starts;
      // const dateStartDay = new Date(dateStart).getDate();

      const startMonth = dayjs(dateStart).add(1, 'day').format('MMM');

      const dateStartString = `${startMonth} ${dayjs(dateStart)
        .add(1, 'day')
        .format('D')}`;

      const dateEnd = getWeekRange(currentWeekNumber, year).ends;

      // const dateEndDay = new Date(dateEnd).getDate();

      const endMonth = dayjs(dateEnd).subtract(1, 'day').format('MMM');

      const dateEndString = `${endMonth} ${dayjs(dateEnd)
        .subtract(1, 'day')
        .format('D')}`;

      return {
        weekOfMonth: getWeekOfMonthFromWeekOfYear(index + 1, year),
        month: new Date(dayjs(dateStart).format('YYYY-MM-DD')).getMonth(),
        monthEnd: new Date(dayjs(dateEnd).format('YYYY-MM-DD')).getMonth(),
        weekOfYear: index + 1,
        dateStart: dayjs(dateStart).format('MM/DD/YYYY'),
        dateEnd: dayjs(dateEnd).subtract(1, 'day').format('MM/DD/YYYY'),
        currentMonth: true,
        isCurrentWeek: dayjs(TODAY).isBetween(dateStart, dateEnd, null, '[]'),
        currentWeek: getWeekOfMonthFromWeekOfYear(index + 1, year),
        currentWeekOfYear: index + 1,
        today: dayjs(TODAY),
        title: `${dateStartString} - ${dateEndString}`,
        year,
      };
    });
  };

  return {
    currentMonthWeeks: createWeeksForCurrentMonth(year, month),
    calendarTitle: `${dayjs(`${year}-${month + 1}-01`).format('MMM')}. ${dayjs(
      `${year}-${month + 1}-01`,
    ).format('YYYY')}`,
    thisCurrentPresentWeekDateInterval: {
      start: getWeekRange(getWeekOfYear(thedate), year)?.starts,
      end: getWeekRange(getWeekOfYear(thedate), year)?.ends,
    },
    year,
    month,
    thisCurrentPresentYear: Number(dayjs(TODAY).format('YYYY')),
    thisCurrentPresentMonth: Number(dayjs(TODAY).format('M')),
    weekOfYear: getWeekOfYear(thedate),
    thisCurrentPresentWeekOfYear: getWeekOfYear(new Date()),
  };
};

export const checkinsCalender = (
  year: number = INITIAL_YEAR,
  month: number = INITIAL_MONTH,
  thedate: Date = new Date(),
) => {
  /// Refurbished Checkins Calendar

  // Today
  const TODAY = dayjs().format('YYYY-MM-DD');

  const createWeeksForCurrentMonth = (year: number, month: number) => {
    const numberOfWeeksInYear = getNumberOfWeeksInYear(year);

    return [...Array(numberOfWeeksInYear)].map((week: any, index: number) => {
      // const numberOfDaysInMonth = getNumberOfDaysInMonth(year, month);

      const currentWeekNumber = index + 1;

      const dateStart = getWeekRange(currentWeekNumber, year).starts;
      // const dateStartDay = new Date(dateStart).getDate();
      const startMonth = dayjs(dateStart).format('MMM');
      const dateStartString = `${startMonth} ${dayjs(dateStart).format('D')}`;

      const dateEnd = getWeekRange(currentWeekNumber, year).ends;

      // const dateEndDay = new Date(dateEnd).getDate();
      const endMonth = dayjs(dateEnd).format('MMM');
      const dateEndString = `${endMonth} ${dayjs(dateEnd).format('D')}`;

      return {
        weekOfMonth: getWeekOfMonthFromWeekOfYear(index + 1, year),
        month: new Date(dayjs(dateStart).format('YYYY-MM-DD')).getMonth(),
        monthEnd: new Date(dayjs(dateEnd).format('YYYY-MM-DD')).getMonth(),
        weekOfYear: index + 1,
        dateStart: dayjs(dateStart).format('MM/DD/YYYY'),
        dateEnd: dayjs(dateEnd).format('MM/DD/YYYY'),
        currentMonth: true,
        isCurrentWeek: dayjs(TODAY).isBetween(dateStart, dateEnd, null, '[]'),
        currentWeek: getWeekOfMonthFromWeekOfYear(index + 1, year),
        currentWeekOfYear: index + 1,
        today: dayjs(TODAY),
        title: `${dateStartString} - ${dateEndString}`,
        year,
      };
    });
  };

  return {
    currentMonthWeeks: createWeeksForCurrentMonth(year, month),
    calendarTitle: `${dayjs(`${year}-${month + 1}-01`).format('MMM')}. ${dayjs(
      `${year}-${month + 1}-01`,
    ).format('YYYY')}`,
    thisCurrentPresentWeekDateInterval: {
      start: getWeekRange(getWeekOfYear(thedate), year)?.starts,
      end: getWeekRange(getWeekOfYear(thedate), year)?.ends,
    },
    year,
    month,
    thisCurrentPresentYear: Number(dayjs(TODAY).format('YYYY')),
    thisCurrentPresentMonth: Number(dayjs(TODAY).format('M')),
    weekOfYear: getWeekOfYear(thedate),
    thisCurrentPresentWeekOfYear: getWeekOfYear(new Date()),
  };
};
