import {AxiosResponse} from 'axios';
import {authStore} from '../../../../../../store/stores/auth-store';
import {BaseRequest} from '../../../../../../configs/request';
import {
  groupTypeFormData,
  groupTypeResponse,
} from './admin-group-type-interface';

export interface groupTypeRequest extends BaseRequest {
  createGroupType(data: groupTypeFormData): Promise<groupTypeResponse>;
  editGroupType(
    data: groupTypeFormData,
    id?: string,
  ): Promise<groupTypeResponse>;
  fetchGroupType(): Promise<groupTypeResponse>;
  deleteGroupType(id?: string): Promise<groupTypeResponse>;
}

export class groupTypeRequestImpl
  extends BaseRequest
  implements groupTypeRequest {
  private groupTypeEndpoint: string = '/group-type';

  async createGroupType<groupTypeResponse>(data: groupTypeFormData) {
    const {data: responseData} = await this.request.post<
      groupTypeFormData,
      AxiosResponse<groupTypeResponse>
    >(this.groupTypeEndpoint, data, {
      headers: {
        Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
      },
    });
    return responseData;
  }

  async editGroupType<groupTypeResponse>(data: groupTypeFormData, id: string) {
    const {data: responseData} = await this.request.patch<
      groupTypeFormData,
      AxiosResponse<groupTypeResponse>
    >(`${this.groupTypeEndpoint}/${id}`, data, {
      headers: {
        Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
      },
    });
    return responseData;
  }

  async deleteGroupType(id: string) {
    const {data: responseData} = await this.request.delete(
      `${this.groupTypeEndpoint}/${id}`,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return responseData;
  }

  async fetchGroupType() {
    const {data: responseData} = await this.request.get<
      any,
      AxiosResponse<groupTypeResponse>
    >(this.groupTypeEndpoint, {
      headers: {
        Authorization:
          authStore.auth === null && !!localStorage.tempAccessToken
            ? `Bearer ${localStorage.tempAccessToken}`
            : `Bearer ${authStore.auth?.tokens.access.token}`,
      },
    });
    return responseData;
  }
}
