import {observer} from 'mobx-react';
import {useMyFeedbackHook} from './my-feedback-hook';
import ObjectiveFilter from '@pages/dashboard/goals/objective-filter';
import {useFetchHook} from '@hooks/query-hook';
import {useBreakpoints} from '@utils/use-breakpoints';
import {useStoreContext} from '@store/store-context';
import {FlexRowSpaceBetween} from '@ui/style/styles';
import FeedbackSummarySection from '../summary-section';
import {SortByFilter} from '@pages/dashboard/reporting/reporting-pages/admin-analytics/analytics-pages/analytics-status/status-component/sort-by-filter';
import {useCallback} from 'react';
import {FeedbackFilter} from './filter';

const MyFeedback = () => {
  const {
    controller,
    dateRange,
    setDateRange,
    filters,
    selectedUser,
    setSelectedUser,
    sortBy,
    setSortBy,
  } = useMyFeedbackHook();

  const {isXs} = useBreakpoints();

  const {
    authStore: {auth},
  } = useStoreContext();

  const fetchFeedback = useCallback(async () => {
    const response = await controller.getFeedback(filters, selectedUser);
    return response.results;
  }, [controller, filters, selectedUser]);

  const {data = [], isLoading} = useFetchHook(
    ['my-feedback', filters, selectedUser],
    () => fetchFeedback(),
  );

  const _data = data as any[];

  return (
    <div>
      <FeedbackSummarySection
        empty={{
          title: 'You have no feedback',
          subText: 'Request for feedback from any manager.',
        }}
        isFetching={isLoading}
        myFeedback
        extraHeader={
          <div className="px-6">
            <FlexRowSpaceBetween className="mt-1 py-4 border-t border-t-[#EDEDF2]  w-[80%]">
              <FeedbackFilter
                onChange={(value) => {
                  setSelectedUser(value || auth.user.id);
                }}
                value={{users: selectedUser}}
              />
              <div>
                <SortByFilter value={sortBy} onChange={setSortBy} excludeIcon />
              </div>
            </FlexRowSpaceBetween>
          </div>
        }
        filter={
          <ObjectiveFilter
            dateRange={dateRange}
            setDateRange={setDateRange}
            removeUserFilter
            _height="36px"
            _width={isXs ? '225px' : '260px'}
            showDateRange
          />
        }
        data={_data}
      />
    </div>
  );
};

export default observer(MyFeedback);
