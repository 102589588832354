import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
`;

export const NavWrapper = styled.nav`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;
