import {
  ComponentPropsWithoutRef,
  forwardRef,
  ReactChild,
  ReactElement,
  Ref,
} from 'react';
import styled, {css} from 'styled-components';
import {ifProp, switchProp} from 'styled-tools';
import {getColorFromTheme} from '../../../ui-utils';
import {Checkbox} from '../../../atoms/checkbox';
import {Helper} from '../../../atoms/helper';
import {CheckKind, InputState} from '../../../interface';
import {Body1} from '../../../atoms/typography/body1';

const FieldWrapper = styled.div<{hasLabel?: boolean; noBottomMargin?: boolean}>`
  position: relative;
  margin-bottom: ${({noBottomMargin}) => (noBottomMargin ? 0 : '16px')};
  transition: all 200ms ease-in-out;
  background-color: transparent;

  ${ifProp(
    'hasLabel',
    css`
      margin-bottom: 4px;
      padding: 8px;
      border-radius: 10px;

      &:hover,
      &:focus-within {
        background-color: ${getColorFromTheme('backgroundLight')};
      }
    `,
  )}
`;

const LabelWrapper = styled.label<{state?: InputState}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;

  ${Body1} {
    margin-left: 5px;
  }

  ${switchProp('state', {
    error: css`
      ${Body1} {
        color: ${getColorFromTheme('red400')};
      }
    `,
  })}
`;

export interface CheckFieldProps extends ComponentPropsWithoutRef<'input'> {
  id: string;
  label?: ReactChild;
  state?: InputState;
  kind?: CheckKind;
  helper?: string | ReactElement;
  noBottomMargin?: boolean;
}

export const CheckField = forwardRef(
  (
    {
      id,
      label,
      state,
      helper,
      noBottomMargin,
      kind,
      readOnly,
      ...rest
    }: Omit<CheckFieldProps, 'type'>,
    ref: Ref<HTMLInputElement>,
  ) => (
    <FieldWrapper
      hasLabel={!!label}
      noBottomMargin={noBottomMargin}
      style={{cursor: readOnly ? 'not-allowed' : undefined}}>
      <LabelWrapper htmlFor={id} state={state}>
        <Checkbox
          {...rest}
          id={id}
          ref={ref}
          state={state}
          kind={kind}
          readOnly={readOnly}
        />
        {label && <Body1 kind="black">{label}</Body1>}
      </LabelWrapper>
      <Helper aria-label="helper" state={state} children={helper} />
    </FieldWrapper>
  ),
);
