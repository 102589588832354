import {Metric, TGoalResponse} from './interface';
import {GoalsController} from '@api/goals-api/goals-controller';
import {GoalsRequestImpl} from '@api/goals-api/goals-request';

export const useRemoveAlignmentHook = () => {
  const request = new GoalsRequestImpl();
  const controller = new GoalsController(request);

  const updateGoal = async (goal: TGoalResponse) => {
    const _data = {
      ...goal,
      performance: undefined,
      goalMeasurement: undefined,
      goalAlignment: undefined,
      keyResults: goal?.keyResults?.map((keyResult: any) => {
        return {
          ...keyResult,
          id: undefined,
        };
      }),
    };

    const res = await controller.updateGoal(_data, goal.id || '', true);

    if (!res) {
      return Promise.reject('Something went wrong');
    }

    return res;
  };

  const updateAlignedGoals = (goals: TGoalResponse[]) => {
    const promises = goals.map((goal) => updateGoal(goal));

    return Promise.all(promises);
  };

  const removeAlignment = async (alignedGoals: TGoalResponse[]) => {
    return await updateAlignedGoals(alignedGoals);
  };
  return {removeAlignment};
};

export const useRemoveCascadeKeyResult = () => {
  const request = new GoalsRequestImpl();
  const controller = new GoalsController(request);

  const updateGoal = async (_kr: Metric) => {
    const _childData = {
      ..._kr,
      keyResultAlignment: undefined,
    };

    const res = await controller.updateKeyResult(_childData, _kr.id || '');

    if (!res) {
      return Promise.reject('Something went wrong');
    }

    return res;
  };

  const updateAlignedGoals = (_kr: Metric[], parentKr: Metric) => {
    const _parentData = {
      ...parentKr,
      alignmentTarget: undefined,
    };

    controller.updateKeyResult(_parentData, parentKr.id);

    const promises = _kr.map((goal) => updateGoal(goal));

    return Promise.all(promises);
  };

  const removeCascading = async (
    alignedKeyResults: Metric[],
    parentKr: Metric,
  ) => {
    return await updateAlignedGoals(alignedKeyResults, parentKr);
  };
  return {removeCascading};
};
