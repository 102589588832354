import {object, string} from 'yup';
import {regexOnlyAlphabet} from '../../../../../constants';

export const WorkspaceValidator = object().shape({
  contactEmail: string().email().required('Fill in your contact Email'),
  name: string()
    .required('Fill in your name')
    .min(2, "Please enter a Workspace name that's more than 2 characters")
    .matches(
      regexOnlyAlphabet,
      'Workspace name should not contain special characters',
    ),
  timezone: string().required('Provide a time zone'),
});

export const AvatarValidator = object().shape({
  avatar: string().required('Select your avatar'),
});
export const GoalsFrameworkValidator = object().shape({
  framework: string(),
});
