import dayjs from 'dayjs';

export const groupMeetingsByDate = (meetings: any[], value: string) => {
  const groupedMeetings: any = {};
  const today = dayjs().format('YYYY-MM-DD');

  // Initialize an object to store the meeting dates
  const datesWithMeetings: any = {};

  meetings.forEach((meeting) => {
    const startDate = dayjs(meeting.startDate);
    const formattedDate = startDate.format('YYYY-MM-DD');

    if (!groupedMeetings[formattedDate]) {
      groupedMeetings[formattedDate] = [];
    }
    groupedMeetings[formattedDate].push(meeting);

    datesWithMeetings[formattedDate] = true;
  });

  // Get the minimum and maximum dates from the meetings
  const allDates = Object.keys(datesWithMeetings).sort();

  const start =
    allDates.length > 0
      ? dayjs(value).isSame(today, 'day') && !allDates.includes(today)
        ? dayjs()
        : dayjs(allDates[0])
      : dayjs(value);

  const end =
    allDates.length > 0 ? dayjs(allDates[allDates.length - 1]) : dayjs(value);

  // Add empty entries for all dates from the start to the end
  const addEmptyEntries = (startDate: any, endDate: any) => {
    let date = startDate;

    while (date.isBefore(endDate) || date.isSame(endDate)) {
      const formattedDate = date.format('YYYY-MM-DD');

      if (!groupedMeetings[formattedDate]) {
        groupedMeetings[formattedDate] = [];
      }
      date = date.add(1, 'day');
    }
  };

  addEmptyEntries(start, end);

  // Group empty dates into ranges
  const result: any = {};

  let currentRange: any = [];

  let currentStart: any = null;

  const sortedDates = Object.keys(groupedMeetings).sort();

  sortedDates.forEach((date) => {
    if (groupedMeetings[date].length === 0) {
      if (currentStart === null) {
        currentStart = date;
      }

      if (date === today) {
        result['Today'] = groupedMeetings[date];
        return;
      }

      currentRange.push(date);
    } else {
      if (currentRange.length > 0) {
        const formattedCurrentStart = dayjs(currentStart).format('ddd. DD');

        const lastDate = dayjs(currentRange[currentRange.length - 1]).format(
          'ddd. DD',
        );

        const rangeKey =
          formattedCurrentStart === lastDate
            ? formattedCurrentStart
            : `${formattedCurrentStart} - ${lastDate}`;

        result[rangeKey] = [];

        currentRange = [];

        currentStart = null;
      }

      const key = date === today ? 'Today' : dayjs(date).format('ddd. DD');

      result[key] = groupedMeetings[date];
    }
  });

  // Handle the case where the last range is empty
  if (currentRange.length > 0) {
    const rangeKey =
      currentRange[currentRange.length - 1] === currentStart
        ? dayjs(currentStart).format('ddd. DD')
        : `${dayjs(currentStart).format('ddd. DD')} - ${dayjs(
            currentRange[currentRange.length - 1],
          ).format('ddd. DD')}`;
    result[rangeKey] = [];
  }

  return result;
};
