import {useNavigate} from 'react-router';
import {Body1, Body2} from '@ui/atoms/typography';
import {EmptyPlaceholder} from '@ui/atoms/empty-placeholder';
import {cloudinary} from '@assets/images/cloudinary';
import {CalenderIcon} from '@ui/atoms/icons/calender-icon';
import {useQuery} from 'react-query';
import {ItemLoader} from '@ui/organisms/item-loader';
import {SectionCard} from '@ui/layouts/section-card';
import {useUpcomingMeetingHook} from '../individual-meetings/individual-hook';
import {FlexRow, FlexRowCenter} from '@ui/style/styles';
import {useBreakpoints} from '@utils/use-breakpoints';
import {MeetingCard} from '@ui/organisms/meetings/meeting-card/meeting-card';
import {IMeetingResponse} from '@hooks';
import {CustomIconModal} from '@ui/organisms/modal/custom-icon-modal';
import {useState} from 'react';
import {userName} from '@utils/user-name';
import {RescheduleMeeting} from '../reschedule-meeting/reschedule-meeting';
import {Button} from '@ui/atoms/button';
import {CalendarView} from '../calendar-view';
import dayjs from 'dayjs';
import {TeamMeetingFilter} from '@ui/organisms/meetings/filter/team-meetings';
import {HorizontalSideRule} from '@ui/atoms/spacer/spacer';
import {TextField} from '@ui/molecules/field/textfield';
import {useMeetingTags} from '../useMeetingTags';
import {theme} from '@ui/style/theme';
import {RawButtonLink} from '@ui/atoms/button-link';
import {DropdownVerticalSecondary} from '@ui/molecules/dropdown-vertical';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {MoreVerticalIcon2} from '@ui/atoms/icons/more-vertical';
import {ManageTags} from '../create-meetings/tags';
import {writeFirebaseData} from '@utils/firebase-handler';

const {emptyGoals} = cloudinary;

const emptyPlaceholder = {
  image: {
    alt: 'alt text',
    shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
    src: emptyGoals,
    style: {height: '30%', width: '30%'},
  },
  subtitle: 'Click "Create" to begin setting one up.',
  title: 'No meetings Scheduled',
};

const disconnectedPlaceholder = {
  image: {
    alt: 'alt text',
    shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
    src: emptyGoals,
    style: {height: '30%', width: '30%'},
  },
  subtitle:
    'Sync your calendar to access smart scheduling, AI-generated meeting recaps, and task tracking.',
  title: 'Sync your calendar to begin',
  action: (
    <RawButtonLink
      style={{padding: '8px 16px'}}
      href="/edit/apps/gcalender-configure">
      Connect
    </RawButtonLink>
  ),
};

interface MeetingProps {
  isGoogleCalenderConnected?: boolean;
}
const TeamMeetings = ({isGoogleCalenderConnected}: MeetingProps) => {
  const navigate = useNavigate();

  const {
    dateRange,
    getMeetings,
    filterBy,
    setSortByDate,
    sortByDate,
    tasks,
    setDateRange,
    handleDateRange,
    deleteMeeting,
    selectedUsers,
    onCancel,
  } = useUpcomingMeetingHook();

  const filteredUsers = selectedUsers.length
    ? selectedUsers.join(',')
    : undefined;

  const filter = {
    startDate: dateRange.starts,
    endDate: dateRange.ends,
    user: !filterBy ? filteredUsers : '',
  };

  const [duplicateAgenda, setDuplicateAgenda] = useState<{
    agenda: string;
    name: string;
    title?: string;
    participant: string;
    label: string;
  }>();

  const [showDuplicateModal, toggleDuplicateModal] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  const [cancelReason, setCancelReason] = useState('');

  const [showCancelModal, setCancelModal] = useState('');

  const [modalLoader, setModalLoader] = useState(false);

  const [selectedMeeting, setSelectedMeeting] = useState<any>();

  const [showCalander, setShowCalendar] = useState(false);

  const {tags: _tags = []} = useMeetingTags();

  const {
    data = {
      limit: 10,
      results: [] as IMeetingResponse[],
      totalPages: 0,
      totalResults: 10,
    },
    isLoading,
    refetch,
  } = useQuery(['meetings-summary-team', filter, tasks], () =>
    getMeetings(filter),
  );
  const tags = [..._tags, {tag: 'No tag', key: ''}];

  const [manageTags, setManageTags] = useState(false);

  const {isXs} = useBreakpoints();

  return (
    <div>
      <div>
        <SectionCard
          headerPadding={isXs ? 'small' : undefined}
          contentStyling={{padding: '16px'}}
          extraHeader={
            isGoogleCalenderConnected ? (
              <div className=" flex flex-row justify-between items-center border-t border-t-[#ededf2] py-4 mt-4 mx-6">
                <div className="">
                  {tags ? (
                    <FlexRow className="gap-3 flex-wrap">
                      <Body2 weight="bold" className="mr-2">
                        Tag bookmarks:
                      </Body2>

                      {tags.map((tag) => (
                        <button
                          className=" bg-[#F6F6F8] py-1 px-2 rounded-lg flex gap-2"
                          onClick={() => {
                            navigate(`/view-grouped-meetings?tag=${tag.tag}`);
                          }}>
                          <Body2 kind="textBody">{tag.tag}</Body2>
                        </button>
                      ))}
                    </FlexRow>
                  ) : (
                    <Body2 weight="bold" kind="textMuted">
                      No items in tagged bookmarks.
                    </Body2>
                  )}
                </div>
                <DropdownVerticalSecondary
                  collapseOnClick
                  customIcon={
                    <MoreVerticalIcon2
                      width={'22'}
                      height={'22'}
                      // viewBox={'0 0 24 24'}
                      fill="#5E5E73"
                      stroke="#5E5E73"
                    />
                  }
                  menu={(handleClose: () => void) => (
                    <DropdownItem
                      onClick={() => {
                        setManageTags(true);
                        handleClose();
                      }}>
                      Manage tags
                    </DropdownItem>
                  )}
                  dropdownWrapperStyle={{
                    right: '50px',
                  }}
                />
              </div>
            ) : null
          }
          CustomHeaderTitle={
            <>
              <TeamMeetingFilter
                selectedUsers={selectedUsers}
                disabled={!isGoogleCalenderConnected}
                sortBy={sortByDate}
                onChange={(data) => {
                  const filter = data.sortBy === 'week' ? 'week' : 'today';

                  setSortByDate(filter);
                  setDateRange(handleDateRange(filter));
                }}
              />
            </>
          }
          variant="columnStart"
          action={
            <div className="flex flex-row items-center gap-3 sm:mt-2 mt-0">
              {isGoogleCalenderConnected && (
                <>
                  <Body2 kind="textBody" data-testid="summary-text">
                    {sortByDate === 'today'
                      ? dayjs(dateRange.starts).format('ddd. DD')
                      : `${dayjs(dateRange.starts).format(
                          'ddd. DD',
                        )} -  ${dayjs(dateRange.ends).format('ddd. DD')}`}
                  </Body2>

                  <HorizontalSideRule size="16px" />
                </>
              )}

              <Button
                kind="secondary"
                disabled={!isGoogleCalenderConnected}
                onClick={() => setShowCalendar(true)}
                style={{padding: '6px 16px'}}
                className="flex items-center before gap-2">
                <span className="mb-[2px]">
                  <CalenderIcon
                    stroke={
                      isGoogleCalenderConnected
                        ? 'black'
                        : theme.colors.textMuted
                    }
                  />
                </span>
                Calendar view
              </Button>
            </div>
          }>
          <div>
            {isGoogleCalenderConnected && (
              <div>
                {!isLoading &&
                  data.results.map((meeting) => (
                    <MeetingCard
                      key={meeting.id}
                      excludeAddToCalendar
                      onCancel={() => {
                        setCancelModal(meeting.id);
                      }}
                      onDelete={() => {
                        deleteMeeting(meeting.id).then((meeting) => refetch());
                      }}
                      startMeeting={() => {
                        navigate(`/view-meetings/${meeting.id}?start=true`);
                      }}
                      onReschedule={() => {
                        setSelectedMeeting(meeting);
                        setOpenDrawer(true);
                      }}
                      viewMeetingLabel={(label) => {
                        navigate(`/view-grouped-meetings?tag=${label}`);
                      }}
                      viewMeeting={() => {
                        navigate(`/view-meetings/${meeting.id}`);
                      }}
                      {...meeting}
                      participant={meeting.participant}
                      extraParticipant={meeting.extraParticipant}
                      onDuplicate={() => {
                        toggleDuplicateModal(true);
                        setDuplicateAgenda({
                          agenda: JSON.stringify(meeting.hostAgenda),
                          name: userName(meeting.participant),
                          title: meeting.title,
                          participant: JSON.stringify(
                            meeting.participant.map(
                              (participant: {id: string}) => participant.id,
                            ),
                          ),
                          label: JSON.stringify(meeting.label),
                        });
                      }}
                    />
                  ))}

                <CustomIconModal
                  open={showDuplicateModal}
                  onClose={() => toggleDuplicateModal(false)}
                  body={
                    <Body1 kind="textBody" align="center">
                      Click{' '}
                      <span style={{fontWeight: 700, color: 'black'}}>
                        “confirm”
                      </span>{' '}
                      to continue.
                    </Body1>
                  }
                  onClick={() => {
                    navigate(`/create-1:1?action=duplicate&type=teams`, {
                      state: {
                        ...duplicateAgenda,
                      },
                    });
                  }}
                  title={'Duplicate Meeting'}
                  description={
                    <Body1 kind="textBody" align="center">
                      Are you sure you want to duplicate{' '}
                      <span style={{color: '#585ADF', fontWeight: 600}}>
                        {duplicateAgenda?.title ||
                          ` 1:1 with ${duplicateAgenda?.name}`}
                      </span>
                      ?
                    </Body1>
                  }
                />

                {openDrawer && (
                  <RescheduleMeeting
                    open={openDrawer}
                    onUpdated={refetch}
                    meeting={selectedMeeting}
                    onClose={() => setOpenDrawer(false)}
                  />
                )}

                <CustomIconModal
                  open={!!showCancelModal}
                  onClose={() => setCancelModal('')}
                  disabled={!cancelReason}
                  loading={modalLoader}
                  body={
                    <div className="mt-4">
                      <TextField
                        multiple
                        inputStyle={{minHeight: '20px'}}
                        placeholder="Add reason"
                        value={cancelReason}
                        onChange={(event) =>
                          setCancelReason(event.target.value)
                        }
                      />
                    </div>
                  }
                  onClick={() => {
                    setModalLoader(true);
                    onCancel(showCancelModal, cancelReason).then(
                      async (meeting) => {
                        await refetch();

                        setModalLoader(false);
                        setCancelModal('');
                      },
                    );
                  }}
                  title={'Cancel Meeting'}
                  description={
                    <Body1 kind="textBody" align="center">
                      Provide reason, click{' '}
                      <span style={{color: 'black', fontWeight: 600}}>
                        “confirm”
                      </span>{' '}
                      to continue. The attendee will be notified.
                    </Body1>
                  }
                />

                {
                  <CalendarView
                    open={showCalander}
                    type="team"
                    onClose={() => {
                      setShowCalendar(false);
                    }}
                  />
                }

                {manageTags && (
                  <ManageTags
                    open={manageTags}
                    onClose={() => setManageTags(false)}
                    tags={tags}
                    onSubmit={(tag) => {
                      writeFirebaseData(`tags`, tag);
                    }}
                  />
                )}

                {!data.results.length && !isLoading ? (
                  <EmptyPlaceholder {...emptyPlaceholder} />
                ) : null}

                {isLoading && (
                  <FlexRowCenter>
                    <ItemLoader />
                  </FlexRowCenter>
                )}
              </div>
            )}

            {!isGoogleCalenderConnected && (
              <EmptyPlaceholder {...disconnectedPlaceholder} />
            )}
          </div>
        </SectionCard>
      </div>
    </div>
  );
};

export default TeamMeetings;
