export const ChatIcon = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="24" fill="#585ADF" />
      <path
        d="M31.95 33C29.8 33 27.7043 32.5207 25.663 31.562C23.621 30.604 21.8127 29.3373 20.238 27.762C18.6627 26.1873 17.396 24.379 16.438 22.337C15.4793 20.2957 15 18.2 15 16.05C15 15.75 15.1 15.5 15.3 15.3C15.5 15.1 15.75 15 16.05 15H20.1C20.3333 15 20.5417 15.075 20.725 15.225C20.9083 15.375 21.0167 15.5667 21.05 15.8L21.7 19.3C21.7333 19.5333 21.7293 19.7457 21.688 19.937C21.646 20.129 21.55 20.3 21.4 20.45L19 22.9C19.7 24.1 20.575 25.225 21.625 26.275C22.675 27.325 23.8333 28.2333 25.1 29L27.45 26.65C27.6 26.5 27.796 26.3873 28.038 26.312C28.2793 26.2373 28.5167 26.2167 28.75 26.25L32.2 26.95C32.4333 27 32.625 27.1123 32.775 27.287C32.925 27.4623 33 27.6667 33 27.9V31.95C33 32.25 32.9 32.5 32.7 32.7C32.5 32.9 32.25 33 31.95 33Z"
        fill="white"
      />
    </svg>
  );
};
