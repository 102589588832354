import {useMemo, useState} from 'react';
import {useCallback} from 'react';
import {MeetingRequestImpl} from '../meeting.request';
import {IMeetingResponse, IMeetingSummary} from '@hooks';
import {MeetingController} from '../meeting-controller';
import {activateNotification} from '@ui/molecules/notification/activate-notification';
import {useFirebaseFetch} from '@hooks/query-hook';
import {useGCalenderHook} from '@hooks/gcalender';
import {useActionItems} from '@hooks/features-action-items';
import dayjs from 'dayjs';
import {useStoreContext} from '@store/store-context';

export const useUpcomingMeetingHook = () => {
  const firebaseGooglePath = `meeting`;

  const {data} = useFirebaseFetch(firebaseGooglePath);

  const {
    deleteEvent,
    isSigned,
    updateEvent,
    getEvent,
    getCalendarEventById,
  } = useGCalenderHook();

  const [sortByDate, setSortByDate] = useState<'today' | 'week'>('week');

  const {
    usersStore: {users},
  } = useStoreContext();

  const handleDateRange = (type: 'week' | 'today') => {
    const weekStartDate = dayjs().startOf('week');

    const weekEndDate = dayjs().endOf('week');

    if (type === 'week') {
      return {
        starts: weekStartDate.format('MM/DD/YYYY'),
        ends: weekEndDate.format('MM/DD/YYYY'),
      };
    }
    return {
      starts: dayjs().startOf('day').format('MM/DD/YYYY'),
      ends: dayjs().endOf('day').format('MM/DD/YYYY'),
    };
  };
  const getPeriod = useMemo(() => {
    return handleDateRange(sortByDate);
  }, [sortByDate]);

  const [dateRange, setDateRange] = useState(getPeriod);

  const [filterBy, setFilterBy] = useState('');

  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  const getMeetings = useCallback(
    async (filters: {
      startDate: string;
      endDate: string;
      user?: string;
      status?: string;
      limit?: number;
      participant?: string;
      label?: string;
      paginate?: boolean;
      excludeGoogle?: boolean;
      page?: number;
    }): Promise<{
      limit: number;
      results: IMeetingResponse[];
      totalPages: number;
      totalResults: number;
      page: number;
    }> => {
      // Helper function to process a single meeting result
      const processMeetingResult = async (result: any) => {
        let processedResult = {
          ...result,
          time: !result.time?.toLowerCase().includes('invalid')
            ? result.time
            : '',
        };

        if (
          result?.meta?.calendarEventId &&
          isSigned &&
          !filters.excludeGoogle
        ) {
          try {
            const calendarResponse = await getCalendarEventById(
              result.meta.calendarEventId,
              'primary',
            );
            const event = calendarResponse.result;

            if (event) {
              const allUsersEmail = users.map(
                (user: {email: string}) => user.email,
              );
              const isExistingParticipant = allUsersEmail.filter(
                (user: string) => result.participant.includes(user),
              );

              const attendees =
                event?.attendees?.filter(
                  (attendee: {email: string}) =>
                    !isExistingParticipant.includes(attendee.email),
                ) || [];

              const newParticipants = attendees
                .filter((attendee: {email: string}) =>
                  allUsersEmail.includes(attendee.email),
                )
                .map((attendee: {email: string}) =>
                  users.find(
                    (user: {email: string}) => user.email === attendee.email,
                  ),
                )
                .filter(Boolean);

              processedResult = {
                ...processedResult,
                participant: [
                  ...(processedResult.participant || []),
                  ...newParticipants,
                ],
                extraParticipant: attendees,
                meetingLink: event?.hangoutLink,
              };
            }
          } catch (error) {
            console.error('Error processing calendar event:', error);
          }
        }

        return processedResult;
      };

      try {
        const request = new MeetingRequestImpl();
        const controller = new MeetingController(request);
        const response = await controller.getMeetings(filters);

        if (!response) {
          return {
            limit: 10,
            results: [],
            totalPages: 0,
            totalResults: 0,
            page: 1,
          };
        }

        // Handle array response
        if (Array.isArray(response)) {
          const processedResults = filters.excludeGoogle
            ? response
            : await Promise.all(response.map(processMeetingResult));

          return {
            limit: filters.limit || 10,
            results: processedResults,
            totalPages: Math.ceil(
              processedResults.length / (filters.limit || 10),
            ),
            totalResults: processedResults.length,
            page: filters.page || 1,
          };
        }

        // Handle paginated response
        const processedResults = filters.excludeGoogle
          ? response.results
          : await Promise.all(
              response.results?.map(processMeetingResult) || [],
            );

        return {
          ...response,
          results: processedResults,
        };
      } catch (error) {
        console.error('Error in getMeetings:', error);
        return {
          limit: 10,
          results: [],
          totalPages: 0,
          totalResults: 0,
          page: 1,
        };
      }
    },
    [],
  );

  const {tasks} = useActionItems({
    userId: '',
    itemId: '',
    source: 'meeting',
    orderBy: {
      key: 'source',
      value: 'meeting',
    },
  });

  const getMeetingsSummary = useCallback(
    async (filters: {
      startDate: string;
      endDate: string;
      user?: string;
      status?: string;
      participant?: string;
    }): Promise<IMeetingSummary[]> => {
      const request = new MeetingRequestImpl();
      const controller = new MeetingController(request);

      const response = await controller.getMeetingsSummary(filters);

      if (response) {
        return response.map((user: {id: any}) => {
          const actionItems = tasks.filter((task) => task.assignee === user.id);

          return {
            ...user,
            actionItems,
          };
        });
      }
      return [];
    },
    [tasks],
  );

  const deleteEventFromCalender = useCallback(
    (meetingId: string) => {
      if (data && isSigned) {
        const EventId = data[meetingId]?.eventId || '';
        deleteEvent(EventId).then(
          function (resp: any) {},
          function (reason: any) {},
        );
      }
    },
    [data, deleteEvent, isSigned],
  );

  const updateEventFromCalender = useCallback(
    (meetingId: string, reason: string) => {
      if (data && isSigned) {
        const EventId = data[meetingId]?.eventId || '';

        getEvent(EventId).then(
          function (response: any) {
            const resp = response.result;

            updateEvent(
              {
                description: `Cancellation reason: ${reason}`,
                summary: `Canceled: ${resp.summary}`,
                attendees: [
                  ...resp.attendees.map(
                    (attendee: {responseStatus: string}) => ({
                      ...attendee,
                      responseStatus: 'declined',
                    }),
                  ),
                  {
                    email: resp.organizer.email,
                    self: true,
                    organizer: true,
                    responseStatus: 'declined',
                  },
                ],
              },
              EventId,
              'primary',
              'none',
            ).then(
              function (resp: any) {},
              function (reason: any) {},
            );
          },
          function (reason: any) {},
        );
      }
    },
    [data, updateEvent, isSigned, getEvent],
  );

  const deleteMeeting = useCallback(
    async (id: string): Promise<any> => {
      const request = new MeetingRequestImpl();
      const controller = new MeetingController(request);

      const response = await controller.deleteMeeting(id);

      deleteEventFromCalender(id);

      activateNotification({
        title: 'Sucesss',
        content: 'Meeting deleted successfully',
        kind: 'success',
      });

      return response;
    },
    [deleteEventFromCalender],
  );

  const onCancel = useCallback(
    async (id: string, reason: string): Promise<any> => {
      const request = new MeetingRequestImpl();
      const controller = new MeetingController(request);

      const response = await controller.patchMeeting(
        {
          status: 'cancelled',
          reasonForCancellation: reason,
        },
        id,
      );
      updateEventFromCalender(id, reason);

      activateNotification({
        title: 'Sucesss',
        content: 'Meeting cancelled successfully',
        kind: 'success',
      });

      return response;
    },
    [updateEventFromCalender],
  );

  return {
    dateRange,
    getMeetings,
    setDateRange,
    deleteMeeting,
    onCancel,
    setFilterBy,
    filterBy,
    getMeetingsSummary,
    setSortByDate,
    sortByDate,
    handleDateRange,
    selectedUsers,
    setSelectedUsers,
    tasks,
  };
};
