import {ifProp} from 'styled-tools';
import {down} from 'styled-breakpoints';
import styled, {css} from 'styled-components';

type justifyContentOptions =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'space-evenly'
  | 'initial'
  | 'inherit';

type alignItemsOptions =
  | 'stretch'
  | 'center'
  | 'flex-start'
  | 'flex-end'
  | 'baseline'
  | 'initial'
  | 'inherit';

export const Flex = styled.div<{
  flexDir?: 'row' | 'column';
  justifyContent?: justifyContentOptions;
  alignItems?: alignItemsOptions;
  flexWrap?: string;
  cursor?: string;
  sm?: boolean;
}>`
  display: flex;
  flex-direction: ${({flexDir}) => (flexDir ? flexDir : 'row')};
  justify-content: ${({justifyContent}) =>
    justifyContent ? justifyContent : ''};
  align-items: ${({alignItems}) => (alignItems ? alignItems : '')};
  flex-wrap: ${({flexWrap}) => (flexWrap ? flexWrap : 'no-wrap')};
  cursor: ${({cursor}) => cursor && cursor};
  ${ifProp(
    'sm',
    css`
      ${down('sm')} {
        flex-direction: column;
        align-items: flex-start;
      }
    `,
  )}
`;
export const Grid = styled.div<{
  gap?: string;
  gridTemplateColumns?: string;
  gridTemplateRows?: string;
  sm?: boolean;
}>`
  display: grid;
  grid-template-columns: ${({gridTemplateColumns}) =>
    gridTemplateColumns ? gridTemplateColumns : ''};
  grid-template-rows: ${({gridTemplateRows}) =>
    gridTemplateRows ? gridTemplateRows : ''};
  grid-gap: ${({gap}) => (gap ? gap : '')};

  ${ifProp(
    'sm',
    css`
      ${down('md')} {
        display: flex;

        flex-direction: column;
      }
    `,
  )}
`;
export const FlexRow = styled.div<{space?: string}>`
  display: flex;
  flex-direction: row;
  align-items: center;

  row-gap: 6px;
`;

export const FlexRowSpaceBetween = styled(FlexRow)`
  justify-content: space-between;
  width: 100%;
`;

export const FlexRowCenter = styled(FlexRow)`
  justify-content: center;
`;
export const FlexRowEnd = styled(FlexRow)`
  justify-content: flex-end;
`;

export const ResponsiveFlexBetween = styled(FlexRowSpaceBetween)`
  @media (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
    .end {
      width: 100%;
      margin-top: 16px;
    }
  }
`;

export const ResponsiveFlexRow = styled(FlexRow)`
  @media (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
    .end {
      width: 100%;
      margin-top: 16px;
    }
  }
`;
