import {cloudinary} from '@assets/images/cloudinary';
import {MemberRole} from '@hooks';
import {useWorkspaceUseCase} from '@hooks/workspace-usecase';
import {TaskList} from '@pages/dashboard/get-started/tasks/task';
import {useStoreContext} from '@store/store-context';
import {authStore} from '@store/stores/auth-store';
import {goalsState} from '@store/stores/goals-state';
import {performanceStore} from '@store/stores/performance-store';
import {storeDashboardRoutesInit} from '@store/stores/store-dashboard-route';
import {storeYouGoals} from '@store/stores/store-you-goals';
import {usersStore} from '@store/stores/users-store';
import {EmptyPlaceholder} from '@ui/atoms/empty-placeholder';
import {
  ActivityIcon,
  CalendarMore,
  CancelIcon,
  CommandIcon,
  JumpIcon,
  NestedViewIcon,
  OutboxIcon,
  RemindIcon,
} from '@ui/atoms/icons';
import {ChatVectorIcon} from '@ui/atoms/icons/chat-icon';
import {Body1} from '@ui/atoms/typography';
import {Modal} from '@ui/molecules/modal';
import {ProfileDropdown} from '@ui/molecules/profile-dropdown';
import {WorkspaceDropdown} from '@ui/molecules/workspace/dropdown';
import {FlexRow, FlexRowCenter} from '@ui/style/styles';
import {AlertsIndicator} from '@ui/templates/dashboard/dashboard';
import {capitalize} from '@utils';
import {action} from 'mobx';
import {observer} from 'mobx-react';
import {usePostHog} from 'posthog-js/react';
import {lazy, ReactNode, useMemo, useState} from 'react';
import {useNavigate} from 'react-router';
import {Dashboards} from './dashboard-routes';
import {useIsRouteActive} from '@utils/route-href';
import {checkinName} from '@utils/checkin-name';

interface INav {
  title: string;
  href: string;
  disabled?: boolean;
  children: INav[];
  icon: ReactNode | null;
  onClick: () => void;
  'data-testid'?: string;
  active: boolean;
  id: string;
}

const Header = lazy(() =>
  import('../ui/organisms/header/header').then((module) => ({
    default: module.Header,
  })),
);

const {emptyGoals} = cloudinary;
interface IHeaderProps {
  navigations: INav[];
}
const workspace_ = (auth: any) => ({
  name:
    auth && auth.user && auth.user.workspace && auth.user.workspace.name
      ? auth.user.workspace.name
      : '',
  logo:
    auth && auth.user && auth.user.workspace && auth.user.workspace.avatar
      ? auth.user.workspace.avatar.url
      : '',
  email: '',
  id:
    auth && auth.user && auth.user.workspace && auth.user.workspace.id
      ? auth.user.workspace.id
      : '',
  link: '',
});

const WorkspaceDropdownComponent = observer(
  ({onClose}: {onClose: () => void}) => {
    const {
      authStore: {auth, featureLimitStatus},
    } = useStoreContext();
    const navigate = useNavigate();

    // TODO: Initialize store for this
    const workspace = {
      name: '',
      logo: '',
      email: '',
      id: '',
      link: '',
    };

    const workspaces = [
      {
        name: '',
        logo: '',
        email: '',
        id: '',
        link: '',
      },
    ];

    return (
      <WorkspaceDropdown
        data-testid="workspace-dropdown"
        onLogout={() => action('clicked logout')}
        onLogoutAll={() => action('clicked logout')}
        onOpenModal={() => navigate('/invite-user')}
        workspaces={auth ? [workspace_(auth)] : workspaces} // TODO (Backend): auth.workspaces replaced with  array-scoped auth.workspace because the login response does not return an array of workspaces.
        onClose={onClose}
        workspace={auth ? workspace_(auth) : workspace}
        isAdmin={auth?.user?.role === 'admin'}
        isInviteDisabled={
          featureLimitStatus?.limited &&
          featureLimitStatus?.currentStatus?.seats >=
            featureLimitStatus?.limits?.seats
        }
      />
    );
  },
);

export const createHeader = () => {
  const ProfileDropdownComponent = observer(
    ({onClose}: {onClose: () => void}) => {
      const {clearAuth, auth} = useStoreContext().authStore;
      const {currentUser} = useStoreContext().usersStore;
      const {currentUserGroups} = useStoreContext().usersStore;
      const postHog = usePostHog();
      const reportee = {
        name:
          auth.user && auth.user.reviewer?.firstName
            ? `${auth.user.reviewer.firstName} ${auth.user.reviewer.lastName}`
            : auth.user.reviewer?.email
            ? `${auth.user.reviewer?.email} `
            : '----',
        avatar:
          auth.user && auth.user.reviewer
            ? `${auth.user.reviewer.avatar?.url}`
            : '',
        id: auth.user?.reviewer?.id,
        url: '',
      };
      const dashboardProfile = {
        name: auth.user.firstName
          ? `${auth.user.firstName} ${auth.user.lastName}`
          : '',
        avatar: auth.user?.avatar?.url ? `${auth.user.avatar.url}` : '',
        url: '',
        id: auth.user ? `${auth.user.id}` : '',
        role: auth.user ? `${auth.user.role}` : '',
      };

      const logout = () => {
        const {clearAuth} = authStore;
        const {clearGoalsState} = goalsState;
        const {clearPerformanceStore} = performanceStore;
        const {clearDashboardRoutes} = storeDashboardRoutesInit;
        const {clearStoreYouGoals} = storeYouGoals;
        const {clearUsers} = usersStore;

        postHog?.reset();
        clearAuth();
        clearGoalsState();
        clearPerformanceStore();
        clearDashboardRoutes();
        clearStoreYouGoals();
        clearUsers();
      };

      return (
        <ProfileDropdown
          data-testid="profile-dropdown"
          onItemClick={() => action('clicked logout')}
          isAdmin={currentUser.role === 'admin'}
          designation={
            currentUser && currentUser.jobTitle
              ? capitalize(currentUser.jobTitle)
              : ''
          }
          groups={currentUserGroups}
          user={dashboardProfile}
          reportee={reportee}
          onLogout={() => clearAuth(logout)}
        />
      );
    },
  );
  const emptyPlaceholder = {
    image: {
      alt: 'alt text',
      shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
      src: emptyGoals,
      style: {height: '50%', width: '50%'},
    },
    imports: true,
    // action: <
    subtitle: '',
    title: 'You currently have no pending alerts.',
  };

  const AlertsDropdownComponent = observer(
    ({onClose}: {onClose: () => void}) => {
      const {
        taskStore: {taskData: tasks, taskListCount},
      } = useStoreContext();

      const notifications = localStorage.getItem('dismissedNotifications');

      const parsedNotifications = notifications
        ? JSON.parse(notifications)
        : null;

      const [dismissedNotifications] = useState(
        parsedNotifications?.notifications || [],
      );

      const isTaskEmpty = dismissedNotifications.length === taskListCount;

      return (
        <Modal open fullHeight onClose={() => null}>
          <div className="top-0 right-0 max-w-[400px] min-w-[400px] bg-white absolute border-l border-l-[#ededf2]">
            <div className="border-b border-borderLight flex items-center justify-between p-6">
              <FlexRow className="gap-1">
                <Body1 weight="bold">Alerts</Body1>

                {taskListCount && !isTaskEmpty ? (
                  <AlertsIndicator>{taskListCount}</AlertsIndicator>
                ) : null}
              </FlexRow>

              <div className="cursor-pointer" onClick={onClose}>
                <CancelIcon style={{strokeWidth: 2}} />
              </div>
            </div>
            <div className="overflow-auto ">
              <div className="h-[80rem] w-full">
                {isTaskEmpty && (
                  <FlexRowCenter className="h-screen p-8">
                    <EmptyPlaceholder {...emptyPlaceholder} />
                  </FlexRowCenter>
                )}

                {!isTaskEmpty && <TaskList tasks={tasks} />}
              </div>
            </div>
          </div>
        </Modal>
      );
    },
  );

  const HeaderComponent = observer(({navigations}: IHeaderProps) => {
    const {auth} = useStoreContext().authStore;

    const {currentUser} = useStoreContext().usersStore;

    const dashboardProfile = {
      avatar: currentUser?.avatar?.url ? `${currentUser.avatar.url}` : '',
      name: currentUser.firstName
        ? `${currentUser.firstName} ${currentUser.lastName}`
        : '',
      id: currentUser ? `${currentUser.id}` : '',
      role: currentUser ? `${currentUser.role}` : '',
      url: '',
    };

    const dashboardWorkspace = {
      name:
        auth && auth.user && auth.user.workspace && auth.user.workspace.name
          ? auth.user.workspace.name
          : '',
      avatar:
        auth && auth.user && auth.user.workspace && auth.user.workspace.avatar
          ? auth.user.workspace.avatar.url
          : '',
    };

    const {
      taskStore: {taskListCount},
    } = useStoreContext();

    const dismissedNotifications = localStorage.getItem(
      'dismissedNotifications',
    );

    const parsedDismissedNotifications = dismissedNotifications
      ? JSON.parse(dismissedNotifications)
      : {notifications: []};

    const notificationIsEmpty =
      parsedDismissedNotifications?.notifications.length === taskListCount;

    return (
      <Header
        WorkspaceDropdown={WorkspaceDropdownComponent}
        profile={dashboardProfile}
        navigations={navigations}
        workspace={dashboardWorkspace}
        showAlertIcon={!!taskListCount && !notificationIsEmpty}
        AlertsDropdown={AlertsDropdownComponent}
        ProfileDropdown={ProfileDropdownComponent}
      />
    );
  });

  return {Header: HeaderComponent};
};

export const createDashboardRoutes = () => {
  const {Header} = createHeader();

  const DashboardsRoutes = observer(() => {
    const {auth} = useStoreContext().authStore;

    const {
      activateDashboardTab,
      tabs,
      activateGoalViewTab,
    } = useStoreContext().storeDashboardRoutes;

    const {updateDateValue} = useStoreContext().analyticsStore;

    const {
      isEngagementActive,
      isActionItemEnabled,
      isPerformanceActive,
      isFeedBackEnabled,
      isCheckinsEnabled,
      isOneOnOneEnabled,
    } = useWorkspaceUseCase();

    const ccEnabled = auth?.user?.workspace?.settings?.ccStatus === 'enabled';

    const trailHasEnded =
      auth.user.workspace.billing.trialCompleted &&
      auth.user.workspace.billing.activeTrial;

    const isFreePlanActive =
      auth.user.workspace.billing.plan === 'free_tier' || trailHasEnded;

    const dashboardWorkspace = {
      name:
        auth && auth.user && auth.user.workspace && auth.user.workspace.name
          ? auth.user.workspace.name
          : '',
      avatar:
        auth && auth.user && auth.user.workspace && auth.user.workspace.avatar
          ? auth.user.workspace.avatar.url
          : '',
    };

    const {isRouteActive} = useIsRouteActive();

    const navigations = useMemo(() => {
      // TODO: Create a dynamic useLink handler with react hooks and react-navigation
      const nav: INav[] = [
        {
          title: 'Home',
          href: '/home',
          children: [],
          icon: <CommandIcon />,
          disabled: false,
          onClick: () => {
            activateDashboardTab('home');
          },
          active: isRouteActive(`/home`),
          'data-testid': 'navigation-home',
          id: '1',
        },
      ];

      if (isPerformanceActive) {
        nav.splice(1, 0, {
          title: 'Goals',
          children: [],
          href: '/goals',
          disabled: isFreePlanActive,
          icon: <NestedViewIcon width={16} height={16} />,
          'data-testid': 'navigation-goals',
          onClick: () => {
            activateGoalViewTab('flat');
            updateDateValue('');
            activateDashboardTab('objective');
          },
          active: isRouteActive(`/goals`) || tabs.objective,
          id: '2',
        });
      }

      if (isCheckinsEnabled && isEngagementActive) {
        nav.push({
          title: `${capitalize(checkinName())}s`,
          href: '/checkins',
          disabled: isFreePlanActive,
          icon: <JumpIcon />,

          children: [],
          onClick: () => {
            activateDashboardTab('checkins');
            authStore.setCalendarView('month');
          },
          active:
            (isRouteActive(`/checkins`) && !isRouteActive(`/reporting`)) ||
            tabs.checkins,
          id: '3',
        });
      }

      if (isEngagementActive) {
        if (isOneOnOneEnabled) {
          nav.push({
            title: 'Meetings',
            href: '/meetings/',
            disabled: isFreePlanActive,

            icon: <CalendarMore />,
            children: [],
            onClick: () => {
              activateDashboardTab('1:1');
            },
            active:
              (isRouteActive(`/meetings`) && !isRouteActive(`/reporting`)) ||
              tabs['1:1'],
            id: '4',
          });
        }
        if (isActionItemEnabled) {
          nav.push({
            title: 'Action Items',
            href: '/action-items',
            disabled: isFreePlanActive,
            icon: <RemindIcon />,
            children: [],
            onClick: () => {
              activateDashboardTab('actionItems');
            },
            active:
              (isRouteActive(`/action-items`) &&
                !isRouteActive(`/reporting`)) ||
              tabs.actionItems,
            id: '5',
          });
        }

        if (isFeedBackEnabled) {
          nav.push({
            title: 'Feedback',
            href: '/feedback/public-wall',
            disabled: isFreePlanActive,
            icon: <ChatVectorIcon />,
            children: [],
            onClick: () => {
              activateDashboardTab('feedback');
            },
            active:
              (isRouteActive(`/feedback`) && !isRouteActive(`/reporting`)) ||
              tabs.feedback,
            id: '6',
          });
        }
      }

      if (ccEnabled && isPerformanceActive) {
        nav.push({
          title: `C&C Review`,
          href: `/culture-competence-review/${
            auth.user.isReviewer || auth.user.role === 'admin'
              ? 'review-cycles'
              : 'your-reviews'
          }`,
          disabled: isFreePlanActive,
          icon: <OutboxIcon />,
          children: [],
          onClick: () => {
            activateDashboardTab('cultureAndCompetence');
          },
          active:
            isRouteActive(`/culture-competence-review`) ||
            tabs.cultureAndCompetence,
          id: '7',
        });
      }

      if (
        (auth && auth.user.isReviewer) ||
        auth.user.role === MemberRole.ADMIN.toLowerCase()
      ) {
        nav.push({
          title: 'Reporting',
          href: '#',
          icon: <ActivityIcon />,
          disabled: isFreePlanActive,
          children: [
            {
              title: `Reports`,
              href: '/reporting/objectives',
              disabled: isFreePlanActive,
              icon: <></>,
              children: [],
              onClick: () => {
                activateGoalViewTab('flat');
                activateDashboardTab('reporting');
              },
              active: isRouteActive(`/reporting/dashboard`),
              id: '8',
            },
            {
              title: `Dashboard`,
              href: '/reporting/dashboard',
              disabled: isFreePlanActive,
              icon: <></>,
              children: [],
              onClick: () => {
                activateDashboardTab('reportingDashboard');
              },
              active: isRouteActive(`/reporting`),
              id: '9',
            },
            {
              title: `Saved insights`,
              href: `/reporting/insights`,
              disabled: isFreePlanActive,
              icon: <></>,
              children: [],
              onClick: () => {
                activateDashboardTab('reportingInsights');
              },
              active: isRouteActive(`/reporting/insights`),
              id: '10',
            },
          ],
          onClick: () => {
            activateGoalViewTab('flat');
            activateDashboardTab('reporting');
          },
          active: isRouteActive(`/reporting`) || tabs.reporting,
          id: '11',
        });
      }

      return nav;
    }, [
      isRouteActive,
      isPerformanceActive,
      isCheckinsEnabled,
      isEngagementActive,
      ccEnabled,
      auth,
      activateDashboardTab,
      isFreePlanActive,
      tabs,
      activateGoalViewTab,
      updateDateValue,
      isOneOnOneEnabled,
      isActionItemEnabled,
      isFeedBackEnabled,
    ]);

    return (
      <Dashboards
        navigations={navigations}
        WorkspaceDropdown={WorkspaceDropdownComponent}
        workspace={dashboardWorkspace}
        header={<Header navigations={navigations} />}
      />
    );
  });

  return {
    DashboardsRoutes,
  };
};
