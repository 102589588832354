import {object, string, array, boolean} from 'yup';

export class YouCheckinsValidatorSchema {
  static get youCheckins() {
    return object().shape({
      pulse: object().shape({
        mood: string().nullable(),
        feedback: string().nullable(),
      }),
      priorities: object().shape({
        currentCheckin: array().of(
          object().shape({
            text: string(),
            completed: boolean().nullable(),
          }),
        ),
        nextCheckin: array().of(
          object().shape({
            text: string(),
            completed: boolean().nullable(),
          }),
        ),
      }),
      questions: object().shape({
        questions: array().of(
          object().shape({
            _id: string(),
            question: string(),
            questionId: string(),
            response: string().nullable(),
          }),
        ),
      }),
      feedback: string().nullable(),
    });
  }
}
