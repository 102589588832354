import React from 'react';

export const OutboxIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.9375 13.1875H1.9375C1.31618 13.1875 0.8125 12.6838 0.8125 12.0625V1.9375C0.8125 1.31618 1.31618 0.8125 1.9375 0.8125H9.8125C10.4338 0.8125 10.9375 1.31618 10.9375 1.9375V5.3125M10.9375 13.1875H12.0625C12.6838 13.1875 13.1875 12.6838 13.1875 12.0625V6.4375C13.1875 5.81618 12.6838 5.3125 12.0625 5.3125H10.9375M10.9375 13.1875V5.3125M3.0625 3.625H5.3125M3.0625 7.5625H8.6875M3.0625 9.8125H6.4375"
        stroke="#5F5F8C"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
