import dayjs from 'dayjs';
import {authStore} from '../store/stores/auth-store';
import {usersStore} from '../store/stores/users-store';

const AuthCheck = () => {
  if (localStorage && localStorage.auth && !!localStorage.auth) {
    const storedAuthObject = JSON.parse(localStorage.auth);
    const accessTokenExpiry = storedAuthObject?.tokens?.access?.expires;
    if (dayjs().isAfter(dayjs(accessTokenExpiry))) {
      authStore.clearAuth(usersStore.clearUsers);
    }
  }
};

AuthCheck();
