import styled from 'styled-components';
import {FlexRowCenter, FlexRowSpaceBetween} from '@ui/style/styles';

export const ActionWrapper = styled.div`
  padding: 18px 16px;
  border: 1px solid #ededf2;
  border-radius: 10px;
`;
export const LoadingWrapper = styled(FlexRowCenter)`
  width: 100%;
  height: 250px;
`;

export const RequestButton = styled.button`
  background: #f6f6f8;
  border-radius: 8px;
  padding: 6px 12px;

  margin-left: 6px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:disabled {
    color: #bfbfd4;
    cursor: not-allowed;
  }
  .notif {
    width: 16px;
    height: 16px;
    margin-left: 4px;
    background: #d52a2a;
    border-radius: 200px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 500;
  }
`;

export const DropdownWrapper = styled.div`
  position: absolute;
  margin-top: 20px;
  right: 20px;
`;

export const RequestCardWrapper = styled(FlexRowSpaceBetween)`
  padding: 12px 16px;
  border: 1px solid #ededf2;
  margin-top: 16px;
  border-radius: 10px;
`;

export const RequestIndicator = styled.div`
  background: #d52a2a;
  color: white;
  width: 16px;
  display: flex;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  height: 16px;
  border-radius: 20px;
`;
