import {IntegrationBody, Wrapper} from '../../components/app-body';
import {Button} from '@ui/atoms/button';
import {GoogleCalenderIcon} from '@ui/atoms/icons/apps/google-calender';
import {observer} from 'mobx-react';
import {Routes, Route, useNavigate} from 'react-router';
import {FeaturesLayout} from '../../../admin-workspace/admin-workspace-styled';
import {EditGoogleCalender} from './edit-gcalender';

export const GoogleCalender = observer(() => {
  const Body = {
    image: <GoogleCalenderIcon />,
    title: 'Google Calendar',
    description:
      'Sync your Google Calendar to schedule meetings, enable BeamAI, and receive automated summaries.',
  };

  const navigate = useNavigate();

  return (
    <Routes>
      <Route path="">
        <Wrapper>
          <IntegrationBody
            {...Body}
            customButton={() => (
              <Button
                kind="secondary"
                className="min-w-[118px] transform transa"
                style={{padding: '6px 16px'}}
                onClick={() => navigate('gcalender-configure')}>
                Configure
              </Button>
            )}
          />
        </Wrapper>
      </Route>

      <Route path="gcalender-configure">
        <FeaturesLayout>
          <EditGoogleCalender />
        </FeaturesLayout>
      </Route>
    </Routes>
  );
});
