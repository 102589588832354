import {ComponentPropsWithoutRef, forwardRef, Ref, useMemo} from 'react';
import styled, {css} from 'styled-components';
import {ifProp} from 'styled-tools';
import {motion} from 'framer-motion';
import {useIsChecked} from '../../../hooks';
import {BinaryIcon} from '../icons/binary';
import {NairaIcon} from '../icons/naira';
import {PercentIcon} from '../icons/percent';
import {HashIcon} from '../icons/hash';
import {getColorFromTheme} from '../../ui-utils';
import {MeasurementType} from '../../interface';

export interface TMeasurementInputProps
  extends ComponentPropsWithoutRef<'input'> {
  id: string;
  kind: MeasurementType;
}

const Wrapper = styled.label`
  cursor: pointer;
  position: relative;
  background: transparent;
  width: 32px;
  height: 32px;
  display: flex;
  margin-right: 6px;
  justify-content: center;
  align-items: center;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  -moz-appearance: none;

  input {
    top: 0;
    left: 0;
    width: 100%;
    cursor: inherit;
    height: 100%;
    margin: 0;
    opacity: 0;
    padding: 0;
    z-index: 1;
    position: absolute;

    &:checked,
    &:focus,
    &:active {
      > svg {
        stroke: ${getColorFromTheme('purple300')};
      }
    }
  }

  &:hover {
    svg {
      stroke: ${getColorFromTheme('purple300')};
    }
  }
`;

const Content = styled.span<{checked?: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;

  svg {
    stroke: ${getColorFromTheme('textBody')};
    transition: all 300ms ease-in-out;
  }

  ${ifProp(
    'checked',
    css`
      svg {
        stroke: ${getColorFromTheme('purple300')};
      }
    `,
  )}
`;

const Icon = styled.span`
  z-index: 2;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 20px;
    height: 20px;
  }
`;

const Background = styled(motion.span)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${getColorFromTheme('white')};
  z-index: 1;
  border-radius: 7px;
  width: 100%;
  height: 100%;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px ${getColorFromTheme('borderLight')};
`;

export const MeasurementInput = forwardRef(
  ({kind, ...props}: TMeasurementInputProps, ref: Ref<HTMLInputElement>) => {
    const icon = useMemo(() => {
      switch (kind) {
        case MeasurementType.BINARY:
          return <BinaryIcon />;
        case MeasurementType.CURRENCY:
          return <NairaIcon />;
        case MeasurementType.NUMERIC:
          return <HashIcon />;
        case MeasurementType.PERCENTAGE:
          return <PercentIcon />;
        // no-default
      }
    }, [kind]);

    const checked = useIsChecked(props);

    return (
      <Wrapper htmlFor={props.id}>
        <input {...props} type="radio" name={props.name} ref={ref} />
        <Content checked={checked}>
          <Icon>{icon}</Icon>
          {checked && (
            <Background
              layoutId="goal-type-radio"
              initial={true}
              transition={{
                duration: 0.3,
                layoutX: {duration: 0.3},
                layoutY: {delay: 0, duration: 0},
              }}
            />
          )}
        </Content>
      </Wrapper>
    );
  },
);
