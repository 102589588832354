import CycleFilter from './cycle-filter/cycle-filter';
import {FlexRowSpaceBetween} from '@ui/style/styles';
import {Body2} from '@ui/atoms/typography';
import {goalDueDate} from '@utils/date';
import {getColorFromTheme} from '@ui/ui-utils';
import styled from 'styled-components';
import {ReviewCycleProps} from '@api/cc-api/cc-request';
import {memo} from 'react';
import {Tooltip} from '@ui/molecules/tooltip';

const Wrapper = styled.div`
  border-radius: 10px;
  border: 1px solid ${getColorFromTheme('borderLight')};
  .wrapper {
    padding: 16px 24px 24px;
  }
  .details {
    display: grid;
    grid-template-columns: 25% 35% 20% 15%;
    grid-gap: 16px;
    background-color: ${getColorFromTheme('white')};
    padding: 16px 24px;
    border-radius: 10px;
  }
`;
const Card = styled.div`
  border: 1px solid ${getColorFromTheme('borderDark')};
  border-radius: 8px;
  display: flex;
  align-items: center;
  background: white;
  padding: 8px 16px;
`;

interface ICycle {
  reviewTimeline: string;
  progress: number;
  dueDay: string;
  disabled?: boolean;
  dateRange?: string;
  isLoading?: boolean;
  reviewOptions: Array<ReviewCycleProps>;
}
export const CycleHeader = memo(
  ({
    reviewTimeline,
    progress,
    isLoading,
    dueDay,
    disabled,
    dateRange,
    reviewOptions,
  }: ICycle) => {
    return (
      <Wrapper className="bg-white">
        <div className="details">
          <CycleFilter
            reviewCycleOptions={reviewOptions}
            isLoading={isLoading}
            disabled={disabled}
          />
          <Card>
            <FlexRowSpaceBetween>
              <Body2 weight="bold">Review period </Body2>
              <Body2
                style={{marginLeft: '6px'}}
                weight="bold"
                kind="textMedium">
                {reviewTimeline || '-'}{' '}
              </Body2>
            </FlexRowSpaceBetween>
          </Card>
          <Card>
            <FlexRowSpaceBetween>
              <Body2 weight="bold">Completion</Body2>
              <Body2 kind="textMedium" weight="bold">
                {progress ? `${progress}%` : ' - '}
              </Body2>
            </FlexRowSpaceBetween>
          </Card>
          <Card style={{justifyContent: 'center'}}>
            <Tooltip
              BodyTextNodeType={Body2}
              bodyStyle={{fontWeight: '500'}}
              tooltipBody={dateRange || ''}
              text={dueDay ? goalDueDate(dueDay, false, false, true) : 'N/A'}
              bodyTextNodeKind="orange300"
            />
          </Card>
        </div>
      </Wrapper>
    );
  },
);
