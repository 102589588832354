import {useStoreContext} from '@store/store-context';
import {useWorkspaceUseCase} from './workspace-usecase';

export const useFeaturesHook = () => {
  const {
    authStore: {auth},
  } = useStoreContext();

  const isCheckinEnabled =
    auth?.user?.workspace?.settings?.checkInStatus === 'enabled';

  const isCcEnabled = auth?.user?.workspace?.settings?.ccStatus === 'enabled';

  const {...rest} = useWorkspaceUseCase();

  return {
    isKpiAllowed: auth.user.workspace.config.allowKpi,
    isCheckinEnabled,
    isCcEnabled,
    ...rest,
  };
};
