import {Button} from '@ui/atoms/button';
import {Body2, Body1} from '@ui/atoms/typography';
import {VerticalSpacer} from '@ui/atoms/spacer/spacer';
import {FlexRowCenter} from '@ui/style/styles';
import {goalDueDate} from '@utils/date';
import {getColorFromTheme} from '@ui/ui-utils';
import {useNavigate} from 'react-router';
import styled from 'styled-components';
import {useStoreContext} from '@store/store-context';
import {cloudinary} from '@assets/images/cloudinary';

import {Table, TableHeader, TableRow, TableWrapper} from '../../cc-styles';
import {UserListCard} from '@ui/molecules/user/list-card';
import {observer} from 'mobx-react';
import {useQuery} from 'react-query';
import {CcRequestImpl, DirectReportResponse} from '@api/cc-api/cc-request';
import {CcController} from '@api/cc-api/cc-controller';
import {ItemLoader} from '@ui/organisms/item-loader';
import {EmptyPlaceholder} from '@ui/atoms/empty-placeholder';
import {CustomColorIcon} from '@ui/atoms/color-icons';

const {emptyGoals} = cloudinary;

export const Wrapper = styled.div`
  width: 100%;
`;
const Card = styled.div`
  border: 1px solid ${getColorFromTheme('borderDark')};
  border-radius: 8px;
  height: 38px;
  background: white;
  width: 168px;
  padding: 8px 16px;
`;
function DirectReportReview({
  disabled,
  dueDay,
  cycleId,
  finalizeDueDay,
}: {
  disabled: boolean;
  dueDay: string;
  cycleId: string;
  finalizeDueDay: string;
}) {
  const {
    authStore: {auth},
  } = useStoreContext();

  const request = new CcRequestImpl();
  const controller = new CcController(request);

  const fetchDirectReports = async (): Promise<DirectReportResponse> => {
    const response = await controller.getDirectReport(cycleId);

    if (response) {
      return response;
    }

    return {
      summary: {
        totalCount: 0,
        reviewed: 0,
        pending: 0,
      },
      reports: [],
    };
  };

  const {data, isLoading} = useQuery(
    ['direct-reports', auth.user.id, cycleId],
    () => fetchDirectReports(),
  );

  const emptyPlaceholder = {
    image: {
      alt: 'alt text',
      shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
      src: emptyGoals,
      style: {height: '30%', width: '30%'},
    },
    imports: true,
    subtitle: '',
    variant: 'default' as 'default',
    title: 'No direct reports, yet.',
  };

  return (
    <div>
      <Wrapper>
        <Body1 weight="bold">Direct reports</Body1>

        <VerticalSpacer size="7px" />
        <TableWrapper>
          <Table>
            <TableHeader variant="you">
              <Body2 kind="textBody">Name</Body2>

              <Body2 kind="textBody">Progress</Body2>

              <Body2 kind="textBody">Actions</Body2>
            </TableHeader>
            {isLoading && (
              <FlexRowCenter style={{height: '200px'}}>
                <ItemLoader />
              </FlexRowCenter>
            )}
            {!isLoading && !data?.reports.length && (
              <EmptyPlaceholder {...emptyPlaceholder} />
            )}
            {data &&
              !isLoading &&
              data.reports?.map((report) => (
                <Reportee
                  key={report.email}
                  report={report}
                  disabled={disabled}
                  dueDay={dueDay}
                  finalizeDueDay={finalizeDueDay}
                  cycleId={cycleId}
                />
              ))}
          </Table>
        </TableWrapper>
      </Wrapper>
    </div>
  );
}

interface IReportee {
  report: {
    firstName: string;
    lastName: string;
    avatar: any;
    email: string;
    reviewed: boolean;
  };
  disabled: boolean;
  finalizeDueDay: string;
  dueDay: string;
  cycleId: string;
}
const Reportee = ({
  report,
  disabled,
  dueDay,
  finalizeDueDay,
  cycleId,
}: IReportee) => {
  const navigate = useNavigate();

  const {
    usersStore: {users},
  } = useStoreContext();

  const request = new CcRequestImpl();
  const controller = new CcController(request);

  const findUser = (email: string) =>
    users.find((user) => user.email === email);

  const fetchWrittenReviews = async () => {
    const response = await controller.fetchWrittenReview({
      reviewCycle: cycleId,
      user: findUser(report.email)?.id,
    });

    return response;
  };

  const {data: writtenReviews} = useQuery(
    ['written-review', cycleId, 'manager-review', report.email],
    () => fetchWrittenReviews(),
  );

  const hasUserWritten = writtenReviews?.find(
    (review: {reviewerType: string}) => review.reviewerType === 'self',
  );

  const disableButton = report.reviewed
    ? !!writtenReviews?.find((review: {finalized: boolean}) => review.finalized)
    : disabled || report.reviewed || !hasUserWritten;

  return (
    <TableRow variant="you">
      <div style={{display: 'flex', alignItems: 'center'}}>
        <UserListCard
          kind="admin"
          type="secondary"
          textStyle={{fontWeight: 600}}
          tooltip={true}
          avatarIcon={
            <CustomColorIcon
              color={hasUserWritten ? '#47B881' : '#cecede'}
              width="10px"
              height="10px"
              margin={0}
            />
          }
          avatar={report.avatar ? report.avatar?.url : ''}
          reviewer={findUser(report.email)?.reviewer}
          name={
            report.firstName && report.lastName
              ? `${report.firstName} ${report.lastName}`
              : ` ... (pending)`
          }
          userId={findUser(report.email)?.id || ''}
        />
      </div>

      <div>
        <Card>
          <FlexRowCenter>
            <Body2
              weight="semibold"
              kind={report.reviewed ? 'green400' : 'orange300'}>
              {report.reviewed
                ? 'Completed'
                : goalDueDate(dueDay, false, false, true)}
            </Body2>
          </FlexRowCenter>
        </Card>
      </div>

      <div>
        <Button
          disabled={disableButton}
          onClick={() =>
            navigate(
              `/make-review?type=manager&peer=${
                findUser(report.email)?.id
              }&id=${cycleId}${
                report.reviewed ? '&action=finalize-review' : ''
              }`,
            )
          }
          kind="secondary"
          style={{
            padding: '8px 16px',
          }}>
          {report.reviewed ? 'Finalize review' : '  Write review'}
        </Button>
      </div>
    </TableRow>
  );
};

export default observer(DirectReportReview);
