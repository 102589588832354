import {convertFromRaw, convertToRaw} from 'draft-js';
import {useMemo, useState} from 'react';
import {markdownToDraft, draftToMarkdown} from 'markdown-draft-js';
import {EditorState} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import styled from 'styled-components';

const Wrapper = styled.div<{error?: boolean}>`
  .input {
    padding: 2px 0px;
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    border-top: ${({error}) =>
      error ? ` 1px solid #d52a2a` : ` 1px solid #cecede`};
  }
  .custom-toolbar-option {
    border: none !important; // Use !important to override existing styles
  }
  .custom-editor-wrapper {
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    line-height: 24px;
  }
  .custom-editor-wrapper .public-DraftEditorPlaceholder-root {
    color: #5f5f8c;
  }
`;

interface RichTextEditorProps {
  onChange: (data: {text: string; markdown: string}) => void;
  placeholder?: string;
  value?: string;
}

export const RichTextEditor = ({
  onChange,
  placeholder,
  value,
}: RichTextEditorProps) => {
  const getDefaultValue = useMemo(() => {
    if (value) {
      const rawData = markdownToDraft(value);

      const contentState = convertFromRaw(rawData);
      const newEditorState = EditorState.createWithContent(contentState);
      return newEditorState;
    }
    return EditorState.createEmpty();
  }, [value]);

  const [editorState, setEditorState] = useState<any>(() => getDefaultValue);

  const onEditorStateChange = (editorState: EditorState) => {
    const value = convertToRaw(editorState.getCurrentContent());
    const markdownContent = draftToMarkdown(value);

    const text = value.blocks.map((block) => block.text);

    onChange({
      text: text.join(','),
      markdown: markdownContent,
    });

    setEditorState(editorState);
  };

  return (
    <Wrapper>
      <Editor
        toolbarStyle={{
          border: 0,
          backgroundColor: 'white',
          borderRadius: '10px 10px 0px 0px',
        }}
        editorState={editorState}
        wrapperStyle={{
          minHeight: '120px',
          maxHeight: '800px',
          padding: '5px 10px',
        }}
        wrapperClassName="custom-editor-wrapper"
        placeholder={placeholder}
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          options: ['inline', 'list'],
          inline: {
            inDropdown: false,
            className: 'border-0 bg-red',
            component: undefined,
            dropdownClassName: undefined,
            options: ['bold', 'italic', 'underline', 'strikethrough'],
            bold: {
              className: 'custom-toolbar-option',
            },
            italic: {
              className: 'custom-toolbar-option',
            },
            underline: {
              className: 'custom-toolbar-option',
            },
            strikethrough: {
              className: 'custom-toolbar-option',
            },
          },

          list: {
            inDropdown: false,
            options: ['unordered', 'ordered'],
            ordered: {
              className: 'custom-toolbar-option',
            },
            unordered: {
              className: 'custom-toolbar-option',
            },
          },
        }}
      />
    </Wrapper>
  );
};
