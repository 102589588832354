import {Body1, Body2} from '@ui/atoms/typography';

import {Card, Value} from './styles';
import {Image} from '@ui/atoms/image';
import {emojiList} from '@ui/assets/emojis';
import {memo} from 'react';

interface PulseSummaryProps {
  updatesSubmission?: {
    count: number;
    percent: number;
  };
  total?: number;
  avgPulse: string;
  view?: string;
  showAveragePulse?: boolean;
}

export const PulseSummary = memo(
  ({
    updatesSubmission,
    total,
    avgPulse,
    showAveragePulse,
    view,
  }: PulseSummaryProps) => {
    const moods = [
      {
        emoji: emojiList.drained,
        text: 'Drained',
      },
      {
        emoji: emojiList.sad,
        text: 'Sad',
      },
      {
        emoji: emojiList.meh,
        text: 'Meh!',
      },
      {
        emoji: emojiList.happy,
        text: 'Happy',
      },
      {
        emoji: emojiList.elated,
        text: 'Elated',
      },
    ];

    return (
      <div className={`flex ${view === 'home' ? 'flex-row' : ''} gap-3 h-full`}>
        <Card bg={'#F6F6FE'} className="h-20">
          <div className="card-wrapper">
            <Body1 weight={'semibold'} kind={'textDark'}>
              Usage
            </Body1>
            <Value bg={'#F6F6FE'}>
              <Body1 weight="semibold">{updatesSubmission?.percent}%</Body1>
            </Value>
          </div>
          <div className="py-1 pr-4 pl-3 border-t border-t-borderLight rounded-lg flex flex-row justify-between items-center">
            <Body2
              weight={'semibold'}
              kind={'textBody'}
              style={{fontSize: '12px'}}>
              Submissions
            </Body2>
            <Body2
              weight={'semibold'}
              kind={'textBody'}
              style={{fontSize: '12px'}}>
              {updatesSubmission?.count}/{total}
            </Body2>
          </div>
        </Card>

        {showAveragePulse && (
          <Card bg={'#F6F6FE'} className="h-20">
            <div className="card-wrapper h-full flex items-center justify-between">
              <div>
                <Body1 weight={'semibold'} kind={'textDark'}>
                  Average Pulse
                </Body1>
                {avgPulse ? (
                  <Body2 weight={'semibold'} kind={'green400'}>
                    {avgPulse}
                  </Body2>
                ) : (
                  <Body2 weight={'semibold'} kind={'textMuted'}>
                    -
                  </Body2>
                )}
              </div>
              <div className="flex justify-end">
                {avgPulse ? (
                  <Image
                    {...moods.find(
                      (mood) => mood.text.toLowerCase() === avgPulse,
                    )?.emoji}
                    width="40px"
                    height="40px"
                  />
                ) : (
                  <div className="w-10 h-10 bg-backgroundDark rounded-full" />
                )}
              </div>
            </div>
          </Card>
        )}
      </div>
    );
  },
);
