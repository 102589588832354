import styled from 'styled-components';
import {getColorFromTheme} from '../../../../../../../../ui/ui-utils';
import {down} from 'styled-breakpoints';

export const EditHeader = styled.div`
  display: flex;
  ${down('sm')} {
    flex-direction: column;
  }
  .hl {
    ${down('sm')} {
      order: 2;
    }
  }
  .btns {
    ${down('sm')} {
      order: 1;
      align-self: flex-end;
      margin-bottom: 24px;
    }
  }
`;

export const QuestionIconWrapper = styled.a<{tooltip?: boolean}>`
  margin-left: 8px;
  outline: none;
  svg {
    width: 14px;
    height: 14px;
    stroke: ${getColorFromTheme('purple300')};
    margin-bottom: -2px;
  }
  @media (max-width: 500px) {
    display: none;
  }
  .tooltip {
    visibility: hidden;
    width: 399px;
    background-color: #fafafa;
    font-size: 14px;
    font-weight: normal;
    color: #5f5f8c;
    text-align: center;
    padding: 16px 16px 16px 26px;
    border: 1px solid #e4e5fb;
    border-radius: 5px;
    position: absolute;
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 3;
    left: 30%;
    margin-top: -130px;
  }
  &:hover .tooltip {
    visibility: ${(props: any) => (props.tooltip === true ? 'visible' : '')};
    opacity: 1;
  }

  .tooltip::before {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 33.1%;
    margin-left: -5px;
    border-width: 11px;
    border-style: solid;
    border-color: #e4e5fb transparent transparent transparent;
  }

  .tooltip::after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 33.3%;
    margin-left: -5px;
    border-width: 10px;
    border-style: solid;
    border-color: #fafafa transparent transparent transparent;
  }
`;

export const DropdownWrapper = styled.div`
  position: absolute;
  display: block;
  width: 100%;
  top: 48%;
  left: 0;
  border-radius: 8px;
  border: solid 1px ${getColorFromTheme('borderDark')};
  background-color: ${getColorFromTheme('white')};
  box-shadow: ${({theme}) => theme.elevation.level2};
  z-index: 3;
  margin-bottom: 100px;
`;
