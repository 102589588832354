import styled from 'styled-components';
import {Button} from '../../../../../../../../ui/atoms/button';
import {getColorFromTheme} from '../../../../../../../../ui/ui-utils';

export const PageLayout = styled.section<any>`
  width: 100%;
  padding: 32px 69px 30px 32px;

  @media (max-width: 900px) {
    flex-wrap: wrap;
  }
  @media (max-width: 500px) {
    padding: 32px 0px 30px 0px;
  }
`;

export const ButtonWrapper = styled.span`
  float: right;
`;

export const RightWrapper = styled.div<any>`
  width: 100%;

  @media (max-width: 900px) {
    margin-left: 0;
  }
`;

export const UserProfileWrapper = styled.div<any>`
  padding: 0 0 20px 0;
  border-bottom: 1px solid #ededf2;
`;

export const RightChildWrapper = styled.div<any>`
  display: flex;
  padding: 0 10px;
  margin-top: 24px;

  justify-content: space-between;
  flex-wrap: wrap;
  align-items: end;
`;

export const RightChildSpan = styled.span<any>`
  width: 49%;

  @media (max-width: 678px) {
    width: 100%;
  }
`;

export const LeftWrapper = styled.div<any>`
  position: relative;
  border: 1px solid #ededf2;
  border-radius: 10px;
  width: 320px;
  height: 320px;
  padding: 32px;

  @media (max-width: 900px) {
    width: 100%;
    margin-bottom: 24px;
    padding-left: 16px;
  }
`;

export const LeftChildSpan = styled.span<any>`
  margin-left: 22px;
  font-size: 8px;

  @media (max-width: 900px) {
    text-align: center;
  }
`;

export const LeftChildInput = styled.input<any>`
  display: none;
`;

export const TagWrapper = styled.span`
  padding: 1px 8px;
  text-align: center;
  font-size: '14px';
`;

export const HeaderWrapper = styled.div`
  box-shadow: 0px 0px 0px 1px ${getColorFromTheme('borderLight')};
  border-radius: 10px;
  width: 100%;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

export const CancelButton = styled(Button)`
  padding: 0 7px 0 0;
  color: red;
  &:not(:disabled):hover {
    color: red;
  }
  &:focus {
    outline: none;
    color: red;
  }
`;

export const EmailButton = styled(Button)`
  padding: 0 3px 0 0;
`;

export const CustomButton = styled(Button)`
  white-space: normal;
  margin-left: 16px;
`;
