import {useWorkspaceUseCase} from '@hooks/workspace-usecase';
import {cfv} from '@utils/framework';
import {capitalize} from '@utils';
import {useStoreContext} from '@store/store-context';
import {useMemo} from 'react';

export const useSnapshotWidget = (
  editedOptions?: {[key: string]: any} | undefined,
) => {
  const {
    isPerformanceActive,
    isEngagementActive,
    isCheckinsEnabled,
  } = useWorkspaceUseCase();
  const {
    authStore: {auth},
  } = useStoreContext();

  const validateOption = (value: any) => {
    return value ?? true;
  };

  const getOptions = useMemo(() => {
    const options = [];
    if (isPerformanceActive) {
      options.unshift({
        title: 'Goal status',
        name: 'goal_status',
        value: validateOption(editedOptions?.goal_status),
      });
    }
    if (isEngagementActive && isCheckinsEnabled) {
      options.push(
        {
          title: 'Pulse status',
          name: 'pulse_status',
          value: validateOption(editedOptions?.pulse_status),
        },
        {
          title: 'Check-ins submission activity',
          name: 'checkins_submission_activity',
          value: validateOption(editedOptions?.checkins_submission_activity),
        },
        {
          title: 'Check-ins review activity',
          name: 'checkins_review_activity',
          value: validateOption(editedOptions?.checkins_review_activity),
        },
      );
    }

    if (isPerformanceActive) {
      options.push(
        {
          title: 'Goal state',
          name: 'goal_state',
          value: validateOption(editedOptions?.goal_state),
        },

        {
          title: `${capitalize(cfv().g_oal)} alignment`,
          name: 'objective_alignment',
          value: validateOption(editedOptions?.objective_alignment),
        },
        {
          title: `${capitalize(cfv().g_oal)} performance`,
          name: 'objective_performance',
          value: validateOption(editedOptions?.objective_performance),
        },
        {
          title: `${capitalize(cfv().k_rs)} performance`,
          name: 'keyresult_performance',
          value: validateOption(editedOptions?.keyresult_performance),
        },
        {
          title: `${capitalize(cfv().g_oal)} performance details`,
          name: 'objective_performance_details',
          value: validateOption(editedOptions?.objective_performance_details),
        },
        {
          title: `${capitalize(cfv().k_rs)} performance details`,
          name: 'keyresult_performance_details',
          value: validateOption(editedOptions?.keyresult_performance_details),
        },
      );
    }
    if (auth.user.workspace.config.allowKpi && isPerformanceActive) {
      options.push({
        title: `KPI Board`,
        name: 'kpi_board',
        value: validateOption(editedOptions?.kpi_board),
      });
    }
    return options;
  }, [
    auth.user.workspace.config.allowKpi,
    editedOptions,
    isPerformanceActive,
    isCheckinsEnabled,
    isEngagementActive,
  ]);
  return {
    getOptions,
  };
};
