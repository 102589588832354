import {useState, useRef, useEffect, useCallback} from 'react';
import {Body2} from '../../atoms/typography';
import {WrapperHeader, CommentsWrapper, MentionDetails} from './comments-style';
import {ChevronDownIcon} from '../../atoms/icons/chevron-down';
import {useNavigate} from 'react-router';
import {HandlerWrapperComment} from '../../molecules/goal/editable-title/style';
import {InputComment} from './comments/input-comments';
import {useStoreContext} from '../../../store/store-context';

import {useLocation} from 'react-router';

import '@draft-js-plugins/emoji/lib/plugin.css';

import {CommentsBody} from './comments/comments-body';
import {useFirebaseFetch} from '@hooks/query-hook';

export default function Comments({
  userId,
  comments,
  handleMentionUser,
  goals,
  isExpand,
  setIsExpand,
  handleOpenComment,
}: any) {
  const {search, pathname} = useLocation();
  const navigate = useNavigate();
  const myRef: any = useRef(null);
  const {
    companyStore: {handleCommentRef},
  } = useStoreContext();

  const executeScroll = () =>
    myRef.current.scrollIntoView({behavior: 'smooth', block: 'center'});

  const handleCommentUserId = useCallback(() => {
    const token = search.slice(search.indexOf('$') + 1);
    if (token.length > 4) {
      executeScroll();
    }
  }, [search]);

  useEffect(() => {
    if (window.location.search !== '') {
      handleCommentUserId();
    }
  }, [handleCommentUserId]);

  return (
    <CommentsWrapper>
      <WrapperHeader
        onClick={() => {
          setIsExpand(!isExpand);
          executeScroll();
          if (window.location.search !== '') {
            navigate(pathname);
          }
          handleMentionUser();
          handleCommentRef('');
          if (!isExpand) {
            handleOpenComment(userId);
          } else {
            handleOpenComment('');
          }
        }}>
        <HandlerWrapperComment
          initial={{rotate: 0}}
          animate={{
            rotate: isExpand ? -180 : 0,
            top: isExpand ? '-2px' : '2px',
          }}
          transition={{
            duration: 0.3,
          }}
          key="goal-toggle">
          <ChevronDownIcon color={isExpand ? '#585ADF' : '#000000'} />
        </HandlerWrapperComment>
      </WrapperHeader>
      <div ref={myRef}>
        {isExpand && (
          <CommentDetails userId={userId} comments={comments} goals={goals} />
        )}
      </div>
    </CommentsWrapper>
  );
}

export function CommentDetails({
  mentionsPath,
  comments,
  path,
  allowedMentionedUsers,
  mentions,
  isReadOnly,
  allGoalSubscribers,
  pathId,
  defaultSubscribers,
}: any) {
  const [editComments, setEditComments] = useState<any>();

  const entitiesPath = `commentsEntities`;

  const {data: entities} = useFirebaseFetch(entitiesPath);

  const handleEditComments = (comment: any) => {
    const commentEntities = entities?.[comment?.[0]];

    if (comment) {
      return setEditComments([
        comment[0],
        {...comment[1], entities: commentEntities || comment[1]?.entities},
      ]);
    }
    setEditComments(comment);
  };

  return (
    <MentionDetails>
      <div style={{padding: '15px 12px 12px 12px'}}>
        <InputComment
          pathId={pathId}
          defaultSubscribers={defaultSubscribers}
          allMentionedUsers={mentions}
          allowedMentionedUsers={allowedMentionedUsers}
          mentionsPath={mentionsPath}
          isReadOnly={isReadOnly}
          path={path}
          allGoalSubscribers={allGoalSubscribers}
          editComments={editComments}
          handleEditComments={handleEditComments}
        />
        <div className="link">
          <Body2 kind="textMedium">@ - mention a team member</Body2>
        </div>
      </div>
      <CommentsBody
        comments={comments?.comments}
        goalId={pathId}
        path={path}
        mentions={comments?.mentions?.map(
          (comment: any) => comment?.id || comment,
        )}
        editComments={editComments}
        handleEditComments={handleEditComments}
      />
    </MentionDetails>
  );
}
