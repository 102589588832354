import React from 'react';

interface FilterIconProps {
  stroke?: string;
}
export const FilterIcon = ({stroke}: FilterIconProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_28735_83511)">
        <path
          d="M2.67188 14.0007V9.33398"
          stroke={stroke || '#585ADF'}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2.67188 6.66667V2"
          stroke={stroke || '#585ADF'}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8 14V8"
          stroke={stroke || '#585ADF'}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8 5.33333V2"
          stroke={stroke || '#585ADF'}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.3281 13.9993V10.666"
          stroke={stroke || '#585ADF'}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.3281 8V2"
          stroke={stroke || '#585ADF'}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M0.671875 9.33398H4.67187"
          stroke={stroke || '#585ADF'}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6 5.33398H10"
          stroke={stroke || '#585ADF'}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.3281 10.666H15.3281"
          stroke={stroke || '#585ADF'}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_28735_83511">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
