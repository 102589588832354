import styled from 'styled-components';
import styledMap, {mapToTheme as theme} from 'styled-map';
import {Colors} from '../../interface';
import {memo, PropsWithChildren} from 'react';

type Size = 'md' | 'lg';

const Wrapper = styled.button<{kind?: Colors; size?: Size}>`
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    stroke: ${theme('colors', 'kind')};
    width: ${styledMap('size', {
      md: '16px',
      lg: '24px',
      default: '16px',
    })};
    height: ${styledMap('size', {
      md: '16px',
      lg: '24px',
      default: '16px',
    })};
  }
`;

export interface InputActionProps
  extends PropsWithChildren<{
    onClick: () => void;
    kind?: Colors;
    size?: Size;
  }> {}

export const InputAction = memo(function ({
  children,
  onClick,
  kind,
  size,
}: InputActionProps) {
  return (
    <Wrapper type="button" size={size} onClick={onClick} kind={kind}>
      {children}
    </Wrapper>
  );
});
InputAction.displayName = 'InputAction';
