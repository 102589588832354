import {useState} from 'react';
import {observer} from 'mobx-react';
import {Button, DisplayNameButton} from '../../../../../../../ui/atoms/button';
import {TextField} from '../../../../../../../ui/molecules/field/textfield';
import {SecondaryBreadcrumb} from '@ui/molecules/breadcrumb';
import {CancelIcon} from '../../../../../../../ui/atoms/icons';
import {Label} from '../../../../../../../ui/atoms/label';
import {VerticalSpacer} from '../../../../../../../ui/atoms/spacer';
import {
  Body1,
  Body2,
  Headline2,
  Headline4,
} from '../../../../../../../ui/atoms/typography';
import {ModalCard} from '../../../../../../../ui/layouts/modal-card';
import {SectionCard} from '@ui/layouts/section-card';
import {BackAction} from '../../../../../../../ui/molecules/back-action';
import {useStoreContext} from '../../../../../../../store/store-context';
import {SwitchToggle} from '@ui/molecules/toggle-switch/toggle-switch';
import FreeTierModal from '@ui/organisms/free-tier/free-tier-modal';
import {illustrationList} from '@ui/assets/illustrations';
import {FlexRow, FlexRowEnd} from '@ui/style/styles';
import {SelectField} from '@ui/molecules/select/selectfield';
import {LockIcon} from '../../../../../../../ui/atoms/icons';
import {useBreakpoints} from '@utils/use-breakpoints';
import {Modal} from '../../../../../../../ui/molecules/modal';
import {useAdminWorkspaceHook} from '../../admin-workspace-hooks';
import GoalAlignmentSettings from './components/goal-alignment-settings';

import {
  ContentBox,
  FeaturesLayout,
  BodyLayout,
  BreadcrumbWrapper,
  SelectFrameworkWrapper,
  MemberEditGoal,
} from '../../admin-workspace-styled';
import ImportPage from '../../../../../../import-goals/import-page';
import {useNavigate} from 'react-router';

const membersEdit = [
  {
    label: 'Always',
    value: 'forever',
  },
  {
    label: '24 hours after goal creation',
    value: '24 hours',
  },
  {
    label: '48 hours after goal creation',
    value: '48 hours',
  },
  {
    label: 'Never',
    value: 'never',
  },
];

export const AdminGoalFramework = observer(() => {
  const {
    activeGoalsFrameworkTab,
    handleGoalsFrameworkTabSwitch,
    nameSaveDisabled,
    goalFrameworkModalIsOpen,
    featureLimitStatus,
    closeGoalFrameworkModal,
    displayNamesModal,
    handleConfirmNameModal,
    handleAutoEndGoal,
    handleKpiSelection,
    handleAlignmentSelection,
    closeGoalNameModal,
    handleConfirmModal,
    modalData,
    loader,
    handleDisplayGoal,
    handleCanMemberEdit,
    handleDisplayKeyResults,
    editMemberGoal,
    setEditMemberGoal,
    editMemberGoalLoader,
    getGoalFrameworkLabel,

    getGoalFrameworkModalText,
    handleClickSave,
  } = useAdminWorkspaceHook();
  const isSubmitting: boolean = loader?.goalFramework;

  const {
    storeDashboardRoutes,
    authStore: {auth},
  } = useStoreContext();

  const [showKPIModal, setShowKPIModal] = useState<'enable' | 'disable' | ''>(
    '',
  );

  const navigate = useNavigate();

  const [showAutoEndGoalModal, setShowAutoEndGoalModal] = useState(false);

  const [isKPIenabled, activateKPI] = useState(
    auth?.user?.workspace?.config?.allowKpi,
  );

  const [autoEndGoals, activateAutoEndGoals] = useState(
    auth?.user?.workspace?.settings?.autoEndGoals,
  );
  const canMemberEditGoal = auth?.user?.workspace?.settings?.allowGoalUpdate;

  const [memberEditGoal, setMemberEditGoal] = useState(canMemberEditGoal);

  const {importGoalTabs} = storeDashboardRoutes;

  const GoalImportBreadCrumb = [
    {
      href: '#',
      id: '1',
      onClick: () => {
        handleGoalsFrameworkTabSwitch('');
      },
      title: 'Goal Settings',
    },
    {
      href: '',
      id: '2',
      isCurrent: true,
      onClick: function noRefCheck() {},
      title: importGoalTabs.import ? 'Import' : 'History',
    },
  ];

  const isAccountFreeTier = auth?.user.workspace.billing.plan === 'free_tier';

  const [showFreeTier, setShowFreeTier] = useState({
    objectiveName: false,
    kpi: false,
    goalAutoEnd: false,
    allowGoalUpdate: false,
  });

  const handleUpdateTier = (
    field: keyof typeof showFreeTier,
    value: boolean,
  ) => {
    setShowFreeTier((prev) => ({...prev, [field]: value}));
  };

  const handleAction = (
    freeTierType: keyof typeof showFreeTier,
    onSubmit: () => void,
  ) => {
    if (isAccountFreeTier) {
      return handleUpdateTier(freeTierType, true);
    }

    return onSubmit();
  };

  const ObjectivesNameFreeModal = (
    <FreeTierModal
      open={showFreeTier.objectiveName}
      image={illustrationList.painting}
      onClose={() => handleUpdateTier('objectiveName', false)}
      description={`Substitute universal phrases such as "Objectives" and "Key Results" with language and expressions that align with your team's culture and vernacular, facilitating comprehension for all members.`}
      title={`Customize your “Objectives” and “Key result”  to Your Taste`}
    />
  );

  const KpiFreeModal = (
    <FreeTierModal
      open={showFreeTier.kpi}
      image={illustrationList.createGoal}
      onClose={() => handleUpdateTier('kpi', false)}
      description={`Facilitate the use of Key Performance Indicators (KPIs) to establish unambiguous expectations, assess team accomplishments, and obtain valuable insights that enable informed decision-making and progress.`}
      title={`Utilize KPIs to Effectively Measure Leading Indicators`}
    />
  );

  const GoalAutoEnd = (
    <FreeTierModal
      open={showFreeTier.goalAutoEnd}
      image={illustrationList.cleanup}
      onClose={() => handleUpdateTier('goalAutoEnd', false)}
      description={`Eliminate concerns regarding the timely completion of objectives and meeting deadlines. With the aid of automation, simplify your workflow and enhance organization, resulting in saved time and effort.`}
      title={`Improve Workflow Efficiency, Automate the Ending of Goals when they are due`}
    />
  );

  const AllowGoalUpdate = (
    <FreeTierModal
      open={showFreeTier.allowGoalUpdate}
      image={illustrationList.goal_ownership}
      onClose={() => handleUpdateTier('allowGoalUpdate', false)}
      description={`Team members can take ownership of their goals and work towards achieving them within the set timelines. This feature improves responsibility and empowers individuals to take charge.`}
      title={`Promote Goal Ownership, Restrict Access to Modifications`}
    />
  );
  const breadCrumbNav = [
    {
      href: '#',
      id: '1',
      onClick: () => {
        navigate('');
      },
      title: 'Performance',
    },
    {
      href: '',
      id: '2',
      isCurrent: true,
      onClick: function noRefCheck() {},
      title: 'Goals & OKRs',
    },
  ];

  const {isXs} = useBreakpoints();

  return (
    <>
      <FeaturesLayout>
        <BreadcrumbWrapper>
          <SecondaryBreadcrumb
            navigation={
              activeGoalsFrameworkTab === 'import'
                ? GoalImportBreadCrumb
                : breadCrumbNav
            }
            wrapperStyles={{marginBottom: 0}}
          />
        </BreadcrumbWrapper>

        <BodyLayout>
          {activeGoalsFrameworkTab !== 'import' && (
            <>
              <Headline2 style={{padding: '24px 0px 24px 0px'}}>
                Goal Settings
              </Headline2>
            </>
          )}
          {ObjectivesNameFreeModal}
          {KpiFreeModal}
          {GoalAutoEnd}
          {AllowGoalUpdate}
          {['name', 'framework', ''].includes(activeGoalsFrameworkTab) && (
            <ContentBox style={{marginTop: '6px'}}>
              <div
                className="flex"
                onClick={() =>
                  isAccountFreeTier
                    ? handleUpdateTier('objectiveName', true)
                    : handleGoalsFrameworkTabSwitch('name')
                }>
                <div className="column">
                  <Headline4 kind="textDark">
                    {isAccountFreeTier && (
                      <span>
                        <LockIcon />{' '}
                      </span>
                    )}{' '}
                    Change Objectives Display name
                  </Headline4>
                  <VerticalSpacer size="8px" />
                  <Body2 kind="textBody">
                    Customize the display name of OKRs to match your preference.
                  </Body2>
                </div>
                <FlexRowEnd>
                  {activeGoalsFrameworkTab !== 'name' ? (
                    <DisplayNameButton
                      width="xm"
                      style={{height: '40.16px', width: '134px'}}
                      disabled={featureLimitStatus?.limited}>
                      Edit name
                    </DisplayNameButton>
                  ) : (
                    <Button
                      width="xm"
                      style={{padding: '8px 16px'}}
                      aria-busy={isSubmitting}
                      isLoading={isSubmitting}
                      disabled={nameSaveDisabled}
                      onClick={() => {
                        handleClickSave();
                      }}>
                      Save name
                    </Button>
                  )}
                </FlexRowEnd>
              </div>

              {activeGoalsFrameworkTab === 'name' && (
                <>
                  <VerticalSpacer size="12px" />
                  <div className="text">
                    <SelectFrameworkWrapper>
                      <Label style={{fontSize: '14px', color: '#1E1E2F'}}>
                        Alternative name for " Objectives"
                      </Label>
                      <VerticalSpacer size="8px" />
                      <TextField
                        placeholder={'Enter alternative name'}
                        defaultValue=""
                        type="text"
                        onChange={(e) => handleDisplayGoal(e.target.value)}
                        autoFocus
                        name="text"
                        className="input"
                      />
                      <VerticalSpacer size="12px" />
                      <Label style={{fontSize: '14px', color: '#1E1E2F'}}>
                        Alternative name for " Key Result"
                      </Label>
                      <VerticalSpacer size="8px" />
                      <TextField
                        placeholder={'Enter alternative name'}
                        defaultValue=""
                        onChange={(e) =>
                          handleDisplayKeyResults(e.target.value)
                        }
                        type="text"
                        name="text"
                        className="input"
                      />
                    </SelectFrameworkWrapper>
                  </div>
                </>
              )}
            </ContentBox>
          )}
          {activeGoalsFrameworkTab !== 'import' && (
            <VerticalSpacer size="16px" />
          )}
          {['name', 'framework', ''].includes(activeGoalsFrameworkTab) && (
            <ContentBox>
              <div
                className="flex"
                onClick={() => handleGoalsFrameworkTabSwitch('import')}>
                <div className="column">
                  <Headline4 kind="textDark">Import OKRs</Headline4>
                  <VerticalSpacer size="8px" />
                  <Body2 kind="textBody">
                    You can choose to import objectives for your workspace.
                  </Body2>
                </div>
                <FlexRowEnd>
                  <Button
                    kind="secondary"
                    style={{padding: '8px 16px', width: '134px'}}>
                    Import
                  </Button>
                </FlexRowEnd>
              </div>
            </ContentBox>
          )}
          {['name', 'framework', ''].includes(activeGoalsFrameworkTab) && (
            <ContentBox style={{marginTop: '16px'}}>
              <div className="flex" onClick={() => null}>
                <div className="column">
                  <Headline4 kind="textDark">
                    {isAccountFreeTier && (
                      <span>
                        <LockIcon />{' '}
                      </span>
                    )}{' '}
                    Enable KPIs
                  </Headline4>
                  <VerticalSpacer size="8px" />
                  <Body2 kind="textBody">
                    Add KPIs to your current framework.
                  </Body2>
                </div>
                <FlexRowEnd>
                  <SwitchToggle
                    checked={isKPIenabled}
                    onChange={(e) => {
                      const updateKpiModal = () =>
                        setShowKPIModal(isKPIenabled ? 'disable' : 'enable');
                      handleAction('kpi', updateKpiModal);
                    }}
                  />
                </FlexRowEnd>
              </div>
            </ContentBox>
          )}

          {['name', 'framework', ''].includes(activeGoalsFrameworkTab) && (
            <GoalAlignmentSettings
              handleAlignmentSelection={handleAlignmentSelection}
            />
          )}

          {['name', 'framework', ''].includes(activeGoalsFrameworkTab) && (
            <ContentBox style={{marginTop: '16px'}}>
              <div className="flex" onClick={() => null}>
                <div className="column">
                  <Headline4 kind="textDark">
                    {isAccountFreeTier && (
                      <span>
                        <LockIcon />{' '}
                      </span>
                    )}{' '}
                    Enable Goal auto-end
                  </Headline4>
                  <VerticalSpacer size="8px" />
                  <Body2 kind="textBody">
                    Automatically end goals (OKR & KPIs) on its past due.
                  </Body2>
                </div>
                <FlexRowEnd>
                  <SwitchToggle
                    onChange={(e) => {
                      const updateAutoEndGoals = () => {
                        if (autoEndGoals === 'disabled') {
                          setShowAutoEndGoalModal(true);
                        } else {
                          handleAutoEndGoal(false);
                          activateAutoEndGoals('disabled');
                        }
                      };

                      handleAction('goalAutoEnd', updateAutoEndGoals);
                    }}
                    checked={autoEndGoals === 'enabled'}
                  />
                </FlexRowEnd>
              </div>
            </ContentBox>
          )}
          <VerticalSpacer size="16px" />
          {['name', 'framework', ''].includes(activeGoalsFrameworkTab) && (
            <SectionCard
              title="Goal permission settings"
              headerPadding={isXs ? 'small' : undefined}
              contentStyling={{
                padding: isXs ? '0px' : '24px 24px 10px 24px',
              }}
              action={
                <>
                  {isAccountFreeTier ? (
                    <SwitchToggle
                      checked={false}
                      onChange={(e) => {
                        handleUpdateTier('allowGoalUpdate', true);
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </>
              }
              CustomHeaderTitle={
                <div>
                  <Headline4 kind="textDark">
                    {' '}
                    {isAccountFreeTier && (
                      <span>
                        <LockIcon />{' '}
                      </span>
                    )}{' '}
                    Goal permission settings
                  </Headline4>
                </div>
              }>
              <MemberEditGoal>
                <SectionCard
                  title="Goal permission settings"
                  headerPadding={isXs ? 'small' : undefined}
                  contentStyling={{
                    padding: isXs ? '0px' : '24px 24px 10px 24px',
                  }}
                  action={
                    <>
                      {!editMemberGoal ? (
                        <Button
                          style={{padding: '8px 16px'}}
                          kind="secondary"
                          disabled={isAccountFreeTier}
                          onClick={() => setEditMemberGoal(true)}>
                          Edit
                        </Button>
                      ) : (
                        <FlexRow>
                          <Button
                            onClick={() => handleCanMemberEdit(memberEditGoal)}
                            style={{padding: '8px 16px'}}
                            isLoading={editMemberGoalLoader}
                            disabled={canMemberEditGoal === memberEditGoal}>
                            Save
                          </Button>{' '}
                          <Button
                            style={{padding: '8px 16px'}}
                            kind="secondary"
                            onClick={() => setEditMemberGoal(false)}>
                            Close
                          </Button>
                        </FlexRow>
                      )}{' '}
                    </>
                  }
                  CustomHeaderTitle={
                    <div>
                      <Headline4 kind="textDark">
                        Allow “member” only role edit goals
                      </Headline4>
                    </div>
                  }>
                  <MemberEditGoal>
                    <SelectField
                      borderRadius={10}
                      options={membersEdit}
                      value={memberEditGoal}
                      onChange={(data: {label: string; value: string}) =>
                        data?.value && setMemberEditGoal(data?.value)
                      }
                      margin
                      disabled={!editMemberGoal}
                    />
                  </MemberEditGoal>
                </SectionCard>
              </MemberEditGoal>
            </SectionCard>
          )}
          {activeGoalsFrameworkTab === 'import' && (
            <>
              <ImportPage type="admin" />
            </>
          )}
        </BodyLayout>
      </FeaturesLayout>
      <Modal open={goalFrameworkModalIsOpen} onClose={() => {}}>
        <div style={{maxWidth: '663px', margin: 'auto', marginTop: '10%'}}>
          <BackAction
            width="md"
            icon={<CancelIcon />}
            onClick={closeGoalFrameworkModal}
            title="Close"
          />
          <VerticalSpacer size="16px" />
          <ModalCard align="center" title="">
            <div>
              {activeGoalsFrameworkTab === 'name' ? (
                <Headline2>Change Display name?</Headline2>
              ) : (
                <Headline2>
                  Switch framework to{' '}
                  {getGoalFrameworkLabel(modalData?.framework)}?
                </Headline2>
              )}
              <VerticalSpacer size="16px" />

              <Body1 kind="textBody">
                {activeGoalsFrameworkTab === 'name'
                  ? ' You’re about to change the goal framework of your workspace. This will affect all previous information uploaded.'
                  : getGoalFrameworkModalText(modalData?.framework)}
              </Body1>
              <VerticalSpacer size="16px" />
              {activeGoalsFrameworkTab === 'framework' && (
                <Body1 kind="textBody">
                  Click <span style={{fontWeight: 700}}>“confirm”</span> to
                  continue.{' '}
                </Body1>
              )}
              <VerticalSpacer size="16px" />
              <Button
                onClick={() => {
                  handleConfirmModal();
                }}
                width="full">
                Confirm
              </Button>
              <VerticalSpacer size="30px" />
            </div>
          </ModalCard>
        </div>
      </Modal>
      <Modal open={displayNamesModal} onClose={() => {}}>
        <div style={{maxWidth: '663px', margin: 'auto', marginTop: '10%'}}>
          <BackAction
            width="md"
            icon={<CancelIcon />}
            onClick={closeGoalNameModal}
            title="Close"
          />
          <VerticalSpacer size="16px" />
          <ModalCard align="center" title="">
            <div>
              <Headline2>Change Display name?</Headline2>

              <VerticalSpacer size="16px" />

              <Body1 kind="textBody">
                You’re about to change the goal framework of your workspace.
                This will affect all previous information uploaded.
              </Body1>
              <VerticalSpacer size="16px" />

              <Button
                onClick={() => {
                  handleConfirmNameModal();
                }}
                width="full">
                Confirm
              </Button>
              <VerticalSpacer size="64px" />
            </div>
          </ModalCard>
        </div>
      </Modal>
      <Modal open={!!showKPIModal} onClose={() => {}}>
        <div style={{maxWidth: '560px', margin: 'auto', marginTop: '10%'}}>
          <BackAction
            width="md"
            icon={<CancelIcon />}
            onClick={() => setShowKPIModal('')}
            title="Close"
          />
          <VerticalSpacer size="16px" />
          <ModalCard align="center" title="">
            <div>
              <Headline2>
                {showKPIModal === 'enable' ? 'Enable' : 'Disable'} KPIs?
              </Headline2>

              <VerticalSpacer size="16px" />

              <Body1 kind="textBody">
                {showKPIModal === 'enable'
                  ? `KPIs will be added to your create, edit and update view under “Goals” and also be available in reporting.`
                  : showKPIModal === 'disable' &&
                    `This will be remove all KPIs added and also the functionality to your create, edit and update view under “Goals” and in reporting. `}
              </Body1>
              <VerticalSpacer size="12px" />
              <Body1 kind="textBody">
                Click <span style={{fontWeight: 700}}>“confirm”</span> to
                continue.
              </Body1>
              <VerticalSpacer size="24px" />

              <Button
                onClick={() => {
                  handleKpiSelection(showKPIModal === 'enable' ? true : false);
                  setShowKPIModal('');
                  activateKPI(isKPIenabled ? false : true);
                }}
                width="full">
                Confirm
              </Button>
              <VerticalSpacer size="64px" />
            </div>
          </ModalCard>
        </div>
      </Modal>
      <Modal open={showAutoEndGoalModal} onClose={() => {}}>
        <div style={{maxWidth: '560px', margin: 'auto', marginTop: '10%'}}>
          <BackAction
            width="md"
            icon={<CancelIcon />}
            onClick={() => setShowAutoEndGoalModal(false)}
            title="Close"
          />
          <VerticalSpacer size="16px" />
          <ModalCard align="center" title="">
            <div>
              <Headline2>Enable Goal auto-end?</Headline2>
              <VerticalSpacer size="16px" />
              <Body1 kind="textBody">
                Goals which includes OKRs & KPIs will automatically end
                immediately they are past due.
              </Body1>
              <VerticalSpacer size="12px" />
              <Body1 kind="textBody">
                Click <span style={{fontWeight: 700}}>“confirm”</span> to
                continue.
              </Body1>
              <VerticalSpacer size="24px" />

              <Button
                onClick={() => {
                  handleAutoEndGoal(true);
                  setShowAutoEndGoalModal(false);
                  activateAutoEndGoals('enabled');
                }}
                width="full">
                Confirm
              </Button>
              <VerticalSpacer size="64px" />
            </div>
          </ModalCard>
        </div>
      </Modal>
    </>
  );
});
