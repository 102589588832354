import {object, string, date, number, ref, array, boolean} from 'yup';
import {MeasurementType} from '../../hooks/interface';
import {GoalKind} from '../../ui/interface';
import {cfv} from '../../utils/framework';

const sharedMeasurement = {
  measurement: object().shape({}),
};

const managerShared = {
  manager: string().required('You need to select your manager.').defined(),
};

export class CreateGoalValidatorSchema {
  static get keyResult() {
    return object().shape({
      ...sharedMeasurement,
      name: string()
        .min(5, 'Minimum of 5 characters')
        .max(90, 'Maximum of 90 characters')
        .required(`You need provide a name for this ${cfv().k_r}`),
      user: string().required(
        `You need to assign the ${
          cfv(undefined, true).k_r
        } to a user or yourself`,
      ),
      measurement: object().shape({
        unit: string().required(`Complete ${cfv(undefined, true).k_r} metric.`),
      }),
      range: object().when(['measurement.unit'], {
        is: (measurement: MeasurementType) =>
          measurement !== MeasurementType.BINARY,
        then: object().shape({
          start: number()
            .required('You must provide a start value')
            .typeError('Invalid value for start value'),

          target: number()
            .required('You must provide a target value')
            .typeError('Invalid value for start value'),
          // .moreThan(
          //   ref('start') as Reference<number>,
          //   'Your target value must be greater than start value',
          // ),
          // .positive('Only positive values are allowed'),
        }),
        otherwise: object().notRequired().nullable(),
      }),
      showSource: boolean(),
      saveSource: boolean().when('showSource', {
        is: true,
        then: boolean()
          .isTrue('Please save the selection')
          .required('Please save the selection'),
        otherwise: boolean().notRequired().nullable(),
      }),
      source: object().when('showSource', {
        is: true,
        then: object().shape({
          type: string().required('Please select an option'),
          keyId: string().required('Please select a report'),
        }),
        otherwise: object().notRequired().nullable(),
      }),
    });
  }

  static get createGoal() {
    return object().shape({
      ...managerShared,

      name: string()
        .min(5, 'Minimum of 5 characters')
        .max(90, 'Maximum of 90 characters')
        .required('Fill in the name for this goal'),
      date: object().shape({
        starts: date()
          .required('You must provide start date')
          .typeError('Invalid value for start date')
          .max(ref('ends'), 'Your start date cannot be greater than end date'),
        ends: date()
          .required('You must provide start date')
          .typeError('Invalid value for end date')
          .min(ref('starts'), 'Your end date cannot be less than start value'),
      }),
      measurement: object().shape({
        unit: string().required('Select a metric unit'),
        symbol: string().when('unit', {
          is: (measurement: MeasurementType) =>
            measurement === MeasurementType.CURRENCY,
          then: string().required('Select a currency'),
          otherwise: string().notRequired().nullable(),
        }),
        preference: string().when('unit', {
          is: (measurement: MeasurementType) =>
            measurement === MeasurementType.NUMERIC ||
            measurement === MeasurementType.CURRENCY,
          then: string().required('Select a preference'),
          otherwise: string().notRequired().nullable(),
        }),
      }),
      keyResults: array().when(['measurement.unit'], {
        is: (measurement: MeasurementType) =>
          measurement === MeasurementType.BINARY,
        then: array()
          .of(CreateGoalValidatorSchema.keyResult)
          .min(1, `You must supply at least 1 ${cfv(undefined, true).k_r}`)
          .required(),

        otherwise: array().notRequired().nullable(),
      }),
      group: array().when(['goalType'], {
        is: (type: GoalKind) => type === GoalKind.GROUP,
        then: array().min(0).required('You must select a group').nullable(),
      }),
    });
  }
}
