import {Body2} from '@ui/atoms/typography';

import styled from 'styled-components';

import {Table, TableHeader, TableWrapper} from '../cc-styles';
import {cloudinary} from '@assets/images/cloudinary';
import {EmptyPlaceholder} from '@ui/atoms/empty-placeholder';

export const Wrapper = styled.div`
  width: 100%;
`;

const {emptyGoals} = cloudinary;

const emptyPlaceholder = {
  image: {
    alt: 'alt text',
    shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
    src: emptyGoals,
    style: {height: '40%', width: '40%'},
  },

  subtitle: ' Cycle information will be available once there’s an assessment.',
  title: 'You are not currently enrolled in any review cycles.',
};

export default function EmptyReview() {
  return (
    <div>
      <Wrapper>
        <TableWrapper>
          <Table>
            <TableHeader variant="you">
              <td style={{maxWidth: '150px', minWidth: '150px'}}>
                <Body2 kind="textBody">Name</Body2>
              </td>

              <td style={{maxWidth: '100px', minWidth: '100px'}}>
                <Body2 kind="textBody">Progress</Body2>
              </td>
              <td style={{maxWidth: '100px', minWidth: '100px'}}>
                <Body2 kind="textBody">Actions</Body2>
              </td>
            </TableHeader>
            <EmptyPlaceholder {...emptyPlaceholder} />
          </Table>
        </TableWrapper>
      </Wrapper>
    </div>
  );
}
