import {observer} from 'mobx-react-lite';
import {
  FormWrapper,
  Header,
  OuterWrapper,
  HorizontalRule,
  ButtonWrapper,
  MoodDiv,
  ProgressDiv,
  PrioritiesDiv,
  WorkLifeDiv,
  LoadingDiv,
  LoadingWrapper,
  StatusWrapper,
  FeedbackDiv,
  HistoricWrapper,
  HistoricDelimitingWrapper,
} from './view-user-checkins.style';
import PageLayout from '@ui/templates/page-template/page-layout';
import {HorizontalSideRule} from '@ui/atoms/spacer/spacer';
import {useNavigate, useParams} from 'react-router';
import {
  Flex,
  FlexRow,
  FlexRowCenter,
  FlexRowSpaceBetween,
} from '@ui/style/styles';
import {getWeekOfYear} from '@utils/date';
import {Label} from '@ui/atoms/label';
import {SubmitCheckIcon} from '@ui/atoms/icons/check';
import FeedBackInput from '@pages/feedback/components/feedback';
import {ViewFeedbackCheckins} from '@pages/feedback/components/view-feedback-checkins';
import SelectCompanyValues from '@pages/feedback/components/select-values';
import {FeedBackType} from '@pages/feedback/components/feedback-type';
import {CalendarIcon} from '../../../../../../../ui/atoms/icons';
import {
  Body1,
  Body2,
  CTA,
  Headline1,
  Headline2,
  Headline3,
} from '../../../../../../../ui/atoms/typography';
import {memo, ReactNode, useCallback, useMemo} from 'react';
import {Button} from '../../../../../../../ui/atoms/button';
import {
  HorizontalSpacer,
  VerticalSpacer,
} from '../../../../../../../ui/atoms/spacer';
import {AuthInputsLayout} from '../../../../../../../ui/layouts/auth';
import {StepProps} from '../../../../../../../ui/organisms/step-wizard/step-wizard';
import {Image} from '../../../../../../../ui/atoms/image';
import {YouHightLight} from '../../../../../you/you-components/you-hightligts';
import {TextField} from '../../../../../../../ui/molecules/field/textfield';
import {useStoreContext} from '../../../../../../../store/store-context';
import {ItemLoader} from '../../../../../../../ui/organisms/item-loader';
import {useViewUserCheckinsHook} from './view-user-checkins.hooks';
import {parseName} from '../../../../../you/you-components/you-checkins/you-checkins';
import {Controller} from 'react-hook-form';
import {emojiList} from '../../../../../../../ui/assets/emojis';
import {capitalize} from '../../../../../../../utils';
import {AlertBadge} from '@ui/atoms/icons/alert-badge';
import {updateTooltipStatus} from '@utils/firebase-request';
import {QuestionIconWrapper} from '@ui/molecules/tooltip/tooltip';
import {TextTag2} from '../../../../../../../ui/atoms/tag';
import {CalendarIconWrapper} from '../company-direct-reports.styles';
import dayjs from 'dayjs';
import {SecondaryBreadcrumb} from '../../../../../../../ui/molecules/breadcrumb';
import {PlainButton} from '@ui/atoms/plain-button/plain-button';
import {checkinName} from '@utils/checkin-name';
import {ActionItems} from '@ui/organisms/action-items/action-items';
import {userName} from '@utils/user-name';
import {useActionItemsWithSortFilter} from '@hooks/action-items';
import {ActionItemsSortByFilter} from '@pages/dashboard/action-items/filter';

const ViewCheckins = memo(function ({
  children,
}: StepProps & {children: ReactNode}) {
  return <AuthInputsLayout feature="invite">{children}</AuthInputsLayout>;
});

export const TooltipBadge = ({message}: {message: string}) => {
  return (
    <QuestionIconWrapper tooltip={true}>
      <AlertBadge />
      <span
        className="tooltip"
        style={{
          width: '300px',
          padding: '16px',
        }}>
        {message}
      </span>
    </QuestionIconWrapper>
  );
};
export const SecondaryTooltipBadge = ({tooltipId}: {tooltipId: string}) => {
  return (
    <PlainButton
      type="button"
      style={{marginLeft: '2px'}}
      onClick={() => updateTooltipStatus(tooltipId, {isDismissed: false})}>
      <AlertBadge />
    </PlainButton>
  );
};

interface IViewUserCheckins {}

// eslint-disable-next-line no-empty-pattern
export const ViewUserCheckins = observer(({}: IViewUserCheckins) => {
  const {checkinsId, type} = useParams();
  const navigate = useNavigate();

  const {
    checkinsStore: {
      specificUserCheckins,
      specificUserCheckinsGoals,

      checkinsDates,
    },
    usersStore: {users},
    authStore: {auth},
    groupStore: {groups},
  } = useStoreContext();

  const {
    handleSubmit,
    control,
    isSubmitting,
    handleFormValueChange,
    submitForm,

    loadingFeedback,
    feedback,
    debounceUpdateQuestions,
    showFeedbackError,
    feedbackError,
    findNextCheckins,
    handleFeedbackFormValueChange,
    feedbackValues,
    debounceUpdateFeedback,
    loadingGoals,
    loadingPage,
  } = useViewUserCheckinsHook(checkinsId);

  // 01. User's Mood

  const MoodBox = useCallback(
    ({
      value,
    }: {
      value: {
        mood: string;
        feedback: string;
        id: string;
      };
    }) => {
      // const value = specificUserCheckins?.responses?.pulse;
      const moods = [
        {
          emoji: emojiList.drained,
          text: 'Drained',
        },
        {
          emoji: emojiList.sad,
          text: 'Sad',
        },
        {
          emoji: emojiList.meh,
          text: 'Meh!',
        },
        {
          emoji: emojiList.happy,
          text: 'Happy',
        },
        {
          emoji: emojiList.elated,
          text: 'Elated',
        },
      ];

      return (
        <MoodDiv>
          <div className="titleDiv">
            <Headline3 kind="textDark">
              {
                users?.filter(
                  (user) => user?.id === specificUserCheckins?.user,
                )[0]?.firstName
              }
              's mood this week
            </Headline3>
          </div>
          <div className="contentDiv">
            {value?.mood && (
              <Image
                {...moods.filter(
                  (mood) => mood.text === capitalize(value?.mood),
                )[0]?.emoji}
                width={60}
                height={60}
              />
            )}
            <HorizontalSpacer size="24px" />
            <div>
              <Headline3 kind="textDark">
                {value?.mood ? capitalize(value?.mood) : 'N/A'}
              </Headline3>
              <VerticalSpacer size="8px" />
              <Body1 kind="textBody">
                {value?.feedback ? `"${value?.feedback}"` : ''}
              </Body1>
            </div>
          </div>
          {(specificUserCheckins?.isSubmitted || value?.mood) && (
            <div className="footerDiv">
              <CTA kind="textDark">Average pulse (last updated) - </CTA>
              <HorizontalSpacer size="4px" />
              {specificUserCheckins?.averagePulse ? (
                <Body2 kind="green400" weight="bold">
                  {capitalize(specificUserCheckins?.averagePulse)}
                </Body2>
              ) : (
                <Body2 kind="textMuted"> not yet calculated</Body2>
              )}
            </div>
          )}
        </MoodDiv>
      );
    },
    [
      specificUserCheckins?.user,
      users,

      specificUserCheckins?.isSubmitted,
      specificUserCheckins?.averagePulse,
    ],
  );

  // 02. User's Progress
  const ProgressOnObjectives = useCallback(
    ({value}) => {
      return (
        <>
          {!loadingGoals && specificUserCheckinsGoals?.length > 0 && (
            <ProgressDiv>
              <div className="titleDiv">
                <Headline3 kind="textDark">Progress on goals </Headline3>
              </div>
              <div className="contentDiv">
                {!loadingGoals && specificUserCheckinsGoals?.length > 0 && (
                  <YouHightLight
                    goals={
                      new Map(
                        specificUserCheckinsGoals?.map((item: any) => [
                          item?.id,
                          item,
                        ]),
                      )
                    }
                    kind="youcheckins"
                    showObjectiveProgress
                    youCheckinsKind="viewuser"
                    isFetching={loadingGoals}
                  />
                )}
                {loadingGoals && <ItemLoader />}
              </div>
            </ProgressDiv>
          )}
          {!loadingGoals && specificUserCheckinsGoals?.length === 0 && <></>}
        </>
      );
    },
    [loadingGoals, specificUserCheckinsGoals],
  );

  // 03. User's Priorities
  const Priorities = useCallback(
    ({
      value,
    }: {
      value: {
        currentCheckin: {
          text?: string;
          completed?: boolean;
        }[];
        nextCheckin: {
          text?: string;
          completed?: boolean;
        }[];
        id: string;
      };
    }) => {
      const {
        handleChange,
        sortedData,
        setSortBy,
        sortBy,
        showCompleted,
        completedTasks,
        updateShowCompleted,
        isCustomFilter,
        firebaseActionItems: tasks,
      } = useActionItemsWithSortFilter('', specificUserCheckins?.user);

      const getPriority = sortedData;

      const showSection =
        checkinsDates.weekOfYear !== getWeekOfYear() && tasks?.length === 0
          ? false
          : true;

      return (
        <>
          {showSection && (
            <PrioritiesDiv>
              <div className="titleDiv">
                <FlexRowSpaceBetween>
                  <Headline3 kind="textDark">
                    {parseName(
                      users?.filter(
                        (user) => user?.id === specificUserCheckins?.user,
                      )[0],
                    )}
                    's Action items
                  </Headline3>
                  <FlexRow className="sm:mt-2 mt-0">
                    <ActionItemsSortByFilter
                      sortBy={sortBy}
                      updateSortBy={setSortBy}
                      isCustomFilter={isCustomFilter}
                      userId={specificUserCheckins?.user}
                    />

                    <div className="mx-1">
                      <HorizontalSideRule size="16px" />
                    </div>

                    <Body2 kind="textBody" className="whitespace-nowrap">
                      {completedTasks.length} completed
                    </Body2>

                    <div className="mx-1">
                      <HorizontalSideRule size="16px" />
                    </div>

                    <button
                      type="button"
                      onClick={() => {
                        updateShowCompleted(!showCompleted);
                      }}>
                      <Body2 weight="bold" kind="purple300">
                        {showCompleted ? 'Hide' : 'Show'}
                      </Body2>
                    </button>
                  </FlexRow>
                </FlexRowSpaceBetween>
              </div>
              <div className="contentDiv">
                <ActionItems
                  handleChange={handleChange}
                  source="priority"
                  removeAddToList
                  pagination={10}
                  users={users.map((user) => ({
                    label: userName(user),
                    value: user.id,
                    avatar: user.avatar,
                  }))}
                  sourceId={''}
                  canDelete={(fieldId) => {
                    const task = tasks.find((task) => task.key === fieldId);

                    if (!!task) {
                      // allow admin delete action item
                      if (auth.user.role === 'admin' && !!task) {
                        return true;
                      }

                      // allow manager of task users delete task
                      const usersInvolved = users
                        .filter((user) =>
                          [
                            task?.user,
                            task?.updatedBy,
                            task?.assignee,
                          ].includes(user.id),
                        )
                        .map((user) => user.reviewer.id);

                      if (usersInvolved.includes(auth.user.id)) {
                        return true;
                      }
                    }
                    return false;
                  }}
                  value={getPriority}
                  userId={specificUserCheckins?.user}
                />
              </div>
            </PrioritiesDiv>
          )}
        </>
      );
    },
    [
      auth.user.id,
      auth.user.role,
      checkinsDates.weekOfYear,
      specificUserCheckins?.user,
      users,
    ],
  );

  // 04. More about User's work life
  const MoreAboutWorkLife = useCallback(
    ({
      value,
    }: {
      value: {
        questions: {
          question: string;
          questionId: string;
          response?: null | string;
          target: {type: string; ids: string[]};
        }[];
        id: string;
      };
    }) => {
      const parseTarget = (target: {type: string; ids: string[]}) => {
        switch (target.type) {
          case 'individual':
            return 'Individual';
          case 'group':
            return `Group: ${target.ids
              ?.map((id: string) => groups.find((grp) => grp.id === id)?.name)
              .join(', ')}`;
          default:
            return 'Company-wide';
        }
      };

      return (
        <>
          {value?.questions?.length > 0 && (
            <WorkLifeDiv>
              <div className="titleDiv">
                <Headline3 kind="textDark">
                  {
                    users?.find(
                      (user) => user?.id === specificUserCheckins?.user,
                    )?.firstName
                  }
                  's answers
                </Headline3>
              </div>
              <div className="contentDiv">
                {value.questions?.map((question, index) => (
                  <div key={question?.question + index}>
                    <TextTag2>{parseTarget(question?.target)}</TextTag2>
                    <VerticalSpacer size="8px" />
                    <TextField
                      placeholder={
                        !!question?.response
                          ? 'Your response here'
                          : 'No response yet'
                      }
                      label={`${question?.question}${
                        (question as any)?.optional ? '(Optional)' : ''
                      }`}
                      value={question?.response as string | undefined}
                      readOnly
                      disabled
                      multiple
                      onChange={(e) => {
                        const parseQ = (
                          questions: {
                            question: string;
                            questionId: string;
                            response?: null | string;
                            target: {type: string; ids: string[]};
                          }[],
                          index: number,
                          response?: null | string,
                        ) => {
                          let newQ = questions;
                          newQ[index].response = response;
                          return newQ;
                        };
                        handleFormValueChange(
                          {
                            ...value,
                            questions: parseQ(
                              value.questions,
                              index,
                              e?.target?.value,
                            ),
                          },
                          'questions',
                        );

                        debounceUpdateQuestions({
                          questions: {
                            ...value,
                            questions: parseQ(
                              value.questions,
                              index,
                              e?.target?.value,
                            ),
                          },
                          questionId: value.id,
                        });
                      }}
                    />
                  </div>
                ))}
              </div>
            </WorkLifeDiv>
          )}
          {value?.questions?.length === 0 && <></>}
        </>
      );
    },
    [
      groups,
      debounceUpdateQuestions,
      handleFormValueChange,

      users,
      specificUserCheckins,
    ],
  );

  const isFeedBackEnabled = useMemo(
    () => auth.user.workspace.settings.feedback === 'enabled',
    [auth.user.workspace.settings.feedback],
  );
  // 05. Your Feedback
  const checkinsStatus = useMemo(() => {
    if (specificUserCheckins?.isReviewed) {
      return (
        <StatusWrapper>
          <Flex alignItems="center" justifyContent="space-between">
            <Body2 kind="textBody" weight="semibold">
              Submitted{' '}
              {specificUserCheckins?.submittedAt
                ? dayjs(specificUserCheckins?.submittedAt).format('MMMM DD')
                : dayjs(checkinsDates?.ends).format('MMMM DD')}
            </Body2>{' '}
            <HorizontalSideRule
              size="19px"
              color="#cecede"
              style={{margin: '0px 8px'}}
            />
            <Body2
              kind="textBody"
              weight="semibold"
              style={{display: 'flex', alignItems: 'center'}}>
              Reviewed{' '}
              <span style={{marginLeft: '6px', marginTop: '4px'}}>
                <SubmitCheckIcon status={true} />
              </span>
            </Body2>
          </Flex>
        </StatusWrapper>
      );
    } else if (specificUserCheckins?.isSubmitted) {
      return (
        <StatusWrapper>
          <Body2 kind="textBody" weight="semibold">
            Submitted{' '}
            {specificUserCheckins?.submittedAt
              ? dayjs(specificUserCheckins?.submittedAt).format('MMMM DD')
              : dayjs(checkinsDates?.ends).format('MMMM DD')}
          </Body2>
        </StatusWrapper>
      );
    } else {
      return (
        <StatusWrapper>
          <Body2 kind="textBody" weight="semibold">
            Not submitted
          </Body2>
        </StatusWrapper>
      );
    }
  }, [
    specificUserCheckins?.isReviewed,
    specificUserCheckins?.isSubmitted,
    checkinsDates?.ends,
    specificUserCheckins?.submittedAt,
  ]);
  const checkinsSection = useMemo(
    () => (
      <>
        {' '}
        <div>
          {type !== 'historic' && (
            <ViewCheckins>
              <form key="form2" method="post" onSubmit={() => {}}>
                <Header>
                  <Flex alignItems="center" justifyContent="space-between" sm>
                    <Headline2>
                      {capitalize(checkinName())}s for{' '}
                      {parseName(
                        users?.filter(
                          (user) => user?.id === specificUserCheckins?.user,
                        )[0],
                      )}
                    </Headline2>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        flexDirection: 'column',
                        width: '100%',
                      }}>
                      <FlexRow>
                        <CalendarIconWrapper>
                          <CalendarIcon />
                        </CalendarIconWrapper>
                        <HorizontalSpacer size="8px" />
                        <Body1 weight="semibold" kind="textDark">
                          {dayjs(checkinsDates?.starts).format('MMMM DD') +
                            ' - ' +
                            dayjs(checkinsDates?.ends).format('MMMM DD')}
                        </Body1>
                      </FlexRow>
                      {!loadingPage && checkinsStatus}
                    </div>
                  </Flex>
                </Header>
              </form>
            </ViewCheckins>
          )}
        </div>
        {!loadingPage && !loadingGoals && (
          <>
            <VerticalSpacer size="40px" />
            <Controller
              control={control}
              name="pulse"
              render={({value}) => <MoodBox value={value} />}
            />
            <VerticalSpacer size="24px" />
            <ProgressOnObjectives />
            <VerticalSpacer size="24px" />
            <Controller
              control={control}
              name="priorities"
              render={({value}) => (
                <>
                  <Controller
                    control={control}
                    name="priorities"
                    render={({value}) => <Priorities value={value} />}
                  />
                </>
              )}
            />
            <VerticalSpacer size="24px" />
            <Controller
              control={control}
              name="questions"
              render={({value}) => <MoreAboutWorkLife value={value} />}
            />
            <VerticalSpacer size="24px" />
            <Controller
              control={control}
              name="feedback"
              render={({value}) => (
                <>
                  {specificUserCheckins.isSubmitted && (
                    <FeedbackDiv>
                      <div className="titleDiv">
                        <Headline3 kind="textDark">
                          {specificUserCheckins?.isReviewed
                            ? 'Manager'
                            : 'Write'}{' '}
                          feedback/praise{' '}
                          {specificUserCheckins?.isReviewed ? (
                            ''
                          ) : (
                            <span className="required">*</span>
                          )}
                        </Headline3>
                      </div>
                      <div className="contentDiv">
                        {loadingFeedback ? (
                          <>
                            <LoadingDiv>
                              {' '}
                              <ItemLoader />{' '}
                            </LoadingDiv>
                          </>
                        ) : (
                          <>
                            {' '}
                            {feedback?.length > 0 ? (
                              feedback.map((fdback: any) => (
                                <>
                                  <ViewFeedbackCheckins
                                    {...fdback}
                                    currentUser={auth.user.id}
                                  />{' '}
                                </>
                              ))
                            ) : !!specificUserCheckins?.isReviewed ? (
                              !!specificUserCheckins?.feedback ? (
                                <Body1 kind="textDark">
                                  {specificUserCheckins?.feedback}
                                </Body1>
                              ) : (
                                <Body1 kind="textBody">
                                  {' '}
                                  No feedback given
                                </Body1>
                              )
                            ) : isFeedBackEnabled ? (
                              <>
                                <FeedBackInput
                                  error={
                                    showFeedbackError
                                      ? feedbackError.feedbackValue ||
                                        feedbackError.impression
                                      : ''
                                  }
                                  updateImpression={(value) => {
                                    handleFeedbackFormValueChange(
                                      value,
                                      'impression',
                                    );
                                  }}
                                  updateFeedbackInput={(value) => {
                                    handleFeedbackFormValueChange(
                                      value,
                                      'feedbackValue',
                                    );
                                  }}
                                />
                                <VerticalSpacer size="32px" />
                                {auth.user.workspace.settings.companyValues
                                  .values?.length > 0 && (
                                  <>
                                    <Label>
                                      Give praise based on values{' '}
                                      <span
                                        style={{
                                          fontWeight: 400,
                                          color: '#5F5F8C',
                                        }}>
                                        (optional){' '}
                                      </span>
                                    </Label>
                                    <VerticalSpacer size="8px" />
                                    <SelectCompanyValues
                                      updateValue={(value) =>
                                        handleFeedbackFormValueChange(
                                          value,
                                          'values',
                                        )
                                      }
                                    />
                                  </>
                                )}
                                <Label>
                                  Sharing<span className="required">*</span>
                                </Label>
                                <VerticalSpacer size="8px" />

                                <FeedBackType
                                  error={
                                    showFeedbackError
                                      ? feedbackError.feedbackSharingType
                                      : ''
                                  }
                                  value={feedbackValues.feedbackSharingType}
                                  updateValue={(value) =>
                                    handleFeedbackFormValueChange(
                                      value,
                                      'feedbackSharingType',
                                    )
                                  }
                                />
                              </>
                            ) : (
                              <TextField
                                multiple
                                placeholder="Add a comment"
                                value={value}
                                onChange={(e) => {
                                  handleFormValueChange(
                                    e?.target?.value,
                                    'feedback',
                                  );
                                  debounceUpdateFeedback({
                                    feedback: e?.target?.value,
                                  });
                                }}
                                readOnly={specificUserCheckins?.isReviewed}
                              />
                            )}
                          </>
                        )}
                      </div>
                    </FeedbackDiv>
                  )}
                </>
              )}
            />
          </>
        )}
      </>
    ),
    [
      MoodBox,
      checkinsDates?.starts,
      checkinsDates?.ends,
      showFeedbackError,
      MoreAboutWorkLife,
      auth.user.id,
      auth.user.workspace.settings.companyValues.values,
      feedbackValues.feedbackSharingType,
      debounceUpdateFeedback,
      specificUserCheckins,
      handleFormValueChange,
      loadingFeedback,
      handleFeedbackFormValueChange,
      isFeedBackEnabled,
      feedbackError,
      loadingGoals,
      loadingPage,
      Priorities,
      ProgressOnObjectives,
      control,
      type,
      checkinsStatus,
      feedback,
      users,
    ],
  );

  const breadcrumbNav = useMemo(() => {
    const nav = [
      {
        title: 'Direct reports',
        href: '#',
        id: '1',
        onClick: (e: any) => {
          e.preventDefault();
          navigate(-1);
        },
      },
      {
        title: parseName(
          users?.filter((user) => user?.id === specificUserCheckins?.user)[0],
        ),
        href: '#',
        id: '2',
        isCurrent: true,
        onClick: () => {},
      },
    ];

    return (
      <div>
        <SecondaryBreadcrumb
          wrapperStyles={{marginBottom: 0}}
          navigation={nav}
        />
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    navigate,

    MoreAboutWorkLife,
    Priorities,
    ProgressOnObjectives,
    specificUserCheckins?.user,
    users,
  ]);

  return (
    <OuterWrapper onSubmit={handleSubmit(submitForm)}>
      {type === 'direct' && (
        <PageLayout
          title={`${capitalize(checkinName())}s`}
          onClick={() =>
            navigate(
              `/checkins/direct-reports?week=${checkinsDates.weekOfYear}&year=${checkinsDates.year}`,
            )
          }>
          <FormWrapper>
            <div className="form">
              {checkinsSection}
              <VerticalSpacer size="40px" />
              {(loadingPage || loadingGoals) && (
                <LoadingWrapper>
                  {' '}
                  <ItemLoader />{' '}
                </LoadingWrapper>
              )}
              {!loadingPage &&
                !loadingGoals &&
                !specificUserCheckins?.isReviewed && (
                  <ButtonWrapper>
                    <Button
                      width="full"
                      disabled={!specificUserCheckins?.isSubmitted}
                      type="submit"
                      data-form-action={true}
                      isLoading={isSubmitting}
                      aria-busy={isSubmitting}>
                      Submit review
                    </Button>
                    <VerticalSpacer size="16px" />
                    {specificUserCheckins?.isSubmitted &&
                      !specificUserCheckins?.isReviewed && (
                        <FlexRowCenter>
                          <PlainButton
                            onClick={() => {
                              navigate({search: '?nextUser=true'});
                            }}
                            disabled={
                              !specificUserCheckins?.isSubmitted ||
                              !findNextCheckins
                            }
                            type="submit"
                            data-form-action={true}>
                            <Body2
                              kind={
                                findNextCheckins ? 'purple300' : 'textMuted'
                              }
                              weight="bold">
                              Submit and review next {checkinName()}
                            </Body2>
                          </PlainButton>
                        </FlexRowCenter>
                      )}
                  </ButtonWrapper>
                )}
            </div>
          </FormWrapper>
        </PageLayout>
      )}
      {type === 'historic' && (
        <HistoricDelimitingWrapper>
          <Headline1 kind="textDark" style={{fontSize: 25}}>
            Review submitted {checkinName()}s
          </Headline1>
          <VerticalSpacer size="24px" />
          <div>{breadcrumbNav}</div>
          <VerticalSpacer size="16px" />
          <HorizontalRule />
          <VerticalSpacer size="24px" />
          <div style={{display: 'flex', alignItems: 'center'}}>
            <CalendarIconWrapper>
              <CalendarIcon />
            </CalendarIconWrapper>
            <HorizontalSpacer size="8px" />

            <Body1 weight="semibold" kind="textDark">
              {dayjs(checkinsDates?.starts).format('MMMM DD') +
                ' - ' +
                dayjs(checkinsDates?.ends).format('MMMM DD')}
            </Body1>
          </div>
          {!loadingPage && !loadingGoals && (
            <>
              <VerticalSpacer size="24px" />

              <HistoricWrapper>{checkinsSection}</HistoricWrapper>
            </>
          )}
          {(loadingPage || loadingGoals) && (
            <LoadingWrapper>
              {' '}
              <ItemLoader />{' '}
            </LoadingWrapper>
          )}
        </HistoricDelimitingWrapper>
      )}
    </OuterWrapper>
  );
});

ViewUserCheckins.displayName = 'ViewUserCheckins';
