import {memo} from 'react';

export const TreeViewIcon = memo(({width, height}: any) => (
  <svg
    width={`${width ? width : 24}`}
    height={`${height ? height : 24}`}
    strokeWidth={1.5}
    viewBox={`${width ? `0 0 24 24` : '0 0 24 24'}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.24805 12C5.24805 10.7574 4.24069 9.75 2.99805 9.75C1.75541 9.75 0.748047 10.7574 0.748047 12C0.748047 13.2426 1.75541 14.25 2.99805 14.25C4.24069 14.25 5.24805 13.2426 5.24805 12Z"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
    <path
      d="M23.248 12C23.248 10.7574 22.2407 9.75 20.998 9.75C19.7554 9.75 18.748 10.7574 18.748 12C18.748 13.2426 19.7554 14.25 20.998 14.25C22.2407 14.25 23.248 13.2426 23.248 12Z"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
    <path
      d="M23.248 20.25C23.248 19.0074 22.2407 18 20.998 18C19.7554 18 18.748 19.0074 18.748 20.25C18.748 21.4926 19.7554 22.5 20.998 22.5C22.2407 22.5 23.248 21.4926 23.248 20.25Z"
      strokeLinecap="round"
      strokeWidth={1.5}
      strokeLinejoin="round"
    />
    <path
      d="M23.248 3.75C23.248 2.50736 22.2407 1.5 20.998 1.5C19.7554 1.5 18.748 2.50736 18.748 3.75C18.748 4.99264 19.7554 6 20.998 6C22.2407 6 23.248 4.99264 23.248 3.75Z"
      strokeLinecap="round"
      strokeWidth={1.5}
      strokeLinejoin="round"
    />
    <path
      d="M18.748 20.25C17.1567 20.25 15.6306 19.6179 14.5054 18.4926C13.3802 17.3674 12.748 15.8413 12.748 14.25L12.748 9.75C12.748 8.1587 13.3802 6.63258 14.5054 5.50736C15.6306 4.38214 17.1567 3.75 18.748 3.75"
      strokeLinecap="round"
      strokeWidth={1.5}
      strokeLinejoin="round"
    />
    <path
      d="M5.24805 12L18.748 12"
      strokeLinecap="round"
      strokeWidth={1.5}
      strokeLinejoin="round"
    />
  </svg>
));

TreeViewIcon.displayName = 'TreeViewIcon';
