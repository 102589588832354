import {useCallback, useState} from 'react';

export const useFeedbackHook = () => {
  const [feedbackError, setFeedbackError] = useState({
    impression: '',
    feedbackSharingType: '',
    feedbackValue: '',
  });

  const handleError = (field: string, value: string) => {
    setFeedbackError((prev) => ({...prev, [field]: value}));
  };
  const [loadingFeedback] = useState(false);
  const [feedback, setFeedback] = useState([]);

  const [feedbackValues, setFeedBackValues] = useState({
    impression: '',
    feedbackValue: '',
    values: [] as string[],
    feedbackSharingType: 'private',
  });

  const isErrorField = useCallback(
    (currentUpdate: any) => {
      const {
        feedbackValue,
        impression,
        feedbackSharingType,
      }: {
        feedbackValue: string;
        impression: string;
        feedbackSharingType: string;
      } = {
        ...feedbackValues,
        ...currentUpdate,
      };

      if (feedbackValue.length < 30) {
        handleError(
          'feedbackValue',
          'Error: Feedback must be at least 30 characters.',
        );
        return true;
      } else if (feedbackError.feedbackValue) {
        handleError('feedbackValue', '');
      }
      if (!impression) {
        handleError('impression', 'Error: Theme must be selected');
        return true;
      } else if (feedbackError.impression) {
        handleError('impression', '');
      }
      if (!feedbackSharingType) {
        handleError(
          'feedbackSharingType',
          ' Error: Sharing type must be selected',
        );
        return true;
      } else if (feedbackError.feedbackSharingType) {
        handleError('feedbackSharingType', '');
      }
    },
    [feedbackError, feedbackValues],
  );

  const handleFeedbackFormValueChange = useCallback(
    (value: any, name: string) => {
      setFeedBackValues((prev) => ({...prev, [name]: value}));
      const currentUpdate = {[name]: value};

      isErrorField(currentUpdate);
    },
    [isErrorField],
  );

  return {
    handleFeedbackFormValueChange,
    feedback,
    feedbackError,
    setFeedback,
    loadingFeedback,
    feedbackValues,
  };
};
