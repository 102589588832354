import {SectionCard} from '@ui/layouts/section-card';
import {cloudinary} from '@assets/images/cloudinary';
import {EmptyPlaceholder} from '@ui/atoms/empty-placeholder';
import {FC, ReactNode, useMemo} from 'react';
import {Body2} from '@ui/atoms/typography';
import {Feedback, FeedbackItems} from './feedback-items';
import {sortFeedback} from '@utils/sortFeedback';
import {LoadingWrapper} from './feedback-styles';
import {ItemLoader} from '@ui/organisms/item-loader';
const {emptyGoals} = cloudinary;

interface ISummary {
  empty: {
    title: string;
    subText: string;
  };
  data: Array<any>;
  filter?: ReactNode;
  variant?: string;
  myFeedback?: boolean;
  sortFeedbackBy?: keyof Feedback;
  extraHeader?: ReactNode;
  isFetching?: boolean;
}

const FeedbackSummarySection: FC<ISummary> = ({
  empty,
  data = [],
  variant,
  filter,
  extraHeader,
  myFeedback,
  sortFeedbackBy = 'user',
  isFetching,
}) => {
  const emptyPlaceholder = {
    image: {
      alt: 'alt text',
      shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
      src: emptyGoals,
      style: {height: '30%', width: '30%'},
    },
    imports: true,
    subtitle: empty.subText,
    title: empty.title,
  };

  const sortData = useMemo(
    () => Object.values(sortFeedback(data, 'createdAt')),
    [data],
  );
  const getSummaryLength = sortData.flatMap((value) => value);

  const Wrapper = ({children}: {children: ReactNode}) => {
    return (
      <SectionCard
        CustomHeaderTitle={
          <div>
            <Body2 weight="bold">Summary ({getSummaryLength.length})</Body2>
          </div>
        }
        variant={variant && 'columnStart'}
        extraHeader={extraHeader}
        action={<div>{filter}</div>}>
        <div>{children}</div>
      </SectionCard>
    );
  };

  if (isFetching) {
    return (
      <LoadingWrapper>
        <ItemLoader />
      </LoadingWrapper>
    );
  }

  if (!data.length) {
    return (
      <Wrapper>
        <EmptyPlaceholder {...emptyPlaceholder} />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <div>
        {sortData.map((data, index) => (
          <FeedbackItems
            data={data}
            myFeedback={myFeedback}
            key={index}
            sortFeedbackBy={sortFeedbackBy}
          />
        ))}
      </div>
    </Wrapper>
  );
};

export default FeedbackSummarySection;
