import styled from 'styled-components';
import {Styles} from 'react-select';
import {getColorFromTheme} from '../../../ui-utils';
import {Typography} from '../../../atoms/typography';
import {theme} from '../../../style';

export const IndicatorWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  svg {
    stroke: ${getColorFromTheme('textBody')};
    width: 16px;
    height: 16px;
  }
`;

export const AvatarWrapper = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ControlWrapper = styled.div`
  display: block;
`;

export const LeadNote = styled.p`
  ${Typography};
  color: ${getColorFromTheme('textBody')};
  margin: 8px;
  padding: 8px;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 1.46;
  border-radius: 5px;
  background-color: ${getColorFromTheme('backgroundDark')};
`;

export const getStyles = <T, V extends boolean>(): Partial<Styles<T, V>> => ({
  valueContainer: (base) => ({
    ...base,
    color: theme.colors.textBody,
    width: '100%',
    paddingLeft: '14px',
    paddingRight: '14px',
  }),
  indicatorsContainer: (base) => ({
    ...base,
    paddingLeft: '2px',
    paddingRight: '2px',
  }),
  menu: (base) => ({
    ...base,
    marginTop: '0px',
    marginBottom: '0px',
    border: 'none',
    borderRadius: 7,
    boxShadow: 'none',
    position: 'relative',
    zIndex: 11,
  }),
  menuList: (base) => ({
    ...base,
    padding: '8px 6px',
  }),
  option: (base, state) => {
    let backgroundColor = theme.colors.white;
    if (state.isFocused) {
      backgroundColor = theme.colors.backgroundDark;
    }
    if (state.isSelected) {
      backgroundColor = theme.colors.purple200;
    }
    return {
      ...base,
      fontSize: '16px',
      borderRadius: 6,
      padding: '8px 16px',
      cursor: 'pointer',
      color: theme.colors.textDark,
      backgroundColor,
      ':active': {
        backgroundColor: theme.colors.backgroundDark,
      },
    };
  },
  control: (base, state) => {
    return {
      ...base,
      color: theme.colors.textDark,
      backgroundColor: theme.colors.white,
      transition: '200ms ease all',
      borderColor: theme.colors.borderDark,
      margin: 8,
      boxShadow: 'none',
      '&:hover': state.isFocused
        ? {
            borderColor: theme.colors.purple300,
            boxShadow: `0 0 0 4px ${theme.colors.purple200}`,
          }
        : {
            borderColor: theme.colors.purple200,
            boxShadow: `0 0 0 2px ${theme.colors.purple200}`,
          },
      ...(state.isFocused
        ? {
            borderColor: theme.colors.purple300,
            boxShadow: `0 0 0 4px ${theme.colors.purple200}`,
          }
        : {}),
    };
  },
});
