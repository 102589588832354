import {Headline2, Body1, Body2} from '@ui/atoms/typography';
import {SearchBox2} from '@ui/molecules/search-box';
import {TabNavigation} from '@ui/molecules/tab-nav';
import {FlexRow, FlexRowSpaceBetween} from '@ui/style/styles';
import {memo, useMemo, useState} from 'react';
import {useFirebaseFetch} from '@hooks/query-hook';
import {ArrowHeadDownIcon} from '@ui/atoms/icons';
import {Insight} from './interface';
import {DropdownCustom} from '@ui/molecules/dropdown-custom';
import isYesterday from 'dayjs/plugin/isYesterday';
import isToday from 'dayjs/plugin/isToday';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {InsightsOverview} from './insight-overview';
import {authStore} from '@store/stores/auth-store';
import dayjs from 'dayjs';
import {insightTypes, insightDateRange} from './insight-options';
import {useStoreContext} from '@store/store-context';
import {userName} from '@utils/user-name';
import {useActionItemInsightHook} from './action-items/action-items-hook';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {useObjectiveInsightHook} from './objectives/objective-insight-hook';
import {usePeriodHook} from '@hooks/period-hook';

dayjs.extend(isToday);
dayjs.extend(isYesterday);

type Insights = Record<string, Insight>;

// Helper function to convert and filter insights based on search value
const getFilteredInsights = (
  insights: [string, Insight][],
  searchValue: string,
  type: string,
  insightRange: string,
  userId: string,
): Insight[] => {
  return insights
    .map(([key, insight]) => {
      return {
        ...insight,
        firebaseId: key,
      };
    })
    .filter((insight) => {
      const searched =
        !searchValue ||
        insight.name.toLowerCase().includes(searchValue.toLowerCase());

      const insightType =
        !type || insight.type.toLowerCase() === type.toLowerCase();

      const insightUser = !userId || insight.createdBy === userId;

      const dateRange = () => {
        if (!insightRange) return true;

        if (insightRange === 'today') return dayjs(insight.updatedAt).isToday();

        if (insightRange === 'yesterday')
          return dayjs(insight.updatedAt).isYesterday();

        return dayjs(insight.updatedAt).isBetween(
          dayjs().subtract(Number(insightRange), 'day'),
          dayjs(),
          'day',
        );
      };

      return searched && insightType && dateRange() && insightUser;
    })
    .sort(
      (a, b) =>
        new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf(),
    );
};

// Helper function to filter insights based on active tab
const filterByActiveTab = (
  insights: Insight[],
  activeTab: string,
  userId: string,
): Insight[] => {
  return insights.filter((insight) =>
    activeTab === 'you' ? insight.createdBy === userId : true,
  );
};

const InsightsPage = () => {
  const [activeTab, setActiveTab] = useState<'all' | 'you'>('all');

  const {data: insights} = useFirebaseFetch('insights');

  useActionItemInsightHook();

  useObjectiveInsightHook();

  const [searchValue, setSearchValue] = useState('');

  const [insightType, setInsightType] = useState('');

  const [insightRange, setInsightRange] = useState('');

  const [selectedUser, setSelectedUser] = useState('');

  const allInsight = !insights ? [] : Object.entries(insights);

  const {
    usersStore: {users},
  } = useStoreContext();

  const userOptions = useMemo(
    () => users.map((user) => ({label: userName(user), value: user.id})),
    [users],
  );

  const {currentPeriod} = usePeriodHook();

  // Custom hook to get filtered insights
  const useFilteredInsights = (
    insights: Insights | null,
    searchValue: string,
    activeTab: string,
    userId: string,
    insightType: string,
    insightRange: string,
    insightUser: string,
  ): Insight[] =>
    useMemo(() => {
      if (!insights) return [];

      const insightsArray = Object.entries(insights) as [string, Insight][];

      const filteredInsights = getFilteredInsights(
        insightsArray,
        searchValue,
        insightType,
        insightRange,
        insightUser,
      );

      return filterByActiveTab(filteredInsights, activeTab, userId).map(
        (insight) => {
          if (
            insight.config?.type === 'periods' &&
            !insight.config.range.value
          ) {
            insight.config.range.value = `${currentPeriod.starts} - ${currentPeriod.ends}`;
          }

          return {
            ...insight,
          };
        },
      );
    }, [
      insights,
      searchValue,
      insightType,
      insightRange,
      insightUser,
      activeTab,
      userId,
    ]);

  const computedInsights = useFilteredInsights(
    insights,
    searchValue,
    activeTab,
    authStore.auth.user.id,
    insightType,
    insightRange,
    selectedUser,
  );

  const navigations: any = useMemo(() => {
    const nav = [
      {
        active: activeTab === 'all',
        disable: false,
        href: '/reporting/insights',
        id: '1',
        onClick: () => {
          setActiveTab('all');
          setSelectedUser('');
        },
        iconSpacing: '',
        title: `All insights`,
        icon: <></>,
      },
    ];

    nav.push({
      active: activeTab === 'you',
      disable: false,
      href: '/reporting/insights/you',
      id: '2',
      onClick: () => {
        setActiveTab('you');
        setSelectedUser(authStore.auth.user.id);
      },
      title: `Your insights`,
      iconSpacing: 'space-between',
      icon: <></>,
    });

    return nav;
  }, [activeTab]);

  const isFilterActive =
    !!searchValue ||
    (activeTab === 'all' && !!selectedUser.length) ||
    !!insightType ||
    !!insightRange;

  return (
    <div>
      <FlexRowSpaceBetween className="mb-6">
        <div className="">
          <Headline2>Insights</Headline2>
          <div className="w-[80%] mt-1">
            <Body1 kind="textBody">
              View and download insights from team and company-wide activities
              across your workspace.
            </Body1>
          </div>
        </div>
      </FlexRowSpaceBetween>

      <TabNavigation navigations={navigations} type="button" />

      <div className="border border-borderLight rounded-[10px] bg-white w-full p-6 flex flex-row justify-between mt-4">
        <SearchBox2
          placeholder="Search insights"
          onChange={(event) => setSearchValue(event.target.value)}
          value={searchValue}
          handleCancel={() => setSearchValue('')}
          className="w-[80vw]"
          inputStyle={{width: '20vw', height: '44px'}}
        />
        <FlexRow className="gap-1">
          <FilterDropdown
            disabled={!allInsight.length || activeTab === 'you'}
            options={[
              {
                value: '',
                label: 'Any user',
              },
              ...userOptions,
            ]}
            label="Created by"
            canSearch
            placeholder="Search members"
            value={selectedUser}
            onChange={setSelectedUser}
          />

          <FilterDropdown
            disabled={!allInsight.length}
            options={insightTypes}
            label="Type"
            value={insightType}
            onChange={setInsightType}
          />
          <FilterDropdown
            disabled={!allInsight.length}
            options={insightDateRange}
            label="Last modified"
            value={insightRange}
            onChange={setInsightRange}
          />
        </FlexRow>
      </div>

      <InsightsOverview
        insights={computedInsights}
        filterActive={isFilterActive}
      />
    </div>
  );
};

export default InsightsPage;

interface DropdownOptions {
  options: {value: string; label: string}[];
  onChange: (value: string) => void;
  value: string;
  disabled?: boolean;
  canSearch?: boolean;
  placeholder?: string;
  label: string;
}

export const FilterDropdown = memo(
  ({
    options,
    onChange,
    value,
    disabled,
    label,
    placeholder,
    canSearch,
  }: DropdownOptions) => {
    const [searchValue, setSearchValue] = useState('');

    const computeOptions = searchValue
      ? options.filter(
          (option) => option.label.toLowerCase() === searchValue.toLowerCase(),
        )
      : options;
    const selectedItem = options.find((option) => option.value === value)
      ?.label;

    return (
      <DropdownCustom
        collapseOnClick={true}
        menu={(handleClose: () => void) => (
          <div>
            {canSearch && (
              <>
                <SearchBox2
                  onChange={(event) => setSearchValue(event.target.value)}
                  handleCancel={() => setSearchValue('')}
                  value={searchValue}
                  placeholder={placeholder}
                  height={'40px'}
                  inputStyle={{borderRadius: '10px', height: '44px'}}
                />
                <VerticalSpacer size="16px" />
              </>
            )}

            {computeOptions.map((option) => (
              <DropdownItem
                onClick={() => {
                  onChange(option.value);
                  handleClose();
                }}>
                {option.label}
              </DropdownItem>
            ))}
          </div>
        )}
        customComponent={(handleOpen: (event: any) => void, open: boolean) => (
          <button disabled={disabled} onClick={handleOpen}>
            <FlexRow className="px-2 py-3">
              <Body2 kind={disabled ? 'textMuted' : 'purple300'} weight="bold">
                {label}: {selectedItem}
              </Body2>
              <ArrowHeadDownIcon
                style={{
                  marginLeft: 7,
                  stroke: disabled ? '#BFBFD4' : '#585ADF',
                  transform: !open ? 'rotate(0deg)' : 'rotate(180deg)',
                }}
              />
            </FlexRow>
          </button>
        )}
        customDropdownWrapperStyles={{
          top: '130%',
          right: '0px',
          width: '240px',
        }}
      />
    );
  },
);
