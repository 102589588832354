import {Drawer} from '@ui/molecules/drawer';
import {useNavigate} from 'react-router';
import {observer} from 'mobx-react-lite';
import {useStoreContext} from '@store/store-context';
import {CreateGoalForm} from '../../../create-goal/create-goal-form';
import {VerticalSpacer} from '@ui/atoms/spacer';

export const ManagerEditGoal = observer(() => {
  const {
    goalsState: {isManagerEditing, setIsManagerEditing},
  } = useStoreContext();
  const navigate = useNavigate();

  const handleCloseDrawer = (e: any) => {
    e.preventDefault();
    setIsManagerEditing(false);
    navigate(-1);
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
  };

  if (!isManagerEditing) {
    return null;
  }

  return (
    <Drawer>
      <div>
        <VerticalSpacer size="24px" />
        <CreateGoalForm
          onSubmit={onSubmit}
          editheaderShow={true}
          handleCloseDrawer={handleCloseDrawer}
        />
      </div>
    </Drawer>
  );
});
