import styled from 'styled-components';
import {FlexRow} from '@ui/style/styles';

export const Wrapper = styled.div`
  width: 70%;

  @media (min-width: 768px) and (max-width: 1100px) {
    width: 70%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
  margin: 80px auto 80px auto;
  padding-bottom: 80px;
`;

export const HRule = styled.div`
  width: 100%;
  height: 1px;
  margin: 24px 0px 14px;

  background: #ededf2;
`;

export const CycleWidget = styled.div`
  border-radius: 10px;
  width: 100%;
  border: 1px solid var(--border-light, #ededf2);

  padding: 24px;
  .border-b {
    padding-bottom: 24px;
    margin-top: 24px;
    border-bottom: 1px solid var(--border-light, #ededf2);
  }
  .group {
    border-radius: 10px;
    border: 1px solid var(--border-light, #ededf2);
    padding: 6px 12px;
    margin-right: 10px;
  }
`;

export const FrameworkWrapper = styled.div`
  border-radius: 6px;
  background: var(--background-light, #fafafa);
  padding: 4px 8px;
  margin-right: 6px;
`;

export const StyledRow = styled(FlexRow)`
  width: 100%;
  align-items: flex-start;
  min-height: 75vh;
  .first-column {
    width: 100%;
    height: 100%;
    margin-right: 16px;
  }
`;

export const MileStoneWrapper = styled.div`
  display: grid;
  grid-template-columns: 60% 40%;

  .milestone-item {
    margin-top: 36px;
  }
`;

export const UserTag = styled.div`
  border-radius: 6px;
  background: var(--border-light, #ededf2);
  padding: 2px 8px;
  margin: 0px 8px 0px 6px;
  font-size: 12px;
`;
