import styled from 'styled-components';
import {getColorFromTheme} from '../../ui/ui-utils';
import {motion} from 'framer-motion';

export const EditHeader = styled.div`
  display: flex;
`;

export const AlignGoalWrapper = styled.div`
  display: block;
  border: 1px solid ${getColorFromTheme('borderDark')};
  border-radius: 10px;
  width: 100%;

  &:hover {
    box-shadow: ${getColorFromTheme('purple200')} 0 0 0 2px;
  }
`;

export const AlignGoalHeader = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
`;

export const MeasurementWrapper = styled.div<{error?: boolean}>`
  border: ${({error}) => (error ? `1px solid  #D52A2A` : `1px solid  #CECEDE`)};
  padding: 16px;
  margin-top: 10px;
  border-radius: 10px;
`;

export const AlignGoalChild = styled(motion.div)`
  border-top: 1px solid ${getColorFromTheme('borderDark')};
  border-radius: 10px;
`;

export const QuestionIconWrapper = styled.a<{tooltip?: boolean}>`
  outline: none;
  position: relative;

  svg {
    width: 14px;
    height: 14px;
    stroke: ${getColorFromTheme('purple300')};
    margin-bottom: -2px;
  }

  @media (max-width: 500px) {
    display: none;
  }
  .tooltip {
    visibility: hidden;
    width: 399px;
    background-color: #fafafa;
    font-size: 14px;
    font-weight: normal;

    color: #5f5f8c;
    text-align: center;
    padding: 16px 16px 16px 26px;
    border: 1px solid #e4e5fb;
    border-radius: 5px;
    position: absolute;
    opacity: 0;
    left: -130px;
    transition: opacity 0.3s;
    z-index: 3;

    margin-top: -130px;
  }
  &:hover .tooltip {
    visibility: ${(props: any) => (props.tooltip === true ? 'visible' : '')};
    opacity: 1;
  }

  .tooltip::before {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 33.1%;
    margin-left: -5px;
    border-width: 11px;
    border-style: solid;
    border-color: #e4e5fb transparent transparent transparent;
  }

  .tooltip::after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 33.3%;
    margin-left: -5px;
    border-width: 10px;
    border-style: solid;
    border-color: #fafafa transparent transparent transparent;
  }
`;
