export function getGroupTypeAndGroup(
  groups: Array<any>,
  groupTypes: Array<any>,
  groupId: string,
) {
  const getGroup = groups?.find((grp) => grp.id === groupId);

  const getGroupType = groupTypes?.find(
    (grpType) => grpType.id === getGroup?.groupType,
  );

  return {
    groupType: getGroupType?.name,
    groupName: getGroup?.name,
  };
}
