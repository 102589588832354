import {observer} from 'mobx-react-lite';
import {useFeedbackWrittenHook} from './feedback-direct-reports-hook';
import {useFetchHook} from '@hooks/query-hook';
import {FlexRow, FlexRowSpaceBetween} from '@ui/style/styles';
import {useStoreContext} from '@store/store-context';
import FeedbackSummarySection from '../summary-section';
import {
  DropdownWrapper,
  RequestButton,
  RequestCardWrapper,
} from '../feedback-styles';
import {Feedback} from '../feedback-items';
import {Body2} from '@ui/atoms/typography';
import {useDropDown} from '@hooks';
import {UserListCard} from '@ui/molecules/user/list-card';
import {Button} from '@ui/atoms/button';
import {activateNotification} from '@ui/molecules/notification/activate-notification';
import {FeedbackRequestImpl} from '../feedback-requests';
import {FeedbackController} from '../feedback-controller';
import {useNavigate} from 'react-router';
import {useCallback, useState} from 'react';
import {RequestDropdown} from '../components/request-dropdown';
import {userName} from '@utils/user-name';
import {SortByFilter} from '@pages/dashboard/reporting/reporting-pages/admin-analytics/analytics-pages/analytics-status/status-component/sort-by-filter';

export const FeedBackDirectReports = observer(() => {
  const {controller, filters, sortBy, setSortBy} = useFeedbackWrittenHook();

  const {
    authStore: {auth},
    feedbackStore: {managerFeedbackRequests: feedbackRequests},
  } = useStoreContext();

  const fectchFeedbackWritten = useCallback(async () => {
    const response = await controller.fetchDirectReportsFeedback(filters);
    return response.results;
  }, [controller, filters]);

  const {data = [], isLoading} = useFetchHook(
    ['feedback-written', filters],
    () => fectchFeedbackWritten(),
    {
      refetchOnWindowFocus: false,
    },
  );

  const directReports = data as any;

  const filterData = directReports?.filter(
    (feedback: Feedback) =>
      !(
        feedback.visibility === 'private' && feedback.reviewer !== auth.user.id
      ),
  );

  const {open, handleOpen, ref, handleClose} = useDropDown();

  const isDisabled = !feedbackRequests.length;

  return (
    <div>
      <FeedbackSummarySection
        empty={{
          title: 'No feedback reports',
          subText: 'Feedbacks & praises will appear here.',
        }}
        variant="direct-reports"
        extraHeader={
          <div className="px-6">
            <FlexRowSpaceBetween className="mt-2 py-4 border-t border-t-[#EDEDF2]  w-[80%]">
              <div>
                <button disabled>
                  <Body2 kind="textMuted" weight="bold">
                    Filtered by: {userName(auth.user)}
                  </Body2>
                </button>
              </div>
              <div>
                <SortByFilter value={sortBy} onChange={setSortBy} excludeIcon />
              </div>
            </FlexRowSpaceBetween>
          </div>
        }
        filter={
          <FlexRow>
            <div className="date-body" ref={ref}>
              <RequestButton onClick={handleOpen} disabled={isDisabled}>
                <Body2
                  weight="bold"
                  kind={isDisabled ? 'textMuted' : 'textDark'}>
                  Request(s)
                </Body2>{' '}
                {feedbackRequests.length > 0 && (
                  <div className="notif">{feedbackRequests.length}</div>
                )}
              </RequestButton>
              {open && (
                <DropdownWrapper>
                  <RequestDropdown
                    width="450px"
                    title="Request(s)"
                    onClickCancel={handleClose}>
                    <div>
                      {feedbackRequests?.map(
                        (request: IRequest, index: number) => (
                          <RequestCard
                            key={index}
                            {...request}
                            requests={feedbackRequests}
                          />
                        ),
                      )}
                    </div>
                  </RequestDropdown>
                </DropdownWrapper>
              )}
            </div>
          </FlexRow>
        }
        isFetching={isLoading}
        data={filterData as any}
      />
    </div>
  );
});

interface IRequest {
  createdAt: string;
  id: string;
  reviewer: string;
  status: string;
  subjectName: string;
  subjectType: string;
  setFeedbackRequests: any;
  subjectId?: string;
  refetch: any;
  requests: any;
  updatedAt: string;
  user: string;
}

const RequestCard = observer((props: IRequest) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const {
    feedbackStore: {updateFeedbackRequest},
  } = useStoreContext();

  const handleDismissRequest = async () => {
    setIsLoading(true);
    const request = new FeedbackRequestImpl();
    const controller = new FeedbackController(request);
    const response = await controller.deleteFeedbackRequest(props.id);

    const filtered = props.requests.filter(
      (request: any) => request.id !== props.id,
    );

    updateFeedbackRequest(filtered);

    response &&
      activateNotification({
        content: 'Request dismissed',
        title: '',
        kind: 'success',
      });
    response && setIsLoading(false);
  };

  const {
    usersStore: {users},
  } = useStoreContext();

  const _user = users.find((user) => user.id === props.user);

  return (
    <>
      <RequestCardWrapper>
        {' '}
        <UserListCard
          tooltip={false}
          userId={_user?.id || ''}
          type="primary"
          textKind="textDark"
          avatar={_user?.avatar ? _user.avatar.url : ''}
          name={
            _user?.firstName && _user?.lastName
              ? `${_user.firstName} ${_user.lastName}`
              : _user?.email || ''
          }
        />
        <FlexRow>
          <Button
            style={{padding: '8px 16px'}}
            kind="secondary"
            className="mr-3"
            onClick={handleDismissRequest}
            isLoading={isLoading}>
            Dismiss
          </Button>
          <Button
            style={{padding: '8px 16px'}}
            onClick={() =>
              navigate(`/write-feedback/?id=${props.id}`, {
                state: {
                  user: props.user,
                  subjectType: props.subjectType,
                  subjectName: props.subjectName,
                  subjectId: props?.subjectId,
                },
              })
            }>
            Respond
          </Button>
        </FlexRow>
      </RequestCardWrapper>
    </>
  );
});
