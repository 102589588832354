import {useStoreContext} from '@store/store-context';
import {useMemo, useCallback} from 'react';
import {monthToIndex} from '@hooks/calendar';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import {useFreeTierHook} from './free-tier';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import {AnalyticsTodayDate} from '@utils/date';

dayjs.extend(isBetween);
dayjs.extend(quarterOfYear);

export const usePeriodHook = (disableFuturePeriods?: boolean) => {
  const {
    authStore: {auth},
  } = useStoreContext();
  const isPeriodEnabled = auth?.user?.workspace?.settings?.customPeriod;

  const {isAccountFreeTier} = useFreeTierHook();

  const periodStartMonth =
    auth?.user?.workspace?.settings?.customPeriodStartMonth;

  const periods = useMemo(() => {
    const now = dayjs();
    const startMonthIndex = monthToIndex(periodStartMonth) - 1;

    const currentYear = now.year();
    const currentMonth = now.month() + 1; // January is 0

    let startYear = currentYear;
    let startMonth = startMonthIndex;

    if (startMonth > currentMonth) {
      startYear -= 1;
    }
    while (startMonth + 2 > 11) {
      startMonth -= 3;
      startYear += 1;
    }

    function formatDate(date: any) {
      return date.format('MM/DD/YYYY');
    }

    function getQuarterLabel(startMonth: number, startYear: number) {
      const start = dayjs(new Date(startYear, startMonth, 1));
      const end = start.clone().endOf('quarter');
      return `${start.format('DD MMMM')} - ${end.format('DD MMMM')}`;
    }

    const periods: any = [];
    for (let i = 0; i < 4; i++) {
      const quarterStartMonth = startMonth + i * 3;

      const quarterStartYear = startYear + Math.floor(quarterStartMonth / 12);

      const quarterName = `Q${i + 1} ${quarterStartYear} (${getQuarterLabel(
        quarterStartMonth,
        quarterStartYear,
      )})`;

      const periodStart = dayjs(new Date(startYear, quarterStartMonth, 1));

      const periodEnd = periodStart.clone().endOf('quarter');

      const periodEndMonth = new Date(dayjs(periodEnd).format()).getMonth() + 1;

      const period = {
        value: formatDate(periodStart),
        label: quarterName,
        starts: dayjs(periodStart).format('MM/DD/YYYY'),
        period: `Q${i + 1} ${quarterStartYear}`,
        ends: dayjs(periodEnd).format('MM/DD/YYYY'),
        disabled:
          isAccountFreeTier &&
          !(
            currentMonth >= quarterStartMonth && currentMonth <= periodEndMonth
          ),
      };

      if (disableFuturePeriods && periodEnd.isAfter(now.endOf('quarter'))) {
        continue;
      }
      periods.push(period);
    }
    return [
      ...periods,
      {
        label: `Custom timeline`,
        value: 'custom',
        disabled: isAccountFreeTier,
      },
    ];
  }, [periodStartMonth, disableFuturePeriods, isAccountFreeTier]);

  // Find the current period range

  const currentMonthRange = useCallback(() => {
    const now = new Date();
    const currentMonth = now.getMonth() + 1;

    return (
      periods.find((period) => {
        const periodStartMonth = new Date(period.starts).getMonth() + 1;

        const periodEndMonth = new Date(period.ends).getMonth() + 1;
        return (
          currentMonth >= periodStartMonth && currentMonth <= periodEndMonth
        );
      }) || {
        value: '',
        label: '',
        starts: '',
        ends: '',
        period: '',
      }
    );
  }, [periods]);

  const getPeriod = (startDate: string) => {
    return periods.find((period) => period.value === startDate);
  };

  const currentPeriod = useMemo(
    () =>
      isPeriodEnabled
        ? {
            starts: currentMonthRange().starts,
            ends: currentMonthRange().ends,
            period: currentMonthRange().period,
          }
        : {
            starts: AnalyticsTodayDate().AnalyticsDefaultStartDate,
            ends: AnalyticsTodayDate().AnalyticsDefaultEndDate,
          },
    [isPeriodEnabled, currentMonthRange],
  );

  return {
    periods,
    currentMonthRange,
    getPeriod,
    isPeriodEnabled,
    currentPeriod,
  };
};
