import styled from 'styled-components';
import {FlexRow, FlexRowSpaceBetween} from '@ui/style/styles';
import {ArrowHeadDownIcon} from '@ui/atoms/icons';
import {ReactNode} from 'react';
import {Button} from '@ui/atoms/button';
import {DropdownCustom} from '@ui/molecules/dropdown-custom';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {Body2} from '@ui/atoms/typography';

const Wrapper = styled.div`
  .header {
  }
  .options {
    border-top: 1px solid #ededf2;
    padding: 16px 24px;
    @media (max-width: 500px) {
      padding: 16px;
    }
    .space-between {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .member-field {
        width: 90%;
      }
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-end;

        .member-field {
          width: 100%;
          margin-bottom: 16px;
        }
      }
    }
  }
`;

interface IMeeting {
  onChange: (data: {sortBy: string}) => void;
  rightAction?: ReactNode | null;
  sortBy?: string;
  selectedUsers?: string[];
  disabled?: boolean;
  past_filter?: boolean;
}

export const TeamMeetingFilter = ({
  rightAction,
  onChange,
  disabled,
  sortBy,
}: IMeeting) => {
  return (
    <Wrapper className="bg-white">
      <div className="header">
        <FlexRowSpaceBetween>
          <FlexRow>
            <DropdownCustom
              collapseOnClick={true}
              menu={(handleClose: () => void) => (
                <div>
                  <DropdownItem
                    onClick={() => {
                      onChange({sortBy: 'week'});
                      handleClose();
                    }}>
                    This week
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      onChange({sortBy: 'today'});

                      handleClose();
                    }}>
                    Today
                  </DropdownItem>
                </div>
              )}
              customComponent={(
                handleOpen: (event: any) => void,
                open: boolean,
              ) => (
                <FlexRow className="gap-2">
                  <Body2
                    weight="semibold"
                    kind={disabled ? 'textMuted' : 'textDark'}>
                    Sort by
                  </Body2>
                  <Button
                    onClick={handleOpen}
                    disabled={disabled}
                    width="sm"
                    kind="secondary"
                    className="flex flex-row gap-2 items-center"
                    style={{
                      padding: '8px 16px',
                    }}>
                    <Body2
                      weight="bold"
                      kind={disabled ? 'textMuted' : 'textDark'}>
                      {sortBy === 'week' ? 'This week' : 'Today'}
                    </Body2>
                    <ArrowHeadDownIcon
                      style={{
                        marginLeft: 14,
                        stroke: disabled ? '#bfbfd4' : '#1E1E2F',
                        transform: !open ? 'rotate(0deg)' : 'rotate(180deg)',
                      }}
                    />
                  </Button>
                </FlexRow>
              )}
              customDropdownWrapperStyles={{
                top: '130%',
                width: '190px',
                left: 0,
              }}
            />
          </FlexRow>
          {rightAction && rightAction}
        </FlexRowSpaceBetween>
      </div>
    </Wrapper>
  );
};
