import styled from 'styled-components';
import {ProgressType} from '../../../interface';
import {getColorFromTheme} from '../../../ui-utils';
import {VerticalSpacer} from '../../../atoms/spacer';
import {down} from 'styled-breakpoints';
import {Body2} from '../../../atoms/typography/body2';

import {ProgressTypeInput} from '../../../atoms/progress-type';
import {ChangeEvent, forwardRef, Ref, useCallback, useState} from 'react';

export interface ProgressFieldProps {
  label?: string;
  name?: string;
  disabled?: boolean;
  defaultValue?: ProgressType;
  setValue?: (value: ProgressType) => void;
}

const Wrapper = styled.div`
  position: relative;

  display: inline-block;
  ${down('md')} {
    width: 100%;
  }
`;

const Container = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;

  background-color: ${getColorFromTheme('white')};
  > div {
    @media (max-width: 600px) {
      width: 33.3%;
    }
  }
  > div:first-of-type > label {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  > div:last-of-type > label {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const ProgressField = forwardRef(
  (
    {defaultValue, setValue, label, name, disabled}: ProgressFieldProps,
    ref: Ref<HTMLInputElement>,
  ) => {
    const [selected, setSelected] = useState(defaultValue);

    const onChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;

        setSelected(value as ProgressType);
        if (setValue) {
          setValue(value as ProgressType);
        }
      },
      [setValue],
    );

    return (
      <Wrapper>
        <Body2 kind={disabled ? 'textMuted' : 'textBody'} as="label">
          {label}
        </Body2>
        <VerticalSpacer size="8px" />
        <Container>
          <div>
            <ProgressTypeInput
              id={`${name}-on-track`}
              disabled={disabled}
              kind={ProgressType.ONTRACK}
              value={ProgressType.ONTRACK}
              name={name}
              checked={!disabled && selected === ProgressType.ONTRACK}
              onChange={onChange}
              ref={ref}
            />
          </div>
          <div>
            <ProgressTypeInput
              id={`${name}-progressing`}
              kind={ProgressType.PROGRESSING}
              disabled={disabled}
              name={name}
              value={ProgressType.PROGRESSING}
              checked={!disabled && selected === ProgressType.PROGRESSING}
              onChange={onChange}
              ref={ref}
            />
          </div>
          <div>
            <ProgressTypeInput
              id={`${name}-off-track`}
              kind={ProgressType.OFFTRACK}
              disabled={disabled}
              value={ProgressType.OFFTRACK}
              checked={!disabled && selected === ProgressType.OFFTRACK}
              onChange={onChange}
              name={name}
              ref={ref}
            />
          </div>
        </Container>
      </Wrapper>
    );
  },
);
