import styled from 'styled-components';
import {FeedbackIllustration} from '@ui/molecules/performance/performance-feedback-illustration';
import React from 'react';

const Wrapper = styled.div`
  padding: 5% 19%;
`;
export const Matrix = ({
  value,
  users,
}: {
  value: {values: number; competence: number};
  users: {
    id: string;
    firstName: string;
    lastName: string;
    avatar: any;
  }[];
}) => {
  const rating = () => {
    if (value.values < 3 && value.competence < 3) {
      return 'lowValuesLowProductivity';
    }

    if (value.values >= 3 && value.competence < 3) {
      return 'highValuesLowProductivity';
    }
    if (value.values < 3 && value.competence >= 3) {
      return 'lowValuesHighProductivity';
    }
    return 'highValuesHighProductivity';
  };

  const computeUsers = users.map((user) => {
    return {
      name: user.firstName + ' ' + user.lastName,
      src: user.avatar,
      id: user.id,
    };
  });
  return (
    <Wrapper>
      {' '}
      <FeedbackIllustration
        quadrantData={null}
        year={new Date().getFullYear()}
        avatars={computeUsers}
        value={value.values >= 3 ? '+ve' : '-ve'}
        competence={value.competence >= 3 ? '+ve' : '-ve'}
        rating={rating()}
        selectedMonths={[10, 8, 9]}
      />
    </Wrapper>
  );
};
