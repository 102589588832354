// DEV, PLEASE ENSURE ALPHABETICAL ORDER OF CLOUDINARY ASSET STRINGS
const cloudinary = {
  adminEmail:
    'https://res.cloudinary.com/dla0k5ne6/image/upload/v1622073565/beam-app-frontend-media-assets/admin-email_j0curk.png',
  adminEmail2x:
    'https://res.cloudinary.com/dla0k5ne6/image/upload/v1622073565/beam-app-frontend-media-assets/admin-email_2x_nnpwvg.png',
  adminEmail3x:
    'https://res.cloudinary.com/dla0k5ne6/image/upload/v1622073565/beam-app-frontend-media-assets/admin-email_3x_l391l6.png',
  avatar1:
    'https://res.cloudinary.com/dla0k5ne6/image/upload/v1616578857/tlqdb6hzaxxb1ovy45tb.jpg',
  calender:
    'https://res.cloudinary.com/dla0k5ne6/image/upload/v1622073565/beam-app-frontend-media-assets/calender_rgckfz.png',
  calender2x:
    'https://res.cloudinary.com/dla0k5ne6/image/upload/v1622073565/beam-app-frontend-media-assets/calender_2x_pz1iaz.png',
  calender3x:
    'https://res.cloudinary.com/dla0k5ne6/image/upload/v1622073565/beam-app-frontend-media-assets/calender_3x_k3bvmd.png',
  emptyGoals:
    'https://res.cloudinary.com/dla0k5ne6/image/upload/v1622073566/beam-app-frontend-media-assets/empty-goals_mrwxvh.png',
  emptyGoals2x:
    'https://res.cloudinary.com/dla0k5ne6/image/upload/v1622073566/beam-app-frontend-media-assets/empty-goals_2x_lkzr6e.png',
  emptyGoals3x:
    'https://res.cloudinary.com/dla0k5ne6/image/upload/v1622073567/beam-app-frontend-media-assets/empty-goals_3x_ywoiwl.png',
  emptyPerformance:
    'https://res.cloudinary.com/dla0k5ne6/image/upload/v1622073566/beam-app-frontend-media-assets/empty-performance_fb9m4g.png',
  emptyPerformance2x:
    'https://res.cloudinary.com/dla0k5ne6/image/upload/v1622073567/beam-app-frontend-media-assets/empty-performance_2x_sxnhml.png',
  emptyPerformance3x:
    'https://res.cloudinary.com/dla0k5ne6/image/upload/v1622073567/beam-app-frontend-media-assets/empty-performance_3x_sojsdw.png',
  emailSent:
    'https://res.cloudinary.com/dla0k5ne6/image/upload/v1622073566/beam-app-frontend-media-assets/email-sent_l36a7v.png',
  emailSent2x:
    'https://res.cloudinary.com/dla0k5ne6/image/upload/v1622073566/beam-app-frontend-media-assets/email-sent_2x_feytlr.png',
  emailSent3x:
    'https://res.cloudinary.com/dla0k5ne6/image/upload/v1622073566/beam-app-frontend-media-assets/email-sent_3x_ptmf7t.png',
  emailUnverified:
    'https://res.cloudinary.com/dla0k5ne6/image/upload/v1622073566/beam-app-frontend-media-assets/email-unverified_xmerqr.png',
  emailUnverified2x:
    'https://res.cloudinary.com/dla0k5ne6/image/upload/v1622073566/beam-app-frontend-media-assets/email-unverified_2x_oxoqfk.png',
  emailUnverified3x:
    'https://res.cloudinary.com/dla0k5ne6/image/upload/v1622073566/beam-app-frontend-media-assets/email-unverified_3x_jdobwq.png',
  googleLogoIcon:
    'https://res.cloudinary.com/dla0k5ne6/image/upload/v1622073567/beam-app-frontend-media-assets/google-logo-icon_qffgfr.png',
  signIn:
    'https://res.cloudinary.com/dla0k5ne6/image/upload/v1622073568/beam-app-frontend-media-assets/sign-in_hazcqh.png',
  sortImage:
    'https://res.cloudinary.com/dla0k5ne6/image/upload/v1622073567/beam-app-frontend-media-assets/sort-image_l7ekwh.png',
  sortImage2x:
    'https://res.cloudinary.com/dla0k5ne6/image/upload/v1622073567/beam-app-frontend-media-assets/sort-image_2x_i846z9.png',
  sortImage3x:
    'https://res.cloudinary.com/dla0k5ne6/image/upload/v1622073567/beam-app-frontend-media-assets/sort-image_3x_lf4f0b.png',
  tourIllustrator:
    'https://res.cloudinary.com/dla0k5ne6/image/upload/v1623802440/beam-app-frontend-media-assets/tour-illustrator_hvp89a.png',
};

export {cloudinary};
