import React from 'react';

export const GoogleMeetIcon = () => {
  return (
    <svg
      width="21"
      height="14"
      viewBox="0 0 21 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.92974 0.250017C2.43934 0.249839 1.96813 0.249668 1.59752 0.329249C1.38533 0.374813 1.15853 0.454519 0.951512 0.606748C0.737473 0.764141 0.587885 0.967097 0.486495 1.19354C0.305459 1.59786 0.266139 2.11628 0.251516 2.66765C0.24145 3.04722 0.243747 3.51515 0.246475 4.0708C0.247881 4.35726 0.249402 4.66704 0.249402 5.00003V9.00003C0.249402 11.6234 2.37605 13.75 4.9994 13.75H12.5451C13.3812 13.7501 14.0719 13.7501 14.6057 13.6908C15.1436 13.631 15.6876 13.4975 16.0922 13.0929C16.4969 12.6882 16.6304 12.1443 16.6901 11.6063C16.7016 11.5032 16.7108 11.3943 16.7183 11.2796L17.262 11.8233C18.3644 12.9257 20.2494 12.1449 20.2494 10.5858V3.41425C20.2494 1.85517 18.3644 1.07437 17.262 2.17681L16.4665 2.97225C15.8529 1.37986 14.3081 0.250034 12.4994 0.250034L2.92974 0.250017ZM18.3226 10.7626L16.7494 9.18937V4.81069L18.3226 3.23747C18.4801 3.07998 18.7494 3.19152 18.7494 3.41425V10.5858C18.7494 10.8085 18.4801 10.9201 18.3226 10.7626ZM1.7494 5.00003C1.7494 4.64103 1.7478 4.31903 1.74635 4.02849C1.74367 3.49268 1.74154 3.06388 1.75099 2.70742C1.76594 2.1436 1.81111 1.91023 1.85332 1.81156C1.86578 1.80732 1.88485 1.80174 1.91244 1.79582C2.10642 1.75416 2.41023 1.75003 2.99947 1.75003H12.4994C14.0182 1.75003 15.2494 2.98125 15.2494 4.50003V9.50003C15.2494 10.3934 15.2483 10.9998 15.1993 11.4406C15.1497 11.8871 15.0645 11.9993 15.0316 12.0322C14.9987 12.0651 14.8864 12.1503 14.44 12.1999C13.9991 12.2489 13.3927 12.25 12.4994 12.25H4.9994C3.20448 12.25 1.7494 10.795 1.7494 9.00003V5.00003Z"
        fill="#363538"
      />
    </svg>
  );
};
