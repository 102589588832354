import {useNavigate} from 'react-router';
import {memo, useCallback, useMemo, useState} from 'react';
import {ItemLoader} from '../../../../ui/organisms/item-loader';
import {activateNotification} from '../../../../ui/molecules/notification/activate-notification';
import {Button} from '../../../../ui/atoms/button';
import {useStoreContext} from '../../../../store/store-context';
import {HorizontalSpacer, VerticalSpacer} from '../../../../ui/atoms/spacer';
import PageLayoutTemplate from '@ui/templates/page-template/page-layout';
import {Body1, Body2, Headline3} from '../../../../ui/atoms/typography';
import {FormWrapper, HeaderWidget, TagName, WidgetWrapper} from './styles';
import {useSearchParams} from 'react-router-dom';
import {FlexRow} from '@ui/style/styles';
import nextId from 'react-id-generator';
import {userName} from '@utils/user-name';
import {ViewUser} from '../view-user/view-user';
import {useWorkspaceUseCase} from '@hooks/workspace-usecase';
import {useQuery} from 'react-query';
import {CcRequestImpl} from '@api/cc-api/cc-request';
import {toJS as proxyToJS} from 'mobx';
import {CcController, ReviewFrameworkProps} from '@api/cc-api/cc-controller';
import {QuestionReview} from './question';
import {TextArea} from '@ui/molecules/field/textArea';
import {Checkbox} from '@ui/atoms/checkbox';
import {QuestionIconWrapper} from '@pages/dashboard/you/you-page.styles';
import {AlertBadge} from '@ui/atoms/icons/alert-badge';
import {UserListCard} from '@ui/molecules/user/list-card';
import {capitalize} from '@utils';
import {parseDateString} from '@utils/date';
import {GoalsRequestImpl} from '@api/goals-api/goals-request';
import {GoalsController} from '@api/goals-api/goals-controller';
import {useFirebaseFetch} from '@hooks/query-hook';
import {checkinName} from '@utils/checkin-name';

type Metric = {
  metric: string;
  id: string;
  goals?: any[];
  competenceCategory?: string;
  score: number;
  note: string;
};
export default function MakeReview({tab}: any) {
  const navigate = useNavigate();
  const {
    usersStore: {users},
  } = useStoreContext();

  const [searchParams] = useSearchParams();

  const peer = searchParams.get('peer') || '';

  const [showViewUser, setShowViewUser] = useState<
    'objective' | 'check-in' | ''
  >('');

  const finalizeReview = searchParams.get('action') === 'finalize-review';

  const userData = users?.find((_user) => _user.id === peer);

  return (
    <>
      <PageLayoutTemplate
        title={finalizeReview ? 'Finalize review' : 'Write review'}
        onClick={() => navigate(-1)}>
        <FormWrapper>
          <div className="form">
            <ReviewComponent setShowViewUser={setShowViewUser} />
          </div>
        </FormWrapper>
      </PageLayoutTemplate>

      {!!showViewUser && (
        <ViewUser
          open={!!showViewUser}
          name={userData ? userName(userData) : ''}
          user={userData?.id || ''}
          type={showViewUser}
          onClose={() => setShowViewUser('')}
        />
      )}
    </>
  );
}

export const ReviewComponent = memo(
  ({
    setShowViewUser,
    _viewResult,
    _finalizeReview,
    removeTitle,
  }: {
    setShowViewUser?: any;
    _viewResult?: boolean;
    _finalizeReview?: boolean;
    removeTitle?: boolean;
  }) => {
    const [submitLoading, setSubmitLoading] = useState(false);
    const navigate = useNavigate();

    const [formData, setformData] = useState<{
      valueRatings: Metric[];
      competenceRatings: Metric[];
    }>({
      valueRatings: [],
      competenceRatings: [],
    });

    const {
      usersStore: {users},
      authStore: {auth},
      ccStore: {ccReviewScale},
      groupStore: {groups},
    } = useStoreContext();

    const [shareWithAdmins, setShareWithAdmins] = useState(false);

    const [feedback, setFeedBack] = useState('');

    const [searchParams] = useSearchParams();

    const type = searchParams.get('type');

    const [managerQuestion, setManagerQuestion] = useState('');

    const [managerQuestionAssessment, setManagerQuestionAssessment] = useState(
      '',
    );

    const viewResult = searchParams.get('view-result') || _viewResult;

    const peer = searchParams.get('peer') || '';

    const id = searchParams.get('id') || '';

    const finalizeReview =
      searchParams.get('action') === 'finalize-review' || _finalizeReview;

    const handleSubmit = async () => {
      setSubmitLoading(true);

      if (formData.competenceRatings && formData.valueRatings) {
        const competenceRatings = formData.competenceRatings.map((ratings) => {
          return {
            ...ratings,
            score: ratings.score,
            goals: ratings?.goals?.map((goal: any) => ({
              ...goal,
              note: goal.note || undefined,
            })),
            competenceCategory: ratings.competenceCategory,
            note: ratings.note || undefined,
            isValueRating: undefined,
            id: undefined,
          };
        });

        const response = await controller.writeReviewCycles({
          user: peer || '',
          reviewCycle: id || '',
          reviewerType: type || '',
          competenceRatings: competenceRatings,
          managerQuestionResponse: managerQuestionAssessment
            ? {
                question: managerQuestion,
                response: managerQuestionAssessment,
              }
            : undefined,
          valueRatings: formData.valueRatings.map((ratings) => {
            return {
              ...ratings,
              isValueRating: undefined,
              note: ratings.note || undefined,

              id: undefined,
            };
          }),
        });

        setSubmitLoading(false);
        if (response) {
          activateNotification({
            title: 'success',
            content: 'Review submitted',
            kind: 'success',
          });

          navigate(-1);
        }
      }
    };

    const fetchWrittenReviews = async () => {
      const response = await controller.fetchWrittenReview({
        reviewCycle: id,
        user: peer,
      });
      return response;
    };

    const {data: writtenReviews} = useQuery(
      ['written-review', id, 'finalize-review'],
      () => fetchWrittenReviews(),
      {
        enabled: !!finalizeReview,
      },
    );

    const request = useMemo(() => new CcRequestImpl(), []);
    const controller = useMemo(() => new CcController(request), [request]);

    const goalsRequest = useMemo(() => new GoalsRequestImpl(), []);
    const goalsController = useMemo(() => new GoalsController(goalsRequest), [
      goalsRequest,
    ]);

    const PATH = `cc-reviews/${id}/`;

    const {
      data: firebaseFrameworks = {
        frameworks: [],
      },
    } = useFirebaseFetch(PATH);

    const fetchData = useCallback(async () => {
      const response = await controller.fetchReviewCycleById(id || '');

      if (response) {
        const frameworks: ReviewFrameworkProps = firebaseFrameworks.frameworks
          ?.length
          ? firebaseFrameworks.frameworks
          : await controller
              .fetchCcFramework()
              .then((frameworks) =>
                frameworks?.filter((framework) =>
                  response.frameworks.includes(framework.id),
                ),
              );

        const dateRange = parseDateString(response.period);

        const goalsReponse = await goalsController.fetchAllGoals(
          1,
          {
            goalFilter: 'active',
            member: peer,
            startDate: dateRange?.starts,
            endDate: dateRange?.ends,
          },
          10000,
        );

        const includeGroups = (goalsResponse: any) => {
          const groupsIncluded = goalsResponse?.map((goal: any) => {
            if (goal.goalType === 'group') {
              const group = goal.group.map((grp: string) => {
                return proxyToJS(groups.find((_group) => _group.id === grp));
              });

              return {
                ...goal,
                group: group,
              };
            } else
              return {
                ...goal,
              };
          });
          return groupsIncluded;
        };

        return {
          ...response,
          goals: includeGroups(goalsReponse.goals),
          frameworks,
        };
      }
      return;
    }, [
      controller,
      id,
      firebaseFrameworks?.frameworks,
      goalsController,
      peer,
      groups,
    ]);

    const {data: cycleData, isLoading} = useQuery(
      ['write-review', id, peer],
      () => fetchData(),
      {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
      },
    );

    const updateRating = (
      id: string,
      score: number,
      note: string,
      field: keyof typeof formData,
      goals?: any[],
    ) => {
      if (formData) {
        setformData((prev) => ({
          ...prev,
          [field]: prev[field]?.map((options) => {
            if (options.id === id) {
              return {
                ...options,
                goals,
                note,
                score,
              };
            }
            return options;
          }),
        }));
      }
    };

    const computeQuestions = useMemo(() => {
      const options: {
        label: string;
        questions: {question: string; id: string}[];
      }[] = [];
      const companyValues: string[] =
        auth?.user?.workspace?.settings?.companyValues?.values || [];

      if (companyValues && companyValues.length > 0) {
        options.push({
          label: 'Values',
          questions: companyValues.map((value) => {
            return {
              question: value,
              id: nextId('question'),
            };
          }),
        });
      }
      if (cycleData) {
        cycleData.frameworks?.forEach((framework) => {
          framework.competenceMetrics.forEach((competence) => {
            options.push({
              label: competence.name,
              questions: competence.components.map((value) => {
                return {
                  question: value.name,
                  useGoals: value.parameter === 'goals',
                  id: nextId('question'),
                };
              }),
            });
          });
        });

        const mangerQuestionExists = cycleData.frameworks?.find(
          (framework) => framework.managerQuestion,
        );
        if (mangerQuestionExists)
          setManagerQuestion(mangerQuestionExists.managerQuestion || '');
      }

      const _options = options.flatMap((option) => {
        return option.questions.map((question) => {
          return {
            metric: question.question,
            id: question.id,
            competenceCategory:
              option.label !== 'Values' ? option.label : undefined,
            isValueRating: option.label === 'Values',
            score: 0,
            note: '',
          };
        });
      });

      const computeOptions = {
        valueRatings: _options.filter((option) => !!option.isValueRating),
        competenceRatings: _options.filter((option) => !option.isValueRating),
      };

      setformData(computeOptions);
      return options;
    }, [auth?.user?.workspace?.settings?.companyValues?.values, cycleData]);

    const disableSubmitButton = useMemo(() => {
      const isManagerType = type === 'manager';
      const isManagerQuestionValid = isManagerType && managerQuestion;

      if (isManagerQuestionValid) return managerQuestionAssessment.length > 29;

      const valuesAreRated = formData.valueRatings.every(
        (rating) => rating.score,
      );

      const getMetricFrameworks = cycleData?.frameworks?.flatMap((framework) =>
        framework.competenceMetrics.flatMap((metric) => metric.components),
      );

      const competenceValidation = formData.competenceRatings
        ?.map((rating) => {
          const ratings = getMetricFrameworks?.find(
            (framework) => framework.name === rating.metric,
          );

          if (rating?.score && ratings?.parameter === 'default') return true;

          const isGoalsParameter = ratings?.parameter === 'goals';
          const noGoalsInCycle = !cycleData?.goals.length;

          if (isGoalsParameter && noGoalsInCycle) return true;

          if (isGoalsParameter && cycleData?.goals.length) {
            return rating.goals?.every((goal) => goal.score);
          }

          return false;
        })
        .every((framework) => framework);

      return valuesAreRated && competenceValidation;
    }, [
      type,
      managerQuestion,
      managerQuestionAssessment.length,
      formData.valueRatings,
      formData.competenceRatings,
      cycleData?.frameworks,
      cycleData?.goals.length,
    ]);

    const {
      isPerformanceActive,
      isEngagementActive,
      isCheckinsEnabled,
    } = useWorkspaceUseCase();

    const viewUserOptions = useMemo(() => {
      const options = [];

      if (isPerformanceActive) {
        options.unshift({
          value: 'objective',
          label: 'View Goals',
        });
      }

      if (type === 'manager') {
        options.unshift({
          value: 'self-review',
          label: 'View Self-review',
        });
      }

      if (isEngagementActive && isCheckinsEnabled) {
        options.push({
          value: 'check-in',
          label: `View ${capitalize(checkinName())}s`,
        });
      }
      return options;
    }, [isPerformanceActive, isEngagementActive, isCheckinsEnabled, type]);

    const reviewFor = users.find((user) => user.id === peer);

    const managerWrittenResponse = writtenReviews?.find(
      (review: any) =>
        review.managerQuestionResponse && review?.reviewerType === 'manager',
    );

    const {data: ccResult, isLoading: ccResultLoading} = useQuery(
      ['cc-results', id, peer],
      () =>
        controller.fetchCcResults(id, {
          user: peer,
        }),
      {
        enabled: !!finalizeReview,
      },
    );

    const [finalizeLoader, setFinalizeLoader] = useState(false);

    const handleFinalizeReview = async () => {
      setFinalizeLoader(true);

      const findReview = writtenReviews.find(
        (review: {reviewerType: string}) => review.reviewerType === 'manager',
      );

      if (findReview) {
        const response = await controller.updateWrittenReviewCycles(
          {
            finalized: true,
            feedback: feedback || undefined,
            shareFeedback: shareWithAdmins,
          },
          findReview.id,
        );
        setFinalizeLoader(false);

        response && navigate(-1);
      }
      setFinalizeLoader(false);
    };

    const totalPercentage =
      ccResult && (ccResult?.averageScore / ccReviewScale.length) * 100;

    const SubmitButton = (
      <Button
        width="full"
        isLoading={submitLoading}
        disabled={!disableSubmitButton}
        onClick={() => handleSubmit()}>
        Submit
      </Button>
    );

    return (
      <>
        <VerticalSpacer size="40px" />
        {isLoading || ccResultLoading ? (
          <div>
            <ItemLoader />
          </div>
        ) : (
          <div>
            {finalizeReview && !removeTitle ? (
              <>
                <Headline3>
                  <span style={{textTransform: 'capitalize'}}>
                    {' '}
                    {cycleData?.cycleName}
                  </span>{' '}
                  review for {reviewFor?.firstName}
                </Headline3>
                <VerticalSpacer size="24px" />
              </>
            ) : null}
            <HeaderWidget
              style={{padding: finalizeReview ? '12px 24px' : '24px'}}>
              <UserListCard
                tooltip={true}
                userId={reviewFor?.id || ''}
                type="secondary"
                textStyle={{fontWeight: finalizeReview ? 500 : 600}}
                TextComponent={finalizeReview ? Body2 : Body1}
                reviewer={
                  reviewFor?.reviewer?.id !== undefined
                    ? reviewFor?.reviewer?.id
                    : reviewFor?.reviewer !== undefined
                    ? reviewFor?.reviewer
                    : null
                }
                avatar={reviewFor?.avatar ? reviewFor?.avatar.url : ''}
                name={
                  finalizeReview
                    ? 'Overall Rating'
                    : reviewFor?.firstName && reviewFor?.lastName
                    ? `${reviewFor.firstName} ${reviewFor.lastName}`
                    : reviewFor?.email || ''
                }
              />
              {finalizeReview ? (
                <TagName>
                  <Body2 weight="bold">
                    {' '}
                    {totalPercentage}% -{' '}
                    {capitalize(ccResult?.averageOverallRating || '')}
                  </Body2>
                </TagName>
              ) : (
                <FlexRow>
                  {viewUserOptions.map((options, idx) => (
                    <Button
                      key={idx}
                      onClick={() => {
                        setShowViewUser(options.value as any);
                      }}
                      className="manager-button"
                      style={{marginRight: '12px', padding: '8px 12px'}}
                      kind="secondary">
                      {options.label}
                    </Button>
                  ))}
                </FlexRow>
              )}
            </HeaderWidget>
            <VerticalSpacer size="32px" />
            {computeQuestions.map((value, idx) => (
              <QuestionReview
                item={value}
                type={type}
                idx={idx}
                showWrittenReview={!!finalizeReview}
                goals={cycleData?.goals}
                writtenReviews={writtenReviews}
                onChange={(data) => {
                  updateRating(
                    data.id,
                    data.rating,
                    data.comment,
                    data.isValue ? 'valueRatings' : 'competenceRatings',
                    data?.goals,
                  );
                }}
                user={users?.filter((user: any) => user.id === peer)[0]}
              />
            ))}
          </div>
        )}

        <VerticalSpacer size="20px" />
        {finalizeReview ? (
          <>
            {managerWrittenResponse && (
              <>
                <WidgetWrapper>
                  <FlexRow>
                    <Body2 weight="bold">
                      {capitalize(
                        managerWrittenResponse?.managerQuestionResponse
                          .question,
                      )}
                    </Body2>
                  </FlexRow>
                  <VerticalSpacer size="8px" />
                  <div className="body-wrapper">
                    <Body1>
                      {managerWrittenResponse?.managerQuestionResponse.response}
                    </Body1>
                  </div>
                </WidgetWrapper>
                <VerticalSpacer size="20px" />
              </>
            )}
            {!viewResult && (
              <WidgetWrapper>
                <div style={{padding: '8px'}}>
                  <Headline3>Do you have any additional feedback?</Headline3>
                  <VerticalSpacer size="20px" />
                  <TextArea
                    placeholder="Write summary here"
                    onChange={(e: any) => setFeedBack(e.target.value)}
                    value={feedback}
                    style={{height: '100px'}}
                  />
                  <VerticalSpacer size="20px" />
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <Checkbox
                      onChange={(e: any) =>
                        setShareWithAdmins(e.target.checked)
                      }
                    />
                    <HorizontalSpacer size="8px" />
                    <Body2 weight="bold">Only share with review admin</Body2>

                    <QuestionIconWrapper tooltip={true}>
                      <AlertBadge />
                      <span className="tooltip">
                        If clicked, only Review admin in your workspace would be
                        able to see the feedback
                      </span>
                    </QuestionIconWrapper>
                  </div>
                  <VerticalSpacer size="16px" />
                  <Button
                    isLoading={finalizeLoader}
                    onClick={handleFinalizeReview}
                    width="full">
                    Finalize review
                  </Button>
                </div>
              </WidgetWrapper>
            )}
          </>
        ) : (
          <>
            {managerQuestion && type === 'manager' ? (
              <>
                <WidgetWrapper>
                  <div style={{padding: '8px'}}>
                    <Headline3>{managerQuestion}</Headline3>
                    <VerticalSpacer size="20px" />
                    <TextArea
                      placeholder="Write answer here (min. 30 characters)"
                      onChange={(e: any) =>
                        setManagerQuestionAssessment(e.target.value)
                      }
                      value={managerQuestionAssessment}
                      style={{height: '100px'}}
                    />
                    <VerticalSpacer size="20px" />

                    {SubmitButton}
                  </div>
                </WidgetWrapper>
              </>
            ) : (
              SubmitButton
            )}
          </>
        )}
      </>
    );
  },
);
export interface Rating {
  metric: string;
  score: number;
  note: string;
  goals?: {goal: string; note?: string; score: number}[];
  competenceCategory?: string;
  _id: string;
}
