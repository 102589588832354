import styled, {css} from 'styled-components';
import {switchProp} from 'styled-tools';
import {Helper} from '../helper';
import {getColorFromTheme} from '../../ui-utils';
import {Label} from '../label';
import {InputState} from '../../interface';

interface InputProps {
  state?: InputState;
}

const helperStyle = css`
  & ~ ${Helper} {
    display: block !important;
    color: ${getColorFromTheme('red400')};
  }
`;

const label = css`
  & + ${Label} {
    position: absolute;
    top: 0;
    pointer-events: none;
  }

  &:invalid:not(:focus):not(:placeholder-shown) {
    & + ${Label} {
      color: ${getColorFromTheme('red400')};
    }
  }
`;

export const Input = styled.textarea<InputProps>`
  padding: 5px 14px;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  border-radius: 7px;
  color: ${getColorFromTheme('textDark')};
  width: 100%;
  display: block;
  height: 120px;
  background-color: ${getColorFromTheme('white')};
  transition: 200ms ease all;
  border: 1px solid;
  border-color: ${getColorFromTheme('borderDark')};
  resize: none;
  &:read-only {
    cursor: not-allowed;
    border-color: #ededf2;
    color: #5e5e73;
  }

  &::placeholder {
    color: ${getColorFromTheme('textBody')};
  }

  &:hover:not(:invalid) {
    border-color: ${getColorFromTheme('purple200')};

    box-shadow: 0 0 0 4px ${getColorFromTheme('purple200')};
  }

  &:hover:invalid {
    border-color: ${getColorFromTheme('red400')};
    box-shadow: 0 0 0 2px ${getColorFromTheme('red400')};
  }

  &:disabled {
    cursor: not-allowed;
    border-color: #cecede;
    background: #f6f6f8;
    color: #999999;
  }
  &:disabled::placeholder {
    color: #999999;
  }

  &:disabled:hover {
    border-color: #cecede;
  }

  &:focus:not(:invalid) {
    outline: 0;
    box-shadow: 0 0 0 4px ${getColorFromTheme('purple200')};

    border-color: ${getColorFromTheme('purple300')};
    border-width: 1px;
  }

  &:focus:invalid {
    outline: 0;
    border-color: ${getColorFromTheme('red400')};
    border-width: 1px;
    box-shadow: 0 0 0 2px ${getColorFromTheme('red400')};
    color: ${getColorFromTheme('red400')};
  }

  &:invalid:not(:focus):not(:placeholder-shown) {
    box-shadow: 0 0 0 2px ${getColorFromTheme('red400')};
    color: ${getColorFromTheme('red400')};
    ${helperStyle}
  }

  ${label};

  ${switchProp('state', {
    error: css`
      color: ${getColorFromTheme('red400')} !important;
      & + ${Label} {
        color: ${getColorFromTheme('red400')};
      }
      border-color: ${getColorFromTheme('red400')} !important;
      ${helperStyle}

      &:focus {
        & ~ ${Helper} {
          color: ${getColorFromTheme('red400')};
        }
      }
    `,
  })}
`;
export const TextAreaNoBorder = styled.textarea<InputProps>`
  padding: 5px 14px;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  border-radius: 7px;
  color: ${getColorFromTheme('textDark')};
  width: 100%;
  display: block;
  // height: 120px;

  transition: 200ms ease all;
  border: 0px solid;
  border-color: none;
  resize: none;
  &:read-only {
    cursor: not-allowed;
    border-color: #ededf2;
    color: #5e5e73;
  }

  &::placeholder {
    color: ${getColorFromTheme('textBody')};
  }

  &:disabled {
    cursor: not-allowed;
    border-color: #cecede;
    background: #f6f6f8;
    color: #999999;
  }
  &:disabled::placeholder {
    color: #999999;
  }

  &:disabled:hover {
    border-color: #cecede;
  }

  &:invalid:not(:focus):not(:placeholder-shown) {
    box-shadow: 0 0 0 2px ${getColorFromTheme('red400')};
    color: ${getColorFromTheme('red400')};
    ${helperStyle}
  }

  ${label};

  ${switchProp('state', {
    error: css`
      color: ${getColorFromTheme('red400')} !important;
      & + ${Label} {
        color: ${getColorFromTheme('red400')};
      }
      border-color: ${getColorFromTheme('red400')} !important;
      ${helperStyle}

      &:focus {
        & ~ ${Helper} {
          color: ${getColorFromTheme('red400')};
        }
      }
    `,
  })}
`;
