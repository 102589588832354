import {useStoreContext} from '@store/store-context';
import {Label} from '@ui/atoms/label';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {DatePicker} from '@ui/molecules/date-picker';
import {UserSelectField} from '@ui/molecules/select/user';
import dayjs from 'dayjs';
import {useState} from 'react';
import {CreateMeetingNoteData} from './meeting-hook';
import {MultiOptionsSelectField} from '@ui/molecules/select/multi-options';
import {ManageTags} from '../create-meetings/tags';
import {writeFirebaseData} from '@utils/firebase-handler';
import {TextField} from '@ui/molecules/field/textfield';
import {useMeetingTags} from '../useMeetingTags';
import {SelectField} from '@ui/molecules/select/selectfield';

export interface FormProps {
  values: CreateMeetingNoteData;
  handleFormValueChange: (value: string | any, name: string) => void;
}

export const MeetingForm = ({values, handleFormValueChange}: FormProps) => {
  const {
    authStore: {auth},
    usersStore: {users},
  } = useStoreContext();

  const MEETING_WITH = () => {
    const computeValue = (user: {
      firstName: string;
      lastName: string;
      email: string;
      avatar: {url: string};
      id: string;
    }) =>
      isTeamMeetings
        ? {
            label:
              user && user.firstName
                ? user.firstName + ' ' + user.lastName
                : user.email + ' (pending invitation)',
            value: user.id,
          }
        : {
            label: {
              avatar: {
                name:
                  user && user.firstName
                    ? user.firstName + ' ' + user.lastName
                    : user.email + ' (pending invitation)',
                src: user.avatar && user.avatar.url ? user.avatar.url : '',
              },
              id: user.id,
              name:
                user && user.firstName
                  ? user.firstName + ' ' + user.lastName
                  : user.email + ' (pending invitation)',
            },
            value: user.id,
          };

    if (auth.user.isReviewer) {
      const reportees = new Set();

      return users
        .filter((user) => {
          if (user.id === auth.user.id) {
            return false;
          }

          const getReviewer =
            typeof user.reviewer === 'string'
              ? user.reviewer
              : user.reviewer?.id;

          if (getReviewer === auth.user.id) {
            reportees.add(user.id);
            return true;
          }

          if (reportees.has(getReviewer)) {
            return true;
          }
          if (user.isReviewer) {
            return true;
          }
          return false;
        })
        .map((user) => {
          return computeValue(user);
        });
    } else {
      return users
        .filter((user) => user.isReviewer && user.id !== auth.user.id)
        .map((user) => {
          return computeValue(user);
        });
    }
  };

  const isTeamMeetings = true;

  const UsersField = isTeamMeetings ? MultiOptionsSelectField : UserSelectField;

  const [manageTags, setManageTags] = useState(false);

  const {tags} = useMeetingTags();

  const UsersDefaultfield = isTeamMeetings
    ? Array.isArray(values.participant)
      ? values.participant.map((participant) => ({
          value: participant,
        }))
      : values.participant
    : values.participant[0];

  return (
    <div>
      {isTeamMeetings && (
        <>
          <Label>What's the topic of this meeting?</Label>
          <VerticalSpacer size="8px" />

          <TextField
            inputStyle={{borderRadius: '10px'}}
            value={values.title}
            placeholder="e.g. Weekly catch up "
            onChange={(event) => {
              handleFormValueChange(event.target.value, 'title');
            }}
          />
        </>
      )}
      <Label>When was this meeting held?</Label>
      <VerticalSpacer size="8px" />

      <DatePicker
        label={'Date'}
        dateAlign="left"
        placeholder={values.startDate ? '' : 'Choose'}
        onChange={(date) => handleFormValueChange(date, 'startDate')}
        value={values.startDate}
        disabledAfter={dayjs().add(1).format()}
      />

      <VerticalSpacer size="24px" />

      {isTeamMeetings && (
        <div className="relative">
          <Label>
            Tags <span className="text-[#5F5F8C] font-normal ">(optional)</span>
          </Label>
          <VerticalSpacer size="8px" />

          <SelectField
            placeholder="# - add tag"
            performance
            type="dropdownOnly"
            borderRadius="10px"
            excludeSearchBox
            showCancelIcon={false}
            defaultValue={values.label?.[0]}
            noOptionMessage={'No option Found'}
            groupDisplayLength={3}
            onClickCustomOption={() => {
              setManageTags(true);
            }}
            customOption
            customOptionName="Manage tags"
            options={[
              ...(tags
                ? tags.map((tag) => ({
                    value: tag.tag,
                    label: tag.tag,
                  }))
                : []),
              {
                label: 'Custom',
                value: 'custom-option',
              },
            ]}
            onChange={(data: {value: string; label: any | null}) => {
              handleFormValueChange(data?.value ? [data?.value] : [], 'label');
            }}
          />
        </div>
      )}

      <Label>Who did the meeting involve</Label>

      <UsersField
        name="participant"
        defaultValue={UsersDefaultfield}
        onChange={(data: {value: string}) => {
          if (Array.isArray(data)) {
            handleFormValueChange(
              data?.map((option) => option.value),
              'participant',
            );

            return;
          }
          handleFormValueChange([data?.value], 'participant');
        }}
        placeholder={`Select member${isTeamMeetings ? 's' : ''}`}
        searchPlaceholder={'Search'}
        options={MEETING_WITH()}
        fieldNotFoundPlaceHolder={(searchTerm?: string) =>
          `Oops! Seems there is no ${searchTerm} found in this workspace`
        }
      />

      {manageTags && (
        <ManageTags
          open={manageTags}
          onClose={() => setManageTags(false)}
          tags={tags}
          onSubmit={(tag) => {
            writeFirebaseData(`tags`, tag);
          }}
        />
      )}
    </div>
  );
};
