import {FilterComponent} from '@pages/dashboard/reporting/reporting-pages/admin-analytics/analytics-shared-components/filterbox/filterbox';
import {useStoreContext} from '@store/store-context';
import {Body2} from '@ui/atoms/typography';
import {DropdownCustom} from '@ui/molecules/dropdown-custom';
import {MultiOptionsSelectFieldGroup} from '@ui/molecules/select/multi-options/multi-options';
import {SelectField} from '@ui/molecules/select/selectfield';
import {capitalize} from '@utils';
import {cfv} from '@utils/framework';
import {useBreakpoints} from '@utils/use-breakpoints';
import {userName} from '@utils/user-name';
import {groupUsers} from '@utils/users';
import React, {useMemo, useState} from 'react';

interface GoalFilterProps {
  isKpi?: boolean;
  onChange: (state: string, users: string[]) => void;
  value: {users: string[]; state: string};
}

export const GoalFilter = ({isKpi, onChange, value}: GoalFilterProps) => {
  const {isXs} = useBreakpoints();

  const [showApply, setShowApply] = useState(false);

  const name = isKpi ? 'KPIs' : capitalize(cfv().g_oals);

  const [state, setState] = useState(value.state || '');

  const [selectedUsers, setSelectedUsers] = useState<string[]>(
    value.users || [],
  );

  const findUsers = (_users: string[]) => {
    return users
      .filter((user) => _users.includes(user.id))
      .map((user) => ({name: userName(user)}));
  };

  const {
    usersStore: {users},
    authStore: {auth},
  } = useStoreContext();

  const formatUsers = useMemo(() => {
    return groupUsers(auth.user, users).map((users) => ({
      name: users.name,
      data: users.groups.map((user) => ({value: user.id, label: user.name})),
    }));
  }, [auth.user, users]);

  const goalStateOptions = useMemo(() => {
    const opts = [
      {
        label: `All  ${name}`,
        value: '',
      },
      {
        label: `Active `,
        value: 'active',
      },
      {
        label: `Ended `,
        value: 'ended',
      },
      {
        label: `Upcoming `,
        value: 'upcoming',
      },
      {
        label: `Archived `,
        value: 'archived',
      },
    ];

    return opts;
  }, [name]);

  const handleApply = (_: any, handleClose: () => void) => {
    setShowApply(false);
    handleClose();
    onChange(state, selectedUsers);
  };

  return (
    <div>
      <DropdownCustom
        collapseOnClick={true}
        menu={(handleClose: () => void) => (
          <FilterComponent
            title="Filters"
            boxStyle={{
              right: isXs ? undefined : 0,
              left: 0,
              zIndex: 2,
            }}
            setView={() => null}
            showFilter={true}
            handleClose={handleClose}
            showApplyButton={{
              show: showApply,
              action: handleApply,
            }}
            type=""
            resetFilter={() => {
              handleClose();
              setSelectedUsers([]);
              setState('active');
              onChange('active', []);
            }}>
            <div>
              <Body2 weight="semibold" className="mb-2">
                {name} state
              </Body2>
              <SelectField
                options={goalStateOptions}
                placeholder={'Select option'}
                defaultValue={state}
                onChange={(data: {value: React.SetStateAction<string>}) => {
                  setShowApply(true);
                  setState(data?.value);
                }}
                borderRadius={10}
              />
              <Body2 weight="semibold" className="mb-2">
                {name} assignee
              </Body2>
              <MultiOptionsSelectFieldGroup
                placeholder="Select Assignee"
                searchPlaceholder="Search members"
                groupDisplayLength={1}
                reporting
                defaultValue={selectedUsers?.map((group) => ({
                  label: userName(users.find((user) => user.id === group)),
                  value: group,
                }))}
                onChange={(data: {label: string; value: string}[]) => {
                  setShowApply(true);
                  setSelectedUsers(data.map((group) => group.value));
                }}
                options={formatUsers}
                theme="users"
                borderRadius={10}
                feature="invite"
              />
            </div>
          </FilterComponent>
        )}
        shouldNotIncludeWrapper={true}
        customComponent={(handleOpen: (event: any) => void, open: boolean) => (
          <>
            <div>
              <button
                onClick={handleOpen}
                className="flex flex-row items-center">
                <Body2 weight="bold" kind="purple300" className="capitalize">
                  Filtered by: {value.state ? value.state : 'All'},{' '}
                  {!value.users.length
                    ? `My ${name}`
                    : `${findUsers(value.users)[0].name} ${
                        value.users.length > 1
                          ? `+ ${value.users.length - 1} others`
                          : ''
                      }`}
                </Body2>
              </button>
            </div>
          </>
        )}
        customDropdownWrapperStyles={{
          top: '130%',
          boxShadow: '0px',
          left: 0,
          padding: 0,
          overflowY: 'auto',
          background: 'none',
          border: 0,
        }}
      />
    </div>
  );
};
