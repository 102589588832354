import {GoalsController} from '@api/goals-api/goals-controller';
import {GoalsRequestImpl} from '@api/goals-api/goals-request';
import {useStoreContext} from '@store/store-context';
import {Button} from '@ui/atoms/button';
import {CustomColorIcon} from '@ui/atoms/color-icons';
import {CancelIcon} from '@ui/atoms/icons';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {Headline2, Body1} from '@ui/atoms/typography';
import {ModalCard} from '@ui/layouts/modal-card';
import {BackAction} from '@ui/molecules/back-action';
import {Modal} from '@ui/molecules/modal';
import {Flex} from '@ui/style/styles';
import React from 'react';

export const ArchiveGoal = ({handleContinue}: {handleContinue: () => void}) => {
  const {goalsState} = useStoreContext();
  return (
    <Modal open={true} onClose={() => {}}>
      <div style={{maxWidth: '500px', margin: 'auto'}}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}>
          <BackAction
            width="md"
            icon={<CancelIcon />}
            onClick={() => {
              goalsState.setArchiveModal('', false, '', '');
            }}
            title="Close"
          />
        </div>
        <VerticalSpacer size="16px" />
        <ModalCard align="center" title={``}>
          <Flex alignItems="center" justifyContent="center" flexDir="column">
            <VerticalSpacer size="16px" />
            <Flex alignItems="center" justifyContent="center">
              <CustomColorIcon
                color="#F7E4E4"
                width="80px"
                margin={0}
                height="80px"
              />
            </Flex>
            <VerticalSpacer size="20px" />
            <span>
              <Headline2 align="center">
                Archive {goalsState.goalFramework}?
              </Headline2>
            </span>
            <VerticalSpacer size="16px" />

            <Body1 align="center" kind="textBody">
              Are you sure you want to archive your {goalsState.goalFramework}
              {'   '}
              <span style={{color: '#585ADF', fontWeight: 500}}>
                {goalsState.archiveName}
              </span>
            </Body1>
            <VerticalSpacer size="32px" />
            <Button
              onClick={() => {
                const request = new GoalsRequestImpl();
                const controller = new GoalsController(request);
                controller.archiveGoal({}, goalsState.archiveId);
                goalsState.setArchiveModal('', false, '', '');
              }}
              width="full">
              Confirm
            </Button>
          </Flex>
        </ModalCard>
      </div>
    </Modal>
  );
};
