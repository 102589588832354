import styled, {css} from 'styled-components';
import styledMap from 'styled-map';
import {
  InnerWrapper,
  RadioInput,
  Wrapper,
} from '../../../../../../../ui/atoms/radio';
import {ifProp} from 'styled-tools';
import {Checkbox} from '@ui/atoms/checkbox';
import {
  HorizontalSpacer,
  VerticalSpacer,
} from '../../../../../../../ui/atoms/spacer';
import {Body1, Body2} from '../../../../../../../ui/atoms/typography';
import {down} from 'styled-breakpoints';
import {getColorFromTheme} from '../../../../../../../ui/ui-utils';

interface StackProps {
  justifyContent?: 'center' | 'space-btw';
  alignItem?: 'center' | 'start' | 'end';
}
export const BreadcrumbWrapper = styled.div`
  border-bottom: 1px solid ${getColorFromTheme('borderLight')};
  padding: 24px 16px 10px;
`;

export const TableContainer = styled.div`
  background-color: ${getColorFromTheme('white')};
  border: 1px solid #ededf2;
  border-radius: 10px;
`;

export const ItemLoaderWrapper = styled.div`
  padding: 28px 12px 32px 22px;
  justify-content: center;
  width: 100%;
  border-top: 1px solid #ededf2;
`;

export const ItemWrapperContainer = styled.div`
  height: 60vh;
  width: 60vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EmptyWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 80px 24px;
  border-top: 1px solid ${getColorFromTheme('borderLight')};
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 500px) {
    padding: 40px 24px;
  }
`;

export const ItemWrapper = styled.div<{addBorderRadius?: boolean}>`
  display: grid;

  grid-template-columns: 90% 8%;
  padding: 16px;
  border-top: 1px solid ${getColorFromTheme('borderLight')};
  border-radius: ${ifProp('addBorderRadius', '10px 10px 0px 0px', '')}
  align-items: center;
  
  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const FrameworkItem = styled.div<{addBorderRadius?: boolean}>`
  display: grid;

  grid-template-columns: 88.3% 9% 0.5% 2%;
  padding: 16px;
  border-top: 1px solid ${getColorFromTheme('borderLight')};
  border-radius: ${ifProp('addBorderRadius', '10px 10px 0px 0px', '')};
  align-items: center;
  @media (max-width: 500px) {
    grid-template-columns: 70.3% 25% 1% 4%;
  }
  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const SecondRowWrapper = styled.div`
  width: 100%;
  overflow: auto;

  @media (max-width: 900px) {
    min-width: 800px;
  }
  @media (max-width: 500px) {
    min-width: 100%;
    overflow: hidden;
  }
`;

export const HeadlineWrapper = styled.div`
  display: grid;
  width: 100%;

  grid-template-columns: 89% 8%;
  padding-left: 16px;
  @media (max-width: 500px) {
    grid-template-columns: 86% 8%;
  }
`;

export const HeadlineWrapperContainer = styled.div`
  display: block;

  padding: 16px 16px 12px 0px;
  background-color: ${getColorFromTheme('backgroundLight')};
  white-space: nowrap;
  height: 52px;
`;

export const Stack = styled.div<StackProps>`
  display: flex;
  width: 100%;
  justify-content: ${styledMap('justifyContent', {
    default: 'flex-start',
    center: 'center',
    'space-btw': 'space-between',
  })};

  align-items: ${styledMap('alignItem', {
    default: 'center',
    center: 'center',
    start: 'flex-start',
    end: 'flex-end',
  })};
  .boxDiv {
    width: 45%;
    height: 56px;
  }
  ${down('md')} {
    flex-direction: column;
    align-items: flex-start;
    .boxDiv {
      width: 100%;
    }
  }
`;

export const Button = styled.button<{isLoading?: boolean}>`
  border: 1px solid #cecede;
  padding: 10px 15px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  font-style: medium;
  flex-wrap: nowrap;
  &:disabled {
    //color: ${getColorFromTheme('white')};
    opacity: 0.4;
  }
`;

export const SwitchWrapper = styled.div`
  display: flex;
`;

export const CollapseWrapper = styled.div<{collapse: boolean}>`
  width: 100%;
  height: 0;
  overflow: hidden;
  ${({collapse}) =>
    !collapse &&
    css`
      height: max-content;
    `}
`;

export const PerformanceWrapper = styled.div`
  width: 100%;

  &.disabled-form {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
export const Block = styled.section`
  border: 1px solid ${getColorFromTheme('borderLight')};
  border-radius: 10px;
`;

export const HintBlock = styled.div<{height?: string}>`
  width: 100%;
  background: #f6f6f8;
  border-radius: 10px;
  text-align: center;
  font-family: Graphik;
  font-weight: 400;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({height}) => height};
  color: #1e1e2f;
  font-weight: 400;
  font-size: 14px;
  line-height: 146%;
  a {
    display: inline-block;
    margin-left: 5px;
    color: #585adf;
    text-decoration: underline;
  }
`;
export const BlockContent = styled.div`
  border-top: 1px solid ${getColorFromTheme('borderLight')};
  border-radius: 7px;
  padding: 24px 26px;
`;

export const BlockHeader = styled.div`
  padding: 16px 24px;
  width: 100%;
  display: inline-block;
`;

export const Box = styled.span`
  flex: 1 1 auto;
  text-align: center;
  width: 100%;
  margin: 5px;
`;
export const CustomFontWeight = styled.span`
  font-weight: 600;
  color: #1e1e2f;
`;

const VisibleCheckBoxStyled = styled.label<{active: boolean}>`
  padding: 16px;
  border: 1px solid ${({active}) => (active ? '#585ADF' : '#ededf2')};
  border-radius: 10px;
  text-align: left;
  cursor: pointer;
  display: block;
  ${down('md')} {
    display: flex;
    & div {
      margin-right: 6px;
    }
  }
`;

interface VP {
  name: string;
  value: string;
  title: string;
  variant?: string;
  description: string;
  onCheck: any;
  defaultValue: string;
}
export const VisibleCheckBox = (props: VP) => {
  return (
    <VisibleCheckBoxStyled active={props.defaultValue === props.value}>
      <Wrapper>
        <RadioInput
          name={props.name}
          value={props.value}
          onChange={props.onCheck}
          type="radio"
          id={props.value}
          checked={props.defaultValue === props.value}
        />
        <InnerWrapper />
      </Wrapper>
      <VerticalSpacer size="10px" />
      <div>
        <Body1
          kind={
            props.defaultValue === props.value
              ? 'textDark'
              : props.variant === 'cycle'
              ? 'textDark'
              : 'textMuted'
          }
          weight="semibold">
          {props.title}
        </Body1>

        <Body2
          // kind="textBody"
          kind={
            props.defaultValue === props.value
              ? 'textBody'
              : props.variant === 'cycle'
              ? 'textDark'
              : 'textMuted'
          }>
          {props.description}
        </Body2>
      </div>
    </VisibleCheckBoxStyled>
  );
};

interface VerticalCheckoutBoxProps {
  description?: string;
  oncheck?: any;
  disabled?: boolean;
  value: string;
  variant?: string;
  name: string;
  defaultValue?: string | boolean;
  title?: string;
}

const VerticalCheckBoxStyled = styled.label<{
  active: boolean;
  variant: any;
  disabled: any;
}>`
  padding: 16px;
  border: 1px solid
    ${({active, disabled}) => (active && !disabled ? '#585ADF' : '#ededf2')};
  border-radius: 10px;
  text-align: left;
  height: ${({variant}) =>
    variant === 'approval' || variant === 'cycle' ? '52px' : ''};
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const VerticalCheckBox = (props: VerticalCheckoutBoxProps) => {
  return (
    <VerticalCheckBoxStyled
      htmlFor={props.value}
      disabled={props.disabled}
      variant={props.variant}
      active={props.defaultValue === props.value}>
      <Checkbox
        disabled={props.disabled}
        onChange={(e) =>
          e.target.checked ? props.oncheck(props.value) : props.oncheck('')
        }
        id={props.value}
        checked={props.defaultValue === props.value}
      />

      <HorizontalSpacer size="15px" />
      <div>
        <Body1 kind={'textDark'} weight="semibold">
          {props.title}
        </Body1>
        <Body2
          kind={'textBody'}
          style={{
            fontSize: '12px',
          }}>
          {props.description}
        </Body2>
      </div>
    </VerticalCheckBoxStyled>
  );
};
interface VerticalRadioBoxProps {
  description?: string;
  oncheck?: any;
  disabled?: boolean;
  value: string;
  checked: boolean;
  variant?: string;
  name: string;
  defaultValue?: string | boolean;
  title?: string;
}
export const VerticalRadioBox = (props: VerticalRadioBoxProps) => {
  return (
    <VerticalCheckBoxStyled
      htmlFor={props.value}
      disabled={props.disabled}
      variant={props.variant}
      active={props.checked}>
      <Wrapper>
        <RadioInput
          name={props.name}
          value={props.value}
          onChange={props.oncheck}
          type="radio"
          id={props.value}
          checked={props.checked}
        />
        <InnerWrapper />
      </Wrapper>

      <HorizontalSpacer size="15px" />
      <div>
        <Body1 kind={'textDark'} weight="semibold">
          {props.title}
        </Body1>
        <Body2
          kind={'textBody'}
          style={{
            fontSize: '12px',
          }}>
          {props.description}
        </Body2>
      </div>
    </VerticalCheckBoxStyled>
  );
};

export const AdditionalOptionsWrapper = styled.div<{display?: boolean}>`
  display: ${(props: any) => (props.display === true ? 'block' : 'none')};
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='rgba(213, 42, 42, 1)' stroke-width='2' stroke-dasharray='14%2c 8' stroke-dashoffset='0' strokeLinecap='round'/%3e%3c/svg%3e");
  border-radius: 10px;
  margin: 8px 0;
  width: 100%;
  height: 79px;
  padding: 16px 24px;
  background-color: #fef6f6;
`;
