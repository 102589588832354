import {CancelIcon} from '@ui/atoms/icons';
import {Body2} from '@ui/atoms/typography';
import {MultiOptionsSelectField} from '@ui/molecules/select/multi-options';
import {MultiOptionsSelectFieldGroup} from '@ui/molecules/select/multi-options/multi-options';
import {CSSProperties} from 'react';

interface CustomFilterMultiSelectProps {
  options: any[];
  defaultValue?: string[];
  placeholderTerm?: string;
  removeSearch?: boolean;
  disabled?: boolean;
  showSearchIcon?: boolean;
  onChange: (value: any) => void;
  excludeCheckBox?: boolean;
  searchPlaceholder?: string;
  type?: 'group';
  selectedOptionStyle?: CSSProperties;

  selectedOptions: {label: string; groupType?: string; value: string}[];
}

export const CustomFilterMultiSelect = ({
  options,
  defaultValue,
  type,
  excludeCheckBox,
  showSearchIcon,
  selectedOptionStyle,
  searchPlaceholder,
  removeSearch,
  disabled,
  onChange,
  placeholderTerm,
  selectedOptions,
}: CustomFilterMultiSelectProps) => {
  const removeOption = (option: string) =>
    onChange(selectedOptions.filter((_option) => option !== _option.value));

  const FilterComponent =
    type === 'group' ? MultiOptionsSelectFieldGroup : MultiOptionsSelectField;

  return (
    <>
      <FilterComponent
        options={options}
        performance
        showSelections={false}
        searchPlaceholder={searchPlaceholder || `Search ${placeholderTerm}`}
        groupDisplayLength={5}
        excludeSearchBox={removeSearch}
        reportingStyling
        excludeCheckBox={excludeCheckBox}
        margin
        useMainSearchAsDropdown={removeSearch}
        disabled={disabled}
        height="44px"
        showSearchIcon={showSearchIcon ?? true}
        defaultValue={selectedOptions}
        noOptionsMessage={`${placeholderTerm} not found`}
        placeholder={`Search for ${placeholderTerm}`}
        onChange={(data: any) => {
          onChange(data);
        }}
      />
      <div className="flex flex-row  flex-wrap">
        {selectedOptions.map((option) => (
          <div
            key={option.value}
            style={selectedOptionStyle}
            className={`py-1 px-2 ${
              option.groupType ? 'w-full' : 'w-fit'
            } bg-[#F6F6F8] flex flex-row items-center justify-between rounded-[6px] mt-4 mr-3 `}>
            <Body2>
              {option.groupType && (
                <>
                  {' '}
                  <span className="font-semibold">
                    {option.groupType}
                  </span> ={' '}
                </>
              )}

              {option.label}
            </Body2>

            <div
              className="cursor-pointer"
              onClick={() => {
                removeOption(option.value);
              }}>
              <CancelIcon
                style={{
                  width: '12px',
                  height: '12px',
                  strokeWidth: 1.5,
                  marginLeft: '15px',
                }}
              />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
