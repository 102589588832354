import dayjs from 'dayjs';
import {StatusType} from '@hooks';
import {getColorFromTheme} from '@ui/ui-utils';
import {goalDueDate} from '@utils/date';
import {HorizontalSideRule} from '@ui/atoms/spacer/spacer';
import {FlexRow} from '@ui/style/styles';
import {Body2} from '@ui/atoms/typography';
import {MeasurementType} from '@hooks';
import {ObjectiveTitle} from './powerpoint-objectives';
import styled from 'styled-components';

export const SecondWrapperChildGoals = styled.div<{
  removeBorder?: boolean;
}>`
  display: grid;
  align-items: center;
  grid-template-columns: 50% 35% 10%;
  grid-gap: 16px;
  width: 100%;
  min-width: 580px;
  font-size: 14px;
  height: 120px;
  color: ${getColorFromTheme('textDark')};
  border-bottom: ${({removeBorder}) =>
    removeBorder ? undefined : '1px solid #ededf2'};
  padding: 16px;

  p {
    overflow: hidden;
    white-space: nowrap;
    line-height: 30px;
    text-overflow: ellipsis;
  }
  .pill-wrapper {
    padding: 6px 12px;
    border: 1px solid #cecede;
    width: 74px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-radius: 8px;
  }
`;

export const PowerpointKpi = ({goal}: {goal: any}) => {
  const isGoalEnded =
    goal.status === StatusType.COMPLETED ||
    goal.status === StatusType.INCOMPLETED;
  const goalIsPastDue = goalDueDate(goal.endDate) === 'Past due';

  const formatGoalDateRange =
    dayjs(goal.startDate).format('DD. MMM YY') +
    '  -  ' +
    dayjs(goal.endDate).format('DD. MMM YY');

  let formatter = Intl.NumberFormat('en', {notation: 'compact'});

  const currency =
    goal?.measurement?.unit === MeasurementType.CURRENCY &&
    goal?.measurement?.symbol;

  return (
    <SecondWrapperChildGoals>
      <div>
        <ObjectiveTitle
          title={goal.name}
          goalType={
            goal.goalType === 'group'
              ? `${goal.groupType?.name} - ${goal.groupName}`
              : goal.goalType === 'company'
              ? 'Company-wide'
              : goal.goalType
          }
        />

        <FlexRow style={{marginLeft: '8px', width: '100%'}}>
          {(isGoalEnded || goalIsPastDue) && (
            <FlexRow>
              <Body2
                kind={goalIsPastDue && !isGoalEnded ? 'red400' : 'textBody'}
                weight="regular"
                style={{fontSize: '9pt'}}>
                {isGoalEnded ? 'Ended' : 'Past due'}{' '}
              </Body2>
              <HorizontalSideRule size={'15px'} />
            </FlexRow>
          )}
          <Body2 kind="textBody" style={{fontSize: '9pt', width: '100%'}}>
            {formatGoalDateRange}
          </Body2>
        </FlexRow>
      </div>
      <Body2 weight="semibold" style={{fontSize: '12pt'}}>
        {goal.assignee?.firstName
          ? goal.assignee?.firstName + ' ' + goal.assignee.lastName
          : goal.assignee?.email}
      </Body2>
      <div className="pill-wrapper">
        <Body2
          style={{
            fontSize: '10pt',
            fontWeight: 700,
            width: '100%',
          }}>
          {`${currency ? currency : ''} ${formatter.format(
            goal?.currentValue,
          )}`}
        </Body2>
      </div>
    </SecondWrapperChildGoals>
  );
};
