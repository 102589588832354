import {AxiosResponse} from 'axios';
import {
  WorkspaceFormData,
  WorkspaceResponse,
} from './admin-workspace-interface';
import {BaseRequest} from '../../../../../configs/request';
import {usersStore} from '../../../../../store/stores/users-store';
import {authStore} from '../../../../../store/stores/auth-store';
import {IWorkspaceApiDataFormat} from '../../../../../hooks/interface';

export interface WorkspaceRequest extends BaseRequest {
  url: string;
  avatarUrl: string;
  adminWorkspace(
    formData: WorkspaceFormData,
    token: string,
  ): Promise<WorkspaceResponse>;
  avatarUpload(
    formData: WorkspaceFormData,
    token: string,
  ): Promise<WorkspaceResponse>;
}

export class WorkspaceRequestImpl
  extends BaseRequest
  implements WorkspaceRequest {
  url: string = `/workspaces/${authStore.auth?.user.workspace.id}`;
  avatarUrl: string = `/workspaces/${usersStore.currentUser.workspace}/upload-avatar`;

  async adminWorkspace<WorkspaceResponse>(data: IWorkspaceApiDataFormat) {
    const {data: responseData} = await this.request.patch<
      WorkspaceFormData,
      AxiosResponse<WorkspaceResponse>
    >(this.url, data, {
      headers: {
        Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
      },
    });
    return responseData;
  }

  async avatarUpload<WorkspaceResponse>(data: IWorkspaceApiDataFormat) {
    const {data: responseData} = await this.request.post<
      WorkspaceFormData,
      AxiosResponse<WorkspaceResponse>
    >(this.avatarUrl, data, {
      headers: {
        Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
      },
    });
    return responseData;
  }
}
