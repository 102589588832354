import ReactSelect, {
  components,
  OptionsType,
  ValueType,
  ActionMeta,
  Props,
  IndicatorProps,
  MenuProps,
  PlaceholderProps,
  SingleValueProps,
} from 'react-select';
import {MagnifyingGlassIcon} from '../../../atoms/icons/magnifying-glass';
import {
  IndicatorWrapper,
  getStyles,
  AvatarWrapper,
  GoalTypeTag,
} from './styled';
import {HorizontalSpacer, VerticalSpacer} from '../../../atoms/spacer';
import {Avatar} from '../../../atoms/avatar';
import {Body1, Body2, CTA, Microcopy} from '../../../atoms/typography';
import {forwardRef, Ref, useCallback, useMemo, useState} from 'react';
import {FlexRow} from '@ui/style/styles';
import {goalDueDate} from '@utils/date';
import {StatusType} from '@hooks';
import {HorizontalSideRule} from '@ui/atoms/spacer/spacer';
import {PillDiv} from '@ui/molecules/progress-tracker/progress-tracker';
import {GreenWhitePlusIcon} from '@ui/atoms/icons/greenWhitePlus';
import {capitalizeGoalType} from '../../../../utils/capitalize';
import {computeFrameworkVars} from '../../../../utils/framework';
import {authStore} from '../../../../store/stores/auth-store';
const commaNumber = require('comma-number');

interface IOption {
  label: {goalName: string};
}

const filterUserSelectOptions = (option: {data: IOption}, rawInput: string) => {
  if (rawInput) {
    const label = option.data.label.goalName?.toLowerCase() || '';
    return rawInput
      .toLowerCase()
      .split(' ')
      .every((word) => label.includes(word));
  }
  return true;
};

const {
  DropdownIndicator: SelectDropdownIndicator,
  Option: SelectOption,
  Menu: MenuOptions,
  Placeholder: SelectPlaceholder,
  SingleValue: SelectSingleValue,
} = components;

export interface TLabel {
  goalName: string;
  goalType: string;
  assigneeName: string;
  assigneeAvatar: {src: string; name: string};
  id: string;
  date: string;
}

export interface TOption {
  value: string;
  label: TLabel;
}

export interface SelectFieldProps extends Props<TOption> {
  options: OptionsType<TOption>;
  onChange?: (
    value: ValueType<TOption, false>,
    action: ActionMeta<TOption>,
  ) => void;
}

const DropdownIndicator = (props: IndicatorProps<TOption, false>) => {
  return (
    <div style={{display: props.hasValue ? 'none' : ''}}>
      <SelectDropdownIndicator {...props}>
        <IndicatorWrapper>
          <MagnifyingGlassIcon />
        </IndicatorWrapper>
      </SelectDropdownIndicator>
    </div>
  );
};

const Placeholder = (props: PlaceholderProps<TOption, false>) => {
  return (
    <SelectPlaceholder {...props}>
      <Body1 kind="textBody">{props.children}</Body1>
    </SelectPlaceholder>
  );
};

const Menu = (props: MenuProps<TOption, false>) => {
  const addCustomOption =
    props.selectProps.options &&
    props.selectProps.options.find((option) => option.label === 'custom');
  const action = (data: any) => {
    return;
  };
  return (
    <>
      <MenuOptions {...props}>
        <div>
          {props.children}
          {addCustomOption && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '8px 16px',
                cursor: 'pointer',
              }}
              onClick={() =>
                props.selectProps.onChange &&
                props.selectProps.onChange(
                  addCustomOption as any,
                  action as any,
                )
              }>
              <GreenWhitePlusIcon /> <HorizontalSpacer size="16px" />{' '}
              <CTA kind="textDark" style={{fontWeight: 600}}>
                {props.selectProps.customOptionName ||
                  'Create your custom question'}
              </CTA>
            </div>
          )}
        </div>
      </MenuOptions>
    </>
  );
};

const Option = (props: any) => {
  return (
    <>
      {props.label === 'custom' ? (
        <></>
      ) : typeof props.label === 'string' ? (
        <SelectOption {...props}>
          <Body2 kind="textDark" weight="semibold">
            {props?.label && ((props.label as unknown) as TLabel)}
          </Body2>
        </SelectOption>
      ) : (
        <SelectOption {...props}>
          <>
            {((props.label as unknown) as TLabel).goalType && (
              <>
                {props?.label?.group?.length ? (
                  <FlexRow>
                    {props?.label?.group.map((group: {name: string}) => (
                      <GoalTypeTag className="mr-3">
                        {capitalizeGoalType(group?.name || '')}
                      </GoalTypeTag>
                    ))}
                  </FlexRow>
                ) : (
                  <GoalTypeTag>
                    {props?.label &&
                      capitalizeGoalType(
                        ((props.label as unknown) as TLabel).goalType,
                      )}
                  </GoalTypeTag>
                )}
              </>
            )}

            <VerticalSpacer size="8px" />
            <Body2 kind="textDark" weight="semibold">
              {props?.label && ((props.label as unknown) as TLabel).goalName}
            </Body2>
            <VerticalSpacer size="8px" />
            {props.selectProps.useProgress ? (
              <FlexRow style={{marginTop: '4px'}}>
                {props.label.isKpi ? (
                  <FlexRow>
                    <Microcopy
                      style={{
                        fontSize: '10px',
                        margin: '0 6px 0 0px',
                        fontWeight: 400,
                      }}>
                      {commaNumber(props.label.progress.percent)}
                    </Microcopy>
                  </FlexRow>
                ) : (
                  <FlexRow>
                    {' '}
                    <PillDiv
                      {...props.label.progress}
                      progress={props.label.progress.type}
                    />
                    <Microcopy
                      style={{
                        fontSize: '10px',
                        margin: ' 0 6px 0 4px',
                        fontWeight: 400,
                      }}>
                      {props.label.progress.percent}%
                    </Microcopy>
                  </FlexRow>
                )}
                <HorizontalSideRule size="10px" />
                <Microcopy
                  style={{
                    fontSize: '10px',
                    fontWeight: 400,
                    marginLeft: '2px',
                  }}
                  kind="textBody">
                  {goalDueDate(
                    props.label.progress?.endDate,
                    true,
                    props.label.progress?.status === StatusType.COMPLETED ||
                      props.label.progress?.status === StatusType.INCOMPLETED,
                  )}
                </Microcopy>
              </FlexRow>
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                <AvatarWrapper>
                  <Avatar
                    src={
                      props?.label
                        ? ((props.label as unknown) as TLabel).assigneeAvatar
                            ?.src
                        : ''
                    }
                    name={
                      props?.label
                        ? ((props.label as unknown) as TLabel).assigneeAvatar
                            ?.name
                        : ''
                    }
                    userId={
                      props?.label
                        ? ((props.label as unknown) as any).assigneeAvatar?.id
                        : ''
                    }
                    size="sm"
                    tooltip={props?.label ? true : false}
                  />

                  <HorizontalSpacer size="8px" />
                  <Body2>
                    {props?.label &&
                      ((props.label as unknown) as TLabel).assigneeName}
                  </Body2>
                </AvatarWrapper>
                {props?.label && ((props.label as unknown) as TLabel).date && (
                  <>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <span style={{fontSize: '14px', color: '#5f5f8c'}}>
                        Created:&nbsp;
                      </span>
                      <Body2>
                        {props?.label &&
                          ((props.label as unknown) as TLabel).date}
                      </Body2>
                    </div>
                  </>
                )}
              </div>
            )}
          </>
        </SelectOption>
      )}
      {/* DO NOT DELETE */}
      {/* <AdditionalOptionsWrapper
        display={props.isSelected || props.data.isEditing}>
        <RadioWrapper
          onClick={() => {
            setIsChecked(isChecked === true ? false : true);
          }}>
          <RadioSm checked={!isChecked} />
          <HorizontalSpacer size="8px" />
          <Body2 kind={!isChecked ? 'textDark' : 'textBody'}>
            Align your progress to this parent goal
          </Body2>
        </RadioWrapper>
        <VerticalSpacer size="8px" />
        <RadioWrapper
          onClick={() => {
            setIsChecked(isChecked === true ? false : true);
          }}>
          <RadioSm checked={isChecked} />
          <HorizontalSpacer size="8px" />
          <Body2 kind={isChecked ? 'textDark' : 'textBody'}>
            Don't align your progress to this parent goal
          </Body2>
        </RadioWrapper>
      </AdditionalOptionsWrapper> */}
    </>
  );
};

const SingleValue = (props: SingleValueProps<TOption>) => {
  return (
    <SelectSingleValue {...props}>
      <Body1 kind="textDark">
        {((props.children as unknown) as TLabel).goalName}
      </Body1>
    </SelectSingleValue>
  );
};

export const UserSelect3 = forwardRef(
  (
    {
      options,
      placeholder,
      onChange,
      value,
      defaultValue,
      useAllOptions,
      ...rest
    }: any,
    ref: Ref<any>,
  ) => {
    const styles = useMemo(() => getStyles<TOption, false>(), []);

    const [innerValue, setInnerValue] = useState<ValueType<TOption, false>>(
      // @ts-ignore
      value,
    );

    const onSelectChange = useCallback(
      (value: ValueType<TOption, false>, action: ActionMeta<TOption>) => {
        if (onChange) {
          onChange(value, action);
        }
        setInnerValue(value);
      },
      [onChange],
    );

    return (
      <ReactSelect
        {...rest}
        isClearable
        backspaceRemovesValue
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        menuIsOpen
        filterOption={filterUserSelectOptions}
        isSearchable
        value={innerValue}
        noOptionsMessage={() =>
          `No ${
            computeFrameworkVars(authStore?.auth?.user?.workspace?.framework)
              ?.g_oal
          } found`
        }
        tabSelectsValue={false}
        placeholder={placeholder}
        onChange={onSelectChange}
        ref={ref}
        maxMenuHeight={240}
        components={{
          DropdownIndicator,
          Option,
          Placeholder,
          Menu,
          SingleValue,
          IndicatorSeparator: null,
        }}
        options={
          !useAllOptions && innerValue && Object.keys(innerValue).length > 0
            ? [innerValue]
            : options
        }
        styles={styles}
        theme={(theme) => ({
          ...theme,
          borderRadius: 7,
          spacing: {
            baseUnit: 6,
            controlHeight: 40,
            menuGutter: 6,
          },
        })}
      />
    );
  },
);
