import {useCallback, useState, useMemo} from 'react';
import {AnalyticsTodayDate} from '@utils/date';
import {useQuery} from 'react-query';
import {usePeriodHook} from '@hooks/period-hook';
import {useDirectOptionHook} from '@pages/dashboard/reporting/reporting-pages/reporting-overview/hooks/direct-options.hook';
import {CompanyGoalsRequestImpl} from './direct-report-request';
import {useStoreContext} from '@store/store-context';
import {CompanyGoalsController} from './direct-report-controller';
export interface IUser {
  label: '';
  value: '';
}

export const useDirectReportsGoal = () => {
  const {
    companyStore: {searchable, setDirectReports},
    authStore: {auth},
    groupStore: {groups},
    storeDashboardRoutes: {GoalViewTabs},
  } = useStoreContext();

  const {directReportsTeamOption} = useDirectOptionHook();

  const [userFilter, setUserFilter] = useState({
    filterBy: {
      label: 'Teams',
      value: 'team',
    },
    selectedTeam: directReportsTeamOption[0],
    members: [] as IUser[],
  });

  const [searchValue, setSearchValue] = useState('');

  const filters = useMemo(() => {
    if (searchable?.member && searchable?.manager) {
      return {
        member: searchable?.member,
        name: searchValue,
        manager: searchable?.manager,
      };
    } else if (!searchable?.member) {
      return {
        manager:
          !userFilter.selectedTeam.value ||
          userFilter.filterBy.value === 'people'
            ? userFilter.members.length
              ? undefined
              : auth.user.id
            : userFilter.selectedTeam.value,
        name: searchValue,

        member:
          userFilter.members.length > 0
            ? userFilter.members.map((member) => member.value).join(',')
            : undefined,
      };
    } else {
      return {
        member: searchable?.member,
        name: searchValue,

        manager: searchable?.manager,
      };
    }
  }, [
    auth.user.id,
    searchable?.member,
    searchable?.manager,
    userFilter,
    searchValue,
  ]);

  const computeView = useCallback(() => {
    if (GoalViewTabs.flat) {
      return 'flat';
    } else if (GoalViewTabs.nested) {
      return 'stacked';
    } else {
      return 'tree';
    }
  }, [GoalViewTabs.flat, GoalViewTabs.nested]);

  const request = useMemo(() => {
    return new CompanyGoalsRequestImpl();
  }, []);

  const controller = useMemo(() => {
    return new CompanyGoalsController(request);
  }, [request]);

  const initDirectReportsGoals = useCallback(
    async (date?: {starts: string; ends: string}) => {
      const response_ = await controller.fetchTeamMemberGoals({
        ...filters,
        ...date,
        name: searchValue,
      });

      const getStackGoals = response_?.users?.map((res: any) => {
        return {
          ...res,
          goals:
            res.goals &&
            res.goals.filter((goal: any) => goal.alignedGoals.length > 0),
        };
      });

      const response =
        computeView() === 'stacked'
          ? getStackGoals?.filter((res: any) => res.goals?.length > 0)
          : response_?.users;

      if (response) {
        const arrayOfAssigneesGoals = response.map((res: any) => {
          return {
            assignee: res,
            goals:
              res.goals &&
              res.goals.map((goal: any) => {
                if (goal.goalType === 'group') {
                  const group = groups.filter((group) =>
                    goal.group.includes(group.id),
                  );

                  return {
                    ...goal,
                    assignee: goal.assignee,
                    group,
                  };
                } else
                  return {
                    ...goal,
                    assignee: goal.assignee,
                  };
              }),
          };
        });
        response && setDirectReports(arrayOfAssigneesGoals);
      }
    },
    [controller, filters, searchValue, computeView, setDirectReports, groups],
  );

  return {
    initDirectReportsGoals,
    filters,
    setUserFilter,
    userFilter,
    setSearchValue,
  };
};

export const useDirectReportHook = () => {
  const [clearSelection, setClearSelection] = useState(false);

  const {
    initDirectReportsGoals,
    filters,
    setUserFilter,
    userFilter,
    setSearchValue,
  } = useDirectReportsGoal();

  const {currentMonthRange, isPeriodEnabled} = usePeriodHook();

  const getPeriod = useMemo(
    () =>
      isPeriodEnabled
        ? {
            starts: currentMonthRange().starts,
            ends: currentMonthRange().ends,
            period: currentMonthRange().period,
          }
        : {
            starts: AnalyticsTodayDate().AnalyticsDefaultStartDate,
            ends: AnalyticsTodayDate().AnalyticsDefaultEndDate,
          },
    [isPeriodEnabled, currentMonthRange],
  );

  const [dateRange, setDateRange] = useState(getPeriod);

  const {isLoading, refetch} = useQuery(
    ['direct_reports', {...filters, dateRange}],
    () => initDirectReportsGoals(dateRange),
  );

  return {
    loading: isLoading,
    userFilter,
    refetch,
    setUserFilter,
    clearSelection,
    setSearchValue,
    setClearSelection,
    dateRange,
    setDateRange,
  };
};
