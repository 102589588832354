import {useNavigate} from 'react-router';
import {BaseSyntheticEvent, useState} from 'react';
import {MeetingDetails} from './create-meeting-form';
import {
  CreateMeetingFormData,
  useCreateMeetingHook,
} from './create-meetings.hook';
import {Control, DeepMap, FieldError, SubmitHandler} from 'react-hook-form';
import PageLayoutTemplate from '@ui/templates/page-template/page-layout';
import {PageWrapper, FormWrapper} from './styles';
import {AddMeetingToCalender} from '../addMeetingToCalender';
import {useGCalenderHook} from '@hooks/gcalender';
import {useMeetingTags} from '../useMeetingTags';

export const CreateMeeting = () => {
  const navigate = useNavigate();
  const {isSigned} = useGCalenderHook();

  const [activeTab] = useState({
    create: true,
    setAgenda: false,
  });

  const {
    control,
    handleSubmit,
    handleFormValueChange,
    isTeamMeetings,
    errors,
    isDuplicate,
    values,
    loading,
    onSubmit,
  } = useCreateMeetingHook();

  const {tags} = useMeetingTags();

  const pageTitle = isTeamMeetings
    ? `Schedule meeting`
    : `${isDuplicate ? 'Duplicate' : 'Schedule'} 1:1`;

  return (
    <PageWrapper>
      <PageLayoutTemplate
        title={pageTitle}
        guideUrl="https://peoplebeam.myhcpage.com/en-us/"
        onClick={() => navigate(-1)}
        bodyStyle={{padding: 0, margin: 0, marginTop: '5px'}}>
        {!isSigned && <AddMeetingToCalender />}

        <FormWrapper wideWidth={activeTab.setAgenda}>
          <div className="form">
            <MeetingDetails
              onSubmit={onSubmit}
              loading={loading}
              control={control}
              isTeamMeetings={isTeamMeetings}
              tags={tags}
              values={values}
              error={errors}
              handleSubmit={handleSubmit}
              handleFormValueChange={handleFormValueChange}
            />
          </div>
        </FormWrapper>
      </PageLayoutTemplate>
    </PageWrapper>
  );
};

export interface FormProps {
  control: Control<CreateMeetingFormData>;
  values: CreateMeetingFormData;
  handleSubmit: (
    values: SubmitHandler<CreateMeetingFormData>,
  ) => (e: BaseSyntheticEvent<any>) => any;
  error: DeepMap<CreateMeetingFormData, FieldError>;
  handleFormValueChange: (value: string | any, name: string) => void;
}
