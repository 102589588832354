import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {Button} from '@ui/atoms/button';
import {Body2} from '@ui/atoms/typography';
import {observer} from 'mobx-react';
import {FilterIcon} from '@ui/atoms/icons/filter-icon';
import {FlexRowSpaceBetween} from '@ui/style/styles';
import {useStoreContext} from '@store/store-context';
import {FiChevronDown} from 'react-icons/fi';
import {DropdownCustom} from '@ui/molecules/dropdown-custom';

export const AnaylyticsDateView = observer(
  ({disabled}: {disabled?: boolean}) => {
    const {
      analyticsStore: {viewAnalyticsRange, setAnalyticsRange},
    } = useStoreContext();

    const displayRange = (range: string) => {
      switch (range) {
        case 'days':
          return 'Daily';
        case 'weeks':
          return 'Weekly';
        case 'months':
          return 'Monthly';

        default:
          return 'All time';
      }
    };

    const handleClick = (
      type: 'days' | 'weeks' | 'months' | '',
      handleClose: () => void,
    ) => {
      handleClose();

      setAnalyticsRange(type);
    };
    return (
      <DropdownCustom
        collapseOnClick={true}
        menu={(handleClose: () => void) => (
          <div>
            <DropdownItem onClick={() => handleClick('', handleClose)}>
              All time
            </DropdownItem>
            <DropdownItem onClick={() => handleClick('days', handleClose)}>
              Daily
            </DropdownItem>
            <DropdownItem onClick={() => handleClick('weeks', handleClose)}>
              Weekly
            </DropdownItem>{' '}
            <DropdownItem onClick={() => handleClick('months', handleClose)}>
              Monthly
            </DropdownItem>{' '}
          </div>
        )}
        customComponent={(handleOpen: (event: any) => void, open: boolean) => (
          <Button
            kind="secondary"
            onClick={handleOpen}
            disabled={disabled}
            style={{padding: '8px 16px', minWidth: '120px'}}>
            <FlexRowSpaceBetween>
              <Body2 weight="bold">{displayRange(viewAnalyticsRange)}</Body2>
              <FiChevronDown
                style={{margin: '0px 5px 0px 0px'}}
                aria-hidden="true"
              />
            </FlexRowSpaceBetween>
          </Button>
        )}
        customDropdownWrapperStyles={{
          top: '130%',
          width: '100%',
          padding: '8px',
          right: 0,
        }}
      />
    );
  },
);
export const ObjectivesCompletionFilter = observer(() => {
  return (
    <DropdownCustom
      collapseOnClick={true}
      menu={(handleClose: () => void) => (
        <div>
          <DropdownItem>Least completed</DropdownItem>
          <DropdownItem>Most completed</DropdownItem>{' '}
        </div>
      )}
      customComponent={(handleOpen: (event: any) => void, open: boolean) => (
        <Button
          kind="secondary"
          onClick={handleOpen}
          style={{padding: '8px 16px', border: 0}}>
          <FlexRowSpaceBetween>
            <Body2 weight="bold" kind="purple300" style={{marginRight: '5px'}}>
              Filter
            </Body2>
            <FilterIcon />
          </FlexRowSpaceBetween>
        </Button>
      )}
      customDropdownWrapperStyles={{
        top: '130%',
        width: '160px',
        padding: '8px',
        right: 0,
      }}
    />
  );
});
