import {useMemo} from 'react';
import {useObject} from 'react-firebase-hooks/database';
import {ref} from 'firebase/database';
import {database} from '@utils/firebase-request';
import {generateRandomDigits} from '@utils/generate-random-numbers';
import {setFirebaseData} from '@utils/firebase-handler';
import {useStoreContext} from '../store/store-context';
import {useIdbStore} from '@hooks/idb-store-ops';

export const useFirebaseAppListeners = () => {
  const {
    authStore: {auth},
  } = useStoreContext();

  const {getKeysFromIDB, getKeysFromIDBUsers} = useIdbStore();

  const workspaceUpdatesPath = `${process.env.REACT_APP_DEV_ENV}/${auth.user.workspace.id}/updates`;
  const userUpdatesPath = `${process.env.REACT_APP_DEV_ENV}/${auth.user.workspace.id}/users/${auth.user.id}/updates`;

  const [workspaceSnapshot, workspaceLoading, workspaceError] = useObject(
    ref(database, workspaceUpdatesPath),
  );

  const [userSnapshot, userLoading, userError] = useObject(
    ref(database, userUpdatesPath),
  );

  useMemo(() => {
    if (workspaceLoading || workspaceError) return null;

    const data = workspaceSnapshot?.val();
    if (!data) {
      const createKey = {
        groupType: generateRandomDigits(16),
        group: generateRandomDigits(16),
        user: generateRandomDigits(16),
        app: generateRandomDigits(16),
        settings: generateRandomDigits(16),
      };
      setFirebaseData(`updates`, createKey);
      return createKey;
    } else {
      getKeysFromIDB(data);
      return data;
    }
  }, [workspaceSnapshot, workspaceLoading, workspaceError, getKeysFromIDB]);

  useMemo(() => {
    if (userLoading || userError) return null;

    const data = userSnapshot?.val();
    if (!data) {
      const createKey = {
        goals: generateRandomDigits(16),
        tasks: generateRandomDigits(16),
      };
      setFirebaseData(`/users/${auth.user.id}/updates`, createKey);
      return createKey;
    } else {
      getKeysFromIDBUsers(data);
      return data;
    }
  }, [userSnapshot, userLoading, userError, auth.user.id, getKeysFromIDBUsers]);

  return {
    workspaceLoading,
    workspaceError,
    userLoading,
    userError,
  };
};
