import PageLayoutTemplate from '@ui/templates/page-template/page-layout';
import {
  AlignmentFormWrapper,
  ObjectiveWrapper,
  RemoveButton,
} from './create-alignment-styles';
import {useQuery} from 'react-query';
import {cfv} from '@utils/framework';
import {Label} from '@ui/atoms/label';
import {SecondaryMinusSVG} from '@ui/atoms/svg/minus';
import {ItemLoader} from '@ui/organisms/item-loader';
import {PlusIcon} from '@ui/atoms/icons';
import {Body2} from '@ui/atoms/typography';
import {NoBorderButton} from '@pages/dashboard/reporting/reporting-pages/reporting-overview/styles';
import {
  FlexRow,
  FlexRowCenter,
  FlexRowEnd,
  FlexRowSpaceBetween,
} from '@ui/style/styles';
import {Button} from '@ui/atoms/button';
import isBetween from 'dayjs/plugin/isBetween';

import {AnalyticsController} from '@pages/dashboard/reporting/reporting-pages/admin-analytics/admin-analytics.controller';
import {AnalyticsRequestImpl} from '@pages/dashboard/reporting/reporting-pages/admin-analytics/admin-analytics.request';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {ItemLoaderLine} from '@ui/organisms/item-loader-line';
import {Tooltip} from '@ui/molecules/tooltip';
import dayjs from 'dayjs';
import {useViewInsight} from '@hooks/insights';
import {CancelIcon} from '@ui/atoms/icons';
import {DeleteMiniDropdown} from '@ui/molecules/mini-modal/mini-modal';
import {EyeIcon} from '@ui/atoms/icons';
import {OptionSelectField} from '@ui/molecules/select/option';
import {transformOptions} from '@pages/dashboard/reporting/reporting-pages/reporting-overview/components/kpi-board';
import {SelectField} from '@ui/molecules/select/selectfield';
import {DateRangeInput} from '@ui/molecules/date-range-input';
import {useCreateAlignmentHook} from './create-alignment-hook';
import {GoalsSelectField} from '@ui/molecules/select/goal-select-field/goal-select-field';
import {useNavigate} from 'react-router';
import {useDropDown} from '@hooks';
import {IChild} from './create-alignment-hook';
import {GOAL_TYPE, IGoalType} from '@ui/interface';
import {FC, useState, useCallback, useMemo, memo} from 'react';
import {useStoreContext} from '@store/store-context';

import {usePeriodHook} from '@hooks/period-hook';
dayjs.extend(isBetween);

export const CreateAlignment = () => {
  const navigate = useNavigate();

  const {
    initGoals,
    parentObjectivesOptions,
    addChildObjective,
    disableChildObj,
    childObjectives,
    deleteObjective,
    loading,
    createAlignment,
    onChangeObjective,
    setParentObjectiveCalc,
    parentObjectiveCalculation,
    disableCreateAlignment,
    editAlignment,
    parentObjective,
    setParentObjective,
  } = useCreateAlignmentHook();

  const {data, isLoading} = useQuery(['alignment-goal'], () =>
    initGoals(1, 'networkLevel'),
  );

  const selectedParentObjective = useMemo(
    () => data?.goals.find((goal: {id: string}) => goal.id === parentObjective),
    [data?.goals, parentObjective],
  );

  return (
    <PageLayoutTemplate
      onClick={() => navigate(-1)}
      guideUrl="https://peoplebeam.myhcpage.com/en-us/"
      title={!!editAlignment ? 'Edit alignment' : 'Create alignment'}>
      <AlignmentFormWrapper>
        <div className="form">
          {isLoading ? (
            <ItemLoader />
          ) : (
            <div>
              <Label>Parent objective</Label>
              <GoalsSelectField
                placeholder={`Search objective`}
                useDropdownIcon
                defaultValue={parentObjective}
                options={data?.options || []}
                onChange={(data: {value: string; label: string}) => {
                  setParentObjective(data?.value);
                }}
              />
              <div>
                <Label>What child objective should align with parent?</Label>
                {childObjectives.map((objective, _, selectedObjectives) => (
                  <AddChildObjective
                    selectedObjectives={selectedObjectives.map(
                      (obj) => obj.objectiveId,
                    )}
                    key={objective.id}
                    parentObjective={selectedParentObjective}
                    {...objective}
                    deleteObjective={deleteObjective}
                    onChangeObjective={onChangeObjective}
                  />
                ))}

                <NoBorderButton
                  onClick={addChildObjective}
                  disabled={disableChildObj}>
                  <FlexRow>
                    <PlusIcon
                      styles={{
                        stroke: disableChildObj ? '#BFBFD4' : '#585ADF',
                        strokeWidth: '2px',
                        marginRight: '4px',
                        width: '16px',
                      }}
                    />
                    <Body2
                      weight="bold"
                      kind={disableChildObj ? 'textMuted' : 'purple300'}>
                      Add child objective
                    </Body2>
                  </FlexRow>
                </NoBorderButton>
                <VerticalSpacer size="16px" />
              </div>
              <div>
                <Label>Calculate parent objective progress by?</Label>
                <SelectField
                  placeholder={`Choose option`}
                  borderRadius="10px"
                  options={parentObjectivesOptions}
                  defaultValue={parentObjectiveCalculation}
                  onChange={(data: {value: string; label: string}) => {
                    setParentObjectiveCalc(data?.value);
                  }}
                />
              </div>
              <Button
                width="full"
                disabled={disableCreateAlignment}
                isLoading={loading}
                onClick={() => createAlignment(data?.goals)}>
                {!!editAlignment ? ' Save changes' : 'Create alignment'}
              </Button>
            </div>
          )}
        </div>
      </AlignmentFormWrapper>
    </PageLayoutTemplate>
  );
};

interface IAddChildObjective extends IChild {
  onChangeObjective: (values: IChild) => void;
  selectedObjectives: string[];
  parentObjective?: {
    id: string;
    startDate: string;
    endDate: string;
    goalType: string;
  };
  deleteObjective: (id: string) => void;
}

const AddChildObjective: FC<IAddChildObjective> = memo(
  ({
    saved,
    id,
    onChangeObjective,
    objectiveId,
    selectedObjectives,
    parentObjective,
    deleteObjective,
    objectiveName,
  }) => {
    const {periods} = usePeriodHook();

    const [date, setDate] = useState({starts: '', ends: ''});

    const [showDateRange, setShowDateRange] = useState(false);

    const {
      groupStore: {groups},
      groupTypeStore: {groupType},
    } = useStoreContext();

    const [selectedValue, setSelectedValue] = useState('');

    const [selectedObjective, setSelectedObjective] = useState({
      name: '',
      id: '',
    });

    const {open, handleOpen, handleClose, ref} = useDropDown();

    const handlePeriodChange = (value: string) => {
      setSelectedValue(value);

      if (value === 'custom') {
        setShowDateRange(true);
        return;
      }

      setShowDateRange(false);

      const findPeriod = periods.find((period) => period.value === value);

      setDate({
        starts: findPeriod.starts || '',
        ends: findPeriod.ends || '',
      });
    };

    const handleSave = () => {
      onChangeObjective({
        saved: true,
        objectiveName: selectedObjective.name,
        objectiveId: selectedObjective.id,
        id,
      });
    };

    const initGoals = useCallback(
      async (filter?: any) => {
        const request = new AnalyticsRequestImpl();
        const controller = new AnalyticsController(request);

        const response = await controller.fetchSpecifiedGoals(filter, '', true);

        const allowGoalType = (type: IGoalType, parentGoalType: IGoalType) => {
          if (
            parentGoalType === GOAL_TYPE.COMPANY &&
            type === GOAL_TYPE.COMPANY
          ) {
            return false;
          }
          if (parentGoalType === GOAL_TYPE.GROUP && type !== GOAL_TYPE.GROUP) {
            return false;
          }
          if (
            parentGoalType === GOAL_TYPE.INDIVIDUAL &&
            type !== GOAL_TYPE.INDIVIDUAL
          ) {
            return false;
          }
          return true;
        };
        function filterGoals(goal: {
          isKpi: boolean;
          id: string;
          startDate: string;
          goalType: IGoalType;
          goalAlignment: string;
        }) {
          const isNotKpi = !goal.isKpi;
          const isNotSelectedObjective = !selectedObjectives.includes(goal.id);
          const isNotParentObjective = parentObjective?.id !== goal.id;
          const isGoalAlignmentEmpty = !goal.goalAlignment;
          const isGoalTypeAllowed = allowGoalType(
            goal.goalType,
            parentObjective?.goalType || ('' as any),
          );
          const isStartDateBetweenParentObjectiveDates = dayjs(
            goal.startDate,
          ).isBetween(
            parentObjective?.startDate || '',
            parentObjective?.endDate || '',
            null,
            '[]',
          );

          return (
            isNotKpi &&
            isNotSelectedObjective &&
            isNotParentObjective &&
            isGoalTypeAllowed &&
            isGoalAlignmentEmpty &&
            isStartDateBetweenParentObjectiveDates
          );
        }

        const alignGoalArr = response.goals
          .filter(filterGoals)
          .map((goal: any) => transformOptions(goal, groups, groupType));

        return alignGoalArr;
      },
      [groups, groupType, selectedObjectives, parentObjective],
    );

    const viewGoalInsight = useViewInsight();

    const disableSaveButton =
      Object.values(date).every((value) => !value) || !selectedObjective.id;

    const {data = [], isLoading} = useQuery(
      ['create-alignment', date.starts, date.ends, parentObjective],
      () =>
        initGoals({
          startDate: date.starts,
          endDate: date.ends,
        }),
      {
        enabled: !!date.starts && !!parentObjective,
      },
    );

    const disableRemoveButton = selectedObjectives.length <= 1;

    if (saved) {
      return (
        <ObjectiveWrapper style={{marginBottom: '16px'}}>
          <FlexRowSpaceBetween>
            <ObjectiveWrapper
              style={{
                padding: '14px 16px',
                width: '92%',
                marginRight: '10px',
              }}>
              <FlexRowSpaceBetween style={{}}>
                <Tooltip
                  text={objectiveName}
                  tooltipBody={objectiveName}
                  maxLength={40}
                  BodyTextNodeType={Body2}
                  bodyTextNodeKind="textDark"
                  withEllipsis
                />
                <NoBorderButton onClick={() => viewGoalInsight(objectiveId)}>
                  <FlexRowCenter
                    style={{
                      background: '#F6F6FE',
                      borderRadius: '100px',
                      width: '24px',
                      height: '24px',
                    }}>
                    <EyeIcon />
                  </FlexRowCenter>
                </NoBorderButton>
              </FlexRowSpaceBetween>
            </ObjectiveWrapper>

            <div style={{position: 'relative'}}>
              <NoBorderButton onClick={handleOpen}>
                <CancelIcon />
              </NoBorderButton>

              {open && (
                <div ref={ref}>
                  <DeleteMiniDropdown
                    title="Delete objective?"
                    onClickCancel={handleClose}
                    onSubmit={() => deleteObjective(id)}
                    description="This objective will be removed."
                  />
                </div>
              )}
            </div>
          </FlexRowSpaceBetween>
        </ObjectiveWrapper>
      );
    }
    return (
      <ObjectiveWrapper style={{marginBottom: '16px'}} className="bg-white">
        <OptionSelectField
          options={periods}
          placeholder={'Select period'}
          name="questionType"
          inputStyle={{borderRadius: '10px'}}
          borderRadius="10px"
          value={selectedValue === 'custom' ? selectedValue : date.starts}
          customOptionName="Choose custom timeline"
          maxHeight={400}
          onChange={(data: {value: string; disabled?: boolean}) => {
            handlePeriodChange(data.value);
          }}
          fieldNotFoundPlaceHolder={(searchTerm?: string) =>
            `Oops! this period does not exist`
          }
          optionsSelectType="period"
        />{' '}
        {showDateRange && (
          <>
            <DateRangeInput
              name="date"
              value={{starts: date.starts, ends: date.ends}}
              helper={
                new Date(date.ends) < new Date(date.starts)
                  ? 'Start date must be before end date'
                  : ''
              }
              state={
                new Date(date.ends) < new Date(date.starts)
                  ? 'error'
                  : 'default'
              }
              admin={true}
              placeholder="Select Date"
              setValue={(e) => {
                if (e.starts && e.ends) {
                  setDate({
                    starts: dayjs(e.starts).format('MM/DD/YYYY'),
                    ends: dayjs(e.ends).format('MM/DD/YYYY'),
                  });
                }
              }}
            />
            <VerticalSpacer size="16px" />
          </>
        )}
        {isLoading ? (
          <FlexRowCenter style={{marginBottom: '10px'}}>
            <ItemLoaderLine />
          </FlexRowCenter>
        ) : (
          <GoalsSelectField
            placeholder={`Search objective`}
            // value={value}
            //   ref={UserSelect3Ref}
            options={data}
            onChange={(data: {value: string; label: any}) => {
              setSelectedObjective({
                name: data.label.goalName || data.label,
                id: data.value,
              });
            }}
          />
        )}
        <Button
          width="full"
          style={{padding: '12px 24px'}}
          onClick={handleSave}
          disabled={disableSaveButton}>
          Add Objective
        </Button>
        <FlexRowEnd>
          <RemoveButton
            type="button"
            disabled={disableRemoveButton}
            aria-label={`remove ${cfv(undefined, true).k_r}`}
            onClick={() => deleteObjective(id)}>
            <SecondaryMinusSVG />
            <Body2
              weight="bold"
              style={{marginLeft: '5px'}}
              kind={disableRemoveButton ? 'textMuted' : 'red400'}>
              Delete
            </Body2>
          </RemoveButton>
        </FlexRowEnd>
      </ObjectiveWrapper>
    );
  },
);
