import {DefaultTheme, ThemedStyledProps} from 'styled-components';
import {Colors, FontSize, FontWeight, LineHeight} from './interface';

export type MapPropsFromTheme<Props, ReturnType> = <T>(
  props: Props,
) => (props: ThemedStyledProps<T, DefaultTheme>) => ReturnType;

export const getColorFromTheme: MapPropsFromTheme<Colors, string> = (color) => {
  return (props) => props.theme.colors[color];
};

export const getFontSizeFromTheme: MapPropsFromTheme<FontSize, string> = (
  size,
) => {
  return (props): string => props.theme.fontSize[size];
};

export const getFontWeightFromTheme: MapPropsFromTheme<FontWeight, number> = (
  weight,
) => {
  return (props) => props.theme.fontWeight[weight];
};

export const getLineHeightFromTheme: MapPropsFromTheme<LineHeight, number> = (
  lineHeight,
) => {
  return (props) => props.theme.lineHeight[lineHeight];
};
