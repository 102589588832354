import {useEmailInvitation} from '../../../hooks';
import {memo, useState} from 'react';
import styled, {css} from 'styled-components';
import {VerticalSpacer, HorizontalSpacer} from '../../atoms/spacer';
import {Label} from '../../atoms/label';
import {UserSelectField} from '@ui/molecules/select/user';
import {ItemLoaderLine} from '../item-loader-line';
import {ReactMultiEmail} from '@ui/molecules/multi-emails/multi-emails';
import {isEmail} from '@ui/molecules/multi-emails/isEmail';
import {MinusIcon} from '@ui/atoms/icons';
import 'react-multi-email/style.css';
import {MultiOptionsSelectFieldGroup} from '@ui/molecules/select/multi-options/multi-options';
import {CancelIcon} from '@ui/atoms/icons';
import {useMemo} from 'react';
import {Body1, Microcopy} from '@ui/atoms/typography';
import {AlertBadge} from '@ui/atoms/icons/alert-badge';
import {QuestionIconWrapper} from '@ui/molecules/tooltip/tooltip';
import {ifProp} from 'styled-tools';
import {InformationBlock} from '@ui/atoms/hint-block/hint-block';
import {AddMoreAction} from '../../molecules/add-more';
import {useStoreContext} from '../../../store/store-context';
import {TextField} from '@ui/molecules/field/textfield';
const Wrapper = styled.div`
  width: 100%;
  .email-wrapper {
    border-bottom: 1px solid #ededf2;
    margin-bottom: 15px;
  }
  .email-field {
    border: 1px solid #ededf2;
    width: 100%;
    margin-bottom: 15px;
    padding: 16px;
    border-radius: 10px;
  }
`;

const EmailWrapper = styled.div<{error?: boolean}>`
  width: 100%;
  .email-wrapper {
    border: ${({error}) => (error ? `1px solid #D52A2A` : `1px solid #cecede`)};
    border-radius: 10px;
    padding: 16px;
    width: 100%;
    min-height: 56px;
    display: flex;
    font-size: 16px;
    align-items: center;
    flex-wrap: wrap;
    .body1 {
      margin-top: 7px;
      @media (max-width: 370px) {
        margin-top: 0px;
      }
    }
    &::placeholder {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

const RemoveWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  cursor: pointer;
  color: #d52a2a;
  font-weight: 600;
  font-size: 14px;
  svg {
    fill: #d52a2a;
    stroke: #d52a2a;
    path {
      fill: #d52a2a;
      stroke: #d52a2a;
    }
  }
`;

const FooterSection = styled.div<{disabled?: boolean}>`
  display: flex;
  justify-content: space-between;
  p.addMore {
    color: #585adf;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
    ${ifProp(
      'disabled',
      css`
        color: #bfbfd4;
        font-style: normal;
        font-weight: normal;
        cursor: not-allowed;
      `,
    )};
  }
  p.clearFields {
    color: #5f5f8c;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    ${ifProp(
      'disabled',
      css`
        color: #bfbfd4;
        font-weight: normal;
        cursor: not-allowed;
      `,
    )};
  }
`;

export interface EmailsInvitationProps {
  setValue?: (values: string[]) => void;
  defaultValue: string[];
  errors?: any;
  control: any;
  handleSelectChangeGroups?: any;
  group?: any;
  disabled?: boolean;
  variant?: string;
}

export const TooltipBadge = ({message}: {message: string}) => {
  return (
    <QuestionIconWrapper tooltip={true}>
      <AlertBadge />
      <span
        className="tooltip"
        style={{
          width: '228px',
          left: '-25px',
          padding: '12px 8px',
        }}>
        {message}
      </span>
    </QuestionIconWrapper>
  );
};

export const EmailsInvitation = memo(function ({
  setValue,
  defaultValue,
  control,
  group,
  disabled,
  variant,
}: EmailsInvitationProps) {
  const {
    emails,
    addEmail,
    onChangeEmail,
    onChangeNewGroup,
    onChangeGroup,
    onChangeManager,
    // clearFields,
    remove,
  } = useEmailInvitation(defaultValue, setValue);

  const [openNewGroup, setOpenNewGroup] = useState(false);

  const {
    authStore: {auth},
    usersStore: {users},
  } = useStoreContext();

  const membersInStore = useMemo(() => {
    return users.map((member) => {
      return {
        label: {
          avatar: {
            name:
              member && member.firstName
                ? member.firstName + ' ' + member.lastName
                : member.email + ' (pending invitation)',
            src: member.avatar && member.avatar.url ? member.avatar.url : '',
          },
          id: member.id,
          name:
            member && member.firstName
              ? member.firstName + ' ' + member.lastName
              : member.email + ' (pending invitation)',
        },
        value: member.id,
      };
    });
  }, [users]);

  return (
    <Wrapper>
      <Body1 weight="bold">New member group</Body1>
      <VerticalSpacer size="10px" />
      {Array.from(emails).map(([key, email], index) => (
        <div className="email-wrapper">
          <div className="email-field bg-white">
            <Label>
              Email Address<span className="required">*</span>{' '}
            </Label>

            <MultiEmail
              onChangeEmail={onChangeEmail}
              email={email.value}
              keyValue={key}
            />
            <VerticalSpacer size="8px" />

            {!(variant && variant === 'signup') && group.length === 0 && (
              <ItemLoaderLine />
            )}

            {!(variant && variant === 'signup') && group.length > 0 && (
              <div>
                <Label>
                  Assign member(s) into what groups?
                  <span className="required">*</span>{' '}
                </Label>
                <VerticalSpacer size="8px" />
                <MultiOptionsSelectFieldGroup
                  control={control}
                  customOption={auth?.user?.role === 'admin'}
                  placeholder="Select group"
                  filterGroupReporting
                  searchPlaceholder="Search group"
                  performance={true}
                  defaultValue={email.group}
                  singleLabel={openNewGroup ? 'Add new group' : ''}
                  onClickCustomOption={() => {
                    setOpenNewGroup(true);
                    onChangeGroup([], key);
                  }}
                  customOptionName="Add new group"
                  onChange={(data: any) => {
                    setOpenNewGroup(false);
                    onChangeGroup(data, key);
                    if (email?.newGroup) {
                      onChangeNewGroup('', key);
                    }
                  }}
                  options={group}
                  feature="invite"
                  disabled={disabled}
                />
              </div>
            )}
            {openNewGroup && (
              <>
                <InformationBlock>
                  Be default, this new group will be added to the group type
                  “Departments”.{' '}
                </InformationBlock>
                <VerticalSpacer size="16px" />
                <TextField
                  placeholder="Enter group name"
                  inputStyle={{borderRadius: '10px'}}
                  onChange={(event) =>
                    onChangeNewGroup(event.target.value, key)
                  }
                />
              </>
            )}
            <VerticalSpacer size="16px" />

            <div className="manager">
              <Label>Member should be managed by?</Label>
              <UserSelectField
                name="manager"
                defaultValue={email.manager}
                // helper={errors.groupAdmin?.message || ''}
                // state={!!errors.groupAdmin ? 'error' : 'default'}
                onChange={(data: {value: string}) =>
                  onChangeManager(data.value, key)
                }
                label=""
                placeholder="Select member"
                options={membersInStore}
              />
            </div>

            {!(variant && variant === 'signup') && index > 0 && (
              <RemoveWrapper
                style={{margin: '10px 0'}}
                onClick={!disabled ? () => remove(key) : undefined}>
                <MinusIcon /> <HorizontalSpacer size="8px" /> Remove
              </RemoveWrapper>
            )}
          </div>
        </div>
      ))}

      <VerticalSpacer size="8px" />
      {variant && variant === 'signup' && (
        <AddMoreAction
          onClick={addEmail}
          disabled={disabled}
          title="Add more email addresses"
        />
      )}
      {!(variant && variant === 'signup') && (
        <FooterSection disabled={disabled}>
          {(emails as any)?.size <= 10 ? (
            <>
              <AddMoreAction
                onClick={!disabled ? addEmail : undefined}
                title={`Add member group`}
              />
            </>
          ) : (
            <div />
          )}
        </FooterSection>
      )}
    </Wrapper>
  );
});

const MultiEmail = ({
  onChangeEmail,
  email,
  keyValue,
}: {
  keyValue: string;
  email?: string[];
  onChangeEmail: Function;
}) => {
  const [error, setError] = useState('');

  return (
    <>
      <EmailWrapper error={!!error}>
        <ReactMultiEmail
          className="email-wrapper"
          placeholder={
            <>
              <Body1 kind="textBody" className="body1">
                Enter email address, separate by commas
              </Body1>
            </>
          }
          emails={email}
          onChange={(_emails: string[]) => {
            onChangeEmail(_emails, keyValue);
          }}
          onChangeInput={(value) => !value && setError('')}
          validateEmail={(email) => {
            const _email = email?.trim();

            if (
              !!_email &&
              !isEmail(email) &&
              _email.length > 0 &&
              _email !== 'undefined'
            ) {
              setError('Error. Input correct email format');
            } else {
              error && setError('');
            }

            return isEmail(email);
          }}
          key={keyValue}
          getLabel={(email, index, removeEmail) => {
            return (
              <div data-tag key={index} style={{background: '#F6F6F8'}}>
                <div
                  data-tag-item
                  style={{
                    color: 'black',
                    fontWeight: 400,
                    fontSize: '16px',
                  }}>
                  {email}
                </div>
                <span data-tag-handle onClick={() => removeEmail(index)}>
                  <CancelIcon />
                </span>
              </div>
            );
          }}
        />
        <VerticalSpacer size="5px" />
        {error && (
          <Microcopy kind="red400" weight="semibold">
            {error}
          </Microcopy>
        )}
      </EmailWrapper>
    </>
  );
};
EmailsInvitation.displayName = 'EmailsInvitation';
