import {Body2} from '@ui/atoms/typography';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {Radio} from '@ui/atoms/radio';
import {useStoreContext} from '@store/store-context';
import {MultiOptionsSelectFieldGroup} from '@ui/molecules/select/multi-options/multi-options';
import {useMemo, useState} from 'react';
import {HorizontalSpacer, VerticalSpacer} from '@ui/atoms/spacer';
import {ValueProps} from '../../create-review-interface';
import {FlexRow, FlexRowSpaceBetween} from '@ui/style/styles';

const RadioWrapper = styled(FlexRow)`
  border-radius: 10px;
  border: 1px solid var(--border-dark, #cecede);
  padding: 14px 16px;
  cursor: pointer;
`;

const NominalValue = styled(FlexRow)`
  border-radius: 10px;
  border: 1px solid var(--border-dark, #cecede);
  background: var(--others-white, #fff);
  margin: 10px 0px 24px 0px;
  width: 47%;
  .label {
    border-radius: 10px 0px 0px 10px;
    border-right: 1px solid var(--border-dark, #cecede);
    background: var(--background-dark, #f6f6f8);
    padding: 14px 16px;
  }
  .input {
    padding: 0px 8px;
  }
`;

type ReviewCycleSettingsProps = {
  cycleType: '360' | 'self' | '';
  handleChange: (field: keyof ValueProps, value: any) => void;
  currentValues: {
    repliesAreAnonymous: ValueProps['allowAnonymity'];
    excludedGroups: ValueProps['excludedGroups'];
  };
};

const ReviewCycleSettings = ({
  cycleType,
  handleChange,
  currentValues,
}: ReviewCycleSettingsProps) => {
  const {
    groupStore: {groups},
    groupTypeStore: {groupType},
  } = useStoreContext();

  const [repliesAreAnonymous, setRepliesAreAnonymous] = useState(
    currentValues.repliesAreAnonymous,
  );

  const handleRadioChange = () => {
    const value = !repliesAreAnonymous;
    handleChange('allowAnonymity', value);
    setRepliesAreAnonymous(value);
  };

  const computeGroups = useMemo(() => {
    return groupType.map((grpType) => {
      return {
        name: grpType.name,
        data: groups
          .filter((group) => group.groupType === grpType.id)
          .map((group) => {
            return {
              label: group.name,
              value: group.id,
            };
          }),
      };
    });
  }, [groupType, groups]);

  const nominationSettings = [
    {
      label: 'Min',
      value: 1,
    },
    {
      label: 'Max',
      value: 3,
    },
  ];

  return (
    <div>
      {cycleType === '360' && (
        <>
          <Body2 weight="bold">
            Peer Nomination settings{' '}
            <span style={{color: '#5F5F8C', fontWeight: 400}}>(default)</span>
          </Body2>
          <Body2 kind="textBody">
            Set how many people you want to participate
          </Body2>
          <FlexRowSpaceBetween>
            {nominationSettings.map((setting) => (
              <NominalValue key={setting.label}>
                <div className="label">
                  <Body2 kind="textMedium">{setting.label}</Body2>
                </div>
                <div className="input">
                  <Body2 kind="textMuted">{setting.value}</Body2>
                </div>
              </NominalValue>
            ))}
          </FlexRowSpaceBetween>

          <Body2 weight="bold">Anonymity settings</Body2>
          <VerticalSpacer size="6px" />
          <RadioWrapper onClick={handleRadioChange}>
            <Radio checked={repliesAreAnonymous} />{' '}
            <HorizontalSpacer size="10px" />
            <Body2 weight="semibold">
              Hide peer identity in concluded reviews
            </Body2>
          </RadioWrapper>
          <VerticalSpacer size="24px" />
        </>
      )}

      <Body2 weight="bold">
        Exclude groups{' '}
        <span style={{fontWeight: 400, color: '#5f5f8c'}}>(optional)</span>
      </Body2>
      <VerticalSpacer size="8px" />
      <MultiOptionsSelectFieldGroup
        options={computeGroups}
        performance={true}
        searchPlaceholder="Search group"
        groupDisplayLength={5}
        defaultValue={currentValues.excludedGroups?.map((group) => {
          return {
            value: group,
            label: groups?.find((value) => value?.id === group)?.name,
          };
        })}
        noOptionsMessage="Group not found"
        placeholder="Select Group"
        onChange={(data: any) => {
          handleChange(
            'excludedGroups',
            data.map((group: {label: string; value: string}) => group.value),
          );
        }}
      />
      <VerticalSpacer size="24px" />
    </div>
  );
};

export default observer(ReviewCycleSettings);
