import {
  forwardRef,
  ReactElement,
  Ref,
  useCallback,
  useEffect,
  useState,
} from 'react';
import ReactSelect, {ActionMeta} from 'react-select';
import {useDropDown} from '../../../../hooks';
import {motion} from 'framer-motion';
import {Label} from '../../../atoms/label';
import {Tooltip} from '@ui/molecules/tooltip';
import {FieldWrapper} from '../../../atoms/field-wrapper';
import {ArrowHeadDownIcon} from '@ui/atoms/icons';
import {Helper} from '../../../atoms/helper';
import {
  Wrapper,
  DropdownWrapper,
  DropWrapper,
  DropdownHandler,
  HandlerWrapper,
  ArrowIconWrapper,
  OptionsWrapper,
} from './styled';
import {Body1} from '@ui/atoms/typography';
import {MagnifyingGlassIcon} from '@ui/atoms/icons';
import {SelectFieldProps} from '../user-multi-select/user-multi-select';
import {InputState} from '../../../interface';
import {UserSelect3} from '../user-select-3';

export interface TOptionsSelectFieldProps extends SelectFieldProps {
  label?: string;
  placeholder?: string;
  state?: InputState;
  helper?: string | ReactElement;
}

export const GoalsSelectField = forwardRef(
  (
    {
      label,
      state,
      helper,
      placeholder,
      inputStyle,
      useDropdownIcon,
      useProgress,
      maxTitleLength,
      margin,
      options,
      onChange,
      value,
      defaultValue,
      disabled,
      ...rest
    }: SelectFieldProps,
    ref: Ref<ReactSelect<any>>,
  ) => {
    const {handleOpen, ref: dropRef, open, handleClose} = useDropDown();

    const getValue = options.find((option: any) => {
      if (defaultValue) {
        return option.value === defaultValue;
      }
      return null;
    });

    const [selectedOptions, setSelectedOptions] = useState<any>(() =>
      defaultValue ? getValue : [],
    );

    useEffect(() => {
      if (defaultValue) {
        if (getValue) setSelectedOptions(getValue);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue]);

    const handleChange = useCallback(
      (options: any, action: ActionMeta<any>) => {
        const formatOptions = {
          label: options.label?.goalName || options.label,
          value: options.value,
        };

        if (formatOptions.label !== 'custom') {
          setSelectedOptions(formatOptions);
        }

        handleClose();
        if (onChange) {
          onChange(formatOptions, action);
        }
      },
      [onChange, handleClose],
    );

    return (
      <FieldWrapper helper={helper} margin={margin}>
        <Wrapper>
          <Label aria-label="label">{label}</Label>
          <DropWrapper ref={dropRef}>
            <DropdownHandler
              open={open}
              onClick={handleOpen}
              style={inputStyle}
              helper={helper}
              disabled={disabled}>
              <HandlerWrapper>
                <OptionsWrapper disabled={disabled}>
                  {selectedOptions.value ? (
                    <Tooltip
                      BodyTextNodeType={Body1}
                      text={
                        selectedOptions.label?.goalName || selectedOptions.label
                      }
                      tooltipBody={
                        selectedOptions.label?.goalName || selectedOptions.label
                      }
                      withEllipsis
                      maxLength={maxTitleLength ?? 35}
                    />
                  ) : (
                    <p className="greyOut">{placeholder}</p>
                  )}
                </OptionsWrapper>
                <ArrowIconWrapper disabled={disabled}>
                  <motion.span
                    initial={{rotate: 0}}
                    animate={{
                      rotate: 0,
                      marginTop: '0px',
                    }}
                    transition={{
                      duration: 0.3,
                    }}
                    key="user-select-toggled">
                    {useDropdownIcon ? (
                      <ArrowHeadDownIcon />
                    ) : (
                      <MagnifyingGlassIcon />
                    )}
                  </motion.span>
                </ArrowIconWrapper>
              </HandlerWrapper>
            </DropdownHandler>
            {open && (
              <DropdownWrapper>
                <UserSelect3
                  {...rest}
                  onChange={handleChange}
                  value={selectedOptions}
                  useAllOptions
                  useProgress={useProgress}
                  defaultValue={defaultValue}
                  ref={ref}
                  placeholder={placeholder}
                  options={options}
                  disabled={disabled}
                />
              </DropdownWrapper>
            )}
          </DropWrapper>
          <Helper aria-label="helper" state={state} children={helper} />
        </Wrapper>
      </FieldWrapper>
    );
  },
);
