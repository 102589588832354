import {memo, SyntheticEvent, ReactNode, CSSProperties} from 'react';
import {CTA} from '../../../atoms/typography/cta';
import {ChevronGoalIcon} from '../../../atoms/icons/chevron-goal-down-icon';
import {Body1, Body2} from '../../../atoms/typography';
import {FlexRow} from '@ui/style/styles';
import {NestedViewIcon} from '../../../atoms/icons';
import {RadioChildSm} from '@ui/atoms/radio/radio';
import {cfv} from '@utils/framework';
import {QuestionIconWrapper} from '@pages/create-goal/create-goal.styles';
import {VerticalSpacer} from '../../../atoms/spacer';
import {
  Wrapper,
  HandlerWrapper,
  GoalTypeTag,
  PlusWrapper,
  MinusWrapper,
} from './style';
import {capitalizeGoalType} from '../../../../utils/capitalize';
import {MinusIcon, PlusIcon} from '../../../atoms/icons';

export interface EditableGoalTitleProps {
  goalType?: string;
  title: string;
  subTitle?: string | ReactNode;
  kind?: string;
  nestedIcon?: boolean;
  hasKeyResults: boolean;
  weight?: number;
  screen?: string;
  lineHeight?: string;
  hasAlignedGoals?: boolean;
  isGoalAligned?: boolean;
  goalGroups?: {name: string; id: string}[];
  isOpen: boolean | string;
  style?: CSSProperties;
  tabKind?: string;
  onClick: (e: SyntheticEvent<HTMLButtonElement>) => void;
  variant?: string;
  view?: string;
}

export const EditableGoalTitle = memo(function ({
  title,
  subTitle,
  kind,
  nestedIcon,
  goalType,
  screen,
  hasKeyResults,
  weight,
  isGoalAligned,
  lineHeight,
  goalGroups,
  hasAlignedGoals,
  style,
  isOpen,
  tabKind,
  onClick,
  variant,
}: EditableGoalTitleProps) {
  const renderToggler = () => {
    if (variant !== 'companyReportPDF') {
      if (hasKeyResults) {
        if (hasAlignedGoals) {
          return (
            <HandlerWrapper
              initial={{}}
              animate={{
                top: isOpen ? '-1px' : '0px',
              }}
              transition={{
                duration: 0.3,
              }}
              key="goal-toggle">
              {!isOpen && (
                <div style={{transform: 'rotate(-90deg)'}}>
                  <ChevronGoalIcon />
                </div>
              )}
              {isOpen && <ChevronGoalIcon />}
            </HandlerWrapper>
          );
        } else {
          return (
            <HandlerWrapper
              initial={{}}
              animate={{
                top: isOpen ? '-1px' : '0px',
              }}
              transition={{
                duration: 0.3,
              }}
              key="goal-toggle">
              {!isOpen && (
                <div style={{transform: 'rotate(-90deg)'}}>
                  <ChevronGoalIcon />
                </div>
              )}
              {isOpen && <ChevronGoalIcon />}
            </HandlerWrapper>
          );
        }
      } else {
        if (hasAlignedGoals && nestedIcon) {
          return (
            <HandlerWrapper
              initial={{marginRight: '3px', top: '3px'}}
              animate={{
                marginRight: '3px',
                top: '3px',
              }}
              transition={{
                duration: 0.3,
              }}
              key="goal-toggle">
              <>
                {!isOpen && (
                  <PlusWrapper>
                    <PlusIcon />
                  </PlusWrapper>
                )}
                {isOpen && (
                  <MinusWrapper>
                    <MinusIcon />
                  </MinusWrapper>
                )}
              </>
            </HandlerWrapper>
          );
        }
      }
    }
  };

  const renderAlignedIcon = () => {
    return (
      <QuestionIconWrapper tooltip={true} style={{marginLeft: '0px'}}>
        <RadioChildSm bg="#1A9E68" checked style={{padding: '2px'}} />
        <span
          className="tooltip"
          style={{
            top: '30px',
            left: '-70px',
            width: '230px',
            padding: '10px',
          }}>
          <Body1 kind="textBody">This is an aligned {cfv().g_oal}</Body1>
          <VerticalSpacer size="12px" />
          <Body2 kind="textBody">
            Click <NestedViewIcon /> on goal page to view.
          </Body2>
        </span>
      </QuestionIconWrapper>
    );
  };

  return (
    <div>
      {goalType && (
        <>
          {goalGroups?.length ? (
            <FlexRow>
              {goalGroups.map((group) => (
                <GoalTypeTag className="mr-3">
                  {capitalizeGoalType(group?.name || '')}
                </GoalTypeTag>
              ))}
            </FlexRow>
          ) : (
            <GoalTypeTag>
              {goalType && capitalizeGoalType(goalType)}
            </GoalTypeTag>
          )}

          <VerticalSpacer size="10px" />
        </>
      )}

      <Wrapper
        type="button"
        onClick={onClick}
        kind={kind}
        tabKind={tabKind}
        style={style ? style : screen === 'desktop' ? {width: 320} : {}}
        disabled={
          nestedIcon
            ? nestedIcon && !hasKeyResults && !hasAlignedGoals
            : !hasKeyResults
        }
        aria-label="view/hide-goal-metrics">
        {renderToggler()}
        <FlexRow>
          {kind === 'admin' ? (
            <span style={{paddingLeft: !hasKeyResults ? '8px' : '4px'}}>
              <Body2
                kind="textDark"
                style={{
                  fontWeight: weight || 400,

                  lineHeight: lineHeight,
                }}>
                {title}
                {(hasAlignedGoals || isGoalAligned) && renderAlignedIcon()}
              </Body2>
            </span>
          ) : (
            <span>
              <CTA
                kind="textDark"
                style={{
                  paddingLeft: !hasKeyResults ? '8px' : '4px',
                }}>
                {title}

                {(hasAlignedGoals || isGoalAligned) && renderAlignedIcon()}
              </CTA>
            </span>
          )}
        </FlexRow>
      </Wrapper>
      {subTitle &&
        variant !== 'companyTeams' &&
        (typeof subTitle === 'string' ? (
          <div
            style={{
              fontSize: '10px',
              color: '#5f5f8c',
              marginTop: '7px',
              marginLeft:
                nestedIcon && hasKeyResults
                  ? '20px'
                  : hasKeyResults
                  ? '16px'
                  : '8px',
            }}>
            ended {subTitle}
          </div>
        ) : (
          subTitle
        ))}
    </div>
  );
});
EditableGoalTitle.displayName = 'EditableGoalTitle';
