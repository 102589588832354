import styled, {css} from 'styled-components';
import {ifNotProp, ifProp} from 'styled-tools';
import {CSSProperties, memo, SyntheticEvent, useMemo} from 'react';
import {getColorFromTheme} from '../../ui-utils';
import {Body2} from '../../atoms/typography/body2';
import {
  CALENDAR_MONTHS,
  getDateISO,
  isDateBefore,
  isSameDay,
  isDateAfter,
  isSameMonth,
  useCalendar,
  WEEK_DAYS,
} from '../../../hooks';
import {HeaderActionSwitcher} from '../../atoms/nav-switcher';
import styledMap from 'styled-map';

const Wrapper = styled.div`
  overflow: hidden;
  border-radius: 10px;
  display: inline-block;
  width: 260px;
  box-shadow: 0 0 0 1px ${getColorFromTheme('borderLight')};
  background-color: ${getColorFromTheme('white')};
`;

const Content = styled.div`
  padding: 8px;
  box-shadow: 0 0 0 1px ${getColorFromTheme('borderLight')};
  display: block;
  border-radius: 10px;
`;

const Weekdays = styled.div`
  margin-bottom: 12px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
`;

const Days = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(6, 36px);
`;

const Week = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-content: center;
`;

const DateComponent = styled.button<{
  isToday?: boolean;
  isCurrent?: boolean;
  inMonth?: boolean;
  disabled?: boolean;
}>`
  display: grid;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-content: center;
  outline: none;
  border-radius: 10px;
  transition: 200ms ease-in-out all;

  &:focus,
  &:active {
    outline: none;
  }

  &[disabled] {
    cursor: not-allowed;
    background-color: ${getColorFromTheme('backgroundLight')};
    ${Body2} {
      color: ${getColorFromTheme('textMuted')};
    }
  }

  color: ${styledMap('inMonth', {
    false: getColorFromTheme('textMuted'),
  })};
  color: ${styledMap('disabled', {
    true: getColorFromTheme('textMuted'),
  })};

  ${ifNotProp(
    'isCurrent',
    css`
      &:hover {
        background-color: ${getColorFromTheme('backgroundDark')};
      }
    `,
  )}

  /*${ifNotProp(
    'inMonth',
    css`
      ${Body2} {
        color: ${getColorFromTheme('textMuted')};
      }
    `,
  )}*/
  
   

  ${ifProp(
    'isToday',
    css`
      background-color: ${getColorFromTheme('purple200')};
    `,
  )}

  ${ifProp(
    'isCurrent',
    css`
      background-color: ${getColorFromTheme('purple300')};

      ${Body2} {
        color: #fff;
      }
    `,
  )} /* ${ifProp(
    'disabled',
    css`
      cursor: not-allowed;
      background-color: ${getColorFromTheme('backgroundLight')};
      ${Body2} {
        color: ${getColorFromTheme('textMuted')};
      }
    `,
  )}*/
`;

export interface CalendarProps {
  value?: string;
  disabledAfter?: string;
  useValue?: boolean;
  onChange?: (value: Date) => void;
  disabledBefore?: string;
  style?: CSSProperties;
}

const SingleDate = memo(function ({
  date,
  today,
  current,
  month,
  year,
  onClick,
  disabled,
}: {
  date: (string | number)[];
  today: Date;
  current: Date;
  month: number;
  year: number;
  onClick: (e: SyntheticEvent<HTMLButtonElement>, date: Date) => void;
  disabled?: boolean;
}) {
  const _date: Date = new Date(date.join('-'));
  const isToday = isSameDay(_date, today);
  const isCurrent = current && isSameDay(_date, current);
  const inMonth =
    month && year && isSameMonth(_date, new Date([year, month, 1].join('-')));

  return (
    <DateComponent
      isToday={isToday}
      isCurrent={isCurrent}
      inMonth={!!inMonth}
      title={_date.toDateString()}
      type="button"
      onClick={(e) => onClick(e, _date)}
      disabled={disabled}>
      <Body2 kind="textDark">{_date.getDate()}</Body2>
    </DateComponent>
  );
});
SingleDate.displayName = 'SingleDate';

export const Calendar = memo(function ({
  value,
  onChange,
  disabledAfter,
  useValue,
  style,
  disabledBefore,
}: CalendarProps) {
  const {
    next,
    previous,
    month,
    year,
    getDates,
    today,
    current,
    handleDateSelection,
  } = useCalendar(value, onChange, useValue);

  const renderMonthYear = useMemo(() => {
    const monthName = Object.values(CALENDAR_MONTHS)[
      Math.max(0, Math.min(month - 1, 11))
    ];

    return `${monthName}. ${year}`;
  }, [month, year]);

  const renderWeekDays = useMemo(() => {
    return (
      <Weekdays>
        {Object.keys(WEEK_DAYS).map((day) => (
          <Week key={day}>
            <Body2 kind="textBody">{WEEK_DAYS[day]}</Body2>
          </Week>
        ))}
      </Weekdays>
    );
  }, []);

  const renderDate = useMemo(() => {
    return (
      <Days>
        {getDates.map((date) => (
          <SingleDate
            key={getDateISO(new Date(date.join('-')))}
            date={date}
            today={today.current}
            current={current}
            month={month}
            year={year}
            onClick={handleDateSelection}
            disabled={
              isDateBefore(new Date(date.join('-')), disabledBefore) ||
              isDateAfter(new Date(date.join('-')), disabledAfter)
            }
          />
        ))}
      </Days>
    );
  }, [
    getDates,
    today,
    current,
    month,
    year,
    handleDateSelection,
    disabledBefore,
    disabledAfter,
  ]);

  return (
    <Wrapper style={style}>
      <HeaderActionSwitcher
        next={{
          title: 'Next month',
          'aria-label': 'Change to next month',
          ...next,
        }}
        prev={{
          title: 'Previous month',
          'aria-label': 'Change to previous month',
          ...previous,
        }}
        title={renderMonthYear}
      />
      <Content>
        {renderWeekDays}
        {renderDate}
      </Content>
    </Wrapper>
  );
});

Calendar.displayName = 'Calendar';
