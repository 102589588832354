import {AxiosResponse} from 'axios';
import {
  ResetPasswordFormData,
  ResetPasswordResponse,
} from './reset-password-interface';
import {BaseRequest} from '../../configs/request';

export interface NewPasswordRequest extends BaseRequest {
  url: string;
  resetPassword(
    data: ResetPasswordFormData,
    token: string,
  ): Promise<ResetPasswordResponse>;
}

export class NewPasswordRequestImpl
  extends BaseRequest
  implements NewPasswordRequest {
  url: string = '/auth/reset-password';

  async resetPassword<SetupWorkspaceResponse>(
    data: ResetPasswordFormData,
    token: string,
  ) {
    const {data: responseData} = await this.request.post<
      ResetPasswordFormData,
      AxiosResponse<SetupWorkspaceResponse>
    >(this.url, {password: data.password, token});
    return responseData;
  }
}
