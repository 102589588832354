import dayjs from 'dayjs';
import {makeAutoObservable, observable, action} from 'mobx';
import {GoalsRequestImpl} from '../../api/goals-api/goals-request';
import {GoalsController} from '../../api/goals-api/goals-controller';
import type {WorkspaceCheckinsSettingsForm} from '../../pages/dashboard/admin/admin-components/admin-workspace/workspace-engagements/admin-checkins/admin-checkins.interface';
import type {
  ICheckinsCalendarWeekListResponseData,
  ICheckinsPulseResponseData,
  ICreateQuestionResponse,
  ICurrentCheckinsInfoResponseData,
} from '../../pages/dashboard/company-legacy/company-components/company-checkins/company-checkins.interface';

import {getWeekOfYear, getWeekRange} from '../../utils/date';
import {updateCheckinsGoals} from '../../utils/firebase-request';

export class CheckinsStore {
  @observable configuration: WorkspaceCheckinsSettingsForm = {
    generalFrequency: {
      week: '',
      frequency: '',
      activeCheckins: [],
    },
  };

  @observable questions: ICreateQuestionResponse['data'] = [];

  @observable workspaceCheckins: ICurrentCheckinsInfoResponseData = {};

  @observable checkinsPulse: ICheckinsPulseResponseData | null = null;

  @observable usersCheckins: any = {
    data: [],
    limit: 10,
    page: 1,
    summary: {},
    totalPages: 0,
    totalResults: 0,
  };

  @observable submittedUsersCheckins: any = {
    data: [],
    limit: 10,
    page: 1,
    summary: {},
    totalPages: 0,
    totalResults: 0,
  };
  @observable nonSubmittedUsersCheckins: any = {
    data: [],
    limit: 10,
    page: 1,
    summary: {},
    totalPages: 0,
    totalResults: 0,
  };

  @observable calendarWeeks: ICheckinsCalendarWeekListResponseData[] = [];
  @observable
  calendarWeeksDirectReports: ICheckinsCalendarWeekListResponseData[] = [];
  @observable usersCheckinsPaginator: any = {
    limit: 10,
    page: 1,
    totalPages: 1,
    totalResults: 0,
    pageNumberLimit: 1,
    maxPageNumberLimit: 2,
    minPageNumberLimit: 0,
  };
  @observable specificUserCheckins: any = {};
  @observable workspaceCheckinsGoals: any = [];
  @observable specificUserCheckinsGoals: any = [];

  @observable showCheckinsDrawer = '';

  @observable checkinsDates: any = {
    starts: dayjs(
      getWeekRange(getWeekOfYear(), new Date().getFullYear()).starts,
    ).format('MM/DD/YYYY'),
    ends: dayjs(
      getWeekRange(getWeekOfYear(), new Date().getFullYear()).ends,
    ).format('MM/DD/YYYY'),
    weekOfYear: getWeekOfYear(),
    year: new Date().getFullYear(),
    date: new Date(),
    month: new Date().getMonth(),
  };
  @observable monthCheckinsFilter = '';

  @observable questionsTemplates: any = [];
  @observable userGoals: any = [];

  @action
  fetchAuthFromLocalStorage = () => {
    const localStorageAuth = localStorage.getItem('auth');
    return localStorageAuth ? JSON.parse(localStorageAuth) : null;
  };

  @observable userFilter = {
    filterBy: {
      label: 'Teams',
      value: 'team',
    },
    selectedTeam: {
      value: this.fetchAuthFromLocalStorage()?.user?.id,

      label: 'Direct reports',
    },
    members: [] as any,
  };

  @observable requiredUserQuestions: any = [];
  @observable requiredCurrentPriority: any = new Map();

  @observable updatedGoalsFirebase: any = {
    weekOfYear: this.checkinsDates?.weekOfYear,
    updatedGoals: [],
  };

  @observable selectedYear = this.checkinsDates?.year;
  @observable pulseValidation: any = {
    error: null,
  };

  private request;
  private controller;

  constructor() {
    makeAutoObservable(this);
    this.request = new GoalsRequestImpl();
    this.controller = new GoalsController(this.request);
  }

  @action setUserFilter = (data: any) => {
    this.userFilter = data;
  };

  @action updateUserFilter = (data: any) => {
    this.userFilter = {...this.userFilter, ...data};
  };

  @action
  setCheckinsConfiguration = (configData: WorkspaceCheckinsSettingsForm) => {
    this.configuration = configData;
  };

  @action setUserGoals = (data: any) => {
    this.userGoals = data;
  };

  @observable updateCheckinsDrawer = (id: string) => {
    this.showCheckinsDrawer = id;
  };
  @action setMonthCheckinsFilter = (value: string) => {
    this.monthCheckinsFilter = value;
  };
  @action setRequiredUserQuestions = (data: any) => {
    this.requiredUserQuestions = data;
  };
  @action
  updatePerformanceCheckins = (goalId: string, key: any, performance: any) => {
    const updateGoalPerformance = this.workspaceCheckinsGoals?.findIndex(
      (goal: any) => goal.id === goalId,
    );
    this.workspaceCheckinsGoals[
      updateGoalPerformance
    ].performance = performance;

    const firebaseGoals = this.updatedGoalsFirebase?.updatedGoals
      ? this.updatedGoalsFirebase?.updatedGoals
      : (Object.values(this.updatedGoalsFirebase) as any)?.[0]?.updatedGoals;

    updateCheckinsGoals(JSON.parse(localStorage?.auth)?.user?.id, {
      weekOfYear: this.checkinsDates?.weekOfYear,
      year: this.checkinsDates?.year,
      updatedGoals: Array.from(new Set([...firebaseGoals, goalId])),
    });

    this.userGoals?.set(goalId, {updated: 'true'});
  };

  @action
  endGoalInCheckins = (data: any, goalId: string) => {
    const endedGoal = this.workspaceCheckinsGoals?.findIndex(
      (goal: any) => goal.id === goalId,
    );
    if (endedGoal !== -1) {
      this.workspaceCheckinsGoals[endedGoal].status =
        data.completed === true ? 'completed' : 'in_completed';

      const firebaseGoals = this.updatedGoalsFirebase?.updatedGoals
        ? this.updatedGoalsFirebase?.updatedGoals
        : (Object.values(this.updatedGoalsFirebase) as any)?.[0]?.updatedGoals;

      updateCheckinsGoals(JSON.parse(localStorage?.auth)?.user?.id, {
        weekOfYear: this.checkinsDates?.weekOfYear,
        year: this.checkinsDates?.year,
        updatedGoals: Array.from(new Set([...firebaseGoals, goalId])),
      });
      this.userGoals?.set(goalId, {updated: 'true'});
    }
  };

  @action updateUserGoals = (goalId: any) => {
    const updateGoalPerformance = this.userGoals.get(goalId);
    if (updateGoalPerformance) {
      updateGoalPerformance.updated = true;
      updateGoalPerformance.error = '';

      this.userGoals.set(goalId, updateGoalPerformance);
    }
  };
  @action updateUserGoalsError = (goalId: any) => {
    const updateGoalPerformance = this.userGoals.get(goalId);
    updateGoalPerformance.error =
      'Kindly update the status of your objective(s)';
    this.userGoals.set(goalId, updateGoalPerformance);
  };
  @action updateRequiredUserQuestionsError = (
    questionId: any,
    noError?: boolean,
  ) => {
    const updateRequiredQE = this.requiredUserQuestions.get(questionId);
    if (!noError) {
      updateRequiredQE.error = 'This is a required question';
    } else {
      updateRequiredQE.error = null;
    }
    this.requiredUserQuestions.set(questionId, updateRequiredQE);
  };

  @action updateRequiredCurrentPriorityError = (
    priorityId: any,
    noError?: boolean,
  ) => {
    const updateRequiredCP = this.requiredCurrentPriority.get(priorityId);

    if (!noError && updateRequiredCP) {
      return this.requiredCurrentPriority.delete(priorityId);
    }
    this.requiredCurrentPriority.set(priorityId, {
      error: '',
    });
  };

  @action updatePulseValidationError = (error: any) => {
    this.pulseValidation.error = error;
  };

  @action
  updateGoalValueCheckins = (
    goalId: string,
    id: string,
    key: any,
    value: any,
  ) => {
    // find the goal with goalID
    const updateGoalPerformance = this.workspaceCheckinsGoals?.findIndex(
      (goal: any) => goal.id === goalId,
    );

    // compare goalId and id if they are not same update key result
    if (goalId !== id) {
      if (this.workspaceCheckinsGoals?.length > 0) {
        this.workspaceCheckinsGoals[
          updateGoalPerformance
        ].keyResults = this.workspaceCheckinsGoals[
          updateGoalPerformance
        ].keyResults.map((result: any) => {
          if (result.id === id) {
            result.currentValue = Number(value);
            return result;
          }
          return result;
        });
      }
    } else {
      // update top level goal
      if (this.workspaceCheckinsGoals?.[updateGoalPerformance]) {
        this.workspaceCheckinsGoals[updateGoalPerformance].currentValue = value;
      }
    }
    // replace set value with updated value
  };
  @action
  updateGoalFieldCheckins = (
    goalId: string,

    value: any,
    field: string,
  ) => {
    // find the goal with goalID
    const updateGoalPerformance = this.workspaceCheckinsGoals?.findIndex(
      (goal: any) => goal.id === goalId,
    );

    if (this.workspaceCheckinsGoals?.[updateGoalPerformance]) {
      this.workspaceCheckinsGoals[updateGoalPerformance][field] = value;
    }

    // replace set value with updated value
  };
  @action
  updateGoalContextCheckins = (
    goalId: string,
    id: string,
    key: any,
    value: any,
  ) => {
    // find the goal with goalID
    const updateGoalPerformance = this.workspaceCheckinsGoals?.findIndex(
      (goal: any) => goal.id === goalId,
    );

    // compare goalId and id if they are not same update key result
    if (goalId !== id) {
      if (this.workspaceCheckinsGoals?.length > 0) {
        this.workspaceCheckinsGoals[
          updateGoalPerformance
        ].keyResults = this.workspaceCheckinsGoals[
          updateGoalPerformance
        ].keyResults.map((result: any) => {
          if (result.id === id) {
            result.contextValue = value;
            return result;
          }
          return result;
        });
      }
    } else {
      if (this.workspaceCheckinsGoals?.[updateGoalPerformance]) {
        this.workspaceCheckinsGoals[updateGoalPerformance].context = value;
      }
    }
  };

  @action
  saveUpdateCheckins = async (goalId: string) => {
    const updateGoal = this.workspaceCheckinsGoals?.find(
      (goal: any) => goal.id === goalId,
    );

    const goalIndex = this.workspaceCheckinsGoals?.findIndex(
      (goal: any) => goal.id === goalId,
    );

    const filterUpdate = {
      ...updateGoal,
      keyResults: updateGoal.keyResults.map((result: any) => {
        return {
          context: result?.contextValue || '',
          assignee: result.assignee,
          currentValue: result.currentValue,
          name: result.name,
          measurement: result.measurement,
          startValue: result.startValue,
          id: result.id,
          targetValue: result.targetValue,
        };
      }),
    };

    const response = await this.controller.updateGoal(
      filterUpdate,
      goalId,
      true,
    );
    if (response) {
      this.workspaceCheckinsGoals[goalIndex].currentValue =
        response?.currentValue;

      this.workspaceCheckinsGoals[
        goalIndex
      ].keyResults = updateGoal?.keyResults.map((result: any) => {
        return {
          contextValue: undefined,
          assignee: result.assignee,
          currentValue: result.currentValue,
          percentageProgress: result.percentageProgress,
          name: result.name,
          measurement: result.measurement,
          startValue: result.startValue,
          id: result.id,
          targetValue: result.targetValue,
        };
      });

      this.workspaceCheckinsGoals[goalIndex].valueUpdated = undefined;
    }
  };

  @action
  saveUpdateV2 = async (goalId: string) => {
    const updateGoal = this.workspaceCheckinsGoals?.find(
      (goal: any) => goal.id === goalId,
    );

    const goalIndex = this.workspaceCheckinsGoals?.findIndex(
      (goal: any) => goal.id === goalId,
    );

    const filterUpdate = {
      ...updateGoal,
      keyResults: updateGoal.keyResults?.map((result: any) => {
        return {
          ...result,
          context: result?.contextValue || '',
          contextValue: undefined,
          id: result.id,
        };
      }),
    };

    const response = await this.controller.updateGoal(filterUpdate, goalId);

    this.workspaceCheckinsGoals[goalIndex] = {
      ...response,
      assignee: updateGoal.assignee,
      keyResults: response?.keyResults.map((kr: {id: string}) => {
        const prevKr = updateGoal.keyResults.find(
          (_kr: {id: string}) => _kr.id === kr.id,
        );
        return {
          ...kr,
          assignee: prevKr?.assignee,
        };
      }),
      goalType: updateGoal.goalType,
    };
  };

  @action
  setQuestions = (response: ICreateQuestionResponse['data']) => {
    this.questions = response;
  };

  @action
  removeQuestionBatch = (batchId: string) => {
    if (!!this.questions) {
      this.questions = this.questions?.filter(
        (question) => question.batch !== batchId,
      ) as any;
    }
  };

  @action
  updateQuestionStatus = (batchId: string, status: string) => {
    if (!!this.questions) {
      this.questions = this.questions?.map((question) =>
        question.batch === batchId ? {...question, status} : question,
      ) as any;
    }
  };

  @action
  setWorkspaceCheckins = (checkins: ICurrentCheckinsInfoResponseData) => {
    this.workspaceCheckins = checkins;
  };
  @action
  setCheckinsPulse = (pulse: ICheckinsPulseResponseData) => {
    this.checkinsPulse = pulse;
  };

  @action
  setUsersCheckins = (usersCheckins: any) => {
    this.usersCheckins = usersCheckins;
    this.modifyUsersCheckinsPaginator('limit', usersCheckins?.limit);
    this.modifyUsersCheckinsPaginator('page', usersCheckins?.page);
    this.modifyUsersCheckinsPaginator('totalPages', usersCheckins?.totalPages);
    this.modifyUsersCheckinsPaginator(
      'totalResults',
      usersCheckins?.totalResults,
    );
  };

  @action
  setSubmittedUserCheckins = (usersCheckins: any) => {
    this.submittedUsersCheckins = usersCheckins;
  };

  @action
  setNonSubmittedUserCheckins = (usersCheckins: any) => {
    this.nonSubmittedUsersCheckins = usersCheckins;
  };

  @action
  setWorkspaceCheckinsGoals = (data: any) => {
    this.workspaceCheckinsGoals = data;
  };
  @action
  setSpecificUserCheckin = (data: any) => {
    this.specificUserCheckins = data;
  };
  @action
  setSpecificUserCheckinsGoals = (data: any) => {
    this.specificUserCheckinsGoals = data;
  };
  ///
  @action
  setCheckinsDate = (data: any) => {
    this.checkinsDates = {
      ...this.checkinsDates,
      ...data,
    };
  };

  @action
  nextYear = () => {
    this.checkinsDates.year = this.checkinsDates.year + 1;
  };

  @action
  prevYear = () => {
    this.checkinsDates.year = this.checkinsDates.year - 1;
  };
  @action
  setYear = (year: number) => {
    this.selectedYear = year;
  };

  @action
  initializeMonthInStore = (month: number, year: number) => {
    this.checkinsDates.year = year;
    this.checkinsDates.month = month;
  };

  @action
  nextMonth = () => {
    if (this.checkinsDates.month === 12) {
      this.checkinsDates.month = 1;
      this.checkinsDates.year = this.checkinsDates.year + 1;
    } else {
      this.checkinsDates.month = this.checkinsDates.month + 1;
    }
  };

  @action
  prevMonth = () => {
    if (this.checkinsDates.month === 1) {
      this.checkinsDates.month = 12;
      this.checkinsDates.year = this.checkinsDates.year - 1;
    } else {
      this.checkinsDates.month = this.checkinsDates.month - 1;
    }
  };
  @action
  setMonth = (month: number) => {
    this.checkinsDates.month = month;
  };
  ////
  @action
  modifyUsersCheckinsPaginator = (field: string, value: number) => {
    this.usersCheckinsPaginator[field] = value;
  };

  @action
  handleUsersCheckinsPaginatorPageClick = (event: any) => {
    this.modifyUsersCheckinsPaginator('page', Number(event.target.id));
  };

  @action
  handleUsersCheckinsPaginatorNextbtn = () => {
    this.modifyUsersCheckinsPaginator(
      'page',
      this.usersCheckinsPaginator.page + 1,
    );

    if (
      this.usersCheckinsPaginator.page + 1 >
      this.usersCheckinsPaginator.maxPageNumberLimit
    ) {
      this.modifyUsersCheckinsPaginator(
        'maxPageNumberLimit',
        this.usersCheckinsPaginator.maxPageNumberLimit +
          this.usersCheckinsPaginator.pageNumberLimit,
      );
      this.modifyUsersCheckinsPaginator(
        'minPageNumberLimit',
        this.usersCheckinsPaginator.minPageNumberLimit +
          this.usersCheckinsPaginator.pageNumberLimit,
      );
    }
  };

  @action
  handleUsersCheckinsPaginatorPrevbtn = () => {
    this.modifyUsersCheckinsPaginator(
      'page',
      this.usersCheckinsPaginator.page - 1,
    );
    if (
      (this.usersCheckinsPaginator.page - 1) %
        this.usersCheckinsPaginator.pageNumberLimit ===
      0
    ) {
      this.modifyUsersCheckinsPaginator(
        'maxPageNumberLimit',
        this.usersCheckinsPaginator.maxPageNumberLimit -
          this.usersCheckinsPaginator.pageNumberLimit,
      );
      this.modifyUsersCheckinsPaginator(
        'minPageNumberLimit',
        this.usersCheckinsPaginator.minPageNumberLimit -
          this.usersCheckinsPaginator.pageNumberLimit,
      );
    }
  };

  @action
  usersCheckinsPages = (totalResults: number, limit: number) => {
    const arr = [];
    for (
      let i = 1;
      i <=
      Math.ceil(
        this.usersCheckinsPaginator.totalResults /
          this.usersCheckinsPaginator.limit,
      );
      i++
    ) {
      arr.push(i);
    }
    return arr;
  };

  @action
  setCalendarWeeks = (data: ICheckinsCalendarWeekListResponseData[]) => {
    this.calendarWeeks = data;
  };
  @action
  setCalendarWeeksDirectReports = (
    data: ICheckinsCalendarWeekListResponseData[],
  ) => {
    this.calendarWeeksDirectReports = data;
  };

  @action
  setQuestionsTemplates = (data: any) => {
    this.questionsTemplates = data;
  };

  @action
  setUpdatedGoalsFirebase = (data: any) => {
    if (data === null) {
      this.updatedGoalsFirebase = {
        weekOfYear: this.checkinsDates?.weekOfYear,
        updatedGoals: [],
      };
    } else {
      this.updatedGoalsFirebase = data;
    }
  };

  @action
  archiveGoalInCheckins = (goalId: string) => {
    const archivedGoal = this.workspaceCheckinsGoals?.findIndex(
      (goal: any) => goal?.id === goalId,
    );
    if (archivedGoal !== -1) {
      this.workspaceCheckinsGoals = this.workspaceCheckinsGoals.filter(
        (goal: any) => goal?.id !== goalId,
      );
    }
  };

  @action
  deleteGoalInCheckins = (goalId: string) => {
    const deletedGoal = this.workspaceCheckinsGoals?.findIndex(
      (goal: any) => goal?.id === goalId,
    );
    if (deletedGoal !== -1) {
      this.workspaceCheckinsGoals = this.workspaceCheckinsGoals.filter(
        (goal: any) => goal?.id !== goalId,
      );

      updateCheckinsGoals(JSON.parse(localStorage?.auth)?.user?.id, {
        weekOfYear: this.checkinsDates?.weekOfYear,
        year: this.checkinsDates?.year,
        updatedGoals: Array.from(
          new Set([...this.updatedGoalsFirebase?.updatedGoals, goalId]),
        ),
      });
      this.userGoals?.set(goalId, {updated: 'true'});
    }
  };

  @action
  setSpecificUserCheckinsIsReviewed = (feedback?: string) => {
    if (Object.keys(this.specificUserCheckins)?.length > 0) {
      this.specificUserCheckins.isReviewed = true;
      this.specificUserCheckins.feedback = feedback;
    }
  };

  // Paginator end
}

export const checkinsStore = new CheckinsStore();
