import {BaseRequest} from '../../configs/request';

export interface ICloudConvertAPI extends BaseRequest {
  createJobs(data: any): Promise<any>;
  syncJobs(jobId: string): Promise<any>;
}

export class CloudConvertRequestImpl
  extends BaseRequest
  implements ICloudConvertAPI {
  async createJobs(data: any) {
    const {data: response} = await this.cloudConvertRequest.post(
      `${process.env.REACT_APP_CLOUD_CONVERT_BASE_URL}/jobs`,
      data,
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_CLOUD_CONVERT_API}`,
        },
      },
    );
    return response;
  }
  async syncJobs(id: string) {
    const {data: response} = await this.cloudConvertRequest.get(
      `${process.env.REACT_APP_CLOUD_CONVERT_SYNC_URL}/jobs/${id}`,
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_CLOUD_CONVERT_API}`,
        },
      },
    );
    return response;
  }
}
