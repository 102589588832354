import styled from 'styled-components';
import {memo, ReactNode, SyntheticEvent} from 'react';
import {HorizontalSpacer} from '../spacer';
import {Body2} from '../typography/body2';
import {CTA} from '../typography/cta';
import {getColorFromTheme} from '../../ui-utils';

const ContentLink = styled.a`
  display: flex;
  align-items: center;
  flex-direction: row;
  text-decoration: none;

  ${Body2} {
    margin-top: 5px;
    transition: color ease-in-out 300ms;
  }

  &:hover ${Body2} {
    color: ${getColorFromTheme('textDark')};
  }
`;
const ContentSpan = styled.span`
  display: flex;
  align-items: center;
  flex-direction: row;
  text-decoration: none;

  ${Body2} {
    margin-top: 5px;
    transition: color ease-in-out 300ms;
  }

  &:hover ${Body2} {
    color: ${getColorFromTheme('textDark')};
  }
`;

export interface ProfileLinkProps {
  heading: string;
  content: string;
  extra?: ReactNode;
  href?: string;
  onClick?: (e: SyntheticEvent<HTMLAnchorElement>) => void;
  notlink?: boolean;
}

export const ProfileLink = memo(function ({
  heading,
  content,
  href,
  extra,
  onClick,
  notlink,
}: ProfileLinkProps) {
  const Content = notlink ? (ContentSpan as any) : (ContentLink as any);
  return (
    <div>
      <CTA kind="textDark">{heading}</CTA>
      <Content href={href} onClick={onClick ? onClick : undefined}>
        <Body2 kind="textBody">{content}</Body2>
        <HorizontalSpacer size="8px" as="span" />
        {extra}
      </Content>
    </div>
  );
});
ProfileLink.displayName = 'ProfileLink';
