import {useStoreContext} from '@store/store-context';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {authStore} from '@store/stores/auth-store';
import {MoreVerticalIcon} from '@ui/atoms/icons';
import {DropdownVertical} from '@ui/molecules/dropdown-vertical';
import {activateNotification} from '@ui/molecules/notification/activate-notification';
import {userName} from '@utils/user-name';
import {useState} from 'react';
import nextId from 'react-id-generator';
import {MeetingBotResponse} from '@hooks';
import {useViewMeetingHook} from '../../view-meeting-hook';
import {SectionMenu} from '@ui/layouts/section-card/section-toggle';
import {MultiField} from '@ui/molecules/field/multifield';
import {PlusIconWrapper} from '@pages/1:1s/calendar-view';
import {FlexRow} from '@ui/style/styles';
import {HoverTooltip} from '@ui/molecules/hover-tooltip';
import {PlusSVG} from '@ui/atoms/svg';
import {Dropdown} from '@ui/molecules/dropdown';

interface MeetingAgendaProps {
  agenda: MeetingBotResponse['agenda'];
  participants: string[];
  updateMeetingResponse: (agenda: {text: string}[]) => void;
  agendaType: string;
  meetingId: string;
  host: string;
  prevAgenda: {text: string; completed: boolean}[];
}

export const MeetingAiAgenda = ({
  agenda,
  participants,
  meetingId,
  updateMeetingResponse,
  host,
  prevAgenda,
}: MeetingAgendaProps) => {
  const {
    usersStore: {users},
  } = useStoreContext();

  const texts = prevAgenda.map((task) => task.text);

  const [agendas, setAgenda] = useState(
    agenda
      .filter((_agenda) => !texts.includes(_agenda.text))
      .map((_agenda) => ({
        text: _agenda.text,
        completed: false,
        user: '',
        key: nextId(),
      })),
  );

  const {handleUpdateMeeting} = useViewMeetingHook();

  const formatAgendas = agendas.map((agenda) => ({
    ...agenda,
    assignee:
      typeof agenda.user === 'string'
        ? users.find((user) => user.id === agenda.user)
        : agenda.user,
  }));

  const addAgendaToMeeting = (fieldId: string, addAll?: boolean) => {
    const findAgenda = agendas.find((agenda) => agenda.key === fieldId);

    const isHost = host === authStore.auth.user.id;

    const agendaType = isHost ? 'hostAgenda' : 'participantAgenda';

    if (addAll) {
      return handleUpdateMeeting(meetingId, {
        [agendaType]: [
          ...prevAgenda,
          ...agendas.map((agenda) => ({
            text: agenda?.text || '',
            completed: false,
            participant: isHost
              ? undefined
              : typeof agenda?.user === 'string'
              ? agenda?.user || authStore.auth.user.id
              : (agenda?.user as any)?.id || authStore.auth.user.id,
          })),
        ],
      } as any).then(() => {
        activateNotification({
          title: 'Success',
          content: 'Added to note succesfully',
          kind: 'success',
        });
      });
    }

    handleUpdateMeeting(meetingId, {
      [agendaType]: [
        ...prevAgenda,
        {
          text: findAgenda?.text || '',
          completed: false,
          participant: isHost
            ? undefined
            : typeof findAgenda?.user === 'string'
            ? findAgenda?.user || authStore.auth.user.id
            : (findAgenda?.user as any)?.id || authStore.auth.user.id,
        },
      ],
    } as any).then(() => {
      activateNotification({
        title: 'Success',
        content: 'Added to note succesfully',
        kind: 'success',
      });
    });

    const updatedAgendas = agendas.filter(
      (_agenda) => _agenda.text !== findAgenda?.text,
    );

    setAgenda(updatedAgendas);
    updateMeetingResponse(updatedAgendas);
  };
  return (
    <div>
      <SectionMenu
        title="Agenda"
        count={agendas.length}
        disabled={!agendas.length}
        action={
          <>
            <DropdownVertical
              disabled={!agendas.length}
              customIcon={
                <MoreVerticalIcon
                  style={{stroke: !agendas.length ? '#bfbfd4' : ''}}
                />
              }
              dropdownWrapperStyle={{right: 0}}
              collapseOnClick
              menu={(handleClose: () => void) => (
                <div>
                  <DropdownItem
                    onClick={() => {
                      handleClose();
                      addAgendaToMeeting('', true);
                      setAgenda([]);
                      updateMeetingResponse([]);
                    }}>
                    Add all to notes
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      updateMeetingResponse([]);
                      setAgenda([]);
                      handleClose();
                    }}>
                    Delete all
                  </DropdownItem>
                </div>
              )}
            />
          </>
        }>
        <div className="py-6 pr-5">
          <MultiField
            checkedIndexes={[0]}
            label=""
            value={formatAgendas}
            placeholder="Add an agenda"
            updateValue={async (value_) => {
              const formattedValue =
                value_ &&
                value_.map((val) => {
                  return {
                    ...val.val,
                    key: val.key,
                  };
                });

              const formatAgenda = () => {
                return formattedValue?.map((agenda) => {
                  return {
                    ...agenda,
                    user:
                      agenda?.assignee ||
                      agenda?.user ||
                      authStore.auth?.user.id,
                    saved: undefined,
                  };
                });
              };

              const getHostAgenda = formatAgenda();

              if (getHostAgenda) {
                setAgenda(getHostAgenda);
                updateMeetingResponse(getHostAgenda);
              }
            }}
            id=""
            returnValueKey
            handleUserSelect={(user, fieldId) => {
              setAgenda((prev) => {
                const updatedItems = prev.map((item) =>
                  item.key === fieldId ? {...item, user: user || ''} : item,
                );

                return updatedItems;
              });
            }}
            fontWeight={400}
            margin
            showCheckbox
            disableCheckbox
            userCanReorder
            removeAddToList
            assigneeOptions={users
              .filter((user) => participants.includes(user.id))
              .map((user) => ({
                label: userName(user),
                value: user.id,
                avatar: user.avatar,
              }))}
            userId={authStore.auth.user.id}
            updateValueInstantly
            addAvatar
            colorString="textMedium"
            variant="youcheckins"
            dropdownComponent={(
              onHandleRemove: () => void,
              fieldId: string,
              indexOfFieldId: number,
            ) => (
              <FlexRow>
                <PlusIconWrapper
                  className="ml-3 mr-5"
                  onClick={() => {
                    addAgendaToMeeting(fieldId);
                  }}>
                  <HoverTooltip tooltipText="Add to note">
                    <PlusSVG />
                  </HoverTooltip>
                </PlusIconWrapper>

                <Dropdown
                  dropdownItemStyle={{whiteSpace: 'nowrap'}}
                  menu={[
                    {
                      content: `Delete`,
                      id: 'removeCurrent',
                    },
                  ]}
                  onClick={() => {
                    onHandleRemove();
                    const updatedAgendas = agendas.filter(
                      (_agenda) => _agenda.key !== fieldId,
                    );

                    setAgenda(updatedAgendas);
                    updateMeetingResponse(updatedAgendas);
                  }}
                  specificIndexDropdownItemStyles={[
                    {index: 1, style: {color: '#D52A2A'}},
                  ]}
                />
              </FlexRow>
            )}
          />
        </div>
      </SectionMenu>
    </div>
  );
};
