import {memo} from 'react';
import {CSSProperties} from 'styled-components';

type Props = {
  style?: CSSProperties;
  pathStyle?: CSSProperties;
  color?: string;
};
export const ChevronDownIcon = memo(({style, pathStyle, color}: Props) => (
  <svg
    width="7"
    height="4"
    fill="none"
    viewBox="0 0 7 4"
    style={style}
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.14645 3.64645L0.353553 0.853553C0.038571 0.538571 0.261654 0 0.707107 0L6.29289 0C6.73835 0 6.96143 0.53857 6.64645 0.853552L3.85355 3.64645C3.65829 3.84171 3.34171 3.84171 3.14645 3.64645Z"
      fill={color || '#242424'}
      style={pathStyle}
    />
  </svg>
));

ChevronDownIcon.displayName = 'ChevronDownIcon';
