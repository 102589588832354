import {authStore} from '@store/stores/auth-store';
import {capitalize} from '@utils';
import {checkinName} from '@utils/checkin-name';

function workspaceUseCase() {
  const useCase = authStore.auth.user.workspace.settings.useCase;

  const isAllUseCase = useCase === 'all';

  const isPerformanceActive = useCase === 'performance' || isAllUseCase;

  const isEngagementActive = useCase === 'engagement' || isAllUseCase;

  const auth = authStore.auth;

  const isCheckinEnabled =
    auth.user?.workspace?.settings?.checkInStatus === 'enabled';

  const isCcEnabled = auth?.user?.workspace?.settings?.ccStatus === 'enabled';

  const isKpiAllowed = auth.user.workspace.config.allowKpi;

  const isAlignmentEnabled = auth?.user?.workspace?.settings?.goalAlignment;

  const isActionItemEnabled =
    auth.user.workspace.settings.actionItemStatus === 'enabled' &&
    isPerformanceActive;

  const isFeedBackEnabled = auth.user.workspace.settings.feedback === 'enabled';

  const isOneOnOneEnabled = auth.user.workspace.settings.oneOnOne === 'enabled';

  return {
    isPerformanceActive,
    isEngagementActive,
    isCheckinEnabled,
    isActionItemEnabled,
    isFeedBackEnabled,
    isOneOnOneEnabled,
    isAlignmentEnabled,
    isCcEnabled,
    isKpiAllowed,
  };
}

export const masteryTasks = () => {
  const {
    isEngagementActive,
    isPerformanceActive,
    isKpiAllowed,
    isCheckinEnabled,
    isCcEnabled,
    isFeedBackEnabled,
    isActionItemEnabled,
    isOneOnOneEnabled,
    isAlignmentEnabled,
  } = workspaceUseCase();

  const tasks = [];

  if (isPerformanceActive) {
    tasks.push(
      {
        title: 'Create Objectives',
        duration: '0:47',
        tab: 'okrs',
        id: 'create_objective',
        url: '/create-goal',
        video: 'https://youtu.be/qXjYnCYA7M4',
        description:
          'Use objectives to boost productivity. Learn to create & track them in Beam.',
      },
      {
        title: 'Update Goals',
        duration: '0:36',
        id: 'update_objective',
        tab: 'okrs',
        url: '/objectives',
        video: 'https://youtu.be/FDF07u6qZRo',
        description: 'Update objective status or key result progress in Beam.',
      },
    );
  }

  if (authStore.auth.user.role === 'admin') {
    tasks.push(
      {
        title: 'Manage groups/Group types',
        duration: '0:32',
        id: 'manage_groups',
        tab: 'members',

        video: 'https://youtu.be/Dyar-pL0CI4',
        url: '/manage-people',

        description:
          "Create your org structure with Beam's group types and subgroups.",
      },
      {
        title: 'Invite members',
        duration: '0:20',
        id: 'invite_members',
        tab: 'members',
        url: '/invite-user',
        video: 'https://youtu.be/XcOjsO6LTfQ',
        description:
          'Add colleagues, managers & team members to begin progress reporting.',
      },
    );
  }
  if (
    authStore.auth.user.role === 'user' &&
    !authStore.auth.user.isReviewer &&
    isCheckinEnabled &&
    isEngagementActive
  ) {
    tasks.push({
      title: `Complete a ${checkinName()}`,
      duration: '0:35',
      tab: 'updates',
      id: 'complete_checkin',
      url: '/checkins',
      video: 'https://youtu.be/OtABBocVnRw',
      description:
        'Record your progress so far and inform your manager about your recent activities.',
    });
  }

  if (isEngagementActive) {
    if (isCheckinEnabled) {
      tasks.push({
        title: `Submit an ${capitalize(checkinName())} `,
        duration: '0:21',
        id: 'submit_checkin',
        video: 'https://youtu.be/Dl0jSe6xUYQ',
        tab: 'updates',
        url: '/checkins',
        description: `Report your progress and updates by submitting an update.`,
      });
    }

    if (isActionItemEnabled) {
      tasks.push({
        title: `Manage action items`,
        duration: '0:21',
        id: 'action_items',
        video: 'https://youtu.be/Dl0jSe6xUYQ',
        tab: 'updates',
        url: '/checkins',
        description: `Track, update, and prioritise your action items efficiently in Beam`,
      });
    }
  }
  if (
    (authStore.auth.user.role === 'admin' || authStore.auth.user.isReviewer) &&
    isEngagementActive &&
    isCheckinEnabled
  ) {
    tasks.push({
      title: `Review a submitted ${capitalize(checkinName())} `,
      duration: '0:21',
      id: 'review_checkin',
      video: 'https://youtu.be/Dl0jSe6xUYQ',
      tab: 'updates',
      url: '/checkins/direct-reports',
      description: `Evaluate and give feedback on updates from your direct report.`,
    });
  }
  if (
    authStore.auth.user.role === 'admin' &&
    isPerformanceActive &&
    isAlignmentEnabled
  ) {
    tasks.push(
      {
        title: 'Create aligned goals',
        duration: '0:32',
        id: 'aligned_goal',
        tab: 'okrs',
        url: '/create-alignment',
        video: 'https://youtu.be/aIAN-rAhsck',
        description:
          'Link objectives to parent goals & set progress update method.',
      },
      {
        title: 'Create cascading key results',
        duration: '0:51',
        id: 'cascade_keyresult',
        tab: 'okrs',
        url: '/cascade-keyresult',
        video: 'https://youtu.be/OPKMm9rROjI',
        description:
          'Link key results for auto progress updates via cascading. ',
      },
    );
  }
  if (isPerformanceActive && isKpiAllowed) {
    tasks.push({
      title: 'Create KPI Groups',
      duration: '0:32',
      id: 'kpi_groups',
      tab: 'kpis',
      url: '/create-alignment',
      video: 'https://youtu.be/aIAN-rAhsck',
      description:
        'Organise KPIs into groups for streamlined tracking and reporting.',
    });
  }
  if (
    authStore.auth.user.role === 'user' &&
    !authStore.auth.user.isReviewer &&
    isOneOnOneEnabled &&
    isEngagementActive
  ) {
    tasks.push({
      title: 'Create Meeting',
      duration: '0:34',
      tab: 'meeting',
      id: 'schedule_meeting',
      video: 'https://youtu.be/tzI5DFqiFiw',
      url: '/create-1:1',
      description:
        'Schedule 1:1 and team meetings to collaborate and discuss progress.',
    });
  }

  if (isFeedBackEnabled) {
    tasks.push(
      {
        title: 'Request a feedback',
        duration: '0:22',
        id: 'request_feedback',
        tab: 'feedback',
        url: '/feedback/my-feedback',
        video: 'https://youtu.be/Up-ziZowWRo',
        description:
          'Ask for feedback to gain insights and improve performance.',
      },
      {
        title: 'Create public Praise',
        duration: '0:22',
        id: 'request_feedback',
        tab: 'feedback',
        url: '/feedback/my-feedback',
        video: 'https://youtu.be/Up-ziZowWRo',
        description:
          'Recognise and celebrate achievements by creating public praise.',
      },
      {
        title: 'Write Feedback ',
        duration: '0:22',
        id: 'request_feedback',
        tab: 'feedback',
        url: '/feedback/my-feedback',
        video: 'https://youtu.be/Up-ziZowWRo',
        description: 'Provide constructive feedback to help others improve.',
      },
    );
  }

  if (authStore.auth.user.role === 'admin') {
    if (isPerformanceActive && isCcEnabled) {
      tasks.push({
        title: 'Run your first Review Cycle',
        duration: '0:28',
        id: 'generate_presentation',
        tab: 'cc-reviews',

        url: '/culture-competence-review/review-cycles#',
        video: 'https://youtu.be/CliqHb-99eY',
        description:
          'Launch your first review cycle to evaluate performance and set goals.',
      });
    }
  }
  if (isPerformanceActive && isKpiAllowed) {
    tasks.push({
      title: 'Create Key Performance Indicators (KPIs) ',
      duration: '0:34',
      id: 'create_kpi',
      tab: 'kpis',

      video: 'https://youtu.be/3Ltp9L6Psao',

      url: '/create-goal?kpi=true',
      description:
        'Track key business areas with KPIs & define how to report progress.',
    });
  }

  return tasks;
};
