import {memo, RefObject, useCallback, useState} from 'react';
import {InputAction} from '../../atoms/input-action';
import {EyeIcon} from '../../atoms/icons';
import {EyeOffIcon} from '../../atoms/icons';
import styled from 'styled-components';

export interface PasswordInputEyeToggleProps {
  inputRef: RefObject<HTMLInputElement>;
}

export const PasswordInputEyeToggle = memo(function ({
  inputRef,
}: PasswordInputEyeToggleProps) {
  const [visible, setVisible] = useState(inputRef.current?.type === 'password');

  const onClick = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.setAttribute(
        'type',
        inputRef.current.type === 'password' ? 'text' : 'password',
      );
      setVisible(!visible);
    }
  }, [inputRef, visible]);

  const FormButton = styled.span`
    line-height: 1.7;
    background-color: #ffffff;
    padding: 7px 5px 0 8px;
    outline: none;
  `;

  return (
    <InputAction
      children={
        visible ? (
          <FormButton>
            <EyeOffIcon />
          </FormButton>
        ) : (
          <FormButton>
            <EyeIcon />
          </FormButton>
        )
      }
      size="lg"
      kind="textDark"
      onClick={onClick}
    />
  );
});

PasswordInputEyeToggle.displayName = 'PasswordInputEyeToggle';
