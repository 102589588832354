import {memo, SyntheticEvent} from 'react';
import styled from 'styled-components';
import {up, down} from 'styled-breakpoints';
import {Button} from '../../atoms/button';
import {Body1, Headline2} from '../../atoms/typography';
import {VerticalSpacer} from '../../atoms/spacer';
import {Image} from '../../atoms/image';
import {ImageType} from '../../interface';
import {getColorFromTheme} from '../../ui-utils';
import {capitalize} from '../../../utils';

const Wrapper = styled.div<{imports: any}>`
  box-shadow: ${(imports) =>
    imports ? '0px' : `0 0 0 1px ${getColorFromTheme('borderLight')}`};
  border-radius: 10px;
  background-color: ${getColorFromTheme('white')};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: ${(imports) => (imports ? '' : '600px')};

  ${down('sm')} {
    padding: 34px 48px;
  }

  ${up('sm')} {
    padding: 56px 80px;
  }

  ${up('lg')} {
    padding: 70px 120px;
  }

  ${up('xl')} {
    padding: 70px 120px;
  }
`;

const ImageWrapper = styled.div`
  text-align: center;
  width: 160px;
  height: 160px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0faf6;
  img {
    width: 122px;
    height: 100px;
  }
`;

export interface CreateGoalModalProps {
  image: ImageType;
  imports?: boolean;
  goalFramework: string;
  onBoardingSuccess?: boolean;
  onCreateNew?: (e: SyntheticEvent<HTMLButtonElement>) => void;
  onExit: (e: SyntheticEvent<HTMLButtonElement>) => void;
}

export const CreateGoalModal = memo(function ({
  onExit,
  image,
  onBoardingSuccess,
  goalFramework,
  imports,
  onCreateNew,
}: CreateGoalModalProps) {
  return (
    <Wrapper imports={imports}>
      <ImageWrapper>
        <Image {...image} />
      </ImageWrapper>
      <VerticalSpacer size="12px" />
      <Headline2 align="center" kind="textDark">
        {capitalize(goalFramework)} published
      </Headline2>
      <VerticalSpacer size="16px" />
      <Body1 align="center" kind="textBody">
        True success is all about working towards meaningful goals and dreams.
      </Body1>
      <VerticalSpacer size="40px" />
      <Button onClick={onExit} style={{width: '200px'}} kind="primary">
        {onBoardingSuccess ? 'Continue' : 'Go to My Goals'}
      </Button>
      <VerticalSpacer size="8px" />
      {!imports && !onBoardingSuccess && (
        <Button
          type="button"
          width="full"
          kind="small-black"
          style={{color: '#585ADF'}}
          onClick={onCreateNew}>
          Create another {goalFramework}
        </Button>
      )}
    </Wrapper>
  );
});
