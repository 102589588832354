export const getPercentCompletedGoal = (goal: any) => {
  if (goal && goal?.keyResults && goal?.keyResults.length > 0) {
    return computeAverageOfSummedupCompletionPercentOfKeyResults(
      goal?.keyResults,
    );
  }

  return computeCompletionMBO(
    goal?.startValue,
    goal?.targetValue,
    goal?.currentValue,
  );
};

export const getPercentOfKeyResultsGoal = (goal: any) => {
  if (goal && goal?.keyResults && goal?.keyResults.length > 0) {
    return computeAverageOfSummedupPercentOfKeyResults(goal?.keyResults);
  }

  return computeCompletionMBO(
    goal?.startValue,
    goal?.targetValue,
    goal?.currentValue,
  );
};

export const computeCompletionMBO = (
  startValue: number,
  targetValue: number,
  currentValue: number,
) => {
  if (startValue === 0) {
    if (currentValue && currentValue !== null && currentValue !== undefined) {
      const numerator = currentValue - startValue;
      const proportion = numerator / targetValue;
      return Math.floor(proportion * 100);
    } else {
      const numerator = startValue;
      const proportion = numerator / targetValue;
      return Math.floor(proportion * 100);
    }
  } else {
    if (currentValue && currentValue !== null && currentValue !== undefined) {
      const numerator = currentValue - startValue;
      const denominator = targetValue - startValue;
      const proportion = numerator / denominator;
      return Math.floor(proportion * 100);
    } else {
      const numerator = startValue - startValue;
      const denominator = targetValue - startValue;
      const proportion = numerator / denominator;
      return Math.floor(proportion * 100);
    }
  }
};
export const computeAverageOfSummedupCompletionPercentOfKeyResults = (
  keyResults: any,
) => {
  const arrayOfCompletion = keyResults.map((keyResult: any) =>
    getPercentCompletedKeyResult(
      keyResult.startValue,
      keyResult.targetValue,
      keyResult.currentValue,
    ),
  );
  const parseCompletionArray = arrayOfCompletion.map((completion: any) =>
    completion >= 100 ? 100 : completion,
  );
  const totalSum = parseCompletionArray.reduce(
    (a: number, b: number) => a + b,
    0,
  );

  const proportion = totalSum / parseCompletionArray.length;
  return Math.round(proportion);
};

export const computeAverageOfSummedupPercentOfKeyResults = (
  keyResults: any,
) => {
  const sumCurrentValue = keyResults
    .map((o: any) => o.currentValue)
    .reduce((a: number, b: number) => {
      return a + b;
    });
  const sumTargetValue = keyResults
    .map((o: any) => o.targetValue)
    .reduce((a: number, b: number) => {
      return a + b;
    });
  const averageKeyResults = sumCurrentValue / sumTargetValue;

  return Math.floor(averageKeyResults * 100);
};

export const GoalProgress = (goal: any) => {
  let result = {percentage: 0, value: goal?.currentValue};
  if (goal?.keyResults && goal?.keyResults.length > 0) {
    goal?.keyResults.forEach(function (kR: any) {
      let diff = kR.targetValue - kR.startValue;
      let percent = 0;
      if (kR.currentValue > kR.targetValue) {
        percent = ((kR.targetValue - kR.startValue) / diff) * 100;
      } else {
        percent = ((kR.currentValue - kR.startValue) / diff) * 100;
      }
      result.percentage += Number(
        ((percent * 1) / goal?.keyResults.length).toFixed(2),
      );
    });
    result.percentage = Number(result.percentage.toFixed(0));
    const diff = goal?.targetValue - goal?.startValue;
    result.value = Number(
      (goal?.startValue + (result.percentage / 100) * diff).toFixed(0),
    );
  } else {
    const diff = goal?.targetValue - goal?.startValue;
    result.percentage = Number(
      (((goal?.currentValue - goal?.startValue) / diff) * 100).toFixed(0),
    );
    if (result.percentage > 100) {
      result.percentage = 100;
      result.value = goal?.targetValue;
    }
  }
  return result;
};

export const getPercentCompletedKeyResult = (
  startValue: number,
  targetValue: number,
  currentValue: number,
) => {
  return computeCompletionMBO(startValue, targetValue, currentValue);
};

export const getStatusPercentage = (
  statusGoals: number,
  totalGoals: number,
) => {
  const result = statusGoals / totalGoals;

  return !isNaN(result) ? Math.round(result * 100) : 0;
};
export const getStatusRateOfIncreaseBetweenDates = (
  _statusAtFirst: number,
  statusAtLast: number,
) => {
  return statusAtLast;
};

export const getPercentage = (count: number, highestValue: number) => {
  return (count / highestValue) * 100;
};
