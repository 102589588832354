import {Button} from '@ui/atoms/button';
import {Body2} from '@ui/atoms/typography';
import {CustomIconModal} from '@ui/organisms/modal/custom-icon-modal';

interface IDeleteTopic {
  onClose: () => void;
  handleDelete: () => void;
  topic: string;
}

export const DeleteSection = ({handleDelete, onClose, topic}: IDeleteTopic) => {
  return (
    <div>
      <CustomIconModal
        open
        onClose={onClose}
        customButton={
          <Button
            kind="secondary"
            width="full"
            onClick={handleDelete}
            type="button">
            Delete
          </Button>
        }
        body={<></>}
        onClick={() => {}}
        title={'Delete Note?'}
        description={
          <div>
            <Body2 align="center">
              Are you sure you want to delete{' '}
              <span className="text-[#585ADF] font-medium text-[16px]">
                {topic}
              </span>
              ? This action cannot be undone.
            </Body2>
          </div>
        }
      />
    </div>
  );
};
