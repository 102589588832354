import {AxiosResponse} from 'axios';
import {BaseRequest} from '../../configs/request';
import {authStore} from '../../store/stores/auth-store';

export interface IWorkspaceAPIRequest extends BaseRequest {
  fetchFeatureLimitStatus(): Promise<any>;
  fetchWorkspaceData(id: string): Promise<any>;
  updateWorkspace(data: any): Promise<any>;
}

export class WorkspaceAPIRequestImpl
  extends BaseRequest
  implements IWorkspaceAPIRequest {
  workspaceEndpoint: string = '/workspaces';

  async fetchFeatureLimitStatus() {
    const {data: response} = await this.request.get(
      `${this.workspaceEndpoint}/limit-status`,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return response;
  }
  async fetchWorkspaceData(id: string) {
    const {data: response} = await this.request.get(
      `${this.workspaceEndpoint}/${id}`,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return response;
  }

  async updateWorkspace(data: any) {
    const {data: responseData} = await this.request.patch<
      any,
      AxiosResponse<any>
    >(
      `${this.workspaceEndpoint}/${authStore?.auth?.user?.workspace?.id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return responseData;
  }
}
