import {SecondWrapperChildGoals} from './analytics-status.styles';
import {Avatars} from '@ui/molecules/avatars';

import {EditableGoalTitle} from '../../../../../../../ui/molecules/goal/editable-title';
import {useViewInsight} from '@hooks/insights';
import {GoalViewInsights} from '@ui/atoms/view-icon/view-icon';
import {Body2} from '@ui/atoms/typography';

export const KeyResultAnalytics = ({
  keyresult,
  open,
  setOpen,
  showProgress,
}: any) => {
  const viewGoalInsight = useViewInsight();

  const keyresultOwner = {
    label: {
      avatar: {
        name: keyresult.assignee?.firstName
          ? `${keyresult.assignee?.firstName} ${keyresult.assignee?.lastName}`
          : keyresult.assignee.email,

        src: keyresult.assignee?.avatar ? keyresult.assignee?.avatar?.url : '',
        id: keyresult.assignee?.id,
        reviewer: keyresult.assignee?.reviewer,
      },
    },
    id: keyresult.assignee.id,
    reviewer: keyresult.assignee?.reviewer,

    src: keyresult.assignee.avatar ? keyresult.assignee.avatar.url : '',
    name: keyresult.assignee.firstName
      ? `${keyresult.assignee.firstName} ${keyresult.assignee.lastName}`
      : keyresult.assignee.email,
  };
  return (
    <div style={{display: 'flex'}}>
      <SecondWrapperChildGoals
        removeBorder={open}
        style={{height: '110px'}}
        includeProgress={showProgress}>
        <div style={{width: '90%'}}>
          <EditableGoalTitle
            kind="admin"
            title={keyresult.name}
            weight={500}
            lineHeight={'20px'}
            hasKeyResults={false}
            isOpen={open}
            onClick={() => null}
          />
        </div>
        <div className="tooltip_container">
          <div>
            <Avatars items={[keyresultOwner]} total={1} />
          </div>
        </div>
        <div className="w-fit">
          <GoalViewInsights
            onClick={() =>
              viewGoalInsight(keyresult?.goal.id, keyresult?.goal.isKpi)
            }
            onHover
          />
        </div>
        {showProgress && (
          <div className="mr-9">
            <Body2 weight="semibold">{keyresult.percentageProgress}%</Body2>
          </div>
        )}
      </SecondWrapperChildGoals>
    </div>
  );
};
