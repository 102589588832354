import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {Button} from '@ui/atoms/button';
import {CancelIcon, ChevronDownIcon} from '@ui/atoms/icons';
import {Body2} from '@ui/atoms/typography';
import {DropdownCustom} from '@ui/molecules/dropdown-custom';
import {TextField} from '@ui/molecules/field/textfield';
import {FlexRowSpaceBetween} from '@ui/style/styles';
import {FC, useState} from 'react';
import {useDebouncedCallback} from 'use-debounce';

export const MeetingAdditionalFilterOptions = [
  {
    value: 'meetings',
    label: 'Meetings count',
  },
];

export const MeetingCountOptions = [
  {
    value: 'completedMeetings',
    label: 'Concluded',
  },
  {
    value: 'missedMeetings',
    label: 'Missed',
  },
];

interface MeetingCountProps {
  onChangeMeetingCount: (data: {meetingType: string; count: string}) => void;
}

export const MeetingCountFilter: FC<MeetingCountProps> = ({
  onChangeMeetingCount,
}) => {
  const [values, setValues] = useState({
    meeting: '',
    value: '',
  });

  const delayOnChange = useDebouncedCallback(
    (data) => onChangeMeetingCount(data),
    1000,
  );
  const handleChange = (field: keyof typeof values, value: string) => {
    const updatedValue = {...values, [field]: value};

    setValues(updatedValue);

    if (value[value.length - 1] !== ',')
      delayOnChange({
        meetingType: MeetingCountOptions.find(
          (option) => option.label === updatedValue.meeting,
        )?.value,
        count: updatedValue.value,
      });
  };

  return (
    <FlexRowSpaceBetween>
      <DropdownCustom
        collapseOnClick={true}
        menu={(handleClose: () => void) => (
          <div>
            <div>
              {MeetingCountOptions.map((option) => (
                <DropdownItem
                  onClick={() => {
                    handleClose();
                    handleChange('meeting', option.label);
                  }}>
                  {option.label}
                </DropdownItem>
              ))}
            </div>
          </div>
        )}
        customComponent={(handleOpen: (event: any) => void, open: boolean) => (
          <Button
            onClick={handleOpen}
            width="sm"
            kind="secondary"
            style={{padding: '6px 12px', borderRadius: '6px'}}
            className="py-2 px-3 flex flex-row justify-between items-center capitalize">
            <Body2
              kind={values.meeting ? 'textDark' : 'textBody'}
              weight={values.meeting ? 'semibold' : 'regular'}>
              {values.meeting || 'Select value'}
            </Body2>
            {values.meeting ? (
              <div
                className="cursor-pointer "
                onClick={() => handleChange('meeting', '')}>
                <CancelIcon style={{marginLeft: '14px', strokeWidth: 1.5}} />
              </div>
            ) : (
              <ChevronDownIcon
                style={{
                  marginLeft: 10,
                  stroke: '#1E1E2F',
                  transform: !open ? 'rotate(0deg)' : 'rotate(180deg)',
                }}
              />
            )}
          </Button>
        )}
        customDropdownWrapperStyles={{
          top: '130%',
          right: 0,
          width: '190px',
        }}
      />
      <TextField
        value={values.value}
        onChange={(event) => handleChange('value', event.target.value)}
        disabled={!values.meeting}
        inputStyle={{height: '34px', width: '94px'}}
        margin
      />
    </FlexRowSpaceBetween>
  );
};
