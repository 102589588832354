import {Body2, Headline2} from '@ui/atoms/typography';
import React, {FC, memo, useState} from 'react';
import {
  TableContainer,
  SecondRowWrapper,
  HeadlineWrapperContainer,
  HeadlineWrapper,
  HeadlineItemWrapper,
  ItemWrapperContainer,
  ModalRenameWrapper,
} from './styles';
import {FlexRow} from '@ui/style/styles';
import {Checkbox} from '@ui/atoms/checkbox';
import {UserListCard} from '@ui/molecules/user/list-card';
import dayjs from 'dayjs';
import {HorizontalDots} from '@ui/atoms/icons/horizontal-dots';
import {DropdownVerticalSecondary} from '@ui/molecules/dropdown-vertical';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {CustomIconModal} from '@ui/organisms/modal/custom-icon-modal';
import {
  removeFirebaseData,
  updateFirebaseData,
  writeFirebaseData,
} from '@utils/firebase-handler';
import {cloudinary} from '@assets/images/cloudinary';
import {EmptyPlaceholder} from '@ui/atoms/empty-placeholder';
import {useStoreContext} from '@store/store-context';
import {userName} from '@utils/user-name';
import {Button} from '@ui/atoms/button';
import {CancelIcon} from '@ui/atoms/icons';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {ModalCard} from '@ui/layouts/modal-card';
import {BackAction} from '@ui/molecules/back-action';
import {TextField} from '@ui/molecules/field/textfield';
import {Modal} from '@ui/molecules/modal';
import {Insight} from './interface';
import {Tooltip} from '@ui/molecules/tooltip';
import {handleInsightName} from './edit-insight/edit-insight-hook';

const {emptyGoals} = cloudinary;

const emptyPlaceholder = (filterActive?: boolean) => ({
  image: {
    alt: 'alt text',
    shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
    src: emptyGoals,
    style: {height: '30%', width: '30%'},
  },
  imports: true,
  // action: <
  subtitle: filterActive
    ? 'Refine your search or try something new.'
    : 'Insights will automatically generate as you create activities.',
  title: filterActive ? 'No results found' : 'No insights available',
});

interface Insights extends Insight {
  firebaseId: string;
}
interface InsightTableProps {
  data: Insights[];
  onCheck: (id: string) => void;
  handleAddToDashboard: (insights: string[]) => void;
  onEdit: (id: string) => void;
  filterActive?: boolean;
  selectedInsights: string[];
}

export const InsightTable: FC<InsightTableProps> = memo(
  ({
    data,
    onCheck,
    onEdit,
    selectedInsights,
    handleAddToDashboard,
    filterActive,
  }) => {
    const [deleteInsightId, setDeleteInsightId] = useState('');
    const {
      usersStore: {users},
      authStore: {auth},
    } = useStoreContext();

    const findUser = (userId: string) =>
      users.find((user) => user.id === userId);

    const [insightName, setInsightName] = useState('');

    const [renameInsightId, setRenameSectionModal] = useState('');

    const handleEditInsightName = () => {
      const insight = data.find(
        (_insight) => _insight.firebaseId === renameInsightId,
      );

      updateFirebaseData(`insights/${renameInsightId}`, {
        ...insight,
        name: insightName,
      }).then(() => {
        setInsightName('');
        setRenameSectionModal('');
      });
    };

    return (
      <div>
        <TableContainer>
          <div style={{overflowX: 'scroll'}}>
            <SecondRowWrapper>
              <HeadlineWrapperContainer className="header">
                <HeadlineWrapper>
                  <Body2 weight="bold">Name</Body2>
                  <Body2 weight="bold">Created by</Body2>
                  <Body2 weight="bold">Created</Body2>
                  <Body2 weight="bold">Last updated</Body2>
                  <Body2 weight="bold">Action</Body2>
                </HeadlineWrapper>
              </HeadlineWrapperContainer>

              {!data.length ? (
                <EmptyPlaceholder {...emptyPlaceholder(filterActive)} />
              ) : null}

              {data.map((insight, index) => (
                <ItemWrapperContainer key={index}>
                  <HeadlineItemWrapper>
                    <FlexRow className="gap-2">
                      <Checkbox
                        kind="import"
                        checked={selectedInsights.includes(insight.firebaseId)}
                        onChange={() => {
                          onCheck(insight.firebaseId);
                        }}
                      />
                      <div
                        className="cursor-pointer"
                        onClick={() => onEdit(insight.firebaseId)}>
                        <Tooltip
                          text={`${insight.name}`}
                          bodyStyle={{fontWeight: 500, fontSize: '14px'}}
                          withEllipsis
                          BodyTextNodeType={Body2}
                          // bodyTextNodeKind="textBody"
                          maxLength={30}
                          tooltipBody={<span>{insight.name}</span>}
                        />
                      </div>
                    </FlexRow>
                    <div>
                      <UserListCard
                        tooltip={!!insight.createdBy}
                        userId={insight.createdBy}
                        type="secondary"
                        avatar={findUser(insight.createdBy)?.avatar?.url || ''}
                        name={
                          insight.createdBy
                            ? userName(findUser(insight.createdBy))
                            : 'Beam'
                        }
                      />
                    </div>
                    <div>
                      <Body2 kind="textBody">
                        {dayjs(insight.createdAt).format('DD MMM. YY')}
                      </Body2>
                    </div>

                    <div>
                      <Body2 kind="textBody">
                        {dayjs(insight.updatedAt).format('DD MMM. YY')}
                      </Body2>
                    </div>

                    <div>
                      <DropdownVerticalSecondary
                        collapseOnClick
                        customIcon={<HorizontalDots />}
                        menu={(handleClose: () => void) => (
                          <div>
                            <DropdownItem
                              onClick={() => {
                                setRenameSectionModal(insight.firebaseId);
                                setInsightName(insight.name);
                                handleClose();
                              }}>
                              Rename
                            </DropdownItem>
                            {!insight.section && (
                              <DropdownItem
                                onClick={() => {
                                  handleAddToDashboard([insight.firebaseId]);
                                  handleClose();
                                }}>
                                Add to dashboard
                              </DropdownItem>
                            )}
                            {/* <DropdownItem onClick={() => {}} >
                              Export as .csv
                            </DropdownItem> */}
                            <DropdownItem
                              onClick={async () => {
                                handleClose();

                                const data = {
                                  ...insight,
                                  name: handleInsightName(
                                    insight.name,
                                    insight.name,
                                  ),
                                  updatedAt: dayjs().format(),
                                };

                                // @ts-ignore
                                delete data.firebaseId;

                                await writeFirebaseData(`insights`, {
                                  ...data,
                                  createdBy: auth.user.id,
                                  createdAt: dayjs().format(),
                                  updatedBy: auth.user.id,
                                });
                              }}>
                              Duplicate
                            </DropdownItem>
                            <DropdownItem
                              disabled={!insight.createdBy}
                              onClick={() => {
                                setDeleteInsightId(insight.firebaseId);
                                handleClose();
                              }}
                              // disabled
                            >
                              Delete
                            </DropdownItem>
                          </div>
                        )}
                      />
                    </div>
                  </HeadlineItemWrapper>
                </ItemWrapperContainer>
              ))}
            </SecondRowWrapper>
          </div>
        </TableContainer>

        <Modal
          open={!!renameInsightId}
          onClose={() => setRenameSectionModal('')}>
          <ModalRenameWrapper>
            <BackAction
              icon={<CancelIcon />}
              title="Close"
              onClick={() => setRenameSectionModal('')}
              width="md"
            />
            <VerticalSpacer size="16px" />
            <ModalCard title="">
              <div>
                <Headline2>Rename insight </Headline2>

                <VerticalSpacer size="20px" />
                <TextField
                  maxLength={48}
                  label="Title"
                  value={insightName}
                  placeholder="Enter title (max 48 characters)"
                  onChange={(event) => setInsightName(event.target.value)}
                  name="name"
                />

                <VerticalSpacer size="14px" />

                <Button
                  kind="primary"
                  width="full"
                  onClick={() => handleEditInsightName()}
                  data-form-action={true}
                  disabled={!insightName || insightName.length > 48}>
                  Create
                </Button>
              </div>
            </ModalCard>
          </ModalRenameWrapper>
        </Modal>

        <CustomIconModal
          open={!!deleteInsightId}
          onClose={() => setDeleteInsightId('')}
          onClick={() => {
            removeFirebaseData(`insights/${deleteInsightId}`);
            setDeleteInsightId('');
          }}
          title={'Delete Insight'}
          description={
            <Body2 align="center">
              Are you sure you want to delete insight? This action cannot be
              undone.
            </Body2>
          }
        />
      </div>
    );
  },
);
