import {observer} from 'mobx-react';
import {Dispatch, SetStateAction, useEffect, useMemo, useState} from 'react';
import {useStoreContext} from '../../../../../../../../store/store-context';
import {VerticalSpacer} from '../../../../../../../../ui/atoms/spacer';
import {
  Body1,
  Body2,
  Headline3,
} from '../../../../../../../../ui/atoms/typography';
import {PlansSection, Wrapper} from './billing-not-active.styles';
import {BillingPlans} from './components/billing-plans';
import {BillingSeats} from './components/billing-seats';
import {ToggleSwitch} from '@ui/atoms/toggle-switch';

interface IAdminBillingNotActive {
  setShowStarterPlanCTA: Dispatch<SetStateAction<boolean>>;
}
export const AdminBillingNotActive = observer(
  ({setShowStarterPlanCTA}: IAdminBillingNotActive) => {
    const {
      billingsStore: {billingPlans, loader},
      billingRoutesStore: {InactiveBillingTabs},
    } = useStoreContext();

    const [billingCycle, setBillingCycle] = useState<'lifetime' | 'monthly'>(
      'monthly',
    );

    useEffect(() => {
      setShowStarterPlanCTA(true);
      return () => {
        setShowStarterPlanCTA(false);
      };
    }, [setShowStarterPlanCTA]);

    const Plans = useMemo(() => {
      const billingplans_ = [
        {
          name: 'Standard',
          currency: billingPlans?.currency,
          monthly: {
            unitCost: billingPlans?.growth?.monthly?.unit_cost,
          },
          yearly: {
            unitCost: billingPlans?.growth?.yearly?.unit_cost,
            yearlyUnitCost: billingPlans?.growth?.yearly?.yearly_unit_cost,
            yearlyDiscountPercent:
              billingPlans?.growth?.yearly?.yearly_discount_percent,
          },
          isVisible: billingCycle === 'monthly',
          features: [
            'Goals - KPI & OKRs Management',
            'Weekly Check-ins',
            '1:1 Meetings agenda, notes & automation',
            'Real-time feedback',
            'Feature customisation',
            'Report downloads & presentations',
            'Action Items management',
            'Dashboards',
          ],
          plan: 'growth',
          active: false,
        },
        {
          name: 'Lifetime',
          currency: billingPlans?.currency,
          monthly: {
            unitCost: (billingPlans?.lifetime?.unit_cost || 1) * 500,
          },

          isVisible: billingCycle === 'lifetime',
          features: [
            '1 - 500 users',
            'Goals - KPI & OKRs Management',
            'Weekly Check-ins',
            '1:1 Meetings agenda, notes & automation',
            'Real-time feedback',
            'Feature customisation',
            'Report downloads & presentations',
            'Action Items management',
            'Dashboards',
          ],
          plan: 'lifetime',
          active: false,
        },
      ];

      return billingplans_;
    }, [
      billingCycle,
      billingPlans?.currency,
      billingPlans?.growth?.monthly?.unit_cost,
      billingPlans?.growth?.yearly?.unit_cost,
      billingPlans?.growth?.yearly?.yearly_discount_percent,
      billingPlans?.growth?.yearly?.yearly_unit_cost,
      billingPlans?.lifetime?.unit_cost,
    ]);

    return (
      <Wrapper>
        <PlansSection>
          <Headline3 kind="textDark" align="left">
            Select a payment plan
          </Headline3>
          <VerticalSpacer size="8px" />
          <Body2 kind="textBody" align="left">
            Upgrade your account to unlock more possibilities
          </Body2>
          <div className="bg-white py-2 px-4 rounded-lg mt-7 border border-borderLight flex items-center">
            <Body2 weight="bold">Monthly</Body2>
            <div className="mx-2">
              <ToggleSwitch
                checked={billingCycle === 'lifetime'}
                labelStyle={{background: '#47B881'}}
                onChange={() => {
                  setBillingCycle((prev) =>
                    prev === 'lifetime' ? 'monthly' : 'lifetime',
                  );
                }}
              />
            </div>
            <Body2 weight="bold">Lifetime</Body2>
            <Body2 kind="purple300" weight="bold" className="ml-1">
              (save 75%)
            </Body2>
          </div>

          {InactiveBillingTabs.plans && (
            <BillingPlans
              plans={Plans as any}
              billingCycle={billingCycle}
              loader={loader.billingPlans as boolean}
              setShowStarterPlanCTA={setShowStarterPlanCTA}
            />
          )}
          <Body1 kind="textBody" className="mt-10">
            Purchasing Power Parity available for select countries. Save up to
            80%.{' '}
            <a
              href="mailto:Hi@peopebeam.co"
              className="text-[#585ADF] font-semibold">
              Contact Support
            </a>
          </Body1>
          {InactiveBillingTabs.seats && (
            <BillingSeats
            // plans={Plans}
            // loader={loader.billingPlans as boolean}
            // setShowStarterPlanCTA={setShowStarterPlanCTA}
            />
          )}
        </PlansSection>
      </Wrapper>
    );
  },
);
