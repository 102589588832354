import {getColorFromTheme} from '@ui/ui-utils';
import styled from 'styled-components';

export const Card = styled.div<{bg: string}>`
  .card-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
  }

  border-radius: 8px;
  border: 1px solid #ededf2;
  width: 100%;

  .compare {
    border-top: 1px solid #ededf2;
    display: flex;
    padding: 4px 12px;
    align-items: center;
    justify-content: space-between;
  }
  @media (max-width: 800px) {
    margin-bottom: 10px;
  }
`;

export const Value = styled.div<{bg: string}>`
  border: 1px solid ${({bg}) => bg};
  background-color: #f6f6fe;
  padding: 4px 12px;
  border-radius: 8px;
`;

export const TableContainer = styled.div`
  border-top: 1px solid #ededf2;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  max-width: 100%;
  overflow-x: hidden;
`;

export const ItemLoaderWrapper = styled.div`
  padding: 28px 12px 32px 22px;
  justify-content: center;
  width: 100%;
  border-top: 1px solid #ededf2;
`;

export const ItemWrapperContainer = styled.div``;

export const ItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 35% 30% 20% 15%;
  padding: 16px;
  border-top: 1px solid ${getColorFromTheme('borderLight')};
  align-items: center;
  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
export const LoadingWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const SecondRowWrapper = styled.div`
  width: 100%;

  max-width: 100%;
  overflow-x: auto;
  display: grid;
  &::-webkit-scrollbar {
    width: 2em;
    border-top: 1px solid #ededf2;
    height: 2.1em;
  }

  &::-webkit-scrollbar-track {
    background: #f6f8fa;
    border: 0.8em solid rgba(0, 0, 0, 0);
    background-clip: padding-box;

    -webkit-border-radius: 1.5em;

    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    height: 10em;
    border: 0.8em solid rgba(0, 0, 0, 0);
    background: #e5ebf1;
    border-radius: 10px;
    background-clip: padding-box;
    -webkit-border-radius: 1.5em;

    -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.025);
  }

  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  div.header {
    min-width: 718px;

    @media (max-width: 500px) {
      min-width: 500px;
    }
  }
`;

export const HeadlineWrapper = styled.div`
  display: grid;
  width: 100%;

  grid-template-columns: 32% 29% 20% 15%;
  padding-left: 16px;
`;

export const HeadlineWrapperContainer = styled.div`
  display: block;

  padding: 16px 16px 12px 0px;
  background-color: ${getColorFromTheme('backgroundLight')};
  white-space: nowrap;
  height: 52px;
`;
