import {memo, useRef} from 'react';
import styled from 'styled-components';

import {Body2, Headline4} from '../../atoms/typography';
import {Button} from '../../atoms/button';
import {SheetsIcon} from '../../atoms/icons/sheets';
import {UploadCircleIcon} from '../../atoms/icons/upload-circle';
import {Tooltip} from '../../molecules/tooltip';
import {CancelIcon} from '../../atoms/icons';
import {FakeProgressLine} from '../fake-progress-line';
const Wrapper = styled.div`
  width: 100%;
  .addMore {
    color: #585adf;
    font-style: normal;
    font-weight: normal;
  }
`;

const DownloadTemplateDiv = styled.div`
  display: grid;
  grid-template-columns: 60% 40%;
  grid-gap: 10px;
  padding: 2rem 0 2rem 0;
  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
`;

const UploadCSVDiv = styled.div`
  padding: 2rem 0 2rem 0;
`;

const HorizontalHr = styled.div`
  border: 0.5px solid #ededf2;
  opacity: 0.5;
`;
const UploadSection = styled.div`
  width: 100%;
  display: grid;
  margin-top: 30px;
  grid-template-columns: 48.9% 51.1%;
  grid-gap: 10px;
`;
const Uploader = styled.div`
  background: #fafafa;
  border: 1px dashed #cecede;
  border-radius: 10px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #cecede;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  span {
    color: #bfbfd4;
  }
  svg {
    stroke: #bfbfd4;
    g {
      path {
        border: unset;
        stroke: #bfbfd4;
      }
    }
  }
`;
const UploadProgress = styled.div`
  padding: 1rem;
  border: 1px solid #cecede;
  border-radius: 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  div.uploading {
    display: flex;
    .fake-loader {
      display: flex;
      flex-direction: column;
      width: 220px;
      p {
        margin-left: 10px;
      }
    }
  }
`;
const UploadPreview = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
  border: 1px solid #cecede;
  border-radius: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
`;
const HiddenInput = styled.input`
  display: none;
`;

export interface CSVInvitationProps {
  handleCSVUpload: any;
  csvFile: any;
  clearCSVFile: any;
  disabled?: boolean;
}

export const CSVInvitation = memo(function ({
  handleCSVUpload,
  csvFile,
  clearCSVFile,
  disabled,
}: CSVInvitationProps) {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const handleClick = (e: any) => {
    hiddenFileInput?.current?.click();
    e.preventDefault();
  };
  return (
    <Wrapper>
      <DownloadTemplateDiv>
        <div className="textBlock">
          <Headline4 style={{fontSize: 16, marginBottom: 8}}>
            Import users in bulk
          </Headline4>
          <Body2 style={{color: '#5F5F8C', lineHeight: '20.4px'}}>
            Use the bulk import tool to add new members. Download the CSV
            template to add email and groups.
          </Body2>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: ' flex-end',
            alignItems: 'center',
          }}>
          {' '}
          <a
            href="/beam-invite-csv-template.csv"
            download="beam-invite-csv-template.csv">
            <Button
              kind="secondary"
              width="sm"
              aria-label="download csv template"
              title="Download CSV template"
              type="button"
              disabled={disabled}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <SheetsIcon />{' '}
              <span style={{marginLeft: 10}}>Download csv template</span>
            </Button>
          </a>
        </div>
      </DownloadTemplateDiv>
      <HorizontalHr />
      <UploadCSVDiv>
        <Headline4 style={{fontSize: 16, marginBottom: 8}}>
          Upload csv file here
        </Headline4>
        <Body2 style={{color: '#5F5F8C'}}>
          The file must be in CSV format, not numbers or another format.
        </Body2>
        <UploadSection>
          {Object.keys(csvFile).length === 0 && (
            <Uploader onClick={handleClick}>
              <UploadCircleIcon />{' '}
              <span style={{marginLeft: 10}}>Upload csv file</span>
            </Uploader>
          )}
          {csvFile?.fakeProgress && (
            <UploadProgress>
              <div className="uploading">
                {' '}
                <SheetsIcon size="md" />
                <div className="fake-loader">
                  <span style={{marginLeft: 10}}>
                    <Tooltip
                      text={csvFile.name}
                      maxLength={15}
                      tooltipBody={<span>{csvFile.name}</span>}
                      withEllipsis
                    />
                  </span>
                  <FakeProgressLine />
                </div>
              </div>

              <span
                style={{marginLeft: '1rem', cursor: 'pointer'}}
                onClick={clearCSVFile}>
                <CancelIcon />
              </span>
            </UploadProgress>
          )}
          {!csvFile?.fakeProgress &&
            Object.keys(csvFile).length > 0 &&
            csvFile.name &&
            csvFile.base64 && (
              <UploadPreview>
                <SheetsIcon />{' '}
                <span style={{marginLeft: 10}}>
                  <Tooltip
                    text={csvFile.name}
                    maxLength={15}
                    tooltipBody={<span>{csvFile?.name}</span>}
                    withEllipsis
                  />
                </span>
                <span style={{color: '#1A9E68', marginLeft: '1rem'}}>
                  Completed
                </span>
                <span
                  style={{marginLeft: '1rem', cursor: 'pointer'}}
                  onClick={clearCSVFile}>
                  <CancelIcon />
                </span>
              </UploadPreview>
            )}
        </UploadSection>
      </UploadCSVDiv>
      <HiddenInput
        type="file"
        ref={hiddenFileInput}
        accept=".csv"
        onChange={handleCSVUpload}
        disabled={disabled}
      />
    </Wrapper>
  );
});
CSVInvitation.displayName = 'CSVInvitation';
