import {ArrowLeftIcon} from '@ui/atoms/icons';
import {HorizontalSpacer, VerticalSpacer} from '@ui/atoms/spacer';
import {Body1, Body2, Headline1} from '@ui/atoms/typography';
import {FlexRow, FlexRowSpaceBetween} from '@ui/style/styles';
import {Wrapper, HRule, CycleWidget, StyledRow, UserTag} from './style';
import {usePreviewCycleHook} from './preview-review-cycle.hook';
import {PreviewMilestone} from './components/preview-milestone';
import {useStoreContext} from '@store/store-context';
import {PreviewCycleDetails} from './components/preview-cycle-details';
import PageLayoutTemplate from '@ui/templates/page-template/page-layout';
import {useNavigate} from 'react-router';
import {PreviewCycleSettings} from './components/preview-cycle-settings';
import {useReviewCycleHook} from '../create-review-hook';
import {Button} from '@ui/atoms/button';
import {PlainButton} from '@ui/atoms/plain-button/plain-button';
import {useSearchParams} from 'react-router-dom';
import {observer} from 'mobx-react';
import {HorizontalSideRule} from '@ui/atoms/spacer/spacer';
import {CustomColorIcon} from '@ui/atoms/color-icons';
import {userName} from '@utils/user-name';
import {useEffect} from 'react';

export const PreviewReviewCycle = observer(() => {
  const navigate = useNavigate();
  const {
    groupStore: {groups},
    authStore: {auth},
    usersStore: {users},
  } = useStoreContext();

  const {handleCreateReview, loading} = useReviewCycleHook();

  const {
    previewData,
    noOfDepartments,
    getSelectedFrameworks,
    totalQuestions,
  } = usePreviewCycleHook();

  const [searchParams] = useSearchParams();
  const isTypeView = searchParams.get('type') === 'view';

  const {
    reviewType,
    cycleName,
    period,
    milestones,
    excludedGroups,
    allowAnonymity,
  } = previewData;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const groupsExcluded = groups
    .filter((group) => excludedGroups?.includes(group.id))
    .map((group) => group.name);

  const editData = (step?: number) => {
    navigate(
      {
        pathname: '/create-review-cycle',
        search: step ? `?step=${step}` : undefined,
      },
      {
        state: {
          data: JSON.stringify(previewData),
        },
      },
    );
  };

  const Questions = () => {
    return (
      <CycleWidget style={{height: '1020px'}} className="bg-white">
        <Body1 weight="bold" style={{fontSize: '18px'}}>
          Questions
        </Body1>
        <VerticalSpacer size="24px" />
        <FlexRowSpaceBetween>
          <Body2 kind="textBody" weight="semibold">
            Number of departments
          </Body2>
          <Body1 weight="bold">{noOfDepartments().count}</Body1>
        </FlexRowSpaceBetween>
        <VerticalSpacer size="10px" />
        <FlexRowSpaceBetween>
          <Body2 kind="textBody" weight="semibold">
            Total questions
          </Body2>
          <Body1 weight="bold">{totalQuestions()}</Body1>
        </FlexRowSpaceBetween>

        <HRule />
        <VerticalSpacer size="8px" />

        {groupsExcluded?.length > 0 && (
          <>
            {' '}
            <Body1 weight="bold" style={{fontSize: '18px'}}>
              Groups excluded
            </Body1>
            <VerticalSpacer size="16px" />
            <FlexRow style={{flexWrap: 'wrap'}}>
              {groupsExcluded.map((group) => (
                <div className="group">
                  <Body2 kind="textBody" weight="semibold">
                    {group}
                  </Body2>
                </div>
              ))}
            </FlexRow>
          </>
        )}
      </CycleWidget>
    );
  };

  const PreviewReviewView = () => {
    return (
      <StyledRow>
        <div className="first-column">
          <PreviewCycleDetails
            reviewCycleType={reviewType}
            isDisabled={['active', 'ended'].includes(previewData?.status || '')}
            reviewPeriod={period}
            editData={editData}
            frameworks={getSelectedFrameworks}
          />

          <VerticalSpacer size="16px" />

          <PreviewMilestone
            reviewCycleType={reviewType}
            editData={editData}
            isDisabled={['active', 'ended'].includes(previewData?.status || '')}
            reviewMilestone={
              milestones || {
                self: '',
                startDate: '',
                endDate: '',
                manager: '',
                finalize: '',
              }
            }
          />

          {reviewType === '360' && (
            <>
              <VerticalSpacer size="16px" />
              <PreviewCycleSettings
                anonymitySettings={allowAnonymity}
                isDisabled={['active', 'ended'].includes(
                  previewData?.status || '',
                )}
                editData={editData}
                nominationSettings={{max: 3, min: 1}}
              />
            </>
          )}
        </div>
        <Questions />
      </StyledRow>
    );
  };

  const getStatus = (status?: string) => {
    switch (status) {
      case 'active':
        return 'Active';

      case 'upcoming':
        return 'Inactive';
      case 'ended':
        return 'Ended';

      default:
        return 'Draft';
    }
  };

  // @ts-ignore
  const author = previewData.createdBy
    ? // @ts-ignore
      users.find((user) => user.id === previewData.createdBy)
    : auth.user;

  return (
    <div>
      <PageLayoutTemplate
        bodyStyle={{margin: 0}}
        leftAction={
          isTypeView ? null : (
            <PlainButton onClick={() => editData()}>
              <FlexRow>
                <ArrowLeftIcon />
                <HorizontalSpacer size="10px" />
                <Body2 weight="semibold">Go back</Body2>
              </FlexRow>
            </PlainButton>
          )
        }
        title={`Preview review cycle`}
        onClick={() => navigate('/culture-competence-review/review-cycles')}>
        <div>
          <Wrapper>
            <FlexRowSpaceBetween>
              <div>
                <Headline1>{cycleName}</Headline1>
                <FlexRow>
                  <Body2 kind="textBody">Created by:</Body2>

                  <Body2 weight="semibold" style={{marginLeft: '4px'}}>
                    {' '}
                    {userName(author)}
                  </Body2>
                  {author?.id === auth.user.id && (
                    <UserTag>
                      <Body2
                        weight="bold"
                        kind="textBody"
                        style={{fontSize: '12px'}}>
                        You
                      </Body2>
                    </UserTag>
                  )}
                  <HorizontalSideRule
                    size="18px"
                    style={{margin: '0px 18px 0px 10px'}}
                  />
                  <CustomColorIcon
                    color={
                      previewData?.status === 'active'
                        ? '#47B881'
                        : previewData?.status === 'ended'
                        ? '#d52a2a'
                        : '#EDEDF2'
                    }
                    height="8px"
                    width="8px"
                    margin={5}
                  />
                  <Body2 weight="bold">{getStatus(previewData?.status)}</Body2>
                </FlexRow>
              </div>
              <FlexRow>
                {!isTypeView && (
                  <Button
                    kind="secondary"
                    style={{
                      padding: '8px 16px',
                      minWidth: '120px',
                    }}
                    isLoading={loading === 'draft'}
                    onClick={() => handleCreateReview(previewData)}>
                    Save as draft
                  </Button>
                )}

                {!previewData.published && (
                  <Button
                    isLoading={loading === 'publish'}
                    style={{
                      padding: '8px 16px',
                      marginLeft: '6px',
                      minWidth: '120px',
                    }}
                    disabled={!milestones}
                    onClick={() => handleCreateReview(previewData, true)}>
                    Publish
                  </Button>
                )}
              </FlexRow>
            </FlexRowSpaceBetween>
            <HRule />
            <VerticalSpacer size="8px" />

            <PreviewReviewView />
            <VerticalSpacer size="24px" />
          </Wrapper>
        </div>
      </PageLayoutTemplate>
    </div>
  );
});
