import {VerticalSpacer} from '@ui/atoms/spacer';
import {Body2} from '@ui/atoms/typography';
import {CcRequestImpl} from '@api/cc-api/cc-request';
import {useCallback, useMemo} from 'react';
import {useQuery} from 'react-query';
import {useStoreContext} from '@store/store-context';
import {CcController} from '@api/cc-api/cc-controller';
import {MultiOptionsSelectField} from '@ui/molecules/select/multi-options';
import {ItemLoaderLine} from '@ui/organisms/item-loader-line';
import {observer} from 'mobx-react';
import {useNavigate} from 'react-router';

type ReviewFrameworkProps = {
  handleChange: (value: string[]) => void;
  reviewFrameworks: string[];
};
export const ReviewFramework = observer(
  ({handleChange, reviewFrameworks}: ReviewFrameworkProps) => {
    const navigate = useNavigate();

    const request = useMemo(() => new CcRequestImpl(), []);
    const controller = useMemo(() => new CcController(request), [request]);
    const {
      groupStore: {groups},
    } = useStoreContext();

    const fetchReviewFrameworks = useCallback(async () => {
      const response: any = await controller.fetchCcFramework();

      const computeResponse = response.map((data: any) => {
        return {
          label: data.allGroups
            ? 'All Groups'
            : groups.find((group) => group.id === data.groups[0])?.name,
          value: data.id,
        };
      });
      return [...computeResponse];
    }, [controller, groups]);

    const {data, isLoading} = useQuery(['cc-review-frameworks'], () =>
      fetchReviewFrameworks(),
    );

    if (isLoading) {
      return (
        <div>
          <ItemLoaderLine />
          <VerticalSpacer size="16px" />
        </div>
      );
    }

    return (
      <div>
        <Body2 weight="bold">Select C&C Framework</Body2>
        <VerticalSpacer size="8px" />
        <MultiOptionsSelectField
          performance={true}
          excludeCheckBox
          noOptionMessage={
            data && data?.length > 0 ? (
              'No options found'
            ) : (
              <Body2 kind="textBody" align="center">
                Your workspace hasn't set up a performance review framework yet.
              </Body2>
            )
          }
          customOption
          customOptionName="Add new C&C Framework"
          onClickCustomOption={() =>
            navigate('/workspace-settings/performance/cc-view-settings')
          }
          defaultValue={reviewFrameworks.map((framework) => {
            return {
              value: framework,
            };
          })}
          placeholder={'Select here'}
          options={data}
          onChange={(data: {value: string; label: string}[]) => {
            const allGroupSelected = data.find(
              (value) => value.label === 'All Groups',
            );

            if (allGroupSelected) {
              return handleChange([allGroupSelected.value]);
            }

            handleChange(data.map((item: any) => item.value));
          }}
          groupDisplayLength={4}
        />
      </div>
    );
  },
);
