import {ICreateGoalApiDataFormat, IResultArray, TKeyResult} from '../hooks';
import {CreateGoalFormData} from '../pages/create-goal/create-goal-interface';
import {MeasurementType} from '../ui/interface';

export const convertFormDataToAPIformat = (data: CreateGoalFormData) => {
  const {
    date,
    keyResults,
    manager,
    measurement,
    name,
    range,
    isKpi,
    status,
    currentValue,
    performance,
    goalType,
    goalAlignment,
    goalMeasurement,
    meta,
    group,
  } = data;

  // convert key results
  const convertKeyResult = () => {
    const resultArray: IResultArray[] = [];

    keyResults.forEach((keyresult) => {
      const baseCommonData = {
        name: keyresult.name,
        measurement: {
          unit: keyresult.measurement.unit.toLowerCase(),
          symbol:
            keyresult.measurement &&
            keyresult.measurement.unit.toLowerCase() === 'currency'
              ? keyresult.measurement.symbol || 'ngn'
              : undefined,
        },
        assignee: keyresult.user,
        id: keyresult.id,
        startValue: keyresult.range ? keyresult.range?.start : 0,
        targetValue: keyresult.range ? keyresult.range?.target : 0,

        source:
          keyresult.source === null
            ? null
            : keyresult.source?.keyId
            ? {
                app: keyresult.source.app,
                keyId: keyresult?.source.keyId,
              }
            : null,
        goodValue: keyresult.range ? keyresult.range?.good : undefined,
        stretchValue: keyresult.range ? keyresult.range?.stretch : undefined,
        currentValue: keyresult.source
          ? keyresult.source?.value
          : keyresult?.range?.current &&
            //@ts-ignore
            !(keyresult?.range?.current < keyresult?.range?.start)
          ? keyresult?.range?.current
          : keyresult?.range?.start,
      };

      switch (keyresult.measurement.unit) {
        case MeasurementType.BINARY:
          resultArray.push({
            ...baseCommonData,
            currentValue: keyresult?.range?.current || 0,
            startValue: 0,
            targetValue: 1,
          });
          break;
        case MeasurementType.CURRENCY:
          resultArray.push({
            ...baseCommonData,
            //TODO: currency: keyresult.range?.currency,
          });
          break;
        default:
          resultArray.push({
            ...baseCommonData,
          });
      }
    });

    return resultArray;
  };

  const convertedKeyresult: any =
    keyResults && keyResults.length > 0 ? convertKeyResult() : undefined;

  const commonData = {
    name,
    measurement: {
      unit: measurement.unit.toLowerCase(),
      symbol:
        measurement?.symbol && measurement.unit.toLowerCase() === 'currency'
          ? measurement.symbol || ''
          : undefined,
      preference: measurement?.preference ? measurement.preference : undefined,
    },
    assignee: manager,
    startDate: date.starts,
    goalMeasurement: goalMeasurement || 'all',
    isKpi: isKpi || false,
    endDate: date.ends,
    meta:
      meta?.description ||
      (meta?.tags && meta?.tags.length > 0) ||
      meta?.kpiType
        ? {
            tags: meta?.tags,
            description: meta?.description || undefined,
            kpiType: meta?.kpiType,
          }
        : undefined,
    keyResults: keyResults ? convertedKeyresult : undefined,
    startValue: range ? range.start : 0,
    targetValue: range ? range.target : 0,
    currentValue: currentValue || range ? range.current : 0,
    goalType: goalType.toLowerCase(),
    goalAlignment: goalAlignment,
    performance,
    group: group,
  };

  switch (measurement?.unit) {
    case MeasurementType.BINARY:
      return {
        ...commonData,
        startValue: 0,
        status,
        targetValue: 1,
      };
    case MeasurementType.CURRENCY:
      return {
        ...commonData,
        //TODO: currency: range.currency,
      };
    default:
      return {
        ...commonData,
      };
  }
};

export const convertAPIformatToFormData = (data: ICreateGoalApiDataFormat) => {
  const {
    endDate,
    startDate,
    id,
    keyResults,
    assignee,
    measurement,
    name,
    startValue,
    targetValue,
    currentValue,
    performance,
    meta,
    goalType,
    group,
    goalAlignment,
  } = data;

  // convert key results
  const convertKeyResult = () => {
    const resultArray: TKeyResult[] = [];

    keyResults.forEach((keyresult: IResultArray) =>
      resultArray.push({
        id: keyresult.id,
        name: keyresult.name,
        // @ts-ignore
        measurement: {
          unit: keyresult.measurement.unit.toUpperCase(),
          symbol:
            keyresult.measurement && keyresult.measurement.symbol
              ? keyresult.measurement.symbol.toUpperCase()
              : 'NGN',
        },
        user: keyresult.assignee,
        source: keyresult.source,
        saveSource: keyresult.source?.app ? true : undefined,
        showSource: keyresult.source?.app ? true : undefined,
        range: {
          start: keyresult.startValue ? keyresult.startValue : 0,
          target: keyresult.targetValue ? keyresult.targetValue : 0,
          current: keyresult.currentValue ? keyresult.currentValue : 0,
          good: keyresult.goodValue ? keyresult.goodValue : 0,
          stretch: keyresult.stretchValue ? keyresult.stretchValue : 0,
        },
        // currency: keyresult.range?.currency,
      }),
    );

    return resultArray;
  };

  const convertedKeyresult = convertKeyResult();

  return {
    id,
    name,
    performance,
    measurement: {
      unit: measurement.unit?.toUpperCase() || measurement,
      symbol:
        measurement && measurement.symbol
          ? measurement.symbol.toUpperCase()
          : '',
      preference: measurement?.preference,
    },
    manager: assignee,
    date: {
      starts: startDate,
      ends: endDate,
    },
    range: {
      start: startValue,
      target: targetValue,
      current: currentValue,
    },
    // currency: range.currency,
    meta,
    keyResults: convertedKeyresult,
    goalType: goalType,
    group,
    goalAlignment,
  };
};
