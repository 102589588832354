import {observer} from 'mobx-react';
import {lazy} from 'react';

const EmailUnverifiedPageComp = lazy(() =>
  import('./email-unverified-page').then((module) => ({
    default: module.EmailUnverifiedPage,
  })),
);

export const installEmailUnverifiedPage = () => {
  const Page = observer(() => <EmailUnverifiedPageComp />);
  return {
    Page,
  };
};
