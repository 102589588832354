import {observer} from 'mobx-react';
import {Navigate, Route, Routes} from 'react-router';

import {installSetupWorkspacePage} from '../pages/admin/setup-workspace/setup-workspace-install';

import {installAdminProfilePage} from '../pages/admin/admin-profile/admin-profile-install';
import {installAdminSignInPage} from '../pages/sign-in/sign-in-install';

import {installEmailSentPage} from '../pages/email-sent/email-sent-install';
import {installEmailUnverifiedPage} from '../pages/email-unverified/email-unverified-install';
import {installForgotPasswordPage} from '../pages/forgot-password/forgot-password-install';
import {installNewPasswordPage} from '../pages/reset-password/reset-password-install';
import {installPasswordCompletePage} from '../pages/password-complete/password-complete-install';
import {installPasswordConfirmationPage} from '../pages/password-confirmation/password-confirmation-install';
import {installMemberSignupPage} from '../pages/member-signup/member-signup-install';
import {lazy} from 'react';
import {installVerifyEmail} from '../pages/verify-email/verify-email-install';
import {installVerifyUser} from '../pages/verify-user/verify-user-install';
import {SetupWorkspaceSlugs} from '../pages/admin/setup-workspace/setup-workspace-page';
import {useIsRouteActive} from '@utils/route-href';

const AuthTemplate = lazy(() =>
  import('../ui/templates/authentication').then((module) => ({
    default: module.AuthTemplate,
  })),
);

export const RedirectTo = ({redirectTo}: {redirectTo: string}) => {
  const To = window.location.search
    ? `${redirectTo}/${window.location.search}`
    : redirectTo;

  return <Navigate to={To} replace />;
};

export const createRootRoutes = () => {
  const {Page: SetupWorkspace} = installSetupWorkspacePage();
  const {Page: AdminProfile} = installAdminProfilePage();
  const {Page: EmailSent} = installEmailSentPage();
  const {Page: EmailUnverified} = installEmailUnverifiedPage();
  const {Page: ForgotPassword} = installForgotPasswordPage();
  const {Page: NewPassword} = installNewPasswordPage();
  const {Page: PasswordComplete} = installPasswordCompletePage();
  const {Page: PasswordConfirmation} = installPasswordConfirmationPage();
  const {Page: AdminSignIn} = installAdminSignInPage();
  const {Page: MemberSignup} = installMemberSignupPage();
  const {Page: VerifyEmail} = installVerifyEmail();
  const {Page: VerifyUser} = installVerifyUser();

  const data: any = Object.fromEntries(
    new URLSearchParams(window.location.search),
  );

  if (data?.goal && data?.insight === 'true') {
    localStorage.setItem(
      'unseen_comment',
      `${window.location.pathname}/${window.location.search}`,
    );
  }

  const RootRoutes = observer(() => {
    const {isRouteActive} = useIsRouteActive();

    return (
      <>
        <AuthTemplate variant={isRouteActive('sign-up') ? 'full' : 'auth'}>
          <Routes>
            <Route path="/verify-user-invitation" element={<VerifyUser />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/member-signup" element={<MemberSignup />} />
            <Route
              path="/workspace-setup"
              element={
                <SetupWorkspace slug={SetupWorkspaceSlugs.workspaceSetup} />
              }
            />
            <Route
              path="/on-boarding"
              element={<SetupWorkspace slug={SetupWorkspaceSlugs.onBoarding} />}
            />

            <Route
              path="/choose-framework"
              element={
                <SetupWorkspace slug={SetupWorkspaceSlugs.chooseFramework} />
              }
            />
            <Route path="/email-sent" element={<EmailSent />} />
            <Route path="/email-unverified" element={<EmailUnverified />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<NewPassword />} />
            <Route path="/password-complete" element={<PasswordComplete />} />
            <Route
              path="/password-confirmation/:email"
              element={<PasswordConfirmation />}
            />
            <Route path="/" element={<RedirectTo redirectTo="/sign-in" />} />
            <Route path="/sign-in" element={<AdminSignIn />} />

            <Route path="*" element={<RedirectTo redirectTo="/" />} />

            <Route path="/sign-up" element={<AdminProfile />} />
          </Routes>
        </AuthTemplate>
      </>
    );
  });

  return {
    RootRoutes,
  };
};
