import {memo} from 'react';

interface IIndicatorIcon {
  type: string;
}
export const IndicatorIcon = memo(({type}: IIndicatorIcon) => (
  <>
    {type === 'alignee' ? (
      <svg
        width="32"
        height="178"
        viewBox="0 0 32 178"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M27 3.5L32 5.88675V0.113249L27 2.5V3.5ZM0 3.5H27.5V2.5H0V3.5Z"
          fill="#CECEDE"
        />
        <path d="M16 3V177.5" stroke="#CECEDE" />
      </svg>
    ) : type === 'child' ? (
      <svg
        width="17"
        height="177"
        viewBox="0 0 17 177"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12 174.5L17 176.887L17 171.113L12 173.5L12 174.5ZM1 174.5L12.5 174.5L12.5 173.5L1 173.5L1 174.5Z"
          fill="#CECEDE"
        />
        <path d="M1 0V174.5" stroke="#CECEDE" />
      </svg>
    ) : (
      <svg
        width="32"
        height="178"
        viewBox="0 0 32 178"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M27 3.5L32 5.88675V0.113249L27 2.5V3.5ZM0 3.5H27.5V2.5H0V3.5Z"
          fill="#CECEDE"
        />
      </svg>
    )}
  </>
));

IndicatorIcon.displayName = 'IndicatorIcon';
