import {memo} from 'react';

export const BeamLogoWithName = memo(() => {
  return (
    <svg
      width="86"
      height="28"
      viewBox="0 0 86 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="27.3684" height="28" rx="6" fill="#585ADF" />
      <path
        d="M8 20H14.1436C17.1524 20 19.0802 18.5231 19.0802 16.3245C19.0802 14.8643 18.1613 14.0755 16.8641 13.807C18.3415 13.4713 19.3684 12.4979 19.3684 10.9371C19.3684 9.22518 18.1973 8 15.2426 8H10.0359L8 20ZM11.9816 17.7007L12.4681 14.8643H13.7833C14.9904 14.8643 15.5129 15.2839 15.5129 16.1231C15.5129 17.0462 14.8283 17.7007 13.6031 17.7007H11.9816ZM12.8104 12.7664L13.2428 10.2825H14.54C15.5309 10.2825 15.9993 10.6014 15.9993 11.3734C15.9993 12.2797 15.4408 12.7664 14.2517 12.7664H12.8104Z"
        fill="white"
      />
      <path
        d="M34.421 20.7879H40.2502C43.5217 20.7879 45.2268 19.4573 45.2268 16.9311V16.854C45.2268 15.0028 44.2355 13.9807 42.3915 13.6143C43.9777 13.1708 44.6915 12.1102 44.6915 10.6639V10.5868C44.6915 8.23416 43.0855 7 39.9925 7H34.421V20.7879ZM37.5537 18.6281V14.6749H39.5959C41.3407 14.6749 42.0545 15.3306 42.0545 16.6033V16.6804C42.0545 17.9339 41.3407 18.6281 39.7545 18.6281H37.5537ZM37.5537 12.6694V9.1405H39.4571C40.964 9.1405 41.6381 9.66116 41.6381 10.8182V10.8953C41.6381 12.1295 41.0235 12.6694 39.4769 12.6694H37.5537Z"
        fill="#171717"
      />
      <path
        d="M52.0113 21C54.8466 21 56.631 19.7851 56.9681 17.6061H54.2517C54.0733 18.4545 53.419 19.0138 52.0906 19.0138C50.5242 19.0138 49.5924 18.0496 49.513 16.3719H56.9879V15.6006C56.9879 11.9945 54.6086 10.4711 51.932 10.4711C48.9182 10.4711 46.5985 12.5344 46.5985 15.7163V15.8705C46.5985 19.0909 48.8786 21 52.0113 21ZM49.5527 14.6556C49.7708 13.2286 50.6432 12.3994 51.932 12.3994C53.3 12.3994 54.0931 13.1322 54.1923 14.6556H49.5527Z"
        fill="#171717"
      />
      <path
        d="M61.7622 21C63.388 21 64.3001 20.3829 64.8949 19.573V20.7879H67.6905V14.2121C67.6905 11.4738 65.8862 10.4711 63.3484 10.4711C60.8105 10.4711 58.8674 11.5317 58.689 13.9229H61.4053C61.5243 13.0551 62.0398 12.4959 63.1699 12.4959C64.4785 12.4959 64.8552 13.1515 64.8552 14.3471V14.7521H63.6854C60.5131 14.7521 58.2924 15.6391 58.2924 17.9917C58.2924 20.1129 59.8786 21 61.7622 21ZM62.6148 19.0523C61.5639 19.0523 61.1079 18.5702 61.1079 17.8375C61.1079 16.7576 62.0794 16.4105 63.7449 16.4105H64.8552V17.259C64.8552 18.3774 63.8837 19.0523 62.6148 19.0523Z"
        fill="#171717"
      />
      <path
        d="M70.1581 20.7879H73.033V14.8292C73.033 13.4601 73.9252 12.7658 75.0355 12.7658C76.0467 12.7658 76.6614 13.3444 76.6614 14.6364V20.7879H79.5165V14.8292C79.5165 13.4601 80.4087 12.7658 81.519 12.7658C82.55 12.7658 83.1449 13.3444 83.1449 14.6364V20.7879H86V14.3278C86 11.6474 84.5129 10.4711 82.5897 10.4711C81.2811 10.4711 79.9329 11.011 79.1001 12.2259C78.6044 11.0303 77.5734 10.4711 76.2054 10.4711C74.6588 10.4711 73.5485 11.3003 73.033 12.2452V10.7025H70.1581V20.7879Z"
        fill="#171717"
      />
    </svg>
  );
});
