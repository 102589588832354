import {CSSProperties, ReactElement} from 'react';
import {BackAction} from '@ui/molecules/back-action';
import {CancelIcon} from '@ui/atoms/icons';
import {Headline2, Body1} from '@ui/atoms/typography';
import {Flex} from '@ui/style/styles';
import {Button} from '@ui/atoms/button';
import {CustomColorIcon} from '@ui/atoms/color-icons';
import {ModalCard} from '@ui/layouts/modal-card';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {Modal} from '@ui/molecules/modal';

interface IModalPopup {
  open: boolean;
  onSubmit?: () => void;
  disabled?: boolean;
  loading?: boolean;
  title: string;
  modalStyle?: CSSProperties;
  description: string | ReactElement;
  onClick: () => void;
  customButton?: ReactElement;
  body?: ReactElement | string;
  onClose: () => void;
}

export const CustomIconModal = ({
  onSubmit,
  open,
  onClose,
  title,
  description,
  loading,
  modalStyle,
  customButton,
  disabled,
  onClick,
  body,
}: IModalPopup) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div style={{maxWidth: '500px', margin: 'auto'}}>
        <BackAction
          icon={<CancelIcon />}
          title="Cancel"
          onClick={onClose}
          width="md"
        />
        <VerticalSpacer size="16px" />
        <ModalCard align="center" title="" modalStyle={modalStyle}>
          <form style={{padding: '10px 30px'}} onSubmit={onSubmit}>
            <Flex alignItems="center" justifyContent="center">
              <CustomColorIcon
                color="#F7E4E4"
                width="80px"
                margin={0}
                height="80px"
              />
            </Flex>

            <VerticalSpacer size="24px" />
            <span>
              <Headline2 align="center">{title}</Headline2>
            </span>

            <VerticalSpacer size="16px" />
            {typeof description === 'string' ? (
              <Body1 kind="textBody" align="center">
                {description}
              </Body1>
            ) : (
              description
            )}

            {body}

            <VerticalSpacer size="24px" />
            <span>
              {customButton || (
                <Button
                  kind="primary"
                  width="full"
                  type="button"
                  isLoading={loading}
                  disabled={disabled}
                  style={{padding: '12px 24px'}}
                  onClick={onClick}
                  data-form-action={true}>
                  Confirm
                </Button>
              )}
            </span>
            <VerticalSpacer size="32px" />
          </form>
        </ModalCard>
      </div>
    </Modal>
  );
};
