import React, {useEffect, useState} from 'react';
import {
  HeadingWrapper,
  ImportErrorSpan,
  ImportErrorTable,
  ImportErrorTableItems,
} from './import-styles';
import {Headline2, Body2} from '../../ui/atoms/typography';
import {UploadCircleIcon} from '../../ui/atoms/icons/upload-circle';
import {CancelIcon, CsvIcon} from '../../ui/atoms/icons';
import {
  AdditionalWrapper,
  AdditionalErrorWrapper,
  ImportErrorWrapper,
  UploadWrapper,
  ButtonWrapper,
} from './import-styles';
import {EmptyPlaceholder} from '../../ui/atoms/empty-placeholder';
import {FakeProgressLine} from '../../ui/organisms/fake-progress-line';
import {cloudinary} from '../../assets/images/cloudinary';
import {Button} from '../../ui/atoms/button';
import Success from '../../assets/images/success.png';
import {Checkbox} from '../../ui/atoms/checkbox';
import {ImportHooks} from './import-hooks';
import {authStore} from '../../store/stores/auth-store';
import {VerticalSpacer} from '../../ui/atoms/spacer';

export default function BulkImport({setUploadSuccess}: any) {
  const {emptyGoals: emptyGoal} = cloudinary;
  const [file, setFile] = useState<any>('');
  const [percent, setPercent] = useState(0);
  const [base64, setBase64] = useState('');

  const [loading, setLoading] = useState(false);

  const {
    uploadFile,
    errors: error,
    setErrors,
    handleSubmit,
    uploads,
    validGoal,
    setuploads,
    isloading,
    uploaded,
  } = ImportHooks();

  const EmptyImport = {
    image: {
      alt: 'alt text',
      shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
      src: emptyGoal,
      style: {height: '30%', width: '30%'},
    },
    imports: true,
    subtitle: '',
    title: `A preview of any error would 
        be displayed here.`,
  };

  const ImportSuccess = {
    image: {
      alt: 'alt text',
      shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
      src: Success,
      style: {height: '30%', width: '30%'},
    },
    imports: true,
    subtitle: '',
    title: `Great! No errors were found, you’re now ready to publish.`,
  };

  const fileHandler = async (event: any) => {
    setFile(event.target.files[0]);
    const files = event.target.files[0];
    setLoading(true);
    setPercent(0);
    const reader: any = new FileReader();
    if (event.target.files[0]) {
      reader.readAsDataURL(files);
      reader.onloadend = async () => {
        if (!!reader.result) {
          const base64String = reader.result
            .replace('data:', '')
            .replace(/^.+,/, '');
          setBase64(base64String);
          setTimeout(async () => {
            await uploadFile(base64String, setPercent, setLoading, files);
          }, 2000);
        }
      };
    }
  };

  useEffect(() => {
    if (loading && percent < 40) {
      setTimeout(() => {
        setPercent(percent + 10);
      }, 400);
    }
    if (uploaded) {
      setUploadSuccess(true);
    }
  }, [loading, percent, uploaded, setUploadSuccess]);

  return (
    <>
      <div>
        <HeadingWrapper>
          <div>
            <Headline2
              style={{
                fontSize: '25px',
                fontWeight: 600,
                lineHeight: '32.5px',
              }}>
              Bulk Import
            </Headline2>
            <VerticalSpacer size="12px" />
            <Body2 kind="textBody">
              {' '}
              Use our import tool to add goals from a CSV file to jump <br />{' '}
              start your workspace setup.
            </Body2>
          </div>
          {authStore?.auth?.user?.workspace?.framework === 'okr' ? (
            <a
              href={'/Beam-goal-upload-template-OKR.xlsx'}
              download={'beam-goal-upload-template-okr.xlsx'}>
              <ButtonWrapper>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                  <CsvIcon />
                </div>
                <Body2
                  style={{
                    fontSize: '14px',
                    fontWeight: 500,
                    marginLeft: '10px',
                  }}>
                  Download template
                </Body2>
              </ButtonWrapper>
            </a>
          ) : authStore?.auth?.user?.workspace?.framework === 'mbo' ? (
            <a
              href={'/Beam-goal-upload-template-MBO.xlsx'}
              download={'beam-goal-upload-template-MBO.xlsx'}>
              <div
                style={{
                  display: 'flex',
                  border: '1px solid #CECEDE',
                  borderRadius: '8px',
                  alignItems: 'center',
                  height: '40px',
                  padding: '8px 16px',
                  justifyContent: 'space-between',
                  textAlign: 'center',
                }}>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                  <CsvIcon />
                </div>
                <Body2
                  style={{
                    fontSize: '14px',
                    fontWeight: 500,
                    marginLeft: '10px',
                  }}>
                  Download csv template
                </Body2>
              </div>
            </a>
          ) : (
            authStore?.auth?.user?.workspace?.framework === 'kpi' && (
              <a
                href={'/Beam-goal-upload-template-kpi.xlsx'}
                download={'beam-goal-upload-template-kpi.xlsx'}>
                <div
                  style={{
                    display: 'flex',
                    border: '1px solid #CECEDE',
                    borderRadius: '8px',
                    alignItems: 'center',
                    height: '40px',
                    padding: '8px 16px',
                    justifyContent: 'space-between',
                    textAlign: 'center',
                  }}>
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <CsvIcon />
                  </div>
                  <Body2
                    style={{
                      fontSize: '14px',
                      fontWeight: 500,
                      marginLeft: '10px',
                    }}>
                    Download csv template
                  </Body2>
                </div>
              </a>
            )
          )}
        </HeadingWrapper>
        <VerticalSpacer size="32px" />
        <UploadWrapper className="bg-white">
          <Headline2
            style={{fontSize: '16px', fontWeight: 500, lineHeight: '24px'}}>
            Upload CSV file here
          </Headline2>
          <VerticalSpacer size="12px" />
          <Body2 kind="textBody">
            The file must be in UTF-8 CSV format, not numbers or any other
            format.
          </Body2>

          <VerticalSpacer size="16px" />

          {file === '' ? (
            <AdditionalWrapper display={true}>
              <div className="flex items-center justify-center cursor-pointer text-center w-full h-full bg-white  ">
                <input
                  type="file"
                  onChange={fileHandler}
                  style={{
                    position: 'absolute',
                    opacity: 0,
                    cursor: 'pointer',
                    width: '53%',
                    height: '55px',
                  }}
                />
                <div style={{cursor: 'pointer', display: 'flex'}}>
                  <UploadCircleIcon />
                  <Body2 kind="textBody" weight="semibold">
                    Upload csv file
                  </Body2>
                </div>
              </div>
            </AdditionalWrapper>
          ) : (
            <AdditionalWrapper display={true}>
              <div
                style={{
                  background: 'rgba(250, 250, 250, 1)',
                  width: '100%',
                  display: 'flex',
                  height: '100%',
                  justifyContent: 'space-between',
                  textAlign: 'left',
                  alignItems: 'center',
                }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: '16px',
                    justifyContent: 'center',
                    textAlign: 'center',
                  }}>
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <CsvIcon />
                  </div>
                  <Body2
                    kind="textDark"
                    style={{
                      fontSize: '14px',
                      marginLeft: '5px',
                      textAlign: 'center',
                      fontWeight: 500,
                    }}>
                    {file?.name}{' '}
                  </Body2>
                </div>
                <div
                  onClick={() => {
                    setFile('');
                    setErrors([]);
                    setPercent(0);
                    setuploads(false);
                  }}
                  style={{
                    display: 'flex',
                    cursor: 'pointer',
                    alignItems: 'center',
                    paddingRight: '16px',
                    justifyContent: 'center',
                    textAlign: 'center',
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}>
                    <CancelIcon color="rgba(95, 95, 140, 1)" />
                  </div>
                  <Body2
                    kind="textBody"
                    style={{
                      fontSize: '14px',
                      lineHeight: '23.8px',
                      marginLeft: '5px',
                      fontWeight: 500,
                    }}>
                    Remove
                  </Body2>
                </div>
              </div>
            </AdditionalWrapper>
          )}

          <Body2 kind="textBody">Max file size of 10Mb</Body2>
          <VerticalSpacer size="32px" />
          <div className="bg-white rounded-[10px] border border-[ rgba(237, 237, 242, 1)] h-[70%] ">
            {file === '' && <EmptyPlaceholder {...EmptyImport} />}
            {loading && percent < 100 && (
              <div
                style={{
                  height: '300px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <div style={{width: '200px'}}>
                  <FakeProgressLine />
                </div>
                <VerticalSpacer size="12px" />
                <Body2
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    fontSize: '14px',
                    fontWeight: 500,
                  }}>
                  {percent < 30 ? 'Uploading...' : 'Checking for errors...'}
                </Body2>
              </div>
            )}
            {!loading && file !== '' && percent === 100 && (
              <>
                {error.length === 0 ? (
                  <EmptyPlaceholder {...ImportSuccess} />
                ) : (
                  error.length !== 0 &&
                  file !== '' && (
                    <div style={{padding: '16px'}}>
                      <AdditionalErrorWrapper display={true}>
                        <div
                          style={{
                            background: 'rgba(254, 246, 246, 1)',
                            width: '100%',
                            display: 'flex',
                            height: '100%',
                            justifyContent: 'center',
                            textAlign: 'center',
                            alignItems: 'center',
                          }}>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              paddingLeft: '16px',
                              justifyContent: 'center',
                              textAlign: 'center',
                              fontSize: '14px',
                              color: 'rgba(30, 30, 47, 1)',
                            }}>
                            Oops! Some errors were found.
                          </div>
                        </div>
                      </AdditionalErrorWrapper>
                      <div
                        style={{
                          marginTop: '20px',
                          border: '1px solid #EDEDF2',
                          borderRadius: '10px',
                        }}>
                        <ImportErrorTable>
                          <span>Row</span>
                          <span>Errors</span>
                        </ImportErrorTable>
                        <ImportErrorWrapper>
                          {error.map((err: any) => (
                            <ImportErrorTableItems>
                              <span>
                                <Body2 style={{textTransform: 'capitalize'}}>
                                  Row {err.rowNumber}
                                </Body2>
                              </span>
                              <ImportErrorSpan
                                style={{display: 'flex', overflowX: 'scroll'}}>
                                {err.errors
                                  .filter((i: any) => !i.rowNumber)
                                  .map((i: any) => (
                                    <div
                                      style={{
                                        border: '1px solid #EDEDF2',
                                        background: '#FAFAFA',

                                        borderRadius: '6px',
                                        padding: '4px 12px',
                                        alignItems: 'center',
                                        display: 'flex',
                                        margin: '5px',
                                        height: '28px',
                                      }}>
                                      <Body2
                                        style={{color: 'rgba(213, 42, 42, 1)'}}>
                                        {i}
                                      </Body2>
                                    </div>
                                  ))}
                              </ImportErrorSpan>
                            </ImportErrorTableItems>
                          ))}
                        </ImportErrorWrapper>
                      </div>
                    </div>
                  )
                )}
              </>
            )}
          </div>
          {error.length > 0 && validGoal !== 0 && (
            <div style={{display: 'flex', alignItems: 'center'}}>
              <VerticalSpacer size="64px" />
              <Checkbox
                checked={uploads}
                onChange={() => setuploads(!uploads)}
              />
              <Body2 kind="textBody" style={{paddingLeft: '8px'}}>
                Upload only error-free goals ({validGoal})
              </Body2>
            </div>
          )}
          {error.length === 0 || validGoal === 0 ? (
            <VerticalSpacer size="48px" />
          ) : null}
          <Button
            width="full"
            disabled={!uploads}
            isLoading={isloading}
            onClick={() => handleSubmit(base64, file)}>
            Upload
          </Button>
        </UploadWrapper>
      </div>
    </>
  );
}
