import {object, string, array} from 'yup';

export class CreateFeedbackValidator {
  static get createFeedback() {
    return object().shape({
      users: array().min(1, 'Please select a user'),

      feedbackFor: string()
        .max(45, 'Error:  Maximum of 45 characters.')
        .required("What's the feedback for "),
      feedback: string()
        .min(30, 'Error: Feedback must be at least 30 characters.')

        .required("What's the feedback for "),

      subjectName: string().required('fill in this field'),
      impression: string().required('Select a theme'),
      sharing: string().required('Who can see this feedback '),
    });
  }
  static get requestFeedback() {
    return object().shape({
      user: string().required('Please select a user'),

      feedbackFor: string().required("What's the feedback for "),

      subjectName: string().when(['feedbackFor'], {
        is: (subjectType: string) => subjectType === 'custom',
        then: string()
          .max(45, 'Maximum of 45 characters')
          .required('Input a reason for the feedback')
          .nullable(),
        otherwise: string().required('Select a goal').nullable(),
      }),
    });
  }
}
