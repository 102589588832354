import {Container} from './aligned-objectives';
import {useState, useEffect, useCallback, useMemo} from 'react';
import {useStoreContext} from '@store/store-context';
import {ItemLoader} from '@ui/organisms/item-loader';
import dayjs from 'dayjs';
import {Avatar} from '@ui/atoms/avatar';
import {Flex, FlexRowCenter} from '@ui/style/styles';
import {EmptyPlaceholder} from '@ui/atoms/empty-placeholder';
import {cloudinary} from '@assets/images/cloudinary';
import {Body1, Body2} from '@ui/atoms/typography';
import {useFreeTierHook} from '@hooks/free-tier';
import {LockIcon} from '@ui/atoms/icons';
import {Button} from '@ui/atoms/button';
import {illustrationList} from '@ui/assets/illustrations';
import FreeTierModal from '@ui/organisms/free-tier/free-tier-modal';
import {capitalize} from '@utils';
import {KeyResultContext} from '@ui/atoms/context-wrapper/key-result-context';
import {cfv} from '@utils/framework';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {ItemLoaderWrapper} from './aligned-objectives';
import {getFromBetween} from '@utils/get-between';
import {AnalyticsController} from '../../../../../reporting/reporting-pages/admin-analytics/admin-analytics.controller';
import {AnalyticsRequestImpl} from '../../../../../reporting/reporting-pages/admin-analytics/admin-analytics.request';
const {emptyGoals: emptyGoal} = cloudinary;
const commaNumber = require('comma-number');

export const useGoalActivityHook = (
  goalId: string,
  createdBy: string,
  updatedAt: string,
  isGoalKpi: boolean,
) => {
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(10);
  const [loadingFeed, setLoadingFeed] = useState(false);
  const [totalResults, setTotalResults] = useState(0);

  const [activities, setActivities] = useState<any>([]);

  const fetchActivities = useCallback(async () => {
    const request = new AnalyticsRequestImpl();
    const controller = new AnalyticsController(request);

    const data = await controller.fetchReportGoalActivitiesById(goalId, limit);

    if (data) {
      const computed = data.results.filter((result: any) => !!result.activity);

      const computeData = isGoalKpi
        ? computed
        : [
            ...computed.filter(
              (result: any) =>
                result.activity !==
                `[${createdBy}] created ${capitalize(
                  cfv(undefined, true).g_oal,
                )}`,
            ),
          ];

      setTotalResults(data.totalResults);

      setActivities(
        computeData.sort(
          (a: any, b: any) =>
            new Date(b.updatedAt).valueOf() - new Date(a.updatedAt).valueOf(),
        ),
      );
    }

    setLoading(false);
    setLoadingFeed(false);
  }, [goalId, createdBy, isGoalKpi, limit]);

  const handleScroll = (event: any) => {
    const {scrollHeight, scrollTop, clientHeight} = event.target;
    const isEndReached = scrollHeight - scrollTop === clientHeight;

    if (isEndReached) {
      if (!loadingFeed && totalResults > limit) {
        setLoadingFeed(true);
        setLimit((prev) => prev + 10);
      }
    }
  };
  useEffect(() => {
    fetchActivities();
  }, [fetchActivities]);

  return {
    loading,
    activities,
    handleScroll,
    loadingFeed,
  };
};

const EmptyObj = {
  image: {
    alt: 'alt text',
    shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
    src: emptyGoal,
    style: {height: '30%', width: '30%'},
  },
  subtitle: '',
  title: `You have no activity`,
};

export const GoalActivity = ({
  id,
  createdBy,
  isGoalKpi,
  updatedAt,
  selectedValue,
}: {
  id: string;
  createdBy: string;
  updatedAt: string;
  isGoalKpi: boolean;
  selectedValue: {id: string; name: string};
}) => {
  const {loading, activities, handleScroll, loadingFeed} = useGoalActivityHook(
    id,
    createdBy,
    updatedAt,
    isGoalKpi,
  );

  const filterActivities = useMemo(
    () =>
      selectedValue.id
        ? [
            ...activities.filter(
              (activity: any) => selectedValue.id === activity.keyResult,
            ),
            {
              activity: `[${createdBy}] created this ${cfv(
                undefined,
                true,
              ).k_r.toLowerCase()}`,
              updatedAt: updatedAt,
            },
          ]
        : activities,
    [selectedValue.id, activities, createdBy, updatedAt],
  );

  const {
    isAccountFreeTier,
    showFreeTierModal,
    handleShowFreeTierModal,
  } = useFreeTierHook();

  const DisplayFreeTierModal = (
    <FreeTierModal
      open={showFreeTierModal}
      image={illustrationList.goldenBarrow}
      onClose={() => handleShowFreeTierModal(false)}
      description={`Retrieve historical data exceeding 30 days on goal-related activities linked to each goal in the workspace, providing valuable insights into their progress.`}
      title={`Unlock Access to all Goal-related Activities within the Workspace`}
    />
  );

  const computeActivities = isAccountFreeTier
    ? filterActivities?.slice(0, 2)
    : filterActivities;

  return (
    <Container title={capitalize(selectedValue.name)}>
      {DisplayFreeTierModal}
      <div
        onScroll={handleScroll}
        style={{maxHeight: '400px', overflow: 'scroll'}}>
        {loading && (
          <ItemLoaderWrapper>
            <ItemLoader />
          </ItemLoaderWrapper>
        )}
        {!loading &&
          computeActivities?.length > 0 &&
          computeActivities.map((activity: any) => (
            <Activity activity={activity} isGoalKpi={isGoalKpi} />
          ))}
        {loadingFeed && (
          <ItemLoaderWrapper>
            <ItemLoader />
          </ItemLoaderWrapper>
        )}
        {!loading && isAccountFreeTier && computeActivities?.length > 0 && (
          <Button
            width="full"
            style={{padding: '8px 16px', width: '100%'}}
            kind="secondary"
            onClick={() => handleShowFreeTierModal(true)}>
            <FlexRowCenter style={{width: '100%'}}>
              <div style={{marginRight: '4px', marginTop: '3px'}}>
                <LockIcon />
              </div>
              See more
            </FlexRowCenter>
          </Button>
        )}
        {!loading && computeActivities?.length === 0 && (
          <EmptyPlaceholder {...EmptyObj} />
        )}
      </div>
    </Container>
  );
};

const Activity = ({activity, isGoalKpi}: any) => {
  const {
    usersStore: {users},
  } = useStoreContext();
  const [contextText, setContextText] = useState('');
  const handleUsersName = (id: string) => {
    const user = users.find((user) => user.id === id);

    return user?.firstName
      ? user.firstName + ' ' + user.lastName
      : user?.email
      ? user.email
      : '';
  };

  const getContext = useCallback(
    (text: string) => {
      if (!contextText) {
        setContextText(text);
      }
    },
    [contextText],
  );

  const handleDescription = (text = '') => {
    let _text = text;

    // eslint-disable-next-line no-useless-escape
    const getTitle = getFromBetween.get(text, `\"`, `\"`);
    const getUsers = getFromBetween.get(text, `[`, `]`);

    const getMentionedTaskUsers = getFromBetween.get(text, `_(`, `)_`);

    getUsers.forEach((user: string) => {
      const contextIncluded = user.includes(' ');
      _text = _text.replaceAll(
        `[Beam Bud]`,
        `<span style="font-weight: 500">Beam Bud</span>`,
      );
      if (!contextIncluded) {
        if (user) {
          _text = _text.replaceAll(
            `[${user}]`,
            `<span style="font-weight: 500">${handleUsersName(user)}</span>`,
          );
        } else {
          _text = _text.replaceAll(`[]`, ``);
        }
      } else {
        getContext(user);
        _text = _text.replaceAll(`[${user}]`, `and added: `);
      }
    });

    getMentionedTaskUsers.forEach((user: string) => {
      _text = _text.replaceAll(
        `_(${user})_`,
        `<span style="font-weight: 500">@${handleUsersName(user)}</span>`,
      );
    });

    getTitle.forEach((title: string) => {
      const hasNumber = /\d/.test(title);

      _text = _text.replace(
        // eslint-disable-next-line no-useless-escape
        `\"${title}\"`,
        `<span style="font-weight: 500">${title}</span>`,
      );

      if (hasNumber) {
        const numbers = title.split(/\D+/).filter(Boolean);

        numbers.forEach((number) => {
          _text = _text.replace(number, `<span>${commaNumber(number)}</span>`);
        });
      }
    });

    _text = _text.replace(
      'behind',
      `<span style='font-weight: 500' >Behind</span>`,
    );
    _text = _text.replace(
      'on track',
      `<span style='font-weight: 500' >On-track</span>`,
    );
    _text = _text.replace(
      'at risk',
      `<span style='font-weight: 500;' >At-risk</span>`,
    );

    return _text;
  };

  const handleAvatar = (id: string) => {
    const user = users.filter((user) => user.id === id)?.[0];

    return {
      avatar: user?.avatar,
      name: user?.firstName
        ? user.firstName + ' ' + user.lastName
        : user?.email
        ? user?.email
        : 'Deleted user',
      id: user?.id,
    };
  };

  return (
    <>
      <Flex>
        <div style={{marginRight: '4%', marginTop: '4px'}}>
          {' '}
          <Avatar
            size="lg"
            tooltip={true}
            name={
              handleAvatar(getFromBetween.get(activity.activity, `[`, `]`)?.[0])
                .name
            }
            userId={
              handleAvatar(getFromBetween.get(activity.activity, `[`, `]`)?.[0])
                .id
            }
            src={
              handleAvatar(getFromBetween.get(activity.activity, `[`, `]`)?.[0])
                ?.avatar?.url
            }
          />
        </div>

        <div>
          <Body1
            dangerouslySetInnerHTML={{
              __html: handleDescription(activity.activity),
            }}
          />
          <VerticalSpacer size="8px" />

          {contextText && (
            <>
              <KeyResultContext text={capitalize(contextText)} />
              <VerticalSpacer size="8px" />{' '}
            </>
          )}

          <Body2 style={{fontSize: '12px'}} kind="textBody">
            {dayjs(activity.updatedAt).format(`DD MMM. YYYY`)} at {'  '}
            {dayjs(activity.updatedAt).format(`hh:mma`)}
          </Body2>
        </div>
      </Flex>

      <VerticalSpacer size="16px" />
    </>
  );
};
