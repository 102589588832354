import {makeAutoObservable, observable, action} from 'mobx';
import {cfv} from '@utils/framework';
import type {PerformanceData} from '@pages/dashboard/admin/admin-components/admin-workspace/workspace-performance/admin-culture-competence/performance-settings-interface';

export class CcStore {
  @observable ccSettings: PerformanceData = {
    reviewers: {
      min: 1,
      max: 3,
    },
    frequency: '',
    valueMetrics: [],
    competenceMetrics: [],
    visibilitySettings: '',
    usersCanSeeFrameworkSetting: [],
    kickOffEmail: {
      subject: `Time for Culture & Competence Review, here's all you need to know.`,
      body: `I'm thrilled to announce that we'd be starting our Culture & Competence Review Process. This process is crucial in determining what progress we've made individually in contribution to the company's ${
        cfv(undefined, true).g_oals
      } for the period in review.  Remember, it is important that you complete and submit your self-evaluation forms on time. If you have any questions or there's anything at all you're unclear about, don't hesitate to reach out to your manager, or you can get in touch with me directly.`,
    },
    weighting: {
      values: 50,
      productivity: 50,
    },
    excludedGroup: [],
    anonymitySetting: '',
    approvePeerNominations: '',
  };
  @observable reviewCycles = new Map();
  @observable currentReviewCycle = {
    cycleName: '',
    reviewType: '',
    progress: 0,
    status: 'upcoming',
    id: '',
    period: '-',
    frameworks: [] as string[],
    milestones: {
      startDate: '',
      endDate: '',
      self: '',
      manager: '',
      peer: '',
      finalize: '',
    },
    nominationSettings: {
      min: 1,
      max: 3,
    },
    allowAnonymity: false,
  };

  @observable cycleFilterOptions = {
    status: {
      label: 'Active',
      value: 'active',
    },
    reviewCycle: {
      label: '',
      value: '',
    },
  };
  constructor() {
    makeAutoObservable(this);
  }

  @action updateCCSettings = (data: PerformanceData) => {
    this.ccSettings = data;
  };

  @action updateCycleFilterOptions = (data: {label: string; value: string}) => {
    this.cycleFilterOptions['reviewCycle'] = data;
  };

  @action updatecycleFilterOptions = (data: any) => {
    this.cycleFilterOptions = data;
  };

  @action resetCurrentReviewCycle = () => {
    this.currentReviewCycle = {
      cycleName: '',
      reviewType: '',
      progress: 0,
      status: 'upcoming',
      id: '',
      period: '-',
      frameworks: [] as string[],
      milestones: {
        startDate: '',
        endDate: '',
        self: '',
        manager: '',
        peer: '',
        finalize: '',
      },
      nominationSettings: {
        min: 1,
        max: 3,
      },
      allowAnonymity: false,
    };
  };

  @action updateCurrentReviewCycle = (data: any) => {
    this.currentReviewCycle = data;
  };

  @action updateCurrentReviewPercentage = (data: number) => {
    this.currentReviewCycle['progress'] = data;
  };

  @action updateReviewCycles = (data: any) => {
    data.forEach((result: any) => {
      if (!this.reviewCycles.has(result.id))
        this.reviewCycles.set(result.id, result);
    });
  };

  @observable workspaceImpressionDetails = [
    {
      name: 'Unsatisfactory',
      description:
        'Fails to achieve the objectives and outcomes expected of the role and does not consistently exhibit  the values and competencies required.',
      min: 0,
      max: 39,
      weight: 1,
    },
    {
      name: 'Average',
      description:
        'Achieves some objectives and displaying some competencies, there is potential for greater impact by consistently demonstrating values and exceeding outcomes.',
      min: 40,
      max: 59,
      weight: 2,
    },
    {
      name: 'Satisfactory',
      description:
        'Performs well in terms of accomplishing most objectives and outcomes of the role, and frequently demonstrating values and competencies, but there is still potential to increase their impact further.',
      min: 60,
      max: 79,
      weight: 3,
    },
    {
      name: 'Exceptional',
      description:
        'Exceeds all objectives and outcomes, exceptional impact. Consistently demonstrates values and competencies.',
      min: 80,
      max: 100,
      weight: 4,
    },
  ];

  @observable ccReviewScale = [
    {
      title: 'Outstanding',
      score: 5,
      detail:
        'Fulfilled obligations with exceptional accomplishments as evidenced by results, consistent work quality, quantity, and timeliness across all responsibilities. ',
    },
    {
      title: 'Exceeds Expectations',
      score: 4,

      detail:
        'Comments on high levels of accuracy and work productivity. Takes pride in work and strives to improve work performance. All tasks are completed on time with no errors.',
    },
    {
      title: 'Meets expectation',
      score: 3,
      detail:
        'Does not require constant supervision. Error rate is acceptable, and all work is completed timely. Forms and required paperwork are completed on time with minimal errors.',
    },

    {
      score: 2,
      title: 'Needs improvement',
      detail:
        'Is not as careful in checking work product for errors as he/she could be. Tends to miss small errors in work product. Required work is completed late or is only partially complete. Misses daily check-ins',
    },
    {
      score: 1,
      title: 'Poor',
      detail:
        'Has made frequent errors that are harmful to business operations. The quality of work produced is unacceptable. Rarely achieves his targets. ',
    },
  ];
  @action getReversedScale = () => {
    return this.ccReviewScale.reverse();
  };
}

export const ccStore = new CcStore();
