import React from 'react';
import {AnalyticsActionItemsOverviewPage} from './overview';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {AnalyticsActionItemsSummaryPage} from './summary';
import {useAnalyticsActionItemsHook} from './analytics-action-items-hook';
import {Body2} from '@ui/atoms/typography';

type Option = {label: string; value: string};

interface AnalyticsActionItemProps {
  selectedGroups: Option[];
  selectedTeams: Option[];
  selectedMembers: Option[];
  breakdownBy: string;
}

export const AnalyticsActionItems = ({
  selectedGroups,
  selectedMembers,
  selectedTeams,
  breakdownBy,
}: AnalyticsActionItemProps) => {
  const {summary, filteredData} = useAnalyticsActionItemsHook({
    groups: selectedGroups.map((group) => group.value),
    teams: selectedTeams.map((team) => team.value),
    members: selectedMembers.map((member) => member.value),
    breakdownBy,
  });

  return (
    <div>
      <div className="bg-white rounded-[10px] p-4 border border-borderLight">
        <div className="pb-4 border-b border-b-[#ededf2]">
          <Body2 weight="bold">Overview</Body2>
        </div>

        <div className="border border-borderLight rounded-lg mt-4 py-6 px-4">
          <AnalyticsActionItemsOverviewPage
            summary={summary}
            isBoards={breakdownBy === 'boards'}
          />
        </div>
      </div>

      <VerticalSpacer size="24px" />
      <AnalyticsActionItemsSummaryPage
        users={filteredData}
        breakdownBy={breakdownBy}
      />
    </div>
  );
};
