import React, {SVGProps} from 'react';

export const TickIcon = (props?: SVGProps<any>) => {
  return (
    <svg
      width="16"
      height="11"
      viewBox="0 0 16 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.5357 1.52495C15.8256 1.22911 15.8208 0.75426 15.5249 0.464341C15.2291 0.174421 14.7543 0.179217 14.4643 0.475054L6.09277 9.01748C5.60265 9.5176 4.79735 9.5176 4.30723 9.01748L1.53566 6.18934C1.24574 5.8935 0.77089 5.88871 0.475054 6.17863C0.179217 6.46855 0.174421 6.9434 0.464341 7.23923L3.23591 10.0674C4.31417 11.1676 6.08583 11.1676 7.16409 10.0674L15.5357 1.52495Z"
        fill="#585ADF"
        {...props}
      />
    </svg>
  );
};
