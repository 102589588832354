import {ItemLoader} from '@ui/organisms/item-loader';
import {FlexRowCenter, FlexRow} from '@ui/style/styles';
import {useQuery} from 'react-query';
import {userName} from '@utils/user-name';
import {Body1, Headline3} from '@ui/atoms/typography';
import {PlainButton} from '@ui/atoms/plain-button/plain-button';
import {useMemo, useState} from 'react';
import {ViewUser} from './components/view-user';
import {Avatar} from '@ui/atoms/avatar';
import {useSearchParams} from 'react-router-dom';
import {Notes} from './components/notes';
import {FeedbackItem} from '@pages/feedback/feedback-item';
import {MeetingAgenda} from './components/agenda';
import {RescheduleMeeting} from '../reschedule-meeting/reschedule-meeting';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {Body2} from '@ui/atoms/typography';
import {Feedback} from './components/feedback';
import {useFeedbackHook} from './components/feedback-hook';
import dayjs from 'dayjs';
import {useViewMeetingHook} from './view-meeting-hook';
import Timezone from 'dayjs/plugin/timezone';
import {authStore} from '@store/stores/auth-store';
import {SectionCard} from '@ui/layouts/section-card';
import {useBreakpoints} from '@utils/use-breakpoints';
import {useStoreContext} from '@store/store-context';
import {observer} from 'mobx-react';
import {MoreVerticalIcon, PlusIcon} from '@ui/atoms/icons';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {useDebouncedCallback} from 'use-debounce';
import {AdditionalOptionsWrapper} from './styles';
import {ThickPlusIcon} from '@ui/atoms/icons/thick-plus';
import {CreateNoteTopic, DeleteTopic, EditTopic} from './modals';
import {NextSteps} from './components/next-steps';
import {DropdownVertical} from '@ui/molecules/dropdown-vertical';
import {MeetingHeader} from './components/meeting-header';

dayjs.extend(Timezone);

interface ViewMeetingsProps {
  handleUseTemplates?: () => void;
}
export const ViewMeetings = observer(
  ({handleUseTemplates}: ViewMeetingsProps) => {
    const {
      fetchMeetingDetails,
      handleUpdateMeeting,
      markAsCompleted,
      handleAddTopic,
      computeTopicNotes,
      computedTeamNotes,
      managerViewUserOptions,
      computedTopics,
      handleDeleteTopic,
      agendas,
      handleEditTopic,
      id,
      handleCopyPreviousActionItems,
      computedNotes,
      loadingNotes,
      submitting,
      handleUpdateNotes,
      getUserFeedBack,
    } = useViewMeetingHook();

    const [searchParams] = useSearchParams();

    const {
      feedbackValues,
      handleFeedbackFormValueChange,
      feedbackError,
    } = useFeedbackHook();

    const [leaveFeedback, setLeaveFeedback] = useState(false);

    const startMeeting = searchParams.get('start');

    const {
      usersStore: {users},
    } = useStoreContext();

    const queryKey = useMemo(() => ['view-meeting', id], [id]);
    const queryFn = useMemo(
      () => () => fetchMeetingDetails(id, !!startMeeting),
      [fetchMeetingDetails, id, startMeeting],
    );

    const {data, isLoading, refetch} = useQuery(queryKey, queryFn, {});

    const isManagerView = searchParams.get('manager');

    const [openRescheduleMeeting, setOpenRescheduleMeeting] = useState(false);

    const getStatus = (status: string) => {
      return data && data.status === status;
    };

    const isMeetingNote = searchParams.get('type') === 'meeting-notes';

    const isMeetingUpcoming = getStatus('upcoming') || isMeetingNote;

    const isMeetingCanceled = getStatus('cancelled');

    const isMeetingActive = getStatus('active');

    const isMeetingMissed = getStatus('missed');

    const isMeetingCompleted = getStatus('completed') && !isMeetingNote;

    const {data: feedback = [], isLoading: loadingFeedback} = useQuery(
      ['view-userFeedback', id],
      () => getUserFeedBack(id, data ? data?.user?.id : ''),
      {
        enabled: data && !!data?.feedback && !!data?.user.id,
      },
    );

    const [showAddTopic, setShowAddTopic] = useState(false);

    const [editTopic, setEditTopic] = useState(false);

    const [deleteTopic, setDeleteTopic] = useState(false);

    const [selectedTopic, setSelectedTopic] = useState<{
      key: string;
      title: string;
    } | null>(null);

    const feedbackData = feedback[0];

    const [showViewUser, setShowViewUser] = useState<
      'objective' | 'check-in' | 'feedback' | '' | '1:1'
    >('');

    const {isXs} = useBreakpoints();

    const updateNotes = useDebouncedCallback(
      (value, id, field, shareNote, firebaseId, topic?: string) => {
        handleUpdateNotes(value, id, field, shareNote, firebaseId, topic);
      },
      1500,
    );

    if (isLoading) {
      return (
        <FlexRowCenter style={{width: '100%', height: '80vh'}}>
          <ItemLoader />
        </FlexRowCenter>
      );
    }

    if (!data) {
      return null;
    }

    const computeParticipants = data.participant;

    const participants = [...computeParticipants, data.user].map(
      (participant) => ({
        name: userName(participant),
        src: participant.avatar?.url || '',
        id: participant.id,
      }),
    );

    const canViewSections =
      isMeetingActive ||
      isMeetingCompleted ||
      isMeetingUpcoming ||
      isMeetingMissed;

    const isTeamsMeeting = data.type !== 'team';

    const isSingleParticipant = users.find((user) => {
      const participant =
        typeof data.participant[0] === 'string'
          ? data.participant[0]
          : data.participant[0]?.id;

      return user.id === participant;
    });

    const reviewee: any = data
      ? participants.includes(authStore.auth.user.id)
        ? data.user
        : isSingleParticipant
      : null;

    const lastUpdatedBy = users.find((user) => user.id === data?.updatedBy);

    const filterComputedTopics =
      computedTopics &&
      computedTopics.filter((value) =>
        value.isPrivate ? data.user.id === authStore.auth.user.id : true,
      );

    const patchData = (field: string, value: any) => {
      return {
        participant: data.participant,
        shareHostAgenda: data.shareHostAgenda,
        shareParticipantAgenda: data.shareParticipantAgenda,
        [field]: value,
        startDate: data.startDate,
        frequency: data.frequency,
        time: data.time,
        reminder: data.reminder,
      };
    };

    return (
      <div>
        <div>
          <MeetingHeader
            isManagerView={!!isManagerView}
            data={data}
            participants={participants}
            allParticipants={computeParticipants}
            isMeetingActive={isMeetingActive}
            handleRemoveTag={() => {
              handleUpdateMeeting(id, patchData('label', []) as any).then(
                () => {
                  refetch();
                },
              );
            }}
            refetch={refetch}
            feedbackValues={feedbackValues}
            markAsCompleted={markAsCompleted}
            isMeetingNote={isMeetingNote}
            isMeetingCanceled={isMeetingCanceled}
            id={id}
            setOpenRescheduleMeeting={setOpenRescheduleMeeting}
            managerViewUserOptions={managerViewUserOptions}
            reviewee={reviewee}
            setShowViewUser={setShowViewUser}
            isSingleParticipant={isSingleParticipant}
            isMeetingMissed={isMeetingMissed}
            submitting={submitting}
            isMeetingUpcoming={isMeetingUpcoming}
          />
          <VerticalSpacer size="24px" />

          <MeetingAgenda
            handleUseTemplates={handleUseTemplates}
            meetingId={data.id}
            isReadOnly={isMeetingCanceled}
            isMeetingActive={isMeetingActive}
            isHostSharing={data.shareHostAgenda}
            users={{
              user: data.user?.id,
              participant: computeParticipants.map(
                (participant: {id: string}) => participant.id,
              ),
            }}
            participantSharing={data.shareParticipantAgenda}
            updateMeeting={async (value, field) => {
              await handleUpdateMeeting(
                id,
                patchData(field, value) as any,
              ).then(() => {
                refetch();
              });
            }}
            agendas={{
              host: {
                user: data.user,
                agenda: agendas.host,
              },
              participant: {
                user: participants as any,
                agenda: agendas.participant,
              },
            }}
          />

          {canViewSections && (
            <div>
              <VerticalSpacer size="24px" />

              <>
                <NextSteps
                  steps={data.nextSteps}
                  meetingId={data.id}
                  canCopyItems={data.frequency !== 'once'}
                  handleCopyActionItems={handleCopyPreviousActionItems}
                  users={[
                    data.user.id,
                    ...participants.map((participant) => participant.id),
                  ]}
                  updateMeeting={async (data) => {
                    await handleUpdateMeeting(
                      id,
                      patchData('nextSteps', data) as any,
                    );
                  }}
                />
              </>
              <VerticalSpacer size="24px" />

              {canViewSections && !loadingNotes && (
                <Notes
                  user={
                    data.user.id === authStore.auth.user.id
                      ? 'user'
                      : 'participant'
                  }
                  isHostSharing={computedNotes?.hostNote?.shareHostNote}
                  isParticipantSharing={
                    computedNotes?.participantNote?.shareParticipantNote
                  }
                  notes={computedTeamNotes}
                  note={
                    data.user.id === authStore.auth.user.id
                      ? computedNotes?.hostNote?.note || ''
                      : computedNotes?.participantNote?.note || ''
                  }
                  updateMeeting={(value, field, id) => {
                    if (
                      ['hostNote', 'participantNote'].includes(field) &&
                      typeof value === 'string'
                    ) {
                      const prevData = (computedNotes as any)?.[field];

                      updateNotes(
                        value,
                        id,
                        field,
                        prevData?.shareNote,
                        prevData || {firebaseId: id},
                      );
                    } else {
                      const noteType =
                        data.user.id === authStore.auth.user.id
                          ? 'hostNote'
                          : 'participantNote';

                      const prevData = computedNotes?.[noteType];
                      if (isTeamsMeeting) {
                        const _prevData = computedTeamNotes?.find(
                          (note: any) => note.firebaseId === id,
                        );

                        updateNotes(
                          _prevData?.note || '',
                          id,
                          noteType,
                          value,
                          prevData || {firebaseId: id},
                          '',
                        );
                      } else {
                        updateNotes(
                          prevData?.note || '',
                          id,
                          noteType,
                          value,
                          prevData || {firebaseId: id},
                          '',
                        );
                      }
                    }
                  }}
                />
              )}

              {filterComputedTopics &&
                filterComputedTopics.map((topic) => (
                  <>
                    <VerticalSpacer size="24px" />

                    <Notes
                      key={topic.id}
                      title={topic.title}
                      isPrivate={topic.isPrivate}
                      dropdownComponent={
                        <div>
                          <DropdownVertical
                            customIcon={<MoreVerticalIcon />}
                            dropdownWrapperStyle={{
                              right: '-83%',
                            }}
                            menu={
                              <div>
                                <DropdownItem
                                  onClick={() => {
                                    setEditTopic(true);
                                    setSelectedTopic(topic);
                                  }}>
                                  Edit title
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => {
                                    setDeleteTopic(true);
                                    setSelectedTopic(topic);
                                  }}>
                                  Delete
                                </DropdownItem>
                              </div>
                            }
                          />
                        </div>
                      }
                      excludeSharing
                      note=""
                      user={
                        data.user.id === authStore.auth.user.id
                          ? 'user'
                          : 'participant'
                      }
                      notes={computeTopicNotes?.[topic.id]}
                      updateMeeting={(value, field, id) => {
                        updateNotes(
                          value,
                          id,
                          field,
                          topic?.isPrivate,
                          {
                            firebaseId: id,
                          },
                          topic.id,
                        );
                      }}
                    />
                    <VerticalSpacer size="24px" />
                  </>
                ))}

              {isManagerView && isMeetingActive && isTeamsMeeting && (
                <>
                  <VerticalSpacer size="20px" />
                  {!leaveFeedback && (
                    <div className="border border-borderLight rounded-lg p-6 flex items-center justify-between">
                      <Headline3>Leave Feedback</Headline3>
                      <PlainButton onClick={() => setLeaveFeedback(true)}>
                        <FlexRow>
                          <PlusIcon styles={{stroke: '#585ADF'}} />

                          <Body2
                            weight="bold"
                            kind="purple300"
                            className="ml-2">
                            Add feedback
                          </Body2>
                        </FlexRow>
                      </PlainButton>
                    </div>
                  )}
                  {leaveFeedback && (
                    <SectionCard
                      headerPadding={isXs ? 'small' : undefined}
                      contentStyling={{padding: isXs ? '12px' : '24px'}}
                      CustomHeaderTitle={<Headline3>Leave feedback</Headline3>}>
                      <div>
                        <Feedback
                          handleFeedbackFormValueChange={
                            handleFeedbackFormValueChange
                          }
                          feedbackValues={feedbackValues}
                          feedbackError={feedbackError}
                        />
                      </div>
                    </SectionCard>
                  )}
                </>
              )}
            </div>
          )}

          {data.feedback ? (
            <div className="mt-6">
              <SectionCard
                headerPadding={isXs ? 'small' : undefined}
                contentStyling={{
                  padding: isXs ? '12px' : '24px 24px 10px 24px',
                }}
                CustomHeaderTitle={<Headline3>Manager’s feedback</Headline3>}>
                <div>
                  {loadingFeedback ? (
                    <FlexRowCenter>
                      <ItemLoader />
                    </FlexRowCenter>
                  ) : (
                    <FeedbackItem {...feedbackData} excludeSubject />
                  )}
                </div>
              </SectionCard>
            </div>
          ) : null}

          {data?.reasonForCancellation && (
            <div className="mt-6">
              <SectionCard
                headerPadding={isXs ? 'small' : undefined}
                contentStyling={{
                  padding: isXs ? '12px' : '24px ',
                }}
                CustomHeaderTitle={<Headline3>Cancellation reason</Headline3>}>
                <div>
                  <div className="rounded-[10px] border border-[#CECEDE] bg-[#FAFAFA] p-4 flex justify-between items-center">
                    <Body1>{data.reasonForCancellation}</Body1>

                    <Avatar
                      src={lastUpdatedBy?.avatar?.url}
                      name={userName(lastUpdatedBy)}
                      isUserDeleted={!!lastUpdatedBy?.deletedAt}
                      userId={lastUpdatedBy?.id || ''}
                      tooltip={true}
                    />
                  </div>
                </div>
              </SectionCard>
            </div>
          )}

          {authStore.auth.user.id === data.user.id && (
            <>
              <VerticalSpacer size="16px" />
              <AdditionalOptionsWrapper
                display
                className="cursor-pointer mt-6"
                onClick={() => setShowAddTopic(true)}>
                <div className="flex items-center justify-center gap-4">
                  <ThickPlusIcon color={'#47B881'} />

                  <Body2 weight="semibold" kind="textBody">
                    Add to topic
                  </Body2>
                </div>
              </AdditionalOptionsWrapper>
            </>
          )}
        </div>

        {showAddTopic && (
          <CreateNoteTopic
            handleCreate={(_data) => {
              handleAddTopic(_data.title, _data.private, data.id);
              setShowAddTopic(false);
            }}
            onClose={() => setShowAddTopic(false)}
          />
        )}
        {editTopic && (
          <EditTopic
            handleEdit={(_data) => {
              handleEditTopic(selectedTopic?.key || '', {
                ...selectedTopic,
                title: _data.title,
              });
              setEditTopic(false);
            }}
            onClose={() => setEditTopic(false)}
          />
        )}

        {deleteTopic && (
          <DeleteTopic
            topic={selectedTopic?.title || ''}
            handleDelete={() => {
              handleDeleteTopic(selectedTopic?.key || '');
              setDeleteTopic(false);
            }}
            onClose={() => setDeleteTopic(false)}
          />
        )}
        {!!showViewUser && (
          <ViewUser
            open={!!showViewUser}
            name={userName(reviewee)}
            user={reviewee?.id || ''}
            type={showViewUser}
            onClose={() => setShowViewUser('')}
          />
        )}

        {openRescheduleMeeting && (
          <RescheduleMeeting
            onUpdated={refetch}
            open={openRescheduleMeeting}
            onClose={() => setOpenRescheduleMeeting(false)}
            meeting={data}
          />
        )}
      </div>
    );
  },
);
