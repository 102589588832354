import {FlexRow, FlexRowSpaceBetween} from '@ui/style/styles';
import styled from 'styled-components';
import {ifProp} from 'styled-tools';

export const AnalayticsWrapper = styled.div`
  border-radius: 10px;
  border: solid var(--border-light, #ededf2);
  border-width: 1px 0px 1px 0px;
  background: #fafafa;
  padding: 16px;
`;

export const ResponsiveFlexRow = styled(FlexRow)`
  width: 50%;
  @media (max-width: 500px) {
    flex-direction: column;
    width: 100%;
  }
`;
export const EmptyOverviewWrapper = styled.div`
  border-radius: 10px;
  border-top: 1px solid var(--border-light, #ededf2);
`;

export const ResponsiveFlexSpaceBetween = styled(FlexRowSpaceBetween)`
  > div {
    width: 50%;
    &:first-of-type {
      margin-right: 16px;
    }
  }
  @media (max-width: 500px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const StatusCard = styled.div<{paddingBottom?: boolean}>`
  border-radius: 10px;
  border: 1px solid var(--border-light, #ededf2);
  background: #fff;

  .header {
    padding: ${ifProp(
      'paddingBottom',
      ' 16px 16px 24px 16px',
      ' 16px 16px 14px 16px',
    )};
    border-radius: 10px 10px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .body {
    border-radius: 10px;
    border-top: 1px solid var(--border-light, #ededf2);
    background: #fff;
    padding: 24px 24px 14px 24px;
  }
`;
