import {GoalKind} from '../../interface';
import {memo} from 'react';
import {CompanyIcon} from '../icons/company';
import {UsersIcon2} from '../icons/users';

import {UserIcon} from '../icons/user';

export interface GoalIconProps {
  kind: GoalKind;
}

export const GoalIcon = memo(({kind}: GoalIconProps) => {
  switch (kind) {
    case GoalKind.COMPANY:
      return <CompanyIcon />;
    case GoalKind.INDIVIDUAL:
      return <UserIcon />;
    case GoalKind.TEAM:
      return <UsersIcon2 />;
    // no-default
    default:
      return <UsersIcon2 />;
  }
});

GoalIcon.displayName = 'GoalIcon';
