import {useEffect, useState, useCallback} from 'react';
import {TaskController} from './tasks-controller';
import {ITaskRequestImpl} from './tasks-requests';
import {onValue, ref} from 'firebase/database';
import {GoalsController} from '@api/goals-api/goals-controller';
import {GoalsRequestImpl} from '@api/goals-api/goals-request';
import {useWorkspaceUseCase} from '@hooks/workspace-usecase';
import {useQuery} from 'react-query';
import {database} from '@utils/firebase-request';
import dayjs from 'dayjs';
import {useStoreContext} from '@store/store-context';

export const useTaskHook = () => {
  const {
    authStore: {auth},
    storeYouGoals: {fetchedGoals},
    taskStore: {taskData, updateTasks, updateCount, updateMentionCount},
  } = useStoreContext();

  const [disableTasks, setDisableTasks] = useState(false);
  const {isEngagementActive, isPerformanceActive} = useWorkspaceUseCase();

  const handleDismissAll = () => {
    localStorage.setItem('disableTasks', `${new Date()}`);
    setDisableTasks(true);
    updateCount(0);
  };

  const {isCheckinsEnabled, isFeedBackEnabled} = useWorkspaceUseCase();

  const showTasks = () => {
    const tasksDisabled = localStorage.getItem('disableTasks');
    if (tasksDisabled) {
      const getTime: any = tasksDisabled;

      const disableTime = dayjs(getTime);
      const timeDifference = Math.abs(
        disableTime.diff(dayjs(Date.now()), 'hours'),
      );

      return timeDifference > 6;
    }
    return true;
  };

  useEffect(() => {
    const tasksDisabled = localStorage.getItem('disableTasks');
    if (tasksDisabled) {
      if (showTasks()) {
        localStorage.removeItem('disableTasks');
      } else {
        setDisableTasks(true);

        updateCount(0);
      }
    }
  }, [updateCount]);

  const addCount = (data: any) => {
    let count = 0;

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key];

        if (Array.isArray(value)) {
          count += value.length;
        } else if (typeof value === 'object' && value !== null) {
          count += 1;
        } else if (value || value === 0) {
          count++;
        }
      }
    }

    return count;
  };

  const getTaskList = useCallback(async () => {
    try {
      const request = new ITaskRequestImpl();
      const controller = new TaskController(request);
      const tasks = await controller.getUserTaskList(auth?.user?.id);
      const response = {
        ...tasks,
      };

      const nominations = localStorage.getItem('nominations');

      if (!isPerformanceActive) {
        delete response?.goals;
      }

      if (!isEngagementActive || !isCheckinsEnabled) {
        delete response?.checkin;
        delete response?.checkinFeedback;
      }

      if (!isFeedBackEnabled || !isEngagementActive) {
        delete response?.feedbackRequests;
      }

      if (nominations && response?.peerReviews?.length > 0) {
        const getPreviousNomination = JSON.parse(localStorage.nominations);

        const getPeers = response?.peerReviews?.map(
          (peer: any) => peer?.reviewCycleId,
        );

        const filterNomination = getPreviousNomination?.filter((review: any) =>
          getPeers?.includes(review?.id),
        );

        if (filterNomination?.length > 0) {
          const getTasks = {
            ...response,
          };

          updateTasks(getTasks);

          updateCount(addCount(response));
        } else {
          updateTasks(response);
          updateCount(addCount(response));
        }
      } else {
        updateTasks(response);

        updateCount(addCount(response));
      }

      onValue(
        ref(
          database,
          `userMentions/${process.env.REACT_APP_DEV_ENV}/${auth?.user?.id}/goals`,
        ),
        async (snapshot: any) => {
          const mention = snapshot.val();
          const request = new GoalsRequestImpl();
          const controller = new GoalsController(request);
          if (mention) {
            const getData: any = Object.entries(mention)?.filter(
              (user) => !user[0].includes('checkins'),
            );

            const comments = getData.map(async (comment: any) => {
              const getGoal = fetchedGoals.get(comment[0]);
              const formatCommentTask = (goalName: string) => {
                const task = {
                  title:
                    comment[1]?.unreadComment === 1
                      ? 'You have a comment'
                      : 'You have comments',
                  id: comment[0],
                  // eslint-disable-next-line no-useless-escape
                  description: `You have ${comment[1]?.unreadComment} unread ${
                    comment[1]?.unreadComment === 1 ? 'comment' : 'comments'
                    // eslint-disable-next-line no-useless-escape
                  } on \"${goalName}\"`,
                };
                return task;
              };

              if (comment[1]?.unreadComment > 0) {
                if (!getGoal) {
                  const loadGoal = await controller.fetchGoalById(comment[0]);

                  return formatCommentTask(loadGoal.name);
                }
                return formatCommentTask(getGoal?.name);
              }
              return null;
            });

            const allMentions = getData.map(async (mention: any) => {
              const getGoal = fetchedGoals.get(mention[0]);

              if (mention[1]?.mentionedBy?.length > 0) {
                const formatMention = (goalName: string) => {
                  const task = {
                    title: 'You have a mention',
                    id: mention[0],
                    type: 'comment',
                    description:
                      getUsers?.length === 1
                        ? `_(${
                            getUsers?.[0]
                            // eslint-disable-next-line no-useless-escape
                          })_ mentioned you on \"${goalName}\"`
                        : getUsers?.length === 2
                        ? `_(${getUsers?.[0]})_ and_(${
                            getUsers?.[1]
                            // eslint-disable-next-line no-useless-escape
                          })_ mentioned you on \"${goalName}\"`
                        : `_(${getUsers?.[0]})_ and ${
                            (getUsers?.length as any) - 1
                            // eslint-disable-next-line no-useless-escape
                          } others mentioned you \"${
                            goalName
                            // eslint-disable-next-line no-useless-escape
                          }\"`,
                  };
                  return task;
                };
                const getUsers = mention[1]?.mentionedBy;
                if (getGoal) {
                  return formatMention(getGoal.name);
                }
                const loadGoal = await controller.fetchGoalById(mention[0]);

                return formatMention(loadGoal.name);
              }
              return null;
            });

            const resolvedComments = await Promise.all(comments);

            const finalComments = resolvedComments.filter(
              (comment: any) => comment != null,
            );
            const resolvedMentions = await Promise.all(allMentions);

            const finalMentions = resolvedMentions.filter(
              (mention: any) => mention != null,
            );

            updateTasks({
              ...response,
              mentions: finalMentions,

              comment: finalComments,
            });

            updateMentionCount(finalMentions.length + finalComments.length);
          }
        },
      );
    } catch {}
  }, [
    auth?.user?.id,
    isPerformanceActive,
    isEngagementActive,
    isCheckinsEnabled,
    isFeedBackEnabled,
    updateTasks,
    updateCount,
    updateMentionCount,
    fetchedGoals,
  ]);

  const {isLoading, refetch} = useQuery('taskList', () => getTaskList(), {
    cacheTime: 10000000,
    enabled: showTasks(),
  });

  return {
    taskLoader: isLoading,
    tasks: taskData,
    getTime: showTasks(),
    updateCount,
    refetch,
    disableTasks,
    handleDismissAll,
  };
};
