import {ComponentPropsWithoutRef, memo, ReactNode} from 'react';
import styled from 'styled-components';
import {Button} from '../../atoms/button';
import {width} from '../../atoms/button/width';
import {ButtonSize} from '../../interface';
import {getColorFromTheme} from '../../ui-utils';

export interface BackActionProps extends ComponentPropsWithoutRef<'button'> {
  icon: ReactNode;
  title: string;
  width?: ButtonSize;
}
export interface BackActionStyleProps
  extends ComponentPropsWithoutRef<'button'> {
  width?: ButtonSize;
}

const IconWrapper = styled.span`
  margin-right: 5px;
  margin-top: -2px;
  display: inline-flex;
  align-items: center;

  svg {
    stroke: ${getColorFromTheme('textDark')};
  }
`;
const OuterWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const Wrapper = styled(Button)<BackActionStyleProps>`
  display: inline-flex;
  align-items: center;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  span {
    font-weight: 600;
    margin-right: 3px;
  }
  ${width}
`;

export const BackAction = memo(function ({
  icon,
  title,
  ...rest
}: BackActionProps) {
  return (
    <OuterWrapper>
      <Wrapper kind="secondary" type="button" {...rest}>
        <span>{title}</span>
        <IconWrapper>{icon}</IconWrapper>
      </Wrapper>
    </OuterWrapper>
  );
});
BackAction.displayName = 'BackAction';
