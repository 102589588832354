import styled from 'styled-components';
import {getColorFromTheme} from '../../../../../../ui/ui-utils';

export const CalendarIconWrapper = styled.span`
  stroke: ${getColorFromTheme('purple300')};
`;
export const HeadlineWrapper = styled.div`
  display: grid;
  grid-template-columns: 35% 30% 25% 10%;
  padding-left: 16px;
`;

export const HeadlineWrapperContainer = styled.div`
  display: block;

  padding: 16px 16px 12px 0px;
  background-color: ${getColorFromTheme('backgroundLight')};
  white-space: nowrap;
  height: 52px;
`;

export const TableContainer = styled.div`
  border-right: 1px solid #ededf2;
  border-top: 1px solid #ededf2;
  border-left: 1px solid #ededf2;
  border-bottom: 1px solid #ededf2;
  border-radius: 10px;
`;

export const ItemLoaderWrapper = styled.div`
  padding: 28px 12px 32px 22px;
  justify-content: center;
  width: 100%;
  border-top: 1px solid #ededf2;
`;

export const ItemWrapperContainer = styled.div``;

export const ItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 35% 30% 25% 10%;
  padding: 16px;
  border-top: 1px solid ${getColorFromTheme('borderLight')};
  align-items: center;
  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const SecondRowWrapper = styled.div`
  width: 100%;

  @media (max-width: 800px) {
    max-width: 100%;
    overflow-x: auto;
    display: grid;
    div.header {
      min-width: 718px;
    }
  }
`;
