import styled from 'styled-components';
import {FlexRowSpaceBetween, FlexRow} from '@ui/style/styles';
import {HorizontalSideRule} from '@ui/atoms/spacer/spacer';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {Body2, Body1} from '@ui/atoms/typography';

const TableContainer = styled.div`
  border: 1px solid #ededf2;
  border-radius: 10px;
  width: 849px;
  .table-head {
    background: #fafafa;
    padding: 14px 24px;
    display: grid;
    grid-template-columns: 90% 5%;
  }
  .table-body {
    padding: 16px 24px;
    .rule {
      margin: 16px 0px;
      height: 1px;
      background-color: #ededf2;
    }
    .keyArea {
      margin-top: 16px;
      .number {
        border: 1px solid #ededf2;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 58px;
        padding: 4px 8px;
      }
    }
  }
`;

export const CompetenceReport = ({report}: {report: any}) => {
  return (
    <TableContainer>
      <div className="table-head">
        <Body2 kind="textBody" style={{fontSize: '12pt'}}>
          Key Areas
        </Body2>
        <Body2 kind="textBody" style={{fontSize: '12pt'}}>
          Performance{' '}
        </Body2>
      </div>
      {report.map((competence: any) => (
        <div className="table-body">
          <Body1 weight="bold" style={{fontWeight: 700, fontSize: '13pt'}}>
            {competence.name}
          </Body1>
          <div className="rule"></div>
          {competence.components.map(
            (keyArea: {
              name: string;
              objectivesCount: number;
              score: number;
              percentageProgress: number;
            }) => (
              <div className="keyArea">
                <FlexRowSpaceBetween>
                  <div>
                    <Body2 weight="semibold" style={{fontSize: '12pt'}}>
                      {keyArea.name}
                    </Body2>
                    <VerticalSpacer size="10px" />
                    <FlexRow>
                      <Body2 style={{fontSize: '11pt'}} kind="textBody">
                        Weighing{' '}
                        <span style={{fontWeight: 700}}>({keyArea.score})</span>
                      </Body2>
                      <HorizontalSideRule size="12px" />
                      <Body2 style={{fontSize: '11pt'}} kind="textBody">
                        Objectives{' '}
                        <span style={{fontWeight: 700}}>
                          ({keyArea.objectivesCount || 0})
                        </span>
                      </Body2>
                    </FlexRow>
                  </div>
                  <div className="number">
                    <Body2
                      style={{fontWeight: 700, fontSize: '12pt'}}
                      kind="textBody"
                      align="center">
                      {keyArea.percentageProgress || 'n/a'}
                    </Body2>
                  </div>
                </FlexRowSpaceBetween>
              </div>
            ),
          )}
        </div>
      ))}
    </TableContainer>
  );
};
