import {ChangeEvent, forwardRef, Fragment, useCallback, useRef} from 'react';
import {Colors} from '@ui/interface';
import {useState} from 'react';
import {FlexRow} from '@ui/style/styles';
import {Input, InputProps} from '@ui/atoms/input/input';
import {IAPIUserFormat} from '@hooks';
import {MinusSVG} from '@ui/atoms/svg/minus';
import {Helper} from '@ui/atoms/helper';
import {CheckField} from '@ui/molecules/field/checkfield';
import {Body2} from '@ui/atoms/typography';
import {ConfigIcon} from '@ui/atoms/icons';
import {
  CheckableInputParentWrapper,
  CheckableInputWrapper,
  RemoveButton,
  TextFieldWrapper,
  HelperWrapper,
} from './style';
import {FieldEditor} from './editor';
import {FieldInput} from './input-field';
import {FieldUser} from './user';
import nextId from 'react-id-generator';

export const Field = forwardRef(function (
  {
    showRemove,
    handleRemove,
    fieldId,
    handleChange,
    disabled,
    handleCheck,
    footerComponent,
    checkable,
    showCheckbox,
    fontWeight,
    useCompletedAsDisabled,
    saveValues,
    colorString,
    addItem,
    fieldLength,
    disableCheckbox,
    editorOptions,
    userCanReorder,
    showAvatar,
    handleUserSelect,
    user,
    background,
    assigneeOptions,
    error,
    checked,
    readonly,
    editor,
    dropdownComponent,
    indexOfFieldId,
    ...props
  }: InputProps & {
    showRemove: boolean;
    handleRemove: (key: string) => void;
    addItem?: (index?: number) => void;
    fieldId: string;
    handleCheck: (e: ChangeEvent<HTMLInputElement>, key: string) => void;
    fontWeight?: number;
    colorString?: Colors;
    handleUserSelect?: (value: string, fieldId: string) => void;
    disableCheckbox?: boolean;
    fieldLength?: number;
    user?: IAPIUserFormat;
    saveValues?: () => Promise<void>;
    showCheckbox?: boolean;
    assigneeOptions?: {value: string; label: string}[];
    editor?: boolean;
    showAvatar?: boolean;
    error?: string;
    userCanReorder?: boolean;
    background?: Colors;
    useCompletedAsDisabled?: boolean;
    editorOptions?: {
      name: '';
      id: '';
    }[];
    handleChange: (e: ChangeEvent<HTMLInputElement>, key: string) => void;
    checkable?: boolean;
    readonly?: boolean;
    disabled?: boolean;
    dropdownComponent?: any;
    footerComponent?: any;
    indexOfFieldId: number;
  },
  inputRef: any,
) {
  const checkboxRef = useRef(null);

  const onHandleRemove = useCallback(() => {
    handleRemove(fieldId);
  }, [handleRemove, fieldId]);

  const onHandleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      handleChange(e, fieldId);
      if (e?.target?.value === '' && checked) {
        (checkboxRef?.current as any)?.click();
      }
    },
    [handleChange, fieldId, checked],
  );

  const onHandleCheck = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      handleCheck(e, fieldId);
    },
    [fieldId, handleCheck],
  );

  const onHandleUser = useCallback(
    (e: string) => {
      handleUserSelect && handleUserSelect(e, fieldId);
    },
    [fieldId, handleUserSelect],
  );

  const [fieldDrag, setFieldDrag] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const showAvatarWhenSaved = showAvatar;

  return (
    <TextFieldWrapper
      editor={editor}
      className="relative"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      draggable={false}>
      {userCanReorder && fieldDrag && isHovering && (
        <div
          className={`absolute top-9 -left-5 bg-gray-700 text-white py-1 px-2 rounded-[5px] `}>
          <Body2 kind="white" size="12px" style={{whiteSpace: 'nowrap'}}>
            Drag to Move
          </Body2>
        </div>
      )}

      <FlexRow className="w-full bg-transparent">
        {userCanReorder && isHovering && fieldLength && fieldLength > 1 ? (
          <div
            onMouseEnter={() => {
              setIsHovering(true);
              setFieldDrag(true);
            }}
            onMouseLeave={() => {
              setFieldDrag(false);
            }}
            className="filter pr-[9px] py-2 cursor-grab">
            <ConfigIcon />
          </div>
        ) : (
          <div className="mr-[20px] w-[5px]" />
        )}

        {checkable ? (
          <>
            <CheckableInputParentWrapper editor={editor} disabled={disabled}>
              <CheckableInputWrapper
                editor={editor}
                error={error !== undefined}
                background={
                  disabled && !editor
                    ? 'backgroundDark'
                    : useCompletedAsDisabled
                    ? 'backgroundLight'
                    : background
                }>
                <FlexRow
                  style={{alignItems: !showCheckbox ? 'flex-start' : 'center'}}>
                  {!showCheckbox && (
                    <div
                      className={`mt-[3px] ${
                        disableCheckbox ? 'cursor-not-allowed' : ''
                      }`}>
                      <CheckField
                        id={nextId('field')}
                        ref={checkboxRef}
                        state={error ? 'error' : 'default'}
                        noBottomMargin
                        kind={editor ? 'import' : 'default'}
                        checked={checked}
                        onChange={(e) => {
                          if (props?.value === '' || disableCheckbox) {
                            return false;
                          } else {
                            onHandleCheck(e);
                          }
                        }}
                        readOnly={disableCheckbox}
                      />
                    </div>
                  )}
                  {editor ? (
                    <>
                      <FieldEditor
                        checked={checked}
                        disabled={disabled}
                        value={props?.value as string | undefined}
                        editorOptions={editorOptions}
                        onHandleChange={(value) => onHandleChange(value)}
                      />
                    </>
                  ) : (
                    <FlexRow
                      style={{
                        width: '100%',
                        alignItems: !showCheckbox ? 'flex-start' : 'center',
                      }}>
                      <FieldInput
                        ref={inputRef}
                        checked={checked}
                        onHandleChange={onHandleChange}
                        useCompletedAsDisabled={useCompletedAsDisabled}
                        readonly={readonly}
                        {...props}
                        fontWeight={fontWeight}
                        background={background}
                        disabled={disabled}
                      />
                      {showAvatarWhenSaved && (
                        <>
                          <FieldUser
                            user={user}
                            disabled={disabled || readonly}
                            handleChange={onHandleUser}
                            assigneeOptions={assigneeOptions}
                          />
                        </>
                      )}
                    </FlexRow>
                  )}
                </FlexRow>

                {!!footerComponent && footerComponent(fieldId, indexOfFieldId)}
              </CheckableInputWrapper>
              <div className="meatball">
                {!!dropdownComponent &&
                  dropdownComponent(onHandleRemove, fieldId, indexOfFieldId)}
              </div>
            </CheckableInputParentWrapper>
            {error && (
              <HelperWrapper>
                <Helper aria-label="helper" state={'error'} children={error} />
              </HelperWrapper>
            )}
          </>
        ) : (
          <Input
            aria-label="input"
            onChange={onHandleChange}
            {...props}
            readOnly={readonly}
          />
        )}
        {!checkable && showRemove && (
          <RemoveButton tabIndex={-1} type="button" onClick={onHandleRemove}>
            <MinusSVG />
          </RemoveButton>
        )}
      </FlexRow>
    </TextFieldWrapper>
  );
});

Field.displayName = 'Field';
