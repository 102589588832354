import {useSections} from '@hooks/action-items-sections';
import {useFirebaseFetch} from '@hooks/query-hook';
import {useStoreContext} from '@store/store-context';
import {
  getGroupStats,
  getManagerStats,
  getTaskStats,
  getUserActionItems,
} from '@utils/action-items';
import {userName} from '@utils/user-name';
import dayjs from 'dayjs';
import {useCallback, useMemo} from 'react';

export const useAnalyticsActionItemsHook = (filters: {
  teams: string[];
  members: string[];
  groups: string[];
  breakdownBy: string;
  date?: {
    startDate: string;
    endDate: string;
  };
}) => {
  const {data} = useFirebaseFetch('action_items');

  const {
    usersStore: {users},
    groupStore: {groups: allGroups},
  } = useStoreContext();

  const formatUsers = useMemo(() => {
    const {teams, members, groups, breakdownBy} = filters;

    if (breakdownBy === 'teams') {
      return [];
    }
    if (members.length) {
      return users.filter((user) => members.includes(user.id));
    }

    if (groups.length) {
      return users.filter((user) =>
        user.groups?.some((group) => groups.includes(group.group)),
      );
    }

    if (teams.length) {
      return users.filter((user) => teams.includes(user.reviewer?.id));
    }
    return users;
  }, [filters, users]);

  const computeActionItems = useCallback(() => {
    const convertAndFilterTasks = (tasks: any) => {
      if (!tasks) return [];
      if (!Array.isArray(tasks)) {
        return Object.entries(tasks).map(([firebaseId, task]: any) => ({
          ...task,
          firebaseId,
        }));
      }
      return tasks;
    };

    const convertedTasks = convertAndFilterTasks(data);

    let _computedTasks = convertedTasks
      .map((task) => ({
        ...task,
        key: task.id || task._id || task.key || '',
      }))
      .sort((a, b) => a.order - b.order);
    //     if (filters.date) {
    //   _computedTasks = _computedTasks.filter((task) => dayjs(task.created))
    // }
    return _computedTasks;
  }, [data]);

  const filteredUsers = formatUsers.map((user) => user.id);

  const tasks = useMemo(() => {
    return computeActionItems()?.length
      ? computeActionItems().filter((task) =>
          filteredUsers.some((user) =>
            [task?.createdBy, task?.user, task?.assignee].includes(user),
          ),
        )
      : [{text: '', completed: false}];
  }, [computeActionItems, filteredUsers]);

  const activeActionItems = tasks.filter((task) => !task.completed).length;

  const completedActionItems = tasks.filter((task) => task.completed).length;

  const overdueActionItems = tasks.filter((task) =>
    dayjs().isAfter(dayjs(task?.dueDate)),
  ).length;

  const assignedActionItems = tasks.filter((task) => !!task?.assignee).length;
  const undatedActionItems = tasks.filter((task) => !task?.dueDate).length;

  const completedOnDueDateActionItems =
    tasks.filter((task) => task?.completed && dayjs(task.dueDate).isValid())
      .length || 0;

  const {computeSections} = useSections();

  const groupBySections = useCallback(
    (sections: {slug: string; name: string}[]) => {
      const groupedItems = tasks.reduce((acc, item) => {
        const itemSection = sections.find(
          (_section) => _section.slug === item?.section,
        );

        let section = itemSection?.name || '';

        if (!!section && itemSection) {
          section = itemSection?.name;

          item.section_slug = itemSection?.slug;
        } else {
          section = 'Inbox';
        }

        if (!acc[section]) {
          acc[section] = [];
        }
        acc[section].push(item);
        sections.forEach((_section) => {
          if (!acc[_section.name]) {
            acc[_section.name] = [];
          }
        });
        return acc;
      }, {});
      // Ensure "Inbox" is the first entry
      const entries = Object.entries(groupedItems);

      entries.sort(([a], [b]) => {
        if (a === 'Inbox') return -1;
        if (b === 'Inbox') return 1;
        return 0;
      });
      return entries;
    },
    [tasks],
  );

  const filteredData = useMemo(() => {
    if (filters.breakdownBy === 'boards') {
      return groupBySections(computeSections).map(
        ([key, value]: [key: string, value: any]) => ({
          name: key,
          avatar: '',

          ...getTaskStats(value),
        }),
      );
    }
    if (filters.breakdownBy === 'team') {
      const managers = users.filter((user) => user.isReviewer);
      return getManagerStats(managers, users, tasks);
    }
    if (filters.breakdownBy === 'group') {
      return getGroupStats(allGroups, users, tasks);
    }

    return formatUsers.map((user) => {
      const userActionItems = getUserActionItems(user.id, tasks);

      return {
        name: userName(user),
        avatar: user?.avatar?.url || '',
        ...getTaskStats(userActionItems, user.id),
      };
    });
  }, [
    filters.breakdownBy,
    formatUsers,
    groupBySections,
    computeSections,
    users,
    tasks,
    allGroups,
  ]);

  const summary = useMemo(() => {
    return {
      assigned: assignedActionItems,
      overdue: overdueActionItems,
      completed: completedActionItems,
      total: activeActionItems + overdueActionItems + completedActionItems,
      active: activeActionItems,
      undated: undatedActionItems,
      undatedPercent: Math.round((undatedActionItems / tasks.length) * 100),
      activePercent: Math.round((activeActionItems / tasks.length) * 100),
      completedPercent: Math.round((completedActionItems / tasks.length) * 100),
      overduePercent: Math.round((overdueActionItems / tasks.length) * 100),
      completionRate: Math.round(
        (completedOnDueDateActionItems / tasks.length) * 100,
      ),
    };
  }, [
    assignedActionItems,
    overdueActionItems,
    undatedActionItems,
    completedActionItems,
    activeActionItems,
    completedOnDueDateActionItems,
    tasks.length,
  ]);

  return {
    summary,
    filteredData,
  };
};
