import React, {memo} from 'react';

export const ArrowLineRight = memo(({color}: any) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      stroke={color}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.32812 8H12.6615"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 3.33594L12.6667 8.0026L8 12.6693"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});
