import {BaseRequest} from '@configs/request';
import {authStore} from '@store/stores/auth-store';

export interface ITaskRequest {
  getUserTaskList(userId: string): Promise<any>;
}
export class ITaskRequestImpl extends BaseRequest implements ITaskRequest {
  url = '/users';

  async getUserTaskList(userId: string): Promise<any> {
    const {data} = await this.request.get(`${this.url}/${userId}/task-list`, {
      headers: {
        Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
      },
    });
    return data;
  }
}
