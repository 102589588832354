/* eslint-disable react-hooks/rules-of-hooks */
import {Button} from '../../../../../../../../ui/atoms/button';

import {memo, useCallback, useMemo, useRef, useState} from 'react';
import {Control, Controller} from 'react-hook-form';
import {VerticalSpacer} from '../../../../../../../../ui/atoms/spacer';
import {Label} from '../../../../../../../../ui/atoms/label';
import {ItemLoader} from '../../../../../../../../ui/organisms/item-loader';
import {useStoreContext} from '../../../../../../../../store/store-context';

import {SelectField} from '@ui/molecules/select/selectfield';
import {EditHeader} from './create-question.styles';
import {MultiOptionsSelectField} from '../../../../../../../../ui/molecules/select/multi-options';
import {GroupsMultiselect} from '../../../../../../member-profile/member-profile-component/personal';
import {Headline2, CTA} from '../../../../../../../../ui/atoms/typography';
import {QuestionTypeField} from '../../../../../../../../ui/molecules/field/question-type-field';
import {Questions} from './question/questions';
import {
  ICreateQuestionForm,
  ICreateQuestionFormFormFormat,
  QuestionKind,
} from '../../../company-checkins.interface';
import {useCreateQuestionHook} from './create-question.hooks';
import {MultiOptionsSelectFieldGroup} from '../../../../../../../../ui/molecules/select/multi-options/multi-options';
import {ItemLoaderLine} from '../../../../../../../../ui/organisms/item-loader-line';
import {observer} from 'mobx-react';

export const IndividualsMultiselect = memo(
  ({
    control,
    handleSelectChangeIndividuals,
    individuals,
    currentIndividuals,
    label,
    disabled,
  }: any) => {
    return (
      <Controller
        control={control}
        name="groups"
        render={({onBlur}) => (
          <MultiOptionsSelectField
            onBlur={onBlur}
            defaultValue={currentIndividuals?.map((indiv: any) => ({
              label: indiv.name,
              value: indiv.id,
            }))}
            name="individuals"
            label={label}
            onChange={(data: {value: string; label: string}[]) =>
              handleSelectChangeIndividuals(data)
            }
            placeholder={'Select individuals'}
            options={individuals}
            disabled={disabled}
          />
        )}
      />
    );
  },
);

export const CreateQuestionForm = observer(
  ({
    onSubmit,
    editheaderShow,
    handleCloseDrawer,
    editMode,
    onCloseEditModal,
    modalBatch,
    batchType,
  }: {
    onSubmit?: (data: ICreateQuestionForm) => Promise<void>;
    editheaderShow?: boolean;
    handleCloseDrawer?: any;
    editMode?: boolean;
    onCloseEditModal?: any;
    modalBatch?: string | null;
    batchType?: string | null;
  }) => {
    const {
      isSubmitting,
      errors,
      control,
      // hasError,
      handleFormValueChange,
      isFetching,
      watch,
      setIsGroup,
      submitForm,
      FREQUENCY_OPTIONS,
      submitEditedForm,
      handleSubmit,
      loadingQuestionTemplates,
      questions__,
      setQuestions__,
      questionTypes,
    } = useCreateQuestionHook(onSubmit, {
      mode: editMode,
      batch: modalBatch,
      type: batchType,
    });

    const {
      groupStore: {allGroups},
      usersStore: {users},
    } = useStoreContext();
    const {target} = watch();

    const [
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      selectedQuestionType,
      setSelectedQuestionType,
    ] = useState<QuestionKind | undefined | string>(QuestionKind.INDIVIDUAL);

    const questions_ = useCallback(
      (type: string) => {
        return questions__.filter((q) => q?.type?.label === type)[0]?.question;
      },
      [questions__],
    );
    const parseName = (data: any) => {
      return data?.firstName && data?.lastName
        ? `${data?.firstName} ${data?.lastName}`
        : data?.email;
    };
    const parseSingleUser = (id: string) => {
      return users?.filter((user) => user?.id === id) as any;
    };

    const Groups = useCallback(
      ({
        control,
        name,
        handleSelectChangeGroups,
        group,
        disabled,
        currentUserGroups,
        label,
        placeholder,
        helper,
        state,
      }: {
        control: Control<ICreateQuestionFormFormFormat>;
        name: string;
        handleSelectChangeGroups: (data: any) => void;
        group: {
          label: any;
          value: any;
        }[];
        disabled: boolean;
        currentUserGroups?: {
          name: string;
          id: string;
        }[];
        label?: string;
        placeholder?: string;
        helper?: any;
        state?: any;
      }) => {
        return (
          <>
            <GroupsMultiselect
              control={control}
              name={name}
              currentUserGroups={currentUserGroups}
              handleSelectChangeGroups={handleSelectChangeGroups}
              group={group}
              //   feature="invite"
              disabled={disabled}
              placeholder={placeholder}
              label={label}
              helper={helper}
              state={state}
            />
          </>
        );
      },
      [],
    );

    const {
      groupStore: {groups: group},
      groupTypeStore: {groupType},
    } = useStoreContext();

    const formatOptionsTypes = useMemo(() => {
      return groupType?.map((grpType) => {
        return {
          ...grpType,
          data: group
            .filter((group) => group.groupType === grpType.id)
            .map((group) => {
              return {
                label: group.name,
                value: group.id,
              };
            }),
        };
      });
    }, [group, groupType]);

    const frequencyRef = useRef(null);

    // when form is not ready
    if (isFetching) {
      return <ItemLoader />;
    }

    return (
      <form
        onSubmit={handleSubmit(
          editMode
            ? (data) => submitEditedForm(data, onCloseEditModal)
            : submitForm,
        )}>
        {editMode && (
          <>
            <EditHeader style={{justifyContent: 'space-between'}}>
              <Headline2 kind="textDark" className="hl">
                Edit question
              </Headline2>
            </EditHeader>
            <VerticalSpacer size="26px" />
          </>
        )}
        <Label>Who answers this question?</Label>
        <Controller
          control={control}
          name="target.type"
          render={({value, onChange}) => (
            <QuestionTypeField
              name="questionType"
              value={value}
              setValue={onChange}
              onClick={(data: any) => {
                setIsGroup(data.target.value === QuestionKind.GROUP && true);
                setSelectedQuestionType(data.target.value);
              }}
            />
          )}
        />

        {watch()?.target?.type === QuestionKind.GROUP && (
          <>
            <Label>Select group(s)</Label>

            <Controller
              control={control}
              name={`target.ids.group`}
              render={({onBlur, value}) => (
                <>
                  <MultiOptionsSelectFieldGroup
                    options={formatOptionsTypes}
                    performance={true}
                    searchPlaceholder="Search group"
                    groupDisplayLength={5}
                    noOptionsMessage="Group not found"
                    placeholder="Select Groups"
                    helper={(errors?.target?.ids?.group as any)?.message}
                    state={!!errors?.target?.ids?.group ? 'error' : 'default'}
                    defaultValue={value?.map((val?: string) => ({
                      label: allGroups?.get(val)?.name,
                      value: val,
                    }))}
                    onChange={(data: any) => {
                      handleFormValueChange(
                        data.map(
                          (group: {label: string; value: string}) =>
                            group.value,
                        ),
                        'target.ids.group',
                      );
                    }}
                  />
                  <VerticalSpacer size="24px" />
                </>
              )}
            />
          </>
        )}
        {watch()?.target?.type === QuestionKind.INDIVIDUAL && (
          <>
            <Groups
              control={control}
              name="target.ids.individual"
              currentUserGroups={target.ids?.individual?.map(
                (userId: string) => ({
                  name: parseName(parseSingleUser(userId)[0]),
                  id: userId,
                }),
              )}
              handleSelectChangeGroups={(data: any) => {
                handleFormValueChange(
                  data.map(
                    (group: {label: string; value: string}) => group.value,
                  ),
                  'target.ids.individual',
                );
              }}
              group={users.map((user) => ({
                label: parseName(user),
                value: user?.id,
              }))}
              //   feature="invite"
              disabled={false}
              label="Select the individual(s)"
              placeholder="Select individual(s)"
              helper={(errors?.target?.ids?.individual as any)?.message}
              state={!!errors?.target?.ids?.individual ? 'error' : 'default'}
            />
          </>
        )}

        <div>
          <CTA kind="textDark">Select frequency</CTA>
          <Controller
            control={control}
            name="frequency"
            render={({onBlur, value}) => (
              <SelectField
                onBlur={onBlur}
                name="frequency"
                borderRadius="10px"
                ref={frequencyRef}
                value={value}
                helper={errors['frequency']?.message || ''}
                state={!!errors['frequency'] ? 'error' : 'default'}
                onChange={(data: {value: string}) =>
                  handleFormValueChange(data?.value, 'frequency')
                }
                placeholder="Select an option"
                options={FREQUENCY_OPTIONS}
                isDisabled={false}
              />
            )}
          />
        </div>

        {loadingQuestionTemplates ? (
          <ItemLoaderLine />
        ) : (
          <Controller
            control={control}
            name="questions"
            render={({value}) => (
              <Questions
                questionTypes={questionTypes.map(
                  (questionType) => questionType.type,
                )}
                questionTypeHelper={(errors as any).questionType?.message || ''}
                questionTypeState={
                  !!(errors as any).questionType ? 'error' : 'default'
                }
                setValue={(value: any) => {
                  handleFormValueChange(value, 'questions');
                }}
                defaultValue={value}
                questions_={questions_}
                questions__={questions__}
                setQuestions__={setQuestions__}
                helper={errors?.questions}
                state={errors?.questions}
              />
            )}
          />
        )}

        <VerticalSpacer size="24px" />

        {!editMode && (
          <Button
            width="full"
            type="submit"
            style={{
              height: '54px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              overflow: 'hidden',
            }}
            data-form-action={true}
            isLoading={isSubmitting}
            disabled={isSubmitting}
            aria-busy={isSubmitting}>
            Save question
          </Button>
        )}
        {editMode && (
          <>
            <Button
              type="submit"
              width="full"
              style={{
                padding: '12px 24px',
              }}>
              Save Changes
            </Button>
            <Button
              kind="secondary"
              width="full"
              style={{marginTop: '10px', padding: '12px 24px'}}
              onClick={onCloseEditModal}>
              Cancel
            </Button>
          </>
        )}
      </form>
    );
  },
);
