import {makeAutoObservable, observable, action} from 'mobx';
import {AnalyticsTodayDate} from '../../utils/date';
import {
  getStatusPercentage,
  getStatusRateOfIncreaseBetweenDates,
} from '../../utils/proportions';

export class AnalyticsState {
  ////////// A.  OBSERVERS START ////////////////////////////////////////
  //1. goal activities, goal ownership and ownership key results data .
  //default
  @observable defaultAnalyticsDataMetrics: any = {
    onTrack: 0,
    onTrackStart: 0,
    behind: 0,
    behindStart: 0,
    atRisk: 0,
    atRiskStart: 0,
    goalsCount: 0,
    withGoals: 0,
    withGoalsStart: 0,
    withoutGoals: 0,
    withoutGoalsStart: 0,
    withKeyResults: 0,
    withKeyResultsStart: 0,
    withoutKeyResults: 0,
    withoutKeyResultsStart: 0,
    graphStatus: {
      goalActivities: [
        {
          startDate: '',
          endDate: '',
          atRisk: 0,
          onTrack: 0,
          behind: 0,
          total: 0,
        },
        {
          startDate: '',
          endDate: '',
          atRisk: 0,
          onTrack: 0,
          behind: 0,
          total: 0,
        },
        {
          startDate: '',
          endDate: '',
          atRisk: 0,
          onTrack: 0,
          behind: 0,
          total: 0,
        },
        {
          startDate: '',
          endDate: '',
          atRisk: 0,
          onTrack: 0,
          behind: 0,
          total: 0,
        },
        {
          startDate: '',
          endDate: '',
          atRisk: 0,
          onTrack: 0,
          behind: 0,
          total: 0,
        },
      ],
    },
    graphOwnership: {
      goalsOwnership: [
        {
          startDate: '',
          endDate: '',
          withGoals: 0,
          withoutGoals: 0,
        },
        {
          startDate: '',
          endDate: '',
          withGoals: 0,
          withoutGoals: 0,
        },
        {
          startDate: '',
          endDate: '',
          withGoals: 0,
          withoutGoals: 0,
        },
        {
          startDate: '',
          endDate: '',
          withGoals: 0,
          withoutGoals: 0,
        },
        {
          startDate: '',
          endDate: '',
          withGoals: 0,
          withoutGoals: 0,
        },
      ],
    },
    graphKeyResults: {
      keyResultsOwnership: [
        {
          startDate: '',
          endDate: '',
          withKeyResults: 0,
          withoutKeyResults: 0,
        },
        {
          startDate: '',
          endDate: '',
          withKeyResults: 0,
          withoutKeyResults: 0,
        },
        {
          startDate: '',
          endDate: '',
          withKeyResults: 0,
          withoutKeyResults: 0,
        },
        {
          startDate: '',
          endDate: '',
          withKeyResults: 0,
          withoutKeyResults: 0,
        },
        {
          startDate: '',
          endDate: '',
          withKeyResults: 0,
          withoutKeyResults: 0,
        },
      ],
    },
  };

  @observable analyticsDataMetrics: any = this.defaultAnalyticsDataMetrics;
  //2. goal activities, goal ownership and ownership key results percentage calculation .
  @observable analyticsPercentages: any = {
    onTrackPercentage: 0,
    behindPercentage: 0,
    atRiskPercentage: 0,
    withGoalsPercentage: 0,
    withoutGoalsPercentage: 0,
    withKeyResultsPercentage: 0,
    withoutKeyResultsPercentage: 0,
  };
  //3. percentage between prior and today's date or end date
  @observable analyticsIntervalPercentage: any = {
    onTrackBetweenDates: 0,
    behindBetweenDates: 0,
    atRiskBetweenDates: 0,
    withGoalsBetweenDates: 0,
    withoutGoalsBetweenDates: 0,
    withKeyResultsBetweenDates: 0,
    withoutKeyResultsBetweenDates: 0,
  };
  // 4a. goals data

  @observable viewAnalyticsRange: 'weeks' | 'months' | 'days' | '' = '';

  @observable goalsData: any = null;

  @observable selectedTags: any = null;

  @observable breakdownBy: 'ownership' | 'group' | 'team' | null = null;

  @observable selectedDateFilterValue = '';

  @observable noGoals: number = 0; // Consider deleting this
  // 4b. kpis data
  @observable kpisData: any = null;
  // 5. people data
  @observable peopleDataForStatus: any = null;
  @observable peopleDataForOwnership: any = null;
  // 6. group data
  @observable groups: any = null;
  @observable groupData: any = null;
  @observable departmentsData: any = [];
  // 7. Dates
  analyticsDates = AnalyticsTodayDate();
  @observable kpiBoardGoals = [];

  @observable dashboardDate: any = {
    starts: this.analyticsDates.AnalyticsDefaultStartDate,
    ends: this.analyticsDates.AnalyticsDefaultEndDate,
  };
  @observable goalsDate: any = {
    starts: this.analyticsDates.AnalyticsDefaultStartDate,
    ends: this.analyticsDates.AnalyticsDefaultEndDate,
    period: this.analyticsDates.AnalyticsDefaultPeriod,
  };
  @observable kpisDate: any = {
    starts: this.analyticsDates.AnalyticsDefaultStartDate,
    ends: this.analyticsDates.AnalyticsDefaultEndDate,
  };
  @observable groupDate: any = {
    starts: this.analyticsDates.AnalyticsDefaultStartDate,
    ends: this.analyticsDates.AnalyticsDefaultEndDate,
  };
  @observable statusPeopleDate: any = {
    starts: this.analyticsDates.AnalyticsDefaultStartDate,
    ends: this.analyticsDates.AnalyticsDefaultEndDate,
  };
  @observable ownershipPeopleDate: any = {
    starts: this.analyticsDates.AnalyticsDefaultStartDate,
    ends: this.analyticsDates.AnalyticsDefaultEndDate,
  };
  @observable exportsReportDate: any = null;

  @observable userOverviewFilters = {
    filterBy: {
      label: 'Team',
      value: 'team',
    },
    selectedTeam: {
      value: '',
      label: '',
    },
    members: [] as {value: string; label: string}[],
  };
  @observable sortBy = 'desc';
  @observable searchName = '';

  @observable OverviewDateRange = {
    starts: AnalyticsTodayDate().AnalyticsDefaultStartDate,
    ends: AnalyticsTodayDate().AnalyticsDefaultEndDate,
    compare: {
      starts: '',
      ends: '',
    },
  };
  // 8. Pagination observables
  computePages = (paginator: any) => {
    let pages = [];
    for (
      let i = 1;
      i <= Math.ceil(paginator['totalResults'] / paginator['limit']);
      i++
    ) {
      pages.push(i);
    }
    return pages;
  };
  // goals
  @observable goalPaginator: any = {
    limit: 0,
    page: 1,
    totalPages: 1,
    totalResults: 0,
    pageNumberLimit: 1,
    maxPageNumberLimit: 2,
    minPageNumberLimit: 0,
  };

  // kpis
  @observable kpiPaginator: any = {
    limit: 0,
    page: 1,
    totalPages: 1,
    totalResults: 0,
    pageNumberLimit: 1,
    maxPageNumberLimit: 2,
    minPageNumberLimit: 0,
  };
  @observable goalPages: number[] = this.computePages(this.goalPaginator);
  @observable kpiPages: number[] = this.computePages(this.kpiPaginator);
  // status people
  @observable statusPeoplePaginator: any = {
    limit: 0,
    page: 1,
    totalPages: 1,
    totalResults: 0,
    pageNumberLimit: 1,
    maxPageNumberLimit: 2,
    minPageNumberLimit: 0,
  };
  @observable statusPeoplePages: number[] = this.computePages(
    this.statusPeoplePaginator,
  );

  // ownership people paginator
  @observable ownershipPeoplePaginator: any = {
    limit: 0,
    page: 1,
    totalPages: 1,
    totalResults: 0,
    pageNumberLimit: 1,
    maxPageNumberLimit: 2,
    minPageNumberLimit: 0,
  };

  @observable ownershipPeoplePages: number[] = this.computePages(
    this.ownershipPeoplePaginator,
  );

  // 9. Filter feature observables
  @observable searchable: any = {
    members: null,
    managers: null,
    department: null,
    departmentMembers: null,
    goals: null,
    goalType: null,
    deactivatedMembers: null,
  };
  @observable companySearchable: any = {
    members: null,
    managers: null,
    department: null,
    departmentMembers: null,
    goalType: null,
  };
  @observable filterGroup: any = [];
  @observable filterGroupType: any = null;
  @observable filterGroupTypeId: any = null;
  @observable filterCompletion: any = {
    goals: null,
    keyresults: null,
  };

  @observable filterState: any = {
    goals: '',
    kpis: '',
    dashboard: '',
    group: '',
    people: '',
  };
  @observable filterTarget: any = {
    kpis: '',
  };
  @observable filterStatus: any = {
    goals: 'on_track,behind,at_risk,no_status',
    kpis: '',
    group: '',
    people: '',
  };
  @observable okrStatus: any = {
    withGoals: null,
    withoutGoals: null,
    withKeyResults: null,
    withoutKeyResults: null,
  };

  // 10. Handle Loaders
  @observable loading: any = {
    goals: true,
    group: true,
    statusPeople: true,
    ownershipPeople: true,
    dashboard: true,
    exports: true,
  };
  @observable disableFilterOutsideClick: boolean = false;
  // 11. Members and managers
  @observable members: any = null;
  @observable managers: any = null;

  //////////// OBSERVERS END ////////////////
  constructor() {
    makeAutoObservable(this);
  }

  // 12. Miscellaneous
  @observable defaultStatus: any = null;
  @observable defaultOwnership: any = null;
  @observable status: any = {
    onTrack: null,
    behind: null,
    atRisk: null,
  };

  //////////// B. ACTIONS START //////////////////////////////////////

  // 1. Update analytics and goals,people, group data
  @action
  updateAnalyticsDataMetrics = (field: string, data: any) => {
    this.analyticsDataMetrics[field] = data;
  };
  @action
  updateSelectedTags = (field: string) => {
    this.selectedTags = field;
  };

  @action handleAnalyticsFilterOpen = (status: boolean) => {
    this.disableFilterOutsideClick = status;
  };

  @action updateKPIBoard = (data: any) => {
    this.kpiBoardGoals = data;
  };
  @action updateDateValue = (value: string) => {
    this.selectedDateFilterValue = value;
  };
  @action
  updateAnalyticsPercentages = (
    field: string,
    statusGoals: number,
    totalGoals: number,
  ) => {
    this.analyticsPercentages[field] = getStatusPercentage(
      statusGoals,
      totalGoals,
    );
  };
  @action
  updateAnalyticsIncreaseRatePercentages = (
    field: string,
    statusAtFirst: number,
    statusAtLast: number,
  ) => {
    this.analyticsIntervalPercentage[
      field
    ] = getStatusRateOfIncreaseBetweenDates(statusAtFirst, statusAtLast);
  };

  @action
  setNumOfGoals = (number: number) => {
    this.noGoals = number;
  };

  @action
  setGoalsData = (data: any) => {
    this.goalsData = data;
  };

  @action
  setKpisData = (data: any) => {
    this.kpisData = data;
  };

  @action
  setPeopleDataForStatus = (data: any) => {
    this.peopleDataForStatus = data;
  };

  @action setAnalyticsRange = (range: 'weeks' | 'days' | 'months' | '') => {
    this.viewAnalyticsRange = range;
  };

  @action setSortBy = (value: string) => {
    this.sortBy = value;
  };
  @action
  setDepartmentsData = (data: any) => {
    this.departmentsData = data;
  };
  @action
  transportAllGroupsToDepartmentsData = (data: any) => {
    this.departmentsData = [...this.departmentsData, ...data];
  };
  @action
  setPeopleDataForOwnership = (data: any) => {
    this.peopleDataForOwnership = data;
  };
  @action
  setGoalPaginator = (field: string, data: any) => {
    this.goalPaginator[field] = data;
  };
  @action
  setKpiPaginator = (field: string, data: any) => {
    this.kpiPaginator[field] = data;
  };
  @action
  setGoalPages = (data: any) => {
    this.goalPages = this.computePages(data);
  };
  @action
  setKpiPages = (data: any) => {
    this.kpiPages = this.computePages(data);
  };
  @action
  setPeoplePaginatorForStatus = (field: string, data: any) => {
    this.statusPeoplePaginator[field] = data;
  };
  @action
  setStatusPeoplePages = (data: any) => {
    this.statusPeoplePages = this.computePages(data);
  };
  @action
  setPeoplePaginatorOwnership = (field: string, data: any) => {
    this.ownershipPeoplePaginator[field] = data;
  };
  @action setuserOverviewFilters = (data: any) => {
    this.userOverviewFilters = data;
  };

  @action setOverviewDateRange = (data: any) => {
    this.OverviewDateRange = data;
  };
  @action
  setOwnershipPeoplePages = (data: any) => {
    this.ownershipPeoplePages = this.computePages(data);
  };
  @action
  setGroupData = (data: any) => {
    this.groupData = data;
  };
  @action
  setGroups = (data: any) => {
    this.groups = data;
  };

  // 2. Modify date
  @action
  modifyDashboardDate = (starts: string, ends: string) => {
    this.dashboardDate = {starts, ends};
  };
  @action
  modifyGoalsDate = (
    starts: string | undefined,
    ends: string | undefined,
    period?: string,
  ) => {
    this.goalsDate = {starts, ends, period};
  };
  @action
  modifyKpisDate = (starts: string | undefined, ends: string | undefined) => {
    this.kpisDate = {starts, ends};
  };
  @action
  modifyGroupDate = (starts: string, ends: string) => {
    this.groupDate = {starts, ends};
  };
  @action
  updateSearchValue = (search: string) => {
    this.searchName = search;
  };

  @action
  modifyStatusPeopleDate = (starts: string, ends: string) => {
    this.statusPeopleDate = {starts, ends};
  };
  @action
  modifyOwnershipPeopleDate = (starts: string, ends: string) => {
    this.ownershipPeopleDate = {starts, ends};
  };
  @action
  modifyExportsReportDate = (starts: string | null, ends?: string | null) => {
    if (starts === null && ends === null) {
      this.exportsReportDate = null;
    } else {
      this.exportsReportDate = {starts, ends};
    }
  };

  // 3. Pagination actions
  //    base functions
  handlePageClick = (id: any, paginator: any) => {
    paginator['page'] = Number(id);
  };
  handleNextbtn = (paginator: any) => {
    paginator['page'] = paginator['page'] + 1;

    if (paginator['page'] + 1 > paginator['maxPageNumberLimit']) {
      paginator['maxPageNumberLimit'] =
        paginator['maxPageNumberLimit'] + paginator['pageNumberLimit'];
      paginator['minPageNumberLimit'] =
        paginator['minPageNumberLimit'] + paginator['pageNumberLimit'];
    }
  };
  handlePrevbtn = (paginator: any) => {
    paginator['page'] = paginator['page'] - 1;

    if ((paginator['page'] - 1) % paginator['pageNumberLimit'] === 0) {
      paginator['maxPageNumberLimit'] =
        paginator['maxPageNumberLimit'] - paginator['pageNumberLimit'];
      paginator['minPageNumberLimit'] =
        paginator['minPageNumberLimit'] - paginator['pageNumberLimit'];
    }
  };
  //   goals
  @action
  handleGoalPageClick = (event: any) =>
    this.handlePageClick(event, this.goalPaginator);
  @action
  handleGoalNextbtn = () => this.handleNextbtn(this.goalPaginator);
  @action
  handleGoalPrevbtn = () => this.handlePrevbtn(this.goalPaginator);
  //   kpis
  @action
  handleKpiPageClick = (event: any) =>
    this.handlePageClick(event, this.kpiPaginator);
  @action
  handleKpiNextbtn = () => this.handleNextbtn(this.kpiPaginator);
  @action
  handleKpiPrevbtn = () => this.handlePrevbtn(this.kpiPaginator);
  //  status people
  @action
  handleStatusPeoplePageClick = (event: any) =>
    this.handlePageClick(event, this.statusPeoplePaginator);
  @action
  handleStatusPeopleNextbtn = () =>
    this.handleNextbtn(this.statusPeoplePaginator);
  @action
  handleStatusPeoplePrevbtn = () =>
    this.handlePrevbtn(this.statusPeoplePaginator);
  //  ownership people
  @action
  handleOwnershipPeoplePageClick = (event: any) =>
    this.handlePageClick(event, this.ownershipPeoplePaginator);
  @action
  handleOwnershipPeopleNextbtn = () =>
    this.handleNextbtn(this.ownershipPeoplePaginator);
  @action
  handleOwnershipPeoplePrevbtn = () =>
    this.handlePrevbtn(this.ownershipPeoplePaginator);

  // 4. Filter feature actions
  @action
  setSearchable = (
    field:
      | 'members'
      | 'managers'
      | 'department'
      | 'selected'
      | 'goals'
      | 'departmentMembers'
      | 'goalType'
      | 'deactivatedMembers',
    data: any,
  ) => {
    this.searchable[field] = data;
  };
  @action
  setCompanySearchable = (
    field:
      | 'members'
      | 'managers'
      | 'department'
      | 'selected'
      | 'departmentMembers'
      | 'goalType',
    data: any,
  ) => {
    this.companySearchable[field] = data;
  };

  @action
  setFilterGroup = (data: any) => {
    this.filterGroup = data;
  };
  @action
  setGoalBreakdownBy = (data: any) => {
    this.breakdownBy = data;
  };

  @action
  setFilterGroupType = (data: any) => {
    this.filterGroupType = data;
  };

  @action
  setFilterGroupTypeId = (data: any) => {
    this.filterGroupTypeId = data;
  };

  @action
  setFilterState = (
    field: 'goals' | 'kpis' | 'dashboard' | 'group' | 'people',
    data: any,
  ) => {
    this.filterState[field] = data;
  };

  @action
  setFilterCompletion = (field: 'goals' | 'keyresults', data: any) => {
    this.filterCompletion[field] = data;
  };

  @action
  setFilterTarget = (field: 'kpis', data: any) => {
    this.filterTarget[field] = data;
  };

  @action
  setFilterStatus = (
    field: 'goals' | 'kpis' | 'group' | 'people',
    data: any,
  ) => {
    this.filterStatus[field] = data;
  };

  @action resetDateFilter = () => {
    this.dashboardDate = {
      starts: this.analyticsDates.AnalyticsDefaultStartDate,
      ends: this.analyticsDates.AnalyticsDefaultEndDate,
    };

    this.goalsDate = {
      starts: this.analyticsDates.AnalyticsDefaultStartDate,
      ends: this.analyticsDates.AnalyticsDefaultEndDate,
    };
    this.groupDate = {
      starts: this.analyticsDates.AnalyticsDefaultStartDate,
      ends: this.analyticsDates.AnalyticsDefaultEndDate,
    };

    this.statusPeopleDate = {
      starts: this.analyticsDates.AnalyticsDefaultStartDate,
      ends: this.analyticsDates.AnalyticsDefaultEndDate,
    };

    this.ownershipPeopleDate = {
      starts: this.analyticsDates.AnalyticsDefaultStartDate,
      ends: this.analyticsDates.AnalyticsDefaultEndDate,
    };
  };

  @action
  resetFilter = () => {
    this.searchable = {
      members: null,
      managers: null,
      department: null,
      goals: null,
      departmentMembers: null,
      goalType: null,
      deactivatedMembers: null,
    };

    this.filterGroupType = null;
    this.filterState = {
      goals: '',
      kpis: '',
      dashboard: '',
      group: '',
      people: '',
    };
    this.filterTarget = {
      kpis: '',
    };
    this.okrStatus = {
      withGoals: null,
      withoutGoals: null,
      withKeyResults: null,
      withoutKeyResults: null,
    };

    this.filterStatus = {
      goals: '',
      kpis: '',
      group: '',
      people: '',
    };
  };

  // 5. Loading actions
  setLoading = (
    field:
      | 'goals'
      | 'kpis'
      | 'group'
      | 'dashboard'
      | 'statusPeople'
      | 'ownershipPeople'
      | 'exports',
    state: boolean,
  ) => {
    this.loading[field] = state;
  };

  // 6. Members and Managers actions
  setMembers = (data: any) => {
    this.members = data;
  };
  setManagers = (data: any) => {
    this.managers = data;
  };

  // 7. Miscellaneous actions
  setDefaultStatus = (data: any) => {
    this.defaultStatus = data;
  };
  setDefaultOwnership = (data: any) => {
    this.defaultOwnership = data;
  };
  setStatus = (field: 'atRisk' | 'behind' | 'onTrack', data: any) => {
    this.status[field] = data;
  };
  setOkrStatus = (
    field:
      | 'withGoals'
      | 'withoutGoals'
      | 'withKeyResults'
      | 'withoutKeyResults',
    data: any,
  ) => {
    this.okrStatus[field] = data;
  };

  ////////// ACTIONS END /////////////////////////////////////////
}

export const analyticsState = new AnalyticsState();
