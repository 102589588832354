import styled from 'styled-components';
import {getColorFromTheme} from '@ui/ui-utils';
import {Typography} from '@ui/atoms/typography';
import {Button} from '@ui/atoms/button';

export const GroupTypeWrapper = styled.div`
  border: solid 1px ${getColorFromTheme('borderLight')};
  border-radius: 10px;
`;
export const DropdownWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 50px;
`;

export const FirstWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;

  > span {
    display: flex;
    align-items: center;
  }
`;

export const SecondWrapper = styled.div`
  border-top: solid 1px ${getColorFromTheme('borderLight')};
  border-radius: 10px;
  .searchBox {
    padding: 16px;
  }
`;
export const ViewButton = styled(Button)`
  width: 71px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 40% 47% 20%;
  background: ${getColorFromTheme('backgroundLight')};

  height: 52px;
  padding: 16px;
`;

export const TableHeaderChild = styled.div`
  display: grid;
  grid-template-columns: 45% 33% 20%;
  grid-gap: 3%;
  padding: 16px;
  border-top: 1px solid ${getColorFromTheme('borderLight')};
  height: 70px;
  padding: 11px 16px 16px 16px;
  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  > button > p {
    display: flex;
    align-items: center;
  }

  > button > p > svg {
    width: 10.5px;
    height: 14px;
    margin: 6.4px 9px 8.4px 0;
  }

  button > svg:hover,
  svg:active {
    stroke: ${getColorFromTheme('purple300')};
  }
`;

export const DropdownItem = styled.button`
  ${Typography};
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  cursor: pointer;
  border: none;
  background: none;
  display: block;
  outline: none;
  line-height: 1.7;
  padding: 6px 16px;
  transition: all ease-in-out 200ms;
  color: ${getColorFromTheme('textBody')};

  &:hover {
    background-color: ${getColorFromTheme('backgroundDark')};
    border-radius: 10px;
    color: ${getColorFromTheme('textDark')};
  }
`;

export const ModalWrapper = styled.div`
  max-width: 560px;
  margin: auto;
`;

export const ModalCard = styled.div`
  background: ${getColorFromTheme('white')};
  padding: 80px;
  border: 1px solid ${getColorFromTheme('borderLight')};
  border-radius: 10px;
`;

export const LoaderWrapper = styled.div`
  padding: 16px;
  border-top: 1px solid ${getColorFromTheme('borderLight')};
`;

export const CustomButton = styled(Button)`
  padding: 8px 16px;
`;
