import {useCallback, useMemo, useState, useEffect} from 'react';
import {useStoreContext} from '@store/store-context';
import {GoalsController} from '@api/goals-api/goals-controller';
import nextId from 'react-id-generator';
import {useNavigate} from 'react-router';
import {useSearchParams} from 'react-router-dom';
import {beamMasteryTasks} from '@utils/firebase-request';
import {activateNotification} from '@ui/molecules/notification/activate-notification';
import {transformOptions} from '@pages/dashboard/reporting/reporting-pages/reporting-overview/components/kpi-board';
import {GoalsRequestImpl} from '@api/goals-api/goals-request';

export interface IChild {
  saved: boolean;
  id: string;
  objectiveName: string;
  objectiveId: string;
}

export const useCreateAlignmentHook = () => {
  const {
    groupStore: {groups},
    groupTypeStore: {groupType},
  } = useStoreContext();

  const [searchParams] = useSearchParams();
  const editAlignment = searchParams.get('edit');

  const navigate = useNavigate();

  const emptyChildObj = {
    saved: false,
    id: nextId('child-obj'),
    objectiveName: '',
    objectiveId: '',
  };

  const request = useMemo(() => new GoalsRequestImpl(), []);

  const controller = useMemo(() => new GoalsController(request), [request]);

  const [parentObjective, setParentObjective] = useState(editAlignment || '');

  const [parentObjectiveCalculation, setParentObjectiveCalc] = useState('');

  const [childObjectives, setChildObjectives] = useState<IChild[]>([
    emptyChildObj,
  ]);

  const fetchSpecificGoal = useCallback(
    async (id: string) => {
      const response = await controller.fetchGoalById(id);
      if (response) {
        setParentObjectiveCalc(response?.goalMeasurement);
        setChildObjectives(
          response?.alignedGoals?.map((goal: any) => {
            return {
              saved: true,
              id: nextId('child-obj'),
              objectiveName: goal.name,
              objectiveId: goal.id,
            };
          }),
        );
      }
    },
    [controller],
  );

  useEffect(() => {
    if (editAlignment) {
      fetchSpecificGoal(editAlignment);
    }
  }, [editAlignment, fetchSpecificGoal]);

  const [loading, setLoading] = useState(false);

  const addChildObjective = () => {
    setChildObjectives((prev) => [...prev, emptyChildObj]);
  };

  const createAlignment = async (goals: any[]) => {
    setLoading(true);
    const request = new GoalsRequestImpl();
    const controller = new GoalsController(request);

    const parentGoal = goals.find((goal: any) => goal.id === parentObjective);

    const _parentGoal = {
      ...parentGoal,
      goalMeasurement: parentObjectiveCalculation,
      performance: undefined,
      keyResults: parentGoal?.keyResults?.map((keyResult: any) => {
        return {
          ...keyResult,
          id: undefined,
        };
      }),
    };

    const updateGoal = async (id: string) => {
      const childGoal = await controller.fetchGoalById(id);

      const _data = {
        ...childGoal,
        goalAlignment: parentObjective,
        performance: undefined,
        goalMeasurement: undefined,
        keyResults: childGoal?.keyResults?.map((keyResult: any) => {
          return {
            ...keyResult,
            id: undefined,
          };
        }),
      };

      const res = await controller.updateGoal(_data, id, true);

      if (!res) {
        return Promise.reject('Something went wrong');
      }

      beamMasteryTasks('aligned_goal', true);
      return res;
    };

    const response = await controller.updateGoal(
      _parentGoal,
      parentObjective,
      true,
    );

    const promises = childObjectives.map((child) =>
      updateGoal(child.objectiveId),
    );

    if (!response) {
      setLoading(false);
      return;
    }
    Promise.all(promises)
      .then((results) => {
        activateNotification({
          title: 'Success',
          kind: 'success',
          content: 'Your objective has been aligned',
        });
        navigate(-1);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onChangeObjective = (values: IChild) => {
    const objectives = childObjectives.map((objective) =>
      objective.id === values.id ? values : objective,
    );

    setChildObjectives(objectives);
  };

  const deleteObjective = (id: string) => {
    const objectives = childObjectives.filter(
      (objective) => objective.id !== id,
    );
    if (!objectives.length) {
      return setChildObjectives([emptyChildObj]);
    }
    setChildObjectives(objectives);
  };

  const disableChildObj = childObjectives.some((obj) => !obj.saved);

  const disableCreateAlignment =
    !parentObjective || !parentObjectiveCalculation || disableChildObj;

  const initGoals = useCallback(
    async (pageNumber?: number, filter?: string) => {
      const response = await controller.fetchGoals(pageNumber, filter);

      const excludeKpi = response.goals.filter((goal: any) => {
        if (goal.isKpi) return false;
        if (
          goal.keyResults.find(
            (keyResult: any) => keyResult.alignedKeyResults?.length > 0,
          )
        )
          return false;

        if (editAlignment) {
          if (goal.id === editAlignment) {
            return true;
          }
          if (goal.alignedGoals.length) return false;
        }
        if (goal.alignedGoals.length) return false;
        return true;
      });

      const alignGoalArr = excludeKpi.map((goal: any) =>
        transformOptions(goal, groups, groupType),
      );

      return {options: alignGoalArr, goals: excludeKpi};
    },
    [groups, groupType, controller, editAlignment],
  );

  const parentObjectivesOptions = [
    {
      value: 'key results',
      label: 'Average of only parent key results',
    },
    {
      value: 'all',
      label: 'Average of parent key results & child objectives',
    },
    {
      value: 'children',
      label: 'Average of only child objectives',
    },
  ];

  return {
    initGoals,
    parentObjectivesOptions,
    addChildObjective,
    childObjectives,
    deleteObjective,
    onChangeObjective,
    disableCreateAlignment,
    setParentObjectiveCalc,
    parentObjectiveCalculation,
    parentObjective,
    createAlignment,
    loading,
    setParentObjective,
    disableChildObj,
    editAlignment,
  };
};
