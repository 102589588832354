import styled, {CSSProperties} from 'styled-components';
import {useDropDown} from '../../../hooks';
import {MoreVerticalIcon} from '@ui/atoms/icons';
import {memo, ReactNode, useCallback} from 'react';
import {down} from 'styled-breakpoints';
import {getColorFromTheme} from '../../ui-utils';
import {Typography} from '../../atoms/typography/typography';

const Wrapper = styled.div`
  position: relative;
`;

const DropdownIcon = styled.button`
  border: none;
  background: none;
  outline: none;
`;

const DropdownWrapper = styled.div`
  display: block;
  position: absolute;
  min-width: 150px;
  max-width: 220px;
  width: auto;
  top: 100%;
  border-radius: 8px;
  right: 0;
  border: solid 1px ${getColorFromTheme('borderDark')};
  background-color: ${getColorFromTheme('white')};
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: ${({theme}) => theme.elevation.level2};
  z-index: 5;
  padding: 16px 10px;
  margin-top: 16px;
  ${down('md')} {
    width: 60%;

    max-width: 300px;
    min-width: 170px;
  }
`;

const DropdownItem = styled.button`
  ${Typography};
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  cursor: pointer;
  border: none;
  background: none;
  display: block;
  outline: none;
  line-height: 1.7;
  padding: 6px 16px;
  transition: all ease-in-out 200ms;
  color: ${getColorFromTheme('textBody')};

  &:hover {
    background-color: ${getColorFromTheme('backgroundDark')};
    border-radius: 8px;
    font-weight: 500;
    color: ${getColorFromTheme('textDark')};
  }
`;

interface Menu {
  id: string;
  content: ReactNode;
  icon?: any;
}

export interface DropdownProps {
  menu: Menu[];
  onClick: (id: string, event?: any) => void;
  customIcon?: ReactNode;
  dropdownItemStyle?: CSSProperties;
  disableCloseOnClick?: (id: string) => boolean;
  specificIndexDropdownItemStyles?: {
    index: number;
    style: CSSProperties;
  }[];
}

const parseSpecificIndexStyle = (
  index: number,

  styles?: {
    index: number;
    style: CSSProperties;
  }[],
) => {
  if (!!styles) {
    return !!styles.filter((style) => style.index === index)[0]?.style
      ? styles.filter((style) => style.index === index)[0]?.style
      : {};
  } else return {};
};

export const Dropdown = memo(function ({
  menu,
  onClick,
  dropdownItemStyle,
  disableCloseOnClick,
  customIcon,
  specificIndexDropdownItemStyles,
}: DropdownProps) {
  const {handleOpen, ref, open, handleClose} = useDropDown();

  const handleClick = useCallback(
    (id: string, event?: any) => {
      onClick(id, event);

      if (!disableCloseOnClick?.(id)) handleClose();
    },
    [handleClose, onClick, disableCloseOnClick],
  );

  return (
    <Wrapper ref={ref}>
      <DropdownIcon onClick={handleOpen}>
        {customIcon || <MoreVerticalIcon />}
      </DropdownIcon>
      {open && (
        <DropdownWrapper>
          {menu.map((m, i) => (
            <DropdownItem
              className="flex flex-row justify-between items-center"
              style={{
                ...dropdownItemStyle,
                ...parseSpecificIndexStyle(i, specificIndexDropdownItemStyles),
              }}
              key={m.id}
              onClick={(event) => handleClick(m.id, event)}>
              {m.content} {m?.icon && m?.icon}
            </DropdownItem>
          ))}
        </DropdownWrapper>
      )}
    </Wrapper>
  );
});
Dropdown.displayName = 'Dropdown';
