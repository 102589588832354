import {observer} from 'mobx-react';
import {lazy} from 'react';
import {handleCaptureLogin} from '@utils/firebase-request';
import {usePostHog} from 'posthog-js/react';
import {useStoreContext} from '../../store/store-context';
import {AdminSignInController} from './sign-in-controller';
import {SignInFormData} from './sign-in-interface';
import {useSearchParams} from 'react-router-dom';
import {AdminSignInRequestImpl} from './sign-in-request';
import {initializePendo} from '@utils/pendo-initialize';

const AdminSignInPage = lazy(() =>
  import('./sign-in-page').then((module) => ({
    default: module.AdminSignInPage,
  })),
);

const SetupWorkspaceForm = lazy(() =>
  import('./sign-in-form').then((module) => ({
    default: module.AdminSignInForm,
  })),
);

export const installAdminSignInPage = () => {
  const request = new AdminSignInRequestImpl();
  const controller = new AdminSignInController(request);

  const FormComponent = observer(() => {
    const {authStore} = useStoreContext();
    const postHog = usePostHog();
    const [searchParams] = useSearchParams();
    const redirect_url = searchParams.get('redirect_url');
    const publicToken = searchParams.get('token');

    const onSubmit = async (data: SignInFormData) => {
      const response = await controller.adminSignIn(data);
      if (!response) return;
      if (response) {
        if (response.user && !response.user.workspace) {
          window.location.href = `/workspace-setup?access_token=${response?.tokens?.access?.token}`;
          return;
        } else {
          postHog?.identify(response.user.id, {
            email: response.user.email,
            workspace: response.user.workspace,
          });

          initializePendo({
            userId: response.user.id,
            workspaceName: response.user.workspace.name,
            workspaceId: response.user.workspace.id,
            firstName: response.user.firstName,
            lastName: response.user.lastName,
            email: response.user.email,
            billingStatus: response.user.workspace.billing.status,
            role: response.user.role,
          });

          postHog?.capture(`frontend - user signin`, {
            workspaceName: response.user.workspace.name,
            workspaceId: response.user.workspace.id,
            workspaceCreatedAt: response.user.workspace.createdAt,
            workspaceBillingStatus: response.user.workspace.billing.status,
            workspaceBillingPlan: response.user.workspace.billing.plan,
          });

          postHog?.capture('workspace', response.user.workspace);

          const userReturnAfter14Days = () => {
            postHog?.capture(`14_days_return - User returns after 14 days`);
          };

          handleCaptureLogin(
            {
              userId: response.user.id,
              workspaceId: response.user.workspace.id,
              userCreatedAt: response.user.createdAt,
            },
            userReturnAfter14Days,
          );

          if (redirect_url && publicToken) {
            return window.location.assign(
              `${redirect_url}?code=${response.tokens.refresh.token}`,
            );
          }
          authStore.setAuthResponse(response);
        }
      }
    };

    return <SetupWorkspaceForm onSubmit={onSubmit} />;
  });

  const Page = observer(() => (
    <AdminSignInPage FormComponent={FormComponent} />
  ));

  return {
    Page,
  };
};
