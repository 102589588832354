import {ComponentPropsWithoutRef, forwardRef, ReactChild, Ref} from 'react';
import styled, {css} from 'styled-components';
import {switchProp} from 'styled-tools';
import {getColorFromTheme} from '../../../ui-utils';
import {Radio} from '../../../atoms/radio';
import {InputState} from '../../../interface';

const LabelWrapper = styled.label<{state?: InputState}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;

  p {
    padding: 0;
    margin: 0;
    color: ${getColorFromTheme('textBody')};
    text-shadow: none;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
  }

  ${switchProp('state', {
    error: css`
      p {
        color: ${getColorFromTheme('red400')};
      }
    `,
  })}
`;

export interface RadioFieldProps extends ComponentPropsWithoutRef<'input'> {
  id: string;
  label?: ReactChild;
  state?: InputState;
}

export const RadioField = forwardRef(
  (
    {id, label, state, checked, ...rest}: Omit<RadioFieldProps, 'type'>,
    ref: Ref<HTMLInputElement>,
  ) => (
    <LabelWrapper htmlFor={id} state={state}>
      <Radio {...rest} id={id} ref={ref} state={state} checked={checked} />
      {label && <p>{label}</p>}
    </LabelWrapper>
  ),
);
