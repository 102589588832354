import {AnalyticsSection} from '@ui/molecules/home-analytics-section';
import {AnalyticsSectionNavProps} from '@ui/molecules/home-analytics-section/analytics-section';
import {FlexRowCenter} from '@ui/style/styles';
import {getColorFromTheme} from '@ui/ui-utils';
import {FC, memo} from 'react';
import {down} from 'styled-breakpoints';
import styled from 'styled-components';
import {ItemLoader} from '../item-loader';
import {PulseSummary} from '@pages/dashboard/reporting/reporting-pages/admin-analytics/analytics-pages/analytics-checkins/pulse-summary';
import {capitalize} from '@utils';
import {checkinName} from '@utils/checkin-name';

export const MoodDiv = styled.div<{
  activated?: boolean;
  disabled?: boolean;
  error?: boolean;
}>`
 
  div.titleDiv {
    padding: 18px 24px 16px;
  }

  div.contentDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 24px 2px;
   
    ${down('sm')} {
      padding: 24px 3px;
    }

    .inner-content {
      width: 65%;

      ${down('lg')} {
        width: 75%;
      }
      ${down('md')} {
        width: 100%;
      }

      display: flex;
      justify-content: space-between;
      align-items: center;

      .emoji {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
       
        .halo {
          padding: 4px;
           border-radius: 50%;
      
         
          &:hover {
            background: ${({disabled}) =>
              disabled ? '' : getColorFromTheme('purple300')};
        
          };
         
          .img {
            width: 56px;
          height: 56px;
          ${down('sm')} {
         width: 46px;
          height: 46px;
          }

            img {
          width: 56px;
          height: 56px;
           ${down('sm')} {
         width: 46px;
          height: 46px;
      }
        }


          }
        }
      }
    }
    .second-inner-content {
      width: 58.8%;
      ${down('md')} {
        width: 100%;
      }
      }
    }
  }

   
  div.footerDiv {
    display: flex;
    align-items: center;
    padding: 12px 40px;
    border-radius: 10px 10px 0px 0px;
    border-top: 1px solid ${getColorFromTheme('borderLight')};
  }
`;

interface MeetingAnalyticsProps extends AnalyticsSectionNavProps {
  isPulseSubmitted: boolean;
  dueDay: number;
  showSummary?: boolean;
  isSubmitting?: boolean;
  onSubmit?: (data: {mood: string; feedback?: string}) => void;
  summary?: any;
}

export const PulseCard: FC<MeetingAnalyticsProps> = memo(
  ({
    isPulseSubmitted,
    dueDay,
    onSubmit,
    isLoading,
    showSummary,
    isSubmitting,
    summary,
    ...rest
  }) => {
    return (
      <AnalyticsSection title={`${capitalize(checkinName())}s`} {...rest}>
        {!isLoading && (
          <div className="">
            <PulseSummary
              view="home"
              showAveragePulse={rest.activeTab !== 'you'}
              total={summary?.totalUpdateScheduled}
              updatesSubmission={{
                count: summary?.submittedUpdates,
                percent: summary?.submittedUpdatesPercent,
              }}
              avgPulse={summary?.averagePulse}
            />
          </div>
        )}

        {isLoading && (
          <FlexRowCenter>
            <ItemLoader />
          </FlexRowCenter>
        )}
      </AnalyticsSection>
    );
  },
);
