import styled from 'styled-components';
import {Button} from '../../../../../../ui/atoms/button';

export const Wrapper = styled.div`
  margin-bottom: 30px;
`;

export const ActiveWrapper = styled.div`
  position: relative;
  top: 0.6%;
  border-width: 1px 1px 0 1px;
  border-style: solid;
  border-color: #ededf2;
  border-radius: 10px 10px 0 0px;
`;

export const BorderWrapper = styled.div`
  display: flex;
  width: 600px;
  border-radius: 10px 10px 0 2px;
  @media (max-width: 700px) {
    width: 98%;
  }
`;
export const BorderWrapper1 = styled.div`
  width: 100%;

  @media (max-width: 700px) {
    width: 98%;
  }
`;

export const BorderWrapper2 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0px;
`;

export const MemberButton = styled(Button)`
  padding: 8px 16px;
`;
