import {memo, useMemo} from 'react';
import styled from 'styled-components';
import {Body1} from '../../atoms/typography/body1';
import {Avatar} from '../../atoms/avatar';
import {IDropdownUser} from '../../../hooks/interface';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

const Text = styled(Body1)`
  margin-left: 5px;
`;

const Container = styled.div`
  display: inline-flex;
  flex-direction: row-reverse;
`;

const Item = styled.div`
  display: inline-block;

  &:not(:last-of-type) {
    margin-left: -8px;
  }
`;

export interface AvatarsProps {
  items: IDropdownUser[];
  total: number;
}

const AvatarItem = memo(function ({label}: IDropdownUser) {
  return (
    <Item>
      <Avatar
        size="md"
        src={label.avatar.src}
        alt={label.avatar.name}
        name={label.avatar.name}
        userId={label.id}
        tooltip={true}
      />
    </Item>
  );
});

export const AvatarsSecondary = memo(function ({total, items}: AvatarsProps) {
  const renderMore = useMemo(() => {
    if (total > 3) {
      return <Text kind="textBody">+{total - 3} other(s)</Text>;
    }

    return null;
  }, [total]);

  return (
    <Wrapper>
      <Container>
        {items.slice(0, 3).map((avatar, idx) => (
          <AvatarItem {...avatar} key={`${idx}-${avatar.label.avatar.src}`} />
        ))}
      </Container>
      {renderMore}
    </Wrapper>
  );
});
AvatarsSecondary.displayName = 'AvatarsSecondary';
