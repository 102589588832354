import {useCallback, useEffect, useState} from 'react';
import axios from 'axios';
import {activateNotification} from '../../ui/molecules/notification/activate-notification';
import {authStore} from '../../store/stores/auth-store';
import {cfv} from '../../utils/framework';
import {capitalize} from '../../utils';
import {useAdminWorkspaceHook} from '../dashboard/admin/admin-components/admin-workspace/admin-workspace-hooks';

import {useNavigate} from 'react-router';
import {useStoreContext} from '../../store/store-context';
export function ImportHooks() {
  const [errors, setErrors] = useState<any>([]);
  const [uploaded, setUploaded] = useState(false);
  const [uploads, setuploads] = useState(false);
  const navigate = useNavigate();
  const {storeDashboardRoutes} = useStoreContext();
  const [validGoal, setValidGoal] = useState(0);
  const [history, setHistory] = useState<any>([]);
  const {activateImportGoalTab, activateWorkspaceTab} = storeDashboardRoutes;
  const {handleGoalsFrameworkTabSwitch} = useAdminWorkspaceHook();
  const [isloading, setIsloading] = useState(false);

  const uploadFile = async (
    body: any,
    setPercentage: any,
    setLoading: any,
    file: any,
  ) => {
    try {
      const options = {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      };
      const {data} = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/goals/import-goals`,
        {
          fileName: file.name,
          file: body,
        },
        options,
      );
      if (data) {
        setPercentage(100);
        setLoading(false);
      }
      if (data.errorLogs.length === 0) {
        setuploads(true);
      }
      setErrors(data.errorLogs);
      setValidGoal(data.validGoals);
      return data;
    } catch (e: any) {
      setLoading(false);
      setPercentage(0);

      activateNotification({
        title: 'Error',
        content: e.response.data?.message,
        kind: 'error',
      });
    }
  };
  const getImportHistory = useCallback(async () => {
    try {
      const options = {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      };
      if (authStore.auth?.user.role === 'admin') {
        const {data} = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/goals/import-goals?include=createdBy`,
          options,
        );
        setHistory(data);
        return data;
      } else {
        const {data} = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/goals/import-goals`,
          options,
        );
        setHistory(data);
        return data;
      }
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e.response.data?.message,
        kind: 'error',
      });
    }
  }, []);
  useEffect(() => {
    getImportHistory();
  }, [getImportHistory]);
  const handleSubmit = async (body: any, file: any) => {
    setIsloading(true);
    try {
      const options = {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      };
      const {data} = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/goals/import-goals`,
        {
          file: body,
          upload: true,
          fileName: file.name,
        },
        options,
      );

      setUploaded(true);

      setIsloading(false);
      return data;
    } catch (e: any) {
      setIsloading(false);
      activateNotification({
        title: 'Error',
        content: e.response.data?.message,
        kind: 'error',
      });
    }
  };
  const revertImportGoals = async (ids: any) => {
    setIsloading(true);
    try {
      const options = {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      };
      const {data} = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/goals/import-goals/revert`,
        {
          importIds: ids,
        },
        options,
      );
      setIsloading(false);

      activateNotification({
        title: 'Success',
        content: `${capitalize(cfv().g_oals)} reverted`,
        kind: 'success',
      });
      if (window.location.pathname === '/import-goal') {
        setTimeout(() => {
          navigate('../you', {replace: true});
          navigate('../import-goal', {replace: true});
          activateImportGoalTab('history');
        }, 1000);
      } else {
        setTimeout(() => {
          navigate('../you', {replace: true});
          navigate('../workspace', {replace: true});
          activateWorkspaceTab('goalFramework');
          handleGoalsFrameworkTabSwitch('import');
          activateImportGoalTab('history');
        }, 1000);
      }

      return data;
    } catch (e: any) {
      setIsloading(false);

      activateNotification({
        title: 'Error',
        content: e.response.data?.message,
        kind: 'error',
      });
    }
  };
  return {
    uploadFile,
    errors,
    uploads,
    setuploads,
    revertImportGoals,
    history,
    validGoal,
    uploaded,
    setErrors,
    isloading,
    handleSubmit,
  };
}
