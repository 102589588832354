import {capitalizeWords} from '../utils';
import {useReducer, ChangeEvent, useCallback} from 'react';
import nextId from 'react-id-generator';

function defaultDepartments(departments?: string[]): Map<string, Department> {
  return new Map(
    (departments || []).map((department) => [
      nextId('department-id'),
      {
        checked: true,
        name: department,
      },
    ]),
  );
}

function updateDepartmentChecked(
  departments: Map<string, Department>,
  key: string,
): Map<string, Department> {
  const department = departments.get(key);

  if (department) {
    department.checked = !department.checked;
    departments.set(key, department);
  }

  return new Map(departments);
}

function saveDepartment(
  departments: Map<string, Department>,
  department: string,
): Map<string, Department> {
  if (department && department.length > 1) {
    departments.set(nextId('department-id'), {
      name: capitalizeWords(department),
      checked: true,
    });
  }
  return new Map(departments);
}

interface Department {
  name: string;
  checked: boolean;
}

interface ReducerState {
  departments: Map<string, Department>;
  isAdding: boolean;
  department: string;
}

export enum ActionType {
  IS_ADDING_DEPARTMENT,
  TOGGLE_DEPARTMENT,
  SAVE_DEPARTMENT,
  ADD_DEPARTMENT,
}

type AddNewDepartmentAction = {type: ActionType.IS_ADDING_DEPARTMENT};

type SaveDepartmentAction = {type: ActionType.SAVE_DEPARTMENT};
type AddDepartmentAction = {
  type: ActionType.ADD_DEPARTMENT;
  payload: {value: string};
};

type ToggleDepartmentAction = {
  type: ActionType.TOGGLE_DEPARTMENT;
  payload: {key: string};
};

type ReducerAction =
  | AddNewDepartmentAction
  | ToggleDepartmentAction
  | SaveDepartmentAction
  | AddDepartmentAction;

const initialState: ReducerState = {
  departments: new Map(),
  isAdding: false,
  department: '',
};

function departmentReducer(
  state: ReducerState,
  action: ReducerAction,
): ReducerState {
  switch (action.type) {
    case ActionType.IS_ADDING_DEPARTMENT:
      return {
        ...state,
        isAdding: true,
      };
    case ActionType.SAVE_DEPARTMENT:
      return {
        ...state,
        departments: saveDepartment(state.departments, state.department),
        isAdding: false,
        department: '',
      };
    case ActionType.ADD_DEPARTMENT:
      return {
        ...state,
        department: action.payload.value,
      };
    case ActionType.TOGGLE_DEPARTMENT:
      return {
        ...state,
        departments: updateDepartmentChecked(
          state.departments,
          action.payload.key,
        ),
      };
    default:
      return state;
  }
}

export function useDepartmentChecklist(
  departments?: string[],
  setValue?: (values: string[]) => void,
) {
  const [state, dispatch] = useReducer(departmentReducer, {
    ...initialState,
    departments: defaultDepartments(departments),
  });

  const updateValue = useCallback(() => {
    if (setValue) {
      const departments = Array.from(state.departments)
        .filter(([key, department]) => department.checked)
        .map(([key, department]) => department.name);

      setValue(departments);
    }
  }, [setValue, state.departments]);

  const setAddDepartment = useCallback(() => {
    dispatch({type: ActionType.IS_ADDING_DEPARTMENT});
  }, []);

  const toggleDepartment = useCallback(
    async (key: string) => {
      await dispatch({type: ActionType.TOGGLE_DEPARTMENT, payload: {key}});
      updateValue();
    },
    [updateValue],
  );

  const saveDepartment = useCallback(async () => {
    await dispatch({type: ActionType.SAVE_DEPARTMENT});
    updateValue();
  }, [updateValue]);

  const onChangeDepartment = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    dispatch({type: ActionType.ADD_DEPARTMENT, payload: {value}});
  }, []);

  return {
    isAdding: state.isAdding,
    departments: state.departments,
    setAddDepartment,
    toggleDepartment,
    saveDepartment,
    department: state.department,
    onChangeDepartment,
  };
}
