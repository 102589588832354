import {memo} from 'react';

export const MinusIcon = memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16">
    <path strokeLinecap="round" strokeLinejoin="round" d="M3.332 8h9.333" />
  </svg>
));

MinusIcon.displayName = 'MinusIcon';
