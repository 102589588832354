import {memo} from 'react';

export const CopyIcon = memo(() => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.25 3.75C0.25 1.67893 1.92893 0 4 0H6.76305C7.85777 0 8.89783 0.47836 9.61026 1.30953L10.845 2.75H8.86934L8.47138 2.28572C8.04392 1.78702 7.41988 1.5 6.76305 1.5H4C2.75736 1.5 1.75 2.50736 1.75 3.75V9.75C1.75 10.9926 2.75736 12 4 12H5V13.5H4C1.92893 13.5 0.25 11.8211 0.25 9.75V3.75Z"
      fill="#5E5E73"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 4C7.92893 4 6.25 5.67893 6.25 7.75V13.75C6.25 15.8211 7.92893 17.5 10 17.5H14C16.0711 17.5 17.75 15.8211 17.75 13.75V9.19311C17.75 8.29794 17.4298 7.4323 16.8472 6.75264L15.6103 5.30953C14.8978 4.47836 13.8578 4 12.7631 4H10ZM7.75 7.75C7.75 6.50736 8.75736 5.5 10 5.5H12.7631C13.4199 5.5 14.0439 5.78702 14.4714 6.28572L15.7083 7.72883C16.0579 8.13662 16.25 8.656 16.25 9.19311V13.75C16.25 14.9926 15.2426 16 14 16H10C8.75736 16 7.75 14.9926 7.75 13.75V7.75Z"
      fill="#5E5E73"
    />
  </svg>
));

CopyIcon.displayName = 'CopyIcon';
