import {TimeWrapper} from '@pages/1:1s/create-meetings/styles';
import {TextField} from '@ui/molecules/field/textfield';
import {SecondaryNavigation} from '@ui/molecules/nav/navigation';
import {formatTime} from '@utils/date';
import {Body1, Body2} from '../typography';

interface SelectedTimeProps {
  time: string;
  period: string;
  error: string;
}

type TimeType = 'starts' | 'ends';

interface TimeSelectProps {
  selectedTime: SelectedTimeProps;
  addEndTime?: boolean;
  updateSelectedTime: (
    value: string,
    field: keyof SelectedTimeProps,
    type: TimeType,
  ) => void;
  type: TimeType;
}

export const TimeSelect = ({
  selectedTime,
  addEndTime,
  updateSelectedTime,
  type,
}: TimeSelectProps) => {
  const selectedPeriodIsPM = selectedTime.period?.toLowerCase() === 'pm';

  const selectedPeriodIsAM = selectedTime.period?.toLowerCase() === 'am';
  return (
    <TimeWrapper error={!!selectedTime.error} className="bg-white">
      <div className="start">
        <Body1
          kind={selectedTime.error ? 'red400' : 'textDark'}
          align="center"
          className="capitalize">
          {type}
        </Body1>
      </div>
      <div className="body">
        <div>
          <TextField
            className="input"
            value={selectedTime.time}
            onBlur={(event) => {
              const inputValue = event.target.value;

              if (inputValue) {
                let error = '';
                let period = selectedTime.period;

                const formattedTime = formatTime(
                  inputValue,
                  (_error) => {
                    error = _error;
                  },
                  (_period) => {
                    if (_period) {
                      period = _period;
                    }
                  },
                );

                if (error) {
                  updateSelectedTime(error, 'error', type);
                  return;
                } else {
                  updateSelectedTime(formattedTime, 'time', type);
                  updateSelectedTime(period, 'period', type);

                  // Add end time
                  if (type === 'starts' && addEndTime) {
                    const [hours] = formattedTime.split(':');

                    const is24HourTime = Number(hours) + 1 > 12;

                    const addedHourToTime = formattedTime.replace(
                      hours,
                      String(
                        is24HourTime
                          ? Number(hours) + 1 - 12
                          : Number(hours) + 1,
                      ),
                    );

                    const startPeriod = period;

                    if (Number(hours) < 11) {
                      updateSelectedTime(startPeriod, 'period', 'ends');
                    } else {
                      updateSelectedTime(
                        period === 'am' ? 'pm' : 'am',
                        'period',
                        'ends',
                      );
                    }

                    updateSelectedTime(addedHourToTime, 'time', 'ends');
                  }
                }
              }
            }}
            margin
            placeholder="00:00"
            onChange={(event) => {
              const inputValue = event.target.value;

              updateSelectedTime(inputValue, 'time', type);
            }}
          />
        </div>

        <div className="relative">
          <SecondaryNavigation
            navigations={[
              {
                title: (
                  <Body2
                    weight={selectedPeriodIsAM ? 'semibold' : 'regular'}
                    kind={selectedPeriodIsAM ? 'textDark' : 'textBody'}
                    className="text-[12px]">
                    AM
                  </Body2>
                ),
                active: selectedPeriodIsAM,
                id: '1',
                onClick: () => {
                  updateSelectedTime('AM', 'period', type);
                },
              },
              {
                title: (
                  <Body2
                    weight={selectedPeriodIsPM ? 'semibold' : 'regular'}
                    kind={selectedPeriodIsPM ? 'textDark' : 'textBody'}
                    className="text-[12px]">
                    PM
                  </Body2>
                ),
                active: selectedPeriodIsPM,
                id: '1',
                onClick: () => {
                  updateSelectedTime('PM', 'period', type);
                },
              },
            ]}
            bodyStyle={{
              padding: '9px 8px',
            }}
            activeStyle={{
              height: '20px',
              width: '30px',
              top: '8px',
              left: selectedPeriodIsAM ? '5px' : '3px',
              marginTop: '0px',
            }}
            navStyle={{
              border: 0,
              height: '30px',
              maxWidth: '72px',
              padding: '2px 6px 0px',
            }}
          />
        </div>
      </div>
    </TimeWrapper>
  );
};
