import {observer} from 'mobx-react';
import React from 'react';
import {AdminSettingsForm} from './admin-settings-form';

export const AdminSettingsPageElement = observer(() => {
  return (
    <>
      <AdminSettingsForm />
    </>
  );
});

export const adminSettingsPage = () => {
  const Page = observer(() => <AdminSettingsPageElement />);
  return {
    Page,
  };
};
