import {FC} from 'react';
import styled from 'styled-components';
import styledMap from 'styled-map';
import {ArrowIcon, IArrow} from '../../../atoms/icons/arrow';

type MetricColor = 'orange' | 'red' | 'green';

const MetricDiv = styled.div<{color?: MetricColor}>`
  display: flex;
  flex-direction: column;
  & span {
    display: flex;
    margin-top: 8px;
    align-items: center;
    & p {
      color: ${styledMap('color', {
        orange: '#703F0F',
        green: '#024B27',
        red: '#661515',
        default: '#024B27',
      })};
      font-size: 14px;
    }
  }
`;

export interface IPerformanceMetric {
  color: MetricColor;
  firstArrowProps: IArrow;
  secondArrowProps: IArrow;
}

export const PerformanceMetric: FC<IPerformanceMetric> = ({
  color,
  firstArrowProps,
  secondArrowProps,
}) => {
  return (
    <MetricDiv color={color}>
      <span color={color}>
        <ArrowIcon
          direction={firstArrowProps.direction}
          color={firstArrowProps.color}
        />
        <p>Values</p>
      </span>
      <span color={color}>
        <ArrowIcon
          direction={secondArrowProps.direction}
          color={secondArrowProps.color}
        />
        <p>Competence</p>
      </span>
    </MetricDiv>
  );
};
PerformanceMetric.displayName = 'PerformanceMetric';
