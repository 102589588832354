import {Modal} from '@ui/molecules/modal';
import {ModalCard} from '@ui/layouts/modal-card';
import {useInsightHook} from './hooks/insight-hook';
import {BackAction} from '@ui/molecules/back-action';
import {CancelIcon} from '@ui/atoms/icons';
import {MemberActivity} from './activity/activity';
import {GoalSection} from './goal/goal-card';
import {ItemLoader} from '@ui/organisms/item-loader';
import {observer} from 'mobx-react-lite';
import {useStoreContext} from '@store/store-context';
import {KeyResultSection} from './keyresults/key-results-cards';
import {VerticalSpacer} from '@ui/atoms/spacer';
import styled from 'styled-components';
import {useLocation, useNavigate} from 'react-router';

export const CancelWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 800px) {
    margin-top: 8%;
    margin-right: 9%;
  }
`;
export const ItemLoaderWrapper = styled.div`
  width: 100%;
  height: 75vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalCardWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  div.child {
    width: 47%;
    @media (min-width: 800px) and (max-width: 1240px) {
      width: 55%;
    }
    @media (max-width: 800px) {
      min-width: 90%;
      margin: 10% 0px;
    }
  }
  @media (max-width: 800px) {
    justify-content: center;
  }
`;
export const DesktopWrapper = styled.div`
  display: none;
  @media (min-width: 800px) {
    display: block;
  }
`;
export const MobileWrapper = styled.div`
  display: none;
  @media (max-width: 800px) {
    display: block;
  }
`;
export const Insights = observer(() => {
  const {
    goalsState: {setInsightGoal, showInsightGoal},
  } = useStoreContext();

  const {loading, goal, updateTimeRange, timeRange} = useInsightHook(
    typeof showInsightGoal.goal === 'string' && showInsightGoal.goal.length > 0
      ? showInsightGoal.goal
      : undefined,
  );

  const navigate = useNavigate();
  const location = useLocation();

  function removeQueryParam(paramKey: string) {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete(paramKey);

    const newSearch = searchParams.toString();
    const newPath = `${location.pathname}${newSearch ? '?' + newSearch : ''}`;

    navigate(newPath);
  }

  return (
    <>
      {showInsightGoal.insight ? (
        <Modal open={true} fullHeight onClose={() => null}>
          <MobileWrapper>
            <CancelWrapper>
              <BackAction
                icon={<CancelIcon />}
                title="Close"
                onClick={() => {
                  setInsightGoal({
                    goal: '',
                    insight: false,
                  });
                  removeQueryParam('insights');
                  removeQueryParam('goal');
                }}
                width="xs"
              />
            </CancelWrapper>
          </MobileWrapper>
          <ModalCardWrapper>
            <div className="child">
              <ModalCard title="" fullHeight onBoarding>
                <div>
                  <DesktopWrapper>
                    <CancelWrapper>
                      <BackAction
                        icon={<CancelIcon />}
                        title="Close"
                        style={{
                          height: '42px',
                          display: 'flex',
                          alignItems: 'center',
                          overflow: 'hidden',
                          justifyContent: 'center',
                        }}
                        onClick={() => {
                          setInsightGoal({
                            goal: '',
                            insight: false,
                          });
                          window.history.replaceState(
                            null,
                            '',
                            window.location.pathname,
                          );
                        }}
                        width="xs"
                      />
                    </CancelWrapper>
                  </DesktopWrapper>
                  <VerticalSpacer size="8px" />
                  {goal && (
                    <>
                      <GoalSection goal={goal} />

                      <VerticalSpacer size="24px" />

                      {goal.keyResults.length > 0 && (
                        <KeyResultSection keyResult={goal.keyResults || []} />
                      )}
                      <VerticalSpacer size="34px" />
                      <MemberActivity
                        goal={goal as any}
                        updateTimeRange={updateTimeRange}
                        timeRange={timeRange}
                      />
                    </>
                  )}

                  {loading && !goal && (
                    <ItemLoaderWrapper>
                      <ItemLoader />
                    </ItemLoaderWrapper>
                  )}
                </div>
              </ModalCard>
            </div>
          </ModalCardWrapper>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
});
