import {useState} from 'react';
import {useStoreContext} from '@store/store-context';
import {activateNotification} from '@ui/molecules/notification/activate-notification';
import {IntegrationsController} from '../../components/app.controller';

export const useSlackHook = () => {
  const [disconnectLoading, setDisconnectLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const {
    integrationStore: {activeIntegrations},
  } = useStoreContext();
  const handleDisconnectSlackConnection = async (
    app: string,
    setAction: any,
  ) => {
    setDisconnectLoading(true);
    const controller = new IntegrationsController();
    const response = await controller.deleteIntegrations(app);

    response &&
      activateNotification({
        title: 'Access disconnected',
        content: 'App has been disconnected from Beam',
        kind: 'success',
      });

    response &&
      setAction(
        activeIntegrations.map((_app: any) => {
          if (_app.app === app) {
            return {
              ..._app,
              status: 'disconnected',
            };
          } else return _app;
        }),
      );

    response && setOpenModal(false);
    setDisconnectLoading(false);
  };

  return {
    handleDisconnectSlackConnection,
    disconnectLoading,
    openModal,
    setOpenModal,
  };
};
