import dayjs from 'dayjs';
import {Insight} from './interface';

export const insightTypes = [
  {
    value: '',
    label: 'All',
  },
  {
    value: 'kpi',
    label: 'KPIS',
  },
  {
    value: 'action-items',
    label: 'Action items performance',
  },
];

export const insightDateRange = [
  {
    value: '',
    label: 'All time',
  },
  {
    value: 'today',
    label: 'Today',
  },
  {
    value: 'yesterday',
    label: 'Yesterday',
  },
  {
    value: '7',
    label: 'Last 7 days',
  },
  {
    value: '14',
    label: 'Last 14 days',
  },
  {
    value: '30',
    label: 'Last 30 days',
  },
];

interface FormattedDate {
  startDate: string;
  endDate: string;
}

export const formatConfigDate = (config: Insight['config']): FormattedDate => {
  const isMeasuredByDays = config.range.type === 'day';
  const isCustomDate = config.type === 'custom';

  if (isMeasuredByDays && !isCustomDate) {
    const startDate = dayjs()
      .subtract(Number(config.range.value), 'day')
      .format('YYYY-MM-DD');

    const endDate = dayjs().format('YYYY-MM-DD');

    return {startDate, endDate};
  }

  if (isCustomDate) {
    const [startDate, endDate] = (config.range.value as string)?.split(
      ' - ',
    ) || ['', ''];

    return {
      startDate: dayjs(startDate, 'DD-MM-YY').format('YYYY-MM-DD'),
      endDate: dayjs(endDate, 'DD-MM-YY').format('YYYY-MM-DD'),
    };
  }
  const [startDate, endDate] = (config.range.value as string)?.split(' - ') || [
    '',
    '',
  ];

  return {startDate, endDate};
};

export const comparisonConfigDate = (
  config: Insight['config'],
  startDate: string,
): FormattedDate | null => {
  if (!config.comparison.value) return null;

  const comparisonStartDate = dayjs(startDate)
    .subtract(Number(config.comparison.value), 'day')
    .format('YYYY-MM-DD');

  const comparisonEndDate = dayjs(startDate)
    .subtract(1, 'day')
    .format('YYYY-MM-DD');

  return {startDate: comparisonStartDate, endDate: comparisonEndDate};
};
