import {observer} from 'mobx-react';
import {lazy} from 'react';
import {useLocation, useNavigate} from 'react-router';
import {ResetPasswordController} from './reset-password-controller';
import {ResetPasswordFormData} from './reset-password-interface';
import {NewPasswordRequestImpl} from './reset-password-request';

const NewPasswordPage = lazy(() =>
  import('./reset-password-page').then((module) => ({
    default: module.NewPasswordPage,
  })),
);

const SetupWorkspaceForm = lazy(() =>
  import('./reset-password-form').then((module) => ({
    default: module.NewPasswordForm,
  })),
);

export const installNewPasswordPage = () => {
  const request = new NewPasswordRequestImpl();
  const controller = new ResetPasswordController(request);

  const FormComponent = observer(() => {
    const navigate = useNavigate();
    const {search} = useLocation();
    const token = search.slice(search.indexOf('=') + 1);

    const onSubmit = async (data: ResetPasswordFormData) => {
      const response = await controller.resetPassword(data, token);
      response && navigate('../password-complete', {replace: true});
    };

    return <SetupWorkspaceForm onSubmit={onSubmit} />;
  });

  const Page = observer(() => (
    <NewPasswordPage FormComponent={FormComponent} />
  ));

  return {
    Page,
  };
};
