import {VerticalSpacer} from '../../../../../../../ui/atoms/spacer';
import {FC} from 'react';
import {useFetchHook} from '@hooks/query-hook';
import {CompetenceCategories} from './components/competence-categories';
import {PerformanceRankings} from './components/performance-rankings';
import {CcController} from '@api/cc-api/cc-controller';
import {ItemLoader} from '@ui/organisms/item-loader';
import {Headline3} from '@ui/atoms/typography';
import {ReviewAdministrators} from './components/review-administrators';
import {CcRequestImpl} from '@api/cc-api/cc-request';
import {
  CollapseWrapper,
  ItemWrapperContainer,
} from './performance-settings-style';
import {ValueCategories} from './components/value-categories';
import {ReviewFrameworks} from './components/review-frameworks';
import {QuestionIconWrapper} from '@ui/molecules/tooltip/tooltip';
import {AlertBadge} from '@ui/atoms/icons/alert-badge';

export const TooltipBadge = ({message}: {message: string}) => {
  return (
    <QuestionIconWrapper tooltip={true}>
      <AlertBadge />
      <span
        className="tooltip"
        style={{
          width: '300px',
          padding: '16px',
        }}>
        {message}
      </span>
    </QuestionIconWrapper>
  );
};

interface IPerformanceSettings {}

export const PerformanceSettingsEditForm: FC<IPerformanceSettings> = () => {
  const request = new CcRequestImpl();

  const controller = new CcController(request);

  const {data, isLoading} = useFetchHook('cc-review-setup', () =>
    controller.fetchCcReviewSetup(),
  );

  const requestData: any = data;

  if (isLoading) {
    return (
      <ItemWrapperContainer>
        <ItemLoader />
      </ItemWrapperContainer>
    );
  }
  return (
    <>
      <CollapseWrapper collapse={false}>
        <VerticalSpacer size="24px" />
        <Headline3>Culture & Competence settings </Headline3>

        <VerticalSpacer size="24px" />
        <PerformanceRankings />
        <VerticalSpacer size="16px" />

        <ReviewAdministrators />

        <VerticalSpacer size="32px" />
        <CompetenceCategories
          categories={
            requestData?.competenceCategories?.map(
              (category: {name: string}) => category.name,
            ) || []
          }
          frameworks={requestData?.frameworks || []}
        />
        <VerticalSpacer size="24px" />

        <ValueCategories />
        <VerticalSpacer size="24px" />
        <ReviewFrameworks
          categories={requestData?.competenceCategories || []}
        />
      </CollapseWrapper>
    </>
  );
};
