import {action} from 'mobx';
import {authStore} from '../../store/stores/auth-store';
import {activateNotification} from '../../ui/molecules/notification/activate-notification';
import {capitalize} from '../../utils';
import {convertFormDataToAPIformat} from '../../utils/convertFormData';
import {computeFrameworkVars} from '../../utils/framework';
import {CreateGoalFormData} from './create-goal-interface';
import {CreateGoalRequest} from './create-goal-request';

export class CreateGoalController {
  constructor(private readonly request: CreateGoalRequest) {}

  @action
  async createGoal(data: CreateGoalFormData) {
    const convertedData = convertFormDataToAPIformat(data);

    try {
      const response = await this.request.createGoal({
        ...convertedData,
        currentValue: convertedData.startValue,
      });
      activateNotification({
        title: 'Success',
        content: `${capitalize(
          computeFrameworkVars(authStore?.auth?.user?.workspace?.framework)
            ?.g_oal,
        )} has been successfully published`,
        kind: 'success',
      });
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e?.response?.data?.message,
        kind: 'error',
      });
      return false;
    }
  }

  @action
  async editGoal(data: CreateGoalFormData, goalId: string) {
    const convertedData = convertFormDataToAPIformat(data);

    try {
      await this.request.editGoal(convertedData, goalId);
      activateNotification({
        title: 'Success',
        content: `${capitalize(
          computeFrameworkVars(authStore?.auth?.user?.workspace?.framework)
            ?.g_oal,
        )} updated`,
        kind: 'success',
      });
      return true;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e?.response?.data?.message,
        kind: 'error',
      });
      return false;
    }
  }

  async getAllGoalTemplates(page?: number, type?: string, limit?: number) {
    try {
      const response = await this.request.getAllGoalTemplates(
        page,
        type,
        limit,
      );

      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e?.response?.data?.message,
        kind: 'error',
      });
    }
  }
  async getTemplatesType() {
    try {
      const response = await this.request.getTemplatesType();

      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e?.response?.data?.message,
        kind: 'error',
      });
    }
  }
}
