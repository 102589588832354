import {
  forwardRef,
  ReactElement,
  Ref,
  useCallback,
  useState,
  useMemo,
} from 'react';
import ReactSelect, {ValueType, ActionMeta} from 'react-select';
import {useDropDown} from '../../../../hooks';
import {motion} from 'framer-motion';
import {Label} from '../../../atoms/label';
import {FieldWrapper} from '../../../atoms/field-wrapper';
import {Helper} from '../../../atoms/helper';
import {
  Wrapper,
  DropdownWrapper,
  DropWrapper,
  DropdownHandler,
  HandlerWrapper,
  ArrowIconWrapper,
  AvatarWrapper,
} from './styled';
import {ArrowHeadDownIcon} from '../../../atoms/icons/arrow-head-down';
import {HorizontalSpacer, VerticalSpacer} from '../../../atoms/spacer';
import {Avatar} from '../../../atoms/avatar';
import {Body2, Body1} from '../../../atoms/typography';

import {
  UserSelect,
  SelectFieldProps,
  TOption,
} from '../user-select/user-select';
import {InputState} from '../../../interface';

export interface TUserSelectFieldProps extends SelectFieldProps {
  label?: string;
  placeholder?: string;
  state?: InputState;
  helper?: string | ReactElement;
}

export const UserSelectField = forwardRef(
  (
    {
      label,
      state,
      helper,
      placeholder,
      options = [],
      margin,
      onChange,
      value,
      disabled,
      defaultValue,
      fieldNotFoundPlaceHolder,
      ...rest
    }: SelectFieldProps,
    ref: Ref<ReactSelect<TOption>>,
  ) => {
    const getValue = options.find(
      // @ts-ignore
      (option) => option.value === defaultValue || option.value === value,
    );
    const [innerValue, setInnerValue] = useState<ValueType<TOption, false>>(
      // @ts-ignore
      () => getValue,
    );

    const {handleOpen, ref: dropRef, open, handleClose} = useDropDown();

    useMemo(() => {
      if (defaultValue) {
        if (getValue) {
          setInnerValue(getValue);
        }
      }
    }, [defaultValue, getValue]);

    const onSelectChange = useCallback(
      (value: ValueType<TOption, false>, action: ActionMeta<TOption>) => {
        if (onChange) {
          onChange(value, action);
        }
        setInnerValue(value);
        handleClose();
      },
      [handleClose, onChange],
    );

    return (
      <FieldWrapper margin={margin}>
        <Wrapper>
          <Label aria-label="label">{label}</Label>
          <VerticalSpacer size="8px" />
          <DropWrapper ref={dropRef}>
            <DropdownHandler
              open={open}
              onClick={handleOpen}
              disabled={disabled}>
              <HandlerWrapper>
                <AvatarWrapper>
                  {innerValue ? (
                    <>
                      <Avatar
                        src={(innerValue as TOption).label.avatar.src}
                        name={(innerValue as TOption).label.avatar.name}
                        userId={(innerValue as TOption).label.id}
                        size="md"
                        tooltip={true}
                      />
                      <HorizontalSpacer size="8px" />
                      <Body2>{(innerValue as TOption).label.name}</Body2>
                    </>
                  ) : (
                    <Body1 kind="textBody">{placeholder}</Body1>
                  )}
                </AvatarWrapper>
                <ArrowIconWrapper>
                  <motion.span
                    initial={{rotate: 0}}
                    animate={{
                      rotate: open ? 180 : 0,
                      marginTop: open ? '-6px' : '0px',
                    }}
                    transition={{
                      duration: 0.3,
                    }}
                    key="user-select-toggled">
                    <ArrowHeadDownIcon />
                  </motion.span>
                </ArrowIconWrapper>
              </HandlerWrapper>
            </DropdownHandler>
            {open && (
              <DropdownWrapper style={{zIndex: 99999}}>
                <UserSelect
                  {...rest}
                  onChange={onSelectChange}
                  value={innerValue}
                  defaultValue={defaultValue}
                  ref={ref}
                  options={options}
                  fieldNotFoundPlaceHolder={fieldNotFoundPlaceHolder}
                />
              </DropdownWrapper>
            )}
          </DropWrapper>
          <Helper aria-label="helper" state={state} children={helper} />
        </Wrapper>
      </FieldWrapper>
    );
  },
);
