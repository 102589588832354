import React from 'react';

export const ScrollIcon = () => {
  return (
    <svg
      width="7"
      height="10"
      viewBox="0 0 7 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.52025 0.646446C3.42648 0.552678 3.29931 0.5 3.1667 0.5C3.03409 0.5 2.90691 0.552679 2.81314 0.646447L0.146484 3.31311C-0.0487776 3.50838 -0.0487772 3.82496 0.146485 4.02022C0.341748 4.21548 0.65833 4.21548 0.853592 4.02022L3.1667 1.70711L5.47982 4.02022C5.67508 4.21548 5.99166 4.21548 6.18692 4.02022C6.38219 3.82496 6.38219 3.50838 6.18692 3.31311L3.52025 0.646446Z"
        fill="#5F5F8C"
      />
      <path
        d="M0.853553 5.97988C0.658291 5.78462 0.341708 5.78462 0.146446 5.97988C-0.0488156 6.17514 -0.0488154 6.49173 0.146447 6.68699L2.81312 9.35365C2.90688 9.44742 3.03406 9.5001 3.16667 9.5001C3.29928 9.5001 3.42646 9.44742 3.52022 9.35365L6.18689 6.68699C6.38215 6.49173 6.38215 6.17514 6.18689 5.97988C5.99162 5.78462 5.67504 5.78462 5.47978 5.97988L3.16667 8.29299L0.853553 5.97988Z"
        fill="#5F5F8C"
      />
    </svg>
  );
};
