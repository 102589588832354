import {CcController} from '@api/cc-api/cc-controller';
import {CcRequestImpl} from '@api/cc-api/cc-request';
import {useStoreContext} from '@store/store-context';
import {useQuery} from 'react-query';

export const useCycleOverview = (props?: {
  userId?: string;
  managerId?: string;
}) => {
  const emptySummary = {
    completed: 0,
    id: '',
    inProgress: 0,
    managers: {totalCount: 0, pending: 0, completed: 0},
    peers: {
      totalCount: 0,
      pending: 0,
      inProgress: 0,
      completed: 0,
      declined: 0,
    },
    self: {
      totalCount: 0,
      pending: 0,
      completed: 0,
    },
    pending: 0,
    percentages: 0,
    period: '',
    reviewType: '',
    totalCount: 0,
    users: [],
  };
  const request = new CcRequestImpl();
  const controller = new CcController(request);

  const {
    ccStore: {currentReviewCycle, updateCurrentReviewPercentage},
  } = useStoreContext();

  const fetchOverview = async () => {
    const response = await controller.fetchReviewOverview(
      currentReviewCycle.id,
      props?.userId,
      props?.managerId,
    );

    if (!response) {
      return emptySummary;
    }
    const _response = {
      ...response,
      users: response.users.map((user) => {
        return {
          ...user,
          peers: Array.isArray(user.peers) ? user.peers : [user.peers],
        };
      }),
    };
    updateCurrentReviewPercentage((_response as any)?.percentageProgress);
    return _response;
  };

  const {data = emptySummary} = useQuery(
    [
      'review-overview',
      currentReviewCycle.id,
      'manager',
      props?.userId,
      props?.managerId,
    ],
    () => fetchOverview(),
    {
      enabled: !!currentReviewCycle.id,
    },
  );
  return {
    data,
  };
};
