import {Body1, Body2, Headline2} from '@ui/atoms/typography';
import {
  SecondRowWrapper,
  HeadlineWrapper,
  HeadlineWrapperContainer,
  FrameworkItem,
  ItemLoaderWrapper,
  EmptyWrapper,
  TableContainer,
} from '../performance-settings-style';
import {NoBorderButton} from '@pages/dashboard/reporting/reporting-pages/reporting-overview/styles';
import {FlexRow, FlexRowSpaceBetween, Flex} from '@ui/style/styles';
import {SecondaryPlusIcon} from '@ui/atoms/icons/plus';
import {capitalize} from '@utils';
import {CcController} from '@api/cc-api/cc-controller';
import {DropdownVerticalSecondary} from '@ui/molecules/dropdown-vertical';
import {MoreVerticalIcon} from '@ui/atoms/icons';
import {AdditionalOptionsWrapper} from '../performance-settings-style';
import {Modal} from '@ui/molecules/modal';
import {ModalWrapper} from '../../../../admin-people/admin-group/admin-group-style';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {CcRequestImpl} from '@api/cc-api/cc-request';
import {Button} from '@ui/atoms/button';
import {CustomColorIcon} from '@ui/atoms/color-icons';
import {BackAction} from '@ui/molecules/back-action';
import {
  SavedWrapper,
  ConfigureWrapper,
  ManagerAssessmentWrapper,
} from '../create-review-framework/styles';
import {ItemLoader} from '@ui/organisms/item-loader';
import {ModalCard} from '@ui/layouts/modal-card';
import {CancelIcon} from '@ui/atoms/icons';
import {useQuery} from 'react-query';
import {useStoreContext} from '@store/store-context';
import {activateNotification} from '@ui/molecules/notification/activate-notification';
import {useNavigate} from 'react-router';
import {observer} from 'mobx-react-lite';
import {usePaginationHook} from '@hooks/pagination-hook';
import {Pagination} from '@ui/molecules/pagination/pagination';
import {HorizontalSpacer, VerticalSpacer} from '@ui/atoms/spacer';
import {useCallback, useMemo, useState} from 'react';

export const ReviewFrameworks = observer(
  ({categories}: {categories: Array<string>}) => {
    const navigate = useNavigate();

    const request = useMemo(() => new CcRequestImpl(), []);

    const [openModal, setOpenModal] = useState(false);

    const [viewDetails, setViewDetails] = useState<any>();

    const [showDeleteModal, setShowDeleteModal] = useState('');
    const [showDeleteModalId, setShowDeleteModalId] = useState('');

    const controller = useMemo(() => new CcController(request), [request]);
    const {
      groupStore: {groups},
      authStore: {auth},
    } = useStoreContext();

    const {
      pages,
      handlePrevBtn,
      nextPageBtn,
      paginationPages,
      updateLimit,

      updateTotalPages,
      goToPage,
      updateTotalResults,
    } = usePaginationHook();

    const findGroup = (id: string, allGroups?: boolean) => {
      if (allGroups) {
        return 'All groups (General)';
      }
      return groups.find((group) => group.id === id)?.name || '';
    };

    const fetchReviewFrameworks = useCallback(async () => {
      const response: any = controller.fetchCcFramework(pages.page);

      updateTotalPages(response?.totalPages || 0);

      updateTotalResults(response?.totalResults);

      return response;
    }, [pages.page, updateTotalResults, updateTotalPages, controller]);

    const {data, refetch, isLoading} = useQuery(
      ['cc-review-frameworks', pages.page],
      () => fetchReviewFrameworks(),
    );

    const [deleteLoader, setDeleteLoader] = useState(false);

    const deleteFramework = async (id: string) => {
      setDeleteLoader(true);

      const response = await controller.deleteCcFramework(id);

      response &&
        activateNotification({
          title: `Success`,
          content: `Framework deleted`,
          kind: 'success',
        });
      response && setDeleteLoader(false);
      response && setShowDeleteModal('');

      response && (await refetch());
    };

    const allData: any = data;

    const totalResults = allData?.totalResults || 0;

    const getPaginationPages = paginationPages(totalResults, 10);

    const reviewFrameworks: any = allData?.results || [];

    const frameworksExists = reviewFrameworks.length > 0;
    const companyValues: string[] =
      auth?.user?.workspace?.settings?.companyValues?.values || [];

    const canCreateFramework =
      categories.length > 0 && companyValues.length > 0;

    const addFramework = (
      <NoBorderButton
        onClick={() => navigate('/create-review-frameworks')}
        disabled={!canCreateFramework}>
        <FlexRow>
          <SecondaryPlusIcon
            stroke={!canCreateFramework ? '#BFBFD4' : undefined}
          />
          <Body2
            weight="bold"
            kind={canCreateFramework ? 'purple300' : 'textMuted'}
            style={{marginLeft: '4px'}}>
            Create new framework
          </Body2>
        </FlexRow>
      </NoBorderButton>
    );

    const ViewModal = () => {
      const [expand, setExpand] = useState<string | number>('');

      return (
        <Modal open={true} onClose={() => setOpenModal(false)}>
          <ModalWrapper>
            <ModalCard title="" getStarted={'getStarted'}>
              <div style={{padding: '5px 8px 22px 8px'}}>
                <FlexRowSpaceBetween>
                  <Headline2 weight="bold">
                    Framework for {viewDetails?.name}
                  </Headline2>
                  <div
                    onClick={() => setOpenModal(false)}
                    style={{cursor: 'pointer'}}>
                    <CancelIcon />
                  </div>
                </FlexRowSpaceBetween>
                <VerticalSpacer size="14px" />

                {viewDetails?.managerQuestion && (
                  <ManagerAssessmentWrapper>
                    <Body2 weight="semibold" kind="textBody">
                      Manager assessment questions
                    </Body2>
                    <VerticalSpacer size="10px" />
                    <Body1 weight="bold">
                      Q1. {viewDetails?.managerQuestion}
                    </Body1>
                  </ManagerAssessmentWrapper>
                )}

                <ConfigureWrapper>
                  <div className="total-sum">
                    <FlexRow>
                      <Body2 weight="bold">Total sum </Body2>
                      <div className="number-count">
                        <Body2
                          weight="bold"
                          kind={
                            !viewDetails?.weighing.competence
                              ? 'textMuted'
                              : 'textDark'
                          }>
                          {viewDetails?.weighing.competence +
                            viewDetails?.weighing.value}
                        </Body2>
                      </div>
                    </FlexRow>
                    <FlexRow>
                      <div className="color-bg">
                        {' '}
                        <Body2>
                          Values :{' '}
                          <span style={{fontWeight: 600}}>
                            {viewDetails?.weighing.value}
                          </span>
                        </Body2>
                      </div>
                      <HorizontalSpacer size="10px" />
                      <div className="color-bg">
                        <Body2>
                          Competencies :{' '}
                          <span style={{fontWeight: 600}}>
                            {viewDetails?.weighing.competence}
                          </span>
                        </Body2>
                      </div>
                    </FlexRow>
                  </div>
                  <div className="body-card">
                    <Body2 weight="bold">Competence category</Body2>
                    <VerticalSpacer size="16px" />

                    {viewDetails?.competenceMetrics.map(
                      (metric: any, index: number) => (
                        <SavedWrapper key={index}>
                          <div className="main-wrapper">
                            <FlexRowSpaceBetween>
                              <Body2 weight="semibold">
                                {capitalize(metric?.name)}
                              </Body2>
                              <FlexRow>
                                <NoBorderButton
                                  onClick={() =>
                                    setExpand(expand === index ? '' : index)
                                  }>
                                  <Body2 weight="bold" kind="purple300">
                                    {expand === index ? 'Collapse' : 'Expand'}
                                  </Body2>
                                </NoBorderButton>
                              </FlexRow>
                            </FlexRowSpaceBetween>
                          </div>
                          {expand === index && (
                            <div className="expand-wrapper">
                              {metric.components.map((component: any) => (
                                <div className="key-areas">
                                  <FlexRow>
                                    <CustomColorIcon
                                      color="black"
                                      width="4px"
                                      height="4px"
                                      margin={4}
                                    />
                                    <Body2 weight="semibold">
                                      {' '}
                                      {capitalize(component.name)}
                                    </Body2>
                                  </FlexRow>
                                  <div className="weigh">
                                    <Body2 weight="bold">
                                      {component.score}
                                    </Body2>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </SavedWrapper>
                      ),
                    )}
                  </div>
                </ConfigureWrapper>
              </div>
            </ModalCard>
          </ModalWrapper>
        </Modal>
      );
    };

    const AddValuesCompetence = () => {
      return (
        <>
          <Body1 weight="bold">Add frameworks</Body1>
          <VerticalSpacer size="10px" />
          <Body2 kind="textBody" align="center">
            Add a set of values and competence and <br /> their respective
            weighing.
          </Body2>
          <VerticalSpacer size="16px" />
          <AdditionalOptionsWrapper
            display
            style={{width: '40%', padding: '12px 16px'}}>
            <Body2 kind="red500" align="center">
              Prior to creating frameworks, values and competence categories
              must first be established.
            </Body2>
          </AdditionalOptionsWrapper>
        </>
      );
    };

    const AddFrameworks = () => {
      return (
        <>
          <Body1 weight="bold">Frameworks</Body1>

          <VerticalSpacer size="10px" />
          <Body2 kind="textBody" align="center">
            Add key areas/competencies and their <br /> weighing under each
            category.
          </Body2>
        </>
      );
    };

    return (
      <div>
        <FlexRowSpaceBetween>
          <Body1 weight="bold">C&C frameworks ({totalResults})</Body1>
          {canCreateFramework && addFramework}
        </FlexRowSpaceBetween>
        <VerticalSpacer size="12px" />
        <TableContainer>
          <div style={{display: 'flex', overflowX: 'scroll'}}>
            <SecondRowWrapper>
              <HeadlineWrapperContainer className="header">
                <HeadlineWrapper>
                  <Body2 kind="textBody">Group(s)</Body2>
                  <Body2 kind="textBody">Actions</Body2>
                </HeadlineWrapper>
              </HeadlineWrapperContainer>
              {!canCreateFramework && !isLoading && !frameworksExists && (
                <EmptyWrapper>
                  <AddValuesCompetence />
                </EmptyWrapper>
              )}
              {!frameworksExists && canCreateFramework && !isLoading && (
                <EmptyWrapper>
                  <AddFrameworks />
                </EmptyWrapper>
              )}
              {isLoading && (
                <ItemLoaderWrapper>
                  <ItemLoader />
                </ItemLoaderWrapper>
              )}
              {openModal && <ViewModal />}
              {frameworksExists &&
                !isLoading &&
                reviewFrameworks?.map((category: any, index: number) => (
                  <FrameworkItem key={index} addBorderRadius={!index}>
                    <Body2 weight="semibold">
                      {capitalize(
                        findGroup(category.groups[0], category.allGroups),
                      )}
                    </Body2>

                    <Button
                      kind="secondary"
                      style={{padding: '8px 16px', maxWidth: '67px'}}
                      onClick={() => {
                        setOpenModal(true);
                        setViewDetails({
                          ...category,
                          name: findGroup(
                            category.groups[0],
                            category.allGroups,
                          ),
                        });
                      }}>
                      {' '}
                      View
                    </Button>
                    <div />
                    <DropdownVerticalSecondary
                      customIcon={<MoreVerticalIcon />}
                      collapseOnClick={true}
                      menu={(handleClose: () => void) => (
                        <div>
                          <DropdownItem
                            onClick={() =>
                              navigate(
                                `/create-review-frameworks/${category.id}`,
                                {
                                  state: category,
                                },
                              )
                            }>
                            Edit
                          </DropdownItem>
                          <DropdownItem
                            onClick={() =>
                              navigate(
                                `/create-review-frameworks?duplicate=true`,
                                {
                                  state: category,
                                },
                              )
                            }>
                            Duplicate
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setShowDeleteModal(
                                findGroup(
                                  category.groups[0],
                                  category.allGroups,
                                ),
                              );
                              setShowDeleteModalId(category.id);
                            }}>
                            Delete
                          </DropdownItem>
                        </div>
                      )}
                    />
                  </FrameworkItem>
                ))}
            </SecondRowWrapper>
          </div>
        </TableContainer>
        <Modal open={!!showDeleteModal} onClose={() => {}}>
          <div style={{maxWidth: '500px', margin: 'auto'}}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}>
              <BackAction
                width="md"
                icon={<CancelIcon />}
                onClick={() => {
                  setShowDeleteModal('');
                }}
                title="Close"
              />
            </div>
            <VerticalSpacer size="16px" />
            <ModalCard align="center" title={``}>
              <Flex
                alignItems="center"
                justifyContent="center"
                flexDir="column">
                <VerticalSpacer size="16px" />
                <Flex alignItems="center" justifyContent="center">
                  <CustomColorIcon
                    color="#F7E4E4"
                    width="80px"
                    margin={0}
                    height="80px"
                  />
                </Flex>
                <VerticalSpacer size="20px" />
                <span>
                  <Headline2 align="center">Delete Framework?</Headline2>
                </span>
                <VerticalSpacer size="16px" />

                <Body1 align="center" kind="textBody">
                  Are you sure you want to delete{' '}
                  <span style={{color: '#585ADF', fontWeight: 500}}>
                    {showDeleteModal}
                  </span>
                  ? This cannot be undone.
                </Body1>
                <VerticalSpacer size="32px" />
                <Button
                  onClick={() => deleteFramework(showDeleteModalId)}
                  width="full"
                  isLoading={deleteLoader}
                  kind="secondary">
                  Delete
                </Button>
              </Flex>
            </ModalCard>
          </div>
        </Modal>

        {totalResults > 10 && (
          <Pagination
            totalPages={pages.totalPages}
            page={pages.page}
            totalResults={pages.totalResults}
            maxPageNumberLimit={pages.maxPageNumberLimit}
            limit={pages.limit}
            updateLimit={updateLimit}
            minPageNumberLimit={pages.minPageNumberLimit}
            goToPage={goToPage}
            pages={getPaginationPages}
            nextBtn={nextPageBtn}
            prevBtn={handlePrevBtn}
          />
        )}
      </div>
    );
  },
);
