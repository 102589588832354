import React, {useCallback} from 'react';
import {TableContainer} from './analytics-status.styles';
import {
  HeadlineWrapper,
  HeadlineWrapperContainer,
  ItemWrapper,
  ItemWrapperContainer,
  SecondRowWrapper,
} from './ownership.style';
import {Body1, Body2, CTA} from '@ui/atoms/typography';
import {UserListCard} from '@ui/molecules/user/list-card';
import {Tooltip} from '@ui/molecules/tooltip';
import {useQuery} from 'react-query';
import {useApiCalls} from '@hooks/init-calls';
import {useStoreContext} from '@store/store-context';

interface OwnershipProps {
  people: any[];
}
export const PeopleOwnership = ({people}: OwnershipProps) => {
  const {initGroupMembers} = useApiCalls();

  const {
    groupStore: {groups: allGroups},
  } = useStoreContext();

  const {data: groupMembers} = useQuery(['initGroupMembers'], () =>
    initGroupMembers(),
  );

  const getUsersGroup = useCallback(async () => {
    const res = groupMembers;
    if (res && people) {
      const includeGroup = people.map((user: {id: any}) => {
        const userGroup = res.get(user.id);
        const getGroupIds = userGroup?.map((grp: any) => grp.group);
        const getUserGroups =
          allGroups.length > 0
            ? allGroups?.filter((grp: any) => getGroupIds?.includes(grp.id))
            : [];
        return {
          ...user,
          groups: getUserGroups,
        };
      });

      return includeGroup;
    }
  }, [groupMembers, people, allGroups]);

  const {data} = useQuery(['getUsersWithGroups', people], () =>
    getUsersGroup(),
  );

  return (
    <TableContainer>
      <div style={{display: 'flex', overflowX: 'scroll'}}>
        <SecondRowWrapper>
          <HeadlineWrapperContainer className="header">
            <HeadlineWrapper>
              <Body2 weight="semibold">Names</Body2>
              <Body2 weight="semibold">Groups</Body2>
              <Body2 weight="semibold">Total objectives</Body2>
              <Body2 weight="semibold">Active objectives</Body2>
              <Body2 weight="semibold">Past due</Body2>
              <Body2 weight="semibold">Ended</Body2>
              <Body2 weight="semibold">Aligned to company</Body2>
            </HeadlineWrapper>
          </HeadlineWrapperContainer>
          {data &&
            data.map((user: any, index: number) => (
              <ItemWrapperContainer key={index}>
                <ItemWrapper>
                  <div>
                    <UserListCard
                      tooltip={false}
                      userId={user?.id}
                      type="secondary"
                      reviewer={''}
                      avatar={user?.avatar?.url}
                      name={user.firstName + ' ' + user?.lastName || ''}
                      TextComponent={CTA}
                      textKind="textDark"
                      tooltipText
                    />
                  </div>

                  <div>
                    {user.groups?.length > 0 ? (
                      <div className="flex flex-row">
                        <div className="px-3 py-2 bg-[#F6F6F8] items-center justify-center rounded-[10px]">
                          <Body2 weight="semibold" kind="textBody">
                            {`${user.groups[0].name}`.length > 6 ? (
                              <>
                                <Tooltip
                                  text={`${user.groups[0].name}  `}
                                  maxLength={10}
                                  tooltipBody={
                                    <span>{user.groups[0].name}</span>
                                  }
                                />
                              </>
                            ) : (
                              `${user.groups[0].name}`
                            )}
                          </Body2>
                        </div>
                        {user.groups.length > 1 && (
                          <div className="px-3 py-2 bg-[#F6F6F8] items-center justify-center rounded-[10px] ml-1">
                            <Body2 weight="semibold" kind="purple300">
                              {' '}
                              +{user.groups.length - 1}
                            </Body2>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="px-3 py-2 bg-[#F6F6F8] items-center justify-center rounded-[10px] w-fit">
                        <Body2 kind="textBody">--- ---</Body2>
                      </div>
                    )}
                  </div>
                  <div>
                    <Body2>{user.goals.count}</Body2>
                  </div>
                  <div>
                    <Body2>{user.goals.active}</Body2>
                  </div>
                  <div>
                    <Body2>{user.goals.pastDue}</Body2>
                  </div>
                  <div>
                    <Body2>{user.goals.ended}</Body2>
                  </div>
                  <div>
                    <Body1>0</Body1>
                  </div>
                </ItemWrapper>
              </ItemWrapperContainer>
            ))}
        </SecondRowWrapper>
      </div>
    </TableContainer>
  );
};

interface GroupOwnershipProps {
  groups: any[];
}
export const GroupOwnership = ({groups}: GroupOwnershipProps) => {
  return (
    <TableContainer>
      <div style={{display: 'flex', overflowX: 'scroll'}}>
        <SecondRowWrapper>
          <HeadlineWrapperContainer className="header">
            <HeadlineWrapper>
              <Body2 weight="semibold">Groups</Body2>
              <Body2 weight="semibold">Type</Body2>
              <Body2 weight="semibold">Total objectives</Body2>
              <Body2 weight="semibold">Active objectives</Body2>
              <Body2 weight="semibold">Past due</Body2>
              <Body2 weight="semibold">Ended</Body2>
              <Body2 weight="semibold">Aligned to company</Body2>
            </HeadlineWrapper>
          </HeadlineWrapperContainer>
          {groups &&
            groups.map((group: any, index: number) => (
              <ItemWrapperContainer key={index}>
                <ItemWrapper>
                  <div>
                    <Body2 weight="semibold">{group.name}</Body2>
                  </div>

                  <div>
                    <Body2>{group.groupType}</Body2>
                  </div>
                  <div>
                    <Body2>{group.goals.count}</Body2>
                  </div>
                  <div>
                    <Body2>{group.goals.active}</Body2>
                  </div>
                  <div>
                    <Body2>{group.goals.pastDue}</Body2>
                  </div>
                  <div>
                    <Body2>{group.goals.ended}</Body2>
                  </div>
                  <div>
                    <Body1>0</Body1>
                  </div>
                </ItemWrapper>
              </ItemWrapperContainer>
            ))}
        </SecondRowWrapper>
      </div>
    </TableContainer>
  );
};
