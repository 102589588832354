import {useMemo} from 'react';
import {usePeriodHook} from '@hooks/period-hook';
import {FeedbackController} from '../feedback-controller';
import {FeedbackRequestImpl} from '../feedback-requests';
import {useState} from 'react';
import {AnalyticsTodayDate} from '@utils/date';
import {useStoreContext} from '@store/store-context';

export const useMyFeedbackHook = () => {
  const {isPeriodEnabled, currentMonthRange} = usePeriodHook();

  const [theme, setTheme] = useState('');

  const [sortBy, setSortBy] = useState('desc');

  const {
    authStore: {auth},
  } = useStoreContext();
  const [selectedUser, setSelectedUser] = useState(auth?.user.id || '');

  const getPeriod = useMemo(
    () =>
      isPeriodEnabled
        ? {
            starts: currentMonthRange().starts,
            ends: currentMonthRange().ends,
            period: currentMonthRange().period,
          }
        : {
            starts: AnalyticsTodayDate().AnalyticsDefaultStartDate,
            ends: AnalyticsTodayDate().AnalyticsDefaultEndDate,
          },
    [isPeriodEnabled, currentMonthRange],
  );
  const [dateRange, setDateRange] = useState(getPeriod);

  const request = useMemo(() => new FeedbackRequestImpl(), []);

  const controller = useMemo(() => new FeedbackController(request), [request]);

  const filters = useMemo(() => {
    return {
      startDate: dateRange.starts,
      endDate: dateRange.ends,
      sortBy,
      impression: theme,
    };
  }, [dateRange.starts, dateRange.ends, theme, sortBy]);

  return {
    controller,
    filters,
    sortBy,
    selectedUser,
    setSelectedUser,
    setSortBy,
    dateRange,
    setDateRange,
    theme,
    setTheme,
  };
};
