import {observer} from 'mobx-react-lite';
import {Button} from '@ui/atoms/button';
import {LockIcon} from '@ui/atoms/icons/lock';
import {Body2} from '@ui/atoms/typography';
import {capitalize} from '@utils';
import {DropdownWrapper} from './styles';
import {useDropDown} from '@hooks';
import {Label} from '@ui/atoms/label';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {TextField} from '@ui/molecules/field/textfield';
import {GroupDropdown} from './group-dropdown';
import {UserSelectField} from '@ui/molecules/select/user';
import {useCreateGroup} from '../admin/admin-components/admin-people/admin-group/admin-group-hooks';
import {
  SecondWrapper,
  TableHeader,
  TableHeaderChild,
  PageWrapper,
  LoaderWrapper,
  PrimaryWrapper,
} from './groups-styles';
import {useMemo} from 'react';
import {useState} from 'react';
import {useStoreContext} from '@store/store-context';

export const Groups = observer(
  ({
    groupTypeId,
    groupTypeName,
  }: {
    groupTypeId: string;
    groupTypeName: string;
  }) => {
    const {
      groupStore: {groups},
      usersStore: {users},
    } = useStoreContext();

    const submitCreateGroup = useCreateGroup();
    const membersInStore = useMemo(() => {
      return users.map((member) => {
        return {
          label: {
            avatar: {
              name:
                member && member.firstName
                  ? member.firstName + ' ' + member.lastName
                  : member.email + ' (pending invitation)',
              src: member.avatar && member.avatar.url ? member.avatar.url : '',
            },
            id: member.id,
            name:
              member && member.firstName
                ? member.firstName + ' ' + member.lastName
                : member.email + ' (pending invitation)',
          },
          value: member.id,
        };
      });
    }, [users]);

    const getGroups = useMemo(
      () => groups.filter((group) => group.groupType === groupTypeId),
      [groupTypeId, groups],
    );

    const {open, handleOpen, handleClose, ref} = useDropDown();

    const [newGroupName, setNewGroupName] = useState('');
    const [selectedGroup, setSelectedGroup] = useState('');
    return (
      <PageWrapper>
        {' '}
        <PrimaryWrapper>
          <span>
            <Body2 kind="textDark" weight="semibold">
              {groupTypeName && `${capitalize(groupTypeName)}`}
            </Body2>
          </span>
          <div style={{position: 'relative'}}>
            <Button
              kind="primary"
              width="sm"
              style={{padding: '8px 16px'}}
              onClick={handleOpen}
              // disabled={reachedLimit}
            >
              Create group
            </Button>

            {open && (
              <DropdownWrapper ref={ref}>
                <GroupDropdown
                  title="New group "
                  onClickCancel={handleClose}
                  disabled={!newGroupName || !selectedGroup}
                  onSubmit={() => {
                    submitCreateGroup({
                      name: newGroupName,
                      groupAdmin: selectedGroup,
                      groupType: groupTypeId,
                    });
                    handleClose();
                    setNewGroupName('');
                    setSelectedGroup('');
                  }}>
                  <div>
                    <Label>What’s the name of the group?</Label>
                    <VerticalSpacer size="8px" />
                    <TextField
                      placeholder="Enter group here"
                      margin
                      onChange={(e) =>
                        setNewGroupName(e.target.value.toLowerCase())
                      }
                      value={newGroupName}
                    />
                    <VerticalSpacer size="12px" />
                    <div>
                      <UserSelectField
                        name="groupAdmin"
                        margin
                        value={selectedGroup}
                        // helper={errors.groupAdmin?.message || ''}
                        // state={!!errors.groupAdmin ? 'error' : 'default'}
                        onChange={(data: {value: string}) =>
                          setSelectedGroup(data.value)
                        }
                        label="Group Admin"
                        placeholder="Select member"
                        options={membersInStore}
                      />
                    </div>
                  </div>
                </GroupDropdown>
              </DropdownWrapper>
            )}
          </div>
        </PrimaryWrapper>
        <SecondWrapper>
          <TableHeader>
            <Body2 weight="semibold">Group(s)</Body2>
            <Body2 weight="semibold">Goals</Body2>
            <Body2 weight="semibold">Members</Body2>
          </TableHeader>

          {getGroups.length === 0 && (
            <LoaderWrapper>
              <Body2 kind="textDark" align="center">
                No groups found
              </Body2>
            </LoaderWrapper>
          )}
          {getGroups.map((group: any, index: number) => (
            <TableHeaderChild key={index}>
              <Body2 kind="textDark">
                {group.isDefault && <LockIcon />} {capitalize(group.name)}
              </Body2>
              <Body2 kind="textDark">{group.goalsCount}</Body2>
              <Body2 kind="textDark">{group.membersCount}</Body2>
            </TableHeaderChild>
          ))}
        </SecondWrapper>
      </PageWrapper>
    );
  },
);
