import {Button} from '@ui/atoms/button';
import {Body2, Body1} from '@ui/atoms/typography';
import {HorizontalSpacer, VerticalSpacer} from '@ui/atoms/spacer/spacer';
import {FlexRowCenter} from '@ui/style/styles';
import {goalDueDate} from '@utils/date';
import {getColorFromTheme} from '@ui/ui-utils';
import {useNavigate} from 'react-router';
import styled from 'styled-components';
import {useStoreContext} from '@store/store-context';

import {Table, TableHeader, TableRow, TableWrapper} from '../../cc-styles';
import dayjs from 'dayjs';
import {useQuery} from 'react-query';
import {CcRequestImpl} from '@api/cc-api/cc-request';
import {CcController} from '@api/cc-api/cc-controller';
import {ItemLoader} from '@ui/organisms/item-loader';

export const Wrapper = styled.div`
  width: 100%;
`;
const Card = styled.div`
  border: 1px solid ${getColorFromTheme('borderDark')};
  border-radius: 8px;
  height: 38px;
  background: white;
  width: 168px;
  padding: 8px 16px;
`;
export default function SelfReview({
  disabled,
  dueDay,
  reviewType,
  cycleId,
}: {
  disabled: boolean;
  dueDay: string;
  cycleId: string;
  reviewType: string;
}) {
  const navigate = useNavigate();
  const {
    authStore: {auth},
  } = useStoreContext();

  const request = new CcRequestImpl();
  const controller = new CcController(request);

  const fetchWrittenReviews = async () => {
    const response = await controller.fetchWrittenReview({
      reviewCycle: cycleId,
      user: auth.user.id,
      reviewerType: 'self',
    });

    return response;
  };

  const {data, isLoading} = useQuery(
    ['written-review', cycleId, reviewType],
    () => fetchWrittenReviews(),
  );

  const isReviewWritten = !!data?.find(
    (review: {reviewer: string; user: string}) =>
      review.reviewer === auth.user.id && review.user === auth.user.id,
  );

  const isDisabled = dayjs().isAfter(dueDay) || disabled || isReviewWritten;

  return (
    <div>
      <Wrapper>
        <Body1 weight="bold">Self review</Body1>

        <VerticalSpacer size="7px" />
        <TableWrapper>
          <Table>
            <TableHeader variant="you">
              <td style={{maxWidth: '150px', minWidth: '150px'}}>
                <Body2 kind="textBody">Name</Body2>
              </td>

              <td style={{maxWidth: '100px', minWidth: '100px'}}>
                <Body2 kind="textBody">Progress</Body2>
              </td>
              <td style={{maxWidth: '100px', minWidth: '100px'}}>
                <Body2 kind="textBody">Actions</Body2>
              </td>
            </TableHeader>
            {isLoading && (
              <FlexRowCenter style={{height: '100px'}}>
                <ItemLoader />
              </FlexRowCenter>
            )}
            {!isLoading &&
              [''].map(() => (
                <TableRow variant="you">
                  <td style={{maxWidth: '200px', minWidth: '200px'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <HorizontalSpacer size="8px" />
                      <Body2 weight="semibold">You</Body2>
                    </div>
                  </td>

                  <td>
                    <div>
                      <Card>
                        <FlexRowCenter>
                          <Body2
                            weight="semibold"
                            kind={isReviewWritten ? 'green400' : 'orange300'}>
                            {isReviewWritten
                              ? 'Completed'
                              : goalDueDate(dueDay, false, false, true)}
                          </Body2>
                        </FlexRowCenter>
                      </Card>
                    </div>
                  </td>
                  <td>
                    <Button
                      disabled={isDisabled}
                      onClick={() =>
                        navigate(
                          `/make-review?type=self&peer=${auth.user.id}&id=${cycleId}`,
                        )
                      }
                      kind="secondary"
                      style={{
                        padding: '8px 16px',
                      }}>
                      Write review
                    </Button>
                  </td>
                </TableRow>
              ))}
          </Table>
        </TableWrapper>
      </Wrapper>
    </div>
  );
}
