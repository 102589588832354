import {memo} from 'react';

export const ProgressingSVG = memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16">
    <path
      stroke="#E6821D"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M7.999 10.667c1.472 0 2.666-1.194 2.666-2.667 0-1.473-1.194-2.667-2.666-2.667-1.473 0-2.667 1.194-2.667 2.667 0 1.473 1.194 2.667 2.667 2.667zM.7 8h3.966M11.34 8h3.966"
    />
  </svg>
));

ProgressingSVG.displayName = 'ProgressingSVG';
