import {down} from 'styled-breakpoints';
import styled from 'styled-components';
import {SectionCard} from '@ui/layouts/section-card';
import {FlexRowSpaceBetween} from '@ui/style/styles';

export const OuterWrapper = styled.div`
  border: 1px solid #ededf2;
  border-radius: 10px;
`;
export const Wrapper = styled.div`
  padding: 0px 32px 40px 15px;

  @media (max-width: 768px) {
    padding: 10px 0px;
  }
`;

export const ResponsiveFlexRowSpaceBetween = styled(FlexRowSpaceBetween)`
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
  .desc {
    width: 50%;
    @media (max-width: 768px) {
      margin-bottom: 10px;
      width: 100%;
    }
  }
`;
export const CustomSectionCard = styled(SectionCard)``;
export const ObjectivesWrapper = styled.div`
  overflow: scroll;

  max-height: 55vh;
`;
export const Hr = styled.div`
  height: 1px;
  width: 100%;
  background: #ededf2;
  margin: 24px 0px;
`;

export const CustomGoal = styled.div`
  padding: 16px;
  border: 1px solid #ededf2;
  background-color: white;
  border-radius: 10px;
  .exploreTemplatesHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .exploreTemplatesKeyresult {
    border-top: 1px solid #ededf2;
    padding-top: 12px;
    margin-top: 12px;
  }
`;
export const ContentHeaderWrapper = styled.div`
  max-width: 70%;
  ${down('lg')} {
    max-width: 70%;
  }

  ${down('md')} {
    max-width: 70%;
  }
  ${down('sm')} {
    max-width: 100%;
  }

  @media (min-width: 1440px) {
    max-width: 70%;
  }
`;
export const PaginationWrapper = styled.div`
  display: flex;
  margin-top: 12px;
  justify-content: center;
`;

export const PaginationUl = styled.ul`
  list-style: none;
  width: 239px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 32px 0;
  padding: 4px;
  border-radius: 5px;
  border: solid 1px #cecede;
  background-color: #f6f6f8;
`;
export const PaginationLi = styled.li`
padding: 8px;
cursor: pointer;
margin: 0 1px;
color: #5f5f8c;
&:hover {
  color: #585adf;
}
  &.active { 
    width: 32px;
    height: 32px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 3px 12px;
    border-radius: 4px;
    box-shadow: 0 2px 5px 0 rgba(172, 172, 190, 0.24);
    background-color: #ffffff;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: normal;
    text-align: left;
    color: #585adf;
    transition: background-color 0.3s ease-in;
    }
  };
`;
export const PaginationButton = styled.button`
  border: none;
  stroke: #5f5f8c;
  stroke-width: 1.5px;
  fill: none;
  width: 100%;
  height: 100%;
  padding-top: 4px;
  &:hover {
    stroke: black;
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
    stroke: #5f5f8c;
  }
};
`;
