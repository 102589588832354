import styled, {css} from 'styled-components';
import {
  ChangeEvent,
  ComponentPropsWithRef,
  forwardRef,
  ReactElement,
  Ref,
  useCallback,
  useState,
} from 'react';
import {switchProp} from 'styled-tools';
import {Helper} from '../../../atoms/helper';
import {BinaryType, InputState} from '../../../interface';
import {NumericFormat} from 'react-number-format';
import {getColorFromTheme} from '../../../ui-utils';
import {HashIcon} from '../../../atoms/icons';
import {HorizontalSpacer, VerticalSpacer} from '../../../atoms/spacer';
import {Body2} from '../../../atoms/typography/body2';
import {parseCurrencyValue} from '../../../../constants';
const commaNumber = require('comma-number');

export interface RangeUpdateFieldProps extends ComponentPropsWithRef<'input'> {
  label?: string;
  name?: string;
  filter?: boolean;
  state?: InputState;
  helper?: string | ReactElement;
  kind: 'number' | 'currency';
  max: string;
  setValue?: (value: BinaryType) => void;
  currencySymbol?: any;
}

const Wrapper = styled.div`
  position: relative;

  display: inline-block;
`;

const Icon = styled.span`
  z-index: 2;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 16px;
    height: 16px;
    stroke: #5f5f8c;
  }
`;
export const Label = styled.span<{disabled?: boolean}>`
  background-color: ${getColorFromTheme('backgroundDark')};
  box-shadow: 0px 0px 0px 1px ${getColorFromTheme('borderLight')};
  height: 100%;
  padding: 12px 8px;
  transition: 200ms ease all;
  flex-shrink: 0;
`;

const LabelX = styled(Label)`
  padding: 12px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconWrapper = styled.span<{disabled?: boolean}>`
  flex-shrink: 0;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${getColorFromTheme('backgroundLight')};
  box-shadow: 0 0 0 1px ${getColorFromTheme('borderLight')};

  svg {
    width: 20px;
    height: 20px;
    stroke: ${getColorFromTheme('textBody')};
  }
`;

export const Input = styled.input`
  appearance: textfield;
  -webkit-appearance: textfield;
  -webkit-tap-highlight-color: transparent;
  -moz-appearance: textfield;
  padding: 5px 16px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: normal;
  color: ${getColorFromTheme('textDark')};
  display: inline-block;
  height: 40px;
  width: 80px;

  background-color: ${getColorFromTheme('white')};
  transition: 200ms ease all;
  border: none;
  outline: none;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: ${getColorFromTheme('textMuted')};
  }
  @media (max-width: 500px) {
    width: 150px;
  }
`;

const Container = styled.label<{state?: InputState}>`
  display: inline-flex;
  flex-direction: row;

  position: relative;
  cursor: pointer;
  height: 40px;
  transition: 200ms ease all;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 0 1px ${getColorFromTheme('borderLight')};
  background-color: ${getColorFromTheme('white')};

  &:focus-within {
    outline: 0;
    box-shadow: 0 0 0 1px ${getColorFromTheme('purple300')},
      0 0 0 6px ${getColorFromTheme('purple200')};

    ${IconWrapper} {
      box-shadow: 0 0 0 1px ${getColorFromTheme('purple300')};
    }
  }

  &:hover:not(:focus-within) {
    box-shadow: 0 0 0 2px ${getColorFromTheme('purple200')};

    ${IconWrapper} {
      box-shadow: 0 0 0 1px ${getColorFromTheme('purple200')};
    }
  }

  ${switchProp('state', {
    error: css`
      box-shadow: 0 0 0 1px ${getColorFromTheme('red400')};

      ${Input} {
        color: ${getColorFromTheme('red400')};

        &::placeholder {
          color: ${getColorFromTheme('red400')};
        }
      }

      ${IconWrapper} {
        box-shadow: 0 0 0 1px ${getColorFromTheme('red400')};
      }

      &:focus-within {
        box-shadow: 0 0 0 1px ${getColorFromTheme('red400')},
          0 0 0 6px ${getColorFromTheme('red200')};

        ${IconWrapper} {
          box-shadow: 0 0 0 1px ${getColorFromTheme('red400')};
        }
      }

      &:hover:not(:focus-within) {
        box-shadow: 0 0 0 2px ${getColorFromTheme('red400')};

        ${IconWrapper} {
          box-shadow: 0 0 0 1px ${getColorFromTheme('red400')};
        }
      }
    `,
  })}
`;
type inputType = string | number | undefined;

export const RangeUpdateField = forwardRef(
  (
    {
      state,
      helper,
      defaultValue,
      label,
      name,
      value,
      setValue,
      id,
      kind,
      filter,
      max,
      currencySymbol,
      ...rest
    }: RangeUpdateFieldProps,
    ref: Ref<HTMLInputElement>,
  ) => {
    const [innerValue, setInnerValue] = useState(defaultValue ?? value);

    const onChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;

        const number = value.replaceAll(',', '');

        setInnerValue(number as BinaryType);
        // TODO: Inner validation here
        if (setValue) {
          setValue(number as BinaryType);
        }
      },
      [setValue],
    );

    return (
      <Wrapper>
        <Body2 kind={filter ? 'textMuted' : 'textBody'} as="label">
          {label}
        </Body2>
        <VerticalSpacer size="8px" />

        <Container htmlFor={id} state={state}>
          <NumericFormat
            {...rest}
            value={commaNumber(innerValue) as inputType}
            defaultValue={defaultValue as inputType}
            onChange={onChange}
            name={name}
            id={id}
            customInput={Input}
            style={{
              color: filter ? '#bfbfd4' : '',
              minWidth: innerValue
                ? `${innerValue.toString().length + 5}ch`
                : '50px',
            }}
            inputMode="numeric"
            type={'text'}
            max={max}
            thousandSeparator=","
          />

          <Body2 kind="textMuted" style={{whiteSpace: 'nowrap'}}>
            of {kind === 'currency' && parseCurrencyValue(currencySymbol)}
            {Number(max).toLocaleString()}
          </Body2>
          <HorizontalSpacer size="8px" />
          <LabelX>
            <Icon>
              <HashIcon />
            </Icon>
          </LabelX>
        </Container>
        <Helper aria-label="helper" state={state} children={helper} />
      </Wrapper>
    );
  },
);
