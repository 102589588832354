import {action} from 'mobx';
import {activateNotification} from '../../../../../../ui/molecules/notification/activate-notification';
import {groupFormData} from './admin-group-interface';
import {groupRequest} from './admin-group-request';
import {beamMasteryTasks} from '@utils/firebase-request';

import {groupStore} from '../../../../../../store/stores/group-store';
import {parseErrorContent} from '../../../../../../utils/error-handling';

export class groupController {
  constructor(private readonly request: groupRequest) {}

  @action
  async newCreateGroup(data: groupFormData, hideNotif?: boolean) {
    try {
      const response = await this.request.createGroup(data);
      !hideNotif &&
        activateNotification({
          title: 'Success',
          content: 'Group  has been created',
          kind: 'success',
        });
      beamMasteryTasks('manage_groups', true);

      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
    }
  }

  @action
  async newEditGroup(data: groupFormData, id?: string) {
    try {
      await this.request.editGroup(data, id);
      activateNotification({
        title: 'Success',
        content: 'Group  updated',
        kind: 'success',
      });
      beamMasteryTasks('manage_groups', true);
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
    }
  }

  @action
  async newfetchGroup(id?: string) {
    try {
      const response = await this.request.fetchGroup(id);
      groupStore.setGroupInStore(response);
      groupStore.setGroupTypeNameInStore(response);

      return response;
    } catch (e: any) {}
  }

  async newfetchSpecGroup(id: string, include: string) {
    try {
      const response = await this.request.fetchSpecGroup(id, include);

      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
    }
  }

  async fetchGroups() {
    try {
      const response = await this.request.fetchGroups();

      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
    }
  }

  async fetchGroupsMembers() {
    try {
      const response = await this.request.fetchGroupsMembers();

      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
    }
  }

  @action
  async newfetchGroupMembers(id?: string) {
    try {
      const response = await this.request.fetchGroupMembers(id);
      groupStore.setGroupMembers(response);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e.response?.data.message,
        kind: 'error',
      });
    }
  }

  async newAddGroupMember(data: any, id?: string) {
    try {
      const response = await this.request.addGroupMember(data, id);

      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e.response?.data.message,
        kind: 'error',
      });
    }
  }
  async newRemoveGroupMember(data: any, id?: string) {
    try {
      const response = await this.request.removeGroupMember(data, id);

      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e.response?.data.message,
        kind: 'error',
      });
    }
  }

  @action
  async newDeleteGroup(id?: string) {
    try {
      await this.request.deleteGroup(id);
      activateNotification({
        title: 'Success',
        content: 'Group  deleted successfully',
        kind: 'success',
      });
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
    }
  }
}
