import {useState} from 'react';

import {activateNotification} from '@ui/molecules/notification/activate-notification';
import {IntegrationsController} from '../../components/app.controller';

export const useHubspotHook = () => {
  const [disconnectLoading, setDisconnectLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleDisconnectHubspotConnection = async (
    app: string,
    setAction: any,
  ) => {
    setDisconnectLoading(true);
    const controller = new IntegrationsController();
    const response = await controller.deleteIntegrations(app);

    response &&
      activateNotification({
        title: 'Access disconnected',
        content: 'App has been disconnected from Beam',
        kind: 'success',
      });

    response &&
      setAction((prev: any) =>
        prev.map((app: any) => {
          if (app.app === 'Hubspot') {
            return {
              ...app,
              status: 'disconnected',
            };
          } else return app;
        }),
      );

    response && setOpenModal(false);
    setDisconnectLoading(false);
  };

  return {
    handleDisconnectHubspotConnection,
    disconnectLoading,
    openModal,
    setOpenModal,
  };
};
