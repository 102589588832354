import {DrawerModal} from '@ui/organisms/modal/drawer-modal';
import {Button} from '@ui/atoms/button';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {TextField} from '@ui/molecules/field/textfield';
import {CancelIcon} from '@ui/atoms/icons';
import {Body1, Body2} from '@ui/atoms/typography';
import {useState} from 'react';
import {Label} from '@ui/atoms/label';
import {removeFirebaseData} from '@utils/firebase-handler';
import {MeetingRequestImpl} from '../meeting.request';
import {MeetingController} from '../meeting-controller';
import {useQuery} from 'react-query';

interface RescheduleProps {
  open: boolean;
  onSubmit: (text: string) => void;
  tags?: {key: string; tag: string; disabled?: boolean}[];
  onClose: () => void;
}

export const ManageTags = ({
  open,
  tags,
  onSubmit,
  onClose,
}: RescheduleProps) => {
  const [tag, setTag] = useState('');

  const getRegisteredTags = async (): Promise<string[]> => {
    const request = new MeetingRequestImpl();

    const controller = new MeetingController(request);
    const response = await controller.getMeetingTags();
    return response;
  };

  const {data} = useQuery(['meeting-tags'], () => getRegisteredTags());

  return (
    <div>
      <DrawerModal open={open} onClose={onClose} title="Manage Tags">
        <VerticalSpacer size="32px" />

        <Label>What's the name of this tag?</Label>
        <VerticalSpacer size="8px" />

        <TextField
          inputStyle={{borderRadius: '10px'}}
          value={tag}
          placeholder="e.g Design meeting"
          onChange={(event) => {
            setTag(event.target.value);
          }}
        />

        <Button
          width="full"
          onClick={() => {
            onSubmit(tag);
            setTag('');
          }}
          disabled={
            !tag ||
            tags?.some((_tag) => tag.toLowerCase() === _tag.tag.toLowerCase())
          }>
          Add tag
        </Button>
        <VerticalSpacer size="32px" />
        <Body2 weight="semibold">Tags</Body2>
        <VerticalSpacer size="16px" />
        <div className="w-[450px] sm:w-full">
          <div className="flex items-center flex-wrap gap-4 ">
            {tags &&
              tags.map((tag) => (
                <div
                  className="px-4 py-2 bg-[#F6F6F8] rounded-full gap-4 flex items-center justify-between w-fit"
                  key={tag.key}>
                  <Body1 className=" whitespace-nowrap">{tag.tag}</Body1>
                  <div
                    onClick={() =>
                      data?.includes(tag.tag) || tag.disabled
                        ? null
                        : removeFirebaseData(`tags/${tag.key}`)
                    }
                    className={
                      data?.includes(tag.tag) || tag.disabled
                        ? 'cursor-not-allowed'
                        : 'cursor-pointer'
                    }>
                    <CancelIcon
                      color={
                        data?.includes(tag.tag) || tag.disabled
                          ? '#BFBFD4'
                          : undefined
                      }
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </DrawerModal>
    </div>
  );
};
