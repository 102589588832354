import styled from 'styled-components';
import {getColorFromTheme} from '../../ui-utils';

export const CalendarWrapper = styled.div`
  box-shadow: 0px 0px 0px 1px #ededf2;
  border-radius: 10px;
  padding: 0;
  display: block;
  position: absolute;
  right: 0;

  top: 120%;
  border-radius: 10px;
  border: solid 1px ${getColorFromTheme('borderLight')};
  background-color: ${getColorFromTheme('white')};
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: ${({theme}) => theme.elevation.level1};
  z-index: 3;
`;

export const Year = styled.div`
  padding: 10px;
  display: flex;
  justify-content: space-between;
  & span.nav {
    &:hover {
      box-shadow: 0px 0px 0px 1px #000;
    }
  }
  & p {
    font-weight: 500;
  }
`;

export const DateList = styled.div`
  padding: 8px;
  display: flex;
  flex-wrap: wrap;
`;
export const DateSpan = styled.span`
  box-shadow: 0px 0px 0px 1px #ededf2;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 500;
  padding: 6px;
  margin: 2px;
`;
export const OuterBox = styled.div`
  box-shadow: 0px 0px 0px 1px #ededf2;
  border-radius: 10px;
  max-height: 300px;
  overflow: scroll;
`;
export const MonthBox = styled.div`
  box-shadow: 0px 0px 0px 1px #ededf2;
  border-radius: 10px;
  padding: 8px 24px 8px 24px;
  max-height: 300px;
  overflow: scroll;
`;

export const MonthCell = styled.div`
  box-shadow: 0px 0px 0px 1px #ededf2;
  padding: 16px;
  margin: 8px;
  border-radius: 10px;
  &:hover {
    background-color: #ffffff;
  }
  & p.highlighted {
    font-weight: 500;
  }
  &.currentMonth {
    background-color: #ffffff;
  }
  display: flex;
  justify-content: space-between;
`;

export const LoaderWrapper = styled.div`
  align-items: center;
  justify-content: center;
  min-height: 300px;
  display: flex;
`;
