import {CycleWidget, FrameworkWrapper} from '../style';
import {Body1, Body2} from '@ui/atoms/typography';
import dayjs from 'dayjs';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {ReviewFrameworkProps} from '@api/cc-api/cc-controller';
import {Button} from '@ui/atoms/button';
import {CompanyIcon} from '@ui/atoms/icons';
import {useStoreContext} from '@store/store-context';
import {FlexRowSpaceBetween, FlexRow} from '@ui/style/styles';
import {ValueProps} from '../../create-review-interface';
import {HorizontalSideRule} from '@ui/atoms/spacer/spacer';
import {PlainButton} from '@ui/atoms/plain-button/plain-button';
import {ViewFrameworkModal} from './view-framework-modal';
import {useNavigate, useSearchParams} from 'react-router-dom';

type CycleDetailsProps = {
  reviewPeriod: ValueProps['period'];
  reviewCycleType: ValueProps['reviewType'];
  isDisabled?: boolean;
  editData: (step: number) => void;
  frameworks: ReviewFrameworkProps;
};

export const PreviewCycleDetails = ({
  reviewCycleType,
  isDisabled,
  reviewPeriod,
  editData,
  frameworks,
}: CycleDetailsProps) => {
  const [searchParams] = useSearchParams();

  const openModal = searchParams.get('view-framework');

  searchParams.append('view-framework', 'true');

  // Get the updated search string
  const updatedSearchString = searchParams.toString();

  const navigate = useNavigate();

  const formatPeriodDate = (date: string) => dayjs(date).format('DD MMM');
  const {
    groupStore: {groups},
  } = useStoreContext();

  const getGroupsFromFramework = frameworks?.flatMap(
    (framework) => framework.groups,
  );

  const selectedGroups = groups.filter((group) =>
    getGroupsFromFramework?.includes(group.id),
  );

  const startEndDate = `${
    reviewPeriod?.starts ? formatPeriodDate(reviewPeriod?.starts || '') : '-'
  } - ${reviewPeriod?.ends ? formatPeriodDate(reviewPeriod?.ends || '') : '-'}`;

  const CycleDetails = () => {
    return (
      <CycleWidget className="bg-white">
        <FlexRowSpaceBetween>
          <Body1 weight="bold" style={{fontSize: '18px'}}>
            Cycle details
          </Body1>
          <Button
            kind="secondary"
            disabled={isDisabled}
            style={{padding: '8px 16px'}}
            onClick={() => {
              editData(1);
            }}>
            Edit
          </Button>
        </FlexRowSpaceBetween>
        <FlexRowSpaceBetween className="border-b">
          <Body2 kind="textBody" weight="semibold">
            Period of review
          </Body2>
          <Body1 weight="bold">
            {reviewPeriod?.period}{' '}
            {reviewPeriod?.period ? `(${startEndDate})` : startEndDate}
          </Body1>
        </FlexRowSpaceBetween>
        <FlexRowSpaceBetween className="border-b">
          <Body2 kind="textBody" weight="semibold">
            Review Type
          </Body2>
          <FlexRow>
            <Body1 weight="bold">
              {reviewCycleType === 'self'
                ? 'Self & manager review'
                : '360 review'}{' '}
            </Body1>
            <CompanyIcon
              style={{
                stroke: '#585ADF',
                width: '18px',
                height: '18px',
                marginLeft: '5px',
              }}
            />
          </FlexRow>
        </FlexRowSpaceBetween>
        <VerticalSpacer size="24px" />
        <FlexRowSpaceBetween>
          <Body2 kind="textBody" weight="semibold">
            C&C Framework
          </Body2>
          <FlexRow>
            <FrameworkWrapper>
              <Body2>{selectedGroups[0]?.name}</Body2>
            </FrameworkWrapper>
            {selectedGroups.length > 1 && (
              <Body2 weight="semibold">+{selectedGroups.length - 1} more</Body2>
            )}

            <HorizontalSideRule size="20px" style={{margin: '0px 10px'}} />

            <PlainButton
              onClick={() => navigate({search: `?${updatedSearchString}`})}>
              <Body1 weight="bold" kind="purple300">
                View
              </Body1>
            </PlainButton>
          </FlexRow>
        </FlexRowSpaceBetween>
      </CycleWidget>
    );
  };

  return (
    <>
      <CycleDetails />
      <ViewFrameworkModal
        frameworks={frameworks}
        openModal={!!openModal}
        reviewCycleType={reviewCycleType}
        setOpenModal={() => {
          searchParams.delete('view-framework');

          // Get the updated search string
          const updatedSearchString = searchParams.toString();
          navigate({search: `?${updatedSearchString}`});
        }}
      />
    </>
  );
};
