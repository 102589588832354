import {AxiosResponse} from 'axios';
import {authStore} from '../../../../../../../../store/stores/auth-store';
import {BaseRequest} from '../../../../../../../../configs/request';
import {removeEmptyStringFromObject} from '../../../../../../../../utils/data-structure-algos';

import {
  AdminEmailData,
  AdminReviewerData,
  AdminSettingsFormData,
  AdminSettingsResponse,
} from './admin-settings-interface';

export interface AdminSettingsRequest extends BaseRequest {
  adminForm(
    data: AdminSettingsFormData,
    id: string,
  ): Promise<AdminSettingsResponse>;
  adminEmail(data: AdminEmailData, id: string): Promise<AdminSettingsResponse>;
  adminReviewer(
    data: AdminReviewerData,
    id: string,
  ): Promise<AdminSettingsResponse>;
}

export class AdminSettingsRequestImpl
  extends BaseRequest
  implements AdminSettingsRequest {
  private adminEndpoint: string = '/users';

  async adminForm<AdminSettingsResponse>(
    data: AdminSettingsFormData,
    id: string,
  ) {
    const {data: responseData} = await this.request.patch<
      AdminSettingsFormData,
      AxiosResponse<AdminSettingsResponse>
    >(`${this.adminEndpoint}/${id}`, removeEmptyStringFromObject(data), {
      headers: {
        Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
      },
    });
    return responseData;
  }

  async adminReviewer<AdminSettingsResponse>(
    data: AdminReviewerData,
    id: string,
  ) {
    const {data: responseData} = await this.request.patch<
      AdminReviewerData,
      AxiosResponse<AdminSettingsResponse>
    >(`${this.adminEndpoint}/${id}`, data, {
      headers: {
        Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
      },
    });
    return responseData;
  }

  async adminEmail<AdminSettingsResponse>(data: AdminEmailData, id: string) {
    const {data: responseData} = await this.request.patch<
      AdminEmailData,
      AxiosResponse<AdminSettingsResponse>
    >(`${this.adminEndpoint}/${id}`, data, {
      headers: {
        Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
      },
    });
    return responseData;
  }
}
