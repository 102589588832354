import styled from 'styled-components';

export const FieldWrapper = styled.div<{helper?: boolean; margin?: boolean}>`
  position: relative;
  margin-bottom: ${(props) =>
    props.margin ? '0px' : props.helper ? '10px' : '24px'};
`;

export const SecondaryFieldWrapper = styled.div<any>`
  position: relative;
  margin-bottom: ${(props) => (props.helper ? '10px' : '0px')};
`;
