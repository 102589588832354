import styled, {css} from 'styled-components';
import {TextField} from '../../../../../ui/molecules/field/textfield';
import {up, down} from 'styled-breakpoints';
import {FlexRowSpaceBetween} from '@ui/style/styles';
import {getColorFromTheme} from '../../../../../ui/ui-utils';
import {ifProp} from 'styled-tools';

export const Wrapper = styled.div``;
export const BreadcrumbWrapper = styled.div`
  padding: 28px 32px 16px;
  display: flex;
  align-items: center;
  width: 80vw;
  justify-content: center;
  border-bottom: 1px solid #ededf2;

  @media (max-width: 500px) {
    padding: 16px 5px;
    width: 90vw;
    border-top: 1px solid #ededf2;
  }
`;

export const ResponsiveFlexRowBetween = styled(FlexRowSpaceBetween)`
  @media (max-width: 500px) {
    flex-direction: column;

    align-items: flex-start;
    justify-content: flex-end;
    width: 100%;
    .title {
      margin-bottom: 16px;
    }
  }
`;
export const PeriodWrapper = styled.div`
  border: 1px solid #ededf2;
  border-radius: 10px;
  padding: 22px 24px;
  margin-top: 24px;
  @media (max-width: 500px) {
    padding: 12px;
  }
  .space-between {
    @media (max-width: 500px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .settings-container {
    border: 1px solid #ededf2;
    border-radius: 10px;
    margin-top: 16px;
    .periods-header {
      padding: 20px 24px;
      @media (max-width: 500px) {
        padding: 12px;
      }
    }
    .periods-select {
      border-top: 1px solid #ededf2;
      border-radius: 10px 10px 0 0;
      padding: 24px;
      @media (max-width: 500px) {
        padding: 12px;
      }
    }
  }
`;
export const SectionComponentWrapper = styled.div`
  padding: 24px;
  @media (max-width: 500px) {
    padding: 0px;
  }
  div.periodParentDivs {
    display: grid;
    grid-gap: 40px;
    align-items: center;
    grid-template-columns: 16.8% 77.9%;
    ${down('md')} {
      grid-template-columns: 20.8% 72.9%;
    }
    ${down('sm')} {
      grid-template-columns: 5% 80.9%;
      grid-gap: 10px;
    }
    ${down('xs')} {
      grid-template-columns: 21% 74.9%;
    }
    padding-bottom: 24px;

    &.custom {
    }
    .period {
      padding: 14px 16px;
      background: ${getColorFromTheme('backgroundDark')};
      border-radius: 10px;
      white-space: nowrap;
    }
    .duration {
      .date-range {
        padding: 14px 16px;
        height: 52px;
        border: 1px solid #ededf2;
        border-radius: 10px;
        white-space: nowrap;
      }
    }
  }
  .visibilitySettingsTitle {
    display: flex;
    align-items: center;
  }
`;

export const AdditionalOptionsWrapper = styled.div<{display?: boolean}>`
  display: ${(props: any) => (props.display ? 'block' : 'none')};
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='rgba(213, 42, 42, 1)' stroke-width='2' stroke-dasharray='14%2c 8' stroke-dashoffset='0' strokeLinecap='round'/%3e%3c/svg%3e");
  border-radius: 10px;
  margin: 8px 0;
  height: 79px;
  padding: 2px 2px;
  .wrapper {
    background: #fef6f6;
    display: flex;
    height: 100%;
    justify-content: space-between;
    text-align: left;
    align-items: center;
    .wrapper-div {
      padding-left: 24px;
      span {
        color: #661515;
        font-weight: 600;
        text-decoration: underline;
        white-space: no-wrap;
      }
    }

    .cancel-div {
      padding-right: 24px;
      margin-top: 5px;
    }
  }
`;

export const PageLayout = styled.section<any>`
  width: 90%;
  display: block;
  padding: 32px 32px 34px 32px;
  @media (max-width: 500px) {
    width: 99%;
    padding: 32px 16px 34px;
  }
`;
export const FeaturesLayout = styled.section<any>`
  width: 100%;
  padding-right: 6.7%;

  display: block;

  @media (min-width: 1000px) {
    width: 99%;
  }
  @media (max-width: 500px) {
    width: 99%;
  }
`;

export const BodyLayout = styled.section<any>`
  padding: 32px 0px 34px 32px;
  @media (max-width: 500px) {
    padding: 32px 0px 34px;
  }
`;

export const SectionCardBody = styled.div<any>`
  padding: 24px;
  @media (max-width: 500px) {
    padding: 0px;
  }
`;

export const RightWrapper = styled.div<any>`
  width: 100%;

  @media (max-width: 900px) {
    margin-left: 0;
  }
`;

export const RightChildWrapper = styled.div<any>`
  display: flex;
  padding: 0 10px;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: space-evenly;
`;

export const RightChildSpan = styled.span<any>`
  width: 49%;

  @media (max-width: 678px) {
    width: 100%;
  }
`;

export const LeftWrapper = styled.div<any>`
  position: relative;
  padding: 24px 0 8px 0;

  @media (max-width: 900px) {
    width: 100%;
    margin-bottom: 24px;
    padding-left: 16px;
  }
`;

export const LeftChildSpan = styled.span<any>`
  position: absolute;
  margin: 20px 0 0 25px;
  font-size: 8px;
`;

export const LeftChildInput = styled(TextField)`
  display: none;
`;

export const CancelText = styled.span`
  padding-left: 8px;
`;

export const WorkspaceWrapper = styled.section``;

export const WorkspaceWrapperHeader = styled.div<{billing?: boolean}>`
  display: flex;
  justify-content: space-between;
  padding: 32px;
  border-bottom: 1px solid ${getColorFromTheme('borderLight')};
  h2 {
    ${down('md')} {
      margin-bottom: 8px;
    }
  }

  ${down('md')} {
    display: block;
  }

  ${ifProp(
    'billing',
    css`
      border-bottom: unset;
    `,
  )}

  ${down('md')} {
    flex-direction: column;
  }
  $
`;

export const WorkspaceWrapperChild = styled.div`
  display: grid;
  grid-template-columns: 21% 79%;
  overflow: hidden;
  ${down('md')} {
    display: block;
  }

  ${down('sm')} {
    margin: 55px 12px 0px;
  }
`;

export const WorkspaceWrapperFooter = styled.div`
  display: grid;
  grid-template-columns: 21% 79%;
  @media (max-width: 500px) {
    display: block;
  }
  div.starterPlan {
    margin-left: auto;
    margin-right: auto;
    a {
      color: ${getColorFromTheme('purple300')};
      font-weight: 600;
      font-size: 16px;
    }
  }
`;

export const ContentBox = styled.div`
  border: 1px solid ${getColorFromTheme('borderLight')};
  border-radius: 10px;
  padding: 24px 0 24px;
  background-color: ${getColorFromTheme('white')};
  cursor: pointer;

  ${down('sm')} {
    padding: 21px 0;
  }

  div.flex-sm {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 32px;
    ${down('sm')} {
      padding: 0 12px;
    }
  }

  div.flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;

    ${down('sm')} {
      display: block;
    }

    ${down('sm')} {
      padding: 0 12px;
    }
  }
  div.column {
    display: flex;
    flex-direction: column;
    ${down('sm')} {
      margin-bottom: 12px;
    }
  }
  div.select {
    border-top: 1px solid ${getColorFromTheme('borderLight')};
    border-top-right: 1px solid ${getColorFromTheme('borderLight')};
    border-top-left: 1px solid ${getColorFromTheme('borderLight')};

    padding: 24px 24px 4px;
    cursor: pointer;

    ${down('sm')} {
      padding: 24px 24px 18px;
    }

    ${up('sm')} {
      padding: 24px 24px 4px;
    }

    ${up('lg')} {
      padding: 24px 24px 4px;
    }

    ${up('xl')} {
      padding: 24px 24px 4px;
    }
  }
  div.text {
    border-top: 1px solid ${getColorFromTheme('borderLight')};
    border-top-right: 1px solid ${getColorFromTheme('borderLight')};
    border-top-left: 1px solid ${getColorFromTheme('borderLight')};

    padding: 24px 24px 4px;
    cursor: pointer;

    ${down('sm')} {
      padding: 24px 24px 18px;
    }

    ${up('sm')} {
      padding: 24px 24px 4px;
    }

    ${up('lg')} {
      padding: 24px 24px 4px;
    }

    ${up('xl')} {
      padding: 24px 24px 0px;
    }
  }
  .resp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;
    ${down('md')} {
      width: 100%;
    }
  }
`;
export const ArrowIconWrapper = styled.span<{
  tab: 'name' | 'framework' | '';
  activeTab: 'name' | 'framework' | '';
}>`
  stroke: #000000;
  transform: ${({tab, activeTab}) =>
    tab === activeTab ? 'rotate(90deg)' : 'rotate(0deg)'};
`;
export const MemberEditGoal = styled.div`
  padding: 16px;
`;
export const SelectFrameworkWrapper = styled.div`
  max-width: 50%;

  .input {
    width: 360px;
    @media (max-width: 500px) {
      width: 100%;
    }
  }
  @media (max-width: 500px) {
    max-width: 100%;
  }
  div.framework {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }
`;
export const NotificationWrapper = styled.div<{fullWidth?: boolean}>`
  width: ${({fullWidth}) => (fullWidth ? '100vw' : '80vw')};
  position: fixed;
  padding-top: 10px;
  z-index: 3;
`;
export const ModalWrapper = styled.div`
  max-width: 560px;
  margin: auto;
  @media (max-width: 500px) {
    margin: 0 10px;
  }
`;
