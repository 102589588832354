import {useEffect, useState} from 'react';
import {getDatabase, ref, get} from 'firebase/database';
import dayjs from 'dayjs';
import {db, FIREBASE_BASE_URL} from '@utils/firebase-request';
import {authStore} from '@store/stores/auth-store';
import {
  setFirebaseData,
  updateFirebaseData,
  writeFirebaseData,
} from '@utils/firebase-handler';
import {DEFAULT_ACTION_ITEMS_CONFIG} from '../edit-insight/edit-insight-hook';

export const useActionItemInsightHook = () => {
  const [actionItemInsights] = useState<any>([]);
  const [isUpdateNeeded, setIsUpdateNeeded] = useState(false);

  useEffect(() => {
    const fetchAndUpdateActionItems = async () => {
      const actionItemsPath = `${process.env.REACT_APP_DEV_ENV}/${authStore.auth.user.workspace.id}/action_items/`;

      const databaseRef = ref(db, actionItemsPath);

      try {
        get(databaseRef).then((snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            if (!data) {
            }

            for (let key in data) {
              const actionItem = data[key];

              if (actionItem.completed && !actionItem.completedAt) {
                updateFirebaseData(`action_items/${key}`, {
                  ...actionItem,
                  completedAt: dayjs().format(),
                });
              }
            }

            setFirebaseData(
              `users/${authStore.auth.user.id}/sync-data/actionItems`,
              {
                completedAt: true,
              },
            );
          } else {
          }
        });
      } catch (error) {}
    };

    if (isUpdateNeeded) {
      fetchAndUpdateActionItems();
    }
  }, [isUpdateNeeded]);

  useEffect(() => {
    const db = getDatabase();
    const actionItemsRef = ref(
      db,
      `${FIREBASE_BASE_URL}/${authStore.auth.user.workspace.id}/users/${authStore.auth.user.id}/sync-data/actionItems`,
    );

    get(actionItemsRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();

          if (!data.completedAt) {
            setIsUpdateNeeded(true);
          }
        } else {
          setIsUpdateNeeded(true);
        }
      })
      .catch((error) => {
        console.error('Error fetching action item ', error);
      });
  }, []);

  const createActionItemsInsights = () => {
    const commonFields = {
      createdBy: '',
      updatedAt: dayjs().format(),
      createdAt: dayjs().format(),
      updatedBy: '',
      config: DEFAULT_ACTION_ITEMS_CONFIG,
      type: 'action-items',
    };

    const actionItems = [
      {
        name: 'Action items completed',
        id: 'action-items-completed',
        ...commonFields,
      },
      {
        name: 'Action items completion rate',
        id: 'action-items-completion-rate',
        ...commonFields,
      },
      {
        name: 'Action items distribution',
        id: 'action-items-distribution',
        ...commonFields,
      },
      {
        name: 'Action items completion per individual',
        id: 'action-items-completion-per-individual',
        ...commonFields,
      },
    ];

    actionItems.forEach((item) => {
      writeFirebaseData('insights', item);
    });
  };

  useEffect(() => {
    const db = getDatabase();
    const actionItemsRef = ref(
      db,
      `${FIREBASE_BASE_URL}/${authStore.auth.user.workspace.id}/insights`,
    );

    get(actionItemsRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();

          const insights = Object.keys(data).map((insight) => ({
            ...data[insight],
          }));

          const isActionItemsIncluded = insights.some(
            (insight) => insight.type === 'action-items',
          );

          if (!isActionItemsIncluded) {
            createActionItemsInsights();
          }
        } else {
          createActionItemsInsights();
        }
      })
      .catch((error) => {
        console.error('Error fetching action item insights:', error);
      });
  }, []);

  return {actionItemInsights};
};
