import {Dispatch, FC, SetStateAction} from 'react';
import {StackViewBox} from '@ui/atoms/stack-view/stack-view-box';

import {StackViewGoal} from '@ui/organisms/stack-view/stack-view';

interface IContainer {
  goal: StackViewGoal;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<any>>;
  index: string;
  allGoals: StackViewGoal[];
}

export const StackViewContainer: FC<IContainer> = ({
  goal,
  allGoals,
  isOpen,
  setIsOpen,
}) => {
  return (
    <div>
      <StackViewBox
        level={0}
        goal={goal}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        type="parent"
        allGoals={allGoals}
        parentGoalId={goal?.id}
      />
    </div>
  );
};
