import {ObjSummary} from '@pages/dashboard/reporting/reporting-pages/reporting-overview/obj-summary';
import {AnalyticsSection} from '@ui/molecules/home-analytics-section';
import {AnalyticsSectionNavProps} from '@ui/molecules/home-analytics-section/analytics-section';
import {capitalize} from '@utils';
import {cfv} from '@utils/framework';
import {FC, memo} from 'react';
import styled from 'styled-components';

export const FeedbackCard = styled.div`
  padding: 8px 16px;
  gap: 16px;

  width: 283px;
  display: flex;
  margin-top: 16px;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ededf2;
  border-radius: 10px;
  .number {
    background: #ededf2;
    border-radius: 8px;
    padding: 6px;
  }
`;

interface GoalAnalyticsProps extends AnalyticsSectionNavProps {
  summary: {
    name: string;
    progress: number;
    percentageIncrease?: number;
    useInverse?: boolean;
  }[];
}

export const GoalsAnalytics: FC<GoalAnalyticsProps> = memo(
  ({summary, activeTab, ...rest}) => {
    return (
      <AnalyticsSection title={capitalize(cfv().g_oals)} {...rest}>
        <div className="w-full">
          <ObjSummary
            showGraph={activeTab !== 'you'}
            data={summary}
            state={['on_track', 'no_status', 'at_risk', 'behind']}
          />
        </div>
      </AnalyticsSection>
    );
  },
);
