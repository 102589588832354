import React from 'react';

export const RemindIcon = () => {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.43773 2.5C5.31273 3.625 2.83774 5.65 1.93773 4.75C-0.0310299 2.78124 4.56273 2.875 6.43773 2.5ZM6.43773 2.5C8.03148 2.5 13.1877 1.9375 11.219 3.90625C9.81273 5.3125 8.03148 3.4375 6.43773 2.5ZM5.21622 12.5285L2.21898 9.53125C1.75299 9.06526 1.75299 8.30974 2.21898 7.84375C2.68497 7.37776 3.44049 7.37776 3.90648 7.84375L4.93588 8.87315C5.07495 9.01222 5.31273 8.91373 5.31273 8.71706V1.9375C5.31273 1.31618 5.81641 0.8125 6.43773 0.8125C7.05905 0.8125 7.56273 1.31618 7.56273 1.9375V6.43588C7.56273 6.62294 7.79828 6.70556 7.91513 6.5595C8.31121 6.06439 9.06424 6.06439 9.46033 6.5595L9.68388 6.83894C9.75904 6.93288 9.88961 6.96156 9.99721 6.90776L10.3721 6.72033C10.7282 6.54228 11.1473 6.54228 11.5034 6.72033C11.8462 6.89173 12.0627 7.2421 12.0627 7.62537V10.9375C12.0627 12.1801 11.0554 13.1875 9.81273 13.1875H6.80721C6.21047 13.1875 5.63818 12.9504 5.21622 12.5285Z"
        stroke="#5F5F8C"
        stroke-width="1.3"
        stroke-linecap="round"
      />
    </svg>
  );
};
