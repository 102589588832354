import {useState, useCallback, useEffect, useMemo} from 'react';
import {GoalsController} from '../../../../../api/goals-api/goals-controller';
import {GoalsRequestImpl} from '../../../../../api/goals-api/goals-request';
import {useStoreContext} from '../../../../../store/store-context';
import {useQuery} from 'react-query';
import {useSearchParams} from 'react-router-dom';
import {useDirectOptionHook} from '../../../reporting/reporting-pages/reporting-overview/hooks/direct-options.hook';
import {CompanyCheckinsController} from './company-checkins.controller';
import {CompanyCheckinsRequestImpl} from './company-checkins.request';

export function useCompanyCheckinsHook(directReports?: boolean) {
  const [loader] = useState(false);

  const checkinsRequest = useMemo(() => new CompanyCheckinsRequestImpl(), []);
  const checkinsController = useMemo(
    () => new CompanyCheckinsController(checkinsRequest),
    [checkinsRequest],
  );
  // 01. Fetch Objectives
  const [isFetching, setIsFetching] = useState(false);
  const {
    storeYouGoals: {fetchedGoals, replaceFetchedGoals},
    groupStore: {groups},
    checkinsStore: {
      setQuestions,
      setUsersCheckins,
      userFilter,
      setSubmittedUserCheckins,
      setNonSubmittedUserCheckins,
      updateUserFilter,
      modifyUsersCheckinsPaginator,
      setCalendarWeeksDirectReports,
      setCalendarWeeks,
    },
  } = useStoreContext();
  const [searchParams] = useSearchParams();

  const weekQuery = searchParams.get('week');
  const yearQuery = searchParams.get('year');

  const {directReportsTeamOption} = useDirectOptionHook();

  useEffect(() => {
    if (!weekQuery && !yearQuery && directReportsTeamOption.length > 0) {
      updateUserFilter({
        selectedTeam: directReportsTeamOption[0],
      });
    }
  }, [weekQuery, yearQuery, directReportsTeamOption, updateUserFilter]);

  useEffect(() => {
    if (
      !userFilter.selectedTeam.value &&
      userFilter.filterBy.value !== 'people' &&
      directReportsTeamOption.length > 0
    ) {
      updateUserFilter({
        selectedTeam: directReportsTeamOption[0],
      });
    }
  }, [
    directReportsTeamOption,
    updateUserFilter,
    userFilter.filterBy.value,
    userFilter.selectedTeam.value,
  ]);

  const [clearSelection, setClearSelection] = useState(false);
  const initGoals = useCallback(
    async (page: number, filter?: string, load?: boolean) => {
      const request = new GoalsRequestImpl();
      const controller = new GoalsController(request);

      load && setIsFetching(true);
      const response = await controller.fetchGoals(page, filter);
      const includeGroups = async (goalsResponse: any) => {
        const groupsIncluded = await Promise.all(
          goalsResponse?.results &&
            goalsResponse?.results?.map(async (goal: any) => {
              if (goal.goalType === 'group') {
                const group = groups.filter((group) =>
                  goal.group.includes(group.id),
                );

                return {
                  ...goal,
                  group,
                };
              } else
                return {
                  ...goal,
                };
            }),
        );
        return groupsIncluded;
      };
      const unfurledData = await includeGroups(response);
      const results = response.results;
      response && replaceFetchedGoals(results);
      response && setIsFetching(false);
      response && replaceFetchedGoals(unfurledData);
    },
    [groups, replaceFetchedGoals],
  );
  useEffect(() => {
    initGoals(1, 'active', true);
  }, [initGoals]);
  // Fetch Objectives End

  // 02. Fetch Questions
  const getCheckinsQuestions = useCallback(
    async (filter) => {
      const response = await checkinsController.getCheckinsQuestions(filter);
      response && setQuestions(response);
    },
    [checkinsController, setQuestions],
  );

  // 03. Fetch List of Checkins
  const fetchListOfCheckins = useCallback(
    async (filter?: {
      weekOfYear?: number;
      year?: number;
      isActive?: boolean;
      isSubmitted?: boolean;
      isReviewed?: boolean;
      reviewer?: string;
      manager?: string;
      user?: string;
      page?: number;
    }) => {
      const response = await checkinsController.getListOfCheckins(filter);

      setUsersCheckins(response);
      if (filter?.isSubmitted) {
        setSubmittedUserCheckins(response);
      }
      if (!filter?.isSubmitted) {
        setNonSubmittedUserCheckins(response);
      }
      return response;
    },
    [
      checkinsController,
      setUsersCheckins,
      setNonSubmittedUserCheckins,
      setSubmittedUserCheckins,
    ],
  );
  // 03. Fetch List of Checkins
  const fetchListOfSubmittedCheckins = useCallback(
    async (filter?: {
      weekOfYear?: number;
      year?: number;
      isActive?: boolean;
      isSubmitted?: boolean;
      isReviewed?: boolean;
      reviewer?: string;
      manager?: string;
      user?: string;
      page?: number;
    }) => {
      const response = await checkinsController.getListOfCheckins(filter);

      if (filter?.isSubmitted) {
        setSubmittedUserCheckins(response);
      }

      return response;
    },
    [checkinsController, setSubmittedUserCheckins],
  );
  // 03. Fetch List of Checkins
  const fetchListOfNonSubmittedCheckins = useCallback(
    async (filter?: {
      weekOfYear?: number;
      year?: number;
      isActive?: boolean;
      isSubmitted?: boolean;
      isReviewed?: boolean;
      reviewer?: string;
      manager?: string;
      user?: string;
      page?: number;
    }) => {
      const response = await checkinsController.getListOfCheckins(filter);

      if (!filter?.isSubmitted) {
        setNonSubmittedUserCheckins(response);
      }

      return response;
    },
    [checkinsController, setNonSubmittedUserCheckins],
  );

  // Fetch Questions End

  ///
  const clearPagination = useCallback(() => {
    modifyUsersCheckinsPaginator('limit', 10);
    modifyUsersCheckinsPaginator('page', 1);
    modifyUsersCheckinsPaginator('totalPages', 1);
    modifyUsersCheckinsPaginator('totalResults', 0);
  }, [modifyUsersCheckinsPaginator]);

  const initCalendarWeeks = useCallback(
    async (filter?: {manager?: string; user?: string}) => {
      const response = await checkinsController.getCheckinsCalendarWeekList(
        filter,
      );
      return response;
    },
    [checkinsController],
  );

  const filter = useMemo(() => {
    return {
      manager:
        userFilter?.members?.length > 0
          ? undefined
          : userFilter?.selectedTeam.value,
      user:
        userFilter?.members?.length > 0
          ? userFilter?.members
              .map((member: {label: string; value: string}) => member.value)
              .join(',')
          : undefined,
    };
  }, [userFilter?.selectedTeam.value, userFilter?.members]);

  const {isLoading: loading, data} = useQuery(
    ['initCalendarWeeksCheckins', filter],
    () => initCalendarWeeks(filter),
  );

  useMemo(() => {
    if (data) {
      !directReports && setCalendarWeeks(data as any);

      directReports && setCalendarWeeksDirectReports(data as any);
    }
  }, [data, directReports, setCalendarWeeks, setCalendarWeeksDirectReports]);

  return {
    fetchedGoals,
    isFetching,
    userFilter,
    updateUserFilter,
    clearSelection,
    setClearSelection,
    fetchListOfNonSubmittedCheckins,
    fetchListOfSubmittedCheckins,
    loading,
    getCheckinsQuestions,
    loader,
    fetchListOfCheckins,
    clearPagination,
  };
}
