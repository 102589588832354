import {useFirebaseFetch} from '@hooks/query-hook';
import {updateFirebaseData} from '@utils/firebase-handler';
import {useMemo} from 'react';

export const useDashboardHook = () => {
  const {data, isLoading} = useFirebaseFetch('dashboard');

  const {data: insights = {}, isLoading: insightsFetching} = useFirebaseFetch(
    'insights',
  );

  const computeData = useMemo(() => {
    if (!data) return [];

    return Object.entries(data)
      .map((section: any) => {
        const dashboardInsights = insights
          ? Object.entries(insights)
              .filter((insight: any) => insight[1].section === section[1].slug)
              ?.map((insight) => insight[1])
          : [];

        return {
          firebaseId: section[0],
          ...section[1],
          canDelete: !dashboardInsights.length && section[1].slug !== 'default',
          widget: dashboardInsights.length
            ? dashboardInsights.sort(
                (a: any, b: any) => a.orderIndex - b.orderIndex,
              )
            : [],
        };
      })
      .sort((a, b) => a.order - b.order);
  }, [data, insights]);

  const allSections = computeData;

  const updateOrder = async (order: number, firebaseId: string) => {
    const dashboard = data[firebaseId];
    const oldOrder = dashboard?.order;

    const allSections = Object.keys(data).map((key) => ({
      ...data[key],
      firebaseId: key,
    }));

    const _order =
      order > computeData.length ? order - 1 : order <= 1 ? 1 : order;

    const updatedData = allSections.map((item) => {
      if (item.firebaseId === firebaseId) {
        return {...item, order: _order};
      } else if (
        oldOrder < _order &&
        item.order > oldOrder &&
        item.order <= _order
      ) {
        return {...item, order: item.order - 1};
      } else if (
        oldOrder > _order &&
        item.order < oldOrder &&
        item.order >= _order
      ) {
        return {...item, order: item.order + 1};
      }
      return item;
    });

    updatedData.sort((a, b) => a.order - b.order);

    updatedData.forEach(async (data, index) => {
      await updateFirebaseData(`dashboard/${data.firebaseId}`, {
        ...data,
      });
    });
  };
  return {
    updateFirebaseData,
    isLoading: isLoading || insightsFetching,
    data,
    updateOrder,
    allSections,
    insights,
  };
};
