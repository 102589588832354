import React from 'react';

export const DotIcon = () => {
  return (
    <svg
      width="13"
      height="6"
      viewBox="0 0 13 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.4987 5.66683C10.9715 5.66683 12.1654 4.47292 12.1654 3.00016C12.1654 1.5274 10.9715 0.333496 9.4987 0.333496C8.02594 0.333496 6.83203 1.5274 6.83203 3.00016C6.83203 4.47292 8.02594 5.66683 9.4987 5.66683Z"
        fill="#BFBFD4"
      />
      <path
        d="M4.83333 3.00016C4.83333 4.19678 3.86328 5.16683 2.66667 5.16683C1.47005 5.16683 0.5 4.19678 0.5 3.00016C0.5 1.80355 1.47005 0.833496 2.66667 0.833496C3.86328 0.833496 4.83333 1.80355 4.83333 3.00016Z"
        stroke="#BFBFD4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
