import {IMeetingResponse} from '@hooks';
import {useActionItems} from '@hooks/features-action-items';
import {useFirebaseFetch} from '@hooks/query-hook';
import {MeetingController} from '@pages/1:1s/meeting-controller';
import {MeetingRequestImpl} from '@pages/1:1s/meeting.request';
import {ActionItemsSortByFilter} from '@pages/dashboard/action-items/filter';
import {ViewActionItemsSource} from '@pages/dashboard/action-items/view-source';
import {useStoreContext} from '@store/store-context';
import {authStore} from '@store/stores/auth-store';
import {Body2} from '@ui/atoms/typography';
import {SectionCard} from '@ui/layouts/section-card';
import {ActionItems} from '@ui/organisms/action-items/action-items';
import {FlexRowSpaceBetween} from '@ui/style/styles';
import {userName} from '@utils/user-name';
import {useCallback, useMemo, useState} from 'react';
import {useQuery} from 'react-query';

interface MeetingTabsProps {
  userId: string | string[];
  meetings: IMeetingResponse[];
}

export const ActionItemsTab = ({userId, meetings}: MeetingTabsProps) => {
  const {
    usersStore: {users},
    authStore: {auth},
  } = useStoreContext();

  const [sortBy, setSortBy] = useState('createdAt');

  const {handleChange, tasks} = useActionItems({
    userId: '',
    itemId: '',
    source: 'meeting',
    orderBy: {
      key: 'source',
      value: 'meeting',
    },
  });

  const meetingsRequest = useMemo(() => new MeetingRequestImpl(), []);

  const meetingsController = useMemo(
    () => new MeetingController(meetingsRequest),
    [meetingsRequest],
  );

  const findUser = useCallback(
    (id: string) => users.find((user) => user.id === id),
    [users],
  );

  const userIds = Array.isArray(userId) ? userId : [userId];

  const meetingIds = meetings.map((meeting) => meeting.id);

  const computedTasks = tasks.filter(
    (task) =>
      meetingIds.includes(task.sourceId) &&
      (userIds.includes(task.user) ||
        userIds.includes(task.assignee?.id || task.assignee)),
  );

  const sources = useMemo(() => new Map(), []);

  const handleSource = useCallback(async () => {
    const updatedTasks = [...computedTasks]; // Create a copy of the tasks array

    const allTasks = await Promise.all(
      updatedTasks.map(async (task, index) => {
        if (task.source === 'meeting' && !!task.sourceId) {
          let meeting =
            meetings?.find((m) => m.id === task.sourceId) ||
            (await meetingsController.getMeeting(
              task.sourceId,
              undefined,
              true,
            ));

          const participant =
            typeof meeting.participant[0] === 'string'
              ? findUser(meeting.participant[0])
              : meeting.participant[0];

          const sourceTitle = meeting
            ? meeting.title || `1:1 meeting with ${userName(participant) || ''}`
            : 'Deleted meeting';

          updatedTasks[index].sourceTitle = sourceTitle;

          updatedTasks[index].isSourceDeleted = !meeting;

          sources.set(task.sourceId, {
            title: meeting
              ? meeting.title || `1:1 meeting with ${userName(participant)}`
              : 'Deleted meeting',

            isDeleted: !meeting,

            meeting: {
              ...meeting,
              user: meeting?.user ? findUser(meeting.user) : null,
              participant: meeting?.participant ? participant : null,
            },
          });

          return {...task, sourceTitle};
        }
        return task;
      }),
    );

    return allTasks;
  }, [computedTasks, meetings, meetingsController, findUser, sources]);

  const getSourceDetail = (sourceId: string) => {
    return sources.get(sourceId);
  };

  const [viewSource, setViewSource] = useState({
    id: '',
    source: '',
  });

  const sourceItem = !!viewSource.source
    ? viewSource.source === 'goal'
      ? (getSourceDetail(viewSource.id) as any)?.goal
      : (getSourceDetail(viewSource.id) as any)?.meeting
    : undefined;

  const {data: allTasks = []} = useQuery(['sources', computedTasks], () =>
    handleSource(),
  );

  const filterPath = `users/${auth.user.id}/action_items/filter`;

  const {data: actionItemsFilterPath} = useFirebaseFetch(filterPath);

  const isCustomFilter = actionItemsFilterPath === 'custom';

  const actionItems = allTasks.length
    ? allTasks.sort((a: any, b: any) => {
        if (isCustomFilter) {
          return a.order - b.order;
        }
        // Handle cases where dueDate is missing or invalid
        const aDate = a?.[sortBy]
          ? new Date(a[sortBy])
          : new Date(8640000000000000); // Largest possible date

        const bDate = b?.[sortBy]
          ? new Date(b[sortBy])
          : new Date(8640000000000000); // Largest possible date

        // Sort in ascending order
        return aDate.getTime() - bDate.getTime();
      })
    : [
        {
          text: '',
          completed: '',
        },
      ];

  return (
    <div>
      <SectionCard
        contentStyling={{padding: '24px'}}
        CustomHeaderTitle={
          <FlexRowSpaceBetween>
            <Body2 weight="bold">Summary ({actionItems?.length || 0})</Body2>

            <ActionItemsSortByFilter
              sortBy={sortBy}
              updateSortBy={setSortBy}
              isCustomFilter={isCustomFilter}
            />
          </FlexRowSpaceBetween>
        }>
        <ActionItems
          showSource
          handleChange={(data, actionType, fieldId, item) =>
            handleChange(
              data,
              actionType,
              fieldId,
              item
                ? {...item, user: userId.length > 1 ? auth.user.id : userId}
                : undefined,
            )
          }
          users={users
            .filter((user) => [auth.user.id, userId].includes(user.id))
            .map((user) => ({
              label: userName(user),
              value: user.id,
              avatar: user.avatar,
            }))}
          source="meeting"
          canDelete={(fieldId) => {
            const task = tasks.find((task) => task.key === fieldId);

            if (!!task) {
              // allow admin delete action item
              if (authStore.auth.user.role === 'admin' && !!task) {
                return true;
              }

              // allow manager of task users delete task
              const usersInvolved = users
                .filter((user) =>
                  [task?.user, task?.updatedBy, task?.assignee].includes(
                    user.id,
                  ),
                )
                .map((user) => user.reviewer.id);

              if (usersInvolved.includes(auth.user.id)) {
                return true;
              }
            }
            return false;
          }}
          sourceId={''}
          value={(actionItems as any) || []}
          userId={auth.user.id}
          handleViewSource={(data) =>
            data.source !== 'url' ? setViewSource(data) : null
          }
        />
      </SectionCard>
      {!!viewSource.id && (
        <ViewActionItemsSource
          userName={userName(auth.user)}
          onClose={() => setViewSource({id: '', source: ''})}
          type={viewSource.source}
          goal={sourceItem}
          meeting={sourceItem}
        />
      )}
    </div>
  );
};
