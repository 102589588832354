import styled from 'styled-components';

export const PlainButton = styled.button`
  border: 0;
  background-color: transparent;
  padding: 0;
  &:disabled {
    cursor: not-allowed;
  }
`;
