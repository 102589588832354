import {down, up} from 'styled-breakpoints';
import styled from 'styled-components';

import {getColorFromTheme} from '@ui/ui-utils';

export const OuterWrapper = styled.form``;

export const DelimitingWrapper = styled.div``;

export const TagWrapper = styled.div`
  padding: 4px 8px;
  border-radius: 8px;
  background: #f6f6f8;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #5f5f8c;
  display: flex;
  align-items: center;
  svg {
    width: 14px;
    height: 14px;
    margin-left: 6px;
    path {
      fill: black;
    }
  }
`;
export const ButtonWrapper = styled.div``;

export const Wrapper = styled.div`
  ${down('sm')} {
    margin-bottom: 10px;
  }

  ${up('sm')} {
    margin-bottom: 20px;
  }

  ${up('lg')} {
    margin-bottom: 40px;
  }

  ${up('xl')} {
    margin-bottom: 40px;
  }
`;

export const HorizontalHr = styled.div`
  border: 1px solid #ededf2;
  opacity: 0.5;
`;
export const MoodDiv = styled.div<{
  activated?: boolean;
  disabled?: boolean;
  error?: boolean;
}>`
  border: 1px solid ${getColorFromTheme('borderLight')};
  border-radius: 10px;
  div.titleDiv {
    padding: 18px 24px 16px;
  }
  div.contentDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 24px 2px;
    border-radius: 10px 10px 0px 0px;
    
    border-top: 1px solid ${getColorFromTheme('borderLight')};
    border: ${({error}) => (error ? '1px solid #D52A2A' : undefined)};
    border-bottom-right-radius: ${({error}) => (error ? '10px' : undefined)};
       border-bottom-left-radius: ${({error}) => (error ? '10px' : undefined)};
 
    ${down('sm')} {
      padding: 24px 30px;

      }
    .inner-content {
      width: 65%;
      ${down('lg')} {
        width: 75%;
      }
      ${down('md')} {
        width: 100%;
      }
      display: flex;
      justify-content: space-between;
      align-items: center;

      .emoji {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
       
        .halo {
          padding: 4px;
           border-radius: 50%;
      
         
          &:hover {
            background: ${({disabled}) =>
              disabled ? '' : getColorFromTheme('purple300')};
          
          }
         
          .img {
            width: 56px;
          height: 56px;
          ${down('sm')} {
         width: 46px;
          height: 46px;
      }
            img {
          width: 56px;
          height: 56px;
           ${down('sm')} {
         width: 46px;
          height: 46px;
      }
        }
          }
        }
      }
    }
    .second-inner-content {
      width: 58.8%;
      ${down('md')} {
        width: 100%;
      }
      }
    }
  }
  div.footerDiv {
    display: flex;
    align-items: center;
    padding: 12px 40px;
    border-radius: 10px 10px 0px 0px;
    border-top: 1px solid ${getColorFromTheme('borderLight')};
  }
`;

export const ProgressDiv = styled.div`
  border: 1px solid ${getColorFromTheme('borderLight')};
  border-radius: 10px;
  div.titleDiv {
    padding: 18px 24px 16px;
  }
  div.contentDiv {
    padding: 8px 8px 0 8px;
    border-radius: 10px 10px 0px 0px;
    border-top: 1px solid ${getColorFromTheme('borderLight')};
  }
`;

export const PrioritiesDiv = styled.div`
  border: 1px solid ${getColorFromTheme('borderLight')};
  border-radius: 10px;
  div.titleDiv {
    padding: 18px 24px 16px;
  }
  div.contentDiv {
    padding: 16px 8px 8px 0px;
    border-radius: 10px 10px 0px 0px;
    border-top: 1px solid ${getColorFromTheme('borderLight')};
  }
`;

export const WorkLifeDiv = styled.div`
  border: 1px solid ${getColorFromTheme('borderLight')};
  border-radius: 10px;
  div.headerDiv {
    padding: 18px 24px 16px;
  }
  div.titleDiv {
    display: flex;
    justify-content: space-between;
  }
  div.contentDiv {
    padding: 24px;
    border-radius: 10px 10px 0px 0px;
    border-top: 1px solid ${getColorFromTheme('borderLight')};
  }
`;

export const FeedbackDiv = styled.div`
  border: 1px solid ${getColorFromTheme('borderLight')};
  border-radius: 10px;
  div.titleDiv {
    padding: 18px 24px 16px;
  }
  div.contentDiv {
    padding: 24px;
    border-radius: 10px 10px 0px 0px;
    border-top: 1px solid ${getColorFromTheme('borderLight')};
  }
`;
export const ItemLoaderWrapper = styled.div`
  padding: 28px 12px 32px 22px;
  justify-content: center;
  width: 100%;
`;

export const ReviewerBox = styled.div`
  border: 1px solid ${getColorFromTheme('borderLight')};
  border-radius: 10px;
  .title-div {
    padding: 16px 16px 8px;
  }
  .content-div {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-top: 1px solid ${getColorFromTheme('borderLight')};
    padding-top: 32px;
    padding-bottom: 56px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
`;
export const EmptyPlaceholderWrapper = styled.div`
  border: 1px solid #ededf2;
  margin-left: 20px;
  width: 98%;
  height: 600px;
  border-radius: 10px;
`;
export const ReviewerBoxMobile = styled.div`
  border: 1px solid ${getColorFromTheme('borderLight')};
  border-radius: 10px;
  display: grid;
  padding: 8px;
  grid-template-columns: 40% 60%;
  align-items: center;
  .title {
    display: flex;
    align-items: center;
  }
  .content-div {
    display: flex;

    align-items: center;
    justify-content: center;
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const MobileWrapper = styled.div`
  display: none;
  ${down('md')} {
    display: block;
  }
`;

export const EmptyDiv = styled.div`
  border: 1px solid #ededf2;
  border-radius: 10px;
`;
export const CommentNotif = styled.div`
  position: relative;

  .dot {
    position: absolute;
    bottom: 16px;
    right: -5%;
  }
`;
