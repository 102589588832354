import {getFromBetween} from './get-between';

export const getStrBtwCharacters = (str: string, start: string, end: string) =>
  str.substring(str.indexOf(start) + 1, str.lastIndexOf(end));
export const stringedObjectParser = (str: string) => {
  var jsonStrig = '{';
  var items = str.split(',');
  for (var i = 0; i < items.length; i++) {
    var current = items[i].split(':');
    jsonStrig += '"' + current[0] + '":"' + current[1] + '",';
  }
  jsonStrig = jsonStrig.substr(0, jsonStrig.length - 1);
  jsonStrig += '}';
  var obj = JSON.parse(jsonStrig);
  return obj;
};
export function toJSONString(str: string) {
  str = str.replace(/,(?=(\]|\})$)/gimu, '');
  str = str.replace(/'/gimu, '');

  [...new Set(str.match(/[a-z]+/gimu))].forEach((el) => {
    const reg = new RegExp(`${el}`, 'g');

    const str2 = `"${el}"`;

    str = str.replace(reg, str2);
  });

  return str;
}

export function fixMarkdownBoldFormatting(inputString: string) {
  // Remove spaces before and after asterisks
  let text = inputString;

  const getBoldWords = getFromBetween.get(inputString, '**', '**');

  getBoldWords.forEach((word: string) => {
    text = text.replace(
      // eslint-disable-next-line no-useless-escape
      `**${word}**`,
      ` **${word.trim()}** `,
    );
  });

  return text;
}
