import styled from 'styled-components';
import {OptionsType, ValueType, ActionMeta, Props, Styles} from 'react-select';
import {ReactElement} from 'react';
import {InputState} from '../../../hooks';

import {theme} from '../../style';
import {getColorFromTheme} from '../../ui-utils';

export interface SharedProps<T> extends Props<T> {
  label?: string;
  state?: InputState;
  helper?: string | ReactElement;
  reportingSearch?: boolean;
  options: OptionsType<T>;
  onChange?: (value: ValueType<T, false>, action: ActionMeta<T>) => void;
}

export const getStyles = <T, V extends boolean>(): Partial<Styles<T, V>> => ({
  valueContainer: (base) => ({
    ...base,
    color: theme.colors.textBody,
    width: '100%',
    paddingLeft: '14px',
    paddingRight: '14px',
  }),

  indicatorsContainer: (base) => ({
    ...base,
    paddingLeft: '2px',
    paddingRight: '2px',
  }),
  menu: (base) => ({
    ...base,
    marginTop: '16px',
    marginBottom: '16px',
    border: '1px solid',
    borderRadius: 7,
    borderColor: theme.colors.borderDark,
    boxShadow: 'none',
    zIndex: 11,
  }),
  menuList: (base) => ({
    ...base,
    padding: '8px 6px',
  }),
  option: (base, state) => {
    let backgroundColor = theme.colors.white;
    if (state.isFocused) {
      backgroundColor = theme.colors.backgroundLight;
    }
    if (state.isSelected) {
      backgroundColor = theme.colors.backgroundDark;
    }
    return {
      ...base,
      fontSize: '16px',
      borderRadius: 6,
      color: theme.colors.textDark,
      backgroundColor,
      cursor: 'pointer',
      ':active': {
        backgroundColor: theme.colors.backgroundLight,
      },
    };
  },
  control: (base, state) => {
    return {
      ...base,
      color: theme.colors.textDark,
      backgroundColor: theme.colors.white,
      transition: '200ms ease all',

      borderColor: theme.colors.borderDark,
      boxShadow: 'none',
      cursor: 'pointer',
      '&:hover': state.isFocused
        ? {
            borderColor: theme.colors.purple300,
            boxShadow: `0 0 0 4px ${theme.colors.purple200}`,
          }
        : {
            borderColor: theme.colors.purple200,
            boxShadow: `0 0 0 2px ${theme.colors.purple200}`,
          },
      ...(state.isFocused
        ? {
            borderColor: theme.colors.purple300,
            boxShadow: `0 0 0 4px ${theme.colors.purple200}`,
          }
        : {}),
      '&:disabled': state.isDisabled
        ? {
            backgroundColor: theme.colors.backgroundDark,
          }
        : {
            backgroundColor: theme.colors.white,
            cursor: 'pointer',
          },
      ...(state.isDisabled
        ? {
            backgroundColor: theme.colors.backgroundDark,
          }
        : {}),
    };
  },
});
export const getStylesMobile = <T, V extends boolean>(): Partial<
  Styles<T, V>
> => ({
  valueContainer: (base) => ({
    ...base,
    color: theme.colors.textBody,
    paddingLeft: '14px',
  }),

  indicatorsContainer: (base) => ({
    ...base,
    paddingLeft: '2px',
    paddingRight: '2px',
  }),
  menu: (base) => ({
    ...base,
    marginTop: '16px',
    marginBottom: '16px',
    border: '1px solid',
    borderRadius: 7,
    borderColor: theme.colors.borderDark,
    boxShadow: 'none',
    zIndex: 11,
  }),
  menuList: (base) => ({
    ...base,
    padding: '8px 6px',
  }),
  option: (base, state) => {
    let backgroundColor = theme.colors.white;
    if (state.isFocused) {
      backgroundColor = theme.colors.backgroundLight;
    }
    if (state.isSelected) {
      backgroundColor = theme.colors.backgroundDark;
    }
    return {
      ...base,
      fontSize: '16px',
      borderRadius: 6,
      color: theme.colors.textDark,
      backgroundColor,
      cursor: 'pointer',
      ':active': {
        backgroundColor: theme.colors.backgroundLight,
      },
    };
  },
  control: (base, state) => {
    return {
      ...base,
      color: theme.colors.textDark,
      backgroundColor: theme.colors.white,
      transition: '200ms ease all',
      maxWidth: '133px',

      borderColor: theme.colors.borderDark,
      boxShadow: 'none',
      cursor: 'pointer',
      '&:hover': state.isFocused
        ? {
            borderColor: theme.colors.purple300,
            boxShadow: `0 0 0 4px ${theme.colors.purple200}`,
          }
        : {
            borderColor: theme.colors.purple200,
            boxShadow: `0 0 0 2px ${theme.colors.purple200}`,
          },
      ...(state.isFocused
        ? {
            borderColor: theme.colors.purple300,
            boxShadow: `0 0 0 4px ${theme.colors.purple200}`,
          }
        : {}),
      '&:disabled': state.isDisabled
        ? {
            backgroundColor: theme.colors.backgroundDark,
          }
        : {
            backgroundColor: theme.colors.white,
            cursor: 'pointer',
          },
      ...(state.isDisabled
        ? {
            backgroundColor: theme.colors.backgroundDark,
          }
        : {}),
    };
  },
});

export const getAnalyticsStyles = <T, V extends boolean>(properties?: {
  control?: {
    height?: string;
    paddingRight?: string;
  };
}): Partial<Styles<T, V>> => ({
  valueContainer: (base) => ({
    ...base,
    color: theme.colors.textBody,
    width: '100%',
    padding: '10px 0 10px 12px',
  }),
  indicatorsContainer: (base) => ({
    ...base,
    paddingLeft: '2px',
    paddingRight: '2px',
  }),
  menu: (base) => ({
    ...base,
    marginTop: '16px',
    marginBottom: '16px',
    border: '1px solid',
    borderRadius: 7,
    borderColor: theme.colors.borderDark,
    boxShadow: 'none',
    zIndex: 11,
    width: '170px',
  }),
  menuList: (base) => ({
    ...base,
    padding: '8px 6px',
    width: '100%',
  }),
  option: (base, state) => {
    let backgroundColor = theme.colors.white;
    if (state.isFocused) {
      backgroundColor = theme.colors.backgroundLight;
    }
    if (state.isSelected) {
      backgroundColor = theme.colors.backgroundDark;
    }
    return {
      ...base,
      fontSize: '16px',
      borderRadius: 6,
      color: theme.colors.textDark,
      backgroundColor,
      cursor: 'pointer',
      width: '100%',
      ':active': {
        backgroundColor: theme.colors.backgroundLight,
      },
    };
  },
  control: (state) => {
    return {
      display: 'flex',
      backgroundColor: theme.colors.backgroundDark,
      borderRadius: '7px 0px 0px 7px',
      borderStyle: 'solid',
      borderWidth: '1px 1px 1px 1px',
      color: theme.colors.textDark,
      transition: '200ms ease all',
      borderColor: theme.colors.borderDark,
      boxShadow: 'none',
      cursor: 'pointer',
      paddingRight: properties?.control?.paddingRight
        ? properties?.control?.paddingRight
        : undefined,
      height: properties?.control?.height
        ? properties?.control?.height
        : '46px',
      '&:disabled': state.isDisabled
        ? {
            backgroundColor: theme.colors.backgroundDark,
          }
        : {
            backgroundColor: theme.colors.white,
            cursor: 'pointer',
          },
      ...(state.isDisabled
        ? {
            backgroundColor: theme.colors.backgroundDark,
          }
        : {}),
    };
  },
});
export const getPhoneStyles = <T, V extends boolean>(properties?: {
  control?: {
    height?: string;
    paddingRight?: string;
  };
}): Partial<Styles<T, V>> => ({
  valueContainer: (base) => ({
    ...base,
    color: theme.colors.textBody,
    width: '100%',
    padding: '10px 0 10px 12px',
  }),
  indicatorsContainer: (base) => ({
    ...base,
    paddingLeft: '2px',
    paddingRight: '2px',
  }),
  menu: (base) => ({
    ...base,
    marginTop: '16px',
    marginBottom: '16px',
    border: '1px solid',
    borderRadius: 7,
    borderColor: theme.colors.borderDark,
    boxShadow: 'none',
    zIndex: 11,
    width: '430px',
  }),
  menuList: (base) => ({
    ...base,
    padding: '8px 6px',
    width: '100%',
  }),
  option: (base, state) => {
    let backgroundColor = theme.colors.white;
    if (state.isFocused) {
      backgroundColor = theme.colors.backgroundLight;
    }
    if (state.isSelected) {
      backgroundColor = theme.colors.backgroundDark;
    }
    return {
      ...base,
      fontSize: '16px',
      borderRadius: 6,
      color: theme.colors.textDark,
      backgroundColor,
      cursor: 'pointer',
      width: '100%',
      ':active': {
        backgroundColor: theme.colors.backgroundLight,
      },
    };
  },
  control: (state) => {
    return {
      display: 'flex',
      backgroundColor: theme.colors.backgroundDark,
      borderRadius: '7px 0px 0px 7px',
      borderStyle: 'solid',
      borderWidth: '1px 1px 1px 1px',
      color: theme.colors.textDark,
      transition: '200ms ease all',
      borderColor: theme.colors.borderDark,
      boxShadow: 'none',
      cursor: 'pointer',
      paddingRight: properties?.control?.paddingRight
        ? properties?.control?.paddingRight
        : undefined,
      height: properties?.control?.height
        ? properties?.control?.height
        : '46px',
      '&:disabled': state.isDisabled
        ? {
            backgroundColor: theme.colors.backgroundDark,
          }
        : {
            backgroundColor: theme.colors.white,
            cursor: 'pointer',
          },
      ...(state.isDisabled
        ? {
            backgroundColor: theme.colors.backgroundDark,
          }
        : {}),
    };
  },
});
export const getAnalyticsStylesVariant4 = <T, V extends boolean>(): Partial<
  Styles<T, V>
> => ({
  valueContainer: (base) => ({
    ...base,
    color: theme.colors.textBody,
    width: '100%',
    padding: '10px 0 10px 12px',
  }),
  indicatorsContainer: (base) => ({
    ...base,
    paddingLeft: '2px',
    paddingRight: '2px',
  }),
  menu: (base) => ({
    ...base,
    marginTop: '16px',
    marginBottom: '16px',
    border: '1px solid',
    borderRadius: 7,
    borderColor: theme.colors.borderDark,
    boxShadow: 'none',
    zIndex: 11,
    width: '170px',
  }),
  menuList: (base) => ({
    ...base,
    padding: '8px 6px',
    width: '100%',
  }),
  option: (base, state) => {
    let backgroundColor = theme.colors.white;
    if (state.isFocused) {
      backgroundColor = theme.colors.backgroundLight;
    }
    if (state.isSelected) {
      backgroundColor = theme.colors.backgroundDark;
    }
    return {
      ...base,
      fontSize: '16px',
      borderRadius: 6,
      color: theme.colors.textDark,
      backgroundColor,
      cursor: 'pointer',
      width: '100%',
      ':active': {
        backgroundColor: theme.colors.backgroundLight,
      },
    };
  },
  control: (state) => {
    return {
      display: 'flex',
      borderRadius: '7px',
      borderStyle: 'solid',
      borderWidth: '1px 1px 1px 1px',
      color: theme.colors.textDark,
      transition: '200ms ease all',
      borderColor: theme.colors.borderDark,
      boxShadow: 'none',
      cursor: 'pointer',
      height: '46px',
      '&:disabled': state.isDisabled
        ? {
            backgroundColor: theme.colors.backgroundDark,
          }
        : {
            backgroundColor: theme.colors.white,
            cursor: 'pointer',
          },
      ...(state.isDisabled
        ? {
            backgroundColor: theme.colors.backgroundDark,
          }
        : {}),
    };
  },
});

export const getCurrencyStyles = <T, V extends boolean>(): Partial<
  Styles<T, V>
> => ({
  valueContainer: (base) => ({
    ...base,
    color: theme.colors.textBody,
    width: '100%',
    paddingLeft: '14px',
    paddingRight: '14px',
  }),
  indicatorsContainer: (base) => ({
    ...base,
    paddingLeft: '2px',
    paddingRight: '2px',
  }),
  menu: (base) => ({
    ...base,
    marginTop: '16px',
    marginBottom: '16px',
    border: '1px solid',
    borderRadius: 7,
    borderColor: theme.colors.borderDark,
    boxShadow: 'none',
    zIndex: 11,
  }),
  menuList: (base) => ({
    ...base,
    padding: '8px 6px',
  }),
  option: (base, state) => {
    let backgroundColor = theme.colors.white;
    if (state.isFocused) {
      backgroundColor = theme.colors.backgroundLight;
    }
    if (state.isSelected) {
      backgroundColor = theme.colors.backgroundDark;
    }
    return {
      ...base,
      fontSize: '16px',
      borderRadius: 6,
      color: theme.colors.textDark,
      backgroundColor,
      cursor: 'pointer',
      ':active': {
        backgroundColor: theme.colors.backgroundLight,
      },
    };
  },
  control: (state) => {
    return {
      display: 'flex',
      color: theme.colors.textDark,
      backgroundColor: theme.colors.white,
      transition: '200ms ease all',
      borderRadius: '0 7px 7px 0',
      borderStyle: 'solid',
      borderWidth: '1px 1px 1px 0',
      borderColor: theme.colors.borderDark,
      cursor: 'pointer',
      height: '52px',
      '&:disabled': state.isDisabled
        ? {
            backgroundColor: theme.colors.backgroundDark,
          }
        : {
            backgroundColor: theme.colors.white,
            cursor: 'pointer',
          },
      ...(state.isDisabled
        ? {
            backgroundColor: theme.colors.backgroundDark,
          }
        : {}),
    };
  },
});

export const Wrapper = styled.div<{hasLabel?: boolean}>`
  position: relative;
  padding-top: ${({hasLabel}) => (hasLabel ? '26px' : '0')};
`;

export const IndicatorWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  svg {
    stroke: ${getColorFromTheme('textBody')};
    width: 20px;
    height: 20px;
  }
`;

export const IndicatorWrapper2 = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  svg {
    width: 22px;
    height: 22px;
    fill: ${getColorFromTheme('textDark')};
  }
`;

export const IndicatorWrapper3 = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  svg {
    stroke: ${getColorFromTheme('textDark')};
  }
`;
