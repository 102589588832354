import {useState, useMemo} from 'react';
import {useStoreContext} from '@store/store-context';
import {useQuery} from 'react-query';
import {activateNotification} from '@ui/molecules/notification/activate-notification';
import {IntegrationsController} from '../components/app.controller';
import {useCallback} from 'react';
import {useSearchParams} from 'react-router-dom';
import {capitalize} from '@utils';

export const useAppHook = () => {
  const [search, setSearch] = useState('');
  const [activatingApp, setActivatingApp] = useState(false);

  const {
    integrationStore: {setActiveIntegrations},
  } = useStoreContext();
  const [searchParams] = useSearchParams();

  const handleSearch = (e: string) => {
    setSearch(e);
  };

  const getActiveIntegrations = useCallback(async () => {
    const controller = new IntegrationsController();
    const response = await controller.getIntegrations();

    return response;
  }, []);

  const {data, isLoading, refetch} = useQuery('activeAppsIntegrations', () =>
    getActiveIntegrations(),
  );

  const activateApp = useCallback(
    async (data: {app: string; token: string}, res: any) => {
      const controller = new IntegrationsController();
      setActivatingApp(true);

      const updateData = async (data: any, edit?: boolean) => {
        data &&
          activateNotification({
            title: 'Access granted',
            content: `App has been ${
              edit ? 're-connected' : 'connected'
            } with Beam`,
            kind: 'success',
          });
        data && window.location.replace('/edit/apps');
        data && (await refetch());
      };
      if (res?.length > 0 && res?.find((app: any) => app.app === data.app)) {
        const response = await controller.patchIntegrations(data);
        response && updateData(response, true);
      } else {
        const response = await controller.postIntegrations(data);
        response && updateData(response);
      }
      setActivatingApp(false);
    },
    [refetch],
  );

  useMemo(() => {
    if (data) {
      setActiveIntegrations(data);
      const urlCode = searchParams.get('code');

      const urlApp = searchParams.get('app');

      const pathname = window.location.pathname;

      const segments = pathname.split('/');

      const appName = segments[segments.indexOf('apps') + 1];
      const getAppFromPathname = appName;

      const app = urlApp || getAppFromPathname;

      if (urlCode && app) {
        activateApp(
          {
            app: capitalize(app),
            token: urlCode,
          },
          data,
        );
      }
    }
  }, [data, setActiveIntegrations, activateApp, searchParams]);

  return {
    handleSearch,
    loading: isLoading || activatingApp,
    search,
  };
};
