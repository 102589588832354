import {HelpGuideIcon} from '@ui/atoms/icons';
import {Body2} from '@ui/atoms/typography';
import React from 'react';

interface IUseGuide {
  url: string;
}

export const UseGuide = (props: IUseGuide) => {
  return (
    <button className="cursor-pointer">
      <div
        className="flex items-center gap-1"
        // href={props.url}
        // target="_blank"
        // rel="noreferrer"
      >
        <HelpGuideIcon />
        <Body2 kind="textBody">How to use</Body2>
      </div>
    </button>
  );
};
