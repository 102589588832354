import styled from 'styled-components';
import {down} from 'styled-breakpoints';

export const PageWrapper = styled.div`
  position: relative;
`;

export const FormWrapper = styled.div<{wideWidth: boolean}>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @media (max-width: 768px) {
    padding: 0px 16px;
  }
  .form {
    width: ${({wideWidth}) => (wideWidth ? '70%' : '35%')};
    margin-top: 90px;
    margin-bottom: 30px;
    @media (min-width: 1100px) and (max-width: 1320px) {
      width: ${({wideWidth}) => (wideWidth ? '70%' : '40%')};
    }
    @media (min-width: 768px) and (max-width: 1100px) {
      width: 70%;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;
export const HeaderWrapper = styled.div`
  background: #fafafa;
  position: fixed;
  border-bottom: 1px solid #ededf2;
  height: 68px;
  width: 100%;
  top: 72px;
  display: flex;
  justify-content: center;
  z-index: 3;
  align-items: center;

  button {
    width: 180px;
    overflow: hidden;
    height: 36px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    box-shadow: 0px 3px 24px rgba(208, 208, 221, 0.16);
    p {
      font-size: 16px;

      ${down('md')} {
        font-size: 14px;
      }
    }
    ${down('md')} {
      width: 155px;
    }
  }
`;
export const TimeWrapper = styled.div<{error?: boolean}>`
  border: ${({error}) => (error ? '1px solid #d52a2a' : '1px solid #cecede')};
  border-radius: 10px;
  display: flex;
  width: 100%;
  align-items: center;
  .start {
    padding: 8px 12px;
    min-width: 70px;
  }
  .body {
    border-left: ${({error}) =>
      error ? '1px solid #d52a2a' : '1px solid #cecede'};
    padding: 8px 12px;
    display: flex;
    width: 100%;
    align-items: center;

    justify-content: space-between;

    .input {
      background: #f6f6f8;
      width: 65px;
      height: 30px;
      text-align: center;
      padding: 2px 2px 2px 0px;
      border-width: 0px;
      border-radius: 4px;
    }
  }
`;
