import styled from 'styled-components';
import {Body1, Body2} from '@ui/atoms/typography';
import {memo} from 'react';
import {cfv} from '@utils/framework';
import {FlexRow, FlexRowSpaceBetween} from '@ui/style/styles';
import {CustomColorIcon} from '@ui/atoms/color-icons';
import {RoundedPieChart} from '@ui/atoms/pie-chart/pie';

const Wrapper = styled.div`
  .heading {
  }
  .progress {
    border-top: 1px solid #ededf2;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 10px;
  }
`;

const Card = styled.div<{bg: string}>`
  .card-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
  }

  border-radius: 8px;
  border: 1px solid #ededf2;
  width: 100%;

  .compare {
    border-top: 1px solid #ededf2;
    display: flex;
    padding: 4px 12px;
    align-items: center;
    justify-content: space-between;
  }
  @media (max-width: 800px) {
    margin-bottom: 10px;
  }
`;

const Value = styled.div<{bg: string}>`
  border: 1px solid ${({bg}) => bg};
  background-color: #f6f6fe;
  padding: 4px 12px;
  border-radius: 8px;
`;

const PulseWrapper = styled.div`
  display: flex;

  @media (max-width: 800px) {
    display: block;
  }
`;
export const ObjSummary = memo(
  ({
    data = {
      onTrack: 0,
      behind: 0,
      atRisk: 0,
      noStatus: 0,
      goals: {fulfilment: 0},
    },
    state,
    avgTotal,
    showGraph = true,
    title = '',
    showCards = true,
  }: any) => {
    const summary = [
      {
        title: 'Avg. Completion',
        label: 'goalsfulfilment',
        value: data?.goals.fulfillment ? `${data?.goals.fulfillment}%` : '-',
        color: 'transparent',
      },
      {
        title: `Total ${cfv().g_oals}`,
        label: 'goals.count',
        value: data?.goals.count ? `${data?.goals.count}` : '-',
        key: 'goals.count',
        color: '#F39C9A',
      },
    ];

    const chartSummary = [
      {
        title: 'Ontrack',
        percent: 'onTrackPercent',
        key: 'onTrack',
        stateKey: 'on_track',
        color: '#47B881',
      },
      {
        title: `Behind`,
        percent: 'behindPercent',
        key: 'behind',
        stateKey: 'behind',

        color: '#E87F16',
      },
      {
        title: `At risk`,
        percent: 'atRiskPercent',
        key: 'atRisk',
        stateKey: 'at_risk',

        color: '#F39C9A',
      },
      {
        title: `Not updated`,
        percent: 'noStatusPercent',
        key: 'noStatus',
        stateKey: 'no_status',

        color: '#E4E5FB',
      },
    ];

    const total = data?.onTrack + data?.behind + data?.atRisk + data?.noStatus;

    const isDisabled = (key?: string) => {
      const getSummary = chartSummary.find((option) => option.stateKey === key);

      if (!getSummary) return;

      return state?.length > 0 && getSummary && !state?.includes(key);
    };

    return (
      <Wrapper>
        <div className="heading">
          <div className="flex flex-col lg:flex-row lg:items-center items-start justify-between gap-3">
            {showGraph && (
              <div
                className={` ${
                  showCards ? 'lg:w-[50%]' : 'lg:w-full'
                } w-full flex flex-row gap-1 items-center`}>
                <div className="w-full">
                  <RoundedPieChart
                    chartStyle={{
                      width: 210,
                      height: 210,
                      margin: {top: 20, left: 20},
                    }}
                    totalValue={avgTotal || total}
                    pieTitle={title}
                    data={
                      total
                        ? [
                            {
                              name: 'on Track',
                              title: 'on Track',
                              value: data?.onTrack,
                              color: '#47B881',
                            },
                            {
                              name: 'Behind',

                              value: data?.behind,
                              color: '#FFD5AD',
                            },
                            {
                              name: 'At risk',

                              value: data?.atRisk,
                              color: '#F39C9A',
                            },
                            {
                              name: 'No status',

                              value: data?.noStatus,
                              color: '#E4E5FB',
                            },
                          ]
                        : [
                            {
                              name: 'Empty',
                              value: 100,
                              color: '#bfbfd4',
                            },
                          ]
                    }
                  />
                </div>

                <div className="w-full">
                  {chartSummary.map((summary) => (
                    <FlexRowSpaceBetween
                      key={summary.key}
                      className={`border-b border-b-[#EDEDF2] pb-4 pt-3 w-full`}>
                      <FlexRow className="gap-2">
                        <CustomColorIcon
                          color={
                            isDisabled(summary.stateKey)
                              ? '#BFBFD4'
                              : summary.color
                          }
                          width="8px"
                          height="8px"
                          margin={0}
                        />
                        <Body2
                          kind={
                            isDisabled(summary.stateKey)
                              ? 'textMuted'
                              : 'textDark'
                          }>
                          {summary.title}
                        </Body2>
                      </FlexRow>
                      <Body2
                        kind={
                          isDisabled(summary.stateKey)
                            ? 'textMuted'
                            : 'textDark'
                        }>
                        {data[summary.key]}{' '}
                        <span
                          className={`text-[12px] ${
                            isDisabled(summary.stateKey) ? '' : 'text-[#5F5F8C]'
                          } `}>
                          ({data[summary.percent]}%)
                        </span>
                      </Body2>
                    </FlexRowSpaceBetween>
                  ))}
                </div>
              </div>
            )}

            {showCards && (
              <PulseWrapper
                className={`${
                  showGraph
                    ? 'flex-col lg:w-[50%] w-full lg:max-w-[300px] max-w-full'
                    : 'flex-row w-full'
                } gap-4`}>
                {summary.map((sum) => (
                  <Card bg={sum.color}>
                    <div className="card-wrapper">
                      <Body1 weight="semibold">{sum.title}</Body1>
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <Value bg="#F6F6FE">
                          <Body1
                            weight="semibold"
                            kind={
                              !isDisabled(sum?.key || '')
                                ? 'textDark'
                                : 'textMuted'
                            }>
                            {sum.value}
                          </Body1>
                        </Value>
                      </div>
                    </div>
                  </Card>
                ))}
              </PulseWrapper>
            )}
          </div>
        </div>
      </Wrapper>
    );
  },
);
