import {useState, useMemo, useCallback, useEffect} from 'react';
import {usePeriodHook} from '@hooks/period-hook';
import {useQuery} from 'react-query';
import {useStoreContext} from '@store/store-context';
import {GoalsRequestImpl} from '../../../../api/goals-api/goals-request';
import {GoalsController} from '../../../../api/goals-api/goals-controller';

export const useKpisHook = (view: string) => {
  const [searchValue, setSearchValue] = useState('');

  const [groupBy, setGroupBy] = useState<'tag' | ''>('');

  const [sortBy, setSortBy] = useState('desc');

  const {
    storeAllObjectives: {
      modifyYouGoalsPaginator,
      youGoalsPaginator,
      setGoals,
      dateRange,
      replaceFetchedGoals,

      setDateRange,
    },

    usersStore: {usersFormatFilter},
    groupStore: {groups},
    authStore: {auth},
    storeYouGoals: {
      youGoalsFilter,
      selectedUsers,
      setSelectedUsers,
      setYouGoalsFilter,
    },
  } = useStoreContext();

  const [userFilter, setUserFilter] = useState({
    filterBy: {
      label: 'People',
      value: 'people',
    },
    selectedTeam: {
      value: '',
      label: '',
    },
    members: usersFormatFilter,
  });

  const {isPeriodEnabled, currentMonthRange} = usePeriodHook();

  useEffect(() => {
    if (isPeriodEnabled && currentMonthRange().starts) {
      setDateRange(currentMonthRange());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPeriodEnabled, currentMonthRange().starts]);

  const [clearSelection, setClearSelection] = useState(false);

  const request = useMemo(() => new GoalsRequestImpl(), []);
  const controller = useMemo(() => new GoalsController(request), [request]);

  const allFilters = useMemo(() => {
    return {
      usersFilter: userFilter,
      view: view,
      name: searchValue,

      members: selectedUsers,
      sortBy,
      paginate: !groupBy,
      dateRange: dateRange,
      pageLimit: youGoalsPaginator.limit,
      paginator: youGoalsPaginator.page,
      goalFilter: youGoalsFilter,
    };
  }, [
    userFilter,
    view,
    searchValue,
    sortBy,
    selectedUsers,
    groupBy,
    dateRange,
    youGoalsPaginator.limit,
    youGoalsPaginator.page,
    youGoalsFilter,
  ]);

  const getGoals = useCallback(async () => {
    const res = await controller.fetchAllGoals(
      youGoalsPaginator.page,
      {
        startDate: dateRange.starts,
        endDate: dateRange.ends,
        view: view,
        goalFilter: youGoalsFilter,
        isKpi: true,
        name: searchValue,
        sortBy,
        paginate: !groupBy,

        member: selectedUsers.length ? selectedUsers.join(',') : auth.user.id,
      },
      youGoalsPaginator.limit,
    );

    const includeGroups = (goalsResponse: any) => {
      const groupsIncluded =
        goalsResponse?.goals &&
        goalsResponse?.goals?.map((goal: any) => {
          if (goal.goalType === 'group') {
            const group = goal.group.map((grp: string) => {
              return groups.find((_group) => _group.id === grp);
            });

            return {
              ...goal,
              group,
            };
          } else
            return {
              ...goal,
            };
        });
      return groupsIncluded;
    };

    return {...res, goals: includeGroups(res)};
  }, [
    controller,
    youGoalsPaginator.page,
    youGoalsPaginator.limit,
    dateRange.starts,
    dateRange.ends,
    view,
    youGoalsFilter,
    searchValue,
    sortBy,
    groupBy,
    selectedUsers,
    auth.user.id,
    groups,
  ]);

  const {data, isLoading, refetch} = useQuery(['kpis-page', allFilters], () =>
    getGoals(),
  );

  const handleUpdateUserFilter = (updatedUserFilter: any) => {
    setUserFilter(updatedUserFilter);
  };

  const handleUpdateDateFilter = (updateDateRange: any) => {
    setDateRange(updateDateRange);
  };
  const initKeyResults = useCallback(async () => {
    const request = new GoalsRequestImpl();
    const controller = new GoalsController(request);

    const response = await controller.fetchGoals(1, 'networkLevel', 10000);

    return response?.goals;
  }, []);

  useMemo(async () => {
    if (data) {
      const totalResults = data && data.totalResults;
      const totalPages = data && data.totalPages;

      if (view === 'tree') {
        setGoals(data);
      } else {
        setGoals(data.goals);
        await replaceFetchedGoals(data?.goals);
      }

      modifyYouGoalsPaginator('page', data.page);
      modifyYouGoalsPaginator('totalPages', totalPages);

      modifyYouGoalsPaginator('totalResults', totalResults);
    }
  }, [data, modifyYouGoalsPaginator, replaceFetchedGoals, setGoals, view]);

  return {
    dateRange,
    setDateRange,
    searchValue,
    setSearchValue,
    refetch,
    loading: isLoading,
    handleUpdateUserFilter,
    setSortBy,
    sortBy,
    handleUpdateDateFilter,
    initKeyResults,
    userFilter,
    state: youGoalsFilter,
    setGoalState: setYouGoalsFilter,
    selectedUsers,
    setSelectedUsers,
    clearSelection,
    setClearSelection,
    setUserFilter,
    groupBy,
    setGroupBy,
  };
};
