import {FilterComponent} from '@pages/dashboard/reporting/reporting-pages/admin-analytics/analytics-shared-components/filterbox/filterbox';
import {useStoreContext} from '@store/store-context';
import {Body2} from '@ui/atoms/typography';
import {DropdownCustom} from '@ui/molecules/dropdown-custom';
import {GroupsSelectField} from '@ui/molecules/select/group-select/group-select';
import {useBreakpoints} from '@utils/use-breakpoints';
import {userName} from '@utils/user-name';
import {groupUsers} from '@utils/users';
import React, {useMemo, useState} from 'react';

interface GoalFilterProps {
  onChange: (users: string) => void;
  value: {users: string};
}

export const FeedbackFilter = ({onChange, value}: GoalFilterProps) => {
  const {isXs} = useBreakpoints();

  const [showApply, setShowApply] = useState(false);

  const [selectedUsers, setSelectedUsers] = useState<string>(value.users || '');

  const findUsers = (_user: string) => {
    return users
      .filter((user) => user.id === _user)
      .map((user) => ({name: userName(user)}));
  };

  const {
    usersStore: {users},
    authStore: {auth},
  } = useStoreContext();

  const formatUsers = useMemo(() => {
    return groupUsers(auth.user, users);
  }, [auth.user, users]);

  const handleApply = (_: any, handleClose: () => void) => {
    setShowApply(false);
    handleClose();
    onChange(selectedUsers);
  };

  return (
    <div>
      <DropdownCustom
        collapseOnClick={true}
        menu={(handleClose: () => void) => (
          <FilterComponent
            title="Filters"
            boxStyle={{
              right: isXs ? undefined : 0,
              left: 0,
              zIndex: 2,
            }}
            setView={() => null}
            showFilter={true}
            handleClose={handleClose}
            resetDisabled={!selectedUsers}
            showApplyButton={{
              show: showApply,
              action: handleApply,
            }}
            type=""
            resetFilter={() => {
              handleClose();
              setSelectedUsers('');

              onChange('');
            }}>
            <div>
              <Body2 weight="semibold" className="mb-2">
                Feedback directed to
              </Body2>
              <GroupsSelectField
                placeholder="Select Assignee"
                searchPlaceholder="Search members"
                options={formatUsers}
                theme="users"
                borderRadius={10}
                feature="invite"
                reporting
                performance
                defaultValue={selectedUsers}
                onChange={(data: {label: string; value: string}) => {
                  setShowApply(true);
                  setSelectedUsers(data?.value);
                }}
              />
            </div>
          </FilterComponent>
        )}
        shouldNotIncludeWrapper={true}
        customComponent={(handleOpen: (event: any) => void, open: boolean) => (
          <>
            <div>
              <button
                onClick={handleOpen}
                className="flex flex-row items-center">
                <Body2 weight="bold" kind="purple300" className="capitalize">
                  Filtered by: {findUsers(value.users)[0].name}
                </Body2>
              </button>
            </div>
          </>
        )}
        customDropdownWrapperStyles={{
          top: '130%',
          boxShadow: '0px',
          left: 0,
          padding: 0,
          overflowY: 'auto',
          background: 'none',
          border: 0,
        }}
      />
    </div>
  );
};
