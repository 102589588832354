import {memo} from 'react';

export const ArrowLeftIcon = memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16">
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M14.001 8H2.668M7.335 12.667L2.668 8l4.667-4.667"
    />
  </svg>
));
ArrowLeftIcon.displayName = 'ArrowLeftIcon';
