import React, {useMemo} from 'react';
import {CycleWidget, MileStoneWrapper} from '../style';
import {Body1, Body2} from '@ui/atoms/typography';
import dayjs from 'dayjs';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {Button} from '@ui/atoms/button';
import {FlexRowSpaceBetween} from '@ui/style/styles';
import {ValueProps} from '../../create-review-interface';

type MileStoneProps = {
  reviewMilestone: ValueProps['milestones'];
  isDisabled?: boolean;

  reviewCycleType: ValueProps['reviewType'];
  editData: (step: number) => void;
};
export const PreviewMilestone = ({
  reviewMilestone,
  reviewCycleType,
  isDisabled,
  editData,
}: MileStoneProps) => {
  const isPeerIncluded = reviewCycleType === '360';
  const milestones = useMemo(() => {
    const options = [
      {
        label: 'Review cycle starts',
        value: reviewMilestone.startDate,
      },
      {
        label: 'Review cycle ends',
        value: reviewMilestone.endDate,
        aligned: 'right',
      },
      {
        label: 'Self review due by',
        value: reviewMilestone.self,
      },
      {
        label: 'Manager review due by',
        value: reviewMilestone.self,
        aligned: isPeerIncluded ? 'left' : 'right',
      },
      {
        label: 'Finalize review by',
        value: reviewMilestone.finalize,
        aligned: isPeerIncluded ? 'right' : 'left',
      },
    ];

    if (isPeerIncluded) {
      options.splice(3, 0, {
        label: 'Peer reviews due by',
        value: reviewMilestone?.peer || '',
        aligned: 'right',
      });
    }

    return options;
  }, [reviewMilestone, isPeerIncluded]);
  const Milestones = () => {
    return (
      <CycleWidget
        style={{height: isPeerIncluded ? '100%' : '710px'}}
        className="bg-white">
        <FlexRowSpaceBetween>
          <Body1 weight="bold" style={{fontSize: '18px'}}>
            Milestones
          </Body1>
          <Button
            kind="secondary"
            disabled={isDisabled}
            style={{padding: '8px 16px'}}
            onClick={() => editData(2)}>
            Edit
          </Button>
        </FlexRowSpaceBetween>

        <MileStoneWrapper>
          {reviewMilestone &&
            milestones.map((milestone) => (
              <div key={milestone.label} className="milestone-item">
                <Body2
                  weight="semibold"
                  kind="textBody"
                  align={(milestone?.aligned as 'right') || 'left'}>
                  {milestone.label}
                </Body2>
                <VerticalSpacer size="10px" />
                <Body1
                  weight="bold"
                  align={(milestone?.aligned as 'right') || 'left'}>
                  {dayjs(milestone.value).isValid()
                    ? dayjs(milestone.value).format('DD/MM/YYYY')
                    : '-'}
                </Body1>
              </div>
            ))}
        </MileStoneWrapper>
      </CycleWidget>
    );
  };

  return <Milestones />;
};
