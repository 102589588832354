import {useCallback, useMemo, useState} from 'react';
import {ConstantType, MoodType} from './interface';

export const MoodMessage: ConstantType<MoodType> = {
  [MoodType.AWFUL]: 'Damn, happens to the best of us. How can we help?',
  [MoodType.UNHAPPY]: 'Well, some days are like that',
  [MoodType.JUSTTHERE]: 'You are getting there',
  [MoodType.HAPPY]: "Let's keep it rolling",
  [MoodType.FIREDUP]: 'Oh! Lets knock some rock',
};

export function useMoodInput(
  mood: MoodType,
  updateMood: (mood: MoodType) => void,
) {
  const [innerMood, setInnerMood] = useState(mood);

  const handleMoodUpdate = useCallback(() => {
    updateMood(innerMood);
  }, [innerMood, updateMood]);

  const handleMood = useCallback(
    (mood: MoodType) => {
      setInnerMood(mood);
      handleMoodUpdate();
    },
    [handleMoodUpdate],
  );

  const moodMessage = useMemo(() => {
    return MoodMessage[innerMood];
  }, [innerMood]);

  return {
    handleMood,
    innerMood,
    moodMessage,
  };
}
