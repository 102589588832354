import {CustomColorIcon} from '@ui/atoms/color-icons';
import {EmptyPlaceholder} from '@ui/atoms/empty-placeholder';
import {Body2, Headline3, Body1} from '@ui/atoms/typography';
import {UserListCard} from '@ui/molecules/user/list-card';
import {ItemLoader} from '@ui/organisms/item-loader';
import {FlexRowCenter, FlexRow} from '@ui/style/styles';
import dayjs from 'dayjs';
import {cloudinary} from '@assets/images/cloudinary';

const {emptyGoals} = cloudinary;

interface ICheckinsQuestionsDirectReports {
  data: {
    responses: {
      avatar: any;
      createdAt: string;
      email: string;
      firstName: string;
      id: string;
      lastName: string;
      response: string;
    }[];
    question: string;
    questionId: string;
  }[];
  isLoading: boolean;
}

const emptyPlaceholder = {
  image: {
    alt: 'alt text',
    shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
    src: emptyGoals,
    style: {height: '30%', width: '30%'},
  },
  imports: true,
  subtitle: 'Try tweaking the selected questions.',
  title: 'No results yet.',
};

export const CheckinsQuestionsDirectReports = ({
  data,
  isLoading,
}: ICheckinsQuestionsDirectReports) => {
  return (
    <div className="table mt-4 border border-borderLight rounded-[10px] bg-white w-full">
      <div className="table-desc p-4">
        <Body2 weight="semibold">Summary ({data?.length || 0})</Body2>
      </div>

      <div className="border-t border-t-[#ededf2] rounded-t-[10px] p-6">
        {!data?.length && !isLoading && (
          <EmptyPlaceholder {...emptyPlaceholder} />
        )}

        {isLoading && (
          <FlexRowCenter className="h-[200px]">
            <ItemLoader />
          </FlexRowCenter>
        )}

        {data &&
          data.map((option, idx, allData) => (
            <div
              key={option.questionId}
              className={
                idx !== allData.length - 1
                  ? 'border-b border-b-[#ededf2] mb-5 pb-3'
                  : ''
              }>
              <Headline3 className="mb-3">
                <span className="text-[#5F5F8C]">Q. </span>
                {option.question}
              </Headline3>
              <div className="mx-5">
                {option.responses.length ? (
                  option.responses.map((response, index, allAnswers) => (
                    <div
                      key={response.id}
                      className={
                        index !== allAnswers.length - 1
                          ? 'border-b border-b-[#ededf2] mb-5'
                          : ''
                      }>
                      <FlexRow>
                        <div className="mr-4">
                          <UserListCard
                            tooltip={true}
                            userId={response?.id || ''}
                            type="primary"
                            textStyle={{fontWeight: 500}}
                            textKind="textDark"
                            avatar={response.avatar?.url}
                            name={
                              response?.firstName && response?.lastName
                                ? `${response.firstName} ${response.lastName}`
                                : response?.email || ''
                            }
                          />
                        </div>

                        <CustomColorIcon
                          color="#D9D9D9"
                          width="8px"
                          height={'8px'}
                          margin={5}
                        />

                        <Body2 kind="textBody" className="date-body">
                          {dayjs(response.createdAt).format('DD MMM. YYYY')}
                        </Body2>
                      </FlexRow>
                      <Body1 className="my-3">{response.response}</Body1>
                    </div>
                  ))
                ) : (
                  <div>
                    <Body1 kind="textBody">Hmm, no answers found.</Body1>
                  </div>
                )}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
