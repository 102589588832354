import {Body1, Microcopy} from '@ui/atoms/typography';
import {FlexRow} from '@ui/style/styles';
import {QuestionIconWrapper} from '@ui/molecules/tooltip/tooltip';
import {AlertBadge} from '@ui/atoms/icons/alert-badge';
import {Helper} from '@ui/atoms/helper';
import {Editor} from 'react-draft-wysiwyg';
import {convertFromRaw, convertToRaw, EditorState} from 'draft-js';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {useDebouncedCallback} from 'use-debounce';
import {useState} from 'react';
import styled from 'styled-components';
import {fixMarkdownBoldFormatting} from '@utils/strings';
import {SelectImpression} from './impression';
import {markdownToDraft, draftToMarkdown} from 'markdown-draft-js';

const Wrapper = styled.div<{error?: boolean}>`
  border: ${({error}) => (error ? ` 1px solid #d52a2a` : ` 1px solid #cecede`)};
  border-radius: 10px;
  .input {
    padding: 2px 0px;
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    border-top: ${({error}) =>
      error ? ` 1px solid #d52a2a` : ` 1px solid #cecede`};
  }
  .custom-toolbar-option {
    border: none !important; // Use !important to override existing styles
  }
  .custom-editor-wrapper {
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
  }
  .custom-editor-wrapper .public-DraftEditorPlaceholder-root {
    color: #5f5f8c;
  }
`;

type IFeedBackInput = {
  updateFeedbackInput: (value: string) => void;
  updateImpression: (value: string) => void;
  error?: string;
};
const FeedBackInput = ({
  updateFeedbackInput,
  updateImpression,
  error,
}: IFeedBackInput) => {
  const [value, setValue] = useState('');

  const debounceValue = useDebouncedCallback(
    (value: string) => setValue(value),
    1000,
  );

  const [editorState, setEditorState] = useState<any>(
    EditorState.createEmpty(),
  );

  const convertToDraft = (text: string) => {
    const rawData = markdownToDraft(text);

    const contentState = convertFromRaw(rawData);

    const newEditorState = EditorState.createWithContent(contentState);

    onEditorStateChange(newEditorState);
  };

  const onEditorStateChange = (editorState: EditorState) => {
    const value = convertToRaw(editorState.getCurrentContent());
    const markdownContent = draftToMarkdown(value);

    const text = value.blocks.map((block) => block.text);

    updateFeedbackInput(fixMarkdownBoldFormatting(markdownContent));

    debounceValue(text.join(','));

    setEditorState(editorState);
  };

  return (
    <>
      <Wrapper error={!!error}>
        <div className="input">
          <Editor
            toolbarStyle={{
              border: 0,
              backgroundColor: 'white',
              borderRadius: '10px 10px 0px 0px',
            }}
            wrapperStyle={{
              minHeight: '120px',
              maxHeight: '350px',
              padding: '5px 10px',
            }}
            wrapperClassName="custom-editor-wrapper"
            editorState={editorState}
            handlePastedText={(text) => {
              convertToDraft(text);
              return true;
            }}
            placeholder="Make a difference with feedback that’s at least 30 characters."
            onEditorStateChange={onEditorStateChange}
            toolbar={{
              options: ['inline', 'list'],
              inline: {
                inDropdown: false,
                className: 'border-0 bg-red',
                component: undefined,
                dropdownClassName: undefined,
                options: ['bold', 'italic', 'underline', 'strikethrough'],
                bold: {
                  className: 'custom-toolbar-option',
                },
                italic: {
                  className: 'custom-toolbar-option',
                },
                underline: {
                  className: 'custom-toolbar-option',
                },
                strikethrough: {
                  className: 'custom-toolbar-option',
                },
              },

              list: {
                inDropdown: false,
                options: ['unordered', 'ordered'],
                ordered: {
                  className: 'custom-toolbar-option',
                },
                unordered: {
                  className: 'custom-toolbar-option',
                },
              },
            }}
          />
        </div>

        {value.length > 29 && (
          <div className="footer">
            <FlexRow>
              <Body1 kind="textBody">Theme</Body1>
              <Microcopy kind={'red400'} style={{marginLeft: '4px'}}>
                beta
              </Microcopy>
              <QuestionIconWrapper type="button" tooltip={true}>
                <AlertBadge />
                <span className="tooltip" style={{left: -34}}>
                  We utilize natural language AI to determine sentiment, but
                  it's still learning. You can choose a theme manually too.
                </span>
              </QuestionIconWrapper>
            </FlexRow>

            <SelectImpression
              updateValue={(data) => updateImpression(data)}
              value={value}
            />
          </div>
        )}
      </Wrapper>
      {!!error && <Helper children={error} state="error" />}
    </>
  );
};

export default FeedBackInput;
