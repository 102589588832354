import {FC} from 'react';
import {BaseFilterProps} from './interface';
import {ReportingObjectiveFilter} from './objective-filter';
import {ReportingCheckinFilter} from './checkin-filter';
import {ReportingQuestionsFilter} from './questions-filter';
import {ReportingKPIFilter} from './kpi-filter';
import {ReportingFeedbackFilter} from './feedback-filter';
import {ReportingActionItemsFilter} from './action-items-filter';

interface ReportingFilterProps extends BaseFilterProps {
  type:
    | 'checkin'
    | 'objectives'
    | 'kpis'
    | 'feedback'
    | 'questions'
    | 'actionItems';
  // Add other common props
}

export const ReportingFilter: FC<ReportingFilterProps> = ({type, ...props}) => {
  const FilterComponent = {
    checkin: ReportingCheckinFilter,
    objectives: ReportingObjectiveFilter,
    kpis: ReportingKPIFilter,
    actionItems: ReportingActionItemsFilter,
    feedback: ReportingFeedbackFilter,
    questions: ReportingQuestionsFilter,
  }[type];

  return <FilterComponent {...props} type={type} />;
};
