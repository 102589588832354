import {action, makeAutoObservable, observable} from 'mobx';
import {CreateGoalController} from '../../pages/create-goal/create-goal-controller';
import {CreateGoalRequestImpl} from '../../pages/create-goal/create-goal-request';

export class GoalTemplatesStore {
  private request = new CreateGoalRequestImpl();
  private controller = new CreateGoalController(this.request);
  @observable allGoalTemplates: any = [];
  @observable loading: any = {
    allGoalTemplates: true,
  };
  @observable goalTemplatesPaginator: any = {
    limit: 10,
    page: 1,
    totalPages: 1,
    totalResults: 0,
    pageNumberLimit: 1,
    maxPageNumberLimit: 2,
    minPageNumberLimit: 0,
  };

  constructor() {
    makeAutoObservable(this);
  }

  // Set all templates
  @action
  setAllGoalTemplates = (data: any) => {
    this.allGoalTemplates = data;
  };

  // Paginator start

  @action
  modifyGoalTemplatesPaginator = (field: string, value: number) => {
    this.goalTemplatesPaginator[field] = value;
  };

  @action
  handleGoalTemplatesPaginatorPageClick = (event: any) => {
    this.modifyGoalTemplatesPaginator('page', Number(event.target.id));
  };
  @action
  handleGoalTemplatesPaginatorLimit = (value: number) => {
    this.modifyGoalTemplatesPaginator('limit', value);
  };

  @action
  handleGoalTemplatesPaginatorNextbtn = () => {
    this.modifyGoalTemplatesPaginator(
      'page',
      this.goalTemplatesPaginator.page + 1,
    );

    if (
      this.goalTemplatesPaginator.page + 1 >
      this.goalTemplatesPaginator.maxPageNumberLimit
    ) {
      this.modifyGoalTemplatesPaginator(
        'maxPageNumberLimit',
        this.goalTemplatesPaginator.maxPageNumberLimit +
          this.goalTemplatesPaginator.pageNumberLimit,
      );
      this.modifyGoalTemplatesPaginator(
        'minPageNumberLimit',
        this.goalTemplatesPaginator.minPageNumberLimit +
          this.goalTemplatesPaginator.pageNumberLimit,
      );
    }
  };

  @action
  handleGoalTemplatesPaginatorPrevbtn = () => {
    this.modifyGoalTemplatesPaginator(
      'page',
      this.goalTemplatesPaginator.page - 1,
    );
    if (
      (this.goalTemplatesPaginator.page - 1) %
        this.goalTemplatesPaginator.pageNumberLimit ===
      0
    ) {
      this.modifyGoalTemplatesPaginator(
        'maxPageNumberLimit',
        this.goalTemplatesPaginator.maxPageNumberLimit -
          this.goalTemplatesPaginator.pageNumberLimit,
      );
      this.modifyGoalTemplatesPaginator(
        'minPageNumberLimit',
        this.goalTemplatesPaginator.minPageNumberLimit -
          this.goalTemplatesPaginator.pageNumberLimit,
      );
    }
  };

  @action
  goalTemplatesPages = (totalResults: number, limit: number) => {
    const arr = [];
    for (
      let i = 1;
      i <=
      Math.ceil(
        this.goalTemplatesPaginator.totalResults /
          this.goalTemplatesPaginator.limit,
      );
      i++
    ) {
      arr.push(i);
    }
    return arr;
  };

  // Paginator end

  // Loader
  @action
  setLoading = (field: string, value: boolean) => {
    this.loading = {
      ...this.loading,
      [field]: value,
    };
  };
}
export const goalTemplatesStore = new GoalTemplatesStore();
