import {forwardRef, ReactElement, Ref, useCallback, useState} from 'react';
import ReactSelect, {ActionMeta} from 'react-select';
import {useDropDown} from '../../../../hooks';
import {motion} from 'framer-motion';
import {Label} from '../../../atoms/label';
import {FieldWrapper} from '../../../atoms/field-wrapper';
import {Helper} from '../../../atoms/helper';
import {
  Wrapper,
  DropdownWrapper,
  DropWrapper,
  DropdownHandler,
  HandlerWrapper,
  ArrowIconWrapper,
  AvatarWrapper,
} from './styled';
import {ArrowHeadDownIcon} from '../../../atoms/icons/arrow-head-down';
import {Body1} from '../../../atoms/typography';

import {SelectFieldProps} from '../user-multi-select/user-multi-select';

import {InputState} from '../../../interface';
import {UserMultiSelect} from '../user-multi-select';
import {AvatarsSecondary} from '../../avatarssecondary';

export interface TUserSelectFieldProps extends SelectFieldProps {
  label?: string;
  placeholder?: string;
  state?: InputState;
  helper?: string | ReactElement;
}

export const MultiUserSelectField = forwardRef(
  (
    {
      label,
      state,
      helper,
      placeholder,
      options,
      onChange,
      value,
      defaultValue,
      ...rest
    }: SelectFieldProps,
    ref: Ref<ReactSelect<any>>,
  ) => {
    const {handleOpen, ref: dropRef, open} = useDropDown();

    const getValue = options.filter((option: any) => {
      if (defaultValue) {
        return defaultValue.includes(option.value);
      }
      return null;
    });

    const [selectedOptions, setSelectedOptions] = useState<any>(() =>
      defaultValue ? getValue : [],
    );

    const handleChange = useCallback(
      (options: any, action: ActionMeta<any>) => {
        setSelectedOptions(options);
        if (onChange) {
          onChange(options, action);
        }
      },
      [onChange],
    );

    return (
      <FieldWrapper>
        <Wrapper>
          <Label aria-label="label">{label}</Label>
          <DropWrapper ref={dropRef}>
            <DropdownHandler open={open} onClick={handleOpen}>
              <HandlerWrapper>
                <AvatarWrapper>
                  {selectedOptions?.length > 0 ? (
                    <AvatarsSecondary
                      total={selectedOptions.length}
                      items={selectedOptions}
                    />
                  ) : (
                    <Body1 kind="textBody">{placeholder}</Body1>
                  )}
                </AvatarWrapper>
                <ArrowIconWrapper>
                  <motion.span
                    initial={{rotate: 0}}
                    animate={{
                      rotate: open ? 180 : 0,
                      marginTop: open ? '-6px' : '0px',
                    }}
                    transition={{
                      duration: 0.3,
                    }}
                    key="user-select-toggled">
                    <ArrowHeadDownIcon />
                  </motion.span>
                </ArrowIconWrapper>
              </HandlerWrapper>
            </DropdownHandler>
            {open && (
              <DropdownWrapper>
                <UserMultiSelect
                  {...rest}
                  onChange={handleChange}
                  value={selectedOptions}
                  defaultValue={defaultValue}
                  ref={ref}
                  options={options}
                />
              </DropdownWrapper>
            )}
          </DropWrapper>
          <Helper aria-label="helper" state={state} children={helper} />
        </Wrapper>
      </FieldWrapper>
    );
  },
);
