import styled from 'styled-components';
import {Button} from '../../../../../../ui/atoms/button';
import {down} from 'styled-breakpoints';

export const Wrapper = styled.div`
  position: relative;
  top: 0.6%;
  border-width: 1px 1px 0 1px;
  width: 100%;
  border-style: solid;
  border-color: #ededf2;
  border-radius: 10px 10px 0 0px;
`;

export const BorderWrapper2 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
`;

export const MemberButton = styled(Button)`
  padding: 8px 16px;
`;

export const BorderWrapper = styled.div`
  display: flex;
  width: 100%;
  border-radius: 10px 10px 0 2px;
`;

export const NavBorderWrapper = styled.div`
  display: flex;
  border-radius: 10px 10px 0px 10px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ededf2;
  ${down('md')} {
    width: auto;
  }
`;

export const BorderWrapper1 = styled.div`
  width: 100%;
  @media (max-width: 700px) {
    width: 98%;
  }
`;
