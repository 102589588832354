import {memo} from 'react';

export const SlackIcon = memo(() => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_26086_114576)">
        <path
          d="M5.04809 15.1236C5.04809 16.5141 3.92431 17.6379 2.53381 17.6379C1.14331 17.6379 0.0195312 16.5141 0.0195312 15.1236C0.0195312 13.7332 1.14331 12.6094 2.53391 12.6094H5.048V15.1237L5.04809 15.1236ZM6.30528 15.1236C6.30528 13.7332 7.42906 12.6094 8.81956 12.6094C10.2101 12.6094 11.3338 13.7332 11.3338 15.1237V21.4093C11.3338 22.7998 10.2101 23.9237 8.81947 23.9237C7.42916 23.9237 6.30528 22.7998 6.30528 21.4093V15.1237V15.1236Z"
          fill="#E01E5A"
        />
        <path
          d="M8.81906 5.02856C7.42856 5.02856 6.30469 3.90478 6.30469 2.51428C6.30469 1.12378 7.42866 0 8.81906 0C10.2095 0 11.3333 1.12378 11.3333 2.51428V5.02866H8.81897L8.81906 5.02856ZM8.81906 6.30478C10.2096 6.30478 11.3333 7.42856 11.3333 8.81906C11.3333 10.2096 10.2096 11.3333 8.81897 11.3333H2.51438C1.12378 11.3333 0 10.2096 0 8.81897C0 7.42866 1.12378 6.30478 2.51428 6.30478H8.81897H8.81906Z"
          fill="#36C5F0"
        />
        <path
          d="M18.8951 8.81906C18.8951 7.42856 20.0189 6.30469 21.4093 6.30469C22.7998 6.30469 23.9237 7.42856 23.9237 8.81906C23.9237 10.2096 22.7998 11.3333 21.4093 11.3333H18.8951V8.81897V8.81906ZM17.6379 8.81906C17.6379 10.2096 16.5141 11.3333 15.1236 11.3333C13.7332 11.3333 12.6094 10.2096 12.6094 8.81897V2.51438C12.6094 1.12378 13.7332 0 15.1236 0C16.514 0 17.6378 1.12378 17.6378 2.51428V8.81897L17.6379 8.81906Z"
          fill="#2EB67D"
        />
        <path
          d="M15.1236 18.8951C16.5141 18.8951 17.6379 20.0189 17.6379 21.4093C17.6379 22.7998 16.5141 23.9237 15.1236 23.9237C13.7332 23.9237 12.6094 22.7998 12.6094 21.4093V18.8951H15.1237H15.1236ZM15.1236 17.6379C13.7332 17.6379 12.6094 16.5141 12.6094 15.1236C12.6094 13.7332 13.7332 12.6094 15.1237 12.6094H21.4283C22.8188 12.6094 23.9427 13.7332 23.9427 15.1237C23.9427 16.5141 22.8188 17.6379 21.4283 17.6379H15.1237H15.1236Z"
          fill="#ECB22E"
        />
      </g>
      <defs>
        <clipPath id="clip0_26086_114576">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
});
