import {useStoreContext} from '@store/store-context';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {generateRandomDigits} from '@utils/generate-random-numbers';
import {database} from '@utils/firebase-request';
import dayjs from 'dayjs';
import {ActionType} from '@api/action-items-api/action-items-request';
import {
  removeFirebaseData,
  updateFirebaseData,
  writeFirebaseData,
} from '@utils/firebase-handler';
import {equalTo, orderByChild, query, ref, get} from 'firebase/database';
import {activateNotification} from '@ui/molecules/notification/activate-notification';

interface ActionItemsHookProps {
  itemId: string;
  source: string;
  userId: string;
  callback?: any;
  orderBy?: {
    key: string;
    value: string;
  };
  fetchAll?: boolean; // Add this new prop
}

export const useActionItems = ({
  itemId,
  source,
  userId,
  orderBy,
  callback,
  fetchAll = false,
}: ActionItemsHookProps) => {
  const {
    authStore,
    usersStore: {users},
  } = useStoreContext();

  const actionItemsPath = useMemo(
    () =>
      `${process.env.REACT_APP_DEV_ENV}/${authStore.auth.user.workspace.id}/action_items/`,
    [authStore.auth.user.workspace.id],
  );

  const [firebaseTasks, setTasks] = useState<any>({});

  const [isLoading, setIsLoading] = useState(true);

  const databaseRef = useMemo(() => ref(database, actionItemsPath), [
    actionItemsPath,
  ]);

  const convertAndFilterTasks = (tasks: Record<string, any>) => {
    return Object.entries(tasks).map(([firebaseId, task]: [string, any]) => ({
      ...task,
      firebaseId,
    }));
  };

  const fetchData = useCallback(async () => {
    let tasks: Record<string, any> = {};

    const fetchSnapshot = async (queryRef: any) => {
      const snapshot = await get(queryRef);
      if (snapshot.exists()) {
        Object.assign(tasks, snapshot.val());
      }
    };

    try {
      if (fetchAll) {
        await fetchSnapshot(databaseRef);
      } else {
        if (orderBy) {
          const orderedQuery = query(
            databaseRef,
            orderByChild(orderBy.key),
            equalTo(orderBy.value),
          );
          await fetchSnapshot(orderedQuery);
        }

        if (userId) {
          const assigneeQuery = query(
            databaseRef,
            orderByChild('assignee'),
            equalTo(userId),
          );
          const userQuery = query(
            databaseRef,
            orderByChild('user'),
            equalTo(userId),
          );

          await Promise.all([
            fetchSnapshot(assigneeQuery),
            fetchSnapshot(userQuery),
          ]);
        }
      }

      setTasks(tasks);
    } catch (error) {
      activateNotification({
        title: 'Error',
        content: 'Failed to fetch action items',
        kind: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  }, [fetchAll, databaseRef, orderBy, userId]);

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, [itemId, userId]);

  const findField = useCallback((data: any[], key: string) => {
    return data.find((field) => field.key === key);
  }, []);

  const convertedTasks = convertAndFilterTasks(firebaseTasks);

  const tasks = convertedTasks
    .map((task) => ({
      ...task,
      assignee: users.find((user) => user.id === task.assignee),
      key: task.id || task._id || task.key || '',
    }))
    .sort((a, b) => a.order - b.order);

  const computeActionItem = useCallback(
    (data: any, removeSource?: boolean) => {
      const assignee = data?.assignee;
      const id = data?.id || data?.key || generateRandomDigits(24);

      const computedItem = {
        completed: data.completed,
        id,
        meta: removeSource
          ? null
          : data?.meta || findField(tasks, id)?.meta || null,
        text: data.text,
        source: removeSource
          ? ''
          : data.source || findField(tasks, id)?.source || source,
        completedAt:
          findField(tasks, id)?.completedAt || data.completed
            ? dayjs().format()
            : '',
        sourceId: removeSource
          ? ''
          : data.sourceId || findField(tasks, id)?.sourceId || itemId,
        section: data?.section ?? findField(tasks, id)?.section ?? '',
        updatedBy: data.updatedBy || authStore.auth.user.id,
        createdBy:
          findField(tasks, id)?.createdBy ||
          data.createdBy ||
          authStore.auth.user.id,
        order: data?.order || findField(tasks, data.key)?.order || null,
        dueDate: data?.dueDate || null,
        createdAt:
          findField(tasks, id)?.createdAt ||
          data?.createdAt ||
          dayjs().format(),
        user: data?.user || findField(tasks, id)?.userId || userId,
        assignee:
          typeof assignee === 'string' ? assignee : assignee?.id || null,
      };

      return computedItem;
    },
    [authStore.auth.user.id, findField, itemId, source, tasks, userId],
  );

  const handleChange = useCallback(
    async (
      data: ActionType[],
      action?: 're-sort' | 'user' | 'dueDate' | '' | 'remove' | 'remove-link',
      fieldId?: string,
      item?: ActionType,
    ) => {
      if (action === 're-sort') {
        const formattedData = data
          .filter((value) => !!value.text)
          .map((value, idx) => {
            const computedValue = computeActionItem({...value, order: idx + 1});

            const taskExists = findField(tasks, value.key)?.firebaseId;

            if (!!taskExists) {
              updateFirebaseData(`action_items/${taskExists}`, computedValue);
            } else {
              writeFirebaseData(`action_items`, computedValue);
            }

            return computedValue;
          });

        callback && callback(formattedData);
        return;
      }

      const taskExists = findField(tasks, fieldId || '')?.firebaseId;

      if (action === 'remove' && taskExists) {
        removeFirebaseData(`action_items/${taskExists}`);
        return;
      }

      const snapshot = await get(
        query(databaseRef, orderByChild('id'), equalTo(fieldId || '')),
      );

      const actionitem = computeActionItem(item, action === 'remove-link');

      if (snapshot.exists()) {
        const actionItem = snapshot.val();

        const firebaseKey = Object.keys(actionItem)?.[0];

        await updateFirebaseData(`action_items/${firebaseKey}`, actionitem);
        await fetchData();

        return;
      }

      if (!!item && !!actionitem?.text) {
        try {
          await writeFirebaseData(`action_items`, actionitem);
          await fetchData();
        } catch (error) {
          activateNotification({
            title: 'Error',
            content: 'Something went wrong',
            kind: 'error',
          });
        }
      }
    },
    [findField, tasks, databaseRef, computeActionItem, callback, fetchData],
  );

  return {
    handleChange,
    isLoading,
    tasks,
  };
};
