import {CSSProperties, memo} from 'react';

export const ArrowHeadRightIcon = memo(({style}: {style?: CSSProperties}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    style={style}
    fill="white"
    viewBox="0 0 16 16">
    <path strokeLinecap="round" strokeLinejoin="round" d="M6 12l4-4-4-4" />
  </svg>
));

ArrowHeadRightIcon.displayName = 'ArrowHeadRightIcon';
