import styled from 'styled-components';
import {memo} from 'react';
import {Typography} from '../typography/typography';
import {getColorFromTheme} from '../../ui-utils';

export enum ListKind {
  CIRCLE = 'circle',
  DECIMAL = 'decimal',
  NONE = 'none',
  DISC = 'disc',
  LOWERALPHA = 'lower-alpha',
  UPERALPHA = 'upper-alpha',
  SQUARE = 'square',
}

const Ol = styled.ol<{kind?: ListKind}>`
  list-style-type: ${({kind}) => kind || 'decimal'} !important;
  list-style: ${({kind}) => kind || 'decimal'} !important;
  display: block;
`;

const Li = styled.li`
  ${Typography};
  line-height: 1.7;
  font-size: 14px;
  color: ${getColorFromTheme('textBody')};
  list-style-position: inside;
`;

export interface ListProps {
  kind?: ListKind;
  items: string[];
}

export const List = memo(function ({kind, items}: ListProps) {
  return (
    <Ol kind={kind}>
      {items.map((item) => (
        <Li key={item}>{item}</Li>
      ))}
    </Ol>
  );
});
List.displayName = 'List';
