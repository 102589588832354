import {getColorFromTheme} from '@ui/ui-utils';
import styled from 'styled-components';

export const SecondRowWrapper = styled.div`
  width: 100%;

  max-width: 100%;
  overflow-x: auto;
  min-width: 900px;

  display: grid;
  div.header {
    min-width: 900px;
  }
`;

export const HeadlineWrapper = styled.div`
  display: grid;
  width: 100%;

  grid-template-columns: 19% 15% 15% 17% 11% 10% 12%;
  padding-left: 16px;
`;

export const HeadlineWrapperContainer = styled.div`
  display: block;
  width: 100%;

  padding: 16px 16px 12px 0px;
  background-color: ${getColorFromTheme('backgroundLight')};
  white-space: nowrap;
  height: 52px;
`;
export const SettlementWrapper = styled.div<{bg: string; svgColor: string}>`
  padding: 4px 8px;
  width: 34px;
  height: 26px;

  background: ${({bg}) => bg};
  border-radius: 8px;
  svg {
    path {
      stroke: ${({svgColor}) => svgColor};
    }
  }
`;

export const ItemLoaderWrapper = styled.div`
  padding: 28px 12px 32px 22px;
  justify-content: center;
  width: 100%;
  border-top: 1px solid #ededf2;
`;

export const ItemWrapperContainer = styled.div``;

export const ItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 19% 18% 18% 14% 13% 10% 10%;
  padding: 16px;
  border-top: 1px solid ${getColorFromTheme('borderLight')};
  align-items: center;
  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
