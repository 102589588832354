export const filterDuplicateItems = (array: any[], field: string) => {
  const uniqueItems: any[] = [];

  array.forEach((item) => {
    // Check if the item's ID already exists in the uniqueItems array
    const index = uniqueItems.findIndex((x) => x[field] === item[field]);

    // If the ID does not exist, add the item to the uniqueItems array
    if (index === -1) {
      uniqueItems.push(item);
    }
  });

  return uniqueItems;
};
export const filterDuplicateString = (array: any[]) => {
  const uniqueItems: any[] = [];

  array.forEach((item) => {
    // Check if the item's ID already exists in the uniqueItems array
    const index = uniqueItems.findIndex((x) => x === item);

    // If the ID does not exist, add the item to the uniqueItems array
    if (index === -1) {
      uniqueItems.push(item);
    }
  });

  return uniqueItems;
};

export function sumArray(numbers: number[]) {
  const sum = numbers?.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0,
  );
  return sum;
}

// Helper function to separate an array into two based on a condition
export const partition = <T>(
  arr: T[],
  condition: (item: T) => boolean,
): [T[], T[]] =>
  arr.reduce<[T[], T[]]>(
    (acc, item) =>
      condition(item)
        ? [[...acc[0], item], acc[1]]
        : [acc[0], [...acc[1], item]],
    [[], []],
  );
