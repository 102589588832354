import React from 'react';
import {
  HeadlineWrapper,
  HeadlineWrapperContainer,
  ItemWrapper,
  ItemWrapperContainer,
  SecondRowWrapper,
  TableContainer,
} from './styles';
import {Body1, Body2, CTA} from '@ui/atoms/typography';
import {EmptyPlaceholder} from '@ui/atoms/empty-placeholder';
import {UserListCard} from '@ui/molecules/user/list-card';
import {cloudinary} from '@assets/images/cloudinary';
const {emptyGoals} = cloudinary;

interface SummaryProps {
  users: {
    name: string;
    avatar: string;
    total: number;
    assigned: number;
    active: number;
    overdue: number;
  }[];
  breakdownBy: string;
}

const emptyPlaceholder = {
  image: {
    alt: 'alt text',
    shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
    src: emptyGoals,
    style: {height: '30%', width: '30%'},
  },
  imports: true,
  subtitle: 'Refine your search or try something new.',
  title: 'No results found',
};

export const AnalyticsActionItemsSummaryPage = ({
  users,
  breakdownBy,
}: SummaryProps) => {
  const isBoards = breakdownBy === 'boards';
  const isGroup = breakdownBy === 'groups';
  const isManager = breakdownBy === 'teams';
  const showAvatar = !isGroup && !isBoards;

  return (
    <div className="bg-white rounded-lg border border-borderLight">
      <div className="py-4 px-6">
        <Body2 weight="bold">Summary ({users.length})</Body2>
      </div>
      <TableContainer>
        <div style={{display: 'flex', overflowX: 'scroll'}}>
          <SecondRowWrapper>
            <HeadlineWrapperContainer className="header">
              <HeadlineWrapper>
                <Body2 weight="semibold">
                  {isBoards
                    ? 'Boards'
                    : isGroup
                    ? 'Groups'
                    : isManager
                    ? 'Manager'
                    : 'Name'}
                </Body2>
                <Body2 weight="semibold">
                  {isBoards ? 'All items' : 'Assigned items'}
                </Body2>
                <Body2 weight="semibold">Open items</Body2>
                <Body2 weight="semibold">Overdue items</Body2>
              </HeadlineWrapper>
            </HeadlineWrapperContainer>

            {users.length ? (
              users.map((user, index) => (
                <ItemWrapperContainer key={index}>
                  <ItemWrapper>
                    <div>
                      {showAvatar ? (
                        <UserListCard
                          tooltip={false}
                          userId={user?.name}
                          type="secondary"
                          reviewer={''}
                          avatar={user?.avatar}
                          name={user.name}
                          TextComponent={CTA}
                          textKind="textDark"
                          tooltipText
                        />
                      ) : (
                        <Body2 weight="semibold">{user.name}</Body2>
                      )}
                    </div>
                    <div>
                      <Body1>{isBoards ? user.total : user.assigned}</Body1>
                    </div>
                    <div>
                      <Body1>{user.active}</Body1>
                    </div>

                    <div>
                      <Body1>{user.overdue}</Body1>
                    </div>
                  </ItemWrapper>
                </ItemWrapperContainer>
              ))
            ) : (
              <EmptyPlaceholder {...emptyPlaceholder} />
            )}
          </SecondRowWrapper>
        </div>
      </TableContainer>
    </div>
  );
};
