import {DashCircle} from '@ui/atoms/icons/dash-circle';
import {FlashIcon} from '@ui/atoms/icons/flash-icon';
import styled from 'styled-components';
import {FeedbackController} from '../feedback-controller';
import {
  DesktopWrapper,
  MobileWrapper,
} from '@ui/molecules/workspace/logo-toggle/logo-toggle';
import {FeedbackRequestImpl} from '../feedback-requests';
import {Body2} from '@ui/atoms/typography';
import {useCallback, useState, useEffect} from 'react';
import {DashDotCircle} from '@ui/atoms/icons/dash-dot-circle';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
const Impression = styled.div<{
  background?: string;
  color?: string;
  disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  cursor: ${({disabled}) => (disabled ? 'not-allowed' : 'pointer')};
  border-radius: 8px;
  width: fit-content;
  margin-right: 5px;
  background: ${({background}) => background};

  p {
    color: ${({color}) => color};
  }
  svg {
    margin-right: 3px;
    path {
      stroke: ${({color}) => color};
    }
  }
`;

const impressions = [
  {
    icon: <FlashIcon />,
    background: '#F0FAF6',
    color: '#47B881',
    title: 'Commend',
  },
  {
    icon: <DashCircle />,
    background: '#F6F6F8',
    color: '#5F5F8C',
    title: 'Neutral',
  },
  {
    icon: <DashDotCircle />,
    background: '#FEF8F2',
    color: '#E87F16',
    title: 'Critic',
  },
];
export const SelectImpression = ({
  updateValue,
  value,
}: {
  updateValue: (value: string) => void;
  value: string;
}) => {
  const [selectedValue, setSelectedValue] = useState('');
  const [analysisUpdated, setAnalysisUpdated] = useState(false);

  const handleSelect = useCallback(
    (impression: string) => {
      setSelectedValue(impression);
      updateValue(impression);
    },
    [updateValue],
  );

  const getAnalysis = useCallback(async () => {
    const request = new FeedbackRequestImpl();
    const controller = new FeedbackController(request);
    const response = await controller.getImpressionAnalysis(value);

    response && handleSelect(response?.sentiment);
    response && setAnalysisUpdated(true);
  }, [value, handleSelect]);

  useEffect(() => {
    if (value.length > 20) {
      getAnalysis();
    }
    //eslint-disable-next-line
  }, [value]);

  return (
    <Wrapper>
      {impressions.map((impression) => (
        <Impression
          disabled={!analysisUpdated}
          onClick={() =>
            !analysisUpdated
              ? null
              : handleSelect(impression.title.toLowerCase())
          }
          background={
            selectedValue === impression.title.toLowerCase()
              ? impression.background
              : '#FAFAFA'
          }
          color={
            selectedValue === impression.title.toLowerCase()
              ? impression.color
              : '#BFBFD4'
          }>
          {impression.icon}
          {selectedValue === impression.title.toLowerCase() && (
            <MobileWrapper>
              <Body2 weight={'semibold'}>{impression.title}</Body2>
            </MobileWrapper>
          )}

          <DesktopWrapper>
            <Body2 weight={'semibold'}>{impression.title}</Body2>
          </DesktopWrapper>
        </Impression>
      ))}
    </Wrapper>
  );
};

export const GetImpression = ({impressionValue}: {impressionValue: string}) => {
  const findImpression = impressions.find(
    (impression) =>
      impression.title.toLowerCase() === impressionValue.toLowerCase(),
  );
  if (findImpression) {
    return (
      <>
        <Impression
          background={findImpression.background}
          color={findImpression.color}>
          {findImpression.icon}
          <DesktopWrapper>
            <Body2 weight={'semibold'}>{findImpression.title}</Body2>
          </DesktopWrapper>
        </Impression>
      </>
    );
  }
  return null;
};
