import {ThickPlusIcon} from '@ui/atoms/icons/thick-plus';
import {FlexRow, FlexRowSpaceBetween} from '@ui/style/styles';
import {NoBorderButton} from '@pages/dashboard/reporting/reporting-pages/reporting-overview/styles';
import {FC, useState} from 'react';
import {TextAreaNoBorder} from '@ui/atoms/textArea/textarea';
import {Body2} from '@ui/atoms/typography';
import {DeleteMiniDropdown} from '@ui/molecules/mini-modal/mini-modal';
import {useDropDown} from '@hooks';
import {CancelIcon} from '@ui/atoms/icons';
import {ifProp} from 'styled-tools';
import {HorizontalSideRule, VerticalSpacer} from '@ui/atoms/spacer/spacer';
import styled from 'styled-components';
import {Label} from '@ui/atoms/label';
import {SelectField} from '@ui/molecules/select/selectfield';
import {useTags} from '@hooks/tags';
import {ManageGoalTags} from '@pages/1:1s/create-meetings/goal-tags';
import {writeFirebaseData} from '@utils/firebase-handler';

const InputWrapper = styled.div<{disabled?: boolean}>`
  margin-top: 8px;
  border: 1px solid #cecede;
  border-radius: 10px;
  min-height: 140px;
  background-color: ${ifProp('disabled', '#FAFAFA', 'white')};
  padding: 16px;
  .tag {
    padding-bottom: 8px;
    position: relative;
    border-bottom: 1px solid #cecede;

    .dropdown {
      position: absolute;
      background: #ffffff;
      padding: 8px;
      min-width: 484px;
      z-index: 3;
      box-shadow: 0px 3px 40px rgba(208, 208, 221, 0.43);

      border-radius: 10px;
    }
    .selected-tag {
      background: #f6f6f8;
      padding: 2px 8px;

      margin-left: 4px;
      display: flex;
      align-items: center;
      border: 1px solid #cecede;
      border-radius: 8px;
      button {
        margin-left: 6px;
        margin-top: 2.5px;
      }
    }
  }
  .description {
    padding-top: 6px;
  }
`;

interface IAddDescription {
  onChange: (data: {tags: string[]; description: string}) => void;
  isGoalKpi?: boolean;
  initSavedMode?: boolean;
  value?: {tags: string[]; description: string};
}

const AddDescriptionAndTags: FC<IAddDescription> = ({
  onChange,
  value,
  isGoalKpi,
  initSavedMode,
}) => {
  const {tags} = useTags('goal_tags');

  const [manageTags, setManageTags] = useState(false);

  const isValue = value && value?.description?.length > 0;

  const [addDescription, setAddDescription] = useState(isValue ? true : false);

  const [tagValue, setTagValue] = useState(value?.tags[0] || '');

  const [description, setDescription] = useState(value?.description || '');

  const [saved, setSaved] = useState(
    initSavedMode && !!value?.description ? true : false,
  );

  const handleSave = () => {
    if (!saved) {
      onChange({
        tags: tagValue ? [tagValue] : [],
        description,
      });
    }

    setSaved(!saved);
  };

  const {
    handleOpen: handleModalOpen,
    open: modalOpen,
    ref: modalRef,
    handleClose: handleModalClose,
  } = useDropDown();

  const deleteDescriptionAndTag = () => {
    setDescription('');
    setTagValue('');
    setSaved(false);
    handleModalClose();
    setAddDescription(false);
    onChange({
      tags: [],
      description: '',
    });
  };

  const formattedTags = tags
    .filter((tag) => !!tag.tag?.name)
    .map((tag) => ({
      ...tag,
      tag: tag.tag?.name,
      goals: tag.tag?.goals,
    }));

  const saveDisabled = !tagValue && !description;

  return (
    <div>
      {!addDescription && (
        <>
          <NoBorderButton onClick={() => setAddDescription(true)}>
            <FlexRow>
              <ThickPlusIcon color="#47B881" />
              <Body2
                weight="bold"
                style={{fontSize: '12px', marginLeft: '6px'}}>
                Add description
              </Body2>
            </FlexRow>
          </NoBorderButton>
        </>
      )}
      {addDescription && (
        <>
          <FlexRowSpaceBetween>
            <Body2 weight="bold">
              Description{' '}
              <span style={{fontWeight: 400, color: '#5F5F8C'}}>
                (optional)
              </span>
            </Body2>
            <FlexRow>
              <NoBorderButton
                type={'button'}
                disabled={saveDisabled}
                onClick={handleSave}>
                <Body2
                  weight="semibold"
                  kind={saveDisabled ? 'textMuted' : 'purple300'}>
                  {saved ? 'Edit' : 'Save'}
                </Body2>
              </NoBorderButton>
              <div style={{margin: '0px 4px 0px 2px'}}>
                <HorizontalSideRule size="16px" />
              </div>
              {saved ? (
                <div>
                  <NoBorderButton onClick={handleModalOpen}>
                    <CancelIcon />
                  </NoBorderButton>

                  {modalOpen && (
                    <div ref={modalRef}>
                      <DeleteMiniDropdown
                        title="Delete description?"
                        description="The description will be removed from the goal. This cannot be undone."
                        onSubmit={deleteDescriptionAndTag}
                        onClickCancel={handleModalClose}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <NoBorderButton
                  type={'button'}
                  onClick={() => setAddDescription(false)}>
                  <Body2 weight="semibold">Close</Body2>
                </NoBorderButton>
              )}
            </FlexRow>
          </FlexRowSpaceBetween>
          <InputWrapper disabled={saved}>
            <div className="description">
              <TextAreaNoBorder
                value={description}
                maxLength={300}
                style={{
                  padding: '5px 0px',
                  fontSize: '14px',
                  pointerEvents: saved ? 'none' : 'auto',
                  backgroundColor: 'transparent',
                }}
                onChange={(event) => {
                  setDescription(event.target.value);
                  onChange({
                    tags: tagValue ? [tagValue] : [],
                    description: event.target.value,
                  });
                }}
                placeholder={'Add a description (max. 300 characters)'}
              />
            </div>
          </InputWrapper>
        </>
      )}

      {isGoalKpi && (
        <>
          <div className="relative mt-4">
            <Label>
              Tags{' '}
              <span className="text-[#5F5F8C] font-normal ">(optional)</span>
            </Label>
            <VerticalSpacer size="8px" />

            <SelectField
              placeholder="# - add tag"
              performance
              type="dropdownOnly"
              excludeSearchBox
              showCancelIcon={false}
              margin
              borderRadius="10px"
              defaultValue={value?.tags?.[0]}
              noOptionMessage={'No option Found'}
              maxSelection={1}
              groupDisplayLength={3}
              onClickCustomOption={() => {
                setManageTags(true);
              }}
              customOption
              customOptionName="Manage tags"
              options={[
                ...(formattedTags
                  ? formattedTags.map((tag) => ({
                      value: tag.tag,
                      label: tag.tag,
                    }))
                  : []),
                {
                  label: 'Custom',
                  value: 'custom-option',
                },
              ]}
              onChange={(data: {value: string; label: any | null}) => {
                if (data?.value.toLowerCase() === 'custom-option') return;

                onChange({
                  tags: data?.value ? [data?.value] : [],
                  description,
                });
              }}
            />
          </div>
        </>
      )}
      {manageTags && (
        <ManageGoalTags
          open={manageTags}
          onClose={() => setManageTags(false)}
          tags={formattedTags}
          onSubmit={(tag) => {
            writeFirebaseData(`goal_tags`, {
              name: tag,
              goals: [],
            });
          }}
        />
      )}
    </div>
  );
};

export default AddDescriptionAndTags;
