import {useStoreContext} from '@store/store-context';
import {useYouHook} from '@pages/dashboard/you/you-hook';
import {useApiCalls} from './init-calls';
import {useCallback, useMemo} from 'react';
import {getMany, get, set} from 'idb-keyval';
import {useTaskHook} from '@pages/dashboard/get-started/tasks/tasks-hook';

export const useIdbStore = () => {
  const {refetch: taskListRefetch, getTime} = useTaskHook();

  const {
    storeYouGoals: {replaceFetchedGoals},
    groupStore: {setGroup},
    taskStore: {updateCount, updateTasks},
    groupTypeStore: {setGroupTypeInStore},
    usersStore: {setUsersData},

    authStore: {auth},
  } = useStoreContext();

  const {refetch: goalsRefetch} = useYouHook();
  const {
    initGroupType,
    initGroups,
    initUsers,
    fetchWorkspaceData,
    fetchUserData,
  } = useApiCalls();
  const updateIDBStoreUser = useCallback(
    (data: any) => {
      set(`${auth.user.id}/currentStateKeyUsers`, data);
    },
    [auth.user.id],
  );

  const getKeysFromIDBUsers = useCallback(
    async (data: any) => {
      const getKeys = await get(`${auth.user.id}/currentStateKeyUsers`);

      const handleUpdateIDBStore = (
        prevData: any,
        data: any,
        field: string,
      ) => {
        if (!prevData) {
          updateIDBStoreUser(data);
        } else {
          updateIDBStoreUser({
            ...prevData,
            [field]: data[field],
          });
        }
      };

      Object.keys(data).forEach(async (key) => {
        if (!getKeys || getKeys[key] !== data[key]) {
          if (key === 'goals') {
            handleUpdateIDBStore(getKeys, data, 'goals');
            await goalsRefetch();
          } else if (key === 'tasks') {
            if (!getTime) {
              await taskListRefetch();
              handleUpdateIDBStore(getKeys, data, 'tasks');
            }
          }
        }
      });
    },
    [auth.user.id, updateIDBStoreUser, goalsRefetch, taskListRefetch, getTime],
  );

  const handleGroups = useCallback(
    (groupsVal: any[] | undefined) => {
      if (!groupsVal) {
        initGroups();
      } else {
        const excludeGeneralGroup = groupsVal.filter(
          (group) => group.name !== 'General',
        );

        setGroup(excludeGeneralGroup);
      }
    },
    [initGroups, setGroup],
  );

  const handleGroupType = useCallback(
    (groupTypeVal: any[] | undefined) => {
      if (!groupTypeVal) {
        initGroupType();
      } else {
        setGroupTypeInStore(groupTypeVal);
      }
    },
    [initGroupType, setGroupTypeInStore],
  );

  const handleActiveGoals = useCallback(
    async (activeGoalsVal: any[] | undefined) => {
      if (!activeGoalsVal) {
        await goalsRefetch();
      } else {
        await replaceFetchedGoals(activeGoalsVal);
      }
    },
    [replaceFetchedGoals, goalsRefetch],
  );

  const handleTasks = useCallback(
    async (taskData: any, taskListCount: number) => {
      if (!taskData || !taskListCount) {
        if (!getTime) {
          await taskListRefetch();
        }
      } else {
        if (!getTime) {
          updateTasks(taskData);

          updateCount(taskListCount);
        }
      }
    },
    [taskListRefetch, updateTasks, updateCount, getTime],
  );

  const handleAllUsers = useCallback(
    async (users, invitedMembers) => {
      if (!users || !invitedMembers) {
        await initUsers();
      } else {
        setUsersData(users);
        await initUsers();
      }
    },
    [initUsers, setUsersData],
  );

  useMemo(() => {
    getMany([
      `${auth.user.workspace.id}/groups`,
      `${auth.user.workspace.id}/groupsType`,
      `${auth.user.workspace.id}/${auth.user.id}/active-goals`,
      `${auth.user.workspace.id}/users`,
      `${auth.user.workspace.id}/${auth.user.id}/direct-reports`,
      `${auth.user.workspace.id}/${auth.user.id}/taskList`,
      `${auth.user.workspace.id}/${auth.user.id}/taskListCount`,
      `${auth.user.workspace.id}/invited-users`,
    ]).then((val) => {
      handleGroups(val[0]);
      handleGroupType(val[1]);
      handleActiveGoals(val[2]);
      handleAllUsers(val[3], val[7]);
      handleTasks(val[5], val[6]);
    });
  }, [
    handleGroups,
    handleGroupType,
    handleActiveGoals,
    handleAllUsers,
    handleTasks,
    auth.user.workspace.id,
    auth.user.id,
  ]);
  const updateIDBStore = useCallback(
    (data: any) => {
      set(`${auth.user.workspace.id}/currentStateKey`, data);
    },
    [auth.user.workspace.id],
  );

  const storeOp = useMemo(() => {
    const handleUpdateIDBStore = (prevData: any, data: any, field: string) => {
      if (!prevData) {
        updateIDBStore(data);
      } else {
        updateIDBStore({
          ...prevData,
          [field]: data[field],
        });
      }
    };
    return {
      goalUpdateOp: async (data: any, prevData: any) => {
        handleUpdateIDBStore(prevData, data, 'goals');
        await goalsRefetch();
      },
      userUpdateOp: (data: any, prevData: any) => {
        handleUpdateIDBStore(prevData, data, 'user');
        fetchUserData();
        initUsers();
      },

      goalReadingOp: () => {},
      groupUpdateOp: (data: any, prevData: any) => {
        handleUpdateIDBStore(prevData, data, 'group');
        initGroups();
      },
      settingsUpdateOp: (data: any, prevData: any) => {
        handleUpdateIDBStore(prevData, data, 'settings');

        fetchWorkspaceData();
      },

      groupReadingOp: () => {},
      groupTypeUpdateOp: (data: any, prevData: any) => {
        initGroupType();

        if (!prevData) {
          updateIDBStore(data);
        } else {
          updateIDBStore({
            ...prevData,
            groupType: data.groupType,
          });
        }
      },
      groupTypeReadingOp: () => {},
    };
  }, [
    initGroupType,
    initGroups,
    updateIDBStore,
    fetchWorkspaceData,
    fetchUserData,
    goalsRefetch,
    initUsers,
  ]);

  const getKeysFromIDB = useCallback(
    async (data: any) => {
      const getKeys = await get(`${auth.user.workspace.id}/currentStateKey`);

      Object.keys(data).forEach((key) => {
        if (!getKeys || getKeys[key] !== data[key]) {
          if (key === 'group') {
            storeOp.groupUpdateOp(data, getKeys);
          } else if (key === 'groupType') {
            storeOp.groupTypeUpdateOp(data, getKeys);
          } else if (key === 'user') {
            storeOp.userUpdateOp(data, getKeys);
          } else if (key === 'settings') {
            storeOp.settingsUpdateOp(data, getKeys);
          } else if (key === 'apps') {
          }
        }
      });
    },
    [auth.user.workspace.id, storeOp],
  );

  return {
    getKeysFromIDBUsers,
    getKeysFromIDB,
  };
};
