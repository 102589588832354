import {object, string, array} from 'yup';

export const WorkspaceInviteValidator = object().shape({
  emails: array()
    .of(
      object().shape({
        group: array().of(string()),
        manager: string().required(),
      }),
    )
    .max(
      7,
      'You can invite up to 7 people at once, you can invite your members later',
    )
    .min(1)
    .required(),
});

export const WorkspaceInviteCsvValidator = object().shape({
  inviteFile: string(),
});
