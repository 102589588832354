import {IMembersFormatFilter} from '@hooks';
import {useMemo} from 'react';
import {usePeriodHook} from '@hooks/period-hook';
import {useStoreContext} from '@store/store-context';
import {FeedbackController} from '../feedback-controller';
import {FeedbackRequestImpl} from '../feedback-requests';
import {useState} from 'react';
import {IOptions} from '../components/values-themes-filter';
import {AnalyticsTodayDate} from '@utils/date';
import {useNavigate} from 'react-router';

export const usePublicWallHook = () => {
  const [userFilter, setUserFilter] = useState({
    filterBy: {
      label: 'People',
      value: 'people',
    },
    selectedTeam: {
      value: '',
      label: '',
    },
    members: [] as Array<IMembersFormatFilter>,
  });
  const {
    usersStore: {users},
  } = useStoreContext();

  const {isPeriodEnabled, currentMonthRange} = usePeriodHook();

  const navigate = useNavigate();

  const handleWriteFeedback = () => {
    navigate('/write-feedback');
  };
  const handleRequestFeedback = () => {
    navigate('/request-feedback');
  };
  const handleWritePraise = () => {
    navigate('/write-feedback?type=praise');
  };

  const [valueThemeFilter, setValueThemeFilter] = useState<IOptions>({
    filterBy: '',
    theme: '',
    value: '',
  });

  const getPeriod = useMemo(() => {
    if (isPeriodEnabled && currentMonthRange().starts) {
      return {
        starts: currentMonthRange().starts,
        ends: currentMonthRange().ends,
        period: currentMonthRange().period,
      };
    }

    return {
      starts: AnalyticsTodayDate().AnalyticsDefaultStartDate,
      ends: AnalyticsTodayDate().AnalyticsDefaultEndDate,
    };
  }, [isPeriodEnabled, currentMonthRange]);

  const [dateRange, setDateRange] = useState(getPeriod);

  const request = useMemo(() => new FeedbackRequestImpl(), []);
  const controller = useMemo(() => new FeedbackController(request), [request]);

  const formatFilteredUser = useMemo(() => {
    const manager = userFilter.selectedTeam.value;
    if (userFilter.members?.length > 0) {
      return userFilter.members.map((member) => member.value).join(',');
    }

    if (manager) {
      return users
        .filter((user) => (user.reviewer?.id || user?.reviewer) === manager)
        .map((user) => user.id)
        .join(',');
    }
  }, [userFilter.selectedTeam.value, users, userFilter.members]);

  const filters = useMemo(() => {
    return {
      startDate: dateRange.starts,
      endDate: dateRange.ends,
      impression: valueThemeFilter.theme,
      values: valueThemeFilter.value,
      user: formatFilteredUser || undefined,
    };
  }, [
    dateRange.starts,
    formatFilteredUser,
    dateRange.ends,
    valueThemeFilter.theme,
    valueThemeFilter.value,
  ]);

  return {
    userFilter,
    setUserFilter,
    controller,
    filters,
    dateRange,
    handleWriteFeedback,
    handleRequestFeedback,
    handleWritePraise,
    setDateRange,
    valueThemeFilter,
    setValueThemeFilter,
  };
};
