import {object, string, array} from 'yup';
import {QuestionKind} from './company-checkins.interface';

export class CreateQuestionsValidatorSchema {
  static get createQuestion() {
    return object().shape({
      type: string(),
      text: string()
        .min(1, 'You must select a question')

        .required(),
    });
  }

  static get createQuestions() {
    return object().shape({
      target: object().shape({
        type: string(),
        ids: object().when(['type'], {
          is: (targetType: QuestionKind) =>
            targetType === QuestionKind.INDIVIDUAL,
          then: object().shape({
            individual: array()
              .of(string())
              .min(1, 'Select at least 1 individual')
              .required('Individual is a required field'),
            group: array().of(string()),
          }),
          otherwise: object().when(['type'], {
            is: (targetType: QuestionKind) => targetType === QuestionKind.GROUP,
            then: object().shape({
              individual: array().of(string()),
              group: array()
                .of(string())
                .min(1, 'Select at least 1 group')
                .required('Group is a required field'),
            }),
            otherwise: object().shape({
              individual: array().of(string()),
              group: array().of(string()),
            }),
          }),
        }),
      }),
      frequency: string(),
      questions: array()
        .of(CreateQuestionsValidatorSchema.createQuestion)
        .min(1, 'You must add at least 1 question')
        .required(),
    });
  }
}
