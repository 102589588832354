import {FC, useState} from 'react';
import {BehaviourWrapper, GoalRating, MultiOption, EmptyGoal} from './styles';
import {useStoreContext} from '@store/store-context';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {Body2} from '@ui/atoms/typography';

import {TextField} from '@ui/molecules/field/textfield';
import {FlexRow, FlexRowSpaceBetween} from '@ui/style/styles';
import {HorizontalSideRule} from '@ui/atoms/spacer/spacer';
import {PlainButton} from '@ui/atoms/plain-button/plain-button';
import {CancelIcon} from '@ui/atoms/icons';
import {DeleteMiniDropdown} from '@ui/molecules/mini-modal/mini-modal';
import {IGoalResult, useDropDown} from '@hooks';
import {ListGoal} from '@pages/dashboard/you/you-components/you-hightligts';
import {RadioSm} from '@ui/atoms/radio';

type OptionSelectProps = {
  onChange: (value: {
    comment: string;
    id: string;
    rating: number;
    goals?: {goal: string; comment?: string; score: number}[];
  }) => void;
  goals?: IGoalResult[];
  useGoals?: boolean;
  id: string;
};

export const OptionSelect = ({
  onChange,
  id,
  goals,
  useGoals,
}: OptionSelectProps) => {
  const [selectedOption, setSelectedOption] = useState('');
  const [comment, setComment] = useState('');

  const [goalsResponse, setGoalsResponse] = useState<
    {goal: string; note?: string; score: number}[] | undefined
  >(
    goals?.map((goal) => ({
      goal: goal.id,
      note: '',
      score: 0,
    })),
  );
  const {
    ccStore: {ccReviewScale},
  } = useStoreContext();

  const handleSelect = (
    option: {
      detail: string;
      title: string;
      score: number;
    },
    comment: string,
  ) => {
    setComment(comment);
    onChange({
      comment,
      id,
      rating: option.score,
    });
  };

  const handleGoalsChange = ({
    goal,
    comment,
    score,
  }: {
    goal: string;
    comment?: string;
    score?: any;
  }) => {
    const updatedResponse = goalsResponse?.map((_goal) =>
      _goal.goal === goal
        ? {goal, note: comment ?? _goal.note, score: score || _goal.score}
        : _goal,
    );

    const scores = updatedResponse
      ?.filter((goal) => goal.score)
      .map((goal) => goal.score);

    const totalScore = scores?.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0,
    );

    const averageScore = Math.round(totalScore / (scores?.length || 0));

    setGoalsResponse(updatedResponse);
    onChange({
      comment: '',
      id,
      goals: updatedResponse,
      rating: averageScore,
    });
  };

  if (useGoals) {
    return (
      <>
        {goals?.length ? (
          goals?.map((goal, idx) => (
            <div
              className={`${
                idx === goals.length - 1
                  ? ''
                  : 'border-b-[#EDEDF2] border-b mb-6 pb-6'
              } `}
              key={goal.id}>
              <GoalReview
                goal={goal}
                goals={goals}
                goalResponse={goalsResponse?.find(
                  (_goal) => goal.id === _goal.goal,
                )}
                handleChange={(value) => handleGoalsChange(value)}
              />
            </div>
          ))
        ) : (
          <EmptyGoal className="px-4 py-3  rounded-[10px] flex items-center justify-center">
            <Body2 kind="red400">
              We couldn't find any objectives for the review period.
            </Body2>
          </EmptyGoal>
        )}
      </>
    );
  }

  return (
    <>
      {ccReviewScale.map((option) => (
        <div style={{marginBottom: '20px'}}>
          <MultiOption
            onClick={() => {
              handleSelect(option, comment);
              setSelectedOption(option.title);
            }}
            active={selectedOption === option.title}>
            <Body2 style={{fontWeight: 500, textTransform: 'capitalize'}}>
              {option.title}
            </Body2>
            <VerticalSpacer size="8px" />
            <Body2 kind="textBody">{option.detail}</Body2>
          </MultiOption>

          {selectedOption === option.title && (
            <BehaviourWrapper>
              <BehaviouralInput
                handleSelect={(comment) => handleSelect(option, comment)}
              />
            </BehaviourWrapper>
          )}
        </div>
      ))}
    </>
  );
};

interface GoalReviewProps {
  goal: IGoalResult;
  goalResponse?: {goal: string; score: number};
  goals: IGoalResult[];
  handleChange: (value: any) => void;
}
const GoalReview = ({
  goal,
  goals,
  handleChange,
  goalResponse,
}: GoalReviewProps) => {
  const [isOpen, setIsOpen] = useState(goal.id);

  const {
    ccStore: {ccReviewScale},
  } = useStoreContext();

  const scaleOptions = [...ccReviewScale].reverse();

  return (
    <>
      <ListGoal
        goal={goal}
        variant={'companyTeams'}
        goals={goals}
        kind="cc-review"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      <VerticalSpacer size="16px" />
      <GoalRating>
        <FlexRowSpaceBetween>
          {scaleOptions.map((scale) => (
            <FlexRow
              onClick={() =>
                handleChange({
                  goal: goal.id,
                  score: scale.score,
                })
              }>
              <RadioSm checked={goalResponse?.score === scale.score} />
              <Body2 weight="semibold" style={{marginLeft: '2px'}}>
                {scale.title}
              </Body2>
            </FlexRow>
          ))}
        </FlexRowSpaceBetween>
        <VerticalSpacer size="20px" />
        <BehaviouralInput
          handleSelect={(comment) =>
            handleChange({
              goal: goal.id,
              comment,
            })
          }
        />
      </GoalRating>
    </>
  );
};

interface InputProps {
  handleSelect: (comment: string) => void;
}

const BehaviouralInput: FC<InputProps> = ({handleSelect}) => {
  const {
    handleOpen: handleModalOpen,
    open: modalOpen,
    ref: modalRef,
    handleClose: handleModalClose,
  } = useDropDown();

  const [comment, setComment] = useState('');

  const [commentSaved, setCommentSaved] = useState(false);

  const [disableButton, setDisableButton] = useState(comment.length < 29);

  return (
    <>
      <FlexRowSpaceBetween>
        <Body2 style={{fontWeight: 500}}>
          What other Behaviour were considered before giving him the rating
          above?{' '}
          <span style={{color: '#5F5F8C', fontWeight: 400}}>(optional)</span>
        </Body2>
        <FlexRow>
          {!commentSaved ? (
            <>
              <PlainButton
                disabled={disableButton}
                onClick={() => {
                  handleSelect(comment);
                  setCommentSaved(true);
                }}>
                <Body2
                  weight="semibold"
                  kind={disableButton ? 'textMuted' : 'purple300'}>
                  Save
                </Body2>
              </PlainButton>{' '}
              <HorizontalSideRule
                size="17px"
                style={{margin: '0px 8px 0px 3px'}}
              />
              <PlainButton
                disabled={!comment}
                onClick={() => {
                  setComment('');
                }}>
                <Body2
                  weight="semibold"
                  kind={!comment ? 'textMuted' : 'textDark'}>
                  Clear
                </Body2>
              </PlainButton>
            </>
          ) : (
            <>
              <PlainButton
                onClick={() => {
                  setCommentSaved(false);
                }}>
                <Body2 weight="semibold">Edit</Body2>
              </PlainButton>{' '}
              <HorizontalSideRule
                size="17px"
                style={{margin: '0px 8px 0px 3px'}}
              />
              <div>
                <PlainButton
                  style={{marginTop: '3px'}}
                  onClick={handleModalOpen}>
                  <CancelIcon />
                </PlainButton>
                {modalOpen && (
                  <div ref={modalRef}>
                    <DeleteMiniDropdown
                      title="Delete context?"
                      description="The context will be removed from the rating. This cannot be undone."
                      onSubmit={() => {
                        handleSelect('');
                        setComment('');
                        setCommentSaved(false);
                      }}
                      onClickCancel={handleModalClose}
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </FlexRow>
      </FlexRowSpaceBetween>

      <VerticalSpacer size="12px" />

      <TextField
        className="textField"
        multiple
        margin
        inputStyle={{minHeight: '100px', background: 'white'}}
        disabled={commentSaved}
        value={comment}
        onChange={(e) => {
          setComment(e.target.value);

          if (e.target.value.length >= 30) {
            setDisableButton(false);
          }
        }}
        placeholder="Please provide more context here (min. 30 characters)."
      />
      <VerticalSpacer size="8px" />
    </>
  );
};
