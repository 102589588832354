import {FC, useState} from 'react';
import {observer} from 'mobx-react';
import {IGoalResult} from '../../../../hooks';
import {ItemLoader} from '../../../../ui/organisms/item-loader';
import {EditableGoal} from '../../../../ui/organisms/editable-goal';
import {VerticalSpacer} from '../../../../ui/atoms/spacer';
import {EmptyPlaceholder} from '../../../../ui/atoms/empty-placeholder';
import {goalDueDate} from '../../../../utils/date';
import {GoalProgress} from '../../../../utils/proportions';
import {cfv} from '../../../../utils/framework';
import {Helper} from '../../../../ui/atoms/helper';
import {cloudinary} from '../../../../assets/images/cloudinary';
import {useStoreContext} from '../../../../store/store-context';
import {Body2} from '../../../../ui/atoms/typography';

const {emptyGoals: emptyGoal} = cloudinary;
interface IYouHightLight {
  isFetching: boolean;
  goals: Map<string, any>;
  managerEdit?: boolean;
  upcomingGoals?: boolean;
  type?: string;
  onUpdateReverted?: (goalId: string) => void;
  userGoals?: any;
  onGoalUpdate?: (goalId: string) => void;
  setUserGoals?: any;
  disabled?: boolean;
  variant?: string | undefined;
  showObjectiveProgress?: boolean;
  kind?: string;
  youCheckinsKind?: string;
}

export const formatGoal = (goal: any) => {
  const goalOwner = {
    label: {
      avatar: {
        name: goal?.assignee?.firstName
          ? `${goal?.assignee?.firstName} ${goal?.assignee?.lastName}`
          : goal?.assignee?.email,
        src: goal?.assignee?.avatar ? goal?.assignee?.avatar.url : '',
        active: true,
        id: goal?.assignee?.id,
        isUserDeleted: !!goal?.assignee?.deletedAt,
        reviewer: goal?.assignee?.reviewer,
      },
      id: goal?.assignee?.id,
      name: goal?.assignee?.firstName
        ? `${goal?.assignee?.firstName} ${goal?.assignee?.lastName}`
        : goal?.assignee?.email,
    },
    value: 'string',
  };

  const keyResultOwner: any = () => {
    if (goal?.keyResults?.length > 0) {
      const krs = goal?.keyResults.map((item: any) => item.assignee);
      const assigneeGoals = [
        ...Array.from(
          new Map(krs.map((item: any) => [item['id'], item])).values(),
        ),
      ];
      const EachAssignee = assigneeGoals.map((kr: any) => {
        return {
          label: {
            avatar: {
              name: kr.firstName ? `${kr.firstName} ${kr.lastName}` : kr.email,
              src: kr.avatar ? kr.avatar.url : '',
              isUserDeleted: !!kr?.assignee?.deletedAt,

              id: kr.id,
              reviewer: kr.reviewer,
            },
            id: kr.id,
            name: kr.firstName ? `${kr.firstName} ${kr.lastName}` : kr.email,
          },
        };
      });
      let goalandKeyResultsAssignees = EachAssignee.find(
        (assignee) => assignee.label.avatar.id === goalOwner.label.id,
      )
        ? [
            goalOwner,
            ...EachAssignee.filter(
              (assignee) => assignee.label.avatar.id !== goalOwner.label.id,
            ),
          ]
        : [goalOwner, ...EachAssignee];

      const ites = goalandKeyResultsAssignees.map((item: any) => item.label);
      const assigneeGoalsKeyResults = [
        ...Array.from(
          new Map(ites.map((item: any) => [item['id'], item])).values(),
        ),
      ];

      return assigneeGoalsKeyResults;
    }
  };

  function isGoalOnTrack() {
    if (!goal || !goal.isKpi) return null;

    if (goal.meta && goal.meta.type === 'positive') {
      return goal.currentValue > goal.targetValue ? 'on_track' : 'at_risk';
    } else if (goal.meta && goal.meta.type === 'negative') {
      return goal.currentValue < goal.targetValue ? 'on_track' : 'at_risk';
    }
  }

  function getGoalPerformance() {
    return goal ? goal.performance : null;
  }

  return {
    ...goal,
    id: goal?.id,
    measurement: goal?.measurement.unit.toUpperCase(),
    currencySymbol: goal?.measurement?.symbol
      ? goal?.measurement?.symbol
      : undefined,
    keyResults: goal?.keyResults,
    goalMeasurement: goal?.measurement,
    isKpi: goal?.isKpi,
    progress: {
      due: goalDueDate(goal?.endDate),
      percent: goal?.isKpi ? goal?.currentValue : goal?.percentageProgress,
      state: `${goal?.percentageProgress}%`,
      // @ts-ignore
      type: isGoalOnTrack() || getGoalPerformance(),
      status: goal?.status,
      endDate: goal?.endDate,
    },
    group: goal?.group,
    name: goal?.name,
    open: goal?.open,
    goalType:
      goal?.goalType.toLowerCase() === 'group'
        ? `${goal?.groupType?.name ?? ''} - ${goal?.groupName ?? ''}`
        : goal?.goalType === 'company'
        ? 'Company-wide'
        : 'Self-development',
    totalUsers: goal?.keyResults?.length > 0 ? keyResultOwner().length : 1,
    // @ts-ignore
    type: 'COMPANY',
    users: goalOwner
      ? goal?.keyResults?.length > 0
        ? keyResultOwner()?.map((item: any) => item.avatar)
        : [goalOwner.label.avatar]
      : [],
    currentValue: GoalProgress(goal).value,
    targetValue: goal?.targetValue,
    assignee: {...goal?.assignee, name: goalOwner.label.avatar},
    createdBy: goal?.createdBy,
  };
};

export const ListGoal: FC<{
  goal: any;
  variant?: string;
  disabled?: boolean;
  onUpdateReverted?: (id: string) => void;
  onGoalUpdate?: (goalId: string) => void;
  type?: string;
  managerEdit?: boolean;
  goals?: any;
  helper?: string;
  upcomingGoals?: boolean;
  showObjectiveProgress?: boolean;
  isOpen: string;
  setIsOpen: any;
  state?: string;
  kind?: string;
  youCheckinsKind?: string;
}> = observer(
  ({
    goal,
    variant,
    managerEdit,
    kind,
    type,
    goals,
    setIsOpen,
    isOpen,
    upcomingGoals,
    disabled,
    onGoalUpdate,
    state,
    showObjectiveProgress,
    onUpdateReverted,
    helper,
    youCheckinsKind,
  }: any) => {
    const goal_: IGoalResult = formatGoal(goal);

    return (
      <>
        <EditableGoal
          key={goal_.id}
          id={goal_.id}
          measurement={goal_.measurement as any}
          currencySymbol={goal_.currencySymbol}
          keyResults={goal_.keyResults}
          progress={goal_.progress}
          name={goal_.name}
          totalUsers={goal_.totalUsers}
          type={goal_.type}
          upcomingGoals={upcomingGoals}
          goal={goal}
          goals={goals}
          managerEdit={managerEdit}
          onUpdateReverted={onUpdateReverted}
          disabled={disabled}
          onGoalUpdate={onGoalUpdate}
          goalType={goal_.goalType}
          isOpen={isOpen}
          showObjectiveProgress={showObjectiveProgress}
          setIsOpen={setIsOpen}
          users={goal_.users}
          variantType={type}
          currentValue={goal_.currentValue}
          targetValue={goal_.targetValue}
          assignee={goal_.assignee}
          createdBy={goal_.createdBy}
          variant={variant}
          kind={kind}
          youCheckinsKind={youCheckinsKind}
          errored={state === 'error'}
        />

        {!(variant && variant === 'companyReportPDF') && (
          <VerticalSpacer size="10px" />
        )}
      </>
    );
  },
);

export const YouHightLight: FC<IYouHightLight> = observer(
  ({
    isFetching,
    goals,
    variant,
    youCheckinsKind,
    onGoalUpdate,
    onUpdateReverted,
    kind,
    type,
    showObjectiveProgress,
    disabled,
    upcomingGoals,
  }) => {
    const [
      companyTeamsVariantSeeMore,
      setCompanyTeamsVariantSeeMore,
    ] = useState<boolean>(false);
    //
    const [isOpen, setIsOpen] = useState('');

    const {
      storeYouGoals: {youGoalsFilter},

      checkinsStore: {userGoals},
      companyStore: {deleteLoading, commentRef},
    } = useStoreContext();

    const EmptyHighlight = {
      image: {
        alt: 'alt text',
        shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
        src: emptyGoal,
        style: {height: '50%', width: '50%'},
      },
      subtitle: 'Create a goal from scratch or choose from our templates.',
      title: `You  have no ${youGoalsFilter} goals.`,
    };

    if (variant && variant === 'companyTeams') {
      const teamsGoals =
        companyTeamsVariantSeeMore || commentRef !== ''
          ? Array.from(goals)
          : Array.from(goals).slice(0, 2);

      teamsGoals.filter(([key, value], index) =>
        deleteLoading.includes(value.id) ? goals.delete(key) : null,
      );

      if (isFetching) {
        return <ItemLoader />;
      }

      return (
        <>
          {goals.size ? (
            <>
              {teamsGoals.map(([key, value], index) => (
                <ListGoal
                  goal={value}
                  key={index + key}
                  onGoalUpdate={onGoalUpdate}
                  onUpdateReverted={onUpdateReverted}
                  type={type}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  goals={goals}
                  disabled={disabled}
                  upcomingGoals={upcomingGoals}
                  variant={variant}
                  managerEdit={true}
                  kind={kind}
                />
              ))}
              {goals.size > 2 && commentRef === '' && (
                <Body2
                  kind="purple300"
                  onClick={() =>
                    setCompanyTeamsVariantSeeMore(!companyTeamsVariantSeeMore)
                  }
                  style={{cursor: 'pointer'}}>
                  {!companyTeamsVariantSeeMore
                    ? `+ ${goals.size - 2} more goals`
                    : `See less goals`}
                </Body2>
              )}
            </>
          ) : (
            !isFetching && !variant && <EmptyPlaceholder {...EmptyHighlight} />
          )}
        </>
      );
    } else {
      return (
        <>
          {goals.size
            ? Array.from(goals).map(([key, value], index) => (
                <ListGoal
                  goal={value}
                  key={index + value.id}
                  variant={variant}
                  disabled={disabled}
                  goals={goals}
                  onGoalUpdate={onGoalUpdate}
                  onUpdateReverted={onUpdateReverted}
                  isOpen={isOpen}
                  upcomingGoals={upcomingGoals}
                  setIsOpen={setIsOpen}
                  showObjectiveProgress={showObjectiveProgress}
                  state={
                    kind === 'youcheckins'
                      ? userGoals &&
                        userGoals?.size > 0 &&
                        userGoals?.get(value.id)?.error?.length > 1
                        ? 'error'
                        : 'default'
                      : 'default'
                  }
                  helper={
                    kind === 'youcheckins'
                      ? userGoals?.size > 0 &&
                        userGoals?.get(value.id)?.error?.length > 1
                        ? userGoals?.get(value.id)?.error
                        : ''
                      : ''
                  }
                  kind={kind}
                  youCheckinsKind={youCheckinsKind}
                />
              ))
            : !isFetching &&
              !variant && <EmptyPlaceholder {...EmptyHighlight} />}

          {kind === 'youcheckins' && (
            <div
              style={{
                margin: '-7px 0px 4px 8px',
              }}>
              <Helper
                aria-label="helper"
                state={'error'}
                children={
                  Array.from(userGoals)?.filter((g: any) => g?.[1]?.error)
                    ?.length
                    ? `Kindly update the status of your ${
                        cfv(undefined, true).g_oals
                      }`
                    : ''
                }
              />
            </div>
          )}
        </>
      );
    }
  },
);
