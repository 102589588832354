import {memo} from 'react';

export const ArrowPointingUpIcon = memo(() => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 11.0837V2.91699"
      stroke="#1A9E68"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.91553 7.00033L6.99886 2.91699L11.0822 7.00033"
      stroke="#1A9E68"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

ArrowPointingUpIcon.displayName = 'ArrowPointingUpIcon';
