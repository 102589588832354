import {useStoreContext} from '@store/store-context';
import {AnalyticsSection} from '@ui/molecules/home-analytics-section';
import {memo, useState} from 'react';
import {useNavigate} from 'react-router';
import {useHomeActionItems} from './action-items-home-hook';
import {Body1} from '@ui/atoms/typography';
import styled from 'styled-components';
import {AnalyticsActionItemsOverviewPage} from '@pages/dashboard/reporting/reporting-pages/admin-analytics/analytics-pages/analytics-action-items/overview';

export const Card = styled.div<{bg: string}>`
  min-width: 48%;
  .card-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
  }

  border-radius: 8px;
  border: 1px solid #ededf2;
  width: 100%;

  .compare {
    border-top: 1px solid #ededf2;
    display: flex;
    padding: 4px 12px;
    align-items: center;
    justify-content: space-between;
  }
  @media (max-width: 800px) {
    margin-bottom: 10px;
  }
`;

export const Value = styled.div<{bg: string}>`
  border: 1px solid ${({bg}) => bg};
  background-color: #f6f6fe;
  padding: 4px 12px;
  border-radius: 8px;
`;

export const HomeActionItemsWidget = memo(() => {
  const {
    authStore: {auth},
  } = useStoreContext();

  const [activeTab, setActiveTab] = useState<'you' | 'team' | 'company'>('you');

  const {
    activeActionItems,
    overdueActionItems,
    assignedActionItems,
    completedOnDueDateActionItems,
    completedActionItems,
  } = useHomeActionItems(activeTab);

  const cardSummary = [
    {
      title: 'Open action items',
      value: activeActionItems,
      color: 'transparent',
    },
    {
      title: `Overdue`,
      value: overdueActionItems,

      color: 'transparent',
    },
  ];

  const navigate = useNavigate();

  return (
    <div>
      <AnalyticsSection
        viewFooterText="action items"
        handleContinue={() => navigate('/action-items')}
        showNav={auth.user.role === 'admin' || auth.user.isReviewer}
        showCompany={auth.user.role === 'admin'}
        showTeam={auth.user.isReviewer}
        updateActiveTab={setActiveTab}
        title="Action Items">
        <div className={`flex ${'flex-row w-full'} gap-4`}>
          {activeTab === 'you' && (
            <>
              {cardSummary.map((sum) => (
                <Card bg={sum.color} className="mb-2">
                  <div className="card-wrapper">
                    <Body1 weight="semibold">{sum.title}</Body1>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <Value bg={sum.color}>
                        <Body1 weight="semibold" kind={'textDark'}>
                          {sum.value}
                        </Body1>
                      </Value>
                    </div>
                  </div>
                </Card>
              ))}
            </>
          )}
          {activeTab !== 'you' && (
            <AnalyticsActionItemsOverviewPage
              summary={{
                active: activeActionItems,
                assigned: assignedActionItems,
                total:
                  activeActionItems + completedActionItems + overdueActionItems,
                completed: completedActionItems,
                completionRate: completedOnDueDateActionItems,
                overdue: overdueActionItems,
              }}
            />
          )}
        </div>
      </AnalyticsSection>
    </div>
  );
});
