import {
  ComponentPropsWithoutRef,
  forwardRef,
  ReactElement,
  Ref,
  RefObject,
  useRef,
} from 'react';
import styled, {CSSProperties} from 'styled-components';
import {useCombinedRefs} from '../../../../hooks';
import {Input} from '../../../atoms/input';
import {Label} from '../../../atoms/label';
import {FieldWrapper} from '../../../atoms/field-wrapper';
import {Helper} from '../../../atoms/helper';
import {InputState} from '../../../interface';
import {InputNoBorder} from '../../../atoms/input/input';
import {Input as InputArea} from '../../../atoms/textArea';

export interface TextFieldProps extends ComponentPropsWithoutRef<'input'> {
  label?: string;
  optional?: boolean;
  state?: InputState;
  helper?: string | ReactElement;
  action?: (ref: RefObject<HTMLInputElement>) => ReactElement;
  margin?: boolean;
  inputStyle?: CSSProperties;
  actionStyle?: CSSProperties;
  noBorder?: boolean;
  showCancel?: boolean;
  handleCancel?: () => void;
}

const Wrapper = styled.div`
  position: relative;
`;

const InputWrapper = styled.div<{hasLabel?: boolean}>`
  padding-top: ${({hasLabel}) => (hasLabel ? '0' : '0')};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  textarea {
    order: 2;
  }
  input {
    order: 2;
  }
  label {
    order: 1;
  }
`;

const ActionWrapper = styled.span`
  position: absolute;
  right: 16px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const TextField = forwardRef(
  (
    {
      label,
      optional,
      placeholder,
      state,
      helper,
      action,
      margin,
      inputStyle,
      style,
      actionStyle,
      noBorder,
      ...rest
    }: TextFieldProps,
    ref: Ref<HTMLInputElement>,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const combinedRef = useCombinedRefs<HTMLInputElement>(
      ref as RefObject<HTMLInputElement>,
      inputRef,
    );

    const InputComponent =
      noBorder && !rest?.multiple
        ? InputNoBorder
        : rest?.multiple
        ? (InputArea as any)
        : Input;

    return (
      <FieldWrapper helper={!!helper} margin={margin} style={style}>
        <Wrapper>
          <InputWrapper hasLabel={!!label}>
            <InputComponent
              aria-label="input"
              placeholder={placeholder ?? label}
              ref={combinedRef}
              onWheelCapture={(e: {currentTarget: {blur: () => void}}) => {
                e.currentTarget.blur();
              }}
              state={state}
              style={inputStyle}
              {...rest}
            />

            {action && (
              <ActionWrapper style={actionStyle}>
                {action(combinedRef)}
              </ActionWrapper>
            )}
            <Label aria-label="label" style={{position: 'unset'}}>
              {label}{' '}
              <span style={{color: '#5F5F8C', fontWeight: 400}}>
                {optional ? '(Optional)' : ''}
              </span>
            </Label>
          </InputWrapper>
          <Helper
            style={{textAlign: 'left'}}
            aria-label="helper"
            state={state}
            children={helper}
          />
        </Wrapper>
      </FieldWrapper>
    );
  },
);

export const TextFieldCustomInput = forwardRef(
  (
    {
      label,
      placeholder,

      state,
      helper,
      action,
      margin,
      inputStyle,
      style,
      children,
      ...rest
    }: TextFieldProps,
    ref: Ref<HTMLInputElement>,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const combinedRef = useCombinedRefs<HTMLInputElement>(
      ref as RefObject<HTMLInputElement>,
      inputRef,
    );

    return (
      <FieldWrapper helper={!!helper} margin={margin} style={style}>
        <Wrapper>
          <InputWrapper hasLabel={!!label}>
            {children}
            {action && <ActionWrapper>{action(combinedRef)}</ActionWrapper>}
            <Label aria-label="label">{label}</Label>
          </InputWrapper>
          <Helper aria-label="helper" state={state} children={helper} />
        </Wrapper>
      </FieldWrapper>
    );
  },
);
