import styledMap, {mapToTheme as theme} from 'styled-map';
import {css} from 'styled-components';

import {Colors, TextAlign} from '../../interface';

export interface ITypography {
  /*
   * Use to specify the text color
   */
  kind?: Colors;
  /*
   * Use to specify the text position
   */
  align?: TextAlign;
  weight?: string;
  size?: string | 'number';
}

export const Typography = css`
  font-family: ${(props) => props.theme.fontFamily};
  font-weight: ${styledMap('weight', {
    default: '400',
    semibold: '500',
    bold: '600',
  })};
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: ${theme('colors', 'kind')};

  text-align: ${styledMap('align', {
    default: 'left',
    left: 'left',
    right: 'right',
    center: 'center',
  })};
`;
