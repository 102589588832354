import styled from 'styled-components';
import styledMap from 'styled-map';
import {down} from 'styled-breakpoints';

export const Wrapper = styled.div`
  width: 100%;
`;

export const MobileWrapper = styled.div`
  display: none;
  @media (max-width: 1000px) {
    display: flex;
    .render-update {
      margin-top: 10px;
      width: 100%;
    }
  }
`;
export const DesktopWrapper = styled.div`
  display: block;
  @media (max-width: 1000px) {
    display: none;
  }
`;
export const Container = styled.div`
  margin: 8px 0;
  display: grid;
  grid-template-columns: auto minmax(500px, 65%);
  grid-column-gap: 24px;
  text-decoration: none;
  align-items: center;
`;

export const StyledContainer = styled(Container)<{
  tabKind: string | undefined;
  isEditing?: boolean;
}>`
  grid-template-columns: ${styledMap('tabKind', {
    team: '1fr 1fr',
    companyReportPDF: '1fr 1fr',
    companyTeams: 'minmax(auto, 320px) auto',
    youcheckins: '30% auto',
    default: 'minmax(auto, 370px) auto',
  })};
  @media (min-width: 1360px) and (max-width: 1450px) {
    grid-template-columns: ${({tabKind, isEditing}) =>
      tabKind === 'youcheckins' && isEditing
        ? '25% auto'
        : tabKind === 'youcheckins' && !isEditing
        ? '40% auto'
        : undefined};
  }
  }
  @media (min-width: 1283px) and (max-width: 1359px) {
    grid-template-columns: ${({tabKind, isEditing}) =>
      tabKind === 'youcheckins' && isEditing
        ? '20% auto'
        : tabKind === 'youcheckins' && !isEditing
        ? '35% auto'
        : undefined};
  }
  @media (min-width: 1060px) and (max-width: 1282px) {
    grid-template-columns: ${({tabKind, isEditing}) =>
      tabKind === 'youcheckins' && isEditing
        ? '12% auto'
        : tabKind === 'youcheckins' && !isEditing
        ? '30% auto'
        : undefined};
  }
  @media (min-width: 1000px) and (max-width: 1059px) {
    grid-template-columns: ${({tabKind, isEditing}) =>
      tabKind === 'youcheckins' && isEditing
        ? '8% auto'
        : tabKind === 'youcheckins' && !isEditing
        ? '29% auto'
        : undefined};
  }
`;

export const EndWrapper = styled.div`
  display: grid;

  grid-template-columns: minmax(auto, 100px) minmax(230px, 1fr) auto;
  align-items: center;

  .action_wrapper-container {
    display: flex;
    justify-content: flex-end;
  }

  @media (max-width: 900px) {
    grid-template-columns: none;
    grid-column-gap: 16px;

    .action_wrapper-container {
      display: flex;
      justify-content: end;
    }
  }
`;

export const MediumWrapper = styled.div`
  display: none;
  @media (min-width: 581px) and (max-width: 1000px) {
    display: block;
  }
`;

export const StyledEndWrapper = styled(EndWrapper)<{
  tabKind: string | undefined;
  isEditing?: boolean;
}>`
  grid-template-columns: ${styledMap('tabKind', {
    team: '1fr 1fr',
    companyReportPDF: '1fr 1fr',

    youcheckins: '10% 90%',
    default: '10% 95%',
  })};

  display: ${({tabKind}) => (tabKind === 'companyTeams' ? 'flex' : '')};
  justify-content: space-between;
  grid-column-gap: ${styledMap('tabKind', {
    team: '56px',
    default: '',
  })};

  .action_wrapper-container {
    display: flex;
    justify-content: ${({tabKind}) =>
      tabKind === 'companyTeams' ? 'space-between' : 'flex-end'};

    padding-left: ${styledMap('tabKind', {
      companyTeams: '30px',
      default: undefined,
    })};

    padding-right: ${styledMap('tabKind', {
      companyTeams: '0px',
      default: '0px',
    })};
  }

  @media (max-width: 1000px) {
    display: none;
    grid-template-columns: none;
  }
`;

export const ActionWrapper = styled.div<{
  isEditing?: boolean;
  tabKind?: string;
  sm?: boolean;
}>`
  display: flex;

  align-items: ${({sm}) => (sm === true ? 'flex-end' : 'center')};
  width: 127px;
  margin-left: 7%;
  justify-content: ${({tabKind}) =>
    tabKind && tabKind === 'youcheckins' ? 'flex-end' : undefined};
`;

export const AvatarWrapper = styled.div`
  @media (max-width: 900px) {
    display: none;
  }
`;
export const NameWrapper = styled.div`
  background: #fafafa;
  border-radius: 8px;
  max-width: fit-content;
  padding: 4px 8px;
  font-size: 12px;
  color: #5f5f8c;
  .container span {
    color: #5f5f8c;
    line-height: 150%;
  }
`;
export const ProgressWrapper = styled.div<{isEditing?: boolean}>`
  display: flex;
  justify-content: flex-end;

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const StyledEndWrapper2 = styled.div`
  display: none;

  @media (max-width: 1000px) {
    display: block;
    margin-top: 16px;
  }
`;
export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  ${down('lg')} {
    width: 100%;
  }
  ${down('md')} {
    width: 100%;
  }
  ${down('sm')} {
    width: 200px;
  }
`;
export const IconWrapper = styled.span<{showIconOnly?: boolean}>`
  svg {
    width: 16px;
    margin-top: 7px;
    margin-left: ${({showIconOnly}) => (showIconOnly ? undefined : '3px')};
    height: 16px;
  }
`;

export const TooltipWrapper = styled.div`
  .tooltip {
    position: relative;
    display: inline-block;
  }
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 238px;
    background-color: white;
    border: 1px solid #ededf2;
    color: #fff;
    margin-top: 5px;
    box-shadow: 0px 9px 38px rgba(0, 0, 0, 0.05),
      0px 6.53341px 11.4559px rgba(0, 0, 0, 0.0325794);
    border-radius: 10px;
    padding: 16px;

    /* Position the tooltip */
    position: absolute;
    z-index: 5;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
`;
export const UpdateWrapper = styled.div`
  background: #fafafa;
  padding: 16px;
  border: 1px solid #ededf2;
  border-radius: 0px 0px 10px 10px;
`;
