import {forwardRef, Ref} from 'react';
import styled, {
  css,
  DefaultTheme,
  StyledComponentProps,
} from 'styled-components';
import {motion} from 'framer-motion';
import {ifProp} from 'styled-tools';
import {Body1} from '../typography/body1';
import {GoalKind} from '../../interface';
import {getColorFromTheme} from '../../ui-utils';
import {GoalIcon} from '../goal-icon';
import {GoalText} from '../goal-text';

export type InputProps = StyledComponentProps<'input', DefaultTheme, {}, never>;

export interface TGoalTypeProps extends InputProps {
  id: string;
  kind: GoalKind;
}

const Wrapper = styled.label`
  cursor: pointer;
  position: relative;
  background: transparent;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  -moz-appearance: none;

  input {
    top: 0;
    left: 0;
    width: 100%;
    cursor: inherit;
    height: 100%;
    margin: 0;
    opacity: 0;
    padding: 0;
    z-index: 1;
    position: absolute;

    &:checked,
    &:focus,
    &:active {
      > ${Body1} {
        color: ${getColorFromTheme('textDark')};
      }

      > svg {
        stroke: ${getColorFromTheme('purple300')};
        > g {
          > path {
            stroke: ${getColorFromTheme('purple300')};
          }
        }
      }
    }
  }

  &:hover {
    ${Body1} {
      color: ${getColorFromTheme('textDark')};
    }

    svg {
      stroke: ${getColorFromTheme('purple300')};
      g {
        path {
          stroke: ${getColorFromTheme('purple300')};
        }
      }
    }
  }
`;

const Container = styled.div<{checked?: boolean}>`
  display: flex;
  padding: 10px 16px;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;

  ${Body1} {
    color: ${getColorFromTheme('textBody')};
    transition: all 300ms ease-in-out;
    z-index: 2;
    margin-bottom: -3px;
  }

  svg {
    stroke: ${getColorFromTheme('textBody')};
    transition: all 300ms ease-in-out;
    g {
      path {
        stroke: ${getColorFromTheme('textBody')};
      }
    }
  }

  ${ifProp(
    'checked',
    css`
      ${Body1} {
        color: ${getColorFromTheme('textDark')};
        font-weight: 500;
      }

      svg {
        stroke: ${getColorFromTheme('purple300')};
        g {
          path {
            stroke: ${getColorFromTheme('purple300')};
          }
        }
      }
    `,
  )}
`;

const Icon = styled.span`
  margin-right: 8px;
  z-index: 2;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 20px;
    height: 20px;
  }
`;

const Background = styled(motion.span)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${getColorFromTheme('white')};
  z-index: 1;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.05);
`;

export const GoalType = forwardRef(
  ({kind, ...props}: TGoalTypeProps, ref: Ref<HTMLInputElement>) => {
    return (
      <Wrapper htmlFor={props.id}>
        <input {...props} type="radio" name={props.name} ref={ref} />
        <Container checked={props.checked}>
          <Icon>
            <GoalIcon kind={kind} />
          </Icon>
          <Body1 style={{whiteSpace: 'nowrap'}}>
            <GoalText kind={kind} />
          </Body1>
          {props.checked && (
            <Background
              layoutId="goal-type-radio"
              initial={true}
              transition={{
                duration: 0,
                layoutX: {duration: 0},
                layoutY: {delay: 0, duration: 0},
              }}
            />
          )}
        </Container>
      </Wrapper>
    );
  },
);

GoalType.displayName = 'GoalType';
