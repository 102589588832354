import DOMPurify from 'dompurify';

export const Linkify = (word: string) => {
  const isUrl = (word: string) => {
    // eslint-disable-next-line no-useless-escape
    const urlPattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;
    return word.match(urlPattern);
  };

  const addMarkup = (word: string) => {
    return isUrl(word)
      ? `<a href="${word}" target="_blank" class="text-blue-300 underline text-[16px]">${word}</a>`
      : word;
  };

  const words = word.split(' ');
  const formatedWords = words.map((w, i) => addMarkup(w));
  const html = DOMPurify.sanitize(formatedWords.join(' '));
  return html;
};
