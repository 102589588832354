import {GoalsController} from '@api/goals-api/goals-controller';
import {GoalsRequestImpl} from '@api/goals-api/goals-request';
import {MeetingController} from '@pages/1:1s/meeting-controller';
import {MeetingRequestImpl} from '@pages/1:1s/meeting.request';
import {useStoreContext} from '@store/store-context';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {transformOptions} from '@pages/dashboard/reporting/reporting-pages/reporting-overview/components/kpi-board';
import {useQuery} from 'react-query';
import {AnalyticsRequestImpl} from '@pages/dashboard/reporting/reporting-pages/admin-analytics/admin-analytics.request';
import {AnalyticsController} from '@pages/dashboard/reporting/reporting-pages/admin-analytics/admin-analytics.controller';
import {usePeriodHook} from './period-hook';
import {AnalyticsTodayDate} from '@utils/date';
import {usePastMeetingHook} from '@pages/1:1s/meetings/meeting-hook';
import {useActionItems} from './features-action-items';
import {userName} from '@utils/user-name';
import {useFetchGoalById} from '@pages/dashboard/you/you-components/insights/hooks/insight-hook';
import {useFirebaseFetch} from './query-hook';
import {get} from 'idb-keyval';
import {useSections} from './action-items-sections';

export const useActionItemsPage = (
  selectedManager: string,
  selectedUser: string,
) => {
  const [actionItems, setActionItems] = useState<any[]>([]);

  const meetingsRequest = useMemo(() => new MeetingRequestImpl(), []);

  const meetingsController = useMemo(
    () => new MeetingController(meetingsRequest),
    [meetingsRequest],
  );

  const {
    usersStore: {users},
    groupStore: {groups},
    groupTypeStore: {groupType},
    authStore: {auth},
  } = useStoreContext();

  const findUser = useCallback(
    (id: string) => users.find((user) => user.id === id),
    [users],
  );

  const {handleChange, tasks: allTasks, isLoading} = useActionItems({
    userId: selectedUser,
    itemId: selectedUser,
    source: '',
    fetchAll: !selectedUser,
    callback: (data: any) => {},
  });

  const [tasks, setTasks] = useState(allTasks);

  useEffect(() => {
    if (allTasks.length && !tasks.length) {
      setTasks(allTasks);
    }
  }, [allTasks, tasks]);

  function removeDuplicateKeys(array: any[]) {
    // Create an object to store unique _id values as keys
    const uniqueIds: any = {};

    // Filter the array to include only objects with unique _id values
    const uniqueArray = array.filter((obj) => {
      if (!uniqueIds[obj.key]) {
        uniqueIds[obj.key] = true;
        return true;
      }

      return false;
    });

    return uniqueArray;
  }

  const {getMeetings} = usePastMeetingHook();

  const fetchSpecifiedGoals = useCallback(
    async (filters: any, view?: string) => {
      const request = new AnalyticsRequestImpl();
      const controller = new AnalyticsController(request);
      const response: {goals: any[]} = await controller.fetchSpecifiedGoals(
        filters,
        view,
      );
      const formatGoal = {
        ...response,
        goals: response.goals.map((goal) => {
          if (goal.goalType === 'group') {
            const group = groups.filter((group) =>
              goal.group.includes(group.id),
            );

            return {
              ...goal,
              group,
            };
          }
          return {
            ...goal,
          };
        }),
      };
      return formatGoal;
    },
    [groups],
  );

  const {currentMonthRange, isPeriodEnabled} = usePeriodHook();

  const {getGoalDetails} = useFetchGoalById();

  const getPeriod = useMemo(
    () =>
      isPeriodEnabled
        ? {
            starts: currentMonthRange().starts,
            ends: currentMonthRange().ends,
            period: currentMonthRange().period,
          }
        : {
            starts: AnalyticsTodayDate().AnalyticsDefaultStartDate,
            ends: AnalyticsTodayDate().AnalyticsDefaultEndDate,
          },
    [isPeriodEnabled, currentMonthRange],
  );

  const {data: goals, isLoading: goalsIsLoading} = useQuery(
    ['action-items-goals', selectedManager, selectedUser],
    () =>
      fetchSpecifiedGoals({
        startDate: getPeriod?.starts,
        endDate: getPeriod?.ends,
        filterBy: 'weeks',
        removeKpiFilter: true,
        member: selectedUser,
        manager: selectedManager,
      }),
  );

  const {data: meetings, isLoading: meetingsIsLoading} = useQuery(
    ['action-items-meetings', selectedManager, selectedUser],
    () =>
      getMeetings({
        startDate: getPeriod?.starts,
        endDate: getPeriod?.ends,
        user: selectedUser,
        participant: selectedUser,
      }),
  );

  const initGoals = useCallback(
    async (page: number, filter?: string) => {
      const request = new GoalsRequestImpl();
      const controller = new GoalsController(request);

      const response = await controller.fetchGoals(page, filter, 10000);
      const groups = await get(`${auth.user.workspace.id}/groups`);

      const unfurledData = response.results;

      const transformGoalOptions = unfurledData.map((goal: any) =>
        transformOptions(goal, groups, groupType),
      );

      setGoalOptions(transformGoalOptions);
    },
    [auth.user.workspace.id, groupType],
  );

  const sources = useMemo(() => new Map(), []);

  const handleSource = useCallback(async () => {
    const updatedTasks = [...allTasks]; // Create a copy of the tasks array

    const tasks = await Promise.all(
      updatedTasks.map(async (task, index) => {
        if (task.source === 'goal') {
          const inSource = sources.get(task.sourceId);
          if (inSource) {
            return {...task, sourceTitle: inSource.title};
          }

          let goal = goals && goals.goals?.find((g) => g.id === task.sourceId);

          if (!goal) {
            try {
              const goalDetails = await getGoalDetails(task.sourceId || '');
              goal = goalDetails;
            } catch (error) {}
          }

          updatedTasks[index].sourceTitle = goal?.name || 'Deleted goal';
          updatedTasks[index].isSourceDeleted = !goal;

          sources.set(task.sourceId, {
            title: goal?.name || 'Deleted goal',
            isDeleted: !goal,
            goal,
          });

          return {...task, sourceTitle: goal?.name || 'Deleted goal'};
        } else if (task.source === 'meeting' && !!task.sourceId) {
          let meeting =
            meetings?.find((m) => m.id === task.sourceId) ||
            (await meetingsController.getMeeting(
              task.sourceId,
              undefined,
              true,
            ));

          const participant =
            typeof meeting.participant === 'string'
              ? findUser(meeting.participant)
              : meeting.participant;

          const sourceTitle = meeting
            ? meeting.title || `1:1 meeting with ${userName(participant)}`
            : 'Deleted meeting';

          updatedTasks[index].sourceTitle = sourceTitle;

          updatedTasks[index].isSourceDeleted = !meeting;

          sources.set(task.sourceId, {
            title: meeting
              ? meeting.title || `1:1 meeting with ${userName(participant)}`
              : 'Deleted meeting',

            isDeleted: !meeting,

            meeting: {
              ...meeting,
              user: meeting?.user ? findUser(meeting.user) : null,
              participant: meeting?.participant ? participant : null,
            },
          });

          return {...task, sourceTitle};
        }
        return task;
      }),
    );

    // Update the tasks state with the modified array

    setTasks(tasks);

    return null;
  }, [
    findUser,
    getGoalDetails,
    goals,
    meetings,
    meetingsController,
    sources,
    allTasks,
  ]);

  const [goalOptions, setGoalOptions] = useState<any[]>([]);

  return {
    actionItems,
    setActionItems,
    sources,
    allTasks,
    setTasks,
    handleSource,
    isLoading: isLoading || meetingsIsLoading || goalsIsLoading,
    allGoals: goals,
    allMeetings: meetings,
    goalOptions,
    initGoals,
    meetingsController,
    findUser,
    removeDuplicateKeys,
    handleChange,
    firebaseActionItems: tasks
      .map((task) =>
        typeof task.assignee === 'string'
          ? {...task, assignee: users.find((user) => user.id === task.assignee)}
          : task,
      )
      .filter(
        (task) =>
          !selectedUser || !task.assignee || task.assignee?.id === selectedUser,
      ),
  };
};

export const useActionItemsWithSortFilter = (
  selectedManager: string,
  selectedUser: string,
) => {
  const {firebaseActionItems: data, ...rest} = useActionItemsPage(
    selectedManager,
    selectedUser,
  );

  const [sortBy, setSortBy] = useState('createdAt');

  const [showCompleted, setShowCompleted] = useState(false);

  const filterPath = `users/${selectedUser}/action_items/filter`;

  const {data: actionItemsFilterPath} = useFirebaseFetch(filterPath);

  const isCustomFilter = actionItemsFilterPath?.type === 'custom';

  const {computeSections, ...section} = useSections();

  const sortedData = useMemo(() => {
    const sortData = data
      ?.filter((task) => showCompleted || !task.completed)
      .slice();

    return sortData;
  }, [data, showCompleted]);

  const groupBySections = useMemo(() => {
    const formattedItems = sortedData.length
      ? sortedData
      : [{text: '', completed: false}];

    const groupedItems = formattedItems.reduce((acc, item) => {
      const itemSection = computeSections.find(
        (_section) => _section.slug === item?.section,
      );

      let section = itemSection?.name || 'Inbox';

      if (itemSection) {
        item.section_slug = itemSection.slug;
      }

      if (!acc[section]) {
        acc[section] = [];
      }

      acc[section].push(item);

      return acc;
    }, {} as Record<string, any[]>);

    // Ensure all sections are initialized in acc
    computeSections.forEach((_section) => {
      if (!groupedItems[_section.name]) {
        groupedItems[_section.name] = [];
      }
    });

    let entries = Object.entries(groupedItems);

    // Sort entries based on multiple criteria
    entries.sort(([a], [b]) => {
      if (a === 'Inbox') return -1;
      if (b === 'Inbox') return 1;

      return 0;
    });

    return entries;
  }, [computeSections, sortedData]);

  const completedTasks = data.filter((task) => !!task.completed);

  return {
    ...rest,
    firebaseActionItems: data,
    completedTasks,
    updateShowCompleted: setShowCompleted,
    ...section,
    sortedData,
    computeSections,
    sortBy,
    showCompleted,
    groupBySections,
    isCustomFilter,
    setSortBy,
  };
};
