import {getFromBetween} from '@utils/get-between';
import {EditorState, convertFromRaw, convertToRaw} from 'draft-js';
import Editor from '@draft-js-plugins/editor';

import createMentionPlugin, {
  defaultSuggestionsFilter,
} from '@draft-js-plugins/mention';
import React, {FC, useCallback, useMemo, useRef, useState} from 'react';
import {Entry} from '@ui/layouts/users-goals/comments/entry';

import {EditorWrapper, PopContainer} from './style';

interface FieldEditorProps {
  value?: string;
  editorOptions?: {
    name: '';
    id: '';
  }[];
  checked?: boolean;
  onHandleChange: (event: any) => void;
  disabled?: boolean;
}

export const FieldEditor: FC<FieldEditorProps> = ({
  value,
  editorOptions,
  checked,
  disabled,
  onHandleChange,
}) => {
  // Convert props value to draft-js
  const getValue = useMemo(() => {
    const isEditorWithValue = typeof value === 'string' && value.length > 0;

    if (isEditorWithValue) {
      // Get mentioned user's from value;
      const mentionedUsers = getFromBetween.get(value, '_(', ')_');

      //    Get mentioned user's details;
      const getMentionedUserDetails = editorOptions?.filter((user) =>
        mentionedUsers.includes(user.id),
      );

      const updatedValue = mentionedUsers?.reduce((acc: string, id: string) => {
        const mentionedUser = editorOptions?.find((user) => user.id === id);
        return acc.replace(`_(${id})_`, `@${mentionedUser?.name}`);
      }, value);

      const rawContent = {
        blocks: [
          {
            text: updatedValue,
            type: 'unstyled',
            entityRanges: getMentionedUserDetails?.map((mentions, idx) => ({
              key: idx,
              length: `@${mentions.name}`.length,
              offset: updatedValue.indexOf(`@${mentions.name}`),
            })),
          },
        ],
        entityMap: getMentionedUserDetails?.map((user) => ({
          type: 'mention',
          mutability: 'IMMUTABLE',
          data: {
            mention: user,
          },
        })),
      };

      const blocks = convertFromRaw(rawContent as any);
      return EditorState.createWithContent(blocks);
    }

    return EditorState.createEmpty();
  }, [value, editorOptions]);

  const [editorState, setEditorState] = useState(() =>
    value ? getValue : EditorState.createEmpty(),
  );

  const onEditorChange = useCallback(
    (_editorState: EditorState) => {
      // Update the component's state with the new editor state
      setEditorState(_editorState);

      // Get the current content state from the new editor state
      const contentState = _editorState.getCurrentContent();

      // Convert the content state to a raw representation
      const raw = convertToRaw(contentState);

      // Extract the text content from the raw representation
      let text = raw.blocks[0].text;

      // Get all the mentioned users from the entity map
      const mentionedUsers = Object.values(raw.entityMap).map(
        (mention: any) => mention.data?.mention,
      );

      // Replace @[username] in the text with _(user_id)_
      mentionedUsers.forEach((user: any) => {
        text = text.replace(`@${user.name}`, `_(${user.id})_`);
      });

      // Get the previous content state from the component's editor state
      const getPrevState = editorState.getCurrentContent();

      // Convert the previous content state to a raw representation
      const getPrevraw = convertToRaw(getPrevState);

      // Extract the text content from the previous raw representation
      const Prevtext = getPrevraw.blocks[0].text;

      // If the new text is different from the previous text, update the input value
      if (Prevtext !== raw.blocks[0].text) {
        onHandleChange({
          target: {value: text},
        } as any);
      }
    },
    [onHandleChange, editorState],
  );

  const {MentionSuggestions, plugins} = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      entityMutability: 'IMMUTABLE',
      theme: {mention: 'taskMention'},
      mentionPrefix: '@',
      supportWhitespace: true,
    });

    // eslint-disable-next-line no-shadow
    const {MentionSuggestions} = mentionPlugin;

    // eslint-disable-next-line no-shadow
    const plugins = [mentionPlugin];
    return {
      plugins,
      MentionSuggestions,
    };
  }, []);

  const ref = useRef<Editor>(null);
  const [open, setOpen] = useState(false);

  const onOpenChange = useCallback((_open: boolean) => {
    setOpen(_open);
  }, []);

  const [searchValue, setSearchValue] = useState<any>([]);

  const onSearchChange = useCallback(
    ({value}: {value: string}) => {
      setSearchValue(defaultSuggestionsFilter(value, editorOptions as any));
    },
    [editorOptions],
  );

  return (
    <EditorWrapper color={checked ? 'textMuted' : 'textDark'}>
      <div className="editor">
        <Editor
          editorKey={'editor'}
          editorState={editorState}
          onChange={onEditorChange}
          readOnly={disabled}
          textAlignment="left"
          spellCheck={true}
          placeholder={disabled ? '' : 'Add an item'}
          ref={ref}
          plugins={plugins}
        />
        <MentionSuggestions
          open={open}
          onOpenChange={onOpenChange}
          suggestions={searchValue}
          onSearchChange={onSearchChange}
          entryComponent={Entry}
          popoverContainer={({children}) => (
            <PopContainer style={{zIndex: 5, position: 'absolute'}}>
              {' '}
              {children}
            </PopContainer>
          )}
        />
      </div>
    </EditorWrapper>
  );
};
