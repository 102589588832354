import {EmptyPlaceholder} from '@ui/atoms/empty-placeholder';
import {cloudinary} from '@assets/images/cloudinary';
import {CCResultFilter} from './components/filter';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {FlexRowCenter} from '@ui/style/styles';

import {useState} from 'react';
import {Ratings} from './ratings';
import {RatingTable} from './rating-table';
import {Matrix} from './matrix';
import {useReviewsHook} from '../your-review/reviews-hooks';
import dayjs from 'dayjs';
import styled from 'styled-components';
import {useQuery} from 'react-query';
import {ItemLoader} from '@ui/organisms/item-loader';
import {observer} from 'mobx-react';
import {useCycleOverview} from '../overview.hook';
import {authStore} from '@store/stores/auth-store';
const {emptyGoals: emptyGoal} = cloudinary;

export const OverviewWrapper = styled.div`
  border-radius: 10px;
  background-color: white;
  padding: 24px;
  border: 1px solid var(--border-light, #ededf2);
`;

const {emptyGoals} = cloudinary;

const emptyPlaceholder = {
  image: {
    alt: 'alt text',
    shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
    src: emptyGoals,
    style: {height: '40%', width: '40%'},
  },

  subtitle: 'Launch a new cycle to start tracking  performance.',
  title: 'No ended review cycles found',
};

export const CultureAndCompetenceResult = observer(() => {
  const [activeTab, setActiveTab] = useState('ratings');

  const {
    reviewCycleOptions,
    currentReviewCycle,
    isLoading,
    controller,
  } = useReviewsHook('results');

  useCycleOverview();

  const [selectedGroups] = useState<{label: string; value: string}[]>([]);

  const [selectedMembers] = useState<{label: string; value: string}[]>([]);

  const [sortBy] = useState({
    label: 'overall',
    value: '',
  });

  const filteredGroupsString = selectedGroups
    .map((group) => group.value)
    .join(',');

  const filteredMembersString = selectedMembers
    .map((group) => group.value)
    .join(',');

  const handleKeyAreaFilter = () => {
    if (sortBy.label === 'keyareas') return sortBy.value;

    return '';
  };
  const handleValueFilter = () => {
    if (sortBy.label === 'values') return sortBy.value;

    return '';
  };

  const {data, isLoading: loadingResults} = useQuery(
    [
      'cc-results',
      currentReviewCycle.id,
      filteredMembersString,
      handleKeyAreaFilter(),
      filteredGroupsString,
    ],
    () =>
      controller.fetchCcResults(currentReviewCycle.id, {
        group: filteredGroupsString,
        member: filteredMembersString,
        keyArea: handleKeyAreaFilter(),
        value: handleValueFilter(),
        manager:
          authStore.auth.user.isReviewer && authStore.auth.user.role !== 'admin'
            ? authStore.auth.user.id
            : undefined,
        user:
          !authStore.auth.user.isReviewer &&
          authStore.auth.user.role !== 'admin'
            ? authStore.auth.user.id
            : undefined,
      }),
    {
      enabled: !!currentReviewCycle.id,
    },
  );

  const EmptyQuestions = {
    image: {
      alt: 'alt text',
      shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
      src: emptyGoal,
      style: {height: '30%', width: '30%'},
    },
    imports: true,
    subtitle: 'Create a review cycle to view its results',
    title: `You have no completed review cycles.`,
  };

  return (
    <div>
      <CCResultFilter
        reviewOptions={reviewCycleOptions()}
        disabled={!reviewCycleOptions().length}
        progress={
          reviewCycleOptions().length ? currentReviewCycle?.progress || 0 : 0
        }
        isLoading={isLoading}
        dueDay={currentReviewCycle.milestones.endDate || ''}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        reviewTimeline={
          currentReviewCycle.milestones.endDate
            ? `${dayjs(currentReviewCycle.milestones.startDate).format(
                'DD MMM. YYYY',
              )} -  ${dayjs(currentReviewCycle.milestones.endDate).format(
                'DD MMM. YYYY',
              )}`
            : ''
        }
      />
      <VerticalSpacer size="24px" />

      {currentReviewCycle?.id && currentReviewCycle.status === 'ended' ? (
        <OverviewWrapper>
          <div>
            {!data && !loadingResults && (
              <EmptyPlaceholder {...EmptyQuestions} />
            )}

            {loadingResults && (
              <FlexRowCenter style={{height: '200px'}}>
                <ItemLoader />
              </FlexRowCenter>
            )}

            {data && !loadingResults && (
              <>
                {activeTab === 'ratings' && <Ratings ratings={data} />}

                {activeTab === 'matrix' && (
                  <Matrix
                    users={data.users}
                    value={{
                      values: data.averageValue,
                      competence: data.averageCompetence,
                    }}
                  />
                )}
              </>
            )}
          </div>
        </OverviewWrapper>
      ) : (
        <OverviewWrapper>
          <EmptyPlaceholder {...emptyPlaceholder} />
        </OverviewWrapper>
      )}

      {activeTab === 'ratings' &&
        data &&
        !loadingResults &&
        currentReviewCycle.status === 'ended' && (
          <>
            <VerticalSpacer size="14px" />
            <RatingTable
              users={data.users}
              cycleId={currentReviewCycle.id}
              cycleType={currentReviewCycle.reviewType}
            />
          </>
        )}
    </div>
  );
});
