import styled from 'styled-components';
import {ChangeEvent, memo, ReactElement, useCallback} from 'react';
import {NumberInput} from '../../atoms/range/number';
import {InputState} from '../../interface';
import {Helper} from '../../atoms/helper';
import {FieldWrapper} from '../../atoms/field-wrapper';
import {toNumber} from '../../../utils';
import {down} from 'styled-breakpoints';
import {authStore} from '../../../store/stores/auth-store';

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  align-items: center;
  padding-bottom: 3px;

  ${down('lg')} {
    grid-template-columns: 1fr;
    grid-row-gap: 24px;
  }
`;

export interface RangeInputProps {
  onChange: (value: {
    start?: number;
    target?: number;
    current?: number;
    good?: number;
    stretch?: number;
  }) => void;
  state?: InputState;
  helper?: string | ReactElement;
  disabled?: boolean;
  name: string;
  value?: {start?: number; target?: number; good?: number; stretch?: number};
  onBlur?: () => void;
}

export const RangeInput = memo(function ({
  state,
  helper,
  onChange,
  disabled,
  value,
  onBlur,
}: RangeInputProps) {
  const onUpdateStart = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const {value: innerValue} = e.target;
      if (disabled) {
        onChange({
          ...value,
          start: toNumber(innerValue),
        });
      } else {
        onChange({
          ...value,
          start: toNumber(innerValue),
          current: toNumber(innerValue),
        });
      }
    },
    [onChange, value, disabled],
  );

  const onUpdateTarget = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const {value: innerValue} = e.target;
      onChange({
        ...value,
        target: toNumber(innerValue),
      });
    },
    [onChange, value],
  );
  const onUpdateGood = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const {value: innerValue} = e.target;
      onChange({
        ...value,
        good: toNumber(innerValue),
      });
    },
    [onChange, value],
  );
  const onUpdateStretch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const {value: innerValue} = e.target;
      onChange({
        ...value,
        stretch: toNumber(innerValue),
      });
    },
    [onChange, value],
  );
  return (
    <FieldWrapper>
      <Content>
        <div>
          <NumberInput
            label="Start"
            value={value?.start}
            onChange={onUpdateStart}
            onBlur={onBlur}
            name="start"
            id="start"
            placeholder="Start value"
            state={state}
          />
        </div>
        <div>
          <NumberInput
            label={
              authStore?.auth?.user?.workspace?.framework === 'kpi'
                ? 'Base'
                : 'Target'
            }
            value={value?.target}
            onChange={onUpdateTarget}
            onBlur={onBlur}
            name="target"
            id="target"
            placeholder={
              authStore?.auth?.user?.workspace?.framework === 'okr'
                ? 'Target value'
                : 'Base value'
            }
            state={state}
          />
        </div>
        {authStore?.auth?.user?.workspace?.framework === 'kpi' && (
          <>
            <div style={{marginTop: 8}}>
              <NumberInput
                label={
                  authStore?.auth?.user?.workspace?.framework === 'kpi'
                    ? 'Good'
                    : 'Good'
                }
                value={value?.good}
                onChange={onUpdateGood}
                onBlur={onBlur}
                name="good"
                id="good"
                placeholder="Good value"
                state={state}
              />
            </div>
            <div style={{marginTop: 8}}>
              <NumberInput
                label={
                  authStore?.auth?.user?.workspace?.framework === 'kpi'
                    ? 'Stretch'
                    : 'Stretch'
                }
                value={value?.stretch}
                onChange={onUpdateStretch}
                onBlur={onBlur}
                name="stretch"
                id="stretch"
                placeholder="Stretch value"
                state={state}
              />
            </div>
          </>
        )}
      </Content>
      <Helper aria-label="helper" state={state} children={helper} />
    </FieldWrapper>
  );
});
RangeInput.displayName = 'RangeInput';
