import {useCallback, useState} from 'react';
import {
  FIREBASE_BASE_URL,
  database,
  updateGoogleTokens,
} from '@utils/firebase-request';
import {get, ref} from 'firebase/database';
import {authStore} from '@store/stores/auth-store';
import ApiCalendar from './google-calender';
import axios from 'axios';
import {useStoreContext} from '@store/store-context';

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID_CALENDAR || '';

const clientSecret = process.env.REACT_APP_GOOGLE_CLIENT_SECRET || '';
const calendarScope = 'https://www.googleapis.com/auth/calendar';

const config = {
  clientId,
  clientSecret,
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
  scope:
    'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/userinfo.email',
  discoveryDocs: [
    'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
  ],
};

const apiCalendar = new ApiCalendar(config);

export const useGCalenderHook = () => {
  const userId = authStore.auth.user.id;

  const firebasePath = `${FIREBASE_BASE_URL}/${authStore.auth.user.workspace.id}/users/${userId}/integration`;

  const [googleRefreshToken, setGoogleRefreshToken] = useState('');

  const {
    integrationStore: {
      googleTokenLoader: loader,
      setLoader,
      googleCalenderIsSigned: isSigned,
      googleScope,
      setScope,
      setSignStatus: setIsSigned,
    },
  } = useStoreContext();

  const [connectedScope, setConnectedScope] = useState('');

  const fetchGoogleToken = useCallback(async () => {
    setLoader(true);

    try {
      const snapshot = await get(ref(database, firebasePath));
      const data = snapshot?.val();

      if (data) {
        setGoogleRefreshToken(data.refresh_token);

        await axios
          .post('https://oauth2.googleapis.com/token', {
            client_id: clientId,
            client_secret: clientSecret,
            refresh_token: data.refresh_token,
            grant_type: 'refresh_token',
          })
          .then((response) => {
            setConnectedScope(response.data.scope);
            setScope(response.data.scope);

            const updateToken = () =>
              apiCalendar.setToken({
                ...data,
                access_token: response.data.access_token,
              });

            apiCalendar.onLoad(updateToken);
          })
          .catch((error) => {});

        setIsSigned(true);
      } else {
        setIsSigned(false);
      }
    } catch (error) {
      setIsSigned(false);
    } finally {
      setLoader(false);
    }
  }, [setLoader, firebasePath, setIsSigned, setScope]);

  const handleLogOut = () => {
    apiCalendar.handleSignoutClick();

    updateGoogleTokens(userId, null, true);
  };

  const isCalendarConnected =
    isSigned && !!googleScope && googleScope.includes(calendarScope);

  return {
    ...apiCalendar,
    handleAuthClick: apiCalendar.handleAuthClick,
    deleteEvent: apiCalendar.deleteEvent,
    createEventWithVideoConference: apiCalendar.createEventWithVideoConference,
    createEvent: apiCalendar.createEvent,
    getCalendarEventById: apiCalendar.getEvent,
    updateEvent: apiCalendar.updateEvent,
    listCalendarEvents: apiCalendar.listUpcomingEvents,
    getEvent: apiCalendar.getEvent,
    handleLogOut,
    loader,
    googleRefreshToken,
    isSigned,
    connectedScope,
    fetchGoogleToken,
    isCalendarConnected,
    setConnectedScope,
    calendarScope,
    setIsSigned,
    getEmail: apiCalendar.getEmail,
  };
};
