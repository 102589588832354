import {FC, memo} from 'react';

export interface IArrow {
  direction: 'up' | 'down';
  color: 'red' | 'green';
}

export const ArrowIcon: FC<IArrow> = memo(({direction, color}) => {
  return direction === 'down' ? (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 3.33398V12.6673"
        stroke={color === 'red' ? '#D52A2A' : '#47B881'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6654 8L7.9987 12.6667L3.33203 8"
        stroke={color === 'red' ? '#D52A2A' : '#47B881'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 12.6673V3.33398"
        stroke={color === 'red' ? '#D52A2A' : '#47B881'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.33203 8.00065L7.9987 3.33398L12.6654 8.00065"
        stroke={color === 'red' ? '#D52A2A' : '#47B881'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

ArrowIcon.displayName = 'ArrowIcon';
