import styled from 'styled-components';
import {Body1} from '@ui/atoms/typography';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {Button} from '@ui/atoms/button';

const Wrapper = styled.div<{width?: string}>`
  border: 1px solid #ededf2;
  min-height: 158px;
  position: absolute;
  z-index: 3;

  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.05);
  background-color: white;
  width: 305px;
  border-radius: 6px;
`;

const CardHeader = styled.div`
  padding: 16px;
`;

const Footer = styled.div`
  padding: 12px 10px;
  display: flex;
  z-index: 3;
  border-top: 1px solid #ededf2;
  justify-content: space-between;
  position: relative;
`;

interface CardProps {
  title: string;

  description: string;

  loading?: boolean;
  disabled?: any;
  onSubmit?: (e: any) => void;

  onClickCancel: (e: any) => void;
  width?: string;
}
export function DeleteMiniDropdown({
  title,
  description,
  onSubmit,
  disabled,
  loading,
  width,
  onClickCancel,
}: CardProps) {
  return (
    <>
      <Wrapper width={width}>
        <CardHeader>
          <Body1 align="center" weight="bold">
            {title}
          </Body1>
          <VerticalSpacer size="10px" />
          <Body1 kind="textBody" align="center">
            {description}
          </Body1>
        </CardHeader>

        <Footer>
          <Button
            style={{
              padding: '8px 20px',
              backgroundColor: '#D52A2A',
            }}
            onClick={onSubmit}
            isLoading={loading}
            disabled={disabled}>
            Delete
          </Button>
          <Button
            width="sm"
            kind="secondary"
            onClick={onClickCancel}
            style={{
              padding: '8px 16px',
            }}>
            Cancel
          </Button>
        </Footer>
      </Wrapper>
    </>
  );
}
