import {CcController} from '@api/cc-api/cc-controller';
import {CcRequestImpl} from '@api/cc-api/cc-request';
import {useStoreContext} from '@store/store-context';
import {useQuery} from 'react-query';

export const useReviewsHook = (type?: string) => {
  const {
    ccStore: {
      updateReviewCycles,
      cycleFilterOptions,
      currentReviewCycle,
      updateCurrentReviewCycle,
      updateCycleFilterOptions,
    },
  } = useStoreContext();

  const request = new CcRequestImpl();
  const controller = new CcController(request);

  const fetchReviews = async () => {
    const response = await controller.fetchUserReviewCycle({
      status: type === 'results' ? 'ended' : cycleFilterOptions.status.value,
    });

    if (response) {
      const sortedResponse = response?.sort((a, b) => {
        return (
          new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
        );
      });

      updateReviewCycles(sortedResponse);

      if (sortedResponse.length > 0 && !currentReviewCycle.id) {
        updateCurrentReviewCycle(sortedResponse[0]);

        updateCycleFilterOptions({
          label: sortedResponse[0].cycleName,
          value: sortedResponse[0].id,
        });
      }

      return sortedResponse;
    }
    return response;
  };

  const {data, isLoading} = useQuery(
    ['user-review-cycle', cycleFilterOptions],
    () => fetchReviews(),
  );

  const reviewCycleOptions = () => {
    return data ?? [];
  };

  return {
    isLoading,
    reviewCycleOptions,
    currentReviewCycle,
    controller,
  };
};
