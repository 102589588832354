import {useStoreContext} from '@store/store-context';
import {usePostHog} from 'posthog-js/react';

export const usePostHogHook = () => {
  const postHog = usePostHog();

  const {
    authStore: {auth},
  } = useStoreContext();

  const postHogCapture = (
    name: string,
    data?: any,
    additionalOptions?: any,
  ) => {
    const workspace = data ? data : auth.user.workspace;

    postHog.capture(name, {
      ...additionalOptions,
      workspaceName: workspace.name,
      workspaceId: workspace.id,
      workspaceCreatedAt: workspace.createdAt,
      workspaceBillingStatus: workspace.billing.status,
      workspaceBillingPlan: workspace.billing.plan,
    });
  };
  return {
    postHogCapture,
  };
};
