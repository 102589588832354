import {AxiosResponse} from 'axios';
import {BaseRequest} from '../../../../../../../configs/request';
import {authStore} from '../../../../../../../store/stores/auth-store';
import {
  WorkspaceCheckinsSettingsForm,
  WorkspaceCheckinsSettingsResponse,
} from './admin-checkins.interface';

export interface WorkspaceCheckinsSettingsRequest extends BaseRequest {
  url: string;
  updateCheckinsSettings(
    data: WorkspaceCheckinsSettingsForm,
    token?: string,
  ): Promise<WorkspaceCheckinsSettingsResponse>;
  getCheckinsSettings(): Promise<WorkspaceCheckinsSettingsForm>;
}

export class WorkspaceCheckinsSettingsRequestImpl
  extends BaseRequest
  implements WorkspaceCheckinsSettingsRequest {
  url: string = '/check-in';

  async updateCheckinsSettings<WorkspaceCheckinsSettingsResponse>(
    data: WorkspaceCheckinsSettingsForm,
    token?: string,
  ) {
    const {data: responseData} = await this.request.patch<
      WorkspaceCheckinsSettingsForm,
      AxiosResponse<WorkspaceCheckinsSettingsResponse>
    >(this.url, data, {
      headers: {
        Authorization: `Bearer ${token || authStore.auth?.tokens.access.token}`,
      },
    });
    return responseData;
  }

  async getCheckinsSettings() {
    const {data: responseData} = await this.request.get(this.url, {
      headers: {
        Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
      },
    });
    return responseData;
  }
}
