import styled, {css} from 'styled-components';
import {ifProp} from 'styled-tools';
import {memo, PropsWithChildren, SyntheticEvent} from 'react';
import {motion} from 'framer-motion';
import {Typography} from '../typography/typography';
import {getColorFromTheme} from '../../ui-utils';
import {NavLink} from 'react-router-dom';

const SharedNavItem = css`
  ${Typography};

  padding: 0 0 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.7;
  letter-spacing: normal;
  color: ${ifProp(
    '$active',
    getColorFromTheme('textDark'),
    getColorFromTheme('textBody'),
  )};
  display: block;
  text-decoration: none;
  position: relative;
  max-width: 100%;
  cursor: pointer;
  flex-shrink: 0;
  overflow: hidden;
  margin-right: 24px;

  &:last-of-type {
    margin-right: 0;
  }

  &:hover {
    color: ${getColorFromTheme('textDark')};
  }
`;

const NavItem = styled(NavLink)<{$active?: boolean}>`
  ${SharedNavItem}
`;

const ButtonItem = styled.button<{$active?: boolean}>`
  ${SharedNavItem}
`;

const NavItemBackground = styled(motion.span)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${getColorFromTheme('purple300')};
  z-index: 1;
  width: inherit;

  height: 4px;
`;

const NavItemContent = styled.span`
  z-index: 2;
  position: relative;
  display: flex;
  align-items: center;
`;

export interface TabNavProps
  extends PropsWithChildren<{
    active: boolean;
    href?: string;
    type?: 'button' | 'link';
    onClick?: (e: SyntheticEvent<HTMLAnchorElement>) => void;
  }> {}

export const TabNav = memo(function ({
  href,
  type,
  onClick,
  active,
  children,
}: TabNavProps) {
  const TabComponent = type === 'button' ? ButtonItem : (NavItem as any);

  return (
    <TabComponent to={href ?? ''} onClick={onClick} $active={active}>
      {active && (
        <NavItemBackground
          layoutId="tab-nav-background"
          initial={true}
          transition={{
            duration: 0.3,
            layoutX: {duration: 0.3},
            layoutY: {delay: 0, duration: 0},
          }}
        />
      )}
      <NavItemContent tabIndex={-1}>{children}</NavItemContent>
    </TabComponent>
  );
});
TabNav.displayName = 'TabNav';
