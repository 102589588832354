import {CommentsBodyStyle, UserHeader} from '../comments-style';
import {Body2} from '../../../atoms/typography';
import dayjs from 'dayjs';
import {getFromBetween, getName} from '../../../../utils/get-between';
// import {writeData} from '../../../../utils/firebase-request';
import {Avatar} from '../../../atoms/avatar';
import {HorizontalSideRule} from '../../../atoms/spacer/spacer';

export const FailedComment = ({users, currentUser}: any) => {
  const items: any = JSON.parse(localStorage.failComment);

  const refactorComment = (comment: any) => {
    const results = getFromBetween.get(comment?.comment, '_(', ')_');

    let text = comment?.comment;
    if (comment.edited) {
      text = `${comment.comment} <span style="color: #BFBFD4;">(edited)</span>`;
    } else {
      text = comment.comment;
    }

    results?.forEach((id: any) => {
      text = text.replace(
        `_(${id})_`,
        `<span style="color: #BFBFD4;">@${getName(id, users)}</span>`,
      );
    });
    return text;
  };

  return (
    <div>
      <div>
        <div>
          {items
            ?.filter((item: any) => item.user === currentUser)
            .map((item: any) => (
              <>
                <div
                  style={{
                    display: 'flex',
                    border: '0.5px solid #EDEDF2',
                    marginTop: '10px',
                    width: '95%',
                    margin: '20px 20px 5px 20px',
                    height: '0px',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}></div>
                <div style={{padding: '10px 12px 12px 12px'}}>
                  <UserHeader>
                    {users
                      ?.filter((user: any) => user?.id === item.commentBy)
                      .map((user: any) => (
                        <div style={{display: 'flex', alignItems: 'center'}}>
                          <Avatar
                            name={
                              user?.firstName && user?.lastName
                                ? user?.firstName + ' ' + user?.lastName
                                : user?.email
                            }
                            src={user?.avatar}
                            userId={''}
                            tooltip={false}
                            size="lg"
                          />
                          <Body2
                            style={{
                              fontSize: '16px',
                              fontWeight: 500,
                              lineHeight: '150%',
                              marginLeft: '5px',
                            }}>
                            {user?.firstName && user?.lastName
                              ? user?.firstName + '  ' + user?.lastName
                              : user?.email}
                          </Body2>
                        </div>
                      ))}
                  </UserHeader>
                  <div style={{display: 'flex'}}>
                    <CommentsBodyStyle
                      style={{
                        width: '90%',
                        marginTop: '10px',
                        marginLeft: '8px',
                      }}>
                      {item?.goalMentions.name !== undefined && (
                        <div>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',

                              padding: '10px 16px 10px 0px',
                            }}>
                            <Body2>#</Body2>
                            <div
                              style={{
                                border: '1px solid  #EDEDF2',
                                borderRadius: '4px',
                                maxWidth: '214px',
                                height: '24px',
                                fontWeight: 500,
                                textOverflow: 'ellipsis',
                                display: 'flex',
                                background: 'white',
                                alignItems: 'center',
                                fontSize: '14px',
                                padding: '2px 4px',
                                marginLeft: '5px',
                              }}>
                              {item?.goalMentions?.name.length > 25
                                ? `${item?.goalMentions?.name.substr(0, 25)}...`
                                : item?.goalMentions?.name}
                            </div>
                          </div>
                        </div>
                      )}
                      <div style={{display: 'flex', width: '550px'}}>
                        <Body2
                          className="taggedStyle"
                          dangerouslySetInnerHTML={{
                            __html: refactorComment(item),
                          }}
                          style={{
                            alignItems: 'center',
                            fontWeight: 400,
                            color: '#BFBFD4',
                          }}
                        />{' '}
                      </div>
                    </CommentsBodyStyle>
                    <Body2
                      style={{fontSize: '14px ', whiteSpace: 'nowrap'}}
                      kind="textMedium">
                      {dayjs(item?.createdAt).format('hh:mm a')}
                    </Body2>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    width: '50%',
                    padding: '10px 20px',
                  }}>
                  <Body2>Message failed to send</Body2>
                  <Body2
                    onClick={() => {
                      // writeData(
                      //   {
                      //     comment: item.comment,
                      //     commentBy: item.commentBy,
                      //     createdAt: item.createdAt,
                      //     edited: false,
                      //     user: item.user,
                      //     rawComment: item.entities,
                      //     updatedAt: item.updatedAt,
                      //     goalMentions: item?.goalMentions,
                      //   },
                      //   currentUser,
                      // );
                      const res = JSON.parse(localStorage.failComment).filter(
                        (iteme: any) => iteme.comment !== item.comment,
                      );
                      localStorage.setItem('failComment', JSON.stringify(res));
                    }}
                    kind="red400"
                    style={{
                      fontWeight: 600,
                      margin: '0px 8px',
                      cursor: 'pointer',
                    }}>
                    Retry
                  </Body2>
                  <HorizontalSideRule size="20px" />
                  <Body2
                    kind="red400"
                    onClick={() => {
                      const res = JSON.parse(localStorage.failComment).filter(
                        (iteme: any) => iteme.comment !== item.comment,
                      );
                      localStorage.setItem('failComment', JSON.stringify(res));
                    }}
                    style={{
                      fontWeight: 600,
                      margin: '0px 8px',
                      cursor: 'pointer',
                    }}>
                    Delete
                  </Body2>
                  <HorizontalSideRule size="20px" />
                </div>
              </>
            ))}
        </div>
      </div>
    </div>
  );
};
