import styled from 'styled-components';
import {getColorFromTheme} from '../../../ui-utils';

export const DropdownWrapper = styled.div`
  position: absolute;
  display: inline-block;
  top: 120%;
  left: 0;
  min-width: 310px;
  border-radius: 8px;
  border: solid 1px ${getColorFromTheme('borderDark')};
  background-color: ${getColorFromTheme('white')};
  box-shadow: ${({theme}) => theme.elevation.level2};
  z-index: 3;
`;

export const DropWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

export const ArrowIconWrapper = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 6px;

  svg {
    stroke: ${getColorFromTheme('textBody')};
    width: 20px;
    height: 20px;
  }
`;

export const AvatarWrapper = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const DropdownHandler = styled.button<{open: boolean}>`
  outline: none;
  color: ${getColorFromTheme('textDark')};
  width: 100%;
  display: inline-block;
  background-color: none;
  border: none;
`;

export const HandlerWrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
