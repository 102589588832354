import {DrawerModal} from '@ui/organisms/modal/drawer-modal';
import {Button} from '@ui/atoms/button';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {TextField} from '@ui/molecules/field/textfield';
import {CancelIcon} from '@ui/atoms/icons';
import {Body1, Body2} from '@ui/atoms/typography';
import {useState} from 'react';
import {Label} from '@ui/atoms/label';
import {removeFirebaseData} from '@utils/firebase-handler';

interface RescheduleProps {
  open: boolean;
  onSubmit: (text: string) => void;
  tags?: {key: string; tag: string; disabled?: boolean; goals?: string[]}[];
  onClose: () => void;
}

export const ManageGoalTags = ({
  open,
  tags,
  onSubmit,
  onClose,
}: RescheduleProps) => {
  const [tag, setTag] = useState('');

  return (
    <div>
      <DrawerModal open={open} onClose={onClose} title="Manage KPI group tags">
        <VerticalSpacer size="32px" />

        <Label>What's the name of this tag?</Label>
        <VerticalSpacer size="8px" />

        <TextField
          inputStyle={{borderRadius: '10px'}}
          value={tag}
          placeholder="e.g. Customer satisfaction KPIs"
          onChange={(event) => {
            setTag(event.target.value);
          }}
        />

        <Button
          width="full"
          onClick={() => {
            onSubmit(tag.trim());
            setTag('');
          }}
          disabled={
            !tag ||
            tags?.some((_tag) => tag.toLowerCase() === _tag.tag.toLowerCase())
          }>
          Add tag
        </Button>
        <VerticalSpacer size="32px" />
        <Body2 weight="semibold">Tags </Body2>
        <VerticalSpacer size="16px" />
        <div className="w-[450px] sm:w-full">
          <div className="flex items-center flex-wrap gap-4">
            {tags &&
              tags.map((tag) => (
                <div
                  className="px-4 py-2 bg-[#F6F6F8] rounded-full gap-4 flex items-center justify-between"
                  key={tag.key}>
                  <Body1 className="whitespace-nowrap">{tag.tag}</Body1>
                  <div
                    onClick={() =>
                      !!tag.goals?.length
                        ? null
                        : removeFirebaseData(`goal_tags/${tag.key}`)
                    }
                    className={
                      !!tag.goals?.length
                        ? 'cursor-not-allowed'
                        : 'cursor-pointer'
                    }>
                    <CancelIcon
                      color={!!tag.goals?.length ? '#BFBFD4' : undefined}
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </DrawerModal>
    </div>
  );
};
