import {AxiosResponse} from 'axios';
import {authStore} from '../../../store/stores/auth-store';
import {BaseRequest} from '../../../configs/request';
import {
  MemberPasswordData,
  MemberEmailData,
  MemberReviewerData,
  MemberProfileFormData,
  MemberProfileResponse,
} from './member-profile-interface';

export interface MemberProfileRequest extends BaseRequest {
  memberForm(
    data: MemberProfileFormData,
    token: string,
  ): Promise<MemberProfileResponse>;
  memberEmail(
    data: MemberEmailData,
    token: string,
  ): Promise<MemberProfileResponse>;
  memberPassword(
    data: MemberPasswordData,
    token: string,
  ): Promise<MemberProfileResponse>;
  memberReviewer(
    data: MemberReviewerData,
    token: string,
  ): Promise<MemberProfileResponse>;
  fetchUser(): Promise<MemberProfileResponse>;
}

export class MemberProfileRequestImpl
  extends BaseRequest
  implements MemberProfileRequest {
  private memberFormEndpoint: string = `/users/${authStore.auth?.user.id}`;
  private memberPasswordEndPoint: string = `/users/${authStore.auth?.user.id}/change-password`;
  private currentUserEndPoint: string = `/users/me?include=[reviewer]`;

  async memberForm<MemberProfileResponse>(data: MemberProfileFormData) {
    const {data: responseData} = await this.request.patch<
      MemberProfileFormData,
      AxiosResponse<MemberProfileResponse>
    >(this.memberFormEndpoint, data, {
      headers: {
        Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
      },
    });
    return responseData;
  }

  async memberEmail<MemberProfileResponse>(data: MemberEmailData) {
    const {data: responseData} = await this.request.patch<
      MemberEmailData,
      AxiosResponse<MemberProfileResponse>
    >(this.memberFormEndpoint, data, {
      headers: {
        Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
      },
    });
    return responseData;
  }

  async memberReviewer<MemberProfileResponse>(data: MemberReviewerData) {
    const {data: responseData} = await this.request.patch<
      MemberReviewerData,
      AxiosResponse<MemberProfileResponse>
    >(this.memberFormEndpoint, data, {
      headers: {
        Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
      },
    });
    return responseData;
  }

  async memberPassword<MemberProfileResponse>(data: MemberPasswordData) {
    const {data: responseData} = await this.request.post<
      MemberPasswordData,
      AxiosResponse<MemberProfileResponse>
    >(this.memberPasswordEndPoint, data, {
      headers: {
        Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
      },
    });
    return responseData;
  }

  async fetchUser() {
    const {data: responseData} = await this.request.get<
      any,
      AxiosResponse<MemberProfileResponse>
    >(this.currentUserEndPoint, {
      headers: {
        Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
      },
    });
    return responseData;
  }
}
