import {memo} from 'react';

export const FlatViewIcon = memo(({width}: any) => (
  <svg
    width={`${width ? width : 24}`}
    height={`${width ? width : 24}`}
    strokeWidth={1.5}
    viewBox={`${width ? `0 0 ${width} 24` : '0 0 24 24'}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 5.24609C4.24264 5.24609 5.25 4.23873 5.25 2.99609C5.25 1.75345 4.24264 0.746094 3 0.746094C1.75736 0.746094 0.75 1.75345 0.75 2.99609C0.75 4.23873 1.75736 5.24609 3 5.24609Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 14.2461C4.24264 14.2461 5.25 13.2387 5.25 11.9961C5.25 10.7535 4.24264 9.74609 3 9.74609C1.75736 9.74609 0.75 10.7535 0.75 11.9961C0.75 13.2387 1.75736 14.2461 3 14.2461Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 23.2461C4.24264 23.2461 5.25 22.2387 5.25 20.9961C5.25 19.7535 4.24264 18.7461 3 18.7461C1.75736 18.7461 0.75 19.7535 0.75 20.9961C0.75 22.2387 1.75736 23.2461 3 23.2461Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.25 3.74609H23.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.25 12.7461H23.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.25 21.7461H23.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

FlatViewIcon.displayName = 'FlatViewIcon';
