import {Body2} from '@ui/atoms/typography';
import {MentionIndicator} from '@ui/layouts/users-goals/comments-style';
import {useStoreContext} from '@store/store-context';
import {observer} from 'mobx-react';
import {FIREBASE_BASE_URL, database} from '@utils/firebase-request';
import {onValue, ref} from 'firebase/database';
import {GoalTasks} from './tasks/goal-tasks';
import {useMemo} from 'react';
import {AddMentions} from '@utils/firebase-request';
import {useState, useEffect, useCallback} from 'react';
import styled from 'styled-components';
import {ColorIconRed} from '@ui/atoms/color-icons';
import {CommentTab} from './comment/comment';
import {InsightTab} from './insight/insight';
import {TabNavigation} from '@ui/molecules/tab-nav';
import {IGoalResult} from '@hooks';

const IconWrapper = styled.div`
  margin-top: 1px;
`;

export const MemberActivity = observer(
  ({
    goal,
    isReadOnly,
    updateTimeRange = () => null,
    timeRange = '',
  }: {
    updateTimeRange?: (value: string) => void;
    timeRange?: string;
    goal: IGoalResult;
    isReadOnly?: boolean;
  }) => {
    const {
      storeDashboardRoutes: {InsightTabs, activateInsightTabs},
      goalsState: {handleClearComment},
      authStore: {auth},
    } = useStoreContext();

    const [goalSubscribers, setGoalSubscribers] = useState([]);
    const [mentions, setMentions] = useState<any>([]);

    const [currentUserMention, setCurrentUserMention] = useState<any>({
      unreadComment: 0,
      unreadTasks: 0,
      tasksMentionedBy: [],
      mentionedBy: [],
    });

    useEffect(() => {
      onValue(
        ref(
          database,
          `${FIREBASE_BASE_URL}/${auth.user.workspace.id}/users/${auth.user.id}/mentions/goals/${goal?.id}`,
        ),
        (snapshot) => {
          const response = snapshot.val();

          if (snapshot.exists()) {
            setCurrentUserMention(response);
          }
        },
      );
    }, [goal?.id, auth.user.id, auth.user.workspace.id]);

    useEffect(() => {
      onValue(
        ref(
          database,
          `${FIREBASE_BASE_URL}/${auth.user.workspace.id}/goals/${goal?.id}`,
        ),
        (snapshot: any) => {
          const subscribers = snapshot.val()?.subscribers;

          if (subscribers && subscribers.length) {
            setGoalSubscribers(subscribers);
            setMentions([]);
            subscribers.forEach((subscriber: string) => {
              onValue(
                ref(
                  database,
                  `${FIREBASE_BASE_URL}/${auth.user.workspace.id}/users/${subscriber}/mentions/goals/${goal?.id}`,
                ),
                (snapshot: any) => {
                  const response = snapshot.val();

                  if (response !== null && response) {
                    setMentions((prev: any) => [
                      ...prev,
                      {[subscriber]: response},
                    ]);
                  }
                },
              );
            });
          }
        },
      );
    }, [goal?.id, auth.user.id, auth.user.workspace.id]);

    const handleUserViewComments = useCallback(() => {
      AddMentions(
        auth.user.id,
        goal.id,
        {
          ...currentUserMention,
          unreadComment: 0,
          mentionedBy: [],
        },
        'goals',
      );
    }, [auth.user.id, goal.id, currentUserMention]);

    const navigations = useMemo(() => {
      return [
        {
          active: InsightTabs.insight,
          href: '/',
          id: '1',
          onClick: (e: any) => {
            e.preventDefault();
            activateInsightTabs('insight');
          },
          title: `Insights`,
        },
        {
          active: InsightTabs.comment,
          href: '/',
          id: '2',
          onClick: (e: any) => {
            e.preventDefault();
            activateInsightTabs('comment');
            handleUserViewComments();
            handleClearComment(goal?.id);
          },
          title: `Comments`,
          iconPosition: 'left',
          icon: (
            <>
              {currentUserMention.unreadComment > 0 && (
                <MentionIndicator
                  style={{
                    background: currentUserMention?.mentionedBy?.length
                      ? '#D52A2A'
                      : '#ededf2',
                  }}>
                  <Body2
                    kind={
                      currentUserMention?.mentionedBy?.length
                        ? 'white'
                        : 'textBody'
                    }
                    style={{fontSize: '12px', fontWeight: 500}}>
                    {currentUserMention.unreadComment > 0
                      ? currentUserMention.unreadComment
                      : 0}
                  </Body2>
                </MentionIndicator>
              )}
            </>
          ),
        },
        {
          active: InsightTabs.tasks,
          href: '/',
          id: '1',
          onClick: (e: any) => {
            e.preventDefault();
            activateInsightTabs('tasks');
            handleClearComment(goal?.id);
          },
          title: `Action Items`,
          iconPosition: 'left',
          icon: (
            <>
              {currentUserMention.unreadTasks > 0 && (
                <IconWrapper>
                  <ColorIconRed width="8px" height="8px" margin={'4'} />
                </IconWrapper>
              )}
            </>
          ),
        },
      ];
    }, [
      InsightTabs.insight,
      InsightTabs.comment,
      handleClearComment,
      InsightTabs.tasks,
      currentUserMention,
      goal?.id,
      activateInsightTabs,
      handleUserViewComments,
    ]);

    const getSubscribers = () => {
      const krs = goal.keyResults.map((item: any) => item.assignee);
      const assigneeGoals = [
        ...Array.from(
          new Map(krs.map((item: any) => [item['id'], item])).values(),
        ),
      ];

      const EachAssignee = assigneeGoals.map((kr: any) => kr.id);

      return EachAssignee.includes(goal.assignee?.id)
        ? EachAssignee
        : [goal.assignee?.id, ...EachAssignee];
    };

    return (
      <div>
        <TabNavigation navigations={navigations} />
        {InsightTabs.comment && (
          <CommentTab
            id={goal?.id}
            handleMentionUser={handleUserViewComments}
            mentions={mentions}
            unreadComment={currentUserMention.unreadComment}
            isReadOnly={isReadOnly}
            defaultGoalSubscribers={getSubscribers()}
            allGoalSubscribers={goalSubscribers}
          />
        )}
        {InsightTabs.insight && (
          <InsightTab
            goal={goal}
            updateTimeRange={updateTimeRange}
            timeRange={timeRange}
          />
        )}
        {InsightTabs.tasks && (
          <GoalTasks
            goalId={goal.id}
            userId={goal.assignee?.id}
            isReadOnly={isReadOnly}
            unreadTasks={currentUserMention.unreadTasks}
            currentUserMentions={currentUserMention}
            mentions={mentions}
            defaultGoalSubscribers={getSubscribers()}
            allGoalSubscribers={goalSubscribers}
          />
        )}
      </div>
    );
  },
);
