import {makeAutoObservable, observable, action} from 'mobx';

export class BillingRoutesStore {
  @observable InactiveBillingTabs = {
    plans: true,
    seats: false,
    billingCycle: false,
  };
  constructor() {
    makeAutoObservable(this);
  }
  @action
  activateInactiveBillingTabs = (tab: 'plans' | 'seats' | 'billingCycle') => {
    switch (tab) {
      case 'plans':
        this.InactiveBillingTabs = {
          plans: true,
          seats: false,
          billingCycle: false,
        };
        break;
      case 'seats':
        this.InactiveBillingTabs = {
          plans: false,
          seats: true,
          billingCycle: false,
        };
        break;
      case 'billingCycle':
        this.InactiveBillingTabs = {
          plans: false,
          seats: false,
          billingCycle: true,
        };
        break;
      default:
        this.InactiveBillingTabs = {
          plans: true,
          seats: false,
          billingCycle: false,
        };
    }
  };
}

export const billingRoutesStore = new BillingRoutesStore();
