import {ChangeEvent, useCallback, useEffect, useReducer, useState} from 'react';
import {generateRandomDigits} from '@utils/generate-random-numbers';
import nextId from 'react-id-generator';
import {IAPIUserFormat} from './interface';
import {authStore} from '@store/stores/auth-store';
import {useDebouncedCallback} from 'use-debounce';

function defaultField(
  values: {
    text?: string;
    key?: string;
    assignee?: IAPIUserFormat;
    disabled?: boolean;
    completed?: boolean;
  }[],
  checkable?: boolean,
): Map<
  string,
  {
    text?: string;
    assignee?: IAPIUserFormat;
    disabled?: boolean;
    completed?: boolean;
  }
> {
  let emptArr = checkable
    ? [...values]
    : [...values, {text: '', completed: false}];

  if (values?.length) {
    return new Map(emptArr.map((v) => [v?.key || nextId(), {...v}]));
  }
  return new Map([]?.map((v) => [nextId(), v]));
}

function addField(
  fields: Map<
    string,
    {
      text?: string;
      assignee?: IAPIUserFormat;
      completed?: boolean;
    }
  >,
  addAvatar?: boolean,
): Map<
  string,
  {
    text?: string;
    assignee?: IAPIUserFormat;
    completed?: boolean;
  }
> {
  const generateId = generateRandomDigits(16);

  return new Map([
    ...Array.from(fields),
    [
      generateId,
      {
        text: '',
        assignee: addAvatar ? authStore.auth.user : undefined,
        completed: false,
      },
    ],
  ]);
}

function removeField(
  fields: Map<
    string,
    {
      text?: string;
      completed?: boolean;
    }
  >,
  key: string,
): Map<
  string,
  {
    text?: string;
    completed?: boolean;
  }
> {
  fields.delete(key);
  return new Map(fields);
}

function updateField(
  fields: Map<
    string,
    {
      text?: string;
      completed?: boolean;
    }
  >,
  key: string,
  value: {
    text?: string;
    completed?: boolean;
  },
): Map<
  string,
  {
    text?: string;
    completed?: boolean;
  }
> {
  fields.set(key, value);

  return new Map(fields);
}

interface ReducerState {
  fields: Map<
    string,
    {
      updatedBy?: string | undefined;
      assignee?: IAPIUserFormat;
      user?: string;
      disabled?: boolean;
      text?: string;
      completed?: boolean;
    }
  >;
}

export enum ActionType {
  ADD_FIELD,
  DEFAULT_FIELD,
  REMOVE_FIELD,
  UPDATE_FIELD,
}

type AddNewFieldAction = {
  type: ActionType.ADD_FIELD;
  payload: {
    addAvatar?: boolean;
  };
};
type DefaultFieldAction = {
  type: ActionType.DEFAULT_FIELD;
  payload: {
    value: {
      text?: string;
      completed?: boolean;
    }[];
    checkable?: boolean;
  };
};

type UpdateFieldAction = {
  type: ActionType.UPDATE_FIELD;
  payload: {
    value: {
      text?: string;
      completed?: boolean;
    };
    key: string;
  };
};
type RemoveFieldAction = {
  type: ActionType.REMOVE_FIELD;
  payload: {key: string};
};

type ReducerAction =
  | AddNewFieldAction
  | UpdateFieldAction
  | RemoveFieldAction
  | DefaultFieldAction;

function multiFieldReducer(
  state: ReducerState,
  action: ReducerAction,
): ReducerState {
  switch (action.type) {
    case ActionType.ADD_FIELD:
      return {
        fields: addField(state.fields, action.payload.addAvatar),
      };
    case ActionType.DEFAULT_FIELD:
      return {
        fields: defaultField(action.payload.value, action.payload.checkable),
      };
    case ActionType.REMOVE_FIELD:
      return {
        fields: removeField(state.fields, action.payload.key),
      };

    case ActionType.UPDATE_FIELD:
      return {
        fields: updateField(
          state.fields,
          action.payload.key,
          action.payload.value,
        ),
      };

    default:
      return state;
  }
}
interface IValue {
  text?: string;
  completed?: boolean;
}
export function useMultiField(
  value: {
    text?: string;
    completed?: boolean;
    key?: string;
  }[],
  setValue: (
    value: Array<
      | IValue
      | {
          key: string;
          val: IValue;
        }
    >,
    action?: 're-sort' | '',
    fieldId?: string,
  ) => void,
  handleShowAddField: () => void,
  checkable?: boolean,
  maxFields?: number,
  returnValueKey?: boolean,
  addAvatar?: boolean,
  updateValueInstantly?: boolean,
  excludeDefaultUser?: boolean,
  onCreateNewItem?: (data: any) => void,
  onComplete?: (data: any) => void,
) {
  const [valueUpdated, setValueUpdated] = useState(false);

  const [state, dispatch] = useReducer(multiFieldReducer, {
    fields: defaultField(value, checkable),
  });

  useEffect(() => {
    if (updateValueInstantly) {
      dispatch({
        type: ActionType.DEFAULT_FIELD,
        payload: {value, checkable},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleUpdateValue = useCallback(
    (key?: string) => {
      setValue(
        Array.from(state.fields)
          .filter(([_, val]) => val)
          .map(([key, val]) => (returnValueKey ? {key, val} : val)),
        '',
        key,
      );
    },
    [state.fields, setValue, returnValueKey],
  );

  const handleRemove = useCallback(
    async (key: string) => {
      dispatch({type: ActionType.REMOVE_FIELD, payload: {key}});
      handleUpdateValue();
    },
    [handleUpdateValue],
  );

  const handleChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>, key: string) => {
      const {value} = e.target;
      const value_ = {
        text: value,
        completed: state.fields.get(key)?.completed,
        assignee: state.fields.get(key)?.assignee || null,
      };

      await dispatch({
        type: ActionType.UPDATE_FIELD,
        payload: {value: value_, key},
      });

      handleUpdateValue(key);
      setValueUpdated(true);
      handleShowAddField();
    },
    [handleUpdateValue, state.fields, handleShowAddField],
  );

  const handleUserSelect = useCallback(
    async (e: string, key: string) => {
      const value_ = {
        text: state.fields.get(key)?.text,
        completed: state.fields.get(key)?.completed,
        assignee: e,
      };

      await dispatch({
        type: ActionType.UPDATE_FIELD,
        payload: {value: value_, key},
      });

      handleUpdateValue();
    },
    [handleUpdateValue, state.fields],
  );

  const handleCheck = useCallback(
    async (e: ChangeEvent<HTMLInputElement>, key: string) => {
      const {checked} = e.target;

      const value_ = {
        text: state.fields.get(key)?.text,
        completed: checked,
        assignee: state.fields.get(key)?.assignee || null,
        disabled: state.fields.get(key)?.disabled || null,
        updatedBy: authStore.auth.user.id || null,
      };

      await dispatch({
        type: ActionType.UPDATE_FIELD,
        payload: {value: value_, key},
      });

      onComplete?.({...value_, key});

      handleUpdateValue(key);

      setValueUpdated(true);
    },
    [handleUpdateValue, onComplete, state.fields],
  );

  const shouldShowRemove = useCallback(
    (index: number) => {
      if (state.fields.size - 1 !== index && state.fields.size > 2) {
        return true;
      }
      return false;
    },
    [state.fields],
  );
  const debounceItem = useDebouncedCallback(() => {
    const lastItem = Array.from(state.fields)[state.fields.size - 1];

    onCreateNewItem?.({key: lastItem[0], ...lastItem[1]});
  }, 4000);

  const handleAddItem = useCallback(async () => {
    if (maxFields) {
      if (state.fields.size >= maxFields) return;
      return dispatch({
        type: ActionType.ADD_FIELD,
        payload: {
          addAvatar: addAvatar && !excludeDefaultUser,
        },
      });
    }

    await dispatch({
      type: ActionType.ADD_FIELD,
      payload: {
        addAvatar: addAvatar && !excludeDefaultUser,
      },
    });

    setTimeout(() => {
      debounceItem();
    }, 1000);
  }, [
    maxFields,
    addAvatar,
    excludeDefaultUser,
    state.fields.size,
    debounceItem,
  ]);

  return {
    shouldShowRemove,
    handleChange,
    handleRemove,
    handleUserSelect,
    values: state.fields,
    valueUpdated,
    setValueUpdated,
    handleAddItem,
    handleCheck,
  };
}
