import {getColorFromTheme} from '../../ui-utils';
import styled from 'styled-components';

export const CommentsWrapper = styled.div`
  border-top: 1px solid ${getColorFromTheme('borderLight')};
  padding: 10px;
`;

export const WrapperHeader = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
`;

export const MentionIndicator = styled.div`
  width: 16px;
  height: 16px;
  margin: 0px 5px;
  border-radius: 100px;
  display: flex;
  color: white;
  font-weight: 500;
  align-items: center;
  justify-content: center;
`;

export const InputWrapper = styled.div`
  background: #ffffff;
  border: 1px solid #cecede;
  padding: 15px 20px;
  border-radius: 10px;
  max-width: 700px;
  max-height: 109px;
  .inputField {
    height: 80%;
    border: none;
    font-weight: 400;
    font-size: 14px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    resize: none;
  }
`;

export const MentionDetails = styled.div`
  background: #f6f6f8;
  border-radius: 10px;
  margin: 10px 0px;
  .link {
    display: flex;
    color: #5e5e73;
    z-index: -1;
    padding: 8px 2px;
    align-items: center;
  }
`;

export const Button = styled.button`
  width: 36px;
  height: 32px;
  color: white;
  background: #585adf;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
`;

export const EmojiSuggestionWrapper = styled.div`
  .emojiSelectPopover {
    border: 1px solid red;
    margin-top: 1.75em;
    // position: absolute;
  }
`;
export const InputWrapperHeader = styled.div`
  position: relative;

  .editor {
    width: 100%;
    max-width: 400px;

    font-size: 14px;
    margin-top: 4px;
    max-height: 60px;
    overflow-y: scroll;
    font-weight: 400;
    position: relative;
  }

  .editor :global(.public-DraftEditor-content) {
    min-height: 40px;
    word-wrap: break-word;
    overflow-x: hidden;
  }

    .editor .public-DraftEditorPlaceholder-root {
    color: #5f5f8c;
    pointer-events: none;
    width: 100%;
    font-size: 14px;
    font-weight 400;
  }

  .editor .public-DraftEditorPlaceholder-inner {
    position: absolute;
    pointer-events: none;
  }

  .testing button {
    padding-top: 5px;
    border: 0;
    width: 20px;
    margin-right: 8px;
  }

  .editor .emojiSuggestions {
    border: 1px solid red;
    margin-top: 1.75em;
    // position: absolute;
  }
  .testing .emojiSuggestions {
    border: 1px solid red;
    margin-top: 1.75em;
    // position: absolute;
  }
`;

export const MentionContainer = styled.div`
  .mentionSuggestionsEntryContainer {
    cursor: pointer;
    padding: 10px 0px;
    z-index: 5;
  }

  .mentionSuggestionsEntryText {
    color: #5f5f8c;
    font-weight: 400;

    font-size: 14px;
    line-height: 170%;
  }
`;

export const PopContainer = styled.div`
  background: white;
  z-index: 5;
  padding: 5px 10px;
  width: 300px;
  box-shadow: 0px 3px 40px rgba(208, 208, 221, 0.43);
  border-radius: 10px;
`;

export const UserHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
`;

export const CommentsBodyStyle = styled.div`
  .taggedStyle .mention {
    margin: 0px 5px;
    font-size: 14px;
    color: #5e5e73;
    font-weight: 600;
    text-decoration: none;
  }
  .taggedStyle .edited {
    color: #bfbfd4;
  }
`;
