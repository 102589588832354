import {object, string, number} from 'yup';

export const AdminBillingSubscriptionFormValidator = object().shape({
  seats: number().required('Kindly enter number of seats'),
  plan: string(),
});

export const AdminBillingBillingDetailsFormValidator = object().shape({
  type: string(),
  billing_details: object().shape({
    address: object().shape({
      city: string().nullable(),
      country: string().nullable(),
      line1: string().nullable(),
      line2: string().nullable(),
      postal_code: string().nullable(),
      state: string().nullable(),
    }),
    email: string().email(),
    name: string().nullable(),
    phone: string(),
  }),
  card: object().nullable(),
});

export const AdminBillingAddressFormValidator = object().shape({
  city: string().nullable(),
  country: string().nullable(),
  line1: string().nullable(),
  line2: string().nullable(),
  postal_code: string().nullable(),
  state: string().nullable(),
});
