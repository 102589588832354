import {memo} from 'react';
import styled from 'styled-components';
import {Body2} from '../../../atoms/typography/body2';
import {Headline4} from '../../../atoms/typography/headline4';
import {WorkspaceLogo} from '../../../atoms/workspace-logo';
import {HorizontalSpacer} from '../../../atoms/spacer';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export interface CurrentWorkspaceProps {
  name: string;
  email: string;
  logo?: string;
}

export const CurrentWorkspace = memo(function ({
  name,
  email,
  logo,
}: CurrentWorkspaceProps) {
  return (
    <Wrapper>
      <LogoWrapper>
        <WorkspaceLogo name={name} size="md" src={logo} />
        <HorizontalSpacer size="16px" />
        <div>
          <Headline4>{name}</Headline4>
          <Body2 kind="textBody">{email}</Body2>
        </div>
      </LogoWrapper>
    </Wrapper>
  );
});
CurrentWorkspace.displayName = 'CurrentWorkspace';
