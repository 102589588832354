import {FlexRowSpaceBetween} from '@ui/style/styles';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .form {
    width: 40%;

    margin-bottom: 30px;
    @media (min-width: 1100px) and (max-width: 1320px) {
      width: 40%;
    }
    @media (min-width: 768px) and (max-width: 1100px) {
      width: 70%;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

export const ConfigureWrapper = styled.div`
  border-radius: 10px;
  border: 1px solid #ededf2;
  background: var(--others-white, #fff);

  .total-sum {
    padding: 12px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ededf2;
    .number-count {
      margin-left: 5px;
      border-radius: 6px;
      border: 1px solid var(--border-light, #ededf2);
      background: #fff;
      padding: 4px 8px;
    }
  }
  .color-bg {
    border-radius: 8px;
    background: var(--background-dark, #f6f6f8);
    padding: 4px 8px;
  }
  .body-card {
    padding: 16px;
  }
`;
export const ManagerAssessmentWrapper = styled.div`
  border-radius: 8px;
  border: 1px solid var(--border-light, #ededf2);
  background: var(--others-white, #fff);
  padding: 16px 24px;
  margin-bottom: 24px;
`;
export const CategoryWrapper = styled.div`
  border: 1px solid #ededf2;
  margin-bottom: 24px;
  border-radius: 10px;
  background: var(--others-white, #fff);

  padding: 16px 12px;
`;

export const SavedWrapper = styled.div`
  border: 1px solid #ededf2;
  border-radius: 10px;
  background: var(--others-white, #fff);

  margin-bottom: 24px;

  .main-wrapper {
    padding: 12px 16px;
  }
  .expand-wrapper {
    padding: 16px;
    border-top: 1px solid #ededf2;
    .key-areas {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      justify-content: space-between;
      .weigh {
        border: 1px solid #ededf2;
        border-radius: 6px;
        padding: 4px 8px;
      }
    }
  }
`;

export const Card = styled(FlexRowSpaceBetween)`
  border-radius: 10px;
  border: 1px solid var(--border-dark, #cecede);
  background: var(--others-white, #fff);
  padding: 16px;
`;

export const Summary = styled.div`
  border: 1px solid #ededf2;
  border-radius: 10px;
  margin-bottom: 24px;
  background-color: white;
  padding: 16px;
  .info {
    background: #f6f6f8;
    border-radius: 10px;
    padding: 8px 16px;
  }
  .border {
    border: 1px solid #ededf2;
    border-radius: 10px;
    padding: 16px;
  }
`;
export const Values = styled.div`
  display: flex;
  align-items: center;
  height: 45px;
  width: 100%;

  .value {
    background: #f6f6f8;
    border-radius: 10px 0px 0px 10px;
    padding: 12px 16px;
    min-width: 50%;

    @media (max-width: 768px) {
      min-width: 44px;
    }
  }
  .competence {
    min-width: 60%;
    @media (max-width: 768px) {
      min-width: 80px;
    }
  }
  .input {
    border: 1px solid #cecede;
    width: 100px;
    border-radius: 0px 10px 10px 0px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .competence-value {
    border: 1px solid #cecede;
    max-width: 94px;
    border-radius: 0px 10px 10px 0px;
    padding: 11px 16px;

    @media (max-width: 768px) {
      max-width: 40px;
    }
  }
`;

export const TotalSum = styled.div<{borderColor: string}>`
  background: #fafafa;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: ${({borderColor}) => `1px solid ${borderColor}`};
  border-radius: 10px;
  .total {
    background: #ffffff;
    padding: 4px 8px;

    border: 1px solid #ededf2;
    border-radius: 6px;
  }
`;

export const CcTips = styled.div`
  border-radius: 10px;
  background: var(--background-light, white);
  padding: 16px 24px;
`;

export const ManagerAssessmentArea = styled.div`
  padding: 16px;
  border: 1px solid #ededf2;
  border-radius: 10px;
`;
