import PageLayoutTemplate from '@ui/templates/page-template/page-layout';
import {useNavigate} from 'react-router';
import {Body2} from '@ui/atoms/typography';
import {ThickPlusIcon} from '@ui/atoms/icons/thick-plus';
import {Button} from '@ui/atoms/button';
import {Wrapper, AdditionalOptionsWrapper} from './styles';
import {MoreVerticalIcon} from '@ui/atoms/icons';
import {activateNotification} from '@ui/molecules/notification/activate-notification';
import nextId from 'react-id-generator';
import {DropdownVertical} from '@ui/molecules/dropdown-vertical';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {TextField} from '@ui/molecules/field/textfield';
import {useStoreContext} from '@store/store-context';
import {useState, useMemo} from 'react';
import {FlexRowSpaceBetween, FlexRow} from '@ui/style/styles';
import {SetupWorkspaceRequestImpl} from '@pages/admin/setup-workspace/setup-workspace-request';
import {SetupWorkspaceController} from '@pages/admin/setup-workspace/setup-workspace-controller';
import {generateRandomDigits} from '@utils/generate-random-numbers';
import {observer} from 'mobx-react';
import {setFirebaseData} from '@utils/firebase-handler';

interface ICategory {
  value: string;
  id: string;
  error?: string;
}

const CreateValues = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [categories, setCategories] = useState<ICategory[]>([
    {
      value: '',
      id: nextId('categ'),
    },
  ]);

  const {
    authStore: {auth},
  } = useStoreContext();

  const companyValues = auth?.user?.workspace?.settings?.companyValues?.values;

  const updateCategory = (id: string, value: string) => {
    const categ = categories.map((category) =>
      id === category.id ? {...category, value, error: ''} : category,
    );

    setCategories(categ);
  };

  const deleteCategory = (id: string) => {
    const categ = categories.filter((category) => id !== category.id);
    setCategories(categ);
  };

  const categoriesCount = useMemo(() => {
    return companyValues?.length || 0;
  }, [companyValues]);

  const addCategory = () => {
    if (categoriesCount < 49) {
      setCategories((prev) => [
        ...prev,
        {
          value: '',
          id: nextId('categ'),
        },
      ]);
    }
  };

  const isValid = categories.every((category) => category.value);

  const handleSubmit = async () => {
    const request = new SetupWorkspaceRequestImpl();
    const controller = new SetupWorkspaceController(request);

    const allCategories = [
      ...companyValues.map((value: string) => {
        return {value};
      }),
      ...categories,
    ];

    const duplicateTextValues = allCategories.map((category, index) => {
      const categoryValue = category.value?.trim().toLowerCase();

      if (
        index !==
        allCategories.findIndex(
          (c) => c.value?.trim().toLowerCase() === categoryValue,
        )
      ) {
        return {
          ...category,
          error: 'Values already exist',
        };
      }

      return category;
    });

    const computeDuplicateValue = duplicateTextValues.filter(
      (value) => value.id,
    );
    if (computeDuplicateValue.some((error) => error.error)) {
      setCategories(computeDuplicateValue);
      return;
    }
    setLoading(true);

    const formattedCategories = [
      ...companyValues,
      ...categories.map((category) => category.value),
    ];

    const response = await controller.workspaceEdit(
      auth?.user?.workspace?.id,
      {
        settings: {
          ...auth?.user?.workspace?.settings,
          companyValues: {
            status: 'enabled',
            values: formattedCategories,
          },
        },
      },
      auth?.tokens?.access?.token,
    );
    setLoading(false);

    response &&
      activateNotification({
        title: `Success`,
        content: `Categories added`,
        kind: 'success',
      });
    response && setFirebaseData(`updates/settings`, generateRandomDigits(16));

    response && navigate(-1);
  };

  return (
    <PageLayoutTemplate
      title={'Create value categories'}
      onClick={() => navigate(-1)}>
      <Wrapper style={{}}>
        <div className="form">
          <VerticalSpacer size="24px" />

          <FlexRowSpaceBetween>
            <FlexRow>
              <Body2 weight="bold">Add company values</Body2>
            </FlexRow>
            <Body2 weight="bold">
              {categoriesCount}
              <span style={{color: '#5F5F8C'}}>/50</span>
            </Body2>
          </FlexRowSpaceBetween>
          <VerticalSpacer size="12px" />
          <div className="body-wrapper">
            {categories.map((category, index) => (
              <>
                <FlexRow style={{width: '100%'}} key={index}>
                  <TextField
                    style={{width: '100%'}}
                    margin
                    value={category.value}
                    helper={category?.error}
                    state={category?.error ? 'error' : 'default'}
                    onChange={(event) =>
                      updateCategory(category.id, event.target.value)
                    }
                    inputStyle={{borderRadius: '10px'}}
                    placeholder="Enter category here"
                  />
                  {(category.value || index !== 0) && (
                    <div style={{marginLeft: '6px'}}>
                      <DropdownVertical
                        dropdownWrapperStyle={{}}
                        customIcon={<MoreVerticalIcon />}
                        menu={
                          <div>
                            <DropdownItem
                              onClick={() => {
                                deleteCategory(category.id);
                              }}>
                              Delete
                            </DropdownItem>
                          </div>
                        }
                      />
                    </div>
                  )}
                </FlexRow>
                <VerticalSpacer size="12px" />
              </>
            ))}
            <AdditionalOptionsWrapper
              display
              onClick={addCategory}
              disabled={categories?.length >= 50}>
              <ThickPlusIcon color="#47B881" />
              <Body2 weight="bold" kind="textBody" style={{marginLeft: '4px'}}>
                Add category
              </Body2>
            </AdditionalOptionsWrapper>
          </div>
          <VerticalSpacer size="24px" />
          <Button
            style={{padding: '16px 32px'}}
            width="full"
            isLoading={loading}
            disabled={!isValid}
            onClick={handleSubmit}>
            Publish categories
          </Button>
        </div>
      </Wrapper>
    </PageLayoutTemplate>
  );
};

export default observer(CreateValues);
