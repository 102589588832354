import {makeAutoObservable, observable, action} from 'mobx';
import {IGroupType} from '../../hooks';

export class GroupTypeStore {
  @observable groupType: IGroupType[] = [];
  @observable groupTypeIdInStore: string = '';
  constructor() {
    makeAutoObservable(this);
  }
  @action
  clearGoalsStore = () => {
    this.groupType = [];
    this.groupTypeIdInStore = '';
  };

  @action
  setGroupTypeInStore = (data: any) => {
    this.groupType = data;
  };

  @action
  setGroupTypeIDInStore = (id: string) => {
    this.groupTypeIdInStore = id;
  };
}

export const groupTypeStore = new GroupTypeStore();
