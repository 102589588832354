import {useState, useCallback} from 'react';

export const usePaginationHook = () => {
  const [pages, setPages] = useState({
    page: 1,
    totalResults: 0,
    limit: 10,
    maxPageNumberLimit: 2,
    minPageNumberLimit: 0,
    totalPages: 1,
  });

  const updatePage = (field: keyof typeof pages, value: number) => {
    setPages({...pages, [field]: value});
  };

  const updatePagination = useCallback((data) => {
    setPages(data);
  }, []);

  const updateLimit = (pageLimit: number) => {
    updatePage('limit', pageLimit);
  };

  const goToPage = (pageNumber: number) => {
    updatePage('page', pageNumber);
  };

  const updateTotalPages = (totalPages: number) => {
    updatePage('totalPages', totalPages);
  };

  const nextPageBtn = () => {
    goToPage(pages.page + 1);

    if (pages.page + 1 > pages.maxPageNumberLimit) {
      updatePage(
        'maxPageNumberLimit',
        pages.maxPageNumberLimit + pages.totalPages,
      );
      updatePage(
        'minPageNumberLimit',
        pages.minPageNumberLimit + pages.totalPages,
      );
    }
  };

  const handlePrevBtn = () => {
    goToPage(pages.page - 1);
    if ((pages.page - 1) % pages.totalPages === 0) {
      updatePage(
        'maxPageNumberLimit',
        pages.maxPageNumberLimit - pages.totalPages,
      );
      updatePage(
        'minPageNumberLimit',
        pages.minPageNumberLimit - pages.totalPages,
      );
    }
  };
  const paginationPages = (totalResults: number, limit: number) => {
    const arr = [];
    for (let i = 1; i <= Math.ceil(totalResults / limit); i++) {
      arr.push(i);
    }
    return arr;
  };

  const updateTotalResults = (totalResults: number) => {
    updatePage('totalResults', totalResults);
  };

  return {
    goToPage,
    pages,
    handlePrevBtn,
    paginationPages,
    nextPageBtn,
    updateTotalPages,
    updateTotalResults,
    updateLimit,
    updatePagination,
  };
};
