import {CycleWidget} from '../style';
import {Body1, Body2} from '@ui/atoms/typography';
import {HorizontalSpacer, VerticalSpacer} from '@ui/atoms/spacer';
import {Button} from '@ui/atoms/button';
import styled from 'styled-components';
import {FlexRow, FlexRowSpaceBetween} from '@ui/style/styles';
import {ValueProps} from '../../create-review-interface';
import {SecondaryEyeOffIcon} from '@ui/atoms/icons/eye-off';
import {EyeIcon} from '@ui/atoms/icons';

type CycleDetailsProps = {
  anonymitySettings: ValueProps['allowAnonymity'];
  isDisabled?: boolean;

  editData: (step: number) => void;
  nominationSettings: {max: number; min: number};
};

const StyledSettings = styled(FlexRow)`
  border-radius: 10px;
  background: var(--background-light, #fafafa);
  padding: 14px 16px;
  svg {
    path: {
      stroke: #585adf;
    }
  }
  .item {
    width: 50%;
  }
`;
export const PreviewCycleSettings = ({
  anonymitySettings,
  nominationSettings,
  isDisabled,
  editData,
}: CycleDetailsProps) => {
  const nominations = [
    {
      label: 'Minimum reviewers ',

      value: nominationSettings.min,
    },
    {
      label: 'Maximum reviewers ',

      value: nominationSettings.max,
    },
  ];
  const CycleDetails = () => {
    return (
      <CycleWidget className="bg-white">
        <FlexRowSpaceBetween>
          <Body1 weight="bold" style={{fontSize: '18px'}}>
            Cycle settings
          </Body1>
          <Button
            kind="secondary"
            disabled={isDisabled}
            style={{padding: '8px 16px'}}
            onClick={() => editData(3)}>
            Edit
          </Button>
        </FlexRowSpaceBetween>
        <VerticalSpacer size="24px" />
        <Body2 weight="bold">Nomination settings</Body2>
        <VerticalSpacer size="12px" />
        <StyledSettings>
          {nominations.map((nomination) => (
            <div className="item">
              <Body2 weight="semibold" kind="textBody">
                {nomination.label}
              </Body2>
              <VerticalSpacer size="12px" />
              <Body1 weight="bold">{nomination.value}</Body1>
            </div>
          ))}
        </StyledSettings>
        <VerticalSpacer size="24px" />
        <Body2 weight="bold">Anonymity settings</Body2>
        <VerticalSpacer size="12px" />

        <StyledSettings>
          <FlexRow>
            {anonymitySettings ? (
              <SecondaryEyeOffIcon style={{stroke: '#585ADF'}} />
            ) : (
              <EyeIcon style={{stroke: '#585ADF'}} />
            )}{' '}
            <HorizontalSpacer size="12px" />{' '}
            <Body2 weight="semibold">
              {' '}
              Replies are {anonymitySettings ? 'anonymous' : 'visible'}
            </Body2>{' '}
          </FlexRow>
        </StyledSettings>
      </CycleWidget>
    );
  };
  return <CycleDetails />;
};
