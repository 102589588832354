import {observer} from 'mobx-react-lite';
import {IntegrationBody, Wrapper} from '../../components/app-body';
import {Button} from '@ui/atoms/button';
import {SuccessIcon} from '@ui/atoms/svg/success-icon';
import dayjs from 'dayjs';
import styled from 'styled-components';
import {useHubspotHook} from './hubspot-hook';
import {cfv} from '@utils/framework';
import {Helper} from '@ui/atoms/helper';
import {Dropdown} from '@ui/molecules/dropdown';
import {Body2, Body1, Headline2} from '@ui/atoms/typography';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {CancelIcon} from '@ui/atoms/icons';
import {SetStateAction, Dispatch} from 'react';
import {BackAction} from '@ui/molecules/back-action';
import {Modal} from '@ui/molecules/modal';
import {ModalCard} from '@ui/layouts/modal-card';
import {useBreakpoints} from '@utils/use-breakpoints';
import {HubspotIcon} from '@ui/atoms/icons/apps/hubspot';

const hubspoturl: any = process.env.REACT_APP_HUBSPOT_URL;

const StatusButton = styled(Button)<{_width?: string}>`
  width: ${({_width}) => (_width ? _width : '118px')};
  height: 38px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SyncRecord = styled.div`
  border-top: 1px solid #ededf2;

  div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 16px;
  }
`;
interface IProps {
  setActiveIntegrations: Dispatch<SetStateAction<any>>;
  data: {
    app: string;
    id: string;
    lastSync: string | null;
    status: string;
    error?: any;
  };
}

export const HubSpot = observer(({data, setActiveIntegrations}: IProps) => {
  const {status, lastSync} = data;
  const {
    handleDisconnectHubspotConnection,
    disconnectLoading,
    openModal,
    setOpenModal,
  } = useHubspotHook();

  const Body = {
    title: 'Hubspot',
    image: <HubspotIcon />,
    description: `Linking HubSpot CRM enables the creation of actions and ${
      cfv(undefined, true).k_rs
    } tied directly to critical metrics, that are then updated automatically in real time.`,
  };

  const CustomButton = () => {
    switch (status) {
      case 'connect':
        return (
          <StatusButton as={'a'} href={hubspoturl} kind="secondary">
            Connect
          </StatusButton>
        );
      case 'disconnected':
        return (
          <StatusButton kind="secondary" as={'a'} href={hubspoturl}>
            {data?.error ? 'Re-connect' : 'Connect'}
          </StatusButton>
        );
      case 'connected':
        return (
          <StatusButton kind="secondary" _width={'136px'}>
            <span style={{margin: '0px 5px 0px 0px'}}>
              {' '}
              <SuccessIcon />
            </span>{' '}
            Connected
          </StatusButton>
        );
      default:
        break;
    }
  };

  const HubSpotDropDown = () => {
    return (
      <Dropdown
        dropdownItemStyle={{whiteSpace: 'nowrap'}}
        menu={[
          {
            content: `Disconnect`,
            id: 'disconnect',
          },
        ]}
        onClick={() => setOpenModal(true)}
      />
    );
  };

  return (
    <>
      <Wrapper
        state={status === 'disconnected' && data?.error ? 'error' : 'default'}>
        <IntegrationBody
          {...Body}
          customButton={CustomButton}
          dropDown={status === 'connected' ? <HubSpotDropDown /> : undefined}
        />
        {(status === 'connected' ||
          (status === 'disconnected' && data?.error)) && (
          <SyncRecord>
            <div>
              <Body2 weight="bold">Data synchronization</Body2>{' '}
              <span
                style={{
                  width: '2px',
                  height: '16px',
                  borderLeft: '1px solid #CECEDE',
                  margin: '0px 9px',
                }}></span>
              <Body2 kind="textBody">
                {lastSync === null
                  ? 'No Sync records'
                  : `Last sync - ${dayjs(lastSync).format(
                      `D/MM/YYYY`,
                    )} at ${dayjs(lastSync).format(`hh:mma`)}  `}
              </Body2>
            </div>
          </SyncRecord>
        )}
      </Wrapper>
      {status === 'disconnected' && data?.error && (
        <div style={{marginTop: '7px'}}>
          <Helper
            state="error"
            children={'Authentication error. Please re-connect the app.'}
          />
        </div>
      )}
      {openModal && (
        <DisconnectModal
          cancelAction={() => setOpenModal(false)}
          loading={disconnectLoading}
          onClick={() =>
            handleDisconnectHubspotConnection('Hubspot', setActiveIntegrations)
          }
        />
      )}
    </>
  );
});

const DisconnectModal = ({
  cancelAction,
  loading,
  onClick,
}: {
  cancelAction: () => void;
  loading: boolean;
  onClick: () => void;
}) => {
  const screenSize = useBreakpoints();
  return (
    <Modal open={true} onClose={() => {}}>
      <div
        style={{
          maxWidth: screenSize.isXs ? '90%' : '550px',
          margin: 'auto',
        }}>
        <div style={{width: screenSize.isXs ? '100%' : 'max-content'}}>
          {screenSize.isXs ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
              }}>
              <Button
                kind="secondary"
                onClick={cancelAction}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  overflow: 'hidden',
                  justifyContent: 'center',
                  height: '38px',
                }}>
                <CancelIcon />
                <span style={{marginLeft: '5px'}}>Cancel</span>
              </Button>
            </div>
          ) : (
            <BackAction
              width="md"
              icon={<CancelIcon />}
              onClick={cancelAction}
              title="Close"
            />
          )}
        </div>
        <VerticalSpacer size="16px" />
        <ModalCard align="center" title={''}>
          <>
            <Headline2 align="center">
              Disconnect Hubspot integration?{' '}
            </Headline2>

            <VerticalSpacer size="20px" />

            <Body1 align="center" kind="textBody">
              You’re about to disconnect this integration from your workspace.
              This will affect all {cfv(undefined, true).g_oals} connected to
              it.
            </Body1>
            <VerticalSpacer size="20px" />
            <span>
              <Button
                kind="primary"
                width="full"
                isLoading={loading}
                type="button"
                onClick={onClick}
                data-form-action={true}>
                Confirm
              </Button>
            </span>
            <VerticalSpacer size="16px" />
          </>
        </ModalCard>
      </div>
    </Modal>
  );
};
