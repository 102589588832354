import {useState} from 'react';
import {CategoryWrapper, SavedWrapper, Card} from './styles';
import {FlexRow, FlexRowEnd, FlexRowSpaceBetween} from '@ui/style/styles';
import {capitalize} from '@utils';
import {Body2} from '@ui/atoms/typography';
import {NoBorderButton} from '@pages/dashboard/reporting/reporting-pages/reporting-overview/styles';
import {DropdownVertical} from '@ui/molecules/dropdown-vertical';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {CustomColorIcon} from '@ui/atoms/color-icons';
import {SelectField} from '@ui/molecules/select/selectfield';
import {VerticalSpacer} from '@ui/atoms/spacer';
import nextId from 'react-id-generator';
import {TextField} from '@ui/molecules/field/textfield';
import {Button} from '@ui/atoms/button';

import {CategValue} from './create-review-frameworks';
import {ToggleSwitch} from '@ui/atoms/toggle-switch';
import {RemoveButton} from '@ui/molecules/key-result-field/key-result-field';
import {SecondaryMinusSVG} from '@ui/atoms/svg/minus';

interface ICateg {
  category: CategValue;
  saveCompetence: (value: CategValue) => void;
  isEditingFramework: boolean;
  canDeleteCategory: boolean;
  categories: Array<{labe: string; value: string}>;
  deleteCompetence: (id: string) => void;
}

export const CompetenceCategories = ({
  category,
  categories,
  canDeleteCategory,
  isEditingFramework,
  saveCompetence,
  deleteCompetence,
}: ICateg) => {
  const [saved, setSaved] = useState(
    isEditingFramework && !!category.competence,
  );

  const [values, setValues] = useState(category);

  const [keyAreasErrors, setErrors] = useState<
    Array<{id: string; error: string}>
  >([]);

  const [expand, setExpand] = useState(false);

  const addKeyArea = () => {
    setValues((prev) => ({
      ...prev,
      keyAreas: [
        ...prev.keyAreas,
        {
          value: '',
          weighing: '',
          parameter: 'default',
          id: nextId('competence-key-areas'),
        },
      ],
    }));
  };

  const onChange = (field: string, value: string) => {
    setValues((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const onChangeKeyAreas = (keyAreaId: string, field: string, value: any) => {
    setValues((prev) => ({
      ...prev,
      keyAreas: prev.keyAreas.map((keyArea) =>
        keyArea.id === keyAreaId ? {...keyArea, [field]: value} : keyArea,
      ),
    }));

    const weigh = field === 'weighing';
    if (weigh) {
      if (parseInt(value) > 100) {
        setErrors((prev) => [
          ...prev,
          {id: keyAreaId, error: 'Value cannot be greater than 100'},
        ]);
      }

      if (parseInt(value) < 0) {
        setErrors((prev) => [
          ...prev,
          {id: keyAreaId, error: 'Value cannot be a negative number'},
        ]);
      }
    }

    const keyAreaError = keyAreasErrors.find((error) => error.id === keyAreaId);

    if (
      keyAreaError &&
      weigh &&
      parseInt(value) <= 100 &&
      parseInt(value) > 0
    ) {
      setErrors((prev) => prev.filter((error) => error.id !== keyAreaId));
    }
  };

  const disableSaveButton =
    !values.competence ||
    values.keyAreas.some((keyArea) => !keyArea.value || !keyArea.weighing) ||
    keyAreasErrors?.length > 0 ||
    values.keyAreas.length === 0;

  const saveCategory = () => {
    const isKeyAreaValid = values.keyAreas.every(
      (keyArea) => parseInt(keyArea.weighing) <= 100,
    );

    if (isKeyAreaValid) {
      setSaved(true);

      saveCompetence(values);
      return;
    }
  };

  const deleteKeyArea = (keyAreaId: string) => {
    setValues((prev) => ({
      ...prev,
      keyAreas: prev.keyAreas.filter((keyArea) => keyArea.id !== keyAreaId),
    }));
  };

  const getError = (id: string) => {
    return keyAreasErrors.find((error) => error.id === id);
  };

  if (saved) {
    return (
      <SavedWrapper>
        <div className="main-wrapper">
          <FlexRowSpaceBetween>
            <Body2 weight="semibold">{capitalize(category.competence)}</Body2>
            <FlexRow>
              <NoBorderButton onClick={() => setExpand(!expand)}>
                <Body2 weight="bold" kind="purple300">
                  {expand ? 'Collapse' : 'Expand'}
                </Body2>
              </NoBorderButton>
              <div style={{marginLeft: '6px', marginTop: '3px'}}>
                <DropdownVertical
                  dropdownWrapperStyle={{}}
                  menu={
                    <div>
                      <DropdownItem
                        onClick={() => {
                          setSaved(false);
                        }}>
                        Edit
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          deleteCompetence(category.id);
                        }}>
                        Delete
                      </DropdownItem>
                    </div>
                  }
                />
              </div>
            </FlexRow>
          </FlexRowSpaceBetween>
        </div>
        {expand && (
          <div className="expand-wrapper">
            {category.keyAreas.map((keyArea) => (
              <div className="key-areas">
                <FlexRow>
                  <CustomColorIcon
                    color="black"
                    width="4px"
                    height="4px"
                    margin={4}
                  />
                  <Body2 weight="semibold"> {capitalize(keyArea.value)}</Body2>
                </FlexRow>
                <div className="weigh">
                  <Body2 weight="bold">{keyArea.weighing}</Body2>
                </div>
              </div>
            ))}
          </div>
        )}
      </SavedWrapper>
    );
  }

  return (
    <CategoryWrapper>
      <SelectField
        borderRadius="10px"
        placeholder="Select category from here"
        onChange={(data: {value: string; label: string}) =>
          onChange('competence', data?.label)
        }
        defaultValue={values.competence}
        options={categories || []}
      />
      <FlexRowSpaceBetween>
        <Body2 weight="bold">Key areas</Body2>
        <NoBorderButton onClick={addKeyArea} style={{marginRight: '12px'}}>
          <Body2 kind="purple300" weight="bold">
            + Add key area
          </Body2>
        </NoBorderButton>
      </FlexRowSpaceBetween>
      <VerticalSpacer size="14px" />

      {values.keyAreas.map((keyArea, index, keyAreas) => (
        <Card style={{display: 'block', marginBottom: '16px'}}>
          <FlexRowSpaceBetween
            style={{marginBottom: '10px', alignItems: 'flex-start'}}
            key={keyArea.id}>
            <TextField
              placeholder="Enter here"
              value={keyArea.value}
              state={getError(keyArea.id) ? 'error' : 'default'}
              helper={getError(keyArea.id)?.error}
              onChange={(event) =>
                onChangeKeyAreas(keyArea.id, 'value', event.target.value)
              }
              style={{width: '67%'}}
              inputStyle={{borderRadius: '10px'}}
              margin
            />
            <TextField
              placeholder="Weighing"
              value={keyArea.weighing}
              type="number"
              state={getError(keyArea.id) ? 'error' : 'default'}
              onChange={(event) =>
                onChangeKeyAreas(keyArea.id, 'weighing', event.target.value)
              }
              style={{width: '24%', margin: '0 10px'}}
              inputStyle={{borderRadius: '10px'}}
              margin
            />
          </FlexRowSpaceBetween>
          <VerticalSpacer size="16px" />
          <Card>
            <Body2 weight="bold" style={{fontSize: '12px'}}>
              Rate this key area based on goals, not questions.
            </Body2>
            <ToggleSwitch
              checked={keyArea.parameter === 'goals'}
              switchId={index}
              onChange={() =>
                onChangeKeyAreas(
                  keyArea.id,
                  'parameter',
                  keyArea.parameter === 'goals' ? 'default' : 'goals',
                )
              }
            />
          </Card>
          <VerticalSpacer size="16px" />

          <FlexRowEnd>
            <RemoveButton
              type="button"
              aria-label={`remove keyarea`}
              onClick={() =>
                index === 0 && keyAreas.length === 1 && canDeleteCategory
                  ? deleteCompetence(values.id)
                  : deleteKeyArea(keyArea.id)
              }>
              <SecondaryMinusSVG />
              <Body2 weight="bold" style={{marginLeft: '5px'}} kind={'red400'}>
                Remove
              </Body2>
            </RemoveButton>
          </FlexRowEnd>
        </Card>
      ))}
      <Button
        style={{padding: '6px 16px'}}
        width="full"
        onClick={saveCategory}
        disabled={disableSaveButton}>
        Save
      </Button>
    </CategoryWrapper>
  );
};
