import {MultiOptionsSelectField} from '@ui/molecules/select/multi-options';
import {useStoreContext} from '@store/store-context';
import {observer} from 'mobx-react-lite';
import {useMemo} from 'react';

const SelectCompanyValues = ({
  updateValue,
}: {
  updateValue: (value: any) => void;
}) => {
  const {
    authStore: {auth},
  } = useStoreContext();

  const values = auth.user.workspace.settings.companyValues.values;

  const formatValues = useMemo(() => {
    return values.map((value: string) => {
      return {
        label: value,
        value: value.toLowerCase(),
      };
    });
  }, [values]);

  return (
    <MultiOptionsSelectField
      placeholder="Select values"
      select="people"
      performance={true}
      showCancelIcon={false}
      noOptionMessage={'No Member Found'}
      type="dropdownOnly"
      options={formatValues}
      onChange={(data: {value: string; label: string}[]) => {
        updateValue(data?.map((d) => d.value));
      }}
    />
  );
};

export default observer(SelectCompanyValues);
