import {memo} from 'react';

export const LockIcon = memo(() => (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.9375 5.25H9.5V3.5C9.5 1.56975 7.93025 0 6 0C4.06975 0 2.5 1.56975 2.5 3.5V5.25H2.0625C1.33917 5.25 0.75 5.83858 0.75 6.5625V12.6875C0.75 13.4114 1.33917 14 2.0625 14H9.9375C10.6608 14 11.25 13.4114 11.25 12.6875V6.5625C11.25 5.83858 10.6608 5.25 9.9375 5.25ZM3.66667 3.5C3.66667 2.21317 4.71317 1.16667 6 1.16667C7.28683 1.16667 8.33333 2.21317 8.33333 3.5V5.25H3.66667V3.5Z"
      fill="#1E1E2F"
    />
  </svg>
));

LockIcon.displayName = 'LockIcon';
