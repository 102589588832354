import styled from 'styled-components';

export const IconWrapper = styled.span<{showIconOnly?: boolean}>`
  svg {
    width: 16px;
    margin-top: 7px;
    margin-left: ${({showIconOnly}) => (showIconOnly ? undefined : '3px')};
    height: 16px;
  }
`;
export const TooltipWrapper = styled.div`
  .tooltip {
    position: relative;
    display: inline-block;
  }
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 238px;
    background-color: white;
    border: 1px solid #ededf2;
    color: #fff;
    margin-top: 5px;
    box-shadow: 0px 9px 38px rgba(0, 0, 0, 0.05),
      0px 6.53341px 11.4559px rgba(0, 0, 0, 0.0325794);
    border-radius: 10px;
    padding: 16px;
    right: 10%;
    /* Position the tooltip */
    position: absolute;
    z-index: 5;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
`;
