import {forwardRef, ReactElement, Ref, useCallback, useState} from 'react';
import ReactSelect, {ActionMeta} from 'react-select';
import {useDropDown} from '../../../../hooks';
import {motion} from 'framer-motion';
import {Label} from '../../../atoms/label';
import {FieldWrapper} from '../../../atoms/field-wrapper';
import {Helper} from '../../../atoms/helper';
import {
  Wrapper,
  DropdownWrapper,
  DropWrapper,
  DropdownHandler,
  HandlerWrapper,
  ArrowIconWrapper,
  OptionsWrapper,
} from './styled';
import {ArrowHeadDownIcon} from '../../../atoms/icons/arrow-head-down';

import {SelectFieldProps} from '../user-multi-select/user-multi-select';
import {InputState} from '../../../interface';
import {OptionsMultiSelect} from '../options-multi-select/options-multi-select';
import {Groups} from '../../groups';

export interface TOptionsSelectFieldProps extends SelectFieldProps {
  label?: string;
  placeholder?: string;
  state?: InputState;
  helper?: string | ReactElement;
}

export const MultiOptionsSelectField2 = forwardRef(
  (
    {
      label,
      state,
      helper,
      placeholder,
      options,
      onChange,
      value,
      defaultValue,
      disabled,
      ...rest
    }: SelectFieldProps,
    ref: Ref<ReactSelect<any>>,
  ) => {
    const {handleOpen, ref: dropRef, open} = useDropDown();

    const getValue = options.filter((option: any) => {
      if (defaultValue) {
        return defaultValue.map((val: any) => val.value).includes(option.value);
      }
      return null;
    });

    const [selectedOptions, setSelectedOptions] = useState<any>(() =>
      defaultValue ? getValue : [],
    );

    const handleChange = useCallback(
      (options: any, action: ActionMeta<any>) => {
        setSelectedOptions(options);
        if (onChange) {
          onChange(options, action);
        }
      },
      [onChange],
    );
    const handleCancelFx = useCallback(
      (value: string) => {
        setSelectedOptions(
          selectedOptions.filter((option: any) => option.value !== value),
        );
      },
      [selectedOptions],
    );

    return (
      <FieldWrapper helper={helper}>
        <Wrapper>
          <Label aria-label="label">{label}</Label>
          <DropWrapper ref={dropRef}>
            <DropdownHandler
              open={open}
              onClick={handleOpen}
              helper={helper}
              disabled={disabled}>
              <HandlerWrapper>
                <OptionsWrapper disabled={disabled}>
                  {selectedOptions?.length > 0 ? (
                    <Groups
                      total={selectedOptions.length}
                      items={selectedOptions.map(
                        (item: {label: string; value: string}) => ({
                          name: item.label,
                          value: item.value,
                        }),
                      )}
                      showCancelIcon={true}
                      disabled={disabled}
                      cancelFx={!disabled ? handleCancelFx : undefined}
                    />
                  ) : (
                    <p className="greyOut">{placeholder}</p>
                  )}
                </OptionsWrapper>
                <ArrowIconWrapper disabled={disabled}>
                  {!disabled ? (
                    <motion.span
                      initial={{rotate: 0}}
                      animate={{
                        rotate: open ? 180 : 0,
                        marginTop: open ? '-6px' : '0px',
                      }}
                      transition={{
                        duration: 0.3,
                      }}
                      key="user-select-toggled">
                      <ArrowHeadDownIcon />
                    </motion.span>
                  ) : (
                    <motion.span
                      initial={{rotate: 0}}
                      animate={{
                        rotate: 0,
                        marginTop: '0px',
                      }}
                      transition={{
                        duration: 0.3,
                      }}
                      key="user-select-toggled">
                      <ArrowHeadDownIcon />
                    </motion.span>
                  )}
                </ArrowIconWrapper>
              </HandlerWrapper>
            </DropdownHandler>
            {open && (
              <DropdownWrapper>
                <OptionsMultiSelect
                  {...rest}
                  onChange={handleChange}
                  value={selectedOptions}
                  defaultValue={defaultValue}
                  ref={ref}
                  placeholder={placeholder}
                  options={options}
                  disabled={disabled}
                />
              </DropdownWrapper>
            )}
          </DropWrapper>
          <Helper aria-label="helper" state={state} children={helper} />
        </Wrapper>
      </FieldWrapper>
    );
  },
);
