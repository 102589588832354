import {FC, useMemo, ReactNode} from 'react';
import styled from 'styled-components';
import {capitalize} from '@utils';
import {observer} from 'mobx-react-lite';
import {SecondaryEyeOffIcon} from '@ui/atoms/icons/eye-off';
import {UserListCard} from '@ui/molecules/user/list-card';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {CustomColorIcon} from '@ui/atoms/color-icons';
import dayjs from 'dayjs';
import {GetImpression} from './components/impression';
import {CustomMarkdown} from '@ui/atoms/typography/markdown';
import {TooltipWrapper} from '@ui/molecules/goal/editable-metric/styles';
import {FeedbackValues} from './components/view-feedback-checkins';
import {FlexRowSpaceBetween, FlexRow} from '@ui/style/styles';
import {Body2, Body1} from '@ui/atoms/typography';
import {DesktopWrapper} from '@ui/molecules/workspace/logo-toggle/logo-toggle';
import {Feedback} from './feedback-items';
import {useStoreContext} from '@store/store-context';
import {Avatars} from '@ui/molecules/avatars';

const Wrapper = styled.div`
  border: 1px solid #ededf2;
  border-radius: 10px;
  margin-bottom: 16px;
  .main {
    padding: 16px;
    .flex {
      display: flex;
      margin-bottom: 16px;
      align-items: center;
      .title {
        background: #f6f6f8;
        border-radius: 10px;
        display: flex;
        width: 100%;
        align-items: center;
        padding: 8px 16px;
      }
    }
  }
  .footer {
    padding: 12px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #ededf2;
  }
`;
export const ResponsiveFlexRow = styled(FlexRow)`
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    .date-body {
      margin-top: 8px;
    }
  }
`;
const Visibility = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  cursor: pointer;
  border-radius: 8px;
  width: fit-content;
  margin-left: 5px;
  background: #f6f6f8;

  p {
    color: #5f5f8c;
    font-weight: 600;
    font-size: 14px;
  }
  svg {
    margin-right: 3px;
  }
`;

interface IFeedback extends Feedback {
  showMore?: boolean;
  showLess?: boolean;
  feedbackCount?: number;
  handleShowMore?: () => void;
  excludeSubject?: boolean;
  myFeedback?: boolean;
  dropdown?: (data: IFeedback) => ReactNode;
  showReviewer?: boolean;
}
export const FeedbackItem: FC<IFeedback> = observer(
  ({
    feedback,
    createdAt,
    subjectName,
    subjectType,
    excludeSubject,
    user,
    impression,
    values,
    showLess,
    dropdown,
    myFeedback,
    showMore,
    visibility,
    reviewer: reviewerId,
    showReviewer,
    group: FeedbackUsers,
    feedbackCount,
    handleShowMore,
    ...rest
  }) => {
    const {
      usersStore: {users},
      authStore: {auth},
    } = useStoreContext();

    const userToDisplay = useMemo(() => (myFeedback ? reviewerId : user), [
      myFeedback,
      reviewerId,
      user,
    ]);

    const displayedAvatar = useMemo(
      () => users.find((reviewee) => reviewee.id === userToDisplay),
      [users, userToDisplay],
    );

    const displayedAvatars = useMemo(
      () =>
        users
          .filter((reviewee) => FeedbackUsers.includes(reviewee.id))
          .map((user) => {
            return {
              src: user.avatar?.url,
              name: user?.firstName
                ? user?.firstName + ' ' + user?.lastName
                : user?.email,
              reviewer: user?.reviewer,
              id: user.id,
            };
          }),
      [users, FeedbackUsers],
    );

    const reviewer = useMemo(
      () => users.find((user) => user.id === reviewerId),
      [users, reviewerId],
    );

    const showFooter = showMore || showReviewer || showLess;

    const displayVisibility = useMemo(() => {
      if (visibility === 'manager') {
        return (
          <TooltipWrapper>
            <div className="tooltip">
              <Visibility>
                <SecondaryEyeOffIcon />{' '}
                <DesktopWrapper>
                  <Body2>Private + Manager</Body2>
                </DesktopWrapper>
              </Visibility>
              <div className="tooltiptext">
                <Body2 style={{fontSize: '12px'}} kind="textBody">
                  Visible to you, the feedback sender and your manager.
                </Body2>
              </div>
            </div>
          </TooltipWrapper>
        );
      }
      if (visibility === 'private') {
        return (
          <TooltipWrapper>
            <div className="tooltip">
              <Visibility>
                <SecondaryEyeOffIcon />
                <DesktopWrapper>
                  <Body2>Private</Body2>
                </DesktopWrapper>
              </Visibility>
              <div className="tooltiptext">
                <Body2 style={{fontSize: '12px'}} kind="textBody">
                  Visible to only you and the feedback sender.
                </Body2>
              </div>
            </div>
          </TooltipWrapper>
        );
      }
      return null;
    }, [visibility]);

    return (
      <Wrapper>
        <div className="main">
          <div className="flex">
            {!excludeSubject && (
              <div className="title">
                <Body2 weight="bold">
                  {capitalize(subjectType)}: {subjectName}
                </Body2>
              </div>
            )}

            {dropdown &&
              dropdown({
                ...rest,
                feedback,
                impression,
                visibility,
                createdAt,
                group: FeedbackUsers,
                reviewer: reviewerId,
                subjectName,
                subjectType,
                user,
                values,
              })}
          </div>
          <FlexRowSpaceBetween>
            <ResponsiveFlexRow>
              <FlexRow>
                {myFeedback && (
                  <Body1
                    kind="textBody"
                    weight="bold"
                    style={{marginRight: '6px'}}>
                    From:
                  </Body1>
                )}
                {FeedbackUsers.length > 0 ? (
                  <Avatars
                    items={displayedAvatars}
                    total={displayedAvatars.length}
                  />
                ) : (
                  <UserListCard
                    tooltip={true}
                    userId={displayedAvatar?.id || ''}
                    type="primary"
                    textStyle={{fontWeight: 500}}
                    textKind="textDark"
                    avatar={
                      displayedAvatar?.avatar ? displayedAvatar.avatar.url : ''
                    }
                    name={
                      displayedAvatar?.firstName && displayedAvatar?.lastName
                        ? `${displayedAvatar.firstName} ${displayedAvatar.lastName}`
                        : displayedAvatar?.email || ''
                    }
                  />
                )}
              </FlexRow>

              <DesktopWrapper style={{margin: '0 8px'}}>
                <CustomColorIcon
                  color="#D9D9D9"
                  width="8px"
                  height={'8px'}
                  margin={5}
                />
              </DesktopWrapper>
              <Body2 kind="textBody" className="date-body">
                {dayjs(createdAt).format('DD MMM. YYYY')}
              </Body2>
            </ResponsiveFlexRow>
            <FlexRow>
              <GetImpression impressionValue={impression} />
              {displayVisibility}
            </FlexRow>
          </FlexRowSpaceBetween>
          <VerticalSpacer size="16px" />
          <div className="">
            <CustomMarkdown text={feedback} />
          </div>
          <VerticalSpacer size="16px" />
          {values?.length > 0 && <FeedbackValues values={values} />}
        </div>
        {showFooter && (
          <div className="footer">
            <div>
              {(showMore || showLess) && (
                <Body2
                  kind="purple300"
                  onClick={handleShowMore}
                  style={{cursor: 'pointer'}}>
                  {' '}
                  {showLess ? 'See less' : `+ ${feedbackCount} more`}
                </Body2>
              )}
            </div>
            <div>
              {showReviewer && (
                <FlexRow>
                  <Body2
                    weight="semibold"
                    kind="textBody"
                    style={{marginRight: '7px'}}>
                    From:
                  </Body2>
                  {reviewerId === auth.user.id ? (
                    <Body2 weight="semibold">You</Body2>
                  ) : (
                    <UserListCard
                      tooltip={true}
                      userId={reviewer?.id || ''}
                      type="aligned"
                      textKind="textDark"
                      textStyle={{fontSize: '14px', fontWeight: 500}}
                      avatar={reviewer?.avatar ? reviewer.avatar.url : ''}
                      name={
                        reviewer?.firstName && reviewer?.lastName
                          ? `${reviewer.firstName} ${reviewer.lastName}`
                          : reviewer?.email || ''
                      }
                    />
                  )}
                </FlexRow>
              )}
            </div>
          </div>
        )}
      </Wrapper>
    );
  },
);
