import {memo} from 'react';

export const ArrowHeadUpIcon = memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16">
    <path strokeLinecap="round" strokeLinejoin="round" d="M4 10l4-4 4 4" />
  </svg>
));

ArrowHeadUpIcon.displayName = 'ArrowHeadUpIcon';
