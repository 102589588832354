import {useState, useMemo, useCallback} from 'react';
import {AnalyticsTodayDate} from '@utils/date';
import {IMembersFormatFilter} from '@hooks';
import {MeetingRequestImpl} from '@pages/1:1s/meeting.request';
import {MeetingController} from '@pages/1:1s/meeting-controller';
import {usePeriodHook} from '@hooks/period-hook';

export const useMeetingReportingHook = (computedFilters: object) => {
  const [userFilter, setUserFilter] = useState({
    filterBy: {
      label: 'People',
      value: 'people',
    },
    selectedTeam: {
      value: '',
      label: '',
    },
    members: [] as Array<IMembersFormatFilter>,
  });

  const {isPeriodEnabled, currentMonthRange} = usePeriodHook();

  const getPeriod = useMemo(() => {
    if (isPeriodEnabled && currentMonthRange().starts) {
      return {
        starts: currentMonthRange().starts,
        ends: currentMonthRange().ends,
        period: currentMonthRange().period,
      };
    }

    return {
      starts: AnalyticsTodayDate().AnalyticsDefaultStartDate,
      ends: AnalyticsTodayDate().AnalyticsDefaultEndDate,
    };
  }, [isPeriodEnabled, currentMonthRange]);

  const [dateRange, setDateRange] = useState(getPeriod);
  const request = useMemo(() => new MeetingRequestImpl(), []);
  const controller = useMemo(() => new MeetingController(request), [request]);

  const filters = useMemo(() => {
    return {
      ...computedFilters,
      startDate: dateRange.starts,
      endDate: dateRange.ends,
    };
  }, [dateRange.starts, computedFilters, dateRange.ends]);

  const getMeetingReporting = useCallback(
    async (filters: {
      startDate: string;
      endDate: string;
    }): Promise<{
      hostedMeetings: number;
      participatedMeetings: number;
      completedMeetings: number;
      missedMeetings: number;
      totalMeetings: number;
      users: [
        {
          hostedMeetings: number;
          participatedMeetings: number;
          completedMeetings: number;
          missedMeetings: number;

          [x: string]: any;
        },
      ];
    }> => {
      const response = await controller.fetchOneOnOneReporting(filters);

      return response;
    },
    [controller],
  );

  return {
    dateRange,
    filters,
    setDateRange,
    userFilter,
    getMeetingReporting,
    setUserFilter,
  };
};
