import React, {useRef, useEffect} from 'react';
import * as d3 from 'd3';
import {capitalize} from '@utils';

interface PieChartData {
  value: number;
  name: string;
  color?: string;
  [key: string]: any;
}

interface RoundedPieChartProps {
  data: PieChartData[];
  chartStyle?: {
    width: number;
    height: number;
    margin?: {top?: number; right?: number; bottom?: number; left?: number};
  };
  pieStyle?: React.CSSProperties;
  pieTitle?: string;
  totalValue?: number | string;
}

export const RoundedPieChart: React.FC<RoundedPieChartProps> = ({
  data,
  totalValue,
  pieStyle,
  chartStyle,
  pieTitle,
}) => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const tooltipRef = useRef<
    d3.Selection<HTMLDivElement, unknown, any, undefined>
  >();

  useEffect(() => {
    if (!svgRef.current) return;

    const width = chartStyle?.width || 150;
    const height = chartStyle?.height || 150;

    const svg = d3.select(svgRef.current);
    svg.selectAll('*').remove();

    const g = svg
      .append('g')
      .attr('transform', `translate(${width / 2},${height / 2})`);

    const radius = Math.min(width, height) / 2;
    const innerRadius = radius * 0.55;
    const outerRadius = radius * 0.9;

    const pie = d3
      .pie<PieChartData>()
      .value((d) => d.value)
      .sort(null);

    const arc = d3
      .arc<d3.PieArcDatum<PieChartData>>()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius);

    // Create a tooltip if it doesn't exist
    if (!tooltipRef?.current) {
      tooltipRef.current = d3
        .select('body')
        .append('div')
        .attr('class', 'tooltip')
        .style('position', 'absolute')
        .style('background-color', 'white')
        .style('border', '1px solid #ededf2')
        .style('padding', '16px 12px')
        .style('border-radius', '5px')
        .style('pointer-events', 'none');
    }

    const tooltip = tooltipRef!.current;

    const arcs = g
      .selectAll('arc')
      .data(pie(data))
      .enter()
      .append('g')
      .attr('class', 'arc');

    arcs
      .append('path')
      .attr('d', arc)
      .attr('fill', (d) => d.data.color || 'blue')
      .attr('stroke', 'none')
      .on('mouseover', (event, d) => {
        if (d.data.name.toLowerCase() !== 'empty') {
          tooltip?.transition().duration(200).style('opacity', 1);
          tooltip
            ?.html(`${capitalize(d.data.name)} : ${d.data.value}`)
            .style('left', `${event.pageX}px`)
            .style('font-family', 'Graphik')
            .attr('font-size', '10px')
            .style('top', `${event.pageY - 28}px`);
        }
      })
      .on('mousemove', (event, d) => {
        if (d.data.name.toLowerCase() !== 'empty') {
          tooltip
            ?.style('left', `${event.pageX}px`)
            .style('top', `${event.pageY - 28}px`);
        }
      })
      .on('mouseout', () => {
        tooltip?.transition().duration(500).style('opacity', 0);
      });

    // Render active shape (first slice)
    const activeArc = arcs.filter((_, i) => i === 0);
    activeArc.select('path').attr('d', arc);

    // Render total value text
    g.append('text')
      .attr('x', 0)
      .attr('y', pieTitle ? 0 : 13)
      .attr('text-anchor', 'middle')
      .attr('fill', '#5E5E73')
      .attr('font-size', pieTitle ? '14px' : '35px')
      .attr('font-weight', 600)
      .style('font-family', 'Graphik')
      .text(String(totalValue));

    // Render pie title
    if (pieTitle) {
      g.append('text')
        .attr('x', 0)
        .attr('y', 14)
        .attr('text-anchor', 'middle')
        .attr('fill', '#5E5E73')
        .attr('font-size', '10px')
        .style('font-family', 'Graphik')
        .text(pieTitle);
    }

    // Cleanup function
    return () => {
      if (tooltipRef.current) {
        tooltipRef.current.remove();
        tooltipRef.current = undefined;
      }
    };
  }, [data, totalValue, pieTitle, chartStyle]);

  return (
    <svg
      ref={svgRef}
      width={chartStyle?.width || 150}
      height={chartStyle?.height || 150}
      style={pieStyle}
    />
  );
};
