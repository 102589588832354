import styled, {
  css,
  DefaultTheme,
  StyledComponentProps,
} from 'styled-components';
import {CSSProperties, memo} from 'react';
import {ifNotProp, ifProp, switchProp} from 'styled-tools';
import {Body1} from '../typography/body1';
import {CalendarIcon} from '../icons/calender';
import {getColorFromTheme} from '../../ui-utils';
import {InputState} from '../../interface';

export const IconWrapper = styled.span<{disabled?: boolean}>`
  flex-shrink: 0;
  height: 100%;
  padding: 12px 16px 12px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  ${ifProp(
    'disabled',
    css`
      background-color: ${getColorFromTheme('backgroundLight')};
      cursor: not-allowed;
    `,
  )}

  svg {
    width: 20px;
    height: 20px;
    stroke: #bfbfd4;
  }
`;

export const Label = styled.span<{disabled?: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 0px 1px ${getColorFromTheme('borderDark')};
  height: 100%;
  width: 69px;
  border-radius: 10px 0 0 10px;
  padding: 12px 16px;
  transition: 200ms ease all;
  flex-shrink: 0;

  ${ifProp(
    'disabled',
    css`
      cursor: not-allowed;
      background-color: ${getColorFromTheme('backgroundLight')};
    `,
  )}

  ${Body1} {
    color: ${getColorFromTheme('textDark')};
    transition: 200ms ease all;
  }
`;

export const Input = styled.span<{
  disabled?: boolean;
  dateAlign?: 'left' | 'right';
}>`
  padding: 5px 16px;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: ${getColorFromTheme('textDark')};
  display: flex;
  align-items: center;
  justify-content: ${({dateAlign}) => (dateAlign ? 'flex-start' : 'center')};
  height: 100%;
  background-color: ${getColorFromTheme('white')};
  transition: 200ms ease all;
  border: none;
  outline: none;
  border-left: solid 1px ${getColorFromTheme('borderDark')};
  width: 100%;

  ${ifProp(
    'disabled',
    css`
      cursor: not-allowed;
      border-color: ${getColorFromTheme('borderLight')};
      background-color: ${getColorFromTheme('backgroundLight')};
      color: ${getColorFromTheme('textMedium')};
    `,
  )}
`;

const Placeholder = styled.span<{disabled?: boolean}>`
  padding: 5px 16px;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: ${getColorFromTheme('textBody')};
  display: flex;
  align-items: center;
  height: 100%;
  background-color: ${getColorFromTheme('white')};
  transition: 200ms ease all;
  border: none;
  outline: none;
  border-left: solid 1px ${getColorFromTheme('borderDark')};
  width: 100%;

  ${ifProp(
    'disabled',
    css`
      cursor: not-allowed;
      border-color: ${getColorFromTheme('borderLight')};
      background-color: ${getColorFromTheme('backgroundLight')};
      color: ${getColorFromTheme('textMedium')};
    `,
  )}
`;

export const Wrapper = styled.button<{
  disabled?: boolean;
  state?: InputState;
  active?: boolean;
}>`
  display: flex;
  flex-direction: row;
  position: relative;
  box-shadow: 0px 0px 0px 1px ${getColorFromTheme('borderDark')};
  border-radius: 10px;
  background: white;
  cursor: pointer;
  height: 56px;
  transition: 200ms ease all;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  &:focus,
  &:active {
    outline: 0;
    box-shadow: 0px 0px 0px 1px ${getColorFromTheme('purple300')},
      0 0 0 6px ${getColorFromTheme('purple200')};

    ${Label} {
      box-shadow: 0px 0px 0px 1px ${getColorFromTheme('purple300')};
    }

    ${Input} {
      border-left: solid 1px ${getColorFromTheme('purple300')};
    }

    ${Body1} {
      color: ${getColorFromTheme('textDark')};
    }

    ${ifProp(
      'disabled',
      css`
        box-shadow: 0px 0px 0px 1px ${getColorFromTheme('borderLight')},
          0 0 0 6px ${getColorFromTheme('borderLight')};

        ${Label} {
          box-shadow: 0px 0px 0px 1px ${getColorFromTheme('borderLight')};
        }

        ${Input} {
          border-left: solid 1px ${getColorFromTheme('borderLight')};
        }
      `,
    )}
  }

  ${ifNotProp(
    'active',
    css`
      &:hover:not(:focus):not(:disabled) {
        box-shadow: 0 0 0 2px ${getColorFromTheme('purple200')};

        ${Label} {
          box-shadow: 0px 0px 0px 1px ${getColorFromTheme('purple200')};
        }

        ${Input} {
          border-left: solid 1px ${getColorFromTheme('purple200')};
        }
        ${ifProp(
          'disabled',
          css`
            box-shadow: 0 0 0 2px ${getColorFromTheme('borderLight')};

            ${Label} {
              box-shadow: 0px 0px 0px 1px ${getColorFromTheme('borderLight')};
            }

            ${Input} {
              border-left: solid 1px ${getColorFromTheme('borderLight')};
            }
          `,
        )}
      }
    `,
  )}

  &:disabled {
    cursor: not-allowed;
  }

  ${ifProp(
    'disabled',
    css`
      cursor: not-allowed;
    `,
  )}

  ${ifProp(
    'active',
    css`
      outline: 0;
      box-shadow: 0px 0px 0px 1px ${getColorFromTheme('purple300')},
        0 0 0 6px ${getColorFromTheme('purple200')};

      ${Label} {
        box-shadow: 0px 0px 0px 1px ${getColorFromTheme('purple300')};
      }

      ${Input} {
        border-left: solid 1px ${getColorFromTheme('purple300')};
      }

      ${Body1} {
        color: ${getColorFromTheme('textDark')};
      }
    `,
  )};

  ${switchProp('state', {
    error: css`
      box-shadow: 0px 0px 0px 1px ${getColorFromTheme('red400')};

      ${Label} {
        box-shadow: 0px 0px 0px 1px ${getColorFromTheme('red400')};
      }

      ${Body1} {
        color: ${getColorFromTheme('red400')};
      }

      ${Input} {
        border-left: 1px solid ${getColorFromTheme('red400')};
        color: ${getColorFromTheme('red400')};
      }

      svg {
        stroke: ${getColorFromTheme('red400')};
      }

      &:hover,
      &:focus,
      &:active {
        box-shadow: 0px 0px 0px 1px ${getColorFromTheme('red400')};

        ${Body1} {
          color: ${getColorFromTheme('red400')};
        }

        ${Input} {
          border-left: 1px solid ${getColorFromTheme('red400')};
          color: ${getColorFromTheme('red400')};
        }

        ${Label} {
          box-shadow: 0px 0px 0px 1px ${getColorFromTheme('red400')};
        }
      }
    `,
  })}
`;

export type DateInputProps = StyledComponentProps<
  'button',
  DefaultTheme,
  {
    active: boolean;
    state?: InputState;
    label: string;
    disabled?: boolean;
    inputStyle?: CSSProperties;
    value?: string;
    dateAlign?: 'left' | 'right';
    placeholder?: string;
  },
  never
>;

export const DateInput = memo(function ({
  label,
  disabled,
  state,
  active,
  onClick,
  inputStyle,
  dateAlign,
  value,
  placeholder,
}: DateInputProps) {
  return (
    <Wrapper
      disabled={disabled}
      active={active}
      type="button"
      style={inputStyle}
      aria-label="calendar-input"
      onClick={onClick}
      state={state}>
      <Label disabled={disabled}>
        <Body1>{label}</Body1>
      </Label>
      {value && (
        <Input disabled={disabled} dateAlign={dateAlign}>
          {value === 'Invalid Date' ? 'Choose Date' : value}
        </Input>
      )}
      {placeholder && (
        <Placeholder disabled={disabled}>{placeholder}</Placeholder>
      )}
      <IconWrapper disabled={disabled}>
        <CalendarIcon />
      </IconWrapper>
    </Wrapper>
  );
});
DateInput.displayName = 'DateInput';
