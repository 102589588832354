import PeerReview from './peer-reviews';
import ManagerReview from './manager-review';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {NominatePeers} from '../../nominate-peers/nominate-peers';
import {useSearchParams} from 'react-router-dom';
import {useStoreContext} from '@store/store-context';

export const RecieveReview = ({
  disabled,
  dueDay,
  peerDueDay,
  reviewType,
  cycleId,
}: {
  disabled: boolean;
  dueDay: string;
  cycleId: string;
  peerDueDay: string;
  reviewType: string;
}) => {
  const [searchParams] = useSearchParams();
  const getNominationDrawerState = searchParams.get('openNomination');
  const {
    authStore: {auth},
  } = useStoreContext();
  return (
    <div>
      {!!auth.user?.reviewer && (
        <ManagerReview
          cycleId={cycleId}
          disabled={disabled}
          dueDay={dueDay}
          reviewType={reviewType}
        />
      )}
      <VerticalSpacer size="16px" />

      {reviewType === '360' && (
        <>
          <PeerReview
            cycleId={cycleId}
            disabled={disabled}
            dueDay={peerDueDay}
            reviewType={reviewType}
          />
          {getNominationDrawerState && <NominatePeers />}
        </>
      )}
    </div>
  );
};
