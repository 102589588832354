import {ElementType, ReactChild, ReactNode, SyntheticEvent} from 'react';
import {theme} from './style';

export type Theme = typeof theme;
export type Colors = keyof Theme['colors'];
export type FontSize = keyof Theme['fontSize'];
export type FontWeight = keyof Theme['fontWeight'];
export type LineHeight = keyof Theme['lineHeight'];
export type InputState = 'error' | 'default' | 'performance';
export type CheckKind = 'default' | 'indeterminate' | 'rounded' | 'import';
export type ButtonKind = 'primary' | 'secondary' | 'small' | 'small-black';
export type ButtonSize = 'xs' | 'sm' | 'md' | 'full';

export enum BinaryType {
  COMPLETED = 'COMPLETED',
  NOT_COMPLETED = 'NOT_COMPLETED',
}

export enum TodoType {
  CHECKIN = 'CHECKIN',
  ONBOARDING = 'ONBOARDING',
}

export enum PerformanceRate {
  BELOW,
  ABOVE,
  NORMAL,
}

export interface IBillingPlans {
  currency: string;
  growth: {
    monthly: {
      unit_cost: number;
    };
    yearly: {
      unit_cost: number;
      yearly_unit_cost: number;
      yearly_discount_percent: number;
    };
  };
  lifetime: {
    unit_cost: number;
  };

  starter: {
    range: {
      min: number;
      max: number;
    };
    monthly: {
      unit_cost: number;
    };
    yearly: {
      unit_cost: number;
      yearly_unit_cost: number;
      yearly_discount_percent: number;
    };
  };
  subscriptionTypes: ('monthly' | 'yearly' | 'free_tier')[];
}

export enum GoalAlignment {
  JUSTIFIED = 'Justified',
  EXPANDED = 'Expanded',
}
export enum FeedbackType {
  PUBLIC = 'public',
  PRIVATE = 'private',
  MANAGER = 'manager',
}

export interface ImageVariant {
  url?: string;
  src?: string;
  size?: string;
}
export type ImageType = {
  src: string;
  extension: string;
  variants?: ImageVariant[];
};
export type TextAlign = 'center' | 'left' | 'right';

export enum GoalKind {
  INDIVIDUAL = 'INDIVIDUAL',
  OVERARCHING = 'OVER-ARCHING',
  COMPANY = 'COMPANY',
  TEAM = 'TEAM',
  GROUP = 'GROUP',
}

export enum ProgressType {
  ONTRACK = 'on_track',
  NOSTATUS = 'no_status',
  PROGRESSING = 'behind',
  OFFTRACK = 'at_risk',
}

export enum MoodType {
  AWFUL = 'AWFUL',
  UNHAPPY = 'UNHAPPY',
  JUSTTHERE = 'JUST_THERE',
  HAPPY = 'HAPPY',
  FIREDUP = 'FIRED_UP',
}

export enum MeasurementType {
  PERCENTAGE = 'PERCENTAGE',
  BINARY = 'BINARY',
  NUMERIC = 'NUMERIC',
  CURRENCY = 'CURRENCY',
}

export enum CheckinState {
  SUBMITTED = 'SUBMITTED',
  PENDING = 'PENDING',
  REVIEWED = 'REVIEWED',
  REVIEWING = 'REVIEWING',
  PAST_DUE = 'PAST_DUE',
  CREATED = 'CREATED',
}
export type IGoalType = 'individual' | 'group' | 'company' | 'over-arching';

export enum GOAL_TYPE {
  INDIVIDUAL = 'individual',
  OVERARCHING = 'over-arching',
  COMPANY = 'company',
  GROUP = 'group',
}

export interface NavigationOption {
  href: string;
  title: string;
  children?: NavigationOption[];
  icon?: ReactNode;
  active: boolean;
  id: string;
  onClick: (e: SyntheticEvent<HTMLAnchorElement>) => void;
}
export interface TChildProps {
  children: ReactChild;
}

export interface UserAvatar {
  src: string;
  name: string;
  reviewer?: string;
  avatarIcon?: ReactNode;
  active?: boolean;
  isUserDeleted?: boolean;
  tooltip?: boolean;
  id: string;
  size?: 'sm' | 'md' | 'lg' | 'xl';
}
export enum MemberState {
  JOINED = 'JOINED',
  INVITED = 'INVITED',
}
export enum MemberRole {
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  USER = 'USER',
  OWNER = 'OWNER',
}

export enum CurrencyType {
  NAIRA = '₦',
  DOLLAR = '$',
  EURO = '€',
  POUND = '£',
  BITCOIN = '₿',
}

export type NotificationType = 'success' | 'warning' | 'error' | 'info';

export interface TNotificationProps {
  content: string | ElementType;
  title: string;
  kind?: NotificationType;
  hideNotification?: () => void;
  timeout?: number;
  action?: {
    title: string;
    onClick: () => void;
  };
}

export enum TransitionType {
  NONE = 'NONE',
  FADE_IN_RIGHT = 'FADE_IN_RIGHT',
  FADE_IN_LEFT = 'FADE_IN_LEFT',
  FADE_OUT_RIGHT = 'FADE_OUT_RIGHT',
  FADE_OUT_LEFT = 'FADE_OUT_LEFT',
}
