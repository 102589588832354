import {ReactNode} from 'react';
import {Headline3} from '@ui/atoms/typography';
import {DashboardTemplate} from '../dashboard';
import {CSSProperties} from 'react';
import {CancelIcon} from '@ui/atoms/icons';
import {BackAction} from '@ui/molecules/back-action';
import styled from 'styled-components';
import {FlexRow} from '@ui/style/styles';
import {UseGuide} from '@ui/molecules/use-guide';

const Wrapper = styled.div<{titleMargin?: string}>`
  .heading {
    border: 1px solid #ededf2;
    padding: 16px 6.7%;
    display: flex;
    width: 100%;
    align-items: center;
    background-color: white;
    justify-content: space-between;
    h3 {
      margin-left: ${({titleMargin}) => titleMargin || '6.7%'};

      @media (max-width: 768px) {
        margin-left: 2%;
      }
    }
    @media (max-width: 768px) {
      padding: 16px 6.7% 16px 2%;
    }
  }
`;
const EmptyDiv = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;
const BodyStyle = styled.div`
  background-color: white;

  margin: 5% 0% 5% 0%;
`;
const PageLayoutTemplate = ({
  title,
  onClick,
  bodyStyle,
  leftAction,
  titleMargin,
  guideUrl,
  children,
}: {
  title: string;
  onClick: () => void;
  bodyStyle?: CSSProperties;
  leftAction?: string | ReactNode | null;
  titleMargin?: string;
  guideUrl?: string;
  children: ReactNode;
}) => {
  return (
    <DashboardTemplate
      dashboardStyle={
        bodyStyle ? {...bodyStyle, marginTop: '0px'} : {marginTop: '0px'}
      }
      navigations={[]}
      header={
        <>
          <Wrapper titleMargin={titleMargin}>
            <div className="heading">
              {leftAction || <EmptyDiv />}
              <FlexRow className="gap-3 w-[50%] items-center justify-center">
                <Headline3 weight={'bold'} align="center">
                  {title}
                </Headline3>
                {guideUrl && <UseGuide url={guideUrl} />}
              </FlexRow>
              <div>
                <BackAction
                  icon={<CancelIcon />}
                  title="Close"
                  width="xs"
                  onClick={onClick}
                />
              </div>
            </div>
          </Wrapper>
        </>
      }>
      <BodyStyle style={bodyStyle}>{children}</BodyStyle>
    </DashboardTemplate>
  );
};
export default PageLayoutTemplate;
