import {Body2} from '@ui/atoms/typography';
import {SelectField} from '@ui/molecules/select/selectfield';
import {FlexRowSpaceBetween, FlexRow} from '@ui/style/styles';
import {capitalize} from '@utils';
import {
  FeedbackValuesFilter,
  SentimentFilter,
} from '../filter-components/feedback';
import {MeetingCountFilter} from '../filter-components/meetings';
import {BaseFilterProps} from './interface';
import {CheckinsGenderOptions} from '../filter-components/checkins';
import {
  GoalStateOptions,
  GoalType,
  goalProgressOptions,
} from '../filter-components/objectives';
import {CustomFilterMultiSelect} from './custom-multi-select';
import {useState} from 'react';
import {SecondaryMinusSVG} from '@ui/atoms/svg/minus';

interface ReportingAdditionalFiltersProps {
  filter: string;
  resetFilter: (value: string) => void;
  groups: {name: string; data: {label: string; value: string}[]}[];
  handleMeetingFilter: BaseFilterProps['handleMeetingFilter'];
  onChangeFeedbackFilter: BaseFilterProps['onChangeFeedbackFilter'];
  onChangeGoalProgress: BaseFilterProps['onChangeGoalProgress'];
  onChangeGoalType: BaseFilterProps['onChangeGoalType'];
  feedbackFilter: BaseFilterProps['feedbackFilter'];
  type: string;
  setBreakdownBy: BaseFilterProps['setBreakdownBy'];
  handleFilterTypeChange: any;
  onChangeGoalState: BaseFilterProps['onChangeGoalState'];
}

export const ReportingAdditionalFilters = ({
  filter,
  resetFilter,
  handleFilterTypeChange,
  handleMeetingFilter,
  type,
  setBreakdownBy,
  feedbackFilter,
  groups,
  onChangeGoalProgress,
  onChangeGoalType,
  onChangeFeedbackFilter,
  onChangeGoalState,
}: ReportingAdditionalFiltersProps) => {
  const [goalProgress, setGoalProgress] = useState<any[]>([]);

  const computeAdditionalFiltersOptions = (filter: string) => {
    const defaultOptions = [
      {
        label: 'Groups',
        value: 'group',
      },
      {
        label: 'Teams',
        value: 'team',
      },
    ];

    if (filter === 'gender') return CheckinsGenderOptions;
    if (filter === 'state') return GoalStateOptions;
    if (filter === 'progress') return goalProgressOptions;
    if (filter === 'breakdown') {
      if (type === 'objectives')
        return [
          ...defaultOptions,
          {
            label: 'Ownership',
            value: 'ownership',
          },
        ];
      if (type === 'actionItems')
        return [
          ...defaultOptions,
          {
            label: 'Boards',
            value: 'boards',
          },
        ];
      return defaultOptions;
    }

    return [];
  };

  return (
    <div key={filter} className="p-4 border-b border-b-[#ededf2]">
      {!['type'].includes(filter) && (
        <FlexRowSpaceBetween className="mb-3">
          <Body2 weight="bold">
            {capitalize(
              filter === 'breakdown'
                ? 'Breakdown by'
                : ['sentiment', 'meetings'].includes(filter)
                ? `${capitalize(filter)} count`
                : filter,
            )}
          </Body2>
          <FlexRow
            className="cursor-pointer"
            onClick={() => resetFilter(filter)}>
            <FlexRow>
              <SecondaryMinusSVG />
              <Body2 kind="red400" weight="bold" className="">
                remove
              </Body2>
            </FlexRow>
          </FlexRow>
        </FlexRowSpaceBetween>
      )}

      {filter === 'sentiment' && (
        <SentimentFilter
          onChangeSentiment={(data) => {
            onChangeFeedbackFilter({
              ...feedbackFilter,
              ...data,
            });
          }}
        />
      )}

      {filter === 'meetings' && (
        <MeetingCountFilter onChangeMeetingCount={handleMeetingFilter} />
      )}

      {filter === 'values' && (
        <FeedbackValuesFilter
          selectedValues={feedbackFilter.values}
          onChangeValues={(data) => {
            onChangeFeedbackFilter({
              ...feedbackFilter,
              values: data,
            });
          }}
        />
      )}

      {['tags', 'progress'].includes(filter) && (
        <CustomFilterMultiSelect
          removeSearch
          excludeCheckBox
          options={computeAdditionalFiltersOptions(filter)}
          onChange={(data: {value: string}[]) => {
            if (filter === 'progress') {
              onChangeGoalProgress(data?.map((option) => option.value));
              setGoalProgress(data);
            }
          }}
          showSearchIcon={false}
          placeholderTerm={'value'}
          selectedOptions={goalProgress}
        />
      )}

      {filter === 'type' && (
        <GoalType
          onChangeType={(value, groups) => onChangeGoalType(value, groups)}
          onCancel={() => resetFilter(filter)}
          groups={groups}
        />
      )}

      {![
        'sentiment',
        'meetings',
        'values',
        'tags',
        'progress',
        'type',
      ].includes(filter) && (
        <SelectField
          placeholder="Select value"
          reportingStatus
          excludeSearchBox
          onChange={(data: {value: string}) => {
            if (filter === 'breakdown') {
              setBreakdownBy(data?.value || '');

              if (data?.value === 'team')
                return handleFilterTypeChange('teams');

              handleFilterTypeChange(data?.value === 'group' ? 'groups' : '');
            }

            if (filter === 'state') {
              onChangeGoalState(data?.value || '');
            }
          }}
          margin
          options={computeAdditionalFiltersOptions(filter)}
        />
      )}
    </div>
  );
};
