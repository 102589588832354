import styled, {DefaultTheme, StyledComponentProps} from 'styled-components';
import styledMap from 'styled-map';
import {Typography} from '../typography/typography';
import {theme} from '../../style';
import {LinkProps, Link as RouterLink} from 'react-router-dom';

export interface TKind extends LinkProps {
  kind?: 'black' | 'blue';
}

export type TLinkProps = StyledComponentProps<
  'a',
  DefaultTheme,
  {
    href?: string;
    onClick?: () => void;
    kind?: 'black' | 'blue';
  },
  never
>;

export const Link = styled(RouterLink)<TKind>`
  ${Typography};

  font-size: 14px;
  color: ${styledMap('kind', {
    black: theme.colors.textMedium,
    blue: theme.colors.purple300,
    default: theme.colors.purple300,
  })};
  text-decoration: none;
  transition: all ease-in-out 300ms;
  position: relative;

  &:hover {
    color: ${styledMap('kind', {
      black: theme.colors.textDark,
      blue: theme.colors.purple300,
      default: theme.colors.purple300,
    })};

    text-decoration: underline;
  }
`;
