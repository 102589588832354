import {FilterComponent} from '@pages/dashboard/reporting/reporting-pages/admin-analytics/analytics-shared-components/filterbox/filterbox';
import {useDirectOptionHook} from '@pages/dashboard/reporting/reporting-pages/reporting-overview/hooks/direct-options.hook';
import {useStoreContext} from '@store/store-context';
import {MultiOptionsSelectField} from '@ui/molecules/select/multi-options';
import {SelectField} from '@ui/molecules/select/selectfield';
import {useState, useMemo, useCallback} from 'react';

interface IUserFilter {
  onChange: (e: any) => void;
  handleClose: () => void;
  clearSelection: boolean;
  filterOnlyTeams?: boolean;
  canFilterAll?: boolean;
  useSingleSelect?: boolean;
  defaultUserFilter?: any;
  filterOnlyPeople?: boolean;
  disableUserReset?: (userId: string) => boolean;
  directReportFilter?: boolean;
  variant?: 'objectives' | '';
  setClearSelection: any;
  selectedValues: any;
}

export const UserFilters = ({
  onChange,
  handleClose,
  canFilterAll,
  clearSelection,
  filterOnlyTeams,
  variant,
  disableUserReset,
  defaultUserFilter,
  useSingleSelect,
  directReportFilter,
  filterOnlyPeople,
  setClearSelection,
  selectedValues,
}: IUserFilter) => {
  const {
    usersStore: {users},
    authStore: {auth},
  } = useStoreContext();

  const [showApply, setShowApply] = useState(false);
  const [clearSelectedMembers, setClearAllSelectedMembers] = useState(false);
  const [filterBy, setFilterBy] = useState(selectedValues.filterBy);
  const [selectedTeam, setSelectedTeam] = useState(selectedValues.selectedTeam);
  const [selectedMembers, setSelectedMembers] = useState(
    selectedValues.members,
  );

  const filterByOption = [
    {value: 'people', label: 'People'},
    {value: 'team', label: 'Teams'},
  ];

  const teamLabel = (user: {firstName: string; email: string}) =>
    user.firstName ? `${user.firstName}'s Team` : `${user.email}'s Team`;

  const teamsOptions = useMemo(() => {
    const myTeamOption = {value: auth?.user?.id, label: 'My Team'};
    if (auth.user.role !== 'admin') return [myTeamOption];
    return [
      myTeamOption,
      ...users
        .filter((user) => user.isReviewer && user.id !== auth.user.id)
        .map((user) => ({value: user.id, label: teamLabel(user)})),
    ];
  }, [users, auth?.user?.role, auth?.user?.id]);
  const {directReportsTeamOption} = useDirectOptionHook();

  const handleApply = () => {
    setShowApply(false);
    handleClose();
    onChange({filterBy, selectedTeam, members: selectedMembers});
  };

  const resetFilter = useCallback(() => {
    setShowApply(true);

    const defaultFilter = filterOnlyPeople
      ? {value: 'people', label: 'People'}
      : filterOnlyTeams
      ? {value: 'team', label: 'Teams'}
      : {value: 'team', label: 'Teams'};

    const defaultSelectedTeam = filterOnlyTeams
      ? {value: auth.user.id, label: 'My Team'}
      : directReportFilter
      ? directReportsTeamOption[0]
      : {
          value:
            typeof auth.user.reviewer === 'string'
              ? auth.user.reviewer
              : auth.user.reviewer?.id,
          label: 'My Team',
        };

    if (defaultUserFilter) {
      setSelectedMembers(defaultUserFilter.members);
      setClearAllSelectedMembers(true);
      setFilterBy(defaultUserFilter.filterBy);
      setSelectedTeam(defaultUserFilter.selectedTeam);

      return onChange(defaultUserFilter);
    }

    setSelectedMembers([]);
    setClearAllSelectedMembers(true);
    setFilterBy({value: 'team', label: 'Team'});
    setSelectedTeam(defaultSelectedTeam);

    onChange({
      filterBy: defaultFilter,
      selectedTeam: defaultSelectedTeam,
      members: [],
    });
  }, [
    filterOnlyPeople,
    filterOnlyTeams,
    auth.user.id,
    auth.user.reviewer,
    directReportFilter,
    directReportsTeamOption,
    defaultUserFilter,
    onChange,
  ]);

  useMemo(() => {
    if (clearSelection) {
      resetFilter();
      setClearSelection(false);
    }
  }, [clearSelection, resetFilter, setClearSelection]);

  const disableButton = useMemo(() => {
    if (disableUserReset) {
      return disableUserReset(selectedMembers[0]?.value);
    }

    const {value: filterValue} = filterBy;

    const selectedMembersLength = selectedMembers.length;

    const {value: selectedTeamValue} = selectedTeam;
    const {id: userId} = auth.user;

    const conditions = [
      !filterValue,
      filterValue === 'people' && selectedMembersLength === 0,
      filterValue === 'team' && !selectedTeamValue,
      filterValue === 'team' &&
        selectedTeamValue === selectedValues.selectedTeam.value &&
        selectedMembersLength === 0 &&
        selectedTeamValue === userId,
      variant === 'objectives' && selectedMembersLength === users.length,
      filterValue === 'team' &&
        filterOnlyTeams &&
        selectedTeamValue === userId &&
        selectedMembersLength === 0,
    ];

    return conditions.some((condition) => condition);
  }, [
    disableUserReset,
    filterBy,
    selectedMembers,
    selectedTeam,
    auth.user,
    selectedValues.selectedTeam.value,
    variant,
    users.length,
    filterOnlyTeams,
  ]);

  const MemberComponent = useSingleSelect
    ? SelectField
    : MultiOptionsSelectField;

  const filterMembers = useCallback(() => {
    if (filterOnlyPeople) {
      return users?.map((member) => ({
        label: member?.firstName
          ? `${member.firstName} ${member.lastName}`
          : member.email,
        value: member?.id,
      }));
    }
    const filteredUsers =
      filterBy.value === 'people'
        ? auth.user.role !== 'admin'
          ? users.filter((item) => item?.reviewer?.id === auth.user.id)
          : users
        : users.filter((item) => item?.reviewer?.id === selectedTeam.value);

    return filteredUsers.map((member) => ({
      label: member?.firstName
        ? `${member.firstName} ${member.lastName}`
        : member.email,
      value: member.id,
    }));
  }, [
    auth.user.id,
    users,
    filterOnlyPeople,
    filterBy.value,
    selectedTeam.value,
    auth.user.role,
  ]);

  return (
    <FilterComponent
      title="Sort by"
      boxStyle={{
        left: 0,
        zIndex: 2,
      }}
      setView={() => null}
      showFilter={true}
      handleClose={handleClose}
      onClickAction={resetFilter}
      resetDisabled={disableButton}
      showApplyButton={{
        show: showApply,
        action: handleApply,
      }}
      type=""
      resetFilter={() => null}>
      <div>
        {(auth?.user?.role === 'admin' || canFilterAll) && !filterOnlyTeams && (
          <SelectField
            options={filterByOption}
            placeholder={'Select option'}
            defaultValue={filterBy.value}
            onChange={(e: any) => {
              setShowApply(true);
              if (e === null || !e) {
                setFilterBy({
                  value: '',
                  label: '',
                });
              } else {
                setFilterBy(e);
              }

              if (selectedTeam.value !== auth.user.id) {
                setSelectedTeam({
                  value: auth?.user?.id,
                  label: 'Direct reports',
                });
              }

              if (selectedMembers.length > 0) {
                setSelectedMembers([]);
                setClearAllSelectedMembers(true);
              }
            }}
            borderRadius={10}
          />
        )}
        {filterBy.value === 'team' && !filterOnlyPeople && (
          <SelectField
            options={
              directReportFilter ? directReportsTeamOption : teamsOptions
            }
            borderRadius={10}
            disabled={auth.user.role !== 'admin'}
            onChange={(e: any) => {
              setShowApply(true);
              if (!e) {
                setSelectedTeam({
                  value: '',
                  label: '',
                });
              } else {
                setSelectedTeam(e);
              }
              if (selectedMembers.length > 0) {
                setSelectedMembers([]);
                setClearAllSelectedMembers(true);
              }
            }}
            placeholder="Select team"
            defaultValue={selectedTeam.value}
          />
        )}

        {!!filterBy.value && (
          <MemberComponent
            options={filterMembers()}
            type="users"
            disabled={
              filterBy.value !== 'people' &&
              auth.user.role === 'admin' &&
              selectedTeam.value === ''
            }
            defaultValue={
              useSingleSelect ? selectedMembers[0]?.value : selectedMembers
            }
            selectAllOption={true}
            clearOptions={clearSelectedMembers}
            setClearAll={setClearAllSelectedMembers}
            performance={true}
            onChange={(e: {value: string; label: string}[]) => {
              setShowApply(true);
              if (!!e) {
                setSelectedMembers(Array.isArray(e) ? e : [e]);
              }
            }}
            placeholder={'Select member'}
          />
        )}
      </div>
    </FilterComponent>
  );
};
