import styled from 'styled-components';
import {ComponentPropsWithRef} from 'react';
import {ifProp} from 'styled-tools';
import styledMap from 'styled-map';
import {appearance, textStyle} from './appearance';
import {loadingStyle} from './loading';
import {width} from './width';
import {Link} from 'react-router-dom';
import {getColorFromTheme} from '../../ui-utils';

export type ButtonKind = 'primary' | 'secondary' | 'small' | 'small-black';
export type ButtonSize = 'xs' | 'xm' | 'sm' | 'md' | 'full';

export interface ButtonProps extends ComponentPropsWithRef<'button'> {
  kind?: ButtonKind;
  width?: ButtonSize;
  isLoading?: boolean;
}

export const cursorValue = styledMap`
  disabled: initial;
  default: pointer;
`;

export const pointerEvents = styledMap`
  disabled: none;
  isLoading: none;
  default: initial;
`;

export const Button = styled.button<ButtonProps>`
  cursor: ${cursorValue};
  outline: none;
  border-radius: 10px;
  padding: 16px 28px;
  pointer-events: ${pointerEvents};
   position: relative;
  transition: 200ms all ease-in-out;
  border: 1px solid;
  border-color: transparent;
  line-height: unset;
  z-index: 0;
  white-space: nowrap;
  ${appearance};
  ${width};
  ${textStyle};
  ${ifProp('isLoading', loadingStyle)}
  overflow-x: hidden;
  &:disabled {
    color: ${({isLoading}) =>
      isLoading ? getColorFromTheme('white') : undefined};
  }
    background-color: ${({isLoading}) =>
      isLoading ? getColorFromTheme('purple300') : undefined};
  }

  }
`;

export const LinkButton = styled(Link)<ButtonProps>`
  cursor: ${cursorValue};
  outline: none;
  border-radius: 10px;
  padding: 16px 28px;
  pointer-events: ${pointerEvents};
  position: relative;
  transition: 200ms all ease-in-out;
  border: 1px solid;
  border-color: transparent;
  line-height: unset;
  z-index: 1;

  ${appearance};
  ${width};
  ${textStyle};
  ${ifProp('isLoading', loadingStyle)}
`;

export const DisplayNameButton = styled.button<ButtonProps>`
  cursor: ${cursorValue};
  outline: none;
  border-radius: 10px;
  padding: 16px 28px;
  pointer-events: ${pointerEvents};
  position: relative;
  transition: 200ms all ease-in-out;
  border: 1px solid;
  border-color: transparent;
  line-height: unset;
  z-index: 1;
  border: 1px solid #cecede;
  padding: 10px 15px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  font-style: medium;
  ${width};
  ${ifProp('isLoading', loadingStyle)}
  overflow-x: hidden;
`;
