import {useState} from 'react';
import {HorizontalSpacer} from '../../../../ui/atoms/spacer';
import {useStoreContext} from '../../../../store/store-context';
import {VerticalSpacer} from '../../../../ui/atoms/spacer';
import {Body1, Headline3} from '../../../../ui/atoms/typography';
import {QuestionReviewWrapper, QuestionContainer, AverageTitle} from './styles';

import {FlexRow, FlexRowSpaceBetween} from '@ui/style/styles';
import {ChevronDownIcon} from '@ui/atoms/icons';
import {IAPIUserFormat, IGoalResult} from '@hooks';

import {WrittenReview} from './written-review';

import {OptionSelect} from './option-select';

import {Rating} from './make-review';

type QuestionReviewProps = {
  item: {
    label: string;
    questions: {
      question: string;
      useGoals?: boolean;
      id: string;
    }[];
  };
  type: string | null;
  showWrittenReview?: boolean;
  writtenReviews: {
    reviewerType: string;
    user: string;
    competenceRatings: Rating[];
    valueRatings: Rating[];
    id: string;
    reviewer: string;
  }[];
  goals?: IGoalResult[];
  onChange?: (value: {
    comment: string;
    id: string;
    isValue: boolean;
    goals?: {goal: string; comment?: string; score: number}[];
    rating: number;
  }) => void;
  user: IAPIUserFormat;
  idx: number;
};

export const QuestionReview = ({
  item,
  type,
  onChange,
  writtenReviews,
  user,
  goals,
  showWrittenReview,
  idx,
}: QuestionReviewProps) => {
  const [open, setIsOpen] = useState(idx === 0 ? true : false);

  const {
    ccStore: {ccReviewScale},
  } = useStoreContext();

  const isValue = item.label === 'Values';

  const getAverage = () => {
    const metrics = isValue
      ? writtenReviews?.flatMap((review) => review.valueRatings)
      : writtenReviews?.flatMap((review) =>
          review.competenceRatings.filter(
            (competence) => competence.competenceCategory === item.label,
          ),
        );

    const totalScore = metrics?.reduce(
      (accumulator, currentValue) => accumulator + currentValue.score,
      0,
    );

    const averageScore = Math.round(totalScore / metrics?.length);

    const averageTitle = ccReviewScale.find(
      (value) => value.score === averageScore,
    )?.title;

    return averageTitle;
  };

  return (
    <>
      <QuestionReviewWrapper>
        <FlexRowSpaceBetween
          onClick={() => setIsOpen(!open)}
          style={{
            padding: '20px 16px',
          }}>
          <FlexRow>
            <ChevronDownIcon
              pathStyle={{
                fill: open ? '#585ADF' : undefined,
              }}
              style={{
                width: '12px',
                height: '12px',
                transform: open ? '' : 'rotate(-90deg)',
              }}
            />

            <HorizontalSpacer size="15px" />
            <Headline3>
              {item.label === 'Values' ? 'Core Values' : item.label}
            </Headline3>
          </FlexRow>
          {showWrittenReview ? (
            <AverageTitle>
              <Body1 weight="bold">{getAverage()}</Body1>
            </AverageTitle>
          ) : null}
        </FlexRowSpaceBetween>
        {open && (
          <QuestionContainer>
            {item.questions.map(({question, id, useGoals}, idx) => (
              <>
                {idx !== 0 && (
                  <>
                    <VerticalSpacer size="20px" />
                    <div className="rule" />
                  </>
                )}
                <VerticalSpacer size="20px" />
                <div style={{padding: '10px'}}>
                  {' '}
                  <FlexRow style={{alignItems: 'flex-start'}}>
                    <Headline3
                      className="questionTitle"
                      style={{color: '#5F5F8C'}}>
                      Q{idx + 1}.
                    </Headline3>
                    <Headline3>
                      How would you rate{' '}
                      {type === 'self' ? 'yourself' : `${user.firstName}`} on
                      this {item.label === 'Values' ? 'Value' : 'Competence'}{' '}
                      metric: <br />
                      <span style={{color: '#585ADF'}}>{question}</span>
                    </Headline3>
                  </FlexRow>
                  <VerticalSpacer size="10px" />
                </div>
                <div>
                  {showWrittenReview ? (
                    <>
                      {writtenReviews?.map((review) => (
                        <WrittenReview
                          review={review}
                          goals={goals}
                          question={question}
                          isValue={isValue}
                          key={review.id}
                        />
                      ))}
                    </>
                  ) : (
                    <OptionSelect
                      id={id}
                      goals={goals}
                      useGoals={useGoals}
                      onChange={(value) => {
                        if (onChange) {
                          onChange({
                            ...value,
                            isValue: isValue,
                          });
                        }
                      }}
                    />
                  )}
                </div>
              </>
            ))}
          </QuestionContainer>
        )}
      </QuestionReviewWrapper>
    </>
  );
};
