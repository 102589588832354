import {forwardRef, memo, Ref} from 'react';
import ReactSelect from 'react-select';
import {
  VerticalSpacer,
  HorizontalSpacer,
} from '../../../../../../../../../ui/atoms/spacer';
import {CTA} from '../../../../../../../../../ui/atoms/typography';
import {
  OptionSelect,
  SelectFieldProps,
  TOption,
} from '../../../../../../../../../ui/molecules/select/option-select/option-select';
import {useQuestion} from '../../../../../../../../../hooks/questions.hooks';
import {Question} from './question';
import {PlusIcon} from '../../../../../../../../../ui/atoms/icons';

type TQuestionField = {
  questionTypes: any;

  questionTypeHelper: any;
  questionTypeState: any;
  setValue: any;
  defaultValue: any;
  questions_: any;
  questions__: any;
  setQuestions__: any;
  state: any;
  helper: any;
};

export const QuestionSelect = forwardRef(
  (
    {
      label,
      state,
      helper,
      placeholder,
      options,
      onChange,
      value,
      defaultValue,
      chosenQuestionType,
      questionTypes,
      controlShouldRenderValue,
      fieldNotFoundPlaceHolder,

      ...rest
    }: SelectFieldProps,
    ref: Ref<ReactSelect<TOption>>,
  ) => {
    return (
      <OptionSelect
        name="question"
        onChange={onChange}
        value={value}
        options={options}
        fieldNotFoundPlaceHolder={fieldNotFoundPlaceHolder}
        {...rest}
      />
    );
  },
);
export const Questions = memo(
  ({
    questionTypes,

    questionTypeHelper,
    questionTypeState,
    setValue,
    defaultValue,
    questions_,
    questions__,
    setQuestions__,
    state,
    helper,
  }: TQuestionField) => {
    const {
      addQuestion,
      questions,
      onUpdateQuestion,
      removeQuestion,
      //    disabled,
    } = useQuestion(defaultValue, setValue);

    return (
      <>
        <div>
          <CTA kind="textDark">Select from template or add custom question</CTA>
          {Array.from(questions).map(([key, {question}], index) => (
            <Question
              key_={key}
              index={index}
              questionTypes={questionTypes}
              questionTypeHelper={questionTypeHelper}
              questionTypeState={questionTypeState}
              removeQuestion={removeQuestion}
              value={question}
              setValue={(questions: any) => {
                onUpdateQuestion(key, questions);
              }}
              questions_={questions_}
              questions__={questions__}
              setQuestions__={setQuestions__}
              state={state}
              helper={helper}
            />
          ))}
        </div>
        <VerticalSpacer size="16px" />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            stroke: '#585ADF',
            cursor: 'pointer',
          }}>
          <PlusIcon />
          <HorizontalSpacer size="8px" />
          <CTA kind="purple300" onClick={addQuestion}>
            Add new question
          </CTA>
        </div>
      </>
    );
  },
);
