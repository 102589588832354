import dayjs from 'dayjs';
import {useCallback, useState} from 'react';
import {useStoreContext} from '../../../store/store-context';
import {AnalyticsExportDates} from '../../../utils/date';
import {transformOptions} from './reporting-pages/reporting-overview/components/kpi-board';
import {AnalyticsController} from './reporting-pages/admin-analytics/admin-analytics.controller';
import {AnalyticsRequestImpl} from './reporting-pages/admin-analytics/admin-analytics.request';
import {groupTypeController} from '../admin/admin-components/admin-people/admin-group-type/admin-group-type-controller';
import {groupTypeRequestImpl} from '../admin/admin-components/admin-people/admin-group-type/admin-group-type-request';
import {groupController} from '../admin/admin-components/admin-people/admin-group/admin-group-controller';
import {groupRequestImpl} from '../admin/admin-components/admin-people/admin-group/admin-group-request';

interface IPDFDataStore {
  timeframe: string;
  group: string;
  member: string;
  customDate?: {
    start?: string;
    end?: string;
  };
}
export function useCompanyReportingHook() {
  const {
    analyticsStore: {
      setManagers,
      setMembers,
      setFilterGroup,
      modifyExportsReportDate,
      members: membersInStore,

      setCompanySearchable,

      filterGroup: groupsInStore,
    },
    groupStore: {setAllGroups, groups},
    groupTypeStore: {groupType},
  } = useStoreContext();

  const [pdfDataStore, setPdfDataStore] = useState<IPDFDataStore>({
    timeframe: '',
    group: '',
    member: '',
    customDate: {
      start: '',
      end: '',
    },
  });

  const [fetchPDF] = useState('');
  const [fetchCSV] = useState('');
  const handleSelectTimeframe = (
    value: string,
    customDate?: {starts: string; ends: string},
  ) => {
    if (value) {
      const {start, end} = AnalyticsExportDates(value, customDate);
      if (value === 'past_week') {
        setPdfDataStore({...pdfDataStore, timeframe: 'past_week'});
        modifyExportsReportDate(
          dayjs(start).format('MM/DD/YYYY'),
          dayjs(end).format('MM/DD/YYYY'),
        );
      } else if (value === 'last_month') {
        setPdfDataStore({...pdfDataStore, timeframe: 'last_month'});
        modifyExportsReportDate(
          dayjs(start).format('MM/DD/YYYY'),
          dayjs(end).format('MM/DD/YYYY'),
        );
      } else if (value === 'last_quarter') {
        setPdfDataStore({...pdfDataStore, timeframe: 'last_quarter'});
        modifyExportsReportDate(
          dayjs(start).format('MM/DD/YYYY'),
          dayjs(end).format('MM/DD/YYYY'),
        );
      } else if (value === 'custom_dates') {
        if (customDate === undefined) {
          setPdfDataStore({
            ...pdfDataStore,
            timeframe: 'custom_dates',
          });
          modifyExportsReportDate(null, null);
        } else {
          setPdfDataStore({
            ...pdfDataStore,
            timeframe: 'custom_dates',
            customDate: {
              start: customDate?.starts,
              end: customDate?.ends,
            },
          });
          modifyExportsReportDate(
            dayjs(customDate?.starts).format('MM/DD/YYYY'),
            dayjs(customDate?.ends).format('MM/DD/YYYY'),
          );
        }
      } else {
        modifyExportsReportDate(
          dayjs(start).format('MM/DD/YYYY'),
          dayjs(end).format('MM/DD/YYYY'),
        );
      }
    } else {
      modifyExportsReportDate(null, null);
    }
  };
  const [, setGoalState] = useState('');
  const handleSelectGoalState = (state: string) => {
    setGoalState(state);
  };
  const handleChangeSearchFilterForExport = (data: {
    value: string;
    label: any | null;
  }) => {
    setCompanySearchable(
      'members',
      data !== null && data.label.id === 'members' ? data.value : '',
    );
    setCompanySearchable(
      'department',
      data !== null &&
        data.label.id !== 'members' &&
        data.label.id !== 'managers' &&
        (data as any)?.variant !== 'departmentMembers'
        ? data.value
        : '',
    );
    setCompanySearchable(
      'departmentMembers',
      data !== null &&
        data.label.id !== 'members' &&
        data.label.id !== 'managers' &&
        (data as any)?.variant === 'departmentMembers'
        ? data.value
        : '',
    );
  };
  const clearMembersAndDepartments = () => {
    setCompanySearchable('members', null);
    setCompanySearchable('department', null);
    setCompanySearchable('departmentMembers', null);
  };
  // Include archived goals
  const [includeArchived, setIncludeArchived] = useState(false);

  // Initialize goals

  const initGroups = useCallback(async () => {
    const grpTypeRequest = new groupTypeRequestImpl();
    const grpTypeController = new groupTypeController(grpTypeRequest);
    const grpRequest = new groupRequestImpl();
    const grpController = new groupController(grpRequest);
    const grpTypeResponse: any = await grpTypeController.newfetchGroupType();
    const grpResponses: any = Promise.all(
      grpTypeResponse.map(async (grpType: any) => {
        const grpResponse: any = await grpController.newfetchGroup(grpType.id);
        grpResponse && setAllGroups(grpResponse.groups, grpType.name);
        return grpResponse.groups;
      }),
    );

    return grpResponses;
  }, [setAllGroups]);
  // Initialize members and managers
  const fetchMembersAndManagers = useCallback(async () => {
    const request = new AnalyticsRequestImpl();
    const controller = new AnalyticsController(request);
    const membersAndManagersResponse = await controller.fetchMembersAndManagers();
    membersAndManagersResponse &&
      membersAndManagersResponse.length > 0 &&
      setMembers(
        membersAndManagersResponse
          .filter((person: any) => person.status.includes('on'))
          // eslint-disable-next-line array-callback-return
          .map((member: any) => {
            if (member) {
              return {
                label: {
                  avatar: {
                    name:
                      member && member.firstName
                        ? member.firstName + ' ' + member.lastName
                        : member.email + ' (pending invitation)',
                    src:
                      member.avatar && member.avatar.url
                        ? member.avatar.url
                        : '',
                    id: member.id,
                    reviewer: member.reviewer,
                  },
                  id: 'members',
                  name:
                    member && member.firstName
                      ? member.firstName + ' ' + member.lastName
                      : member.email + ' (pending invitation)',
                },
                value: member.id,
              };
            }
          }),
      );
    membersAndManagersResponse &&
      membersAndManagersResponse.length > 0 &&
      setManagers(
        membersAndManagersResponse
          .filter((person: any) => person.isReviewer === true)
          // eslint-disable-next-line array-callback-return
          .map((member: any) => {
            if (member) {
              return {
                label: {
                  avatar: {
                    name:
                      member && member.firstName
                        ? member.firstName + ' ' + member.lastName
                        : member.email + ' (pending invitation)',
                    src:
                      member.avatar && member.avatar.url
                        ? member.avatar.url
                        : '',
                    id: member.id,
                  },
                  id: 'managers',
                  name:
                    member && member.firstName
                      ? member.firstName + ' ' + member.lastName
                      : member.email + ' (pending invitation)',
                },
                value: member.id,
              };
            }
          }),
      );
  }, [setManagers, setMembers]);
  // Initialize groups
  const fetchGroups = useCallback(async () => {
    const request = new AnalyticsRequestImpl();
    const controller = new AnalyticsController(request);
    const groupsResponse = await controller.fetchGroups();

    setFilterGroup(
      groupsResponse &&
        // eslint-disable-next-line array-callback-return
        groupsResponse.results.map((group: any) => {
          if (group) {
            return {
              label:
                group.name === 'General'
                  ? `General (${group.groupType?.name})`
                  : group.name,
              value: group.id,
            };
          }
        }),
    );
  }, [setFilterGroup]);

  const [objectiveOptions, setObjectiveOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const getGoals = useCallback(
    async (filters: any, view: string) => {
      setLoading(true);
      const request = new AnalyticsRequestImpl();
      const controller = new AnalyticsController(request);
      const response = await controller.fetchSpecifiedGoals(
        filters,
        view,
        true,
      );

      const transformGoalOptions = response?.goals.map((goal: any) =>
        transformOptions(goal, groups, groupType),
      );

      setObjectiveOptions(transformGoalOptions);

      setLoading(false);
    },
    [groups, groupType],
  );

  return {
    handleSelectTimeframe,
    fetchPDF,
    fetchCSV,
    handleSelectGoalState,
    getGoals,
    fetchMembersAndManagers,
    fetchGroups,
    loading,
    objectiveOptions,
    handleChangeSearchFilterForExport,
    membersInStore,
    initGroups,
    groupsInStore,
    pdfDataStore,
    setPdfDataStore,
    clearMembersAndDepartments,
    includeArchived,
    setIncludeArchived,
  };
}
