import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import dayjs from 'dayjs';
import {
  LongPressCallback,
  LongPressDetectEvents,
  useLongPress,
} from './longpress';

const THIS_MONTH = +new Date().getMonth() + 1;
const THIS_YEAR = +new Date().getFullYear();
const CALENDAR_WEEKS = 6;

export const WEEK_DAYS: Record<string, string> = {
  Sunday: 'Su',
  Monday: 'Mo',
  Tuesday: 'Tu',
  Wednesday: 'We',
  Thursday: 'Th',
  Friday: 'Fr',
  Saturday: 'Sa',
};
export const monthToIndex = (month: string) => {
  switch (month) {
    case 'January':
      return 1;
    case 'February':
      return 2;
    case 'March':
      return 3;
    case 'April':
      return 4;
    case 'May':
      return 5;
    case 'June':
      return 6;
    case 'July':
      return 7;
    case 'August':
      return 8;
    case 'September':
      return 9;
    case 'October':
      return 10;
    case 'November':
      return 11;
    case 'December':
      return 12;
    default:
      return 0;
  }
};
export const CALENDAR_MONTHS: Record<string, string> = {
  January: 'Jan',
  February: 'Feb',
  March: 'Mar',
  April: 'Apr',
  May: 'May',
  June: 'Jun',
  July: 'Jul',
  August: 'Aug',
  September: 'Sep',
  October: 'Oct',
  November: 'Nov',
  December: 'Dec',
};

const getDate = (value?: string | Date) => {
  return dayjs(value).isValid() ? dayjs(value) : dayjs();
};

export const defaultDate = (value?: string | Date) => {
  return getDate(value).toDate();
};

export const getMonth = (value?: string | Date) => {
  return getDate(value).month();
};

export const getYear = (value?: string | Date) => {
  return getDate(value).year();
};

export const getPreviousMonth = (month: number, year: number) => {
  const prevMonth = month > 1 ? month - 1 : 12;
  const prevMonthYear = month > 1 ? year : year - 1;

  return {month: prevMonth, year: prevMonthYear};
};

export const getNextMonth = (month: number, year: number) => {
  const nextMonth = month < 12 ? month + 1 : 1;
  const nextMonthYear = month < 12 ? year : year + 1;

  return {month: nextMonth, year: nextMonthYear};
};

export const getMonthDays = (month = THIS_MONTH, year = THIS_YEAR) => {
  const months30 = [4, 6, 9, 11];
  const leapYear = year % 4 === 0;

  return month === 2
    ? leapYear
      ? 29
      : 28
    : months30.includes(month)
    ? 30
    : 31;
};

export const zeroPad = (value: number, length: number) =>
  `${value}`.padStart(length, '0');

export const getMonthFirstDay = (month = THIS_MONTH, year = THIS_YEAR) => {
  return +new Date(`${year}-${zeroPad(month, 2)}-01`).getDay() + 1;
};

export const getDateISO = (date = new Date()) => {
  if (!dayjs(date).isValid()) return null;

  return [
    date.getFullYear(),
    zeroPad(+date.getMonth() + 1, 2),
    zeroPad(+date.getDate(), 2),
  ].join('-');
};

export const isSameMonth = (date: Date, basedate = new Date()) => {
  if (!(dayjs(date).isValid() && dayjs(basedate).isValid())) return false;

  const basedateMonth = +basedate.getMonth() + 1;
  const basedateYear = basedate.getFullYear();

  const dateMonth = +date.getMonth() + 1;
  const dateYear = date.getFullYear();

  return +basedateMonth === +dateMonth && +basedateYear === +dateYear;
};

export const isSameDay = (date: Date, basedate = new Date()) => {
  if (!(dayjs(date).isValid() && dayjs(date).isValid())) return false;

  const basedateDate = basedate.getDate();
  const basedateMonth = +basedate.getMonth() + 1;
  const basedateYear = basedate.getFullYear();

  const dateDate = date.getDate();
  const dateMonth = +date.getMonth() + 1;
  const dateYear = date.getFullYear();

  return (
    +basedateDate === +dateDate &&
    +basedateMonth === +dateMonth &&
    +basedateYear === +dateYear
  );
};

export const isDateBefore = (date: Date, dateBefore?: string) => {
  if (dateBefore) {
    return dayjs(date).isBefore(dayjs(dateBefore));
  }

  return false;
};

export const isDateAfter = (date: Date, dateAfter?: string) => {
  if (dateAfter) {
    return dayjs(date).isAfter(dayjs(dateAfter));
  }

  return false;
};

export const constructCalendar = (month = THIS_MONTH, year = THIS_YEAR) => {
  const monthDays = getMonthDays(month, year);
  const monthFirstDay = getMonthFirstDay(month, year);

  const daysFromPrevMonth = monthFirstDay - 1;
  const daysFromNextMonth =
    CALENDAR_WEEKS * 7 - (daysFromPrevMonth + monthDays);

  const {month: prevMonth, year: prevMonthYear} = getPreviousMonth(month, year);
  const {month: nextMonth, year: nextMonthYear} = getNextMonth(month, year);

  const prevMonthDays = getMonthDays(prevMonth, prevMonthYear);

  const prevMonthDates = [...new Array(daysFromPrevMonth)].map((n, index) => {
    const day = index + 1 + (prevMonthDays - daysFromPrevMonth);
    return [prevMonthYear, zeroPad(prevMonth, 2), zeroPad(day, 2)];
  });

  const thisMonthDates = [...new Array(monthDays)].map((n, index) => {
    const day = index + 1;
    return [year, zeroPad(month, 2), zeroPad(day, 2)];
  });

  const nextMonthDates = [...new Array(daysFromNextMonth)].map((n, index) => {
    const day = index + 1;
    return [nextMonthYear, zeroPad(nextMonth, 2), zeroPad(day, 2)];
  });

  return [...prevMonthDates, ...thisMonthDates, ...nextMonthDates];
};

type CallBack = LongPressCallback<HTMLButtonElement>;

export function useCalendar(
  value?: string,
  onChange?: (date: Date) => void,
  useValue?: boolean,
) {
  const interval = useRef<NodeJS.Timeout>();
  const today = useRef(new Date());
  const [current, setCurrent] = useState<Date>(defaultDate(value));

  const [data, setData] = useState<{month: number; year: number}>({
    month: getMonth(value) + 1,
    year: getYear(value),
  });

  useEffect(() => {
    if (value && useValue) {
      const newDate = new Date(value);
      setCurrent(newDate);

      setData({
        month: newDate.getMonth() + 1,
        year: newDate.getFullYear(),
      });
    }
  }, [value, useValue]);

  const handleDateSelection = useCallback(
    (evt: SyntheticEvent<HTMLButtonElement>, date: Date) => {
      evt && evt.preventDefault();
      const isDateObject = dayjs(date).isValid();
      const _date = isDateObject ? date : new Date();

      setCurrent(_date);
      setData({
        year: _date.getFullYear(),
        month: +_date.getMonth() + 1,
      });
      if (onChange) {
        onChange(date);
      }
    },
    [onChange],
  );

  const gotoPreviousYear = useCallback(() => {
    setData((prev) => ({...prev, year: prev.year - 1}));
  }, []);

  const gotoNextYear = useCallback(() => {
    setData((prev) => ({...prev, year: prev.year + 1}));
  }, []);

  const gotoPreviousMonth: CallBack = useCallback(
    (event) => {
      if (event?.shiftKey) {
        gotoPreviousYear();
      } else {
        setData((prev) => ({...getPreviousMonth(prev.month, prev.year)}));
      }
    },
    [gotoPreviousYear],
  );

  const gotoNextMonth: CallBack = useCallback(
    (event) => {
      if (event?.shiftKey) {
        gotoNextYear();
      } else {
        setData((prev) => ({...getNextMonth(prev.month, prev.year)}));
      }
    },
    [gotoNextYear],
  );

  const gotoPreviousMonthLong: CallBack = useCallback(
    (event) => {
      if (event?.shiftKey) {
        gotoPreviousYear();
      } else {
        interval.current = setInterval(() => {
          setData((prev) => ({...getPreviousMonth(prev.month, prev.year)}));
        }, 100);
      }
    },
    [gotoPreviousYear],
  );

  const gotoNextMonthLong: CallBack = useCallback(
    (event) => {
      if (event?.shiftKey) {
        gotoNextYear();
      } else {
        interval.current = setInterval(() => {
          setData((prev) => ({...getNextMonth(prev.month, prev.year)}));
        }, 100);
      }
    },
    [gotoNextYear],
  );

  const cancelLongPress = useCallback(() => {
    if (interval.current) {
      clearInterval(interval.current);
    }
  }, []);

  useEffect(() => {
    const int = interval.current;

    return () => {
      if (int) {
        clearInterval(int);
      }
    };
  }, []);

  const previous = useLongPress<HTMLButtonElement, CallBack>(
    gotoPreviousMonthLong,
    {
      onStart: gotoPreviousMonth,
      onFinish: cancelLongPress,
      threshold: 500,
      captureEvent: true,
      detect: LongPressDetectEvents.BOTH,
    },
  );

  const next = useLongPress<HTMLButtonElement, CallBack>(gotoNextMonthLong, {
    onStart: gotoNextMonth,
    onFinish: cancelLongPress,
    threshold: 500,
    captureEvent: true,
    detect: LongPressDetectEvents.BOTH,
  });

  const getDates = useMemo(() => {
    const calendarMonth = data.month || +current.getMonth() + 1;
    const calendarYear = data.year || current.getFullYear();

    return constructCalendar(calendarMonth, calendarYear);
  }, [current, data]);

  return {
    month: data.month,
    year: data.year,
    current,
    today,
    previous,
    next,
    getDates,
    handleDateSelection,
  };
}
