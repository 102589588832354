import {activateNotification} from '@ui/molecules/notification/activate-notification';
import {
  IFiltersTeamMemberGoals,
  CompanyGoalsRequestImpl,
} from './direct-report-request';
import {parseErrorContent} from '@utils/error-handling';

export class CompanyGoalsController {
  constructor(private readonly request: CompanyGoalsRequestImpl) {}

  async fetchTeamMemberGoals(filters: IFiltersTeamMemberGoals) {
    try {
      const response = await this.request.fetchTeamMemberGoals(filters);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }
}
