import {ComponentPropsWithoutRef, memo} from 'react';
import styled from 'styled-components';
import {ifProp} from 'styled-tools';
import {getColorFromTheme} from '../../ui-utils';
import {PlusSVG} from '../../atoms/svg/plus';

export interface AddMoreActionProps extends ComponentPropsWithoutRef<'button'> {
  title: string;
  disabled?: boolean;
}

export const Label = styled.span`
  padding: 8px 16px;
  border: 1px solid ${getColorFromTheme('borderDark')};
  border-radius: 10px;
  color: ${getColorFromTheme('textDark')};
  text-shadow: none;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: normal;
  transition: 200ms all ease-in-out;
  background-color: ${getColorFromTheme('white')};
`;

export const Wrapper = styled.button`
  display: flex;
  flex-direction: row;
  border: none;
  background: none;
  appearance: none;
  align-items: center;
  cursor: pointer;
  padding: 0;
  margin: 0;
  outline: none;

  &:disabled {
    cursor: not-allowed;
  }

  &:disabled ${Label} {
    background-color: ${getColorFromTheme('white')};
    border-color: ${getColorFromTheme('borderDark')};
    color: ${getColorFromTheme('textMuted')};
  }

  &:not(:disabled):hover ${Label} {
    border-color: ${getColorFromTheme('purple300')};
  }

  &:not(:disabled):focus ${Label} {
    border-color: ${getColorFromTheme('purple300')};
    box-shadow: ${getColorFromTheme('purple200')} 0 0 0 4px;
  }
`;

export const Icon = styled.span<{disabled?: boolean}>`
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 24px;
    height: 24px;
    circle {
      fill: ${ifProp('disabled', '#F6F6F8')};
    }
    path {
      stroke: ${ifProp('disabled', '#BFBFD4')};
    }
  }
`;

export const AddMoreAction = memo(function ({
  title,
  disabled,
  ...rest
}: AddMoreActionProps) {
  return (
    <Wrapper {...rest} disabled={disabled} type="button">
      <Icon disabled={disabled}>
        <PlusSVG />
      </Icon>
      <Label>{title}</Label>
    </Wrapper>
  );
});

AddMoreAction.displayName = 'AddMoreAction';
