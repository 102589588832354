import {AuthFormLayout, AuthFormLayoutWider} from '../../../ui/layouts/auth';
import {Headline1, Body1} from '../../../ui/atoms/typography';
import {VerticalSpacer} from '../../../ui/atoms/spacer';
import {useState, useMemo} from 'react';
import {ComponentType} from 'react';
import {Image} from '../../../ui/atoms/image';
import {down} from 'styled-breakpoints';
import {FlexRowCenter} from '@ui/style/styles';
import {DesktopWrapper} from '../../../ui/molecules/workspace/logo-toggle/logo-toggle';
import {useStoreContext} from '../../../store/store-context';
import {cloudinary} from '../../../assets/images/cloudinary';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import Navbar from '../../../ui/molecules/onboarding-nav';
import {Button} from '../../../ui/atoms/button';
export enum SetupWorkspaceSlugs {
  workspaceSetup = 'workspace-setup',
  chooseFramework = 'choose-framework',
  onBoarding = 'on-boarding',
}
const {sortImage: img, sortImage2x: img2x, sortImage3x: img3x} = cloudinary;

const image = {
  src: img,
  extension: 'png',
  variants: [
    {src: img, size: '1x'},
    {src: img2x, size: '2x'},
    {src: img3x, size: '3x'},
  ],
};

const HeaderWrapper = styled.div<{secondStepActive?: boolean}>`
  background: #fafafa;
  position: relative;
  border-bottom: 1px solid #ededf2;
  height: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
  .container {
    display: flex;
    align-items: center;
    justify-content: center;

  .rule {
    width: 40px;
    height: 1px;
  }
  .fade-out {
    opacity: 0.3;
  }
  .g-border {
    border: 1px solid #47b881;
  }
  .b-border {
    border: 1px solid #585adf;
  }
  button {
    width: 180px;
    overflow: hidden;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    box-shadow: 0px 3px 24px rgba(208, 208, 221, 0.16);
    p {
      font-size: 16px;
      ${down('md')} {
        font-size: 14px;
      }
    }
    ${down('md')} {
      width: 155px;
    }
  }
`;

const DescriptionText = styled.div`
  width: 75%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const SetupWorkspacePage = observer(
  ({
    FormComponent,
    slug,
  }: {
    FormComponent: ComponentType;
    slug: SetupWorkspaceSlugs;
  }) => {
    const {
      authStore: {workspaceSetupStep},
    } = useStoreContext();

    const [active, setActive] = useState(
      Number(localStorage?.activeWorkspaceSetupStep),
    );

    useMemo(() => {
      // setActive(Number(localStorage?.activeWorkspaceSetupStep));
      if (workspaceSetupStep === 2) setActive(2);
    }, [workspaceSetupStep]);

    return (
      <>
        <Navbar />
        <HeaderWrapper secondStepActive={active === 2}>
          <div className="container">
            <Button
              kind="secondary"
              className={`${active === 2 ? 'fade-out g-border' : ' b-border'}`}>
              <Body1
                kind={active === 2 ? 'green300' : 'textDark'}
                weight={active === 2 ? 'semibold' : 'bold'}>
                Create workspace
              </Body1>
            </Button>
            <div
              className={`rule ${active === 2 ? 'fade-out ' : ''}`}
              style={{
                background: active === 2 ? '#47B881' : '#cecede',
              }}></div>
            <Button
              kind="secondary"
              className={`${active === 2 ? ' b-border' : ''}`}
              style={{
                background: '#fafafa',
              }}>
              <Body1
                kind={active === 2 ? 'textDark' : 'textMuted'}
                weight={active === 2 ? 'bold' : 'default'}>
                Select use case
              </Body1>
            </Button>
          </div>
        </HeaderWrapper>
        <AuthFormLayoutWider>
          {slug === SetupWorkspaceSlugs.workspaceSetup && (
            <AuthFormLayout marginTop={'60px'} maxWidthDefault="600px">
              <FlexRowCenter style={{width: '100%'}}>
                {active !== 2 ? (
                  <DescriptionText>
                    <Headline1 align="center">Welcome to Beam 🌟</Headline1>
                    <VerticalSpacer size="16px" />
                    <Body1 kind="textBody" align="center">
                      For us to create your account, please fill in all the
                      information required. It would only take a minute.
                    </Body1>
                  </DescriptionText>
                ) : (
                  <DescriptionText>
                    <Headline1 align="center" style={{fontWeight: 700}}>
                      Customise Your Experience
                    </Headline1>
                    <VerticalSpacer size="16px" />
                    <Body1 kind="textBody" align="center">
                      We've designed a range of powerful features to help you
                      get the most out of Beam. Now it's time to personalise
                      your experience.
                    </Body1>
                  </DescriptionText>
                )}
              </FlexRowCenter>

              <FormComponent />
              <DesktopWrapper>
                <Body1 align="center">
                  <Image alt="grouping" {...image} width="100%" />
                </Body1>
              </DesktopWrapper>
            </AuthFormLayout>
          )}
        </AuthFormLayoutWider>
      </>
    );
  },
);
