import styled from 'styled-components';
import styledMap from 'styled-map';

export const Wrapper = styled.div<{type?: string}>`
  width: 100%;
  height: ${(props: any) => (props.type === 'workspace' ? '100%' : '')};
  display: flex;
  z-index: 0;
`;

export const NavWrapper = styled.nav<{type?: string}>`
  width: 100%;
  display: flex;
  flex-direction: column;

  border-left: ${styledMap('type', {
    workspace: '0px',
    default: 'solid 3px #ededf2',
  })};
  border-right: ${styledMap('type', {
    workspace: 'solid 1px #ededf2',
    default: '0px',
  })};

  padding: ${styledMap('type', {
    workspace: '40px 20px',
    default: '0px',
  })};

  position: relative;
  min-height: 100%;

  height: ${styledMap('type', {
    workspace: '100vh',
    default: '90%',
  })};

  z-index: 1;
`;

export const MobileWrapper = styled.div<{open: boolean}>`
  border: 1px solid #ededf2;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  height: 56px;
  margin-bottom: 24px;
  align-items: center;
  justify-content: space-between;
  position: relative;

  .navWrapper {
    display: flex;
    overflow-y: ${({open}) => (open ? undefined : 'scroll')};
    width: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    align-items: center;
    justify-content: space-between;
    .itemWrapper {
      position: relative;
      width: 100%;
    }
  }

  .leftOverflow {
    padding: 4px;
    z-index: 4;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 25px;
    svg {
      width: 10px;
      height: 6px;
      transform: rotate(90deg);
    }
  }

  .rightOverflow {
    padding: 4px;
    left: 100px;
    height: 100%;
    width: 16px;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 25px;

    z-index: 4;
    svg {
      width: 10px;
      height: 6px;
      transform: rotate(-90deg);
    }
  }
`;
