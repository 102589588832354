import ReactSelect, {
  components,
  OptionsType,
  ValueType,
  ActionMeta,
  Props,
  IndicatorProps,
  OptionProps,
  ControlProps,
} from 'react-select';
import {MagnifyingGlassIcon} from '../../../atoms/icons/magnifying-glass';
import {
  IndicatorWrapper,
  getStyles,
  AvatarWrapper,
  ControlWrapper,
  LeadNote,
} from './styled';
import {HorizontalSpacer} from '../../../atoms/spacer';
import {Avatar} from '../../../atoms/avatar';
import {Body2} from '../../../atoms/typography';
import {forwardRef, Ref, useMemo, useState} from 'react';

interface IOption {
  label: {name: string};
}

const filterUserSelectOptions = (option: {data: IOption}, rawInput: string) => {
  if (rawInput) {
    const label = option.data.label.name.toLowerCase();
    return rawInput
      .toLowerCase()
      .split(' ')
      .every((word) => label.includes(word));
  }
  return true;
};

const {
  DropdownIndicator: SelectDropdownIndicator,
  Option: SelectOption,
  Control: SelectControl,
} = components;

export interface TLabel {
  name: string;
  avatar: {src: string; name: string};
  id: string;
}

export interface TOption {
  value: string;
  label: TLabel;
}

export interface SelectFieldProps extends Props<TOption> {
  leadingNote?: string;
  options: OptionsType<TOption>;
  onChange?: (
    value: ValueType<TOption, false>,
    action: ActionMeta<TOption>,
  ) => void;
}

const DropdownIndicator = (props: IndicatorProps<TOption, false>) => {
  return (
    <SelectDropdownIndicator {...props}>
      <IndicatorWrapper>
        <MagnifyingGlassIcon />
      </IndicatorWrapper>
    </SelectDropdownIndicator>
  );
};

const Control = (props: ControlProps<TOption, false>) => {
  return (
    <ControlWrapper>
      {props.selectProps?.leadingNote && (
        <LeadNote>{props.selectProps.leadingNote}</LeadNote>
      )}
      <SelectControl {...props} />
    </ControlWrapper>
  );
};

const Option = (props: OptionProps<TOption, false>) => {
  return (
    <SelectOption {...props}>
      <AvatarWrapper>
        <Avatar
          src={((props.label as unknown) as TLabel).avatar.src}
          name={((props.label as unknown) as TLabel).avatar.name}
          userId={((props.label as unknown) as TLabel).id}
          size="md"
          tooltip={true}
        />
        <HorizontalSpacer size="8px" />
        <Body2>{((props.label as unknown) as TLabel).name}</Body2>
      </AvatarWrapper>
    </SelectOption>
  );
};

export const UserSelect = forwardRef(
  (
    {
      options,
      onChange,
      value,
      fieldNotFoundPlaceHolder,
      searchPlaceholder,
      ...rest
    }: SelectFieldProps,
    ref: Ref<ReactSelect<TOption>>,
  ) => {
    const styles = useMemo(() => getStyles<TOption, false>(), []);

    const [searchTerm, setSearchTerm] = useState<string>('');

    const filterOption = (
      option: {
        data: IOption;
      },
      rawInput: string,
    ) => {
      setSearchTerm(rawInput);
      return filterUserSelectOptions(option, rawInput);
    };
    return (
      <ReactSelect
        {...rest}
        isClearable={false}
        backspaceRemovesValue={false}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        menuIsOpen
        filterOption={filterOption}
        isSearchable
        value={value}
        noOptionsMessage={
          fieldNotFoundPlaceHolder
            ? () => fieldNotFoundPlaceHolder(searchTerm)
            : () => `No members found`
        }
        tabSelectsValue={false}
        placeholder={searchPlaceholder || 'Search for team members'}
        onChange={onChange}
        ref={ref}
        maxMenuHeight={240}
        components={{
          DropdownIndicator,
          Option,
          Control,
          IndicatorSeparator: null,
        }}
        options={options}
        styles={styles}
        theme={(theme) => ({
          ...theme,
          borderRadius: 7,
          spacing: {
            baseUnit: 6,
            controlHeight: 40,
            menuGutter: 6,
          },
        })}
      />
    );
  },
);
