import {ChevronDownIcon} from '@ui/atoms/icons';
import {Body2, Headline3} from '@ui/atoms/typography';
import {FlexRow, FlexRowSpaceBetween} from '@ui/style/styles';
import {CSSProperties, ReactNode, memo, useState} from 'react';

interface SectionMenuProps {
  title: string;
  children: ReactNode;
  action?: any;
  count?: number;
  style?: CSSProperties;
  disabled?: boolean;
}
export const SectionMenu = memo(
  ({title, children, action, count, disabled, style}: SectionMenuProps) => {
    const [isOpened, setIsOpened] = useState(!disabled);

    return (
      <div className=" border border-[#F6F6F8] rounded-[10px]" style={style}>
        <FlexRowSpaceBetween
          className={`bg-[#F6F6F8] rounded-t-[10px] py-5 px-6 ${
            disabled ? 'cursor-not-allowed' : 'cursor-pointer'
          }`}>
          <FlexRow
            className="gap-2"
            onClick={() => {
              if (!disabled) {
                setIsOpened(!isOpened);
              }
            }}>
            <ChevronDownIcon
              style={{
                width: '12px',
                height: '12px',
                rotate: isOpened && !disabled ? '0deg' : '-90deg',
              }}
              color={disabled ? '#BFBFD4' : isOpened ? '#585adf' : 'black'}
            />
            <Headline3 kind={disabled ? 'textMuted' : 'textDark'}>
              {title}
            </Headline3>
            {count !== undefined && (
              <div className="bg-white py-1 px-2 rounded-[6px] border border-borderLight flex items-center justify-center">
                <Body2 weight="bold" kind="textMedium">
                  {count}
                </Body2>
              </div>
            )}
          </FlexRow>
          {action}
        </FlexRowSpaceBetween>

        {isOpened && !disabled && (
          <div className="rounded-lg border-t bg-white border-t-[#EDEDF2]">
            {children}
          </div>
        )}
      </div>
    );
  },
);
