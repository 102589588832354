import {useState, useEffect, useMemo, useCallback} from 'react';
import {TKeyResult} from './interface';
import {FieldError, DeepMap} from 'react-hook-form';

import {IntegrationsController} from '../pages/dashboard/admin/admin-components/admin-integrations/components/app.controller';
import {useStoreContext} from '../store/store-context';

export const useHubspotHook = (
  value: TKeyResult,
  setValue: (value: TKeyResult) => void,
  error?: (DeepMap<TKeyResult, FieldError> | undefined)[],
) => {
  const [expand, setExpand] = useState(
    value?.source?.app !== '' && typeof value?.source?.app === 'string'
      ? true
      : false,
  );
  const [showErrors, setErrors] = useState(true);

  const [reports, setReports] = useState({
    stage: [] as any,
    owner: [] as any,
  });

  const [loading, setLoading] = useState(false);

  const {
    goalsState: {apps},
  } = useStoreContext();

  const getAppReport = useCallback(async () => {
    if (value?.source?.app !== '' && typeof value?.source?.app === 'string') {
      setLoading(true);
      const controller = new IntegrationsController();

      const res = await controller.getAppReport(value?.source?.app ?? '');

      const getStagesData = res.stagesResult.map((result: any) => {
        return {
          name: result.pipeline,
          id: result.pipelineId,
          data: result.stages.map((stage: any) => {
            return {
              ...stage,
              label: `${stage.type === 'count' ? 'Total' : 'Total Amount'} ${
                !stage.label.includes('(') ? `(${stage.label})` : stage.label
              }`,
            };
          }),
        };
      });

      const getOwnersData = res.ownersResult.map((result: any) => {
        return {
          name: result.pipeline,
          id: result.pipelineId,
          data: result.owners.map((owner: any) => {
            return {
              ...owner,
              label: `${owner.type === 'count' ? 'Total' : 'Total Amount'} ${
                !owner.stage.includes('(') ? `(${owner.stage})` : owner.stage
              } - ${owner.owner}`,
            };
          }),
        };
      });

      res &&
        setReports({
          stage: getStagesData,
          owner: getOwnersData,
        });
      const owner = res.ownersResult?.map(
        (x: any) =>
          x?.owners?.filter(
            (report: any) => report.keyId === value?.source?.keyId,
          )?.[0],
      )?.[0];

      const stage = res.stagesResult?.map(
        (x: any) =>
          x?.stages?.filter(
            (report: any) => report.keyId === value?.source?.keyId,
          )?.[0],
      )?.[0];

      res &&
        (owner || stage) &&
        value?.source?.type === undefined &&
        setValue({
          ...value,
          source: {
            keyId: value?.source?.keyId,
            type: owner ? 'owner' : stage ? 'stage' : '',
            value: 0,
            app: 'Hubspot',
          },
        });

      setLoading(false);
    }
  }, [setValue, value]);

  useEffect(() => {
    getAppReport();
  }, [getAppReport]);
  const [save, setSave] = useState(false);

  const options = [
    {
      value: 'owner',
      label: 'Owner',
    },
    {
      value: 'stage',
      label: 'Stage',
    },
  ];
  const errors = useMemo(() => {
    return !showErrors ? null : error;
  }, [error, showErrors]);

  return {
    apps,
    reports,
    errors,
    options,
    setExpand,
    loading,
    setErrors,
    save,
    setSave,
    expand,
  };
};
