import {useState} from 'react';
interface IValues {
  value: string;
  id: string;
  templates: any[];
}

interface ICompetence {
  competence: string;
  id: string;
  templates: any[];
  useObjectives: boolean;
}

const useCreateTemplateHook = () => {
  const [selectedValues, setSelectedValues] = useState<IValues[]>([]);
  const [selectedCompetencies, setSelectedCompetencies] = useState<
    ICompetence[]
  >([]);
  const handleUpdateValues = (values: IValues) => {
    setSelectedValues((prev) => [...prev, values]);
  };

  const updateSpecificCompetence = (id: string, field: string, value: any) => {
    setSelectedCompetencies((prev) =>
      prev.map((competence) =>
        competence.id === id ? {...competence, [field]: value} : competence,
      ),
    );
  };

  const deleteSpecificValue = (id: string) => {
    setSelectedValues((prev) =>
      prev.filter((competence) => competence.id !== id),
    );
  };

  const deleteSpecificCompetence = (id: string) => {
    setSelectedCompetencies((prev) =>
      prev.filter((competence) => competence.id !== id),
    );
  };

  const handleUpdateCompetencies = (values: ICompetence) => {
    setSelectedCompetencies((prev) => [...prev, values]);
  };

  return {
    selectedValues,
    handleUpdateValues,
    selectedCompetencies,
    deleteSpecificCompetence,
    handleUpdateCompetencies,
    deleteSpecificValue,
    updateSpecificCompetence,
  };
};

export default useCreateTemplateHook;
