import {observer} from 'mobx-react-lite';
import {IntegrationBody, Wrapper} from '../../components/app-body';
import {Button} from '@ui/atoms/button';
import {SuccessIcon} from '@ui/atoms/svg/success-icon';
import styled from 'styled-components';
import {useSlackHook} from './slack-hook';
import {SlackIcon} from '@ui/atoms/icons/apps/slack';
import {cfv} from '@utils/framework';
import {Dropdown} from '@ui/molecules/dropdown';
import {Body1, Headline2} from '@ui/atoms/typography';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {CancelIcon} from '@ui/atoms/icons';
import {SetStateAction, Dispatch} from 'react';
import {BackAction} from '@ui/molecules/back-action';
import {Modal} from '@ui/molecules/modal';
import {ModalCard} from '@ui/layouts/modal-card';
import {useBreakpoints} from '@utils/use-breakpoints';

const StatusButton = styled(Button)<{_width?: string}>`
  width: ${({_width}) => (_width ? _width : '118px')};
  height: 38px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SlackDetails = {
  image: <SlackIcon />,
  title: 'Slack',
  description: `Easily receive notifications on your channels, write comments and create ${
    cfv(undefined, true).g_oals
  } from your messages with Slack integration`,
};

const slackurl: any = process.env.REACT_APP_SLACK_URL;

interface IProps {
  setActiveIntegrations: Dispatch<SetStateAction<any>>;
  data: {
    app: string;
    id: string;
    lastSync: string | null;
    status: string;
    error?: any;
  };
}

export const Slack = observer(({data, setActiveIntegrations}: IProps) => {
  const {status} = data;
  const {
    handleDisconnectSlackConnection,
    disconnectLoading,
    openModal,
    setOpenModal,
  } = useSlackHook();

  const CustomButton = () => {
    switch (status) {
      case 'connected':
        return (
          <StatusButton kind="secondary" _width={'136px'}>
            <span style={{margin: '0px 5px 0px 0px'}}>
              {' '}
              <SuccessIcon />
            </span>{' '}
            Connected
          </StatusButton>
        );
      default:
        return (
          <StatusButton as={'a'} href={`${slackurl}`} kind="secondary">
            Connect
          </StatusButton>
        );
    }
  };

  const SlackDropdown = () => {
    return (
      <Dropdown
        dropdownItemStyle={{whiteSpace: 'nowrap'}}
        menu={[
          {
            content: `Disconnect`,
            id: 'disconnect',
          },
        ]}
        onClick={() => setOpenModal(true)}
      />
    );
  };

  return (
    <>
      <Wrapper state={'default'}>
        <IntegrationBody
          {...SlackDetails}
          customButton={CustomButton}
          dropDown={status === 'connected' ? <SlackDropdown /> : undefined}
        />
      </Wrapper>

      {openModal && (
        <DisconnectModal
          cancelAction={() => setOpenModal(false)}
          loading={disconnectLoading}
          onClick={() =>
            handleDisconnectSlackConnection('Slack', setActiveIntegrations)
          }
        />
      )}
    </>
  );
});

const DisconnectModal = ({
  cancelAction,
  loading,
  onClick,
}: {
  cancelAction: () => void;
  loading: boolean;
  onClick: () => void;
}) => {
  const screenSize = useBreakpoints();
  return (
    <Modal open={true} onClose={() => {}}>
      <div
        style={{
          maxWidth: screenSize.isXs ? '90%' : '550px',
          margin: 'auto',
        }}>
        <div style={{width: screenSize.isXs ? '100%' : 'max-content'}}>
          {screenSize.isXs ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
              }}>
              <Button
                kind="secondary"
                onClick={cancelAction}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  overflow: 'hidden',
                  justifyContent: 'center',
                  height: '38px',
                }}>
                <CancelIcon />
                <span style={{marginLeft: '5px'}}>Cancel</span>
              </Button>
            </div>
          ) : (
            <BackAction
              width="md"
              icon={<CancelIcon />}
              onClick={cancelAction}
              title="Close"
            />
          )}
        </div>
        <VerticalSpacer size="16px" />
        <ModalCard align="center" title={''}>
          <>
            <Headline2 align="center">Disconnect Slack integration? </Headline2>

            <VerticalSpacer size="20px" />

            <Body1 align="center" kind="textBody">
              You’re about to disconnect this integration from your workspace.
            </Body1>
            <VerticalSpacer size="20px" />
            <span>
              <Button
                kind="primary"
                width="full"
                isLoading={loading}
                type="button"
                onClick={onClick}
                data-form-action={true}>
                Confirm
              </Button>
            </span>
            <VerticalSpacer size="16px" />
          </>
        </ModalCard>
      </div>
    </Modal>
  );
};
