import styled from 'styled-components';
import {getFromBetween} from '@utils/get-between';
import {useNavigate} from 'react-router';
import {useTaskHook} from './task-hook';
import {useStoreContext} from '@store/store-context';
import {cfv} from '@utils/framework';
import {observer} from 'mobx-react-lite';
import {Body2} from '@ui/atoms/typography';
import {getColorFromTheme} from '@ui/ui-utils';
import {DropdownVertical} from '@ui/molecules/dropdown-vertical';
import {MoreVerticalIcon} from '@ui/atoms/icons';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {useState} from 'react';
import {BeamLogo} from '@ui/atoms/icons/beam-logo';

const TaskWrapper = styled.div`
  cursor: pointer;
  min-height: 88px;
  display: flex;
  width: 100%;
  padding: 24px 16px 24px 24px;
  justify-content: space-between;
  align-items: center;
  .text {
    @media (max-width: 1400px) {
      width: 100%;
    }
  }
  :hover {
    background-color: ${getColorFromTheme('backgroundDark')};
  }
`;
export const Task = observer(
  ({
    description,
    users,
    onClick,
  }: {
    title: string;
    description: string;
    buttonText?: string;
    onClick?: () => void;
    users?: any[];
  }) => {
    const {
      taskStore: {updateCount, taskListCount},
    } = useStoreContext();
    const handleUsersName = (id: string) => {
      const user = users?.find((user) => user.id === id);

      return user?.firstName
        ? user.firstName + ' ' + user.lastName
        : user?.email
        ? user.email
        : 'Deleted user';
    };

    const handleDescription = (text: string) => {
      let _text = text;
      if (!text) return '';
      // eslint-disable-next-line no-useless-escape
      const getTitle = getFromBetween.get(text, `\"`, `\"`);
      const getUsers = getFromBetween.get(text, `_(`, `)_`);

      getUsers.forEach((user: string) => {
        _text = _text.replace(
          // eslint-disable-next-line no-useless-escape
          `_(${user})_`,
          `<span style="font-weight: 500">${handleUsersName(user)}</span>`,
        );
      });

      getTitle.forEach((title: string) => {
        // eslint-disable-next-line no-useless-escape
        _text = _text.replace(
          // eslint-disable-next-line no-useless-escape
          `\"${title}\"`,
          `<span style="font-weight: 500">${title}</span>`,
        );
      });
      _text = _text.replace(
        'past due',
        `<span style="background: #FEF6F6; border-radius: 6px; color: #910C0C; padding: 2px 6px; height: 26px; display: inline-flex; align-items: center; justify-content: center; width: 69px">past due</span>`,
      );
      _text = _text.replace('objective', cfv(undefined, true).g_oal);
      return _text;
    };

    const notifications = localStorage.getItem('dismissedNotifications');

    const parsedNotifications = notifications
      ? JSON.parse(notifications)
      : null;

    const [dismissedNotifications, setDismissedNotification] = useState(
      parsedNotifications?.notifications || [],
    );

    if (dismissedNotifications.includes(description)) return null;

    return (
      <TaskWrapper>
        <div>
          <div className="text flex items-center gap-4">
            <div>
              <div className="w-[40px] h-[40px] rounded-full bg-[#ededf2] flex items-center justify-center">
                <BeamLogo />
              </div>
            </div>

            <div className="w-[100%]" onClick={onClick}>
              <Body2
                kind="textDark"
                dangerouslySetInnerHTML={{
                  __html: handleDescription(description),
                }}
              />
              <Body2 kind="textBody" size="10px" style={{fontSize: '10px'}}>
                Today
              </Body2>
            </div>
            <div className="relative w-[50px] h-[35px] pt-1 hover:bg-[#ededf2] rounded-full flex items-center justify-center">
              <DropdownVertical
                customIcon={<MoreVerticalIcon />}
                collapseOnClick
                dropdownWrapperStyle={{
                  right: '-83%',
                }}
                menu={(handleClose: any) => (
                  <div>
                    <DropdownItem
                      type="button"
                      onClick={() => {
                        const addDismissedNotification = parsedNotifications
                          ? [...parsedNotifications.notifications, description]
                          : [description];

                        localStorage.setItem(
                          'dismissedNotifications',
                          JSON.stringify({
                            notifications: addDismissedNotification,
                          }),
                        );

                        setDismissedNotification(addDismissedNotification);
                        updateCount(taskListCount - 1);
                        handleClose();
                      }}>
                      Dismiss
                    </DropdownItem>
                  </div>
                )}
              />
            </div>
          </div>
        </div>
      </TaskWrapper>
    );
  },
);

type ITaskList = {
  tasks: {
    ccReviewNominatePeers: any[];
    ccReviewPeerNominations: any[];
    checkinFeedback: any[];
    comment: any[];
    selfReviews: any[];
    reviewPeers: any[];
    mentions: any[];
    managerReviews?: {
      title: string;
      description: string;
      reviewCycleId: string;
    }[];
    feedbackRequests?: {
      description: string;
      title: string;
    };
    upgradeAccount?: {
      description: string;
      title: string;
    };
    peerReviews?: {
      title: string;

      description: string;

      reviewCycleId: string;
    }[];

    ccReviews?: {
      title: string;

      description: string;

      reviewCycleId: string;
    }[];
    checkin?: {
      title: string;

      description: string;
    };
    goals: any[];
    payment?: {
      title: string;
      description: string;
    };
  };
};

export const TaskList = observer(({tasks}: ITaskList) => {
  const {
    ccReviewNominatePeers,
    ccReviewPeerNominations,
    checkinFeedback,
    ccReviews,
    peerReviews,
    feedbackRequests,
    upgradeAccount,
    managerReviews,
    comment,
    mentions,
    reviewPeers,
    goals,
    checkin,
    payment,
  } = tasks;

  const navigate = useNavigate();
  const {handleCheckinFeedback} = useTaskHook();

  const {
    storeDashboardRoutes: {
      activatePeerReviewTab,
      activateCycleTab,
      activateCheckinsTabs,
      activateObjTabs,
      activateDashboardTab,
      activateInsightTabs,
    },
    usersStore: {users},
    goalsState: {setInsightGoal},
  } = useStoreContext();

  return (
    <>
      {upgradeAccount && (
        <Task
          title={upgradeAccount.title}
          buttonText="Upgrade account"
          description={upgradeAccount.description}
          onClick={() => {
            navigate('/workspace-settings/billing');
          }}
        />
      )}

      {checkin && checkin?.title.length > 0 && (
        <Task
          title={checkin.title}
          description={checkin.description}
          onClick={() => {
            navigate('/checkins');
            activateDashboardTab('checkins');
            activateCheckinsTabs('my_checkins');
          }}
        />
      )}
      {feedbackRequests && feedbackRequests?.title && (
        <Task
          title={feedbackRequests.title}
          description={feedbackRequests.description}
          onClick={() => {
            navigate('/feedback/direct-reports');
            activateDashboardTab('feedback');
          }}
        />
      )}

      {comment &&
        comment.length > 0 &&
        comment.map((com: any) => (
          <Task
            title={com.title}
            description={com.description}
            onClick={() => {
              navigate(`/objectives?goal=${com.id}&insight=true`);
              setInsightGoal({
                insight: true,
                goal: com.id,
              });
              activateInsightTabs('comment');
            }}
          />
        ))}

      {mentions &&
        mentions.length > 0 &&
        mentions.map((mention: any) => (
          <Task
            title={mention.title}
            description={mention.description}
            users={users}
            onClick={() => {
              navigate(`/objectives?goal=${mention.id}&insight=true`);
              setInsightGoal({
                insight: true,
                goal: mention.id,
              });
              activateInsightTabs(
                mention.type === 'comment' ? 'comment' : 'tasks',
              );
            }}
          />
        ))}

      {reviewPeers &&
        reviewPeers.map((peer) => (
          <Task
            title={peer.title}
            description={peer.description}
            onClick={() => {
              navigate(`/you/view-cycle/peer-review/${peer.reviewCycleId}`);
              activatePeerReviewTab('request');
            }}
          />
        ))}
      {managerReviews &&
        managerReviews.map((manager) => (
          <Task
            title={manager.title}
            description={manager.description}
            onClick={() => {
              navigate(`/company/view-cycle/${manager.reviewCycleId}`);
              activateCycleTab('overview');
            }}
          />
        ))}

      {peerReviews &&
        peerReviews.map((peer) => (
          <Task
            title={peer.title}
            description={peer.description}
            onClick={() => {
              navigate(`/you/view-cycle/peer-review/${peer.reviewCycleId}`);
              activatePeerReviewTab('nomination');
            }}
          />
        ))}

      {ccReviewNominatePeers &&
        ccReviewNominatePeers.map((peer) => (
          <Task
            title={peer.title}
            description={peer.description}
            onClick={() => {
              navigate(`/you/view-cycle/peer-review/${peer.reviewCycleId}`);
              activatePeerReviewTab('nomination');
            }}
          />
        ))}
      {ccReviewPeerNominations &&
        ccReviewPeerNominations.map((nomination) => (
          <Task
            title={nomination.title}
            description={nomination.description}
            onClick={() => {
              navigate(
                `/you/view-cycle/peer-review/${nomination.reviewCycleId}`,
              );
              activatePeerReviewTab('request');
            }}
          />
        ))}
      {checkinFeedback &&
        checkinFeedback.map((checkin) => (
          <Task
            title={checkin.title}
            description={checkin.description}
            onClick={() =>
              handleCheckinFeedback(checkin.weekOfYear, checkin?.year)
            }
          />
        ))}

      {ccReviews &&
        ccReviews.map((review) => (
          <Task
            title={review.title}
            description={review.description}
            onClick={() => navigate(`culture-competence-review/your-reviews`)}
          />
        ))}
      {goals &&
        goals.map((goal) => (
          <Task
            title={goal.title}
            description={goal.description}
            onClick={() => {
              navigate(`/objectives?taskGoalId=${goal?.id}`);
              activateDashboardTab('objective');
              activateObjTabs('objective');
            }}
          />
        ))}
      {payment && payment?.title.length > 0 && (
        <Task
          title={payment.title}
          description={payment.description}
          onClick={() => navigate('/workspace-settings/billing')}
        />
      )}
    </>
  );
});
