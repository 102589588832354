export function ListGoals(goal: any) {
  const keyResultOwner: any = () => {
    if (goal.keyResults?.length > 0) {
      const krs = goal.keyResults.map((item: any) => item.assignee);

      const assigneeGoals = [
        ...Array.from(
          new Map(krs.map((item: any) => [item['id'], item])).values(),
        ),
      ];
      const EachAssignee = assigneeGoals.map((kr: any) => {
        return {
          label: {
            avatar: {
              name: kr.firstName ? `${kr.firstName} ${kr.lastName}` : kr.email,
              src: kr.avatar ? kr.avatar.url : '',
              id: kr.id,
              reviewer: kr.reviewer,
            },
            id: kr.id,
            reviewer: kr?.reviewer,
            src: kr.avatar ? kr.avatar.url : '',
            name: kr.firstName ? `${kr.firstName} ${kr.lastName}` : kr.email,
          },
        };
      });
      const goalOwner = {
        label: {
          avatar: {
            name: goal.assignee.firstName
              ? `${goal.assignee.firstName} ${goal.assignee.lastName}`
              : goal.assignee?.email,
            active: true,
            src: goal.assignee.avatar ? goal.assignee.avatar.url : '',
            id: goal.assignee.id,
            reviewer: goal.assignee.reviewer,
          },
          id: goal.assignee.id,
          reviewer: goal.assignee?.reviewer,
          active: true,

          src: goal.assignee.avatar ? goal.assignee.avatar.url : '',
          name: goal.assignee.firstName
            ? `${goal.assignee.firstName} ${goal.assignee.lastName}`
            : goal.assignee.email,
        },
        value: 'string',
      };

      let goalandKeyResultsAssignees = EachAssignee.find(
        (assignee) => assignee.label.avatar.id === goal.assignee.id,
      )
        ? [
            goalOwner,
            ...EachAssignee.filter(
              (assignee) => assignee.label.avatar.id !== goalOwner.label.id,
            ),
          ]
        : [goalOwner, ...EachAssignee];

      const ites = goalandKeyResultsAssignees.map((item: any) => item.label);
      const assigneeGoalsKeyResults = [
        ...Array.from(
          new Map(ites.map((item: any) => [item['id'], item])).values(),
        ),
      ];
      return assigneeGoalsKeyResults;
    } else {
      const goalOwner = {
        label: {
          avatar: {
            name: goal.assignee?.firstName
              ? `${goal.assignee?.firstName} ${goal.assignee?.lastName}`
              : goal.assignee.email,
            active: true,
            src: goal.assignee?.avatar ? goal.assignee?.avatar?.url : '',
            id: goal.assignee?.id,
            reviewer: goal.assignee?.reviewer,
          },
          id: goal.assignee.id,
          reviewer: goal.assignee?.reviewer,
          active: true,

          src: goal.assignee.avatar ? goal.assignee.avatar.url : '',
          name: goal.assignee.firstName
            ? `${goal.assignee.firstName} ${goal.assignee.lastName}`
            : goal.assignee.email,
        },
      };
      return [goalOwner.label];
    }
  };
  return keyResultOwner();
}
