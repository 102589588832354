import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {getGroupTypeAndGroup} from '@utils/get-group-grouptype';
import dayjs from 'dayjs';
import {useStoreContext} from '@store/store-context';
import {GoalsRequestImpl} from '@api/goals-api/goals-request';
import {FeedbackRequestImpl} from '../feedback-requests';
import {FeedbackController} from '../feedback-controller';
import {useNavigate} from 'react-router';
import {useWorkspaceUseCase} from '@hooks/workspace-usecase';
import {GoalsController} from '@api/goals-api/goals-controller';
import {Resolver} from 'react-hook-form';
import {CreateFeedbackValidator} from '../create-feedback/create-feedback-validator';
import {activateNotification} from '@ui/molecules/notification/activate-notification';
import {beamMasteryTasks} from '@utils/firebase-request';

const transformOptions = (goal: any, groups: any[], groupType: any[]) => {
  return {
    label: {
      goalType:
        goal.goalType === 'group'
          ? `${
              getGroupTypeAndGroup(groups, groupType, goal.group)?.groupType
            } - ${
              getGroupTypeAndGroup(groups, groupType, goal.group)?.groupName
            }`
          : goal.goalType === 'company'
          ? 'Company-wide'
          : goal.goalType,
      goalName: goal.name,
      group: groups.filter((group) => goal.group.includes(group.id)),

      assigneeName:
        goal.assignee &&
        goal.assignee.hasOwnProperty('firstName') &&
        goal.assignee.hasOwnProperty('lastName')
          ? `${goal.assignee?.firstName} ${goal.assignee?.lastName}`
          : goal.assignee?.email,
      assigneeAvatar: {
        src:
          goal.assignee?.avatar && goal.assignee?.avatar.url
            ? goal.assignee?.avatar.url
            : '',
        name:
          goal.assignee &&
          goal.assignee.hasOwnProperty('firstName') &&
          goal.assignee.hasOwnProperty('lastName')
            ? `${goal.assignee?.firstName} ${goal.assignee?.lastName}`
            : goal.assignee?.email,
        id: goal.assignee?.id,
      },
      date: dayjs(goal.createdAt).format('DD/MM/YYYY'),
      id: goal.id,
    },
    value: goal.id,
  };
};
interface RequestFeedbackFormData {
  user: string;
  feedbackFor: string;
  subjectName: string;
  subjectId: string;
}

const useRequestFeedback = () => {
  const {usersStore} = useStoreContext();
  const {users, deactivatedUsers} = usersStore;
  const navigate = useNavigate();
  const {isPerformanceActive} = useWorkspaceUseCase();

  const {
    handleSubmit,
    errors,
    formState,
    setValue,
    reset,
    trigger,
    control,
    watch,
  } = useForm<RequestFeedbackFormData>({
    resolver: yupResolver(CreateFeedbackValidator.requestFeedback) as Resolver<
      RequestFeedbackFormData,
      object
    >,
    mode: 'all',
    defaultValues: {
      user: '',
      feedbackFor: '',
      subjectName: '',
      subjectId: '',
    },
  });

  useEffect(() => {
    if (!isPerformanceActive && !watch().feedbackFor) {
      reset({
        feedbackFor: 'custom',
      });
    }
  }, [isPerformanceActive, reset, watch]);

  const {
    groupStore: {groups},
    groupTypeStore: {groupType},
    authStore: {auth},
  } = useStoreContext();

  const initGoals = useCallback(
    async (pageNumber?: number, filter?: string) => {
      const request = new GoalsRequestImpl();
      const controller = new GoalsController(request);
      const response = await controller.fetchGoals(pageNumber, filter);

      const goals = response.goals.map((goal: any) =>
        transformOptions(goal, groups, groupType),
      );
      return goals;
    },
    [groups, groupType],
  );

  const isSubmitting = useMemo(() => {
    return formState.isSubmitting;
  }, [formState]);

  const handleFormValueChange = useCallback(
    async (value: string | null, name: string) => {
      setValue(name as any, value);
      formState.submitCount > 0 && (await trigger());
    },
    [setValue, trigger, formState.submitCount],
  );
  const [isDisabled, setIsDisabled] = useState(true);

  const {user, feedbackFor, subjectName} = watch();

  useMemo(() => {
    user && feedbackFor && subjectName
      ? setIsDisabled(false)
      : setIsDisabled(true);
  }, [user, feedbackFor, subjectName]);

  const feedbackForOptions = [
    {
      label: 'Goal',
      value: 'goal',
    },
    {
      label: 'Custom',
      value: 'custom',
    },
  ];
  const feedbackRequest = useMemo(() => new FeedbackRequestImpl(), []);
  const feedbackController = useMemo(
    () => new FeedbackController(feedbackRequest),
    [feedbackRequest],
  );

  const onSubmit = async (data: RequestFeedbackFormData) => {
    try {
      const response = await feedbackController.postRequestFeedback(
        {
          subjectType: data.feedbackFor,
          subjectName: data.subjectName,
          subjectId: data.subjectId,
        },
        data.user,
      );

      if (auth.user.role === 'user' && !auth.user.isReviewer) {
        response && beamMasteryTasks('request_feedback', true);
      }
      response &&
        activateNotification({
          content: 'Your request has been submitted.',
          title: 'Success',
          kind: 'success',
        });
      response && navigate(-1);
    } catch (error) {}
  };

  return {
    handleFormValueChange,
    handleSubmit,
    users: users
      .filter((user) => user.id !== auth.user.id)
      .map((member: any) => {
        return {
          label: {
            avatar: {
              name:
                member && member.firstName
                  ? member.firstName + ' ' + member.lastName
                  : member.email + ' (pending invitation)',
              src: member.avatar && member.avatar.url ? member.avatar.url : '',
            },
            id: member.id,
            name:
              member && member.firstName
                ? member.firstName + ' ' + member.lastName
                : member.email + ' (pending invitation)',
          },
          value: member.id,
        };
      })
      .filter((user: any) => {
        const arrayOfDeactivatedUserIds = deactivatedUsers.map(
          (user: any) => user.id,
        );
        return arrayOfDeactivatedUserIds.includes(user.value) ? false : true;
      }),
    errors,
    control,
    onSubmit,
    isDisabled,
    initGoals,
    feedbackFor,
    feedbackForOptions,
    isSubmitting,
  };
};

export default useRequestFeedback;
