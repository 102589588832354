import {action} from 'mobx';
import {activateNotification} from '../../../../../../ui/molecules/notification/activate-notification';
import {groupTypeFormData} from './admin-group-type-interface';
import {groupTypeRequest} from './admin-group-type-request';
import {groupTypeStore} from '../../../../../../store/stores/group-type-store';
import {parseErrorContent} from '../../../../../../utils/error-handling';

export class groupTypeController {
  constructor(private readonly request: groupTypeRequest) {}

  @action
  async newCreateGroupType(data: groupTypeFormData) {
    try {
      await this.request.createGroupType(data);
      activateNotification({
        title: 'Success',
        content: 'Group type has been created',
        kind: 'success',
      });
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
    }
  }

  @action
  async newEditGroupType(data: groupTypeFormData, id?: string) {
    try {
      await this.request.editGroupType(data, id);
      activateNotification({
        title: 'Success',
        content: 'Group type updated',
        kind: 'success',
      });
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
    }
  }

  @action
  async newfetchGroupType() {
    try {
      const response = await this.request.fetchGroupType();
      groupTypeStore.setGroupTypeInStore(response);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
    }
  }

  @action
  async newDeleteGroupType(id?: string) {
    try {
      await this.request.deleteGroupType(id);
      activateNotification({
        title: 'Success',
        content: 'Group type deleted successfully',
        kind: 'success',
      });
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
    }
  }
}
