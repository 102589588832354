import {memo} from 'react';

export const CopyLinkIcon = memo(() => {
  return (
    <svg
      width="24"
      height="18"
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.5 13.5H13.8V11.7H16.5C17.2161 11.7 17.9028 11.4155 18.4092 10.9092C18.9155 10.4028 19.2 9.71608 19.2 9C19.2 8.28392 18.9155 7.59716 18.4092 7.09081C17.9028 6.58446 17.2161 6.3 16.5 6.3H13.8V4.5H16.5C17.6935 4.5 18.8381 4.97411 19.682 5.81802C20.5259 6.66193 21 7.80653 21 9C21 10.1935 20.5259 11.3381 19.682 12.182C18.8381 13.0259 17.6935 13.5 16.5 13.5ZM10.2 13.5H7.5C6.30653 13.5 5.16193 13.0259 4.31802 12.182C3.47411 11.3381 3 10.1935 3 9C3 7.80653 3.47411 6.66193 4.31802 5.81802C5.16193 4.97411 6.30653 4.5 7.5 4.5H10.2V6.3H7.5C6.78392 6.3 6.09716 6.58446 5.59081 7.09081C5.08446 7.59716 4.8 8.28392 4.8 9C4.8 9.71608 5.08446 10.4028 5.59081 10.9092C6.09716 11.4155 6.78392 11.7 7.5 11.7H10.2V13.5ZM16.5 9.9H7.5V8.1H16.5V9.9Z"
        fill="#585ADF"
      />
    </svg>
  );
});
