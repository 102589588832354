import styled, {DefaultTheme, StyledComponentProps} from 'styled-components';
import styledMap from 'styled-map';
import {theme} from '../../style';
import {FC} from 'react';

export interface RawLabelProps {
  state?: 'default' | 'error';
  events?: 'disabled' | 'all';
  weight?: 'bold' | 'default';
}

export type LabelProps = StyledComponentProps<
  'label',
  DefaultTheme,
  RawLabelProps,
  never
>;

const color = styledMap('state', {
  error: theme.colors.red400,
  default: theme.colors.textDark,
});

const pointerEvents = styledMap('events', {
  disabled: 'none',
  all: 'all',
  default: 'initial',
});

export const Label = styled.label<RawLabelProps>`
  color: ${color};
  pointer-events: ${pointerEvents};
  text-shadow: none;
  font-size: 14px;
  font-weight: ${styledMap('weight', {
    default: 500,
    bold: 600,
  })};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: normal;
  .required {
    color: #d52a2a;
  }
`;

interface RequiredLabelProps extends RawLabelProps {
  required?: boolean;
  children: string;
}

export const RequiredLabel: FC<RequiredLabelProps> = ({children}) => {
  return (
    <Label weight="bold">
      {children} <span className="required">*</span>
    </Label>
  );
};
