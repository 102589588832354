import styled from 'styled-components';
import {getColorFromTheme} from '../../../ui-utils';

export const Wrapper = styled.nav`
  display: block;
  position: absolute;
  left: 0;
  width: 320px;
  top: 120%;
  border-radius: 10px;
  border: solid 1px ${getColorFromTheme('borderLight')};
  background-color: ${getColorFromTheme('white')};
  box-shadow: ${({theme}) => theme.elevation.level1};
  z-index: 3;
`;

export const Footer = styled.footer`
  width: 100%;
  border-top: solid 1px ${getColorFromTheme('borderLight')};
  background-color: ${getColorFromTheme('white')};
  padding-top: 16px;
  padding-bottom: 24px;
  border-radius: 0 0 10px 10px;
`;

export const Container = styled.div`
  width: 100%;
  padding: 0 0 16px;
  min-height: 120px;
`;

export const LoaderWrapper = styled.div`
  align-items: center;
  justify-content: center;
  min-height: 120px;
  display: flex;
`;

export const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: 0 12px;
  grid-row-gap: 4px;
`;

export const ContentContainer = styled.div`
  padding: 0 20px;
`;

export const CurrentWorkspaceWrapper = styled.div`
  padding: 26px 24px;
  background-color: ${getColorFromTheme('backgroundLight')};
  border-bottom: 1px solid ${getColorFromTheme('borderLight')};
  border-radius: 10px 10px 0 0;

  position: relative;
  &::after {
    position: absolute;
    height: 100%;
    top: 0;
    bottom: 0;
    content: '';
    z-index: 3;
    display: block;
    width: 60px;
  }

  &::after {
    right: 0;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      ${getColorFromTheme('backgroundLight')},
      ${getColorFromTheme('backgroundLight')}
    );
  }
`;
