import {useState} from 'react';
import {ValueProps} from '../create-review-interface';
import {useLocation} from 'react-router';
import {useStoreContext} from '@store/store-context';
import {CcRequestImpl} from '@api/cc-api/cc-request';
import {useMemo} from 'react';
import {useQuery} from 'react-query';
import {CcController} from '@api/cc-api/cc-controller';

type StateType = {
  data?: string;
};

export const usePreviewCycleHook = () => {
  const {state} = useLocation() as {state: StateType};

  const [previewData] = useState<ValueProps>(
    state?.data ? JSON.parse(state.data) : null,
  );

  const {
    authStore: {auth},
    groupStore: {groups},
  } = useStoreContext();

  const request = new CcRequestImpl();
  const controller = new CcController(request);

  const {data: existingFrameworks} = useQuery(['cc-review-frameworks'], () =>
    controller.fetchCcFramework(),
  );

  const getSelectedFrameworks = useMemo(
    () =>
      existingFrameworks
        ? existingFrameworks.filter((framework) =>
            previewData?.frameworks.includes(framework.id),
          )
        : [],
    [previewData.frameworks, existingFrameworks],
  );

  const noOfDepartments = () => {
    let count = 0;
    let membersCount = 0;

    getSelectedFrameworks.forEach((framework) => {
      if (framework.allGroups) {
        const allGroupsMembersCount = groups
          .map((group) => group.membersCount)
          .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

        membersCount = allGroupsMembersCount;
        return (count = groups.length);
      }
      // framework.groups((group))
      groups
        .filter((group) => framework.groups.includes(group.id))
        .forEach((group) => {
          membersCount += group.membersCount;
        });

      count += framework.groups.length;
    });

    return {
      count,
      membersCount,
    };
  };

  const totalQuestions = () => {
    const companyValues: string[] =
      auth?.user?.workspace?.settings?.companyValues?.values || [];

    const membersCount = noOfDepartments().membersCount * 2;

    let total = 0;

    companyValues.forEach(() => {
      total += membersCount;
    });
    return total;
  };

  return {previewData, getSelectedFrameworks, noOfDepartments, totalQuestions};
};
