import styled, {css} from 'styled-components';
import {ifProp, switchProp} from 'styled-tools';
import {Body1} from '../typography/body1';
import {getColorFromTheme} from '../../ui-utils';
import {InputState} from '../../interface';

export const Label = styled.span<{disabled?: boolean; borderRadius?: string}>`
  background-color: ${getColorFromTheme('backgroundDark')};
  box-shadow: 0px 0px 0px 1px ${getColorFromTheme('borderLight')};
  height: 100%;
  border-radius: ${({borderRadius}) => borderRadius || '7px 0 0 7px'};
  padding: 12px 8px;
  transition: 200ms ease all;
  flex-shrink: 0;

  ${ifProp(
    'disabled',
    css`
      background-color: ${getColorFromTheme('backgroundLight')};
    `,
  )}

  ${Body1} {
    color: ${getColorFromTheme('textBody')};
    transition: 200ms ease all;

    ${ifProp(
      'disabled',
      css`
        color: ${getColorFromTheme('textMedium')};
      `,
    )}
  }
`;

export const IconWrapper = styled.span<{disabled?: boolean}>`
  flex-shrink: 0;
  height: 100%;
  padding: 12px 8px 12px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  ${ifProp(
    'disabled',
    css`
      background-color: ${getColorFromTheme('backgroundLight')};
    `,
  )}

  svg {
    width: 20px;
    height: 20px;
    stroke: ${getColorFromTheme('textBody')};
  }
`;

export const Input = styled.input`
  appearance: textfield;
  -webkit-appearance: textfield;
  -webkit-tap-highlight-color: transparent;
  -moz-appearance: textfield;
  border-radius: 0 7px 7px 0;
  padding: 5px 8px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  display: inline-block;
  line-height: 1.6;
  letter-spacing: normal;
  color: ${getColorFromTheme('textDark')};
  display: block;
  height: 52px;
  background-color: ${getColorFromTheme('white')};
  transition: 200ms ease all;
  border: none;
  outline: none;
  border-left: solid 1px ${getColorFromTheme('borderLight')};
  width: 100%;
  flex-grow: 1;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: ${getColorFromTheme('textBody')};
  }

  &:disabled {
    cursor: not-allowed;
    border-color: ${getColorFromTheme('borderLight')};
    background-color: ${getColorFromTheme('backgroundLight')};
    color: ${getColorFromTheme('textMedium')};
  }
`;
export const SecondaryInput = styled.input`
  appearance: textfield;
  -webkit-appearance: textfield;
  -webkit-tap-highlight-color: transparent;
  -moz-appearance: textfield;
  border-radius: 7px 0px 7px 7px;
  padding: 5px 8px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  display: inline-block;
  line-height: 1.6;
  letter-spacing: normal;
  color: ${getColorFromTheme('textDark')};
  display: block;
  height: 40px;
  background-color: ${getColorFromTheme('white')};
  transition: 200ms ease all;
  border: none;
  outline: none;
  border-right: solid 1px ${getColorFromTheme('borderLight')};
  width: 100%;
  flex-grow: 1;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: ${getColorFromTheme('textBody')};
  }

  &:disabled {
    cursor: not-allowed;
    border-color: ${getColorFromTheme('borderLight')};

    color: ${getColorFromTheme('textMedium')};
  }
`;

export const Wrapper = styled.label<{disabled?: boolean; state?: InputState}>`
  display: flex;
  flex-direction: row;
  position: relative;
  box-shadow: 0px 0px 0px 1px ${getColorFromTheme('borderLight')};
  border-radius: 7px;
  cursor: pointer;
  height: 52px;
  transition: 200ms ease all;

  &:focus-within {
    outline: 0;
    box-shadow: 0px 0px 0px 1px ${getColorFromTheme('purple300')},
      0 0 0 6px ${getColorFromTheme('purple200')};

    ${Label} {
      box-shadow: 0px 0px 0px 1px ${getColorFromTheme('purple300')};
    }

    ${Input} {
      border-left: solid 1px ${getColorFromTheme('purple300')};
    }

    ${Body1} {
      color: ${getColorFromTheme('textDark')};
    }

    ${ifProp(
      'disabled',
      css`
        box-shadow: 0px 0px 0px 1px ${getColorFromTheme('borderLight')},
          0 0 0 6px ${getColorFromTheme('borderLight')};

        ${Label} {
          box-shadow: 0px 0px 0px 1px ${getColorFromTheme('borderLight')};
        }

        ${Input} {
          border-left: solid 1px ${getColorFromTheme('borderLight')};
        }

        ${Body1} {
          color: ${getColorFromTheme('textBody')};
        }
      `,
    )}
  }

  &:hover:not(:focus-within) {
    box-shadow: 0 0 0 2px ${getColorFromTheme('purple200')};

    ${Label} {
      box-shadow: 0px 0px 0px 1px ${getColorFromTheme('purple200')};
    }

    ${Input} {
      border-left: solid 1px ${getColorFromTheme('purple200')};
    }

    ${ifProp(
      'disabled',
      css`
        box-shadow: 0 0 0 2px ${getColorFromTheme('borderLight')};

        ${Label} {
          box-shadow: 0px 0px 0px 1px ${getColorFromTheme('borderLight')};
        }

        ${Input} {
          border-left: solid 1px ${getColorFromTheme('borderLight')};
        }
      `,
    )}
  }

  ${ifProp(
    'disabled',
    css`
      cursor: not-allowed;
    `,
  )}

  ${switchProp('state', {
    error: css`
      box-shadow: 0px 0px 0px 1px ${getColorFromTheme('red400')};
      ${Body1} {
        color: ${getColorFromTheme('red400')};
      }

      ${Label} {
        box-shadow: 0px 0px 0px 1px ${getColorFromTheme('red400')};
      }

      ${Input} {
        color: ${getColorFromTheme('red400')};

        border-left: 1px solid ${getColorFromTheme('red400')};

        &::placeholder {
          color: ${getColorFromTheme('red400')};
        }
      }

      &:focus-within {
        box-shadow: 0px 0px 0px 1px ${getColorFromTheme('red400')},
          0 0 0 6px ${getColorFromTheme('red200')};

        ${Label} {
          box-shadow: 0px 0px 0px 1px ${getColorFromTheme('red400')};
        }

        ${Input} {
          border-left: solid 1px ${getColorFromTheme('red400')};
        }

        ${Body1} {
          color: ${getColorFromTheme('red400')};
        }
      }

      &:hover:not(:focus-within) {
        box-shadow: 0 0 0 2px ${getColorFromTheme('red400')};

        ${Label} {
          box-shadow: 0px 0px 0px 1px ${getColorFromTheme('red400')};
        }

        ${Input} {
          border-left: solid 1px ${getColorFromTheme('red400')};
        }
      }
    `,
  })}
`;
export const SecondaryWrapper = styled.label<{
  disabled?: boolean;
  state?: InputState;
}>`
  display: flex;
  flex-direction: row;
  position: relative;
  box-shadow: 0px 0px 0px 1px ${getColorFromTheme('borderLight')};
  border-radius: 7px;
  cursor: pointer;
  height: 40px;
  transition: 200ms ease all;

  &:focus-within {
    outline: 0;
    box-shadow: 0px 0px 0px 1px ${getColorFromTheme('purple300')},
      0 0 0 6px ${getColorFromTheme('purple200')};

    ${Label} {
      box-shadow: 0px 0px 0px 1px ${getColorFromTheme('purple300')};
    }

    ${Input} {
      border-left: solid 1px ${getColorFromTheme('purple300')};
    }

    ${Body1} {
      color: ${getColorFromTheme('textDark')};
    }

    ${ifProp(
      'disabled',
      css`
        box-shadow: 0px 0px 0px 1px ${getColorFromTheme('borderLight')},
          0 0 0 6px ${getColorFromTheme('borderLight')};

        ${Label} {
          box-shadow: 0px 0px 0px 1px ${getColorFromTheme('borderLight')};
        }

        ${Input} {
          border-left: solid 1px ${getColorFromTheme('borderLight')};
        }

        ${Body1} {
          color: ${getColorFromTheme('textBody')};
        }
      `,
    )}
  }

  &:hover:not(:focus-within) {
    box-shadow: 0 0 0 2px ${getColorFromTheme('purple200')};

    ${Label} {
      box-shadow: 0px 0px 0px 1px ${getColorFromTheme('purple200')};
    }

    ${Input} {
      border-left: solid 1px ${getColorFromTheme('purple200')};
    }

    ${ifProp(
      'disabled',
      css`
        box-shadow: 0 0 0 2px ${getColorFromTheme('borderLight')};

        ${Label} {
          box-shadow: 0px 0px 0px 1px ${getColorFromTheme('borderLight')};
        }

        ${Input} {
          border-left: solid 1px ${getColorFromTheme('borderLight')};
        }
      `,
    )}
  }

  ${ifProp(
    'disabled',
    css`
      cursor: not-allowed;
    `,
  )}

  ${switchProp('state', {
    error: css`
      box-shadow: 0px 0px 0px 1px ${getColorFromTheme('red400')};
      ${Body1} {
        color: ${getColorFromTheme('red400')};
      }

      ${Label} {
        box-shadow: 0px 0px 0px 1px ${getColorFromTheme('red400')};
      }

      ${Input} {
        color: ${getColorFromTheme('red400')};

        border-left: 1px solid ${getColorFromTheme('red400')};

        &::placeholder {
          color: ${getColorFromTheme('red400')};
        }
      }

      &:focus-within {
        box-shadow: 0px 0px 0px 1px ${getColorFromTheme('red400')},
          0 0 0 6px ${getColorFromTheme('red200')};

        ${Label} {
          box-shadow: 0px 0px 0px 1px ${getColorFromTheme('red400')};
        }

        ${Input} {
          border-left: solid 1px ${getColorFromTheme('red400')};
        }

        ${Body1} {
          color: ${getColorFromTheme('red400')};
        }
      }

      &:hover:not(:focus-within) {
        box-shadow: 0 0 0 2px ${getColorFromTheme('red400')};

        ${Label} {
          box-shadow: 0px 0px 0px 1px ${getColorFromTheme('red400')};
        }

        ${Input} {
          border-left: solid 1px ${getColorFromTheme('red400')};
        }
      }
    `,
  })}
`;
