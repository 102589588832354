import {useReducer, useCallback, useMemo} from 'react';
import {authStore} from '@store/stores/auth-store';
import nextId from 'react-id-generator';

interface Email {
  value?: string[];
  manager?: string;
  group?: any;
  newGroup?: string;
}

function defaultEmails(emails?: any): Map<string, Email> {
  return new Map(
    (emails || []).map((email: any, index: number) => [
      nextId(`email-id-${index + 1}`),
      {
        value: email.value,
        manager: authStore?.auth?.user?.id || email?.manager,
        group: email?.group || [],
      },
    ]),
  );
}

function addNewEmail(
  emails: Map<string, Email>,
  Limit: number = Infinity,
): Map<string, Email> {
  if (emails.size >= Limit) {
    return emails;
  } else {
    return new Map([
      ...Array.from(emails),
      [
        nextId(`email-id-${emails.size + 1}`),
        {
          value: undefined,
          manager: authStore?.auth?.user?.id || undefined,
          group: [],
        },
      ],
    ]);
  }
}

interface IUpdateEmailProps {
  emails: Map<string, Email>;
  key: string;
  manager?: any;
  value?: any;
  group?: any;
}

interface IRemoveKeyProps {
  emails: Map<string, Email>;
  key: string;
}
function updateEmail({
  emails,
  key,
  value,
}: IUpdateEmailProps): Map<string, Email> {
  emails.set(key, {
    ...emails.get(key),
    value,
  });

  return new Map(emails);
}
function removeKey({emails, key}: IRemoveKeyProps): Map<string, Email> {
  emails.delete(key);

  return new Map(emails);
}
function updateGroup({
  emails,
  key,
  group,
}: IUpdateEmailProps): Map<string, Email> {
  emails.set(key, {
    ...emails.get(key),
    group,
  });

  return new Map(emails);
}
function updateNewGroup({
  emails,
  key,
  group,
}: IUpdateEmailProps): Map<string, Email> {
  emails.set(key, {
    ...emails.get(key),
    newGroup: group,
  });

  return new Map(emails);
}

function updateManager({
  emails,
  key,
  manager,
}: IUpdateEmailProps): Map<string, Email> {
  emails.set(key, {
    ...emails.get(key),
    manager,
  });

  return new Map(emails);
}

function Reachedlimit() {
  const reachedLimit =
    authStore.featureLimitStatus?.limits?.seats -
    authStore.featureLimitStatus?.currentStatus?.seats;
  return reachedLimit;
}

interface ReducerState {
  emails: Map<string, Email>;
}

export enum ActionType {
  ADD_EMAIL,
  UPDATE_EMAIL,
  UPDATE_EMAIL_GROUP,
  UPDATE_EMAIL_NEW_GROUP,
  UPDATE_EMAIL_MANAGER,
  CLEAR_FIELDS,
  REMOVE_KEY,
}

type AddNewEmailAction = {type: ActionType.ADD_EMAIL};

type UpdateEmailAction = {
  type: ActionType.UPDATE_EMAIL;
  payload: {value?: string[]; key: string};
};

type UpdateGroupAction = {
  type: ActionType.UPDATE_EMAIL_GROUP;
  payload: {key: string; group?: any};
};

type UpdateNewGroupAction = {
  type: ActionType.UPDATE_EMAIL_NEW_GROUP;
  payload: {key: string; group?: any};
};

type UpdateManagerAction = {
  type: ActionType.UPDATE_EMAIL_MANAGER;
  payload: {key: string; manager?: any};
};

type ClearFieldsAction = {
  type: ActionType.CLEAR_FIELDS;
};
type RemoveKeyAction = {
  type: ActionType.REMOVE_KEY;
  payload: {key: string};
};

type ReducerAction =
  | AddNewEmailAction
  | UpdateEmailAction
  | UpdateGroupAction
  | UpdateNewGroupAction
  | UpdateManagerAction
  | ClearFieldsAction
  | RemoveKeyAction;

const initialState: ReducerState = {
  emails: new Map(),
};

function emailsReducer(
  state: ReducerState,
  action: ReducerAction,
): ReducerState {
  switch (action.type) {
    case ActionType.ADD_EMAIL:
      return {
        emails: addNewEmail(state.emails, Reachedlimit()),
      };
    case ActionType.UPDATE_EMAIL:
      return {
        emails: updateEmail({
          emails: state.emails,
          key: action.payload.key,
          value: action.payload?.value,
        }),
      };
    case ActionType.UPDATE_EMAIL_GROUP:
      return {
        emails: updateGroup({
          emails: state.emails,
          key: action.payload.key,
          group: action.payload?.group,
        }),
      };
    case ActionType.UPDATE_EMAIL_NEW_GROUP:
      return {
        emails: updateNewGroup({
          emails: state.emails,
          key: action.payload.key,
          group: action.payload?.group,
        }),
      };
    case ActionType.UPDATE_EMAIL_MANAGER:
      return {
        emails: updateManager({
          emails: state.emails,
          key: action.payload.key,
          manager: action.payload?.manager,
        }),
      };
    case ActionType.CLEAR_FIELDS:
      return {
        emails: defaultEmails(['']),
      };
    case ActionType.REMOVE_KEY:
      return {
        emails: removeKey({
          emails: state.emails,
          key: action.payload.key,
        }),
      };
    default:
      return state;
  }
}

export function useEmailInvitation(
  emails?: any,
  setValue?: (values: any) => void,
) {
  const [state, dispatch] = useReducer(emailsReducer, {
    ...initialState,
    emails: defaultEmails(emails),
  });

  const updateValue = useCallback(
    (type: string) => {
      if (setValue) {
        if (type === 'clear') {
          setValue(['']);
        }

        const emails = Array.from(state.emails).map(([key, email]) => ({
          ...email,
          email: email.value,
          group: email.group?.map((grp: any) => grp.value),
        }));

        setValue(
          emails.map((item: any) => ({
            email: item.email,
            newGroup: item?.newGroup,

            group: item.group,
            manager: item.manager,
          })),
        );
      }
    },
    [setValue, state.emails],
  );

  const addEmail = useCallback(async () => {
    await dispatch({type: ActionType.ADD_EMAIL});
  }, []);

  const onChangeEmail = useCallback(
    async (value: string[], key: string) => {
      await dispatch({
        type: ActionType.UPDATE_EMAIL,
        payload: {value, key},
      });
      updateValue('email');
    },
    [updateValue],
  );

  const onChangeGroup = useCallback(
    async (data: any, key: string) => {
      await dispatch({
        type: ActionType.UPDATE_EMAIL_GROUP,
        payload: {group: data, key},
      });
      updateValue('group');
    },
    [updateValue],
  );
  const onChangeNewGroup = useCallback(
    async (data: any, key: string) => {
      await dispatch({
        type: ActionType.UPDATE_EMAIL_NEW_GROUP,
        payload: {group: data, key},
      });
      updateValue('newGroup');
    },
    [updateValue],
  );

  const onChangeManager = useCallback(
    async (data: string, key: string) => {
      await dispatch({
        type: ActionType.UPDATE_EMAIL_MANAGER,
        payload: {manager: data, key},
      });
      updateValue('');
    },
    [updateValue],
  );

  const disabled = useMemo(() => {
    return state.emails.size > 6;
  }, [state.emails.size]);

  const clearFields = useCallback(async () => {
    await dispatch({
      type: ActionType.CLEAR_FIELDS,
    });
    updateValue('clear');
  }, [updateValue]);

  const remove = useCallback(
    async (key) => {
      await dispatch({
        type: ActionType.REMOVE_KEY,
        payload: {key},
      });
      updateValue('removeKey');
    },
    [updateValue],
  );

  return {
    emails: state.emails,
    addEmail,
    onChangeEmail,
    onChangeManager,
    onChangeNewGroup,
    onChangeGroup,
    disabled,
    clearFields,
    remove,
  };
}
