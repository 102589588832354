import styled from 'styled-components';
import styledMap from 'styled-map';
import {motion} from 'framer-motion';

export const Wrapper = styled.div`
  width: 100%;
`;

export const Container = styled(motion.div)`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 8px;
  text-decoration: none;
  align-items: center;
`;
export const DesktopWrapper = styled.div`
  display: block;
  @media (max-width: 1360px) {
    display: none;
  }
`;

export const MediumWrapper = styled.div<{kind?: string}>`
  display: ${({kind}) => (kind === 'youcheckins' ? 'block' : 'none')};
  @media (min-width: 581px) and (max-width: 1360px) {
    display: block;
  }
  @media (max-width: 500px) {
    display: none;
  }
`;

export const StyledContainer = styled(Container)<{
  tabKind: string | undefined;
  feature?: string;
  isUpdate?: boolean;
}>`
  grid-template-columns: ${({isUpdate}) =>
    isUpdate
      ? styledMap('tabKind', {
          team: '1fr 1fr',
          companyReportPDF: '1fr 1fr',
          youcheckins: '25% auto',
          default: 'max-content auto',
        })
      : styledMap('tabKind', {
          team: '1fr 1fr',
          companyReportPDF: '1fr 1fr',
          youcheckins: '30% auto',
          default: 'max-content auto',
        })};
    @media (min-width: 1360px) and (max-width: 1450px) {
     grid-template-columns: ${({isUpdate}) =>
       isUpdate
         ? styledMap('tabKind', {
             team: '1fr 1fr',
             companyReportPDF: '1fr 1fr',
             youcheckins: '25% auto',
             default: 'max-content auto',
           })
         : styledMap('tabKind', {
             team: '1fr 1fr',
             companyReportPDF: '1fr 1fr',
             youcheckins: '25% auto',
             default: 'max-content auto',
           })};
  }
  }
  @media (min-width: 1283px) and (max-width: 1359px) {
     grid-template-columns: ${({isUpdate}) =>
       isUpdate
         ? styledMap('tabKind', {
             team: '1fr 1fr',
             companyReportPDF: '1fr 1fr',
             youcheckins: '22% auto',
             default: 'max-content auto',
           })
         : styledMap('tabKind', {
             team: '1fr 1fr',
             companyReportPDF: '1fr 1fr',
             youcheckins: '22% auto',
             default: 'max-content auto',
           })};
  }
  @media (min-width: 1060px) and (max-width: 1282px) {
     grid-template-columns: ${({isUpdate}) =>
       isUpdate
         ? styledMap('tabKind', {
             team: '1fr 1fr',
             companyReportPDF: '1fr 1fr',
             youcheckins: '8% auto',
             default: 'max-content auto',
           })
         : styledMap('tabKind', {
             team: '1fr 1fr',
             companyReportPDF: '1fr 1fr',
             youcheckins: '22% auto',
             default: 'max-content auto',
           })};
  }
  @media (min-width: 1029px) and (max-width: 1059px) {
    grid-template-columns: ${({isUpdate}) =>
      isUpdate
        ? styledMap('tabKind', {
            team: '1fr 1fr',
            companyReportPDF: '1fr 1fr',
            youcheckins: '8% auto',
            default: 'max-content auto',
          })
        : styledMap('tabKind', {
            team: '1fr 1fr',
            companyReportPDF: '1fr 1fr',
            youcheckins: '21% auto',
            default: 'max-content auto',
          })};
  }
    @media (min-width: 991px) and (max-width: 1028px) {

      grid-template-columns: ${({isUpdate}) =>
        isUpdate
          ? styledMap('tabKind', {
              team: '1fr 1fr',
              companyReportPDF: '1fr 1fr',
              youcheckins: '8% auto',
              default: 'minmax(264px, 200px) auto',
            })
          : styledMap('tabKind', {
              team: '1fr 1fr',
              companyReportPDF: '1fr 1fr',
              youcheckins: '20% auto',
              default: 'minmax(264px, 200px) auto',
            })};
  }
`;

export const EndWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (min-width: 991px) and (max-width: 1360px) {
    grid-column-gap: 24px;
  }

  @media (max-width: 900px) {
    grid-template-columns: none;
    grid-column-gap: 16px;
  }
`;

export const UpdateWrapper = styled.div`
  @media (max-width: 500px) {
    display: none;
  }
`;

export const AvatarWrapper = styled.div`
  margin-top: 6px;
  @media (max-width: 900px) {
    display: none;
  }
`;
export const NameWrapper = styled.div`
  background: #fafafa;
  border-radius: 8px;
  max-width: fit-content;
  padding: 4px 8px;
  font-size: 12px;
  color: #5f5f8c;
  .container span {
    color: #5f5f8c;
    line-height: 150%;
  }
`;

export const ProgressWrapper = styled.div<{variant?: string}>`
  display: flex;
  justify-content: flex-end;
  @media (max-width: 900px) {
    display: none;
  }
`;

export const StyledEndWrapper = styled(EndWrapper)<{
  tabKind: string | undefined;
  feature?: string;
  kind?: string;
  isUpdate?: boolean;
}>`
  grid-template-columns: ${styledMap('tabKind', {
    team: '1fr 1fr',
    companyReportPDF: '1fr 1fr',
    companyTeams: 'minmax(auto, 100px) minmax(auto, 1fr) 5%',
    youcheckins: 'minmax(auto,100px) minmax(210px,1fr) 39%',
    // default: '20% 77%',
  })};

  @media (max-width: 1360px) {
    display: none;
    grid-template-columns: none;
  }
`;

export const StyledEndWrapper3 = styled.div<{kind?: string}>`
  display: ${({kind}) => (kind === 'youcheckins' ? 'block' : 'none')};
  @media (max-width: 1360px) {
    display: flex;
    flex-direction: column;

    width: 100%;
  }
`;

export const ActionWrapper = styled.div`
  display: flex;

  align-items: center;
  @media (max-width: 578px) {
    flex-wrap: wrap;
  }
`;

export const ResponsiveProgressTracker = styled.div<{kind?: string}>`
  display: ${({kind}) => (kind === 'youcheckins' ? 'flex' : 'none')};

  @media (max-width: 500px) {
    display: flex;
    margin-right: 5px;
    margin-top: 12px;
    min-width: 85%;
  }

  @media (min-width: 500px) and (max-width: 728px) {
    display: flex;

    margin-top: 12px;
    min-width: 40%;
  }
  @media (min-width: 728px) and (max-width: 1360px) {
    display: flex;

    margin-top: 12px;
    min-width: 60%;
  }
`;

export const UpdateGoalWrapper = styled.div`
  @media (max-width: 1360px) {
    display: none;
  }
`;

export const UpdateGoalWrapper2 = styled.div<{kind?: string}>`
  display: none;
  @media (max-width: 1360px) {
    display: block;

    margin-top: 12px;
  }
  @media (max-width: 500px) {
    display: flex;
  }
`;

export const CustomCancelButton = styled.button<{openKR?: boolean}>`
  width: ${({openKR}) => (openKR ? '100%' : '80%')};
  height: 38px;
  flex-grow: 0;
  font-size: 14px;
  display: flex;
  font-weight: 500;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 8px 15px 8px 16px;
  border-radius: 10px;
  border: solid 1px #cecede;
  background-color: #ffffff;
  svg {
    margin-left: ${({openKR}) => (openKR ? '3px' : '6px')};
    stroke: #1e1e2f;
  }
`;
