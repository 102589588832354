import {forwardRef, ReactElement, Ref} from 'react';
import ReactSelect from 'react-select';
import {useDropDown} from '../../../../hooks';
import {FieldWrapper} from '../../../atoms/field-wrapper';
import {Helper} from '../../../atoms/helper';
import {
  Wrapper,
  DropWrapper,
  DropdownHandler,
  HandlerWrapper,
  Layout,
} from './styled';
import {Body1} from '../../../atoms/typography';
import {SelectFieldProps, TOption} from '../user-select-2/user-select-2';
import {InputState} from '../../../interface';
import {PhoneSelectFieldOption} from '../selectfield-secondary/phone-select';
import {TextField} from '../../field/textfield';

export interface TPhoneSelectFieldProps extends SelectFieldProps {
  label?: string;
  placeholder?: string;
  state?: InputState;
  helper?: string | ReactElement;
}

export const PhoneSelectField = forwardRef(
  (
    {
      state,
      helper,
      placeholder,
      options,
      options2,
      options3,
      options4,
      options5,
      onChange,
      value,
      defaultValue,
      select,
      setSelect,
      selectOptions,
      type,
      phoneNumberInputInfo,
      ...rest
    }: SelectFieldProps,
    ref: Ref<ReactSelect<TOption>>,
  ) => {
    const {handleOpen, ref: dropRef, open} = useDropDown();
    const switchSelect = (select: string) => {
      setSelect(select);
    };

    return (
      <div style={{marginBottom: '24px'}}>
        <Layout type={type}>
          <PhoneSelectFieldOption
            placeholder="Select"
            options={selectOptions}
            state={state}
            defaultValue={defaultValue}
            onChange={(data: {value: string}) => {
              switchSelect(data.value);
            }}
            ReactSelectStylingProperties={{
              control: {
                paddingRight: '12px !important',
                height: '56px',
              },
            }}
            type="phoneSelect"
          />
          <FieldWrapper margin={true}>
            <Wrapper>
              <DropWrapper ref={dropRef}>
                {(select?.length < 1 || !select?.length) && (
                  <DropdownHandler open={open} onClick={handleOpen}>
                    <HandlerWrapper>
                      <Body1 kind="textBody">{placeholder}</Body1>
                    </HandlerWrapper>
                  </DropdownHandler>
                )}
                {select?.length > 1 && (
                  <DropdownHandler
                    open={open}
                    state={state}
                    onClick={handleOpen}
                    style={{overflowY: 'hidden'}}>
                    <HandlerWrapper
                      style={{
                        height: '-webkit-fill-available',
                      }}>
                      <TextField
                        defaultValue={phoneNumberInputInfo?.defaultValue}
                        placeholder={phoneNumberInputInfo?.placeholder}
                        type={phoneNumberInputInfo?.type}
                        autoComplete={phoneNumberInputInfo?.autoComplete}
                        ref={phoneNumberInputInfo?.ref}
                        helper={phoneNumberInputInfo?.helper}
                        state={phoneNumberInputInfo?.state}
                        name={phoneNumberInputInfo?.name}
                        style={{
                          border: 'none',
                          boxShadow: 'none',
                          color: '#5F5F8C',
                          width: '100%',
                        }}
                        inputStyle={{border: 'none', height: '56px'}}
                        margin
                        noBorder
                      />
                    </HandlerWrapper>
                  </DropdownHandler>
                )}
              </DropWrapper>
            </Wrapper>
          </FieldWrapper>
        </Layout>
        <Helper aria-label="helper" state={state} children={helper} />
      </div>
    );
  },
);
