export const getFromBetween = {
  results: [] as any,
  string: '',
  getFromBetween: function (sub1: any, sub2: any) {
    if (this.string?.indexOf(sub1) < 0 || this.string?.indexOf(sub2) < 0)
      return false;
    var SP = this.string?.indexOf(sub1) + sub1.length;
    var string1 = this.string?.substr(0, SP) || '';
    var string2 = this.string?.substr(SP) || '';
    var TP = string1.length + string2?.indexOf(sub2);
    return this.string?.substring(SP, TP);
  },
  removeFromBetween: function (sub1: any, sub2: any) {
    if (this.string?.indexOf(sub1) < 0 || this.string?.indexOf(sub2) < 0)
      return false;
    var removal = sub1 + this.getFromBetween(sub1, sub2) + sub2;
    this.string = this.string?.replace(removal, '');
  },
  getAllResults: function (sub1: any, sub2: any) {
    // first check to see if we do have both substrings
    if (this.string?.indexOf(sub1) < 0 || this.string?.indexOf(sub2) < 0)
      return;

    // find one result
    const result: any = this.getFromBetween(sub1, sub2);
    // push it to the results array
    this.results.push(result);
    // remove the most recently found one from the string
    this.removeFromBetween(sub1, sub2);

    // if there's more substrings
    if (this.string?.indexOf(sub1) > -1 && this.string?.indexOf(sub2) > -1) {
      this.getAllResults(sub1, sub2);
    } else return;
  },
  get: function (string: any, sub1: any, sub2: any) {
    this.results = [];
    this.string = string;
    this.getAllResults(sub1, sub2);
    return this.results;
  },
};

// const results = getFromBetween.get(backendVersion, '_(', ')_');

export const getName = (id: any, data: any) => {
  const index = data.findIndex((x: any) => x.id === id);
  if (index === -1) return 'deleted_user';
  return data[index].firstName && data[index].lastName
    ? data[index].firstName + ' ' + data[index].lastName
    : data[index].email;
};

export const getHastag = (s: string) =>
  s.includes('#') && s.substr(s.lastIndexOf('#') + 1).split(' ')[0];
