import styled, {DefaultTheme, StyledComponentProps} from 'styled-components';
import {ITypography, Typography} from './typography';
import {getFontSizeFromTheme, getLineHeightFromTheme} from '../../ui-utils';

export type Body2Props = StyledComponentProps<
  'p',
  DefaultTheme,
  ITypography,
  'kind' | 'align'
>;

/**
 Used for multiline pieces of content.
 **/
export const Body2 = styled.p<ITypography>`
  ${Typography};

  font-size: ${({size}) => size || getFontSizeFromTheme('body2')};
  line-height: ${getLineHeightFromTheme('body2')};
`;
