import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const AddButton = styled.button`
  display: flex;
  border: none;
  background: none;
  appearance: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  margin: 0;
  position: absolute;
  bottom: 15px;
  right: -35px;
  z-index: 1;
`;
export const AddButton2 = styled.button`
  display: flex;
  border: none;
  background: none;
  appearance: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  margin: 0;
  z-index: 1;
`;

export const HelperWrapper = styled.div`
  position: relative;

  > span {
    bottom: -12px;
  }
`;
