import styled from 'styled-components';
import {motion} from 'framer-motion';
import {getColorFromTheme} from '../../../ui-utils';

export const Wrapper = styled.button<{kind?: string; tabKind?: string}>`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  outline: none;
  width: ${({kind, tabKind}) =>
    kind === 'admin' ? '' : tabKind === 'youcheckins' ? '' : 350};
  &:disabled {
    pointer-events: none;
  }

  @media (max-width: 600px) {
    width: auto;
  }
`;

export const HandlerWrapper = styled(motion.span)`
  position: relative;
  svg {
    fill: ${getColorFromTheme('textDark')};
    // stroke: ${getColorFromTheme('textDark')};
  }
`;
export const HandlerWrapperComment = styled(motion.span)`
  position: relative;
`;

export const PlusWrapper = styled.span`
  position: relative;
  svg {
    stroke: ${getColorFromTheme('purple300')};
  }
`;

export const MinusWrapper = styled.span`
  position: relative;
  svg {
    stroke: ${getColorFromTheme('red400')};
  }
`;

export const GoalTypeTag = styled.div`
  height: 7%;
  padding: 4px 8px;
  background: #f6f6f8;
  border-radius: 8px;
  font-size: 12px;
  color: #5f5f8c;
  display: flex;
  align-items: center;
  font-weight: 500;
  line-height: 18px;
  width: fit-content;
`;
