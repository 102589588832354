import {FilterComponent} from '@pages/dashboard/reporting/reporting-pages/admin-analytics/analytics-shared-components/filterbox/filterbox';
import {SelectField} from '@ui/molecules/select/selectfield';
import {useStoreContext} from '@store/store-context';
import {observer} from 'mobx-react-lite';
import {FiChevronDown} from 'react-icons/fi';
import {Body2} from '@ui/atoms/typography';
import {capitalize} from '@utils';
import {CustomColorIcon} from '@ui/atoms/color-icons';
import {FlexRowSpaceBetween, FlexRow} from '@ui/style/styles';
import {DropdownCustom} from '@ui/molecules/dropdown-custom';
import {Button} from '@ui/atoms/button';
import {FC, useState, useMemo, CSSProperties} from 'react';

export interface IOptions {
  filterBy: string;
  theme?: string;
  value?: string;
}

interface IFilter {
  onChange: (value: IOptions) => void;
  values: IOptions;
  style?: CSSProperties;
  className?: string;
  disabled?: boolean;
}

const ValueThemeFilter: FC<IFilter> = ({
  onChange,
  values,
  style,
  disabled,
  className,
}) => {
  const [filterBy, setFilterBy] = useState(values.filterBy);
  const [themeValue, setThemeValue] = useState(values.theme);
  const [value, setValue] = useState(values.value);

  const {
    authStore: {auth},
  } = useStoreContext();

  const [showApply, setShowApply] = useState(
    filterBy &&
      values.filterBy === filterBy &&
      ((themeValue && values.theme === themeValue) ||
        (value && value === values.value))
      ? false
      : true,
  );

  const handleApply = () => {
    setShowApply(false);
    onChange({
      filterBy,
      value,
      theme: themeValue,
    });
  };

  const filterTypeOption = [
    {
      label: 'Values',
      value: 'values',
    },
    {
      label: 'Themes',
      value: 'themes',
    },
  ];

  const valuesOptions = useMemo(
    () =>
      auth.user.workspace.settings.companyValues.values.map((value: string) => {
        return {
          label: value,
          value: value.toLowerCase(),
        };
      }),
    [auth.user.workspace.settings.companyValues.values],
  );

  const disableButton = useMemo(() => {
    if (!filterBy) return true;
    if (filterBy === 'themes' && themeValue === values.theme) return true;
    if (filterBy === 'values' && (!value || value === values.value)) {
      return true;
    }
  }, [filterBy, themeValue, value, values.theme, values.value]);

  const themeOptions = useMemo(
    () => [
      {
        label: 'All',
        color: '#CECEDE',

        value: '',
      },
      {
        label: 'Commend',
        color: '#47B881',
        value: 'commend',
      },
      {
        label: 'Neutral',
        color: '#5F5F8C',
        value: 'neutral',
      },
      {
        label: 'Critic',
        color: '#E87F16',
        value: 'critic',
      },
    ],
    [],
  );

  const themeIconColor = useMemo(
    () =>
      themeOptions.find((theme) => theme.value === values.theme)?.color ||
      '#CECEDE',
    [themeOptions, values.theme],
  );

  const resetFilter = () => {
    setShowApply(true);
    onChange({
      filterBy: '',
      value: '',
      theme: '',
    });
    setFilterBy('');
    setThemeValue('');
    setValue('');
  };

  return (
    <DropdownCustom
      collapseOnClick={true}
      menu={(handleClose: () => void) => (
        <FilterComponent
          title="Filters"
          showFilter={true}
          type="date"
          showApplyButton={{
            show: showApply,
            action: handleApply,
          }}
          resetDisabled={disableButton}
          resetDateDisabled={false}
          resetDateFilter={resetFilter}
          handleClose={handleClose}
          boxStyle={{right: 0, zIndex: 2}}
          resetFilter={() => null}
          setView={() => null}>
          <SelectField
            options={filterTypeOption}
            placeholder={'Select option'}
            defaultValue={filterBy}
            onChange={(data: any) => {
              setShowApply(true);

              setFilterBy(data?.value);
            }}
            borderRadius={10}
          />
          {filterBy === 'themes' && (
            <SelectField
              options={themeOptions}
              placeholder={'Select theme'}
              defaultValue={themeValue}
              onChange={(data: any) => {
                setShowApply(true);
                setThemeValue(data?.value);
              }}
              borderRadius={10}
            />
          )}
          {filterBy === 'values' && valuesOptions.length > 0 && (
            <SelectField
              options={valuesOptions}
              placeholder={'Select value'}
              defaultValue={value}
              onChange={(data: any) => {
                setShowApply(true);
                setValue(data?.value);
              }}
              borderRadius={10}
            />
          )}
        </FilterComponent>
      )}
      shouldNotIncludeWrapper={true}
      customComponent={(handleOpen: (event: any) => void, open: boolean) => (
        <>
          <Button
            kind="secondary"
            className={className}
            style={style || {padding: '12px 16px', minWidth: '153px'}}
            disabled={disabled}
            onClick={handleOpen}>
            <FlexRowSpaceBetween>
              <FlexRow>
                <CustomColorIcon
                  width="10px"
                  height="10px"
                  margin={5}
                  color={themeIconColor}
                />{' '}
                <Body2 weight="bold">
                  {capitalize(values.theme || values.value || 'Filter')}
                </Body2>
              </FlexRow>
              <FiChevronDown
                style={{margin: '0px 5px 0px 0px'}}
                aria-hidden="true"
              />
            </FlexRowSpaceBetween>
          </Button>
        </>
      )}
      customDropdownWrapperStyles={{
        top: '130%',
        boxShadow: '0px',
        right: 0,
        padding: 0,
        overflowY: 'auto',
        background: 'none',
        border: 0,
      }}
    />
  );
};

export default observer(ValueThemeFilter);
