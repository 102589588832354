import {motion} from 'framer-motion';
import styled from 'styled-components';
import {Button} from '../../../../../../../ui/atoms/button';
import {getColorFromTheme} from '../../../../../../../ui/ui-utils';

export const SearchFilterWrapper = styled.div`
  display: inline-block;
  width: 100%;
  padding: 16px 24px;
`;

export const FirstWrapper = styled.section``;

export const FirstWrapperChild = styled(motion.div)<{
  isStatusTab?: boolean;
  isStatusTabGoal?: boolean;
  isStatusTabPeople?: boolean;
}>`
  display: flex;
  grid-column-gap: 12px;
  z-index: 9999;
  width: 100%;
  display: flex;
`;

export const SearchWrapper = styled.div`
  width: 100%;
`;

export const FilterWrapper = styled.div`
  display: grid;
  grid-gap: 12px;
  border: 1px solid #cecede;
  position: absolute;
  z-index: 4;
  width: 352px;
  margin-top: 9px;
  right: -66%;
  padding: 16px;
  background: white;
  border-radius: 10px;
  box-shadow: 1px 1px 5px 4px rgba(0, 0, 0, 0.05);
`;

export const FilterBox = styled.div`
  svg {
    margin-right: 12px;
    position: relative;
    top: 3px;
  }
`;

export const FilterBoxWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const FilterButton = styled(Button)`
  width: 100%;
  padding: 0;
  border: 1px solid #cecede;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  height: 38px;
  width: 93px;
`;
export const ApplyFilterButton = styled(Button)`
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  width: 72px;
`;

export const FilterHeaderButton = styled(Button)`
  padding: 0 16px;
  height: 40px;
`;

export const DateWrapper = styled.span`
  width: 100%;
`;

export const FilterDropDownHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;

  > div {
    display: flex;
    grid-gap: 16px;
  }
`;

export const CustomFontWeight = styled.span`
  font-weight: 500;
  color: #1e1e2f;
  font-size: 14px;
`;

export const EmailButton = styled(Button)`
  line-height: 1;
  padding: 16px 0;
  font-size: 14px;
  font-weight: 500;

  &:disabled {
  }
`;
export const UserSelectWrap = styled.div`
  border: 1px solid ${getColorFromTheme('borderDark')};
  border-radius: 6px;
`;
