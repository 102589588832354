import {useMemo, useState, useCallback} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm, Resolver} from 'react-hook-form';
import {useNavigate} from 'react-router';
import {useQuery} from 'react-query';
import {groupTypeFormData} from './admin-group-type-interface';
import {generateRandomDigits} from '@utils/generate-random-numbers';
import {adminGroupTypeValidator} from './admin-group-type-validator';
import {useApiCalls} from '@hooks/init-calls';
import {useSearchParams} from 'react-router-dom';
import {groupTypeController} from './admin-group-type-controller';
import FreeTierModal from '@ui/organisms/free-tier/free-tier-modal';
import {useFreeTierHook} from '@hooks/free-tier';

import {cloudinary} from '@assets/images/cloudinary';
import {groupTypeRequestImpl} from './admin-group-type-request';
import {useStoreContext} from '../../../../../../store/store-context';
import {setFirebaseData} from '@utils/firebase-handler';
const {sortImage: img, sortImage2x: img2x, sortImage3x: img3x} = cloudinary;

const image = {
  src: img,
  extension: 'png',
  variants: [
    {src: img, size: '1x'},
    {src: img2x, size: '2x'},
    {src: img3x, size: '3x'},
  ],
};

export function useAdminGroupHook() {
  const [hideCreateGroupModal, showCreateGroupModal] = useState<boolean>(false);
  const [hideEditGroupModal, showEditGroupModal] = useState<boolean>(false);
  const [hideDeleteGroupModal, showDeleteGroupModal] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const drawerOpen = searchParams.get('group-drawer');

  const {
    isAccountFreeTier,
    showFreeTierModal,
    handleShowFreeTierModal,
  } = useFreeTierHook();

  const DisplayFreeTierModal = (
    <FreeTierModal
      open={showFreeTierModal}
      image={image}
      onClose={() => handleShowFreeTierModal(false)}
      description={`Customize group types to align with your organization's structure. Creating bespoke group types provides flexibility, allowing for seamless adjustments to changes in your company's structure or operations.`}
      title={`Use Custom Group Types for 
Ultimate Flexibility`}
    />
  );

  const {
    register,
    handleSubmit,
    errors,
    formState,
    watch,
  } = useForm<groupTypeFormData>({
    resolver: yupResolver(adminGroupTypeValidator) as Resolver<
      groupTypeFormData,
      object
    >,
    mode: 'onSubmit',
  });

  const {handleSubmit: handleDelete, formState: deleteFormState} = useForm({
    mode: 'onSubmit',
  });

  const isSubmittingCreateGroupType = useMemo(() => {
    return formState.isSubmitting;
  }, [formState]);

  const isSubmittingDeleteGroupType = useMemo(() => {
    return deleteFormState.isSubmitting;
  }, [deleteFormState]);

  const {initGroupType} = useApiCalls();

  const {setInitResponseAsAuth} = useStoreContext().authStore;

  const request = new groupTypeRequestImpl();
  const controller = new groupTypeController(request);

  const navigate = useNavigate();

  const submitCreateGroupType = async (data: groupTypeFormData) => {
    await controller.newCreateGroupType(data);
    setInitResponseAsAuth();
    setFirebaseData(`updates/groupType`, generateRandomDigits(16));

    initGroupType();
    if (!drawerOpen) {
      navigate('/home', {replace: true});
      navigate('/manage-people');
    }
  };
  const [disabledCreateGroupType, setDisabledCreateGroupType] = useState(true);
  const {name} = watch();
  useMemo(() => {
    name ? setDisabledCreateGroupType(false) : setDisabledCreateGroupType(true);
  }, [name]);

  const fetchGroupType = useCallback(async () => {
    const request = new groupTypeRequestImpl();
    const controller = new groupTypeController(request);

    const response: any = await controller.newfetchGroupType();

    return response;
  }, []);

  const {isLoading: isFetchingGroupType} = useQuery(['fetchGroupType'], () =>
    fetchGroupType(),
  );

  const {groupType} = useStoreContext().groupTypeStore;
  const [groupTypeName, setGroupTypeName] = useState<string>();
  const [groupTypeId, setGroupTypeId] = useState<string>();
  const submitEditGroupType = async (data: groupTypeFormData) => {
    await controller.newEditGroupType(data, groupTypeId);
    setInitResponseAsAuth();

    navigate('/home');
    setFirebaseData(`updates/groupType`, generateRandomDigits(16));

    initGroupType();
    navigate('/manage-people');
  };
  const submitDeleteGroupType = async () => {
    await controller.newDeleteGroupType(groupTypeId);
    setInitResponseAsAuth();
    navigate('/home');

    setFirebaseData(`updates/groupType`, generateRandomDigits(16));

    initGroupType();
    navigate('/manage-people');
  };

  const [searchGroupType, setSearchGroupType] = useState<string>();
  const groupTypeData = groupType.filter((groupType: any) =>
    searchGroupType?.length === 0 || searchGroupType === undefined || null
      ? groupType
      : groupType.name.toLowerCase().includes(searchGroupType),
  );

  return {
    hideCreateGroupModal,
    showCreateGroupModal,
    hideEditGroupModal,
    showEditGroupModal,
    hideDeleteGroupModal,
    showDeleteGroupModal,
    register,
    errors,
    isSubmittingCreateGroupType,
    handleSubmit,
    submitCreateGroupType,
    disabledCreateGroupType,
    isFetchingGroupType,
    groupTypeData,
    groupTypeName,
    setGroupTypeName,
    setGroupTypeId,
    submitEditGroupType,
    submitDeleteGroupType,
    handleDelete,
    isSubmittingDeleteGroupType,
    setSearchGroupType,
    searchGroupType,
    DisplayFreeTierModal,
    isAccountFreeTier,
    handleShowFreeTierModal,
  };
}
