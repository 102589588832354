import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {getGroupTypeAndGroup} from '@utils/get-group-grouptype';
import dayjs from 'dayjs';
import {useStoreContext} from '@store/store-context';
import {GoalsRequestImpl} from '@api/goals-api/goals-request';
import {FeedbackRequestImpl} from '../feedback-requests';
import {FeedbackController} from '../feedback-controller';
import {useNavigate, useLocation} from 'react-router';
import {useSearchParams} from 'react-router-dom';
import {GoalsController} from '@api/goals-api/goals-controller';
import {Resolver} from 'react-hook-form';
import {useWorkspaceUseCase} from '@hooks/workspace-usecase';
import {FeedbackType} from '@ui/interface';
import {CreateFeedbackValidator} from './create-feedback-validator';
import {activateNotification} from '@ui/molecules/notification/activate-notification';
import {usePostHogHook} from '@hooks/post-hog';

interface CreateFeedbackFormData {
  users: string[];
  feedbackFor: string;
  feedback: string;
  subjectName: string;
  subjectId: string;
  impression: string;
  values: Array<string>;
  sharing: string;
}
const useCreateFeedbackHook = () => {
  const {usersStore} = useStoreContext();
  const {users, deactivatedUsers} = usersStore;
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const type = searchParams.get('type');

  const FeedbackIsPraise = type === 'praise';

  const location = useLocation();

  const navigate = useNavigate();
  const {isPerformanceActive} = useWorkspaceUseCase();

  const {
    handleSubmit,
    errors,
    formState,
    setValue,
    trigger,
    control,
    watch,
    reset,
  } = useForm<CreateFeedbackFormData>({
    resolver: yupResolver(CreateFeedbackValidator.createFeedback) as Resolver<
      CreateFeedbackFormData,
      object
    >,
    mode: 'all',
    defaultValues: {
      users: [],
      feedbackFor: '',
      feedback: '',
      impression: '',
      values: [],
      sharing: FeedbackIsPraise ? FeedbackType.PUBLIC : FeedbackType.MANAGER,
    },
  });

  useEffect(() => {
    if (!isPerformanceActive && !watch().feedbackFor) {
      reset({
        feedbackFor: 'custom',
      });
    }
  }, [isPerformanceActive, reset, watch]);

  const {
    groupStore: {groups},
    groupTypeStore: {groupType},
    authStore: {auth},
  } = useStoreContext();

  const prefillCreateFeedbackData = useCallback(() => {
    const prefilledData = location.state as any;

    reset({
      users: [prefilledData.user],
      feedbackFor: prefilledData.subjectType,
      subjectName: prefilledData.subjectName,
      subjectId: prefilledData.subjectId,
      feedback: '',
      impression: '',
      values: [],
      sharing:
        users.find((_user) => _user.id === prefilledData.user)?.reviewer?.id ===
        auth.user.id
          ? FeedbackType.PRIVATE
          : FeedbackType.MANAGER,
    });
  }, [location.state, reset, users, auth.user.id]);

  useEffect(() => {
    if (id && location.state) {
      prefillCreateFeedbackData();
    }
  }, [prefillCreateFeedbackData, id, location.state]);

  const transformOptions = useCallback(
    (goal: any, groups: any[], groupType: any[]) => ({
      label: {
        goalType:
          goal.goalType === 'group'
            ? `${
                getGroupTypeAndGroup(groups, groupType, goal.group)?.groupType
              } - ${
                getGroupTypeAndGroup(groups, groupType, goal.group)?.groupName
              }`
            : goal.goalType === 'company'
            ? 'Company-wide'
            : goal.goalType,
        goalName: goal.name,
        group: groups.filter((group) => goal.group.includes(group.id)),

        assigneeName:
          goal.assignee &&
          goal.assignee.hasOwnProperty('firstName') &&
          goal.assignee.hasOwnProperty('lastName')
            ? `${goal.assignee?.firstName} ${goal.assignee?.lastName}`
            : goal.assignee?.email,
        assigneeAvatar: {
          src:
            goal.assignee?.avatar && goal.assignee?.avatar.url
              ? goal.assignee?.avatar.url
              : '',
          name:
            goal.assignee &&
            goal.assignee.hasOwnProperty('firstName') &&
            goal.assignee.hasOwnProperty('lastName')
              ? `${goal.assignee?.firstName} ${goal.assignee?.lastName}`
              : goal.assignee?.email,
          id: goal.assignee?.id,
        },
        date: dayjs(goal.createdAt).format('DD/MM/YYYY'),
        id: goal.id,
      },
      value: goal.id,
    }),
    [],
  );

  const initGoals = useCallback(
    async (pageNumber?: number, filter?: string) => {
      const request = new GoalsRequestImpl();
      const controller = new GoalsController(request);
      const response = await controller.fetchGoals(pageNumber, filter);

      const goals = response.goals.map((goal: any) =>
        transformOptions(goal, groups, groupType),
      );
      return goals;
    },
    [transformOptions, groups, groupType],
  );

  const isSubmitting = useMemo(() => {
    return formState.isSubmitting;
  }, [formState]);

  const handleFormValueChange = useCallback(
    async (value: string | null | string[], name: string) => {
      setValue(name, value);
      if (name === 'feedback' && (await trigger(name)))
        formState.submitCount > 0 && (await trigger());
    },
    [setValue, trigger, formState.submitCount],
  );
  const [isDisabled, setIsDisabled] = useState(true);

  const {
    users: _users,
    feedback,
    feedbackFor,
    impression,
    sharing,
    subjectName,
  } = watch();

  useMemo(() => {
    _users && feedback && feedbackFor && impression && sharing && subjectName
      ? setIsDisabled(false)
      : setIsDisabled(true);
  }, [_users, feedback, feedbackFor, impression, sharing, subjectName]);

  const feedbackForOptions = [
    {
      label: 'Goal',
      value: 'goal',
    },
    {
      label: 'Custom',
      value: 'custom',
    },
  ];
  const feedbackRequest = useMemo(() => new FeedbackRequestImpl(), []);

  const feedbackController = useMemo(
    () => new FeedbackController(feedbackRequest),
    [feedbackRequest],
  );
  const {postHogCapture} = usePostHogHook();

  const onSubmit = async (data: CreateFeedbackFormData) => {
    try {
      const handleSuccess = (type: 'feedback' | 'praise') => {
        postHogCapture('frontend - Feedback submission');

        activateNotification({
          content: `Your ${type} has been submitted.`,
          title: 'Success',
          kind: 'success',
        });
        navigate(-1);
      };

      if (FeedbackIsPraise) {
        const praiseResponse = await feedbackController.postPraise({
          feedback: data.feedback,
          impression: data.impression,
          subjectType: data.feedbackFor,
          subjectName: data.subjectName,
          requestId: id || undefined,
          subjectId: data.subjectId || undefined,
          group: data.users,
          visibility: data.sharing,
          values: data.values,
        });

        if (praiseResponse) {
          return handleSuccess('praise');
        }
      }

      const _uploadFeedback = async (user: string) => {
        return await feedbackController.postFeedback(
          {
            feedback: data.feedback,
            impression: data.impression,
            subjectType: data.feedbackFor,
            subjectName: data.subjectName,
            requestId: id || undefined,
            subjectId: data.subjectId || undefined,
            visibility: data.sharing,
            values: data.values,
          },
          user,
        );
      };

      const response = await Promise.all(
        data.users.map(async (user) => await _uploadFeedback(user)),
      );

      response && handleSuccess('feedback');
    } catch (error) {}
  };
  return {
    handleFormValueChange,
    handleSubmit,
    users: users
      .filter((user) => user.id !== auth.user.id)
      .map((member: any) => {
        return {
          label: {
            avatar: {
              name:
                member && member.firstName
                  ? member.firstName + ' ' + member.lastName
                  : member.email + ' (pending invitation)',
              src: member.avatar && member.avatar.url ? member.avatar.url : '',
            },
            id: member.id,
            name:
              member && member.firstName
                ? member.firstName + ' ' + member.lastName
                : member.email + ' (pending invitation)',
          },
          value: member.id,
        };
      })
      .filter((user: any) => {
        const arrayOfDeactivatedUserIds = deactivatedUsers.map(
          (user: any) => user.id,
        );
        return arrayOfDeactivatedUserIds.includes(user.value) ? false : true;
      }),
    errors,
    control,
    onSubmit,
    id,
    FeedbackIsPraise,
    isDisabled,
    initGoals,
    feedbackFor,
    selectedUsers: _users,
    feedbackForOptions,
    isSubmitting,
  };
};

export default useCreateFeedbackHook;
