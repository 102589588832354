import {CSSProperties, memo, PropsWithChildren, SyntheticEvent} from 'react';
import styled from 'styled-components';
import {ifProp} from 'styled-tools';
import styledMap from 'styled-map';
import {down} from 'styled-breakpoints';

import {NavLink} from 'react-router-dom';
import {motion} from 'framer-motion';
import {Typography} from '../typography/typography';
import {getColorFromTheme} from '../../ui-utils';

const getDisabledColor = (props: any) => {
  return getColorFromTheme('textMuted');
};

const getFeaturesColor = (props: any) => {
  return getColorFromTheme('textDark');
};

const getActiveColor = (props: any) => {
  return getColorFromTheme('purple300');
};

const getDefaultColor = (props: any) => {
  return getColorFromTheme('textBody');
};
const shouldAddBorder = (props: any) => {
  return [
    'performance',
    'goalFramework',
    'checkins',
    'status',
    // 'lightBackground',
  ].includes(props.type);
};

const NavItem = styled(NavLink)<{
  $active?: boolean;
  $disable?: boolean;
  variant?: any;
  indent?: any;
  ivariant?: string;
  subActive?: boolean;
  type?: string;
}>`
  ${Typography};

  padding: ${styledMap('type', {
    workspace: '4px 8px',
    performance: '0px 0 17px 17px',
    goalFramework: '0px 0 17px 17px',
    checkins: '0px 0 6px 17px',
    default: '0 0 6px 12px',
  })};
  font-size: 14px;
  font-weight: ${(props: any) => {
    if (props.$active || props.subActive) {
      return 500;
    } else {
      return 400;
    }
  }};

  line-height: 1.5;
  letter-spacing: normal;
  color: ${(props: any) => {
    if (props.$disable) {
      return getDisabledColor(props);
    } else if (props.ivariant === 'features') {
      return getFeaturesColor(props);
    } else if (props.$active || props.subActive) {
      return getActiveColor(props);
    } else {
      return getDefaultColor(props);
    }
  }};

  background-color: ${(props: any) =>
    ['workspace'].includes(props.type) && props.$active
      ? getColorFromTheme('backgroundLight')
      : ['workspace', 'status'].includes(props.type)
      ? getColorFromTheme('white')
      : getColorFromTheme('backgroundLight')};

  text-decoration: none;
  border-radius: ${(props) =>
    props.type === 'workspace' ? '10px' : undefined};

  position: relative;

  margin-left: ${(props: any) => (props.indent ? props.indent : undefined)};
  border-left: ${(props) =>
    shouldAddBorder(props) ? '1px solid #ededf2' : ''};
  cursor: ${ifProp('$disable', 'not-allowed', 'pointer')};
  flex-shrink: 0;
  margin-top: ${(props: any) => (props.type === 'performance' ? '12px' : '')};
  margin-bottom: ${(props: any) =>
    ['goalFramework', 'performance', 'status'].includes(props.type)
      ? ''
      : '8px'};
  display: inline-flex;

  &:last-of-type {
    margin-bottom: 0px;
  }

  &:hover {
    color: ${(props: any) => {
      if (props.$disable) {
        return getDisabledColor(props);
      } else if (props.ivariant === 'features') {
        return getFeaturesColor(props);
      } else {
        return getActiveColor(props);
      }
    }};
  }
  ${down('sm')} {
    margin-bottom: 0px;
    padding: 0;
    width: 100%;
  }
  ${down('sm')} {
    color: ${(props: any) => {
      if (props.$disable) {
        return getDisabledColor(props);
      } else if (props.ivariant === 'features') {
        return getActiveColor(props);
      } else if (props.$active || props.subActive) {
        return getActiveColor(props);
      } else {
        return getDefaultColor(props);
      }
    }};
  }
`;

const NavItemBackground = styled(motion.span)`
  position: absolute;
  left: -3.5px;
  top: 0;
  background-color: ${getColorFromTheme('purple300')};
  z-index: 1;
  width: 3.5px;

  height: 100%;
  border-radius: 10px;
`;

const NavItemContent = styled.span<{
  indent?: string;
  active?: boolean;
  type?: string;
  subActive?: boolean;
}>`
  z-index: 1;
  position: relative;
  background: ${(props: any) =>
    props.active || props.subActive
      ? ['status', 'features', 'workspace'].includes(props.type)
        ? getColorFromTheme('backgroundLight')
        : 'white'
      : undefined};
  border-radius: 8px;
  width: 100%;
  padding: 8px;
  display: flex;
  align-items: center;

  ${down('sm')} {
    text-align: center;
    justify-content: center;

    white-space: nowrap;
  }
`;

export interface SideNavProps
  extends PropsWithChildren<{
    href?: string;
    onClick?: (e: SyntheticEvent<HTMLAnchorElement>) => void;
    active: boolean;
    disable?: boolean;
    subActive?: boolean;
    ivariant?: string;
    disableHref?: boolean;
    type?: string;
    variant?: any;
    indent?: string;
    style?: CSSProperties;
  }> {}

export const SideNav = memo(function ({
  href,
  active,
  children,
  disable,
  type,
  disableHref,
  variant,
  subActive,
  ivariant,
  onClick,
  indent,
  style,
}: SideNavProps) {
  const navigateTo = () => {
    if (disable || disableHref) {
      return window.location.pathname;
    }
    return href ?? '#';
  };
  return (
    <NavItem
      to={navigateTo()}
      type={type}
      id="nav"
      onClick={disable ? undefined : onClick}
      subActive={subActive}
      indent={indent}
      ivariant={ivariant}
      variant={variant}
      $active={active}
      $disable={disable}>
      {active && type !== 'workspace' && (
        <NavItemBackground
          layoutId="side-nav-background"
          initial={true}
          transition={{
            duration: 0.3,
            layoutY: {duration: 0.3},
            layoutX: {delay: 0, duration: 0},
          }}
        />
      )}
      <NavItemContent
        style={style}
        tabIndex={-1}
        type={type}
        indent={indent}
        subActive={subActive}
        active={active}>
        {children}
      </NavItemContent>
    </NavItem>
  );
});
export const MobileSideNav = memo(
  ({
    href,
    active,
    children,
    disable,
    type,
    variant,
    subActive,
    ivariant,
    onClick,

    indent,
    style,
  }: SideNavProps) => {
    return (
      <NavItem
        to={href ?? '#'}
        type={type}
        id="nav"
        onClick={disable ? undefined : onClick}
        subActive={subActive}
        indent={indent}
        ivariant={ivariant}
        variant={variant}
        $active={active}
        $disable={disable}>
        <NavItemContent
          style={style}
          tabIndex={-1}
          indent={indent}
          subActive={subActive}
          active={active}>
          {children}
        </NavItemContent>
      </NavItem>
    );
  },
);
SideNav.displayName = 'SideNav';
