import {savePDF} from '@progress/kendo-react-pdf';

class DocService {
  createPdf = (html: any, data?: any) => {
    savePDF(html, {
      scale: 1.0,
      fileName: `${data.filename}.pdf`,
      margin: '0cm',
    });
  };
}

class CSVDocService {
  createPdf = (html: any, data?: any) => {
    savePDF(html, {
      paperSize: 'A4',
      scale: 0.6,
      fileName: `${data.filename}.csv`,
      margin: '1cm',
    });
  };
}

const Doc = new DocService();
export const CSVDoc = new CSVDocService();
export default Doc;
