import {BaseRequest} from '../../configs/request';
import {authStore} from '../../store/stores/auth-store';

interface IFeedbackRequest extends BaseRequest {
  postFeedback(data: any, userId: string): Promise<any>;
  postPraise(data: any): Promise<any>;
  postRequestFeedback(data: any, userId: string): Promise<any>;
  deleteFeedbackRequest(requestId: string): Promise<any>;
  fetchRequestFeedback(userId: string): Promise<any>;
  getFeedback(userId: string, data?: any): Promise<any>;
  fetchPublicFeedback(data: any): Promise<any>;
}

export class FeedbackRequestImpl
  extends BaseRequest
  implements IFeedbackRequest {
  url: string = '/users';

  private requestHeader = {
    headers: {
      Authorization: `Bearer ${authStore?.auth?.tokens.access.token}`,
    },
  };

  async postFeedback(data: any, userId: string) {
    const {data: response} = await this.request.post(
      `${this.url}/${userId}/feedback`,
      data,

      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return response;
  }

  async postPraise(data: any) {
    const {data: response} = await this.request.post(
      `${this.url}/feedback`,
      data,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return response;
  }

  async deleteFeedbackRequest(requestId: string) {
    const {data: response} = await this.request.delete(
      `${this.url}/feedback-request/${requestId}`,

      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return response;
  }

  async patchFeedback(data: any, feedbackId: string) {
    const {data: response} = await this.request.patch(
      `${this.url}/feedback/${feedbackId}`,
      data,

      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return response;
  }

  async postRequestFeedback(data: any, reviewerId: string) {
    const {data: response} = await this.request.post(
      `${this.url}/${reviewerId}/feedback-request`,
      data,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return response;
  }
  async fetchRequestFeedback(reviewerId: string) {
    const {data: response} = await this.request.get(
      `${this.url}/feedback-request?reviewer=${reviewerId}&status=pending&paginate=false`,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return response;
  }

  async fetchFeedbackReporting(data: any) {
    const {
      impression,
      manager,
      startDate,
      endDate,
      user,
      values,
      groupBy,
      group,
      sortByCount,
    } = data;

    const {data: response} = await this.request.get(
      `/reports/feedback?paginate=false${
        startDate ? `&startDate=${startDate}` : ''
      }${values ? `&values=${values}` : ''}${group ? `&group=${group}` : ''}${
        endDate ? `&endDate=${endDate}` : ''
      }${impression ? `&impression=${impression}` : ''}${
        groupBy ? `&groupBy=${groupBy}` : ''
      }${sortByCount ? `&sortByCount=${sortByCount}` : ''}${
        user ? `&user=${user}` : ''
      }${manager ? `&manager=${manager}` : ''}`,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
          useCache: false,
        },
      },
    );
    return response;
  }

  async getFeedback(data: any, userId: string) {
    const {
      subjectId,
      subjectType,
      impression,
      manager,
      archive,
      values,
      reviewer,
      startDate,
      endDate,
      user,
    } = data;

    const {data: response} = await this.request.get(
      `${this.url}/${userId}/feedback?${archive ? `archived=${archive}` : ''}${
        subjectId ? `subjectId=${subjectId}` : ''
      }${values ? `&values=${values}` : ''}${
        subjectType ? `&subjectType=${subjectType}` : ''
      }${reviewer ? `&reviewer=${reviewer}` : ''}${
        startDate ? `&startDate=${startDate}` : ''
      }${endDate ? `&endDate=${endDate}` : ''}${
        impression ? `&impression=${impression}` : ''
      }${user ? `&user=${user}` : ''} ${manager ? `&manager=${manager}` : ''}`,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return response;
  }

  async getSentimentAnalytics(text: string) {
    const {data: response} = await this.request.get(
      `workspaces/sentiment-analysis?text=${encodeURIComponent(text)}`,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return response;
  }
  async fetchPublicFeedback(data: any) {
    const {
      subjectId,
      subjectType,
      impression,
      public: _public,
      sortBy,
      values,
      reviewer,
      startDate,
      endDate,
      user,
    } = data;
    const {data: response} = await this.request.get(
      `${this.url}/feedback?visibility=public${
        subjectId ? `&subjectId=${subjectId}` : ''
      }&sortBy=createdAt:${sortBy || 'desc'}${
        subjectType ? `&subjectType=${subjectType}` : ''
      }${_public ? `&public=${_public}` : ''}${
        values ? `&values=${values}` : ''
      }${reviewer ? `&reviewer=${reviewer}` : ''}${
        startDate ? `&startDate=${startDate}` : ''
      }${endDate ? `&endDate=${endDate}` : ''}${
        impression ? `&impression=${impression}` : ''
      }${user ? `&user=${user}` : ''}`,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return response;
  }
  async fetchDirectReportsFeedback(data: any) {
    const {
      subjectId,
      subjectType,
      impression,
      public: _public,
      values,
      reviewer,
      startDate,
      endDate,
      user,
    } = data;
    const {data: response} = await this.request.get(
      `${this.url}/feedback?${subjectId ? `&subjectId=${subjectId}` : ''}${
        subjectType ? `&subjectType=${subjectType}` : ''
      }${_public ? `&public=${_public}` : ''}${
        values ? `&values=${values}` : ''
      }${reviewer ? `&reviewer=${reviewer}` : ''}${
        startDate ? `&startDate=${startDate}` : ''
      }${endDate ? `&endDate=${endDate}` : ''}${
        impression ? `&impression=${impression}` : ''
      }${user ? `&user=${user}` : ''}`,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return response;
  }
}
