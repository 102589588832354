// 1. Falsify all keys in an object of booleans
export const falsyObject = (obj: any) => {
  return Object.fromEntries(
    Object.keys(obj).map((val: string) => [val, false]),
  );
};

// 2. Array of key value pairs to object
export const arrOfKeyValToObject = (res: any) => {
  return Object.fromEntries(res?.map((rs: any) => [rs.groupId, rs.response]));
};

// 3. Filter an object by key
export const filterObject = (obj: any, callback: any) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([key, val]) => callback(val, key)),
  );
};

export const removeEmptyStringFromObject = (obj: any) => {
  let newObj: any = {};

  Object.keys(obj).forEach((key) => {
    if (obj[key] !== '') {
      newObj[key] = obj[key];
    }
  });
  return newObj;
};
export const removeUndefinedFromObject = (obj: any) => {
  let newObj: any = {};

  Object.keys(obj).forEach((key) => {
    if (obj[key] !== undefined) {
      newObj[key] = obj[key];
    }
  });
  return newObj;
};
