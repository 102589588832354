import styled from 'styled-components';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {BeamSlideIcon} from '@ui/atoms/icons/beam-slide-icon';
import {FlexRow} from '@ui/style/styles';
import {
  ObjectiveProgress,
  KeyResultProgress,
  KPIProgress,
  CompetenceProgress,
} from './components/objective-progress';
import ObjectivePerformance from './components/objective-performance';
import KeyResultPerformance from './components/keyresult-summary';
import {PowerpointKpi} from './components/kpi-goals';
import {CompetenceReport} from './components/competence-report';
import dayjs from 'dayjs';
import {cfv} from '@utils/framework';
import {FC, forwardRef} from 'react';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import '../../../reporting-export/pdf-templates/export.css';
import {useStoreContext} from '@store/store-context';
import {PowerPointVector} from '@ui/atoms/icons/powerpoint-vector';
import {PowerpointObjective} from './components/powerpoint-objectives';
import {Headline2, Headline1, Body1} from '@ui/atoms/typography';
import {PowerpointKeyResult} from './components/powerpoint-keyresults';
import {capitalize} from '@utils';

dayjs.extend(advancedFormat);
const MasterSlideWrapper = styled.div`
  width: 1920px;
  height: 1164px;
  background: #585adf;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
interface IMaster {
  user: string;
  title: string;
  date: string;
  reportType: string;
}

const MasterSlide: FC<IMaster> = ({user, date, reportType, title}) => {
  return (
    <MasterSlideWrapper>
      <Headline1
        style={{
          fontSize: '100px',
          fontWeight: 'bold',
          textTransform: 'capitalize',
        }}
        kind="white">
        {title}
      </Headline1>

      <Headline1 kind="white" style={{fontWeight: 500}}>
        {reportType} Report
      </Headline1>
      <VerticalSpacer size="60px" />
      <Headline2 kind="white" style={{fontWeight: 'bold'}}>
        {date}
      </Headline2>
      <VerticalSpacer size="25%" />
      <Headline2 kind="white">{user}</Headline2>
      <VerticalSpacer size="24px" />

      <Body1 kind="white">{dayjs().format('MMMM Do YYYY')}</Body1>
    </MasterSlideWrapper>
  );
};

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 40px 150px;
  background: #f6f6f8;
  margin-bottom: 42px;
  height: 112px;
`;

const SlideHeader = ({title}: {title: string}) => {
  return (
    <HeaderWrapper>
      <Headline2 style={{fontWeight: 700}}>{title}</Headline2>
      <BeamSlideIcon />
    </HeaderWrapper>
  );
};

const SlideWrapper = styled.div`
  width: 1920px;
  height: 1164px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  .flex {
    padding: 20px 150px;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
  }
  .competence {
    padding: 20px 150px;
    width: 100%;
    align-items: flex-start;
  }
  .goals {
    width: 1162px;
    border: 1px solid #ededf2;
    border-radius: 10px;
  }
`;

const PageNumberWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  .page {
    border: 1px solid #ededf2;
    border-radius: 8px;
    color: #5f5f8c;
    width: 80px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    padding: 8px 16px;
  }
`;

const PageNumber = ({page}: {page: number}) => {
  return (
    <PageNumberWrapper>
      <div className="page">Page {page}</div>
    </PageNumberWrapper>
  );
};
const ObjectiveSlide = ({
  goals,
  date,
  title,
  pageNumber,
  summary,
}: {
  goals: Array<any>;
  date: string;
  title: string;
  pageNumber: number;
  summary: any;
}) => {
  return (
    <SlideWrapper>
      <SlideHeader title={`${date} ${capitalize(cfv().g_oals)} performance`} />
      <FlexRow className="flex" style={{height: '75%'}}>
        <div className="goals">
          {goals.map((goal, index) => (
            <PowerpointObjective goal={goal} key={index} />
          ))}
        </div>

        <div>
          <ObjectivePerformance
            lessThan50={{
              count: summary?.lessThan50 || 0,
              percent: summary?.lessThan50Percent || 0,
            }}
            lessThan75={{
              count: summary?.lessThan75 || 0,
              percent: summary?.lessThan75Percent || 0,
            }}
            lessThan100={{
              count: summary?.lessThan100 || 0,
              percent: summary?.lessThan100Percent || 0,
            }}
            HundredPercent={{
              count: summary?.completed || 0,
              percent: summary?.completedPercent || 0,
            }}
          />
          <VerticalSpacer size="32px" />

          <KeyResultPerformance
            lessThan50={{
              count: summary?.keyResults?.lessThan50 || 0,
              percent: summary?.keyResults?.lessThan50Percent || 0,
            }}
            lessThan75={{
              count: summary?.keyResults?.lessThan75 || 0,
              percent: summary?.keyResults?.lessThan75Percent || 0,
            }}
            lessThan100={{
              count: summary?.keyResults?.lessThan100 || 0,
              percent: summary?.keyResults?.lessThan100Percent || 0,
            }}
            HundredPercent={{
              count: summary?.keyResults?.completed || 0,
              percent: summary?.keyResults?.completedPercent || 0,
            }}
          />
        </div>
      </FlexRow>

      <PageNumber page={pageNumber} />
    </SlideWrapper>
  );
};

const KeyResultSlide = ({
  goals,
  summary,
  pageNumber,

  date,
  title,
}: {
  goals: Array<any>;
  summary: any;
  pageNumber: number;

  title: string;
  date: string;
}) => {
  return (
    <SlideWrapper>
      <SlideHeader
        title={`${date} ${capitalize(cfv().k_rs)} performance ${
          title ? `for ${title}` : ''
        }`}
      />
      <FlexRow className="flex" style={{height: '75%'}}>
        <div className="goals">
          {goals.map((goal, index) => (
            <PowerpointKeyResult goal={goal} key={index} />
          ))}
        </div>
        <div>
          <ObjectiveProgress
            summary={{
              total: summary?.objectives || 0,
              activeObjectives: summary?.activeObjectives || 0,
              endedObjectives: summary?.endedObjectives || 0,
              completion: summary?.goals?.fulfillment || 0,
            }}
          />
          <VerticalSpacer size="10px" />
          <KeyResultProgress
            summary={{
              total: summary?.keyResults.count || 0,
              completion: summary?.keyResults?.fulfillment || 0,
            }}
          />
        </div>
      </FlexRow>
      <PageNumber page={pageNumber} />
    </SlideWrapper>
  );
};

const KPISlide = ({
  goals,
  date,
  title,
  pageNumber,
  summary,
}: {
  goals: Array<any>;
  summary: any;
  pageNumber: number;
  title: string;
  date: string;
}) => {
  return (
    <SlideWrapper>
      <SlideHeader
        title={`${date} KPIs performance ${title ? `for ${title}` : ''}`}
      />

      <FlexRow className="flex" style={{height: '75%'}}>
        <div className="goals">
          {goals.map((goal, index) => (
            <PowerpointKpi goal={goal} key={index} />
          ))}
        </div>
        <div>
          <KPIProgress
            summary={{
              kpis: summary?.kpis || 0,
              activeKpis: summary?.activeKpis || 0,
              endedKpis: summary?.endedKpis || 0,
            }}
          />
        </div>
      </FlexRow>
      <PageNumber page={pageNumber} />
    </SlideWrapper>
  );
};

const CompetenceSlide = ({
  date,
  competence,
  pageNumber,
  title,
}: {
  date: string;
  competence: any;
  pageNumber: number;
  title: string;
}) => {
  return (
    <SlideWrapper>
      <SlideHeader
        title={`${date} Competence  performance ${title ? `for ${title}` : ''}`}
      />
      <div className="competence" style={{height: '75%'}}>
        <Headline2 style={{fontWeight: 700}}>Individual Report</Headline2>

        <FlexRow style={{alignItems: 'flex-start'}}>
          <CompetenceReport report={competence?.competenceCategories || []} />

          <div style={{marginLeft: '5%'}}>
            <CompetenceProgress
              summary={{
                keyAreasCount: competence?.keyAreasCount || 0,
                objectivesCount: competence?.objectivesCount || 0,
              }}
            />
          </div>
        </FlexRow>
      </div>
      <PageNumber page={pageNumber} />
    </SlideWrapper>
  );
};

const EndSlideWrapper = styled.div`
  width: 1920px;
  height: 1164px;
  background: #585adf;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`;

const EndSlide = () => {
  return (
    <EndSlideWrapper>
      <PowerPointVector />
    </EndSlideWrapper>
  );
};

const GeneratePowerPoint = forwardRef(
  (
    {
      goals,
      type,
      date,
      pageTitle,
      competence,
    }: {
      goals: {goals: Array<any>; summary: any};
      date: string;
      competence?: any;
      pageTitle: string;
      type: string;
    },
    ref,
  ) => {
    const goalsPerPage = 7;

    const numPages = Math.ceil(goals.goals.length / goalsPerPage);

    const goalPages = Array.from({length: numPages}, (_, i) =>
      goals.goals.slice(i * goalsPerPage, (i + 1) * goalsPerPage),
    );
    const {
      authStore: {auth},
    } = useStoreContext();
    const formatType = type === 'kpis' ? 'KPIs' : capitalize(type);

    return (
      <div ref={ref as any} id="powerpoint" className="k-pdf-export">
        <MasterSlide
          user={`${auth.user.firstName} ${auth.user.lastName}`}
          date={date}
          title={pageTitle}
          reportType={formatType}
        />

        {type === 'objectives' && (
          <div>
            {goalPages.map((pageGoals, index) => (
              <ObjectiveSlide
                key={index}
                date={date}
                goals={pageGoals}
                title={pageTitle}
                pageNumber={index + 1}
                summary={goals.summary}
              />
            ))}

            {goalPages.map((pageGoals, index) => (
              <KeyResultSlide
                goals={pageGoals}
                date={date}
                title={pageTitle}
                pageNumber={goalPages.length + index + 1}
                summary={goals.summary}
                key={index}
              />
            ))}
          </div>
        )}

        {type === 'kpis' &&
          goalPages.map((pageGoals, index) => (
            <KPISlide
              key={index}
              title={pageTitle}
              goals={pageGoals}
              pageNumber={index + 1}
              date={date}
              summary={goals.summary}
            />
          ))}

        {type === 'competence' && (
          <CompetenceSlide
            date={date}
            pageNumber={1}
            competence={competence}
            title={pageTitle}
          />
        )}

        <EndSlide />
      </div>
    );
  },
);

export default GeneratePowerPoint;
