import {observer} from 'mobx-react';
import {lazy} from 'react';
import {useNavigate} from 'react-router';
import Timezone from 'dayjs/plugin/timezone';
import dayjs from 'dayjs';
import {usePostHog} from 'posthog-js/react';
import {AdminProfileController} from './admin-profile-controller';
import {AdminProfileFormData} from './admin-profile-interface';
import {AdminProfileRequestImpl} from './admin-profile-request';
import {timezones} from '@constants';

var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);
dayjs.extend(Timezone);

const SetupPersonalProfilePage = lazy(() =>
  import('./admin-profile-page').then((module) => ({
    default: module.AdminProfilePage,
  })),
);

const SetupWorkspaceForm = lazy(() =>
  import('./admin-profile-form').then((module) => ({
    default: module.AdminProfileForm,
  })),
);

export const installAdminProfilePage = () => {
  const request = new AdminProfileRequestImpl();
  const controller = new AdminProfileController(request);

  const FormComponent = observer(() => {
    const navigate = useNavigate();
    const posthog = usePostHog();
    const userTimezone = timezones.find(
      (timezone) => timezone.label === dayjs.tz.guess(),
    );

    const onSubmit = async (data: AdminProfileFormData) => {
      const response = await controller.adminProfile({
        ...data,
        timezone: userTimezone?.value || '(GMT+01:00) Lagos',
      });

      if (response) {
        if (window?.gtag_report_conversion) {
          window.gtag_report_conversion(window.location);
        }

        posthog?.identify(response?.id, {
          email: response?.email,
        });

        posthog?.capture('frontend_admin_signup - Admin signup');

        navigate('../email-sent', {replace: true});
      }
    };

    return <SetupWorkspaceForm onSubmit={onSubmit} />;
  });

  const Page = observer(() => (
    <SetupPersonalProfilePage FormComponent={FormComponent} />
  ));

  return {
    Page,
  };
};
