import nextId from 'react-id-generator';
import React from 'react';
import {ResultsResponse} from '@api/cc-api/cc-request';
import {useCycleOverview} from '../overview.hook';
import styled from 'styled-components';
import {FlexRow, FlexRowCenter, FlexRowSpaceBetween} from '@ui/style/styles';
import {getStatusPercentage} from '@utils/proportions';
import {CustomColorIcon} from '@ui/atoms/color-icons';
import {RoundedPieChart} from '@ui/atoms/pie-chart/pie';
import {HorizontalSpacer, VerticalSpacer} from '@ui/atoms/spacer';
import {Body2} from '@ui/atoms/typography';

const ResponsiveFlexSpaceBetween = styled(FlexRowSpaceBetween)`
  > div {
    width: 50%;
    &:first-of-type {
      margin-right: 16px;
    }
  }
  @media (max-width: 500px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const StatusCard = styled.div`
  border-radius: 10px;
  border: 1px solid var(--border-light, #ededf2);
  background: #fff;
  min-height: 300px;
  .header {
    padding: 16px 16px 24px 16px;
    border-radius: 10px 10px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .body {
    border-radius: 10px;
    border-top: 1px solid var(--border-light, #ededf2);
    background: #fff;
    padding: 24px;
  }
`;

export const Ratings = ({ratings}: {ratings: ResultsResponse}) => {
  const {data: summary} = useCycleOverview();

  const ReviewCompletion = () => {
    const reviewees = {
      summary: [
        {
          name: 'Not Started',
          percentage: getStatusPercentage(
            summary?.pending,
            summary?.totalCount,
          ),
          color: '#CECEDE',
          value: summary?.pending,
        },
        {
          name: 'In-progress',
          percentage: getStatusPercentage(
            summary?.inProgress,
            summary?.totalCount,
          ),
          color: '#FFD5AD',
          value: summary?.inProgress,
        },
        {
          name: 'Completed',
          percentage: getStatusPercentage(
            summary?.completed,
            summary?.totalCount,
          ),
          color: '#47B881',
          value: summary?.completed,
        },
      ],
    };

    return (
      <StatusCard>
        <div className="header">
          <Body2 weight="semibold">Review Completion</Body2>
        </div>
        <div className="body">
          <FlexRowCenter>
            <RoundedPieChart
              data={reviewees.summary}
              chartStyle={{
                width: 165,
                height: 165,
                margin: {top: 5, right: 0, left: 4, bottom: 0},
              }}
              pieStyle={{width: '140px'}}
              totalValue={summary.totalCount}
            />
            <div style={{marginLeft: '20px'}}>
              {reviewees.summary.map((summary) => (
                <div key={summary.name} style={{width: '100%'}}>
                  <FlexRow>
                    <CustomColorIcon
                      color={summary.color}
                      width="10px"
                      height="10px"
                      margin={0}
                    />{' '}
                    <HorizontalSpacer size="10px" />
                    <Body2 kind="textBody">
                      {summary.name}:{' '}
                      <span style={{fontWeight: 500}}>{summary.value}</span>{' '}
                      <span style={{fontSize: '12px'}}>
                        {' '}
                        ({summary.percentage}%)
                      </span>
                    </Body2>{' '}
                  </FlexRow>
                  <VerticalSpacer size="14px" />
                </div>
              ))}
            </div>
          </FlexRowCenter>
        </div>
      </StatusCard>
    );
  };

  const ReviewResult = () => {
    const results = {
      summary: [
        {
          name: 'Poor',
          percentage: getStatusPercentage(
            ratings.scoresCount.poor,
            ratings.total,
          ),
          value: ratings.scoresCount.poor,

          id: nextId('bar-id'),
          color: '#CBE2F7',
        },
        {
          name: 'Needs Improvement',
          percentage: getStatusPercentage(
            ratings.scoresCount.needsImprovement,
            ratings.total,
          ),

          value: ratings.scoresCount.needsImprovement,
          id: nextId('bar-id'),
          color: '#DBEAF7',
        },
        {
          name: 'Meets Expectations',
          percentage: getStatusPercentage(
            ratings.scoresCount.meetsExpectation,
            ratings.total,
          ),
          value: ratings.scoresCount.meetsExpectation,

          id: nextId('bar-id'),
          color: '#92C2F2',
        },
        {
          name: 'Exceeds Expectations',
          percentage: getStatusPercentage(
            ratings.scoresCount.exceedsExpectation,
            ratings.total,
          ),
          value: ratings.scoresCount.exceedsExpectation,
          id: nextId('bar-id'),
          color: '#1177DB',
        },
        {
          name: 'Outstanding',
          value: ratings.scoresCount.outstanding,
          percentage: getStatusPercentage(
            ratings.scoresCount.outstanding,
            ratings.total,
          ),
          id: nextId('bar-id'),
          color: '#5AABFE',
        },
      ],
    };

    return (
      <StatusCard>
        <div className="header">
          <Body2 weight="semibold">Performance Overview</Body2>
        </div>
        <div className="body">
          <FlexRowCenter>
            <RoundedPieChart
              data={results.summary}
              chartStyle={{
                width: 165,
                height: 165,
                margin: {top: 5, right: 0, left: 4, bottom: 0},
              }}
              pieStyle={{width: '140px'}}
              totalValue={ratings.total}
            />
            <div style={{marginLeft: '20px'}}>
              {results.summary.map((summary) => (
                <div key={summary.name} style={{width: '100%'}}>
                  <FlexRow>
                    <CustomColorIcon
                      color={summary.color}
                      width="10px"
                      height="10px"
                      margin={0}
                    />{' '}
                    <HorizontalSpacer size="10px" />
                    <Body2 kind="textBody">
                      {summary.name}:{' '}
                      <span style={{fontWeight: 500}}>{summary.value}</span>{' '}
                      <span style={{fontSize: '12px'}}>
                        {' '}
                        ({summary.percentage}%)
                      </span>
                    </Body2>{' '}
                  </FlexRow>
                  <VerticalSpacer size="14px" />
                </div>
              ))}
            </div>
          </FlexRowCenter>
        </div>
      </StatusCard>
    );
  };

  return (
    <div>
      <ResponsiveFlexSpaceBetween>
        <ReviewCompletion />
        <ReviewResult />
      </ResponsiveFlexSpaceBetween>
    </div>
  );
};
