import {useStoreContext} from '@store/store-context';
import {useSearchParams} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';

export const useShowGroupTypeDrawer = () => {
  const {
    groupStore: {updateGroupDrawer},
  } = useStoreContext();
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const activateGroupDrawer = () => {
    updateGroupDrawer(true);
    searchParams.set('group-drawer', 'true');
    navigate({search: `?group-drawer=true`});
  };
  return activateGroupDrawer;
};
