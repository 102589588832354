import styled from 'styled-components';
import {CSSProperties, memo} from 'react';
import {CTA} from '../../atoms/typography/cta';
import {getColorFromTheme} from '../../ui-utils';
import {MeasurementType} from '../../interface';
import {useBreakpoints} from '../../../utils/use-breakpoints';
import {Tooltip} from '../tooltip';
import {Body2} from '../../atoms/typography/body2';
import {kpiValueWeighting} from '../../../utils/framework';
import {authStore} from '../../../store/stores/auth-store';

const Wrapper = styled.div<{type: string | undefined}>`
  display: inline-grid;
  grid-template-columns: ${({type}) =>
    `${
      type === 'admin'
        ? '150px auto !important'
        : type === 'admin-plus'
        ? '100% auto'
        : type === 'graph'
        ? '50% auto !important'
        : type === 'graph-okr'
        ? '70% auto !important'
        : '68px auto'
    }`};
  grid-column-gap: ${({type}) => `${type === 'graph-okr' ? '2px' : '5px'}`};
  flex-direction: row;
  align-items: center;
  @media (max-width: 900px) {
    grid-template-columns: auto auto;
  }
`;

const ProgressBackground = styled.span<{type: string | undefined}>`
  display: flex;
  position: relative;
  background-color: ${getColorFromTheme('borderLight')};
  height: 4px;
  width: ${({type}) =>
    `${type === 'admin' ? '160px' : type === 'admin-plus' ? '100%' : '68px'}`};
  border-radius: 8px;
  overflow: hidden;
  max-width: 100%;
`;

const ProgressIndicator = styled.span<{width: number}>`
  position: absolute;
  height: 4px;
  border-radius: 8px;
  left: 0;
  bottom: 0;
  top: 0;
  width: ${({width}) => `${width}%`};
  background-color: ${getColorFromTheme('purple300')};
`;

export interface TProps {
  state: string;
  measurement: MeasurementType;
  percent: number;
  type?: string;
  pStyle?: any;

  wStyle?: CSSProperties;
  kpi?: any;
  lowercaseKpi?: boolean;
}

export const ProgressBar = memo(function ({
  state,
  measurement,
  percent,
  type,
  pStyle,
  wStyle,
  kpi,
  lowercaseKpi,
}: TProps) {
  const toLowerCase_ = (text?: string, lowercase?: boolean) => {
    return lowercase ? text?.toLowerCase() : text;
  };
  const screenSize = useBreakpoints();
  return (
    <Wrapper type={type} style={wStyle}>
      <div>
        {/* {measurement !== MeasurementType.BINARY && ( */}
        <ProgressBackground type={type} style={pStyle}>
          <ProgressIndicator width={percent} />
        </ProgressBackground>
        {/* )} */}
      </div>
      <div>
        {type === 'performance' && (
          <CTA kind="textDark" style={{marginLeft: '7px'}}>
            {state}
          </CTA>
        )}
        {type === 'admin-plus' ? (
          <Body2 kind="textDark">
            {' '}
            {authStore?.auth?.user?.workspace?.framework === 'kpi'
              ? `${state}${
                  kpi
                    ? ' - ' +
                      toLowerCase_(
                        kpiValueWeighting(kpi?.current, kpi),
                        lowercaseKpi,
                      )
                    : ''
                }`
              : `${state}`}
          </Body2>
        ) : (
          type !== 'performance' && (
            <>
              {type === 'keyResult' && screenSize.isXs ? (
                <>
                  <Tooltip
                    maxLength={10}
                    bodyStyle={{fontWeight: 500}}
                    tooltipBody={<CTA kind="textDark">{state}</CTA>}
                    text={state}
                  />
                  <span>{state.length > 10 ? ' ...%' : ''}</span>
                </>
              ) : (
                <CTA kind="textDark">
                  {authStore?.auth?.user?.workspace?.framework === 'kpi'
                    ? `${state}${
                        kpi
                          ? ' - ' +
                            toLowerCase_(
                              kpiValueWeighting(kpi?.current, kpi),
                              lowercaseKpi,
                            )
                          : ''
                      }`
                    : `${state}`}
                </CTA>
              )}
            </>
          )
        )}
      </div>
    </Wrapper>
  );
});
ProgressBar.displayName = 'ProgressBar';
