import ReactSelect, {
  components,
  OptionsType,
  ValueType,
  ActionMeta,
  Props,
  IndicatorProps,
  MenuProps,
  PlaceholderProps,
  SingleValueProps,
} from 'react-select';
import {MagnifyingGlassIcon} from '../../../atoms/icons/magnifying-glass';
import {IndicatorWrapper, getStyles} from './styled';
import {CustomColorIcon} from '@ui/atoms/color-icons';
import {HorizontalSpacer, VerticalSpacer} from '../../../atoms/spacer';

import {Body1, Body2, CTA, Microcopy} from '../../../atoms/typography';
import {forwardRef, Ref, useCallback, useMemo, useState} from 'react';
import {FlexRow} from '@ui/style/styles';
import {GreenWhitePlusIcon} from '@ui/atoms/icons/greenWhitePlus';

interface IOption {
  label: {goalName: string};
}

const filterUserSelectOptions = (option: {data: IOption}, rawInput: string) => {
  if (rawInput) {
    const label = option.data.label.goalName?.toLowerCase() || '';
    return rawInput
      .toLowerCase()
      .split(' ')
      .every((word) => label.includes(word));
  }
  return true;
};

const {
  DropdownIndicator: SelectDropdownIndicator,
  Option: SelectOption,
  Menu: MenuOptions,
  Placeholder: SelectPlaceholder,
  SingleValue: SelectSingleValue,
} = components;

export interface TLabel {
  name: string;
  startDate: string;
  time: string;
}

export interface TOption {
  value: string;
  label: TLabel;
}

export interface SelectFieldProps extends Props<TOption> {
  options: OptionsType<TOption>;
  onChange?: (
    value: ValueType<TOption, false>,
    action: ActionMeta<TOption>,
  ) => void;
}

const DropdownIndicator = (props: IndicatorProps<TOption, false>) => {
  return (
    <div style={{display: props.hasValue ? 'none' : ''}}>
      <SelectDropdownIndicator {...props}>
        <IndicatorWrapper>
          <MagnifyingGlassIcon />
        </IndicatorWrapper>
      </SelectDropdownIndicator>
    </div>
  );
};

const Placeholder = (props: PlaceholderProps<TOption, false>) => {
  return (
    <SelectPlaceholder {...props}>
      <Body1 kind="textBody">{props.children}</Body1>
    </SelectPlaceholder>
  );
};

const Menu = (props: MenuProps<TOption, false>) => {
  const addCustomOption =
    props.selectProps.options &&
    props.selectProps.options.find((option) => option.label === 'custom');
  const action = (data: any) => {
    return;
  };
  return (
    <>
      <MenuOptions {...props}>
        <div>
          {props.children}
          {addCustomOption && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '8px 16px',
                cursor: 'pointer',
              }}
              onClick={() =>
                props.selectProps.onChange &&
                props.selectProps.onChange(
                  addCustomOption as any,
                  action as any,
                )
              }>
              <GreenWhitePlusIcon /> <HorizontalSpacer size="16px" />{' '}
              <CTA kind="textDark" style={{fontWeight: 600}}>
                {props.selectProps.customOptionName ||
                  'Create your custom question'}
              </CTA>
            </div>
          )}
        </div>
      </MenuOptions>
    </>
  );
};

const Option = (props: any) => {
  return (
    <>
      <SelectOption {...props}>
        {props.label === 'custom' ? (
          <></>
        ) : (
          <>
            <Body2 kind="textDark" weight="bold">
              {props?.label && ((props.label as unknown) as TLabel).name}
            </Body2>
            <VerticalSpacer size="8px" />

            <FlexRow style={{marginTop: '4px'}}>
              <Microcopy
                kind="textBody"
                style={{
                  margin: '0 6px 0 0px',
                  fontWeight: 400,
                }}>
                {props?.label && ((props.label as unknown) as TLabel).startDate}
              </Microcopy>
              <HorizontalSpacer size="10px" />
              <CustomColorIcon
                height="8px"
                width="8px"
                margin={3}
                color="#D9D9D9"
              />
              <Microcopy
                kind="textBody"
                style={{
                  margin: '0 6px 0 0px',
                  fontWeight: 400,
                }}>
                {props?.label && ((props.label as unknown) as TLabel).time}
              </Microcopy>
            </FlexRow>
          </>
        )}
      </SelectOption>

      {/* DO NOT DELETE */}
      {/* <AdditionalOptionsWrapper
        display={props.isSelected || props.data.isEditing}>
        <RadioWrapper
          onClick={() => {
            setIsChecked(isChecked === true ? false : true);
          }}>
          <RadioSm checked={!isChecked} />
          <HorizontalSpacer size="8px" />
          <Body2 kind={!isChecked ? 'textDark' : 'textBody'}>
            Align your progress to this parent goal
          </Body2>
        </RadioWrapper>
        <VerticalSpacer size="8px" />
        <RadioWrapper
          onClick={() => {
            setIsChecked(isChecked === true ? false : true);
          }}>
          <RadioSm checked={isChecked} />
          <HorizontalSpacer size="8px" />
          <Body2 kind={isChecked ? 'textDark' : 'textBody'}>
            Don't align your progress to this parent goal
          </Body2>
        </RadioWrapper>
      </AdditionalOptionsWrapper> */}
    </>
  );
};

const SingleValue = (props: SingleValueProps<TOption>) => {
  return (
    <SelectSingleValue {...props}>
      <Body1 kind="textDark">
        {((props.children as unknown) as TLabel).startDate}
      </Body1>
    </SelectSingleValue>
  );
};

export const MeetingSelect = forwardRef(
  (
    {
      options,
      placeholder,
      onChange,
      value,
      defaultValue,
      useAllOptions,
      ...rest
    }: any,
    ref: Ref<any>,
  ) => {
    const styles = useMemo(() => getStyles<TOption, false>(), []);

    const [innerValue, setInnerValue] = useState<ValueType<TOption, false>>(
      // @ts-ignore
      value,
    );

    const onSelectChange = useCallback(
      (value: ValueType<TOption, false>, action: ActionMeta<TOption>) => {
        if (onChange) {
          onChange(value, action);
        }
        setInnerValue(value);
      },
      [onChange],
    );

    return (
      <ReactSelect
        {...rest}
        isClearable
        // isMulti
        backspaceRemovesValue
        hideSelectedOptions={false}
        menuIsOpen
        filterOption={filterUserSelectOptions}
        isSearchable
        value={innerValue}
        noOptionsMessage={() => `No 1:1 found`}
        tabSelectsValue={false}
        placeholder={placeholder}
        onChange={onSelectChange}
        ref={ref}
        maxMenuHeight={240}
        components={{
          DropdownIndicator,
          Option,
          Placeholder,
          Menu,
          SingleValue,
          IndicatorSeparator: null,
        }}
        options={
          !useAllOptions && innerValue && Object.keys(innerValue).length > 0
            ? [innerValue]
            : options
        }
        styles={styles}
        theme={(theme) => ({
          ...theme,
          borderRadius: 7,
          spacing: {
            baseUnit: 6,
            controlHeight: 40,
            menuGutter: 6,
          },
        })}
      />
    );
  },
);
