import styled, {css} from 'styled-components';
import {getColorFromTheme} from '../../../ui-utils';
import {down} from 'styled-breakpoints';
import {ifProp, switchProp} from 'styled-tools';

export const DropdownWrapper = styled.div`
  position: absolute;
  display: block;
  width: 100%;
  top: 120%;
  border-radius: 8px;
  border: solid 1px ${getColorFromTheme('borderDark')};
  background-color: ${getColorFromTheme('white')};
  box-shadow: ${({theme}) => theme.elevation.level2};
  z-index: 9999;
`;

export const DropWrapper = styled.div`
  position: relative;
`;
export const Stack = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px;
  align-items: center;
  width: 100%;
  cursor: pointer;
`;
export const Wrapper = styled.div<{hasLabel?: boolean}>`
  position: relative;
  padding-top: ${({hasLabel}) => (hasLabel ? '26px' : '0')};
`;

export const ArrowIconWrapper = styled.span<{disabled?: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    stroke: ${getColorFromTheme('textBody')};
    width: 20px;
    height: 20px;
    ${ifProp(
      'disabled',
      css`
        stroke: #bfbfd4;
      `,
    )};
  }
`;
export const GroupArrowWrapper = styled.span<{disabled?: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    stroke: ${getColorFromTheme('textBody')};
    width: 16px;
    height: 16px;
    ${ifProp(
      'disabled',
      css`
        stroke: #bfbfd4;
      `,
    )};
  }
`;

export const AvatarWrapper = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const DropdownHandler = styled.button<{
  open: boolean;
  height?: any;
  disabled?: boolean;
  state?: any;
  performance?: boolean;
  reportingStyling?: boolean;
}>`
  outline: none;
  border-radius: ${({performance}) => (performance ? '10px' : '7px')};
  padding: 5px 14px;

  color: ${getColorFromTheme('textDark')};
  width: 100%;
  display: block;
  height: ${(props: any) =>
    props.height ? props.height : props.reportingStyling ? '44px' : '52px'};
  background-color: ${getColorFromTheme('white')};
  transition: 200ms ease all;
  border: 1px solid;
  border-color: ${getColorFromTheme('borderDark')};

  ${switchProp('state', {
    error: css`
      border-color: ${getColorFromTheme('red400')};
    `,
  })}
  &:focus:not(:invalid) {
    outline: 0;
    border-color: ${getColorFromTheme('purple300')};
  }

  ${ifProp(
    'open',
    css`
      outline: 0;
      border-color: ${getColorFromTheme('purple300')};
    `,
  )};
  ${ifProp(
    'disabled',
    css`
      outline: 0;
      cursor: not-allowed;
      border-color: #cecede;
      color: #bfbfd4;
    `,
  )};
`;

export const HandlerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const OptionsWrapper = styled.span<{disabled?: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  p.greyOut {
    color: #5f5f8c;
    font-size: 14px;
    ${ifProp(
      'disabled',
      css`
        outline: 0;
        cursor: not-allowed;
        color: #bfbfd4;
      `,
    )};
  }
  ${down('sm')} {
    overflow-y: auto;
  }
`;

export const OptionWrapper = styled.div<{active?: boolean}>`
  padding: 12px 16px;
  margin: 0 12px;
  border-radius: 8px;

  font-family: 'Graphik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 170%;

  &:hover {
    background: #f6f6f8;
    p {
      color: #1e1e2f;
      font-weight: 500;
    }
  }

  p {
    color: ${getColorFromTheme('textBody')};

    font-family: 'Graphik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 146%;
  }
  ${({active}) =>
    active &&
    css`
      p {
        // color: ${getColorFromTheme('blue500')}

        font-family: 'Graphik';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 170%;

        /* Text/Dark */
        color: #1e1e2f;
      }
    `}
`;
