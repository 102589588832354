import styled from 'styled-components';
import {Body2, Body1, Headline1} from '@ui/atoms/typography';
import {
  Flex,
  FlexRowSpaceBetween,
  FlexRow,
  FlexRowCenter,
} from '@ui/style/styles';
import {CustomColorIcon} from '@ui/atoms/color-icons';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {ReactElement, ReactNode} from 'react';
import {Button} from '@ui/atoms/button';

const Wrapper = styled.div`
  .filter-nav {
  }

  .top {
    margin-top: 16px;
    padding-top: 8px;
  }
`;
interface IContainer {
  Header: ReactNode;
  children: ReactNode;
}

export const CancelIcon = () => {
  return (
    <div
      style={{
        background: '#F6F6F8',
        borderRadius: '100px',
        width: '22px',
        marginLeft: '8px',
        cursor: 'pointer',
        display: 'flex',
        padding: '4px',
        alignItems: 'center',
        justifyContent: 'center',
        height: '22px',
      }}>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.5 3.5L3.5 10.5"
          stroke="#1E1E2F"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3.5 3.5L10.5 10.5"
          stroke="#1E1E2F"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
};
export const OverallWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 24px 16px 24px;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .sort {
    display: flex;
    align-items: center;

    width: 100%;
    @media (max-width: 800px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 25px;
    }
  }
`;
export const KPIBoardWrapper = styled.div`
  border: 1px solid #ededf2;
  margin-top: 16px;
  padding: 24px;
  box-shadow: 0px 3px 24px rgba(208, 208, 221, 0.16);
  border-radius: 10px;
`;

export const KPISaveButton = styled(Button)`
  padding: 8px 16px;
  width: 98px;
  height: 38px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InnerWrapper = styled.div`
  padding: 16px;
  background: #ffffff;
  border: 1px solid #ededf2;
  min-width: 49%;
  width: 100%;

  box-shadow: 0px 3px 24px rgba(208, 208, 221, 0.16);
  border-radius: 10px;
  @media (max-width: 500px) {
    margin-bottom: 10px;
  }
`;
interface ITitle {
  title: string;
}

type Obj = {
  title: string;
  value: number | string | ReactNode;
};

interface ISummary extends ITitle {
  percentageValue: number;
  subtitle: Obj;
  totalValue: Obj;
}

interface IInner extends ITitle {
  children: ReactElement;
  fixedChildren?: ReactElement;
  show: boolean;
  setShow: any;
}

const PercentageValueWrapper = styled.div`
  width: 120px;
  height: 60px;
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f6f6f8;
  border-radius: 16px;
`;
export const SummaryCard = ({
  title,
  percentageValue,
  subtitle,
  totalValue,
}: ISummary) => {
  return (
    <InnerWrapper style={{padding: '24px'}}>
      <Body1 weight="semibold">{title}</Body1>
      <VerticalSpacer size="10px" />
      <PercentageValueWrapper>
        <Headline1>{percentageValue}%</Headline1>
      </PercentageValueWrapper>
      <FlexRowSpaceBetween>
        <FlexRow>
          <CustomColorIcon
            color="#E4E5FB"
            width="10px"
            height="10px"
            margin={5}
          />
          <Body2>{subtitle.title}</Body2>
        </FlexRow>
        {typeof subtitle.value === 'number' ||
        typeof subtitle.value === 'string' ? (
          <Body2>{subtitle.value}</Body2>
        ) : (
          subtitle.value
        )}
      </FlexRowSpaceBetween>
      <FlexRowSpaceBetween
        style={{
          marginTop: '10px',
          borderTop: '1px solid #ededf2',
          paddingTop: '16px',
        }}>
        <Body2 weight="semibold">{totalValue.title}</Body2>

        <Body2 weight="semibold">{totalValue.value}</Body2>
      </FlexRowSpaceBetween>
    </InnerWrapper>
  );
};

export const ContainerWrapper = ({Header, children}: IContainer) => {
  return (
    <>
      <Wrapper>
        <div>{Header}</div>
        <div className="filter-nav top">{children}</div>
      </Wrapper>
    </>
  );
};

export const InnerContainers = ({
  title,
  children,
  show,
  setShow,
  fixedChildren,
}: IInner) => {
  return (
    <InnerWrapper>
      <Flex justifyContent="space-between" alignItems="center">
        <Body1 weight="semibold">{title}</Body1>
        <Body2
          weight="bold"
          kind="purple300"
          style={{cursor: 'pointer'}}
          onClick={() => setShow(!show)}>
          {!show ? 'Expand' : 'Collapse'}
        </Body2>
      </Flex>
      {show && <Childwrapper>{children}</Childwrapper>}
      {fixedChildren}
    </InnerWrapper>
  );
};

export const Childwrapper = styled.div`
  padding: 24px 16px 10px;
  border: 1px solid #ededf2;
  margin-top: 16px;
  border-radius: 10px;
  width: 100%;
`;

export const CustomButton = styled(Button)<{_width?: string}>`
  width: ${({_width}) => (_width ? _width : '125px')};
  height: 36px;
  padding: 8px 16px;
  display: flex;
  margin-left: 10px;
  align-items: center;
  overflow: hidden;
  @media (max-width: 800px) {
    margin-left: 0px;
  }
`;

export const ExportPDF = styled.div<{exporting?: boolean}>`
  margin: 5px 10px 0px;
`;

export const DateCompareWrapper = styled.div`
  border: 1px solid #ededf2;
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin: 6px 18px 6px 24px;
  height: 46px;
  justify-content: center;
`;

export const ChildrenWrapper = styled.div`
  margin-top: 10px;
  border: 1px solid #ededf2;
  background-color: white;
  border-radius: 10px;
  padding-top: 10px;
  .children-div {
    margin: 14px 20px;
  }
`;
export const HideMobileView = styled.div`
  @media (max-width: 500px) {
    display: none;
  }
`;
export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 48% 50%;
  grid-gap: 16px;

  @media (max-width: 500px) {
    display: block;
  }
`;

export const ModalCardWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  div.child {
    width: 40%;
    @media (min-width: 800px) and (max-width: 1240px) {
      width: 40%;
    }
    @media (max-width: 800px) {
      min-width: 90%;
      margin: 10% 0px;
    }
  }
  @media (max-width: 800px) {
    justify-content: center;
  }
`;
export const EditCard = styled.div`
  border: 1px solid #ededf2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  height: 52px;
  border-radius: 10px;
  padding: 16px;
`;

export const LoadingWrapper = styled(FlexRowCenter)`
  height: 200px;
`;
export const NoBorderButton = styled.button`
  border: 0;
  background-color: transparent;
  padding: 0;
  &:disabled {
    cursor: not-allowed;
  }
`;
