import {motion} from 'framer-motion';

import styled from 'styled-components';
import {getColorFromTheme} from '../../../../../../../ui/ui-utils';

export const Wrapper = styled.div<{
  type?: string;
  variant?: string;
  hasKeyResults: boolean;
  isOpen: any;
}>`
  background: #ffffff;
  border-width: ${(props: any) =>
    props.variant === 'companyTeams'
      ? '1px'
      : props.variant !== 'companyTeams' && props.type === 'child'
      ? '1px'
      : undefined};
  border-style: ${(props: any) =>
    props.variant === 'companyTeams'
      ? 'solid'
      : props.variant !== 'companyTeams' && props.type === 'child'
      ? 'solid'
      : undefined};
  border-color: ${(props: any) =>
    props.variant === 'companyTeams'
      ? getColorFromTheme('borderLight')
      : props.variant !== 'companyTeams' && props.type === 'child'
      ? getColorFromTheme('borderLight')
      : undefined};

  border-radius: ${(props: any) =>
    props.variant === 'companyTeams' && (!props.hasKeyResults || !props.isOpen)
      ? '10px'
      : props.variant === 'companyTeams' && props.hasKeyResults && props.isOpen
      ? '10px 10px 0px 0px'
      : props.variant !== 'companyTeams' && props.type === 'child'
      ? '10px 0px 0px 10px'
      : undefined};
  margin-top: ${(props: any) =>
    props.variant === 'companyTeams'
      ? '4px'
      : props.variant !== 'companyTeams' && props.type === 'child'
      ? '4px'
      : undefined};
  margin-bottom: ${(props: any) =>
    props.variant === 'companyTeams' && !props.hasKeyResults
      ? '4px'
      : props.variant === 'companyTeams' && props.hasKeyResults
      ? undefined
      : props.variant !== 'companyTeams' && props.type === 'child'
      ? '4px'
      : undefined};
`;
export const SecondWrapperChildGoals = styled.div<{
  variant?: string;
  isBinary?: boolean;
}>`
  display: grid;
  grid-template-columns: ${(props: any) =>
    props.variant === 'companyTeams' ? '35% 26% 26% 13%' : ' 40% 18% 20% 12%'};
  grid-gap: 1%;
  font-size: 14px;
  color: ${getColorFromTheme('textDark')};
  border-top-width: ${(props: any) =>
    props.variant === 'companyTeams'
      ? undefined
      : props.variant !== 'companyTeams' && props.type === 'child'
      ? '1px'
      : '1px'};
  border-top-style: ${(props: any) =>
    props.variant === 'companyTeams'
      ? undefined
      : props.variant !== 'companyTeams' && props.type === 'child'
      ? 'solid'
      : 'solid'};
  border-top-color: ${(props: any) =>
    props.variant === 'companyTeams'
      ? undefined
      : props.variant !== 'companyTeams' && props.type === 'child'
      ? getColorFromTheme('borderLight')
      : getColorFromTheme('borderLight')};
  padding: 16px;
  p {
    overflow: hidden;
    white-space: nowrap;
    line-height: 30px;
    text-overflow: ellipsis;
  }
  .goalProgress .tooltip {
    visibility: hidden;
    width: auto;
    background-color: rgba(0, 0, 0, 0.7);
    font-size: 12px;
    color: #fff;
    text-align: center;
    padding: 12px;
    border-radius: 6px;
    position: absolute;
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 1;
    margin-top: -50px;
    margin-left: -7%;
  }
  .goalProgress:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }

  .goalProgress .tooltip::after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
  .ellipsis {
    overflow: hidden;
    white-space: nowrap;
    line-height: 30px;
    text-overflow: ellipsis;
    line-height: 75px;
    padding-right: 8px;
  }

  .tooltip_container {
    display: inline-flex;
    padding-right: 24px;
  }

  .tooltip_container .tooltip {
    visibility: hidden;
    width: auto;
    background-color: rgba(0, 0, 0, 0.7);
    font-size: 12px;
    color: #fff;
    text-align: center;
    padding: 12px;
    border-radius: 6px;
    position: absolute;
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 1;
    margin-top: -20px;
  }
  .tooltip_container:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }

  .tooltip_container .tooltip::after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
`;

export const SecondWrapperChildNestedKeyResults = styled.div<{
  variant?: string;
}>`
  display: grid;
  grid-template-columns: ${(props: any) =>
    props.variant === 'companyTeams' ? '35% 27% 10% ' : '35% 28% 32% 2%'};
  font-size: 14px;
  color: ${getColorFromTheme('textDark')};
  background: ${getColorFromTheme('backgroundLight')};
  border-left: 1px solid ${getColorFromTheme('borderDark')};
  margin: 0px 0px 0px 25px;
  padding: 22px 14px 22px 0px;
  p {
    overflow: hidden;
    white-space: nowrap;
    line-height: 30px;
    text-overflow: ellipsis;
  }
`;

export const NestedDivider = styled(motion.div)<{variant?: string}>`
  border-top: 1px solid ${getColorFromTheme('borderLight')};
  margin-left: -16px;
  margin-right: ${(props: any) =>
    props.variant === 'companyTeams' ? undefined : '1px'};
`;

export const Content = styled(motion.div)<{variant?: string}>`
  margin-right: 0px !important;
  margin-left: 16px !important;
  border-bottom-left-radius: ${(props: any) =>
    props.variant === 'companyTeams' ? '10px' : undefined};

  border-bottom-right-radius: ${(props: any) =>
    props.variant === 'companyTeams' ? '10px' : undefined};
`;

export const ProgressWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 16px;
  @media (max-width: 900px) {
    display: none;
  }
`;
export const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
