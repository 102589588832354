import PublicWall from './public-wall/public-wall';
import {FeedBackDirectReports} from './direct-reports/feedback-direct-reports';
import MyFeedBacks from './my-feedback/my-feedback';
import {Routes, Route, useNavigate} from 'react-router';
import {observer} from 'mobx-react-lite';
import {RequestIndicator} from './feedback-styles';
import {useIsRouteActive} from '@utils/route-href';
import {FeedbackRequestImpl} from './feedback-requests';
import {FeedbackController} from './feedback-controller';
import {useMemo, useEffect, useCallback} from 'react';
import {useStoreContext} from '@store/store-context';
import {FlexRowSpaceBetween} from '@ui/style/styles';
import {Body1, Headline2} from '@ui/atoms/typography';
import {TabNavigation} from '@ui/molecules/tab-nav';
import {
  DropdownItem,
  MenuButtonPrimary,
} from '@pages/dashboard/you/you-page.styles';
import {ArrowHeadDownIcon} from '@ui/atoms/icons';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {DropdownCustom} from '@ui/molecules/dropdown-custom';

const FEEDBACK_ROUTES = {
  PUBLIC_WALL: `/feedback/public-wall`,
  MY_FEEDBACK: `/feedback/my-feedback`,
  DIRECT_REPORTS: `/feedback/direct-reports`,
};

export const Feedback = observer(() => {
  const {
    storeDashboardRoutes: {activateFeedbackTabs, activateDashboardTab},
    authStore: {auth},
    feedbackStore: {
      updateFeedbackRequest,
      managerFeedbackRequests: feedbackRequests,
    },
  } = useStoreContext();

  const navigate = useNavigate();

  const {isRouteActive} = useIsRouteActive();

  const isPublicWallActive = useMemo(
    () => isRouteActive(FEEDBACK_ROUTES.PUBLIC_WALL),
    [isRouteActive],
  );

  const isMyFeedBackActive = useMemo(
    () => isRouteActive(FEEDBACK_ROUTES.MY_FEEDBACK),
    [isRouteActive],
  );

  const isDirectReportsActive = useMemo(
    () => isRouteActive(FEEDBACK_ROUTES.DIRECT_REPORTS),
    [isRouteActive],
  );

  const handleWriteFeedback = () => {
    navigate('/write-feedback');
  };
  const handleRequestFeedback = () => {
    navigate('/request-feedback');
  };
  const handleWritePraise = () => {
    navigate('/write-feedback?type=praise');
  };

  const DropdownOptions = [
    {
      label: 'Write Feedback',
      action: () => handleWriteFeedback(),
    },
    {
      label: 'Request feedback',
      action: () => handleRequestFeedback(),
    },
    {
      label: 'Write Praise',
      action: () => handleWritePraise(),
    },
  ];

  const getManagerFeedbacks = useCallback(async () => {
    const request = new FeedbackRequestImpl();
    const controller = new FeedbackController(request);

    const response = await controller.getRequestFeedback(auth.user.id);

    updateFeedbackRequest(response);
  }, [auth.user.id, updateFeedbackRequest]);

  useEffect(() => {
    getManagerFeedbacks();
  }, [getManagerFeedbacks]);

  useEffect(() => {
    activateDashboardTab('feedback');
  }, [activateDashboardTab]);

  const ObjNav: any = useMemo(() => {
    const nav = [
      {
        active: isPublicWallActive,
        href: FEEDBACK_ROUTES.PUBLIC_WALL,
        id: '1',
        onClick: () => {
          activateFeedbackTabs('publicWall');
        },
        icon:
          feedbackRequests?.length > 0 ? (
            <RequestIndicator>{feedbackRequests.length || 0}</RequestIndicator>
          ) : (
            <></>
          ),
        iconPosition: '',

        title: `Public praise wall`,
      },
      {
        active: isMyFeedBackActive,
        disable: false,
        href: FEEDBACK_ROUTES.MY_FEEDBACK,
        id: '1',
        onClick: () => {
          activateFeedbackTabs('myFeedback');
        },
        icon: <></>,
        iconPosition: '',

        title: `Feedback received`,
      },
      {
        active: isDirectReportsActive,
        href: FEEDBACK_ROUTES.DIRECT_REPORTS,
        id: '1',
        onClick: () => {
          activateFeedbackTabs('directReports');
        },
        icon: <></>,
        title: 'Feedback written',
        iconPosition: '',
      },
    ];

    return nav;
  }, [
    activateFeedbackTabs,
    feedbackRequests,
    isDirectReportsActive,
    isMyFeedBackActive,
    isPublicWallActive,
  ]);

  return (
    <>
      <FlexRowSpaceBetween className="mb-10">
        <div className="">
          <Headline2>Feedback</Headline2>
          <div className="w-[80%] mt-1">
            <Body1 kind="textBody">
              Feedback requests, public praise, and writing feedback for
              colleagues and direct reports.
            </Body1>
          </div>
        </div>

        <DropdownCustom
          collapseOnClick={true}
          menu={(handleClose: () => void) => (
            <div>
              {DropdownOptions.map((_option) => (
                <DropdownItem
                  onClick={() => {
                    _option.action();
                    handleClose();
                  }}>
                  {_option.label}
                </DropdownItem>
              ))}
            </div>
          )}
          customComponent={(
            handleOpen: (event: any) => void,
            open: boolean,
          ) => (
            <MenuButtonPrimary onClick={handleOpen} width="sm">
              Create
              <ArrowHeadDownIcon
                style={{
                  marginLeft: 14,
                  stroke: '#fff',
                  transform: !open ? 'rotate(0deg)' : 'rotate(180deg)',
                }}
              />
            </MenuButtonPrimary>
          )}
          customDropdownWrapperStyles={{
            top: '130%',
            right: '0px',
            width: '120%',
          }}
        />
      </FlexRowSpaceBetween>

      <TabNavigation navigations={ObjNav} />

      <VerticalSpacer size="24px" />

      <Routes>
        <Route path="/public-wall" element={<PublicWall />} />
        <Route path="/my-feedback" element={<MyFeedBacks />} />
        <Route path="/direct-reports" element={<FeedBackDirectReports />} />
      </Routes>
    </>
  );
});
