import {useEffect, useRef} from 'react';

export function useEventListener(
  eventName: string,
  handler: CallableFunction,
  element: HTMLElement | Document = document,
) {
  const savedHandler = useRef<CallableFunction>(() => {});

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) return;

    const eventListener: EventListenerOrEventListenerObject = (event: Event) =>
      savedHandler.current(event);

    element.addEventListener(eventName, eventListener);

    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
}
