import {action} from 'mobx';
import {activateNotification} from '../../../../../ui/molecules/notification/activate-notification';
import {parseErrorContent} from '../../../../../utils/error-handling';
import {WorkspaceFormData} from './admin-workspace-interface';
import {WorkspaceRequest} from './admin-workspace-request';

export class WorkspaceController {
  constructor(private readonly request: WorkspaceRequest) {}

  @action
  async newWorkspace(data: WorkspaceFormData, accessToken: string) {
    try {
      await this.request.adminWorkspace(data, accessToken);
      activateNotification({
        title: 'Success',
        content: 'Workspace info has been updated',
        kind: 'success',
      });
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
    }
  }

  @action
  async newAvatar(data: WorkspaceFormData, accessToken: string) {
    try {
      await this.request.avatarUpload(data, accessToken);
      activateNotification({
        title: 'Success',
        content: 'Avatar Uploaded',
        kind: 'success',
      });
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
    }
  }
}
