import {Modal} from '@ui/molecules/modal';
import {CancelIcon} from '@ui/atoms/icons';
import styled from 'styled-components';
import {Body2, Headline3} from '@ui/atoms/typography';
import {getColorFromTheme} from '@ui/ui-utils';
import {FlexRowSpaceBetween, FlexRowEnd} from '@ui/style/styles';
import {IllustrationProps} from '@ui/assets/illustrations';
import {Image} from '@ui/atoms/image';
import {useNavigate} from 'react-router';
import {useStoreContext} from '@store/store-context';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {BackAction} from '@ui/molecules/back-action';
import {Button} from '@ui/atoms/button';

interface FreeTierModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  description: string;
  image: IllustrationProps;
}
const OuterWrapper = styled.main`
  width: 100%;
  box-shadow: 0px 0px 0px 1px ${getColorFromTheme('borderLight')};
  background-color: ${getColorFromTheme('white')};
  border-radius: 10px;
`;

const Wrapper = styled.div`
  padding: 40px;
`;

const FreeTierModal = ({
  open,
  onClose,
  image,
  title,
  description,
}: FreeTierModalProps) => {
  const navigate = useNavigate();

  const {
    authStore: {auth},
  } = useStoreContext();
  return (
    <div>
      <Modal open={open} onClose={onClose}>
        <div
          style={{
            maxWidth: '840px',
            margin: 'auto',
          }}>
          <FlexRowEnd style={{width: '100%'}}>
            <BackAction
              width="md"
              icon={<CancelIcon />}
              onClick={onClose}
              title="Close"
            />
          </FlexRowEnd>
          <VerticalSpacer size="16px" />
          <OuterWrapper>
            <Wrapper>
              <FlexRowSpaceBetween>
                <div style={{width: '47%'}}>
                  <Body2 weight="semibold">Upgrade to Standard plan</Body2>
                  <VerticalSpacer size="14px" />

                  <Headline3>{title}</Headline3>
                  <VerticalSpacer size="10px" />
                  <Body2 kind="textBody">{description}</Body2>
                  <VerticalSpacer size="40px" />
                  <Button
                    style={{padding: '8px 16px ', width: '206px'}}
                    disabled={auth.user.role !== 'admin'}
                    onClick={() => navigate('/workspace-settings/billing')}>
                    Upgrade to Standard
                  </Button>
                  <VerticalSpacer size="16px" />
                </div>
                <div>
                  <Image {...image} alt="image" width={'310px'} />
                </div>
              </FlexRowSpaceBetween>
            </Wrapper>
          </OuterWrapper>
        </div>
      </Modal>
    </div>
  );
};

export default FreeTierModal;
