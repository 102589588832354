import styled from 'styled-components';
import {AnimateSharedLayout} from 'framer-motion';
import {
  ChangeEvent,
  forwardRef,
  ReactElement,
  Ref,
  useCallback,
  useMemo,
} from 'react';
import {InputState, MeasurementType} from '../../../interface';
import {FieldWrapper} from '../../../atoms/field-wrapper';
import {Helper} from '../../../atoms/helper';
import {cfv} from '@utils/framework';
import {capitalize} from '@utils';
import {HorizontalSpacer} from '../../../atoms/spacer';
import {Label} from '../../../atoms/label';
import {getColorFromTheme} from '../../../ui-utils';
import {Body1} from '../../../atoms/typography/body1';
import {MeasurementInput} from '../../../atoms/measurement-input';

export interface MeasurementTypeFieldProps {
  label?: string;
  name?: string;
  state?: InputState;
  value: string;
  isKpi?: boolean;
  margin?: boolean;
  remove?: any;
  helper?: string | ReactElement;
  setValue?: (value: string) => void;
}

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  position: relative;
  border-radius: 7px;
  height: 44px;
  border: 1px solid ${getColorFromTheme('borderLight')};
  background-color: ${getColorFromTheme('backgroundDark')};
  padding: 12px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 4px;
`;

const QuestionIconWrapper = styled.a<{tooltip?: boolean}>`
  position: relative;
  outline: none;

  .tooltip {
    visibility: hidden;
    width: 202px;
    background-color: #ffffff;
    font-size: 14px;
    font-weight: normal;
    color: #5f5f8c;
    text-align: center;
    padding: 12px;
    border: 1px solid #e4e5fb;
    border-radius: 4px;
    position: absolute;
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 3;
    left: -10px;
    margin-top: -145px;
  }
  &:hover .tooltip {
    visibility: ${(props: any) => (props.tooltip ? 'visible' : '')};
    opacity: 1;
  }

  .tooltip::before {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 9%;
    margin-left: -4px;
    border-width: 10px;
    border-style: solid;
    border-color: #e4e5fb transparent transparent transparent;
  }

  .tooltip::after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 10%;
    margin-left: -5px;
    border-width: 9px;
    border-style: solid;
    border-color: #ffffff transparent transparent transparent;
  }
`;

export const MeasurementTypeField = forwardRef(
  (
    {
      label,
      setValue,
      helper,
      isKpi,
      state,
      remove,
      margin,
      name,
      value,
      ...rest
    }: MeasurementTypeFieldProps,
    ref: Ref<HTMLInputElement>,
  ) => {
    const text = useMemo(() => {
      switch (value) {
        case MeasurementType.BINARY:
          return 'Binary';
        case MeasurementType.CURRENCY:
          return 'Currency';
        case MeasurementType.NUMERIC:
          return 'Numeric';
        case MeasurementType.PERCENTAGE:
          return 'Percentage';
        // no-default
      }
    }, [value]);

    const onChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;

        if (setValue) {
          setValue(value as MeasurementType);
        }
      },
      [setValue],
    );
    const goalFramework = isKpi ? 'KPI' : capitalize(cfv(undefined, true).k_r);
    return (
      <FieldWrapper margin={margin}>
        <Label aria-label="label">{label}</Label>
        <Wrapper>
          <AnimateSharedLayout type="crossfade">
            <Container>
              {remove && !remove?.includes('percentage') && (
                <QuestionIconWrapper tooltip>
                  <MeasurementInput
                    id={`${name}-measurement-percentage`}
                    kind={MeasurementType.PERCENTAGE}
                    value={MeasurementType.PERCENTAGE}
                    name={name}
                    checked={value === MeasurementType.PERCENTAGE}
                    onChange={onChange}
                    ref={ref}
                    {...rest}
                  />
                  <span className="tooltip">
                    <Body1>Percentages</Body1>
                    <Body1 kind="textBody">
                      {capitalize(cfv(undefined, true).k_r)} is measured in
                      percentages.
                    </Body1>
                  </span>
                </QuestionIconWrapper>
              )}
              <QuestionIconWrapper tooltip>
                <MeasurementInput
                  id={`${name}-measurement-numeric`}
                  kind={MeasurementType.NUMERIC}
                  value={MeasurementType.NUMERIC}
                  name={name}
                  checked={value === MeasurementType.NUMERIC}
                  onChange={onChange}
                  ref={ref}
                  {...rest}
                />
                <span className="tooltip">
                  <Body1>Numeric</Body1>
                  <Body1 kind="textBody">
                    {goalFramework} is measured in numbers.
                  </Body1>
                </span>
              </QuestionIconWrapper>
              <QuestionIconWrapper tooltip>
                <MeasurementInput
                  id={`${name}-measurement-currency`}
                  kind={MeasurementType.CURRENCY}
                  value={MeasurementType.CURRENCY}
                  name={name}
                  checked={value === MeasurementType.CURRENCY}
                  onChange={onChange}
                  ref={ref}
                  {...rest}
                />

                <span className="tooltip">
                  <Body1>Currency</Body1>
                  <Body1 kind="textBody">
                    {goalFramework} is measured in a currency.
                  </Body1>
                </span>
              </QuestionIconWrapper>
              {remove && !remove?.includes('binary') && (
                <QuestionIconWrapper tooltip>
                  <MeasurementInput
                    id={`${name}-measurement-boolean`}
                    kind={MeasurementType.BINARY}
                    value={MeasurementType.BINARY}
                    name={name}
                    checked={value === MeasurementType.BINARY}
                    onChange={onChange}
                    ref={ref}
                    {...rest}
                  />
                  <span className="tooltip" style={{marginTop: '-170px'}}>
                    <Body1>Binary</Body1>
                    <Body1 kind="textBody">
                      {goalFramework} is either Not complete or Complete.
                    </Body1>
                  </span>
                </QuestionIconWrapper>
              )}
            </Container>
          </AnimateSharedLayout>
          <HorizontalSpacer size="16px" />
          <Body1 kind="textBody">{text}</Body1>
        </Wrapper>
        <Helper aria-label="helper" state={state} children={helper} />
      </FieldWrapper>
    );
  },
);
