import styled from 'styled-components';
import {down, up} from 'styled-breakpoints';
const Overlay = styled.div`
  z-index: 3;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  min-height: 1px;
  width: 100%;
  position: fixed;
  background: rgba(39, 50, 64, 0.25);
  will-change: opacity;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`;

const DrawerWrapper = styled.div`
  width: 45%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  transition: -webkit-transform 0.3s;
  background: white;
  overflow-y: scroll;
  overflow-x: hidden;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  transition-timing-function: var(--ease-in-out);

  ${down('md')} {
    width: 70%;
    padding: 32px 70px;
  }

  ${up('lg')} {
    width: 60%;
    padding: 32px 70px;
  }

  ${up('xl')} {
    width: 45%;
    padding: 32px 70px;
  }
`;
const DrawerContainer = styled.div``;
export const Drawer = ({children}: any) => {
  return (
    <div>
      <Overlay>
        <DrawerWrapper>
          <DrawerContainer>{children}</DrawerContainer>
        </DrawerWrapper>
      </Overlay>
    </div>
  );
};
