import {Button} from '@ui/atoms/button';
import {Body2, Body1} from '@ui/atoms/typography';
import {VerticalSpacer} from '@ui/atoms/spacer/spacer';
import {FlexRowCenter} from '@ui/style/styles';
import {goalDueDate} from '@utils/date';
import {getColorFromTheme} from '@ui/ui-utils';
import {useNavigate} from 'react-router';
import styled from 'styled-components';
import {useStoreContext} from '@store/store-context';
import {cloudinary} from '@assets/images/cloudinary';

import {Table, TableHeader, TableRow, TableWrapper} from '../../cc-styles';
import {UserListCard} from '@ui/molecules/user/list-card';
import {observer} from 'mobx-react';
import {useQuery} from 'react-query';
import {CcRequestImpl} from '@api/cc-api/cc-request';
import {CcController} from '@api/cc-api/cc-controller';
import {ItemLoader} from '@ui/organisms/item-loader';
import {EmptyPlaceholder} from '@ui/atoms/empty-placeholder';

const {emptyGoals} = cloudinary;

export const Wrapper = styled.div`
  width: 100%;
`;
const Card = styled.div`
  border: 1px solid ${getColorFromTheme('borderDark')};
  border-radius: 8px;
  height: 38px;
  background: white;
  width: 168px;
  padding: 8px 16px;
`;
function PeerReviews({
  disabled,
  dueDay,
  cycleId,
}: {
  disabled: boolean;
  dueDay: string;
  cycleId: string;
}) {
  const navigate = useNavigate();

  const {
    authStore: {auth},
    usersStore: {users},
  } = useStoreContext();

  const request = new CcRequestImpl();
  const controller = new CcController(request);

  const fetchUserNomination = async () => {
    const response = await controller.fetchReviewNomination(
      undefined,
      cycleId,
      auth.user.id,
    );

    if (response) {
      return response;
    }

    return [];
  };

  const {data, isLoading} = useQuery(['nomination', cycleId], () =>
    fetchUserNomination(),
  );

  const fetchWrittenReviews = async () => {
    const response = await controller.fetchWrittenReview({
      reviewCycle: cycleId,
      reviewer: auth.user.id,
    });

    return response;
  };

  const {data: writtenReviews} = useQuery(
    ['written-review', cycleId, 'peer'],
    () => fetchWrittenReviews(),
  );

  const isReviewWritten = (user: string) => {
    return !!writtenReviews?.find(
      (review: {user: string}) => review.user === user,
    );
  };

  const emptyPlaceholder = {
    image: {
      alt: 'alt text',
      shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
      src: emptyGoals,
      style: {height: '30%', width: '30%'},
    },
    imports: true,
    subtitle: '',
    variant: 'default' as 'default',

    title:
      'There are no pending peer review requests for you to review at this time.',
  };

  return (
    <div>
      <Wrapper>
        <Body1 weight="bold">Your peers</Body1>

        <VerticalSpacer size="7px" />
        <TableWrapper>
          <Table>
            <TableHeader variant="you">
              <Body2 kind="textBody">Name</Body2>

              <Body2 kind="textBody">Progress</Body2>

              <Body2 kind="textBody">Actions</Body2>
            </TableHeader>
            {isLoading && (
              <FlexRowCenter style={{height: '200px'}}>
                <ItemLoader />
              </FlexRowCenter>
            )}
            {!isLoading && !data?.length && (
              <EmptyPlaceholder {...emptyPlaceholder} />
            )}
            {data &&
              !isLoading &&
              data?.map((nomination: {user: string}) => (
                <TableRow variant="you">
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    {users
                      .filter((user) => user.id === nomination.user)
                      .map((user) => (
                        <UserListCard
                          kind="admin"
                          type="secondary"
                          textStyle={{fontWeight: 600}}
                          tooltip={true}
                          avatar={user.firstName ? user.firstName : ''}
                          reviewer={user.reviewer}
                          name={
                            user.firstName && user.lastName
                              ? `${user.firstName} ${user.lastName}`
                              : ` ... (pending)`
                          }
                          userId={user.id}
                        />
                      ))}
                  </div>

                  <div>
                    <Card>
                      <FlexRowCenter>
                        <Body2
                          weight="semibold"
                          kind={
                            isReviewWritten(nomination.user)
                              ? 'green400'
                              : 'orange300'
                          }>
                          {isReviewWritten(nomination.user)
                            ? 'Completed'
                            : goalDueDate(dueDay)}
                        </Body2>
                      </FlexRowCenter>
                    </Card>
                  </div>

                  <div>
                    <Button
                      disabled={disabled || isReviewWritten(nomination.user)}
                      onClick={() =>
                        navigate(
                          `/make-review?type=peer&peer=${nomination.user}&id=${cycleId}`,
                        )
                      }
                      kind="secondary"
                      style={{
                        padding: '8px 16px',
                      }}>
                      Write review
                    </Button>
                  </div>
                </TableRow>
              ))}
          </Table>
        </TableWrapper>
      </Wrapper>
    </div>
  );
}

export default observer(PeerReviews);
