import {action} from 'mobx';
import {authStore} from '../../store/stores/auth-store';
import {activateNotification} from '../../ui/molecules/notification/activate-notification';
import {parseErrorContent} from '../../utils/error-handling';
import {MemberSignupFormData} from './member-signup-interface';
import {MemberSignupRequest} from './member-signup-request';

export class MemberSignupController {
  constructor(private readonly request: MemberSignupRequest) {}

  @action
  async memberSignup(data: MemberSignupFormData, token: string) {
    try {
      const response = await this.request.memberSignup(data, token);
      authStore.setAuthResponse(response);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }
}
