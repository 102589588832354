import React from 'react';

export const CalendarMore = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.8125 6.75H15.1875M11.8125 4.5V2.25M6.1875 4.5V2.25M3.9375 15.75H14.0625C14.6838 15.75 15.1875 15.2463 15.1875 14.625V4.5C15.1875 3.87868 14.6838 3.375 14.0625 3.375H3.9375C3.31618 3.375 2.8125 3.87868 2.8125 4.5V14.625C2.8125 15.2463 3.31618 15.75 3.9375 15.75Z"
        stroke="#5F5F8C"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <mask id="path-2-inside-1_42331_125486" fill="white">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.03125 10.9688C7.03125 11.4347 6.65349 11.8125 6.1875 11.8125C5.72151 11.8125 5.34375 11.4347 5.34375 10.9688C5.34375 10.5028 5.72151 10.125 6.1875 10.125C6.65349 10.125 7.03125 10.5028 7.03125 10.9688ZM9.84375 10.9688C9.84375 11.4347 9.46599 11.8125 9 11.8125C8.53401 11.8125 8.15625 11.4347 8.15625 10.9688C8.15625 10.5028 8.53401 10.125 9 10.125C9.46599 10.125 9.84375 10.5028 9.84375 10.9688ZM11.8125 11.8125C12.2785 11.8125 12.6562 11.4347 12.6562 10.9688C12.6562 10.5028 12.2785 10.125 11.8125 10.125C11.3465 10.125 10.9688 10.5028 10.9688 10.9688C10.9688 11.4347 11.3465 11.8125 11.8125 11.8125Z"
        />
      </mask>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.03125 10.9688C7.03125 11.4347 6.65349 11.8125 6.1875 11.8125C5.72151 11.8125 5.34375 11.4347 5.34375 10.9688C5.34375 10.5028 5.72151 10.125 6.1875 10.125C6.65349 10.125 7.03125 10.5028 7.03125 10.9688ZM9.84375 10.9688C9.84375 11.4347 9.46599 11.8125 9 11.8125C8.53401 11.8125 8.15625 11.4347 8.15625 10.9688C8.15625 10.5028 8.53401 10.125 9 10.125C9.46599 10.125 9.84375 10.5028 9.84375 10.9688ZM11.8125 11.8125C12.2785 11.8125 12.6562 11.4347 12.6562 10.9688C12.6562 10.5028 12.2785 10.125 11.8125 10.125C11.3465 10.125 10.9688 10.5028 10.9688 10.9688C10.9688 11.4347 11.3465 11.8125 11.8125 11.8125Z"
        fill="#5F5F8C"
      />
      <path
        d="M6.1875 13.3125C7.48192 13.3125 8.53125 12.2632 8.53125 10.9688H5.53125C5.53125 10.6063 5.82506 10.3125 6.1875 10.3125V13.3125ZM3.84375 10.9688C3.84375 12.2632 4.89308 13.3125 6.1875 13.3125V10.3125C6.54994 10.3125 6.84375 10.6063 6.84375 10.9688H3.84375ZM6.1875 8.625C4.89308 8.625 3.84375 9.67433 3.84375 10.9688H6.84375C6.84375 11.3312 6.54994 11.625 6.1875 11.625V8.625ZM8.53125 10.9688C8.53125 9.67433 7.48192 8.625 6.1875 8.625V11.625C5.82506 11.625 5.53125 11.3312 5.53125 10.9688H8.53125ZM9 13.3125C10.2944 13.3125 11.3438 12.2632 11.3438 10.9688H8.34375C8.34375 10.6063 8.63756 10.3125 9 10.3125V13.3125ZM6.65625 10.9688C6.65625 12.2632 7.70558 13.3125 9 13.3125V10.3125C9.36244 10.3125 9.65625 10.6063 9.65625 10.9688H6.65625ZM9 8.625C7.70558 8.625 6.65625 9.67433 6.65625 10.9688H9.65625C9.65625 11.3312 9.36244 11.625 9 11.625V8.625ZM11.3438 10.9688C11.3438 9.67433 10.2944 8.625 9 8.625V11.625C8.63756 11.625 8.34375 11.3312 8.34375 10.9688H11.3438ZM11.1562 10.9688C11.1562 10.6063 11.4501 10.3125 11.8125 10.3125V13.3125C13.1069 13.3125 14.1562 12.2632 14.1562 10.9688H11.1562ZM11.8125 11.625C11.4501 11.625 11.1562 11.3312 11.1562 10.9688H14.1562C14.1562 9.67433 13.1069 8.625 11.8125 8.625V11.625ZM12.4688 10.9688C12.4688 11.3312 12.1749 11.625 11.8125 11.625V8.625C10.5181 8.625 9.46875 9.67433 9.46875 10.9688H12.4688ZM11.8125 10.3125C12.1749 10.3125 12.4688 10.6063 12.4688 10.9688H9.46875C9.46875 12.2632 10.5181 13.3125 11.8125 13.3125V10.3125Z"
        fill="#5F5F8C"
        mask="url(#path-2-inside-1_42331_125486)"
      />
    </svg>
  );
};
