import styled from 'styled-components';
import {AnimateSharedLayout} from 'framer-motion';
import {Helper} from '../../../atoms/helper';
import {BinaryType, InputState} from '../../../interface';
import {getColorFromTheme} from '../../../ui-utils';
import {VerticalSpacer} from '../../../atoms/spacer';
import {BinaryToggle} from '../../../atoms/binary-toggle';
import {Body2} from '../../../atoms/typography/body2';
import {
  ChangeEvent,
  forwardRef,
  ReactElement,
  Ref,
  useCallback,
  useState,
} from 'react';

export interface TBinarySwitchFieldProps {
  label?: string;
  name?: string;
  state?: InputState;
  helper?: string | ReactElement;
  setValue?: (value: BinaryType) => void;
  currentValue?: number;
}

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 4px;
  border-radius: 5px;
  border: 1px solid ${getColorFromTheme('borderLight')};
  background-color: ${getColorFromTheme('backgroundDark')};
`;

export const BinarySwitchField = forwardRef(
  (
    {
      state,
      helper,
      label,
      name,
      setValue,
      currentValue,
      ...rest
    }: TBinarySwitchFieldProps,
    ref: Ref<HTMLInputElement>,
  ) => {
    const [selected, setSelected] = useState(
      currentValue === 0 ? BinaryType.NOT_COMPLETED : BinaryType.COMPLETED,
    );

    const onChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;
        setSelected(value as BinaryType);
        if (setValue) {
          setValue(value as BinaryType);
        }
      },
      [setValue],
    );

    return (
      <Wrapper>
        <Body2 kind="textBody" as="label">
          {label}
        </Body2>
        <VerticalSpacer size="8px" />
        <AnimateSharedLayout>
          <Container>
            <BinaryToggle
              id={`${name}-completed`}
              title="Completed"
              checked={selected === BinaryType.COMPLETED}
              name={name}
              value={BinaryType.COMPLETED}
              onChange={onChange}
              ref={ref}
              {...rest}
            />
            <BinaryToggle
              id={`${name}-not-completed`}
              title="Incomplete"
              checked={selected === BinaryType.NOT_COMPLETED}
              name={name}
              value={BinaryType.NOT_COMPLETED}
              onChange={onChange}
              ref={ref}
              {...rest}
            />
          </Container>
        </AnimateSharedLayout>
        <Helper aria-label="helper" state={state} children={helper} />
      </Wrapper>
    );
  },
);
