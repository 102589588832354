import styled from 'styled-components';
import {getColorFromTheme} from '@ui/ui-utils';

export const Table = styled.div`
  border-collapse: collapse;
  min-width: 800px;
  overflow-x: auto;

  td {
    padding: 10px;
  }
`;

export const TableWrapper = styled.div`
  border: 1px solid #ededf2;
  overflow: auto;
  border-radius: 8px;

  &::-webkit-scrollbar {
    width: 2em;
    border-top: 1px solid #ededf2;
    height: 2.1em;
  }

  &::-webkit-scrollbar-track {
    background: #f6f8fa;
    border: 0.8em solid rgba(0, 0, 0, 0);
    background-clip: padding-box;

    -webkit-border-radius: 1.5em;

    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    height: 10em;
    border: 0.8em solid rgba(0, 0, 0, 0);
    background: #e5ebf1;
    border-radius: 10px;
    background-clip: padding-box;
    -webkit-border-radius: 1.5em;

    -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.025);
  }

  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;

export const TableHeader = styled.div<{
  variant?: string;
  gridTemplate?: string;
}>`
  background-color: ${getColorFromTheme('backgroundLight')};
  white-space: nowrap;
  height: 52px;
  width: 100%;

  border-top-left-radius: 30px;
  display: grid;
  padding-left: 24px;
  border-top-right-radius: 30px;
  grid-template-columns: ${({gridTemplate}) => gridTemplate || '60% 20% 20%'};
  align-items: center;

  border-radius: 10px 10px 0px 0px;

  border-top: ${(props: any) =>
    props.variant !== 'you' ? '1px solid #ededf2' : ''};
`;

export const TableRow = styled.div<{variant?: string; gridTemplate?: string}>`
  height: 72px;
  width: 100%;
  padding-left: 24px;
  display: grid;
  grid-template-columns: ${({gridTemplate}) => gridTemplate || '55% 20% 20%'};
  grid-gap: 16px;
  align-items: center;
  border-top: 1px solid ${getColorFromTheme('borderLight')};
`;
