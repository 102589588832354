import {TKeyResult, useKeyResult} from '../../../hooks';
import {memo, ReactElement} from 'react';
import styled from 'styled-components';
import {VerticalSpacer} from '../../atoms/spacer';
import {AddMoreAction} from '../../molecules/add-more';
import {Helper} from '../../atoms/helper';
import {Fragment} from 'react';
import {KeyResultField} from '../../molecules/key-result-field';
import {Label} from '../../atoms/label';
import {TMemberOption} from '../../molecules/key-result-field/key-result-field';
import {DeepMap, FieldError} from 'react-hook-form';
import {cfv, computeFrameworkVars} from '../../../utils/framework';
import {authStore} from '../../../store/stores/auth-store';
import {InputState} from '../../interface';
import {FlexRow} from '@ui/style/styles';

const Wrapper = styled.div`
  width: 100%;
`;

const CursorWrapper = styled.div`
  position: relative;

  > span {
    position: relative;
    bottom: 0;
  }
`;

export interface EmailsInvitationProps {
  setValue?: (values: TKeyResult[]) => void;
  defaultValue: TKeyResult[];
  options: TMemberOption[];
  validateForm?: () => void;
  errors?: (DeepMap<TKeyResult, FieldError> | undefined)[] | undefined;
  state?: InputState;
  helper?: string | ReactElement;
}

export const KeyResults = memo(function ({
  setValue,
  defaultValue,
  options,
  errors,
  validateForm,
  state,
  helper,
}: EmailsInvitationProps) {
  const {
    addKeyresult,
    keyresults,
    onChangeKeyResult,
    removeKeyresult,
    disabled,
  } = useKeyResult(defaultValue, setValue);

  return (
    <Wrapper>
      <FlexRow className="mb-2">
        <Label>
          What's a{' '}
          {
            computeFrameworkVars(authStore?.auth?.user?.workspace?.framework)
              ?.k_r
          }{' '}
          to track this with?
        </Label>
      </FlexRow>
      {Array.from(keyresults).map(
        ([key, {keyresult}], index, allKeyResults) => (
          <Fragment key={key}>
            {!keyresult.id ? (
              <>
                <KeyResultField
                  key={key}
                  setValue={(results: TKeyResult) => {
                    onChangeKeyResult(key, results);
                  }}
                  index={index}
                  members={options}
                  size={allKeyResults.length}
                  value={{
                    measurement: {
                      unit: keyresult.measurement.unit,
                      symbol: keyresult.measurement.symbol,
                    },
                    name: keyresult.name,
                    range: keyresult.range,
                    source: keyresult.source,
                    showSource: keyresult?.showSource,
                    saveSource: keyresult?.saveSource,
                    user: keyresult.user,
                  }}
                  onRemove={() => removeKeyresult(key)}
                  name={key}
                  // @ts-ignore
                  errors={errors && errors[index]}
                />
              </>
            ) : (
              <>
                <KeyResultField
                  key={key}
                  index={index}
                  size={allKeyResults.length}
                  setValue={(results: TKeyResult) => {
                    onChangeKeyResult(key, results);
                  }}
                  members={options}
                  value={{
                    id: keyresult?.id,
                    measurement: {
                      unit: keyresult?.measurement?.unit,
                      symbol: keyresult?.measurement?.symbol,
                    },
                    name: keyresult.name,
                    range: keyresult?.range,
                    showSource: keyresult?.showSource,
                    saveSource: keyresult?.saveSource,
                    user: keyresult?.user,
                    source: keyresult.source,
                  }}
                  onRemove={() => removeKeyresult(key)}
                  name={key}
                  // @ts-ignore
                  errors={errors && errors[index]}
                />
              </>
            )}
            <VerticalSpacer size="18px" />
          </Fragment>
        ),
      )}

      {disabled && (
        <CursorWrapper>
          <Helper
            children={`You can add up to 5 ${cfv().k_rs} at once`}
            state="warning"
          />
        </CursorWrapper>
      )}
      <VerticalSpacer size="8px" />
      <AddMoreAction
        onClick={() => {
          addKeyresult();
          validateForm && validateForm();
        }}
        disabled={disabled}
        title={`Add ${
          computeFrameworkVars(authStore?.auth?.user?.workspace?.framework)?.k_r
        }`}
      />
      <Helper aria-label="helper" state={state} children={helper} />
    </Wrapper>
  );
});
KeyResults.displayName = 'KeyResults';
