import {CSVLink} from 'react-csv';
import {observer} from 'mobx-react-lite';
import {Body2} from '@ui/atoms/typography';
import {CancelIcon} from '@ui/atoms/icons';
import dayjs from 'dayjs';
import {FlexRow} from '@ui/style/styles';
import {NoBorderButton} from '../reporting-overview/styles';
import {useFreeTierHook} from '@hooks/free-tier';
import FreeTierModal from '@ui/organisms/free-tier/free-tier-modal';
import {LockIcon} from '@ui/atoms/icons';
import {illustrationList} from '@ui/assets/illustrations';
import {RotatingLines} from 'react-loader-spinner';
import {useCsvHook} from './csv-hook';
import {useEffect, useRef, useState} from 'react';
import {DropdownCustom} from '@ui/molecules/dropdown-custom';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {CloudConvertRequestImpl} from '@api/cloud-convert/cloud-request';
import GeneratePowerPoint from '../admin-analytics/analytics-pages/analytics-presentations/powerpoint';
import {formatToMonthAndDay} from '@utils/date';
import {drawDOM, exportPDF} from '@progress/kendo-drawing';
import {beamMasteryTasks} from '@utils/firebase-request';
import {activateNotification} from '@ui/molecules/notification/activate-notification';
import {useStoreContext} from '@store/store-context';

interface ICSV {
  exportType: 'objectives' | 'keyresult' | 'kpis' | '';
  dateRange: any;
  goals?: any[];
  summary: any;
  reachedLimit?: boolean;
}

const ExportCsv = ({
  exportType,
  dateRange,
  reachedLimit,
  goals,
  summary,
}: ICSV) => {
  const {
    fetchKeyResult,
    fetchObjective,
    data,
    isLoading,
    dataLoaded,
    setDataLoaded,
    goalsType,
  } = useCsvHook(dateRange, exportType);

  useEffect(() => {
    return () => setDataLoaded(false);
  }, [setDataLoaded]);

  const {
    analyticsStore: {goalsDate},
  } = useStoreContext();

  const bodyRef: any = useRef(null);

  const exportPDFWithMethod = () => {
    if (isAccountFreeTier) return handleShowFreeTierModal(true);

    setIsExporting(true);
    let element = bodyRef.current || document.body;

    drawDOM(element, {
      paperSize: 'legal',
      landscape: true,
      scale: 0.53,
    })
      .then((group) => {
        return exportPDF(group);
      })
      .then((dataUri) => {
        convertPdfToPptx(dataUri.split(';base64,')[1]);
      });
  };

  const [isExporting, setIsExporting] = useState(false);

  const convertPdfToPptx = async (pdfData: string) => {
    const request = new CloudConvertRequestImpl();

    try {
      const {data} = await request.createJobs({
        tasks: {
          'import-my-file': {
            operation: 'import/base64',
            file: pdfData,
            filename: 'beam.pdf',
          },
          'convert-my-file': {
            operation: 'convert',
            input: 'import-my-file',
            output_format: 'pptx',
          },
          'export-my-file': {
            operation: 'export/url',
            input: 'convert-my-file',
            inline: true,
          },
        },
      });

      if (data) {
        const {data: job} = await request.syncJobs(data.id);

        if (job) {
          const downloadURL =
            job.tasks &&
            job.tasks.find((task: any) => task.operation === 'export/url')
              ?.result?.files?.[0].url;

          if (downloadURL) {
            beamMasteryTasks('generate_presentation', true);
            window.location.assign(downloadURL);
          }
          setIsExporting(false);
        }
      }
    } catch (error) {
      setIsExporting(false);
      activateNotification({
        content: 'Oops, something went wrong ',
        kind: 'error',
        title: 'Error',
      });
    }
  };

  const {
    isAccountFreeTier,
    showFreeTierModal,
    handleShowFreeTierModal,
  } = useFreeTierHook();

  const [type, setType] = useState<'csv' | '' | 'powerpoint'>('');

  const DisplayFreeTierModal = (
    <FreeTierModal
      open={showFreeTierModal}
      image={illustrationList.board}
      onClose={() => handleShowFreeTierModal(false)}
      description={`This feature allows you to create a detailed .csv file that outlines the progress, performance, and team members linked to each goal in your workspace.`}
      title={`Simplify Goal Tracking with Customizable .CSV Reports`}
    />
  );

  const getData = async () => {
    if (isAccountFreeTier) return handleShowFreeTierModal(true);
    switch (exportType) {
      case 'objectives':
        return await fetchObjective(undefined);

      case 'keyresult':
        return await fetchKeyResult();
      case 'kpis':
        return await fetchObjective(true);
    }
  };
  return (
    <>
      {DisplayFreeTierModal}
      {dataLoaded && type === 'csv' ? (
        <>
          <FlexRow>
            <CSVLink
              data={data}
              filename={`${goalsType}-${dayjs().format()}`}
              onClick={() => setDataLoaded(false)}>
              <Body2
                kind={reachedLimit ? 'textMuted' : 'green300'}
                weight="semibold">
                Download
              </Body2>
            </CSVLink>
            <NoBorderButton
              onClick={() => setDataLoaded(false)}
              style={{marginLeft: '3px'}}>
              <CancelIcon />
            </NoBorderButton>
          </FlexRow>
        </>
      ) : (
        <DropdownCustom
          collapseOnClick={true}
          menu={(handleClose: () => void) => (
            <div>
              <div>
                <DropdownItem
                  onClick={() => {
                    handleClose();
                    setType('csv');
                    getData();
                  }}>
                  Csv
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    handleClose();
                    setType('powerpoint');

                    exportPDFWithMethod();

                    // updateSelectedTime('PM', 'time');
                  }}>
                  Powerpoint (ppt.)
                </DropdownItem>
              </div>
            </div>
          )}
          customComponent={(
            handleOpen: (event: any) => void,
            open: boolean,
          ) => (
            <NoBorderButton
              onClick={(event) => {
                if (isAccountFreeTier) return handleShowFreeTierModal(true);
                handleOpen(event);
              }}>
              <Body2
                kind={reachedLimit ? 'textMuted' : 'green300'}
                weight="semibold">
                <FlexRow>
                  {isAccountFreeTier && (
                    <span style={{margin: '2px 3px 0px 0px'}}>
                      <LockIcon />
                    </span>
                  )}
                  <span style={{marginRight: '3px'}}>
                    {isLoading ? ' Preparing Export ' : 'Export'}
                  </span>
                  {(isLoading || isExporting) && (
                    <RotatingLines width="16" strokeColor="#47B881" />
                  )}
                </FlexRow>
              </Body2>
            </NoBorderButton>
          )}
          customDropdownWrapperStyles={{
            top: '130%',
            right: 0,
            width: '190px',
          }}
        />
      )}
      <div
        style={{
          position: 'absolute',
          left: '-100000px',
          top: '-2000000px',
        }}>
        {goals && (
          <GeneratePowerPoint
            goals={{goals, summary}}
            type={exportType}
            pageTitle={'Reports'}
            date={
              goalsDate?.period
                ? `${goalsDate?.period} (${formatToMonthAndDay(
                    goalsDate.starts,
                  )} -
                          ${formatToMonthAndDay(goalsDate.ends)}) `
                : `${dayjs(goalsDate.starts).format('DD MMM. YYYY')} -
                          ${dayjs(goalsDate.ends).format('DD MMM. YYYY')} `
            }
            ref={bodyRef}
          />
        )}
      </div>
    </>
  );
};

export default observer(ExportCsv);
