import {useCallback, useEffect, useMemo, useState} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm, Resolver} from 'react-hook-form';
import {useNavigate} from 'react-router';
import {
  AdminSettingsFormData,
  AdminEmailData,
  AdminReviewerData,
} from './admin-settings-interface';
import {
  adminSettingsValidator,
  adminEmailValidator,
  adminReviewerValidator,
} from './admin-settings-validator';
import {AdminSettingsController} from './admin-settings-controller';
import {AdminSettingsRequestImpl} from './admin-settings-request';
import {useStoreContext} from '../../../../../../../../store/store-context';
import {useParams} from 'react-router-dom';
import {generateRandomDigits} from '@utils/generate-random-numbers';
import {resizeFile} from '../../../../../../../../utils/compress-images';
import {UserAPIRequestImpl} from '../../../../../../../../api/user-api/user-api-request';
import {UserAPIController} from '../../../../../../../../api/user-api/user-api-controller';
import {groupRequestImpl} from '../../../admin-group/admin-group-request';
import {groupController} from '../../../admin-group/admin-group-controller';
import {activateNotification} from '../../../../../../../../ui/molecules/notification/activate-notification';
import axios from 'axios';
import {BaseURL} from '../../../../../../../../configs/request';
import {AnalyticsController} from '../../../../../../reporting/reporting-pages/admin-analytics/admin-analytics.controller';
import {AnalyticsRequestImpl} from '../../../../../../reporting/reporting-pages/admin-analytics/admin-analytics.request';
import {setFirebaseData} from '@utils/firebase-handler';

export function useAdminSettingsHook() {
  const {
    register,
    handleSubmit,
    errors,
    formState,
    setValue,
    control,
    watch,
  } = useForm<AdminSettingsFormData>({
    resolver: yupResolver(adminSettingsValidator) as Resolver<
      AdminSettingsFormData,
      object
    >,
    mode: 'onSubmit',
  });

  const {
    register: emailRegister,
    control: emailControl,
    errors: emailErrors,
    formState: emailFormState,
    handleSubmit: emailHandleSubmit,
    watch: watchEmail,
  } = useForm<AdminEmailData>({
    resolver: yupResolver(adminEmailValidator) as Resolver<
      AdminEmailData,
      object
    >,
    mode: 'onSubmit',
  });

  const {
    register: reviewerRegister,
    errors: reviewerErrors,
    control: reviewerControl,
    formState: reviewerFormState,
    handleSubmit: reviewerHandleSubmit,
    watch: watchReviewer,
    setValue: setValueReviewer,
  } = useForm<AdminReviewerData>({
    resolver: yupResolver(adminReviewerValidator) as Resolver<
      AdminReviewerData,
      object
    >,
    mode: 'onSubmit',
  });

  const isSubmitting = useMemo(() => {
    return formState.isSubmitting;
  }, [formState]);

  const isSubmittingEmail = useMemo(() => {
    return emailFormState.isSubmitting;
  }, [emailFormState]);

  const isSubmittingReviewer = useMemo(() => {
    return reviewerFormState.isSubmitting;
  }, [reviewerFormState]);

  const hasError = useMemo(() => {
    return formState.submitCount > 0 && formState.errors;
  }, [formState.errors, formState.submitCount]);

  const handleSelectChange = (value: string) => {
    setValue('timezone', value);
  };

  const handleSelectChangeGroups = (data: {value: string; label: string}[]) => {
    setValue('groups', data);
  };

  const handleSelectChangeReviewer = (value: string) => {
    setValueReviewer('reviewer', value);
  };

  const handleSelectChangeWeekday = (weekDay: string) => {
    setValueReviewer('reporting.weekDay', weekDay);
  };
  const handleSelectChangeSchedule = (schedule: string) => {
    setValueReviewer('reporting.schedule', schedule);
  };
  const handleSetPrefix = (prefix: string) => {
    setValue('countryCode', prefix);
  };

  const {
    usersStore,
    authStore: {setInitResponseAsAuth, auth},
    analyticsStore: {setFilterGroup},
  } = useStoreContext();
  //
  let {id: useParamsId} = useParams();

  const [profile, setProfiles] = useState({
    firstName: '',
    lastName: '',
    timezone: '',
    email: '',
    avatar: {
      url: '',
    },
    role: '',
    jobTitle: '',
    department: '',
    reviewer: {
      firstName: '',
      lastName: '',
      id: '',
      timezone: '',
      email: '',
      avatar: {
        url: '',
      },
      role: '',
      jobTitle: '',
    },
    reporting: {
      schedule: '',
      weekDay: '',
    },
    phoneNumber: '',
    id: '',
    countryCode: '',
  });

  let profileAvatar = profile.avatar ? profile.avatar.url : '';

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    axios
      .get(`${BaseURL}/users/${useParamsId}?include=[reviewer]`, {
        headers: {
          Authorization: `Bearer ${auth?.tokens.access.token}`,
        },
      })
      .then((response) => {
        let userData = response.data;
        setProfiles(userData);
        setIsLoading(true);
      })
      .catch((error) => {
        setIsLoading(true);
        //TODO: Address this popup error
        // activateNotification({
        //   title: 'Error',
        //   content: error.response.data.message,
        //   kind: 'error',
        // });
      });
  }, [auth?.tokens.access.token, useParamsId]);

  let reviewerAvatar =
    profile.reviewer && profile.reviewer.avatar
      ? profile.reviewer.avatar.url
      : '';

  const [progress, setProgress] = useState(false);
  const uploadAvatar = async (e: any) => {
    e.preventDefault();
    let avatarData = {
      avatar: base64Upload,
    };
    setProgress(true);
    await fetch(`${BaseURL}/users/${useParamsId}/upload-avatar`, {
      mode: 'cors',
      method: 'POST',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Bearer ${auth?.tokens.access.token}`,
      },
      body: JSON.stringify(avatarData),
    })
      .then((res) => {
        if (res.ok) {
          activateNotification({
            title: 'Success',
            content: 'Avatar uploaded succesfully',
            kind: 'success',
          });
        } else {
          activateNotification({
            title: 'Error',
            content: res.statusText,
            kind: 'error',
          });
        }
        return res.json();
      })
      .then(() => {
        navigate(`/admin/edit-profile/${useParamsId}`);
      });
  };

  //

  const request = new AdminSettingsRequestImpl();
  const controller = new AdminSettingsController(request);
  const {
    setUsersGroups,
    usersGroups,
    users: allUsers,
    getUsers,
    setCurrentUserGroups,
  } = usersStore;

  const uiUsersFormat = useMemo(() => {
    return allUsers.map((user) => {
      return {
        label: {
          avatar: {
            name:
              user.firstName && user.lastName
                ? `${user.firstName} ${user.lastName}`
                : user.email,
            src: user.avatar && user.avatar.url ? user.avatar.url : '',
          },
          id: user.id,
          name:
            user.firstName && user.lastName
              ? `${user.firstName} ${user.lastName}`
              : user.email,
        },
        value: user.id,
      };
    });
  }, [allUsers]);
  const navigate = useNavigate();
  const {id} = useParams();

  const submitEmail = async (data: AdminEmailData) => {
    await controller.newAdminEmail(data, id);
    setInitResponseAsAuth();

    navigate(-1);
    // activateDashboardTab('admin');
  };

  const submitReviewer = async (data: AdminReviewerData) => {
    await controller.newAdminReviewer(data, id);

    getUsers();

    setInitResponseAsAuth();

    // activateDashboardTab('admin');
  };

  const [isDisabled, setIsDisabled] = useState(true);
  const {
    firstName,
    lastName,
    phoneNumber,
    jobTitle,
    timezone,
    groups,
    countryCode,
  } = watch();

  const [isDisabledEmail, setIsDisabledEmail] = useState(true);
  const {email} = watchEmail();

  const [isDisabledReviewer] = useState(true);
  const {reviewer} = watchReviewer();

  const [image, setImage] = useState({preview: '', raw: '', disable: false});

  const [base64Upload, setbase64Upload] = useState<any | null>(null);

  const handleChange = async (e: any) => {
    const file = e.target.files[0];
    const base64 = await resizeFile(file);
    setbase64Upload(base64);
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
        disable: true,
      });
    }
  };

  useMemo(() => {
    if (profile.id !== '') {
      (firstName && firstName !== profile.firstName) ||
      (timezone && timezone !== profile.timezone) ||
      (lastName && lastName !== profile.lastName) ||
      (jobTitle && jobTitle !== profile.jobTitle) ||
      (countryCode && countryCode !== profile?.countryCode) ||
      (phoneNumber && phoneNumber !== profile.phoneNumber) ||
      (groups &&
        JSON.stringify(groups) !==
          JSON.stringify(usersStore.usersGroups[profile.id])) ||
      reviewer !== profile.reviewer.id
        ? setIsDisabled(false)
        : setIsDisabled(true);
    }
  }, [
    profile.id,
    profile.firstName,
    profile.reviewer.id,
    profile.timezone,
    reviewer,
    profile.lastName,
    // watchAccountSettings,
    profile.jobTitle,
    profile?.countryCode,
    profile.phoneNumber,
    firstName,
    timezone,
    lastName,
    jobTitle,
    countryCode,
    phoneNumber,
    groups,
    usersStore.usersGroups,
  ]);

  useMemo(() => {
    email ? setIsDisabledEmail(false) : setIsDisabledEmail(true);
  }, [email]);

  // Initialize groups
  const fetchGroups = useCallback(async () => {
    const request = new AnalyticsRequestImpl();
    const controller = new AnalyticsController(request);
    const groupsResponse = await controller.fetchGroups();
    setFilterGroup(
      groupsResponse &&
        // eslint-disable-next-line array-callback-return
        groupsResponse.results.map((group: any) => {
          if (group) {
            return {
              label:
                group.name === 'General'
                  ? `General (${group.groupType?.name})`
                  : group.name,
              value: group.id,
            };
          }
        }),
    );
  }, [setFilterGroup]);
  useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);
  const {id: currentUserId} = useParams();
  const fetchCurrentUserGroups = useCallback(async () => {
    const request = new UserAPIRequestImpl();
    const controller = new UserAPIController(request);
    const response: any = await controller.fetchSpecificUserGroups(
      currentUserId,
    );

    response && setUsersGroups(currentUserId, response);
    response && setCurrentUserGroups(response);
  }, [currentUserId, setCurrentUserGroups, setUsersGroups]);
  useEffect(() => {
    fetchCurrentUserGroups();
  }, [fetchCurrentUserGroups]);

  const [updateMemberShip, setUpdateMembership] = useState(false);

  const submitForm = async (data: AdminSettingsFormData) => {
    const groupsRequest = new groupRequestImpl();
    const groupsController = new groupController(groupsRequest);

    const {groups, ...profileData} = data;

    // if (reviewer) {
    //   submitReviewer({
    //     reviewer: watchReviewer().reviewer,
    //     reporting: watchReviewer().reporting,
    //   });
    // }
    if (groups) {
      const defaultData = usersGroups[currentUserId].map((grp: any) => grp.id);
      const selectedData = groups.map((grp: any) => grp.value);
      const toBeRemoved = defaultData.filter(
        (grp: any) => !selectedData.includes(grp),
      );
      const toBeAdded = selectedData.filter(
        (grp: any) => !defaultData.includes(grp),
      );

      if (toBeAdded.length > 0) {
        const addedData = groups.map((grp: any) =>
          toBeAdded.includes(grp.value),
        );
        setUsersGroups(currentUserId, [
          ...usersGroups[currentUserId],
          ...addedData,
        ]);
        const responseAdd = await Promise.all(
          toBeAdded.map(async (group: any) => {
            const dt = {add: {users: [id], type: 'add'}};
            await groupsController.newAddGroupMember(dt, group);
          }),
        );

        responseAdd && setUpdateMembership(true);
      }
      if (toBeRemoved.length > 0) {
        setUsersGroups(
          currentUserId,
          usersGroups[currentUserId].filter(
            (grp: any) => !toBeRemoved.includes(grp.id),
          ),
        );
        const responseRemove = await Promise.all(
          toBeRemoved.map(async (group: any) => {
            const dt = {remove: {users: [id]}};
            await groupsController.newAddGroupMember(dt, group);
          }),
        );
        responseRemove && setUpdateMembership(true);
      }
    }
    updateMemberShip &&
      activateNotification({
        title: 'Success',
        content: 'Successfully updated group members',
        kind: 'success',
      });

    await controller.newAdminSettingsForm({...profileData, reviewer}, id);
    setInitResponseAsAuth();
    setFirebaseData(`updates/user`, generateRandomDigits(16));

    fetchCurrentUserGroups();
    navigate(-1);
    // activateDashboardTab('admin');
  };

  return {
    register,
    errors,
    isSubmitting,
    isSubmittingEmail,
    isSubmittingReviewer,
    submitForm,
    submitEmail,
    submitReviewer,
    handleSelectChange,
    handleSelectChangeGroups,
    handleSelectChangeReviewer,
    handleSelectChangeWeekday,
    handleSelectChangeSchedule,
    handleSubmit,
    control,
    emailControl,
    reviewerControl,
    image,
    base64Upload,
    handleChange,
    hasError,
    isDisabled,
    isDisabledEmail,
    isDisabledReviewer,
    emailRegister,
    reviewerRegister,
    emailErrors,
    reviewerErrors,
    emailHandleSubmit,
    reviewerHandleSubmit,
    users: uiUsersFormat.map((user: any) => {
      if (
        user &&
        user.label &&
        user.label.name &&
        user.label.name.includes('@')
      ) {
        return {
          ...user,
          label: {
            ...user.label,
            name: `${user.label.name} (pending invitation)`,
          },
        };
      } else {
        return user;
      }
    }),
    profile,
    profileAvatar,
    isLoading,
    reviewerAvatar,
    progress,
    uploadAvatar,
    handleSetPrefix,
  };
}
