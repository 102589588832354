import {observer} from 'mobx-react';
import {
  ComponentType,
  lazy,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {Navigate, Route, Routes, useNavigate} from 'react-router';
import {ObjectivesPage} from '../pages/dashboard/goals';
import {CheckinsPage} from '../pages/dashboard/checkins';
import {useStoreContext} from '../store/store-context';
import {VerticalSpacer} from '../ui/atoms/spacer';
import {NavigationOption} from '@ui/interface';
import {CompanyReporting} from '@pages/dashboard/reporting/company-reporting';
import {Modal} from '../ui/molecules/modal/modal';
import {ViewUserCheckins} from '@pages/dashboard/company-legacy/company-components/company-checkins/company-direct-reports/view-user-checkins/view-user-checkins';
import {BackAction} from '../ui/molecules/back-action/back-action';
import {CancelIcon} from '../ui/atoms/icons/cancel';
import {CultureAndCompetence} from '@pages/dashboard/culture-competence';
import {ModalCard} from '../ui/layouts/modal-card/modal-card';
import {Home} from '../pages/dashboard/get-started/get-started-page';
import {WorkspaceInviteForm} from '../pages/dashboard/admin/admin-components/invite/workspace-invite-form';
import {IntegrationsController} from '../pages/dashboard/admin/admin-components/admin-integrations/components/app.controller';
import {HeaderTooltip} from '../ui/molecules/tooltip/tooltip';
import {illustrationList} from '@ui/assets/illustrations';
import {Button} from '@ui/atoms/button';
import {GoalsRequestImpl} from '../api/goals-api/goals-request';
import {GoalsController} from '../api/goals-api/goals-controller';
import {FlexRowEnd} from '@ui/style/styles';
import {Body2} from '@ui/atoms/typography';
import {EndGoal} from '@pages/dashboard/goals/end-goal';
import dayjs from 'dayjs';
import {Feedback} from '@pages/feedback';
import FreeTierModal from '@ui/organisms/free-tier/free-tier-modal';
import {UserAPIRequestImpl} from '../api/user-api/user-api-request';
import {OneonOnePage} from '@pages/1:1s';
import {UserAPIController} from '../api/user-api/user-api-controller';
import {useWorkspaceUseCase} from '@hooks/workspace-usecase';
import {WorkspaceCheckinsSettingsRequestImpl} from '../pages/dashboard/admin/admin-components/admin-workspace/workspace-engagements/admin-checkins/admin-checkins.request';
import {WorkspaceCheckinsSettingsController} from '../pages/dashboard/admin/admin-components/admin-workspace/workspace-engagements/admin-checkins/admin-checkins.controller';
import {useYouHook} from '@pages/dashboard/you/you-hook';
import {RedirectTo} from './create-routes';
import {SurgeIcon} from '@ui/atoms/icons/surge';
import {useIsRouteActive} from '@utils/route-href';
import {AdminBillingRequestImpl} from '@pages/dashboard/admin/admin-components/admin-workspace/admin-billing-setup/admin-billing.request';
import {AdminBillingController} from '@pages/dashboard/admin/admin-components/admin-workspace/admin-billing-setup/admin-billing.controller';
import {AlertBadgeErrorSVG} from '@ui/atoms/svg';
import {NoBorderButton} from '@pages/dashboard/reporting/reporting-pages/reporting-overview/styles';
import {useDirectReportHook} from '@pages/dashboard/goals/direct-reports/direct-report-hook';
import {ActionItemsPage} from '@pages/dashboard/action-items';
import {DeleteGoal} from '@pages/dashboard/goals/delete-goal';
import {ArchiveGoal} from '@pages/dashboard/goals/archive-goal';
import {ReactivateGoal} from '@pages/dashboard/goals/reactivate-goal';
import InsightsPage from '@pages/dashboard/reporting/insights';
import ReportingDashboard from '@pages/dashboard/reporting/dashboard';
import {useKpiInsightHook} from '@hooks/kpi-insights';
import {useOneTimeFetch} from '@hooks/query-hook';

const DashboardTemplate = lazy(() =>
  import('../ui/templates/dashboard').then((module) => ({
    default: module.DashboardTemplate,
  })),
);

declare const window: any;

export const Dashboards = observer(
  ({
    header,
    navigations,
    WorkspaceDropdown,
    workspace,
  }: {
    header: ReactNode;
    navigations: NavigationOption[];
    WorkspaceDropdown?: ComponentType<{onClose: () => void}>;
    workspace?: {
      avatar?: string;
      name: string;
    };
  }) => {
    const {
      usersStore: {getUsers, fetchDeactivatedUsers, setCurrentUserGroups},
      billingsStore: {showFreeTierModals, updateShowFreeTierModals},
      authStore: {
        globalUserInvitationPopupState,
        handleOpen,

        openTooltip,
        closeGlobalUserInvitationModal,
      },
      goalsState: {setApps},
      integrationStore: {setActiveIntegrations},
      checkinsStore: {setCheckinsConfiguration},
    } = useStoreContext();

    const navigate = useNavigate();

    const {
      isCheckinsEnabled,
      isFeedBackEnabled,
      isOneOnOneEnabled,
      isEngagementActive,
      isPerformanceActive,
    } = useWorkspaceUseCase();

    useKpiInsightHook();

    const fetchCurrentUserGroups = useCallback(async () => {
      const request = new UserAPIRequestImpl();
      const controller = new UserAPIController(request);
      const response: any = await controller.fetchCurrentUserGroups();

      response && setCurrentUserGroups(response);
    }, [setCurrentUserGroups]);

    useEffect(() => {
      getUsers();
      fetchCurrentUserGroups();
    }, [fetchCurrentUserGroups, fetchDeactivatedUsers, getUsers, handleOpen]);

    const {
      storeYouGoals: {endGoalInStore},
      storeAllObjectives: {
        setGoals,
        goals_,
        endGoalInAllObjectiveStore,
        modifyYouGoalsPaginator,
        youGoalsPaginator,
      },
      companyStore: {setDirectReports, directReports},
      checkinsStore: {
        endGoalInCheckins,
        archiveGoalInCheckins,
        deleteGoalInCheckins,
      },
      goalsState,
      authStore: {auth},
      billingsStore: {updateShowNonActiveBilling, setBillingSubscriptions},
    } = useStoreContext();

    const isTrialActive =
      auth.user.workspace.billing.activeTrial &&
      !auth.user.workspace.billing.trialCompleted;

    const isFreePlanActive = auth.user.workspace.billing.plan === 'free_tier';

    const trailCountDown = dayjs(auth.user.workspace.billing.cycle.endDate)
      .add(1, 'days')
      .diff(dayjs().format(), 'days');

    const getIntegrationName = () => {
      const pathname = window.location.pathname;

      if (pathname.includes('admin/apps')) {
        const segments = pathname.split('/');
        const appName = segments[segments.indexOf('apps') + 1];

        return appName;
      }
      return '';
    };

    const {refetch: goalsRefetch} = useYouHook();
    const {refetch: directReportGoalsRefetch} = useDirectReportHook();

    const getActiveIntegrations = useCallback(async () => {
      const controller = new IntegrationsController();
      const response = await controller.getIntegrations();

      response && setApps(response);
      response && setActiveIntegrations(response);
    }, [setApps, setActiveIntegrations]);

    useOneTimeFetch('activeIntegrations', () => getActiveIntegrations(), {
      enabled: true,
    });

    const workspaceCheckinsRequest = useMemo(
      () => new WorkspaceCheckinsSettingsRequestImpl(),
      [],
    );

    const workspaceCheckinsController = useMemo(
      () => new WorkspaceCheckinsSettingsController(workspaceCheckinsRequest),
      [workspaceCheckinsRequest],
    );

    const getCheckinsConfigData = useCallback(async () => {
      const response = await workspaceCheckinsController.getCheckinsSettings();
      response && setCheckinsConfiguration(response);
    }, [setCheckinsConfiguration, workspaceCheckinsController]);

    useOneTimeFetch('checkinsconfigdata', () => getCheckinsConfigData(), {
      enabled: true,
    });

    const [reOpenGoalLoader, setReOpenGoalLoader] = useState(false);

    const getBillingMethods = async () => {
      const request = new AdminBillingRequestImpl();
      const controller = new AdminBillingController(request);

      const response = await controller.fetchAllBillingSubscriptions();
      response && setBillingSubscriptions(response);
    };

    useOneTimeFetch(['billing-methods'], () => getBillingMethods(), {
      enabled: auth.user.role === 'admin',
    });

    const reActivateGoal = async () => {
      setReOpenGoalLoader(true);

      try {
        const request = new GoalsRequestImpl();
        const controller = new GoalsController(request);
        const response = await controller.reactivateGoal(
          goalsState.reactivate_goal.id,
          goalsState.goalFramework,
        );
        response && setReOpenGoalLoader(false);
        response &&
          goalsState.setReactivateGoal({
            reOpen: false,
            id: '',
            name: '',
          });
      } catch (error) {
        setReOpenGoalLoader(false);
      }
    };

    const isCurrentSubscriptionPending =
      auth.user.workspace.billing.status === 'pending';

    const [showPaymentError, setShowPaymentError] = useState(true);

    const {isRouteActive} = useIsRouteActive();

    const isHomePageActive = isRouteActive('/home');

    return (
      <>
        <DashboardTemplate
          header={header}
          navigations={navigations}
          workspace={workspace}
          WorkspaceDropdown={WorkspaceDropdown}
          outerStyle={{
            backgroundColor: '#fafafa',
            maxHeight: '100%',
            position: 'relative',
            minHeight: '100vh',
          }}>
          {showPaymentError &&
            isCurrentSubscriptionPending &&
            isHomePageActive && (
              <div className="fixed w-[90%] z-[2] sm:w-full -translate-x-10 sm:-translate-x-[1rem] sm:top-[4.6rem]  top-20 bg-[#d52a2a] text-white py-3 px-4 flex items-center justify-center">
                <AlertBadgeErrorSVG />
                {'    '}
                <Body2
                  weight="semibold"
                  kind="white"
                  style={{marginLeft: '8px'}}>
                  Payment Failure. Please correct your{' '}
                  <NoBorderButton
                    onClick={() => navigate('/workspace-settings/billing')}>
                    <span
                      style={{textDecoration: 'underline', fontWeight: 600}}>
                      billing information
                    </span>
                  </NoBorderButton>{' '}
                </Body2>
                <NoBorderButton
                  style={{marginLeft: '5px', marginTop: '3px'}}
                  onClick={() => setShowPaymentError(false)}>
                  <CancelIcon color={'white'} />
                </NoBorderButton>
              </div>
            )}
          {isTrialActive && isHomePageActive && auth.user.role === 'admin' && (
            <div className="border-t-4 fixed w-full z-[2] -translate-x-[10%] laptop:-translate-x-[16%] sm:-translate-x-[1rem] sm:top-[4.6rem] top-20 border-[#585ADF] pr-16 laptop:pr-28 sm:pr-10">
              <FlexRowEnd>
                <div className="px-4 pt-2 pb-3 bg-[#585ADF] w-[306px] flex items-center justify-between column-6 rounded-b-2xl">
                  <SurgeIcon />

                  <Body2
                    weight="semibold"
                    kind="white"
                    className="whitespace-nowrap">
                    <span style={{fontWeight: 500}}>{trailCountDown}</span> days
                    free trial left.
                  </Body2>
                  <Button
                    style={{padding: '4px 16px'}}
                    kind="secondary"
                    onClick={() => {
                      navigate('/workspace-settings/billing');
                      updateShowNonActiveBilling(true);
                    }}>
                    Upgrade
                  </Button>
                </div>
              </FlexRowEnd>
            </div>
          )}

          {globalUserInvitationPopupState && (
            <Modal
              open={globalUserInvitationPopupState}
              onClose={() => closeGlobalUserInvitationModal()}>
              <div style={{maxWidth: '900px', margin: 'auto'}}>
                <div style={{width: 'max-content'}}>
                  <BackAction
                    width="md"
                    icon={<CancelIcon />}
                    onClick={() => closeGlobalUserInvitationModal()}
                    title="Close"
                  />
                </div>
                <div>
                  <VerticalSpacer size="16px" />
                  <ModalCard align="center" title="">
                    <WorkspaceInviteForm global />
                  </ModalCard>
                </div>
              </div>
            </Modal>
          )}
          <FreeTierModal
            open={!!showFreeTierModals.date_filter}
            image={
              showFreeTierModals.date_filter === 'custom'
                ? illustrationList.customTimeline
                : illustrationList.quarterlyTimeline
            }
            onClose={() => updateShowFreeTierModals('date_filter', '')}
            description={`This feature allows you to adjust the timeline according to your needs, making it easier to identify trends and patterns that can inform your decision-making process.`}
            title={`Gain Valuable Insights with Historical Quarterly Reports`}
          />
          <FreeTierModal
            open={showFreeTierModals.invite_user}
            image={illustrationList.goldenBarrow}
            onClose={() => updateShowFreeTierModals('invite_user', false)}
            description={`It appears that you have reached the maximum number of members allowed for this workspace. In order to collaborate with a larger team, assign tasks more effectively, and ultimately accomplish your goals more quickly, you may wish to consider adding more members to your workspace.`}
            title={`Opps, you’re fresh out of available seats`}
          />
          <HeaderTooltip tooltip={openTooltip}>
            <span className="tooltip">
              You can access the get started guide here at anytime.
            </span>
          </HeaderTooltip>
          <Routes>
            <Route path="/home" element={<Home />} />

            {isPerformanceActive && !isFreePlanActive && (
              <>
                <Route path="/goals" element={<ObjectivesPage />} />

                <Route
                  path="/goals/kpis"
                  element={<ObjectivesPage tab={'kpis'} />}
                />

                <Route path="/goals/:id" element={<ObjectivesPage />} />
              </>
            )}

            <Route path="/action-items" element={<ActionItemsPage />} />

            {isEngagementActive && !isFreePlanActive && (
              <>
                {isCheckinsEnabled && (
                  <>
                    <Route path="/checkins" element={<CheckinsPage />} />

                    <Route
                      path="/checkins/direct-reports"
                      element={<CheckinsPage tab="directReports" />}
                    />

                    <Route
                      path="/checkins/action-items"
                      element={<CheckinsPage tab="actionItems" />}
                    />

                    <Route
                      path="/checkins/questions"
                      element={<CheckinsPage tab="questions" />}
                    />

                    <Route
                      path="/view-user-checkins/:checkinsId/:type"
                      element={<ViewUserCheckins />}
                    />
                  </>
                )}

                {isFeedBackEnabled && !isFreePlanActive && (
                  <>
                    <Route path="/feedback/*" element={<Feedback />} />
                  </>
                )}

                {isOneOnOneEnabled && !isFreePlanActive && (
                  <>
                    <Route path="/meetings/*" element={<OneonOnePage />} />
                  </>
                )}
              </>
            )}

            <Route
              path="/culture-competence-review/*"
              element={<CultureAndCompetence />}
            />

            <Route
              path="/apps/*"
              element={<RedirectTo redirectTo="/edit/apps" />}
            />

            <Route
              path="/admin/apps/people"
              element={<RedirectTo redirectTo="/edit/apps" />}
            />

            <Route
              path="/admin/apps/*"
              element={
                <RedirectTo redirectTo={`/edit/apps/${getIntegrationName()}`} />
              }
            />

            <Route
              path="/manage-people"
              element={<RedirectTo redirectTo="/workspace-settings/members" />}
            />
            <Route path="/reporting/*" element={<CompanyReporting />} />

            <Route path="/reporting/insights" element={<InsightsPage />} />
            <Route
              path="/reporting/dashboard"
              element={<ReportingDashboard />}
            />

            <Route path="*" element={<Navigate to="/home" replace />} />
          </Routes>

          {goalsState.delete && (
            <DeleteGoal
              handleContinue={() => {
                deleteGoalInCheckins(goalsState?.deleteId);
                goalsRefetch();

                const request = new GoalsRequestImpl();
                const controller = new GoalsController(request);

                controller.deleteGoal(goalsState.deleteId).then(() => {
                  directReportGoalsRefetch();
                });

                goals_ &&
                  setGoals(
                    goals_?.filter(
                      (goal: any) => goal.id !== goalsState.deleteId,
                    ),
                  );

                youGoalsPaginator.totalResults &&
                  modifyYouGoalsPaginator(
                    'totalResults',
                    youGoalsPaginator.totalResults - 1,
                  );

                directReports &&
                  setDirectReports(
                    directReports?.map((report: any) => {
                      return {
                        ...report,
                        goals: report.goals?.filter(
                          (goal: any) => goal.id !== goalsState.deleteId,
                        ),
                      };
                    }),
                  );
              }}
            />
          )}
          {goalsState.end && (
            <EndGoal
              endGoal={endGoalInStore}
              endGoalInAllObjectiveStore={endGoalInAllObjectiveStore}
              endCheckinGoal={endGoalInCheckins}
            />
          )}
          {goalsState.archive && (
            <ArchiveGoal
              handleContinue={() => {
                archiveGoalInCheckins(goalsState.archiveId);
              }}
            />
          )}
          {goalsState.reactivate_goal.reOpen && (
            <ReactivateGoal
              reActivateGoal={reActivateGoal}
              reOpenGoalLoader={reOpenGoalLoader}
            />
          )}
        </DashboardTemplate>
      </>
    );
  },
);
