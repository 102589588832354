import awfulImg from './images/awful.png';
import awfulImg2x from './images/awful@2x.png';
import awfulImg3x from './images/awful@3x.png';
import elatedImgx1 from './images/elated-imgs/elatedx1.png';
import elatedImgx2 from './images/elated-imgs/elatedx2.png';
import elatedImgx3 from './images/elated-imgs/elatedx3.png';
import elatedImgx4 from './images/elated-imgs/elatedx4.png';
import happyImgx1 from './images/happy-imgs/happyx1.png';
import happyImgx2 from './images/happy-imgs/happyx2.png';
import happyImgx3 from './images/happy-imgs/happyx3.png';
import happyImgx4 from './images/happy-imgs/happyx4.png';
import sadImgx1 from './images/sad-imgs/sadx1.png';
import sadImgx2 from './images/sad-imgs/sadx2.png';
import sadImgx3 from './images/sad-imgs/sadx3.png';
import sadImgx4 from './images/sad-imgs/sadx4.png';
import drainedImgx1 from './images/drained-imgs/drainedx1.png';
import drainedImgx2 from './images/drained-imgs/drainedx2.png';
import drainedImgx3 from './images/drained-imgs/drainedx3.png';
import drainedImgx4 from './images/drained-imgs/drainedx4.png';
import mehImgx1 from './images/meh-imgs/mehx1.png';
import mehImgx2 from './images/meh-imgs/mehx2.png';
import mehImgx3 from './images/meh-imgs/mehx3.png';
import mehImgx4 from './images/meh-imgs/mehx4.png';

export interface IEmoji {
  src: string;
  extension: string;
  variants: {
    src: string;
    size: string;
  }[];
}

export const emojiList = {
  awful: {
    src: awfulImg3x,
    extension: 'png',
    variants: [
      {src: awfulImg, size: '1x'},
      {src: awfulImg2x, size: '2x'},
      {src: awfulImg3x, size: '3x'},
    ],
  },

  drained: {
    src: drainedImgx3,
    extension: 'png',
    variants: [
      {src: drainedImgx1, size: '1x'},
      {src: drainedImgx2, size: '2x'},
      {src: drainedImgx3, size: '3x'},
      {src: drainedImgx4, size: '4x'},
    ],
  },
  elated: {
    src: elatedImgx3,
    extension: 'png',
    variants: [
      {src: elatedImgx1, size: '1x'},
      {src: elatedImgx2, size: '2x'},
      {src: elatedImgx3, size: '3x'},
      {src: elatedImgx4, size: '4x'},
    ],
  },
  meh: {
    src: mehImgx3,
    extension: 'png',
    variants: [
      {src: mehImgx1, size: '1x'},
      {src: mehImgx2, size: '2x'},
      {src: mehImgx3, size: '3x'},
      {src: mehImgx4, size: '4x'},
    ],
  },
  sad: {
    src: sadImgx3,
    extension: 'png',
    variants: [
      {src: sadImgx1, size: '1x'},
      {src: sadImgx2, size: '2x'},
      {src: sadImgx3, size: '3x'},
      {src: sadImgx4, size: '4x'},
    ],
  },

  happy: {
    src: happyImgx3,
    extension: 'png',
    variants: [
      {src: happyImgx1, size: '1x'},
      {src: happyImgx2, size: '2x'},
      {src: happyImgx3, size: '3x'},
      {src: happyImgx4, size: '4x'},
    ],
  },
};
