import {YouHightLight} from '../../you/you-components/you-hightligts';
import {observer} from 'mobx-react-lite';
import {useMemo, useState} from 'react';
import {Body2} from '@ui/atoms/typography';
import {useYouHook} from '../../you/you-hook';
import {useQuery} from 'react-query';
import {Pagination} from '@ui/molecules/pagination/pagination';
import {ColorIconDisableDark} from '@ui/atoms/color-icons/color-icons';
import StackView from '@ui/organisms/stack-view/stack-view';
import {useStoreContext} from '@store/store-context';
import {TreeView} from '@pages/dashboard/reporting/reporting-pages/admin-analytics/analytics-shared-components/treeview/treeview';
import {GoalViewNavigation} from '../goal-view-navigation';
import {ColorIconGreen} from '@ui/atoms/color-icons';
import styled from 'styled-components';
import ObjectiveFilter from '../objective-filter';
import {SortByFilter} from '@pages/dashboard/reporting/reporting-pages/admin-analytics/analytics-pages/analytics-status/status-component/sort-by-filter';
import {GoalFilter} from '../kpis/kpi-filter';
import {FlexRowCenter} from '@ui/style/styles';
import {ItemLoader} from '@ui/organisms/item-loader';
import {authStore} from '@store/stores/auth-store';

const BodyWrapper = styled.div<{noPadding?: boolean}>`
  .g-container {
    border: 1px solid #ededf2;
    border-radius: 10px;
    margin-top: 24px;
  }

  .body-wrapper {
    padding: 16px 24px;
  }

  .goal-wrapper {
    padding: ${({noPadding}) => (noPadding ? '0px' : '16px')};
    border-top: 1px solid #ededf2;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    @media (max-width: 500px) {
      padding: 16px 12px;
    }
  }
`;

export const goalFilterOptions = [
  {
    value: 'active',
    label: (
      <>
        <ColorIconGreen />
        <p style={{paddingLeft: '4px'}}>Active</p>
      </>
    ),
    hiddenLabel: 'Active',
  },
  {
    value: 'upcoming',
    label: (
      <>
        <ColorIconDisableDark />
        <p style={{paddingLeft: '4px'}}>Upcoming</p>
      </>
    ),
    hiddenLabel: 'Upcoming',
  },
  {
    value: 'archived',
    label: (
      <>
        <ColorIconDisableDark />
        <p style={{paddingLeft: '4px'}}>Archived</p>
      </>
    ),
    hiddenLabel: 'Archived',
  },
];

export const MyObjectivesPage = observer(() => {
  const {isFetching, data, initKeyResults} = useYouHook();

  const {
    storeDashboardRoutes: {GoalViewTabs},
    storeYouGoals: {
      fetchedGoals,
      dateRange,
      setDateRange,
      sortBy,
      setSortBy,
      setSelectedUsers,
      selectedUsers,
      youGoalsPaginator,
      youGoalsPages,
      handleYouGoalsPaginatorPageClick,
      setYouGoalsFilterLimit,
      handleYouGoalsPaginatorNextbtn,
      handleYouGoalsPaginatorPrevbtn,
      setYouGoalsFilter,
      youGoalsFilter,
    },
  } = useStoreContext();

  const disableFilter = ['active', 'upcoming', 'archived'].includes(
    youGoalsFilter,
  );

  const isAlignmentEnabled =
    authStore.auth.user.workspace.settings.goalAlignment;

  const {data: keyResults = []} = useQuery(
    'key-results',
    () => initKeyResults(),
    {
      enabled: GoalViewTabs.nested,
      staleTime: 50000,
    },
  );

  const computeGoals = useMemo(
    () =>
      data &&
      !GoalViewTabs.flat &&
      data?.filter(
        (goal: any) =>
          goal.alignedGoals?.length > 0 ||
          goal.keyResults?.find(
            (keyResult: any) => keyResult.alignedKeyResults,
          ),
      ),
    [data, GoalViewTabs.flat],
  );

  const [clearSelection, setClearSelection] = useState(false);
  const isActiveFilter = youGoalsFilter === 'active';

  return (
    <>
      <BodyWrapper noPadding={!GoalViewTabs.flat} className="bg-white">
        <div className="g-container">
          <div className="body-wrapper">
            <div className="flex items-center sm:items-start justify-between  ">
              <Body2 weight="bold">
                Summary ({youGoalsPaginator.totalResults})
              </Body2>

              <div className="flex items-center sm:items-end sm:flex-col flex-row">
                {isAlignmentEnabled && <GoalViewNavigation />}

                {!isActiveFilter && (
                  <div className="ml-2">
                    <ObjectiveFilter
                      showDateRange
                      filterOnlyPeople
                      disabled={disableFilter}
                      removeUserFilter
                      filterOnlyTeams
                      variant={'objectives'}
                      clearSelection={clearSelection}
                      setClearSelection={setClearSelection}
                      setDateRange={setDateRange}
                      dateRange={dateRange}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="border-t border-t-[#EDEDF2] mt-4 pt-4 flex sm:flex-col flex-row items-center sm:items-start justify-between ">
              <div>
                <GoalFilter
                  value={{users: selectedUsers, state: youGoalsFilter}}
                  onChange={(state, users) => {
                    setSelectedUsers(users);
                    setYouGoalsFilter(state as any);
                    handleYouGoalsPaginatorPageClick('1');
                  }}
                />
              </div>

              <div className="flex flex-row items-center sm:items-end justify-end sm:w-full w-fit mt-0 sm:mt-2">
                <SortByFilter
                  value={sortBy}
                  onChange={(value) => {
                    setSortBy(value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="goal-wrapper">
            {GoalViewTabs.tree && (
              <TreeView
                data={data ? computeGoals : []}
                bodyStyle={{border: 0}}
                loading={isFetching}
              />
            )}

            {GoalViewTabs.flat &&
              (isFetching ? (
                <FlexRowCenter className="min-h-[200px]">
                  <ItemLoader />
                </FlexRowCenter>
              ) : (
                <YouHightLight goals={fetchedGoals} isFetching={isFetching} />
              ))}

            {GoalViewTabs.nested && (
              <StackView
                goals={computeGoals}
                isFetching={isFetching}
                allGoals={keyResults}
              />
            )}
          </div>
        </div>
      </BodyWrapper>

      {youGoalsPaginator.totalResults > 10 && GoalViewTabs.flat ? (
        <Pagination
          totalPages={youGoalsPaginator.totalPages}
          page={youGoalsPaginator.page}
          totalResults={youGoalsPaginator.totalResults}
          maxPageNumberLimit={youGoalsPaginator.maxPageNumberLimit}
          limit={youGoalsPaginator.limit}
          updateLimit={setYouGoalsFilterLimit}
          showing={fetchedGoals.size}
          minPageNumberLimit={youGoalsPaginator.minPageNumberLimit}
          goToPage={(value) => {
            handleYouGoalsPaginatorPageClick(value);
          }}
          pages={youGoalsPages(
            youGoalsPaginator.totalResults,
            youGoalsPaginator.limit,
          )}
          nextBtn={handleYouGoalsPaginatorNextbtn}
          prevBtn={handleYouGoalsPaginatorPrevbtn}
        />
      ) : null}
    </>
  );
});
