import {action} from 'mobx';

import {activateNotification} from '../../../../../../../../ui/molecules/notification/activate-notification';
import {
  AdminSettingsFormData,
  AdminEmailData,
  AdminReviewerData,
} from './admin-settings-interface';
import {AdminSettingsRequest} from './admin-settings-request';

export class AdminSettingsController {
  constructor(private readonly request: AdminSettingsRequest) {}

  @action
  async newAdminSettingsForm(data: AdminSettingsFormData, id: string) {
    try {
      const response = await this.request.adminForm(data, id);

      response &&
        activateNotification({
          title: 'Success',
          content: 'Profile has been updated',
          kind: 'success',
        });
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e?.response?.data?.message,
        kind: 'error',
      });
    }
  }

  @action
  async newAdminEmail(data: AdminEmailData, id: string) {
    try {
      await this.request.adminEmail(data, id);
      activateNotification({
        title: 'Success',
        content: 'Email Changed',
        kind: 'success',
      });
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e?.response?.data?.message,
        kind: 'error',
      });
    }
  }

  @action
  async newAdminReviewer(data: AdminReviewerData, id: string) {
    try {
      await this.request.adminReviewer(data, id);
      activateNotification({
        title: 'Success',
        content: 'Changes Saved',
        kind: 'success',
      });
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e?.response?.data?.message,
        kind: 'error',
      });
    }
  }
}
