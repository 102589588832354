import dayjs from 'dayjs';

export const FREQUENCY_OPTIONS = [
  {
    value: 'once',
    label: 'Just once',
  },
  {
    value: 'daily',
    label: 'Daily',
  },
  {
    value: 'weekly',
    label: 'Weekly',
  },
  {
    value: 'bi-weekly',
    label: 'Bi-weekly',
  },
  {
    value: 'monthly',
    label: 'Monthly',
  },
];

const CALC_MINUTES = (hour: number) => 60 * hour;

export const REMINDER_OPTIONS = [
  {
    value: '10',
    label: '10 minutes before',
  },
  {
    value: '30',
    label: '30 minutes before',
  },
  {
    value: `${CALC_MINUTES(1)}`,
    label: '1 hour before',
  },
  {
    value: `${CALC_MINUTES(24)}`,
    label: '24 hours before',
  },
  {
    value: `${CALC_MINUTES(72)}`,
    label: '3 days before',
  },
  {
    value: `${CALC_MINUTES(168)}`,
    label: '1 week before',
  },
];

export const computeReminderOptions = (startDate: string) => {
  const hourDiff = dayjs(startDate).diff(dayjs(), 'hours');
  const oneDay = 24;
  const threeDays = 72;
  const oneWeek = 168;

  if (hourDiff <= oneDay) {
    return REMINDER_OPTIONS.slice(0, 3);
  }

  if (hourDiff <= threeDays) {
    return REMINDER_OPTIONS.slice(0, 4);
  }

  if (hourDiff <= oneWeek) {
    return REMINDER_OPTIONS.slice(0, 5);
  }

  return REMINDER_OPTIONS;
};
