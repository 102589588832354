import mixpanel from 'mixpanel-browser';
import {authStore} from '../store/stores/auth-store';
mixpanel.init(`${process.env.REACT_APP_MIX_PANEL}`);

let env_check = process.env.NODE_ENV === 'production';

let actions = {
  identify: (id: any) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id: any) => {
    if (env_check) mixpanel.alias(id);
  },

  time_event: (id: any) => {
    if (env_check) mixpanel.time_event(id);
  },

  track: (name: any, props: any) => {
    mixpanel.track(name, props);
  },
  track_links: (query: any, event_name: any) => {
    if (env_check) mixpanel.track_links(query, event_name);
  },
  people: {
    set: (props: any) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;

export const trackPage = (name: string, prev_location?: string) => {
  if (authStore.auth !== null) {
    Mixpanel.track(name, {
      isReviewer:
        authStore.auth && authStore.auth.user.isReviewer ? 'Yes' : 'no',
      url: prev_location ?? window.location.pathname,
      userId: authStore.auth && authStore.auth.user.id,
      workspaceName: authStore.auth && authStore.auth.user.workspace.name,
    });
  }
};

export const trackTime = (name: string) => {
  Mixpanel.time_event(name);
};
