import styled from 'styled-components';
import dayjs from 'dayjs';
import {useDropDown} from '../../../hooks';
import {DateInput} from '../../atoms/date-input';
import {Calendar} from '../calendar';
import {CSSProperties, memo, useCallback, useMemo, useState} from 'react';

const Wrapper = styled.div`
  position: relative;
`;

export const DropdownWrapper = styled.div`
  position: absolute;
  display: inline-block;
  top: 130%;
  left: 0;
  z-index: 3;
`;

export interface DatePickerProps {
  label: string;
  value?: string;
  placeholder?: string;
  onChange?: (date: string) => void;
  disabledBefore?: string;
  inputStyle?: CSSProperties;
  dateAlign?: 'left' | 'right';
  disabledAfter?: string;
  disabled?: boolean;
}

export const DatePicker = memo(function ({
  onChange,
  label,
  value,
  placeholder,
  inputStyle,
  disabledBefore,
  dateAlign,
  disabledAfter,
  disabled,
}: DatePickerProps) {
  const [innerValue, setInnerValue] = useState(value ?? '');
  const {handleOpen, ref, open, handleClose} = useDropDown();

  const onDatePicked = useCallback(
    (value: Date) => {
      handleClose();
      const v = dayjs(value).format();
      setInnerValue(v);
      if (onChange) {
        onChange(v);
      }
    },
    [handleClose, onChange],
  );

  const formatValue = useMemo(() => {
    if (innerValue) {
      return dayjs(innerValue).format('DD/MM/YYYY');
    }
    return '';
  }, [innerValue]);

  return (
    <Wrapper ref={ref}>
      <DateInput
        label={label}
        onClick={handleOpen}
        value={formatValue}
        inputStyle={inputStyle}
        dateAlign={dateAlign}
        placeholder={placeholder}
        active={open}
        disabled={disabled && disabled}
      />

      {open && (
        <DropdownWrapper>
          <Calendar
            onChange={onDatePicked}
            value={dayjs(innerValue).format()}
            disabledBefore={disabledBefore}
            disabledAfter={disabledAfter}
          />
        </DropdownWrapper>
      )}
    </Wrapper>
  );
});

DatePicker.displayName = 'DatePicker';
