import {checkinsCalender} from '@hooks/checkins-calendar';
import {useStoreContext} from '@store/store-context';
import {getYear} from '@hooks/calendar';
import {useState} from 'react';
import {useNavigate} from 'react-router';

export const useTaskHook = () => {
  const navigate = useNavigate();
  const {
    checkinsStore: {setCheckinsDate},
    storeDashboardRoutes: {activateDashboardTab, activateCheckinsTabs},
  } = useStoreContext();

  const handleCheckinFeedback = (week: number, year: number) => {
    const calendarData = checkinsCalender(year);

    const findWeek = calendarData.currentMonthWeeks.find((currentWeek) =>
      year !== getYear()
        ? currentWeek.weekOfYear === week - 1
        : currentWeek.weekOfYear === week,
    );

    setCheckinsDate({
      month: findWeek?.month,
      year: year,
      ends: findWeek?.dateEnd,
      starts: findWeek?.dateStart,
      weekOfYear: findWeek?.weekOfYear,
    });

    navigate(`/checkins/direct-reports?week=${week}&year=${year}`);
    activateDashboardTab('checkins');
    activateCheckinsTabs('direct_reports');
  };
  const [comments] = useState<any>({
    comments: [],
    mentions: [],
  });

  const [commentCount, setCommentCount] = useState({
    comment: 0,
    mention: 0,
  });

  const [mentions, setMentions] = useState({
    title: '',
    description: '',
  });

  const [getMentions] = useState<string[]>([]);

  const [commentTasks, setCommentsTasks] = useState({
    title: '',
    description: '',
  });

  const [userLog] = useState<any>([]);

  return {
    handleCheckinFeedback,
    mentions,
    setCommentsTasks,
    commentTasks,
    getMentions,
    setCommentCount,
    setMentions,
    commentCount,
    comments,
    userLog,
  };
};
