import {Button} from '@ui/atoms/button';
import {CancelIcon} from '@ui/atoms/icons';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {Headline2} from '@ui/atoms/typography';
import {ModalCard} from '@ui/layouts/modal-card';
import {BackAction} from '@ui/molecules/back-action';
import {TextField} from '@ui/molecules/field/textfield';
import {Modal} from '@ui/molecules/modal';
import {writeFirebaseData} from '@utils/firebase-handler';

import React, {useState} from 'react';
import nextId from 'react-id-generator';
import {ModalRenameWrapper} from '../insights/styles';

interface CreateSectionProps {
  showNewSectionModal: boolean;
  allSections: any[];
  setNewSectionModal: (value: boolean) => void;
}
export const CreateSection = ({
  showNewSectionModal,
  allSections,
  setNewSectionModal,
}: CreateSectionProps) => {
  const [text, setText] = useState('');

  return (
    <div>
      <Modal
        open={showNewSectionModal}
        onClose={() => setNewSectionModal(false)}>
        <ModalRenameWrapper>
          <BackAction
            icon={<CancelIcon />}
            title="Close"
            onClick={() => setNewSectionModal(false)}
            width="md"
          />
          <VerticalSpacer size="16px" />
          <ModalCard title="">
            <div>
              <Headline2>New Section</Headline2>

              <VerticalSpacer size="20px" />
              <TextField
                maxLength={25}
                label="Title"
                value={text}
                placeholder="Enter title (max 25 characters)"
                onChange={(event) => setText(event.target.value)}
                name="name"
              />

              <VerticalSpacer size="14px" />

              <Button
                kind="primary"
                width="full"
                onClick={() => {
                  writeFirebaseData('dashboard', {
                    name: text,
                    slug: text,
                    id: nextId('dashboard'),

                    order: allSections.length + 1,
                  });
                  setNewSectionModal(false);
                }}
                data-form-action={true}
                disabled={!text || text.length > 25}>
                Create
              </Button>
            </div>
          </ModalCard>
        </ModalRenameWrapper>
      </Modal>
    </div>
  );
};
