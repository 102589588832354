import {useFirebaseFetch} from '@hooks/query-hook';
import {useStoreContext} from '@store/store-context';
import dayjs from 'dayjs';
import {useCallback, useMemo} from 'react';

export const useHomeActionItems = (filterBy: 'you' | 'team' | 'company') => {
  const {data} = useFirebaseFetch('action_items');

  const {
    usersStore: {users},
    authStore: {auth},
  } = useStoreContext();

  const computeActionItems = useCallback(() => {
    const convertAndFilterTasks = (tasks: any) => {
      if (!tasks) return [];
      if (!Array.isArray(tasks)) {
        return Object.entries(tasks).map(([firebaseId, task]: any) => ({
          ...task,
          firebaseId,
        }));
      }
      return tasks;
    };

    const convertedTasks = convertAndFilterTasks(data);

    const _computedTasks = convertedTasks
      .map((task) => ({
        ...task,
        assignee: users.find((user) => user.id === task.assignee),
        key: task.id || task._id || task.key || '',
      }))
      .sort((a, b) => a.order - b.order);

    return _computedTasks;
  }, [data, users]);

  const tasks = useMemo(() => {
    return computeActionItems()?.length
      ? computeActionItems()
      : [{text: '', completed: false}];
  }, [computeActionItems]);

  const directReports = users
    .filter((user) => (user.reviewer?.id || user.reviewer) === auth.user.id)
    .map((user) => user.id);
  const _tasks = {
    you: tasks.filter((task) =>
      [task?.user, task?.assignee, task?.createdBy].includes(auth.user.id),
    ),
    team: tasks.filter((task) => {
      return directReports.some((user) =>
        [task?.user, task?.assignee, task?.createdBy].includes(user),
      );
    }),
    company: tasks,
  };

  const activeActionItems = _tasks[filterBy].filter((task) => !task.completed)
    .length;

  const overdueActionItems = _tasks[filterBy].filter((task) =>
    dayjs().isAfter(dayjs(task?.dueDate)),
  ).length;

  const completedActionItems = _tasks[filterBy].filter((task) => task.completed)
    .length;

  const assignedActionItems = _tasks[filterBy].filter(
    (task) => !!task?.assignee,
  ).length;

  const completedOnDueDateActionItems = _tasks[filterBy].filter(
    (task) => task?.completed && dayjs(task.dueDate).isValid(),
  ).length;

  return {
    activeActionItems,
    overdueActionItems,
    completedActionItems,
    assignedActionItems,
    completedOnDueDateActionItems,
  };
};
