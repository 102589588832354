import {useCallback, useEffect, useMemo, useState} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm, Resolver} from 'react-hook-form';
import {CreateGoalFormData} from './create-goal-interface';
import {CreateGoalValidatorSchema} from './create-goal-validator';
import {useLocation} from 'react-router';
import {useStoreContext} from '../../store/store-context';
import {GoalKind, TKeyResult} from '../../hooks';
import {CreateGoalRequestImpl} from './create-goal-request';
import {CreateGoalController} from './create-goal-controller';
import {useSearchParams} from 'react-router-dom';
import {getGroupTypeAndGroup} from '@utils/get-group-grouptype';
import {convertAPIformatToFormData} from '../../utils/convertFormData';
import {convertFormDataToAPIformat} from '../../utils/convertFormData';
import {IntegrationsController} from '../dashboard/admin/admin-components/admin-integrations/components/app.controller';
import {TOption} from '../../ui/molecules/select/user-select-3/user-select-3';
import {useNavigate} from 'react-router';
import dayjs from 'dayjs';
import {useDirectReportsGoal} from '@pages/dashboard/goals/direct-reports/direct-report-hook';
import {MeasurementType} from '../../ui/interface';

export function useCreateGoalHook(
  onSubmit: (data: CreateGoalFormData) => Promise<void>,
) {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    goalsState,
    usersStore,
    storeYouGoals,
    authStore: {auth},
    groupStore: {groups},
    groupTypeStore: {groupType: allGroupTypes},
    goalsState: {setApps, setIsManagerEditing},
  } = useStoreContext();

  const currentUser = usersStore.currentUser;
  const [searchParams] = useSearchParams();
  const [isFetching, setIsFetching] = useState(false);
  const [editable, setEditable] = useState<CreateGoalFormData>();
  const [isEditingDisabled, setIsEditingDisabled] = useState(true);
  const {uiUsersFormat, deactivatedUsers} = usersStore;
  const [loader, setLoader] = useState<any>({});

  const {initDirectReportsGoals} = useDirectReportsGoal();

  const isGoalKpi = searchParams.get('kpi');
  const managerEditing = searchParams.get('managerEditGoal');

  const getUser: any = usersStore.users.find(
    (user: any) => user.id === auth?.user?.id,
  );

  const {
    register,
    handleSubmit,
    errors,
    formState,
    setValue,
    trigger,
    control,
    setError,
    watch,
    clearErrors,
    reset,
  } = useForm<CreateGoalFormData>({
    resolver: yupResolver(CreateGoalValidatorSchema.createGoal) as Resolver<
      CreateGoalFormData,
      object
    >,
    mode: 'all',
    defaultValues: {
      keyResults: [
        {
          id: '',
          measurement: {unit: '', symbol: ''},
          name: '',
          range: {start: undefined, target: undefined, current: undefined},
          user: '',
          showSource: false,
          saveSource: false,
          source: null,
        },
      ],
      date: {
        starts: '',
        ends: '',
      },
      meta: {
        tags: [],
        kpiType: '',
        description: '',
      },
      targetValue: '',
      name: '',
      measurement: {unit: isGoalKpi ? undefined : MeasurementType.BINARY},
      range: {},
    },
  });

  const duplicate_goal = searchParams.get('duplicate_goal');

  const {groupType} = watch();

  useEffect(() => {
    if (!!editable) {
      const {
        name,
        keyResults,
        goalAlignment,
        group,
        range,
        manager,
        date,
        measurement,
      } = watch();

      if (
        (name && name !== editable?.name) ||
        (group && group !== editable?.group) ||
        (goalAlignment &&
          goalAlignment.value &&
          goalAlignment.value !== editable?.goalAlignment) ||
        // (editable?.goalAlignment && !goalAlignment) ||
        // (!editable?.goalAlignment && goalAlignment) ||
        (group && group !== editable?.group) ||
        (keyResults &&
          JSON.stringify(keyResults) !==
            JSON.stringify(editable?.keyResults)) ||
        (range && JSON.stringify(range) !== JSON.stringify(editable?.range)) ||
        (manager && manager !== editable?.manager) ||
        (measurement &&
          measurement.unit === MeasurementType.CURRENCY &&
          JSON.stringify(measurement) !==
            JSON.stringify(editable?.measurement)) ||
        (measurement &&
          measurement.unit !== MeasurementType.CURRENCY &&
          measurement.unit !== editable?.measurement.unit) ||
        (date && JSON.stringify(date) !== JSON.stringify(editable?.date))
      ) {
        setIsEditingDisabled(false);
      } else {
        setIsEditingDisabled(true);
      }
    }
  }, [editable, editable?.name, watch]);

  const validateKeyResult = useMemo(() => {
    if (watch().keyResults) {
      const index = watch().keyResults.findIndex(
        // @ts-ignore
        (val: any) =>
          val?.source?.app !== undefined &&
          val?.range?.start > val?.range?.current,
      );

      if (index !== -1 && Object.keys(formState?.errors)?.length === 0) {
        setError(`keyResults.${index}.range.current`, {type: 'min'});
        return true;
      } else {
        if (formState?.errors?.keyResults && index === -1) {
          formState?.errors?.keyResults?.length > 0 &&
            formState?.errors?.keyResults?.forEach((key: any, idx: any) =>
              idx?.range?.current
                ? clearErrors(`keyResults.${idx}.range.current`)
                : null,
            );
          return false;
        }
        if (index === -1) return false;
        return true;
      }
    }
    return false;
  }, [setError, watch, formState.errors, clearErrors]);

  const submitForm = useCallback(
    (data) => {
      if (!validateKeyResult) {
        onSubmit({
          ...data,
          goalAlignment:
            data.goalAlignment && data.goalAlignment.value
              ? data.goalAlignment.value
              : data.goalAlignment,
          isKpi: isGoalKpi,
        });
      }
    },
    [onSubmit, validateKeyResult, isGoalKpi],
  );

  const isSubmitting = useMemo(() => {
    return formState.isSubmitting;
  }, [formState]);

  const hasError = useMemo(() => {
    return (
      formState.submitCount > 1 && Object.keys(formState?.errors)?.length > 0
    );
  }, [formState.errors, formState.submitCount]);

  const handleFormValueChange = useCallback(
    async (value: any, name: string) => {
      setValue(name, value);
      formState.submitCount > 0 && (await trigger());
    },
    [setValue, trigger, formState.submitCount],
  );

  const handleCurrencyChange = (value: string) => {
    setValue('measurement.symbol', value);
  };

  const [todayDate, setTodayDate] = useState({
    starts: new Date().toISOString(),
    ends: '',
  });

  const updateKeyResult = useCallback(
    async (values: TKeyResult[]) => {
      setValue('keyResults', values);
      formState.submitCount > 0 && (await trigger());
    },
    [setValue, formState.submitCount, trigger],
  );

  const validateForm = useCallback(async () => {
    if (formState.submitCount > 0) await trigger();
  }, [formState.submitCount, trigger]);

  const initEditMode = useCallback(async () => {
    setIsFetching(true);
    goalsState.setEditing(true);

    const stateData = JSON.parse((location.state as any)?.data);

    const converted: any = convertAPIformatToFormData({
      ...stateData,
      measurement: stateData.measurement,
    });

    setEditable({
      ...converted,
      manager: converted.manager?.deletedAt ? undefined : converted.manager.id,
      measurement: converted.goalMeasurement || converted.measurement,
      assignee: converted.manager?.deletedAt ? undefined : converted.manager,
      keyResults: converted.keyResults.map((keyResult: any) => ({
        ...keyResult,
        user: keyResult.user.id,
      })),
    });

    // @ts-ignore
  }, [goalsState, location.state]);

  const initDuplicateMode = useCallback(async () => {
    setIsFetching(true);

    const converted: any = convertAPIformatToFormData(
      JSON.parse((location.state as any)?.data),
    );
    goalsState.setEditing(false);
    setEditable({
      ...converted,
      manager: converted.manager.id,
      date: {
        starts: new Date().toISOString(),
        ends: '',
      },
      id: undefined,
      isKpi: converted.isKpi || undefined,
      assignee: converted.manager,
      keyResults: converted.keyResults.map((keyResult: any) => ({
        ...keyResult,
        id: undefined,
        range: {
          ...keyResult.range,
          current: 0,
        },
        user: keyResult.user.id,
      })),
    });
  }, [location.state, goalsState]);

  useMemo(() => {
    if (duplicate_goal) {
      initDuplicateMode();
    } else if (location.state && storeYouGoals.goals_.length) {
      initEditMode();
    } else if (location.pathname === '/edit-goal' || managerEditing) {
      initEditMode();
    }
  }, [
    location.state,
    duplicate_goal,
    initDuplicateMode,
    location.pathname,
    managerEditing,
    storeYouGoals.goals_.length,
    initEditMode,
  ]);

  const getActiveIntegrations = useCallback(async () => {
    const controller = new IntegrationsController();
    const response = await controller.getIntegrations();

    response &&
      setApps(response.filter((res: any) => res.status === 'connected'));
  }, [setApps]);

  useEffect(() => {
    getActiveIntegrations();
  }, [getActiveIntegrations]);

  const submitEditedForm = useCallback(
    async (data: CreateGoalFormData) => {
      formState.submitCount > 0 && (await trigger());

      if (validateKeyResult !== true) {
        setLoader({
          ...loader,
          edit: true,
        });
        const parseData = (data: any) => {
          if (data && data.goalAlignment) {
            if (data.goalAlignment === '') {
              const {goalAlignment, ..._data} = data;
              return {isKpi: isGoalKpi, ..._data};
            } else {
              return {
                ...data,
                goalAlignment:
                  data.goalAlignment && data.goalAlignment.value
                    ? data.goalAlignment.value
                    : data.goalAlignment,

                isKpi: isGoalKpi,
              };
            }
          } else {
            const {goalAlignment, ..._data} = data;
            return {
              isKpi: isGoalKpi,
              range: {current: editable?.range?.current},
              ..._data,
            };
          }
        };

        const request = new CreateGoalRequestImpl();
        const controller: any = new CreateGoalController(request);
        const flushEditedGoalToMobx = (id: any, data: any) => {
          storeYouGoals.fetchedGoals.set(id, convertFormDataToAPIformat(data));
        };
        try {
          controller
            .editGoal(parseData(data), editable?.id)
            .then((res: any) => {
              res && flushEditedGoalToMobx(editable?.id, data);

              if (managerEditing) {
                navigate(-1);
                searchParams.delete('managerEditGoal');
                setIsManagerEditing(false);
                initDirectReportsGoals();
              } else {
                res && navigate(-1);
              }
              res && goalsState.setEditing(false);
              setLoader({
                ...loader,
                edit: false,
              });
            });
        } catch (e) {
          setLoader({
            ...loader,
            edit: false,
          });
        }
      }
    },
    [
      editable?.id,
      goalsState,
      managerEditing,
      editable?.range,
      setIsManagerEditing,
      searchParams,
      initDirectReportsGoals,
      loader,
      navigate,
      isGoalKpi,
      validateKeyResult,
      trigger,
      formState.submitCount,
      storeYouGoals.fetchedGoals,
    ],
  );

  const [isGroup, setIsGroup] = useState(false);
  const [isGroupDisable, setIsGroupDisable] = useState(true);
  const [isAlign, setIsAlign] = useState(false);
  //this is a dummy data for aligning new goal to a parent goal, this is also the format the data used should be rendered
  const dummyGoalsData = [
    {
      label: {
        goalType: 'individual',
        goalName: 'Ship goal feature',
        assigneeName: 'harry potter',
        assigneeAvatar: {
          src: 'https://avatarmaker.net/images/1.png',
          name: 'harry potter',
        },
        date: '20-10-2021',
        id: '1',
      },
      value: '1',
    },
    {
      label: {
        goalType: 'individual',
        goalName: 'Ship analytics feature',
        assigneeName: 'peter drucker',
        assigneeAvatar: {
          src: 'https://avatarmaker.net/images/2.png',
          name: 'peter drucker',
        },
        date: '20-10-2021',
        id: '2',
      },
      value: '2',
    },
  ];
  //
  const [selectedAlignGoal, setSelectedAlignGoal] = useState<any>([]);

  const [
    storeSelectedAlignedGoal,
    // setStoreSelectedAlignGoals,
  ] = useState<any>();
  const insertDefaultData = useCallback(async () => {
    editable && setTodayDate(editable?.date);
    const getGroup = groups?.find((grp) => grp.id === editable?.group);

    const goalType =
      editable?.goalType?.toLowerCase() === 'self-development'
        ? 'individual'
        : editable?.goalType;

    reset({
      name: editable?.name,
      measurement: editable?.measurement,
      meta: editable?.meta,
      manager: editable?.manager,
      range: editable?.range,
      goalType: goalType?.toUpperCase(),
      groupType: getGroup?.groupType,
      group: editable?.group?.map((group: string | {id: string}) =>
        typeof group === 'string' ? group : group.id,
      ),
      goalAlignment: storeSelectedAlignedGoal?.find(
        (alignedGoal: any) => alignedGoal.value === editable?.goalAlignment,
      ),
    });
  }, [editable, reset, storeSelectedAlignedGoal, groups]);

  useEffect(() => {
    if (
      location.pathname === '/edit-goal' ||
      duplicate_goal ||
      managerEditing
    ) {
      setIsFetching(true);
      insertDefaultData().then(() => setIsFetching(false));
    }
  }, [
    editable,
    insertDefaultData,
    location.pathname,
    duplicate_goal,
    location.search,
    managerEditing,
  ]);

  const handleAlignGoal = (data: TOption) => {
    if (data && typeof data === 'object' && data.hasOwnProperty('value')) {
      setValue('goalAlignment', data.value);
    } else {
      setValue('goalAlignment', '');
      setSelectedAlignGoal(storeSelectedAlignedGoal);
    }
  };
  const handleSelectGroup = (value: string[]) => {
    setValue('group', value);
  };
  const handleSelectGroupType = (value: string) => {
    setValue('groupType', value);
  };
  //
  const transformOptions = useCallback(
    (goal: any) => ({
      label: {
        goalType:
          goal.goalType === 'group' || goal.goalType === 'GROUP'
            ? `${
                getGroupTypeAndGroup(groups, allGroupTypes, goal.group)
                  ?.groupType
              } - ${
                getGroupTypeAndGroup(groups, allGroupTypes, goal.group)
                  ?.groupName
              }`
            : goal.goalType === 'company'
            ? 'Company-wide'
            : goal.goalType,
        goalName: goal.name,
        assigneeName:
          goal.assignee &&
          goal.assignee.hasOwnProperty('firstName') &&
          goal.assignee.hasOwnProperty('lastName')
            ? `${goal.assignee?.firstName} ${goal.assignee?.lastName}`
            : goal.assignee?.email,
        assigneeAvatar: {
          src:
            goal.assignee?.avatar && goal.assignee?.avatar.url
              ? goal.assignee?.avatar.url
              : '',
          name:
            goal.assignee &&
            goal.assignee.hasOwnProperty('firstName') &&
            goal.assignee.hasOwnProperty('lastName')
              ? `${goal.assignee?.firstName} ${goal.assignee?.lastName}`
              : goal.assignee?.email,
          id: goal.assignee?.id,
        },
        date: dayjs(goal.createdAt).format('DD/MM/YYYY'),
        id: goal.id,
      },
      value: goal.id,
    }),
    [allGroupTypes, groups],
  );

  const sortingAlgorithm = (
    selectedAlignGoal: any,
    selectedGoalType?: string,
  ) => {
    if (selectedGoalType === GoalKind.INDIVIDUAL) {
      const reviewerCompanyGoals = selectedAlignGoal.filter(
        (goal: any) =>
          goal.label.assigneeAvatar.id === currentUser?.reviewer?.id &&
          goal.label.goalType === GoalKind.COMPANY.toLowerCase(),
      );
      const reviewerGroupGoals = selectedAlignGoal.filter(
        (goal: any) =>
          goal.label.assigneeAvatar.id === currentUser?.reviewer?.id &&
          goal.label.goalType !== GoalKind.INDIVIDUAL.toLowerCase() &&
          goal.label.goalType !== GoalKind.COMPANY.toLowerCase(),
      );
      const reviewerIndividualGoals = selectedAlignGoal.filter(
        (goal: any) =>
          goal.label.assigneeAvatar.id === currentUser?.reviewer?.id &&
          goal.label.goalType === GoalKind.INDIVIDUAL.toLowerCase(),
      );

      const otherCompanyGoals = selectedAlignGoal.filter(
        (goal: any) =>
          goal.label.assigneeAvatar.id !== currentUser?.reviewer?.id &&
          goal.label.goalType === GoalKind.COMPANY.toLowerCase(),
      );
      const otherGroupGoals = selectedAlignGoal.filter(
        (goal: any) =>
          goal.label.assigneeAvatar.id !== currentUser?.reviewer?.id &&
          goal.label.goalType !== GoalKind.INDIVIDUAL.toLowerCase() &&
          goal.label.goalType !== GoalKind.COMPANY.toLowerCase(),
      );
      const otherIndividualGoals = selectedAlignGoal.filter(
        (goal: any) =>
          goal.label.assigneeAvatar.id !== currentUser?.reviewer?.id &&
          goal.label.assigneeAvatar.id === currentUser?.reviewer?.id &&
          goal.label.goalType === GoalKind.INDIVIDUAL.toLowerCase(),
      );

      return [
        ...reviewerCompanyGoals,
        ...reviewerGroupGoals,
        ...reviewerIndividualGoals,
        ...otherCompanyGoals,
        ...otherGroupGoals,
        ...otherIndividualGoals,
      ];
    } else {
      const groupGoals = selectedAlignGoal.filter(
        (goal: any) =>
          goal.label.goalType !== GoalKind.INDIVIDUAL.toLowerCase() &&
          goal.label.goalType !== GoalKind.OVERARCHING,
      );

      const companyGoals = selectedAlignGoal.filter(
        (goal: any) => goal.label.goalType === GoalKind.OVERARCHING,
      );
      return [...companyGoals, ...groupGoals];
    }
  };

  const [isFetchingGroupType] = useState(false);
  const [isFetchingGroup] = useState(false);

  // Goal Template Functionality

  const prefillCreateGoalData = useCallback(async () => {
    const prefilledData = JSON.parse((location.state as any)?.prefilledData);

    reset({
      name: prefilledData?.name,
      goalType: GoalKind.GROUP,
      measurement: {
        unit: MeasurementType.BINARY,
      },
      manager: currentUser?.id,
      assignee: editable?.assignee,
      keyResults: prefilledData?.keyResults?.map((keyResult: any) => ({
        name: keyResult,
        saveSource: false,
        showSource: false,
        source: null,
        range: {
          current: undefined,
          start: undefined,
          target: undefined,
        },
        user: currentUser?.id,
        measurement: {
          unit: MeasurementType.BINARY,
        },
      })),
    });
  }, [currentUser?.id, location.state, reset, editable?.assignee]);

  useEffect(() => {
    if (
      location.pathname === '/create-goal' &&
      (location.state as any)?.prefilledData
    ) {
      setIsFetching(true);

      prefillCreateGoalData().then(() => setIsFetching(false));
    }
  }, [location.pathname, location.state, prefillCreateGoalData]);

  const KpiMeasurementOption = [
    {
      value: 'latest',
      label: 'Latest Value (show the newest value entered)',
    },
    {
      value: 'sum',
      label: 'Sum Value (show the sum of all values entered)',
    },
    {
      value: 'average',
      label: 'Average Value (show the average of all values entered)',
    },
  ];
  const KpiTypeOption = [
    {
      value: 'positive',
      label: 'Positive KPI - Above target figure is positive ',
    },
    {
      value: 'negative',
      label: 'Negative KPI - Above target figure is negative',
    },
  ];

  return {
    register,
    onSubmit,
    errors,
    isSubmitting,
    submitForm,
    handleSubmit,
    control,
    hasError,
    watch,
    handleFormValueChange,
    handleCurrencyChange,
    isFetching,
    editable,
    todayDate,
    updateKeyResult,
    KpiTypeOption,
    dummyGoalsData,
    users: uiUsersFormat
      .map((user: any) => {
        if (
          user &&
          user.label &&
          user.label.name &&
          user.label.name.includes('@')
        ) {
          return {
            ...user,
            label: {
              ...user.label,
              name: `${user.label.name} (pending invitation)`,
              id: user.id,
            },
          };
        } else {
          return user;
        }
      })
      .filter((user: any) => {
        const arrayOfDeactivatedUserIds = deactivatedUsers.map(
          (user: any) => user.id,
        );
        return arrayOfDeactivatedUserIds.includes(user.value) ? false : true;
      }),
    submitEditedForm,
    isGroup,
    setIsGroup,
    isGroupDisable,
    setIsGroupDisable,
    isAlign,
    setIsAlign,
    selectedAlignGoal,
    setSelectedAlignGoal,
    handleAlignGoal,
    isGoalKpi,
    isFetchingGroup,
    isFetchingGroupType,
    validateKeyResult,
    KpiMeasurementOption,
    userGroup: getUser?.groups,
    handleSelectGroup,
    handleSelectGroupType,
    goalGroupType: groupType,
    storeSelectedAlignedGoal,
    setValue,
    transformOptions,
    validateForm,
    sortingAlgorithm,
    isEditingDisabled,
    loader,
    clearErrors,
  };
}
