import styled from 'styled-components';
import {toNumber} from '../../../utils';
import {ChangeEvent, memo, ReactElement, useCallback} from 'react';
import {InputState} from '../../interface';
import {Helper} from '../../atoms/helper';
import {FieldWrapper} from '../../atoms/field-wrapper';
import {down} from 'styled-breakpoints';
import {authStore} from '../../../store/stores/auth-store';
import {KpiPercentageInputNoIcon} from '../../atoms/range/percentage/percentage';

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  align-items: center;
  padding-bottom: 3px;

  ${down('lg')} {
    grid-template-columns: 1fr;
    grid-row-gap: 24px;
  }
`;

export interface PercentageRangeInputProps {
  onChange: (value: {
    start?: number;
    target?: number;
    current?: number;
    good?: number;
    stretch?: number;
  }) => void;
  state?: InputState;
  helper?: string | ReactElement;
  name: string;
  value?: {start: number; target: number; good?: number; stretch?: number};
  onBlur?: () => void;
}

export const PercentageRangeInput = memo(function ({
  state,
  helper,
  onChange,
  value,
  onBlur,
}: PercentageRangeInputProps) {
  const onUpdateStart = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const {value: innerValue} = e.target;
      onChange({
        ...value,
        start: toNumber(innerValue),
        current: toNumber(innerValue),
      });
    },
    [onChange, value],
  );

  const onUpdateTarget = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const {value: innerValue} = e.target;
      onChange({
        ...value,
        target: toNumber(innerValue),
      });
    },
    [onChange, value],
  );
  const onUpdateGood = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const {value: innerValue} = e.target;
      onChange({
        ...value,
        good: toNumber(innerValue),
      });
    },
    [onChange, value],
  );
  const onUpdateStretch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const {value: innerValue} = e.target;
      onChange({
        ...value,
        stretch: toNumber(innerValue),
      });
    },
    [onChange, value],
  );

  const PercentageInputX = KpiPercentageInputNoIcon;

  return (
    <FieldWrapper>
      <Content>
        <div>
          <PercentageInputX
            label={'%'}
            value={value?.start}
            onChange={onUpdateStart}
            onBlur={onBlur}
            name="start"
            id="start"
            state={state}
            placeholder="Start value"
          />
        </div>
        <div>
          <PercentageInputX
            label={'%'}
            value={value?.target}
            onChange={onUpdateTarget}
            onBlur={onBlur}
            name="target"
            id="target"
            state={state}
            placeholder={
              authStore?.auth?.user?.workspace?.framework === 'kpi'
                ? 'Base value'
                : 'Target value'
            }
          />
        </div>
        {authStore?.auth?.user?.workspace?.framework === 'kpi' && (
          <>
            <div style={{marginTop: 8}}>
              <PercentageInputX
                label={
                  authStore?.auth?.user?.workspace?.framework === 'kpi'
                    ? '%'
                    : 'Good'
                }
                value={value?.good}
                onChange={onUpdateGood}
                onBlur={onBlur}
                name="good"
                id="good"
                state={state}
                placeholder="Good value"
              />
            </div>
            <div style={{marginTop: 8}}>
              <PercentageInputX
                label={
                  authStore?.auth?.user?.workspace?.framework === 'kpi'
                    ? '%'
                    : 'Stretch'
                }
                value={value?.stretch}
                onChange={onUpdateStretch}
                onBlur={onBlur}
                name="stretch"
                id="stretch"
                state={state}
                placeholder="Stretch value"
              />
            </div>
          </>
        )}
      </Content>
      <Helper aria-label="helper" state={state} children={helper} />
    </FieldWrapper>
  );
});

PercentageRangeInput.displayName = 'PercentageRangeInput';
