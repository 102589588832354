import {YouHightLight} from '@pages/dashboard/you/you-components/you-hightligts';
import {Headline3, Body1} from '@ui/atoms/typography';
import {ItemLoader} from '@ui/organisms/item-loader';
import {ProgressDiv} from '../my_checkins-style';
import {observer} from 'mobx-react';
import {VerticalSpacer} from '@ui/atoms/spacer';

interface IProgress {
  loadingGoals: boolean;
  isReadOnly: boolean;
  goals: any[];
}
export const ProgressOnObjectives = observer(
  ({loadingGoals, isReadOnly, goals}: IProgress) => {
    return (
      <>
        {
          <ProgressDiv className="bg-white">
            {loadingGoals && <ItemLoader />}

            {!loadingGoals && goals?.length > 0 && (
              <>
                <div className="titleDiv">
                  <Headline3 kind="textDark">
                    Goals
                    <span className="required">*</span> {'  '}
                  </Headline3>
                  <VerticalSpacer size="5px" />
                  <Body1 kind="textBody">Update your progress</Body1>
                </div>
                <div className="contentDiv">
                  {!loadingGoals && goals?.length > 0 && (
                    <YouHightLight
                      goals={
                        new Map(goals?.map((item: any) => [item?.id, item]))
                      }
                      kind="youcheckins"
                      youCheckinsKind={isReadOnly ? 'viewuser' : undefined}
                      isFetching={loadingGoals}
                    />
                  )}
                  {loadingGoals && <ItemLoader />}
                </div>
              </>
            )}
          </ProgressDiv>
        }
        {!loadingGoals && goals?.length === 0 && <></>}
      </>
    );
  },
);
