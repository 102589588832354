import styled, {DefaultTheme, StyledComponentProps} from 'styled-components';
import {ITypography, Typography} from './typography';
import {
  getFontSizeFromTheme,
  getFontWeightFromTheme,
  getLineHeightFromTheme,
} from '../../ui-utils';

export type Headline3Props = StyledComponentProps<
  'h3',
  DefaultTheme,
  ITypography,
  'kind' | 'align'
>;

/**
 Use to display higher hierarchical content
 **/
export const Headline3 = styled.h3<ITypography>`
  ${Typography};

  font-size: ${getFontSizeFromTheme('headline3')};
  font-weight: ${getFontWeightFromTheme('bold')};
  line-height: ${getLineHeightFromTheme('headline3')};
`;
