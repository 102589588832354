import {CurrencyType, MeasurementType} from './hooks/interface';

export const departments = [
  {
    label: 'Engineering',
    value: 'Engineering',
  },
  {
    label: 'Marketing',
    value: 'Marketing',
  },
  {
    label: 'Human Resource Management',
    value: 'Human Resource Management',
  },
  {
    label: 'Purchasing',
    value: 'Purchasing',
  },
  {
    label: 'Research and Development',
    value: 'Research and Development',
  },
  {
    label: 'Business Development',
    value: 'Business Development',
  },
];

export const userRoleOptions = [
  {label: 'Accountant', value: 'Accountant'},
  {label: 'Admin', value: 'Admin'},
  {label: 'Analyst', value: 'Analyst'},
  {label: 'Assistant Manager', value: 'Assistant Manager'},
  {label: 'BDM', value: 'BDM'},
  {label: 'CEO', value: 'CEO'},
  {label: 'CEO/Founder', value: 'CEO/Founder'},
  {label: 'Chartered Accountant', value: 'Chartered Accountant'},
  {label: 'Chairman', value: 'Chairman'},
  {label: 'Chief Financial Officer', value: 'Chief Financial Officer'},
  {label: 'Clerk', value: 'Clerk'},
  {label: 'COO', value: 'COO'},
  {label: 'CTO', value: 'CTO'},
  {label: 'CXO', value: 'CXO'},
  {label: 'Consultant', value: 'Consultant'},
  {label: 'Committee Member', value: 'Committee Member'},
  {label: 'Designer', value: 'Designer'},
  {label: 'Developer', value: 'Developer'},
  {label: 'Director', value: 'Director'},
  {label: 'Doctor', value: 'Doctor'},
  {label: 'Engineer', value: 'Engineer'},
  {label: 'Executive', value: 'Executive'},
  {label: 'Finance Manager', value: 'Finance Manager'},
  {label: 'Freelancer', value: 'Freelancer'},
  {label: 'General Manager', value: 'General Manager'},
  {label: 'HR Executive', value: 'HR Executive'},
  {label: 'HR Manager', value: 'HR Manager'},
  {label: 'IT Manager', value: 'IT Manager'},
  {label: 'Librarian', value: 'Librarian'},
  {label: 'Managing Director', value: 'Managing Director'},
  {label: 'Marketing Manager', value: 'Marketing Manager'},
  {label: 'Manager', value: 'Manager'},
  {label: 'Operations Head', value: 'Operations Head'},
  {label: 'Operations Lead', value: 'Operations Lead'},
  {label: 'Owner/Proprietor', value: 'Owner/Proprietor'},
  {label: 'President', value: 'President'},
  {label: 'Partner', value: 'Partner'},
  {label: 'Principal', value: 'Principal'},
  {label: 'Professor', value: 'Professor'},
  {label: 'Project Lead', value: 'Project Lead'},
  {label: 'Promoter', value: 'Promoter'},
  {label: 'Purchase Manager', value: 'Purchase Manager'},
  {label: 'Regional Director', value: 'Regional Director'},
  {label: 'Regional Manager', value: 'Regional Manager'},
  {label: 'Sales Manager', value: 'Sales Manager'},
  {label: 'Secretary', value: 'Secretary'},
  {label: 'Society Member', value: 'Society Member'},
  {label: 'Supervisor', value: 'Supervisor'},
  {label: 'Teacher', value: 'Teacher'},
  {label: 'Team Lead', value: 'Team Lead'},
  {label: 'Technical', value: 'Technical'},
  {label: 'Trustee', value: 'Trustee'},
  {label: 'Vice President', value: 'Vice President'},
];

export const permissions = [
  {
    label: 'Admin',
    value: 'admin',
  },
  {
    label: 'User',
    value: 'user',
  },
];

export const frequencies = [
  {
    label: 'Monthly',
    value: 'monthly',
  },
  {
    label: 'Quarterly',
    value: 'quarterly',
  },
];

export const schedules = [
  // {
  //   label: 'Same as company’s',
  //   value: 'same-as-company’s',
  // },
  {
    label: 'Weekly',
    value: 'weekly',
  },
  {
    label: 'Bi-weekly',
    value: 'bi-weekly',
  },
  {
    label: 'Monthly',
    value: 'monthly',
  },
];

export const weekdays = [
  {
    label: 'Monday',
    value: 'monday',
  },
  {
    label: 'Tuesday',
    value: 'tuesday',
  },
  {
    label: 'Wednesday',
    value: 'wednesday',
  },
  {
    label: 'Thursday',
    value: 'thursday',
  },
  {
    label: 'Friday',
    value: 'friday',
  },
  {
    label: 'Saturday',
    value: 'saturday',
  },
];

export const departmentsList = [
  'Engineering',
  'Marketing',
  'Human Resource Management',
  'Purchasing',
  'Research and Development',
  'Business Development',
];
export const AUTH_ROUTES = [
  '/sign-in',
  '/workspace-setup',
  '/sign-up',
  '/verify-user-invitation',
  '/verify-email',
  '/verify-user',
  '/email-sent',
  '/email-unverified',
  '/forgot-password',
  '/reset-password',
  '',
];
//eslint-disable-next-line
export const symbolRegex = /[!@#$ %^&* ()_ + {}\[\]:; <>,.? ~\\\-]/;

//eslint-disable-next-line
export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\\-])(?=.{8,})/;

export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const passwordPhrase =
  'Password must be at least 8 characters long and must contain one of each (upper case, lower case and special characters- !@#$%^&* )';

export const regexTwoMinCharacters = /^[a-zA-Z -]{2,}$/;
export const regexSpecialCharacters = /^((?=.[!@#$%^&()-=+{};:,<.>]){1})+$/;
export const regexOnlyAlphabet = /^[a-zA-Z ]+$/;
export const regexAlphabetHyphen = /^[a-zA-Z]+(-[a-zA-Z]+)*$/;

export const measurements = [
  {
    label: 'BOOLEAN',
    value: 'BOOLEAN',
  },
  {
    label: 'BOOLEAN',
    value: 'BOOLEAN',
  },
  {
    label: 'BOOLEAN',
    value: 'BOOLEAN',
  },
  {
    label: 'BOOLEAN',
    value: 'BOOLEAN',
  },
];

export const users = [
  {
    label: {
      avatar: {
        name: 'Obi-Enadhuze Oke',
        src: 'https://avatarmaker.net/images/1.png',
      },
      id: 'id-1',
      name: 'Obi-Enadhuze Oke',
    },
    value: '5fb5061576b5ac003c6c2ff1',
  },
  {
    label: {
      avatar: {
        name: 'Quadri Jones',
        src: 'https://avatarmaker.net/images/2.png',
      },
      id: 'id-2',
      name: 'Quadri Jones',
    },
    value: '5fb5061576b5ac003c6c2ff1',
  },
  {
    label: {
      avatar: {
        name: 'Gladys Wilson',
        src: 'https://avatarmaker.net/images/3.png',
      },
      id: 'id-3',
      name: 'Gladys Wilson',
    },
    value: '5fb5061576b5ac003c6c2ff1',
  },
  {
    label: {
      avatar: {
        name: 'Quadri Jones',
        src: 'https://avatarmaker.net/images/2.png',
      },
      id: 'id-2',
      name: 'Quadri Jones',
    },
    value: '5fb5061576b5ac003c6c2ff1',
  },
  {
    label: {
      avatar: {
        name: 'Gladys Wilson',
        src: 'https://avatarmaker.net/images/3.png',
      },
      id: 'id-3',
      name: 'Gladys Wilson',
    },
    value: '5fb5061576b5ac003c6c2ff1',
  },
  {
    label: {
      avatar: {
        name: 'Jennie McKinney',
        src: 'https://avatarmaker.net/images/4.png',
      },
      id: 'id-4',
      name: 'Jennie McKinney',
    },
    value: '5fb5061576b5ac003c6c2ff1',
  },
];

export const Currencies: Map<CurrencyType, string> = new Map([
  [CurrencyType.DOLLAR, '$ Dollars'],
  [CurrencyType.EURO, '€ Euros'],
  [CurrencyType.POUND, '£ Pounds'],
  [CurrencyType.NAIRA, '₦ Naira'],
]);

export enum CurrencyValue {
  NAIRA = 'NGN',
  DOLLAR = 'USD',
  EURO = 'EUR',
  POUND = 'GBP',
}

export const getCurrencySymbol: Map<CurrencyValue, string> = new Map([
  [CurrencyValue.DOLLAR, '$'],
  [CurrencyValue.EURO, '€'],
  [CurrencyValue.POUND, '£'],
  [CurrencyValue.NAIRA, '₦ '],
]);

export const CurrencySymbol = [
  {
    label: '$ Dollars',
    value: 'USD',
  },
  {
    label: '€ Euros',
    value: 'EUR',
  },
  {
    label: '£ Pounds',
    value: 'GBP',
  },
  {
    label: '₦ Naira',
    value: 'NGN',
  },
];
export const parseCurrencyValue = (currencyValue: string) => {
  if (currencyValue === 'NGN') {
    return CurrencyType.NAIRA;
  } else if (currencyValue === 'EUR') {
    return CurrencyType.EURO;
  } else if (currencyValue === 'GBP') {
    return CurrencyType.POUND;
  } else if (currencyValue === 'USD') {
    return CurrencyType.DOLLAR;
  } else return CurrencyType.DOLLAR;
};

export const Metrics = [
  {
    label: MeasurementType.BINARY,
    value: MeasurementType.BINARY,
  },
  {
    label: MeasurementType.NUMERIC,
    value: MeasurementType.NUMERIC,
  },
  {
    label: MeasurementType.PERCENTAGE,
    value: MeasurementType.PERCENTAGE,
  },
  {
    label: MeasurementType.CURRENCY,
    value: MeasurementType.CURRENCY,
  },
];

export const timezones = [
  {
    label: 'Pacific/Niue',
    value: '(GMT-11:00) Niue',
  },
  {
    label: 'Pacific/Pago_Pago',
    value: '(GMT-11:00) Pago Pago',
  },
  {
    label: 'Pacific/Honolulu',
    value: '(GMT-10:00) Hawaii Time',
  },
  {
    label: 'Pacific/Rarotonga',
    value: '(GMT-10:00) Rarotonga',
  },
  {
    label: 'Pacific/Tahiti',
    value: '(GMT-10:00) Tahiti',
  },
  {
    label: 'Pacific/Marquesas',
    value: '(GMT-09:30) Marquesas',
  },
  {
    label: 'America/Anchorage',
    value: '(GMT-09:00) Alaska Time',
  },
  {
    label: 'Pacific/Gambier',
    value: '(GMT-09:00) Gambier',
  },
  {
    label: 'America/Los_Angeles',
    value: '(GMT-08:00) Pacific Time',
  },
  {
    label: 'America/Tijuana',
    value: '(GMT-08:00) Pacific Time - Tijuana',
  },
  {
    label: 'America/Vancouver',
    value: '(GMT-08:00) Pacific Time - Vancouver',
  },
  {
    label: 'America/Whitehorse',
    value: '(GMT-08:00) Pacific Time - Whitehorse',
  },
  {
    label: 'Pacific/Pitcairn',
    value: '(GMT-08:00) Pitcairn',
  },
  {
    label: 'America/Dawson_Creek',
    value: '(GMT-07:00) Mountain Time - Dawson Creek',
  },
  {
    label: 'America/Denver',
    value: '(GMT-07:00) Mountain Time',
  },
  {
    label: 'America/Edmonton',
    value: '(GMT-07:00) Mountain Time - Edmonton',
  },
  {
    label: 'America/Hermosillo',
    value: '(GMT-07:00) Mountain Time - Hermosillo',
  },
  {
    label: 'America/Mazatlan',
    value: '(GMT-07:00) Mountain Time - Chihuahua, Mazatlan',
  },
  {
    label: 'America/Phoenix',
    value: '(GMT-07:00) Mountain Time - Arizona',
  },
  {
    label: 'America/Yellowknife',
    value: '(GMT-07:00) Mountain Time - Yellowknife',
  },
  {
    label: 'America/Belize',
    value: '(GMT-06:00) Belize',
  },
  {
    label: 'America/Chicago',
    value: '(GMT-06:00) Central Time',
  },
  {
    label: 'America/Costa_Rica',
    value: '(GMT-06:00) Costa Rica',
  },
  {
    label: 'America/El_Salvador',
    value: '(GMT-06:00) El Salvador',
  },
  {
    label: 'America/Guatemala',
    value: '(GMT-06:00) Guatemala',
  },
  {
    label: 'America/Managua',
    value: '(GMT-06:00) Managua',
  },
  {
    label: 'America/Mexico_City',
    value: '(GMT-06:00) Central Time - Mexico City',
  },
  {
    label: 'America/Regina',
    value: '(GMT-06:00) Central Time - Regina',
  },
  {
    label: 'America/Tegucigalpa',
    value: '(GMT-06:00) Central Time - Tegucigalpa',
  },
  {
    label: 'America/Winnipeg',
    value: '(GMT-06:00) Central Time - Winnipeg',
  },
  {
    label: 'Pacific/Galapagos',
    value: '(GMT-06:00) Galapagos',
  },
  {
    label: 'America/Bogota',
    value: '(GMT-05:00) Bogota',
  },
  {
    label: 'America/Cancun',
    value: '(GMT-05:00) America Cancun',
  },
  {
    label: 'America/Cayman',
    value: '(GMT-05:00) Cayman',
  },
  {
    label: 'America/Guayaquil',
    value: '(GMT-05:00) Guayaquil',
  },
  {
    label: 'America/Havana',
    value: '(GMT-05:00) Havana',
  },
  {
    label: 'America/Iqaluit',
    value: '(GMT-05:00) Eastern Time - Iqaluit',
  },
  {
    label: 'America/Jamaica',
    value: '(GMT-05:00) Jamaica',
  },
  {
    label: 'America/Lima',
    value: '(GMT-05:00) Lima',
  },
  {
    label: 'America/New_York',
    value: '(GMT-05:00) Eastern Time',
  },
  {
    label: 'America/Nassau',
    value: '(GMT-05:00) Nassau',
  },
  {
    label: 'America/Panama',
    value: '(GMT-05:00) Panama',
  },
  {
    label: 'America/Port-au-Prince',
    value: '(GMT-05:00) Port-au-Prince',
  },
  {
    label: 'America/Rio_Branco',
    value: '(GMT-05:00) Rio Branco',
  },
  {
    label: 'America/Toronto',
    value: '(GMT-05:00) Eastern Time - Toronto',
  },
  {
    label: 'Pacific/Easter',
    value: '(GMT-05:00) Easter Island',
  },
  {
    label: 'America/Caracas',
    value: '(GMT-04:30) Caracas',
  },
  {
    label: 'America/Asuncion',
    value: '(GMT-03:00) Asuncion',
  },
  {
    label: 'America/Barbados',
    value: '(GMT-04:00) Barbados',
  },
  {
    label: 'America/Boa_Vista',
    value: '(GMT-04:00) Boa Vista',
  },
  {
    label: 'America/Campo_Grande',
    value: '(GMT-03:00) Campo Grande',
  },
  {
    label: 'America/Cuiaba',
    value: '(GMT-03:00) Cuiaba',
  },
  {
    label: 'America/Curacao',
    value: '(GMT-04:00) Curacao',
  },
  {
    label: 'America/Grand_Turk',
    value: '(GMT-04:00) Grand Turk',
  },
  {
    label: 'America/Guyana',
    value: '(GMT-04:00) Guyana',
  },
  {
    label: 'America/Halifax',
    value: '(GMT-04:00) Atlantic Time - Halifax',
  },
  {
    label: 'America/La_Paz',
    value: '(GMT-04:00) La Paz',
  },
  {
    label: 'America/Manaus',
    value: '(GMT-04:00) Manaus',
  },
  {
    label: 'America/Martinique',
    value: '(GMT-04:00) Martinique',
  },
  {
    label: 'America/Port_of_Spain',
    value: '(GMT-04:00) Port of Spain',
  },
  {
    label: 'America/Porto_Velho',
    value: '(GMT-04:00) Porto Velho',
  },
  {
    label: 'America/Puerto_Rico',
    value: '(GMT-04:00) Puerto Rico',
  },
  {
    label: 'America/Santo_Domingo',
    value: '(GMT-04:00) Santo Domingo',
  },
  {
    label: 'America/Thule',
    value: '(GMT-04:00) Thule',
  },
  {
    label: 'Atlantic/Bermuda',
    value: '(GMT-04:00) Bermuda',
  },
  {
    label: 'America/St_Johns',
    value: '(GMT-03:30) Newfoundland Time - St. Johns',
  },
  {
    label: 'America/Araguaina',
    value: '(GMT-03:00) Araguaina',
  },
  {
    label: 'America/Argentina/Buenos_Aires',
    value: '(GMT-03:00) Buenos Aires',
  },
  {
    label: 'America/Bahia',
    value: '(GMT-03:00) Salvador',
  },
  {
    label: 'America/Belem',
    value: '(GMT-03:00) Belem',
  },
  {
    label: 'America/Cayenne',
    value: '(GMT-03:00) Cayenne',
  },
  {
    label: 'America/Fortaleza',
    value: '(GMT-03:00) Fortaleza',
  },
  {
    label: 'America/Godthab',
    value: '(GMT-03:00) Godthab',
  },
  {
    label: 'America/Maceio',
    value: '(GMT-03:00) Maceio',
  },
  {
    label: 'America/Miquelon',
    value: '(GMT-03:00) Miquelon',
  },
  {
    label: 'America/Montevideo',
    value: '(GMT-03:00) Montevideo',
  },
  {
    label: 'America/Paramaribo',
    value: '(GMT-03:00) Paramaribo',
  },
  {
    label: 'America/Recife',
    value: '(GMT-03:00) Recife',
  },
  {
    label: 'America/Santiago',
    value: '(GMT-03:00) Santiago',
  },
  {
    label: 'America/Sao_Paulo',
    value: '(GMT-02:00) Sao Paulo',
  },
  {
    label: 'Antarctica/Palmer',
    value: '(GMT-03:00) Palmer',
  },
  {
    label: 'Antarctica/Rothera',
    value: '(GMT-03:00) Rothera',
  },
  {
    label: 'Atlantic/Stanley',
    value: '(GMT-03:00) Stanley',
  },
  {
    label: 'America/Noronha',
    value: '(GMT-02:00) Noronha',
  },
  {
    label: 'Atlantic/South_Georgia',
    value: '(GMT-02:00) South Georgia',
  },
  {
    label: 'America/Scoresbysund',
    value: '(GMT-01:00) Scoresbysund',
  },
  {
    label: 'Atlantic/Azores',
    value: '(GMT-01:00) Azores',
  },
  {
    label: 'Atlantic/Cape_Verde',
    value: '(GMT-01:00) Cape Verde',
  },
  {
    label: 'Africa/Abidjan',
    value: '(GMT+00:00) Abidjan',
  },
  {
    label: 'Africa/Accra',
    value: '(GMT+00:00) Accra',
  },
  {
    label: 'Africa/Bissau',
    value: '(GMT+00:00) Bissau',
  },
  {
    label: 'Africa/Casablanca',
    value: '(GMT+00:00) Casablanca',
  },
  {
    label: 'Africa/El_Aaiun',
    value: '(GMT+00:00) El Aaiun',
  },
  {
    label: 'Africa/Monrovia',
    value: '(GMT+00:00) Monrovia',
  },
  {
    label: 'America/Danmarkshavn',
    value: '(GMT+00:00) Danmarkshavn',
  },
  {
    label: 'Atlantic/Canary',
    value: '(GMT+00:00) Canary Islands',
  },
  {
    label: 'Atlantic/Faroe',
    value: '(GMT+00:00) Faeroe',
  },
  {
    label: 'Atlantic/Reykjavik',
    value: '(GMT+00:00) Reykjavik',
  },
  {
    label: 'Etc/GMT',
    value: '(GMT+00:00) GMT (no daylight saving)',
  },
  {
    label: 'Europe/Dublin',
    value: '(GMT+00:00) Dublin',
  },
  {
    label: 'Europe/Lisbon',
    value: '(GMT+00:00) Lisbon',
  },
  {
    label: 'Europe/London',
    value: '(GMT+00:00) London',
  },
  {
    label: 'Africa/Algiers',
    value: '(GMT+01:00) Algiers',
  },
  {
    label: 'Africa/Ceuta',
    value: '(GMT+01:00) Ceuta',
  },
  {
    label: 'Africa/Lagos',
    value: '(GMT+01:00) Lagos',
  },
  {
    label: 'Africa/Ndjamena',
    value: '(GMT+01:00) Ndjamena',
  },
  {
    label: 'Africa/Tunis',
    value: '(GMT+01:00) Tunis',
  },
  {
    label: 'Africa/Windhoek',
    value: '(GMT+02:00) Windhoek',
  },
  {
    label: 'Europe/Amsterdam',
    value: '(GMT+01:00) Amsterdam',
  },
  {
    label: 'Europe/Andorra',
    value: '(GMT+01:00) Andorra',
  },
  {
    label: 'Europe/Belgrade',
    value: '(GMT+01:00) Central European Time - Belgrade',
  },
  {
    label: 'Europe/Berlin',
    value: '(GMT+01:00) Berlin',
  },
  {
    label: 'Europe/Brussels',
    value: '(GMT+01:00) Brussels',
  },
  {
    label: 'Europe/Budapest',
    value: '(GMT+01:00) Budapest',
  },
  {
    label: 'Europe/Copenhagen',
    value: '(GMT+01:00) Copenhagen',
  },
  {
    label: 'Europe/Gibraltar',
    value: '(GMT+01:00) Gibraltar',
  },
  {
    label: 'Europe/Luxembourg',
    value: '(GMT+01:00) Luxembourg',
  },
  {
    label: 'Europe/Madrid',
    value: '(GMT+01:00) Madrid',
  },
  {
    label: 'Europe/Malta',
    value: '(GMT+01:00) Malta',
  },
  {
    label: 'Europe/Monaco',
    value: '(GMT+01:00) Monaco',
  },
  {
    label: 'Europe/Oslo',
    value: '(GMT+01:00) Oslo',
  },
  {
    label: 'Europe/Paris',
    value: '(GMT+01:00) Paris',
  },
  {
    label: 'Europe/Prague',
    value: '(GMT+01:00) Central European Time - Prague',
  },
  {
    label: 'Europe/Rome',
    value: '(GMT+01:00) Rome',
  },
  {
    label: 'Europe/Stockholm',
    value: '(GMT+01:00) Stockholm',
  },
  {
    label: 'Europe/Tirane',
    value: '(GMT+01:00) Tirane',
  },
  {
    label: 'Europe/Vienna',
    value: '(GMT+01:00) Vienna',
  },
  {
    label: 'Europe/Warsaw',
    value: '(GMT+01:00) Warsaw',
  },
  {
    label: 'Europe/Zurich',
    value: '(GMT+01:00) Zurich',
  },
  {
    label: 'Africa/Cairo',
    value: '(GMT+02:00) Cairo',
  },
  {
    label: 'Africa/Johannesburg',
    value: '(GMT+02:00) Johannesburg',
  },
  {
    label: 'Africa/Maputo',
    value: '(GMT+02:00) Maputo',
  },
  {
    label: 'Africa/Tripoli',
    value: '(GMT+02:00) Tripoli',
  },
  {
    label: 'Asia/Amman',
    value: '(GMT+02:00) Amman',
  },
  {
    label: 'Asia/Beirut',
    value: '(GMT+02:00) Beirut',
  },
  {
    label: 'Asia/Damascus',
    value: '(GMT+02:00) Damascus',
  },
  {
    label: 'Asia/Gaza',
    value: '(GMT+02:00) Gaza',
  },
  {
    label: 'Asia/Jerusalem',
    value: '(GMT+02:00) Jerusalem',
  },
  {
    label: 'Asia/Nicosia',
    value: '(GMT+02:00) Nicosia',
  },
  {
    label: 'Europe/Athens',
    value: '(GMT+02:00) Athens',
  },
  {
    label: 'Europe/Bucharest',
    value: '(GMT+02:00) Bucharest',
  },
  {
    label: 'Europe/Chisinau',
    value: '(GMT+02:00) Chisinau',
  },
  {
    label: 'Europe/Helsinki',
    value: '(GMT+02:00) Helsinki',
  },
  {
    label: 'Europe/Istanbul',
    value: '(GMT+02:00) Istanbul',
  },
  {
    label: 'Europe/Kaliningrad',
    value: '(GMT+02:00) Moscow-01 - Kaliningrad',
  },
  {
    label: 'Europe/Kiev',
    value: '(GMT+02:00) Kiev',
  },
  {
    label: 'Europe/Riga',
    value: '(GMT+02:00) Riga',
  },
  {
    label: 'Europe/Sofia',
    value: '(GMT+02:00) Sofia',
  },
  {
    label: 'Europe/Tallinn',
    value: '(GMT+02:00) Tallinn',
  },
  {
    label: 'Europe/Vilnius',
    value: '(GMT+02:00) Vilnius',
  },
  {
    label: 'Africa/Khartoum',
    value: '(GMT+03:00) Khartoum',
  },
  {
    label: 'Africa/Nairobi',
    value: '(GMT+03:00) Nairobi',
  },
  {
    label: 'Antarctica/Syowa',
    value: '(GMT+03:00) Syowa',
  },
  {
    label: 'Asia/Baghdad',
    value: '(GMT+03:00) Baghdad',
  },
  {
    label: 'Asia/Qatar',
    value: '(GMT+03:00) Qatar',
  },
  {
    label: 'Asia/Riyadh',
    value: '(GMT+03:00) Riyadh',
  },
  {
    label: 'Europe/Minsk',
    value: '(GMT+03:00) Minsk',
  },
  {
    label: 'Europe/Moscow',
    value: '(GMT+03:00) Moscow+00 - Moscow',
  },
  {
    label: 'Asia/Tehran',
    value: '(GMT+03:30) Tehran',
  },
  {
    label: 'Asia/Baku',
    value: '(GMT+04:00) Baku',
  },
  {
    label: 'Asia/Dubai',
    value: '(GMT+04:00) Dubai',
  },
  {
    label: 'Asia/Tbilisi',
    value: '(GMT+04:00) Tbilisi',
  },
  {
    label: 'Asia/Yerevan',
    value: '(GMT+04:00) Yerevan',
  },
  {
    label: 'Europe/Samara',
    value: '(GMT+04:00) Moscow+01 - Samara',
  },
  {
    label: 'Indian/Mahe',
    value: '(GMT+04:00) Mahe',
  },
  {
    label: 'Indian/Mauritius',
    value: '(GMT+04:00) Mauritius',
  },
  {
    label: 'Indian/Reunion',
    value: '(GMT+04:00) Reunion',
  },
  {
    label: 'Asia/Kabul',
    value: '(GMT+04:30) Kabul',
  },
  {
    label: 'Antarctica/Mawson',
    value: '(GMT+05:00) Mawson',
  },
  {
    label: 'Asia/Aqtau',
    value: '(GMT+05:00) Aqtau',
  },
  {
    label: 'Asia/Aqtobe',
    value: '(GMT+05:00) Aqtobe',
  },
  {
    label: 'Asia/Ashgabat',
    value: '(GMT+05:00) Ashgabat',
  },
  {
    label: 'Asia/Dushanbe',
    value: '(GMT+05:00) Dushanbe',
  },
  {
    label: 'Asia/Karachi',
    value: '(GMT+05:00) Karachi',
  },
  {
    label: 'Asia/Tashkent',
    value: '(GMT+05:00) Tashkent',
  },
  {
    label: 'Asia/Yekaterinburg',
    value: '(GMT+05:00) Moscow+02 - Yekaterinburg',
  },
  {
    label: 'Indian/Kerguelen',
    value: '(GMT+05:00) Kerguelen',
  },
  {
    label: 'Indian/Maldives',
    value: '(GMT+05:00) Maldives',
  },
  {
    label: 'Asia/Calcutta',
    value: '(GMT+05:30) India Standard Time',
  },
  {
    label: 'Asia/Colombo',
    value: '(GMT+05:30) Colombo',
  },
  {
    label: 'Asia/Katmandu',
    value: '(GMT+05:45) Katmandu',
  },
  {
    label: 'Antarctica/Vostok',
    value: '(GMT+06:00) Vostok',
  },
  {
    label: 'Asia/Almaty',
    value: '(GMT+06:00) Almaty',
  },
  {
    label: 'Asia/Bishkek',
    value: '(GMT+06:00) Bishkek',
  },
  {
    label: 'Asia/Dhaka',
    value: '(GMT+06:00) Dhaka',
  },
  {
    label: 'Asia/Omsk',
    value: '(GMT+06:00) Moscow+03 - Omsk, Novosibirsk',
  },
  {
    label: 'Asia/Thimphu',
    value: '(GMT+06:00) Thimphu',
  },
  {
    label: 'Indian/Chagos',
    value: '(GMT+06:00) Chagos',
  },
  {
    label: 'Asia/Rangoon',
    value: '(GMT+06:30) Rangoon',
  },
  {
    label: 'Indian/Cocos',
    value: '(GMT+06:30) Cocos',
  },
  {
    label: 'Antarctica/Davis',
    value: '(GMT+07:00) Davis',
  },
  {
    label: 'Asia/Bangkok',
    value: '(GMT+07:00) Bangkok',
  },
  {
    label: 'Asia/Hovd',
    value: '(GMT+07:00) Hovd',
  },
  {
    label: 'Asia/Jakarta',
    value: '(GMT+07:00) Jakarta',
  },
  {
    label: 'Asia/Krasnoyarsk',
    value: '(GMT+07:00) Moscow+04 - Krasnoyarsk',
  },
  {
    label: 'Asia/Saigon',
    value: '(GMT+07:00) Hanoi',
  },
  {
    label: 'Asia/Ho_Chi_Minh',
    value: '(GMT+07:00) Ho Chi Minh',
  },
  {
    label: 'Indian/Christmas',
    value: '(GMT+07:00) Christmas',
  },
  {
    label: 'Antarctica/Casey',
    value: '(GMT+08:00) Casey',
  },
  {
    label: 'Asia/Brunei',
    value: '(GMT+08:00) Brunei',
  },
  {
    label: 'Asia/Choibalsan',
    value: '(GMT+08:00) Choibalsan',
  },
  {
    label: 'Asia/Hong_Kong',
    value: '(GMT+08:00) Hong Kong',
  },
  {
    label: 'Asia/Irkutsk',
    value: '(GMT+08:00) Moscow+05 - Irkutsk',
  },
  {
    label: 'Asia/Kuala_Lumpur',
    value: '(GMT+08:00) Kuala Lumpur',
  },
  {
    label: 'Asia/Macau',
    value: '(GMT+08:00) Macau',
  },
  {
    label: 'Asia/Makassar',
    value: '(GMT+08:00) Makassar',
  },
  {
    label: 'Asia/Manila',
    value: '(GMT+08:00) Manila',
  },
  {
    label: 'Asia/Shanghai',
    value: '(GMT+08:00) China Time - Beijing',
  },
  {
    label: 'Asia/Singapore',
    value: '(GMT+08:00) Singapore',
  },
  {
    label: 'Asia/Taipei',
    value: '(GMT+08:00) Taipei',
  },
  {
    label: 'Asia/Ulaanbaatar',
    value: '(GMT+08:00) Ulaanbaatar',
  },
  {
    label: 'Australia/Perth',
    value: '(GMT+08:00) Western Time - Perth',
  },
  {
    label: 'Asia/Pyongyang',
    value: '(GMT+08:30) Pyongyang',
  },
  {
    label: 'Asia/Dili',
    value: '(GMT+09:00) Dili',
  },
  {
    label: 'Asia/Jayapura',
    value: '(GMT+09:00) Jayapura',
  },
  {
    label: 'Asia/Seoul',
    value: '(GMT+09:00) Seoul',
  },
  {
    label: 'Asia/Tokyo',
    value: '(GMT+09:00) Tokyo',
  },
  {
    label: 'Asia/Yakutsk',
    value: '(GMT+09:00) Moscow+06 - Yakutsk',
  },
  {
    label: 'Pacific/Palau',
    value: '(GMT+09:00) Palau',
  },
  {
    label: 'Australia/Adelaide',
    value: '(GMT+10:30) Central Time - Adelaide',
  },
  {
    label: 'Australia/Darwin',
    value: '(GMT+09:30) Central Time - Darwin',
  },
  {
    label: 'Antarctica/DumontDUrville',
    value: '(GMT+10:00) Dumont D\\"Urville',
  },
  {
    label: 'Asia/Magadan',
    value: '(GMT+10:00) Moscow+07 - Magadan',
  },
  {
    label: 'Asia/Vladivostok',
    value: '(GMT+10:00) Moscow+07 - Yuzhno-Sakhalinsk',
  },
  {
    label: 'Australia/Brisbane',
    value: '(GMT+10:00) Eastern Time - Brisbane',
  },
  {
    label: 'Australia/Hobart',
    value: '(GMT+11:00) Eastern Time - Hobart',
  },
  {
    label: 'Australia/Sydney',
    value: '(GMT+11:00) Eastern Time - Melbourne, Sydney',
  },
  {
    label: 'Pacific/Chuuk',
    value: '(GMT+10:00) Truk',
  },
  {
    label: 'Pacific/Guam',
    value: '(GMT+10:00) Guam',
  },
  {
    label: 'Pacific/Port_Moresby',
    value: '(GMT+10:00) Port Moresby',
  },
  {
    label: 'Pacific/Efate',
    value: '(GMT+11:00) Efate',
  },
  {
    label: 'Pacific/Guadalcanal',
    value: '(GMT+11:00) Guadalcanal',
  },
  {
    label: 'Pacific/Kosrae',
    value: '(GMT+11:00) Kosrae',
  },
  {
    label: 'Pacific/Norfolk',
    value: '(GMT+11:00) Norfolk',
  },
  {
    label: 'Pacific/Noumea',
    value: '(GMT+11:00) Noumea',
  },
  {
    label: 'Pacific/Pohnpei',
    value: '(GMT+11:00) Ponape',
  },
  {
    label: 'Asia/Kamchatka',
    value: '(GMT+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy',
  },
  {
    label: 'Pacific/Auckland',
    value: '(GMT+13:00) Auckland',
  },
  {
    label: 'Pacific/Fiji',
    value: '(GMT+13:00) Fiji',
  },
  {
    label: 'Pacific/Funafuti',
    value: '(GMT+12:00) Funafuti',
  },
  {
    label: 'Pacific/Kwajalein',
    value: '(GMT+12:00) Kwajalein',
  },
  {
    label: 'Pacific/Majuro',
    value: '(GMT+12:00) Majuro',
  },
  {
    label: 'Pacific/Nauru',
    value: '(GMT+12:00) Nauru',
  },
  {
    label: 'Pacific/Tarawa',
    value: '(GMT+12:00) Tarawa',
  },
  {
    label: 'Pacific/Wake',
    value: '(GMT+12:00) Wake',
  },
  {
    label: 'Pacific/Wallis',
    value: '(GMT+12:00) Wallis',
  },
  {
    label: 'Pacific/Apia',
    value: '(GMT+14:00) Apia',
  },
  {
    label: 'Pacific/Enderbury',
    value: '(GMT+13:00) Enderbury',
  },
  {
    label: 'Pacific/Fakaofo',
    value: '(GMT+13:00) Fakaofo',
  },
  {
    label: 'Pacific/Tongatapu',
    value: '(GMT+13:00) Tongatapu',
  },
  {
    label: 'Pacific/Kiritimati',
    value: '(GMT+14:00) Kiritimati',
  },
];
