import {useCallback, useMemo} from 'react';
import {useFirebaseFetch} from '@hooks/query-hook';
import {sumArray} from '@utils/array';
import {useStoreContext} from '@store/store-context';
export const useCheckinsTeamsMention = () => {
  const {
    authStore: {auth},
    checkinsStore: {submittedUsersCheckins, nonSubmittedUsersCheckins},
  } = useStoreContext();

  const {data: mentions} = useFirebaseFetch(
    `userMentions/${process.env.REACT_APP_DEV_ENV}/${auth.user.id}/checkins/`,
  );

  const computeMentions = useCallback(
    (data: any[]) => {
      if (mentions) {
        const unReadCommentArr = data
          ?.map((report: any) => {
            return mentions[report?.id]
              ? Object.values(mentions[report?.id]).map(
                  (value: any) => value?.unreadComment,
                )
              : [];
          })
          ?.flatMap((value: any) => value);

        return sumArray(unReadCommentArr || []);
      }
      return 0;
    },
    [mentions],
  );

  const getSubmittedActiveMentions = useMemo(() => {
    const getSubmittedData = submittedUsersCheckins?.data?.filter(
      (report?: any) => report?.isSubmitted,
    );

    return computeMentions(getSubmittedData);
  }, [submittedUsersCheckins?.data, computeMentions]);

  const getPendingReviews = useMemo(() => {
    return submittedUsersCheckins?.data?.filter(
      (report?: any) => report?.isSubmitted && !report.isReviewed,
    );
  }, [submittedUsersCheckins?.data]);

  const getNotSubmittedActiveMentions = useMemo(() => {
    const getSubmittedData = nonSubmittedUsersCheckins?.data?.filter(
      (report?: any) => !report?.isSubmitted,
    );

    return computeMentions(getSubmittedData);
  }, [nonSubmittedUsersCheckins?.data, computeMentions]);

  return {
    getSubmittedActiveMentions,
    getNotSubmittedActiveMentions,
    getPendingReviews,
  };
};
