import {Modal} from '@ui/molecules/modal';
import {ModalCard} from '@ui/layouts/modal-card';
import {BackAction} from '@ui/molecules/back-action';
import {CancelIcon} from '@ui/atoms/icons';
import {capitalize} from '@utils';
import {GroupType} from './group-type';
import {SecondaryBreadcrumb} from '@ui/molecules/breadcrumb';
import {Groups} from './groups';
import {NotificationCard} from '@ui/molecules/notification/notification';
import {observer} from 'mobx-react-lite';
import {useStoreContext} from '@store/store-context';

import {useState} from 'react';
import {VerticalSpacer} from '@ui/atoms/spacer';
import styled from 'styled-components';

const CancelWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 800px) {
    margin-top: 8%;
    margin-right: 9%;
  }
`;

export const ModalCardWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  div.child {
    width: 47%;
    @media (min-width: 800px) and (max-width: 1240px) {
      width: 55%;
    }
    @media (max-width: 800px) {
      min-width: 90%;
      margin: 10% 0px;
    }
  }
  @media (max-width: 800px) {
    justify-content: center;
  }
`;
export const DesktopWrapper = styled.div`
  display: none;
  @media (min-width: 800px) {
    display: block;
  }
`;
export const MobileWrapper = styled.div`
  display: none;
  @media (max-width: 800px) {
    display: block;
  }
`;
export const ManageGroups = observer(() => {
  const {
    groupStore: {showGroupDrawer, updateGroupDrawer},
    groupTypeStore: {groupType},
  } = useStoreContext();

  const [view, setView] = useState('');

  const getGroupTypeName = (id: string) => {
    return groupType.find((grpType) => grpType.id === id)?.name || '';
  };

  const GroupBreadCrumbsNav = [
    {
      href: '#',
      id: '1',
      onClick: () => {
        setView('');
      },
      title: 'Manage Groups',
    },
    {
      href: '',
      id: '2',
      isCurrent: true,
      onClick: function noRefCheck() {},
      title: capitalize(getGroupTypeName(view)),
    },
  ];

  if (!showGroupDrawer) {
    return null;
  }

  return (
    <>
      <Modal open={true} fullHeight onClose={() => null}>
        <MobileWrapper>
          <CancelWrapper>
            <BackAction
              icon={<CancelIcon />}
              title="Close"
              onClick={() => {
                updateGroupDrawer(false);
                window.history.replaceState(null, '', window.location.pathname);
              }}
              width="xs"
            />
          </CancelWrapper>
        </MobileWrapper>
        <ModalCardWrapper>
          <div className="child">
            <NotificationCard />

            <ModalCard title="" fullHeight onBoarding>
              <div>
                <DesktopWrapper>
                  <CancelWrapper>
                    <BackAction
                      icon={<CancelIcon />}
                      title="Close"
                      style={{
                        height: '42px',
                        display: 'flex',
                        alignItems: 'center',
                        overflow: 'hidden',
                        justifyContent: 'center',
                      }}
                      onClick={() => {
                        updateGroupDrawer(false);
                        window.history.replaceState(
                          null,
                          '',
                          window.location.pathname,
                        );
                      }}
                      width="xs"
                    />
                  </CancelWrapper>
                </DesktopWrapper>
                <VerticalSpacer size="32px" />
                {!view && <GroupType viewGroup={(e: string) => setView(e)} />}
                {view && (
                  <>
                    <SecondaryBreadcrumb navigation={GroupBreadCrumbsNav} />
                    <Groups
                      groupTypeId={view}
                      groupTypeName={getGroupTypeName(view)}
                    />
                  </>
                )}
              </div>
            </ModalCard>
          </div>
        </ModalCardWrapper>
      </Modal>
    </>
  );
});
