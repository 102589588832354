import {FlexRow} from '@ui/style/styles';
import {ArrowPointingUpIcon} from '@ui/atoms/icons/arrow-pointing-up';
import {CustomColorIcon} from '@ui/atoms/color-icons';
import {ArrowDownIcon} from '@ui/atoms/icons';
import styled from 'styled-components';

const PercentageChangeWrapper = styled.div<{color: string}>`
  margin-right: 2px;
  margin-top: 2px;
  svg {
    stroke: ${({color}) => color};
    width: 16px;
    height: 16px;
  }
`;

export const ArrowIcons = ({value}: {value: number}) => {
  if (value > 0) {
    return (
      <FlexRow>
        <PercentageChangeWrapper color="#47B881">
          <ArrowPointingUpIcon />
        </PercentageChangeWrapper>
      </FlexRow>
    );
  } else if (value < 0) {
    return (
      <FlexRow>
        <PercentageChangeWrapper color="#D52A2A">
          <ArrowDownIcon />
        </PercentageChangeWrapper>
      </FlexRow>
    );
  } else {
    return (
      <FlexRow>
        <CustomColorIcon
          width="6px"
          height="6px"
          margin={2}
          color={'#CECEDE'}
        />
      </FlexRow>
    );
  }
};
