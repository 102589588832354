import {makeAutoObservable, observable, action} from 'mobx';
import {authStore} from './auth-store';
import {set} from 'idb-keyval';

export class TaskStore {
  @observable taskData = {
    ccReviewNominatePeers: [],
    ccReviewPeerNominations: [],
    checkinFeedback: [],
    selfReviews: [],
    reviewPeers: [],
    comment: [],
    mentions: [],
    managerReviews: [],
    peerReviews: [],
    goals: [],
    checkin: {
      title: '',
      description: '',
    },
    payment: {
      title: '',
      description: '',
    },
  };
  @observable taskListCount = 0;

  constructor() {
    makeAutoObservable(this);
  }

  @action updateTasks = (data: any) => {
    this.taskData = data;
    if (authStore.auth !== null) {
      set(
        `${authStore.auth.user.workspace.id}/${authStore.auth.user.id}/taskList`,
        data,
      );
    }
  };

  @action updateCount = (data: number) => {
    this.taskListCount = data;
    if (authStore.auth !== null) {
      set(
        `${authStore.auth.user.workspace.id}/${authStore.auth.user.id}/taskListCount`,
        this.taskListCount,
      );
    }
  };
  @action updateMentionCount = (data: number) => {
    this.taskListCount = this.taskListCount + data;
    if (authStore.auth !== null) {
      set(
        `${authStore.auth.user.workspace.id}/${authStore.auth.user.id}/taskListCount`,
        this.taskListCount,
      );
    }
  };
}

export const taskStore = new TaskStore();
