import {getColorFromTheme} from '@ui/ui-utils';
import {Body1} from '@ui/atoms/typography';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {Microcopy} from '@ui/atoms/typography';
import {FlexRow, FlexRowEnd} from '@ui/style/styles';
import {ArrowDownIcon} from '../../../../../../../ui/atoms/icons';
import {ArrowPointingUpIcon} from '@ui/atoms/icons/arrow-pointing-up';
import {CustomColorIcon} from '@ui/atoms/color-icons';
import styled from 'styled-components';
const commaNumber = require('comma-number');

const AnalyticsCard = styled.div<{background: string; height?: string}>`
  width: 150px;
  height: ${({height}) => height || '120px'};
  border-width: 0px 1px 1px 0px;
  border-style: solid;
  border-color: ${getColorFromTheme('borderLight')};
  padding: 16px;

  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  background-color: ${({background}) => background};
`;
const PercentageChangeWrapper = styled.div<{color: string}>`
  margin-right: 2px;
  margin-top: 2px;
  svg {
    stroke: ${({color}) => color};
    width: 16px;
    height: 16px;
  }
`;
interface ICard {
  completed: boolean;
  height?: string;
  showPercentageInValue?: boolean;
  isKeyResultValue?: boolean;
  activity: {
    changeInValue: string;
    date: string;
    endDate: string;
    percentageChangeInValue: string | number;
    progress: string | number;
    startDate: string;
  };
}
export const handleDisplayValue = (value: number | string) => {
  if (Number(value) > 0) {
    return (
      <FlexRow>
        <PercentageChangeWrapper color="#47B881">
          <ArrowPointingUpIcon />
        </PercentageChangeWrapper>
        <Microcopy kind={'green300'}>{value}%</Microcopy>
      </FlexRow>
    );
  } else if (Number(value) < 0) {
    return (
      <FlexRow>
        <PercentageChangeWrapper color="#D52A2A">
          <ArrowDownIcon />
        </PercentageChangeWrapper>
        <Microcopy kind={'red400'}>{value}%</Microcopy>
      </FlexRow>
    );
  } else {
    return (
      <FlexRow>
        <CustomColorIcon
          width="6px"
          height="6px"
          margin={2}
          color={'#CECEDE'}
        />
        <Microcopy kind={'textMedium'}>{value}%</Microcopy>
      </FlexRow>
    );
  }
};

export const AnalyticsActivityCard = ({
  activity,
  completed,
  height,
  isKeyResultValue,
  showPercentageInValue,
}: ICard) => {
  const showPurpleBackground = (value: number | string): boolean => {
    if (Number(value) >= 0) {
      return true;
    } else if (Number(value) < 0) {
      return false;
    }
    return true;
  };
  const isValuePositive = (value: number | string): boolean => {
    if (Number(value) > 0) {
      return true;
    }
    return false;
  };

  const isValueValid = (value: number | string) => {
    if (value === '-') {
      return false;
    }
    return true;
  };

  if (!isValueValid(activity.progress)) {
    return <AnalyticsCard background="white" height={height} />;
  }
  return (
    <AnalyticsCard
      height={height}
      background={
        completed
          ? 'white'
          : showPurpleBackground(activity.percentageChangeInValue)
          ? '#E4E5FB'
          : '#F7E4E4'
      }>
      <Body1 weight="semibold" align="right">
        {typeof activity.progress === 'number' && !isKeyResultValue
          ? `${activity.progress}%`
          : activity.progress}
      </Body1>
      <VerticalSpacer size="8px" />
      <FlexRowEnd>
        <Microcopy
          kind="textMedium"
          align="center"
          style={{marginRight: '7px'}}>
          {isValuePositive(activity.changeInValue)
            ? `+${commaNumber(activity.changeInValue)}`
            : `${commaNumber(activity.changeInValue)}`}
        </Microcopy>
        {showPercentageInValue && (
          <FlexRow>
            {handleDisplayValue(activity.percentageChangeInValue)}{' '}
          </FlexRow>
        )}
      </FlexRowEnd>
    </AnalyticsCard>
  );
};
