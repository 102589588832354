import styled, {css} from 'styled-components';
import {getColorFromTheme} from '../../../ui-utils';
import {ifProp} from 'styled-tools';

export const DropdownWrapper = styled.div<{type?: string}>`
  position: absolute;
  display: block;
  width: 100%;
  top: 120%;
  height: 46px;
  border-radius: ${({type}) =>
    type === 'dropdownOnly' ? '7px 7px 7px 7px' : ' 0px 7px 7px 0px'};
  border-width: ${({type}) =>
    type === 'dropdownOnly' ? ' 1px 1px 1px 1px' : ' 1px 1px 1px 0px'};
  border-style: solid;
  border-color: ${getColorFromTheme('borderDark')};
  background-color: ${getColorFromTheme('white')};
  z-index: 2;
`;

export const DropWrapper = styled.div`
  position: relative;
`;

export const Wrapper = styled.div<{hasLabel?: boolean}>`
  position: relative;
  padding-top: ${({hasLabel}) => (hasLabel ? '26px' : '0')};
`;

export const ArrowIconWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    stroke: ${getColorFromTheme('textBody')};
    width: 16px;
    height: 16px;
  }
`;

export const AvatarWrapper = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const DropdownHandler = styled.button<{open: boolean; state?: string}>`
  outline: none;
  padding: 5px 14px;
  border-radius: 0px 7px 7px 0;
  color: ${getColorFromTheme('textDark')};
  font-weight: 500;
  width: 100%;
  display: block;
  height: 56px;
  background-color: ${getColorFromTheme('white')};
  transition: 200ms ease all;
  border-width: 1px 1px 1px 0px;
  border-style: solid;
  border-color: ${({state}) =>
    state === 'error'
      ? getColorFromTheme('red400')
      : getColorFromTheme('borderDark')};

  &:focus:not(:invalid) {
    outline: 0;
  }

  ${ifProp(
    'open',
    css`
      outline: 0;
    `,
  )};
`;

export const HandlerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Layout = styled.div<{type?: string}>`
  display: ${({type}) => (type === 'dropdownOnly' ? 'block' : 'grid')};
  grid-template-columns: minmax(90px, auto) minmax(auto, 89%);
  border-radius: ${({type}) =>
    type === 'phoneNumberDropdownInput' ? '10px' : '7px'};

  &:hover {
    box-shadow: ${({type}) =>
      type === 'dropdownOnly'
        ? 'none'
        : `0 0 0 2.5px ${getColorFromTheme('purple200')}`};
  }
`;
