import {CSSProperties, memo} from 'react';

export const ArrowHeadLeftIcon = memo(({style}: {style?: CSSProperties}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    style={style}
    height="16"
    fill="none"
    viewBox="0 0 16 16">
    <path strokeLinecap="round" strokeLinejoin="round" d="M10 12L6 8l4-4" />
  </svg>
));

ArrowHeadLeftIcon.displayName = 'ArrowHeadLeftIcon';
