import {makeAutoObservable, observable, action} from 'mobx';
import {GoalsController} from '../../api/goals-api/goals-controller';
import {GoalsRequestImpl} from '../../api/goals-api/goals-request';
import {AnalyticsTodayDate} from '@utils/date';
import {IGoalResult} from '../../hooks/interface';

type IKey = 'percent' | 'type' | 'numeric' | 'binary' | 'currency';

export class StoreYouGoals {
  @observable goals_: IGoalResult[] = [];
  @observable editedGoals: Set<IGoalResult> = new Set();
  @observable fetchedGoals: Map<string, any> = new Map();
  @observable currentValues: Map<string, any> = new Map();
  @observable allActiveGoals: any = [];
  @observable fetchedActiveGoalsById: Map<string, any> = new Map();
  @observable youGoalsFilter: string = 'active';
  @observable youGoalsFilterUpdate: boolean = false;
  @observable dateRange = {
    starts: AnalyticsTodayDate().AnalyticsDefaultStartDate,
    ends: AnalyticsTodayDate().AnalyticsDefaultEndDate,
  };
  @observable selectedUsers: string[] = [];

  @observable sortBy = 'desc';

  @observable youGoalsPaginator: any = {
    limit: 10,
    page: 1,
    totalPages: 1,
    totalResults: 0,
    pageNumberLimit: 1,
    maxPageNumberLimit: 2,
    minPageNumberLimit: 0,
  };

  private request;
  private controller;

  constructor() {
    makeAutoObservable(this);
    this.request = new GoalsRequestImpl();
    this.controller = new GoalsController(this.request);
  }

  @action
  setYouGoalsFilter = (filter: 'active' | 'upcoming' | 'archived' | '') => {
    this.youGoalsFilter = filter;
  };
  @action
  setSortBy = (value: string) => {
    this.sortBy = value;
  };

  @action
  setSelectedUsers = (value: string[]) => {
    this.selectedUsers = value;
  };

  @action
  setYouGoalsFilterUpdate = (state: boolean) => {
    this.youGoalsFilterUpdate = state;
  };
  @action
  setYouGoalsFilterLimit = (value: number) => {
    this.youGoalsPaginator.limit = value;
  };
  // Paginator start
  @action
  modifyYouGoalsPaginator = (field: string, value: number) => {
    this.youGoalsPaginator[field] = value;
  };

  @action setDateRange = (data: any) => {
    this.dateRange = data;
  };

  @action
  handleYouGoalsPaginatorPageClick = (event: any) => {
    this.modifyYouGoalsPaginator('page', Number(event));
  };

  @action
  handleYouGoalsPaginatorNextbtn = () => {
    this.modifyYouGoalsPaginator('page', this.youGoalsPaginator.page + 1);

    if (
      this.youGoalsPaginator.page + 1 >
      this.youGoalsPaginator.maxPageNumberLimit
    ) {
      this.modifyYouGoalsPaginator(
        'maxPageNumberLimit',
        this.youGoalsPaginator.maxPageNumberLimit +
          this.youGoalsPaginator.pageNumberLimit,
      );
      this.modifyYouGoalsPaginator(
        'minPageNumberLimit',
        this.youGoalsPaginator.minPageNumberLimit +
          this.youGoalsPaginator.pageNumberLimit,
      );
    }
  };

  @action
  handleYouGoalsPaginatorPrevbtn = () => {
    this.modifyYouGoalsPaginator('page', this.youGoalsPaginator.page - 1);
    if (
      (this.youGoalsPaginator.page - 1) %
        this.youGoalsPaginator.pageNumberLimit ===
      0
    ) {
      this.modifyYouGoalsPaginator(
        'maxPageNumberLimit',
        this.youGoalsPaginator.maxPageNumberLimit -
          this.youGoalsPaginator.pageNumberLimit,
      );
      this.modifyYouGoalsPaginator(
        'minPageNumberLimit',
        this.youGoalsPaginator.minPageNumberLimit -
          this.youGoalsPaginator.pageNumberLimit,
      );
    }
  };

  @action
  youGoalsPages = (totalResults: number, limit: number) => {
    const arr = [];
    for (
      let i = 1;
      i <=
      Math.ceil(
        this.youGoalsPaginator.totalResults / this.youGoalsPaginator.limit,
      );
      i++
    ) {
      arr.push(i);
    }
    return arr;
  };

  // Paginator end

  @action
  clearStoreYouGoals = () => {
    this.goals_ = [];
    this.editedGoals = new Set();
    this.fetchedGoals = new Map();
  };

  @action
  setFetchGoals = async (data: any) => {
    data.forEach((result: any) => {
      if (!this.fetchedGoals.has(result.id))
        this.fetchedGoals.set(result.id, result);
    });
  };

  @action
  replaceFetchedGoals = async (data: any) => {
    this.fetchedGoals = new Map();
    data.forEach((result: any) => {
      if (!this.fetchedGoals.has(result.id))
        this.fetchedGoals.set(result.id, result);
    });
  };

  @action
  setAllActiveGoals = (data: any) => {
    this.allActiveGoals = data;
  };

  @action
  setFetchActiveGoalsById = async (data: any) => {
    data.forEach((result: any) => {
      if (!this.fetchedActiveGoalsById.has(result.id))
        this.fetchedActiveGoalsById.set(result.id, result);
    });
  };
  @action updateKeyResultContext = (
    goalId: string,
    keyResultId: string,
    value: string,
  ) => {
    const updateGoal = this.fetchedGoals.get(goalId);
    if (updateGoal) {
      updateGoal.keyResults = updateGoal.keyResults.map((result: any) => {
        if (result.id === keyResultId) {
          result.contextValue = value;
          return result;
        }
        return result;
      });
    }
  };

  @action
  updateGoalValue = (goalId: string, id: string, key: IKey, value: any) => {
    // find the goal with goalID
    const updateGoal = this.fetchedGoals.get(goalId);

    // compare goalId and id if they are not same update key result
    if (updateGoal) {
      if (goalId !== id) {
        updateGoal.keyResults = updateGoal.keyResults.map((result: any) => {
          if (result.id === id) {
            result.currentValue = Number(value);

            this.currentValues.set(id, Number(value));

            return result;
          }
          return result;
        });
      } else {
        // update top level goal
        updateGoal.currentValue = Number(value);
      }
      // replace set value with updated value
      this.fetchedGoals.set(goalId, updateGoal);
    }
  };

  @action
  updateGoalFields = (goalId: string, field: string, value: any) => {
    const updateGoal = this.fetchedGoals.get(goalId);
    if (updateGoal) {
      updateGoal[field] = value;

      this.fetchedGoals.set(goalId, updateGoal);
    }
  };

  @action
  updateGoalContextValue = (goalId: string, value: any) => {
    // find the goal with goalID
    const updateGoal = this.fetchedGoals.get(goalId);

    if (updateGoal) {
      updateGoal.context = value;

      this.fetchedGoals.set(goalId, updateGoal);
    }
  };

  @action
  updatePerformance = (goalId: string, key: IKey, performance: any) => {
    const updateGoalPerformance = this.fetchedGoals.get(goalId);
    if (updateGoalPerformance) {
      updateGoalPerformance.performance = performance;
      this.fetchedGoals.set(goalId, updateGoalPerformance);
    }
  };

  @action
  updateGoalKeyResult = async (goalId: string) => {
    this.controller.updateGoal(this.fetchedGoals.get(goalId), goalId);
  };

  @action
  saveUpdate = async (goalId: string) => {
    const updateGoal = this.fetchedGoals.get(goalId);

    const filterUpdate = {
      ...updateGoal,
      keyResults: updateGoal.keyResults?.map((result: any) => {
        return {
          ...result,
          context: result?.contextValue || '',
          contextValue: undefined,
          id: result.id,
        };
      }),
    };

    this.fetchedGoals.set(goalId, {
      ...updateGoal,
      keyResults: updateGoal.keyResults?.map((result: any) => {
        return {
          ...result,
          contextValue: undefined,
        };
      }),
    });

    const response = await this.controller.updateGoal(filterUpdate, goalId);

    response &&
      this.fetchedGoals.set(goalId, {
        ...response,
        assignee: updateGoal.assignee,
        keyResults: response?.keyResults.map((kr: {id: string}) => {
          const prevKr = updateGoal.keyResults.find(
            (_kr: {id: string}) => _kr.id === kr.id,
          );
          return {
            ...kr,
            assignee: prevKr?.assignee,
          };
        }),
        goalType: updateGoal.goalType,
      });
    // return response;
  };

  @action
  saveUpdateV2 = async (goal: any, goalId: string) => {
    await this.controller.updateGoal(
      {
        context:
          goal?.contextValue !== goal?.context ? goal?.contextValue : undefined,
        assignee: goal.assignee,
        currentValue: goal.currentValue,
        name: goal.name,
        measurement: goal.measurement,
        startValue: goal.startValue,
        id: goal.id,
        targetValue: goal.targetValue,
      },
      goalId,
    );
  };

  @action
  getGoalById = (id: string) => {
    return this.goals_.filter((goal) => goal.id === id);
  };

  @action
  deleteGoalFromStore(goalId: string) {
    // Todo: remove goal with id from store
    this.fetchedGoals.delete(goalId);
  }

  @action
  endGoalInStore = (goalId: string, data: any) => {
    const fetchGoal = this.fetchedGoals.get(goalId);
    if (fetchGoal) {
      fetchGoal.status = data.completed === true ? 'completed' : 'in_completed';
      this.fetchedGoals.set(goalId, fetchGoal);
    }
  };

  @action
  archiveGoalInStore(goalId: string) {
    this.fetchedGoals.delete(goalId);
    this.modifyYouGoalsPaginator(
      'totalResults',
      this.youGoalsPaginator.totalResults - 1,
    );
  }

  @action
  restoreGoalInStore(goalId: string) {
    this.fetchedGoals.delete(goalId);
    this.modifyYouGoalsPaginator(
      'totalResults',
      this.youGoalsPaginator.totalResults - 1,
    );
  }
}

export const storeYouGoals = new StoreYouGoals();
