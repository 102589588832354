import Cleanup from './images/illustrations/1.png';
import Painting from './images/illustrations/2.png';
import Board from './images/illustrations/3.png';
import CuttingWood from './images/illustrations/4.png';
import Signup from './images/illustrations/5.png';
import GoldenBarrow from './images/illustrations/6.png';
import Time from './images/illustrations/9.png';
import ScatteredPapers from './images/illustrations/10.png';
import Pickup from './images/illustrations/14.png';
import createGoal from './images/illustrations/create-goal.png';
import CustomTimeline from './images/illustrations/custom-timeline.png';
import QuarterlyTimeline from './images/illustrations/quater-timeline.png';
import GoalOwnership from './images/illustrations/goal-ownership.png';

export interface IllustrationProps {
  src: string;
  extension: string;
  variants: {
    src: string;
    size: string;
  }[];
}

export const illustrationList = {
  createGoal: {
    src: createGoal,
    extension: 'png',

    variants: [{src: createGoal, size: '1x'}],
  },
  goal_ownership: {
    src: GoalOwnership,
    extension: 'png',

    variants: [{src: GoalOwnership, size: '1x'}],
  },
  time: {
    src: Time,
    extension: 'png',

    variants: [{src: Time, size: '1x'}],
  },
  customTimeline: {
    src: CustomTimeline,
    extension: 'png',

    variants: [{src: CustomTimeline, size: '1x'}],
  },
  quarterlyTimeline: {
    src: QuarterlyTimeline,
    extension: 'png',

    variants: [{src: QuarterlyTimeline, size: '1x'}],
  },
  painting: {
    src: Painting,
    extension: 'png',

    variants: [{src: Painting, size: '1x'}],
  },
  cleanup: {
    src: Cleanup,
    extension: 'png',

    variants: [{src: Cleanup, size: '1x'}],
  },
  signup: {
    src: Signup,
    extension: 'png',

    variants: [{src: Signup, size: '1x'}],
  },
  board: {
    src: Board,
    extension: 'png',

    variants: [{src: Board, size: '1x'}],
  },
  scatteredPapers: {
    src: ScatteredPapers,
    extension: 'png',
    variants: [{src: ScatteredPapers, size: '1x'}],
  },
  pickup: {
    src: Pickup,
    extension: 'png',
    variants: [{src: Pickup, size: '1x'}],
  },
  cuttingWood: {
    src: CuttingWood,
    extension: 'png',
    variants: [{src: CuttingWood, size: '1x'}],
  },
  goldenBarrow: {
    src: GoldenBarrow,
    extension: 'png',
    variants: [{src: GoldenBarrow, size: '1x'}],
  },
};
