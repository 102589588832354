import {memo} from 'react';

export const AlertBadgeWarningSVG = memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20">
    <path
      stroke="#E6821D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M8.575 3.217L1.516 15c-.145.252-.222.538-.223.829 0 .291.075.577.219.83.144.253.352.463.602.611.251.148.536.227.827.23h14.117c.291-.003.576-.082.827-.23.25-.148.458-.358.603-.611.144-.253.22-.539.218-.83 0-.291-.078-.577-.223-.829L11.425 3.217c-.149-.245-.358-.448-.608-.588-.25-.14-.53-.214-.817-.214-.287 0-.568.073-.818.214-.25.14-.459.343-.607.588v0zM10 7.5v3.333M10 14.166h.009"
    />
  </svg>
));

AlertBadgeWarningSVG.displayName = 'AlertBadgeWarningSVG';
