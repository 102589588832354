import {RefObject, useEffect, useRef} from 'react';

export const useMounted = (): RefObject<boolean> => {
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  return mounted;
};
