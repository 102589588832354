import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {useStoreContext} from '@store/store-context';
import {Button} from '@ui/atoms/button';
import {CancelIcon, ChevronDownIcon} from '@ui/atoms/icons';
import {Body2} from '@ui/atoms/typography';
import {DropdownCustom} from '@ui/molecules/dropdown-custom';
import {TextField} from '@ui/molecules/field/textfield';
import {useDebouncedCallback} from 'use-debounce';

import {MultiOptionsSelectField} from '@ui/molecules/select/multi-options';
import {FlexRowSpaceBetween} from '@ui/style/styles';
import {FC, useState} from 'react';

export const FeedbackAdditionalFilterOptions = [
  {
    value: 'sentiment',
    label: 'Sentiment count',
  },
  {
    value: 'values',
    label: 'Values',
  },
];

export const FeedbackSentimentOptions = [
  {
    value: 'commend',
    label: 'Commend',
  },
  {
    value: 'neutral',
    label: 'Neutral',
  },
  {
    value: 'critic',
    label: 'Critic',
  },
];

interface SentimentFilterProps {
  onChangeSentiment: (data: {sentiment: string; count: string}) => void;
}

export const SentimentFilter: FC<SentimentFilterProps> = ({
  onChangeSentiment,
}) => {
  const [values, setValues] = useState({
    sentiment: '',
    count: '',
  });

  const delayOnChange = useDebouncedCallback(
    (data) => onChangeSentiment(data),
    1000,
  );

  const handleChange = (field: keyof typeof values, value: string) => {
    const updatedValue = {...values, [field]: value};

    setValues(updatedValue);

    if (value[value.length - 1] !== ',') delayOnChange(updatedValue);
  };

  return (
    <FlexRowSpaceBetween>
      <DropdownCustom
        collapseOnClick={true}
        menu={(handleClose: () => void) => (
          <div>
            <div>
              {FeedbackSentimentOptions.map((option) => (
                <DropdownItem
                  onClick={() => {
                    handleClose();
                    handleChange('sentiment', option.value);
                  }}>
                  {option.label}
                </DropdownItem>
              ))}
            </div>
          </div>
        )}
        customComponent={(handleOpen: (event: any) => void, open: boolean) => (
          <Button
            onClick={handleOpen}
            width="sm"
            kind="secondary"
            style={{padding: '6px 12px', borderRadius: '6px'}}
            className="py-2 px-3 flex flex-row justify-between items-center capitalize">
            <Body2
              kind={values.sentiment ? 'textDark' : 'textBody'}
              weight={values.sentiment ? 'semibold' : 'regular'}>
              {values.sentiment || 'Select value'}
            </Body2>
            {values.sentiment ? (
              <div
                className="cursor-pointer "
                onClick={() => handleChange('sentiment', '')}>
                <CancelIcon style={{marginLeft: '14px', strokeWidth: 1.5}} />
              </div>
            ) : (
              <ChevronDownIcon
                style={{
                  marginLeft: 10,
                  stroke: '#1E1E2F',
                  transform: !open ? 'rotate(0deg)' : 'rotate(180deg)',
                }}
              />
            )}
          </Button>
        )}
        customDropdownWrapperStyles={{
          top: '130%',
          right: 0,
          width: '190px',
        }}
      />
      <TextField
        value={values.count}
        onChange={(event) => {
          const text = event.target.value.trim();

          handleChange('count', text);
        }}
        disabled={!values.sentiment}
        inputStyle={{height: '34px', width: '94px'}}
        margin
      />
    </FlexRowSpaceBetween>
  );
};

interface FeedbackValuesProps {
  selectedValues: string[];
  onChangeValues: (value: string[]) => void;
}
export const FeedbackValuesFilter: FC<FeedbackValuesProps> = ({
  selectedValues,
  onChangeValues,
}) => {
  const {
    authStore: {auth},
  } = useStoreContext();

  const companyValues = auth?.user?.workspace?.settings?.companyValues?.values;

  const formatCompanyValues =
    companyValues.length > 0
      ? companyValues?.map((value: string) => {
          return {
            label: value,
            value: value,
          };
        })
      : [];

  return (
    <>
      <MultiOptionsSelectField
        placeholder="Search value"
        name="groups"
        select="people"
        type="dropdownOnly"
        performance
        noOptionMessage={'No values  Found'}
        groupDisplayLength={3}
        margin
        showSelections={false}
        showCancelIcon={false}
        onChange={(data: any) => {
          onChangeValues(data.map((option: {value: string}) => option.value));
        }}
        reportingStyling
        options={formatCompanyValues}
      />
      {selectedValues.length > 0 && (
        <div className="flex flex-row flex-wrap gap-x-4 ">
          {selectedValues.map((value) => (
            <div
              key={value}
              className="py-1 px-2 mt-3 bg-[#F6F6F8] flex flex-row items-center justify-between rounded-[6px] ">
              <Body2>{value}</Body2>
              <div
                onClick={() => {
                  onChangeValues(
                    selectedValues.filter((_value) => value !== _value),
                  );
                }}>
                <CancelIcon
                  style={{
                    width: '12px',
                    height: '12px',
                    strokeWidth: 1.5,
                    marginLeft: '15px',
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};
