import {IMembersFormatFilter} from '@hooks';
import {useMemo} from 'react';
import {usePeriodHook} from '@hooks/period-hook';
import {IOptions} from '../components/values-themes-filter';
import {AnalyticsTodayDate} from '@utils/date';
import {useStoreContext} from '@store/store-context';
import {FeedbackController} from '../feedback-controller';
import {FeedbackRequestImpl} from '../feedback-requests';
import {useState} from 'react';

export const useFeedbackWrittenHook = () => {
  const {
    authStore: {auth},
  } = useStoreContext();

  const [userFilter, setUserFilter] = useState({
    filterBy: {
      label: 'Teams',
      value: 'team',
    },
    selectedTeam: {
      value: auth.user.id,
      label: 'My Team',
    },
    members: [] as Array<IMembersFormatFilter>,
  });

  const [sortBy, setSortBy] = useState('asc');

  const {isPeriodEnabled, currentMonthRange} = usePeriodHook();

  const [valueThemeFilter, setValueThemeFilter] = useState<IOptions>({
    filterBy: '',
    theme: '',
    value: '',
  });

  const getPeriod = useMemo(
    () =>
      isPeriodEnabled
        ? {
            starts: currentMonthRange().starts,
            ends: currentMonthRange().ends,
            period: currentMonthRange().period,
          }
        : {
            starts: AnalyticsTodayDate().AnalyticsDefaultStartDate,
            ends: AnalyticsTodayDate().AnalyticsDefaultEndDate,
          },
    [isPeriodEnabled, currentMonthRange],
  );

  const [dateRange, setDateRange] = useState(getPeriod);

  const request = useMemo(() => new FeedbackRequestImpl(), []);
  const controller = useMemo(() => new FeedbackController(request), [request]);

  const filters = useMemo(() => {
    return {
      startDate: dateRange.starts,
      endDate: dateRange.ends,
      values: valueThemeFilter.value,
      impression: valueThemeFilter.theme,
      sortBy,
      reviewer: auth.user.id || undefined,
    };
  }, [
    dateRange.starts,
    dateRange.ends,
    valueThemeFilter.value,
    sortBy,
    valueThemeFilter.theme,
    auth.user.id,
  ]);
  return {
    userFilter,
    setUserFilter,
    controller,
    filters,
    sortBy,
    setSortBy,
    dateRange,
    setDateRange,
    valueThemeFilter,
    setValueThemeFilter,
  };
};
