import {memo} from 'react';

export const RemoveSVG = memo(() => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="12" fill="#D52A2A" />
    <path
      d="M8.7002 8.7002L15.2999 15.2999"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.7002 15.2998L15.2999 8.70014"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

RemoveSVG.displayName = 'RemoveSVG';
