import {useCallback} from 'react';
import {CompanyCheckinsController} from '@pages/dashboard/company-legacy/company-components/company-checkins/company-checkins.controller';
import {CompanyCheckinsRequestImpl} from '@pages/dashboard/company-legacy/company-components/company-checkins/company-checkins.request';

export function useCheckinsQuestions() {
  const getCheckinsQuestions = useCallback(async () => {
    const request = new CompanyCheckinsRequestImpl();
    const controller = new CompanyCheckinsController(request);

    const response = await controller.getCheckinsQuestions();

    if (response) {
      return response.flatMap((question) =>
        question.questions.map((question) => ({
          value: question.id,
          label: question.text,
        })),
      );
    }
    return [];
  }, []);

  return {
    getCheckinsQuestions,
  };
}
