import {BeamLogoWithName} from '../../atoms/icons/beam-logo-name';
import {ifProp} from 'styled-tools';
import {NotificationCard} from '../notification/notification';
import {down} from 'styled-breakpoints';
import styled, {css} from 'styled-components';
import {ReactNode} from 'react';

const Wrapper = styled.div<{sm?: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0px 0px 14px 0px;
  border-bottom: 1px solid #ededf2;

  ${ifProp(
    'sm',
    css`
      ${down('sm')} {
     border: 0;
      }}
      `,
  )}
`;
export default function Navbar({
  sm,
  children,
}: {
  sm?: boolean;
  children?: ReactNode;
}) {
  return (
    <>
      {children ? (
        children
      ) : (
        <Wrapper sm={sm}>
          <BeamLogoWithName />{' '}
        </Wrapper>
      )}

      <NotificationCard />
    </>
  );
}
