import {Body2} from '../../../../../../../../../ui/atoms/typography';
import {VerticalSpacer} from '../../../../../../../../../ui/atoms/spacer';
import {TextField} from '../../../../../../../../../ui/molecules/field/textfield';

import styled from 'styled-components';

export const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  p {
    margin-right: 10px;
  }
`;

type CycleNameProps = {
  cycleName: string;
  disabled?: boolean;
  handleChange: (value: string) => void;
};
export default function CycleName({
  cycleName,
  disabled,
  handleChange,
}: CycleNameProps) {
  return (
    <div>
      <NameWrapper>
        <Body2 weight="bold">Cycle name</Body2>
      </NameWrapper>
      <VerticalSpacer size="8px" />

      <TextField
        placeholder="Provide a descriptive name"
        inputStyle={{borderRadius: '10px'}}
        disabled={disabled}
        // state={cycleError.name !== '' ? 'error' : 'performance'}
        value={cycleName}
        // helper={cycleError.name !== '' ? cycleError.name : ''}
        onChange={(e) => {
          handleChange(e.target.value);
        }}
      />
    </div>
  );
}
