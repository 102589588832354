import {useMemo, useState, useCallback} from 'react';
import {usePeriodHook} from '@hooks/period-hook';
import {MeetingRequestImpl} from '../meeting.request';
import {MeetingController} from '../meeting-controller';
import {IMeetingResponse} from '@hooks';

import {AnalyticsTodayDate} from '@utils/date';

interface IMeetingAPIResponse {
  participant: string;
  user: string;
  time: string;
}

export const usePastMeetingHook = () => {
  const {currentMonthRange, isPeriodEnabled} = usePeriodHook();

  const getPeriod = useMemo(
    () =>
      isPeriodEnabled
        ? {
            starts: currentMonthRange().starts,
            ends: currentMonthRange().ends,
            period: currentMonthRange().period,
          }
        : {
            starts: AnalyticsTodayDate().AnalyticsDefaultStartDate,
            ends: AnalyticsTodayDate().AnalyticsDefaultEndDate,
          },
    [isPeriodEnabled, currentMonthRange],
  );

  const [dateRange, setDateRange] = useState(getPeriod);
  const [filterBy, setFilterBy] = useState('');
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  const getMeetings = useCallback(
    async (filters: {
      startDate: string;
      endDate: string;
      user?: string;
      status?: string;
      participant?: string;
    }): Promise<IMeetingResponse[]> => {
      const request = new MeetingRequestImpl();
      const controller = new MeetingController(request);

      const response = await controller.getMeetings(filters);

      if (response) {
        const formatResponse = response.results.map(
          (results: IMeetingAPIResponse) => {
            return {
              ...results,
              time: !results.time?.toLowerCase().includes('invalid')
                ? results.time
                : '',
            };
          },
        );

        return formatResponse;
      }
      return [];
    },
    [],
  );

  return {
    dateRange,
    setDateRange,
    getMeetings,
    setFilterBy,
    filterBy,
    selectedUsers,
    setSelectedUsers,
  };
};
