import {useQuery, QueryKey, UseQueryOptions} from 'react-query';
import {onValue, ref, query, off, Database} from 'firebase/database';
import {FIREBASE_BASE_URL, database} from '@utils/firebase-request';
import {useCallback, useEffect, useState, useMemo} from 'react';
import {authStore} from '@store/stores/auth-store';

// Generic fetch hook
export const useFetchHook = <TData = unknown, TError = unknown>(
  queryKey: QueryKey,
  queryFn: () => Promise<TData>,
  options?: UseQueryOptions<TData, TError>,
) => {
  return useQuery<TData, TError>(queryKey, queryFn, options);
};

// One-time fetch hook
export const useOneTimeFetch = <TData = unknown, TError = unknown>(
  queryKey: QueryKey,
  queryFn: () => Promise<TData>,
  options?: UseQueryOptions<TData, TError>,
) => {
  return useFetchHook<TData, TError>(queryKey, queryFn, {
    ...options,
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};

export const useFirebaseFetch = (path: string, filter?: any) => {
  const [data, setData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  // Create database reference
  const databaseRef = useMemo(
    () =>
      ref(
        database,
        `${FIREBASE_BASE_URL}${authStore.auth.user.workspace.id}/${path}`,
      ),
    [path],
  );

  // Apply filter if provided
  const filteredQuery = useMemo(
    () => (filter?.equalTo ? query(databaseRef, filter) : databaseRef),
    [databaseRef, filter],
  );

  const fetchData = useCallback(() => {
    setIsLoading(true);
    setError(null);

    // Create the listener
    const handleDataUpdate = (snapshot: any) => {
      try {
        const value = snapshot.val();
        // Only update state if the value is different
        setData((prevData: any) => {
          if (JSON.stringify(prevData) !== JSON.stringify(value)) {
            return value;
          }
          return prevData;
        });
      } catch (err) {
        setError(
          err instanceof Error ? err : new Error('Unknown error occurred'),
        );
      } finally {
        setIsLoading(false);
      }
    };

    const handleError = (error: Error) => {
      console.error('Firebase fetch error:', error);
      setError(error);
      setIsLoading(false);
    };

    // Attach the listener
    onValue(filteredQuery, handleDataUpdate, handleError);

    // Return cleanup function
    return () => {
      // Remove all listeners for this reference
      off(filteredQuery);
    };
  }, [filteredQuery]);

  useEffect(() => {
    const cleanup = fetchData();
    return () => {
      cleanup();
    };
  }, [fetchData]);

  const refetch = useCallback(() => {
    const cleanup = fetchData();
    return cleanup;
  }, [fetchData]);

  return {data, isLoading, error, refetch};
};
