import {VerticalSpacer} from '@ui/atoms/spacer';
import {useState} from 'react';
import dayjs from 'dayjs';
import {Body2} from '@ui/atoms/typography';
import {DateRangeInput} from '@ui/molecules/date-range-input';
import {OptionSelectField} from '@ui/molecules/select/option';
import {usePeriodHook} from '@hooks/period-hook';
import {FlexRow} from '@ui/style/styles';

type ReviewPeriodProps = {
  handleChange: (value: {starts: string; ends: string}) => void;
  reviewPeriod: {starts: string; ends: string; period?: string};
};
export const ReviewPeriod = ({
  handleChange,
  reviewPeriod,
}: ReviewPeriodProps) => {
  const {periods} = usePeriodHook();
  const [date, setDate] = useState(reviewPeriod);

  const initCustomDate =
    reviewPeriod.ends && reviewPeriod.starts && !reviewPeriod.period;

  const [showDateRange, setShowDateRange] = useState(initCustomDate);
  const [selectedValue, setSelectedValue] = useState(
    initCustomDate ? 'custom' : '',
  );

  const updateDate = (date: {
    starts: string;
    ends: string;
    period?: string;
  }) => {
    setDate(date);
    handleChange(date);
  };

  const handlePeriodChange = (value: string) => {
    setSelectedValue(value);

    if (value === 'custom') {
      setShowDateRange(true);
      return;
    }

    setShowDateRange(false);

    const findPeriod = periods.find((period) => period.value === value);

    findPeriod &&
      updateDate({
        starts: findPeriod.starts,
        ends: findPeriod.ends,
        period: findPeriod.period,
      });
  };

  return (
    <div>
      <FlexRow>
        <Body2 weight="bold">What period is under review? </Body2>
      </FlexRow>
      <OptionSelectField
        options={periods}
        placeholder={'Select period'}
        name="questionType"
        inputStyle={{borderRadius: '10px'}}
        borderRadius="10px"
        value={selectedValue === 'custom' ? selectedValue : date.starts}
        customOptionName="Choose custom timeline"
        maxHeight={400}
        onChange={(data: {value: string; disabled?: boolean}) => {
          handlePeriodChange(data.value);
        }}
        fieldNotFoundPlaceHolder={(searchTerm?: string) =>
          `Oops! this period does not exist`
        }
        optionsSelectType="period"
      />{' '}
      {showDateRange && (
        <>
          <DateRangeInput
            name="date"
            value={{starts: date.starts, ends: date.ends}}
            helper={
              new Date(date.ends) < new Date(date.starts)
                ? 'Start date must be before end date'
                : ''
            }
            state={
              new Date(date.ends) < new Date(date.starts) ? 'error' : 'default'
            }
            admin={true}
            placeholder="Select Date"
            setValue={(e) => {
              if (e.starts && e.ends) {
                updateDate({
                  starts: dayjs(e.starts).format('MM/DD/YYYY'),
                  ends: dayjs(e.ends).format('MM/DD/YYYY'),
                });
              }
            }}
          />
          <VerticalSpacer size="16px" />
        </>
      )}
    </div>
  );
};
