import {activateNotification} from '../../../../../ui/molecules/notification/activate-notification';
import {parseErrorContent} from '../../../../../utils/error-handling';
import {AnalyticsRequest} from './admin-analytics.request';

export class AnalyticsController {
  constructor(private readonly request: AnalyticsRequest) {}

  async getAnalyticsGoals(goalPage?: number) {
    try {
      const response = await this.request.getAnalyticsGoals(goalPage);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }

  async fetchSpecifiedGoals(
    filters: any,
    view?: string,
    notPaginated?: boolean,
  ) {
    try {
      const response = await this.request.fetchSpecifiedGoals(
        filters,
        view,
        notPaginated,
      );
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e.response.data?.message,
        kind: 'error',
      });
      return false;
    }
  }
  async fetchSpecifiedKpis(filters: any, notPaginated?: boolean) {
    try {
      const response = await this.request.fetchSpecifiedKpis(
        filters,
        notPaginated,
      );
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e.response.data?.message,
        kind: 'error',
      });
      return false;
    }
  }

  async fetchReportKpis(date: any) {
    try {
      const response = await this.request.fetchReportsKpis(date);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e.response.data?.message,
        kind: 'error',
      });
      return false;
    }
  }
  async fetchReportKPIComparison(
    id: string,
    preference: 'sum' | 'average' | 'latest',
    firstDate: {startDate: string; endDate: string},
    secondDate?: {startDate: string; endDate: string},
  ) {
    try {
      const response = await this.request.fetchReportKPIComparison(
        id,
        preference,
        firstDate,
        secondDate,
      );
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e.response.data?.message,
        kind: 'error',
      });
      return false;
    }
  }

  async getCompetenceReport(filter: any) {
    try {
      const response = await this.request.getCompetenceReport(filter);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e.response.data?.message,
        kind: 'error',
      });
      return false;
    }
  }

  async fetchReportGoals(date: any) {
    try {
      if (date?.starts !== undefined) {
        const response = await this.request.fetchReportsGoals(date);
        return response;
      }
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e.response.data?.message,
        kind: 'error',
      });
      return false;
    }
  }
  async fetchParentsGoals(id: any) {
    try {
      const response = await this.request.fetchParentGoals(id);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e.response.data?.message,
        kind: 'error',
      });
      return false;
    }
  }
  async fetchSpecifiedGoalsForExport(filters: any, view?: string) {
    try {
      const response = await this.request.fetchSpecifiedGoalsForExport(
        filters,
        view,
      );
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e.response.data?.message,
        kind: 'error',
      });
      return false;
    }
  }

  async fetchSpecifiedPeopleForStatus(filters: any) {
    try {
      const response = await this.request.fetchSpecifiedPeopleForStatus(
        filters,
      );
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e.response && e.response.data && parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }

  async fetchSpecifiedPeopleForOwnership(filters: any) {
    try {
      const response = await this.request.fetchSpecifiedPeopleForOwnership(
        filters,
      );
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }
  async fetchSpecifiedGroups(filters: any) {
    try {
      const response = await this.request.fetchSpecifiedGroups(filters);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }
  async fetchMembersAndManagers() {
    try {
      const response = await this.request.fetchMembersAndManagers();
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }

  async fetchGroupType() {
    try {
      const response = await this.request.fetchGroupType();
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }
  async fetchGroups() {
    try {
      const response = await this.request.fetchGroups();
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }
  async fetchDepartmentGroups(groupTypeId: string) {
    try {
      const response = await this.request.fetchDepartmentGroups(groupTypeId);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),

        kind: 'error',
      });
      return false;
    }
  }
  async fetchGoalAlignmentTree(id: string) {
    try {
      const response = await this.request.fetchGoalAlignmentTree(id);
      return response;
    } catch (e: any) {
      return false;
    }
  }
  async fetchReportGoalById(
    id: string,
    filter?: {startDate: string; endDate: string},
  ) {
    try {
      const response = await this.request.fetchReportGoalById(id, filter);
      return response;
    } catch (e: any) {
      return false;
    }
  }
  async fetchReportGoalActivitiesById(id: string, limit: number) {
    try {
      const response = await this.request.fetchReportGoalActivitiesById(
        id,
        limit,
      );
      return response;
    } catch (e: any) {
      return false;
    }
  }
  async fetchReportPulse(filters: any) {
    try {
      const response = await this.request.fetchReportPulse(filters);
      return response;
    } catch (e: any) {
      return false;
    }
  }
  async fetchReportObjectives(filters: any) {
    try {
      const response = await this.request.fetchReportObjectives(filters);
      return response;
    } catch (e: any) {
      return false;
    }
  }
  async fetchReportCompetence(filters: any) {
    try {
      const response = await this.request.fetchReportCompetence(filters);
      return response;
    } catch (e: any) {
      return false;
    }
  }
  async fetchReportCheckinsQuestions(filters: any) {
    try {
      const response = await this.request.fetchReportCheckinsQuestions(filters);
      return response;
    } catch (e: any) {
      return false;
    }
  }
}
