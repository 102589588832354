import {AxiosResponse} from 'axios';
import {
  WorkspaceInviteCsvData,
  WorkspaceInviteFormData,
  WorkspaceInviteResponse,
} from './workspace-invite-interface';
import {BaseRequest} from '../../../../../configs/request';
import {authStore} from '../../../../../store/stores/auth-store';

export interface WorkspaceInviteRequest extends BaseRequest {
  WorkspaceInvite(
    data: WorkspaceInviteFormData,
    accessToken: string,
  ): Promise<WorkspaceInviteResponse>;
  WorkspaceInviteCsv(
    data: WorkspaceInviteCsvData,
    accessToken: string,
  ): Promise<WorkspaceInviteResponse>;
}

export class WorkspaceInviteRequestImpl
  extends BaseRequest
  implements WorkspaceInviteRequest {
  private inviteEndPoint: string = '/workspaces/invite-team';
  private inviteEndPointCsv: string = '/workspaces/invite-file';

  async WorkspaceInvite<WorkspaceInviteResponse>(
    data: WorkspaceInviteFormData,
    accessToken: string,
  ) {
    const trimmedData = {
      ...data,
      emails: data.emails.filter((email: string) => email !== ''),
    };
    {
      const {data: responseData} = await this.request.post<
        WorkspaceInviteFormData,
        AxiosResponse<WorkspaceInviteResponse>
      >(this.inviteEndPoint, trimmedData, {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      });
      return responseData;
    }
  }

  async WorkspaceInviteCsv<WorkspaceInviteResponse>(
    data: WorkspaceInviteCsvData,
    accessToken: string,
  ) {
    const {data: responseData} = await this.request.post<
      WorkspaceInviteCsvData,
      AxiosResponse<WorkspaceInviteResponse>
    >(this.inviteEndPointCsv, data, {
      headers: {
        Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
      },
    });
    return responseData;
  }
}
