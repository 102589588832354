import {action} from 'mobx';
import {activateNotification} from '../../ui/molecules/notification/activate-notification';
import {SignInFormData} from './sign-in-interface';
import {AdminSignInRequest} from './sign-in-request';

export class AdminSignInController {
  constructor(private readonly request: AdminSignInRequest) {}

  @action
  async adminSignIn(data: SignInFormData) {
    try {
      const response = await this.request.adminSignIn(data);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e?.response?.data?.message,
        kind: 'error',
      });
      return false;
    }
  }
}
