import {memo} from 'react';

export const EditIcon = memo(() => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.08252 1.25143C7.19195 1.14199 7.32187 1.05518 7.46486 0.995959C7.60784 0.936733 7.76109 0.90625 7.91585 0.90625C8.07062 0.90625 8.22387 0.936733 8.36685 0.995959C8.50983 1.05518 8.63975 1.14199 8.74919 1.25143C8.85862 1.36086 8.94543 1.49078 9.00466 1.63376C9.06388 1.77675 9.09436 1.93 9.09436 2.08476C9.09436 2.23953 9.06388 2.39277 9.00466 2.53576C8.94543 2.67874 8.85862 2.80866 8.74919 2.91809L3.12419 8.54309L0.83252 9.16809L1.45752 6.87643L7.08252 1.25143Z"
      stroke="#5F5F8C"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

EditIcon.displayName = 'EditIcon';
