import React, {
  useMemo,
  forwardRef,
  Ref,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {motion} from 'framer-motion';
import ReactSelect, {
  components,
  IndicatorProps,
  SingleValueProps,
  OptionProps,
  PlaceholderProps,
  ActionMeta,
  ValueType,
} from 'react-select';
import {Label} from '../../../atoms/label';
import {FieldWrapper} from '../../../atoms/field-wrapper';
import {SearchBox} from '../../search-box';
import {Helper} from '../../../atoms/helper';
import {ArrowHeadDownIcon} from '../../../atoms/icons/arrow-head-down';
import {Body1} from '../../../atoms/typography/body1';
import {FlexRowCenter} from '@ui/style/styles';
import {Body2, CTA} from '../../../atoms/typography';
import {AvatarWrapper} from '../multi-options/styled';
import styled from 'styled-components';
import {getStyles, Wrapper, IndicatorWrapper, SharedProps} from '../shared';
import {GreenWhitePlusIcon} from '@ui/atoms/icons/greenWhitePlus';
import {HorizontalSpacer} from '@ui/atoms/spacer';

const CustomBody1 = styled(Body1)`
  width: max-content;
`;

const {
  DropdownIndicator: SelectDropdownIndicator,
  Placeholder: SelectPlaceholder,
  MenuList: MenuWrapper,
  Option: SelectOption,
  ValueContainer: CustomValueContainer,
  SingleValue: SelectSingleValue,
} = components;

export interface TOption {
  value: string;
  label: string | ReactNode;
}

export interface SelectFieldProps extends SharedProps<TOption> {}

const SingleValue = (props: SingleValueProps<TOption>) => {
  return (
    <SelectSingleValue {...props}>
      <CustomBody1 kind="textDark">{props.children as string}</CustomBody1>
    </SelectSingleValue>
  );
};

const MenuList = (props: any) => {
  const {
    onInputChange,
    inputValue,
    excludeSearchBox,
    onMenuInputFocus,
    onRemoveFocus,
  } = props.selectProps;

  return (
    <>
      {!excludeSearchBox && (
        <div style={{padding: '8px'}}>
          <SearchBox
            placeholder="Search"
            margin={true}
            id="menu-search"
            value={inputValue}
            style={{height: '39px', borderRadius: '8px'}}
            onMouseDown={(e) => {
              e.stopPropagation();
            }}
            onTouchEnd={(e) => {
              e.stopPropagation();
            }}
            onFocus={onMenuInputFocus()}
            onChange={(e) =>
              onInputChange(e.currentTarget.value, {
                action: 'input-change',
              })
            }
          />
        </div>
      )}

      <MenuWrapper {...props}>
        <div onClick={() => onRemoveFocus()}>{props.children}</div>
      </MenuWrapper>
    </>
  );
};

const Placeholder = (props: PlaceholderProps<TOption, false>) => {
  return (
    <SelectPlaceholder {...props}>
      <CustomBody1 kind={props.isDisabled ? 'textMuted' : 'textBody'}>
        {props.children}
      </CustomBody1>
    </SelectPlaceholder>
  );
};
const Option = (props: OptionProps<TOption, false>) => {
  const val: any = props.selectProps.value;

  const addCustomOption = (props as any).value === 'custom-option';

  const {onChange, ...rest} = props.selectProps;

  const selectProps = addCustomOption
    ? {...props, selectProps: {...rest}}
    : {...props};

  return (
    <>
      <SelectOption {...selectProps}>
        {addCustomOption ? (
          <div
            className="flex items-center "
            onClick={() => {
              props.selectProps?.onClickCustomOption();

              props.selectProps?.handleClose &&
                props.selectProps?.handleClose();
            }}>
            <GreenWhitePlusIcon /> <HorizontalSpacer size="16px" />{' '}
            <CTA kind="textDark" style={{fontWeight: 600}}>
              {props.selectProps.customOptionName ||
                'Create your custom question'}
            </CTA>
          </div>
        ) : (
          <AvatarWrapper>
            <Body2
              kind={val?.label === props.label ? 'textDark' : 'textBody'}
              style={
                val?.label === props.label
                  ? {fontWeight: 500}
                  : {fontWeight: 400}
              }>
              {(props.label as unknown) as string}
            </Body2>
          </AvatarWrapper>
        )}
      </SelectOption>
    </>
  );
};
const ValueContainer = ({children, selectProps, ...props}: any) => {
  const commonProps = {
    cx: props.cx,
    clearValue: props.clearValue,
    getStyles: props.getStyles,
    getValue: props.getValue,
    hasValue: props.hasValue,
    isMulti: props.isMulti,
    isRtl: props.isRtl,
    options: props.options,
    selectOption: props.selectOption,
    innerProps: props.innerProps,
    setValue: props.setValue,
    selectProps,
    theme: props.theme,
  };

  return (
    <CustomValueContainer {...props} selectProps={selectProps}>
      {React.Children.map(children, (child) => {
        return child ? (
          child
        ) : props.hasValue ? (
          <SingleValue
            {...commonProps}
            data={props.getValue()}
            isDisabled={selectProps.isDisabled}>
            {selectProps.getOptionLabel(props.getValue()[0])}
          </SingleValue>
        ) : (
          <Placeholder
            {...commonProps}
            key="placeholder"
            isFocused={selectProps.isFocused}
            isDisabled={selectProps.isDisabled}>
            {selectProps.placeholder}
          </Placeholder>
        );
      })}
    </CustomValueContainer>
  );
};
const DropdownIndicator = (props: IndicatorProps<TOption, false>) => {
  return (
    <div style={{display: props.hasValue ? 'none' : ''}}>
      <SelectDropdownIndicator {...props}>
        <IndicatorWrapper>
          <motion.span
            initial={{rotate: 0}}
            animate={{
              rotate: props.selectProps.menuIsOpen ? 180 : 0,
              marginTop: props.selectProps.menuIsOpen ? '-4px' : '1px',
            }}
            transition={{
              duration: 0.3,
            }}
            key="select-toggled">
            <ArrowHeadDownIcon />
          </motion.span>
        </IndicatorWrapper>
      </SelectDropdownIndicator>
    </div>
  );
};

const CustomSelectField = forwardRef(
  (
    {
      label,
      placeholder,
      state,
      helper,
      options,
      onChange,
      disable,
      reportingStatus,
      borderRadius,
      clearValue,
      excludeSearchBox,
      defaultValue,
      margin,
      NoOptionsMessage,
      ...rest
    }: SelectFieldProps,
    ref: Ref<ReactSelect<TOption>>,
  ) => {
    const containerRef: any = useRef(null);
    const styles = useMemo(() => getStyles<TOption, false>(), []);
    const [isFocused, setIsFocused] = useState(false);
    const [inputValue, setInputValue] = useState('');

    const onDomClick = (e: any) => {
      if (containerRef.current) {
        let menu = containerRef.current.querySelector('.select__menu');

        if (
          !containerRef.current.contains(e.target) ||
          (menu && !menu.contains(e.target))
        ) {
          setIsFocused(false);
        }
      }
    };

    useEffect(() => {
      document.addEventListener('mousedown', onDomClick);

      return () => {
        document.removeEventListener('mousedown', onDomClick);
      };
    }, []);

    return (
      <FieldWrapper margin={margin}>
        <Wrapper>
          <Label aria-label="label">{label}</Label>
          <div ref={containerRef}>
            <ReactSelect
              {...rest}
              isClearable
              placeholder={placeholder}
              onChange={onChange}
              isSearchable={false}
              inputValue={inputValue}
              isDisabled={disable}
              excludeSearchBox={excludeSearchBox}
              clearValue={clearValue}
              hideSelectedOptions
              onMenuInputFocus={() => setIsFocused(true)}
              onRemoveFocus={() => setIsFocused(false)}
              onInputChange={(val) => setInputValue(val)}
              ref={ref}
              onBlur={(event) => {
                const relatedTarget = event.nativeEvent.relatedTarget;
                if (relatedTarget) {
                  //@ts-ignore
                  if (relatedTarget.id !== 'menu-search') setIsFocused(false);
                  return;
                }
                setIsFocused(false);
              }}
              isFoc
              {...{
                menuIsOpen: isFocused || undefined,
                isFocused: isFocused || undefined,
              }}
              components={{
                DropdownIndicator,
                IndicatorSeparator: null,
                ValueContainer,
                Placeholder,
                Option,
                MenuList,
                NoOptionsMessage,
              }}
              defaultValue={defaultValue}
              options={options}
              styles={styles}
              theme={(theme) => ({
                ...theme,
                borderRadius: borderRadius ? borderRadius : 7,
                spacing: {
                  baseUnit: reportingStatus ? 4 : 6,
                  controlHeight: reportingStatus ? 44 : 52,
                  menuGutter: 8,
                },
              })}
            />
          </div>
          <Helper aria-label="helper" state={state} children={helper} />
        </Wrapper>
      </FieldWrapper>
    );
  },
);

export const SelectField = forwardRef(
  (
    {
      label,
      state,
      helper,
      placeholder,
      options = [],
      reportingStatus,
      onChange,
      value,
      defaultValue,
      removeOption,
      excludeSearchBox,
      borderRadius,
      removeOptionType,
      useLabel,
      clearValue,
      handleClearValue,
      disabled,

      NoOptionsMessage,
      ...rest
    }: SelectFieldProps,
    ref: Ref<ReactSelect<TOption>>,
  ) => {
    const getValue = useMemo(() => {
      return options.find(
        // @ts-ignore
        (option) => option.value === defaultValue || option.value === value,
      );
    }, [options, value, defaultValue]);

    const [innerValue, setInnerValue] = useState<ValueType<TOption, false>>(
      // @ts-ignore
      () => getValue,
    );

    useMemo(() => {
      if (clearValue) {
        setInnerValue(null as any);
        handleClearValue(false);
      }
    }, [clearValue, handleClearValue]);

    useMemo(() => {
      if (defaultValue) {
        if (getValue) {
          setInnerValue(getValue);
        }
      }
    }, [defaultValue, getValue]);

    const onSelectChange = useCallback(
      (value: ValueType<TOption, false>, action: ActionMeta<TOption> | any) => {
        if (value?.value === 'custom-option') {
          setInnerValue(innerValue ? innerValue : null);

          return;
        }

        if (onChange) {
          onChange(value, action);
        }
        setInnerValue(value);
      },
      [innerValue, onChange],
    );

    return (
      <CustomSelectField
        {...rest}
        inputValue={value || defaultValue}
        onChange={onSelectChange}
        value={innerValue}
        defaultValue={getValue}
        disable={disabled}
        clearValue={clearValue}
        excludeSearchBox={excludeSearchBox}
        helper={helper}
        state={state}
        borderRadius={borderRadius}
        placeholder={placeholder}
        NoOptionsMessage={
          NoOptionsMessage
            ? NoOptionsMessage
            : () => <FlexRowCenter>No option found</FlexRowCenter>
        }
        ref={ref}
        reportingStatus={reportingStatus}
        options={options}
      />
    );
  },
);
