import {MeasurementType} from '@hooks';
import {parseCurrencyValue} from '@constants';

import {Tooltip} from '@ui/molecules/tooltip';
import {Body2} from '../typography';
import {CSSProperties} from 'react';
const commaNumber = require('comma-number');

export const RenderKeyResultValue = ({
  measurement,
  currencySymbol,
  targetValue,
  style,
  customParseCurrencyValue,
  currentValue,
  renderFullValue,
}: {
  measurement: string;
  currencySymbol: string;
  targetValue: number;
  style?: CSSProperties;
  customParseCurrencyValue?: (value: string) => void;
  currentValue: number;
  renderFullValue?: boolean;
}) => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}>
        <span
          style={{
            color: '#5f5f8c',
            textOverflow: 'ellipsis',
            marginRight: 4,
            fontSize: '14px',
          }}>
          {(function () {
            const progressText = `${
              measurement.toUpperCase() === MeasurementType.CURRENCY &&
              currencySymbol
                ? (customParseCurrencyValue &&
                    customParseCurrencyValue(currencySymbol)) ||
                  parseCurrencyValue(currencySymbol)
                : measurement.toUpperCase() === MeasurementType.CURRENCY &&
                  !currencySymbol
                ? parseCurrencyValue('')
                : ''
            }${commaNumber(
              currentValue && measurement !== 'BINARY'
                ? currentValue
                : currentValue === 1 && measurement === 'BINARY'
                ? 'complete'
                : !currentValue && measurement === 'BINARY'
                ? 'Incomplete'
                : currentValue === 0 && measurement === 'BINARY'
                ? 'Incomplete'
                : 0,
            )}${
              measurement.toUpperCase() === MeasurementType.PERCENTAGE
                ? '%'
                : ''
            } ${
              !currentValue && measurement === 'BINARY'
                ? ''
                : currentValue === 1 && measurement === 'BINARY'
                ? ''
                : 'of'
            }${' '}${
              measurement.toUpperCase() === MeasurementType.CURRENCY &&
              currencySymbol
                ? parseCurrencyValue(currencySymbol)
                : measurement.toUpperCase() === MeasurementType.CURRENCY &&
                  !currencySymbol
                ? parseCurrencyValue('')
                : ''
            }${commaNumber(
              targetValue === 1 && measurement === 'BINARY' ? '' : targetValue,
            )}${
              measurement.toUpperCase() === MeasurementType.PERCENTAGE
                ? '%'
                : ''
            }`;

            return (
              <Body2 style={style} kind="textBody">
                <>
                  <span key="open-paren">{'('}</span>
                  <Tooltip
                    key="tooltip"
                    text={progressText}
                    tooltipBody={progressText}
                    maxLength={renderFullValue ? undefined : 15}
                  />
                  <span key="close-paren" style={{marginLeft: '1px'}}>
                    {progressText.length > 15 && !renderFullValue
                      ? ' ...)'
                      : ')'}
                  </span>
                </>
              </Body2>
            );
          })()}
        </span>
      </div>
    </>
  );
};
