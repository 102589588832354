import {cloudinary} from '@assets/images/cloudinary';
import {Button} from '@ui/atoms/button';
import {EmptyPlaceholder} from '@ui/atoms/empty-placeholder';
import {FlexRow} from '@ui/style/styles';
import {useNavigate} from 'react-router';

const {emptyGoals} = cloudinary;

export const EmptyDashboardState = () => {
  const navigate = useNavigate();

  const emptyPlaceholder = {
    image: {
      alt: 'alt text',
      shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
      src: emptyGoals,
      style: {height: '45%', width: '45%'},
    },
    imports: true,
    size: 'empty',
    style: {margin: '50px auto'},
    action: (
      <Button
        width="sm"
        onClick={() => {
          navigate('/reporting/insights');
        }}>
        Go to saved insights
      </Button>
    ),
    subtitle:
      'Select from pre-saved insights or customise custom insights, and effortlessly add them into your dashboard.',
    title: 'Add insights to dashboard',
  };

  return (
    <div className="relative">
      <div className="py-4 px-6 border border-borderLight shadow-sm rounded-[10px]">
        <FlexRow className="gap-4">
          {['', '', ''].map((_, index) => (
            <div
              className="w-full border shadow-[0_3px_24px_0_rgba(208,208,221,0.16)] border-borderLight bg-backgroundLight rounded-[10px] h-[220px]"
              key={index}
            />
          ))}
        </FlexRow>
      </div>
      <FlexRow className="gap-4  mt-6">
        {['', ''].map((_, index) => (
          <div
            className="w-full shadow-[0_3px_24px_0_rgba(208,208,221,0.16)] border border-borderLight bg-backgroundLight rounded-[10px] h-[300px]"
            key={index}
          />
        ))}
      </FlexRow>

      <FlexRow className="gap-4 mt-6">
        {['', ''].map((_, index) => (
          <div
            className="w-full shadow-[0_3px_24px_0_rgba(208,208,221,0.16)]  border border-borderLight bg-backgroundLight rounded-[10px] h-[300px]"
            key={index}
          />
        ))}
      </FlexRow>
      <div className="absolute top-[25%] bg-white left-[15%] border border-borderLight shadow-sm rounded-[10px] px-[60px] min-w-[65%]">
        <EmptyPlaceholder {...emptyPlaceholder} />
      </div>
    </div>
  );
};
