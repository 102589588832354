import {ComponentPropsWithoutRef, forwardRef, ReactElement, Ref} from 'react';
import styled, {css} from 'styled-components';
import {switchProp} from 'styled-tools';
import {InputState} from '../../../interface';
import {getColorFromTheme} from '../../../ui-utils';
import {Helper} from '../../../atoms/helper';

export interface TInputFieldProps extends ComponentPropsWithoutRef<'input'> {
  label?: string;
  state?: InputState;
  helper?: string | ReactElement;
}

interface TInputProps {
  state?: InputState;
}

const Input = styled.input<TInputProps>`
  padding: 5px 0;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: ${getColorFromTheme('textDark')};
  width: 100%;
  display: block;
  border-width: 0px;
  border-bottom: 1px solid;
  background: none;
  line-height: unset;
  border-color: ${getColorFromTheme('borderDark')};

  &::placeholder {
    color: ${getColorFromTheme('textMuted')};
  }

  &:disabled {
    cursor: not-allowed;
    background: none;
  }

  &:focus:not(:invalid) {
    outline: 0;
    border-color: ${getColorFromTheme('purple300')};
  }

  &:focus:invalid {
    outline: 0;
    border-color: ${getColorFromTheme('red400')};
    color: ${getColorFromTheme('red400')};
  }

  &:invalid:not(:focus):not(:placeholder-shown) {
    border-color: ${getColorFromTheme('red400')};
    color: ${getColorFromTheme('red400')};
  }

  ${switchProp('state', {
    error: css`
      color: ${getColorFromTheme('red400')} !important;
      border-color: ${getColorFromTheme('red400')} !important;
    `,
  })}
`;

const Wrapper = styled.div<{hasLabel?: boolean}>`
  position: relative;
  margin-bottom: 10px;
`;

export const Inputfield = forwardRef(
  (
    {label, placeholder, state, helper, ...rest}: TInputFieldProps,
    ref: Ref<HTMLInputElement>,
  ) => (
    <Wrapper hasLabel={!!label}>
      <Input
        aria-label="input"
        placeholder={placeholder ?? label}
        ref={ref}
        state={state}
        {...rest}
      />
      <Helper aria-label="helper" state={state} children={helper} />
    </Wrapper>
  ),
);
