import {down} from 'styled-breakpoints';
import styled from 'styled-components';
import {Colors} from '../../../../../../../ui/interface';
import {getColorFromTheme} from '../../../../../../../ui/ui-utils';

export const EditCheckinsOuterWrapper = styled.form`
  border-top: 1px solid ${getColorFromTheme('borderLight')};
`;

export const BreadcrumbWrapper = styled.div`
  border-bottom: 1px solid #ededf2;
  width: 84vw;
  margin-bottom: 24px;

  @media (max-width: 500px) {
    width: 90vw;
    padding-bottom: 10px;
    padding-left: 0px;
  }
`;
export const EditCheckinsInnerWrapper = styled.div`
  width: 90%;
  div.headlineDiv {
    padding-bottom: 24px;
  }
  padding: 32px;
  padding-top: 8px;
  ${down('lg')} {
    padding: 32px;
    padding-top: 8px;
  }

  ${down('md')} {
    padding: 16px;
    padding-top: 4px;
  }
  ${down('sm')} {
    padding: 8px;
    padding-top: 4px;
  }
`;
export const SectionComponentWrapper = styled.div`
  padding: 24px;
  @media (max-width: 500px) {
    padding: 12px;
  }
  div.checkinsParentDivs {
    display: grid;
    grid-gap: 40px;
    align-items: center;
    grid-template-columns: 16.8% 77.9%;
    ${down('md')} {
      grid-template-columns: 20.8% 72.9%;
    }
    ${down('sm')} {
      grid-template-columns: 28.8% 67.9%;
    }
    ${down('xs')} {
      grid-template-columns: 38% 60%;
      grid-gap: 15px;
    }
    padding-bottom: 24px;
    &.custom {
    }
    .checkins {
      padding: 14px 16px;
      background: ${getColorFromTheme('backgroundDark')};
      border-radius: 10px;
      white-space: nowrap;
    }
    .duration {
      margin-bottom: -24px;
    }
  }
  .visibilitySettingsTitle {
    display: flex;
    align-items: center;
  }
`;

export const SectionCardNoContent = styled.div<{disabled?: boolean}>`
  border: 1px solid ${getColorFromTheme('borderLight')};
  border-radius: 10px;
  padding: 24px;
  opacity: ${({disabled}) => (disabled ? 0.6 : 1)};
  display: flex;
  justify-content: space-between;
  .allow {
    display: flex;
    align-items: center;
  }
`;

export const AddCustom = styled.div`
  display: flex;
  align-items: center;
  stroke: ${getColorFromTheme('purple300')};
  cursor: pointer;
`;
export const IconWrapper = styled.span<{
  colorString: Colors;
}>`
  svg {
    stroke: ${getColorFromTheme('purple300')};
  }
`;
