import {ContentBox} from '../../../admin-workspace-styled';
import {SwitchToggle} from '@ui/molecules/toggle-switch/toggle-switch';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {FlexRowEnd} from '@ui/style/styles';
import {useState} from 'react';
import {BackAction} from '@ui/molecules/back-action';
import {CancelIcon} from '@ui/atoms/icons';
import {ModalCard} from '@ui/layouts/modal-card';
import {Headline2} from '@ui/atoms/typography';
import {Button} from '@ui/atoms/button';
import {useStoreContext} from '@store/store-context';
import {Modal} from '@ui/molecules/modal';
import {Headline4, Body2, Body1} from '@ui/atoms/typography';
type Props = {
  handleAlignmentSelection: (value: boolean) => Promise<any>;
};

const GoalAlignmentSettings = (props: Props) => {
  const [showModal, setShowModal] = useState<'enable' | 'disable' | ''>('');
  const {
    authStore: {auth},
  } = useStoreContext();

  const [isAlignmentEnabled, activateAlignment] = useState(
    auth?.user?.workspace?.settings?.goalAlignment,
  );

  return (
    <div>
      <ContentBox style={{marginTop: '16px'}}>
        <div className="flex" onClick={() => null}>
          <div className="column">
            <Headline4 kind="textDark">
              Enable goal alignment & key result cascading
            </Headline4>
            <VerticalSpacer size="8px" />
            <Body2 kind="textBody">
              Turn on goal alignment and key result cascading for your
              workspace.
            </Body2>
          </div>
          <FlexRowEnd>
            <SwitchToggle
              checked={isAlignmentEnabled}
              onChange={(e) => {
                const updateModal = () =>
                  setShowModal(isAlignmentEnabled ? 'disable' : 'enable');
                updateModal();
                // handleAction('kpi', updateKpiModal);
              }}
            />
          </FlexRowEnd>
        </div>
      </ContentBox>
      <Modal open={!!showModal} onClose={() => {}}>
        <div style={{maxWidth: '560px', margin: 'auto', marginTop: '10%'}}>
          <BackAction
            width="md"
            icon={<CancelIcon />}
            onClick={() => setShowModal('')}
            title="Close"
          />
          <VerticalSpacer size="16px" />
          <ModalCard align="center" title="">
            <div>
              <Headline2 align="center">
                {showModal === 'enable' ? 'Enable' : 'Disable'} goal alignment &
                key result cascading?
              </Headline2>

              <VerticalSpacer size="16px" />

              <Body1 kind="textBody" align="center">
                {showModal === 'enable'
                  ? `Goal alignment and key result cascading will be added to your create under “Goals”. `
                  : showModal === 'disable' &&
                    `This will be remove all goal alignment and cascaded key results added and also the functionality to your create under “Goals”. `}
              </Body1>
              <VerticalSpacer size="12px" />
              <Body1 kind="textBody" align="center">
                Click{' '}
                <span style={{fontWeight: 700, color: 'black'}}>“confirm”</span>{' '}
                to continue.
              </Body1>
              <VerticalSpacer size="24px" />

              <Button
                onClick={() => {
                  props.handleAlignmentSelection(
                    showModal === 'enable' ? true : false,
                  );
                  setShowModal('');
                  activateAlignment(isAlignmentEnabled ? false : true);
                }}
                width="full">
                Confirm
              </Button>
              <VerticalSpacer size="64px" />
            </div>
          </ModalCard>
        </div>
      </Modal>
    </div>
  );
};

export default GoalAlignmentSettings;
