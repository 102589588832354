import {useCallback, useEffect} from 'react';
import {AnalyticsController} from './admin-analytics.controller';
import {AnalyticsRequestImpl} from './admin-analytics.request';
import {authStore} from '../../../../../store/stores/auth-store';
import {useStoreContext} from '../../../../../store/store-context';
import {capitalize} from '../../../../../utils/capitalize';
import {groupRequestImpl} from '../../../admin/admin-components/admin-people/admin-group/admin-group-request';
import {groupController} from '../../../admin/admin-components/admin-people/admin-group/admin-group-controller';
import {groupTypeRequestImpl} from '../../../admin/admin-components/admin-people/admin-group-type/admin-group-type-request';
import {groupTypeController} from '../../../admin/admin-components/admin-people/admin-group-type/admin-group-type-controller';

export function AdminAnalyticsHook() {
  const {
    analyticsStore: {
      goalPaginator,
      kpiPaginator,
      setGoalsData,
      setKpisData,
      setGoalPaginator,
      setKpiPaginator,
      setLoading,
      selectedTags,
      sortBy,
      searchName,
      setStatusPeoplePages,
      setMembers,
      setPeopleDataForStatus,
      setManagers,
      statusPeoplePaginator,
      setFilterGroupType,
      setFilterGroupTypeId,
      setPeoplePaginatorForStatus,
      setFilterGroup,
      setDepartmentsData,
      okrStatus,
      setGroups,
      filterGroupTypeId,
      viewAnalyticsRange,
      setGoalPages,
      setKpiPages,
      filterCompletion,
      filterState,
      filterStatus,
      goalsDate,
      breakdownBy,
      searchable,
      filterTarget,
    },
    groupStore: {setAllGroups, groups},
    authStore: {auth},
    storeDashboardRoutes: {
      GoalViewTabs,
      statusTabs,
      companyReportingTabs,
      analyticsDashboardTabs,
    },
  } = useStoreContext();

  // Initialize goals
  const fetchSpecifiedGoals = useCallback(
    async (filters: any, view?: string) => {
      setLoading('goals', true);
      const request = new AnalyticsRequestImpl();
      const controller = new AnalyticsController(request);

      const specifiedGoalsResponse = await controller.fetchSpecifiedGoals(
        filters,
        view,
      );

      const groupsIncluded = await Promise.all(
        specifiedGoalsResponse.goals.map(async (goal: any) => {
          if (goal.goalType === 'group') {
            const group = goal.group.map((grp: string) => {
              return groups.find((_group) => _group.id === grp);
            });

            return {
              ...goal,
              group,
            };
          } else
            return {
              ...goal,
            };
        }),
      );
      if (GoalViewTabs.tree || GoalViewTabs.nested) {
        const treeArrangedGoalsResponse = await Promise.all(
          groupsIncluded.map(async (goal: any) => {
            const response = await controller.fetchGoalAlignmentTree(goal.id);
            return {
              ...goal,
              ...response,
            };
          }),
        );
        setGoalsData({
          ...specifiedGoalsResponse,
          goals: treeArrangedGoalsResponse,
        });
      } else {
        setGoalsData({
          ...specifiedGoalsResponse,
          goals: groupsIncluded,
        });
      }

      setGoalPaginator('limit', specifiedGoalsResponse.limit);
      setGoalPaginator('totalPages', specifiedGoalsResponse.totalPages);
      setGoalPaginator('totalResults', specifiedGoalsResponse.totalResults);
      setGoalPages({
        limit: specifiedGoalsResponse.limit,
        page: filters.goalPage,
        totalPages: specifiedGoalsResponse.totalPages,
        totalResults: specifiedGoalsResponse.totalResults,
        pageNumberLimit: 2,
        maxPageNumberLimit: 2,
        minPageNumberLimit: 0,
      });
      setLoading('goals', false);
    },
    [
      GoalViewTabs.nested,
      GoalViewTabs.tree,
      groups,
      setGoalPages,
      setGoalPaginator,
      setGoalsData,
      setLoading,
    ],
  );

  const initGroups = useCallback(async () => {
    const grpTypeRequest = new groupTypeRequestImpl();
    const grpTypeController = new groupTypeController(grpTypeRequest);
    const grpRequest = new groupRequestImpl();
    const grpController = new groupController(grpRequest);
    const grpTypeResponse: any = await grpTypeController.newfetchGroupType();
    const grpResponses: any = Promise.all(
      grpTypeResponse.map(async (grpType: any) => {
        const grpResponse: any = await grpController.newfetchGroup(grpType.id);
        grpResponse && setAllGroups(grpResponse.groups, grpType.name);
        return grpResponse.groups;
      }),
    );

    return grpResponses;
  }, [setAllGroups]);
  useEffect(() => {
    initGroups();
  }, [initGroups]);
  // Page number reolve

  useEffect(() => {
    const view = GoalViewTabs.tree
      ? 'tree'
      : GoalViewTabs.nested
      ? 'stacked'
      : undefined;
    if (!companyReportingTabs.overview) {
      fetchSpecifiedGoals(
        {
          goalPage: goalPaginator.page,
          limit: goalPaginator.limit,
          state: filterState.goals,
          status: filterStatus.goals,
          startDate: goalsDate.starts,
          endDate: goalsDate.ends,
          newReport: true,
          filterBy: viewAnalyticsRange,
          goalIds: searchable.goals,
          name: searchName,
          goalType: searchable.goalType,
          threshold: filterCompletion.goals,
          sortBy: `status:${sortBy},createdAt:${sortBy}`,
          member: searchable.members,
          groupBy: breakdownBy,
          isKpi: statusTabs.Kpi ? true : undefined,
          tag: selectedTags,
          manager: searchable.managers
            ? searchable.managers
            : authStore.auth?.user?.role !== 'admin' &&
              authStore?.auth?.user?.isReviewer === true
            ? authStore?.auth?.user?.id
            : undefined,
          department: searchable.department,
          departmentMembers: searchable.departmentMembers,
          deactivatedMembers: searchable.deactivatedMembers,
        },
        view,
      );
    }
  }, [
    fetchSpecifiedGoals,
    goalPaginator.page,
    goalPaginator.limit,
    auth?.user.role,
    statusTabs.Kpi,
    searchable.goals,
    selectedTags,
    breakdownBy,
    companyReportingTabs.overview,
    auth?.user.isReviewer,
    auth?.user?.id,
    searchName,
    sortBy,
    searchable.members,
    searchable.department,
    filterState.goals,
    filterStatus.goals,
    viewAnalyticsRange,
    filterCompletion.goals,
    goalsDate.starts,
    goalsDate.ends,
    searchable.goalType,
    searchable.managers,
    GoalViewTabs.tree,
    GoalViewTabs.nested,
    searchable.departmentMembers,
    searchable.deactivatedMembers,
  ]);

  // Initialize KPIs
  const fetchSpecifiedKPIs = useCallback(
    async (filters) => {
      setLoading('kpis', true);
      const request = new AnalyticsRequestImpl();
      const controller = new AnalyticsController(request);
      const grpRequest = new groupRequestImpl();
      const grpController = new groupController(grpRequest);
      const specifiedKpisResponse = await controller.fetchSpecifiedKpis(
        filters,
      );

      const groupsIncluded = await Promise.all(
        specifiedKpisResponse?.results?.map(async (kpi: any) => {
          if (kpi.kpiType === 'group') {
            const response = await grpController.newfetchSpecGroup(
              kpi.group,
              'groupType',
            );
            return {
              ...kpi,
              groupType: response.groupType,
              groupName: response.name,
            };
          } else
            return {
              ...kpi,
            };
        }),
      );

      setKpisData({
        ...specifiedKpisResponse,
        kpis: specifiedKpisResponse?.results ? groupsIncluded : [],
      });

      setKpiPaginator('limit', specifiedKpisResponse.limit);
      setKpiPaginator('totalPages', specifiedKpisResponse.totalPages);
      setKpiPaginator('totalResults', specifiedKpisResponse.totalResults);
      setKpiPages({
        limit: specifiedKpisResponse.limit,
        page: filters.goalPage,
        totalPages: specifiedKpisResponse.totalPages,
        totalResults: specifiedKpisResponse.totalResults,
        pageNumberLimit: 2,
        maxPageNumberLimit: 2,
        minPageNumberLimit: 0,
      });
      setLoading('kpis', false);
    },
    [setKpiPages, setKpiPaginator, setKpisData, setLoading],
  );
  useEffect(() => {
    if (!companyReportingTabs.overview) {
      fetchSpecifiedKPIs({
        kpiPage: kpiPaginator.page,
        kpiPageLimit: kpiPaginator.limit,
        state: filterState.kpis,
        status: filterStatus.kpis,
        startDate: goalsDate.starts,
        endDate: goalsDate.ends,
        member: searchable.members,
        threshold: filterCompletion.keyresults,
        name: searchName,

        newReport: true,
        filterBy: viewAnalyticsRange,
        sortBy: `status:${sortBy},createdAt:${sortBy}`,
        goalIds: searchable.goals,
        manager: searchable.managers
          ? searchable.managers
          : authStore.auth?.user?.role !== 'admin'
          ? authStore?.auth?.user?.id
          : undefined,
        department: searchable.department,
        departmentMembers: searchable.departmentMembers,
        deactivatedMembers: searchable.deactivatedMembers,
        target: filterTarget.kpis,
      });
    }
  }, [
    fetchSpecifiedKPIs,
    kpiPaginator.limit,
    filterState.kpis,
    companyReportingTabs.overview,
    filterStatus.kpis,
    filterTarget.kpis,
    searchable.goals,
    sortBy,
    filterCompletion.keyresults,
    viewAnalyticsRange,
    analyticsDashboardTabs.keyresults,
    kpiPaginator.page,
    goalsDate.starts,
    goalsDate.ends,
    searchable.deactivatedMembers,
    searchable.department,
    searchable.departmentMembers,
    searchable.managers,
    searchable.members,
    searchName,
  ]);

  // Initialize groups goal reporting
  const fetchSpecifiedGroups = useCallback(
    async (filters: any) => {
      setLoading('group', true);
      const request = new AnalyticsRequestImpl();
      const controller = new AnalyticsController(request);
      const specifiedGroupsResponse = await controller.fetchSpecifiedGroups(
        filters,
      );

      setGroups(specifiedGroupsResponse);
      setLoading('group', false);
    },
    [setGroups, setLoading],
  );
  useEffect(() => {
    fetchSpecifiedGroups({
      state: filterState.group,
      status: filterStatus.group,
      member: searchable.members,
      manager: searchable.managers,
      startDate: goalsDate.starts,
      endDate: goalsDate.ends,
      department: searchable.department,
      deactivatedMembers: searchable.deactivatedMembers,
      withGoals: okrStatus.withGoals,
      withoutGoals: okrStatus.withoutGoals,
      withKeyResults: okrStatus.withKeyResults,
      withoutKeyResults: okrStatus.withoutKeyResults,
    });
  }, [
    fetchSpecifiedGroups,
    filterState.group,
    filterStatus.group,
    goalsDate.ends,
    goalsDate.starts,
    okrStatus.withGoals,
    okrStatus.withKeyResults,
    okrStatus.withoutGoals,
    okrStatus.withoutKeyResults,
    searchable.deactivatedMembers,
    searchable.department,
    searchable.managers,
    searchable.members,
  ]);

  // Initialize members and managers
  const fetchMembersAndManagers = useCallback(async () => {
    const request = new AnalyticsRequestImpl();
    const controller = new AnalyticsController(request);
    const membersAndManagersResponse = await controller.fetchMembersAndManagers();
    membersAndManagersResponse &&
      membersAndManagersResponse.length > 0 &&
      setMembers(
        membersAndManagersResponse
          .filter((person: any) => person.status.includes('on'))
          // eslint-disable-next-line array-callback-return
          .map((member: any) => {
            if (member) {
              return {
                label: {
                  avatar: {
                    name:
                      member && member.firstName
                        ? member.firstName + ' ' + member.lastName
                        : member.email + ' (pending invitation)',
                    src:
                      member.avatar && member.avatar.url
                        ? member.avatar.url
                        : '',
                    id: member.id,
                  },
                  reviewer: member.reviewer,
                  id: 'members',
                  name:
                    member && member.firstName
                      ? member.firstName + ' ' + member.lastName
                      : member.email + ' (pending invitation)',
                },
                value: member.id,
              };
            }
          }),
      );
    membersAndManagersResponse &&
      membersAndManagersResponse.length > 0 &&
      setManagers(
        membersAndManagersResponse
          .filter((person: any) => person.isReviewer === true)
          // eslint-disable-next-line array-callback-return
          .map((member: any) => {
            if (member) {
              return {
                label: {
                  avatar: {
                    name:
                      member && member.firstName
                        ? member.firstName + ' ' + member.lastName
                        : member.email + ' (pending invitation)',
                    src:
                      member.avatar && member.avatar.url
                        ? member.avatar.url
                        : '',
                    id: member.id,
                  },
                  id: 'managers',
                  name:
                    member && member.firstName
                      ? member.firstName + ' ' + member.lastName
                      : member.email + ' (pending invitation)',
                },
                value: member.id,
              };
            }
          }),
      );
  }, [setManagers, setMembers]);
  useEffect(() => {
    fetchMembersAndManagers();
  }, [fetchMembersAndManagers]);

  // Initialize group type
  const fetchGroupType = useCallback(async () => {
    const request = new AnalyticsRequestImpl();
    const controller = new AnalyticsController(request);
    const groupTypeResponse = await controller.fetchGroupType();
    setFilterGroupTypeId(
      groupTypeResponse
        .filter((groupType: any) => groupType.isDefault === true)
        .map((groupType: any) => groupType.id),
    );
    setFilterGroupType(
      groupTypeResponse.map((groupType: any) => {
        if (groupType) {
          return {label: groupType.name, value: groupType.id};
        }
        return null;
      }),
    );
  }, [setFilterGroupType, setFilterGroupTypeId]);
  useEffect(() => {
    fetchGroupType();
  }, [fetchGroupType]);

  // Initialize groups
  const fetchGroups = useCallback(async () => {
    const request = new AnalyticsRequestImpl();
    const controller = new AnalyticsController(request);
    const groupsResponse = await controller.fetchGroups();
    setFilterGroup(
      groupsResponse &&
        // eslint-disable-next-line array-callback-return
        groupsResponse.results.map((group: any) => {
          if (group) {
            return {
              label:
                group.name === 'General'
                  ? `General (${group.groupType?.name})`
                  : group.name,
              value: group.id,
            };
          }
        }),
    );
  }, [setFilterGroup]);
  useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  // Initialize people for status
  const fetchSpecifiedPeopleForStatus = useCallback(
    async (filters: any) => {
      setLoading('statusPeople', true);
      const request = new AnalyticsRequestImpl();
      const controller = new AnalyticsController(request);
      const specifiedPeopleForStatusResponse = await controller.fetchSpecifiedPeopleForStatus(
        filters,
      );
      setPeopleDataForStatus(specifiedPeopleForStatusResponse.people);
      setPeoplePaginatorForStatus(
        'limit',
        specifiedPeopleForStatusResponse.limit,
      );
      setPeoplePaginatorForStatus(
        'totalPages',
        specifiedPeopleForStatusResponse.totalPages,
      );
      setPeoplePaginatorForStatus(
        'totalResults',
        specifiedPeopleForStatusResponse.totalResults,
      );
      setStatusPeoplePages({
        limit: specifiedPeopleForStatusResponse.limit,
        page: filters.peoplePage,
        totalPages: specifiedPeopleForStatusResponse.totalPages,
        totalResults: specifiedPeopleForStatusResponse.totalResults,
        pageNumberLimit: 2,
        maxPageNumberLimit: 2,
        minPageNumberLimit: 0,
      });
      setLoading('statusPeople', false);
    },
    [
      setLoading,
      setPeopleDataForStatus,
      setPeoplePaginatorForStatus,
      setStatusPeoplePages,
    ],
  );

  useEffect(() => {
    fetchSpecifiedPeopleForStatus({
      peoplePage: statusPeoplePaginator.page,
      state: filterState.people,
      status: filterStatus.people,
      startDate: goalsDate.starts,
      endDate: goalsDate.ends,
      member: searchable.members,
      manager: searchable.managers,
      department: searchable.department,
      departmentMembers: searchable.departmentMembers,
      deactivatedMembers: searchable.deactivatedMembers,
    });
  }, [
    fetchSpecifiedPeopleForStatus,
    filterState.people,
    filterStatus.people,
    goalsDate.ends,
    goalsDate.starts,
    searchable.deactivatedMembers,
    searchable.department,
    searchable.departmentMembers,
    searchable.managers,
    searchable.members,
    statusPeoplePaginator.page,
  ]);

  // Initialize department groups
  const fetchDepartmentGroups = useCallback(
    async (groupTypeId: string) => {
      const request = new AnalyticsRequestImpl();
      const controller = new AnalyticsController(request);
      const departmentGroupsResponse = await controller.fetchDepartmentGroups(
        groupTypeId,
      );
      setDepartmentsData(
        departmentGroupsResponse &&
          // eslint-disable-next-line array-callback-return
          departmentGroupsResponse.groups.map((group: any) => {
            if (group) {
              return {
                label: capitalize(group.name),
                value: group.id,
              };
            }
          }),
      );
    },
    [setDepartmentsData],
  );

  useEffect(() => {
    filterGroupTypeId &&
      filterGroupTypeId.length > 0 &&
      fetchDepartmentGroups(filterGroupTypeId);
  }, [fetchDepartmentGroups, filterGroupTypeId]);

  return {
    fetchSpecifiedGoals,
    fetchSpecifiedGroups,
    fetchMembersAndManagers,
    fetchSpecifiedKPIs,
    fetchGroupType,
    fetchGroups,
    fetchDepartmentGroups,
  };
}
