import {ReviewType} from './components/review-type';
import {VerticalSpacer} from '@ui/atoms/spacer';

import {ReviewFramework} from './components/review-framework';
import CycleName from './cycle-name';
import type {ValueProps} from '../../create-review-interface';
import {ReviewPeriod} from './components/review-period';

type Props = {
  handleChange: (field: keyof ValueProps, value: any) => void;
  currentValues: {
    cycleName: string;
    reviewType: string;
    reviewPeriod: {starts: string; ends: string};
    reviewFrameworks: string[];
  };
};

export const CycleInfo = ({handleChange, currentValues}: Props) => {
  const tooltipId = 'cc_review_create_review';

  return (
    <div>
      <VerticalSpacer size="24px" />

      <ReviewType
        reviewType={currentValues.reviewType}
        tooltipId={tooltipId}
        handleChange={(value) => {
          handleChange('reviewType', value);
        }}
      />

      <ReviewPeriod
        reviewPeriod={currentValues.reviewPeriod}
        handleChange={(value) => {
          handleChange('period', value);
        }}
      />

      <CycleName
        cycleName={currentValues.cycleName}
        handleChange={(value) => {
          handleChange('cycleName', value);
        }}
      />

      <ReviewFramework
        reviewFrameworks={currentValues.reviewFrameworks}
        handleChange={(value) => {
          handleChange('frameworks', value);
        }}
      />
    </div>
  );
};
