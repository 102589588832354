import {AxiosResponse} from 'axios';
import {
  SetupWorkspaceFormData,
  SetupWorkspaceResponse,
} from './setup-workspace-interface';
import {BaseRequest} from '../../../configs/request';

export interface SetupWorkspaceRequest extends BaseRequest {
  workspaceInvite(
    data: SetupWorkspaceFormData,
    accessToken: string,
  ): Promise<any>;
  workspaceName(
    data: string,
    accessToken: string,
  ): Promise<SetupWorkspaceResponse>;
  updateUser(userData: any, userId: string, accessToken: string): Promise<any>;
  workspaceEdit(
    id: string,
    data: string,
    accessToken: string,
  ): Promise<SetupWorkspaceResponse>;
  workspaceComplete(accessToken: string): Promise<any>;
}

export class SetupWorkspaceRequestImpl
  extends BaseRequest
  implements SetupWorkspaceRequest {
  private workspaceEndpoint: string = '/workspaces';
  private fetchUsersEndpoint: string = '/users';

  private inviteEndPoint: string = '/workspaces/invite-team';
  private completeSetupEndpoint: string = '/workspaces/complete-setup';

  async workspaceInvite<SetupWorkspaceResponse>(
    data: SetupWorkspaceFormData,
    accessToken: string,
  ) {
    const trimmedData = {
      emails: data.emails.filter(
        (val: any) =>
          Object.keys(val).length > 0 &&
          val.hasOwnProperty('email') &&
          val.email !== undefined,
      ),
    };
    const response = await this.request.post<
      SetupWorkspaceFormData,
      AxiosResponse<SetupWorkspaceResponse>
    >(this.inviteEndPoint, trimmedData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (response.status === 204) {
      return true;
    }
  }

  async workspaceName<SetupWorkspaceResponse>(
    data: string,
    accessToken: string,
  ) {
    const {data: responseData} = await this.request.post<
      SetupWorkspaceFormData,
      AxiosResponse<SetupWorkspaceResponse>
    >(this.workspaceEndpoint, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return responseData;
  }

  async workspaceEdit<SetupWorkspaceResponse>(
    id: string,
    data: any,
    accessToken: string,
  ) {
    const {data: responseData} = await this.request.patch<
      SetupWorkspaceFormData,
      AxiosResponse<SetupWorkspaceResponse>
    >(
      `${this.workspaceEndpoint}/${id}`,
      data?.settings
        ? {settings: {...data.settings, goalStatus: undefined}}
        : data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    return responseData;
  }

  async updateUser(userData: any, userId: string, accessToken: string) {
    const {data: response} = await this.request.patch(
      `${this.fetchUsersEndpoint}/${userId}`,
      userData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    return response;
  }

  async workspaceComplete<SetupWorkspaceResponse>(accessToken: string) {
    const response = await this.request.post<
      null,
      AxiosResponse<SetupWorkspaceResponse>
    >(
      this.completeSetupEndpoint,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    if (response.status === 204) {
      return true;
    }
  }
}
