import {memo, useMemo} from 'react';
import styled, {css} from 'styled-components';
import {Microcopy} from '../../atoms/typography/microcopy';
import {TextTag2} from '../../atoms/tag';
import {IGroupMembers} from '../../../hooks';
import {capitalize} from '../../../utils/capitalize';
import {CancelIcon} from '../../atoms/icons';
import {Tooltip} from '../tooltip';
import {ifProp} from 'styled-tools';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

const Text = styled(Microcopy)`
  margin-left: 0px;
  color: #585adf;
  font-weight: 500;
`;

const Container = styled.div<{disabled?: boolean}>`
  display: inline-flex;
  position: relative;
  .groupName .tooltip {
    visibility: hidden;
    width: auto;
    background-color: rgba(0, 0, 0, 0.7);
    font-size: 12px;
    color: #fff;
    text-align: center;
    padding: 12px;
    border-radius: 6px;
    position: absolute;
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 1;
    margin-top: -90px;
  }
  .groupName:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }

  .groupName .tooltip::after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
  .groupName .cancel {
    svg {
      transform: scale(0.95);
      fill: #5f5f8c;
      stroke: #5f5f8c;
      ${ifProp(
        'disabled',
        css`
          fill: #bfbfd4;
        `,
      )};
      path {
        ${ifProp(
          'disabled',
          css`
            stroke: #bfbfd4;
          `,
        )};
      }
    }
  }
`;

export interface GroupsProps {
  items: IGroupMembers[];
  total: number;
  groupDisplayLength?: any;
  showCancelIcon?: boolean;
  textStyle?: {
    color: string;
    size: string;
    weight: number;
  };
  cancelFx?: (value: string, clearAll?: boolean) => void;
  disabled?: boolean;
}

export const Groups = memo(function ({
  total,
  items,
  showCancelIcon,
  textStyle,
  groupDisplayLength,
  cancelFx,
  disabled,
}: GroupsProps) {
  const renderMore = useMemo(() => {
    if (groupDisplayLength !== undefined) {
      if (total > groupDisplayLength) {
        return (
          <Tooltip
            BodyTextNodeType={Text}
            text={`+${total - groupDisplayLength}`}
            bodyStyle={{color: 'rgb(88, 90, 223)', fontWeight: 500}}
            tooltipBody={
              <span>
                {items.map((group: IGroupMembers, index: number) => (
                  <span>
                    {`${group.name}${index === items?.length - 1 ? '' : ', '}`}
                  </span>
                ))}
              </span>
            }
          />
        );
      }
    } else {
      if (total > 2) {
        return (
          <Tooltip
            BodyTextNodeType={Text}
            text={`+${total - 2}`}
            bodyStyle={{color: 'rgb(88, 90, 223)', fontWeight: 500}}
            tooltipBody={
              <span>
                {items.map((group: IGroupMembers, index: number) => (
                  <span>
                    {`${group.name}${index === items?.length - 1 ? '' : ', '}`}
                  </span>
                ))}
              </span>
            }
          />
        );
      }
    }

    return null;
  }, [items, total, groupDisplayLength]);

  const item = items.slice(0, groupDisplayLength ? groupDisplayLength : 2);

  return (
    <Wrapper>
      <Container disabled={disabled}>
        {item.map((group: any, idx) => (
          <>
            {group?.name && (
              <div className="groupName">
                <TextTag2
                  style={{
                    marginRight: '4px',
                    borderRadius: '8px',
                    whiteSpace: 'nowrap',
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',
                    fontSize: textStyle?.size || undefined,
                    fontWeight: textStyle?.weight || undefined,
                    color:
                      disabled || group?.disabled
                        ? '#bfbfd4'
                        : textStyle?.color,
                  }}
                  key={`${idx}-${group.name}`}>
                  {group?.name?.length > 12
                    ? capitalize(group.name.substr(0, 12) + ' ...')
                    : capitalize(group.name || '')}
                  {showCancelIcon && !group?.disabled && (
                    <span
                      onClick={() => {
                        if (cancelFx) {
                          cancelFx(group.value);
                        }
                      }}
                      className="cancel"
                      style={{marginLeft: 7}}>
                      {' '}
                      <CancelIcon />
                    </span>
                  )}
                </TextTag2>
                {group?.name && group?.name?.length > 12 && (
                  <span className="tooltip">{group?.name}</span>
                )}
              </div>
            )}
          </>
        ))}
      </Container>
      {renderMore}
    </Wrapper>
  );
});
export const Groups2 = memo(function ({
  total,
  items,
  showCancelIcon,
  cancelFx,
  disabled,
}: GroupsProps) {
  const renderMore = useMemo(() => {
    if (total > 2) {
      return (
        <Tooltip
          BodyTextNodeType={Text}
          text={`+${total - 2}`}
          bodyStyle={{color: 'rgb(88, 90, 223)', fontWeight: 500}}
          tooltipBody={
            <span>
              {items.map((group: IGroupMembers, index: number) => (
                <span>
                  {`${group.name}${index === items?.length - 1 ? '' : ', '}`}
                </span>
              ))}
            </span>
          }
        />
      );
    }

    return null;
  }, [items, total]);
  const item = items.slice(0, 2);

  return (
    <Wrapper>
      <Container disabled={disabled}>
        {item.map((group: any, idx) => (
          <div className="groupName">
            <TextTag2
              style={{
                marginRight: '4px',
                borderRadius: '8px',
                display: 'flex',
                fontWeight: 500,
                fontSize: '12px',
                lineHeight: '150%',
                color: disabled ? '#bfbfd4' : '#1E1E2F',
              }}
              key={`${idx}-${group.name}`}>
              {group?.name?.length > 12
                ? capitalize(group.name.substr(0, 12) + ' ...')
                : capitalize(group.name)}
              {showCancelIcon && (
                <span
                  onClick={() => {
                    if (cancelFx) {
                      cancelFx(group.value);
                    }
                  }}
                  className="cancel"
                  style={{marginLeft: 7, color: '#5f5f8c'}}>
                  {' '}
                  <CancelIcon />
                </span>
              )}
            </TextTag2>
            {group.name && group?.name?.length > 12 && (
              <span className="tooltip">{group.name}</span>
            )}
          </div>
        ))}
      </Container>
      {renderMore}
    </Wrapper>
  );
});
