import {makeAutoObservable, observable, action} from 'mobx';
import {AnalyticsTodayDate} from '@utils/date';
import {IGoalResult} from '../../hooks/interface';
import {GoalsController} from '../../api/goals-api/goals-controller';
import {GoalsRequestImpl} from '../../api/goals-api/goals-request';

export class StoreAllObjectives {
  @observable goals_: IGoalResult[] = [];
  @observable youGoalsFilter: string = '';
  @observable fetchedGoals: Map<string, any> = new Map();
  @observable dateRange = {
    starts: AnalyticsTodayDate().AnalyticsDefaultStartDate,
    ends: AnalyticsTodayDate().AnalyticsDefaultEndDate,
  };
  @observable youGoalsFilterUpdate: boolean = false;
  @observable youGoalsPaginator: any = {
    limit: 10,
    page: 1,
    totalPages: 1,
    totalResults: 0,
    pageNumberLimit: 1,
    maxPageNumberLimit: 2,
    minPageNumberLimit: 0,
  };
  private request: any;
  private controller: any;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  setYouGoalsFilter = (filter: 'active' | 'upcoming' | 'archived' | '') => {
    this.youGoalsFilter = filter;
  };

  @action
  endGoalInAllObjectiveStore = (goalId: string, data: any) => {
    const endedGoal = this.goals_?.findIndex((goal: any) => goal.id === goalId);

    if (endedGoal !== -1) {
      this.goals_[endedGoal].status =
        data.completed === true ? 'completed' : 'in_completed';
    }
  };

  @action
  updateEndedGoal = (goalId: string, data: any) => {
    const endedGoal = this.goals_?.findIndex((goal: any) => goal.id === goalId);

    if (endedGoal !== -1) {
      this.goals_[endedGoal] = {
        ...data,
        assignee: this.goals_[endedGoal].assignee,
        keyResults: this.goals_[endedGoal].keyResults,
      };
    }
  };

  @action
  setGoals = (data: any) => {
    this.goals_ = data;
  };

  @action
  setYouGoalsFilterUpdate = (state: boolean) => {
    this.youGoalsFilterUpdate = state;
  };

  // Paginator start
  @action
  modifyYouGoalsPaginator = (field: string, value: number) => {
    this.youGoalsPaginator[field] = value;
  };

  @action
  setDateRange = (data: any) => {
    this.dateRange = data;
  };

  @action
  handleYouGoalsPaginatorPageClick = (id: any) => {
    this.modifyYouGoalsPaginator('page', id);
  };

  @action
  setFetchGoals = async (data: any) => {
    data.forEach((result: any) => {
      if (!this.fetchedGoals.has(result.id))
        this.fetchedGoals.set(result.id, result);
    });
  };

  @action
  replaceFetchedGoals = async (data: any) => {
    this.fetchedGoals = new Map();
    data.forEach((result: any) => {
      if (!this.fetchedGoals.has(result.id))
        this.fetchedGoals.set(result.id, result);
    });
  };

  @action
  updateGoalFields = (goalId: string, field: string, value: any) => {
    const updateGoal = this.fetchedGoals.get(goalId);
    if (updateGoal) {
      updateGoal[field] = value;

      this.fetchedGoals.set(goalId, updateGoal);
    }
  };

  @action
  updateGoalContextValue = (goalId: string, value: any) => {
    // find the goal with goalID
    const updateGoal = this.fetchedGoals.get(goalId);

    if (updateGoal) {
      updateGoal.context = value;

      this.fetchedGoals.set(goalId, updateGoal);
    }
  };

  @action
  saveUpdate = async (goalId: string) => {
    const updateGoal = this.fetchedGoals.get(goalId);

    this.request = new GoalsRequestImpl();
    this.controller = new GoalsController(this.request);

    const filterUpdate = {
      ...updateGoal,
      keyResults: updateGoal.keyResults?.map((result: any) => {
        return {
          ...result,
          context: result?.contextValue || '',
          contextValue: undefined,
          id: result.id,
        };
      }),
    };

    this.fetchedGoals.set(goalId, {
      ...updateGoal,
      keyResults: updateGoal.keyResults?.map((result: any) => {
        return {
          ...result,
          contextValue: undefined,
        };
      }),
    });

    const response = await this.controller.updateGoal(filterUpdate, goalId);

    response &&
      this.fetchedGoals.set(goalId, {
        ...response,
        assignee: updateGoal.assignee,
        keyResults: response?.keyResults.map((kr: {id: string}) => {
          const prevKr = updateGoal.keyResults.find(
            (_kr: {id: string}) => _kr.id === kr.id,
          );
          return {
            ...kr,
            assignee: prevKr?.assignee,
          };
        }),
        valueUpdated: false,
        goalType: updateGoal.goalType,
      });
    return response;
  };

  @action
  revertChanges = async (goalId: string) => {
    const updateGoal = this.fetchedGoals.get(goalId);

    this.request = new GoalsRequestImpl();
    this.controller = new GoalsController(this.request);

    const response = await this.controller.revertUpdate(goalId);

    response &&
      this.fetchedGoals.set(goalId, {
        ...response,
        assignee: updateGoal.assignee,
        valueUpdated: false,
        goalType: updateGoal.goalType,
      });
    return response;
  };

  @action
  handleYouGoalsPaginatorNextbtn = () => {
    this.modifyYouGoalsPaginator('page', this.youGoalsPaginator.page + 1);

    if (
      this.youGoalsPaginator.page + 1 >
      this.youGoalsPaginator.maxPageNumberLimit
    ) {
      this.modifyYouGoalsPaginator(
        'maxPageNumberLimit',
        this.youGoalsPaginator.maxPageNumberLimit +
          this.youGoalsPaginator.pageNumberLimit,
      );
      this.modifyYouGoalsPaginator(
        'minPageNumberLimit',
        this.youGoalsPaginator.minPageNumberLimit +
          this.youGoalsPaginator.pageNumberLimit,
      );
    }
  };

  @action
  handleYouGoalsPaginatorPrevbtn = () => {
    this.modifyYouGoalsPaginator('page', this.youGoalsPaginator.page - 1);
    if (
      (this.youGoalsPaginator.page - 1) %
        this.youGoalsPaginator.pageNumberLimit ===
      0
    ) {
      this.modifyYouGoalsPaginator(
        'maxPageNumberLimit',
        this.youGoalsPaginator.maxPageNumberLimit -
          this.youGoalsPaginator.pageNumberLimit,
      );
      this.modifyYouGoalsPaginator(
        'minPageNumberLimit',
        this.youGoalsPaginator.minPageNumberLimit -
          this.youGoalsPaginator.pageNumberLimit,
      );
    }
  };

  @action
  youGoalsPages = (totalResults: number, limit: number) => {
    const arr = [];
    for (
      let i = 1;
      i <=
      Math.ceil(
        this.youGoalsPaginator.totalResults / this.youGoalsPaginator.limit,
      );
      i++
    ) {
      arr.push(i);
    }
    return arr;
  };

  // Paginator end

  @action
  getGoalById = (id: string) => {
    return this.goals_.filter((goal) => goal.id === id);
  };
}

export const storeAllObjectives = new StoreAllObjectives();
