import {activateNotification} from '../../../../../../ui/molecules/notification/activate-notification';
import {parseErrorContent} from '../../../../../../utils/error-handling';
import {
  IAdminBillingEditSubscriptionFormData,
  IAdminBillingFormData,
} from './admin-billing.interface';
import {AdminBillingRequestImpl} from './admin-billing.request';

export class AdminBillingController {
  constructor(private readonly request: AdminBillingRequestImpl) {}

  async fetchAllBillingPlans() {
    try {
      const response = await this.request.fetchAllBillingPlans();

      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
    }
  }
  async fetchAllBillingSubscriptions() {
    try {
      const response = await this.request.fetchAllBillingSubscriptions();
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
    }
  }

  async createBillingSubscription(
    data: IAdminBillingFormData,
    hideNotification?: boolean,
    accessToken?: string,
  ) {
    try {
      const response = await this.request.createBillingSubscription(
        data,
        accessToken,
      );
      response &&
        !hideNotification &&
        activateNotification({
          title: 'Success',
          content: data?.isTrial
            ? 'Your free trial has started'
            : 'Billing subscription created successfully',
          kind: 'success',
        });
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
    }
  }
  async fetchSpecificBillingSubscription(subscriptionId?: string) {
    try {
      const response = await this.request.fetchSpecificBillingSubscription(
        subscriptionId,
      );
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
    }
  }
  async editSpecificBillingSubscription(
    subscriptionId: string,
    data: IAdminBillingEditSubscriptionFormData,
  ) {
    try {
      const response = await this.request.editSpecificBillingSubscription(
        subscriptionId,
        data,
      );
      response &&
        activateNotification({
          title: 'Success',
          content: 'Billing subscription edited successfully',
          kind: 'success',
        });
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
    }
  }

  async cancelSpecificBillingSubscription(subscriptionId: string) {
    try {
      const response = await this.request.cancelSpecificBillingSubscription(
        subscriptionId,
      );
      response &&
        activateNotification({
          title: 'Success',
          content: 'Billing subscription deleted successfully',
          kind: 'success',
        });
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
    }
  }

  async cancelSubscription() {
    try {
      const response = await this.request.cancelSubscription();
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
    }
  }
  async restartSubscription() {
    try {
      const response = await this.request.restartSubscription();
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
    }
  }
  async retrySubscription() {
    try {
      const response = await this.request.retrySubscription();
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
    }
  }
  async switchSubscription(plan: string) {
    try {
      const response = await this.request.switchSubscription(plan);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
    }
  }

  async createBillingMethod(
    data: {paymentId: string},
    hideNotification?: boolean,
  ) {
    try {
      const response = await this.request.createBillingMethod(data);
      response &&
        !hideNotification &&
        activateNotification({
          title: 'Success',
          content: 'Billing method added successfully',
          kind: 'success',
        });
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
    }
  }
  async getAllBillingMethods() {
    try {
      const response = await this.request.getAllBillingMethods();
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
    }
  }
  async getSpecificBillingMethodById(paymentId?: string) {
    try {
      const response = await this.request.getSpecificBillingMethodById(
        paymentId,
      );
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
    }
  }
  async removeSpecificBillingMethodById(paymentId: string) {
    try {
      const response = await this.request.removeSpecificBillingMethodById(
        paymentId,
      );
      response &&
        activateNotification({
          title: 'Success',
          content: 'Billing method removed successfully',
          kind: 'success',
        });
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
    }
  }
  async getAllValidCoupons() {
    try {
      const response = await this.request.getAllValidCoupons();
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
    }
  }
  async validateCouponCode(data: {code: string}) {
    try {
      const response = await this.request.validateCouponCode(data);
      response &&
        activateNotification({
          title: 'Success',
          content: 'Coupon validated successfully',
          kind: 'success',
        });
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      throw new Error(e);
    }
  }
  async fetchNextCycle() {
    try {
      const response = await this.request.fetchNextCycle();
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
    }
  }
}
