import {ButtonNavigation} from '@ui/molecules/button-nav';
import {Wrapper, NavBorderWrapper, BorderWrapper, BodyWrapper} from './style';

import {Body1} from '@ui/atoms/typography';

import {observer} from 'mobx-react';
import {ManageReviewCycle} from './review-cycle-page';
import {useStoreContext} from '@store/store-context';

import {CycleOverview} from '../cycle-overview/cycle-overview';
import {useMemo} from 'react';
import {authStore} from '@store/stores/auth-store';

export const ReviewCycles = observer(() => {
  const {
    storeDashboardRoutes: {
      activateManageReviewCycleTabs,
      manageReviewCycleTabs: activeTabs,
    },
  } = useStoreContext();

  const cycleNavigations = useMemo(() => {
    const options = [
      {
        active: activeTabs.overview,
        href: '#',
        id: '3',
        onClick: () => activateManageReviewCycleTabs('overview'),
        title: 'Cycle overview',
      },
      {
        active: activeTabs.reviewCycles,
        href: '#',
        id: '2',
        onClick: () => activateManageReviewCycleTabs('reviewCycles'),
        title: 'Review cycles',
      },
    ];

    return options;
  }, [
    activateManageReviewCycleTabs,
    activeTabs.overview,
    activeTabs.reviewCycles,
  ]);

  return (
    <Wrapper className="bg-white">
      <div className="heading-top">
        <Body1 weight="bold">Manage cycle settings</Body1>
      </div>
      {authStore.auth.user.role === 'admin' && (
        <NavBorderWrapper>
          <BorderWrapper>
            <ButtonNavigation
              navigations={cycleNavigations}
              variant="addBorderTop"
              theme="purpleText"
            />
          </BorderWrapper>
        </NavBorderWrapper>
      )}
      <BodyWrapper>
        {activeTabs.reviewCycles && (
          <div className="add-p">
            <ManageReviewCycle />
          </div>
        )}
        {activeTabs.overview && <CycleOverview />}
      </BodyWrapper>
    </Wrapper>
  );
});
