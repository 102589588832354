import {
  EventStreamContentType,
  fetchEventSource,
} from '@microsoft/fetch-event-source';
import {authStore} from '../store/stores/auth-store';
import dayjs from 'dayjs';
import {AUTH_ROUTES} from '../constants';
import axios, {AxiosAdapter, AxiosInstance} from 'axios';
import {logError} from '../utils/firebase-request';

import {
  cacheAdapterEnhancer,
  throttleAdapterEnhancer,
  ICacheLike,
} from 'axios-extensions';

declare module 'axios' {
  interface AxiosRequestConfig {
    useCache?: boolean | ICacheLike<any>;
  }
}
export class BaseRequest {
  request: AxiosInstance;
  url: string = 'http://127.0.0.1:4000';
  apiEndpoint: string | undefined = process.env.REACT_APP_BASE_URL;
  thirdPartyRequest: AxiosInstance;
  cloudConvertRequest: AxiosInstance;

  constructor() {
    this.request = axios.create({
      baseURL: this.apiEndpoint,
      headers: {
        'Cache-Control': 'no-cache',
        'Access-Control-Allow-Origin': '*',
      },
      adapter: throttleAdapterEnhancer(
        cacheAdapterEnhancer(axios.defaults.adapter as AxiosAdapter, {
          cacheFlag: 'useCache',
        }),
      ),
    });
    this.thirdPartyRequest = axios.create({
      headers: {
        'Cache-Control': 'no-cache',
        'Access-Control-Allow-Origin': '*',
      },
      adapter: throttleAdapterEnhancer(
        cacheAdapterEnhancer(axios.defaults.adapter as AxiosAdapter, {
          cacheFlag: 'useCache',
        }),
      ),
    });

    this.cloudConvertRequest = axios.create();

    this.request.interceptors.request.use(
      function (config) {
        return config;
      },
      function (error) {
        return Promise.reject(error);
      },
    );

    this.cloudConvertRequest.interceptors.request.use((config) => {
      if (config.headers) {
        delete config.headers['User-Agent'];
      }
      return config;
    });

    this.request.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (!AUTH_ROUTES.includes(window.location.pathname)) {
          logError({
            url: error?.response?.config?.url,
            error: error?.response?.data,
            page: window.location.pathname,
            createdAt: dayjs().format(),
            workspace: {
              name: authStore.auth?.user?.workspace?.name,
              id: authStore.auth?.user?.workspace?.id,
            },
            loggedInUser: {
              name: authStore.auth?.user?.firstName,
              id: authStore.auth?.user?.id,
            },
          });

          if (error.response.status === 401) {
            localStorage.clear();
            window.location.assign('/sign-in');
          }
        }
        return Promise.reject(error);
      },
    );
    this.request.defaults.headers.common['Content-Type'] = 'application/json';
  }
}

export interface ISSEFetch {
  method: string;
  url: string;
  data?: any;
  onMessageCallback?: (ev: any) => void;
  onOpenCallback?: (
    response: Response,
    responseType: string,
  ) => Promise<Response>;
  onCloseCallback?: () => void;
  onErrorCallback?: (err: any) => void;
  headers?: any;
}
class RetriableError extends Error {}
class FatalError extends Error {}

export const SSEFetch = async ({
  method,
  url,
  data,
  headers,
  onMessageCallback,
  onCloseCallback,
  onOpenCallback,
  onErrorCallback,
}: ISSEFetch) => {
  const apiEndpoint: string | undefined = process.env.REACT_APP_BASE_URL;

  const body = data ? JSON.stringify({data}) : null;
  if (!!window.EventSource) {
    const ctrl = new AbortController();
    await fetchEventSource(`${apiEndpoint}${url}`, {
      async onopen(response: any) {
        if (
          response.ok &&
          response.headers.get('content-type') === EventStreamContentType
        ) {
          if (onOpenCallback) {
            onOpenCallback(response, 'ok');
            return response;
          } else return response;
        } else if (response.status === 200) {
          if (onOpenCallback) {
            onOpenCallback(response, 'ok');
            return response;
          } else return response;
        } else if (
          response.status >= 400 &&
          response.status < 500 &&
          response.status !== 429
        ) {
          if (onOpenCallback) {
            onOpenCallback(response, 'error');
            return response;
          } else {
            throw new FatalError();
            // client-side errors are usually non-retriable:
          }
        } else {
          throw new RetriableError();
        }
      },
      method,
      body,
      headers: headers ? headers : null,
      signal: ctrl.signal,
      onmessage(ev) {
        if (onMessageCallback) {
          onMessageCallback(ev);
          return ev;
        }
        if (ev.event === 'FatalError') {
          throw new FatalError(ev.data);
        }
      },
      onclose() {
        if (onCloseCallback) {
          onCloseCallback();
        }
        // if the server closes the connection unexpectedly, retry:
        throw new RetriableError();
      },
      onerror(err) {
        if (err instanceof FatalError) {
          if (onErrorCallback) {
            onErrorCallback(err);
            return err;
          }
          throw err; // rethrow to stop the operation
        } else {
          if (onErrorCallback) {
            onErrorCallback(err);
            return err;
          }
          // do nothing to automatically retry. You can also
          // return a specific retry interval here.
        }
      },
    });
  }
};

export const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
