import React, {FC, useCallback, useMemo} from 'react';
import {ObjSummary} from '../../reporting-pages/reporting-overview/obj-summary';
import {Insight} from '../interface';
import {Body1} from '@ui/atoms/typography';
import {VerticalInsightConfig} from '../../dashboard/insight-config';
import {getInitialAdditionalFilters} from '../edit-insight/edit-insight-filter';
import {AnalyticsController} from '../../reporting-pages/admin-analytics/admin-analytics.controller';
import {AnalyticsRequestImpl} from '../../reporting-pages/admin-analytics/admin-analytics.request';
import {useQuery} from 'react-query';
import {FlexRowCenter} from '@ui/style/styles';
import {ItemLoader} from '@ui/organisms/item-loader';
import {formatConfigDate} from '../insight-options';

interface ObjectiveStatusRateProps extends Insight {
  showRangeType?: boolean;
}

export const ObjectiveStatus: FC<ObjectiveStatusRateProps> = ({
  showRangeType,
  name,
  config,
}) => {
  const additionalFilters = getInitialAdditionalFilters(
    config.list,
    config.users,
  );
  const formatDateRange = useMemo(() => formatConfigDate(config), [config]);

  const fetchSpecifiedGoals = useCallback(
    async (filters: any, view?: string) => {
      const request = new AnalyticsRequestImpl();
      const controller = new AnalyticsController(request);

      const specifiedGoalsResponse = await controller.fetchSpecifiedGoals(
        filters,
        view,
      );

      return specifiedGoalsResponse;
    },
    [],
  );

  const {data, isLoading} = useQuery(['objective-status', config], () =>
    fetchSpecifiedGoals({
      startDate: formatDateRange.startDate,
      endDate: formatDateRange.endDate,
      newReport: true,
      goalType: config.objectiveType,
      isKpi: false,
      department: config.groups?.join(','),
    }),
  );

  return (
    <div className="bg-white shadow-sm p-6 h-full border rounded-[10px] border-borderLight  min-h-[150px] w-full ">
      <Body1 weight="bold" className="mb-2">
        {name}
      </Body1>

      {showRangeType && (
        <div>
          <VerticalInsightConfig
            config={config}
            additionalFilters={additionalFilters}
          />
        </div>
      )}

      {isLoading ? (
        <FlexRowCenter className="w-full pt-4">
          <ItemLoader />
        </FlexRowCenter>
      ) : (
        <ObjSummary
          showCards={false}
          data={data?.summary}
          avgTotal={`${data?.summary.goals.fulfillment || 0}%`}
          title={'Completion'}
        />
      )}
    </div>
  );
};
