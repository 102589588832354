import {useTags} from '@hooks/tags';

export const useMeetingTags = () => {
  const {tags} = useTags('tags', [
    {
      tag: 'All-hands meeting',
      disabled: true,
      key: '',
    },
    {
      tag: '1-on-1s',
      disabled: true,
      key: '',
    },
  ]);
  return {
    tags,
  };
};
