import React from 'react';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import PageLayoutTemplate from '@ui/templates/page-template/page-layout';
import {cfv} from '@utils/framework';
import {useNavigate} from 'react-router';
import ImportPage from './import-page';
const ImportWrapper = styled.div`
  margin: 0px 20px;
  @media (max-width: 499px) {
    margin: 0px;
  }
`;
export default function ImportManager() {
  const navigate = useNavigate();
  return (
    <PageLayoutTemplate
      title={`Create ${cfv(undefined, true).g_oals}`}
      onClick={() => navigate('/objectives')}>
      <ImportWrapper>
        <ImportPage />
      </ImportWrapper>
    </PageLayoutTemplate>
  );
}
export const importManager = () => {
  const Page = observer(() => <ImportManager />);
  return {
    Page,
  };
};
