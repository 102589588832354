import {useSearchParams} from 'react-router-dom';
import {useStoreContext} from '@store/store-context';
import {useEffect} from 'react';

export const useUrlQuery = () => {
  const {
    groupStore: {updateGroupDrawer},
    checkinsStore: {updateCheckinsDrawer},
    goalsState: {setInsightGoal, setIsManagerEditing, isManagerEditing},
    storeDashboardRoutes: {activateInsightTabs},
  } = useStoreContext();

  // Manage url query

  const [searchParams] = useSearchParams();
  const viewInsight = searchParams.get('insight');
  const goalInsight = searchParams.get('goal');
  const managerEditGoal = searchParams.get('managerEditGoal');
  const manageGroup = searchParams.get('group-drawer');
  const viewInsightComment = searchParams.get('comment');
  const checkinsComment = searchParams.get('checkins-comment');

  useEffect(() => {
    if (viewInsight) {
      setInsightGoal({
        goal: goalInsight as string,
        insight: true,
      });
      if (viewInsightComment) {
        activateInsightTabs('comment');
      }
    } else if (checkinsComment) {
      updateCheckinsDrawer(checkinsComment);
    } else if (managerEditGoal) {
      setIsManagerEditing(true);
    } else if (manageGroup) {
      updateGroupDrawer(true);
    } else if (isManagerEditing && !managerEditGoal) {
      setIsManagerEditing(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    manageGroup,
    checkinsComment,
    viewInsight,
    managerEditGoal,
    goalInsight,
    viewInsightComment,
    isManagerEditing,
  ]);

  return {};
};
