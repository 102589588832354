import {useStoreContext} from '@store/store-context';
import {useLocation, useNavigate} from 'react-router-dom';
import {useSearchParams} from 'react-router-dom';

export const useViewInsight = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const location = useLocation();

  const {
    goalsState: {setInsightGoal},
    storeDashboardRoutes: {activateInsightTabs},
  } = useStoreContext();

  const viewGoalInsight = (goalId: string, isKpi?: boolean) => {
    setInsightGoal({
      goal: goalId,
      insight: true,
    });

    activateInsightTabs('insight');

    searchParams.set('goal', goalId);

    searchParams.set('insight', 'true');

    if (isKpi) searchParams.set('isKpi', 'true');

    const searchValues = new URLSearchParams(location.search);

    const newSearch = searchValues.toString();

    navigate({
      search: `?goal=${goalId}&insight=true${newSearch ? '&' + newSearch : ''}${
        isKpi ? '&isKpi=true' : ''
      }`,
    });
  };

  return viewGoalInsight;
};
