import {memo} from 'react';

export const Company2Icon = memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16">
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M11.332 1.333H4.665c-.736 0-1.333.566-1.333 1.265V12.71c0 1.58 0 1.264 1.333 1.264h6.667c1.333 0 1.333 0 1.333-1.264V2.598c0-.699-.597-1.264-1.333-1.264z"
    />
    <path d="M6 11c0-.552.448-1 1-1h2c.552 0 1 .448 1 1v3H6v-3z" />
    <path strokeLinecap="round" d="M6 7.333h4M2.668 14h10.667M6 4.667h4" />
  </svg>
));

Company2Icon.displayName = 'Company2Icon';
