import {observer} from 'mobx-react-lite';
import {useNavigate} from 'react-router';
import {FlexRow} from '@ui/style/styles';
import {sortFeedback} from '@utils/sortFeedback';
import {useMemo, useCallback} from 'react';
import ValueThemeFilter from '@pages/feedback/components/values-themes-filter';
import {useFetchHook} from '@hooks/query-hook';
import {ItemLoader} from '@ui/organisms/item-loader';
import dayjs from 'dayjs';
import {
  DesktopWrapper,
  MobileWrapper,
} from '@ui/molecules/workspace/logo-toggle/logo-toggle';
import {activateNotification} from '@ui/molecules/notification/activate-notification';
import {HorizontalDots} from '@ui/atoms/icons/horizontal-dots';
import {DropdownVertical} from '@ui/molecules/dropdown-vertical';
import {useStoreContext} from '@store/store-context';
import {Feedback, FeedbackItems} from '@pages/feedback/feedback-items';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {TabNavigation} from '@ui/molecules/tab-nav';
import styled from 'styled-components';
import {EmptyPlaceholder} from '@ui/atoms/empty-placeholder';
import {CSVLink} from 'react-csv';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {CalenderIcon} from '@ui/atoms/icons/calender-icon';
import {cloudinary} from '@assets/images/cloudinary';
import {useViewUserFeedbackHook} from './view-user-feedback-hook';
import {Headline2, Body1, Body2} from '@ui/atoms/typography';
import PageLayoutTemplate from '@ui/templates/page-template/page-layout';
import {userName} from '@utils/user-name';
const {emptyGoals} = cloudinary;

const EmptyFeedback = {
  image: {
    alt: 'alt text',
    shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
    src: emptyGoals,
    style: {height: '30%', width: '30%'},
  },
  imports: true,
  subtitle: '',
  title: `No Feedback found.`,
};
const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .page {
    width: 70%;

    margin-bottom: 30px;
    @media (min-width: 1100px) and (max-width: 1320px) {
      width: 70%;
    }
    @media (min-width: 768px) and (max-width: 1100px) {
      width: 70%;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

const SummaryWrapper = styled.div`
  padding: 16px 24px;
  display: flex;
  align-items: center;
  height: 68px;
  justify-content: space-between;
  border: 1px solid #ededf2;
  border-radius: 10px;
`;

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
`;
const DateRangeWrapper = styled(FlexRow)`
  svg {
    width: 20px;
    height: 20px;
    margin-right: 4px;
    path {
      stroke: #585adf;
    }
  }
`;
const FilterWrapper = styled.div`
  .filter {
    min-width: 153px;
    @media (max-width: 768px) {
      min-width: 96px;
    }
  }
`;
const ViewUserFeedback = () => {
  const navigate = useNavigate();

  const {
    controller,
    id,
    filters,
    period,
    startDate,
    setArchive,
    endDate,
    archive,
    user,
    setValueThemeFilter,
    valueThemeFilter,
  } = useViewUserFeedbackHook();

  const {data = [], isLoading, refetch} = useFetchHook(
    ['user-feedback', id, filters],
    () => controller.getFeedback(filters, id),
  );
  const {
    usersStore: {users},
  } = useStoreContext();
  const userFeedbacks = data as any;

  const updateCSVData = useCallback(
    (data: Array<Feedback>) => {
      return [
        [
          'name',
          'feedback',
          'feedback name',
          'Theme',
          'feedback type',
          'date',
          'reviewer',
          'values',
        ],
        ...data.map((feedback: Feedback) => [
          userName(user),
          feedback.feedback,
          feedback.subjectName,
          feedback.impression,
          feedback.subjectType,
          dayjs(feedback.createdAt).format('DD MMM. YYYY'),
          userName(users.find((_user) => _user.id === feedback.reviewer)),
          feedback.values,
        ]),
      ];
    },
    [user, users],
  );
  const pageNavigation = useMemo(() => {
    const navigations = [
      {
        active: !archive,
        href: '#',
        id: '1',
        onClick: () => {
          setArchive(false);
        },
        title: 'Active',
      },
      {
        active: archive,
        href: '#',
        id: '3',
        onClick: () => {
          setArchive(true);
        },
        title: 'Archived',
      },
    ];

    return navigations;
  }, [archive, setArchive]);

  const sortData = useMemo(
    () => Object.values(sortFeedback(userFeedbacks, 'createdAt')),
    [userFeedbacks],
  );

  const formatDate = (date: string) => {
    return date.replaceAll('-', '/');
  };

  const formatStartDate = dayjs(formatDate(startDate), 'MM-DD-YYYY').format(
    'DD MMM. ',
  );

  const formatEndDate = dayjs(formatDate(endDate), 'MM-DD-YYYY').format(
    'DD MMM. ',
  );

  const formatYear = dayjs(formatDate(endDate), 'MM-DD-YYYY').format('YYYY');

  const dateRange = period
    ? `${period} (${formatStartDate} - ${formatEndDate})`
    : `${formatStartDate} - ${formatEndDate} ${formatYear}`;

  const patchFeedback = async (feedbackId: string, archive: boolean) => {
    const response = await controller.patchFeedback(
      {
        archived: archive,
      },
      feedbackId,
    );

    response && (await refetch());
    response &&
      activateNotification({
        content: `Feedback ${archive ? 'archived' : 'restored'} successfully`,
        title: 'Success',
        kind: 'success',
      });
  };
  const title = `Feedback for  ${
    user?.firstName ? user?.firstName + ' ' + user?.lastName : user?.email
  } `;

  return (
    <PageLayoutTemplate title={'Feedback'} onClick={() => navigate(-1)}>
      <PageWrapper>
        <div className="page">
          <Headline2>{title}</Headline2>

          <VerticalSpacer size="30px" />
          <SummaryWrapper>
            <DateRangeWrapper>
              <CalenderIcon />
              <MobileWrapper>
                <Body2 weight="semibold">{dateRange}</Body2>
              </MobileWrapper>
              <DesktopWrapper>
                <Body1 weight="semibold">{dateRange}</Body1>
              </DesktopWrapper>
            </DateRangeWrapper>
            <FlexRow>
              <FilterWrapper>
                <ValueThemeFilter
                  className="filter"
                  style={{
                    padding: '8px 16px',
                    marginLeft: '4px',
                  }}
                  onChange={(value) => setValueThemeFilter(value)}
                  values={valueThemeFilter}
                />
              </FilterWrapper>
              <DropdownVertical
                customIcon={<HorizontalDots />}
                menu={(handleClose: () => void) => (
                  <div>
                    <CSVLink
                      data={updateCSVData(userFeedbacks)}
                      filename={`${userName(
                        user,
                      )}-feedbacks-${dayjs().format()}`}>
                      <DropdownItem
                        onClick={() => {
                          handleClose();
                        }}>
                        Export .csv
                      </DropdownItem>
                    </CSVLink>
                  </div>
                )}
                collapseOnClick
                dropdownWrapperStyle={{right: 0}}
              />
            </FlexRow>
          </SummaryWrapper>
          <VerticalSpacer size="60px" />
          <TabNavigation navigations={pageNavigation} type="button" />
          <VerticalSpacer size="30px" />
          {isLoading && (
            <LoadingWrapper>
              <ItemLoader />
            </LoadingWrapper>
          )}
          {!isLoading && sortData.length === 0 && (
            <EmptyPlaceholder {...EmptyFeedback} />
          )}
          {sortData &&
            !isLoading &&
            sortData.map((data, index) => (
              <FeedbackItems
                data={data}
                dropdown={(data) => (
                  <DropdownVertical
                    customIcon={<HorizontalDots />}
                    menu={(handleClose: () => void) => (
                      <div>
                        <DropdownItem
                          onClick={() => {
                            patchFeedback(data.id, archive ? false : true);
                            handleClose();
                          }}>
                          {archive ? 'Restore feedback' : 'Archive feedback'}
                        </DropdownItem>
                      </div>
                    )}
                    collapseOnClick
                    dropdownWrapperStyle={{right: 0}}
                  />
                )}
                key={index}
                sortFeedbackBy={'reviewer'}
              />
            ))}
        </div>
      </PageWrapper>
    </PageLayoutTemplate>
  );
};

export default observer(ViewUserFeedback);
