import {AxiosResponse} from 'axios';
import {
  CreateGoalFormData,
  CreateGoalResponse,
  GoalTemplatesResponse,
} from './create-goal-interface';
import {BaseRequest} from '../../configs/request';
import {authStore} from '../../store/stores/auth-store';
import {ICreateGoalApiDataFormat} from '../../hooks';

export interface CreateGoalRequest extends BaseRequest {
  url: string;
  templatesUrl: string;
  createGoal(data: ICreateGoalApiDataFormat): Promise<CreateGoalResponse>;
  editGoal(
    data: ICreateGoalApiDataFormat,
    goalId: string,
  ): Promise<CreateGoalResponse>;

  getAllGoalTemplates(
    page?: number,
    type?: string,
    limit?: number,
  ): Promise<GoalTemplatesResponse>;
  getTemplatesType(): Promise<any>;
}

export class CreateGoalRequestImpl
  extends BaseRequest
  implements CreateGoalRequest {
  url: string = '/goals';
  templatesUrl: string = '/goal-templates';

  async createGoal<CreateGoalResponse>(data: ICreateGoalApiDataFormat) {
    const {data: responseData} = await this.request.post<
      CreateGoalFormData,
      AxiosResponse<CreateGoalResponse>
    >(this.url, data, {
      headers: {
        Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
      },
    });
    return responseData;
  }

  async editGoal<CreateGoalResponse>(
    data: ICreateGoalApiDataFormat,
    goalId: string,
  ) {
    const {data: responseData} = await this.request.patch<
      CreateGoalFormData,
      AxiosResponse<CreateGoalResponse>
    >(`${this.url}/${goalId}`, data, {
      headers: {
        Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
      },
    });
    return responseData;
  }

  async getAllGoalTemplates(page?: number, type?: string, limit?: number) {
    const url = `${this.templatesUrl}${page || type ? '?' : ''}${
      page ? `paginate=false&` : ''
    }${type ? `type=${type}&` : ''}${limit ? `limit=${limit}` : ''}`;
    const {data: responseData} = await this.request.get(url, {
      headers: {
        Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
      },
    });
    return responseData;
  }

  async getTemplatesType() {
    const url = `${this.templatesUrl}/types`;
    const {data: responseData} = await this.request.get(url, {
      headers: {
        Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
      },
    });
    return responseData;
  }
}
