import {memo} from 'react';

interface IArrowHeadDownIcon {
  style?: any;
}
export const ArrowHeadDownIcon = memo(({style}: IArrowHeadDownIcon) => (
  <svg
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16">
    <path strokeLinecap="round" strokeLinejoin="round" d="M4 6l4 4 4-4" />
  </svg>
));

ArrowHeadDownIcon.displayName = 'ArrowHeadDownIcon';
