import ReactSelect, {
  components,
  OptionsType,
  ValueType,
  ActionMeta,
  Props,
  IndicatorProps,
  OptionProps,
  ControlProps,
} from 'react-select';
//import Select from 'react-select'
import {MagnifyingGlassIcon} from '../../../atoms/icons/magnifying-glass';
import {
  IndicatorWrapper,
  getStyles,
  AvatarWrapper,
  ControlWrapper,
} from './styled';
import {HorizontalSpacer} from '../../../atoms/spacer';
import {Avatar} from '../../../atoms/avatar';
import {Body2} from '../../../atoms/typography';
import {forwardRef, Ref, useMemo} from 'react';
import {Checkbox} from '../../../atoms/checkbox';

interface IOption {
  label: {name: string};
}

const filterUserSelectOptions = (option: {data: IOption}, rawInput: string) => {
  if (rawInput) {
    const label = option.data.label.name.toLowerCase();
    return rawInput
      .toLowerCase()
      .split(' ')
      .every((word) => label.includes(word));
  }
  return true;
};

const {
  DropdownIndicator: SelectDropdownIndicator,
  Option: SelectOption,
  Control: SelectControl,
} = components;

export interface TLabel {
  name: string;
  avatar: {src: string; name: string};
  id: string;
}

export interface TOption {
  value: string;
  label: TLabel;
}
export interface SelectFieldProps extends Props<any> {
  leadingNote?: string;
  options: OptionsType<any>;
  onChange?: (value: ValueType<any, false>, action?: ActionMeta<any>) => void;
  loading?: boolean;
}

const DropdownIndicator = (props: IndicatorProps<TOption, false>) => {
  return (
    <SelectDropdownIndicator {...props}>
      <IndicatorWrapper>
        <MagnifyingGlassIcon />
      </IndicatorWrapper>
    </SelectDropdownIndicator>
  );
};

const Control = (props: ControlProps<TOption, false>) => {
  return (
    <ControlWrapper>
      <SelectControl {...props} />
    </ControlWrapper>
  );
};

const Option = (props: OptionProps<TOption, false>) => {
  return (
    <SelectOption {...props}>
      <AvatarWrapper>
        <Checkbox checked={props.isSelected} />
        <HorizontalSpacer size="8px" />
        <Avatar
          src={((props.label as unknown) as TLabel).avatar.src}
          name={((props.label as unknown) as TLabel).avatar.name}
          size="lg"
          userId={((props.label as unknown) as TLabel).id}
          tooltip={true}
        />
        <HorizontalSpacer size="8px" />
        <Body2>{((props.label as unknown) as TLabel).name}</Body2>
      </AvatarWrapper>
    </SelectOption>
  );
};

export const UserMultiSelect = forwardRef(
  (
    {options, onChange, value, ...rest}: any,
    ref: Ref<ReactSelect<TOption>>,
  ) => {
    const styles = useMemo(() => getStyles<TOption, false>(), []);

    return (
      <ReactSelect
        {...rest}
        isMulti
        isClearable={false}
        backspaceRemovesValue={false}
        controlShouldRenderValue={false}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        menuIsOpen
        filterOption={filterUserSelectOptions}
        isSearchable
        value={value}
        noOptionsMessage={() => `No member found`}
        tabSelectsValue={false}
        placeholder="Search for team members"
        onChange={onChange}
        ref={ref}
        maxMenuHeight={240}
        components={{
          DropdownIndicator,
          Option,
          Control,
          IndicatorSeparator: null,
        }}
        options={options}
        styles={styles}
        theme={(theme) => ({
          ...theme,
          borderRadius: 7,
          spacing: {
            baseUnit: 6,
            controlHeight: 40,
            menuGutter: 6,
          },
        })}
      />
    );
  },
);
