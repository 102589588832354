import {initializeApp} from 'firebase/app';

import 'firebase/database';

const firebaseConfig = initializeApp({
  apiKey: 'AIzaSyBtx5bXKNtArDGf4sRoDZEGcNs51zqYrzc',
  authDomain: 'beamhq-app.firebaseapp.com',
  databaseURL:
    'https://beamhq-app-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'beamhq-app',
  storageBucket: 'beamhq-app.appspot.com',
  messagingSenderId: '259414181455',
  appId: '1:259414181455:web:06ba1f0b6e8d3ef4ba4c3a',
});
export default firebaseConfig;
