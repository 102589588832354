import {action} from 'mobx';
import {activateNotification} from '../../../ui/molecules/notification/activate-notification';
import {SetupWorkspaceFormData} from './setup-workspace-interface';
import {SetupWorkspaceRequest} from './setup-workspace-request';

export class SetupWorkspaceController {
  constructor(private readonly request: SetupWorkspaceRequest) {}

  @action
  async newWorkspaceInvite(data: SetupWorkspaceFormData, accessToken: string) {
    try {
      const responseData = await this.request.workspaceInvite(
        data,
        accessToken,
      );
      activateNotification({
        title: 'Success',
        content: 'Invitation sent',
        kind: 'success',
      });
      return responseData;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e.response.data?.message,
        kind: 'error',
      });
    }
  }

  @action
  async newWorkspaceName(data: any, accessToken: string) {
    try {
      const {...message} = await this.request.workspaceName(data, accessToken);
      localStorage.setItem('workspaceObject', JSON.stringify(message));
      return true;
    } catch (e: any) {
      if (e.response.data.message === 'Workspace with name already exist') {
        return e.response.data.message;
      } else {
        activateNotification({
          title: 'Error',
          content: e?.response?.data?.message,
          kind: 'error',
        });
        return false;
      }
    }
  }

  @action async updateUser(userData: any, userId: string, accessToken: string) {
    try {
      await this.request.updateUser(userData, userId, accessToken);

      return true;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e?.response?.data?.message,
        kind: 'error',
      });
    }
  }

  @action
  async workspaceEdit(
    id: string,
    data: any,
    accessToken: string,
    returnResult?: boolean,
  ) {
    try {
      const {...message} = await this.request.workspaceEdit(
        id,
        data,
        accessToken,
      );
      localStorage.setItem('workspaceObject', JSON.stringify(message));
      if (returnResult) {
        return message;
      }
      return true;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e?.response?.data?.message,
        kind: 'error',
      });
      if (e.response?.data?.message === 'You already have a workspace') {
        return true;
      }

      return false;
    }
  }

  @action
  async completeSetup(accessToken: string) {
    try {
      const responseData = await this.request.workspaceComplete(accessToken);
      return responseData;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e.response.data?.message,
        kind: 'error',
      });
    }
  }
}
