import {SectionCard} from '@ui/layouts/section-card';
import {Button} from '@ui/atoms/button';
import {Body1} from '@ui/atoms/typography';
import {SelectField} from '@ui/molecules/select/selectfield';
import {Label} from '@ui/atoms/label';
import {VerticalSpacer} from '@ui/atoms/spacer';

export const ReviewAdministrators = () => {
  const reviewOptions = [
    {
      label: 'All admins',
      value: 'admins',
    },
  ];
  return (
    <div>
      {' '}
      <SectionCard
        contentStyling={{padding: '24px'}}
        CustomHeaderTitle={
          <div>
            <Body1 weight="bold">Review Administrators</Body1>
          </div>
        }
        action={
          <div>
            <Button kind="secondary" disabled style={{padding: '8px 18px'}}>
              Edit
            </Button>
          </div>
        }>
        <div>
          <Label>Who can create and manage review cycles?</Label>
          <VerticalSpacer size="8px" />
          <SelectField
            options={reviewOptions}
            disabled
            defaultValue="admins"
            margin
            borderRadius="10px"
          />
        </div>
      </SectionCard>
    </div>
  );
};
