import {AnimatePresence} from 'framer-motion';
import {observer} from 'mobx-react';
import {ComponentType, memo, useCallback, useEffect, useState} from 'react';
import {Avatar} from '../../../../../../../ui/atoms/avatar';
import {ChevronDownIcon} from '../../../../../../../ui/atoms/icons';
import {VerticalSpacer} from '../../../../../../../ui/atoms/spacer';
import {Body2, Body1} from '../../../../../../../ui/atoms/typography';
import {ProgressTracker} from '../../../../../../../ui/molecules/progress-tracker';
import {TreeViewBoxKeyResults} from './treeview-box.keyresults';
import {capitalize} from '../../../../../../../utils';
import {DropdownVertical} from '@ui/molecules/dropdown-vertical';
import {goalDueDate} from '../../../../../../../utils/date';
import {useViewInsight} from '@hooks/insights';
import {GoalViewInsights} from '@ui/atoms/view-icon/view-icon';
import {FlexRow} from '@ui/style/styles';
import {CustomIconModal} from '@ui/organisms/modal/custom-icon-modal';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {FiChevronRight} from 'react-icons/fi';
import {
  useRemoveAlignmentHook,
  useRemoveCascadeKeyResult,
} from '@hooks/remove-alignment';
import {useStoreContext} from '@store/store-context';
import {useNavigate} from 'react-router';
import {cfv} from '../../../../../../../utils/framework';
import {useFetchUserGoals} from '@hooks/goals.hook';
import {GreenLine} from '@ui/atoms/icons/align-line';
import {groupController} from '../../../../../admin/admin-components/admin-people/admin-group/admin-group-controller';
import {groupRequestImpl} from '../../../../../admin/admin-components/admin-people/admin-group/admin-group-request';
import {
  TreeViewGoalsCard,
  TreeViewGoalsCardFlex,
  GoalTypeTag,
  Rule,
  TreeViewGoalsCardButton,
  TreeViewKeyResultsButton,
  Content,
} from './treeview.styles';

interface IEdit {
  alignment: boolean;
  cascade: boolean;
  goal: boolean;
  removeAlignment: boolean;
  removeCascade: boolean;
}
const getTitle = (edit: IEdit) => {
  if (edit.goal) {
    return 'Edit objective';
  } else if (edit.alignment) {
    return 'Edit objective alignment';
  } else if (edit.removeAlignment) {
    return 'Remove alignment? ';
  } else if (edit.removeCascade) {
    return ' Remove cascade?';
  } else {
    return 'Edit cascaded key result';
  }
};

const getDescription = (edit: IEdit) => {
  if (edit.goal) {
    return 'This will remove current update made on the objective progress';
  } else if (edit.alignment) {
    return 'This will remove the current update made on the parent objective from the child objective(s).';
  } else if (edit.removeAlignment) {
    return 'This will remove all the child objectives connected to this parent objective.';
  } else if (edit.removeCascade) {
    return 'This will remove all the child key results connected to this parent key result.';
  } else {
    return 'This will remove the current contribution made on the parent key result from the sub key result(s).';
  }
};

export default CustomIconModal;

export const AligneeComponent = observer(
  ({
    goal,
    isExpand,
    showSpecificAlignee,
    setShowSpecificAlignee,
    numberOfAlignedGoals,
    parentGoalId,
    parentKr,
    height,
    keyResults,
    sameParentGoals,
    updateHeight,
    kind,
    nextGoalExists,
    lastGoal,
    goalIndex,
  }: any) => {
    return (
      <TreeViewGoalsBox
        goal={goal}
        nextGoalExists={nextGoalExists}
        isExpand={isExpand}
        parentGoalId={parentGoalId}
        allGoals={keyResults}
        kind={kind}
        sameParentGoals={sameParentGoals}
        parentKr={parentKr}
        height={height}
        updateHeight={updateHeight}
        numberOfAlignedGoals={numberOfAlignedGoals}
        lastGoal={lastGoal}
        showSpecificAlignee={showSpecificAlignee}
        setShowSpecificAlignee={setShowSpecificAlignee}
        type="child"
        goalIndex={goalIndex}
      />
    );
  },
);

interface ITreeViewGoalsBox {
  goal: any;
  isExpand: boolean;
  numberOfAlignedGoals: number;
  lastGoal: boolean;
  kind?: string;
  nextGoalExists?: boolean;
  parentKr?: any[];
  height?: number;
  level?: number;
  sameParentGoals?: any[];
  allGoals: any[];
  updateHeight?: (value: number) => void;
  showSpecificAlignee: any;
  parentGoalId: string;
  setShowSpecificAlignee: any;
  AligneeComponent?: ComponentType;
  type?: string;
  goalIndex?: number;
}

export const TreeViewGoalsBox = memo(
  ({
    goal,
    goalIndex,
    isExpand,
    nextGoalExists,
    allGoals,
    parentGoalId,
    sameParentGoals,
    kind,
    lastGoal,
    height,
    parentKr,
    updateHeight,
    showSpecificAlignee,
    setShowSpecificAlignee,
    type,
  }: ITreeViewGoalsBox) => {
    const genShowAlignee = () => {
      if (showSpecificAlignee && showSpecificAlignee[goal.id]) {
        return showSpecificAlignee[goal.id]?.show;
      } else {
        return false;
      }
    };
    const {goalsState} = useStoreContext();
    const {refetch: goalsRefetch} = useFetchUserGoals();
    const {removeAlignment} = useRemoveAlignmentHook();
    const {removeCascading} = useRemoveCascadeKeyResult();

    function filterGoalsByAlignedKeyResults() {
      const keyResultIds = goal.keyResults
        .filter((result: any) => result.alignedKeyResults?.length > 0)
        .flatMap((result: any) =>
          result.alignedKeyResults.map((_kr: any) => _kr.id),
        );

      const filteredGoals = allGoals.filter((goal) =>
        goal.keyResults.some((kr: any) => keyResultIds.includes(kr.id)),
      );
      return filteredGoals;
    }

    const isKeyResultCascaded = goal.keyResults?.find(
      (keyResult: any) => keyResult.alignedKeyResults?.length > 0,
    );

    const resetModal = () => {
      setEdit({
        goal: false,
        alignment: false,
        cascade: false,
        removeAlignment: false,
        removeCascade: false,
      });
    };
    const navigate = useNavigate();

    const doShowAlignee = () => {
      const shouldUpdateHeight =
        showSpecificAlignee && showSpecificAlignee[goal.id];

      const isChild = type === 'child';

      setShowSpecificAlignee({
        ...(isChild ? showSpecificAlignee : {}),
        [goal.id]: {
          show: shouldUpdateHeight ? !showSpecificAlignee[goal.id].show : true,
        },
      });
    };

    const isGoalCascaded = kind === 'cascade';

    const [isOpen, setIsOpen] = useState<any>(
      isKeyResultCascaded || isGoalCascaded ? '1' : true,
    );

    const [group, setGroup] = useState({name: '', type: ''});

    const [edit, setEdit] = useState({
      alignment: false,
      cascade: false,
      goal: false,
      removeAlignment: false,
      removeCascade: false,
    });

    const [cascadeId, setCascadeId] = useState<any>();

    const updateEdit = (status: boolean, field: keyof typeof edit) => {
      setEdit({...edit, [field]: status});
    };

    const fetchGrpTypeAndName = useCallback(async () => {
      const grpRequest = new groupRequestImpl();
      const grpController = new groupController(grpRequest);
      const response = await grpController.newfetchSpecGroup(
        goal.group,
        'groupType',
      );
      response &&
        setGroup({
          name: response.name,
          type: response.groupType.name,
        });
    }, [goal.group]);

    useEffect(() => {
      if (goal.goalType === 'group' && !goal.hasOwnProperty('groupType')) {
        fetchGrpTypeAndName();
      }
    }, [fetchGrpTypeAndName, goal]);

    const isFirstChildOpen =
      type === 'child' && (isExpand || isOpen === '1') && nextGoalExists;

    const goalInsight = useViewInsight();

    const getIndex = (keyResult: any) => {
      const findGoal = parentKr?.findIndex((kr: any) =>
        kr.alignedKeyResults?.some((_kr: any) => _kr.id === keyResult.id),
      );
      return findGoal === -1 || !findGoal ? 0 : findGoal;
    };

    const getChildIndex = (keyResult: any) => {
      const findGoal = goal.keyResults?.findIndex((kr: any) =>
        kr.alignedKeyResults?.some(
          (_kr: any) => _kr.keyResultAlignment?.keyResult === keyResult.id,
        ),
      );
      return findGoal === -1 || !findGoal ? 0 : findGoal;
    };

    const calcLineHeight = (id: string) => {
      const count = {
        goal: 0,
        kr: 0,
      };
      let stop = false;

      sameParentGoals?.forEach((goal: any) => {
        if (!stop) {
          count.goal += 1;
          goal.keyResults.forEach((kr: any) => {
            if (!stop) {
              if (kr.id === id) {
                stop = true;
                return;
              } else {
                count.kr += 1;
              }
            }
          });
        }
      });
      return count;
    };

    return (
      <div
        style={{
          display: 'flex',
        }}>
        <div
          style={{
            display: 'flex',
            position: 'relative',
            marginRight: 40,
            height: 'fit-content',
            marginBottom: 20,
          }}>
          {isFirstChildOpen && (
            <Rule
              bg={isGoalCascaded ? '#084B8A' : '#1A9E68'}
              height="100%"
              left="-14px"
              top="80px"
              style={{bottom: 0}}
            />
          )}

          {type === 'child' && goalIndex !== 0 && !isGoalCascaded && (
            <span
              style={{
                position: 'absolute',
                left: '-4.3%',
                height:
                  isOpen === '1'
                    ? 140 + Number(goal.keyResults?.length * 10) + `px`
                    : '150px',
                bottom:
                  isOpen === '1'
                    ? `${122 * (goal.keyResults?.length + 1)}px`
                    : '90px',
              }}>
              <div style={{position: 'relative', height: '100%'}}>
                <div style={{position: 'absolute', bottom: '-15px'}}>
                  <GreenLine />
                </div>

                {nextGoalExists && (
                  <Rule
                    bg={isGoalCascaded ? '#084B8A' : '#1A9E68'}
                    height="14px"
                    left="0px"
                    top="145px"
                    style={{bottom: 0}}
                  />
                )}
                <div
                  style={{
                    height: '100%',
                    width: '2px',
                    background: '#1A9E68',
                  }}
                />
                <Rule
                  bg={isGoalCascaded ? '#084B8A' : '#1A9E68'}
                  left="0px"
                  top={goalIndex === 1 ? '-12px' : '-22px'}
                />
              </div>
            </span>
          )}
          <TreeViewGoalsCard
            height={!isExpand && isOpen !== '1'}
            style={{
              zIndex: 1,
              position: 'relative',
              paddingBottom: '10px',
            }}>
            <TreeViewGoalsCardFlex>
              <GoalTypeTag>
                {goal.goalType === 'group' && goal.hasOwnProperty('groupType')
                  ? `${capitalize(goal.groupType.name)} - ${capitalize(
                      goal.groupName,
                    )}`
                  : goal.goalType === 'group' &&
                    !goal.hasOwnProperty('groupType')
                  ? `${capitalize(group.type)} - ${capitalize(group.name)}`
                  : goal.goalType === 'company'
                  ? 'Company-wide'
                  : capitalize(goal.goalType)}
              </GoalTypeTag>
              <DropdownVertical
                dropdownWrapperStyle={{
                  right: '-83%',
                  minWidth: '100%',

                  overflow: 'initial',
                }}
                menu={
                  <div>
                    <DropdownItem
                      onClick={() => {
                        updateEdit(true, 'goal');
                      }}>
                      Edit objective
                    </DropdownItem>
                    {(goal.alignedGoals.length > 0 || isKeyResultCascaded) && (
                      <>
                        <DropdownItem
                          onClick={() => {
                            updateEdit(
                              true,
                              isGoalCascaded || isKeyResultCascaded
                                ? 'cascade'
                                : 'alignment',
                            );

                            if (type !== 'child') {
                              const getAlignmentId = goal.keyResults.find(
                                (kr: any) => kr.alignedKeyResults?.length > 0,
                              )?.id;
                              setCascadeId({
                                keyResult: getAlignmentId,
                                goal: goal.id,
                              });
                              return;
                            }

                            const getId =
                              parentKr &&
                              parentKr.find(
                                (kr: any) => kr.alignedKeyResults?.length > 0,
                              )?.id;

                            const getAlignmentId = goal.keyResults.find(
                              (kr: any) => kr.keyResultAlignment?.keyResult,
                            )?.id;

                            setCascadeId({
                              keyResult: getId || getAlignmentId,
                              goal: parentGoalId,
                            });
                          }}>
                          {isGoalCascaded || isKeyResultCascaded
                            ? `Edit cascade key result`
                            : ` Edit objective alignment`}
                        </DropdownItem>

                        <DropdownItem
                          onClick={() => {
                            updateEdit(
                              true,
                              isGoalCascaded || isKeyResultCascaded
                                ? 'removeCascade'
                                : 'removeAlignment',
                            );
                          }}>
                          {isKeyResultCascaded
                            ? `Remove cascaded key result`
                            : `Remove alignment`}
                        </DropdownItem>
                      </>
                    )}

                    <DropdownItem
                      onClick={() => {
                        goalsState.setDeleteModal(
                          goal.id,
                          true,
                          goal.name,
                          '',
                          goal.alignedGoals,
                        );
                      }}>
                      Delete objective{' '}
                    </DropdownItem>
                  </div>
                }
              />
            </TreeViewGoalsCardFlex>
            <VerticalSpacer size="8px" />
            <TreeViewGoalsCardFlex>
              <span className="goalName">
                <Body2 kind="textDark" weight="semibold">
                  {goal.name.length > 30
                    ? `${goal.name.substr(0, 30)} ...`
                    : goal.name}
                </Body2>
                {goal.name && goal.name.length > 30 && (
                  <span className="tooltip">{goal.name}</span>
                )}
              </span>
              <Avatar
                tooltip={true}
                size="sm"
                isUserDeleted={!!goal.assignee?.deletedAt}
                src={goal.assignee.avatar?.url}
                name={
                  goal.assignee.firstName
                    ? `${goal.assignee.firstName} ${goal.assignee.lastName}`
                    : goal.assignee.email
                }
                userId={goal.assignee.id}
              />

              {(isKeyResultCascaded ? isOpen !== '1' : !lastGoal) && (
                <TreeViewGoalsCardButton
                  bg={isKeyResultCascaded ? '#084B8A' : '#1A9E68'}
                  onClick={() => {
                    if (isKeyResultCascaded && isOpen !== '1') {
                      setIsOpen('1');
                    }
                    doShowAlignee();
                  }}>
                  <div className="arrow">
                    {!genShowAlignee() ? (
                      isKeyResultCascaded ? (
                        isKeyResultCascaded?.alignedKeyResults?.length
                      ) : (
                        goal.alignedGoals?.length
                      )
                    ) : (
                      <FiChevronRight size="10" />
                    )}
                  </div>

                  {!lastGoal && genShowAlignee() && (
                    <span
                      style={{
                        position: 'absolute',
                        bottom: '-8px',
                        height: '100%',
                        left: '35px',
                      }}>
                      <div
                        style={{
                          position: 'relative',
                          height: '100%',
                          width: '100%',
                        }}>
                        <Rule
                          bg={
                            isGoalCascaded || isKeyResultCascaded
                              ? '#084B8A'
                              : '#1A9E68'
                          }
                          width="250px"
                          height="2px"
                          style={{left: '-20px'}}
                          top={'2px'}
                        />

                        <div style={{position: 'absolute', bottom: '-7px'}}>
                          <GreenLine
                            color={isKeyResultCascaded ? '#084B8A' : undefined}
                          />
                        </div>
                      </div>
                    </span>
                  )}
                </TreeViewGoalsCardButton>
              )}
            </TreeViewGoalsCardFlex>
            <VerticalSpacer size="8px" />
            <TreeViewGoalsCardFlex>
              <FlexRow>
                <GoalViewInsights onClick={() => goalInsight(goal.id)} />
                <ProgressTracker
                  treeView={true}
                  type={goal.performance}
                  due={goalDueDate(goal?.endDate)}
                  state={`${goal?.percentageProgress}%`}
                  status={goal?.status}
                  percent={goal?.percentageProgress}
                  endDate={goal.endDate}
                />
              </FlexRow>
            </TreeViewGoalsCardFlex>
            <VerticalSpacer size="10px" />
            <TreeViewGoalsCardFlex>
              <TreeViewKeyResultsButton
                onClick={() => {
                  if (
                    !isGoalCascaded &&
                    !(isKeyResultCascaded && genShowAlignee())
                  ) {
                    setIsOpen('1' === isOpen ? null : '1');
                  }
                }}
                isOpen={isOpen === '1' && goal.keyResults.length > 0}>
                {goal.keyResults?.length > 0 && <ChevronDownIcon />}{' '}
                {capitalize(cfv(undefined, true).k_rs)} (
                {goal.keyResults?.length})
              </TreeViewKeyResultsButton>
            </TreeViewGoalsCardFlex>

            <AnimatePresence>
              {goal.keyResults?.length > 0 && (isOpen === '1' || isExpand) ? (
                <Content
                  initial={{
                    height: '0px',
                    opacity: 0,
                    background: '#ffffff',
                    padding: '0px',
                  }}
                  animate={{
                    height: 'auto',
                    opacity: 1,
                    background: '#ffffff',
                    padding: '0px',
                  }}
                  exit={{height: '0px', opacity: 0}}
                  transition={{
                    duration: 0.3,
                  }}
                  key="goal-content">
                  <TreeViewBoxKeyResults
                    keyResults={goal.keyResults}
                    doShowAlignee={doShowAlignee}
                    goalIndex={goalIndex || 0}
                    isGoalCascaded={isGoalCascaded}
                    isKeyResultCascaded={isKeyResultCascaded}
                    getChildIndex={getChildIndex}
                    getIndex={getIndex}
                    genShowAlignee={genShowAlignee}
                    calcLineHeight={calcLineHeight}
                    type={type}
                    kind={kind}
                  />
                </Content>
              ) : null}
            </AnimatePresence>
            <VerticalSpacer size="20px" />
          </TreeViewGoalsCard>
        </div>
        {genShowAlignee() && goal.alignedGoals?.length > 0 && (
          <div>
            {goal.alignedGoals.map((alignedGoal: any, index: number) => {
              return (
                <div>
                  <AligneeComponent
                    goal={alignedGoal}
                    isExpand={isExpand}
                    keyResults={allGoals}
                    parentGoalId={goal.id}
                    nextGoalExists={!!goal.alignedGoals[index + 1]}
                    updateHeight={updateHeight}
                    numberOfAlignedGoals={alignedGoal.alignedGoals?.length ?? 0}
                    lastGoal={
                      (alignedGoal.alignedGoals?.length === 0 &&
                        !alignedGoal.keyResults.find(
                          (keyResult: any) =>
                            keyResult.alignedKeyResults?.length > 0,
                        )) ||
                      !alignedGoal.alignedGoals
                    }
                    showSpecificAlignee={showSpecificAlignee}
                    setShowSpecificAlignee={setShowSpecificAlignee}
                    goalIndex={index}
                  />
                </div>
              );
            })}
          </div>
        )}
        {genShowAlignee() && filterGoalsByAlignedKeyResults()?.length > 0 && (
          <div>
            {filterGoalsByAlignedKeyResults().map(
              (cascadeKeyResult: any, index: number) => {
                return (
                  <div>
                    <AligneeComponent
                      goal={cascadeKeyResult}
                      isExpand={isExpand}
                      height={height}
                      kind="cascade"
                      keyResults={allGoals}
                      sameParentGoals={filterGoalsByAlignedKeyResults()}
                      parentKr={goal.keyResults}
                      parentGoalId={goal.id}
                      nextGoalExists={
                        !!cascadeKeyResult.keyResults.find(
                          (kr: any) => kr.alignedKeyResults?.length > 0,
                        )
                      }
                      updateHeight={updateHeight}
                      numberOfAlignedGoals={0}
                      lastGoal={
                        !cascadeKeyResult.keyResults.find(
                          (kr: any) => kr.alignedKeyResults?.length > 0,
                        )
                      }
                      showSpecificAlignee={showSpecificAlignee}
                      setShowSpecificAlignee={setShowSpecificAlignee}
                      goalIndex={index}
                    />
                  </div>
                );
              },
            )}
          </div>
        )}

        <CustomIconModal
          open={Object.values(edit).some((edit) => edit)}
          onClose={resetModal}
          body={
            <Body1 kind="textBody" align="center">
              Click{' '}
              <span style={{fontWeight: 700, color: 'black'}}>“confirm”</span>{' '}
              to continue.
            </Body1>
          }
          onClick={() => {
            if (edit.removeAlignment) {
              removeAlignment(goal.alignedGoals).then(() => {
                resetModal();
                goalsRefetch();
              });
            }
            if (edit.removeCascade) {
              const getKr = goal.keyResults.find(
                (kr: any) => kr.alignedKeyResults?.length > 0,
              );

              removeCascading(getKr.alignedKeyResults, getKr).then(() => {
                resetModal();
                goalsRefetch();
              });
            }
            if (edit.goal) {
              navigate(`/edit-goal`, {
                replace: true,
                state: {
                  id: goal.id,
                  data: JSON.stringify(goal),
                },
              });
            }
            if (edit.alignment) {
              navigate(`/create-alignment?edit=${parentGoalId}`);
            }
            if (edit.cascade) {
              navigate(
                `/cascade-keyresult?edit=${cascadeId.keyResult}&goalId=${cascadeId.goal}`,
              );
            }
          }}
          title={getTitle(edit)}
          description={getDescription(edit)}
        />
      </div>
    );
  },
);
