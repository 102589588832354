import {CompanyCheckinsController} from '@pages/dashboard/company-legacy/company-components/company-checkins/company-checkins.controller';
import {CompanyCheckinsRequestImpl} from '@pages/dashboard/company-legacy/company-components/company-checkins/company-checkins.request';
import {AnalyticsController} from '@pages/dashboard/reporting/reporting-pages/admin-analytics/admin-analytics.controller';
import {AnalyticsRequestImpl} from '@pages/dashboard/reporting/reporting-pages/admin-analytics/admin-analytics.request';
import {useStoreContext} from '@store/store-context';
import {useDebounce} from '@utils/debounce';
import dayjs from 'dayjs';
import {useCallback, useMemo, useState} from 'react';

export const useHomePulseHook = () => {
  const {
    checkinsStore: {calendarWeeks},
  } = useStoreContext();

  const request = useMemo(() => new CompanyCheckinsRequestImpl(), []);
  const controller = useMemo(() => new CompanyCheckinsController(request), [
    request,
  ]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const updatePulse = useCallback(
    async (data: {
      pulse: {mood: string; feedback?: string};
      pulseId: string;
    }) => {
      setIsSubmitting(true);

      await controller.updateCheckinsPulseResponse(
        {
          feedback: data?.pulse?.feedback ? data.pulse.feedback : undefined,
          mood: data.pulse.mood !== null ? data.pulse.mood : undefined,
        },
        data.pulseId,
      );
      setIsSubmitting(false);
    },
    [controller],
  );
  const debounceUpdatePulse = useDebounce(updatePulse, 500);

  const initWorkspaceCheckins = useCallback(
    async (filter?: {weekOfYear?: number; year?: number}) => {
      const response = await controller.getCurrentPulseInfo(filter);

      return response;
    },
    [controller],
  );

  const initFilter = useMemo(() => {
    return {
      weekOfYear: calendarWeeks?.[calendarWeeks?.length - 1]?.weekOfYear,

      year: calendarWeeks?.[calendarWeeks?.length - 1]?.year,
    };
  }, [calendarWeeks]);

  const getPulseReport = useCallback(async (filters: any) => {
    const request = new AnalyticsRequestImpl();
    const controller = new AnalyticsController(request);
    try {
      const response = await controller.fetchReportPulse(filters);

      if (response) {
        const prevResponse = await controller.fetchReportPulse({
          ...filters,
          endDate: dayjs().subtract(14, 'days').format('MM/DD/YYYY'),
        });

        const computeResponse = {
          ...response.summary,
          submittedProgress:
            response.summary.submittedCheckinsPercent -
            prevResponse.summary.submittedCheckinsPercent,
          elatedProgress: response.summary.elated - prevResponse.summary.elated,
          mehProgress: response.summary['meh!'] - prevResponse.summary['meh!'],
          sadProgress: response.summary.sad - prevResponse.summary.sad,
          drainedProgress:
            response.summary.drained - prevResponse.summary.drained,
          happyProgress: response.summary.happy - prevResponse.summary.happy,
        };
        return computeResponse;
      }
    } catch {}
  }, []);

  return {
    initFilter,
    initWorkspaceCheckins,
    getPulseReport,
    updatePulse,
    debounceUpdatePulse,
    isSubmitting,
  };
};
