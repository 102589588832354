import styled from 'styled-components';
import {QuestionMarkIcon} from '@ui/atoms/icons/question-mark-icon';
import {FlexRow, FlexRowSpaceBetween} from '../../../ui/style/styles';
import {ChatIcon} from '@ui/atoms/icons/chat';
import {VerticalSpacer} from '../../../ui/atoms/spacer';
import {Body1, Body2} from '../../../ui/atoms/typography';
import {useState} from 'react';

const Wrapper = styled.div`
  border-top: 1px solid #e1e1ea;
  height: 30.5%;
  margin: 18px 16px;
  padding-top: 24px;

  .communication {
    padding: 8px 16px;
    background: #ededf2;
    border-radius: 100px;
    height: 42px;
    width: 100%;

    svg {
      width: 26px;
      height: 26px;
      margin-right: 10px;
    }
  }
`;
declare const window: any;
export default function TipsCard() {
  return (
    <Wrapper>
      <Body1 weight="bold">Talk to an expert</Body1>
      <VerticalSpacer size="8px" />

      <Body2 kind="textBody" style={{lineHeight: '22px'}}>
        Our consulting expert will provide free training and support you need
        for every step of your strategy execution journey.
      </Body2>
      <VerticalSpacer size="16px" />
      <button
        style={{width: '100%'}}
        onClick={() => {
          window.Calendly.initPopupWidget({
            url: 'https://calendly.com/beam-demo/sessions',
          });
          return false;
        }}>
        <div className="communication">
          <FlexRow className="gap-2">
            <ChatIcon />
            <Body2 weight={'bold'}>Schedule a call</Body2>
          </FlexRow>
        </div>
      </button>
    </Wrapper>
  );
}

export function HelpCenter() {
  return (
    <Wrapper>
      <Body1 weight="bold">Help us Improve Beam</Body1>
      <VerticalSpacer size="8px" />

      <Body2 kind="textBody" style={{lineHeight: '22px'}}>
        Book a 1-on-1 with our product team to share feedback and provide
        valuable insights.
      </Body2>
      <VerticalSpacer size="16px" />
      <button
        style={{width: '100%'}}
        onClick={() => {
          window.Calendly.initPopupWidget({
            url: 'https://calendly.com/beam-demo/sessions',
          });
          return false;
        }}>
        <div className="communication">
          <FlexRow className="gap-2">
            <ChatIcon />
            <Body2 weight={'bold'}>Get in touch</Body2>
          </FlexRow>
        </div>
      </button>
    </Wrapper>
  );
}
export function ResourcesGuides() {
  const [isDismissed] = useState(false);

  if (isDismissed) {
    return null;
  }
  return (
    <Wrapper>
      <FlexRowSpaceBetween>
        <Body1 weight="bold">How-To guides and Resources</Body1>
      </FlexRowSpaceBetween>
      <VerticalSpacer size="8px" />

      <Body2 kind="textBody" style={{lineHeight: '22px'}}>
        Read useful tips and strategies on how to improve your people management
        process.
      </Body2>
      <VerticalSpacer size="16px" />

      <button
        className="w-full mt-4"
        onClick={() => {
          window.open('https://peoplebeam.myhcpage.com/en-us/');
        }}>
        <div className="communication">
          <FlexRow className="gap-2">
            <QuestionMarkIcon />
            <Body2 weight={'bold'}>Help center</Body2>
          </FlexRow>
        </div>
      </button>
    </Wrapper>
  );
}
