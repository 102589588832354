import {useStoreContext} from '@store/store-context';
import {FeedbackController} from '@pages/feedback/feedback-controller';
import {useMemo, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {FeedbackRequestImpl} from '@pages/feedback/feedback-requests';
import {IOptions} from '@pages/feedback/components/values-themes-filter';
import {useParams} from 'react-router';

export const useViewUserFeedbackHook = () => {
  const {id} = useParams();

  const {
    usersStore: {users},
  } = useStoreContext();
  const request = new FeedbackRequestImpl();
  const [searchParams] = useSearchParams();

  const startDate = searchParams.get('startDate');
  const endDate = searchParams.get('endDate');
  const period = searchParams.get('period');
  const [archive, setArchive] = useState(false);

  const user = users.find((_user) => _user.id === id);

  const controller = new FeedbackController(request);

  const [valueThemeFilter, setValueThemeFilter] = useState<IOptions>({
    filterBy: '',
    theme: '',
    value: '',
  });

  const filters = useMemo(() => {
    return {
      startDate: startDate,
      archive: archive,
      endDate: endDate,
      impression: valueThemeFilter.theme,
      values: valueThemeFilter.value,
    };
  }, [startDate, endDate, valueThemeFilter, archive]);

  return {
    request,
    filters,
    valueThemeFilter,
    user,
    period,
    setArchive,
    archive,
    startDate: startDate || '',
    endDate: endDate || '',
    setValueThemeFilter,
    id,
    controller,
  };
};
