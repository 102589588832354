import {AnimateSharedLayout} from 'framer-motion';
import {
  memo,
  SyntheticEvent,
  ReactNode,
  useRef,
  useState,
  forwardRef,
  useMemo,
  useEffect,
  Fragment,
} from 'react';

import {NavWrapper, Wrapper, MobileWrapper} from './style';
import {ChevronDownIcon} from '@ui/atoms/icons';
import {down} from 'styled-breakpoints';
import {FlexRow} from '@ui/style/styles';
import {useDropDown} from '@hooks';
import {switchProp} from 'styled-tools';
import {NavLink} from 'react-router-dom';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import styled from 'styled-components';
import {SideNav, MobileSideNav} from '../../atoms/side-nav';

interface NavigationOption {
  href: string;
  title: string;
  icon?: ReactNode;
  iconPosition?: 'left' | 'right';
  iconSpacing?: string;
  type?: string;
  active: boolean;
  variant?: string;
  subActive?: boolean;
  showSubNavigation?: boolean;
  subNavigation?: any[];
  disable?: boolean;
  disableHref?: boolean;
  id: string;
  indent?: string;
  onClick: (e: SyntheticEvent<HTMLAnchorElement>) => void;
}

export interface TProps {
  navigations: NavigationOption[];
  type?: string;
  iconSpacing?: 'flex-start' | 'center' | 'space-between';
  variant?: string;
}

const NavLg = styled.div<{type?: string}>`
  display: block;
  height: ${(props: any) => (props.type === 'workspace' ? '100%' : '')};

  ${down('sm')} {
    display: none;
  }
  z-index: 1;
`;

const NavSm = styled.div`
  display: none;
  ${down('sm')} {
    display: block;
  }
`;

const DropdownCustomWrapper = styled.div`
  background: #ffffff;
  position: absolute;

  right: 0;
  border: 1px solid #cecede;
  padding: 8px;
  z-index: 999999;
  box-shadow: 0px 2px 5px rgba(172, 172, 190, 0.24);
  border-radius: 7px;
`;

const SubNavTitle = styled(FlexRow)<{
  iconSpacing?: string;
  iconPosition?: string;
}>`
  width: ${switchProp('iconSpacing', {
    'space-between': '100%',
    default: 'fit-content',
  })};

  flex-direction: ${switchProp('iconPosition', {
    left: 'row-reverse',
    default: 'row',
  })};

  justify-content: ${({iconSpacing}) => iconSpacing || 'space-between'};

  align-items: center;
  .icon {
    order: ${switchProp('iconPosition', {
      left: 0,
      default: 1,
    })};
    margin: ${switchProp('iconPosition', {
      left: '4px 6px 0px 0px',
      default: '4px 6px 0px 6px',
    })};

    margin-left: ${({iconPosition}) => (iconPosition ? undefined : '5px')};
  }
`;

export const SideNavigation = memo(function ({
  navigations,
  type,
  iconSpacing,
  variant,
}: TProps) {
  return (
    <>
      <NavLg type={type}>
        <Wrapper type={type}>
          <AnimateSharedLayout>
            <NavWrapper type={type}>
              {navigations.map((item) => (
                <Fragment key={item.id}>
                  <SideNav
                    type={item.type}
                    href={item.href}
                    active={item.active}
                    variant={variant}
                    disable={item.disable}
                    ivariant={item.variant}
                    subActive={item.subActive}
                    disableHref={item.disableHref}
                    key={item.id}
                    onClick={item.onClick}
                    indent={item?.indent}>
                    <SubNavTitle
                      iconSpacing={item.iconSpacing || iconSpacing}
                      iconPosition={item.iconPosition}>
                      {item.title}
                      <span className="icon">{item.icon}</span>
                    </SubNavTitle>
                  </SideNav>
                  {item.subNavigation &&
                    (item.showSubNavigation ?? item.active) &&
                    item.subNavigation.map((nav) => (
                      <>
                        <SideNav
                          type={nav.type}
                          href={nav.href}
                          active={nav.active}
                          variant={variant}
                          disable={nav.disable}
                          ivariant={nav.variant}
                          disableHref={nav.disableHref}
                          subActive={nav.subActive}
                          key={nav.id}
                          onClick={nav.onClick}
                          indent={nav?.indent}>
                          <SubNavTitle
                            iconSpacing={nav.iconSpacing}
                            iconPosition={nav.iconPosition}>
                            {nav.title}
                            <span className="icon">{nav.icon}</span>
                          </SubNavTitle>
                        </SideNav>
                      </>
                    ))}
                </Fragment>
              ))}
            </NavWrapper>
          </AnimateSharedLayout>
        </Wrapper>
      </NavLg>
      <NavSm>
        <MobileNavigation navigations={navigations} />
      </NavSm>
    </>
  );
});

SideNavigation.displayName = 'SideNavigation';

export const MobileNavigation = memo(function ({navigations, variant}: TProps) {
  const myRef = useRef<HTMLDivElement>(null);

  const [scrollLeft, setScrollLeft] = useState(0);

  const [hasRightOverflow, setHasRightOverflow] = useState(false);

  useEffect(() => {
    if (myRef.current) {
      myRef.current.scrollLeft = scrollLeft;
      if (scrollLeft + myRef.current.offsetWidth < myRef.current.scrollWidth) {
        setHasRightOverflow(true);
      } else {
        setHasRightOverflow(false);
      }
    }
  }, [scrollLeft, myRef]);

  const hasLeftOverflow = useMemo(() => {
    if (myRef.current) {
      return scrollLeft > 0;
    }
  }, [myRef, scrollLeft]);

  const handleScrollRight = () => {
    if (myRef.current) setScrollLeft(myRef.current.scrollWidth);
  };

  const handleScrollLeft = () => {
    setScrollLeft(0);
  };

  const {handleOpen, open, ref, handleClose} = useDropDown();
  const [title, setTitle] = useState('');

  return (
    <MobileWrapper open={open}>
      {hasLeftOverflow && (
        <div className="leftOverflow" onClick={() => handleScrollLeft()}>
          <ChevronDownIcon />
        </div>
      )}
      <div className="navWrapper" ref={myRef}>
        {navigations.map((item, idx) => (
          <div className="itemWrapper" key={idx}>
            <MobileNav
              item={item}
              variant={variant}
              key={idx}
              open={open}
              ref={ref}
              selectedTitle={title}
              updateTitle={(value: string) => setTitle(value)}
              handleClose={handleClose}
              handleOpen={handleOpen}
            />
          </div>
        ))}
      </div>
      {hasRightOverflow && (
        <div className="rightOverflow" onClick={() => handleScrollRight()}>
          <ChevronDownIcon />
        </div>
      )}
    </MobileWrapper>
  );
});

interface MobileNavProps {
  item: NavigationOption;
  variant?: string;
  open: boolean;
  selectedTitle: string;
  updateTitle: (value: string) => void;
  handleClose: () => void;
  handleOpen: (value: any) => void;
}
const MobileNav = forwardRef(
  (
    {
      item,
      variant,
      handleOpen,
      open,
      selectedTitle,
      updateTitle,
      handleClose,
    }: MobileNavProps,
    ref: any,
  ) => {
    const [selectedNav, setOpenNav] = useState('');

    return (
      <>
        <MobileSideNav
          type={item.type}
          href={item.href}
          active={item.active}
          variant={variant}
          disable={item.disable}
          ivariant={item.variant}
          subActive={item.subActive}
          key={item.id}
          onClick={
            item.subNavigation
              ? (e) => {
                  handleOpen(e as any);
                  updateTitle(item.title);
                }
              : item.onClick
          }
          indent={item?.indent}>
          {selectedNav || item.title}
          {item.icon && (
            <span style={{margin: '0px 0px 0px 12px'}}>{item?.icon}</span>
          )}
        </MobileSideNav>
        {open && item.subNavigation && selectedTitle === item.title && (
          <DropdownCustomWrapper onClick={handleClose} ref={ref}>
            <div style={{width: '180px'}}>
              {item.subNavigation &&
                item.subNavigation.map((nav) => (
                  <NavLink
                    key={nav.id}
                    to={nav.href ?? '#'}
                    onClick={() => {
                      nav.onClick();
                      setOpenNav(nav.title);
                    }}>
                    <DropdownItem>{nav.title}</DropdownItem>
                  </NavLink>
                ))}
            </div>
          </DropdownCustomWrapper>
        )}
      </>
    );
  },
);

export const SideNavigation2 = memo(function ({
  navigations,
  type,
  variant,
}: TProps) {
  return (
    <>
      <NavLg>
        <Wrapper>
          <AnimateSharedLayout>
            <NavWrapper type={type}>
              {navigations.map((item) => (
                <SideNav
                  style={{marginBottom: '10px'}}
                  type={item.type}
                  href={item.href}
                  variant={variant}
                  active={item.active}
                  disable={item.disable}
                  subActive={item.subActive}
                  key={item.id}
                  onClick={item.onClick}
                  indent={item?.indent}>
                  {item.title}
                  {item?.icon}
                </SideNav>
              ))}
            </NavWrapper>
          </AnimateSharedLayout>
        </Wrapper>
      </NavLg>
      <NavSm>
        <MobileNavigation navigations={navigations} />
      </NavSm>
    </>
  );
});

SideNavigation2.displayName = 'SideNavigation2';
