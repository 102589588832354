import {memo, ReactNode, SyntheticEvent, useMemo} from 'react';
import styled from 'styled-components';
import {FlexRow} from '@ui/style/styles';
import {getColorFromTheme} from '../../ui-utils';
import {Typography} from '../../atoms/typography/typography';
import {ArrowHeadRightIcon} from '../../atoms/icons/arrow-head-right';

interface TDropItem {
  item?: JSX.Element;
  title: string;
}

interface NavItemProps {
  id: string;
  title: string;
  href?: string;
  icon?: ReactNode;
  onClick?: (e: SyntheticEvent<HTMLAnchorElement | HTMLButtonElement>) => void;
  item?: JSX.Element;
  disabled?: boolean;
}

export interface NavLinksProps {
  items: NavItemProps[];
}

const ItemWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const Link = styled.a`
  ${Typography};
  font-size: 14px;
  font-weight: 500;
  line-height: 1.7;
  width: 100%;
  display: inline-block;
  padding: 8px 24px;
  border: none;
  background: none;
  transition: color ease-in-out 300ms;
  color: ${getColorFromTheme('textBody')};
  cursor: pointer;

  &:hover,
  &:focus,
  &:focus-within {
    color: ${getColorFromTheme('textDark')};
    background-color: ${getColorFromTheme('backgroundDark')};
    outline: none;
    box-shadow: none;
  }
`;

export const Action = styled.button`
  ${Typography};
  font-size: 14px;
  font-weight: 500;
  line-height: 1.7;
  width: 100%;
  display: inline-block;
  padding: 8px 24px;
  border: none;
  background: none;
  transition: color ease-in-out 300ms;
  color: ${getColorFromTheme('textBody')};
  cursor: pointer;

  &:hover,
  &:focus,
  &:focus-within {
    color: ${getColorFromTheme('textDark')};
    background-color: ${getColorFromTheme('backgroundDark')};
  }
`;

export const DropdownContainer = styled.div`
  clear: both;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  transition: all 0.3s ease;
  display: none;
  float: none;
  min-width: 220px;
  z-index: 3;
`;

export const DropContainer = styled.div`
  ${Typography};
  font-size: 14px;
  font-weight: 500;
  line-height: 1.7;
  width: 100%;
  display: inline-block;
  position: relative;
  padding: 8px 24px;
  border: none;
  background: transparent;
  transition: color ease-in-out 300ms;
  color: ${getColorFromTheme('textBody')};
  cursor: pointer;
  float: none;
  appearance: none;
  -webkit-appearance: none;

  &:hover,
  &:focus,
  &:focus-within {
    color: ${getColorFromTheme('textDark')};
    background-color: ${getColorFromTheme('backgroundDark')};
  }

  &:hover
    > ${DropdownContainer},
    &:focus-within
    > ${DropdownContainer},
    > ${DropdownContainer}:hover {
    visibility: visible;
    opacity: 1;
    display: block;
    left: 100%;
    top: 0;
  }
`;

export const DropdownItem = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const DropdownIcon = styled.span`
  svg {
    width: 16px;
    height: 16px;
    stroke: ${getColorFromTheme('textDark')};
  }
`;

const DropItem = memo(function (props: TDropItem) {
  return (
    <DropContainer tabIndex={0} role="button">
      <DropdownItem aria-haspopup="true">
        <span>{props.title}</span>
        <DropdownIcon>
          <ArrowHeadRightIcon />
        </DropdownIcon>
      </DropdownItem>
      <DropdownContainer aria-label="submenu">{props.item}</DropdownContainer>
    </DropContainer>
  );
});

const NavItem = memo(function ({
  item,
  href,
  icon,
  onClick,
  title,
  disabled,
}: NavItemProps) {
  const renderItem = useMemo(() => {
    if (item) {
      return <DropItem item={item} title={title} />;
    }
    if (href) {
      return (
        <Link href={href} onClick={onClick}>
          {title}
        </Link>
      );
    }
    return (
      <Action type="button" onClick={onClick} disabled={disabled}>
        <FlexRow>
          {icon && <span style={{margin: '3px 2px 0px 0px '}}>{icon}</span>}{' '}
          {title}
        </FlexRow>
      </Action>
    );
  }, [item, href, onClick, disabled, title, icon]);
  return <ItemWrapper children={renderItem} />;
});

export const NavLinks = memo(function ({items}: NavLinksProps) {
  return (
    <Wrapper>
      {items.map((item) => (
        <NavItem key={item.id} {...item} />
      ))}
    </Wrapper>
  );
});
