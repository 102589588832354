import * as React from 'react';
import {ThemeProvider} from 'styled-components';
import {GlobalStyle} from './global';
import {theme} from './theme';

export const StyleProvider = ({children}: {children: React.ReactNode}) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    {children}
  </ThemeProvider>
);
