import {usePeriodHook} from '@hooks/period-hook';
import {AnalyticsController} from '@pages/dashboard/reporting/reporting-pages/admin-analytics/admin-analytics.controller';
import {AnalyticsRequestImpl} from '@pages/dashboard/reporting/reporting-pages/admin-analytics/admin-analytics.request';
import {useStoreContext} from '@store/store-context';
import {GoalsAnalytics} from '@ui/organisms/goal/analytics';
import {AnalyticsTodayDate} from '@utils/date';
import dayjs from 'dayjs';
import {memo, useCallback, useMemo, useState} from 'react';
import {useQuery} from 'react-query';
import {useNavigate} from 'react-router';

export const GoalsWidget = memo(() => {
  const {
    authStore: {auth},
    usersStore: {users: allUsers},
  } = useStoreContext();

  const {isPeriodEnabled, currentMonthRange} = usePeriodHook();

  const getPeriod = useMemo(() => {
    if (isPeriodEnabled && currentMonthRange().starts) {
      return {
        starts: currentMonthRange().starts,
        ends: currentMonthRange().ends,
        period: currentMonthRange().period,
      };
    }

    return {
      starts: AnalyticsTodayDate().AnalyticsDefaultStartDate,
      ends: AnalyticsTodayDate().AnalyticsDefaultEndDate,
    };
  }, [isPeriodEnabled, currentMonthRange]);

  const fetchSpecifiedGoals = useCallback(
    async (filters: any, view?: string) => {
      const request = new AnalyticsRequestImpl();
      const controller = new AnalyticsController(request);

      const response = await controller.fetchSpecifiedGoals(
        filters,
        view,
        true,
      );
      if (response) {
        const prevResponse = await controller.fetchSpecifiedGoals(
          {
            ...filters,
            endDate: dayjs().subtract(14, 'days').format('MM/DD/YYYY'),
          },
          view,
          true,
        );

        const computeResponse = {
          ...response.summary,
          onTrackProgress:
            response.summary.onTrackPercent -
            prevResponse.summary.onTrackPercent,
          fulfillmentProgress:
            response.summary.goals?.fulfillment -
            prevResponse.summary.goals?.fulfillment,
          behindProgress:
            response.summary.behindPercent - prevResponse.summary.behindPercent,
          atRiskProgress:
            response.summary.atRiskPercent - prevResponse.summary.atRiskPercent,
        };
        return computeResponse;
      }
    },
    [],
  );

  const [activeTab, setActiveTab] = useState<'you' | 'team' | 'company'>('you');

  const {data, isLoading} = useQuery(
    ['goal-widget', getPeriod, activeTab],
    () =>
      fetchSpecifiedGoals({
        startDate: getPeriod?.starts,
        endDate: getPeriod?.ends,
        removeKpiFilter: true,
        newReport: true,
        manager: activeTab === 'team' ? auth.user.id : undefined,
        member:
          activeTab === 'you'
            ? auth.user.id
            : activeTab === 'company'
            ? allUsers.map((user) => user.id).join(',')
            : undefined,
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  const navigate = useNavigate();

  return (
    <div>
      <GoalsAnalytics
        showNav={auth.user.role === 'admin' || auth.user.isReviewer}
        showCompany={auth.user.role === 'admin'}
        showTeam={auth.user.isReviewer}
        viewFooterText="goals"
        activeTab={activeTab}
        handleContinue={() => navigate('/goals')}
        isLoading={isLoading}
        updateActiveTab={(value) => setActiveTab(value)}
        summary={data}
      />
    </div>
  );
});
