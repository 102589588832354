import React, {useMemo, useState} from 'react';
import {DropdownCustom} from '@ui/molecules/dropdown-custom';
import {ArrowHeadDownIcon} from '@ui/atoms/icons';
import {Body2, Body1, Headline3} from '@ui/atoms/typography';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {Button} from '@ui/atoms/button';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {useStoreContext} from '@store/store-context';
import {FlexRowSpaceBetween} from '@ui/style/styles';
import {HRule} from '../style';
import styled from 'styled-components';
import {DrawerModal} from '@ui/organisms/modal/drawer-modal';
import {ReviewFrameworkProps} from '@api/cc-api/cc-controller';
import {PlainButton} from '@ui/atoms/plain-button/plain-button';
import {capitalize} from '@utils';
import {ValueProps} from '../../create-review-interface';
import {SecondaryNavigation} from '@ui/molecules/nav/navigation';

const WidgetWrapper = styled.div`
  border-radius: 10px;
  border: 1px solid var(--border-light, #ededf2);
  padding: 16px;
`;

export const SavedWrapper = styled.div`
  border: 1px solid #ededf2;
  border-radius: 10px;

  .main-wrapper {
    padding: 12px 16px;
    cursor: pointer;
  }
  .expand-wrapper {
    padding: 16px;
    border-top: 1px solid #ededf2;
    .key-areas {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      justify-content: space-between;
      .weigh {
        border: 1px solid #ededf2;
        border-radius: 6px;
        padding: 4px 8px;
      }
    }
  }
`;
const ManagerAssessmentQuestions = styled.div`
  border-radius: 10px;
  background: var(--background-dark, #f6f6f8);
  padding: 14px 16px;
`;
interface IProps {
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  reviewCycleType: ValueProps['reviewType'];

  frameworks: ReviewFrameworkProps;
}
export const ViewFrameworkModal = ({
  openModal,
  reviewCycleType,
  setOpenModal,
  frameworks,
}: IProps) => {
  const {
    groupStore: {groups},
    authStore: {auth},
  } = useStoreContext();

  const groupDropdown = frameworks?.map((framework) => {
    if (framework.allGroups) {
      return {
        ...framework,
        group: {
          label: 'All groups',
          value: framework.id,
        },
      };
    }
    return {
      ...framework,
      group: {
        label: groups.find((group) => group.id === framework.groups[0])?.name,
        value: framework.id,
      },
    };
  });

  const [cycleTypeView, setCycleTypeView] = useState('self');

  const cycleTypeNavigation = useMemo(() => {
    const nav = [
      {
        active: cycleTypeView === 'self',
        href: '#',
        id: '1',
        onClick: (e: {preventDefault: () => void}) => {
          e.preventDefault();
          setCycleTypeView('self');
        },
        title: 'Self & Manager questions',
      },
      {
        active: cycleTypeView === 'peer',
        href: '#',
        id: '2',
        onClick: (e: {preventDefault: () => void}) => {
          e.preventDefault();
          setCycleTypeView('peer');
        },
        title: 'Peer questions',
      },
    ];
    return nav;
  }, [cycleTypeView]);

  const [selectedFramework, setSelectedFramework] = useState(
    groupDropdown?.[0],
  );

  const companyValues: string[] =
    auth?.user?.workspace?.settings?.companyValues?.values || [];

  const [expandValues, setExpandValues] = useState(false);

  const [expandKeyAreas, setExpandKeyAreas] = useState(false);

  return (
    <div>
      {' '}
      <DrawerModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        title="C&C Framework">
        <>
          <HRule />
          <FlexRowSpaceBetween>
            <Body1 kind="textBody">Select a group to view questions.</Body1>
            <DropdownCustom
              collapseOnClick={true}
              menu={(handleClose: () => void) => (
                <div>
                  <div>
                    {groupDropdown?.map((option, idx) => (
                      <DropdownItem
                        key={idx}
                        onClick={() => {
                          handleClose();
                          setSelectedFramework(option);
                        }}>
                        {option.group.label}
                      </DropdownItem>
                    ))}
                  </div>
                </div>
              )}
              customComponent={(
                handleOpen: (event: any) => void,
                open: boolean,
              ) => (
                <Button
                  onClick={handleOpen}
                  width={'sm'}
                  kind="secondary"
                  style={{
                    padding: '14px 16px',
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <Body2 weight="semibold">
                    {selectedFramework?.group.label}
                  </Body2>
                  <ArrowHeadDownIcon
                    style={{
                      marginLeft: 14,
                      stroke: '#1E1E2F',
                      transform: !open ? 'rotate(0deg)' : 'rotate(180deg)',
                    }}
                  />
                </Button>
              )}
              customDropdownWrapperStyles={{
                top: '130%',
                right: 0,
                width: '210px',
              }}
            />
          </FlexRowSpaceBetween>
          <VerticalSpacer size="20px" />
          <WidgetWrapper>
            <FlexRowSpaceBetween>
              <Headline3>Values</Headline3>
              <PlainButton onClick={() => setExpandValues(!expandValues)}>
                <Body2 kind="purple300" weight="bold">
                  {expandValues ? 'Collapse' : 'Expand'}
                </Body2>
              </PlainButton>
            </FlexRowSpaceBetween>
            {expandValues && (
              <>
                <VerticalSpacer size="14px" />
                {reviewCycleType === '360' && (
                  <SecondaryNavigation
                    kind="details"
                    navStyle={{
                      width: 'max-content',
                      padding: '4px 8px',
                      height: '38px',
                    }}
                    navigations={cycleTypeNavigation}
                  />
                )}
                <VerticalSpacer size="14px" />
                <div className="values">
                  {companyValues.map((value, idx) => (
                    <ValueItem
                      key={value}
                      viewQuestion={cycleTypeView}
                      value={value}
                      lastItem={idx === companyValues.length - 1}
                      idx={idx}
                    />
                  ))}
                </div>
              </>
            )}
          </WidgetWrapper>
          <VerticalSpacer size="32px" />
          <WidgetWrapper>
            <FlexRowSpaceBetween>
              <Headline3>Key Areas</Headline3>
              <PlainButton onClick={() => setExpandKeyAreas(!expandKeyAreas)}>
                <Body2 kind="purple300" weight="bold">
                  {expandValues ? 'Collapse' : 'Expand'}
                </Body2>
              </PlainButton>
            </FlexRowSpaceBetween>

            {expandKeyAreas && (
              <>
                <VerticalSpacer size="14px" />

                {selectedFramework?.competenceMetrics.map(
                  (competence, idx, allCompetence) => (
                    <div key={competence.name}>
                      <KeyAreas
                        competenceMetrics={competence}
                        lastItem={idx === allCompetence.length - 1}
                        idx={idx}
                      />
                    </div>
                  ),
                )}
              </>
            )}
          </WidgetWrapper>
          <VerticalSpacer size="32px" />
          {selectedFramework?.managerQuestion && (
            <WidgetWrapper>
              <Body2 weight="bold">Manager overall assessment</Body2>
              <VerticalSpacer size="14px" />
              <ManagerAssessmentQuestions>
                {selectedFramework?.managerQuestion}
              </ManagerAssessmentQuestions>
            </WidgetWrapper>
          )}
        </>
      </DrawerModal>
    </div>
  );
};
type KeyAreasProps = {
  competenceMetrics: {
    name: string;
    totalValue: number;
    components: {
      name: string;
      score: number;
    }[];
  };
  idx: number;

  lastItem: boolean;
};
const KeyAreas = ({
  competenceMetrics: metric,
  idx,
  lastItem,
}: KeyAreasProps) => {
  const [showQuestions, setShowQuestions] = useState(false);

  return (
    <>
      <Wrapper
        openedStyle={showQuestions}
        firstItem={idx === 0}
        lastItem={lastItem}>
        <ValueItemWrapper
          style={{border: lastItem ? 0 : undefined}}
          showBottomBorder={showQuestions}
          onClick={() => setShowQuestions(!showQuestions)}>
          <Body2 weight="bold">{capitalize(metric.name)}</Body2>
          <ArrowHeadDownIcon
            style={{
              stroke: '#1E1E2F',
              transform: showQuestions ? 'rotate(0deg)' : 'rotate(270deg)',
            }}
          />
        </ValueItemWrapper>

        {showQuestions && (
          <>
            {metric.components.map((component, idx) => (
              <QuestionItem>
                <Body2 weight="bold">Self review</Body2>
                <VerticalSpacer size="8px" />
                <Body2 weight="bold">
                  Q{idx + 1}.{' '}
                  <span style={{color: '#5F5F8C', fontWeight: 400}}>
                    How would you rate your {component.name}?
                  </span>{' '}
                </Body2>
                <VerticalSpacer size="16px" />
                <Body2 weight="bold">Manager review</Body2>
                <VerticalSpacer size="8px" />
                <Body2 weight="bold">
                  Q{idx + 1}.{' '}
                  <span style={{color: '#5F5F8C', fontWeight: 400}}>
                    How would you rate <strong>[first name]</strong>{' '}
                    {component.name}?
                  </span>{' '}
                </Body2>
              </QuestionItem>
            ))}
          </>
        )}
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div<{
  openedStyle: boolean;
  firstItem: boolean;
  lastItem: boolean;
}>`
  border-width: ${({openedStyle, lastItem}) =>
    openedStyle || lastItem ? '1px' : '1px 1px 0px 1px'};
  margin-bottom: ${({openedStyle}) => (openedStyle ? '24px' : '0px')};
  border-color: #ededf2;
  border-style: solid;
  border-radius: ${({firstItem, lastItem, openedStyle}) =>
    openedStyle
      ? '10px'
      : lastItem
      ? '0px 0px 10px 10px'
      : firstItem
      ? '10px 10px 0px 0px'
      : ''};
`;

const ValueItemWrapper = styled(FlexRowSpaceBetween)<{
  showBottomBorder?: boolean;
}>`
  padding: 14px 16px;
  border-bottom: ${({showBottomBorder}) =>
    showBottomBorder && '1px solid #ededf2 '};
  cursor: pointer;
`;

const QuestionItem = styled.div`
  padding: 16px;

  margin-bottom: 16px;
`;

type ItemProp = {
  value: string;
  lastItem: boolean;
  idx: number;
  viewQuestion: string;
};

const ValueItem = ({value, lastItem, idx, viewQuestion}: ItemProp) => {
  const [showQuestions, setShowQuestions] = useState(false);

  return (
    <Wrapper
      openedStyle={showQuestions}
      firstItem={idx === 0}
      lastItem={lastItem}>
      <ValueItemWrapper
        style={{border: lastItem ? 0 : undefined}}
        showBottomBorder={showQuestions}
        onClick={() => setShowQuestions(!showQuestions)}>
        <Body2 weight="bold">{value}</Body2>
        <ArrowHeadDownIcon
          style={{
            stroke: '#1E1E2F',
            transform: showQuestions ? 'rotate(0deg)' : 'rotate(270deg)',
          }}
        />
      </ValueItemWrapper>
      {showQuestions && (
        <QuestionItem>
          {viewQuestion === 'peer' ? (
            <>
              <Body2 weight="bold">Peer review</Body2>
              <VerticalSpacer size="8px" />
              <Body2 weight="bold">
                Q1.{' '}
                <span style={{color: '#5F5F8C', fontWeight: 400}}>
                  How would you rate <strong>[first name]</strong> {value}?
                </span>{' '}
              </Body2>
            </>
          ) : (
            <>
              <Body2 weight="bold">Self review</Body2>
              <VerticalSpacer size="8px" />
              <Body2 weight="bold">
                Q1.{' '}
                <span style={{color: '#5F5F8C', fontWeight: 400}}>
                  How would you rate your {value}?
                </span>{' '}
              </Body2>
              <VerticalSpacer size="16px" />
              <Body2 weight="bold">Manager review</Body2>
              <VerticalSpacer size="8px" />
              <Body2 weight="bold">
                Q2.{' '}
                <span style={{color: '#5F5F8C', fontWeight: 400}}>
                  How would you rate <strong>[first name]</strong> {value}?
                </span>{' '}
              </Body2>
            </>
          )}
        </QuestionItem>
      )}
    </Wrapper>
  );
};
