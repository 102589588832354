import {memo} from 'react';
import {CSSProperties} from 'styled-components';

interface ICancelIcon {
  style?: CSSProperties;
  color?: string;
}
export const CancelIcon = memo(({style, color}: ICancelIcon) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
    style={style}>
    <path
      stroke={color ? color : '#000'}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 4l-8 8M4 4l8 8"
    />
  </svg>
));

CancelIcon.displayName = 'CancelIcon';
