import {createGlobalStyle} from 'styled-components';
import {normalize} from 'styled-normalize';
import {getColorFromTheme} from '../ui-utils';
import {reset} from './reset';

export const GlobalStyle = createGlobalStyle`
  ${normalize}

  ${reset}

  body {
    font-family: ${(props) => props.theme.fontFamily};
    margin: 0;
    padding: 0; 
    -webkit-font-smoothing: antialiased;
    text-rendering: geometricPrecision;
    height: 100%;
  }

  html {
    height: 100%;
  }

  * {
    box-sizing: border-box;
  }

  a:focus:not(.focus-visible),
  *:focus {
    outline: none;
    box-shadow: none;
  }

  a.focus-visible {
    outline: none;
    box-shadow: 0 0 0 3px ${getColorFromTheme('purple200')};
  }

 
  &::-webkit-scrollbar-track {
    background: #f6f8fa;
    border: 0.8em solid rgba(0, 0, 0, 0);
    background-clip: padding-box;

    -webkit-border-radius: 1.5em;

    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    height: 10em;
    border: 0.8em solid rgba(0, 0, 0, 0);
    background: #e5ebf1;
    border-radius: 10px;
    background-clip: padding-box;
    -webkit-border-radius: 1.5em;

    -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.025);
  }

  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;
