import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import {observer} from 'mobx-react';
import {useRef} from 'react';
import {Body2} from '@ui/atoms/typography';
import {useStoreContext} from '@store/store-context';
// import {WorkspaceLogo} from '@../../ui/atoms/workspace-logo';
import './export.css';
import {PDFExport} from '@progress/kendo-react-pdf';
import BeamLogo from '@assets/images/Beam-logo-mVN.png';
import {DropdownCustom} from '@ui/molecules/dropdown-custom';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {DropdownWrapper} from '@pages/dashboard/you/you-page.styles';
import {cfv} from '@utils/framework';
import {RotatingLines} from 'react-loader-spinner';
import {CancelIcon} from '@ui/atoms/icons';
import Doc from '@utils/pdf';

dayjs.extend(localizedFormat);

export const CompanyPDFReport = observer(
  ({
    ref,
    timeframe,
    data,
    fetchPDF,
    isloading,
    openExport,
    setFetchPDF,
    reachedLimit,
    fetchSpecifiedGoalsForExport,
    setOpenExport,
    setisLoading,
    fetchCSV,
  }: {
    ref?: any;
    timeframe?: string;
    data?: any;
    isloading?: boolean;
    setisLoading?: any;
    openExport?: any;
    setOpenExport?: any;
    setFetchPDF?: any;
    fetchSpecifiedGoalsForExport?: any;
    reachedLimit?: any;
    fetchPDF?: any;
    fetchCSV?: any;
  }) => {
    const {
      analyticsStore: {goalsData},
    } = useStoreContext();

    const bodyRef: any = useRef(null);

    const createPdf = (html: any) =>
      Doc.createPdf(html, {
        filename: `${
          data?.goalType.typeof('string') && data?.goalType?.length > 0
            ? `${data?.goalType}-`
            : ''
        }${cfv().g_oals}-report-${dayjs(data?.startDate).format(
          'DDMMYYYY',
        )}-${dayjs(data?.ends).format(
          'DDMMYYYY',
        )}-${new Date().toDateString()}-${new Date().toLocaleTimeString()}`,
      });

    const adjustWidth = (html: any) => {
      html.style.width = 600;
      return html;
    };

    return (
      <>
        {!fetchPDF?.summary && (
          <Body2
            kind={
              reachedLimit || goalsData?.goals?.length === 0
                ? 'textMuted'
                : 'green300'
            }
            onClick={() =>
              reachedLimit || goalsData?.goals?.length === 0
                ? null
                : fetchSpecifiedGoalsForExport(data)
            }
            style={{
              fontWeight: 500,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor:
                reachedLimit || goalsData?.goals?.length === 0
                  ? 'not-allowed'
                  : 'pointer',
            }}>
            <span style={{marginRight: '3px', marginTop: '2px'}}>
              {isloading === true ? ' Preparing Export ' : 'Export'}
            </span>
            {isloading && <RotatingLines width="16" strokeColor="#47B881" />}
          </Body2>
        )}

        {fetchPDF?.summary && (
          <DropdownWrapper>
            <DropdownCustom
              collapseOnClick={true}
              menu={(handleClose: () => void) => (
                <div style={{width: '180px'}}>
                  <DropdownItem
                    onClick={() => createPdf(adjustWidth(bodyRef.current))}>
                    {' '}
                    PDF
                  </DropdownItem>
                </div>
              )}
              customComponent={(
                handleOpen: (event: any) => void,
                open: boolean,
              ) => (
                <Body2
                  style={{
                    color: '#47B881',
                    fontWeight: 500,
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <span
                    onClick={() => {
                      if (bodyRef.current) {
                        bodyRef.current.save();
                      }
                    }}>
                    Download{' '}
                  </span>
                  <span
                    onClick={() => {
                      setFetchPDF({});
                    }}
                    style={{
                      display: 'flex',
                      marginLeft: '3px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginBottom: '2px',
                    }}>
                    <CancelIcon color="#47B881" />
                  </span>
                </Body2>
              )}
              customDropdownWrapperStyles={{top: '130%', right: 0}}
            />
          </DropdownWrapper>
        )}

        <body
          style={{
            width: '100% !important',
            margin: 0,
            padding: 0,
            position: 'absolute',
            left: '-100000px',
            top: 0,
          }}>
          <div>
            <PDFExport
              margin="0cm"
              fileName={`${data?.goalType !== null ? data?.goalType : ''}-${
                cfv().g_oals
              }-report-${dayjs(data?.startDate).format('DDMMYYYY')}-${dayjs(
                data?.ends,
              ).format(
                'DDMMYYYY',
              )}-${new Date().toDateString()}-${new Date().toLocaleTimeString()}`}
              paperSize={'A4'}
              scale={0.7}
              ref={bodyRef}>
              <div
                className="pc-email-body"
                style={{
                  backgroundColor: 'white',
                  tableLayout: 'fixed',
                  width: '100%',
                }}>
                <div>
                  <div>
                    <div className="pc-email-body-inner">
                      <div style={{width: '100%'}}>
                        <div>
                          <div style={{width: '100%'}}>
                            <div
                              className="pc-email-container"
                              style={{
                                margin: '0 auto',
                                maxWidth: '800px',
                                width: '100%',
                                alignContent: 'center',
                              }}>
                              <div>
                                <div>
                                  <div style={{padding: '0 10px'}}>
                                    <div style={{width: '100%'}}>
                                      <div
                                        style={{
                                          fontSize: '1px',
                                          lineHeight: '1px',
                                          height: '20',
                                        }}>
                                        &nbsp;
                                      </div>
                                    </div>
                                  </div>

                                  <div style={{width: '100%'}}>
                                    <div>
                                      <div>
                                        <div>
                                          <div style={{width: '100%'}}>
                                            <div>
                                              <div>
                                                <div
                                                  className="pc-sm-p-24-20 pc-xs-p-14-10"
                                                  style={{
                                                    padding: '29px 30px',
                                                    backgroundColor: '#ffffff',
                                                    fontSize: 0,
                                                  }}
                                                  pc-default-class="pc-sm-p-24-20 pc-xs-p-14-10"
                                                  pc-default-padding="29px 30px">
                                                  <div>
                                                    <div
                                                      style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                          'flex-start',
                                                      }}>
                                                      <div
                                                        style={{
                                                          padding: '10px',
                                                        }}>
                                                        <img
                                                          src={BeamLogo}
                                                          width="50"
                                                          height="50"
                                                          alt=""
                                                          style={{
                                                            maxWidth: '100%',
                                                            height: 'auto',
                                                            lineHeight: '100%',
                                                            outline: 0,
                                                            fontSize: '14px',
                                                            color: '#1B1B1B',
                                                          }}
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div
                                            style={{
                                              width: '100%',
                                              textAlign: 'left',
                                            }}>
                                            <div>
                                              <div>
                                                <div
                                                  style={{
                                                    padding: '10px 30px 20px',
                                                    background: '#ffffff',
                                                  }}>
                                                  <div style={{width: '100%'}}>
                                                    <div>
                                                      <div>
                                                        <div
                                                          className="pc-sm-fs-30 pc-fb-font"
                                                          style={{
                                                            fontFamily:
                                                              'Work Sans, sans-serif',
                                                            fontSize: '28px',
                                                            fontWeight: 'bold',
                                                            lineHeight: '46px',
                                                            letterSpacing:
                                                              '-0.6px',
                                                            color: '#151515',
                                                            padding: '0 10px',
                                                            textAlign: 'left',
                                                          }}>
                                                          {
                                                            fetchPDF?.summary
                                                              ?.title
                                                          }{' '}
                                                          <br />
                                                        </div>
                                                      </div>
                                                      <div>
                                                        <div
                                                          // height="15"
                                                          style={{
                                                            lineHeight: '1px',
                                                            height: 15,
                                                            fontSize: '1px',
                                                          }}>
                                                          &nbsp;
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div>
                                                      <div>
                                                        <div
                                                          className="pc-sm-fs-18 pc-xs-fs-16 pc-fb-font"
                                                          style={{
                                                            fontFamily:
                                                              'Work Sans, sans-serif',
                                                            fontSize: '16px',
                                                            lineHeight: '30px',
                                                            letterSpacing:
                                                              '-0.2px',
                                                            color: '#312f2f',
                                                            padding: '0 10px',
                                                          }}>
                                                          <span
                                                            style={{
                                                              color: '#1e1e2f',
                                                            }}>
                                                            {
                                                              fetchPDF?.summary
                                                                ?.summaryText
                                                            }
                                                            <br />
                                                          </span>
                                                          <strong>
                                                            <br></br>
                                                            <span
                                                              style={{
                                                                color:
                                                                  '#1e1e2f',
                                                              }}>
                                                              Goal Completion
                                                            </span>
                                                            <br></br>
                                                          </strong>
                                                          <span
                                                            style={{
                                                              color: '#5f5f8c',
                                                            }}>
                                                            All Goals:{' '}
                                                            <span
                                                              style={{
                                                                color:
                                                                  '#1e1e2f',
                                                              }}>
                                                              {
                                                                fetchPDF
                                                                  ?.summary
                                                                  ?.count
                                                              }
                                                            </span>{' '}
                                                          </span>
                                                          &nbsp; &nbsp; &nbsp;
                                                          <span
                                                            style={{
                                                              color: '#5f5f8c',
                                                            }}>
                                                            In progress:
                                                          </span>{' '}
                                                          <span
                                                            style={{
                                                              color: '#1e1e2f',
                                                            }}>
                                                            {
                                                              fetchPDF?.summary
                                                                ?.active
                                                            }
                                                          </span>{' '}
                                                          &nbsp;&nbsp;
                                                          <span
                                                            style={{
                                                              color: '#5f5f8c',
                                                            }}>
                                                            Ended:{' '}
                                                            <span
                                                              style={{
                                                                color:
                                                                  '#1e1e2f',
                                                              }}>
                                                              {
                                                                fetchPDF
                                                                  ?.summary
                                                                  ?.ended
                                                              }
                                                            </span>{' '}
                                                          </span>
                                                          &nbsp;&nbsp;
                                                          <span
                                                            style={{
                                                              color: '#5f5f8c',
                                                            }}>
                                                            Pastdue:{' '}
                                                            <span
                                                              style={{
                                                                color:
                                                                  '#1e1e2f',
                                                              }}>
                                                              {
                                                                fetchPDF
                                                                  ?.summary
                                                                  ?.pastDue
                                                              }
                                                            </span>{' '}
                                                          </span>
                                                          <span
                                                            style={{
                                                              color: '#9b9b9b',
                                                            }}>
                                                            <span
                                                              style={{
                                                                color:
                                                                  '#5e5e73',
                                                              }}>
                                                              <strong>
                                                                <br></br>
                                                              </strong>
                                                            </span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div>
                                                        <div
                                                          // height="18"
                                                          style={{
                                                            lineHeight: '1px',
                                                            fontSize: '1px',
                                                            height: '18',
                                                          }}>
                                                          &nbsp;
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          {fetchPDF?.goalsByAssignee !==
                                            undefined &&
                                            fetchPDF?.goalsByAssignee.map(
                                              (user: any) => (
                                                <>
                                                  {
                                                    <div
                                                      style={{
                                                        width: '100%',
                                                        textAlign: 'left',
                                                      }}>
                                                      <div>
                                                        <div>
                                                          <div
                                                            style={{
                                                              padding:
                                                                '0px 20px',
                                                              backgroundColor:
                                                                '#ffffff',
                                                            }}
                                                            pc-default-class="pc-sm-p-35-10-15 pc-xs-p-25-0-5"
                                                            pc-default-padding="40px 20px">
                                                            <div
                                                              style={{
                                                                width: '100%',
                                                              }}>
                                                              <div>
                                                                <div>
                                                                  <div
                                                                    className="pc-fb-font"
                                                                    style={{
                                                                      fontFamily:
                                                                        'Work Sans, sans-serif',
                                                                      fontSize:
                                                                        '20px',
                                                                      fontWeight: 700,
                                                                      lineHeight:
                                                                        '34px',
                                                                      letterSpacing:
                                                                        '-0.4px',
                                                                      color:
                                                                        '#1e1e2f',
                                                                      padding:
                                                                        '0 20px',
                                                                    }}>
                                                                    {user.name}
                                                                  </div>
                                                                </div>
                                                                <div>
                                                                  <div
                                                                    // height="1"
                                                                    style={{
                                                                      height: 1,
                                                                      fontSize:
                                                                        '1px',
                                                                      lineHeight:
                                                                        '1px',
                                                                    }}>
                                                                    &nbsp;
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div>
                                                                <div>
                                                                  <div
                                                                    className="pc-fb-font"
                                                                    style={{
                                                                      fontFamily:
                                                                        'Work Sans, sans-serif',
                                                                      fontSize:
                                                                        '12px',
                                                                      fontWeight: 300,
                                                                      lineHeight:
                                                                        '28px',
                                                                      letterSpacing:
                                                                        '-0.2px',
                                                                      color:
                                                                        '#9B9B9B',
                                                                      padding:
                                                                        '0 20px',
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          '#5f5f8c',
                                                                      }}>
                                                                      {
                                                                        user.jobTitle
                                                                      }
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                                <div>
                                                                  <div
                                                                    style={{
                                                                      height: 0,
                                                                      fontSize:
                                                                        '1px',
                                                                      lineHeight:
                                                                        '1px',
                                                                    }}>
                                                                    &nbsp;
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  }
                                                  {user.goals.map(
                                                    (goal: any) => (
                                                      <div
                                                        style={{
                                                          width: '100%',
                                                          textAlign: 'left',
                                                        }}>
                                                        <div>
                                                          <div
                                                            style={{
                                                              padding:
                                                                '10px 20px 0px',
                                                              background:
                                                                '#ffffff',
                                                            }}
                                                            pc-default-class="pc-sm-p-30-10 pc-xs-p-25-0"
                                                            pc-default-padding="40px 20px">
                                                            <div
                                                              style={{
                                                                width: '100%',
                                                              }}>
                                                              <div>
                                                                <div
                                                                  style={{
                                                                    fontSize: 0,
                                                                  }}>
                                                                  <div>
                                                                    <div
                                                                      style={{
                                                                        display:
                                                                          'grid',

                                                                        gridTemplateColumns:
                                                                          '1fr 25.5%',
                                                                      }}>
                                                                      <div
                                                                        style={{
                                                                          width: 500,
                                                                        }}>
                                                                        <div
                                                                          className="pc-sm-mw-100pc"
                                                                          style={{
                                                                            display:
                                                                              'inline-block',
                                                                            maxWidth:
                                                                              '500px',
                                                                            width:
                                                                              '100%',
                                                                            verticalAlign:
                                                                              'top',
                                                                          }}>
                                                                          <div
                                                                            style={{
                                                                              width:
                                                                                '100%',
                                                                            }}>
                                                                            <div>
                                                                              <div>
                                                                                <div
                                                                                  style={{
                                                                                    padding:
                                                                                      '10px 20px',
                                                                                  }}>
                                                                                  <div
                                                                                    style={{
                                                                                      width:
                                                                                        '100%',
                                                                                    }}>
                                                                                    <div>
                                                                                      <div>
                                                                                        <div
                                                                                          className="pc-fb-font"
                                                                                          style={{
                                                                                            letterSpacing:
                                                                                              '-0.2px',
                                                                                            lineHeight:
                                                                                              '26px',
                                                                                            fontFamily:
                                                                                              'Work Sans, sans-serif',
                                                                                            fontSize:
                                                                                              '16px',
                                                                                            color:
                                                                                              '#151515',
                                                                                          }}>
                                                                                          <span
                                                                                            style={{
                                                                                              color:
                                                                                                '#1e1e2f',
                                                                                            }}>
                                                                                            <strong
                                                                                              style={{
                                                                                                fontSize:
                                                                                                  '18px',
                                                                                              }}>
                                                                                              {
                                                                                                goal.name
                                                                                              }
                                                                                            </strong>
                                                                                          </span>
                                                                                          <br></br>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize:
                                                                                                '12px',
                                                                                              color:
                                                                                                '#5f5f8c',
                                                                                            }}>
                                                                                            {
                                                                                              goal.groupInfo
                                                                                            }
                                                                                            <br></br>
                                                                                          </span>
                                                                                        </div>
                                                                                      </div>
                                                                                    </div>
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                      <div
                                                                        style={{
                                                                          width:
                                                                            '500',
                                                                        }}>
                                                                        <div
                                                                          className="pc-sm-mw-100pc"
                                                                          style={{
                                                                            display:
                                                                              'inline-block',
                                                                            maxWidth:
                                                                              '500px',
                                                                            width:
                                                                              '100%',
                                                                            verticalAlign:
                                                                              'top',
                                                                          }}>
                                                                          <div
                                                                            style={{
                                                                              width:
                                                                                '100%',
                                                                            }}>
                                                                            <div>
                                                                              <div>
                                                                                <div
                                                                                  style={{
                                                                                    padding:
                                                                                      '10px 0px',
                                                                                  }}>
                                                                                  <div
                                                                                    style={{
                                                                                      width:
                                                                                        '100%',
                                                                                    }}>
                                                                                    <div
                                                                                      className="pc-fb-font"
                                                                                      style={{
                                                                                        letterSpacing:
                                                                                          '-0.2px',

                                                                                        width:
                                                                                          '200px',
                                                                                        lineHeight:
                                                                                          '26px',
                                                                                        fontFamily:
                                                                                          'Work Sans, sans-serif',
                                                                                        fontSize:
                                                                                          '14px',
                                                                                        color:
                                                                                          '#151515',
                                                                                      }}>
                                                                                      <span
                                                                                        style={{
                                                                                          color:
                                                                                            '#1e1e2f',
                                                                                        }}>
                                                                                        {
                                                                                          goal.summary
                                                                                        }
                                                                                      </span>
                                                                                    </div>
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              {goal.keyResults?.map(
                                                                (kr: any) => (
                                                                  <>
                                                                    <div>
                                                                      <div
                                                                        style={{
                                                                          height: 10,
                                                                          lineHeight:
                                                                            '1px',
                                                                          fontSize:
                                                                            '1px',
                                                                        }}>
                                                                        &nbsp;
                                                                      </div>
                                                                    </div>
                                                                    <div>
                                                                      <div
                                                                        style={{
                                                                          fontSize: 0,
                                                                        }}>
                                                                        <div
                                                                          style={{
                                                                            width:
                                                                              '100%',
                                                                          }}>
                                                                          <div
                                                                            style={{
                                                                              display:
                                                                                'grid',
                                                                              gridTemplateColumns:
                                                                                '1fr 25.5%',
                                                                            }}>
                                                                            <div
                                                                              style={{
                                                                                width:
                                                                                  '250',
                                                                              }}>
                                                                              <div
                                                                                className="pc-sm-mw-100pc"
                                                                                style={{
                                                                                  display:
                                                                                    'inline-block',
                                                                                  maxWidth:
                                                                                    '500px',
                                                                                  width:
                                                                                    '100%',
                                                                                  verticalAlign:
                                                                                    'top',
                                                                                }}>
                                                                                <div
                                                                                  style={{
                                                                                    width:
                                                                                      '100%',
                                                                                  }}>
                                                                                  <div
                                                                                    style={{
                                                                                      padding:
                                                                                        '10px 20px',
                                                                                    }}>
                                                                                    <div
                                                                                      style={{
                                                                                        width:
                                                                                          '100%',
                                                                                      }}>
                                                                                      <div
                                                                                        className="pc-fb-font"
                                                                                        style={{
                                                                                          letterSpacing:
                                                                                            '-0.2px',
                                                                                          lineHeight:
                                                                                            '26px',
                                                                                          fontFamily:
                                                                                            'Work Sans, sans-serif',
                                                                                          fontSize:
                                                                                            '14px',
                                                                                          color:
                                                                                            '#151515',
                                                                                        }}>
                                                                                        <span
                                                                                          style={{
                                                                                            color:
                                                                                              '#5f5f8c',
                                                                                          }}>
                                                                                          {
                                                                                            kr.name
                                                                                          }
                                                                                        </span>
                                                                                        <br></br>
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize:
                                                                                              '11px',
                                                                                            color:
                                                                                              '#5f5f8c',
                                                                                          }}>
                                                                                          {
                                                                                            kr.assignee
                                                                                          }
                                                                                        </span>
                                                                                      </div>
                                                                                    </div>
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                            </div>
                                                                            <div
                                                                              style={{
                                                                                width:
                                                                                  '300',
                                                                              }}>
                                                                              <div
                                                                                className="pc-sm-mw-100pc"
                                                                                style={{
                                                                                  display:
                                                                                    'inline-block',
                                                                                  maxWidth:
                                                                                    '300px',
                                                                                  width:
                                                                                    '100%',
                                                                                  verticalAlign:
                                                                                    'top',
                                                                                }}>
                                                                                <div
                                                                                  style={{
                                                                                    width:
                                                                                      '100%',
                                                                                  }}>
                                                                                  <div>
                                                                                    <div>
                                                                                      <div
                                                                                        style={{
                                                                                          padding:
                                                                                            '10px 0px',
                                                                                        }}>
                                                                                        <div
                                                                                          style={{
                                                                                            width:
                                                                                              '100%',
                                                                                          }}>
                                                                                          <div
                                                                                            className="pc-fb-font"
                                                                                            style={{
                                                                                              letterSpacing:
                                                                                                '-0.2px',
                                                                                              lineHeight:
                                                                                                '26px',
                                                                                              fontFamily:
                                                                                                'Work Sans, sans-serif',
                                                                                              fontSize:
                                                                                                '14px',
                                                                                              color:
                                                                                                '#151515',
                                                                                            }}>
                                                                                            <span
                                                                                              style={{
                                                                                                color:
                                                                                                  '#1e1e2f',
                                                                                              }}>
                                                                                              {
                                                                                                kr.progress
                                                                                              }
                                                                                            </span>{' '}
                                                                                            <span
                                                                                              style={{
                                                                                                color:
                                                                                                  '#5f5f8c',
                                                                                              }}>
                                                                                              {kr.summary?.trim() ===
                                                                                              '(0 of 1)'
                                                                                                ? '( Incomplete )'
                                                                                                : kr.summary?.trim() ===
                                                                                                  '(1 of 1)'
                                                                                                ? '( complete )'
                                                                                                : kr.summary}
                                                                                            </span>
                                                                                          </div>
                                                                                        </div>
                                                                                      </div>
                                                                                    </div>
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </>
                                                                ),
                                                              )}
                                                            </div>

                                                            <div>
                                                              <div>
                                                                <div
                                                                  // height="0"
                                                                  style={{
                                                                    height: 0,
                                                                    lineHeight:
                                                                      '1px',
                                                                    fontSize:
                                                                      '1px',
                                                                  }}>
                                                                  &nbsp;
                                                                </div>
                                                              </div>
                                                              <div>
                                                                <div
                                                                  style={{
                                                                    padding:
                                                                      '0 20px',
                                                                    verticalAlign:
                                                                      'top',
                                                                  }}>
                                                                  <div
                                                                    // width="100%"
                                                                    style={{
                                                                      width:
                                                                        '100%',
                                                                    }}></div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    ),
                                                  )}
                                                  <div
                                                    // width="100%"
                                                    style={{
                                                      width: '100%',
                                                    }}>
                                                    <div
                                                      style={{
                                                        margin:
                                                          '15px 10px 40px 20px',
                                                      }}>
                                                      <div>
                                                        <div
                                                          style={{
                                                            fontWeight: 'bold',
                                                            color: 'black',
                                                            padding: '0px 20px',
                                                            textAlign: 'left',
                                                            fontFamily:
                                                              'Work Sans, sans-serif',
                                                          }}>
                                                          {
                                                            user?.averageProgress
                                                          }
                                                        </div>
                                                      </div>
                                                      <div>
                                                        <div>
                                                          <div
                                                            style={{
                                                              margin:
                                                                '14px 20px',
                                                              borderBottom:
                                                                '1px solid #CECEDE',
                                                              width: '97%',
                                                            }}></div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              ),
                                            )}

                                          <div
                                            // width="100%"
                                            style={{
                                              width: '100%',
                                            }}>
                                            <div>
                                              <div>
                                                <div
                                                  className="pc-sm-p-31-20-39 pc-xs-p-15-10-25"
                                                  style={{
                                                    padding:
                                                      '3px 30px 39px 30px',
                                                    backgroundColor: '#ffffff',
                                                    verticalAlign: 'top',
                                                  }}>
                                                  <div
                                                    // width="100%"
                                                    style={{
                                                      width: '100%',
                                                    }}>
                                                    <div>
                                                      <div>
                                                        <div
                                                          // valign="top"
                                                          style={{fontSize: 0}}>
                                                          <div
                                                            // width="100%"
                                                            style={{
                                                              width: '100%',
                                                            }}>
                                                            <div>
                                                              <div
                                                                // width="433"
                                                                style={{
                                                                  width: 433,
                                                                }}
                                                                // valign="top"
                                                              >
                                                                <div
                                                                  className="pc-sm-mw-100pc"
                                                                  style={{
                                                                    display:
                                                                      'inline-block',
                                                                    width:
                                                                      '100%',
                                                                    maxWidth:
                                                                      '433px',
                                                                    verticalAlign:
                                                                      'top',
                                                                  }}>
                                                                  <div
                                                                    // width="100%"
                                                                    style={{
                                                                      width:
                                                                        '100%',
                                                                    }}>
                                                                    <div>
                                                                      <div>
                                                                        <div
                                                                          className="pc-fb-font"
                                                                          style={{
                                                                            lineHeight:
                                                                              '20px',
                                                                            letterSpacing:
                                                                              '-0.2px',
                                                                            textAlign:
                                                                              'left',
                                                                            fontFamily:
                                                                              'Work Sans, sans-serif',
                                                                            fontSize:
                                                                              '14px',
                                                                            color:
                                                                              '#9B9B9B',
                                                                            padding:
                                                                              '10px',
                                                                          }}
                                                                          // valign="top"
                                                                        >
                                                                          &#169;
                                                                          2022
                                                                          Beam.
                                                                          All
                                                                          rights
                                                                          reserved.
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div
                                                                // width="107"
                                                                style={{
                                                                  width: 107,
                                                                }}
                                                                // valign="top"
                                                              >
                                                                <div
                                                                  className="pc-sm-mw-100pc"
                                                                  style={{
                                                                    display:
                                                                      'inline-block',
                                                                    width:
                                                                      '100%',
                                                                    maxWidth:
                                                                      '107px',
                                                                    verticalAlign:
                                                                      'top',
                                                                  }}>
                                                                  <div
                                                                    style={{
                                                                      width:
                                                                        '100%',
                                                                    }}>
                                                                    <div>
                                                                      <div>
                                                                        <div
                                                                          style={{
                                                                            padding:
                                                                              '9px 0 10px',
                                                                          }}>
                                                                          <div>
                                                                            <div>
                                                                              <div></div>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div>
                                                      <div>
                                                        <div
                                                          style={{
                                                            fontSize: '1px',
                                                            height: 9,
                                                            lineHeight: '1px',
                                                          }}>
                                                          &nbsp;
                                                        </div>
                                                      </div>
                                                      <div>
                                                        <div
                                                          className="pc-fb-font"
                                                          style={{
                                                            lineHeight: '20px',
                                                            fontFamily:
                                                              'Work Sans, sans-serif',
                                                            fontSize: '14px',
                                                            color: '#9B9B9B',
                                                            padding: '0 10px',
                                                          }}></div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div style={{width: '100%'}}>
                              <div>
                                <div>
                                  <div
                                    style={{
                                      height: 20,
                                      fontSize: '1px',
                                      lineHeight: '1px',
                                    }}>
                                    &nbsp;
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </PDFExport>
            <div
              className="pc-gmail-fix"
              style={{
                whiteSpace: 'nowrap',
                font: '15px courier',
                lineHeight: 0,
              }}>
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              &nbsp; &nbsp; &nbsp;{' '}
            </div>
          </div>
        </body>
      </>
    );
  },
);
