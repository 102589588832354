import styled from 'styled-components';
import {ArrowHeadLeftIcon} from '../icons/arrow-head-left';
import {ArrowHeadRightIcon} from '../icons/arrow-head-right';
import {CTA} from '../typography/cta';
import {getColorFromTheme} from '../../ui-utils';
import {ComponentPropsWithoutRef, memo} from 'react';

const Header = styled.div`
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Icon = styled.button`
  padding: 2px;
  background: none;
  border: none;

  &:disabled {
    cursor: not-allowed;

    svg {
      stroke: ${getColorFromTheme('textMuted')};
    }
  }

  svg {
    width: 20px;
    height: 20px;
    stroke: ${getColorFromTheme('black')};
  }
`;

export interface THeaderActionSwitcherProps {
  title: string;
  prev: ComponentPropsWithoutRef<'button'>;
  next: ComponentPropsWithoutRef<'button'>;
}

export const HeaderActionSwitcher = memo(function ({
  title,
  prev,
  next,
}: THeaderActionSwitcherProps) {
  return (
    <Header>
      <Icon {...prev} type="button">
        <ArrowHeadLeftIcon />
      </Icon>
      <CTA kind="textDark">{title}</CTA>
      <Icon {...next} type="button">
        <ArrowHeadRightIcon />
      </Icon>
    </Header>
  );
});
HeaderActionSwitcher.displayName = 'HeaderActionSwitcher';
