import {useCallback, useEffect} from 'react';
import {useLocation} from 'react-router-dom';

export const useScrollToTop = () => {
  const handleScrollToTop = useCallback(() => {
    const dashboardScreen = document.getElementById('dashboard-body');

    dashboardScreen?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);
  return {handleScrollToTop};
};

const ScrollToTop = () => {
  // Extracts pathname property(key) from an object
  const {pathname} = useLocation();
  const {handleScrollToTop} = useScrollToTop();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    handleScrollToTop();
  }, [pathname, handleScrollToTop]);

  return null;
};

export default ScrollToTop;
