import {AxiosResponse} from 'axios';
import {
  AdminProfileFormData,
  AdminProfileResponse,
} from './admin-profile-interface';
import {BaseRequest} from '../../../configs/request';

export interface AdminProfileRequest extends BaseRequest {
  url: string;
  adminProfile(data: AdminProfileFormData): Promise<AdminProfileResponse>;
}
export interface IGoogleAuthRequest extends BaseRequest {
  googleAuth(token: string, isAdmin: boolean): Promise<any>;
}

export class AdminProfileRequestImpl
  extends BaseRequest
  implements AdminProfileRequest {
  url: string = '/auth/admin/sign-up';
  async adminProfile<AdminProfileResponse>(data: AdminProfileFormData) {
    const {data: responseData} = await this.request.post<
      AdminProfileFormData,
      AxiosResponse<AdminProfileResponse>
    >(this.url, data);
    return responseData;
  }
}

export class GoogleAuthRequestImpl
  extends BaseRequest
  implements IGoogleAuthRequest {
  url = '/auth/google';
  async googleAuth(token: string, isAdmin: boolean) {
    const response = this.request.post<string>(this.url, {token, isAdmin});
    return response;
  }
}
