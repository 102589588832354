import {Modal} from '@ui/molecules/modal';
import {ModalCard} from '@ui/layouts/modal-card';
import {BackAction} from '@ui/molecules/back-action';
import {CancelIcon} from '@ui/atoms/icons';
import {
  ModalCardWrapper,
  DesktopWrapper,
  MobileWrapper,
  CancelWrapper,
  ItemLoaderWrapper,
} from '@pages/dashboard/you/you-components/insights/insights';
import {useState} from 'react';
import {Checkbox} from '@ui/atoms/checkbox';
import {useNavigate} from 'react-router';
import dayjs from 'dayjs';
import {useFirebaseFetch} from '@hooks/query-hook';
import {Body2, Headline3, Microcopy} from '@ui/atoms/typography';
import {CommentDetails} from '@ui/layouts/users-goals/comments';
import {ItemLoader} from '@ui/organisms/item-loader';
import {useSearchParams} from 'react-router-dom';
import styled from 'styled-components';
import {capitalize} from '@utils';
import {observer} from 'mobx-react-lite';
import {useStoreContext} from '@store/store-context';
import {VerticalSpacer} from '@ui/atoms/spacer';

const PriorityWrapper = styled.div`
  padding: 16px;
  border: 1px solid #ededf2;
  border-radius: 10px;
  .priority-details {
    margin-top: 8px;
    border-top: 1px solid #ededf2;

    .flex-row {
      display: flex;
      align-items: flex-start;
      .checkbox {
        margin: 2px 6px 0px 0;
      }
      margin: 16px 0 5px 0px;
    }
  }
`;

export const CheckinsComments = observer(() => {
  const {
    checkinsStore: {
      showCheckinsDrawer: checkinsPriorityId,
      checkinsDates,
      updateCheckinsDrawer,
    },
    authStore: {auth},
    usersStore: {users},
  } = useStoreContext();

  const [searchParams] = useSearchParams();

  const userId = searchParams.get('user');
  const fieldId = searchParams.get('checkins-comment');
  const checkinsId = searchParams.get('checkinsId');

  const navigate = useNavigate();

  const [loading] = useState(false);

  const PATH = `checkins/${process.env.REACT_APP_DEV_ENV}/${
    userId || auth.user.id
  }/yr-${checkinsDates?.year}/wk-${checkinsDates?.weekOfYear}/${fieldId}/`;

  const {
    data = {
      comments: [],
      content: {
        text: '',
        createdAt: new Date(),
        completed: false,
      },
    },
  } = useFirebaseFetch(PATH);

  const {data: subscribers} = useFirebaseFetch(`${PATH}/subscribers`);

  const closeDrawer = () => {
    updateCheckinsDrawer('');
    navigate(-1);
  };

  if (!checkinsPriorityId) {
    return null;
  }

  if (loading) {
    return (
      <ItemLoaderWrapper>
        <ItemLoader />
      </ItemLoaderWrapper>
    );
  }

  return (
    <Modal open={true} fullHeight onClose={() => null}>
      <MobileWrapper>
        <CancelWrapper>
          <BackAction
            icon={<CancelIcon />}
            title="Close"
            onClick={() => {
              closeDrawer();
            }}
            width="xs"
          />
        </CancelWrapper>
      </MobileWrapper>
      <ModalCardWrapper>
        <div className="child">
          <ModalCard title="" fullHeight onBoarding>
            <div>
              <DesktopWrapper>
                <CancelWrapper>
                  <BackAction
                    icon={<CancelIcon />}
                    title="Close"
                    style={{
                      padding: '10px 16px',
                    }}
                    onClick={() => {
                      closeDrawer();
                    }}
                    width="xs"
                  />
                </CancelWrapper>
              </DesktopWrapper>
              <VerticalSpacer size="8px" />
              <PriorityWrapper>
                <Body2 weight="bold">
                  {!userId
                    ? 'Your '
                    : `${
                        users.find((user) => user.id === userId)?.firstName
                      } 's  `}
                  Priority
                </Body2>
                <div className="priority-details">
                  <div className="flex-row">
                    <div className="checkbox">
                      <Checkbox checked={data?.content?.completed} />
                    </div>
                    <div>
                      <Headline3>{capitalize(data?.content?.text)}</Headline3>
                      <Microcopy kind="textBody">
                        {dayjs(data?.content?.createdAt, 'YYYY/MM/DD').format(
                          'MMM. DD, YYYY',
                        )}
                      </Microcopy>
                    </div>
                  </div>
                </div>
              </PriorityWrapper>
              <CommentDetails
                pathId={fieldId}
                allowedMentionedUsers={userId ? [userId] : [auth.user.reviewer]}
                mentionsPath={`checkins/${checkinsId}`}
                allGoalSubscribers={subscribers}
                defaultSubscribers={[auth.user.id, auth.user.reviewer]}
                path={PATH}
                comments={{comments: data?.comments}}
              />
            </div>
          </ModalCard>
        </div>
      </ModalCardWrapper>
    </Modal>
  );
});
