import Meetings from './meetings';
import {observer} from 'mobx-react-lite';
import {FlexRowCenter, FlexRowSpaceBetween} from '@ui/style/styles';
import {Body1, Headline2} from '@ui/atoms/typography';
import {
  DropdownItem,
  MenuButtonPrimary,
} from '@pages/dashboard/you/you-page.styles';
import {ArrowHeadDownIcon} from '@ui/atoms/icons';
import {DropdownCustom} from '@ui/molecules/dropdown-custom';
import {useNavigate} from 'react-router';
import {useGCalenderHook} from '@hooks/gcalender';
import {ItemLoader} from '@ui/organisms/item-loader';
import {useQuery} from 'react-query';

export const OneonOnePage = observer(() => {
  const navigate = useNavigate();

  const {
    isCalendarConnected: isGoogleCalenderConnected,
    fetchGoogleToken,
  } = useGCalenderHook();

  const {isLoading} = useQuery(['google-scope'], fetchGoogleToken);

  return (
    <>
      <FlexRowSpaceBetween className="mb-10">
        <div className="">
          <Headline2>Meetings</Headline2>
          <div className="w-[80%] mt-1">
            <Body1 kind="textBody">
              Schedule and manage 1:1s and team meetings, take notes, and create
              action items.
            </Body1>
          </div>
        </div>
        {isGoogleCalenderConnected && (
          <DropdownCustom
            collapseOnClick={true}
            menu={(handleClose: () => void) => (
              <div>
                <DropdownItem
                  onClick={() => {
                    navigate('/create-1:1');
                    handleClose();
                  }}>
                  Schedule new meeting
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    navigate('/create-meeting-notes');
                    handleClose();
                  }}>
                  Add meeting notes
                </DropdownItem>
              </div>
            )}
            customComponent={(
              handleOpen: (event: any) => void,
              open: boolean,
            ) => (
              <MenuButtonPrimary onClick={handleOpen} className="px-6 py-3">
                Create
                <ArrowHeadDownIcon
                  style={{
                    marginLeft: 14,
                    stroke: '#fff',
                    transform: !open ? 'rotate(0deg)' : 'rotate(180deg)',
                  }}
                />
              </MenuButtonPrimary>
            )}
            customDropdownWrapperStyles={{
              top: '130%',
              right: '0px',
              width: '160%',
            }}
          />
        )}
      </FlexRowSpaceBetween>

      {isLoading && (
        <FlexRowCenter className="min-h-[200px]">
          <ItemLoader />
        </FlexRowCenter>
      )}

      {!isLoading && (
        <Meetings isGoogleCalenderConnected={isGoogleCalenderConnected} />
      )}
    </>
  );
});
