import {FlexRow} from '@ui/style/styles';
import {AnalyticsActivityCard} from '../analytics-activity-card';

interface IAnalyticsBox {
  activities: Array<{
    changeInValue: string;
    date: string;
    endDate: string;
    percentageChangeInValue: string | number;
    progress: string | number;
    startDate: string;
  }>;
  height?: string;
  isKeyResultValue?: boolean;
  completion: boolean;
  showPercentageInValue?: boolean;
  staticDates: Array<TemplateStringsArray> | null;
}

export const AnalyticsGrid = ({
  activities,
  completion,
  isKeyResultValue,
  staticDates,
  showPercentageInValue,
  height,
}: IAnalyticsBox) => {
  return (
    <FlexRow>
      {activities?.map((activity: any, index: number) => (
        <AnalyticsActivityCard
          key={index}
          isKeyResultValue={isKeyResultValue}
          height={height}
          showPercentageInValue={showPercentageInValue}
          activity={activity}
          completed={completion}
        />
      ))}
      {staticDates &&
        staticDates.map((value, idx) => (
          <AnalyticsActivityCard
            key={idx}
            height={height}
            activity={{
              changeInValue: '',
              progress: '',
              percentageChangeInValue: 0,
              date: '',
              startDate: '',
              endDate: '',
            }}
            completed={completion}
          />
        ))}
    </FlexRow>
  );
};
