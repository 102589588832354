import {ReactNode, useMemo} from 'react';
import {down, up} from 'styled-breakpoints';
import styled, {css} from 'styled-components';
import {PlusIcon} from '../../../../../../../ui/atoms/icons';
import {
  InnerWrapper,
  RadioInput,
  Wrapper as RadioWrapper,
} from '../../../../../../../ui/atoms/radio';
import {ifProp} from 'styled-tools';
import {
  HorizontalSpacer,
  VerticalSpacer,
} from '../../../../../../../ui/atoms/spacer';
import {Body1} from '../../../../../../../ui/atoms/typography';
import {Stack} from '../../../admin-workspace/workspace-performance/admin-culture-competence/performance-settings-style';
//import {MultiOptionsSelectField2} from '../../../../../../../ui/molecules/select/multi-options/multi-options';
import {SelectField} from '../../../../../../../ui/molecules/select/selectfield';
import {getColorFromTheme} from '../../../../../../../ui/ui-utils';
import {ThickLockIcon} from '../../../../../../../ui/atoms/icons/thick-lock-icon';
import {ThickPlusIcon} from '../../../../../../../ui/atoms/icons/thick-plus';

export const OuterWrapper = styled.div`
  width: 704px;
  max-width: 100%;
  margin: 0 auto;
  ${down('md')} {
    width: 100%;
    padding: 20px 20px;
  }

  ${up('sm')} {
    padding: 40px 0;
  }

  ${up('lg')} {
    padding: 80px 0;
  }

  ${up('xl')} {
    padding: 80px 0;
  }
`;
export const BackWrapper = styled.div`
  width: 100%;
`;

export const WrappedBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .border-div {
    border: 1px solid #ededf2;
    border-radius: 10px;
    padding: 14px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
  }
`;
export const Info = styled.div`
  background: #f6f6f8;
  border-radius: 10px;
  padding: 14px 16px;
`;

export const TemplateWrapper = styled.div`
  border: 1px solid #ededf2;
  border-radius: 10px;
  .body {
    padding: 16px;
  }
  .footer {
    padding: 16px;
    border-top: 1px solid #ededf2;
  }
`;

export const ReadOnlyInput = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 5px 16px;

  width: 100%;
  min-height: 54px;

  background: #f6f6f8;
  border: 1px solid #cecede;
  border-radius: 10px;
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  color: #1e1e2f;
  span {
    color: #585adf;
    margin-left: 5px;
    display: inline-block;
  }
`;

export const AddQuestionHeader = styled.div`
  background: #fafafa;
  border-bottom: 1px solid #ededf2;
  padding: 16px 40px;
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  .flex {
    display: flex;
    align-items: center;
    .bg-dark {
      background: #fafafa;
    }
    .bg-light {
      background: #ffffff;
    }
  }
  .rule {
    min-width: 40px;
    height: 1px;
    background-color: #cecede;
  }

  button {
    min-width: 140px;
    padding: 6px 16px;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 600;
    border: 1px solid #cecede;
    box-shadow: 0px 3px 24px rgba(208, 208, 221, 0.16);
    border-radius: 20px;
  }
`;

export const RatingScaleWrapper = styled.div`
  border: 1px solid #ededf2;
  border-radius: 10px;
  padding: 16px;

  .rating {
    border: 1px solid #ededf2;
    border-radius: 10px;
    margin-bottom: 12px;
    padding: 16px 24px;
  }
`;

export const DelimitingWrapper = styled.div`
  width: 37%;
  margin-bottom: 30px;

  @media (min-width: 768px) and (max-width: 1100px) {
    width: 45%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
  margin: 0 auto;
`;
export const QuestionForButton = styled.button<{active?: boolean}>`
  background: ${ifProp('active', '#47B881', '#ffffff')};
  padding: 8px 16px;
  font-weight: 600;
  margin-right: 10px;
  font-size: 14px;
  color: ${ifProp('active', 'white', 'black')};
  border: ${ifProp('active', '1px solid #1A9E68', '1px solid #cecede')};
  border-radius: 10px;
`;

export const QuestionWrapper = styled.div`
  border: 1px solid #ededf2;
  border-radius: 10px;
  margin-top: 16px;
  .header {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .body {
    padding: 16px;
    border-top: 1px solid #ededf2;
  }
`;
export const Wrapper = styled.div`
  width: 100%;
  padding-bottom: 30px;
`;

export const HintBlock = styled.div`
  width: 100%;
  padding: 8px;
  background: #f6f6f8;
  border-radius: 10px;
  text-align: center;
  font-family: Graphik;
  font-weight: 400;
  line-height: 22.44px;
  font-size: 14px;
  color: #1e1e2f;
  position: relative;
  a {
    color: #585adf;
    text-decoration: underline;
  }
  div {
    position: absolute;
    cursor: pointer;
    right: 50px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const TypeCheckBoxStyled = styled.label`
  padding: 8px;
  border: 1px solid #ededf2;
  border-radius: 10px;

  text-align: left;
  cursor: pointer;
  display: block;
  height: 56px;

  display: flex;
  // justify-content: space-between;
  align-items: center;
  text-align: center;
`;

interface VP {
  name: string;
  value: string;
  title: string;
  onCheck: any;
  defaultValue: string;
}
export const TypeCheckBox = (props: VP) => {
  return (
    <TypeCheckBoxStyled>
      <RadioWrapper>
        <RadioInput
          name={props.name}
          value={props.value}
          onChange={props.onCheck}
          type="radio"
          id={props.value}
          checked={props.defaultValue === props.value}
        />
        <InnerWrapper />
      </RadioWrapper>
      <VerticalSpacer size="12px" />
      <HorizontalSpacer size="15px" />
      <Body1
        kind="textDark"
        style={{
          fontFamily: 'Graphik',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: '170%',
        }}>
        {props.title}
      </Body1>
    </TypeCheckBoxStyled>
  );
};

export const Border = styled.div`
  height: 1px;
  width: 100%;
  background-color: #ededf2;
  margin: 20px 0;
`;

const TemplateBlockStyled = styled.div`
  padding: 16px;
  background: #ffffff;
  /* Border/Light */
  border: 1px solid #ededf2;
  border-radius: 10px;

  ${Border}:first-child {
    display: none;
  }
`;

export const AdditionalOptionsWrapper = styled.button<{active: boolean}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='rgba(206, 206, 222, 1)' stroke-width='2' stroke-dasharray='14%2c 8' stroke-dashoffset='0' strokeLinecap='round'/%3e%3c/svg%3e");
  border-radius: 10px;
  margin: 8px 0;
  width: 100%;
  height: 56px;
  cursor: not-allowed;
  padding: 10px;
  background-color: #fafafa;
  /* Frame 1000002867 */
  ${({active}) =>
    active &&
    css`
      p {
        color: #5f5f8c !important;
      }
      cursor: pointer;
    `}
`;
export const ExpandText = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 170%;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }

  color: #585adf;
`;

export const CollapseWithTransition = styled.div<{show: boolean}>`
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.26s ease;
  height: 0;
  ${({show}) =>
    show &&
    css`
      transform: scaleY(1);
      height: max-content;
    `}
`;

export const BoxWithBorder = styled.div<{active?: boolean}>`
  box-sizing: border-box;
  padding: 16px;

  background: #ffffff;

  border: 1px solid #ededf2;
  border-radius: 10px;

  .iconwrapper {
    display: none;
  }
  ${({active}) =>
    active &&
    css`
      background: #47b881;
      border: 2px solid #1a9e68;
      p {
        color: #ffffff !important;
      }
      .iconwrapper {
        background: #1a9e68;
        display: flex;
        width: 16px;
        height: 16px;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        margin-right: 5px;
      }
    `}
`;

export const PlusWrapper = styled.span<{active?: boolean}>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  background: #bfbfd4;
  stroke: ${getColorFromTheme('white')};
  border-radius: 50%;
  width: 18px;
  height: 18px;

  ${({active}) =>
    active === true &&
    css`
      background: ${getColorFromTheme('green300')};
    `}
`;

export const TemplateButton = styled.button<{diss: boolean}>`
  display: flex;
  align-items: center;
  margin-top: 20px;

  p {
    color: ${getColorFromTheme('textMuted')};
    cursor: not-allowed;
  }

  span {
    position: relative;
    cursor: not-allowed;

    svg {
      stroke: ${getColorFromTheme('textMuted')};
    }
  }

  ${({diss}) =>
    !diss &&
    css`
      p {
        color: ${getColorFromTheme('purple300')};
        cursor: pointer;
      }
      span {
        position: relative;
        cursor: pointer;

        svg {
          stroke: ${getColorFromTheme('purple300')};
        }
      }
      $:hover {
        opacity: 0.8;
      }
    `}
`;

export const EditWrapper = styled.div`
  background: #fafafa;
  border: 1px solid #ededf2;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  margin: 8px 0;
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 170%;
    color: #1e1e2f;
  }
  .line {
    /* Vector 41 */

    width: 1px;
    height: 12px;

    /* Border/Dark */
    background-color: #cecede;
    margin: 0 10px;
  }
  span {
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 146%;
    /* or 20px */

    /* Purple/300 */

    color: #585adf;
  }
`;

interface TemplateFieldProps {
  placeholder?: string;
  onClickHandleQuestion?: () => void;
  displayEditCard?: boolean;
  disabledButton?: boolean;
  disabled?: boolean;
  onInputChange?: (e: any) => void;
  value: string;
  options?: any[];
  children?: ReactNode;
  removeOptions?: any;
}

export const TemplateField = (props: TemplateFieldProps) => {
  const defaultValue = useMemo(() => props.value, [props.value]);

  return (
    <div style={{width: '100%'}}>
      <VerticalSpacer size="5px" />
      <SelectField
        options={props.options}
        label=""
        state=""
        helper=""
        placeholder={props.placeholder}
        disabled={props.disabled}
        margin={true}
        removeOption={props.removeOptions}
        removeOptionType={'value'}
        borderRadius={10}
        onChange={props.onInputChange}
        value={defaultValue}
        defaultValue={
          props.options?.filter(
            (opt: any) =>
              opt.label === defaultValue || opt.value === defaultValue,
          )[0]?.value
        }
        NoOptionsMessage={() => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              padding: '10px',
            }}>
            No Data found
          </div>
        )}
      />

      {props.children}
      <AdditionalOptionsWrapper
        active={
          props.disabledButton === undefined ? false : !props.disabledButton
        }
        disabled={props.disabledButton}
        onClick={props.onClickHandleQuestion}>
        <PlusWrapper
          active={
            props.disabledButton === undefined ? false : !props.disabledButton
          }>
          <ThickPlusIcon color={props.disabledButton ? '#BFBFD4' : '#47B881'} />
        </PlusWrapper>
        <Body1
          style={{
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '170%',
          }}
          kind="textMuted">
          Add question
        </Body1>
      </AdditionalOptionsWrapper>
    </div>
  );
};

interface TemplateBlockProps {
  label?: string;
  buttonLabel?: string;
  children?: ReactNode;
  onClickButton?: () => void;
  disabledButton: boolean;
  disabled: boolean;
}
const TemplateBlocktyled = styled.div<{disabled: boolean}>`
  ${(disabled) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`;
export const TemplateBlock = (props: TemplateBlockProps) => {
  return (
    <>
      <Body1
        style={{
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: '170%',
        }}
        kind="textDark">
        {props.label}
      </Body1>
      <TemplateBlocktyled disabled={props.disabled}>
        <VerticalSpacer size="8px" />
        <TemplateBlockStyled>
          {props.children}
          <TemplateButton
            disabled={props.disabledButton}
            onClick={props.onClickButton}
            diss={props.disabledButton}>
            <span style={{marginTop: '4px'}}>
              <PlusIcon />
            </span>
            <Body1
              style={{
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '170%',
                marginLeft: '5px',
              }}>
              {props.buttonLabel}
            </Body1>
          </TemplateButton>
        </TemplateBlockStyled>
      </TemplateBlocktyled>
    </>
  );
};

export const TemplateBlockForAss = (props: TemplateBlockProps) => {
  return (
    <TemplateBlocktyled disabled={props.disabled}>
      <VerticalSpacer size="8px" />
      <TemplateBlockStyled>
        <Stack
          justifyContent="space-btw"
          style={{padding: '5px 2px', marginBottom: '10px'}}>
          <Stack alignItem="center">
            <Body1
              style={{
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '170%',
              }}
              kind="textDark">
              {props.label}{' '}
              <span style={{fontWeight: 400, color: '#5F5F8C'}}>
                {' '}
                (optional){' '}
              </span>
            </Body1>
          </Stack>
          <ThickLockIcon />
        </Stack>
        {props.children}
      </TemplateBlockStyled>
    </TemplateBlocktyled>
  );
};
