import React from 'react';

export const BeamLogo = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" rx="4" fill="#F6F6F8" />
      <rect
        x="3.3335"
        y="3.33301"
        width="33.3333"
        height="33.3333"
        rx="3"
        fill="#585ADF"
      />
      <path
        d="M13.3335 26.6667H20.0886C23.3969 26.6667 25.5165 24.9231 25.5165 22.3275C25.5165 20.6037 24.5062 19.6725 23.0799 19.3555C24.7043 18.9592 25.8335 17.81 25.8335 15.9674C25.8335 13.9464 24.5459 12.5 21.297 12.5H15.572L13.3335 26.6667ZM17.7115 23.9522L18.2463 20.6037H19.6924C21.0197 20.6037 21.5942 21.0991 21.5942 22.0897C21.5942 23.1795 20.8414 23.9522 19.4944 23.9522H17.7115ZM18.6227 18.127L19.0982 15.1946H20.5245C21.614 15.1946 22.1291 15.5711 22.1291 16.4825C22.1291 17.5524 21.515 18.127 20.2075 18.127H18.6227Z"
        fill="white"
      />
    </svg>
  );
};
