import React, {FC, memo, useCallback, useMemo} from 'react';
import {Insight} from '../insights/interface';
import {AnalyticsController} from '../reporting-pages/admin-analytics/admin-analytics.controller';
import {AnalyticsRequestImpl} from '../reporting-pages/admin-analytics/admin-analytics.request';
import {useQueries} from 'react-query';
import {InsightCard, PercentageProgress} from './insight-card';
import {MeasurementType} from '@ui/interface';
import {
  comparisonConfigDate,
  formatConfigDate,
} from '../insights/insight-options';
import dayjs from 'dayjs';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {VerticalInsightConfig} from './insight-config';
import {getInitialAdditionalFilters} from '../insights/edit-insight/edit-insight-filter';
import {Body1, Headline2} from '@ui/atoms/typography';
import {FlexRow, FlexRowEnd, FlexRowSpaceBetween} from '@ui/style/styles';
import {MultiLineChart} from '../reporting-pages/admin-analytics/analytics-pages/analytics-status/Graph';
import {goalDueDate} from '@utils/date';
import {IGoalResult} from '@hooks';
import {HoverTooltip} from '@ui/molecules/hover-tooltip';
import {CandleVIcon} from '@ui/atoms/icons/candle-v';
const commaNumber = require('comma-number');

interface KPIInsightProps extends Insight {
  showRangeType?: boolean;
  handleEditLayout?: (id: string) => void;
}

interface Activity {
  startDate: string;
  currentValue: number;
  percentageProgress: number;
  endDate: string;
  performance: 'at_risk' | 'on_track' | 'behind';
  prevValue?: number;
}

interface KPIResponse extends IGoalResult {
  activities: Activity[];
  firstValue: number;
  secondValue: number;
  percentageChange: number;
  difference: number;
}

export const KpiInsight: FC<KPIInsightProps> = memo((props) => {
  const {config, id, ...rest} = props;

  const formatDate = useMemo(() => formatConfigDate(config) || {}, [config]);

  const comparisonDate = useMemo(
    () =>
      formatDate ? comparisonConfigDate(config, formatDate.startDate) : null,
    [config, formatDate],
  );

  const fetchGoalById = useCallback(async (): Promise<KPIResponse> => {
    if (!formatDate || !id) {
      return {} as KPIResponse;
    }

    const request = new AnalyticsRequestImpl();
    const controller = new AnalyticsController(request);
    const response = await controller.fetchReportGoalById(id);

    if (response) {
      const _response = await controller.fetchReportKPIComparison(
        id,
        response.measurement?.preference,
        formatDate,
        comparisonDate ? comparisonDate : formatDate,
      );
      return {
        ...response,
        ..._response,
      };
    }
    return response;
  }, [comparisonDate, formatDate, id]);

  const queries = useMemo(() => {
    const baseQuery = {
      queryKey: [
        'goal',
        id,
        formatDate.startDate,
        formatDate.endDate,
        comparisonDate,
      ],
      queryFn: () => fetchGoalById(),
      staleTime: 5 * 60 * 1000, // 5 minutes
      cacheTime: 10 * 60 * 1000, // 10 minutes
    };

    return [baseQuery];
  }, [
    id,
    formatDate.startDate,
    formatDate.endDate,
    comparisonDate,
    fetchGoalById,
  ]);

  const results = useQueries(queries);

  const [kpi] = results;

  const measurement = useMemo(() => {
    if (kpi.data) {
      return kpi.data.measurement;
    }
    return;
  }, [kpi.data]);

  const comparisonValue = 0;

  const percentProgress = useMemo(() => {
    if (comparisonValue === null) return;

    return kpi.data?.percentageChange;
  }, [kpi.data?.percentageChange]);

  const currency =
    measurement?.unit.toUpperCase() === MeasurementType.CURRENCY
      ? measurement.symbol
      : '';

  const percentage =
    measurement?.unit.toUpperCase() === MeasurementType.PERCENTAGE ? '%' : '';

  const isChartType = config.view === 'chart';

  const additionalFilters = getInitialAdditionalFilters(
    config.list,
    config.users,
  );

  const value = `${currency}${commaNumber(
    kpi.data?.firstValue || 0,
  )}${percentage}`;

  const goal = kpi.data;

  return (
    <div className="h-full w-full">
      {isChartType ? (
        <>
          <div className="bg-white flex justify-between flex-col items-start shadow-[0_3px_24px_0_rgba(208,208,221,0.16)] p-6 border h-full rounded-[10px] border-borderLight  min-h-[150px]">
            <Body1 weight="bold">{rest.name}</Body1>
            {rest.showRangeType && (
              <>
                <VerticalSpacer size="4px" />
                <VerticalInsightConfig
                  config={config}
                  additionalFilters={additionalFilters}
                />
              </>
            )}

            <FlexRow className="gap-2 mb-2 mt-3">
              <Headline2>{value}</Headline2>
              <PercentageProgress value={percentProgress} />
            </FlexRow>

            {goal && (
              <div className="w-full">
                <MultiLineChart
                  data={goal.activities}
                  completedAt={dayjs(
                    goal?.completedAt || goal?.updatedAt,
                  ).format('MM/DD/YYYY')}
                  lastUpdated={goal.status || ''}
                  KRtargetValue={0}
                  isKpi={goal.isKpi}
                  useCurrentValue={goal.isKpi}
                  dueDate={dayjs(goal.endDate).format('MM/DD/YYYY')}
                  todayDate={dayjs().format('MM/DD/YYYY')}
                  performance={
                    goalDueDate(goal.endDate || '') === 'Past due'
                      ? 'at_risk'
                      : (goal.performance as any)
                  }
                  height={190}
                  margin={{top: 20, right: 5, bottom: 30, left: 40}}
                />
              </div>
            )}
            <FlexRowSpaceBetween className="w-full">
              <div />
              <FlexRowEnd>
                {rest.showRangeType && (
                  <button
                    onClick={() => {
                      if (rest.firebaseId && rest.handleEditLayout)
                        rest.handleEditLayout(rest.firebaseId);
                    }}>
                    <HoverTooltip tooltipText="Edit insight">
                      <CandleVIcon />
                    </HoverTooltip>
                  </button>
                )}
              </FlexRowEnd>
            </FlexRowSpaceBetween>
          </div>
        </>
      ) : (
        <InsightCard
          id={id}
          config={config}
          showPercentProgress
          value={value}
          percentProgress={percentProgress}
          {...rest}
        />
      )}
    </div>
  );
});
