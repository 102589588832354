import {useReducer, useCallback, useMemo} from 'react';
import nextId from 'react-id-generator';

type TQuestion = {
  type: string;
  text: string | null;
  optional: boolean;
};
interface IResult {
  question: TQuestion;
  valid: [];
}
interface ReducerState {
  questions: Map<string, IResult>;
}
const initialState: ReducerState = {
  questions: new Map(),
};
export enum ActionType {
  REMOVE_QUESTION,
  ADD_QUESTION,
  UPDATE_QUESTION,
}

type AddNewQuestionAction = {type: ActionType.ADD_QUESTION};

type UpdateQuestionAction = {
  type: ActionType.UPDATE_QUESTION;
  payload: {value: TQuestion; key: string};
};
type RemoveQuestionAction = {
  type: ActionType.REMOVE_QUESTION;
  payload: {key: string};
};

type ReducerAction =
  | AddNewQuestionAction
  | UpdateQuestionAction
  | RemoveQuestionAction;

function addNewQuestion(questions: Map<string, IResult>): Map<string, IResult> {
  questions.set(nextId('question-id'), {
    question: {type: '', text: '', optional: true},
    valid: [],
  });

  return new Map(questions);
}

const updateQuestion = (
  questions: Map<string, IResult>,
  key: string,
  question: TQuestion,
): Map<string, IResult> => {
  questions.set(key, {
    question,
    valid: [],
  });

  return new Map(questions);
};

function removeQuestion(
  questions: Map<string, IResult>,
  key: string,
): Map<string, IResult> {
  questions.delete(key);

  return new Map(questions);
}

function questionsReducer(
  state: ReducerState,
  action: ReducerAction,
): ReducerState {
  switch (action.type) {
    case ActionType.ADD_QUESTION:
      return {
        questions: addNewQuestion(state.questions),
      };
    case ActionType.UPDATE_QUESTION:
      return {
        questions: updateQuestion(
          state.questions,
          action.payload.key,
          action.payload.value,
        ),
      };
    case ActionType.REMOVE_QUESTION:
      return {
        questions: removeQuestion(state.questions, action.payload.key),
      };
    default:
      return state;
  }
}

function seedQuestions(questions: TQuestion[]): Map<string, IResult> {
  return new Map(
    questions.map((question) => [nextId('question-id'), {question, valid: []}]),
  );
}

export function useQuestion(
  questions: TQuestion[],
  setValue?: (values: TQuestion[]) => void,
) {
  const [state, dispatch] = useReducer(questionsReducer, {
    ...initialState,
    questions: seedQuestions(questions),
  });

  const updateValue = useCallback(() => {
    if (setValue) {
      const questions = Array.from(state.questions)
        // .filter(([key, question]) => question && question.valid)
        .map(([key, question]) => question.question);

      setValue(questions);
    }
  }, [setValue, state.questions]);

  const addQuestion = useCallback(async () => {
    await dispatch({type: ActionType.ADD_QUESTION});
  }, []);
  const removeQuestion = useCallback(
    async (key: string) => {
      await dispatch({type: ActionType.REMOVE_QUESTION, payload: {key}});
      updateValue();
    },
    [updateValue],
  );

  const onUpdateQuestion = useCallback(
    async (key: string, question: TQuestion) => {
      await dispatch({
        type: ActionType.UPDATE_QUESTION,
        payload: {value: question, key},
      });
      updateValue();
    },
    [updateValue],
  );

  const disabled = useMemo(() => {
    return state.questions.size >= 5;
  }, [state.questions.size]);

  return {
    questions: state.questions,
    addQuestion,
    removeQuestion,
    onUpdateQuestion,
    disabled,
  };
}
