import {IMembersFormatFilter} from '@hooks';
import {useMemo, useCallback} from 'react';
import {usePeriodHook} from '@hooks/period-hook';
import {FeedbackController} from '@pages/feedback/feedback-controller';
import {FeedbackRequestImpl} from '@pages/feedback/feedback-requests';
import {useState} from 'react';
import {useStoreContext} from '@store/store-context';
import {usePaginationHook} from '@hooks/pagination-hook';
import {useFetchHook} from '@hooks/query-hook';
import {IOptions} from '@pages/feedback/components/values-themes-filter';
import {AnalyticsTodayDate} from '@utils/date';

export const useAnalyticsFeedbackHook = (computedFilters: any) => {
  const [userFilter, setUserFilter] = useState({
    filterBy: {
      label: 'People',
      value: 'people',
    },
    selectedTeam: {
      value: '',
      label: '',
    },
    members: [] as Array<IMembersFormatFilter>,
  });

  const {
    pages,
    handlePrevBtn,
    nextPageBtn,
    updateLimit,
    paginationPages,
    updateTotalPages,
    goToPage,
    updateTotalResults,
  } = usePaginationHook();

  const {isPeriodEnabled, currentMonthRange} = usePeriodHook();

  const {
    feedbackStore: {dateRange: datePeriod, setDateRange},
  } = useStoreContext();

  const [valueThemeFilter, setValueThemeFilter] = useState<IOptions>({
    filterBy: '',
    theme: '',
    value: '',
  });

  const getPeriod = useMemo(
    () =>
      isPeriodEnabled
        ? {
            starts: currentMonthRange().starts,
            ends: currentMonthRange().ends,
            period: currentMonthRange().period,
          }
        : {
            starts: AnalyticsTodayDate().AnalyticsDefaultStartDate,
            ends: AnalyticsTodayDate().AnalyticsDefaultEndDate,
          },
    [isPeriodEnabled, currentMonthRange],
  );

  const dateRange = useMemo(() => datePeriod || getPeriod, [
    datePeriod,
    getPeriod,
  ]);

  useMemo(() => {
    if (!datePeriod) {
      setDateRange(getPeriod);
    }
  }, [getPeriod, setDateRange, datePeriod]);

  const request = useMemo(() => new FeedbackRequestImpl(), []);
  const controller = useMemo(() => new FeedbackController(request), [request]);

  const filters = useMemo(() => {
    return {
      ...computedFilters,
      startDate: dateRange.starts,
      endDate: dateRange.ends,
      impression: valueThemeFilter.theme,
    };
  }, [
    computedFilters,
    dateRange.starts,
    dateRange.ends,
    valueThemeFilter.theme,
    // valueThemeFilter.value,
  ]);

  const emptySummary = {
    averageImpression: '',
    impressions: {
      commend: 0,
      commendPercentage: 0,
      neutral: 0,
      neutralPercentage: 0,
      critic: 0,
    },
    requested: 0,
    totalFeedback: 0,
    values: [],
  };

  const getFeedBackReporting = useCallback(async () => {
    const response = await controller.getFeedbackReporting(filters);

    updateTotalPages(Math.round(response?.users?.length / pages.limit) || 0);

    updateTotalResults(response?.users?.length || 0);

    return response;
  }, [filters, controller, updateTotalPages, updateTotalResults, pages.limit]);

  const {
    data = {
      summary: emptySummary,
      users: [],
    },
    isLoading,
  } = useFetchHook(['reporting-feedback', filters], () =>
    getFeedBackReporting(),
  );

  const formatData = data as any;

  const formatPagination = useMemo(() => {
    const pageLimitCount = pages.page * pages.limit;

    return {
      starts: pageLimitCount - pages.limit,
      ends: pageLimitCount,
    };
  }, [pages.limit, pages.page]);

  const sortUser = (
    data: Array<{
      feedback: {
        given: number;
      };
    }>,
  ) => {
    return data?.sort((a, b) => b?.feedback.given - a?.feedback?.given);
  };

  const reportingData = useMemo(() => {
    return {
      ...formatData,
      users: sortUser(formatData?.users)?.slice(
        formatPagination.starts,
        formatPagination.ends,
      ),
    };
  }, [formatPagination, formatData]);

  const {impressions, requested, totalFeedback, values} =
    reportingData?.summary || emptySummary;

  const totalValue = useMemo(() => totalFeedback, [totalFeedback]);

  const pieData = useMemo(
    () =>
      totalValue === 0
        ? [{name: '0', value: 100, color: '#EDEDF2'}]
        : [
            {name: 'commend', value: impressions.commend, color: '#47B881'},
            {name: 'Neutral', value: impressions.neutral, color: '#CECEDE'},
            {name: 'Critic', value: impressions.critic, color: '#FFD5AD'},
          ],
    [totalValue, impressions],
  );

  const feedbackSummary = [
    {
      title: 'Feedback requested',
      value: requested,
    },
    {
      title: 'Feedback received',
      value: totalFeedback,
    },
  ];

  return {
    userFilter,
    setUserFilter,
    isLoading,
    summary: formatData?.summary,
    pieData,
    totalValue,
    feedbackSummary,
    totalResults: formatData?.users?.length || 0,
    impressions,
    users: reportingData?.users || [],
    values,
    controller,
    filters,
    nextPageBtn,
    updateLimit,
    handlePrevBtn,
    dateRange: dateRange || getPeriod,
    goToPage,
    setDateRange,
    paginationPages,
    pages,
    valueThemeFilter,
    setValueThemeFilter,
  };
};
