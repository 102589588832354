import {getColorFromTheme} from '@ui/ui-utils';
import {down, up} from 'styled-breakpoints';
import styled from 'styled-components';

export const TableContainer = styled.div`
  border-top: 1px solid #ededf2;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  -ms-overflow-style: none;

  overflow-x: scroll;
  scrollbar-width: none;
  div::-webkit-scrollbar {
    display: none;
  }
`;

export const ItemLoaderWrapper = styled.div`
  padding: 28px 12px 32px 22px;
  justify-content: center;
  width: 100%;
  border-top: 1px solid #ededf2;
`;

export const ItemWrapperContainer = styled.div``;

export const ItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 21% 20% 13% 12% 13% 12% 8% 2%;
  padding: 16px;
  border-top: 1px solid ${getColorFromTheme('borderLight')};
  align-items: center;
  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const SecondRowWrapper = styled.div`
  width: 100%;

  min-width: 850px;
  overflow-x: scroll;
  display: grid;
  @media (max-width: 800px) {
    max-width: 100%;
    overflow-x: auto;
    display: grid;
    min-width: 900px;
    div.header {
      min-width: 900px;
    }
  }
`;

export const HeadlineItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 35% 20% 18% 18% 15%;
  padding: 16px;
  border-top: 1px solid ${getColorFromTheme('borderLight')};
  align-items: center;
  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const HeadlineWrapper = styled.div`
  display: grid;
  width: 100%;

  grid-template-columns: 35% 20% 17% 18% 14%;
  padding-left: 16px;
`;

export const HeadlineWrapperContainer = styled.div`
  display: block;
  width: 100%;
  padding: 16px 16px 12px 0px;
  background-color: ${getColorFromTheme('backgroundLight')};
  white-space: nowrap;
  height: 52px;
`;

export const ModalWrapper = styled.div`
  max-width: 1300px;
  width: 100vw;
  margin: 0 auto;
  ${down('sm')} {
    width: 92vw;
  }

  ${up('sm')} {
    width: 95vw;
  }

  ${up('lg')} {
    width: 100vw;
  }
`;

export const ModalRenameWrapper = styled.div`
  max-width: 623px;
  width: 80vw;
  margin: 0 auto;
  ${down('sm')} {
    width: 92vw;
  }

  ${up('sm')} {
    width: 95vw;
  }

  ${up('lg')} {
    width: 88vw;
  }

  ${up('xl')} {
    width: 78vw;
  }
`;

export const QuestionIconWrapper = styled.button<{tooltip?: boolean}>`
  outline: none;

  @media (max-width: 500px) {
    display: none;
  }
  .tooltip {
    visibility: hidden;
    width: 224px;
    background-color: #ffffff;
    font-size: 14px;
    color: #5f5f8c;
    text-align: center;
    padding: 16px 16px 16px 26px;
    border: 1px solid #e4e5fb;
    border-radius: 5px;
    position: absolute;
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 1;
    left: 0;
    margin-top: 20px;
  }
  &:hover .tooltip {
    visibility: ${(props: any) => (props.tooltip === true ? 'visible' : '')};
    opacity: 1;
  }

  .tooltip::before {
    content: ' ';
    position: absolute;
    top: -22px;
    left: 11.5%;
    margin-left: -5px;
    border-width: 11px;
    border-style: solid;
    border-color: transparent transparent #e4e5fb transparent;
  }

  .tooltip::after {
    content: ' ';
    position: absolute;
    top: -20px;
    left: 11.7%;
    margin-left: -5px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #fafafa transparent;
  }
`;
