import {observable, action, makeAutoObservable} from 'mobx';

interface ITabs {
  [x: string]: boolean;
}

export class StoreDashboardRoutes {
  @observable tabs: ITabs = {
    home: true,
    objective: false,
    checkins: false,
    feedback: false,
    '1:1': false,
    actionItems: false,
    reportingDashboard: false,
    reportingInsights: false,
    cultureAndCompetence: false,
    reporting: false,
    apps: false,
    people: false,
  };

  @observable InsightTabs: ITabs = {
    insight: true,
    comment: false,
    tasks: false,
  };

  @observable manageReviewCycleTabs: ITabs = {
    overview: true,
    reviewCycles: false,
  };

  @observable CultureCompetenceTabs: ITabs = {
    yourReview: true,
    reviewCycle: false,
    templates: false,
    result: false,
  };

  @observable feedbackTabs: ITabs = {
    publicWall: true,
    myFeedback: false,
    directReports: false,
  };

  @observable companyPerformanceTabs: ITabs = {
    review: true,
    result: false,
  };

  @observable companyCheckinsTabs: ITabs = {
    directReports: true,
    pastCheckins: false,
    questions: false,
  };

  @observable peerReviewTabs: ITabs = {
    nomination: true,
    request: false,
  };

  @observable cycleTabs: ITabs = {
    overview: true,
    peerReviews: false,
    selfReviews: false,
    summary: false,
  };

  @observable GoalViewTabs: ITabs = {
    flat: true,
    nested: false,
    tree: false,
  };

  @observable memberTab: ITabs = {
    active: true,
    invite: false,
    deactivate: false,
  };

  @observable ReviwsCycleTab: ITabs = {
    active: true,
    upcoming: false,
    Draft: false,
    Ended: false,
  };

  @observable peopleTab: ITabs = {
    member: true,
    group: false,
  };

  @observable checkinsQuestionsTabs: ITabs = {
    general: true,
    group: false,
    individual: false,
  };

  @observable AdminPTabs: ITabs = {
    framework: true,
    review: false,
  };

  @observable AdminAnalyticsTabs: ITabs = {
    goals: false,
    status: true,
    ownership: false,
  };

  @observable PRTabs: ITabs = {
    quality: true,
    communication: false,
    output: false,
    teamwork: false,
  };

  @observable contentTabs: ITabs = {
    home: true,
    performance: false,
    highlight: false,
    checkins: false,
  };

  @observable companyTabs: ITabs = {
    reporting: false,
    goals: true,
    performance: false,
    checkins: false,
  };
  @observable inviteTabs: ITabs = {
    manual: true,
    csv: false,
  };

  @observable checkinsTabs: ITabs = {
    questions: false,
    direct_reports: false,
    action_items: false,
    my_checkins: true,
  };

  @observable importGoalTabs: ITabs = {
    import: true,
    history: false,
  };

  @observable adminTabs: ITabs = {
    people: true,
    performance: false,
    analytics: false,
    integrations: false,
  };
  @observable adminIntegrationTabs: ITabs = {
    integrations: true,
  };
  @observable userProfileTabs: ITabs = {
    personal: true,
    security: false,
    apps: false,
    notification: false,
    account: false,
  };

  @observable analyticsDashboardTabs: ITabs = {
    keyresults: false,
    objectives: true,
  };

  @observable performanceSummary: ITabs = {
    summary: true,
    results: false,
  };
  @observable statusTabs: ITabs = {
    Kpi: false,
    people: false,
    goal: true,
  };

  @observable ownershipTabs: ITabs = {
    people: false,
    group: true,
  };

  @observable workspaceTabs: ITabs = {
    performance: false,
    profile: true,
    values: false,
    goalFramework: false,
    feedback: false,
    notifications: false,
    features: false,
    billing: false,
    checkins: false,
  };

  @observable companyReportingTabs: ITabs = {
    goals: false,
    overview: false,
    kpi: false,
    status: false,
    meeting: false,
    actionItems: false,
    feedback: false,
    ownership: true,
    export: false,
  };

  @observable companyGoalsTabs: any = {
    myTeams: false,
    directReports: true,
  };

  @observable goalTemplatesTabs: ITabs = {
    humanResource: false,
    product: false,
    marketing: false,
    people: false,
    operations: false,
    customer_success: false,
    sales: false,
    engineering: false,
    company: true,
    finance: false,
  };

  @observable ObjTabs: ITabs = {
    objective: true,
    kpi: false,
  };

  @observable teamCheckinsTabs: ITabs = {
    submitted: true,
    'not-submitted': false,
  };

  constructor() {
    makeAutoObservable(this);
  }

  @action
  activateTab = (activeTab: string, tabs: ITabs) => {
    Object.keys(tabs).forEach((key) => {
      tabs[key as keyof typeof tabs] = false;
    });

    tabs[activeTab as keyof typeof tabs] = true;
    return tabs;
  };

  @action
  clearDashboardRoutes = () => {
    this.tabs = {
      home: true,
      objective: false,
      checkins: false,
      feedback: false,
      '1:1': false,
      cultureAndCompetence: false,
      reporting: false,
      people: false,
      apps: false,
    };

    this.GoalViewTabs = {
      flat: true,
      nested: false,
      tree: false,
    };

    this.memberTab = {
      active: true,
      invite: false,
      deactivate: false,
    };

    this.peopleTab = {
      member: true,
      group: false,
    };

    this.checkinsQuestionsTabs = {
      general: true,
      group: false,
      individual: false,
    };

    this.AdminPTabs = {
      framework: true,
      review: false,
    };

    this.AdminAnalyticsTabs = {
      goals: true,
      status: false,
      ownership: false,
    };

    this.PRTabs = {
      quality: true,
      communication: false,
      output: false,
      teamwork: false,
    };

    this.userProfileTabs = {
      personal: true,
      security: false,
      notification: false,
      account: false,
    };

    this.contentTabs = {
      home: true,
      performance: false,
      highlight: false,
      checkins: false,
    };

    this.companyTabs = {
      reporting: false,
      goals: true,
      performance: false,
      checkins: false,
    };
    this.importGoalTabs = {
      import: true,
      history: false,
    };

    this.adminTabs = {
      people: true,
      performance: false,
      analytics: false,
      integrations: false,
    };

    this.analyticsDashboardTabs = {
      objectives: true,
      keyresults: false,
    };
    this.statusTabs = {
      goal: true,
      people: false,
      Kpi: false,
    };
    this.ownershipTabs = {
      group: true,
      people: false,
    };
    this.workspaceTabs = {
      profile: true,
      performance: false,
      features: false,
      values: false,
      feedback: false,

      goalFramework: false,
      billing: false,
      checkins: false,
    };

    this.companyReportingTabs = {
      goals: false,
      status: false,
      kpi: false,
      overview: true,
      meeting: false,

      feedback: false,
      ownership: false,
      export: false,
    };
    this.companyGoalsTabs = {
      myTeams: true,
      directReports: false,
    };
    this.goalTemplatesTabs = {
      humanResource: false,
      product: false,
      marketing: false,
      operations: false,
      people: false,

      customer_success: false,
      sales: false,
      engineering: false,
      company: true,
      finance: false,
    };
  };
  @action activateObjTabs = (tab: 'objective' | 'kpi') => {
    const tabs = {
      objective: false,
      kpi: false,
    };
    this.ObjTabs = this.activateTab(tab, tabs);
  };

  @action activateCompanyPerformanceTabs = (tab: 'review' | 'result') => {
    const tabs = {
      review: false,
      result: false,
    };
    this.companyPerformanceTabs = this.activateTab(tab, tabs);
  };

  @action activateCompanyCheckinsTabs = (
    tab: 'directReports' | 'pastCheckins' | 'questions',
  ) => {
    const tabs = {
      directReports: false,
      pastCheckins: false,
      questions: false,
    };
    this.companyCheckinsTabs = this.activateTab(tab, tabs);
  };
  @action activateCheckinsTabs = (
    tab: 'direct_reports' | 'questions' | 'my_checkins' | 'action_items',
  ) => {
    const tabs = {
      questions: false,
      direct_reports: false,
      action_items: false,
      my_checkins: false,
    };
    this.checkinsTabs = this.activateTab(tab, tabs);
  };
  @action activatePerformanceSummary = (tab: 'summary' | 'results') => {
    const tabs = {
      summary: false,
      results: false,
    };
    this.performanceSummary = this.activateTab(tab, tabs);
  };

  @action activateInviteTabs = (tab: 'csv' | 'manual') => {
    const tabs = {
      manual: false,
      csv: false,
    };
    this.inviteTabs = this.activateTab(tab, tabs);
  };

  @action activatePeerReviewTab = (tab: 'nomination' | 'request') => {
    const tabs = {
      nomination: false,
      request: false,
    };
    this.peerReviewTabs = this.activateTab(tab, tabs);
  };
  @action activateInsightTabs = (tab: 'insight' | 'comment' | 'tasks') => {
    const tabs = {
      insight: false,
      comment: false,
      tasks: false,
    };
    this.InsightTabs = this.activateTab(tab, tabs);
  };

  @action
  activateAnalyticsDashboardTab = (tab: 'keyresults' | 'objectives') => {
    const tabs = {
      keyresults: false,
      objectives: false,
    };

    this.analyticsDashboardTabs = this.activateTab(tab, tabs);
  };
  @action activateCycleTab = (
    tab: 'overview' | 'peerReviews' | 'selfReviews' | 'summary',
  ) => {
    const tabs = {
      overview: false,
      peerReviews: false,
      selfReviews: false,
      summary: false,
    };
    this.cycleTabs = this.activateTab(tab, tabs);
  };

  @action
  activateStatusTab = (tab: 'Kpi' | 'goal' | 'people') => {
    const tabs = {
      Kpi: false,
      people: false,
      goal: false,
    };
    this.statusTabs = this.activateTab(tab, tabs);
  };
  @action
  activateOwnershipTab = (tab: 'people' | 'group') => {
    const tabs = {
      people: false,
      group: false,
    };
    this.ownershipTabs = this.activateTab(tab, tabs);
  };

  @action
  activateFeedbackTabs = (
    tab: 'directReports' | 'publicWall' | 'myFeedback',
  ) => {
    const tabs = {
      publicWall: false,
      myFeedback: false,
      directReports: false,
    };
    this.feedbackTabs = this.activateTab(tab, tabs);
  };

  @action
  activateContentTab = (
    tab: 'highlight' | 'performance' | 'checkins' | 'home',
  ) => {
    const tabs = {
      home: false,
      performance: false,
      highlight: false,
      checkins: false,
    };
    this.contentTabs = this.activateTab(tab, tabs);
  };

  @action activateTeamCheckinsTab = (tab: 'submitted' | 'not-submitted') => {
    const tabs = {
      submitted: false,
      'not-submitted': false,
    };
    this.teamCheckinsTabs = this.activateTab(tab, tabs);
  };
  @action
  activateAdminPerformanceTab = (tab: 'framework' | 'review') => {
    const tabs = {
      framework: false,
      review: false,
    };
    this.AdminPTabs = this.activateTab(tab, tabs);
  };
  @action
  activateManageReviewCycleTabs = (tab: 'overview' | 'reviewCycles') => {
    const tabs = {
      overview: true,
      reviewCycles: false,
    };
    this.manageReviewCycleTabs = this.activateTab(tab, tabs);
  };

  @action
  activateAdminAnalyticsTab = (tab: 'ownership' | 'status' | 'goals') => {
    const tabs = {
      goals: false,
      status: false,
      ownership: false,
    };
    this.AdminAnalyticsTabs = this.activateTab(tab, tabs);
  };
  @action
  activateGoalViewTab = (tab: 'flat' | 'nested' | 'tree') => {
    const tabs = {
      flat: false,
      nested: false,
      tree: false,
    };
    this.GoalViewTabs = this.activateTab(tab, tabs);
  };

  @action
  activateUserProfileTab = (
    tab: 'security' | 'account' | 'personal' | 'notification' | 'apps',
  ) => {
    const tabs = {
      personal: true,
      security: false,
      apps: false,
      notification: false,
      account: false,
    };
    this.userProfileTabs = this.activateTab(tab, tabs);
  };

  @action
  activateCompanyTab = (
    tab: 'reporting' | 'goals' | 'performance' | 'checkins' | '',
  ) => {
    const tabs = {
      reporting: false,
      goals: false,
      performance: false,
      checkins: false,
    };
    this.companyTabs = this.activateTab(tab, tabs);
  };
  @action
  activateImportGoalTab = (tab: 'import' | 'history') => {
    const tabs = {
      import: true,
      history: false,
    };
    this.importGoalTabs = this.activateTab(tab, tabs);
  };

  @action
  activateAdminTab = (
    tab: 'people' | 'performance' | 'analytics' | 'integrations',
  ) => {
    const tabs = {
      people: true,
      performance: false,
      analytics: false,
      integrations: false,
    };
    this.adminTabs = this.activateTab(tab, tabs);
  };

  @action
  activateMemberTab = (tab: 'deactivate' | 'active' | 'invite') => {
    const tabs = {
      active: true,
      invite: false,
      deactivate: false,
    };
    this.memberTab = this.activateTab(tab, tabs);
  };

  @action
  activateReviewCycleTab = (tab: 'upcoming' | 'active' | 'draft' | 'ended') => {
    const tabs = {
      active: true,
      upcoming: false,
      Draft: false,
      Ended: false,
    };
    this.ReviwsCycleTab = this.activateTab(tab, tabs);
  };
  ///
  @action
  activatePeopleTab = (tab: 'member' | 'group') => {
    const tabs = {
      member: true,
      group: false,
    };

    this.peopleTab = this.activateTab(tab, tabs);
  };
  @action
  activateCultureAndCompetenceTab = (
    tab: 'yourReview' | 'reviewCycle' | 'templates' | 'result',
  ) => {
    const tabs = {
      yourReview: false,
      reviewCycle: false,
      templates: false,
      result: false,
    };
    this.CultureCompetenceTabs = this.activateTab(tab, tabs);
  };
  @action
  activateCheckinsQuestionsTabs = (tab: 'general' | 'group' | 'individual') => {
    const tabs = {
      general: false,
      group: false,
      individual: false,
    };
    this.checkinsQuestionsTabs = this.activateTab(tab, tabs);
  };
  ////

  @action
  activateWorkspaceTab = (
    tab:
      | 'profile'
      | 'performance'
      | 'performance2'
      | 'values'
      | 'feedback'
      | 'goalFramework'
      | 'billing'
      | 'features'
      | 'checkins'
      | 'notifications',
  ) => {
    const tabs = {
      performance: false,
      profile: false,
      values: false,
      goalFramework: false,
      feedback: false,
      notifications: false,
      features: false,
      billing: false,
      checkins: false,
    };

    this.workspaceTabs = this.activateTab(tab, tabs);
  };

  @action
  activatePerformanceReviewTab = (
    tab: 'teamwork' | 'quality' | 'output' | 'communication',
  ) => {
    const tabs = {
      quality: false,
      communication: false,
      output: false,
      teamwork: false,
    };
    this.PRTabs = this.activateTab(tab, tabs);
  };

  @action
  activateDashboardTab = (
    tab:
      | 'home'
      | 'objective'
      | 'checkins'
      | 'actionItems'
      | 'cultureAndCompetence'
      | 'reporting'
      | 'feedback'
      | 'reportingInsights'
      | 'reportingDashboard'
      | 'people'
      | 'apps'
      | '1:1',
  ) => {
    const tabs = {
      home: false,
      objective: false,
      checkins: false,
      feedback: false,
      '1:1': false,
      actionItems: false,
      reportingInsights: false,
      reportingDashboard: false,
      cultureAndCompetence: false,
      reporting: false,
      apps: false,
      people: false,
    };
    this.tabs = this.activateTab(tab, tabs);
  };

  @action
  activateCompanyReportingTab = (
    tab:
      | 'status'
      | 'kpi'
      | 'goals'
      | 'ownership'
      | 'export'
      | 'feedback'
      | 'meeting'
      | 'action-items'
      | 'overview',
  ) => {
    const tabs = {
      goals: false,
      overview: false,
      kpi: false,
      status: false,
      meeting: false,
      actionItems: false,
      feedback: false,
      ownership: false,
      export: false,
    };
    this.companyReportingTabs = this.activateTab(tab, tabs);
  };

  @action
  activateCompanyGoalsTab = (tab: 'myTeams' | 'directReports' | string) => {
    const tabs = {
      myTeams: false,
      directReports: false,
    };
    this.companyGoalsTabs = this.activateTab(tab, tabs);
  };

  @action
  activateGoalTemplatesTab = (
    tab:
      | 'humanResource'
      | 'product'
      | 'operations'
      | 'marketing'
      | 'customer_success'
      | 'sales'
      | 'engineering'
      | 'people'
      | 'company'
      | 'finance',
  ) => {
    const tabs = {
      humanResource: false,
      product: false,
      marketing: false,
      operations: false,
      people: false,

      customer_success: false,
      sales: false,
      engineering: false,
      company: false,
      finance: false,
    };
    this.goalTemplatesTabs = this.activateTab(tab, tabs);
  };
}

export const storeDashboardRoutesInit = new StoreDashboardRoutes();
