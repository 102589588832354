import {memo} from 'react';

export const NestedViewIcon = memo(({width, height}: any) => (
  <svg
    width={`${width ? width : 24}`}
    height={`${height ? height : 24}`}
    strokeWidth={1.5}
    viewBox={`${width ? `0 0 22 24` : '0 0 24 24'}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.9172 6.20002L12.7082 0.92202C12.4893 0.808984 12.2466 0.75 12.0002 0.75C11.7539 0.75 11.5111 0.808984 11.2922 0.92202L1.08321 6.20002C0.982944 6.25195 0.89888 6.33043 0.840201 6.4269C0.781523 6.52337 0.750488 6.63411 0.750488 6.74702C0.750488 6.85993 0.781523 6.97067 0.840201 7.06714C0.89888 7.16361 0.982944 7.2421 1.08321 7.29402L11.2922 12.575C11.5111 12.6881 11.7539 12.747 12.0002 12.747C12.2466 12.747 12.4893 12.6881 12.7082 12.575L22.9172 7.30002C23.0175 7.2481 23.1015 7.16961 23.1602 7.07314C23.2189 6.97667 23.2499 6.86593 23.2499 6.75302C23.2499 6.64011 23.2189 6.52937 23.1602 6.4329C23.1015 6.33643 23.0175 6.25795 22.9172 6.20602V6.20002Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.10021 9.375L1.08321 11.453C0.982944 11.5049 0.89888 11.5834 0.840201 11.6799C0.781523 11.7763 0.750488 11.8871 0.750488 12C0.750488 12.1129 0.781523 12.2237 0.840201 12.3201C0.89888 12.4166 0.982944 12.4951 1.08321 12.547L11.2922 17.828C11.5111 17.941 11.7539 18 12.0002 18C12.2466 18 12.4893 17.941 12.7082 17.828L22.9172 12.547C23.0175 12.4951 23.1015 12.4166 23.1602 12.3201C23.2189 12.2237 23.2499 12.1129 23.2499 12C23.2499 11.8871 23.2189 11.7763 23.1602 11.6799C23.1015 11.5834 23.0175 11.5049 22.9172 11.453L18.9002 9.375"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.10021 14.625L1.08321 16.7C0.982944 16.7519 0.89888 16.8304 0.840201 16.9269C0.781523 17.0233 0.750488 17.1341 0.750488 17.247C0.750488 17.3599 0.781523 17.4707 0.840201 17.5671C0.89888 17.6636 0.982944 17.7421 1.08321 17.794L11.2922 23.075C11.5111 23.188 11.7539 23.247 12.0002 23.247C12.2466 23.247 12.4893 23.188 12.7082 23.075L22.9172 17.8C23.0175 17.7481 23.1015 17.6696 23.1602 17.5731C23.2189 17.4767 23.2499 17.3659 23.2499 17.253C23.2499 17.1401 23.2189 17.0293 23.1602 16.9329C23.1015 16.8364 23.0175 16.7579 22.9172 16.706L18.9002 14.625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

NestedViewIcon.displayName = 'NestedViewIcon';
