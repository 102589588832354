import {ComponentPropsWithRef, forwardRef, Ref} from 'react';
import {Body1} from '../../typography/body1';
import {IconWrapper, Input, Label, Wrapper} from '../shared';
import {PercentIcon} from '../../icons/percent';
import {InputState} from '../../../interface';

export interface TPercentageInputProps extends ComponentPropsWithRef<'input'> {
  state?: InputState;
  label: string;
  disabled?: boolean;
}

export const PercentageInput = forwardRef(
  (
    {label, id, disabled, state, ...props}: TPercentageInputProps,
    ref: Ref<HTMLInputElement>,
  ) => {
    return (
      <Wrapper htmlFor={id} disabled={disabled} state={state}>
        <Label disabled={disabled}>
          <Body1>{label}</Body1>
        </Label>
        <Input {...props} ref={ref} id={id} disabled={disabled} type="number" />
        <IconWrapper disabled={disabled}>
          <PercentIcon />
        </IconWrapper>
      </Wrapper>
    );
  },
);
PercentageInput.displayName = 'PercentageInput';

export const PercentageInputNoIcon = forwardRef(
  (
    {label, id, disabled, state, ...props}: TPercentageInputProps,
    ref: Ref<HTMLInputElement>,
  ) => {
    return (
      <Wrapper htmlFor={id} disabled={disabled} state={state}>
        <Label disabled={disabled}>
          <Body1 style={{margin: '0 12px'}}>{label}</Body1>
        </Label>
        <Input
          {...props}
          ref={ref}
          id={id}
          disabled={disabled}
          type="number"
          style={{paddingLeft: '16px'}}
        />
      </Wrapper>
    );
  },
);
PercentageInputNoIcon.displayName = 'PercentageInputNoIcon';

export const KpiPercentageInputNoIcon = forwardRef(
  (
    {label, id, disabled, state, placeholder, ...props}: TPercentageInputProps,
    ref: Ref<HTMLInputElement>,
  ) => {
    return (
      <Wrapper htmlFor={id} disabled={disabled} state={state}>
        <Label disabled={disabled}>
          <Body1 style={{margin: '0 12px'}}>{label}</Body1>
        </Label>
        <div style={{position: 'relative'}}>
          <Input
            {...props}
            ref={ref}
            id={id}
            disabled={disabled}
            type="number"
            onWheelCapture={(e) => {
              e.currentTarget.blur();
            }}
            style={{paddingLeft: '16px'}}
            placeholder={placeholder}
          />
          {/* <Body1
            kind="textBody"
            align="center"
            style={{
              position: 'absolute',
              zIndex: 0,
              left: '50%',
              marginLeft: '-25%',
              top: '50%',
              marginTop: '-8%',
            }}>
            {placeholder}
          </Body1> */}
        </div>
      </Wrapper>
    );
  },
);
KpiPercentageInputNoIcon.displayName = 'KpiPercentageInputNoIcon';
