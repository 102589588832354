import {Body1, Body2, Headline2} from '@ui/atoms/typography';
import {SecondaryPlusIcon} from '@ui/atoms/icons/plus';
import {
  SecondRowWrapper,
  HeadlineWrapper,
  HeadlineWrapperContainer,
  EmptyWrapper,
  ItemWrapper,
  TableContainer,
} from '../performance-settings-style';
import {FlexRow, FlexRowSpaceBetween, Flex} from '@ui/style/styles';
import {MoreVerticalIcon} from '@ui/atoms/icons';
import {capitalize} from '@utils';
import {SetupWorkspaceRequestImpl} from '@pages/admin/setup-workspace/setup-workspace-request';
import {SetupWorkspaceController} from '@pages/admin/setup-workspace/setup-workspace-controller';
import {useStoreContext} from '@store/store-context';
import {activateNotification} from '@ui/molecules/notification/activate-notification';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {BackAction} from '@ui/molecules/back-action';
import {Button} from '@ui/atoms/button';
import {generateRandomDigits} from '@utils/generate-random-numbers';
import {CancelIcon} from '@ui/atoms/icons';
import {ModalCard} from '@ui/layouts/modal-card';
import {CustomColorIcon} from '@ui/atoms/color-icons';
import {DropdownVerticalSecondary} from '@ui/molecules/dropdown-vertical';
import {useNavigate} from 'react-router';
import {Modal} from '@ui/molecules/modal';
import {NoBorderButton} from '@pages/dashboard/reporting/reporting-pages/reporting-overview/styles';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {FC, useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {setFirebaseData} from '@utils/firebase-handler';
interface IValues {}

export const ValueCategories: FC<IValues> = observer(() => {
  const {
    authStore: {auth},
  } = useStoreContext();

  const [companyValues, setCompanyValues] = useState<string[]>(
    auth?.user?.workspace?.settings?.companyValues?.values || [],
  );

  useEffect(() => {
    setCompanyValues(auth?.user?.workspace?.settings?.companyValues?.values);
  }, [auth?.user?.workspace?.settings?.companyValues?.values]);

  const [showDeleteModal, setShowDeleteModal] = useState('');

  const valuesExists = companyValues.length > 0;

  const navigate = useNavigate();

  const handleDelete = async (value: string) => {
    const request = new SetupWorkspaceRequestImpl();
    const controller = new SetupWorkspaceController(request);
    const computeValues = companyValues.filter((_value) => _value !== value);

    const response = await controller.workspaceEdit(
      auth?.user?.workspace?.id,
      {
        settings: {
          ...auth?.user?.workspace?.settings,
          companyValues: {
            status: 'enabled',
            values: computeValues,
          },
        },
      },
      auth?.tokens?.access?.token,
    );
    response && setShowDeleteModal('');
    response && setCompanyValues(computeValues);

    response &&
      activateNotification({
        title: `Success`,
        content: `Value  deleted`,
        kind: 'success',
      });

    response && setFirebaseData(`updates/settings`, generateRandomDigits(16));
  };

  const addCategory = (
    <NoBorderButton onClick={() => navigate('/create-review-values')}>
      <FlexRow>
        <SecondaryPlusIcon />
        <Body2 weight="bold" kind="purple300" style={{marginLeft: '4px'}}>
          Add values
        </Body2>
      </FlexRow>
    </NoBorderButton>
  );

  const EmptyCategories = () => {
    return (
      <>
        <Body1 weight="bold">Add values</Body1>
        <VerticalSpacer size="10px" />
        <Body2 kind="textBody" align="center">
          Add umbrella classifications for attributes, skills, and knowledge{' '}
          <br />
          that are required for putting in suitable performance.
        </Body2>
      </>
    );
  };

  return (
    <div>
      <FlexRowSpaceBetween>
        <Body1 weight="bold">Values categories ({companyValues.length})</Body1>
        {addCategory}
      </FlexRowSpaceBetween>
      <VerticalSpacer size="12px" />
      <TableContainer>
        <div>
          <SecondRowWrapper>
            <HeadlineWrapperContainer className="header">
              <HeadlineWrapper>
                <Body2 kind="textBody">Type</Body2>
                <Body2 kind="textBody">Actions</Body2>
              </HeadlineWrapper>
            </HeadlineWrapperContainer>
            {!valuesExists && (
              <EmptyWrapper>
                <EmptyCategories />
              </EmptyWrapper>
            )}
            {valuesExists &&
              companyValues.map((category, index) => (
                <ItemWrapper key={index} addBorderRadius={!index}>
                  <Body2 weight="semibold">{capitalize(category)}</Body2>
                  <DropdownVerticalSecondary
                    customIcon={<MoreVerticalIcon />}
                    menu={
                      <div>
                        <DropdownItem
                          onClick={() => setShowDeleteModal(category)}>
                          Delete
                        </DropdownItem>
                      </div>
                    }
                  />
                </ItemWrapper>
              ))}
          </SecondRowWrapper>
        </div>
      </TableContainer>
      <Modal open={!!showDeleteModal} onClose={() => {}}>
        <div style={{maxWidth: '500px', margin: 'auto'}}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}>
            <BackAction
              width="md"
              icon={<CancelIcon />}
              onClick={() => {
                setShowDeleteModal('');
              }}
              title="Close"
            />
          </div>
          <VerticalSpacer size="16px" />
          <ModalCard align="center" title={``}>
            <Flex alignItems="center" justifyContent="center" flexDir="column">
              <VerticalSpacer size="16px" />
              <Flex alignItems="center" justifyContent="center">
                <CustomColorIcon
                  color="#F7E4E4"
                  width="80px"
                  margin={0}
                  height="80px"
                />
              </Flex>
              <VerticalSpacer size="20px" />
              <span>
                <Headline2 align="center">Delete value?</Headline2>
              </span>
              <VerticalSpacer size="16px" />

              <Body1 align="center" kind="textBody">
                Are you sure you want to delete{' '}
                <span style={{color: '#585ADF', fontWeight: 500}}>
                  {showDeleteModal}
                </span>
                ? This cannot be undone.
              </Body1>
              <VerticalSpacer size="32px" />
              <Button
                onClick={() => handleDelete(showDeleteModal)}
                width="full"
                kind="secondary">
                Delete
              </Button>
            </Flex>
          </ModalCard>
        </div>
      </Modal>
    </div>
  );
});
