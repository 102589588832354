import {useCallback, useState, useLayoutEffect, useMemo} from 'react';
import {TransitionType} from './interface';
import {useEventListener} from './event-listener';

interface Props {
  children: React.ReactNode[];
  onStepChange?: (stats: {previousStep: number; activeStep: number}) => void;
  initialStep?: number;
}

export function useWizard({children, initialStep, onStepChange}: Props) {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [transitions, setTransitions] = useState<
    Record<string, TransitionType>
  >({});
  useLayoutEffect(() => {
    if (initialStep && children[initialStep - 1]) {
      setActiveStep(initialStep - 1);
    }
  }, [children, initialStep]);

  const getSteps = useCallback(() => children.filter((el) => el), [children]);

  const totalSteps = useMemo(() => {
    return getSteps().length;
  }, [getSteps]);

  const isInvalidStep = useCallback(
    (next: number) => next < 0 || next >= totalSteps,
    [totalSteps],
  );

  const currentStep = useMemo(() => {
    return activeStep + 1;
  }, [activeStep]);

  const onHandleStepChange = useCallback(
    (stats: {previousStep: number; activeStep: number}) => {
      if (onStepChange) {
        onStepChange(stats);
      }
    },
    [onStepChange],
  );

  const onHandleStep = useCallback(
    (next) => {
      const active = activeStep;
      if (active === next) return;
      if (isInvalidStep(next)) {
        console.error(`${next + 1} is an invalid step`);
        return;
      }

      if (active < next) {
        transitions[active] = TransitionType.FADE_OUT_LEFT;
        transitions[next] = TransitionType.FADE_IN_RIGHT;
      } else {
        transitions[active] = TransitionType.FADE_OUT_RIGHT;
        transitions[next] = TransitionType.FADE_IN_LEFT;
      }

      setActiveStep(next);
      setTransitions({...transitions});
      onHandleStepChange({
        previousStep: active + 1,
        activeStep: next + 1,
      });
    },
    [activeStep, isInvalidStep, transitions, onHandleStepChange],
  );

  const goToStep = useCallback(
    (step: number) => {
      onHandleStep(step - 1);
    },
    [onHandleStep],
  );

  const firstStep = useCallback(() => {
    goToStep(1);
  }, [goToStep]);

  const lastStep = useCallback(() => goToStep(totalSteps), [
    goToStep,
    totalSteps,
  ]);

  const nextStep = useCallback(() => {
    onHandleStep(activeStep + 1);
  }, [activeStep, onHandleStep]);

  const previousStep = useCallback(() => {
    onHandleStep(activeStep - 1);
  }, [activeStep, onHandleStep]);

  const tabListener = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Tab') {
        const element = e.target as HTMLElement;
        const isButton = element.tagName === 'BUTTON';
        const isSubmit = (element as HTMLButtonElement).type === 'submit';
        const isFormAction =
          element.dataset['formAction'] || element.getAttribute('formAction');
        if (isButton && !isSubmit && isFormAction) {
          nextStep();
          return;
        }
        return;
      }
    },
    [nextStep],
  );

  useEventListener('keyup', tabListener, document);

  return {
    previousStep,
    lastStep,
    firstStep,
    goToStep,
    currentStep,
    totalSteps,
    nextStep,
    transitions,
    getSteps,
    activeStep,
  };
}
