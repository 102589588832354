import dayjs from 'dayjs';
import {StatusType} from '@hooks';
import {ProgressTracker} from '@ui/molecules/progress-tracker';
import {getColorFromTheme} from '@ui/ui-utils';
import {HorizontalSideRule} from '@ui/atoms/spacer/spacer';
import {FlexRow} from '@ui/style/styles';
import {goalDueDate} from '@utils/date';
import {TbCurrencyNaira} from 'react-icons/tb';
import {MeasurementType} from '@hooks';
import {CurrencyType} from '@hooks';
import ReactDOMServer from 'react-dom/server';
import {ObjectiveTitle} from './powerpoint-objectives';
import {Body2} from '@ui/atoms/typography';
import styled from 'styled-components';
const commaNumber = require('comma-number');

const WrapperChildKeyResults = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 45% 35% 30%;
  grid-gap: 16px;
  width: 100%;
  margin-top: 24px;
`;
export const SecondWrapperChildGoals = styled.div<{
  removeBorder?: boolean;
}>`
  display: grid;
  align-items: center;
  grid-template-columns: 45% 35% 15%;
  grid-gap: 16px;
  width: 100%;
  font-size: 14px;

  color: ${getColorFromTheme('textDark')};

  padding: 16px 16px 0px;

  p {
    overflow: hidden;
    white-space: nowrap;
    line-height: 30px;
    text-overflow: ellipsis;
  }
`;

const Wrapper = styled.div`
  .keyResults {
    border-top: 1px solid #ededf2;
    margin: 0px 24px 16px;
  }
  border-bottom: 1px solid #ededf2;
`;
export const PowerpointKeyResult = ({goal}: {goal: any}) => {
  const isGoalEnded =
    goal.status === StatusType.COMPLETED ||
    goal.status === StatusType.INCOMPLETED;
  const goalIsPastDue = goalDueDate(goal.endDate) === 'Past due';

  const formatGoalDateRange =
    dayjs(goal.startDate).format('DD. MMM YY') +
    '  -  ' +
    dayjs(goal.endDate).format('DD. MMM YY');

  const parseCurrencyValue = (currencyValue: string) => {
    if (currencyValue === 'NGN') {
      return ReactDOMServer.renderToString(
        <span style={{marginTop: '15px'}}>
          {' '}
          <TbCurrencyNaira color="#5f5f8c" style={{paddingTop: '4px'}} />
        </span>,
      );
    } else if (currencyValue === 'EUR') {
      return CurrencyType.EURO;
    } else if (currencyValue === 'GBP') {
      return CurrencyType.POUND;
    } else if (currencyValue === 'USD') {
      return CurrencyType.DOLLAR;
    } else return CurrencyType.DOLLAR;
  };
  const getProgressText = (keyResult: any) => {
    return `(${
      keyResult?.measurement?.unit.toUpperCase() === MeasurementType.CURRENCY &&
      keyResult?.measurement?.symbol
        ? parseCurrencyValue(keyResult?.measurement?.symbol)
        : keyResult?.measurement?.unit.toUpperCase() ===
            MeasurementType.CURRENCY && !keyResult?.measurement?.symbol
        ? parseCurrencyValue('')
        : ''
    }${commaNumber(
      keyResult?.currentValue && keyResult?.measurement?.unit !== 'BINARY'
        ? keyResult?.currentValue
        : keyResult?.currentValue === 1 &&
          keyResult?.measurement?.unit === 'BINARY'
        ? 'complete'
        : !keyResult?.currentValue && keyResult?.measurement?.unit === 'BINARY'
        ? 'Incomplete'
        : keyResult?.currentValue === 0 &&
          keyResult?.measurement?.unit === 'BINARY'
        ? 'Incomplete'
        : 0,
    )}${
      keyResult?.measurement?.unit.toUpperCase() === MeasurementType.PERCENTAGE
        ? '%'
        : ''
    }
            ${
              !keyResult?.currentValue &&
              keyResult?.measurement?.unit === 'BINARY'
                ? ''
                : keyResult?.currentValue === 1 &&
                  keyResult?.measurement?.unit === 'BINARY'
                ? ''
                : 'of'
            }
            ${
              keyResult?.measurement?.unit.toUpperCase() ===
                MeasurementType.CURRENCY && keyResult?.measurement?.symbol
                ? parseCurrencyValue(keyResult?.measurement?.symbol)
                : keyResult?.measurement?.unit.toUpperCase() ===
                    MeasurementType.CURRENCY && !keyResult?.measurement?.symbol
                ? parseCurrencyValue('')
                : ''
            }${commaNumber(
      keyResult?.targetValue === 1 && keyResult?.measurement?.unit === 'BINARY'
        ? ''
        : keyResult?.targetValue,
    )}
            ${
              keyResult?.measurement?.unit.toUpperCase() ===
              MeasurementType.PERCENTAGE
                ? '%'
                : ''
            })
            `;
  };
  return (
    <Wrapper>
      <SecondWrapperChildGoals removeBorder>
        <div>
          <ObjectiveTitle
            goalGroups={goal.group}
            goalType={
              goal.goalType === 'group'
                ? ``
                : goal.goalType === 'company'
                ? 'Company-wide'
                : goal.goalType
            }
            title={goal.name}
          />
          <FlexRow style={{marginLeft: '8px'}}>
            {(isGoalEnded || goalIsPastDue) && (
              <FlexRow>
                <Body2
                  kind={goalIsPastDue && !isGoalEnded ? 'red400' : 'textBody'}
                  weight="regular"
                  style={{fontSize: '9pt'}}>
                  {isGoalEnded ? 'Ended' : 'Past due'}{' '}
                </Body2>
                <HorizontalSideRule size={'15px'} />
              </FlexRow>
            )}
            <Body2
              kind="textBody"
              style={{fontSize: '9pt', width: '100%'}}
              weight="regular">
              {formatGoalDateRange}
            </Body2>
          </FlexRow>
        </div>

        <Body2 weight="semibold" style={{fontSize: '12pt'}}>
          {goal.assignee?.firstName
            ? goal.assignee?.firstName + ' ' + goal.assignee.lastName
            : goal.assignee?.email}
        </Body2>
        <div>
          <ProgressTracker
            due={goalDueDate(goal?.endDate)}
            type={goal?.performance}
            removePill
            percentStyle={{fontSize: '10pt'}}
            state={`${goal?.percentageProgress}%`}
            percent={goal?.percentageProgress}
            status={goal?.status}
          />
        </div>
      </SecondWrapperChildGoals>
      <div className="keyResults">
        {goal?.keyResults &&
          goal?.keyResults.map((keyResult: any, index: number) => (
            <WrapperChildKeyResults key={index}>
              <Body2 kind="textBody" style={{fontSize: '12pt'}}>
                {keyResult.name}
              </Body2>
              <Body2 weight="semibold" style={{fontSize: '12pt'}}>
                {keyResult.assignee?.firstName
                  ? keyResult.assignee?.firstName +
                    ' ' +
                    keyResult.assignee.lastName
                  : keyResult.assignee?.email}
              </Body2>
              <Body2
                kind="textBody"
                dangerouslySetInnerHTML={{__html: getProgressText(keyResult)}}
              />
            </WrapperChildKeyResults>
          ))}
      </div>
    </Wrapper>
  );
};
