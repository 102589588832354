import {AxiosResponse} from 'axios';
import {BaseRequest} from '../../../../../../configs/request';
import {authStore} from '../../../../../../store/stores/auth-store';
import {
  IAdminBillingEditSubscriptionFormData,
  IAdminBillingFormData,
  IAdminBillingResponse,
} from './admin-billing.interface';

export interface AdminBillingRequest extends BaseRequest {
  url: string;
  fetchAllBillingPlans(): Promise<IAdminBillingResponse>;
  fetchAllBillingSubscriptions(): Promise<IAdminBillingResponse>;
  createBillingSubscription(
    data: IAdminBillingFormData,
    accessToken?: string,
  ): Promise<IAdminBillingResponse>;
  fetchSpecificBillingSubscription(id?: string): Promise<IAdminBillingResponse>;
  editSpecificBillingSubscription(
    id: string,
    data: IAdminBillingEditSubscriptionFormData,
  ): Promise<IAdminBillingResponse>;
  cancelSpecificBillingSubscription(
    id?: string,
  ): Promise<IAdminBillingResponse>;
  createBillingMethod(data: {
    paymentId: string;
  }): Promise<IAdminBillingResponse>;
  getAllBillingMethods(): Promise<IAdminBillingResponse>;
  cancelSubscription(): Promise<any>;
  retrySubscription(): Promise<any>;
  restartSubscription(): Promise<any>;
  switchSubscription(plan: string): Promise<any>;
  getSpecificBillingMethodById(
    paymentId?: string,
  ): Promise<IAdminBillingResponse>;
  removeSpecificBillingMethodById(
    paymentId?: string,
  ): Promise<IAdminBillingResponse>;
  getAllValidCoupons(): Promise<IAdminBillingResponse>;
  validateCouponCode<IAdminBillingResponse>(data: {
    code: string;
  }): Promise<IAdminBillingResponse>;
  fetchNextCycle(): Promise<IAdminBillingResponse>;
}

export class AdminBillingRequestImpl
  extends BaseRequest
  implements AdminBillingRequest {
  url: string = `/billing`;

  async fetchAllBillingPlans<IAdminBillingResponse>() {
    const {data: responseData} = await this.request.get<
      any,
      AxiosResponse<IAdminBillingResponse>
    >(`${this.url}/plans`, {
      headers: {
        Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
      },
    });
    return responseData;
  }
  async fetchAllBillingSubscriptions<IAdminBillingResponse>() {
    const {data: responseData} = await this.request.get<
      any,
      AxiosResponse<IAdminBillingResponse>
    >(`${this.url}/subscriptions?sortBy=createdAt:desc`, {
      headers: {
        Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        useCache: false,
      },
    });
    return responseData;
  }
  async createBillingSubscription<IAdminBillingResponse>(
    data: IAdminBillingFormData,
    accessToken?: string,
  ) {
    const {data: responseData} = await this.request.post<
      any,
      AxiosResponse<IAdminBillingResponse>
    >(`${this.url}/subscriptions`, data, {
      headers: {
        Authorization: `Bearer ${
          accessToken || authStore.auth?.tokens.access.token
        }`,
      },
    });
    return responseData;
  }
  async fetchSpecificBillingSubscription<IAdminBillingResponse>(id?: string) {
    const {data: responseData} = await this.request.get<
      any,
      AxiosResponse<IAdminBillingResponse>
    >(`${this.url}/subscriptions/${id}`, {
      headers: {
        Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
      },
    });
    return responseData;
  }
  async editSpecificBillingSubscription<IAdminBillingResponse>(
    id: string,
    data: IAdminBillingEditSubscriptionFormData,
  ) {
    const {data: responseData} = await this.request.patch<
      any,
      AxiosResponse<IAdminBillingResponse>
    >(`${this.url}/subscriptions/${id}`, data, {
      headers: {
        Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
      },
    });
    return responseData;
  }
  async cancelSpecificBillingSubscription<IAdminBillingResponse>(id?: string) {
    const {data: responseData} = await this.request.delete<
      any,
      AxiosResponse<IAdminBillingResponse>
    >(`${this.url}/subscriptions/${id}`, {
      headers: {
        Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
      },
    });
    return responseData;
  }

  async createBillingMethod<IAdminBillingResponse>(data: {paymentId: string}) {
    const {data: responseData} = await this.request.post<
      any,
      AxiosResponse<IAdminBillingResponse>
    >(`${this.url}/payment-methods`, data, {
      headers: {
        Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
      },
    });
    return responseData;
  }
  async cancelSubscription() {
    const {data: responseData} = await this.request.post<any, AxiosResponse>(
      `${this.url}/subscriptions/cancel`,
      {},
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return responseData;
  }
  async restartSubscription() {
    const {data: responseData} = await this.request.post<any, AxiosResponse>(
      `${this.url}/subscriptions/revert-cancel`,
      {},
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return responseData;
  }

  async retrySubscription() {
    const {data: responseData} = await this.request.post<any, AxiosResponse>(
      `${this.url}/subscriptions/retry-payment`,
      {},
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return responseData;
  }

  async switchSubscription(plan: string) {
    const {data: responseData} = await this.request.post<any, AxiosResponse>(
      `${this.url}/subscriptions/switch`,
      {plan},
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return responseData;
  }
  async getAllBillingMethods<IAdminBillingResponse>() {
    const {data: responseData} = await this.request.get<
      any,
      AxiosResponse<IAdminBillingResponse>
    >(`${this.url}/payment-methods`, {
      headers: {
        Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
      },
    });
    return responseData;
  }
  async getSpecificBillingMethodById<IAdminBillingResponse>(
    paymentId?: string,
  ) {
    const {data: responseData} = await this.request.get<
      any,
      AxiosResponse<IAdminBillingResponse>
    >(`${this.url}/payment-methods/${paymentId}`, {
      headers: {
        Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
      },
    });
    return responseData;
  }
  async removeSpecificBillingMethodById<IAdminBillingResponse>(
    paymentId?: string,
  ) {
    const {data: responseData} = await this.request.delete<
      any,
      AxiosResponse<IAdminBillingResponse>
    >(`${this.url}/payment-methods/${paymentId}`, {
      headers: {
        Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
      },
    });
    return responseData;
  }

  async getAllValidCoupons<IAdminBillingResponse>() {
    const {data: responseData} = await this.request.get<
      any,
      AxiosResponse<IAdminBillingResponse>
    >(`${this.url}/coupons`, {
      headers: {
        Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
      },
    });
    return responseData;
  }

  async validateCouponCode<IAdminBillingResponse>(data: {code: string}) {
    const {data: responseData} = await this.request.post<
      any,
      AxiosResponse<IAdminBillingResponse>
    >(`${this.url}/coupons`, data, {
      headers: {
        Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
      },
    });
    return responseData;
  }

  async fetchNextCycle<IAdminBillingResponse>() {
    const {data: responseData} = await this.request.get<
      any,
      AxiosResponse<IAdminBillingResponse>
    >(`${this.url}/subscriptions/next-cycle`, {
      headers: {
        Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
      },
      useCache: false,
    });
    return responseData;
  }
}
