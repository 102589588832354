import {SelectField} from '@ui/molecules/select/selectfield';
import {useMemo} from 'react';
import {useStoreContext} from '@store/store-context';

export const FeedBackType = ({
  updateValue,
  value,
  user,
  disabled,
  includePublic,
  error,
}: {
  updateValue: (value: any) => void;
  value: string;
  user?: string;
  includePublic?: boolean;
  disabled?: boolean;
  error?: string;
}) => {
  const {
    authStore: {auth},
    usersStore: {users},
  } = useStoreContext();

  const values = useMemo(() => {
    const options = [
      {
        label: 'Private (visible to the recipient only)',
        value: 'private',
      },
    ];
    if (includePublic) {
      options.unshift({
        label: 'Public wall (visible to everyone in the workspace)',
        value: 'public',
      });
    }
    const isSelectedUserManager =
      users.find((_user) => _user.id === user)?.reviewer.id === auth.user.id;

    if (!isSelectedUserManager) {
      options.splice(1, 0, {
        label: 'Private + Manager (visible to the recipient and their manager)',
        value: 'manager',
      });
    }
    return options;
  }, [user, auth.user.id, users, includePublic]);

  return (
    <SelectField
      options={values}
      placeholder="Select"
      helper={error}
      disabled={disabled}
      state={error ? 'error' : 'default'}
      defaultValue={value}
      borderRadius={10}
      onChange={(e: any) => {
        updateValue(e?.value);
      }}
    />
  );
};
