import React from 'react';

export const GreenLine = ({color}: {color?: string}) => {
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 7L16 11.7735L16 0.226499L6 5L6 7ZM-8.74228e-08 7L7 7L7 5L8.74228e-08 5L-8.74228e-08 7Z"
        fill={color ?? '#1A9E68'}
      />
    </svg>
  );
};
