import {observer} from 'mobx-react';
import {Dispatch, SetStateAction, useMemo} from 'react';
import {useStoreContext} from '../../../../../../../../store/store-context';
import {VerticalSpacer} from '../../../../../../../../ui/atoms/spacer';
import {Body1, Body2} from '../../../../../../../../ui/atoms/typography';
import {SecondaryBreadcrumb} from '@ui/molecules/breadcrumb';
import {ItemLoaderLine} from '@ui/organisms/item-loader-line';
import {capitalize} from '@utils';
import {useAdminCheckinsHook} from '../admin-checkins.hooks';
import {
  EditCheckinsInnerWrapper,
  EditCheckinsOuterWrapper,
  BreadcrumbWrapper,
} from '../admin-checkins.styles';
import {useNavigate} from 'react-router';
import {FlexRow} from '@ui/style/styles';
import {checkinName} from '@utils/checkin-name';
import {SwitchToggle} from '@ui/molecules/toggle-switch/toggle-switch';
import {CheckinFrequency} from './checkin-frequency';
import {CheckinDueDateSettings} from './checkin-dueDate';
import {ResetCheckins} from './reset-checkins';
import {CheckinGroups} from './checkin-groups';
import {useDebouncedCallback} from 'use-debounce';

interface IEditCheckins {
  setIsConfiguring: Dispatch<SetStateAction<boolean>>;
}

interface ISettingsCardProps {
  title: string;
  message: string;
  checked: boolean;
  handleChecked: (value: boolean) => void;
}

const SettingToggleCard = ({
  title,
  message,
  checked,
  handleChecked,
}: ISettingsCardProps) => {
  return (
    <div className="p-6 flex items-center justify-between rounded-[10px] border border-borderLight">
      <div>
        <FlexRow>
          <Body1 weight="bold">{title}</Body1>
        </FlexRow>
        {message && <Body2 kind="textBody">{message}</Body2>}
      </div>
      <SwitchToggle
        checked={checked}
        onChange={() => handleChecked(!checked)}
      />
    </div>
  );
};

export const EditCheckins = observer(({setIsConfiguring}: IEditCheckins) => {
  const {
    handleFormValueChange,
    pendingChangesCount,
    formState,
    handleActiveCheckins,
    resetPendingChanges,
    submitForm,
    loading,
  } = useAdminCheckinsHook();

  const {checkinsStore} = useStoreContext();

  const navigate = useNavigate();

  const breadcrumbNav = useMemo(() => {
    const nav = [
      {
        title: 'Engagement',
        href: '#',
        id: '1',
        onClick: () => {
          setIsConfiguring(false);
          navigate(-1);
        },
      },
      {
        title: capitalize(checkinName()),
        href: '#',
        id: '2',
        isCurrent: true,
        onClick: () => {
          setIsConfiguring(true);
        },
      },
    ];
    return nav;
  }, [setIsConfiguring, navigate]);

  const isEditing = Object.keys(checkinsStore.configuration).length > 0;

  const updateSettings = useDebouncedCallback(submitForm, 1000);

  return (
    <EditCheckinsOuterWrapper
      method={isEditing ? 'patch' : 'post'}
      onSubmit={(event) => {
        event.preventDefault();
        submitForm();
      }}>
      <BreadcrumbWrapper>
        <SecondaryBreadcrumb
          wrapperStyles={{padding: '24px 32px 12px', marginBottom: 0}}
          navigation={breadcrumbNav}
        />
      </BreadcrumbWrapper>
      <EditCheckinsInnerWrapper>
        {
          <>
            {loading ? (
              <ItemLoaderLine />
            ) : (
              <>
                {pendingChangesCount ? (
                  <div className="px-6 py-4 bg-backgroundLight rounded-lg ">
                    <Body1 weight="bold">Updates to Check-in setting</Body1>
                    <VerticalSpacer size="16px" />
                    <Body2 kind="textBody" weight="semibold">
                      {pendingChangesCount} pending change(s) to be applied in
                      the next check-in cycle.
                    </Body2>
                  </div>
                ) : null}
                <VerticalSpacer size="24px" />

                <CheckinFrequency
                  value={formState.generalFrequency}
                  handleFormValueChange={handleFormValueChange}
                />

                <VerticalSpacer size="24px" />

                <CheckinDueDateSettings
                  value={formState.generalFrequency?.dueDay || ''}
                  handleFormValueChange={handleFormValueChange}
                />

                <VerticalSpacer size="24px" />

                <SettingToggleCard
                  title={`Mood Check`}
                  message=""
                  checked={formState.generalFrequency.activeCheckins.includes(
                    'pulse',
                  )}
                  handleChecked={() => {
                    handleActiveCheckins('pulse');
                    updateSettings();
                  }}
                />

                <VerticalSpacer size="24px" />

                <SettingToggleCard
                  title={`Action items`}
                  message=""
                  checked={formState.generalFrequency.activeCheckins.includes(
                    'priorities',
                  )}
                  handleChecked={() => {
                    handleActiveCheckins('priorities');
                    updateSettings();
                  }}
                />
                <VerticalSpacer size="24px" />

                <SettingToggleCard
                  title={`Allow late submissions`}
                  message="With this option, you can manually send reminders"
                  checked={formState.allowLateSubmission || false}
                  handleChecked={(value) => {
                    handleFormValueChange(value, 'allowLateSubmission');
                    updateSettings();
                  }}
                />

                <VerticalSpacer size="24px" />
                <CheckinGroups
                  value={formState.excludedGroups}
                  handleFormValueChange={handleFormValueChange}
                />
                <VerticalSpacer size="24px" />

                <ResetCheckins resetPendingChanges={resetPendingChanges} />
                <VerticalSpacer size="24px" />
              </>
            )}
          </>
        }
      </EditCheckinsInnerWrapper>
    </EditCheckinsOuterWrapper>
  );
});
