import {TGoalResponse} from '@hooks';
import {makeAutoObservable, observable, action} from 'mobx';

export class GoalsState {
  @observable isEditing: boolean = false;
  @observable isManagerEditing: boolean = false;
  @observable showGoalForm: boolean = true;
  @observable delete: boolean = false;
  @observable deleteId: string = '';
  @observable deleteGoalAlignedGoals: TGoalResponse[] = [];
  @observable goalFramework: string = '';
  @observable deleteName: string = '';
  @observable clearComment = '';
  @observable end: boolean = false;
  @observable reactivate_goal = {
    id: '',
    name: '',
    reOpen: false,
  };
  @observable apps: any = [];
  @observable showInsightGoal = {
    goal: '',
    insight: false,
  };

  @observable endId: string = '';
  @observable endName: string = '';
  @observable archive: boolean = false;
  @observable archiveId: string = '';
  @observable archiveName: string = '';

  constructor() {
    makeAutoObservable(this);
  }
  @action
  clearGoalsState = () => {
    this.isEditing = false;
    this.showGoalForm = true;
    this.delete = false;
    this.deleteId = '';
    this.deleteGoalAlignedGoals = [];
    this.deleteName = '';
    this.goalFramework = '';
    this.end = false;
    this.endId = '';
    this.endName = '';
  };

  @action handleClearComment = (value: string) => {
    this.clearComment = value;
  };
  @action setGoalFramework = (value: string) => {
    this.goalFramework = value;
  };

  @action
  setEditing = (state: boolean) => {
    this.isEditing = state;
  };

  @action
  setApps = (data: any) => {
    this.apps = data;
  };
  @action
  setReactivateGoal = (data: {id: string; reOpen: boolean; name: string}) => {
    this.reactivate_goal = data;
  };

  @action
  setShowGoalForm = (value: boolean) => {
    this.showGoalForm = value;
  };

  @action
  setDeleteModal = (
    goalId: string,
    state: boolean,
    name: string,
    goalFramework: string,
    alignedGoals?: TGoalResponse[],
  ) => {
    this.deleteId = goalId;
    this.delete = state;
    this.goalFramework = goalFramework;
    if (alignedGoals) this.deleteGoalAlignedGoals = alignedGoals;
    this.deleteName = name;
  };
  @action
  setArchiveModal = (
    goalId: string,
    state: boolean,
    name: string,
    goalFramework: string,
  ) => {
    this.archiveId = goalId;
    this.archive = state;
    this.goalFramework = goalFramework;

    this.archiveName = name;
  };
  @action setInsightGoal = (data: {insight: boolean; goal: string}) => {
    this.showInsightGoal = data;
  };
  @action setIsManagerEditing = (data: boolean) => {
    this.isManagerEditing = data;
  };
  @action
  setEndModal = (
    goalId: string,
    goalName: string,
    state: boolean,
    goalFramework: string,
  ) => {
    this.endId = goalId;
    this.end = state;
    this.goalFramework = goalFramework;

    this.endName = goalName;
  };
}

export const goalsState = new GoalsState();
