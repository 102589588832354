import styled, {css} from 'styled-components';
import {ifProp, ifNotProp, switchProp} from 'styled-tools';
import {Body1, Typography} from '../../../atoms/typography';
import {getColorFromTheme} from '../../../ui-utils';
import {InputState} from '../../../interface';

export const IconWrapper = styled.span<{disabled?: boolean}>`
  flex-shrink: 0;
  height: 100%;
  padding: 12px 16px 12px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  ${ifProp(
    'disabled',
    css`
      background-color: ${getColorFromTheme('backgroundLight')};
      cursor: not-allowed;
    `,
  )}

  svg {
    width: 20px;
    height: 20px;
    stroke: ${getColorFromTheme('textBody')};
  }
`;

export const Label = styled.span<{disabled?: boolean}>`
  background-color: ${getColorFromTheme('backgroundDark')};
  border-radius: 7px 0 0 7px;
  border-color: #cecede;
  border-style: solid;
  border-width: 1px;
  padding: 12px 16px;
  transition: 200ms ease all;
  flex-shrink: 0;

  ${ifProp(
    'disabled',
    css`
      cursor: not-allowed;
      background-color: ${getColorFromTheme('backgroundLight')};
    `,
  )}

  ${Body1} {
    color: ${getColorFromTheme('textBody')};
    transition: 200ms ease all;
  }
`;

export const Input = styled.span<{disabled?: boolean}>`
  ${Typography};

  padding: 5px 15px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: ${getColorFromTheme('textDark')};
  display: flex;
  align-items: center;
  height: 100%;
  background-color: ${getColorFromTheme('white')};
  transition: 200ms ease all;
  border: none;
  outline: none;
  border-left: solid 1px ${getColorFromTheme('borderLight')};
  width: 100%;

  ${ifProp(
    'disabled',
    css`
      cursor: not-allowed;
      border-color: ${getColorFromTheme('borderLight')};
      background-color: ${getColorFromTheme('backgroundLight')};
      color: ${getColorFromTheme('textMedium')};
    `,
  )}
`;

export const HandlerWrapper = styled.button<{
  disabled?: boolean;
  state?: InputState;
  active?: boolean;
}>`
  display: flex;
  flex-direction: row;
  position: relative;
  box-shadow: 0px 0px 0px 1px ${getColorFromTheme('borderLight')};
  border-radius: 7px;
  cursor: pointer;
  height: 52px;
  transition: 200ms ease all;
  width: 100%;
  align-items: center;

  &:focus,
  &:active {
    outline: 0;
    box-shadow: 0px 0px 0px 1px ${getColorFromTheme('purple300')},
      0 0 0 6px ${getColorFromTheme('purple200')};

    ${Label} {
      box-shadow: 0px 0px 0px 1px ${getColorFromTheme('purple300')};
    }

    ${Input} {
      border-left: solid 1px ${getColorFromTheme('purple300')};
    }

    ${Body1} {
      color: ${getColorFromTheme('textDark')};
    }

    ${ifProp(
      'disabled',
      css`
        box-shadow: 0px 0px 0px 1px ${getColorFromTheme('borderLight')},
          0 0 0 6px ${getColorFromTheme('borderLight')};

        ${Label} {
          box-shadow: 0px 0px 0px 1px ${getColorFromTheme('borderLight')};
        }

        ${Input} {
          border-left: solid 1px ${getColorFromTheme('borderLight')};
        }
      `,
    )}
  }

  ${ifNotProp(
    'active',
    css`
      &:hover:not(:focus):not(:disabled) {
        box-shadow: 0 0 0 2px ${getColorFromTheme('purple200')};

        ${Label} {
          box-shadow: 0px 0px 0px 1px ${getColorFromTheme('purple200')};
        }

        ${Input} {
          border-left: solid 1px ${getColorFromTheme('purple200')};
        }
        ${ifProp(
          'disabled',
          css`
            box-shadow: 0 0 0 2px ${getColorFromTheme('borderLight')};

            ${Label} {
              box-shadow: 0px 0px 0px 1px ${getColorFromTheme('borderLight')};
            }

            ${Input} {
              border-left: solid 1px ${getColorFromTheme('borderLight')};
            }
          `,
        )}
      }
    `,
  )}

  &:disabled {
    cursor: not-allowed;
  }

  ${ifProp(
    'disabled',
    css`
      cursor: not-allowed;
    `,
  )}

  ${ifProp(
    'active',
    css`
      outline: 0;
      box-shadow: 0px 0px 0px 1px ${getColorFromTheme('purple300')},
        0 0 0 6px ${getColorFromTheme('purple200')};

      ${Label} {
        box-shadow: 0px 0px 0px 1px ${getColorFromTheme('purple300')};
      }

      ${Input} {
        border-left: solid 1px ${getColorFromTheme('purple300')};
      }

      ${Body1} {
        color: ${getColorFromTheme('textDark')};
      }
    `,
  )};

  ${switchProp('state', {
    error: css`
      box-shadow: 0px 0px 0px 1px ${getColorFromTheme('red400')};

      ${Label} {
        box-shadow: 0px 0px 0px 1px ${getColorFromTheme('red400')};
      }

      ${Body1} {
        color: ${getColorFromTheme('red400')};
      }

      ${Input} {
        border-left: 1px solid ${getColorFromTheme('red400')};
        color: ${getColorFromTheme('red400')};
      }

      svg {
        stroke: ${getColorFromTheme('red400')};
      }

      &:hover,
      &:focus,
      &:active {
        box-shadow: 0px 0px 0px 1px ${getColorFromTheme('red400')};

        ${Body1} {
          color: ${getColorFromTheme('red400')};
        }

        ${Input} {
          border-left: 1px solid ${getColorFromTheme('red400')};
          color: ${getColorFromTheme('red400')};
        }

        ${Label} {
          box-shadow: 0px 0px 0px 1px ${getColorFromTheme('red400')};
        }
      }
    `,
  })}
`;
