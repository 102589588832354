import {observer} from 'mobx-react';
import {lazy} from 'react';

const EmailSentPageComp = lazy(() =>
  import('./email-sent-page').then((module) => ({
    default: module.EmailSentPage,
  })),
);

export const installEmailSentPage = () => {
  const Page = observer(() => <EmailSentPageComp />);
  return {
    Page,
  };
};
