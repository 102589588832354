import {observer} from 'mobx-react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {NotificationCard} from '@ui/molecules/notification/notification';
import {FiChevronDown, FiChevronUp} from 'react-icons/fi';
import {
  Wrapper,
  WorkspaceWrapper,
  NotificationWrapper,
  WorkspaceWrapperChild,
  FeaturesLayout,
  BodyLayout,
} from './admin-workspace-styled';
import {useStoreContext} from '../../../../../store/store-context';
import PageLayout from '@ui/templates/page-template/page-layout';
import {SideNavigation} from '../../../../../ui/molecules/side-nav';
import {AdminSettings} from './admin-workspace-components/admin-workspace';
import WorkspacePerformance from './workspace-performance/workspace-performance';
import WorkSpaceEngagement from './workspace-engagements/workspace-engagements';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {Routes, Route} from 'react-router-dom';
import {useIsRouteActive} from '@utils/route-href';
import {AdminValues} from './admin-values/admin-values';
import {AdminBillingSetup} from './admin-billing-setup/admin-billing-setup.page';
import {BillingCycle} from './admin-billing-setup/admin-billing-components/billing-not-active/components/billng-cycle';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import pRetry from 'p-retry';
import {AdminMemberPage} from '../admin-people/admin-member/admin-member-page';
import {AdminGroupTypePage} from '../admin-people/admin-group-type/admin-group-type-page';
import {SecondaryBreadcrumb} from '@ui/molecules/breadcrumb';
import {AdminGroupPage} from '../admin-people/admin-group/admin-group-page';

const stripePromise = pRetry(
  () => loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_ID as any),
  {
    onFailedAttempt: (error) => {},
    retries: 5,
  },
);

export const WORKSPACE_ROUTES = {
  PROFILE: 'profile',
  VALUES: 'values',
  ENGAGEMENT: 'engagement',
  PERFORMANCE: 'performance',
  MEMBERS: 'members',
  GROUPS: 'groups',
  BILLING: 'billing',
  BILLING_CYCLE: 'billing-cycle',
};

export const AdminWorkspacePage = observer(
  ({tab, subTab}: {tab?: string; subTab?: string}) => {
    const {
      storeDashboardRoutes,
      authStore: {auth},
      billingsStore: {updateShowNonActiveBilling},
      groupStore,
    } = useStoreContext();

    const [prevPathName] = useState<any>(sessionStorage.getItem('prevurl'));

    const [, setShowStarterPlanCTA] = useState<boolean>(false);
    const {isRouteActive} = useIsRouteActive();

    const isFreePlanActive = auth.user.workspace.billing.plan === 'free_tier';

    const {activateWorkspaceTab} = storeDashboardRoutes;

    const [rotate, setRotate] = useState(false);
    const [peopleTabRotate, setPeopleTabRotate] = useState(false);

    const routesUnderFeatures = [
      WORKSPACE_ROUTES.ENGAGEMENT,
      WORKSPACE_ROUTES.PERFORMANCE,
    ];

    const routesUnderPeople = [
      WORKSPACE_ROUTES.MEMBERS,
      WORKSPACE_ROUTES.GROUPS,
    ];

    const featureisActive = routesUnderFeatures.some((route) =>
      isRouteActive(route),
    );
    const peopleisActive = routesUnderPeople.some((route) =>
      isRouteActive(route),
    );

    const isTrialActive = auth.user.workspace.billing.activeTrial;

    const featuresNav = useMemo(
      () => [
        {
          title: 'Engagement',
          href: WORKSPACE_ROUTES.ENGAGEMENT,
          type: 'status',

          onClick: () => {},
          active: false,
          subActive: isRouteActive(WORKSPACE_ROUTES.ENGAGEMENT),
          id: '11',
          disable: isFreePlanActive,
          indent: '18px',
          icon: <></>,
        },
        {
          title: 'Performance',
          href: WORKSPACE_ROUTES.PERFORMANCE,
          type: 'status',
          onClick: () => {},
          active: false,
          subActive: isRouteActive(WORKSPACE_ROUTES.PERFORMANCE),
          id: '11',
          disable: isFreePlanActive,
          indent: '18px',
          icon: <></>,
        },
      ],
      [isRouteActive, isFreePlanActive],
    );

    const peoplesNav = useMemo(
      () => [
        {
          title: 'Members',
          href: WORKSPACE_ROUTES.MEMBERS,
          type: 'status',
          onClick: () => {},
          active: false,
          subActive: isRouteActive(WORKSPACE_ROUTES.MEMBERS),
          id: '11',
          // disable: isFreePlanActive,
          indent: '18px',
          icon: <></>,
        },
        {
          title: 'Groups',
          href: WORKSPACE_ROUTES.GROUPS,
          type: 'status',
          onClick: () => {},
          active: false,
          subActive: isRouteActive(WORKSPACE_ROUTES.GROUPS),
          id: '11',
          disable: isFreePlanActive,
          indent: '18px',
          icon: <></>,
        },
      ],
      [isRouteActive, isFreePlanActive],
    );

    const workspaceNavigations = useMemo(() => {
      const nav = [
        {
          active:
            isRouteActive(WORKSPACE_ROUTES.PROFILE) ||
            window.location.pathname === '/workspace',
          href: WORKSPACE_ROUTES.PROFILE,
          id: '1',
          type: 'workspace',
          disable: isFreePlanActive,
          onClick: () => {
            activateWorkspaceTab('profile');
            setRotate(false);
          },
          title: 'Workspace Profile',
        },
        {
          active: isRouteActive(WORKSPACE_ROUTES.VALUES),
          href: WORKSPACE_ROUTES.VALUES,
          id: '1',
          disable: isFreePlanActive,
          type: 'workspace',
          onClick: () => {
            activateWorkspaceTab('values');
            setRotate(false);
          },
          title: 'Values',
        },
        {
          active: peopleisActive,
          // disable: isFreePlanActive,
          href: WORKSPACE_ROUTES.MEMBERS,
          id: '3',
          variant: 'features',
          type: 'workspace',
          onClick: () => {
            setPeopleTabRotate(peopleTabRotate === false ? true : false);
          },
          subNavigation: peoplesNav,
          showSubNavigation: peopleTabRotate,
          iconSpacing: 'space-between',
          title: 'People',
          icon: (
            <>
              {peopleTabRotate || peopleisActive ? (
                <FiChevronUp width="8px" />
              ) : (
                <FiChevronDown width={'8px'} />
              )}
            </>
          ),
        },
        {
          active: featureisActive,
          href: WORKSPACE_ROUTES.ENGAGEMENT,
          id: '3',
          disable: isFreePlanActive,
          variant: 'features',
          type: 'workspace',
          onClick: () => {
            setRotate(rotate === false ? true : false);
          },
          subNavigation: featuresNav,
          showSubNavigation: rotate,
          iconSpacing: 'space-between',
          title: 'Features',
          icon: (
            <>
              {rotate || featureisActive ? (
                <FiChevronUp width="8px" />
              ) : (
                <FiChevronDown width={'8px'} />
              )}
            </>
          ),
        },
        {
          active: isRouteActive(WORKSPACE_ROUTES.BILLING),
          href: WORKSPACE_ROUTES.BILLING,
          id: '4',
          type: 'workspace',
          onClick: () => {
            activateWorkspaceTab('billing');

            if (isTrialActive) {
              updateShowNonActiveBilling(true);
            }
            setRotate(false);
          },
          title: 'Billing',
        },
      ];

      return nav;
    }, [
      isRouteActive,
      isFreePlanActive,
      peopleisActive,
      peoplesNav,
      peopleTabRotate,
      featureisActive,
      featuresNav,
      rotate,
      activateWorkspaceTab,
      isTrialActive,
      updateShowNonActiveBilling,
    ]);

    useEffect(() => {
      if (isTrialActive) {
        updateShowNonActiveBilling(true);
      }
    }, [isTrialActive, updateShowNonActiveBilling]);

    let title =
      groupStore.groupTypeNameInStore && `${groupStore.groupTypeNameInStore}`;

    const GroupBreadCrumbsNav = useMemo(
      () => [
        {
          href: '#',
          id: '1',
          onClick: () => {
            groupStore.setIsGroup(false);
            groupStore.setGroupTypeNameInStore('');
            //  activateAdminTab('people');
            //  activatePeopleTab('group');
          },
          title: 'Manage Groups',
        },
        {
          href: '',
          id: '2',
          isCurrent: true,
          onClick: function noRefCheck() {},
          title: title,
        },
      ],
      [title, groupStore],
    );

    const WorkSpaceChild = useCallback(() => {
      return (
        <WorkspaceWrapperChild className="max-h-[90vh]">
          <SideNavigation navigations={workspaceNavigations} type="workspace" />

          <div className="overflow-auto relative h-[99vh] pb-20">
            <NotificationWrapper>
              <NotificationCard />
            </NotificationWrapper>
            <Routes>
              <Route
                path={WORKSPACE_ROUTES.PROFILE}
                element={<AdminSettings />}
              />

              <Route
                path={`${WORKSPACE_ROUTES.ENGAGEMENT}/*`}
                element={<WorkSpaceEngagement />}
              />

              <Route
                path={`${WORKSPACE_ROUTES.MEMBERS}/*`}
                element={
                  <FeaturesLayout>
                    <BodyLayout>
                      <AdminMemberPage />
                    </BodyLayout>
                  </FeaturesLayout>
                }
              />

              <Route
                path={`${WORKSPACE_ROUTES.GROUPS}/*`}
                element={
                  groupStore.isGroup ? (
                    <>
                      <SecondaryBreadcrumb
                        navigation={GroupBreadCrumbsNav}
                        wrapperStyles={{
                          padding: '20px 32px 16px 20px',
                          borderBottom: '1px solid #ededf2',
                        }}
                      />
                      <FeaturesLayout>
                        <BodyLayout>
                          <div className="pr-2">
                            <AdminGroupPage />
                          </div>
                        </BodyLayout>
                      </FeaturesLayout>
                    </>
                  ) : (
                    <FeaturesLayout>
                      <BodyLayout>
                        <div className="pr-2">
                          <AdminGroupTypePage />{' '}
                        </div>
                      </BodyLayout>
                    </FeaturesLayout>
                  )
                }
              />

              <Route
                path={`${WORKSPACE_ROUTES.VALUES}/*`}
                element={<AdminValues />}
              />

              <Route
                path={`${WORKSPACE_ROUTES.PERFORMANCE}/*`}
                element={<WorkspacePerformance />}
              />

              <Route
                path={WORKSPACE_ROUTES.BILLING}
                element={
                  <Elements stripe={stripePromise}>
                    <AdminBillingSetup
                      setShowStarterPlanCTA={setShowStarterPlanCTA}
                    />
                  </Elements>
                }
              />
            </Routes>
          </div>
        </WorkspaceWrapperChild>
      );
    }, [workspaceNavigations, groupStore.isGroup, GroupBreadCrumbsNav]);
    // const {noOfMembers} = useAdminWorkspaceHook();
    const [searchParams] = useSearchParams();

    const navigate = useNavigate();

    const pageTitle = () => {
      if (searchParams.get('free-trial')) return 'Start free trial';

      if (
        isRouteActive(WORKSPACE_ROUTES.BILLING) ||
        isRouteActive(WORKSPACE_ROUTES.BILLING_CYCLE)
      )
        return 'Billing';

      if (peopleisActive) return `People`;

      if (featureisActive) return 'Features';

      return `${auth.user.workspace.name}'s Workspace`;
    };

    return (
      <Wrapper>
        <PageLayout
          title={pageTitle()}
          guideUrl="https://peoplebeam.myhcpage.com/en-us/"
          titleMargin={peopleisActive ? '0%' : undefined}
          onClick={() =>
            prevPathName ? navigate(prevPathName) : navigate('/')
          }
          bodyStyle={{margin: 0, padding: 0}}>
          <WorkspaceWrapper style={{}}>
            {!isRouteActive(WORKSPACE_ROUTES.BILLING_CYCLE) && (
              <WorkSpaceChild />
            )}

            <Routes>
              <Route
                path={WORKSPACE_ROUTES.BILLING_CYCLE}
                element={
                  <Elements stripe={stripePromise}>
                    <NotificationWrapper fullWidth>
                      <NotificationCard />
                    </NotificationWrapper>
                    <BillingCycle />
                  </Elements>
                }
              />
            </Routes>
          </WorkspaceWrapper>
        </PageLayout>
      </Wrapper>
    );
  },
);

export const adminWorkspacePage = () => {
  const Page = observer(({tab, subTab}: {tab?: string; subTab?: string}) => (
    <AdminWorkspacePage tab={tab} subTab={subTab} />
  ));
  return {
    Page,
  };
};
