import {CSSProperties, forwardRef, Ref} from 'react';
import styled, {DefaultTheme, StyledComponentProps} from 'styled-components';

import {CheckKind, InputState} from '../../interface';
import {getColorFromTheme} from '../../ui-utils';

interface TProps {
  state?: InputState;
  kind?: CheckKind;
  disabled?: boolean;
}

const SwitchInput = styled.input<TProps>`
  height: 0;
  width: 0;
  visibility: hidden;

  /* Default checked state */
  &:checked + .react-switch-label {
    background: ${getColorFromTheme('green300')};
  }

  &:checked + .react-switch-label .react-switch-button {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }

  /* Disabled unchecked state */
  &:disabled:not(:checked) + .react-switch-label {
    background: #ededf2;
    cursor: not-allowed;

    .react-switch-button {
      background: #cecede;
      box-shadow: none;
    }
  }

  /* Disabled checked state */
  &:disabled:checked + .react-switch-label {
    background: #47b881;
    cursor: not-allowed;

    .react-switch-button {
      background: #47b881;
      box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

interface SwitchLabelProps {
  size: 'sm' | 'md' | 'lg' | undefined;
  disabled?: boolean;
}

const SwitchLabel = styled.label<SwitchLabelProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  width: ${({size}) =>
    size === 'lg' ? '100px' : size === 'md' ? '60px' : '32px'};
  height: ${({size}) =>
    size === 'lg' ? '50px' : size === 'md' ? '35px' : '20px'};
  background: #ededf2;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;

  & .react-switch-button {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: ${({size}) =>
      size === 'lg' ? '45px' : size === 'md' ? '25px' : '16px'};
    height: ${({size}) =>
      size === 'lg' ? '45px' : size === 'md' ? '25px' : '16px'};
    border-radius: ${({size}) =>
      size === 'lg' ? '45px' : size === 'md' ? '25px' : '16px'};
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  }

  &:active .react-switch-button {
    width: ${(props) => (props.disabled ? 'auto' : '20px')};
  }
`;

export type ToggleSwitchProps = StyledComponentProps<
  'input',
  DefaultTheme,
  {
    state?: InputState;
    kind?: CheckKind;
    labelStyle?: CSSProperties;
    switchId?: number;
    togglWidth?: 'sm' | 'md' | 'lg' | undefined;
    disabled?: boolean;
  },
  never
>;

export const ToggleSwitch = forwardRef(
  (
    {
      kind,
      switchId,
      labelStyle,
      disabled,
      ...props
    }: Omit<ToggleSwitchProps, 'type'>,
    ref: Ref<HTMLInputElement>,
  ) => (
    <Wrapper>
      <SwitchInput
        {...props}
        disabled={disabled}
        type="checkbox"
        ref={ref}
        id={`react-switch-new-${switchId}`}
      />
      <SwitchLabel
        htmlFor={`react-switch-new-${switchId}`}
        style={labelStyle}
        className="react-switch-label"
        size={props.togglWidth}
        disabled={disabled}>
        <span className="react-switch-button" />
      </SwitchLabel>
    </Wrapper>
  ),
);
