import styled, {css} from 'styled-components';
import {ifProp, switchProp} from 'styled-tools';
import {Body2} from '../typography/body2';
import {down} from 'styled-breakpoints';
import {OffTrackSVG} from '../svg/off-track';
import {OnTrackSVG} from '../svg/on-track';
import {ProgressingSVG} from '../svg/progressing';
import {ComponentPropsWithoutRef, forwardRef, Ref, useMemo} from 'react';
import {getColorFromTheme} from '../../ui-utils';
import {ProgressType} from '../../interface';

export interface TProgressTypeInputProps
  extends ComponentPropsWithoutRef<'input'> {
  id: string;
  kind: ProgressType;
  disabled?: boolean;
}

const onTrackStyle = css`
  background-color: ${getColorFromTheme('green100')};
  box-shadow: 0px 0px 0px 1px ${getColorFromTheme('green400')};
  z-index: 1;
  > ${Body2} {
    color: ${getColorFromTheme('green500')};
  }
`;

const offTrackStyle = css`
  background-color: ${getColorFromTheme('red100')};
  box-shadow: 0px 0px 0px 1px ${getColorFromTheme('red400')};
  z-index: 1;
  > ${Body2} {
    color: ${getColorFromTheme('red500')};
  }
`;

const progressingStyle = css`
  background-color: ${getColorFromTheme('orange100')};
  box-shadow: 0px 0px 0px 1px ${getColorFromTheme('orange300')};
  z-index: 1;
  > ${Body2} {
    color: ${getColorFromTheme('orange400')};
  }
`;

const activeStyle = css`
  ${switchProp('kind', {
    [ProgressType.ONTRACK]: onTrackStyle,
    [ProgressType.PROGRESSING]: progressingStyle,
    [ProgressType.OFFTRACK]: offTrackStyle,
  })}
`;

const Wrapper = styled.label<{checked?: boolean; kind?: ProgressType}>`
  cursor: pointer;
  position: relative;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: 0px 0px 0px 1px ${getColorFromTheme('borderLight')};
  transition: all 200ms ease;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  -moz-appearance: none;
  input {
    top: 0;
    left: 0;
    width: 100%;
    cursor: inherit;
    height: 100%;

    margin: 0;
    opacity: 0;
    padding: 0;
    z-index: 5;
    position: absolute;
  }
  &:focus,
  &:active {
    ${activeStyle}
  }
  ${down('md')} {
    width: 100%;
  }
  ${ifProp(
    'checked',
    css`
      ${activeStyle};
    `,
  )}
`;

const Content = styled.span`
  display: inline-flex;
  padding: 8px 16px;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  ${Body2} {
    color: ${getColorFromTheme('textBody')};
    transition: all 300ms ease-in-out;
    white-space: nowrap;
    z-index: 2;
  }
  svg {
    transition: all 300ms ease-in-out;
  }
  @media (max-width: 320px) {
    padding: 8px 10px;
  }
`;

const Icon = styled.span`
  margin-right: 8px;
  z-index: 2;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 16px;
    height: 16px;
  }
  @media (max-width: 400px) {
    display: none;
  }
`;

export const ProgressTypeInput = forwardRef(
  (
    {kind, disabled, ...props}: TProgressTypeInputProps,
    ref: Ref<HTMLInputElement>,
  ) => {
    const progressText = useMemo(() => {
      switch (kind) {
        case ProgressType.PROGRESSING:
          return 'Behind';
        case ProgressType.ONTRACK:
          return 'On-track';
        case ProgressType.OFFTRACK:
          return 'At-risk';
        // no-default
      }
    }, [kind]);

    const icon = useMemo(() => {
      switch (kind) {
        case ProgressType.PROGRESSING:
          return <ProgressingSVG />;
        case ProgressType.ONTRACK:
          return <OnTrackSVG />;
        case ProgressType.OFFTRACK:
          return <OffTrackSVG />;
        // no-default
      }
    }, [kind]);

    return (
      <Wrapper
        style={{pointerEvents: disabled ? 'none' : 'auto'}}
        checked={props.checked}
        kind={kind}
        htmlFor={props.id}>
        <input {...props} type="radio" name={props.name} ref={ref} />
        <Content>
          <Icon style={{opacity: disabled ? '.5' : ''}}>{icon}</Icon>
          <Body2 style={{color: disabled ? '#bfbfd4' : '#5f5f8c'}}>
            {progressText}
          </Body2>
        </Content>
      </Wrapper>
    );
  },
);
