import PageLayout from '@ui/templates/page-template/page-layout';
import {FlexRow, FlexRowSpaceBetween} from '@ui/style/styles';
import {Button} from '@ui/atoms/button';
import {AddMoreAction} from '@ui/molecules/add-more';
import {useState} from 'react';
import {NoBorderButton} from '@pages/dashboard/reporting/reporting-pages/reporting-overview/styles';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {TextField} from '@ui/molecules/field/textfield';
import nextId from 'react-id-generator';

import {
  AddQuestionHeader,
  RatingScaleWrapper,
  DelimitingWrapper,
  QuestionWrapper,
  QuestionForButton,
  HintBlock,
} from './create-template-style';
import {Body2, Headline3} from '@ui/atoms/typography';
import {ArrowHeadLeftIcon} from '@ui/atoms/icons';

interface IQuestionReview {
  questionFor: string | Array<string>;
}
interface ISelfManagerReview extends IQuestionReview {
  selfQuestion: string;
  managerQuestion: string;
}

interface IPeerManagerReview extends IQuestionReview {
  peerQuestion: Array<string>;
}
export const AddQuestionPage = ({onClose}: {onClose: () => void}) => {
  const [questionType, setQuestionType] = useState<'self-manager' | 'peer'>(
    'self-manager',
  );

  const emptySelf = {
    questionFor: '',
    selfQuestion: '',
    managerQuestion: '',
  };

  const [selfManagerReviewQuestions, setSelfManagerReviewQuestions] = useState<
    Array<ISelfManagerReview>
  >([emptySelf]);
  const emptyPeer = {
    questionFor: '',
    peerQuestion: [''],
  };

  const [peerReviewQuestions, setPeerReviewQuestions] = useState<
    Array<IPeerManagerReview>
  >([emptyPeer]);

  const isQuestionPeer = questionType === 'peer';

  const addQuestionReview = () => {
    if (isQuestionPeer) {
      setPeerReviewQuestions((prev) => [...prev, emptyPeer]);
      return;
    }
    setSelfManagerReviewQuestions((prev) => [...prev, emptySelf]);
  };

  const [expandRatingScale, setRatingScale] = useState(false);

  const RatingScale = () => {
    return (
      <RatingScaleWrapper>
        <FlexRowSpaceBetween
          style={{marginBottom: expandRatingScale ? '12px' : ''}}>
          <Body2 weight="bold">Default rating scale</Body2>
          <NoBorderButton onClick={() => setRatingScale(!expandRatingScale)}>
            <Body2 weight="bold" kind="textBody">
              {expandRatingScale ? 'Collapse' : 'Expand'}
            </Body2>
          </NoBorderButton>
        </FlexRowSpaceBetween>
        {expandRatingScale && (
          <>
            {/* {ccReviewScale.map((rating) => (
              <div className="rating">
                <Body1 weight="semibold">{rating.type}</Body1>
                <VerticalSpacer size="12px" />
                <Body2 kind="textBody">{rating.detail}</Body2>
              </div>
            ))} */}
            <HintBlock>
              To add a custom scale, please
              <a href="mailto:Hi@peopebeam.co"> contact support.</a>
            </HintBlock>
          </>
        )}
      </RatingScaleWrapper>
    );
  };

  return (
    <PageLayout
      title="Add questions"
      bodyStyle={{margin: 0}}
      onClick={() => null}
      leftAction={
        <FlexRow onClick={onClose}>
          <ArrowHeadLeftIcon />{' '}
          <Body2 weight="semibold">Back to template</Body2>
        </FlexRow>
      }>
      <div>
        <AddQuestionHeader>
          <div className="flex">
            <button
              className={isQuestionPeer ? 'bg-dark' : 'bg-light'}
              onClick={() => isQuestionPeer && setQuestionType('self-manager')}>
              Self and manager/reviewers
            </button>
            <div className="rule" />{' '}
            <button
              className={!isQuestionPeer ? 'bg-dark' : 'bg-light'}
              onClick={() => !isQuestionPeer && setQuestionType('peer')}>
              Peers
            </button>
          </div>
        </AddQuestionHeader>
        <DelimitingWrapper>
          <VerticalSpacer size="40px" />
          <Headline3>Add a question - Productivity</Headline3>
          <VerticalSpacer size="40px" />
          <RatingScale />
          <div
            style={{
              width: '100%',
              background: '#EDEDF2',
              margin: '24px 0px',
              height: '1px',
            }}></div>
          {!isQuestionPeer && (
            <HintBlock>
              <Body2 kind="textBody" align="center">
                Typing <span style={{fontWeight: 500}}>[name]</span> will
                auto-fill the employee's first and last name.
              </Body2>
            </HintBlock>
          )}
          <VerticalSpacer size="24px" />
          <Body2 weight="bold">Review questions</Body2>
          <VerticalSpacer size="10px" />
          {isQuestionPeer &&
            peerReviewQuestions.map((peerQuestion, index) => (
              <QuestionsTemplate
                key={index}
                peerQuestion={peerQuestion.peerQuestion}
                questionType={questionType}
              />
            ))}
          {!isQuestionPeer &&
            selfManagerReviewQuestions.map((selfManagerQuestion, index) => (
              <QuestionsTemplate
                key={index}
                selfManagerQuestion={selfManagerQuestion}
                questionType={questionType}
              />
            ))}
          <VerticalSpacer size="24px" />

          <AddMoreAction
            onClick={addQuestionReview}
            title="Add question review"
          />
        </DelimitingWrapper>
      </div>
    </PageLayout>
  );
};

const QuestionsTemplate = ({
  selfManagerQuestion,
  peerQuestion,
  questionType,
}: {
  peerQuestion?: Array<string>;
  selfManagerQuestion?: ISelfManagerReview;
  questionType: 'peer' | 'self-manager';
}) => {
  const isQuestionPeer = questionType === 'peer';

  const [peerQuestions, setPeerQuestions] = useState<
    Array<{id: string; text: string}>
  >(
    peerQuestion?.map((question) => {
      return {
        id: nextId('peer-question'),
        text: question,
      };
    }) || [
      {
        id: nextId('peer-question'),
        text: '',
      },
    ],
  );
  const [peerErrors, setPeerErrors] = useState<{error: string; id: string}[]>(
    [],
  );

  const [selectedQuestionFor, setSelectedQuestionFor] = useState('');

  const addPeerQuestions = () => {
    setPeerQuestions((prev) => [
      ...prev,
      {id: nextId('peer-question'), text: ''},
    ]);
  };

  const handleSave = () => {
    if (isQuestionPeer) {
      const formatPeerQuestions = peerQuestions.map(
        (question) => question.text,
      );
      if (formatPeerQuestions.some((question) => !question)) {
        setPeerErrors(
          peerQuestions
            .filter((question) => !question.text)
            .map((question) => {
              return {
                id: question.id,
                error: 'Complete required field',
              };
            }),
        );
        return;
      }
    }
  };

  const getPeerError = (id: string) => {
    return peerErrors.find((error) => error.id === id);
  };

  const updatePeerFields = (id: string, value: string) => {
    setPeerQuestions((prev) =>
      prev.map((val) => (val.id === id ? {...val, text: value} : val)),
    );

    if (getPeerError(id)) {
      setPeerErrors((prev) => prev.filter((err) => err.id !== id));
    }
  };

  const questionFor = ['All', 'Groups', 'Teams'];

  return (
    <QuestionWrapper>
      <div className="header">
        <Body2 weight="bold">Who is this question for?</Body2>
        <FlexRow>
          {questionFor.map((option) => (
            <QuestionForButton
              onClick={() => setSelectedQuestionFor(option)}
              active={selectedQuestionFor === option}>
              {selectedQuestionFor === option && (
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <circle cx="4" cy="4" r="4" fill="#BFBFD4" />
                </svg>
              )}
              {option}
            </QuestionForButton>
          ))}
        </FlexRow>
      </div>
      <div className="body">
        {isQuestionPeer && (
          <div>
            <Body2 weight="bold">Peer questions</Body2>
            <VerticalSpacer size="10px" />
            {peerQuestions.map((peerQuestion, index) => (
              <TextField
                placeholder="Eg. Rate [Name]’s performance last quarter?"
                inputStyle={{borderRadius: '10px'}}
                style={{marginBottom: '10px'}}
                onChange={(event) =>
                  updatePeerFields(peerQuestion.id, event.target.value)
                }
                helper={getPeerError(peerQuestion.id)?.error}
                state={getPeerError(peerQuestion.id) ? 'error' : 'default'}
                value={peerQuestion.text}
                margin
                key={index}
              />
            ))}

            <VerticalSpacer size="14px" />
            <NoBorderButton onClick={() => addPeerQuestions()}>
              <Body2 kind="purple300" weight="bold">
                + Ask another question{' '}
              </Body2>
            </NoBorderButton>
          </div>
        )}
        {!isQuestionPeer && (
          <div>
            <Body2 weight="bold">Self review</Body2>
            <VerticalSpacer size="10px" />

            <TextField
              placeholder="Eg. How would you rate your performance last quarter?"
              inputStyle={{borderRadius: '10px'}}
            />
            <VerticalSpacer size="10px" />
            <Body2 weight="bold">Manager/Reviewers’</Body2>
            <VerticalSpacer size="10px" />

            <TextField
              placeholder="Eg. Rate [Name]’s performance last quarter?"
              inputStyle={{borderRadius: '10px'}}
            />
          </div>
        )}
        <VerticalSpacer size="10px" />

        <Button style={{padding: '7px 16px'}} width="full" onClick={handleSave}>
          Save
        </Button>
      </div>
    </QuestionWrapper>
  );
};
