import {Body1, Body2} from '@ui/atoms/typography';
import {ifProp} from 'styled-tools';
import {useState} from 'react';
import {FlexRowSpaceBetween} from '@ui/style/styles';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {useStoreContext} from '@store/store-context';
import {NoBorderButton} from '@pages/dashboard/reporting/reporting-pages/reporting-overview/styles';
import styled from 'styled-components';

const Impression = styled.div<{removeBorderBottom: boolean}>`
  border: 1px solid #ededf2;
  border-radius: 10px;
  margin-bottom: ${ifProp('removeBorderBottom', '', '16px')};
  padding: 24px;
`;

const Wrapper = styled.div`
  border: 1px solid #ededf2;
  border-radius: 10px;

  .header {
    padding: 16px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .body {
    border-top: 1px solid #ededf2;
    padding: 24px;
    border-radius: 10px 10px 0px 0px;
    @media (max-width: 500px) {
      padding: 12px;
    }
  }
`;

export const PerformanceRankings = () => {
  const {
    ccStore: {workspaceImpressionDetails},
  } = useStoreContext();

  const [expand, setExpand] = useState(false);

  return (
    <div>
      <Wrapper className="bg-white">
        <div className="header">
          <div>
            <Body1 kind="textDark" weight="bold">
              Overall performance rankings{' '}
              <span
                style={{fontSize: '14px', color: '#5F5F8C', fontWeight: 400}}>
                {' '}
                (Default)
              </span>
            </Body1>
          </div>
          <NoBorderButton onClick={() => setExpand(!expand)}>
            <Body2 weight="bold" kind="purple300">
              {!expand ? 'Expand' : 'Collapse'}
            </Body2>
          </NoBorderButton>
        </div>
        {expand && (
          <div className="body">
            <div>
              {workspaceImpressionDetails.map((impression, index) => (
                <Impression
                  key={index}
                  removeBorderBottom={
                    index === workspaceImpressionDetails.length - 1
                  }>
                  <FlexRowSpaceBetween>
                    <Body1 weight="bold">{impression.name}</Body1>

                    <Body1 weight="bold">
                      MIN {impression.min} - MAX {impression.max}
                    </Body1>
                  </FlexRowSpaceBetween>
                  <VerticalSpacer size="8px" />
                  <Body2 kind="textBody" style={{width: '80%'}}>
                    {impression.description}
                  </Body2>
                </Impression>
              ))}
            </div>
          </div>
        )}
      </Wrapper>
    </div>
  );
};
