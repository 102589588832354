import {activateNotification} from '../../ui/molecules/notification/activate-notification';
import {parseErrorContent} from '../../utils/error-handling';
import {IWorkspaceAPIRequest} from './workspaces-api-request';

export class WorkspaceAPIController {
  constructor(private readonly request: IWorkspaceAPIRequest) {}

  async fetchFeatureLimitStatus() {
    try {
      const response = await this.request.fetchFeatureLimitStatus();
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }

  async fetchWorkspaceData(id: string) {
    try {
      const response = await this.request.fetchWorkspaceData(id);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }

  async updateWorkspace(data: any, noNotification?: boolean) {
    try {
      await this.request.updateWorkspace(data);
      if (!noNotification) {
        activateNotification({
          title: 'Success',
          content: `Updated workspace settings`,
          kind: 'success',
        });
      }
      return true;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e?.response?.data?.message,
        kind: 'error',
      });
      return false;
    }
  }
}
