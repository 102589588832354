import {Colors} from '@ui/interface';
import {getColorFromTheme} from '@ui/ui-utils';
import styled from 'styled-components';

export const CheckableInputWrapper = styled.div<{
  background?: Colors;
  error?: boolean;
  editor?: boolean;
}>`
  border: ${({error}) => (error ? `1px solid #d52a2a` : `1px solid #cecede`)};
  border-radius: 10px;
  padding: ${({editor}) => (editor ? '14px 16px' : '12px 12px 8px 12px')};
  width: 100%;
  background: ${({background}) =>
    background ? getColorFromTheme(background) : undefined};
`;

export const PopContainer = styled.div`
  background: white;
  z-index: 5;
  padding: 5px 10px;
  width: 300px;
  box-shadow: 0px 3px 40px rgba(208, 208, 221, 0.43);
  border-radius: 10px;
`;

export const CheckableInputParentWrapper = styled.div<{
  disabled?: boolean;
  editor?: boolean;
}>`
  display: flex;
  // align-items: center;
  width: 100%;
  justify-content: space-between;
  grid-template-columns: 95% 8%;
  border-radius: 10px;
  .meatball {
    display: flex;
    padding-left: 10px;
    align-items: center;
    svg {
      path {
        // stroke: ${({editor}) => (editor ? undefined : '#bfbfd4')};
      }
    }
  }
`;

export const RemoveButton = styled.button`
  display: flex;
  border: none;
  background: none;
  appearance: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  margin: 0;
  position: absolute;
  bottom: 15px;
  right: -35px;
  z-index: 1;
`;

export const HelperWrapper = styled.div`
  position: relative;

  > span {
    bottom: -12px;
  }
`;

export const TextFieldWrapper = styled.div<{editor?: boolean}>`
  position: relative;
  width: 100%;
`;

export const EditorWrapper = styled.div<{color: Colors}>`
  position: relative;
  width: 100%;
  .editor {
    margin-left: 6px;
    width: 100%;
    font-size: 16px;
    font-weight 400;
    color: ${({color}) => (color ? getColorFromTheme(color) : undefined)};
  }
  .editor :global(.public-DraftEditor-content) {
    min-height: 40px;
    word-wrap: break-word;
    overflow-x: hidden;
  }
  .editor .public-DraftEditorPlaceholder-root {
    color: #5f5f8c;
    pointer-events: none;
    font-size: 16px;
    font-weight 400;
    width: 100%;
  }
  .editor .public-DraftEditorPlaceholder-inner {
    position: absolute;
     pointer-events: none;
  }
`;
