import React from 'react';

export const ClockIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.4987 3.66927C7.4987 3.39313 7.27484 3.16927 6.9987 3.16927C6.72256 3.16927 6.4987 3.39313 6.4987 3.66927V7.66927C6.4987 7.94541 6.72256 8.16927 6.9987 8.16927H9.66536C9.94151 8.16927 10.1654 7.94541 10.1654 7.66927C10.1654 7.39313 9.94151 7.16927 9.66536 7.16927H7.4987V3.66927Z"
        fill="#585ADF"
        strokeWidth={1.5}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6654 7.0026C13.6654 10.6845 10.6806 13.6693 6.9987 13.6693C3.3168 13.6693 0.332031 10.6845 0.332031 7.0026C0.332031 3.32071 3.3168 0.335938 6.9987 0.335938C10.6806 0.335938 13.6654 3.32071 13.6654 7.0026ZM12.6654 7.0026C12.6654 10.1322 10.1283 12.6693 6.9987 12.6693C3.86908 12.6693 1.33203 10.1322 1.33203 7.0026C1.33203 3.87299 3.86908 1.33594 6.9987 1.33594C10.1283 1.33594 12.6654 3.87299 12.6654 7.0026Z"
        fill="#585ADF"
        strokeWidth={1.5}
      />
    </svg>
  );
};
