import {useAnalyticsFeedbackHook} from '@pages/dashboard/reporting/reporting-pages/admin-analytics/analytics-pages/analytics-feedback/feedback-hook';
import {useStoreContext} from '@store/store-context';
import {FeedbackAnalytics} from '@ui/organisms/feedback/feedback-analytics';
import {memo, useMemo, useState} from 'react';
import {useNavigate} from 'react-router';

export const HomeFeedbackWidget = memo(() => {
  const {
    authStore: {auth},
  } = useStoreContext();

  const [activeTab, setActiveTab] = useState<'you' | 'team' | 'company'>('you');

  const computeFilters = useMemo(() => {
    return {
      manager: activeTab === 'team' ? auth.user.id : undefined,

      user: activeTab === 'you' ? auth.user.id : undefined,
    };
  }, [activeTab, auth.user.id]);

  const {summary, pieData, isLoading, totalValue} = useAnalyticsFeedbackHook(
    computeFilters,
  );

  const navigate = useNavigate();

  return (
    <div>
      <FeedbackAnalytics
        showNav={auth.user.role === 'admin' || auth.user.isReviewer}
        showCompany={auth.user.role === 'admin'}
        viewFooterText="feedback"
        handleContinue={() => navigate('/feedback/public-wall')}
        showTeam={auth.user.isReviewer}
        summary={{
          requested: summary?.requested || 0,
          given: summary?.given || 0,
        }}
        updateActiveTab={(value) => setActiveTab(value)}
        isLoading={isLoading}
        analyticsData={pieData}
        analyticsTotal={totalValue}
        impressions={{
          commend: {
            value: summary?.impressions?.commend,
            percentage: summary?.impressions?.commendPercentage,
          },
          neutral: {
            value: summary?.impressions?.neutral,
            percentage: summary?.impressions?.neutralPercentage,
          },
          critic: {
            value: summary?.impressions?.critic,
            percentage: summary?.impressions?.criticPercentage,
          },
        }}
      />
    </div>
  );
});
