import React, {FC, useMemo} from 'react';
import {InsightCard} from '../../dashboard/insight-card';
import {Insight} from '../interface';
import {useInsightActionItemHook} from './action-items-insight-hook';
import {formatConfigDate, comparisonConfigDate} from '../insight-options';

interface ActionItemCompletionRateProps extends Insight {
  showRangeType?: boolean;
}

export const ActionItemCompletionRate: FC<ActionItemCompletionRateProps> = ({
  ...rest
}) => {
  const config = rest.config;

  const formatDate = useMemo(() => formatConfigDate(config), [config]);

  const comparisonDate = useMemo(
    () => comparisonConfigDate(config, formatDate.startDate),
    [config, formatDate.startDate],
  );

  const {summary} = useInsightActionItemHook({
    members: config.users || [],
    sections: config.list || [],
    date: formatDate,
    comparisonDate: comparisonDate,
  });

  return (
    <div>
      <InsightCard
        {...rest}
        value={`${summary.completionRate.current}%`}
        percentProgress={summary.completionRate.change}
      />
    </div>
  );
};
