import React, {useState} from 'react';
import {Headline2, Body2} from '../../ui/atoms/typography';
import dayjs from 'dayjs';
import {VerticalSpacer} from '../../ui/atoms/spacer';
import {EmptyPlaceholder} from '../../ui/atoms/empty-placeholder';
import {cloudinary} from '../../assets/images/cloudinary';
import {ModalCard} from '../../ui/layouts/modal-card';
import {BackAction} from '../../ui/molecules/back-action';
import {Modal} from '../../ui/molecules/modal';
import {CancelIcon} from '../../ui/atoms/icons';
import {Checkbox} from '../../ui/atoms/checkbox';
import {Button} from '../../ui/atoms/button';
import {ImportHooks} from './import-hooks';
import {
  HistoryTable,
  HistoryTableContainer,
  HistoryTableItems,
  TableContainer,
} from './import-styles';
import {UserListCard} from '../../ui/molecules/user/list-card';

export default function BulkHistory({type, history}: any) {
  const {emptyGoals: emptyGoal} = cloudinary;
  const [isCheck, setIsCheck] = useState<any>([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [dateFilter, setDateFilter] = useState<Date>();
  const {revertImportGoals, isloading} = ImportHooks();
  const EmptyImport = {
    image: {
      alt: 'alt text',
      shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
      src: emptyGoal,
      style: {height: '30%', width: '30%'},
    },
    subtitle: '',
    title: `Your import history would be
    displayed here.`,
  };

  const handleSelectAll = (e: any) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(history?.results.map((li: any) => li.id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };
  const getLastDate = () => {
    var imports: any = [];
    isCheck.forEach((i: any) => {
      const fil = history?.results.filter((ite: any) => {
        return i === ite.id;
      });
      imports.push(new Date(fil[0].createdAt));

      var minDate = new Date(Math.min.apply(null, imports));
      setDateFilter(minDate);
    });
  };

  const handleClick = (e: any) => {
    const {id, checked} = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item: any) => item !== id));
    }
  };

  return (
    <div>
      <Headline2
        style={{fontSize: '25px', fontWeight: 600, lineHeight: '32.5px'}}>
        View History
      </Headline2>
      <VerticalSpacer size="12px" />
      <Body2 kind="textBody">
        {' '}
        Use import history to see every successful upload made.
      </Body2>
      <VerticalSpacer size="32px" />

      <div
        className="bg-white"
        style={{
          border: '1px solid rgba(237, 237, 242, 1)',
          borderRadius: '10px',
          height: '70%',
        }}>
        {history?.results && history?.results.length !== 0 && (
          <div className="flex justify-between px-5 py-4">
            <div style={{display: 'flex'}}>
              <div className="flex items-center relative pr-2">
                <Checkbox
                  kind="import"
                  onChange={handleSelectAll}
                  checked={isCheckAll}
                />
                <Body2 kind="textBody" style={{marginLeft: '5px'}}>
                  Select all
                </Body2>
                <div className="border-l border-l-[rgba(237, 237, 242, 1)] h-[20px] ml-2"></div>
              </div>
              <div className="flex items-center whitespace-nowrap">
                <Body2
                  kind="textBody"
                  className="flex items-center justify-center">
                  Showing {history?.results.length}
                </Body2>
                <Body2
                  kind="textBody"
                  style={{fontWeight: 500, marginLeft: '2px'}}>
                  of {history?.totalResults}
                </Body2>
              </div>
            </div>
            <div>
              <Button
                width="sm"
                disabled={!isCheck.length}
                onClick={() => {
                  getLastDate();
                  setOpenModal(true);
                }}
                kind="primary"
                style={{
                  height: '40px',
                  overflow: 'hidden',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                Revert Import
              </Button>
            </div>
          </div>
        )}

        <HistoryTableContainer>
          <TableContainer>
            <HistoryTable type={type}>
              {type === 'admin' && (
                <Body2 kind="textDark" weight="semibold" className="pl-1">
                  Importer
                </Body2>
              )}
              <span>Filename</span>
              <span>Count</span>
              <span>Date</span>
            </HistoryTable>

            {history?.results && !history?.results.length ? (
              <EmptyPlaceholder {...EmptyImport} />
            ) : (
              history?.results &&
              history?.results
                .filter((i: any) => i.status === 'active')
                .map((item: any) => (
                  <>
                    <HistoryTableItems type={type}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                        }}>
                        <div style={{marginRight: '5px'}}>
                          <Checkbox
                            kind="import"
                            onChange={handleClick}
                            name={item.fileName}
                            id={item.id}
                            checked={isCheck.includes(item.id)}
                          />
                        </div>
                        <UserListCard
                          kind="admin"
                          type="secondary"
                          tooltip={true}
                          avatar={
                            item?.createdBy.firstName
                              ? item?.createdBy.firstName
                              : ''
                          }
                          reviewer={item?.createdBy.reviewer}
                          name={
                            item?.createdBy.firstName &&
                            item?.createdBy.lastName
                              ? `${item?.createdBy.firstName} ${item?.createdBy.lastName}`
                              : ` ... (pending)`
                          }
                          userId={item?.createdBy.id}
                        />
                      </div>
                      <Body2 style={{marginLeft: '5px'}}>
                        {item?.fileName}
                      </Body2>
                      <Body2 kind="textBody">{item.goalsCount}</Body2>
                      <Body2 kind="textBody">
                        {dayjs(item.createdAt).format('dddd, MMMM DD, YYYY')}
                      </Body2>
                      <a
                        href={item.file}
                        download={`${item.fileName}.xslx`}
                        className="flex items-center justify-center">
                        <Body2
                          weight="semibold"
                          className="cursor-pointer flex justify-center items-center"
                          style={{
                            color: 'rgba(88, 90, 223, 1)',
                            lineHeight: '23.8px',
                          }}>
                          Download
                        </Body2>
                      </a>
                    </HistoryTableItems>
                  </>
                ))
            )}
          </TableContainer>
        </HistoryTableContainer>

        <>
          <Modal open={openModal} onClose={() => {}}>
            <div style={{maxWidth: '601px', margin: 'auto', marginTop: '10%'}}>
              <BackAction
                width="md"
                icon={<CancelIcon />}
                onClick={() => setOpenModal(false)}
                title="Close"
              />
              <VerticalSpacer size="16px" />
              <ModalCard align="center" title="">
                <div>
                  <Headline2 align="center">Revert import?</Headline2>

                  <VerticalSpacer size="16px" />

                  <Body2 kind="textBody" align="center">
                    Goals will revert to the version prior to{' '}
                    {dayjs(dateFilter).format('MMMM DD, YYYY')}. You cannot undo
                    this action.
                  </Body2>
                  <VerticalSpacer size="16px" />

                  <Button
                    width="full"
                    isLoading={isloading}
                    onClick={() => revertImportGoals(isCheck)}>
                    Revert
                  </Button>
                  <VerticalSpacer size="32px" />
                </div>
              </ModalCard>
            </div>
          </Modal>
        </>
      </div>
    </div>
  );
}
