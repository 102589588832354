import {Headline4, Body2, CTA} from '@ui/atoms/typography';
import {SectionCard} from '@ui/layouts/section-card';
import {MultiOptionsSelectFieldGroup} from '@ui/molecules/select/multi-options/multi-options';
import {ItemLoaderLine} from '@ui/organisms/item-loader-line';
import {checkinName} from '@utils/checkin-name';
import React, {FC, memo, useCallback, useEffect, useState} from 'react';
import {SectionComponentWrapper} from '../admin-checkins.styles';
import {useBreakpoints} from '@utils/use-breakpoints';
import {useStoreContext} from '@store/store-context';
import {Button} from '@ui/atoms/button';

interface CheckinGroupProps {
  value?: string[];
  errors?: any;
  handleFormValueChange: (data: any, value: string) => void;
}

export const CheckinGroups: FC<CheckinGroupProps> = memo(
  ({value = [], handleFormValueChange}) => {
    const [isEditingCheckinGroups, setIsEditingCheckinGroups] = useState(false);

    const [groupOptions, setgroupOptions] = useState<any[]>([]);

    const [loadingGroups, setLoadingGroups] = useState<any>(false);

    const {
      groupStore: {groups},
      groupTypeStore: {groupType},
    } = useStoreContext();

    const fetchGroup = useCallback(
      async (groupTypeId: string, name: string) => {
        try {
          setLoadingGroups(true);
          if (groups) {
            const _groups = groups.filter(
              (grp) => grp.groupType === groupTypeId,
            );

            setgroupOptions((prev) => [
              ...prev,
              {
                name: name,
                data:
                  _groups &&
                  _groups.map((group: any) => ({
                    label: group.name,
                    value: group.id,
                  })),
              },
            ]);
          }
          setLoadingGroups(false);
        } catch (error) {
          setLoadingGroups(false);
        }
      },
      [groups],
    );

    useEffect(() => {
      groupType.map((itm) => fetchGroup(itm?.id, itm.name));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupType]);

    const {isXs} = useBreakpoints();

    return (
      <div>
        <SectionCard
          title="Exclude groups"
          headerPadding={isXs ? 'small' : undefined}
          contentStyling={{
            padding: isXs ? '0px' : '0px 0px 10px 0px',
          }}
          action={
            <Button
              kind={isEditingCheckinGroups ? 'primary' : 'secondary'}
              style={{padding: '8px 16px'}}
              onClick={() => {
                setIsEditingCheckinGroups(!isEditingCheckinGroups);
              }}
              type={!isEditingCheckinGroups ? 'submit' : 'button'}>
              {isEditingCheckinGroups ? 'Save' : 'Edit'}
            </Button>
          }
          CustomHeaderTitle={
            <div>
              <Headline4 kind="textDark">
                Exclude groups from {checkinName()}s submission
              </Headline4>
              <Body2 kind="textBody">
                Select which group should be exempted from {checkinName()}s
              </Body2>
            </div>
          }>
          <SectionComponentWrapper>
            {loadingGroups && <ItemLoaderLine />}
            {!loadingGroups && (
              <>
                <CTA kind="textDark">Groups</CTA>

                <MultiOptionsSelectFieldGroup
                  options={groupOptions}
                  performance={true}
                  disabled={!isEditingCheckinGroups}
                  searchPlaceholder="Search group"
                  groupDisplayLength={5}
                  noOptionsMessage="Group not found"
                  placeholder="Select Groups"
                  defaultValue={value?.map((val?: string) => ({
                    label: groups?.find((value) => value?.id === val)?.name,
                    value: val,
                  }))}
                  onChange={(data: any) => {
                    handleFormValueChange(
                      data.map(
                        (group: {label: string; value: string}) => group.value,
                      ),
                      'excludedGroups',
                    );
                  }}
                />
              </>
            )}
          </SectionComponentWrapper>
        </SectionCard>
      </div>
    );
  },
);
