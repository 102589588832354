import {CcController} from '@api/cc-api/cc-controller';
import {CcRequestImpl} from '@api/cc-api/cc-request';
import {activateNotification} from '@ui/molecules/notification/activate-notification';
import {ccFirebaseReminderTracker} from '@utils/firebase-request';
import {useState} from 'react';

export const useNominationHook = () => {
  const request = new CcRequestImpl();
  const controller = new CcController(request);
  const [reminderLoading, setReminderLoading] = useState(false);
  const [selfLoader, setSelfLoader] = useState(false);

  const sendSelfReminder = async (
    userId: string,
    reviewerId: string,
    cycleId: string,
  ) => {
    setSelfLoader(true);

    const response = await controller.sendManagerReminder({
      user: userId,
      reviewer: reviewerId,
      cycleId,
    });

    setSelfLoader(false);
    if (response) {
      activateNotification({
        title: 'Success',
        content: 'Reminder sent successfully',
        kind: 'success',
      });

      ccFirebaseReminderTracker(userId, cycleId);
    }
  };

  const sendManagerReminder = async (
    userId: string,
    reviewerId: string,
    cycleId: string,
  ) => {
    setReminderLoading(true);

    const response = await controller.sendManagerReminder({
      user: userId,
      reviewer: reviewerId,
      cycleId,
    });
    setReminderLoading(false);
    activateNotification({
      title: 'Success',
      content: 'Reminder sent successfully',
      kind: 'success',
    });
    if (response) {
      ccFirebaseReminderTracker(reviewerId, cycleId);
    }
    return response;
  };
  return {
    sendManagerReminder,
    reminderLoading,
    selfLoader,
    sendSelfReminder,
  };
};
