import React, {FC, useState} from 'react';
import {Avatar} from '../avatar';
import {IAPIUserFormat} from '@hooks';
import {userName} from '@utils/user-name';
import {DropdownCustom} from '@ui/molecules/dropdown-custom';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {UserProfile} from '../icons/user-profile';
import {FlexRow, FlexRowSpaceBetween} from '@ui/style/styles';
import {Body1, Body2} from '../typography';
import {SearchBox2} from '@ui/molecules/search-box';
import {CancelIcon} from '../icons';
import {PlainButton} from '../plain-button/plain-button';

interface FieldUserProps {
  user?: IAPIUserFormat;
  assigneeOptions?: {label: string; value: string; avatar?: any}[];
  disabled?: boolean;
  handleChange?: (user: string) => void;
}

export const FieldUser: FC<FieldUserProps> = ({
  user,
  assigneeOptions,
  disabled,
  handleChange,
}) => {
  const [searchValue, setSearchValue] = useState('');

  const computeUsers =
    assigneeOptions &&
    assigneeOptions.filter((option) =>
      option.label.toLowerCase().includes(searchValue),
    );

  if (user) {
    return (
      <DropdownCustom
        collapseOnClick={true}
        menu={(handleClose: () => void) => (
          <div className="mx-1">
            <Body2 weight="semibold">Assigned to</Body2>
            <FlexRowSpaceBetween className="mt-4">
              <FlexRow className="gap-4 ">
                <Avatar
                  src={user?.avatar ? user?.avatar.url : ''}
                  name={userName(user)}
                  userId={user.id || ''}
                  tooltip={true}
                />
                <Body1>{userName(user)}</Body1>
              </FlexRow>
              <PlainButton
                onClick={() => {
                  handleChange && handleChange('');
                  handleClose();
                }}>
                <CancelIcon />
              </PlainButton>
            </FlexRowSpaceBetween>
          </div>
        )}
        customComponent={(handleOpen: (event: any) => void, open: boolean) => (
          <button disabled={disabled} onClick={handleOpen}>
            <Avatar
              src={user?.avatar ? user?.avatar.url : ''}
              name={userName(user)}
              userId={user?.id || ''}
              tooltip={true}
            />
          </button>
        )}
        customDropdownWrapperStyles={{
          top: '130%',
          maxHeight: '300px',
          right: 0,
          minWidth: '400px',
        }}
      />
    );
  }

  return (
    <div className="relative">
      <DropdownCustom
        collapseOnClick={true}
        menu={(handleClose: () => void) => (
          <div>
            <div className="mx-2 mb-2">
              <SearchBox2
                placeholder="Search member"
                value={searchValue}
                onChange={(value) => setSearchValue(value.target.value)}
                handleCancel={() => setSearchValue('')}
              />
            </div>
            <div>
              {computeUsers &&
                computeUsers.map((option) => (
                  <DropdownItem
                    key={option.value}
                    onClick={() => {
                      handleClose();
                      handleChange && handleChange(option.value);
                    }}>
                    <FlexRow className="gap-4">
                      <Avatar
                        src={option?.avatar ? option?.avatar.url : ''}
                        name={option.label}
                        userId={option.value || ''}
                        tooltip={true}
                      />
                      <Body1>{option.label}</Body1>
                    </FlexRow>
                  </DropdownItem>
                ))}
            </div>
          </div>
        )}
        customComponent={(handleOpen: (event: any) => void, open: boolean) => (
          <button disabled={disabled} onClick={handleOpen}>
            <UserProfile />
          </button>
        )}
        customDropdownWrapperStyles={{
          top: '-700%',
          padding: '10px 0px',
          maxHeight: '310px',
          right: 0,
          width: '290px',
        }}
      />
    </div>
  );
};
