import {Body2, Body1} from '@ui/atoms/typography';
import {ProgressBar} from '@ui/molecules/progress-bar';
import {UserListCard} from '@ui/molecules/user/list-card';
import {getPercentCompletedKeyResult} from '@utils/proportions';
import {SecondWrapperChildKeyResultsGraph} from './analytics-status.styles';
import {parseCurrencyValue} from '@constants';
import {capitalize} from '@utils';
import styled from 'styled-components';
import {HubSpotKeyResults} from '@ui/atoms/hubspot/hubspot-keyresults';
import {Tooltip} from '@ui/molecules/tooltip';
import {RadioChildSm} from '@ui/atoms/radio/radio';
import Lines from '@assets/images/line.png';

const commaNumber = require('comma-number');

const ImageWrapper = styled.div`
  .image {
    position: absolute;
    left: 11.7px;
    z-index: 0;
    top: 24px;
    height: calc(100% + 40px);
    width: 1px;
  }
`;

export const ChildGoal = ({
  childGoal,
  size,
  index,
  variant,
  activeGoal,
}: any) => {
  return (
    <>
      <div style={{width: '100%', padding: '10px 5px'}}>
        <div style={{display: 'flex'}}>
          <div style={{position: 'relative'}}>
            <RadioChildSm checked bg="#1A9E68" />
            {size !== index && (
              <ImageWrapper>
                <img src={Lines} alt="" className="image" />
              </ImageWrapper>
            )}
          </div>
          {variant === 'member' ? (
            <Body1
              style={{marginLeft: '10px'}}
              weight={activeGoal ? 'semibold' : 'regular'}>
              {capitalize(childGoal.name)}
            </Body1>
          ) : (
            <Body2 kind={'textMedium'} style={{marginLeft: '10px'}}>
              {childGoal.name}
            </Body2>
          )}
        </div>
        <div style={{margin: '6px 25px 5px 35px'}}>
          <UserListCard
            kind="admin"
            type="aligned"
            tooltip={true}
            reviewer={childGoal.assignee.reviewer}
            avatar={
              childGoal.assignee.avatar ? childGoal.assignee.avatar.url : ''
            }
            name={
              childGoal.assignee.firstName && childGoal.assignee.lastName
                ? `${childGoal.assignee.firstName} ${childGoal.assignee.lastName}`
                : ` ... (pending)`
            }
            userId={childGoal.assignee.id}
          />
        </div>
      </div>
    </>
  );
};
export const KeyResultValues = ({keyResult, index, variant}: any) => {
  const progressText = `(${
    keyResult.measurement.unit === 'currency' && keyResult.measurement.symbol
      ? parseCurrencyValue(keyResult.measurement.symbol)
      : keyResult.measurement.unit === 'currency' &&
        !keyResult.measurement.symbol
      ? parseCurrencyValue('')
      : ''
  } ${commaNumber(
    keyResult?.currentValue && keyResult.measurement.unit !== 'binary'
      ? keyResult?.currentValue
      : keyResult?.currentValue === 1 && keyResult.measurement.unit === 'binary'
      ? 'complete'
      : !keyResult?.currentValue && keyResult.measurement.unit === 'binary'
      ? 'Incomplete'
      : 0,
  )}${keyResult.measurement.unit === 'percentage' ? '%' : ''} ${
    (keyResult?.currentValue === 1 || !keyResult?.currentValue) &&
    keyResult.measurement.unit === 'binary'
      ? ''
      : 'of'
  } ${
    keyResult.measurement.unit === 'currency'
      ? parseCurrencyValue(keyResult.measurement.symbol || '')
      : ''
  }${commaNumber(
    keyResult.targetValue === 1 && keyResult.measurement.unit === 'binary'
      ? ''
      : keyResult.targetValue,
  )}${keyResult.measurement.unit === 'percentage' ? '%' : ''})`;

  return (
    <>
      <SecondWrapperChildKeyResultsGraph key={index} variant={variant}>
        <div className="endwrapper">
          <div className="keyResultName">
            <Body2 kind="textBody">{capitalize(keyResult.name)}</Body2>
          </div>
          <div>
            <div className="krResults">
              <UserListCard
                kind="admin"
                type="secondary"
                tooltip={true}
                reviewer={keyResult?.assignee?.reviewer}
                avatarName={
                  keyResult?.assignee?.firstName
                    ? keyResult?.assignee?.firstName +
                      '  ' +
                      keyResult?.assignee?.lastName
                    : ''
                }
                optional={
                  keyResult?.assignee?.firstName +
                  '  ' +
                  keyResult?.assignee?.lastName
                }
                avatar={
                  keyResult?.assignee.avatar
                    ? keyResult?.assignee.avatar.url
                    : ''
                }
                name=""
                userId={keyResult?.assignee?.id}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'end',
          }}>
          <>
            <ProgressBar
              // type={'graph-okr'}
              state={
                getPercentCompletedKeyResult(
                  keyResult.startValue,
                  keyResult.targetValue,
                  keyResult.currentValue,
                ) + '%'
              }
              percent={getPercentCompletedKeyResult(
                keyResult.startValue,
                keyResult.targetValue,
                keyResult.currentValue,
              )}
              measurement={keyResult.measurement}
              kpi={{
                current: keyResult?.currentValue,
                base: keyResult?.targetValue,
                good: keyResult?.goodValue,
                stretch: keyResult?.stretchValue,
              }}
            />
          </>

          <div
            style={{
              display: 'flex',
            }}>
            <Tooltip
              text={progressText}
              bodyTextNodeKind="textBody"
              withEllipsis
              BodyTextNodeType={Body2}
              bodyStyle={{paddingRight: '5px'}}
              tooltipBody={progressText}
              maxLength={25}
            />

            {variant === 'member' && keyResult?.source?.app && (
              <HubSpotKeyResults showIconOnly={true} keyResult={keyResult} />
            )}
          </div>
        </div>
      </SecondWrapperChildKeyResultsGraph>
    </>
  );
};
