import {Body1, Body2, Headline3} from '@ui/atoms/typography';
import {HorizontalSpacer} from '@ui/atoms/spacer';
import {FlexRow, FlexRowSpaceBetween} from '@ui/style/styles';
import {useMemo, useState} from 'react';
import {SectionCard} from '@ui/layouts/section-card';
import {Wrapper} from '../styles';
import {RichTextEditor} from '@ui/molecules/editor/editor-rich-text';
import {authStore} from '@store/stores/auth-store';
import {Avatar} from '@ui/atoms/avatar';
import {useStoreContext} from '@store/store-context';
import {userName} from '@utils/user-name';
import {SecondaryEyeOffIcon} from '@ui/atoms/icons/eye-off';

interface NotesProps {
  updateMeeting: (
    value: string | boolean,
    field:
      | 'hostNote'
      | 'participantNote'
      | 'shareHostNote'
      | 'shareParticipantNote',
    id?: string,
  ) => void;
  user: string;
  title?: string;
  dropdownComponent?: any;
  isPrivate?: boolean;
  notes?: {note: string; updatedBy: string; firebaseId?: string}[];
  isHostSharing?: boolean;
  excludeSharing?: boolean;
  isParticipantSharing?: boolean;
  note: string;
}
export const Notes = ({
  updateMeeting,
  user,
  note,
  notes,
  excludeSharing,
  isPrivate,
  dropdownComponent,
  isHostSharing,
  title,
  isParticipantSharing,
}: NotesProps) => {
  const {
    usersStore: {users},
  } = useStoreContext();

  const [noteValue, setNoteValue] = useState(note);

  const computeNotes = useMemo(() => {
    if (notes) {
      const isLoggedInUserNote = notes.find(
        (note) => note.updatedBy === authStore.auth.user.id,
      );
      const isOtherUsers = notes
        .filter((note) => note.updatedBy !== authStore.auth.user.id)
        .map((note) => ({
          ...note,
          updatedBy: users.find((user) => user.id === note.updatedBy),
        }));
      return {
        users: isOtherUsers,
        loggedInUser: isLoggedInUserNote,
      };
    }
  }, [notes, users]);

  return (
    <div>
      <SectionCard
        title=""
        contentStyling={{padding: '24px'}}
        action={
          <FlexRow>
            <div>{dropdownComponent && dropdownComponent}</div>
          </FlexRow>
        }
        CustomHeaderTitle={
          <div className="flex items-center justify-between">
            <Headline3 kind="textDark">{title || 'Meeting Notes'} </Headline3>
          </div>
        }>
        <div>
          <Wrapper disabled={false}>
            <div className="input px-4">
              {notes ? (
                <>
                  {computeNotes?.users.map((user) => (
                    <div className="mx-3">
                      <FlexRowSpaceBetween className="mb-2 ">
                        <Body1 className="w-5/12">{user.note}</Body1>
                        <Avatar
                          tooltip
                          userId={user.updatedBy?.id || ''}
                          name={userName(user.updatedBy)}
                          size="xs"
                        />
                      </FlexRowSpaceBetween>
                    </div>
                  ))}

                  <RichTextEditor
                    placeholder="Write note"
                    value={computeNotes?.loggedInUser?.note || ''}
                    onChange={(event) => {
                      setNoteValue(event.markdown);
                      updateMeeting(
                        event.markdown,
                        user === 'user' ? 'hostNote' : 'participantNote',
                        computeNotes?.loggedInUser?.firebaseId,
                      );
                    }}
                  />
                </>
              ) : (
                <RichTextEditor
                  placeholder="Write note"
                  value={noteValue}
                  onChange={(event) => {
                    setNoteValue(event.markdown);
                    updateMeeting(
                      event.markdown,
                      user === 'user' ? 'hostNote' : 'participantNote',
                    );
                  }}
                />
              )}
            </div>
            {isPrivate && (
              <div className="footer">
                <FlexRow>
                  <Body2 weight="semibold">Private</Body2>
                  <HorizontalSpacer size="8px" />

                  <SecondaryEyeOffIcon style={{stroke: '#5E5E73'}} />
                </FlexRow>
              </div>
            )}
          </Wrapper>
        </div>
      </SectionCard>
    </div>
  );
};
