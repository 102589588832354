import {memo} from 'react';

export const BinaryIcon = memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16">
    <path
      fill="#1E1E2F"
      d="M11.499 10.667c1.473 0 2.666-1.194 2.666-2.667 0-1.473-1.193-2.667-2.666-2.667-1.473 0-2.667 1.194-2.667 2.667 0 1.473 1.194 2.667 2.667 2.667z"
    />
    <path
      stroke="#1E1E2F"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6.833 8c0 1.197-.97 2.167-2.166 2.167C3.47 10.167 2.5 9.197 2.5 8c0-1.196.97-2.167 2.167-2.167 1.196 0 2.166.97 2.166 2.167z"
    />
  </svg>
));

BinaryIcon.displayName = 'BinaryIcon';
