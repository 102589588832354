import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isoWeeksInYear from 'dayjs/plugin/isoWeeksInYear';
import isLeapYear from 'dayjs/plugin/isLeapYear';
import {monthToIndex} from '@hooks/calendar';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import {capitalize} from './capitalize';

dayjs.extend(isoWeeksInYear);
dayjs.extend(isLeapYear);

dayjs.extend(quarterOfYear);
dayjs.extend(isBetween);
dayjs.extend(weekOfYear);

export const formatToMonthAndDay = (date: string, format = 'DD MMM.') => {
  return dayjs(date, 'MM-DD-YYYY').format(format);
};

export const daysBetween = (date1: string, date2: string) => {
  const date1_ = dayjs(date1);
  const date2_ = dayjs(date2);
  return date1_.diff(date2_, 'day');
};

export const monthsBetween = (date1: string, date2: string) => {
  const date1_ = dayjs(date1);
  const date2_ = dayjs(date2);
  return date1_.diff(date2_, 'month');
};

export const yearsBetween = (date1: string, date2: string) => {
  const date1_ = dayjs(date1);
  const date2_ = dayjs(date2);
  return date1_.diff(date2_, 'year');
};
const handleTimeDue = (endDate: string, today: string, fullDate?: boolean) => {
  if (daysBetween(endDate, today) < 100) {
    return `${daysBetween(endDate, today)} ${fullDate ? 'days' : 'd'}`;
  } else if (monthsBetween(endDate, today) < 100) {
    return `${monthsBetween(endDate, today)} ${fullDate ? 'months' : 'mo'} `;
  } else if (monthsBetween(endDate, today) > 100) {
    return `${yearsBetween(endDate, today)}  ${fullDate ? 'years' : 'yr'} `;
  }
};
export const goalDueDate = (
  endDate: string,
  fullDate?: boolean,
  goalEnded?: boolean,
  useEnded?: boolean,
) => {
  const today = new Date().toISOString();
  const date1_ = dayjs(endDate);
  const date2_ = dayjs(today);
  if (goalEnded) {
    return `Ended`;
  } else if (date2_.isSame(date1_, 'day')) {
    return `Due today`;
  } else if (date2_.isBefore(date1_)) {
    return `Due in ${handleTimeDue(endDate, today, fullDate)}`;
  } else {
    return useEnded ? 'Ended' : `Past due`;
  }
};

export function filterArrayByWeek(
  array: any,
  startDate: string,
  endDate: string,
) {
  // Convert startDate and endDate to Date objects
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Filter the array based on the weekOfYear property
  const filteredArray = array.filter(
    (item: {weekOfYear: number; year: number}) => {
      const firstDayOfYear = new Date(`${item.year}-01-01`);
      const daysToAdd = (item.weekOfYear - 1) * 7;
      const firstDayOfWeek = new Date(
        firstDayOfYear.getTime() + daysToAdd * 24 * 60 * 60 * 1000,
      );

      // Calculate the last day of the week by adding 6 days to the first day
      const lastDayOfWeek = new Date(
        firstDayOfWeek.getTime() + 6 * 24 * 60 * 60 * 1000,
      );

      // Check if the first day of the week is within the start and end dates
      return firstDayOfWeek >= start && lastDayOfWeek <= end;
    },
  );

  return filteredArray;
}

export const goalDueDate2 = (endDate: string) => {
  const today = new Date().toISOString();
  if (daysBetween(today, endDate) > 0) {
    return `${
      daysBetween(today, endDate) === 1
        ? 'yesterday'
        : daysBetween(today, endDate) + ' days ago'
    }`;
  } else {
    return `today`;
  }
};

// WEEK CALENDAR
export interface IWeekOfCurrentMonth {
  weekOfMonth: number;
  dateStart: string;
  dateEnd: string;
  currentMonth: boolean;
  isCurrentWeek: boolean;
  currentWeek: number;
  title: string;
}
//Current Year
export const INITIAL_YEAR = Number(dayjs().format('YYYY'));
// Current Month
export const INITIAL_MONTH = Number(dayjs().format('M'));
export const calendar = (
  year: number = INITIAL_YEAR,
  month: number = INITIAL_MONTH,
) => {
  // Today
  const TODAY = dayjs().format('YYYY-MM-DD');

  // Days in Month
  const getNumberOfDaysInMonth = (
    year: number = INITIAL_YEAR,
    month: number = INITIAL_MONTH,
  ) => {
    return dayjs(`${year}-${month}-01`).daysInMonth();
  };
  // Weeks in Month
  const createWeeksForCurrentMonth = (
    year: number = INITIAL_YEAR,
    month: number = INITIAL_MONTH,
  ) => {
    const numberOfWeeksInMonth = Math.ceil(
      getNumberOfDaysInMonth(year, month) / 7,
    );

    return [...Array(numberOfWeeksInMonth)].map((week: any, index: number) => {
      const numberOfDaysInMonth = getNumberOfDaysInMonth(year, month);

      // compute start date
      const dateStartDay = index * 7 + 1;
      const dateStart = dayjs(`${year}-${month}-${dateStartDay}`);
      const startMonth = dayjs(dateStart).format('MMM');
      const dateStartString = `${startMonth} ${dayjs(dateStart).format('D')}`;

      // compute end date
      const dateEndBaseDay = index * 7 + 7;
      const dateEndBase = dayjs(`${year}-${month}-${dateEndBaseDay}`);
      const correctedDateEndBase = (diff: number) => {
        return month < 12
          ? dayjs(`${year}-${month + 1}-${convertSingleDouble(diff)}`)
          : dayjs(`${year + 1}-01-${convertSingleDouble(diff)}`);
      };
      const diff = dateEndBaseDay - numberOfDaysInMonth;
      const dateEnd =
        dateEndBaseDay <= numberOfDaysInMonth
          ? dateEndBase
          : correctedDateEndBase(diff);
      const endMonth = dayjs(dateEnd).format('MMM');
      const dateEndString = `${endMonth} ${dayjs(dateEnd).format('D')}`;

      return {
        weekOfMonth: index + 1,
        dateStart: dateStart.format('YYYY-MM-DD'),
        dateEnd: dateEnd.format('YYYY-MM-DD'),
        currentMonth: true,
        isCurrentWeek: dayjs(TODAY).isBetween(dateStart, dateEnd, null, '[]'),
        currentWeek: index + 1,
        title: `${dateStartString} - ${dateEndString}`,
      };
    });
  };

  return {
    currentMonthWeeks: createWeeksForCurrentMonth(year, month),
    calendarTitle: `${dayjs(`${year}-${month}-01`).format('MMMM')} ${dayjs(
      `${year}-${month}-01`,
    ).format('YYYY')}`,
    thisCurrentPresentWeekDateInterval: {
      start: createWeeksForCurrentMonth(INITIAL_YEAR, INITIAL_MONTH).filter(
        (week: IWeekOfCurrentMonth, index: number) =>
          week.isCurrentWeek === true,
      )[0].dateStart,
      end: createWeeksForCurrentMonth(INITIAL_YEAR, INITIAL_MONTH).filter(
        (week: IWeekOfCurrentMonth, index: number) =>
          week.isCurrentWeek === true,
      )[0].dateEnd,
    },
    year,
    month,
    thisCurrentPresentYear: Number(dayjs(TODAY).format('YYYY')),
    thisCurrentPresentMonth: Number(dayjs(TODAY).format('M')),
  };
};

// convert single digit numbers to double
export const convertSingleDouble = (num: number) => {
  let n: string = String(num);
  if (n.length === 1) {
    n = '0' + n;
    return Number(n);
  } else {
    return num;
  }
};

export function YearRange() {
  return {
    starts: dayjs().startOf('year').format('MM/DD/YYYY'),
    ends: dayjs().endOf('year').format('MM/DD/YYYY'),
  };
}
export function AnalyticsTodayDate() {
  const localStorageAuth = localStorage.getItem('auth');
  const auth = localStorageAuth ? JSON.parse(localStorageAuth) : undefined;

  const isPeriodEnabled = auth?.user?.workspace?.settings?.customPeriod;

  const periodStartMonth =
    auth?.user?.workspace?.settings?.customPeriodStartMonth;

  const periods = () => {
    const now = dayjs();
    const startMonthIndex = monthToIndex(periodStartMonth) - 1;

    const currentYear = now.year();
    const currentMonth = now.month() + 1; // January is 0

    let startYear = currentYear;
    let startMonth = startMonthIndex;

    if (startMonth > currentMonth) {
      startYear -= 1;
    }
    while (startMonth + 2 > 11) {
      startMonth -= 3;
      startYear += 1;
    }

    function formatDate(date: any) {
      return date.format('MM/DD/YYYY');
    }

    function getQuarterLabel(startMonth: number, startYear: number) {
      const start = dayjs(new Date(startYear, startMonth, 1));
      const end = start.clone().endOf('quarter');
      return `${start.format('DD MMMM')} - ${end.format('DD MMMM')}`;
    }

    const periods: any = [];
    for (let i = 0; i < 4; i++) {
      const quarterStartMonth = startMonth + i * 3;

      const quarterStartYear = startYear + Math.floor(quarterStartMonth / 12);

      const quarterName = `Q${i + 1} ${quarterStartYear} (${getQuarterLabel(
        quarterStartMonth,
        quarterStartYear,
      )})`;

      const periodStart = dayjs(new Date(startYear, quarterStartMonth, 1));

      const periodEnd = periodStart.clone().endOf('quarter');

      const period = {
        value: formatDate(periodStart),
        label: quarterName,
        starts: dayjs(periodStart).format('MM/DD/YYYY'),
        period: `Q${i + 1} ${quarterStartYear}`,
        ends: dayjs(periodEnd).format('MM/DD/YYYY'),
      };

      if (periodEnd.isAfter(now.endOf('quarter'))) {
        continue;
      }
      periods.push(period);
    }
    return [...periods];
  };

  const currentMonthRange = () => {
    const now = new Date();
    const currentMonth = now.getMonth() + 1;

    return (
      periods().find((period) => {
        const periodStartMonth = new Date(period.starts).getMonth() + 1;

        const periodEndMonth = new Date(period.ends).getMonth() + 1;
        return (
          currentMonth >= periodStartMonth && currentMonth <= periodEndMonth
        );
      }) || {
        value: '',
        label: '',
        starts: '',
        ends: '',
        period: '',
      }
    );
  };

  const today = new Date().toISOString();

  let priorDate = new Date(new Date().setDate(new Date().getDate() - 30));

  const AnalyticsDefaultEndDate =
    isPeriodEnabled && currentMonthRange().ends
      ? currentMonthRange().ends
      : dayjs(today).format('MM/DD/YYYY');

  const AnalyticsDefaultStartDate =
    isPeriodEnabled && currentMonthRange().starts
      ? currentMonthRange().starts
      : dayjs(priorDate).format('MM/DD/YYYY');
  const AnalyticsDefaultPeriod =
    isPeriodEnabled && currentMonthRange().period
      ? currentMonthRange().period
      : undefined;

  return {
    AnalyticsDefaultEndDate,
    AnalyticsDefaultStartDate,
    AnalyticsDefaultPeriod,
  };
}

export function getWeekRange(weekNo: number, yearNo: number) {
  let firstDayofYear = new Date(yearNo, 0, 1);

  if (firstDayofYear.getDay() > 4) {
    let weekStart = new Date(
      yearNo,
      0,
      1 + (weekNo - 1) * 7 - firstDayofYear.getDay() + 8,
    );
    let weekEnd = new Date(
      yearNo,
      0,
      1 + (weekNo - 1) * 7 - firstDayofYear.getDay() + 8 + 4,
    );
    return {starts: weekStart, ends: weekEnd};
  } else {
    let weekStart = new Date(
      yearNo,
      0,
      1 + (weekNo - 1) * 7 - firstDayofYear.getDay() + 0,
    );
    let weekEnd = new Date(
      yearNo,
      0,
      1 + (weekNo - 1) * 7 - firstDayofYear.getDay() + 1 + 5,
    );
    return {starts: weekStart, ends: weekEnd};
  }
}
export function getWeekRangeWorkDays(weekNo: number, yearNo: number) {
  let firstDayofYear = new Date(yearNo, 0, 1);

  if (firstDayofYear.getDay() > 4) {
    let weekStart = new Date(
      yearNo,
      0,
      1 + (weekNo - 1) * 7 - firstDayofYear.getDay() + 8,
    );
    let weekEnd = new Date(
      yearNo,
      0,
      1 + (weekNo - 1) * 7 - firstDayofYear.getDay() + 8 + 4,
    );
    return {starts: weekStart, ends: weekEnd};
  } else {
    let weekStart = new Date(
      yearNo,
      0,
      1 + (weekNo - 1) * 7 - firstDayofYear.getDay() + 1,
    );
    let weekEnd = new Date(
      yearNo,
      0,
      1 + (weekNo - 1) * 7 - firstDayofYear.getDay() + 1 + 4,
    );
    return {starts: weekStart, ends: weekEnd};
  }
}
export function getNumberOfWeeksInYear(year: number) {
  return dayjs(`${year}-01-01`).isoWeeksInYear();
}
export const getWeekOfYear = (date = new Date()) => {
  return dayjs(date).week();
};
export const getWeekOfMonth = (date = new Date()) => {
  const date_ = date.getDate();
  const day_ = date.getDay();
  return Math.ceil((date_ - 1 - day_) / 7);
};
export const getWeekOfMonthFromWeekOfYear = (
  weekOfYear: number,
  year: number,
) => {
  const genFirstDate = getWeekRange(weekOfYear, year).starts;
  return getWeekOfMonth(genFirstDate);
};
export function getMonthFromWeek(year?: number, week?: number) {
  if (year && week) {
    const date = dayjs().year(year).week(week).startOf('week');

    const month = date.month();
    return month;
  }
}
export const CheckinsCurrentWeek = () => {
  const today = new Date().toISOString();
  let priorDate = new Date(new Date().setDate(new Date().getDate() - 30));
  const AnalyticsDefaultEndDate = dayjs(today).format('MM/DD/YYYY');
  const AnalyticsDefaultStartDate = dayjs(priorDate).format('MM/DD/YYYY');
  return {
    AnalyticsDefaultEndDate,
    AnalyticsDefaultStartDate,
  };
};
export const AnalyticsExportDates = (
  timeframe: string,
  customDate?: {starts: string; ends: string},
) => {
  const today = new Date().toISOString();
  let priorDate = new Date(new Date().setDate(new Date().getDate() - 30));
  const AnalyticsDefaultEndDate = dayjs(today).format('MM/DD/YYYY');
  const AnalyticsDefaultStartDate = dayjs(priorDate).format('MM/DD/YYYY');
  let pastWeek = {
    start: new Date(new Date().setDate(new Date().getDate() - 7)),
    end: new Date(new Date().setDate(new Date().getDate())),
  };
  let pastMonth = {
    start: new Date(new Date().setDate(new Date().getDate() - 30)),
    end: new Date(new Date().setDate(new Date().getDate())),
  };
  let pastQuarter = {
    start: new Date(new Date().setDate(new Date().getDate() - 90)),
    end: new Date(new Date().setDate(new Date().getDate())),
  };
  if (timeframe === 'past_week') {
    return pastWeek;
  } else if (timeframe === 'last_month') {
    return pastMonth;
  } else if (timeframe === 'last_quarter') {
    return pastQuarter;
  } else if (timeframe === 'custom_dates') {
    return {
      start: customDate?.starts,
      end: customDate?.ends,
    };
  }
  return {
    start: AnalyticsDefaultStartDate,
    end: AnalyticsDefaultEndDate,
  };
};

export const getDifferenceInDays = (date1: any, date2: any) => {
  const date1_ = dayjs(date1);
  const date2_ = dayjs(date2);
  return date1_.diff(date2_, 'day');
};

export const getDayOfWeekStringFromIndex = (index: number) => {
  return [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ][index];
};
export const getDayOfWeekIndexFromString = (dayOfWeek?: string) => {
  if (dayOfWeek) {
    return [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ].indexOf(capitalize(dayOfWeek));
  } else return 5;
};
export const dateParamsToday = () => {
  return {
    dayOfWeekIndex: new Date().getDay(),
    dayOfWeekString: getDayOfWeekStringFromIndex(new Date().getDay()),
  };
};

export const getTime = (time: string) => {
  const timeParts = time.split(':');

  let targetHour = parseInt(timeParts[0]);

  const targetMinute = timeParts[1] ? parseInt(timeParts[1].split(' ')[0]) : 0;

  const targetPeriod = time.toLowerCase();

  if (targetPeriod.includes('pm') && targetHour !== 12) {
    targetHour += 12;
  } else if (targetPeriod.includes('am') && targetHour === 12) {
    targetHour = 0;
  }

  return {
    targetPeriod: targetPeriod.includes('pm') ? 'pm' : 'am',
    targetHour,
    targetMinute,
  };
};

export function parseDateString(
  input: string,
): {starts: string; period?: string; ends: string} | null {
  const patternWithPeriod = /(.*)\((.*) - (.*)\)/;
  const patternWithoutPeriod = /(.*) - (.*)/;

  const matchWithPeriod = input.match(patternWithPeriod);
  const matchWithoutPeriod = input.match(patternWithoutPeriod);

  if (matchWithPeriod) {
    return {
      period: matchWithPeriod[1],
      starts: matchWithPeriod[2],
      ends: matchWithPeriod[3],
    };
  } else if (matchWithoutPeriod) {
    return {
      period: undefined,
      starts: matchWithoutPeriod[1],
      ends: matchWithoutPeriod[2],
    };
  }

  return null; // Return null for invalid input
}

export const splitTimeFormat = (time: string) => {
  if (!time.includes(':')) {
    time = time.replace(/(am|pm)/i, ':00 $1');
  }

  const timeParts = time.split(':');

  let targetHour = parseInt(timeParts[0]);

  const secondSplit = timeParts[1]?.split(' ');

  const targetMinute = parseInt(secondSplit?.[0]);

  const targetPeriod = secondSplit?.[1];

  if (targetPeriod === 'PM' && targetHour !== 12) {
    targetHour += 12;
  } else if (targetPeriod === 'AM' && targetHour === 12) {
    targetHour = 0;
  }
  return {
    targetPeriod,
    targetHour,
    targetMinute,
  };
};

export function daysLeftToNextCheckin(
  frequency: 'weekly' | 'bi-weekly' | 'monthly',
) {
  const currentDate = dayjs();

  const value = frequency === 'weekly' || frequency === 'monthly' ? 1 : 14;

  const unit =
    frequency === 'weekly'
      ? 'weeks'
      : frequency === 'monthly'
      ? 'months'
      : 'days';

  const addFrequencyToDate = currentDate.add(value, unit);

  const getStartOfWeek = addFrequencyToDate.startOf('week');

  const daysLeft = getStartOfWeek.diff(currentDate, 'days');

  return daysLeft + 1;
}
type ErrorCallback = (error: string) => void;
type PeriodChangeCallback = (period: string) => void;

const validateTime = (
  hours: string,
  minutes: string,
  onError?: ErrorCallback,
): boolean => {
  if (parseInt(hours, 10) > 24 || parseInt(minutes, 10) > 59) {
    onError?.('Invalid time format');
    return false;
  }
  onError?.('');
  return true;
};

const convertTo12HourFormat = (
  hours: string,
  onPeriodChange?: PeriodChangeCallback,
): string => {
  const is24HourPeriod = Number(hours) > 12;

  const convertedHours = is24HourPeriod ? String(Number(hours) - 12) : hours;

  if (is24HourPeriod) {
    onPeriodChange?.('pm');
  }

  return convertedHours;
};

const handleColonFormat = (
  cleanInput: string,
  onError?: ErrorCallback,
  onPeriodChange?: PeriodChangeCallback,
): string => {
  const [hours, minutes] = cleanInput.split(':');

  if (!validateTime(hours, minutes, onError)) return '';

  return `${parseInt(
    convertTo12HourFormat(hours, onPeriodChange),
    10,
  )}:${minutes.padStart(2, '0')}`;
};

const handleNumericFormat = (
  cleanInput: string,
  onError?: ErrorCallback,
  onPeriodChange?: PeriodChangeCallback,
): string => {
  const numericValue = cleanInput.replace(/\D/g, '');
  const timeValue = parseInt(numericValue, 10);

  if (timeValue > 2459 || numericValue.length > 4 || isNaN(timeValue)) {
    onError?.('Invalid time format');
    return '';
  }

  onError?.('');

  if (numericValue.length <= 2) {
    return `${convertTo12HourFormat(numericValue, onPeriodChange)}:00`;
  } else {
    const hours = Math.floor(timeValue / 100).toString();
    const minutes = (timeValue % 100).toString();
    return `${convertTo12HourFormat(hours, onPeriodChange)}:${minutes.padStart(
      2,
      '0',
    )}`;
  }
};

export const formatTime = (
  inputValue: string,
  onError?: ErrorCallback,
  onPeriodChange?: PeriodChangeCallback,
): string => {
  const cleanInput = inputValue.replace(/[^\d:]/g, '');
  if (cleanInput.includes(':')) {
    return handleColonFormat(cleanInput, onError, onPeriodChange);
  } else {
    return handleNumericFormat(cleanInput, onError, onPeriodChange);
  }
};
