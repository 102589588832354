import styled from 'styled-components';
import {useCallback, useEffect, useMemo} from 'react';
import {DateRangeInput} from '@ui/molecules/date-range-input';
import {VerticalSpacer} from '@ui/atoms/spacer';
import dayjs from 'dayjs';
import {down} from 'styled-breakpoints';
import {DatePicker} from '@ui/molecules/date-picker';
import {Body2} from '@ui/atoms/typography';
import {ValueProps} from '../../create-review-interface';

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;

const DateRangeContainer = styled.div`
  padding: 16px;
  border-radius: 10px;
  border: 1px solid var(--border-light, #ededf2);
`;

const DateWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .w-full {
    width: 100%;
  }
  .w-46 {
    width: 48%;
  }
  ${down('md')} {
    display: block;
  }
`;

interface MileStoneProps {
  mileStones: ValueProps['milestones'];
  handleChange: (value: ValueProps['milestones']) => void;
  errors?: {
    startDate?: string;
    endDate?: string;
    peer?: string;
    self?: string;
    manager?: string;
    finalize?: string;
  };
  cycleType: 'self' | '360' | '';
}
export default function ReviewMilestones({
  mileStones: values,
  handleChange,
  errors,
  cycleType,
}: MileStoneProps) {
  const updateDueByReviewMilestone = (field: string, value: string) => {
    handleChange({
      ...values,
      [field]: value,
    });
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const formatTime = (e: string) =>
    dayjs(e).set('hour', 23).set('minute', 59).set('second', 0).format();

  const setOverallTimeline = (value: {startDate: string; endDate: string}) => {
    const _value = {
      startDate: value.startDate,
      endDate: dayjs(value.endDate).isValid() ? formatTime(value.endDate) : '',
    };

    handleChange({
      ...values,
      ..._value,
    });
  };

  const setSelfDueFor = (value: string) => {
    updateDueByReviewMilestone('self', value);
  };

  const setManagerDueFor = (value: string) => {
    updateDueByReviewMilestone('manager', value);
  };

  const setFinalizeReviewDueFor = (value: string) => {
    updateDueByReviewMilestone('finalize', value);
  };

  const setpeerDueFor = (value: string) => {
    updateDueByReviewMilestone('peer', value);
  };

  const selfDueFor = values.self;

  const overallTimeline = {starts: values.startDate, ends: values.endDate};

  const peerDueFor = values.peer;

  const managerDueFor = values.manager;

  const finalizeReviewDueFor = values.finalize;

  const handleSelfDue = (e: string) => {
    setSelfDueFor(formatTime(e));
  };

  const handlePeerDue = (e: string) => {
    const formattedDate = formatTime(e);

    setpeerDueFor(formattedDate);
  };

  const handleManagerDue = (e: string) => {
    const formattedDate = formatTime(e);

    setManagerDueFor(formattedDate);
  };

  const handleFinalizeDue = (e: string) => {
    const formattedDate = formatTime(e);

    setFinalizeReviewDueFor(formattedDate);
  };

  const reviewIncludesPeer = useMemo(() => cycleType === '360', [cycleType]);

  const reviewisSelfReviewer = useMemo(() => cycleType === 'self', [cycleType]);

  useEffect(() => {
    if (reviewIncludesPeer && values.peer === undefined) {
      handleChange({
        ...values,
        peer: '',
      });
    }
  }, [handleChange, reviewIncludesPeer, values]);

  const DateRange = useCallback(
    ({
      overallTimeline,
      setOverallTimeline,
      error,
      peerDueFor,
      setError,
      managerDueFor,
      selfDueFor,
    }: any) => {
      return (
        <DateRangeInput
          value={values ? overallTimeline : {starts: '', ends: ''}}
          name="date"
          disabledBefore={dayjs(Date.now()).subtract(1, 'day').format()}
          setValue={(e) => {
            setOverallTimeline({startDate: e.starts, endDate: e.ends});

            if (error && reviewisSelfReviewer) {
              if (managerDueFor >= selfDueFor) {
                setError('');
              }
            }
            if (error && reviewIncludesPeer) {
              if (
                dayjs(peerDueFor).isBetween(e.starts, e.ends, 'dates', '[]')
              ) {
                setError('');
              }
            }
          }}
          placeholder="Enter date"
        />
      );
    },
    [values, reviewisSelfReviewer, reviewIncludesPeer],
  );

  const ManagerDateInput = (
    <div>
      <Body2 weight="semibold">Manager reviews due by</Body2>

      <VerticalSpacer size="10px" />
      <DatePicker
        label="Due"
        placeholder={managerDueFor ? '' : 'Choose date'}
        onChange={handleManagerDue}
        value={managerDueFor}
        disabledAfter={overallTimeline?.ends}
        disabledBefore={overallTimeline?.starts}
      />

      {errors?.manager && (
        <Body2
          kind="red400"
          weight="semibold"
          style={{
            fontSize: '12px',

            marginTop: '2px',
          }}>
          {errors?.manager}
        </Body2>
      )}
    </div>
  );

  return (
    <>
      <Wrapper>
        <div>
          <Body2 weight="bold">Review cycle overall timeline</Body2>

          <VerticalSpacer size="10px" />

          <div>
            <DateRange
              overallTimeline={overallTimeline}
              managerDueFor={managerDueFor}
              peerDueFor={peerDueFor}
              selfDueFor={selfDueFor}
              cycleType={cycleType}
              setOverallTimeline={setOverallTimeline}
            />
            {errors?.startDate && (
              <Body2
                kind="red400"
                weight="semibold"
                style={{
                  fontSize: '12px',

                  marginTop: '2px',
                }}>
                {errors?.startDate}
              </Body2>
            )}
          </div>
        </div>
        <VerticalSpacer size="24px" />

        <div>
          <Body2 weight="bold">Select due date for each review</Body2>
          <VerticalSpacer size="10px" />
          <DateRangeContainer className="bg-white">
            <DateWrapper>
              <div className={reviewIncludesPeer ? 'w-46' : 'w-full'}>
                <Body2 weight="semibold">Self reviews due by</Body2>
                <VerticalSpacer size="10px" />
                <DatePicker
                  label="Due"
                  placeholder={!selfDueFor ? 'Choose date' : ''}
                  onChange={handleSelfDue}
                  value={selfDueFor}
                  disabledAfter={overallTimeline?.ends}
                  disabledBefore={overallTimeline?.starts}
                />
                {errors?.self && (
                  <Body2
                    kind="red400"
                    weight="semibold"
                    style={{
                      fontSize: '12px',

                      marginTop: '2px',
                    }}>
                    {errors?.self}
                  </Body2>
                )}
              </div>
              {reviewIncludesPeer ? (
                <div className="w-46">
                  <Body2 weight="semibold">Peer reviews due by</Body2>
                  <VerticalSpacer size="10px" />
                  <DatePicker
                    label="Due"
                    placeholder={peerDueFor ? '' : 'Choose date'}
                    onChange={handlePeerDue}
                    value={peerDueFor}
                    disabledAfter={overallTimeline?.ends}
                    disabledBefore={overallTimeline?.starts}
                  />
                  {errors?.peer && (
                    <Body2
                      kind="red400"
                      weight="semibold"
                      style={{
                        fontSize: '12px',

                        marginTop: '2px',
                      }}>
                      {errors?.peer}
                    </Body2>
                  )}
                </div>
              ) : null}
            </DateWrapper>

            <VerticalSpacer size="14px" />

            <div>{ManagerDateInput}</div>
            <VerticalSpacer size="14px" />

            <div>
              <div>
                <Body2 weight="semibold">Finalize reviews by</Body2>

                <VerticalSpacer size="10px" />
                <DatePicker
                  label="Due"
                  placeholder={finalizeReviewDueFor ? '' : 'Choose date'}
                  onChange={handleFinalizeDue}
                  value={finalizeReviewDueFor}
                  disabledAfter={overallTimeline?.ends}
                  disabledBefore={managerDueFor}
                />
                {errors?.finalize && (
                  <Body2
                    kind="red400"
                    weight="semibold"
                    style={{
                      fontSize: '12px',

                      marginTop: '2px',
                    }}>
                    {errors?.finalize}
                  </Body2>
                )}
              </div>
            </div>
          </DateRangeContainer>
        </div>
      </Wrapper>
    </>
  );
}
