import styled from 'styled-components';
import {getColorFromTheme} from '@ui/ui-utils';

export const SecondWrapperHeader = styled.div`
  padding: 16px 16px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const StackViewWrapper = styled.div`
  display: block;
  background: ${getColorFromTheme('backgroundLight')};
  border-top: 1px solid ${getColorFromTheme('borderLight')};
  border-radius: 10px;
`;

export const LineColor = styled.div<{bg: string}>`
  width: 24px;
  height: 2px;
  background-color: ${({bg}) => bg};
`;

export const SecondWrapperStackGoals = styled.div`
  display: block;
  background: ${getColorFromTheme('white')};
  border-top: 1px solid ${getColorFromTheme('borderLight')};
  border-radius: 10px;
  padding: 24px 16px;
`;

export const MobileView = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;
  min-height: 300px;

  .wrapper {
    padding: 6px, 16px, 6px, 16px;
    background: #f6f6fe;
    border-radius: 8px;
    height: 34px;
    width: 70%;
    display: flex;

    justify-content: center;
    align-items: center;
  }
  p {
    margin-left: 15px;
  }
`;

export const MobileWrapper = styled.div`
  @media (min-width: 500px) {
    display: none;
  }
`;
export const DesktopWrapper = styled.div`
  @media (max-width: 500px) {
    display: none;
  }
`;
