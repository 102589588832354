import {useEffect, useMemo, useState} from 'react';
import {
  AnalayticsWrapper,
  ResponsiveFlexSpaceBetween,
  EmptyOverviewWrapper,
  StatusCard,
} from './style';
import {CycleHeader} from '../components/cycle-headers';
import {HorizontalSpacer, VerticalSpacer} from '@ui/atoms/spacer';
import {RoundedPieChart} from '@ui/atoms/pie-chart/pie';
import {Body2} from '@ui/atoms/typography';
import {useStoreContext} from '@store/store-context';
import {CustomColorIcon} from '@ui/atoms/color-icons';
import {FlexRow, FlexRowCenter} from '@ui/style/styles';
import {Button} from '@ui/atoms/button';
import {cloudinary} from '@assets/images/cloudinary';
import {Table, TableHeader, TableRow, TableWrapper} from '../cc-styles';
import {UserListCard} from '@ui/molecules/user/list-card';
import {Avatars} from '@ui/molecules/avatars';
import {observer} from 'mobx-react';
import {CcRequestImpl} from '@api/cc-api/cc-request';
import {CcController} from '@api/cc-api/cc-controller';
import {useQuery} from 'react-query';
import {SecondaryNavigation} from '@ui/molecules/nav/navigation';
import {getStatusPercentage} from '@utils/proportions';
import {EmptyPlaceholder} from '@ui/atoms/empty-placeholder';
import dayjs from 'dayjs';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {HorizontalDots} from '@ui/atoms/icons/horizontal-dots';
import {DropdownVerticalSecondary} from '@ui/molecules/dropdown-vertical';
import {useCycleOverview} from '../overview.hook';
import {useNavigate} from 'react-router';
import {NominatePeers} from '../nominate-peers/nominate-peers';
import {useSearchParams} from 'react-router-dom';
import {useFirebaseFetch} from '@hooks/query-hook';
import {useCountdown} from '@utils/countdown';
import {useNominationHook} from '../nomination.hook';
import {authStore} from '@store/stores/auth-store';

const {emptyGoals} = cloudinary;

const overviewEmptyPlaceholder = {
  image: {
    alt: 'alt text',
    shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
    src: emptyGoals,
    style: {height: '30%', width: '30%'},
  },

  subtitle: 'Visit the review cycles page to create performance reviews.',
  title: 'Your review cycle history is empty',
};

export const CycleOverview = observer(() => {
  const {
    ccStore: {
      cycleFilterOptions,
      currentReviewCycle,
      updateCurrentReviewCycle,
      updateCycleFilterOptions,
    },
  } = useStoreContext();

  const {data} = useCycleOverview({
    managerId:
      authStore.auth.user.role === 'admin' ? undefined : authStore.auth.user.id,
  });

  const request = useMemo(() => new CcRequestImpl(), []);
  const controller = useMemo(() => new CcController(request), [request]);

  const fetchReviews = async () => {
    const response = await controller.fetchReviewCycle({
      status: cycleFilterOptions.status.value,
    });

    if (response) {
      const sortedResponse = response?.sort((a, b) => {
        return (
          new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
        );
      });

      if (sortedResponse.length > 0 && !currentReviewCycle.id) {
        updateCurrentReviewCycle(sortedResponse[0]);
        updateCycleFilterOptions({
          label: sortedResponse[0].cycleName,
          value: sortedResponse[0].id,
        });
      }

      return sortedResponse;
    }
  };

  const {data: reviewCycleData, isLoading} = useQuery(
    ['review-cycles', cycleFilterOptions],
    () => fetchReviews(),
  );

  const reviewCycleOptions = () => {
    return reviewCycleData ?? [];
  };

  useEffect(() => {
    return () => {
      updateCurrentReviewCycle({
        cycleName: '',
        reviewType: '',
        progress: 0,
        status: 'upcoming',
        id: '',
        period: '-',
        frameworks: [] as string[],
        milestones: {
          startDate: '',
          endDate: '',
          self: '',
          manager: '',
          peer: '',
          finalize: '',
        },
        nominationSettings: {
          min: 1,
          max: 3,
        },
        allowAnonymity: false,
      });
      updateCycleFilterOptions({label: '', value: ''});
    };
  }, [updateCurrentReviewCycle, updateCycleFilterOptions]);

  const RevieweeStatus = () => {
    const isPastDue = dayjs().isAfter(currentReviewCycle.milestones.self);

    const nonWrittenReview = data.users.filter((user) => !user.reviewWritten)
      .length;

    const reviewees = {
      summary: [
        {
          name: 'Not Started',
          percentage: getStatusPercentage(
            data?.self.pending,
            data?.self.totalCount,
          ),
          color: '#CECEDE',
          value: data?.self.pending,
        },
        // {
        //   name: 'In-progress',
        //   percentage: getStatusPercentage(data?.self., data?.self.totalCount),
        //   color: '#FFD5AD',
        //   value: data?.self.,
        // },
        {
          name: 'Completed',
          percentage: getStatusPercentage(
            data?.self.completed,
            data?.self.totalCount,
          ),
          color: '#47B881',
          value: data?.self.completed,
        },
        {
          name: 'Past Due',
          percentage: getStatusPercentage(
            isPastDue ? nonWrittenReview : 0,
            data?.totalCount,
          ),
          color: '#F39C9A',
          value: isPastDue ? nonWrittenReview : 0,
        },
      ],
    };

    return (
      <StatusCard paddingBottom className="min-h-[276px]">
        <div className="header">
          <Body2 weight="semibold">Status by Reviewees</Body2>
        </div>
        <div className="body">
          <FlexRowCenter>
            <div>
              <RoundedPieChart
                chartStyle={{
                  width: 165,
                  height: 165,
                  margin: {top: 5, right: 0, left: 4, bottom: 0},
                }}
                pieStyle={{width: '140px'}}
                data={reviewees.summary}
                totalValue={data.self.totalCount}
              />
            </div>
            <div className="ml-6">
              {reviewees.summary.map((summary) => (
                <div key={summary.name} style={{width: '100%'}}>
                  <FlexRow>
                    <CustomColorIcon
                      color={summary.color}
                      width="10px"
                      height="10px"
                      margin={0}
                    />{' '}
                    <HorizontalSpacer size="10px" />
                    <Body2 kind="textBody">
                      {summary.name}:{' '}
                      <span style={{fontWeight: 500}}>{summary.value}</span>{' '}
                      <span style={{fontSize: '12px'}}>
                        {' '}
                        ({summary.percentage}%)
                      </span>
                    </Body2>{' '}
                  </FlexRow>
                  <VerticalSpacer size="16px" />
                </div>
              ))}
            </div>
          </FlexRowCenter>
        </div>
      </StatusCard>
    );
  };

  const [reviewersOption, setReviewersOption] = useState('peer');

  const [searchParams] = useSearchParams();

  const getNominationDrawerState = searchParams.get('openNomination');

  const reviewersNavigation = useMemo(() => {
    const nav = [
      {
        active: reviewersOption === 'peer',
        href: '#',
        id: '1',
        onClick: () => setReviewersOption('peer'),
        title: 'Peer',
      },
      {
        active: reviewersOption === 'manager',
        href: '#',
        id: '2',
        onClick: () => setReviewersOption('manager'),
        title: 'Manager',
      },
    ];
    return nav;
  }, [reviewersOption]);

  const ReviewerStatus = () => {
    const managers = {
      summary: [
        {
          name: 'Not-started',
          percentage: getStatusPercentage(
            data?.managers.pending,
            data?.managers.totalCount,
          ),
          color: '#CECEDE',
          value: data?.managers.pending,
        },

        {
          name: 'Completed',
          percentage: getStatusPercentage(
            data?.managers.completed,
            data?.managers.totalCount,
          ),
          color: '#47B881',

          value: data?.managers.completed,
        },
      ],
    };

    const reviewers = {
      summary: [
        {
          name: 'Not Started',
          percentage: getStatusPercentage(
            data?.peers.pending,
            data?.peers.totalCount,
          ),

          value: data?.peers.pending || 0,
          color: '#CECEDE',
        },
        {
          name: 'In-progress',
          percentage: getStatusPercentage(
            data?.peers.inProgress,
            data?.peers.totalCount,
          ),
          value: data?.peers.inProgress || 0,
          color: '#FFD5AD',
        },
        {
          name: 'Completed',
          percentage: getStatusPercentage(
            data?.peers.completed,
            data?.peers.totalCount,
          ),
          value: data?.peers.completed || 0,
          color: '#47B881',
        },
        {
          name: 'Declined',
          percentage: getStatusPercentage(
            data?.peers.declined,
            data?.peers.totalCount,
          ),
          value: data?.peers.declined || 0,
          color: '#F39C9A',
        },
      ],
    };

    const getSummary =
      reviewersOption === 'peer' && currentReviewCycle?.reviewType === '360'
        ? reviewers
        : managers;

    const total =
      reviewersOption === 'peer' && currentReviewCycle?.reviewType === '360'
        ? data.peers
        : data.managers;

    return (
      <StatusCard
        className="min-h-[276px]"
        paddingBottom={currentReviewCycle?.reviewType !== '360'}>
        <div className="header">
          <Body2 weight="semibold">Status by Reviewers</Body2>
          {currentReviewCycle?.reviewType === '360' && (
            <>
              <SecondaryNavigation
                kind="details"
                navStyle={{
                  width: 'max-content',
                  padding: '4px 8px',
                  height: '38px',
                }}
                navigations={reviewersNavigation}
              />
            </>
          )}
        </div>
        <div className="body">
          <FlexRowCenter>
            <div className="">
              <RoundedPieChart
                chartStyle={{
                  width: 165,
                  height: 165,
                  margin: {top: 5, right: 0, left: 5, bottom: 0},
                }}
                pieStyle={{width: '140px'}}
                data={getSummary.summary}
                totalValue={total.totalCount}
              />
            </div>

            <div className="ml-6">
              {getSummary.summary.map((summary) => (
                <div key={summary.name} style={{width: '100%'}}>
                  <FlexRow>
                    <CustomColorIcon
                      color={summary.color}
                      width="10px"
                      height="10px"
                      margin={0}
                    />{' '}
                    <HorizontalSpacer size="10px" />
                    <Body2 kind="textBody">
                      {summary.name}:{' '}
                      <span style={{fontWeight: 500}}>{summary.value}</span>{' '}
                      <span style={{fontSize: '12px'}}>
                        {' '}
                        ({summary.percentage}%)
                      </span>
                    </Body2>{' '}
                  </FlexRow>
                  <VerticalSpacer size="14px" />
                </div>
              ))}
            </div>
          </FlexRowCenter>
        </div>
      </StatusCard>
    );
  };
  const emptyPlaceholder = {
    image: {
      alt: 'alt text',
      shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
      src: emptyGoals,
      style: {height: '30%', width: '30%'},
    },
    imports: true,
    subtitle: '',
    variant: 'default' as 'default',

    title: 'No review selected, yet.',
  };

  const tableHeader = () => {
    const options = [
      'Reviewee',
      'Reminder',
      'Manager',
      'Reminder',
      'Completion',
    ];

    if (currentReviewCycle.reviewType === '360') {
      options.splice(4, 0, 'Peers');
      options.splice(6, 0, 'Action');
    }

    return options;
  };

  return (
    <div>
      {getNominationDrawerState && <NominatePeers />}
      <div className="add-p">
        <CycleHeader
          reviewOptions={reviewCycleOptions()}
          progress={currentReviewCycle?.progress || 0}
          isLoading={isLoading}
          disabled={!reviewCycleOptions().length}
          dueDay={currentReviewCycle.milestones.endDate || ''}
          reviewTimeline={
            currentReviewCycle.milestones.endDate
              ? `${dayjs(currentReviewCycle.milestones.startDate).format(
                  'DD MMM. YYYY',
                )} -  ${dayjs(currentReviewCycle.milestones.endDate).format(
                  'DD MMM. YYYY',
                )}`
              : ''
          }
        />
      </div>

      {currentReviewCycle?.id ? (
        <>
          <VerticalSpacer size="16px" />
          <AnalayticsWrapper>
            <VerticalSpacer size="16px" />
            <ResponsiveFlexSpaceBetween>
              <RevieweeStatus />
              <ReviewerStatus />
            </ResponsiveFlexSpaceBetween>
          </AnalayticsWrapper>
          <VerticalSpacer size="16px" />
          <TableWrapper style={{borderWidth: '1px 0px 1px 0px'}}>
            <Table>
              <TableHeader
                variant="you"
                gridTemplate={
                  currentReviewCycle?.reviewType === '360'
                    ? '20% 12% 20% 12% 15% 12% 15%'
                    : '20% 12% 20% 12% 15%'
                }>
                {tableHeader().map((value) => (
                  <Body2 weight="bold" key={value}>
                    {value}
                  </Body2>
                ))}
              </TableHeader>
              {data && data.users.length > 0 ? (
                data.users.map((user) => (
                  <Reviewee
                    user={user}
                    reviewType={currentReviewCycle.reviewType}
                  />
                ))
              ) : (
                <FlexRowCenter>
                  <EmptyPlaceholder {...emptyPlaceholder} />
                </FlexRowCenter>
              )}
            </Table>
          </TableWrapper>
        </>
      ) : (
        <EmptyOverviewWrapper>
          <EmptyPlaceholder {...overviewEmptyPlaceholder} />
        </EmptyOverviewWrapper>
      )}
    </div>
  );
});

type RevieweeProps = {
  reviewType: string;
  user: {
    reviewWritten: boolean;
    name: string;
    id: string;
    avatar: any;
    percentageProgress: number;
    peers: {reviewWritten: boolean; name: string; id: string; avatar: any}[];
    manager: {
      reviewWritten: boolean;
      name: string;
      id: string;
      avatar: any;
    };
  };
};

const Reviewee = ({reviewType, user}: RevieweeProps) => {
  const {
    usersStore: {users},
    ccStore: {currentReviewCycle},
  } = useStoreContext();

  const navigate = useNavigate();

  const managerReminderId = `${currentReviewCycle.id}/manager/${user.manager.id}/${user.id}`;

  const selfReminderId = `${currentReviewCycle.id}/self/${user.id}`;

  const {data: managerReminders} = useFirebaseFetch(
    `cc-review/${managerReminderId}`,
  );

  const {data: selfReminder} = useFirebaseFetch(`cc-review/${selfReminderId}`);

  const {minutes, seconds} = useCountdown(
    managerReminders?.reminderLastSent || 0,
  );

  const {minutes: selfMinutes, seconds: selfSeconds} = useCountdown(
    selfReminder?.reminderLastSent || 0,
  );

  const isSelfPastDue = dayjs().isAfter(currentReviewCycle.milestones.self);
  const selfButtonDisabled =
    isSelfPastDue || selfMinutes > 0 || user.reviewWritten;

  const {
    sendManagerReminder,
    reminderLoading,
    sendSelfReminder,
    selfLoader,
  } = useNominationHook();

  const managerButtonDisabled =
    dayjs().isAfter(currentReviewCycle.milestones.manager) ||
    minutes > 0 ||
    user.manager.reviewWritten;

  const avatarIconColor = () => {
    if (user.reviewWritten) return '#47B881';
    if (isSelfPastDue) return '#F39C9A';
    return '#CECEDE';
  };
  return (
    <>
      <TableRow
        variant="you"
        gridTemplate={
          reviewType === '360'
            ? '20% 8% 20% 10% 15% 10% 15%'
            : '20% 8% 20% 10% 15% 15%'
        }>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <UserListCard
            kind="admin"
            type="secondary"
            avatarIcon={
              <CustomColorIcon
                color={avatarIconColor()}
                width="10px"
                height="10px"
                margin={0}
              />
            }
            textStyle={{fontWeight: 600}}
            tooltip={true}
            avatar={user?.avatar ? user.avatar.url : ''}
            reviewer={users?.find((_user) => user.id)?.reviewer}
            name={user.name}
            userId={user?.id}
          />
        </div>
        <div>
          <Button
            kind="secondary"
            isLoading={selfLoader}
            onClick={() =>
              sendSelfReminder(user.id, user.manager.id, currentReviewCycle.id)
            }
            disabled={selfButtonDisabled}
            style={{
              padding: '8px 16px',
            }}>
            {selfMinutes > 0 ? `${selfMinutes}:${selfSeconds}` : 'Send '}{' '}
          </Button>
        </div>
        <div>
          <UserListCard
            kind="admin"
            type="secondary"
            avatarIcon={
              <CustomColorIcon
                color={user.manager.reviewWritten ? '#47B881' : '#cecede'}
                width="10px"
                height="10px"
                margin={0}
              />
            }
            textStyle={{fontWeight: 600}}
            tooltip={true}
            avatar={user.manager?.avatar ? user.manager.avatar.url : ''}
            reviewer={users?.find((_user) => user.manager.id)?.reviewer}
            name={user.manager.name}
            userId={user.manager.id}
          />
        </div>
        <div>
          <Button
            kind="secondary"
            isLoading={reminderLoading}
            disabled={managerButtonDisabled}
            onClick={() => {
              sendManagerReminder(
                user.id,
                user.manager.id,
                currentReviewCycle.id,
              );
            }}
            style={{
              padding: '8px 16px',
            }}>
            {minutes > 0 ? `${minutes}:${seconds}` : 'Send '}{' '}
          </Button>
        </div>
        {reviewType === '360' && (
          <div>
            {user.peers.length ? (
              <Avatars
                items={user.peers.map((peer) => {
                  return {
                    ...peer,
                    src: peer.avatar?.url,
                    avatarIcon: (
                      <CustomColorIcon
                        color={peer.reviewWritten ? '#47B881' : '#FFD5AD'}
                        width="10px"
                        height="10px"
                        margin={0}
                      />
                    ),

                    reviewer: users?.find((_user) => _user.id === peer.id)
                      ?.reviewer,
                  };
                })}
                total={user.peers.length}
              />
            ) : (
              <>
                <Body2 kind="textBody">Not yet added</Body2>
              </>
            )}
          </div>
        )}
        <div>
          <FlexRow>
            <CustomColorIcon
              color="#585ADF"
              width="10px"
              height="10px"
              margin={8}
            />
            <Body2 weight="bold">{user.percentageProgress}%</Body2>
          </FlexRow>
        </div>
        <div>
          {reviewType === '360' && (
            <DropdownVerticalSecondary
              collapseOnClick={true}
              customIcon={
                <div>
                  <HorizontalDots />
                </div>
              }
              menu={(handleClose: () => void) => (
                <div>
                  <DropdownItem
                    disabled={reviewType !== '360'}
                    onClick={() => {
                      navigate({
                        search: `?openNomination=true&userId=${user.id}&reviewId=${currentReviewCycle.id}`,
                      });

                      handleClose();
                    }}>
                    Nominate user
                  </DropdownItem>
                </div>
              )}
            />
          )}
        </div>
      </TableRow>
    </>
  );
};
