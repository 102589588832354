import {
  Wrapper,
  FeedbackCard,
  TableContainer,
  HeadlineWrapperContainer,
  HeadlineWrapper,
  ItemWrapperContainer,
  LoadingWrapper,
  ItemWrapper,
  SecondRowWrapper,
} from './styled';
import {useAnalyticsFeedbackHook} from './feedback-hook';
import {FlexRowSpaceBetween, FlexRow} from '@ui/style/styles';
import {Body1, Body2, Microcopy, Headline3, CTA} from '@ui/atoms/typography';
import {RoundedPieChart} from '@ui/atoms/pie-chart/pie';
import {FC, useCallback, useMemo, useRef} from 'react';
import {Pagination} from '@ui/molecules/pagination/pagination';
import {useNavigate} from 'react-router';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import {UserListCard} from '@ui/molecules/user/list-card';
import {ItemLoader} from '@ui/organisms/item-loader';
import {CustomColorIcon} from '@ui/atoms/color-icons';
import ObjectiveFilter from '@pages/dashboard/goals/objective-filter';
import {useStoreContext} from '@store/store-context';
import {DropdownVertical} from '@ui/molecules/dropdown-vertical';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {Tooltip} from '@ui/molecules/tooltip';
import {useApiCalls} from '@hooks/init-calls';
import {useQuery} from 'react-query';
import {cloudinary} from '@assets/images/cloudinary';
import {EmptyPlaceholder} from '@ui/atoms/empty-placeholder';
const {emptyGoals} = cloudinary;

const ResponsiveFlexRow = styled(FlexRow)`
  width: 50%;
  @media (max-width: 500px) {
    flex-direction: column;
    width: 100%;
  }
`;
const ResponsiveFlexSpaceBetween = styled(FlexRowSpaceBetween)`
  @media (max-width: 500px) {
    flex-direction: column;
    width: 100%;
  }
`;

type Option = {label: string; value: string};
type Feedback = {
  sentiment: string;
  count: string;
  values: string[];
};

interface AnalyticsFeedbackProps {
  selectedGroups: Option[];
  selectedTeams: Option[];
  selectedMembers: Option[];
  breakdownBy: string;
  feedbackFilter: Feedback;
}
export const AnalyticsFeedback: FC<AnalyticsFeedbackProps> = observer(
  ({
    selectedGroups,
    selectedTeams,
    selectedMembers,
    breakdownBy,
    feedbackFilter,
  }) => {
    const {
      groupStore: {groups: allGroups},
    } = useStoreContext();

    const selectedGroupsId = selectedGroups.map((group) => group.value);

    const selectedTeamsId = selectedTeams.map((team) => team.value);

    const selectedMembersId = selectedMembers.map((member) => member.value);

    const computeFilters = useMemo(() => {
      const sentimentCount = feedbackFilter.count;
      return {
        groupBy: breakdownBy,
        sortByCount:
          feedbackFilter.sentiment && !!sentimentCount
            ? `${feedbackFilter.sentiment}:${
                sentimentCount[sentimentCount?.length - 1] === ','
                  ? ''
                  : sentimentCount
              }`
            : '',
        manager: selectedTeamsId.length ? selectedTeamsId.join(',') : '',
        values: feedbackFilter.values.length
          ? feedbackFilter.values.join(',')
          : '',
        user: selectedMembersId.length ? selectedMembersId.join(',') : '',
        group: selectedGroupsId.length ? selectedGroupsId.join(',') : '',
      };
    }, [
      breakdownBy,
      feedbackFilter.count,
      feedbackFilter.values,
      feedbackFilter.sentiment,
      selectedGroupsId,
      selectedMembersId,
      selectedTeamsId,
    ]);

    const {
      userFilter,
      setUserFilter,
      dateRange,
      setDateRange,
      feedbackSummary,
      pieData,
      users,
      pages: {page, totalPages, maxPageNumberLimit, minPageNumberLimit, limit},
      values,
      nextPageBtn,
      paginationPages,
      handlePrevBtn,
      updateLimit,
      goToPage,
      totalResults,
      isLoading,
      totalValue,
      impressions,
    } = useAnalyticsFeedbackHook(computeFilters);

    const {initGroupMembers} = useApiCalls();

    const {data: groupMembers} = useQuery(['initGroupMembers'], () =>
      initGroupMembers(),
    );

    const emptyPlaceholder = {
      image: {
        alt: 'alt text',
        shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
        src: emptyGoals,
        style: {height: '30%', width: '30%'},
      },
      imports: true,
      subtitle: 'Refine your search or try something new.',
      title: 'No results found',
    };

    const getUsersGroup = useCallback(async () => {
      const res = groupMembers;
      if (res) {
        const includeGroup = users.map((user: {id: any}) => {
          const userGroup = res.get(user.id);
          const getGroupIds = userGroup?.map((grp: any) => grp.group);
          const getUserGroups =
            allGroups.length > 0
              ? allGroups?.filter((grp: any) => getGroupIds?.includes(grp.id))
              : [];
          return {
            ...user,
            groups: getUserGroups,
          };
        });

        return includeGroup;
      }
    }, [users, allGroups, groupMembers]);

    const {data} = useQuery(['getUsersWithGroups', users], () =>
      getUsersGroup(),
    );

    const navigate = useNavigate();

    const bodyRef: any = useRef(null);

    const getPaginationPages = paginationPages(totalResults, 10);

    const breakdownIsGroup = breakdownBy === 'group';
    const breakdownIsTeam = breakdownBy === 'team';

    return (
      <Wrapper ref={bodyRef} className="sm:mt-4 mt-0">
        <div className="body">
          <div className="analytics">
            <div className="analytics-head">
              <div className="flex sm:flex-col flex-row w-full  justify-between sm:items-start items-center border-b pb-4  border-b-[#ededf2] ">
                <Body2 weight="bold">Overview</Body2>
                <div>
                  <ObjectiveFilter
                    showDateRange
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    _height="36px"
                    userFilter={userFilter}
                    removeUserFilter
                    setUserFilter={setUserFilter}
                  />
                </div>
              </div>
            </div>
            <div className="analytics-body">
              {isLoading && (
                <LoadingWrapper className="my-10">
                  <ItemLoader />
                </LoadingWrapper>
              )}
              {!isLoading && (
                <ResponsiveFlexSpaceBetween>
                  <ResponsiveFlexRow>
                    <div className="w-full">
                      <RoundedPieChart data={pieData} totalValue={totalValue} />
                    </div>
                    <div style={{width: '100%'}}>
                      <Summary
                        title={'Commend'}
                        value={impressions.commend}
                        percentageValue={impressions.commendPercentage}
                        showBorder
                        color="#1A9E68"
                      />
                      <Summary
                        title={'Neutral'}
                        value={impressions.neutral}
                        percentageValue={impressions.neutralPercentage}
                        showBorder
                        color="#CECEDE"
                        textColor="black"
                      />
                      <Summary
                        title={'Critic'}
                        value={impressions.critic}
                        percentageValue={impressions.criticPercentage}
                        color="#E87F16"
                        textColor="black"
                      />
                    </div>
                  </ResponsiveFlexRow>

                  <div>
                    {feedbackSummary.map((feedback, index) => (
                      <CardFeedback {...feedback} key={index} />
                    ))}
                  </div>
                </ResponsiveFlexSpaceBetween>
              )}
            </div>
            {values.length > 0 && (
              <div className="footer">
                <Body2 weight="semibold">Values:</Body2>
                {values.map(
                  (value: {name: string; count: number}, index: number) => (
                    <div className="value" key={index}>
                      <Body2 kind="textBody">
                        #{value?.name}:{' '}
                        <span style={{fontWeight: 500}}>{value?.count}</span>
                      </Body2>
                    </div>
                  ),
                )}
              </div>
            )}
          </div>
          <div className="bg-white">
            <div className="pt-4 pb-6 px-6">
              <Body2 weight="semibold">Summary ({totalResults})</Body2>
            </div>
            <TableContainer>
              <div style={{overflowX: 'scroll'}}>
                <SecondRowWrapper>
                  <HeadlineWrapperContainer className="header">
                    <HeadlineWrapper>
                      <Body2 weight="semibold">
                        {' '}
                        {breakdownIsGroup ? 'Group' : 'Names'}
                      </Body2>
                      <Body2 weight="semibold">
                        {' '}
                        {breakdownIsGroup
                          ? ' Group Manager'
                          : breakdownIsTeam
                          ? 'Manager'
                          : 'Groups'}
                      </Body2>
                      <Body2 weight="semibold">Recieved</Body2>
                      <Body2 weight="semibold">Requested</Body2>
                      <Body2 weight="semibold">Commended</Body2>
                      <Body2 weight="semibold">Neutral</Body2>
                      <Body2 weight="semibold">Critic</Body2>
                    </HeadlineWrapper>
                  </HeadlineWrapperContainer>
                  {data &&
                    !isLoading &&
                    data.map((user: any, index: number) => (
                      <ItemWrapperContainer key={index}>
                        <ItemWrapper>
                          <div>
                            {breakdownIsGroup || breakdownIsTeam ? (
                              <Body2 weight="semibold">{user.firstName}</Body2>
                            ) : (
                              <UserListCard
                                tooltip={false}
                                userId={user?.id}
                                type="secondary"
                                reviewer={''}
                                avatar={user?.avatar?.url}
                                name={
                                  user.firstName + ' ' + user?.lastName || ''
                                }
                                TextComponent={CTA}
                                textKind="textDark"
                                tooltipText
                              />
                            )}
                          </div>

                          <div>
                            {breakdownIsGroup ? (
                              <UserListCard
                                tooltip={false}
                                userId={user?.groupAdmin.id}
                                type="secondary"
                                reviewer={''}
                                avatar={user?.groupAdmin?.avatar?.url}
                                name={
                                  user.groupAdmin.firstName +
                                    ' ' +
                                    user?.groupAdmin.lastName || ''
                                }
                                TextComponent={CTA}
                                textKind="textDark"
                                tooltipText
                              />
                            ) : breakdownIsTeam ? (
                              <UserListCard
                                tooltip={false}
                                userId={user?.manager.id}
                                type="secondary"
                                reviewer={''}
                                avatar={user?.manager.avatar?.url}
                                name={
                                  user.manager.firstName +
                                    ' ' +
                                    user?.manager.lastName || ''
                                }
                                TextComponent={CTA}
                                textKind="textDark"
                                tooltipText
                              />
                            ) : user.groups?.length > 0 ? (
                              <div className="flex flex-row">
                                <div className="px-3 py-2 bg-[#F6F6F8] items-center justify-center rounded-[10px]">
                                  <Body2 weight="semibold" kind="textBody">
                                    {`${user.groups[0].name}`.length > 6 ? (
                                      <>
                                        <Tooltip
                                          text={`${user.groups[0].name}  `}
                                          maxLength={10}
                                          tooltipBody={
                                            <span>{user.groups[0].name}</span>
                                          }
                                        />
                                      </>
                                    ) : (
                                      `${user.groups[0].name}`
                                    )}
                                  </Body2>
                                </div>
                                {user.groups.length > 1 && (
                                  <div className="px-3 py-2 bg-[#F6F6F8] items-center justify-center rounded-[10px] ml-1">
                                    <Body2 weight="semibold" kind="purple300">
                                      {' '}
                                      + {user.groups.length - 1}
                                    </Body2>
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div className="px-3 py-2 bg-[#F6F6F8] items-center justify-center rounded-[10px] w-fit">
                                <Body2 kind="textBody">--- ---</Body2>
                              </div>
                            )}
                          </div>
                          <div>
                            <Body2>{user.feedback.given}</Body2>
                          </div>
                          <div>
                            <Body2>{user.feedback.requested}</Body2>
                          </div>
                          <div>
                            <Body2>{user.feedback.commend}</Body2>
                          </div>
                          <div>
                            <Body2>{user.feedback.neutral}</Body2>
                          </div>
                          <div>
                            <Body1>{user.feedback.critic}</Body1>
                          </div>

                          {!breakdownBy && (
                            <div>
                              <DropdownVertical
                                dropdownWrapperStyle={{
                                  padding: 0,
                                  right: '-83%',
                                  minWidth: 214,
                                  maxWidth: 214,
                                }}
                                menu={
                                  <div>
                                    <DropdownItem
                                      onClick={() => {
                                        navigate(
                                          `/view-user-feedback/${
                                            user.id
                                          }?startDate=${
                                            dateRange.starts
                                          }&endDate=${dateRange.ends}${
                                            dateRange.period
                                              ? `&period=${dateRange.period}`
                                              : ''
                                          }`,
                                        );
                                      }}>
                                      View
                                    </DropdownItem>
                                  </div>
                                }
                              />
                            </div>
                          )}
                        </ItemWrapper>
                      </ItemWrapperContainer>
                    ))}
                  {!isLoading && !data?.length && (
                    <EmptyPlaceholder {...emptyPlaceholder} />
                  )}
                  {isLoading && (
                    <LoadingWrapper className="min-h-[200px]">
                      <ItemLoader />
                    </LoadingWrapper>
                  )}
                </SecondRowWrapper>
              </div>
            </TableContainer>
            {totalResults > 10 && (
              <Pagination
                totalPages={totalPages}
                page={page}
                totalResults={totalResults}
                limit={limit}
                updateLimit={updateLimit}
                maxPageNumberLimit={maxPageNumberLimit}
                minPageNumberLimit={minPageNumberLimit}
                goToPage={goToPage}
                pages={getPaginationPages}
                nextBtn={nextPageBtn}
                prevBtn={handlePrevBtn}
              />
            )}
          </div>
        </div>
      </Wrapper>
    );
  },
);

const CardFeedback = ({title, value}: {title: string; value: number}) => {
  return (
    <FeedbackCard>
      <Body1 weight={'semibold'}>{title}</Body1>
      <div className="number">
        <Headline3>{value}</Headline3>
      </div>
    </FeedbackCard>
  );
};

const Summary = ({
  title,
  value,
  percentageValue,
  showBorder,
  color,
  textColor,
}: {
  title: string;
  value: number;
  percentageValue: number;
  showBorder?: boolean;
  color: string;
  textColor?: string;
}) => {
  return (
    <FlexRowSpaceBetween
      style={{
        borderBottom: showBorder ? '1px solid #ededf2' : '',
        padding: '16px 0',
      }}>
      <FlexRow>
        <CustomColorIcon width="10px" height="10px" margin={5} color={color} />{' '}
        <Body2 style={{color: textColor || color}}>{title}</Body2>
      </FlexRow>
      <Body2 weight="semibold">
        {value}
        <span style={{marginLeft: '3px'}}>
          <Microcopy kind="textBody" weight="default">
            ({percentageValue}%)
          </Microcopy>
        </span>
      </Body2>
    </FlexRowSpaceBetween>
  );
};
