import styled from 'styled-components';
import {Button} from '../../../ui/atoms/button';

export const Wrapper = styled.div`
  width: 92%;
  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const PageLayout = styled.section<any>`
  width: 100%;
  display: block;
  padding: 32px 69px 30px 32px;

  @media (max-width: 500px) {
    padding: 32px 12px 30px 12px;
  }
`;
export const ButtonWrapper = styled.span`
  float: right;
`;

export const RightWrapper = styled.div<any>`
  width: 100%;

  @media (max-width: 900px) {
    margin-left: 0;
  }
`;

export const UserProfileWrapper = styled.div<any>`
  padding: 0 0 20px 0;
  border-bottom: 1px solid #ededf2;
`;

export const RightChildWrapper = styled.div<any>`
  display: flex;
  padding: 0 10px;
  margin-top: 24px;

  justify-content: space-between;
  flex-wrap: wrap;
`;
export const RightChildWrapperSecurity = styled.div<any>`
  width: 50%;

  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const RightChildSpan = styled.span<any>`
  width: 49%;

  @media (max-width: 678px) {
    width: 100%;
  }
`;

export const LeftWrapper = styled.div<any>`
  position: relative;
  padding-top: 24px;

  @media (max-width: 900px) {
    // width: 100%;
    margin-bottom: 24px;
    padding-left: 16px;
  }
`;

export const LeftWrapperInnerChild = styled.div<any>`
  display: flex;
  align-items: center;
  position: relative;

  @media (max-width: 900px) {
    position: absolute;
    left: 40%;
    flex-direction: column;
    justify-content: center;
    align-items: unset;
  }

  @media (max-width: 600px) {
    position: absolute;
    left: 30%;
    flex-direction: column;
    justify-content: center;
    align-items: unset;
  }

  @media (max-width: 480px) {
    position: relative;
    left: 0%;
    flex-direction: column;
    justify-content: center;
    align-items: unset;
  }
`;

export const LeftChildSpan = styled.span<any>`
  font-size: 8px;

  position: absolute;
  bottom: -10%;
  right: 20%;

  @media (max-width: 900px) {
    text-align: center;
    margin: auto;
    margin-top: 8px;
  }

  @media (max-width: 500px) {
    bottom: -2%;
  }
`;

export const LeftChildInput = styled.input<any>`
  display: none;
`;

export const CancelButton = styled(Button)`
  padding: 0 7px 0 0;
  line-height: 1;
  color: red;
  &:not(:disabled):hover {
    color: red;
  }
  &:focus {
    outline: none;
    color: red;
  }
`;

export const EmailButton = styled(Button)`
  padding: 0 3px 0 0;
  line-height: 1;
  overflow: hidden;
`;

export const CustomButton = styled(Button)`
  white-space: normal;
  font-weight: 400;
`;

export const ExternalLink = styled.a`
  font-weight: 500;
  font-size: 14px;
  color: #bfbfd4;
`;
