import React from 'react';

export const BeamSlideIcon = () => {
  return (
    <svg
      width="88"
      height="26"
      viewBox="0 0 88 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="25.8828" height="26" rx="12.9414" fill="#EDEDF2" />
      <rect width="26" height="26" rx="8" fill="#585ADF" />
      <path
        d="M8 18H12.8637C15.2456 18 16.7718 16.7692 16.7718 14.9371C16.7718 13.7203 16.0444 13.0629 15.0174 12.8392C16.187 12.5594 17 11.7483 17 10.4476C17 9.02098 16.0729 8 13.7338 8H9.61173L8 18ZM11.1521 16.0839L11.5372 13.7203H12.5784C13.5341 13.7203 13.9477 14.0699 13.9477 14.7692C13.9477 15.5385 13.4057 16.0839 12.4358 16.0839H11.1521ZM11.8082 11.972L12.1506 9.9021H13.1775C13.962 9.9021 14.3328 10.1678 14.3328 10.8112C14.3328 11.5664 13.8906 11.972 12.9493 11.972H11.8082Z"
        fill="white"
      />
      <path
        d="M32 20.7576H38.3288C41.8807 20.7576 43.732 19.2369 43.732 16.3499V16.2617C43.732 14.146 42.6557 12.978 40.6537 12.5592C42.3758 12.0523 43.1508 10.8402 43.1508 9.18733V9.09917C43.1508 6.41047 41.4071 5 38.049 5H32V20.7576ZM35.4012 18.2893V13.7714H37.6184C39.5128 13.7714 40.2878 14.5207 40.2878 15.9752V16.0634C40.2878 17.4959 39.5128 18.2893 37.7907 18.2893H35.4012ZM35.4012 11.4793V7.44628H37.4678C39.1038 7.44628 39.8357 8.04132 39.8357 9.36364V9.45179C39.8357 10.8623 39.1684 11.4793 37.4893 11.4793H35.4012Z"
        fill="#242424"
      />
      <path
        d="M51.098 21C54.1763 21 56.1137 19.6116 56.4796 17.1212H53.5305C53.3368 18.0909 52.6264 18.73 51.1841 18.73C49.4835 18.73 48.4717 17.6281 48.3856 15.7107H56.5012V14.8292C56.5012 10.708 53.918 8.96694 51.0119 8.96694C47.7398 8.96694 45.2212 11.3251 45.2212 14.9614V15.1377C45.2212 18.8182 47.6968 21 51.098 21ZM48.4287 13.7493C48.6655 12.1185 49.6126 11.1708 51.0119 11.1708C52.4972 11.1708 53.3583 12.0083 53.4659 13.7493H48.4287Z"
        fill="#242424"
      />
      <path
        d="M61.6847 21C63.4499 21 64.4401 20.2948 65.0859 19.3691V20.7576H68.1212V13.2424C68.1212 10.1129 66.1623 8.96694 63.4068 8.96694C60.6514 8.96694 58.5418 10.1791 58.3481 12.9118H61.2972C61.4264 11.9201 61.9861 11.281 63.2131 11.281C64.6339 11.281 65.0429 12.0303 65.0429 13.3967V13.8595H63.7728C60.3285 13.8595 57.9176 14.8733 57.9176 17.562C57.9176 19.9862 59.6397 21 61.6847 21ZM62.6104 18.7741C61.4694 18.7741 60.9743 18.2231 60.9743 17.3857C60.9743 16.1515 62.0291 15.7548 63.8374 15.7548H65.0429V16.7245C65.0429 18.0028 63.9881 18.7741 62.6104 18.7741Z"
        fill="#242424"
      />
      <path
        d="M70.8002 20.7576H73.9216V13.9477C73.9216 12.3829 74.8903 11.5895 76.0958 11.5895C77.1936 11.5895 77.861 12.2507 77.861 13.7273V20.7576H80.9608V13.9477C80.9608 12.3829 81.9295 11.5895 83.135 11.5895C84.2544 11.5895 84.9002 12.2507 84.9002 13.7273V20.7576H88V13.3747C88 10.3113 86.3855 8.96694 84.2974 8.96694C82.8767 8.96694 81.4129 9.58402 80.5087 10.9725C79.9706 9.60606 78.8512 8.96694 77.3658 8.96694C75.6868 8.96694 74.4813 9.9146 73.9216 10.9945V9.23141H70.8002V20.7576Z"
        fill="#242424"
      />
    </svg>
  );
};
