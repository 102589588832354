import {memo} from 'react';

export const SlidersIcon = memo(({color}: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16">
    <path
      stroke={color ? color : '#000'}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M2.668 14V9.334M2.668 6.667V2M8 14V8M8 5.333V2M13.332 14v-3.334M13.332 8V2M.668 9.334h4M6 5.333h4M11.332 10.666h4"
    />
  </svg>
));

SlidersIcon.displayName = 'SlidersIcon';
