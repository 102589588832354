import {Wrapper} from './review-cycle-style';
import {useNavigate} from 'react-router';
import PageLayout from '@ui/templates/page-template/page-layout';
import {CycleInfo} from './create-review-components/cycle-info';
import {Button} from '@ui/atoms/button';
import {useParams} from 'react-router';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {PageStep} from '@ui/molecules/page-step';
import ReviewCycleSettings from './create-review-components/settings/settings';
import ReviewMilestones from './create-review-components/milestones/milestones';
import {useReviewCycleHook} from './create-review-hook';

export default function CreateReviewCycle() {
  const navigate = useNavigate();
  const {
    step,
    values,
    setStep,
    handleChange,
    milestoneError,
    handleCreateReview,
    loading,
    formValidation,
    handleSubmit,
  } = useReviewCycleHook();

  const steps = [
    {
      title: 'Create cycle',
    },
    {
      title: 'Set milestones',
    },
    {
      title: 'Settings',
    },
  ];

  const params = useParams();

  const disabled = formValidation() || (step === 2 && !!milestoneError);

  return (
    <PageLayout
      bodyStyle={{padding: 0, margin: 0, marginTop: '2.75%'}}
      title={params?.id ? 'Edit review cycle' : `Create review cycle`}
      onClick={() => navigate(-1)}>
      <div className="">
        <PageStep
          steps={steps}
          activeStep={step}
          onChange={(value) => setStep(value)}
        />
      </div>

      <Wrapper>
        <div>
          {step === 1 && (
            <CycleInfo
              handleChange={handleChange}
              currentValues={{
                cycleName: values.cycleName,
                reviewType: values.reviewType,
                reviewPeriod: values.period,
                reviewFrameworks: values.frameworks,
              }}
            />
          )}

          {step === 2 && (
            <ReviewMilestones
              handleChange={(value) => handleChange('milestones', value)}
              errors={milestoneError}
              mileStones={
                values?.milestones || {
                  self: '',
                  startDate: '',
                  endDate: '',
                  manager: '',
                  finalize: '',
                }
              }
              cycleType={values.reviewType}
            />
          )}
          {step === 3 && (
            <ReviewCycleSettings
              cycleType={values.reviewType}
              currentValues={{
                repliesAreAnonymous: values?.allowAnonymity,
                excludedGroups: values?.excludedGroups,
              }}
              handleChange={handleChange}
            />
          )}
        </div>

        <Button width="full" onClick={handleSubmit} disabled={disabled}>
          Continue
        </Button>
        <VerticalSpacer size="10px" />
        <Button
          kind="secondary"
          width="full"
          disabled={disabled}
          isLoading={loading === 'draft'}
          onClick={() => handleCreateReview(values)}>
          Save as draft
        </Button>
      </Wrapper>
    </PageLayout>
  );
}
