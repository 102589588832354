import {AdminSettingsRequestImpl} from '../admin-settings/admin-settings-request';
import {AdminSettingsController} from '../admin-settings/admin-settings-controller';
import {useState} from 'react';

export function useActiveHook() {
  const request = new AdminSettingsRequestImpl();
  const controller = new AdminSettingsController(request);
  const [isSettingRole, setIsSettingRole] = useState<any>({});
  const changeRole = async (role: string, id: string, callback: any) => {
    setIsSettingRole({...isSettingRole, [id]: true});
    const response = await controller.newAdminSettingsForm({role}, id);
    response && callback(role);
    response && setIsSettingRole({...isSettingRole, [id]: false});
  };
  return {
    changeRole,
    isSettingRole,
  };
}
