import {useCallback, useReducer} from 'react';
import Schema from 'yup/lib/schema';

type Values = {
  [field: string]: any;
};

type ValidationErrors<T> = {
  [K in keyof T]?: T[K] extends object ? ValidationErrors<T[K]> : string;
};

interface ReducerState<T> {
  errors: ValidationErrors<T>;
}

export enum ActionType {
  UPDATE_ERROR,
}

type UpdateErrorAction<T> = {
  type: ActionType.UPDATE_ERROR;
  payload: ValidationErrors<T>;
};

type ReducerAction<T> = UpdateErrorAction<T>;

function errorReducer<T>(
  state: ReducerState<T>,
  action: ReducerAction<T>,
): ReducerState<T> {
  switch (action.type) {
    case ActionType.UPDATE_ERROR:
      return {
        errors: action.payload,
      };
    default:
      return state;
  }
}

export function useYup<T extends Values>(
  values: T,
  validationSchema: Schema<any>,
) {
  const [state, dispatch] = useReducer(errorReducer, {errors: {}});
  const isValid = Object.keys(state.errors).length === 0;

  const validate = useCallback(
    async (val?: T) => {
      const error = await new Promise((resolve) => {
        validationSchema.validate(val ?? values, {abortEarly: false}).then(
          () => {
            resolve({} as ValidationErrors<T>);
          },
          (err) => {
            const parsedError = yupToValidationErrors<T>(err);
            resolve(parsedError);
          },
        );
      });
      dispatch({type: ActionType.UPDATE_ERROR, payload: error as any});
    },
    [validationSchema, values],
  );

  return {
    validate,
    errors: state.errors as ValidationErrors<T>,
    isValid,
  };
}

/**
 * Transform Yup errors to a ValidationErrors object
 */
function yupToValidationErrors<T extends Values>(
  yupError: any,
): ValidationErrors<T> {
  let errors: any = {} as ValidationErrors<Values>;
  if (yupError.inner.length === 0) {
    updateIn(errors, yupError.path, yupError.message);
    return errors;
  }
  for (let err of yupError.inner) {
    updateIn(errors, err.path, err.message);
  }
  return errors;
}

function updateIn(obj: any, path: string, value: any): any {
  const pathArray = path.split('.');
  let destinationObject = obj;
  for (let i = 0; i < pathArray.length - 1; i++) {
    if (pathArray[i] in destinationObject === false) {
      destinationObject[pathArray[i]] = {};
    }
    destinationObject = destinationObject[pathArray[i]];
  }
  destinationObject[pathArray[pathArray.length - 1]] = value;
}
