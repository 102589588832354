export const userName = (user: any, returnEmail?: boolean) => {
  if (returnEmail) {
    return {
      name: user?.firstName + ' ' + user?.lastName,
      email: user?.email,
    };
  }
  if (user?.firstName) {
    return user?.firstName + ' ' + user?.lastName;
  } else if (user?.email) {
    return user?.email;
  }
};
