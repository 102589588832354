import {ReactChild} from 'react';
import styled, {DefaultTheme, StyledComponentProps} from 'styled-components';
import styledMap from 'styled-map';
import {ifProp} from 'styled-tools';
import {theme} from '../../style';

export type HelperProps = StyledComponentProps<
  'span',
  DefaultTheme,
  {
    children?: string | ReactChild;
    weight?: number;
    state?: 'error' | 'warning' | 'default' | 'success' | 'performance';
  },
  never
>;

const color = styledMap('state', {
  default: theme.colors.textMedium,
  error: theme.colors.red400,
  warning: theme.colors.orange300,
  success: theme.colors.green400,
});

export const Helper = styled.span<HelperProps>`
  color: ${color};
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  margin-top: 5px;
  /* position: absolute; */
  bottom: 20px;
  bottom: -20px;
  display: ${ifProp('children', 'block', 'none')};
`;
export const SecondaryHelper = styled.span<HelperProps>`
  color: ${color};
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  margin-top: 5px;
  /* position: absolute; */
  bottom: 20px;
  bottom: -20px;
  display: ${ifProp('children', 'block', 'none')};
`;
