import styled from 'styled-components';
import {useDropDown} from '../../../hooks';
import {memo} from 'react';
import {getColorFromTheme} from '../../ui-utils';

const Wrapper = styled.div`
  position: relative;
`;

const DropdownWrapper = styled.div`
  display: block;
  position: absolute;
  width: auto;
  top: 100%;
  border-radius: 8px;
  right: 100%;
  border: solid 1px ${getColorFromTheme('borderDark')};
  background-color: ${getColorFromTheme('white')};
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: ${({theme}) => theme.elevation.level2};
  z-index: 2;
  padding: 16px 8px;

  @media (max-width: 500px) {
    right: -27%;
  }
`;

export interface DropdownCustomProps {
  menu: any; //(handleClose: () => void) => ReactNode;
  collapseOnClick?: boolean;
  shouldNotIncludeWrapper?: boolean;
  customComponent: any;
  customDropdownWrapperStyles?: any;
  wrapperStyles?: any;
}

export const DropdownCustom = memo(function ({
  menu,
  collapseOnClick,
  customComponent,
  shouldNotIncludeWrapper,
  customDropdownWrapperStyles,
  wrapperStyles,
}: DropdownCustomProps) {
  const {handleOpen, ref, open, handleClose} = useDropDown();

  return (
    <Wrapper ref={ref} style={wrapperStyles}>
      {customComponent(handleOpen, open)}
      {open &&
        (shouldNotIncludeWrapper && shouldNotIncludeWrapper ? (
          collapseOnClick ? (
            menu(handleClose)
          ) : (
            menu()
          )
        ) : (
          <DropdownWrapper style={customDropdownWrapperStyles}>
            {collapseOnClick ? menu(handleClose) : menu}
          </DropdownWrapper>
        ))}
    </Wrapper>
  );
});
DropdownCustom.displayName = 'DropdownCustom';
