import {GoalKind, MeasurementType} from '../../../hooks';
import {AnimatePresence, motion} from 'framer-motion';
import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import {ProgressType, UserAvatar} from '../../interface';
import {EditableGoalHeader} from '../../molecules/goal/editable-header';
import {EditableKeyMetric} from '../../molecules/goal/editable-metric';
import {getColorFromTheme} from '../../ui-utils';
import {onValue, ref} from 'firebase/database';
import {FIREBASE_BASE_URL, database} from '@utils/firebase-request';
import {Body2} from '../../atoms/typography';
import {observer} from 'mobx-react';
import {useStoreContext} from '../../../store/store-context';
import {computeFrameworkVars} from '../../../utils/framework';

interface Metric {
  [x: string]: any;
  title: string;
  slug: string;
  user: {
    name: string;
    avatar: string;
    id: string;
  };
  state: string;
  measurement: MeasurementType;
  percent: number;
  id: string;
}

export interface EditableGoalProps {
  name: string;
  type: GoalKind;
  managerEdit?: boolean;
  variantType?: string;
  onGoalUpdate?: (goalId: string) => void;
  onUpdateReverted?: (goalId: string) => void;
  goalType: string;
  assignee: {
    id: string;
    name: string;
  };
  createdBy: string;
  goal?: any;
  totalUsers: number;
  isOpen: boolean;
  upcomingGoals?: boolean;
  goals?: any;
  setIsOpen: any;
  users: UserAvatar[];
  disabled?: boolean;
  measurement: MeasurementType;
  showObjectiveProgress?: boolean;
  currencySymbol?: any;
  progress: {
    state: string;
    type: ProgressType;
    percent: number;
    due: string;
    status: string;
  };
  id: string;
  keyResults: Metric[];
  currentValue?: number;
  targetValue?: number;
  className?: string;
  kind?: string;
  variant?: string;
  youCheckinsKind?: string;
  errored?: boolean;
}

const Wrapper = styled(motion.section)<{errored?: boolean}>`
  box-shadow: ${({errored}) =>
    errored ? `0 0 0 1px #D52A2A` : `0 0 0 1px #ededf2`};
  border-radius: 8px;
  padding: 16px;
`;

const Header = styled.header`
  padding-bottom: 0;
  border-bottom: none;
`;

const Divider = styled(motion.div)`
  border-top: 1px solid ${getColorFromTheme('borderLight')};
  padding-bottom: 15px;
`;

const Content = styled(motion.div)`
  padding-top: 15px;
`;

const GenerateEditableKeyMetrics: FC<{
  metric: any;
  goalId: string;
  goalStatus: string;
  goalAssignee: string;
  goalCreatedBy: string;
  tabKind: string | undefined;
  showKeyResultProgress?: boolean;
  index: number;
  variantType?: string;
  variant?: string;
  youCheckinsKind?: string;
}> = observer(
  ({
    metric,
    goalId,
    goalAssignee,
    goalCreatedBy,
    goalStatus,
    showKeyResultProgress,
    variantType,
    tabKind,
    index,
    variant,
    youCheckinsKind,
  }) => {
    const {users} = useStoreContext().usersStore;

    const uiUsersFormat = useMemo(() => {
      return users.map((user) => {
        return {
          label: {
            avatar: {
              name:
                user.firstName && user.lastName
                  ? `${user.firstName} ${user.lastName}`
                  : user.email,
              src: user.avatar && user.avatar.url ? user.avatar.url : '',
            },
            id: user.id,
            name:
              user.firstName && user.lastName
                ? `${user.firstName} ${user.lastName}`
                : user.email,
          },
          value: user.id,
        };
      });
    }, [users]);
    const goalOwner = uiUsersFormat.filter(
      (value) => value.value === metric.assignee,
    );
    const keyresult = {
      ...metric,
      id: metric.id,
      activities: metric.activities,
      goal: metric.goal,
      measurement: metric.measurement.unit.toUpperCase(),
      currencySymbol: metric.measurement?.symbol
        ? metric.measurement?.symbol
        : undefined,
      percent: metric.currentValue,
      slug: metric.name,
      state: `${metric.currentValue}%`,
      title: metric.name,
      context: metric.context,
      updatedAt: metric.updatedAt,
      source: metric.source,
      user: {
        avatar: metric.assignee.avatar
          ? metric.assignee.avatar.url
          : goalOwner[0]?.label.avatar
          ? goalOwner[0].label.avatar.src
          : '',
        reviewer: metric.assignee.reviewer,
        id: metric.assignee.id || goalOwner[0].label.id,
        name: metric.assignee.firstName
          ? metric.assignee.firstName + ' ' + metric.assignee.lastName
          : metric.assignee.email,
      },
      currentValue: metric.currentValue,
      startValue: metric.startValue,
      goodValue: metric?.goodValue,
      stretchValue: metric?.stretchValue,
      targetValue: metric.targetValue,
    };

    return (
      <EditableKeyMetric
        goalId={goalId}
        goalAssignee={goalAssignee}
        goalCreatedBy={goalCreatedBy}
        key={keyresult.id}
        keyResult={keyresult}
        goalStatus={goalStatus}
        showKeyResultProgress={showKeyResultProgress}
        tabKind={tabKind}
        index={index}
        variantType={variantType}
        variant={variant}
        youCheckinsKind={youCheckinsKind}
      />
    );
  },
);
export const EditableGoal = observer(function ({
  name,
  type,
  goalType,
  users,
  totalUsers,
  progress,
  keyResults,
  measurement,
  currencySymbol,
  id,
  upcomingGoals,
  goals,
  currentValue,
  managerEdit,
  onGoalUpdate,
  onUpdateReverted,
  isOpen,
  setIsOpen,
  disabled,
  targetValue,
  className,
  goal,
  showObjectiveProgress,
  kind,
  variantType,
  assignee,
  createdBy,
  variant,
  youCheckinsKind,
  errored,
}: EditableGoalProps) {
  const defaultKeyResults = keyResults;

  const toggleOpen = useCallback(() => {
    setIsOpen(isOpen !== goal.id ? goal.id : '');
  }, [goal.id, isOpen, setIsOpen]);

  useMemo(() => {
    if (goal?.open) {
      setIsOpen(goal.id);
    }
  }, [goal?.open, goal.id, setIsOpen]);

  const {
    authStore: {auth},
    goalsState: {clearComment, handleClearComment},
    storeYouGoals: {
      youGoalsFilter,
      youGoalsFilterUpdate,

      setYouGoalsFilterUpdate,
    },

    companyStore: {commentRef},
  } = useStoreContext();

  const [userIsMentioned, setUserIsMentioned] = useState(false);

  useEffect(() => {
    onValue(
      ref(
        database,
        `${FIREBASE_BASE_URL}/${auth.user.workspace.id}/users/${auth.user.id}/mentions/goals/${goal?.id}`,
      ),
      (snapshot: any) => {
        const mentions = snapshot.val();
        if (mentions) {
          if (mentions?.mentionedBy?.length > 0) setUserIsMentioned(true);
        } else {
          setUserIsMentioned(false);
        }
        if (clearComment === id) {
          setUserIsMentioned(false);
          handleClearComment('');
        }
      },
    );
  }, [
    auth.user.id,
    id,
    clearComment,
    handleClearComment,
    goal?.id,
    auth.user.workspace.id,
  ]);

  const renderChildren = useMemo(() => {
    if (variant && variant === 'companyReportPDF') {
      return (
        <>
          {defaultKeyResults &&
            defaultKeyResults.length >= 1 &&
            defaultKeyResults.map((metric, index) => (
              <GenerateEditableKeyMetrics
                goalId={id}
                metric={metric}
                key={index}
                showKeyResultProgress={showObjectiveProgress}
                goalAssignee={assignee.id}
                goalCreatedBy={createdBy}
                goalStatus={progress.status}
                tabKind={kind}
                index={index}
                variant={variant}
                youCheckinsKind={youCheckinsKind}
              />
            ))}
        </>
      );
    } else {
      return (
        <Content
          initial={{height: '0px', opacity: 0}}
          animate={{height: 'auto', opacity: 1}}
          exit={{height: '0px', opacity: 0}}
          transition={{
            duration: 0.3,
          }}
          key="goal-content">
          <Divider />
          {defaultKeyResults && defaultKeyResults.length >= 1
            ? defaultKeyResults.map((metric, index) => (
                <GenerateEditableKeyMetrics
                  goalId={id}
                  metric={metric}
                  key={index}
                  goalAssignee={assignee.id}
                  goalCreatedBy={createdBy}
                  showKeyResultProgress={showObjectiveProgress}
                  goalStatus={progress.status}
                  tabKind={kind}
                  variantType={variantType}
                  index={index}
                  variant={variant}
                  youCheckinsKind={youCheckinsKind}
                />
              ))
            : !goal?.isKpi && (
                <Body2 align="center">
                  There are no{' '}
                  {computeFrameworkVars(auth?.user?.workspace?.framework)?.k_rs}
                </Body2>
              )}
        </Content>
      );
    }
  }, [
    variant,
    defaultKeyResults,
    id,
    assignee.id,
    createdBy,
    goal?.isKpi,
    progress.status,
    showObjectiveProgress,
    kind,
    youCheckinsKind,
    auth?.user?.workspace?.framework,
    variantType,
  ]);

  return (
    <>
      <Wrapper
        errored={errored}
        className={className}
        onMouseLeave={() => setYouGoalsFilterUpdate(false)}
        style={{
          border:
            (youGoalsFilter === 'upcoming' || youGoalsFilter === 'archived') &&
            youGoalsFilterUpdate
              ? '1px solid #ededf2'
              : '',
          boxShadow:
            (youGoalsFilter === 'upcoming' || youGoalsFilter === 'archived') &&
            youGoalsFilterUpdate
              ? '0px 7px 7px rgba(0, 0, 0, 0.05), 0px -2.53341px 10.4559px rgba(0, 0, 0, 0.0325794)'
              : goal?.name === commentRef
              ? '0px 4px 4px #e4e5fb'
              : '',
        }}>
        <Header>
          <EditableGoalHeader
            title={name}
            goalType={goalType}
            kind={type}
            goal={goal}
            id={id}
            progress={progress}
            onGoalUpdate={onGoalUpdate}
            onUpdateReverted={onUpdateReverted}
            upcomingGoal={upcomingGoals}
            users={users}
            hasKeyResults={
              defaultKeyResults && defaultKeyResults.length === 0 ? false : true
            }
            totalUsers={totalUsers}
            measurement={measurement}
            goals={goals}
            currencySymbol={currencySymbol}
            isOpen={isOpen === goal.id ? isOpen : ''}
            managerEdit={managerEdit}
            userIsMentioned={userIsMentioned}
            disabled={disabled}
            showObjectiveProgress={showObjectiveProgress}
            variantType={variantType}
            handleOpen={toggleOpen}
            currentValue={currentValue}
            targetValue={targetValue}
            tabKind={kind}
            assignee={assignee}
            createdBy={createdBy}
            variant={variant}
            youCheckinsKind={youCheckinsKind}
          />
        </Header>
        <AnimatePresence>
          {(isOpen === goal.id || variant === 'companyReportPDF') &&
            renderChildren}
        </AnimatePresence>
      </Wrapper>
    </>
  );
});
// Editablegoal?.displayName = 'EditableGoal';
