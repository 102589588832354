import {useState, useMemo, useCallback} from 'react';
import {AnalyticsTodayDate} from '@utils/date';
import {IMembersFormatFilter} from '@hooks';
import {usePeriodHook} from '@hooks/period-hook';
import {AnalyticsRequestImpl} from '../../admin-analytics.request';
import {AnalyticsController} from '../../admin-analytics.controller';

export const useAnalyticsQuestionHook = (computedFilters: object) => {
  const [userFilter, setUserFilter] = useState({
    filterBy: {
      label: 'People',
      value: 'people',
    },
    selectedTeam: {
      value: '',
      label: '',
    },
    members: [] as Array<IMembersFormatFilter>,
  });

  const {isPeriodEnabled, currentMonthRange} = usePeriodHook();

  const getPeriod = useMemo(() => {
    if (isPeriodEnabled && currentMonthRange().starts) {
      return {
        starts: currentMonthRange().starts,
        ends: currentMonthRange().ends,
        period: currentMonthRange().period,
      };
    }

    return {
      starts: AnalyticsTodayDate().AnalyticsDefaultStartDate,
      ends: AnalyticsTodayDate().AnalyticsDefaultEndDate,
    };
  }, [isPeriodEnabled, currentMonthRange]);

  const [dateRange, setDateRange] = useState(getPeriod);
  const request = useMemo(() => new AnalyticsRequestImpl(), []);
  const controller = useMemo(() => new AnalyticsController(request), [request]);

  const filters = useMemo(() => {
    return {
      ...computedFilters,
      startDate: dateRange.starts,
      endDate: dateRange.ends,
    };
  }, [dateRange.starts, computedFilters, dateRange.ends]);

  const fetchReportCheckinsQuestions = useCallback(
    async (filters: {
      startDate: string;
      endDate: string;
      questionId: string;
    }): Promise<
      {
        questionId: string;
        responses: {
          avatar: any;
          createdAt: string;
          email: string;
          firstName: string;
          id: string;
          lastName: string;
          response: string;
        }[];
        question: string;
      }[]
    > => {
      const response = await controller.fetchReportCheckinsQuestions(filters);

      return response;
    },
    [controller],
  );

  return {
    dateRange,
    filters,
    setDateRange,
    userFilter,
    fetchReportCheckinsQuestions,
    setUserFilter,
  };
};
