import {memo} from 'react';

export const ChevronUpIcon = memo((props: any) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill={props.color ? props.color : '#000000'}
    stroke={props.color ? props.color : '#000000'}
    xmlns="http://www.w3.org/2000/svg">
    <path d="M7.29289 5.70711L5.70711 7.29289C5.07714 7.92286 5.52331 9 6.41421 9L9.58579 9C10.4767 9 10.9229 7.92286 10.2929 7.29289L8.70711 5.70711C8.31658 5.31658 7.68342 5.31658 7.29289 5.70711Z" />
  </svg>
));

ChevronUpIcon.displayName = 'ChevronUpIcon';
