import styled from 'styled-components';
import {getColorFromTheme} from '@ui/ui-utils';

export const AlignmentFormWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .form {
    width: 40%;

    margin-bottom: 30px;
    @media (min-width: 1100px) and (max-width: 1320px) {
      width: 40%;
    }
    @media (min-width: 768px) and (max-width: 1100px) {
      width: 70%;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

export const ObjectiveWrapper = styled.div`
  padding: 16px;
  // margin: 16px 0px 6px;
  border: 1px solid #ededf2;
  border-radius: 10px;
  background-color: white;
`;

export const RemoveButton = styled.button`
  display: inline-flex;
  border: none;
  background: none;
  appearance: none;
  align-items: center;
  margin-top: 8px;
  justify-content: center;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
    svg {
      circle {
        fill: ${getColorFromTheme('backgroundDark')};
      }
      path {
        stroke: ${getColorFromTheme('textMuted')};
      }
    }
  }
`;
