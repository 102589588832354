import {CSSProperties, memo} from 'react';

export const PlusSVG = memo(
  ({style, fill}: {style?: CSSProperties; fill?: string}) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill={fill || '#47B881'} />
      <path
        d="M17.6569 11.9996H6.34315"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 6.34277V17.6565"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
);

PlusSVG.displayName = 'PlusSVG';
