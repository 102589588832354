import React from 'react';

export const UserProfile = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_40486_23637)">
        <rect width="28" height="28" rx="14" fill="#F6F6F8" />
        <rect x="7" y="3" width="14" height="14" rx="7" fill="#CECEDE" />
        <rect x="-16" y="22" width="60" height="60" rx="30" fill="#CECEDE" />
      </g>
      <rect x="0.5" y="0.5" width="27" height="27" rx="13.5" stroke="#CECEDE" />
      <defs>
        <clipPath id="clip0_40486_23637">
          <rect width="28" height="28" rx="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
