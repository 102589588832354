import dayjs from 'dayjs';
import {StatusType} from '@hooks';
import {getColorFromTheme} from '@ui/ui-utils';
import {goalDueDate} from '@utils/date';
import {GoalTypeTag} from '@ui/molecules/goal/editable-title/style';
import {capitalizeGoalType} from '@utils';
import {HorizontalSideRule, VerticalSpacer} from '@ui/atoms/spacer/spacer';
import {FlexRow} from '@ui/style/styles';
import {Body2} from '@ui/atoms/typography';
import styled from 'styled-components';

export const SecondWrapperChildGoals = styled.div<{
  removeBorder?: boolean;
}>`
  display: grid;
  align-items: center;
  grid-template-columns: 45% 40% 10%;
  grid-gap: 16px;
  width: 100%;
  min-width: 580px;
  font-size: 14px;
  height: 120px;
  color: ${getColorFromTheme('textDark')};
  border-bottom: ${({removeBorder}) =>
    removeBorder ? undefined : '1px solid #ededf2'};
  padding: 16px;

  p {
    overflow: hidden;
    white-space: nowrap;
    line-height: 30px;
    text-overflow: ellipsis;
  }
  .pill-wrapper {
    padding: 6px 12px;
    border: 1px solid #cecede;
    width: 50px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    p {
      margin-bottom: 4px;
    }
  }
`;

export const ObjectiveTitle = ({
  goalType,
  goalGroups,
  title,
}: {
  goalType: string;
  title: string;
  goalGroups?: {name: string; id: string}[];
}) => {
  return (
    <div>
      {goalGroups ? (
        <FlexRow>
          {goalGroups.map((group) => (
            <GoalTypeTag className="mr-3">
              {capitalizeGoalType(group?.name || '')}
            </GoalTypeTag>
          ))}
        </FlexRow>
      ) : (
        <GoalTypeTag style={{fontSize: '11pt'}}>
          {goalType && capitalizeGoalType(goalType)}
        </GoalTypeTag>
      )}

      <VerticalSpacer size="10px" />
      <Body2
        kind="textDark"
        style={{marginLeft: '6px', fontWeight: 700, fontSize: '13pt'}}>
        {title}
      </Body2>
    </div>
  );
};

export const PowerpointObjective = ({goal}: {goal: any}) => {
  const isGoalEnded =
    goal.status === StatusType.COMPLETED ||
    goal.status === StatusType.INCOMPLETED;
  const goalIsPastDue = goalDueDate(goal.endDate) === 'Past due';

  const formatGoalDateRange =
    dayjs(goal.startDate).format('DD. MMM YY') +
    '  -  ' +
    dayjs(goal.endDate).format('DD. MMM YY');

  return (
    <SecondWrapperChildGoals>
      <div>
        <ObjectiveTitle
          title={goal.name}
          goalGroups={goal.group}
          goalType={
            goal.goalType === 'group'
              ? '-'
              : goal.goalType === 'company'
              ? 'Company-wide'
              : goal.goalType
          }
        />

        <FlexRow style={{marginLeft: '8px', width: '100%'}}>
          {(isGoalEnded || goalIsPastDue) && (
            <FlexRow>
              <Body2
                kind={goalIsPastDue && !isGoalEnded ? 'red400' : 'textBody'}
                weight="regular"
                style={{fontSize: '9pt'}}>
                {isGoalEnded ? 'Ended' : 'Past due'}{' '}
              </Body2>
              <HorizontalSideRule size={'15px'} />
            </FlexRow>
          )}
          <Body2 kind="textBody" style={{fontSize: '9pt', width: '100%'}}>
            {formatGoalDateRange}
          </Body2>
        </FlexRow>
      </div>

      <Body2 weight="semibold" style={{fontSize: '12pt'}}>
        {goal.assignee?.firstName
          ? goal.assignee?.firstName + ' ' + goal.assignee.lastName
          : goal.assignee?.email}
      </Body2>
      <div className="pill-wrapper">
        <Body2
          style={{
            fontSize: '10pt',
            width: '100%',
          }}>
          {`${goal.percentageProgress}%`}
        </Body2>
      </div>
    </SecondWrapperChildGoals>
  );
};
