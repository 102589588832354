import {memo} from 'react';
import styled from 'styled-components';
import {getColorFromTheme} from '../../ui-utils';
import {Body2} from '../../atoms/typography/body2';

const Wrapper = styled.div`
  margin: 16px 0;
  padding: 8px;
  border-radius: 5px;
  background-color: ${getColorFromTheme('red100')};
`;

export interface TErrorBadgeProps {
  children: string;
}

export const ErrorBadge = memo(function ({children}: TErrorBadgeProps) {
  return (
    <Wrapper>
      <Body2 kind="red400">{children}</Body2>
    </Wrapper>
  );
});
ErrorBadge.displayName = 'ErrorBadge';
