import {useMemo, forwardRef, Ref, ReactNode} from 'react';
import ReactSelect, {
  components,
  IndicatorProps,
  SingleValueProps,
  PlaceholderProps,
  Props,
  ValueType,
  ActionMeta,
  OptionsType,
} from 'react-select';
import {Body1} from '../../../atoms/typography/body1';

import {getStyles, Wrapper, IndicatorWrapper} from '../shared';

const {
  DropdownIndicator: SelectDropdownIndicator,
  Placeholder: SelectPlaceholder,
  SingleValue: SelectSingleValue,
} = components;

export interface TOption {
  value: string;
  label: string;
}

export interface SharedProps<T> extends Props<T> {
  options: OptionsType<T>;
  icon: ReactNode;
  onChange?: (value: ValueType<T, false>, action: ActionMeta<T>) => void;
}

export interface SelectFieldProps extends SharedProps<TOption> {}

const SingleValue = (props: SingleValueProps<TOption>) => {
  return (
    <SelectSingleValue {...props}>
      <Body1 kind="textDark">{props.children as string}</Body1>
    </SelectSingleValue>
  );
};

const Placeholder = (props: PlaceholderProps<TOption, false>) => {
  return (
    <SelectPlaceholder {...props}>
      <Body1 kind="textBody">{props.children}</Body1>
    </SelectPlaceholder>
  );
};

const DropdownIndicator = (props: IndicatorProps<TOption, false>) => {
  return (
    <SelectDropdownIndicator {...props}>
      <IndicatorWrapper>{props.selectProps.icon}</IndicatorWrapper>
    </SelectDropdownIndicator>
  );
};

export const ActionSelectField = forwardRef(
  (
    {
      label,
      placeholder,
      state,
      helper,
      options,
      onChange,
      isDisabled,
      ...rest
    }: SelectFieldProps,
    ref: Ref<ReactSelect<TOption>>,
  ) => {
    const styles = useMemo(() => getStyles<TOption, false>(), []);

    return (
      <Wrapper>
        <ReactSelect
          {...rest}
          isDisabled={isDisabled}
          isClearable={false}
          isSearchable={false}
          placeholder={placeholder}
          onChange={onChange}
          ref={ref}
          components={{
            DropdownIndicator,
            IndicatorSeparator: null,
            Placeholder,
            SingleValue,
          }}
          options={options}
          styles={styles}
          theme={(theme) => ({
            ...theme,
            borderRadius: 7,
            spacing: {
              baseUnit: 6,
              controlHeight: 52,
              menuGutter: 8,
            },
          })}
        />
      </Wrapper>
    );
  },
);
