import {motion} from 'framer-motion';
import styled from 'styled-components';
import {Button} from '../../../../../../../ui/atoms/button';
import {getColorFromTheme} from '../../../../../../../ui/ui-utils';

export const SearchFilterWrapper = styled.div`
  display: inline-block;
  width: 100%;
  padding: 16px 24px;
`;
export const LineColor = styled.div<{bg: string}>`
  width: 24px;
  height: 2px;
  background-color: ${({bg}) => bg};
`;
export const FirstWrapper = styled.div`
  border: 1px solid ${getColorFromTheme('borderLight')};
  border-radius: 10px;
`;

export const SecondWrapperHeader = styled.div`
  padding: 16px 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const SecondWrapperHeader2 = styled.div`
  padding: 24px 16px;
  width: 100%;
  display: flex;
  justify-content: end;
  font-size: 14px;
`;

export const FirstWrapperChild = styled(motion.div)`
  display: block;
  padding: 16px 24px;
  width: 100%;
  border-top: 1px solid ${getColorFromTheme('borderLight')};
  border-radius: 10px;
`;

export const SearchWrapper = styled.div`
  width: 100%;
`;

export const FilterWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, 44%) minmax(auto, 55%);
  grid-gap: 12px;
  width: 100%;
  margin-top: 16px;
`;

export const FilterBoxWrapper = styled.span`
  width: 100%;
`;

export const DateWrapper = styled.span`
  width: 100%;
`;

export const FirstWrapperHeaderSpanLeft = styled.span`
  float: left;
  padding-top: 8px;
`;

export const ThirdWrapperHeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;

export const ThirdWrapperHeaderMiddle = styled.div`
  text-align: right;
`;

export const ThirdWrapperHeaderRight = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  text-align: right;
  color: ${getColorFromTheme('textBody')};

  >span: not(: first-child) {
    margin-left: 16px;
  }
`;

export const FirstWrapperHeaderSpanRight = styled.span`
  float: right;
`;

export const CustomFontWeight = styled.span`
  font-weight: 500;
  color: #1e1e2f;
  font-size: 14px;
`;

export const EmailButton = styled(Button)`
  line-height: 1;
  padding: 16px 0;
  font-size: 14px;
  font-weight: 500;

  &:disabled {
  }
`;

export const SecondWrapperChildHeader = styled.div`
  display: grid;
  grid-template-columns: 40% 20% 20% 20%;
  font-size: 14px;
  color: ${getColorFromTheme('textBody')};
  border-top: 1px solid ${getColorFromTheme('borderLight')};
  border-radius: 10px 0 0 0;
  padding: 16px;
  background: #fafafa;
`;

export const SecondWrapperChildHeader2 = styled.div`
  display: grid;
  grid-template-columns: 17% 17% 17% 10% 10% 10% 10% 9%;
  font-size: 14px;
  color: ${getColorFromTheme('textBody')};
  border-top: 1px solid ${getColorFromTheme('borderLight')};
  border-radius: 10px 0 0 0;
  padding: 16px 0 16px 16px;
  background: #fafafa;
`;

export const SecondWrapperChildGoals = styled.div`
  display: grid;
  grid-template-columns: 40% 20% 20% 20%;
  font-size: 14px;
  color: ${getColorFromTheme('textDark')};
  border-top: 1px solid ${getColorFromTheme('borderLight')};
  padding: 16px;
`;

export const SecondWrapperChildTreeGoals = styled.div`
  display: block;
  background: ${getColorFromTheme('white')};
  border-top: 1px solid ${getColorFromTheme('borderLight')};
  border-radius: 10px;
`;

export const ZoomWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 1px solid ${getColorFromTheme('borderLight')};
  height: 32px;
  width: 106px;
  border-radius: 6px;
  padding: 4px 8px 4px 8px;

  svg {
    stroke: ${getColorFromTheme('textDark')};
    position: relative;
    top: 2.7px;
  }
`;

export const TreeViewGoalsContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: visible;
  padding: 24px;

  &:last-child {
    border: none;
  }

  @media (min-width: 1023px) {
    min-width: 600px;
  }
`;

export const TreeViewGoalsCard = styled.div<{height?: boolean}>`
  display: block;
  border: 1px solid ${getColorFromTheme('borderDark')};
  border-radius: 8px;
  padding: 10px;
  background: #ffffff;

  width: 328px;
  max-height: ${(props: any) => (props.height ? '100%' : '100%')};
  height: ${(props: any) => (props.height ? '150px' : '100%')};
  position: relative;
  // box-shadow: 0px 9px 38px rgba(0, 0, 0, 0.05),
  //   0px 6.53341px 11.4559px rgba(0, 0, 0, 0.0325794);
`;

export const TreeViewGoalsCardFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: relative;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .goalName .tooltip {
    visibility: hidden;
    width: auto;
    background-color: rgba(0, 0, 0, 0.7);
    font-size: 12px;
    color: #fff;
    text-align: center;
    padding: 12px;
    border-radius: 6px;
    position: absolute;
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 1;
    margin-top: -75px;
  }
  .goalName:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }

  .goalName .tooltip::after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
`;
export const Rule = styled.div<{
  bg: string;
  width?: string;
  height?: string;
  top?: string;
  left?: string;
}>`
  height: ${({height}) => height || '100%'};
  width: ${({width}) => width || '2px'};

  top: ${({top}) => top};
  left: ${({left}) => left || '0px'};
  background: ${({bg}) => bg};
  position: absolute;
`;

export const TreeViewGoalsCardButton = styled.button<{bg: string}>`
  position: relative;

  color: #ffffff;
  font-size: 10px;
  padding-top: 2px;
  .arrow {
    position: absolute;

    left: 10;
    background: ${({bg}) => bg};
    border-radius: 20px;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  > svg {
    stroke: ${getColorFromTheme('white')};
  }
`;

export const TreeViewKeyResultsWrapper = styled.div<{bg?: string}>`
  ${({bg}) =>
    bg
      ? `background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23${bg}' stroke-width='2.5' stroke-dasharray='14%2c 9' stroke-dashoffset='100' strokeLinecap='square'/%3e%3c/svg%3e");`
      : `background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23CECEDEFF' stroke-width='2.5' stroke-dasharray='14%2c 9' stroke-dashoffset='100' strokeLinecap='square'/%3e%3c/svg%3e");`}

  border-radius: 8px;
  margin: 12px 0 0 0;
  padding: 10px 0 10px 12px;
  position: relative;
`;

export const TreeViewKeyResultsButton = styled.button<{isOpen?: boolean}>`
  display: flex;
  align-items: center;
  color: ${(props: any) => (props.isOpen ? '#585adf' : '#1e1e2f')};
  font-size: 14px;
  weight: 500;

  svg {
    margin-right: 5px;
    path {
      fill: ${(props: any) => (props.isOpen ? '#585adf' : '#1e1e2f')};
    }
    transform: ${(props: any) =>
      props.isOpen ? 'rotate(0deg)' : 'rotate(-90deg)'};
  }
`;

export const GoalTypeTag = styled.div`
  height: 26px;
  padding: 4px 8px;
  background: #f6f6f8;
  border-radius: 8px;
  font-size: 12px;
  color: #5f5f8c;
  font-weight: 500;
  line-height: 18px;
  width: fit-content;
`;

export const SecondWrapperChildKeyResults = styled.div`
  display: grid;
  grid-template-columns: 40% 22% 38%;
  font-size: 14px;
  color: ${getColorFromTheme('textDark')};
  padding: 16px;
`;

export const SecondWrapperChildNestedKeyResults = styled.div`
  display: grid;
  grid-template-columns: 40% 38% 22%;
  font-size: 14px;
  color: ${getColorFromTheme('textDark')};
  background: ${getColorFromTheme('backgroundLight')};
  border-left: 1px solid ${getColorFromTheme('borderDark')};
  margin: 0px 0px 0px 25px;
  padding: 22px 14px 22px 0px;
`;

export const SecondWrapperChildPeople = styled.div`
  display: grid;
  grid-template-columns: 17% 17% 17% 10% 10% 10% 10% 9%;
  font-size: 14px;
  color: ${getColorFromTheme('textDark')};
  border-top: 1px solid ${getColorFromTheme('borderLight')};
  padding: 16px;
`;

export const Divider = styled(motion.div)`
  border-top: 1px solid ${getColorFromTheme('borderLight')};
  padding-bottom: 15px;
  margin: 0 32px 0 10px;
`;

export const NestedDivider = styled(motion.div)`
  border-top: 1px solid ${getColorFromTheme('borderLight')};
`;

export const Content = styled(motion.div)`
  padding-top: 15px;
`;

export const PaginationUl = styled.ul`
  list-style: none;
  width: 239px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 32px 201px 0 315px;
  padding: 4px;
  border-radius: 5px;
  border: solid 1px #cecede;
  background-color: #f6f6f8;
`;

export const PaginationLi = styled.li`
padding: 8px;
cursor: pointer;
margin: 0 1px;
color: #5f5f8c;

&:hover {
  color: #585adf;
}

  &.active { 
    width: 32px;
    height: 32px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 3px 12px;
    border-radius: 4px;
    box-shadow: 0 2px 5px 0 rgba(172, 172, 190, 0.24);
    background-color: #ffffff;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: normal;
    text-align: left;
    color: #585adf;
    transition: background-color 0.3s ease-in;
    }
  };
`;

export const PaginationButton = styled.button`
  border: none;
  stroke: #5f5f8c;
  stroke-width: 1.5px;
  fill: none;
  width: 100%;
  height: 100%;
  padding-top: 4px;

  &:hover {
    stroke: black;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    stroke: #5f5f8c;
  }
};
`;

export const ItemLoaderWrapper = styled.div`
  display: block;
  border-top: 1px solid ${getColorFromTheme('borderLight')};
  padding: 16px;
`;

export const MobileView = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;
  min-height: 300px;

  .wrapper {
    padding: 6px, 16px, 6px, 16px;
    background: #f6f6fe;
    border-radius: 8px;
    height: 34px;
    width: 70%;
    display: flex;

    justify-content: center;
    align-items: center;
  }
  p {
    margin-left: 15px;
  }
`;

export const MobileWrapper = styled.div`
  @media (min-width: 500px) {
    display: none;
  }
`;
export const DesktopWrapper = styled.div`
  @media (max-width: 500px) {
    display: none;
  }
`;
