export enum QuestionKind {
  INDIVIDUAL = 'INDIVIDUAL',
  GROUP = 'GROUP',
  GENERAL = 'GENERAL',
}

export enum EQuestionType {
  INDIVIDUAL = 'individual',
  GROUP = 'group',
  GENERAL = 'general',
}

export enum ECheckinsFrequencyType {
  MONTHLY = 'monthly',
  WEEKLY = 'weekly',
  BIWEEKLY = 'bi-weekly',
  BIMONTHLY = 'bi-monthly',
  QUARTERLY = 'quarterly',
}
export interface ICreateQuestionForm {
  target: {
    type: string;
    ids: string[];
  };
  frequency?: string;
  status?: string;
  questions: [
    {
      type: string;
      text: string;
    },
  ];
}
export interface ICreateQuestionFormFormFormat {
  target: {
    type: string;
    ids: {
      individual: string[];
      group: string[];
    };
  };
  frequency: string;
  questions: [
    {
      type: string;
      text: string;
    },
  ];
}

export interface ICreateQuestionResponseDataSingle {
  questions: IQuestion[];
  // page: number;
  // limit: number;
  // totalPages: number;
  // totalResults: number;
  batch: string;
  status?: string;
  lastRun?: string;
  startDate?: string;
  createdAt: string;
  createdBy: string;
  target: {
    type: string;
    ids: string[];
    count: number;
  };
}
export type ICreateQuestionResponseData = ICreateQuestionResponseDataSingle[];
export interface ICreateQuestionResponse {
  statusCode: number;
  message: string;
  data: ICreateQuestionResponseData;
}
export interface IQuestion {
  id: string;
  type: string;
  text: string;
  frequency: string;
}

export interface ICheckinsQuestionResponseData {
  year?: number;
  isActive?: boolean;
  isSubmitted?: boolean;
  dueDay?: string;
  workspace?: string;
  weekOfYear?: number;
  user?: string;
  questions?: {
    _id?: string;
    question?: string;
    questionId?: string;
    response?: string | null;
    optional?: boolean;
    target?: {
      type?: string;
      ids?: string[];
    };
  }[];
  id?: string;
  checkinId?: string;
}
export interface ICheckinsQuestionResponse {
  statusCode: number;
  message: string;
  data: ICheckinsQuestionResponseData;
}

export interface ICheckinsPulseResponseData {
  mood?: null | string;
  feedback?: null | string;
  year?: number;
  isActive?: boolean;
  isSubmitted?: boolean;
  dueDay?: string;
  weekOfYear?: number;
  user?: string;
  id?: string;
  workspace?: string;
  checkinId?: string;
}

export interface ICheckinsPulseResponse {
  statusCode: number;
  message: string;
  data: ICheckinsPulseResponseData;
}

export interface ICheckinsPrioritiesResponseData {
  currentCheckin?: {
    text?: string;
    completed?: boolean;
    key?: string;
  }[];
  nextCheckin?: {
    text?: string;
    completed?: boolean;
  }[];
  year?: number;
  isActive?: boolean;
  isSubmitted?: boolean;
  dueDay?: string;
  weekOfYear?: number;
  user?: string;
  id?: string;
  workspace?: string;
  checkinId?: string;
}

export interface ICheckinsPrioritiesResponse {
  statusCode: number;
  message: string;
  data: ICheckinsPrioritiesResponseData;
}

export interface ICurrentCheckinsInfoResponseData {
  params?: string[];
  feedback?: null;
  year?: number;
  isActive?: boolean;
  isReviewed?: boolean;
  isSubmitted?: boolean;
  dueDay?: string;
  submittedAt?: string;
  weekOfYear?: number;
  workspace?: string;
  user?: string;
  id?: string;
  responses?: {
    questions?: ICheckinsQuestionResponseData;
    priorities?: ICheckinsPrioritiesResponseData;
    pulse?: ICheckinsPulseResponseData;
    goals?: boolean;
  };
}
export interface ICurrentCheckinsForm {
  feedback?: string;
  year?: number;
  isActive?: boolean;
  isReviewed?: boolean;
  dueDay?: string;
  weekOfYear?: number;
  workspace?: string;
  user?: string;
  id?: string;
  questions?: ICheckinsQuestionResponseData;
  priorities?: ICheckinsPrioritiesResponseData;
  pulse?: ICheckinsPulseResponseData;
  goals?: boolean;
}

export interface ICurrentCheckinsInfoResponse {
  statusCode: number;
  message: string;
  data: ICheckinsQuestionResponseData;
}

export interface ISpecificCheckinsWithoutResponseData {
  params?: string[];
  feedback?: null;
  year?: number;
  isActive?: boolean;
  isReviewed?: boolean;
  dueDay?: string;
  weekOfYear?: number;
  workspace?: string;
  user?: string;
  id?: string;
}
export interface ISpecificCheckinsWithoutResponse {
  statusCode: number;
  message: string;
  data: ISpecificCheckinsWithoutResponseData;
}

export interface ISpecificCheckinsWithResponseData {
  updatedAt: string | undefined;
  params?: string[];
  feedback?: null;
  year?: number;
  isActive?: boolean;
  isReviewed?: boolean;
  dueDay?: string;
  weekOfYear?: number;
  workspace?: string;
  user?: string;
  id?: string;
  responses?: {
    questions: ICheckinsQuestionResponseData;
    priorities: ICheckinsPrioritiesResponseData;
    pulse: ICheckinsPulseResponseData;
  };
}
export interface ISpecificCheckinsWithResponse {
  averagePulse: any;
  statusCode: number;
  message: string;
  data: ISpecificCheckinsWithResponseData;
}

export interface ICheckinsListResponseData {
  summary?: {
    averagePulse?: string;
    reviewed?: number;
    submitted?: number;
  };
  data?: ISpecificCheckinsWithoutResponseData[];
}
export interface ICheckinsListResponse {
  statusCode: number;
  message: string;
  data: ICheckinsListResponseData;
}

export interface ICheckinsCalendarWeekListResponseData {
  reviewedAt?: null | string;
  dueDay?: string;
  weekOfYear?: number;
  month?: number;
  scheduledCount?: number;
  year?: number;
  id?: string;
}

export interface ICheckinsCalendarWeekListResponse {
  statusCode: number;
  message: string;
  data: ICheckinsCalendarWeekListResponseData[];
}

export interface ICheckinsQuestionsTemplatesResponseData {
  type: string;
  questions: string[];
}

export interface ICheckinsQuestionsTemplatesResponse {
  statusCode: number;
  message: string;
  data: ICheckinsQuestionsTemplatesResponseData[];
}
