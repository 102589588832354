import CycleFilter from '../../components/cycle-filter/cycle-filter';
import {FlexRowSpaceBetween} from '@ui/style/styles';
import {Body2} from '@ui/atoms/typography';
import {getColorFromTheme} from '@ui/ui-utils';
import styled from 'styled-components';
import {ReviewCycleProps} from '@api/cc-api/cc-request';
import {SecondaryNavigation} from '@ui/molecules/nav/navigation';
import {useMemo} from 'react';

const Wrapper = styled.div`
  border-radius: 10px;
  background-color: white;
  border: 1px solid ${getColorFromTheme('borderLight')};
  .wrapper {
    padding: 16px 24px 24px;
  }
  .details {
    display: flex;
    justify-content: space-between;

    grid-gap: 16px;
    background-color: ${getColorFromTheme('white')};
    padding: 16px 24px;
    border-radius: 10px;
  }
`;
const Card = styled.div`
  border: 1px solid ${getColorFromTheme('borderDark')};
  border-radius: 8px;
  display: flex;
  align-items: center;
  background: white;
  padding: 8px 16px;
  min-width: 30%;
  @media (max-width: 1300px) {
    min-width: 20%;
  }
`;

interface ICycle {
  reviewTimeline: string;
  progress: number;
  dueDay: string;
  disabled?: boolean;
  isLoading?: boolean;
  reviewOptions: Array<ReviewCycleProps>;
  setActiveTab: any;
  activeTab: string;
}
export const CCResultFilter = ({
  reviewTimeline,
  progress,
  isLoading,
  disabled,
  dueDay,
  setActiveTab,
  activeTab,
  reviewOptions,
}: ICycle) => {
  const resultsView = useMemo(() => {
    const nav = [
      {
        active: activeTab === 'ratings',
        href: '#',
        id: '1',
        onClick: () => setActiveTab('ratings'),
        title: 'Ratings',
      },
      {
        active: activeTab === 'matrix',
        href: '#',
        id: '2',
        onClick: () => setActiveTab('matrix'),
        title: 'C&C Matrix',
      },
    ];
    return nav;
  }, [activeTab, setActiveTab]);

  return (
    <Wrapper>
      <div className="details">
        <CycleFilter
          reviewCycleOptions={reviewOptions}
          showOnlyEnded
          isLoading={isLoading}
          disabled={disabled}
        />
        <Card>
          <FlexRowSpaceBetween>
            <Body2 weight="bold">Review period </Body2>
            <Body2 style={{marginLeft: '6px'}} weight="bold" kind="textMedium">
              {reviewTimeline || '-'}{' '}
            </Body2>
          </FlexRowSpaceBetween>
        </Card>
        <Card>
          <FlexRowSpaceBetween>
            <Body2 weight="bold">Completion</Body2>
            <Body2 kind="textMedium" weight="bold">
              {progress ? `${progress}%` : ' - '}
            </Body2>
          </FlexRowSpaceBetween>
        </Card>
        <SecondaryNavigation
          kind="details"
          navStyle={{
            width: 'max-content',
            borderRadius: '10px',
            padding: '4px 8px',
            height: '46px',
          }}
          navigations={resultsView}
        />
      </div>
    </Wrapper>
  );
};
