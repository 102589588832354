import {IBillingPlans} from '@ui/interface';

interface PriceCalculationParams {
  billingPlans: IBillingPlans;
  chosenBillingPlanType: string;
  selectedBillingCycle: string;
  numSeats: number;
  couponDiscount: number;
}

interface PriceCalculationResult {
  subtotal: number;
  annualDiscountPercent: number;
  couponDiscountAmount: number;
  dueToday: number;
  perSeatCost: number;
}

export const calculateBillingPrices = ({
  billingPlans,
  chosenBillingPlanType,
  selectedBillingCycle,
  numSeats,
  couponDiscount,
}: PriceCalculationParams): PriceCalculationResult => {
  const isLifeTimePlan = chosenBillingPlanType === 'lifetime';

  const currentPlan = (billingPlans as any)?.[chosenBillingPlanType];

  // Calculate base cost
  let baseCost: number;
  if (isLifeTimePlan) {
    baseCost = currentPlan.unit_cost * numSeats;
  } else {
    const unitCost = currentPlan[selectedBillingCycle].unit_cost;
    baseCost =
      selectedBillingCycle === 'monthly'
        ? unitCost * numSeats
        : unitCost * 12 * numSeats;
  }

  // Calculate annual discount
  const annualDiscountPercent = isLifeTimePlan
    ? 0
    : currentPlan.yearly?.yearly_discount_percent || 0;

  const annualDiscountAmount = (annualDiscountPercent / 100) * baseCost;

  // Apply annual discount if applicable
  const subtotal =
    selectedBillingCycle === 'yearly' && !isLifeTimePlan
      ? baseCost - annualDiscountAmount
      : baseCost;

  // Calculate coupon discount
  const couponDiscountAmount = (couponDiscount / 100) * subtotal;

  // Calculate final amount due
  const dueToday = subtotal - couponDiscountAmount;

  // Calculate per-seat cost
  const perSeatCost = subtotal / numSeats;

  return {
    subtotal,
    annualDiscountPercent,
    couponDiscountAmount,
    dueToday,
    perSeatCost,
  };
};
