import {memo} from 'react';
import {TreeViewGoalsBox} from './treeview-box';
import {TreeViewGoalsContainer} from './treeview.styles';

export const TreeViewContainer = memo(
  ({goal, isExpand, keyResults, isOpen, setIsOpen}: any) => {
    return (
      <>
        <TreeViewGoalsContainer>
          <div style={{height: '100%'}}>
            <TreeViewGoalsBox
              goal={goal}
              isExpand={isExpand}
              allGoals={keyResults}
              parentGoalId={goal?.id}
              updateHeight={(value: number) =>
                setIsOpen({
                  ...isOpen,
                  [goal.id]: {
                    ...isOpen?.[goal.id],
                    height: value,
                  },
                })
              }
              numberOfAlignedGoals={goal.alignedGoals.length}
              type="parent"
              showSpecificAlignee={isOpen}
              setShowSpecificAlignee={setIsOpen}
              lastGoal={goal.alignedGoals.length === 0}
            />
          </div>
        </TreeViewGoalsContainer>
      </>
    );
  },
);
