import {observer} from 'mobx-react';
import {lazy} from 'react';
import {useNavigate} from 'react-router';
import {ForgotPasswordController} from './forgot-password-controller';
import {ForgotPasswordFormData} from './forgot-password-interface';
import {ForgotPasswordRequestImpl} from './forgot-password-request';

const ForgotPasswordPage = lazy(() =>
  import('./forgot-password-page').then((module) => ({
    default: module.ForgotPasswordPage,
  })),
);

const SetupWorkspaceForm = lazy(() =>
  import('./forgot-password-form').then((module) => ({
    default: module.ForgotPasswordForm,
  })),
);

export const installForgotPasswordPage = () => {
  const request = new ForgotPasswordRequestImpl();
  const controller = new ForgotPasswordController(request);

  const FormComponent = observer(() => {
    const navigate = useNavigate();

    const onSubmit = async (data: ForgotPasswordFormData) => {
      const response = await controller.forgotPassword(data);
      response &&
        navigate(`../password-confirmation/${data.email}`, {replace: true});
    };

    return <SetupWorkspaceForm onSubmit={onSubmit} />;
  });

  const Page = observer(() => (
    <ForgotPasswordPage FormComponent={FormComponent} />
  ));

  return {
    Page,
  };
};
