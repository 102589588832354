import styled from 'styled-components';
import {getColorFromTheme} from '../../../../../../ui/ui-utils';
import {Typography} from '../../../../../../ui/atoms/typography';

export const PageWrapper = styled.div`
  border: solid 1px ${getColorFromTheme('borderLight')};
  border-radius: 10px;
}`;

export const FirstWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  > span {
    display: flex;
    align-items: center;
  }
`;

export const PrimaryWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 0px;
  > span {
    display: flex;
    align-items: center;
  }
`;

export const SecondWrapper = styled.div`
  border: solid 1px ${getColorFromTheme('borderLight')};
  border-radius: 10px;
  .searchBox {
    padding: 12px 16px 16px 16px;
    border-radius: 10px;
  }
  .groupsCount {
    padding: 16px;
    border-top: solid 1px ${getColorFromTheme('borderLight')};
    border-radius: 10px;
  }
  .table-container {
    overflow-x: scroll;
    &::-webkit-scrollbar {
      width: 2em;
      border-top: 1px solid #ededf2;
      height: 2.1em;
    }
  }
`;

export const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 55% 14% 14% 14%;
  background: ${getColorFromTheme('backgroundLight')};
  border-top: 1px solid ${getColorFromTheme('borderLight')};

  grid-gap: 3%;
  padding: 16px;
  height: 52px;
  @media (max-width: 768px) {
    min-width: 500px;
  }
`;

export const TableHeaderChild = styled.div`
  display: grid;
  grid-template-columns: 55% 14% 14% 8%;
  grid-gap: 3%;
  padding: 12px 16px 16px 16px;
  border-top: 1px solid ${getColorFromTheme('borderLight')};
  height: 52px;
  @media (max-width: 768px) {
    min-width: 500px;
  }
  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  > p {
    display: flex;
    align-items: center;
  }
  > p > svg {
    width: 10.5px;
    height: 14px;
    margin: 7.4px 9px 7.4px 0;
  }
  button > svg:hover,
  svg:active {
    stroke: ${getColorFromTheme('purple300')};
  }
`;

export const DropdownItem = styled.button`
  ${Typography};
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  cursor: pointer;
  border: none;
  background: none;
  display: block;
  outline: none;
  line-height: 1.7;
  padding: 6px 16px;
  transition: all ease-in-out 200ms;
  color: ${getColorFromTheme('textBody')};
  &:hover {
    background-color: ${getColorFromTheme('backgroundDark')};
    color: ${getColorFromTheme('textDark')};
  }
`;

export const ModalWrapper = styled.div`
  max-width: 600px;
  margin: auto;
  @media (max-width: 500px) {
    margin: 0 20px;
  }
`;

export const ModalCard = styled.div`
  background: ${getColorFromTheme('white')};
  padding: 80px;
  border: 1px solid ${getColorFromTheme('borderLight')};
  border-radius: 10px;
  @media (max-width: 500px) {
    padding: 40px;
  }
`;

export const LoaderWrapper = styled.div`
  padding: 16px;
  border-top: 1px solid ${getColorFromTheme('borderLight')};
`;
