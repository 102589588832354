import {memo} from 'react';

export const MagnifyingGlassIcon = memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="8"
    fill="none"
    viewBox="0 0 16 16">
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M7.333 12.667c2.946 0 5.334-2.388 5.334-5.334C12.667 4.388 10.279 2 7.333 2 4.388 2 2 4.388 2 7.333c0 2.946 2.388 5.334 5.333 5.334zM14.002 14l-2.9-2.9"
    />
  </svg>
));

MagnifyingGlassIcon.displayName = 'MagnifyingGlassIcon';
