import {
  Wrapper,
  DesktopWrapper,
  StyledContainer,
  UpdateWrapper,
  AvatarWrapper,
  NameWrapper,
  ProgressWrapper,
  StyledEndWrapper,
  StyledEndWrapper3,
  ActionWrapper,
  ResponsiveProgressTracker,
  UpdateGoalWrapper,
  UpdateGoalWrapper2,
  CustomCancelButton,
} from './styles';
import {memo, useState, useMemo} from 'react';
import {AnimatePresence} from 'framer-motion';
import {MeasurementType} from '../../../interface';
import {EditGoalHeaderProps} from './interface';
import {Avatars} from '../../avatars';
import {ProgressTracker} from '../../progress-tracker';
import {ArrowIcons} from '@ui/molecules/arrow-icons/arrow-icons';
import {Button} from '../../../atoms/button';
import {EditableGoalTitle} from '../editable-title';
import {UpdateGoal} from '../update-goal';
import {CancelIcon} from '../../../atoms/icons';
import {BackAction} from '../../../molecules/back-action';
import {GoalViewInsights} from '@ui/atoms/view-icon/view-icon';
import {HorizontalSpacer} from '../../../atoms/spacer';
import {HorizontalSideRule} from '@ui/atoms/spacer/spacer';
import {useViewInsight} from '@hooks/insights';
import {CustomIconModal} from '@ui/organisms/modal/custom-icon-modal';
import {Body1, Body2} from '../../../atoms/typography';
import {Dropdown} from '../../dropdown';
import {useEditableHeaderHook} from './editable-header-hook';
import {useNavigate} from 'react-router';
import {useStoreContext} from '../../../../store/store-context';
import {StatusType} from '../../../../hooks';
import {Flex, FlexRow, FlexRowEnd} from '../../../style/styles';
import {useDebouncedCallback} from 'use-debounce';

export const EditableGoalHeader = memo(function ({
  kind,
  title,
  goalType,
  progress,
  users,
  measurement,
  onGoalUpdate,
  currencySymbol,
  isOpen,
  disabled,
  id,
  handleOpen,
  hasKeyResults,
  onUpdateReverted,
  upcomingGoal,
  showObjectiveProgress,
  userIsMentioned,
  totalUsers,
  goals,
  currentValue,
  targetValue,
  managerEdit,
  variantType,
  goal,
  tabKind,
  assignee,
  variant,
  youCheckinsKind,
}: EditGoalHeaderProps) {
  const navigate = useNavigate();

  const {auth} = useStoreContext().authStore;
  const [openKR, setOpenKR] = useState(goal?.open ? true : false);
  const {
    computeShowSaveChanges,
    handleEditAndDelete,
    displayArchiveOrRestore,
    showEditModal,
    setShowEditModal,
    isUpdate,
    formatColor,
    setIsUpdate,
    open,
    setShowDuplicateGoalModal,
    goalFramework,
    setInitialGoalPerformance,
    getLastActivity,
    setOpen,
    setChangesSaved,
    changesSaved,
    setIsUpdated,
    updateEditing,
    showDuplicateGoalModal,
    revertChanges,
    initialGoalPerformance,
    handleManagerEdit,
    updateGoal,
    endGoal,
  } = useEditableHeaderHook({
    id,
    title,
    handleOpen,
    tabKind,
    goal,
    isOpen,
    variant,
    progress,
    showObjectiveProgress,
    onGoalUpdate,
    onUpdateReverted,
  });

  const {
    storeYouGoals: {fetchedGoals, updatePerformance},
    usersStore: {currentUser},
    goalsState,
  } = useStoreContext();

  const {
    youGoalsFilter,
    setYouGoalsFilterUpdate,
    updateGoalFields: addGoalFields,
  } = useStoreContext().storeYouGoals;

  useMemo(() => {
    if (isOpen !== goal.id && (isUpdate || openKR)) {
      if (isUpdate) setIsUpdate(false);
      if (openKR) setOpenKR(false);
    }
  }, [goal.id, isOpen, isUpdate, openKR, setIsUpdate]);

  const isValuePositive = (value: number): boolean => {
    if (value > 0) {
      return true;
    }
    return false;
  };

  useMemo(() => {
    if (initialGoalPerformance === '') {
      setInitialGoalPerformance(goal?.performance);
      setChangesSaved(false);
    }
  }, [
    initialGoalPerformance,
    goal?.performance,
    setInitialGoalPerformance,
    setChangesSaved,
  ]);
  const [kpiInitialValue] = useState({
    value: goal.value,
    context: goal.context,
  });

  const currency = measurement === MeasurementType.CURRENCY && currencySymbol;

  const handleStyledEndPadding = () => {
    if (variant === 'companyTeams') return '5px';
    if (tabKind === 'youcheckins') return '15px';
    return '35px';
  };

  const viewGoalInsight = useViewInsight();

  const ProgressTracking = () => {
    return (
      <ProgressTracker
        archived={youGoalsFilter === 'archived'}
        isGoalKpi={goal?.isKpi}
        currency={currency}
        unit={measurement}
        upcoming={youGoalsFilter === 'upcoming' || upcomingGoal}
        variant={variant ? variant : tabKind}
        {...progress}
      />
    );
  };

  const debounceUpdateGoal = useDebouncedCallback((e) => {
    updateGoal(e);
  }, 2000);

  const UpdateButton = ({title}: {title: string}) => {
    return (
      <Button
        kind={isUpdate ? 'primary' : 'secondary'}
        width="sm"
        aria-label="update key metric"
        disabled={
          disabled ||
          youCheckinsKind === 'viewuser' ||
          (goal.isKpi && assignee.id !== auth?.user.id)
        }
        title="Update progress"
        type="button"
        style={{
          padding: '8px 16px',
        }}
        onClick={(e) => {
          if (assignee.id === auth?.user.id) {
            updateEditing();
          } else {
            if (!isOpen) {
              handleOpen();
              setOpenKR(true);
            }
          }
          updateGoal(e);
        }}>
        {isUpdate ? title : !isUpdate && goal?.isKpi ? 'Update' : 'Expand'}
      </Button>
    );
  };

  const UpdateDropdown = () => {
    return (
      <Dropdown
        dropdownItemStyle={{whiteSpace: 'nowrap'}}
        menu={
          [String(StatusType.INPROGRESS), String(StatusType.PENDING)].includes(
            progress.status,
          )
            ? [...displayArchiveOrRestore(youGoalsFilter, true)]
            : [...displayArchiveOrRestore(youGoalsFilter, false)]
        }
        onClick={(option) => handleEditAndDelete(option)}
      />
    );
  };

  const isKpi = goal?.isKpi;

  const UpdateGoalComponent = (
    <UpdateGoal
      goalId={id}
      tabKind={tabKind}
      handleRevertChanges={revertChanges}
      isKpi={isKpi}
      measurement={measurement}
      currencySymbol={currencySymbol}
      youGoalsFilter={upcomingGoal ? 'upcoming' : youGoalsFilter}
      kind="goal"
      saveChanges={(e: any, type?: string) => {
        if (type === 'kpi') {
          if (assignee.id === auth?.user.id) {
            updateEditing();
          } else {
            if (!isOpen) {
              handleOpen();
              setOpenKR(true);
            }
          }
          updateGoal(e);
        } else {
          debounceUpdateGoal(e);
        }
      }}
      name={id}
      endGoal={endGoal}
      currentValue={currentValue}
      targetValue={targetValue}
      setIsUpdated={setIsUpdated}
      {...progress}
    />
  );
  const excludeGoalInsight =
    (tabKind && ['team'].includes(tabKind)) ||
    (variant && variant === 'companyReportPDF') ||
    ['archived', 'upcoming'].includes(youGoalsFilter) ||
    upcomingGoal;

  const {status} = goal || {};

  const updateButtonDropdown =
    [assignee?.reviewer, assignee?.id].includes(currentUser?.id) ||
    (variant === 'all-objectives' && auth.user.role === 'admin');

  const showDropdown =
    managerEdit &&
    currentUser.isReviewer &&
    [assignee?.reviewer, assignee?.id].includes(currentUser?.id) &&
    !['completed', 'in_completed'].includes(status);

  const hideUpdateButton =
    (tabKind && tabKind === 'team') ||
    (variant &&
      ['companyReportPDF', 'companyTeams', 'all-objectives'].includes(variant));

  const hideDropdownButton =
    (tabKind && tabKind === 'team') ||
    (variant && ['companyReportPDF', 'companyTeams'].includes(variant));

  const handleUpdateClose = () => {
    setIsUpdate(false);
    if (isOpen) handleOpen();
    if (openKR) setOpenKR(false);
    if (!changesSaved) {
      updatePerformance(id, 'type', initialGoalPerformance);
      addGoalFields(id, 'value', kpiInitialValue.value);
      addGoalFields(id, 'context', kpiInitialValue.context);
    }
    setYouGoalsFilterUpdate(false);
  };

  const changeInValue = goal?.isKpi
    ? getLastActivity?.percentageChangeInValue
    : getLastActivity?.changeInValue;

  return (
    <Wrapper>
      {showEditModal && (
        <CustomIconModal
          open={open}
          onClose={() => {
            setOpen(false);
            setShowEditModal(false);
          }}
          body={
            managerEdit ? (
              ''
            ) : (
              <Body1 kind="textBody" align="center">
                Click{' '}
                <span style={{fontWeight: 700, color: 'black'}}>“confirm”</span>{' '}
                to continue.
              </Body1>
            )
          }
          onClick={() =>
            managerEdit
              ? handleManagerEdit()
              : navigate(`/edit-goal${goal?.isKpi ? '?kpi=true' : ''}`, {
                  // replace: true,
                  state: {
                    id,
                    data: goals
                      ? JSON.stringify(goals.get(id))
                      : JSON.stringify(fetchedGoals.get(id)),
                  },
                })
          }
          title={`Edit ${goalFramework}?`}
          description={` This will remove current update made on the ${goalFramework}
              progress`}
        />
      )}
      {showDuplicateGoalModal && (
        <CustomIconModal
          open={open}
          onClose={() => {
            setOpen(false);
            setShowDuplicateGoalModal(false);
          }}
          onClick={() => {
            goalsState.setShowGoalForm(true);
            navigate(
              `/create-goal?duplicate_goal=true${
                goal?.isKpi ? '&kpi=true' : ''
              }`,
              {
                replace: true,
                state: {
                  id,
                  data: goals
                    ? JSON.stringify(goals.get(id))
                    : JSON.stringify(fetchedGoals.get(id)),
                },
              },
            );
          }}
          title={`Duplicate ${goalFramework}?`}
          description={
            <Body1 align="center" kind="textBody">
              You are about to duplicate{' '}
              <span style={{color: '#585ADF', fontWeight: 500}}>{title}</span> ?
            </Body1>
          }
        />
      )}
      <StyledContainer
        isUpdate={isUpdate}
        tabKind={
          variant && variant === 'companyReportPDF'
            ? 'companyReportPDF'
            : tabKind
        }>
        {' '}
        {tabKind !== 'youcheckins' && (
          <DesktopWrapper>
            <EditableGoalTitle
              kind={kind}
              title={title}
              goalType={goalType}
              hasKeyResults={hasKeyResults}
              goalGroups={goal.group}
              isOpen={isOpen}
              hasAlignedGoals={goal.alignedGoals?.length > 0}
              isGoalAligned={goal?.goalAlignment}
              screen={'desktop'}
              onClick={handleOpen}
              variant={variant}
              tabKind={tabKind}
            />
          </DesktopWrapper>
        )}
        {tabKind !== 'youcheckins' && (
          <StyledEndWrapper
            kind={kind}
            style={{
              paddingLeft: handleStyledEndPadding(),
            }}
            isUpdate={isUpdate}
            tabKind={
              variantType && variantType === 'make-review'
                ? 'review'
                : variant && variant === 'companyReportPDF'
                ? 'companyReportPDF'
                : variant && variant === 'companyTeams'
                ? 'companyTeams'
                : tabKind
            }>
            {variant && variant === 'companyReportPDF' ? (
              <NameWrapper>
                <span>{users[0].name}</span>
              </NameWrapper>
            ) : (
              <AvatarWrapper>
                <Avatars items={users} total={totalUsers} />
              </AvatarWrapper>
            )}

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}>
              {excludeGoalInsight ? null : (
                <GoalViewInsights
                  onClick={() => viewGoalInsight(goal.id, goal.isKpi)}
                  onHover
                  variant={variant}
                  userIsMentioned={userIsMentioned}
                />
              )}
              <ProgressWrapper
                style={{
                  paddingLeft: variant === 'companyTeams' ? '50px' : '5px',
                  marginRight: 10,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <ProgressTracking />
              </ProgressWrapper>
              {showDropdown ? (
                <Dropdown
                  dropdownItemStyle={{whiteSpace: 'nowrap'}}
                  menu={[
                    {
                      content: `Edit ${goalFramework}`,
                      id: 'edit',
                    },
                    {
                      content: `Delete ${goalFramework}`,
                      id: 'delete',
                    },
                    {
                      content: `End ${goalFramework}`,
                      id: 'end',
                    },
                  ]}
                  onClick={(option) => handleEditAndDelete(option)}
                />
              ) : null}

              {hideUpdateButton ? null : (
                <UpdateWrapper>
                  <ActionWrapper>
                    {!upcomingGoal &&
                      (isKpi ? isKpi : !isUpdate) &&
                      !openKR &&
                      computeShowSaveChanges() && (
                        <UpdateButton title="Save changes" />
                      )}
                    {!isUpdate &&
                      (youGoalsFilter === 'upcoming' || upcomingGoal) && (
                        <Button
                          kind="secondary"
                          width="sm"
                          aria-label="update key metric"
                          title="Update progress"
                          type="button"
                          disabled={assignee.id !== auth?.user.id}
                          style={{padding: '8px 16px'}}
                          onClick={(e) => {
                            updateEditing();
                            updateGoal(e);
                            setYouGoalsFilterUpdate(true);
                          }}>
                          View
                        </Button>
                      )}
                    {!isUpdate && youGoalsFilter === 'archived' && (
                      <Button
                        kind="secondary"
                        width="sm"
                        aria-label="update key metric"
                        title="Update progress"
                        type="button"
                        disabled={
                          assignee.id !== auth?.user.id &&
                          auth?.user.role !== 'admin'
                        }
                        style={{padding: '6px 16px'}}
                        onClick={(e) => {
                          updateEditing();
                          updateGoal(e);
                          setYouGoalsFilterUpdate(true);
                        }}>
                        View
                      </Button>
                    )}
                    &nbsp; &nbsp;
                    {(isUpdate || openKR) && (
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <BackAction
                          icon={<CancelIcon />}
                          onClick={() => {
                            handleUpdateClose();
                          }}
                          title="Close"
                          style={{
                            padding: '8px 16px',
                            display: 'flex',

                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                          width="sm"
                        />
                        &nbsp; &nbsp;
                        {/* 
                    // Todo: edit or delete depending on the selected menu item
                  */}
                      </div>
                    )}
                  </ActionWrapper>
                </UpdateWrapper>
              )}
              {hideDropdownButton ? null : updateButtonDropdown ? (
                <UpdateDropdown />
              ) : (
                <HorizontalSpacer size="24px" />
              )}
            </div>
          </StyledEndWrapper>
        )}
      </StyledContainer>
      {(tabKind && tabKind === 'team') || (
        <AnimatePresence>
          {isUpdate &&
            tabKind !== 'youcheckins' &&
            [
              String(StatusType.INPROGRESS),
              String(StatusType.PENDING),
            ].includes(progress.status) && (
              <UpdateGoalWrapper>{UpdateGoalComponent}</UpdateGoalWrapper>
            )}
        </AnimatePresence>
      )}

      <StyledEndWrapper3 kind={tabKind}>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          style={{width: '100%'}}>
          <EditableGoalTitle
            kind={kind}
            goalGroups={goal.group}
            title={title}
            goalType={goalType}
            hasAlignedGoals={goal.alignedGoals?.length > 0}
            isGoalAligned={goal?.goalAlignment}
            screen={'mobile'}
            hasKeyResults={hasKeyResults}
            isOpen={isOpen}
            onClick={handleOpen}
            variant={variant}
            tabKind={tabKind}
          />

          <div>
            <FlexRowEnd>
              {hideDropdownButton ||
              showObjectiveProgress ||
              !updateButtonDropdown ? null : (
                <UpdateDropdown />
              )}
            </FlexRowEnd>
            <div style={{marginTop: '5px'}}>
              <Avatars items={users} total={totalUsers} />
            </div>
          </div>
        </Flex>
        <Flex alignItems="center" justifyContent="space-between">
          <Flex alignItems="center">
            <ResponsiveProgressTracker kind={tabKind}>
              <ProgressTracking />
            </ResponsiveProgressTracker>

            {excludeGoalInsight ? null : (
              <GoalViewInsights
                onClick={() => viewGoalInsight(goal.id, goal.isKpi)}
                variant={variant}
                userIsMentioned={userIsMentioned}
              />
            )}
          </Flex>
          {hideUpdateButton ? null : (
            <div
              style={{
                marginTop: '12px',
                alignItems: 'center',
                display: 'flex',
              }}>
              <div>
                {!isUpdate && !openKR && (
                  <>
                    {showObjectiveProgress ? (
                      <FlexRow style={{marginBottom: '4px'}}>
                        {goal?.isKpi && (
                          <FlexRow>
                            <Body2 style={{fontSize: '12px'}} kind="textMedium">
                              {' '}
                              {isValuePositive(getLastActivity?.changeInValue)
                                ? `+${getLastActivity?.changeInValue}`
                                : `${getLastActivity?.changeInValue || 0}`}
                            </Body2>
                            <HorizontalSideRule
                              size="16px"
                              style={{margin: '0px 8px'}}
                            />
                          </FlexRow>
                        )}
                        <ArrowIcons value={changeInValue || 0} />
                        <Body2 kind={formatColor(changeInValue)}>
                          {changeInValue || 0}%
                        </Body2>
                        <HorizontalSideRule
                          size="16px"
                          style={{margin: '0px 8px'}}
                        />
                        <Body2 kind="textBody">Last 7 days</Body2>
                      </FlexRow>
                    ) : (
                      <UpdateButton title="Save Changes" />
                    )}
                  </>
                )}
              </div>

              {(isUpdate || openKR) && (
                <Flex
                  alignItems="center"
                  justifyContent="flex-end"
                  style={{marginLeft: 7}}>
                  <CustomCancelButton
                    openKR={openKR}
                    onClick={() => {
                      handleUpdateClose();
                    }}>
                    Close
                    <CancelIcon />
                  </CustomCancelButton>
                </Flex>
              )}
            </div>
          )}
        </Flex>
      </StyledEndWrapper3>

      {(tabKind && tabKind === 'team') || (
        <AnimatePresence>
          {isUpdate &&
            tabKind === 'youcheckins' &&
            [
              String(StatusType.INPROGRESS),
              String(StatusType.PENDING),
            ].includes(progress.status) && (
              <UpdateGoalWrapper>{UpdateGoalComponent}</UpdateGoalWrapper>
            )}
        </AnimatePresence>
      )}
      {tabKind && tabKind === 'team' ? null : (
        <AnimatePresence>
          {isUpdate &&
            [
              String(StatusType.INPROGRESS),
              String(StatusType.PENDING),
            ].includes(progress.status) && (
              <UpdateGoalWrapper2 kind={tabKind}>
                {UpdateGoalComponent}
              </UpdateGoalWrapper2>
            )}
        </AnimatePresence>
      )}
    </Wrapper>
  );
});
EditableGoalHeader.displayName = 'EditableGoalHeader';
