import {
  MouseEvent as ReactMouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {useEventListener} from './event-listener';
import {useMounted} from './mounted';

export function useDropDown(noOutSideListener?: boolean) {
  const ref = useRef(null);
  const mounted = useMounted();

  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(
    (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (event?.type === 'mousedown' && event?.button !== 0) return;
      event?.stopPropagation();
      event?.preventDefault();

      setOpen(!open);
    },
    [open],
  );

  const close = useCallback(() => {
    setOpen(false);
  }, []);

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  const handleDocumentClick = useCallback(
    (event: MouseEvent) => {
      if (mounted?.current) {
        if (!(ref.current! as any)?.contains(event?.target)) {
          if (open) {
            if (!noOutSideListener || noOutSideListener === undefined) {
              close();
            }
          }
        }
      }
    },
    [mounted, open, noOutSideListener, close],
  );

  const escapeListener = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        close();
      }
    },
    [close],
  );

  const useOutsideAlerter = (ref: any) => {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (!noOutSideListener) {
            handleClose();
          }
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  useOutsideAlerter(ref);

  // useEventListener('click', handleDocumentClick, document);
  useEventListener('touchend', handleDocumentClick, document);
  useEventListener('keyup', escapeListener, document);

  return {
    handleOpen,
    handleClose,
    ref,
    open,
  };
}
