import {useEffect, useCallback, useState} from 'react';
import {useStoreContext} from '../../../../store/store-context';
import {CommentsBodyStyle, UserHeader} from '../comments-style';
import {Body2} from '../../../atoms/typography';
import dayjs from 'dayjs';
import {DropdownVertical} from '../../../molecules/dropdown-vertical';
import {DropdownItem} from '../../../../pages/dashboard/admin/admin-components/admin-people/admin-group-type/admin-group-type-style';
import isToday from 'dayjs/plugin/isToday';
import {Deletedata} from '../../../../utils/firebase-request';
import {Avatar} from '../../../atoms/avatar';
import {EmptyPlaceholder} from '@ui/atoms/empty-placeholder';
import {cloudinary} from '@assets/images/cloudinary';
import {MoreVerticalIcon2} from '@ui/atoms/icons/more-vertical';
import isYesterday from 'dayjs/plugin/isYesterday';
import {FailedComment} from './comment-failed';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import {getFromBetween, getName} from '../../../../utils/get-between';
import {useRef} from 'react';
import {authStore} from '@store/stores/auth-store';
const {emptyGoals: emptyGoal} = cloudinary;

const EmptyQuestions = {
  image: {
    alt: 'alt text',
    shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
    src: emptyGoal,
    style: {height: '30%', width: '30%'},
  },

  subtitle: '',
  title: `No comments added yet.`,
};

export function CommentsBody({
  comments,
  goalId,
  editComments,
  path,
  handleEditComments,
  mentions,
}: any) {
  const {
    usersStore: {users, currentUser},
  } = useStoreContext();
  const myRef: any = useRef(null);

  const [redirectComment] = useState('');
  const [getMentionComment, setMentionedComment] = useState<any>([]);
  const refactorComment = (comment: any) => {
    const results = getFromBetween.get(comment?.comment, '_(', ')_');

    let text = comment?.comment;
    if (comment.edited) {
      text = `${comment.comment} <span class='edited'>(edited)</span>`;
    } else {
      text = comment.comment;
    }

    results?.forEach((id: any) => {
      text = text.replace(
        `_(${id})_`,
        `<span class="mention">@${getName(id, users)}</span>`,
      );
    });

    return text;
  };

  const executeScroll = () =>
    myRef?.current?.scrollIntoView({behavior: 'smooth', block: 'center'});

  const items = useCallback(() => {
    if (!!comments) {
      return Object.entries(comments);
    } else {
      return [];
    }
  }, [comments]);

  useEffect(() => {
    if (myRef !== null) {
      executeScroll();
    }
  }, [myRef, redirectComment]);

  dayjs.extend(isToday);
  dayjs.extend(isYesterday);
  dayjs.extend(advancedFormat);
  const dates = new Set();
  const renderDate = (chat: any, dateNum: any) => {
    dates.add(dateNum);
    if (dayjs(chat).isToday()) {
      return (
        <div
          style={{
            width: '111px',
            height: '26px',
            border: '1px solid #EDEDF2',
            borderRadius: '6px',
            background: '#F6F6F8',

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Body2 kind="textMedium" style={{fontSize: '12px', fontWeight: 500}}>
            Today
          </Body2>
        </div>
      );
    } else if (dayjs(chat).isYesterday()) {
      return (
        <div
          style={{
            width: '111px',
            height: '26px',
            border: '1px solid #EDEDF2',
            borderRadius: '6px',
            background: '#F6F6F8',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Body2 kind="textMedium" style={{fontSize: '12px', fontWeight: 500}}>
            Yesterday
          </Body2>
        </div>
      );
    } else {
      return (
        <div
          style={{
            width: '111px',
            height: '26px',
            border: '1px solid #EDEDF2',
            borderRadius: '6px',
            background: '#F6F6F8',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Body2 kind="textMedium" style={{fontSize: '12px', fontWeight: 500}}>
            {dayjs(chat).format('Do MMM YYYY')}
          </Body2>
        </div>
      );
    }
  };

  const getUserRef = useCallback(() => {
    const res = items()
      .reverse()
      .filter((item: any) => item[1]?.mentions?.includes(currentUser.id));
    setMentionedComment(res[0]);
  }, [items, currentUser.id]);

  useEffect(() => {
    getUserRef();
  }, [getUserRef]);

  return (
    <div>
      {items()?.length > 0 ? (
        items()
          .reverse()
          .map((item: any, idx: any) => (
            <div style={{paddingTop: '5px', paddingBottom: '12px'}}>
              <div
                style={{
                  display: 'flex',
                  border: '0.5px solid #EDEDF2',
                  marginTop: '7px',
                  width: dates.has(
                    dayjs(item[1].createdAt).format('Do MMM YYYY'),
                  )
                    ? '95%'
                    : '100%',
                  margin: dates.has(
                    dayjs(item[1].createdAt).format('Do MMM YYYY'),
                  )
                    ? '5px 20px 5px 20px'
                    : '5px 0px 5px 0px',
                  height: '0px',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                {dates.has(dayjs(item[1].createdAt).format('Do MMM YYYY'))
                  ? ''
                  : renderDate(
                      item[1].createdAt,
                      dayjs(item[1].createdAt).format('Do MMM YYYY'),
                    )}
              </div>
              {mentions?.includes(currentUser.id)
                ? getMentionComment?.length > 0 &&
                  getMentionComment[0] === item[0] && (
                    <>
                      <div ref={myRef}></div>
                    </>
                  )
                : ''}
              {redirectComment === item[0] && <div ref={myRef}></div>}
              <div style={{padding: '10px 12px 4px 12px'}}>
                <UserHeader>
                  {users
                    ?.filter((user: any) => user?.id === item[1].commentBy)
                    .map((user: any) => (
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <Avatar
                          size="md"
                          name={
                            user?.firstName && user?.lastName
                              ? user?.firstName + ' ' + user?.lastName
                              : user?.email
                          }
                          src={user?.avatar?.url}
                          userId={''}
                          tooltip={false}
                        />
                        <Body2
                          style={{
                            fontSize: '16px',
                            fontWeight: 500,
                            lineHeight: '150%',
                            marginLeft: '9px',
                          }}>
                          {user?.firstName && user?.lastName
                            ? user?.firstName + '  ' + user?.lastName
                            : user?.email}
                        </Body2>
                      </div>
                    ))}
                  {authStore.auth.user.id === item[1].commentBy && (
                    <DropdownVertical
                      collapseOnClick={true}
                      dropdownWrapperStyle={{right: 0}}
                      customIcon={<MoreVerticalIcon2 />}
                      menu={(handleClose: () => void) => (
                        <div>
                          <DropdownItem
                            onClick={() => {
                              handleEditComments(item);
                              handleClose();
                            }}>
                            Edit Comment
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              Deletedata(path, item[0]);
                              if (editComments) {
                                handleEditComments('');
                              }
                              handleClose();
                            }}>
                            Delete Comment
                          </DropdownItem>
                        </div>
                      )}
                    />
                  )}
                </UserHeader>
                <div style={{display: 'flex'}}>
                  <CommentsBodyStyle
                    style={{
                      width: '90%',
                      marginTop: '10px',
                      marginLeft: '8px',
                    }}>
                    <div style={{display: 'flex', width: '70%'}}>
                      <Body2
                        className="taggedStyle"
                        dangerouslySetInnerHTML={{
                          __html: refactorComment(item[1]),
                        }}
                      />{' '}
                    </div>
                  </CommentsBodyStyle>
                  <Body2
                    style={{fontSize: '14px ', whiteSpace: 'nowrap'}}
                    kind="textMedium">
                    {dayjs(item[1]?.createdAt).format('hh:mm a')}
                  </Body2>
                </div>
              </div>
            </div>
          ))
      ) : (
        <div
          style={{
            background: 'white',
            border: '1px solid #ededf2',
            borderRadius: '0px 0px 10px 10px',
          }}>
          <EmptyPlaceholder {...EmptyQuestions} />
        </div>
      )}
      {localStorage.getItem('failComment') ? (
        <>
          <FailedComment users={users} currentUser={goalId} />
        </>
      ) : (
        ''
      )}
    </div>
  );
}
