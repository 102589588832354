import React, {FC, useMemo, useState} from 'react';
import {observer} from 'mobx-react';
import {useStoreContext} from '@store/store-context';
import {Button} from '@ui/atoms/button';
import {CancelIcon, ChevronDownIcon} from '@ui/atoms/icons';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {Body2} from '@ui/atoms/typography';
import {DropdownCustom} from '@ui/molecules/dropdown-custom';
import {FlexRowSpaceBetween} from '@ui/style/styles';
import {getGroupTypeAndGroup} from '@utils/get-group-grouptype';
import {userName} from '@utils/user-name';
import Filter from '@pages/dashboard/goals/objective-filter';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {AddFilter} from './add-filter';
import {ReportingAdditionalFilters} from './additional-filter';
import {BaseFilterProps} from './interface';
import {CustomFilterMultiSelect} from './custom-multi-select';

export const ReportingBaseFilter: FC<BaseFilterProps> = observer(
  ({
    dateRange,
    setDateRange,
    selectedGroups,
    type,
    selectedTeams,
    selectedMembers,
    children,
    onChangeGroups,
    additionalFilters,
    setBreakdownBy,
    feedbackFilter,
    onChangeFeedbackFilter,
    handleMeetingFilter,
    onChangeGoalProgress,
    onChangeGoalState,
    onChangeGoalType,
    setAdditionalFilters,
    onChangeTeams,
    onChangeMembers,
    breakdownBy,
    disableAdditionalFilter,
  }) => {
    const {
      groupStore: {groups},
      groupTypeStore: {groupType},
      authStore: {auth},
      usersStore: {users},
    } = useStoreContext();

    const [filterType, handleFilterTypeChange] = useState<
      'groups' | 'teams' | ''
    >('');

    const selectedGroupsId = selectedGroups.map((group) => group.value);

    const selectedTeamsId = selectedTeams.map((team) => team.value);

    const computeUsers = useMemo(() => {
      if (filterType === 'groups' && selectedGroupsId.length > 0) {
        return users.filter((user) =>
          user.groups?.some((grp) => selectedGroupsId.includes(grp.id)),
        );
      } else if (filterType === 'teams' && selectedTeams.length > 0) {
        return users.filter((user) =>
          selectedTeamsId.includes(user.reviewer?.id),
        );
      } else {
        return users;
      }
    }, [
      filterType,
      selectedGroupsId,
      selectedTeams.length,
      selectedTeamsId,
      users,
    ]);

    const computeSelectedGroups = useMemo(() => {
      return selectedGroups.map((group) => ({
        ...group,
        ...getGroupTypeAndGroup(groups, groupType, group.value),
      }));
    }, [selectedGroups, groupType, groups]);

    const computeGroups = useMemo(() => {
      return groupType.map((grpType) => ({
        name: grpType.name,
        data: groups
          .filter((group) => group.groupType === grpType.id)
          .map((group) => ({
            label: group.name,
            value: group.id,
          })),
      }));
    }, [groupType, groups]);

    const teamLabel = (user: {firstName: string; email: string}) =>
      user.firstName ? `${user.firstName}'s Team` : `${user.email}'s Team`;

    const teamsOptions = useMemo(() => {
      const myTeamOption = {value: auth?.user?.id, label: 'My Team'};

      if (auth.user.role !== 'admin') return [myTeamOption];

      return [
        myTeamOption,
        ...users
          .filter((user) => user.isReviewer && user.id !== auth.user.id)
          .map((user) => ({value: user.id, label: teamLabel(user)})),
      ];
    }, [users, auth?.user?.role, auth?.user?.id]);

    const resetFilter = (filter: string) => {
      setAdditionalFilters(
        additionalFilters.filter((prevFilter) => filter !== prevFilter),
      );

      if (filter === 'state') {
        onChangeGoalState('');
      }

      if (filter === 'progress') {
        onChangeGoalProgress([]);
      }
      if (filter === 'type') {
        onChangeGoalType('');
      }
      if (filter === 'sentiment') {
        onChangeFeedbackFilter({
          ...feedbackFilter,
          sentiment: '',
          count: '',
        });
      }
      if (filter === 'meetings') {
        handleMeetingFilter({
          meetingType: '',
          count: '',
        });
      }
      if (filter === 'values') {
        onChangeFeedbackFilter({
          ...feedbackFilter,
          values: [],
        });
      }
      if (filter === 'breakdown') {
        setBreakdownBy('');
        handleFilterTypeChange('');
      }
    };

    return (
      <div className="rounded-[10px] border border-borderLight min-h-[74vh] w-full bg-white">
        <div className="border-b border-b-[#ededf2] p-4 w-full ">
          <Filter
            showDateRange
            dateRange={dateRange}
            setDateRange={setDateRange}
            _height="36px"
            _width="100%"
            reduceOnWindowSize
            buttonStyle={{width: '100%'}}
            removeUserFilter
          />
        </div>
        <div className="border-b border-b-[#ededf2] p-4">
          <FlexRowSpaceBetween className="mb-2">
            <Body2 weight="bold">Showing people in</Body2>
            <DropdownCustom
              collapseOnClick={true}
              menu={(handleClose: () => void) => (
                <div>
                  <div>
                    <DropdownItem
                      onClick={() => {
                        handleClose();
                        handleFilterTypeChange('groups');
                        onChangeTeams([]);
                      }}>
                      Groups
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        handleClose();
                        handleFilterTypeChange('teams');
                        onChangeGroups([]);
                      }}>
                      Teams
                    </DropdownItem>
                  </div>
                </div>
              )}
              customComponent={(
                handleOpen: (event: any) => void,
                open: boolean,
              ) => (
                <Button
                  onClick={handleOpen}
                  width="sm"
                  disabled={!!breakdownBy || disableAdditionalFilter}
                  kind="secondary"
                  style={{padding: '6px 12px', borderRadius: '6px'}}
                  className="py-2 px-3 flex flex-row justify-between items-center capitalize">
                  <Body2
                    kind={filterType ? 'textDark' : 'textBody'}
                    weight={filterType ? 'semibold' : 'regular'}>
                    {filterType || 'All'}
                  </Body2>

                  {filterType ? (
                    <div
                      className="cursor-pointer "
                      onClick={() => {
                        handleFilterTypeChange('');
                        onChangeTeams([]);
                        onChangeGroups([]);
                      }}>
                      <CancelIcon
                        style={{marginLeft: '14px', strokeWidth: 1.5}}
                      />
                    </div>
                  ) : (
                    <ChevronDownIcon
                      style={{
                        marginLeft: 10,
                        stroke: '#1E1E2F',
                        transform: !open ? 'rotate(0deg)' : 'rotate(180deg)',
                      }}
                    />
                  )}
                </Button>
              )}
              customDropdownWrapperStyles={{
                top: '130%',
                right: 0,
                width: '190px',
              }}
            />
          </FlexRowSpaceBetween>

          {filterType === 'groups' && (
            <CustomFilterMultiSelect
              options={computeGroups}
              onChange={onChangeGroups}
              placeholderTerm="group"
              type="group"
              selectedOptions={computeSelectedGroups}
            />
          )}
          {filterType === 'teams' && (
            <CustomFilterMultiSelect
              options={teamsOptions}
              onChange={onChangeTeams}
              searchPlaceholder="Search for teams"
              removeSearch
              placeholderTerm={'team'}
              selectedOptions={selectedTeams}
            />
          )}
        </div>

        <div className="p-4 border-b border-borderLight">
          <Body2 weight="bold">People</Body2>
          <VerticalSpacer size="16px" />
          <CustomFilterMultiSelect
            options={computeUsers.map((user) => ({
              value: user.id,
              label: userName(user),
            }))}
            onChange={onChangeMembers}
            disabled={!!breakdownBy}
            placeholderTerm={'person'}
            selectedOptions={selectedMembers}
          />
        </div>

        {children}

        {additionalFilters.map((filter) => (
          <ReportingAdditionalFilters
            key={filter}
            groups={computeGroups}
            filter={filter}
            onChangeGoalProgress={onChangeGoalProgress}
            onChangeGoalType={onChangeGoalType}
            feedbackFilter={feedbackFilter}
            onChangeFeedbackFilter={onChangeFeedbackFilter}
            resetFilter={resetFilter}
            type={type}
            handleMeetingFilter={handleMeetingFilter}
            onChangeGoalState={onChangeGoalState}
            setBreakdownBy={setBreakdownBy}
            handleFilterTypeChange={handleFilterTypeChange}
          />
        ))}
        <AddFilter
          disableAdditionalFilter={disableAdditionalFilter}
          type={type}
          filters={additionalFilters}
          updateFilters={(value) => {
            setAdditionalFilters([...additionalFilters, value]);
            if (value === 'type') {
              handleFilterTypeChange('');
            }
          }}
        />
      </div>
    );
  },
);
