import {AnimatePresence} from 'framer-motion';
import {memo, useState} from 'react';
import {parseCurrencyValue} from '../../../../../../../constants';
import {MeasurementType} from '../../../../../../../hooks';
import {authStore} from '../../../../../../../store/stores/auth-store';
import {Avatar} from '../../../../../../../ui/atoms/avatar';
import {
  ColorIconGreen,
  ColorIconOrange,
  ColorIconRed,
} from '../../../../../../../ui/atoms/color-icons';
import {EyeIcon, IndicatorIcon} from '../../../../../../../ui/atoms/icons';
import {Body2} from '../../../../../../../ui/atoms/typography';
import {Avatars} from '../../../../../../../ui/molecules/avatars';
import {EditableGoalTitle} from '../../../../../../../ui/molecules/goal/editable-title';
import {ProgressBar} from '../../../../../../../ui/molecules/progress-bar';
import {ProgressTracker} from '../../../../../../../ui/molecules/progress-tracker';
import {Tooltip} from '../../../../../../../ui/molecules/tooltip';
import {TooltipIconBlack} from '../../../../../../../ui/molecules/tooltip/tooltip';
import {UserListCard} from '../../../../../../../ui/molecules/user/list-card';
import {goalDueDate2, goalDueDate} from '../../../../../../../utils/date';
import {
  getPercentCompletedKeyResult,
  getPercentCompletedGoal,
} from '../../../../../../../utils/proportions';
import {
  INestedViewAligneeComponent,
  INestedViewGoal,
} from './nestedview.interface';
import {
  SecondWrapperChildGoals,
  Content,
  NestedDivider,
  SecondWrapperChildNestedKeyResults,
  Wrapper,
  ProgressWrapper,
  AvatarWrapper,
} from './nestedview.styles';
var commaNumber = require('comma-number');

const NestedViewAligneeComponent = ({
  goal,
  isExpand,
  loading,
  variant,
  allGroups,
}: INestedViewAligneeComponent) => {
  return (
    <NestedViewGoal
      goal={goal}
      isExpand={isExpand}
      type="child"
      loading={loading}
      variant={variant}
      allGroups={allGroups}
    />
  );
};

export const NestedViewGoal = memo(
  ({goal, isExpand, type, loading, variant, allGroups}: INestedViewGoal) => {
    const [isOpen, setIsOpen] = useState<any>(false);
    const progress = {
      due: goalDueDate(goal.endDate),
      percent: getPercentCompletedGoal(goal),
      state: `${getPercentCompletedGoal(goal)}%`,
      // @ts-ignore
      type: goal.performance,
      status: goal.status,
      endDate: goal.endDate,
    };
    const avat = {
      name:
        goal.assignee?.firstName && goal.assignee?.lastName
          ? `${goal.assignee?.firstName} ${goal.assignee?.lastName}`
          : goal.assignee?.email,
      src: goal.assignee?.avatar?.url,
      id: goal.assignee?.id,
    };

    const keyResultOwner: any = () => {
      if (goal.keyResults?.length > 0) {
        const krs = goal.keyResults.map((item: any) => item.assignee);
        const assigneeGoals = [
          ...Array.from(
            new Map(krs.map((item: any) => [item['id'], item])).values(),
          ),
        ];
        const EachAssignee = assigneeGoals.map((kr: any) => {
          return {
            name: kr.firstName ? `${kr.firstName} ${kr.lastName}` : kr.email,
            src: kr.avatar ? kr.avatar.url : '',
            id: kr.id,
            reviewer: kr.reviewer,
          };
        });
        let goalandKeyResultsAssignees = [avat, ...EachAssignee];
        const ites = goalandKeyResultsAssignees.map((item: any) => item);
        const assigneeGoalsKeyResults = [
          ...Array.from(
            new Map(ites.map((item: any) => [item['id'], item])).values(),
          ),
        ];
        return assigneeGoalsKeyResults;
      }
    };
    const users = avat
      ? goal.keyResults?.length > 0
        ? keyResultOwner()
        : [avat]
      : [];
    const computeGoalType = (type?: string) => {
      if (type && type === 'child') {
        return goal.goalType.toLowerCase() === 'group'
          ? `${allGroups?.get(goal.group)?.groupTypeName} - ${
              allGroups?.get(goal.group)?.name
            }`
          : goal.goalType === 'company'
          ? 'Company-wide'
          : goal.goalType;
      } else
        return goal.goalType.toLowerCase() === 'group'
          ? `${goal.groupType?.name} - ${goal.groupName}`
          : goal.goalType === 'company'
          ? 'Company-wide'
          : goal.goalType;
    };
    return (
      <>
        <Wrapper
          type={type}
          variant={variant}
          hasKeyResults={goal.keyResults?.length > 0}
          isOpen={isExpand || isOpen === goal.id}>
          <SecondWrapperChildGoals
            variant={variant}
            isBinary={
              goal.measurement.unit.toUpperCase() === MeasurementType.BINARY
            }>
            <EditableGoalTitle
              kind="admin"
              nestedIcon={true}
              title={goal.name}
              goalType={computeGoalType(type)}
              subTitle={goalDueDate2(goal.updatedAt)}
              hasKeyResults={goal.keyResults?.length > 0}
              hasAlignedGoals={goal.alignedGoals?.length > 0}
              isOpen={isExpand || isOpen === goal.id}
              onClick={() => setIsOpen(goal.id === isOpen ? null : goal.id)}
              variant={variant}
              view="stacked"
            />
            {variant === 'companyTeams' ? (
              <AvatarWrapper>
                <Avatars items={users} total={users?.length} />
              </AvatarWrapper>
            ) : (
              <div
                className="tooltip_container"
                style={{
                  alignItems: 'center',
                  paddingRight: '24px',
                }}>
                <UserListCard
                  kind="admin"
                  type="secondary"
                  tooltip={true}
                  avatar={goal.assignee.avatar ? goal.assignee.avatar.url : ''}
                  reviewer={goal.assignee.reviewer}
                  name={
                    goal.assignee.firstName && goal.assignee.lastName
                      ? `${goal.assignee.firstName} ${goal.assignee.lastName}`
                      : `${goal.assignee.email.substr(0, 5)}... (pending)`
                  }
                  userId={goal.assignee.id}
                />
                {goal.assignee.firstName &&
                  `${goal.assignee?.firstName} ${goal.assignee?.lastName}`
                    .length > 20 && (
                    <span style={{marginTop: '-75px'}} className="tooltip">
                      {`${goal.assignee?.firstName} ${goal.assignee?.lastName}`}
                    </span>
                  )}
              </div>
            )}
            {variant === 'companyTeams' ? (
              <ProgressWrapper>
                <ProgressTracker
                  variant={variant}
                  view={'stacked'}
                  {...progress}
                />
              </ProgressWrapper>
            ) : (
              <div className="tooltip_container">
                <span className="ellipsis">{goal.assignee.department}</span>
                {goal.assignee.department &&
                  goal.assignee.department.length > 20 && (
                    <span className="tooltip">{goal.assignee.department}</span>
                  )}
              </div>
            )}
            {variant !== 'companyTeams' && (
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: 'max-content',
                }}>
                {goal.performance === 'on_track' && (
                  <span>
                    <ColorIconGreen margin="6" />
                    {getPercentCompletedGoal(goal)}%{' '}
                    <span style={{color: '#5f5f8c'}}>
                      {goalDueDate(goal.endDate)}
                    </span>
                  </span>
                )}
                {goal.performance === 'behind' && (
                  <span>
                    <ColorIconOrange margin="6" />
                    {getPercentCompletedGoal(goal)}%{' '}
                    <span style={{color: '#5f5f8c'}}>
                      {goalDueDate(goal.endDate)}
                    </span>
                  </span>
                )}
                {goal.performance === 'at_risk' && (
                  <span>
                    <ColorIconRed margin="6" />
                    {getPercentCompletedGoal(goal)}%{' '}
                    <span style={{color: '#5f5f8c'}}>
                      {goalDueDate(goal.endDate)}
                    </span>
                  </span>
                )}
              </span>
            )}
            {variant &&
              variant === 'companyTeams' &&
              goal.measurement.unit.toUpperCase() !==
                MeasurementType.BINARY && (
                <span
                  style={{
                    color: '#5f5f8c',
                    display: 'inline-flex',
                    alignItems: 'center',
                    overflow: 'elipsis',
                  }}>
                  {(function () {
                    const progressText = `${
                      goal.measurement.unit.toUpperCase() ===
                        MeasurementType.CURRENCY && goal.measurement?.symbol
                        ? parseCurrencyValue(goal.measurement?.symbol)
                        : goal.measurement.unit.toUpperCase() ===
                            MeasurementType.CURRENCY &&
                          !goal.measurement?.symbol
                        ? parseCurrencyValue('')
                        : ''
                    }${goal.currentValue ? goal.currentValue : 0}${
                      goal.measurement.unit.toUpperCase() ===
                      MeasurementType.PERCENTAGE
                        ? '%'
                        : ''
                    } of ${
                      goal.measurement.unit.toUpperCase() ===
                        MeasurementType.CURRENCY && goal.measurement?.symbol
                        ? parseCurrencyValue(goal.measurement?.symbol)
                        : goal.measurement.unit.toUpperCase() ===
                            MeasurementType.CURRENCY &&
                          !goal.measurement?.symbol
                        ? parseCurrencyValue('')
                        : ''
                    }${goal.targetValue}${
                      goal.measurement.unit.toUpperCase() ===
                      MeasurementType.PERCENTAGE
                        ? '%'
                        : ''
                    }`;

                    return (
                      <>
                        <span>{'('}</span>
                        <Tooltip
                          text={progressText}
                          tooltipBody={progressText}
                          maxLength={6}
                        />
                        <span>{progressText.length > 6 ? ' ...)' : ')'}</span>
                      </>
                    );
                  })()}
                </span>
              )}
          </SecondWrapperChildGoals>
        </Wrapper>
        <div
          style={{
            width: '100%',
            background: '#fafafa',
            borderLeft: '1px solid #EDEDF2',
          }}>
          <AnimatePresence>
            {!!goal.keyResults &&
            goal.keyResults?.length > 0 &&
            (isOpen === goal.id || isExpand) ? (
              <Content
                variant={variant}
                initial={{
                  height: '0px',
                  opacity: 0,
                  background: '#fafafa',
                  padding: '0px',
                }}
                animate={{
                  height: 'auto',
                  opacity: 1,
                  background: '#fafafa',
                  padding: '0px',
                }}
                exit={{height: '0px', opacity: 0}}
                transition={{
                  duration: 0.3,
                }}
                key="goal-content">
                <NestedDivider variant={variant} />
                {goal &&
                  goal.keyResults?.map((keyResult: any, index: number) => (
                    <SecondWrapperChildNestedKeyResults
                      key={index}
                      variant={variant}>
                      <Body2>
                        <IndicatorIcon type="" />
                        &nbsp;{keyResult.name}
                      </Body2>
                      <AvatarWrapper>
                        <Avatar
                          tooltip={true}
                          name={
                            keyResult?.assignee.firstName &&
                            keyResult?.assignee.lastName
                              ? `${keyResult?.assignee.firstName} ${keyResult?.assignee.lastName}`
                              : `${keyResult?.assignee.email.substr(
                                  0,
                                  5,
                                )}... (pending)`
                          }
                          src={
                            keyResult?.assignee.avatar
                              ? keyResult?.assignee.avatar.url
                              : ''
                          }
                          alt={
                            keyResult?.assignee.firstName &&
                            keyResult?.assignee.lastName
                              ? `${keyResult?.assignee.firstName} ${keyResult?.assignee.lastName}`
                              : `${keyResult?.assignee.email.substr(
                                  0,
                                  5,
                                )}... (pending)`
                          }
                          userId={keyResult?.assignee.id}
                        />
                      </AvatarWrapper>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'flex-end',
                          alignItems: 'flex-end',
                          width: '250px',

                          marginLeft: '-19%',
                        }}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            width: '100%',
                          }}>
                          <ProgressBar
                            type="admin"
                            state={
                              getPercentCompletedKeyResult(
                                keyResult.startValue,
                                keyResult.targetValue,
                                keyResult.currentValue,
                              ) + '%'
                            }
                            percent={getPercentCompletedKeyResult(
                              keyResult.startValue,
                              keyResult.targetValue,
                              keyResult.currentValue,
                            )}
                            measurement={keyResult.measurement}
                            kpi={{
                              current: keyResult?.currentValue,
                              base: keyResult?.targetValue,
                              good: keyResult?.goodValue,
                              stretch: keyResult?.stretchValue,
                            }}
                          />
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                          }}>
                          {variant && variant === 'companyTeams' && (
                            <span
                              style={{
                                color: '#5f5f8c',
                                display: 'inline-flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                fontSize: '14px',

                                overflow: 'elipsis',
                              }}>
                              {(function () {
                                const progressText = `${
                                  keyResult.measurement.unit.toUpperCase() ===
                                    MeasurementType.CURRENCY &&
                                  keyResult?.measurement?.symbol
                                    ? parseCurrencyValue(
                                        keyResult?.measurement?.symbol,
                                      )
                                    : keyResult.measurement.unit.toUpperCase() ===
                                        MeasurementType.CURRENCY &&
                                      !keyResult?.measurement?.symbol
                                    ? parseCurrencyValue('')
                                    : ''
                                }${commaNumber(
                                  keyResult?.currentValue &&
                                    keyResult?.measurement?.unit !== 'binary'
                                    ? keyResult?.currentValue
                                    : keyResult?.currentValue === 1 &&
                                      keyResult.measurement?.unit === 'binary'
                                    ? 'complete'
                                    : !keyResult?.currentValue &&
                                      keyResult.measurement?.unit === 'binary'
                                    ? 'Incomplete'
                                    : keyResult?.currentValue === 0 &&
                                      keyResult.measurement?.unit === 'binary'
                                    ? 'Incomplete'
                                    : 0,
                                )}${
                                  keyResult.measurement.unit.toUpperCase() ===
                                  MeasurementType.PERCENTAGE
                                    ? '%'
                                    : ''
                                } ${
                                  !keyResult?.currentValue &&
                                  keyResult.measurement?.unit === 'binary'
                                    ? ''
                                    : keyResult?.currentValue === 1 &&
                                      keyResult.measurement?.unit === 'binary'
                                    ? ''
                                    : 'of'
                                }${' '} ${
                                  keyResult.measurement.unit.toUpperCase() ===
                                    MeasurementType.CURRENCY &&
                                  keyResult?.measurement?.symbol
                                    ? parseCurrencyValue(
                                        keyResult?.measurement?.symbol,
                                      )
                                    : keyResult.measurement.unit.toUpperCase() ===
                                        MeasurementType.CURRENCY &&
                                      !keyResult?.measurement?.symbol
                                    ? parseCurrencyValue('')
                                    : ''
                                }${commaNumber(
                                  keyResult.targetValue === 1 &&
                                    keyResult.measurement?.unit === 'binary'
                                    ? ''
                                    : keyResult.targetValue,
                                )}${
                                  keyResult.measurement.unit.toUpperCase() ===
                                  MeasurementType.PERCENTAGE
                                    ? '%'
                                    : ''
                                }`;

                                return (
                                  <>
                                    <span>{'('}</span>
                                    <span>{progressText}</span>
                                    <span>{')'}</span>
                                  </>
                                );
                              })()}
                            </span>
                          )}
                          {authStore?.auth?.user?.workspace?.framework ===
                            'kpi' && (
                            <TooltipIconBlack
                              Icon={EyeIcon}
                              parentStyles={{marginLeft: '8px'}}
                              tooltipWidthExtendedStyle={(width) => ({
                                right: `${-22 - (width - 69) / 2}px`,
                              })}
                              tooltipBody={
                                <div
                                  style={{
                                    padding: '5px 10px',
                                    whiteSpace: 'nowrap',
                                  }}>
                                  {
                                    <p>
                                      <span
                                        style={{
                                          fontWeight: 500,
                                          color: '#CECEDE',
                                        }}>
                                        Start:
                                      </span>{' '}
                                      {keyResult?.measurement?.unit?.toUpperCase() ===
                                      MeasurementType.BINARY
                                        ? 'Incomplete'
                                        : keyResult?.startValue}
                                    </p>
                                  }
                                  {
                                    <p>
                                      <span
                                        style={{
                                          fontWeight: 500,
                                          color: '#CECEDE',
                                        }}>
                                        Base:
                                      </span>{' '}
                                      {keyResult?.measurement?.unit?.toUpperCase() ===
                                      MeasurementType.BINARY
                                        ? 'Complete'
                                        : keyResult?.targetValue}
                                    </p>
                                  }
                                  {keyResult?.measurement?.unit?.toUpperCase() !==
                                    MeasurementType.BINARY &&
                                    keyResult?.goodValue && (
                                      <p>
                                        {' '}
                                        <span
                                          style={{
                                            fontWeight: 500,
                                            color: '#CECEDE',
                                          }}>
                                          Good:
                                        </span>{' '}
                                        {keyResult?.goodValue}
                                      </p>
                                    )}
                                  {keyResult?.measurement?.unit?.toUpperCase() !==
                                    MeasurementType.BINARY &&
                                    keyResult?.stretchValue && (
                                      <p>
                                        {' '}
                                        <span
                                          style={{
                                            fontWeight: 500,
                                            color: '#CECEDE',
                                          }}>
                                          Stretch:
                                        </span>{' '}
                                        {keyResult?.stretchValue}
                                      </p>
                                    )}
                                </div>
                              }
                            />
                          )}
                        </div>
                      </div>
                    </SecondWrapperChildNestedKeyResults>
                  ))}
                <NestedDivider variant={variant} />
              </Content>
            ) : null}
          </AnimatePresence>
        </div>
        <div
          style={{
            width: '100%',
            background: '#fafafa',
            paddingBottom: isOpen === goal.id ? 8 : undefined,
            borderLeft: isOpen === goal.id ? '1px solid #EDEDF2' : undefined,
            borderRight: isOpen === goal.id ? '1px solid #EDEDF2' : undefined,
            borderBottom: isOpen === goal.id ? '1px solid #EDEDF2' : undefined,
            borderBottomLeftRadius: isOpen === goal.id ? 8 : undefined,
            borderBottomRightRadius: isOpen === goal.id ? 8 : undefined,
          }}>
          <AnimatePresence>
            {goal.alignedGoals?.length > 0 &&
            (isOpen === goal.id || isExpand) ? (
              <Content
                initial={{
                  height: '0px',
                  opacity: 0,
                  background: '#fafafa',
                  padding: '0px',
                }}
                animate={{
                  height: 'auto',
                  opacity: 1,
                  background: '#fafafa',
                  padding: '0px',
                }}
                exit={{height: '0px', opacity: 0}}
                transition={{
                  duration: 0.3,
                }}
                key="aligned-goal">
                <NestedDivider />
                {goal &&
                  goal.alignedGoals?.map((goal: any, index: number) => (
                    <NestedViewAligneeComponent
                      key={goal.id}
                      goal={goal}
                      isExpand={isExpand}
                      loading={loading}
                      variant={variant}
                      allGroups={allGroups}
                    />
                  ))}
              </Content>
            ) : null}
          </AnimatePresence>
        </div>
      </>
    );
  },
);
// NestedViewgoal.displayName = 'NestedView Goal';
