import React from 'react';
import {ReportingBaseFilter} from './base-filter';
import {BaseFilterProps} from './interface';
import {PulseToggles} from '../filter-components/checkins';

interface CheckinFilterProps extends BaseFilterProps {}

export const ReportingCheckinFilter = (props: CheckinFilterProps) => {
  return (
    <ReportingBaseFilter {...props}>
      <div className="p-4 border-b border-b-[#ededf2]">
        <PulseToggles
          onChangePulse={props.onChangePulse}
          pulse={props.selectedPulse}
        />
      </div>
    </ReportingBaseFilter>
  );
};
