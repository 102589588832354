import {WrittenContainer} from './styles';
import {useStoreContext} from '@store/store-context';
import {UserListCard} from '@ui/molecules/user/list-card';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {Body2, Microcopy} from '@ui/atoms/typography';
import {Rating} from './make-review';
import {FlexRow} from '@ui/style/styles';
import styled from 'styled-components';
import {IGoalResult} from '@hooks';
import {useMemo} from 'react';
import {PillDiv} from '@ui/molecules/progress-tracker/progress-tracker';
import {HorizontalSideRule} from '@ui/atoms/spacer/spacer';
import dayjs from 'dayjs';
import {ifProp} from 'styled-tools';

const MetricDetail = styled.div`
  padding: 4px 8px;
  border-radius: 8px;
  background: var(--background-dark, #f6f6f8);
  width: fit-content;
`;
const GoalView = styled.div<{showBorder: boolean}>`
  border-bottom: ${ifProp('showBorder', '1px solid #EDEDF2', '')};
  padding-bottom: 16px;
  margin-bottom: ${ifProp('showBorder', '16px', '')};
  width: 75%;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;
export const WrittenReview = ({
  review,
  isValue,
  goals,
  question,
}: {
  review: {
    reviewerType: string;
    user: string;
    reviewer: string;
    competenceRatings: Rating[];
    valueRatings: Rating[];
    id: string;
  };
  goals?: IGoalResult[];
  question: string;
  isValue: boolean;
}) => {
  const {
    usersStore: {users},
    ccStore: {ccReviewScale},
  } = useStoreContext();

  const metric = (isValue
    ? review.valueRatings
    : review.competenceRatings
  ).find((value) => value.metric === question);

  const title = ccReviewScale.find((value) => value.score === metric?.score)
    ?.title;

  const description = metric?.note;

  const user = users.find((_user) => _user.id === review.reviewer);
  const computeGoals = useMemo(() => {
    if (metric?.goals) {
      const detailedGoals = metric.goals.map((goal) => {
        const getGoal = goals?.find((_goal) => _goal.id === goal.goal);

        return {
          ...goal,
          ...getGoal,
          progress: getGoal?.isKpi
            ? getGoal?.currentValue
            : (getGoal as any)?.percentageProgress,
        };
      });
      return detailedGoals;
    }
    return [];
  }, [metric?.goals, goals]);

  const findGoalMetric = (score: number) => {
    return ccReviewScale.find((value) => value.score === score);
  };
  return (
    <WrittenContainer>
      <FlexRow>
        <UserListCard
          tooltip={true}
          userId={user?.id || ''}
          type="secondary"
          textStyle={{fontWeight: 600}}
          reviewer={
            user?.reviewer?.id !== undefined
              ? user?.reviewer?.id
              : user?.reviewer !== undefined
              ? user?.reviewer
              : null
          }
          avatar={user?.avatar ? user?.avatar.url : ''}
          name={
            user?.firstName && user?.lastName
              ? `${user.firstName} ${user.lastName}`
              : user?.email || ''
          }
        />
        <div className="tag">
          <Body2 weight="bold" style={{fontSize: '12px'}} kind="textBody">
            {review.reviewerType === 'self'
              ? 'Self'
              : review.reviewerType === 'peer'
              ? 'Reviewers'
              : 'Manager'}
          </Body2>
        </div>
      </FlexRow>
      <VerticalSpacer size="13px" />
      {computeGoals.length ? (
        computeGoals.map((goal, idx, goals) => (
          <GoalView showBorder={idx !== goals.length - 1} key={goal.id}>
            <MetricDetail>
              <Body2 weight="bold" style={{fontSize: '12px'}}>
                {findGoalMetric(goal.score)?.title}
              </Body2>
            </MetricDetail>
            <VerticalSpacer size="8px" />
            <Body2 weight="bold">{goal.name}</Body2>
            <VerticalSpacer size="8px" />

            <FlexRow>
              <PillDiv
                percent={goal.progress}
                progress={goal?.performance as any}
                status={goal.status}
              />
              <Microcopy
                style={{
                  fontSize: '10px',
                  margin: ' 0 6px 0 4px',
                  fontWeight: 400,
                }}>
                {goal.progress}%
              </Microcopy>
              <HorizontalSideRule size="10px" />
              <Microcopy
                kind="textBody"
                style={{
                  fontSize: '10px',
                  margin: ' 0 6px 0 4px',
                  fontWeight: 400,
                }}>
                {dayjs(goal.startDate).format('DD. MMM YY')} -{' '}
                {dayjs(goal.endDate).format('DD. MMM YY')}
              </Microcopy>
            </FlexRow>
            {goal?.note && (
              <>
                <VerticalSpacer size="8px" />

                <Body2 kind="textBody" style={{fontSize: '12px'}}>
                  {goal.note}
                </Body2>
              </>
            )}
          </GoalView>
        ))
      ) : (
        <>
          <MetricDetail>
            <Body2 weight="bold" style={{fontSize: '12px'}}>
              {title}
            </Body2>
          </MetricDetail>
          <Body2 kind="textBody">{description}</Body2>
        </>
      )}
    </WrittenContainer>
  );
};
