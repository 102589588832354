import {PropsWithChildren, Suspense} from 'react';
import {StoreContextProvider} from './store/store-context';
import 'core-js/es/date';
import {PageLoader} from './ui/organisms/page-loader';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

export function App({children}: PropsWithChildren<{}>) {
  return (
    <StoreContextProvider>
      <ReactNotification />
      <Suspense fallback={<PageLoader />}>{children}</Suspense>
    </StoreContextProvider>
  );
}
