import {ResultUserResponse, ResultsResponse} from '@api/cc-api/cc-request';
import {Table, TableHeader, TableRow, TableWrapper} from '../cc-styles';
import {cloudinary} from '@assets/images/cloudinary';

import React from 'react';
import {Body2} from '@ui/atoms/typography';
import {FlexRowCenter} from '@ui/style/styles';
import {EmptyPlaceholder} from '@ui/atoms/empty-placeholder';
import {useStoreContext} from '@store/store-context';
import {UserListCard} from '@ui/molecules/user/list-card';
import {CustomColorIcon} from '@ui/atoms/color-icons';
import {Avatars} from '@ui/molecules/avatars';
import {Button} from '@ui/atoms/button';
import {DrawerModal} from '@ui/organisms/modal/drawer-modal';
import {ReviewComponent} from '../make-review/make-review';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {capitalize} from '@utils';
const {emptyGoals} = cloudinary;

const emptyPlaceholder = {
  image: {
    alt: 'alt text',
    shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
    src: emptyGoals,
    style: {height: '30%', width: '30%'},
  },
  imports: true,
  subtitle: '',
  variant: 'default' as 'default',

  title: 'No review selected, yet.',
};

export const RatingTable = ({
  users,
  cycleId,
  cycleType,
}: {
  users: ResultsResponse['users'];
  cycleId: string;
  cycleType: string;
}) => {
  const [searchParams] = useSearchParams();
  const showModal = searchParams.get('view-result');

  const navigate = useNavigate();

  const openModal = (userId: string) => {
    searchParams.append('type', 'manager');
    searchParams.append('view-result', 'true');
    searchParams.append('peer', userId);
    searchParams.append('id', cycleId);
    searchParams.append('action', 'finalize-review');

    // Get the updated search string
    const updatedSearchString = searchParams.toString();
    navigate({search: `?${updatedSearchString}`});
  };
  const closeModal = () => {
    navigate({search: ``});
  };
  const tableHeader = () => {
    const options = ['Name', 'Manager', 'Overall rating', 'Results'];

    if (cycleType === '360') {
      options.splice(2, 0, 'Peers');
    }

    return options;
  };

  return (
    <>
      <TableWrapper className="bg-white">
        <Table>
          <TableHeader
            variant="you"
            gridTemplate={
              cycleType === '360' ? '23% 23% 20% 20% 15% ' : '25% 25% 25% 12% '
            }>
            {tableHeader().map((value) => (
              <Body2 weight="bold" key={value}>
                {value}
              </Body2>
            ))}
          </TableHeader>
          {users.length > 0 ? (
            users.map((user) => (
              <Reviewee
                user={user}
                openModal={openModal}
                reviewType={cycleType}
              />
            ))
          ) : (
            <FlexRowCenter>
              <EmptyPlaceholder {...emptyPlaceholder} />
            </FlexRowCenter>
          )}
        </Table>
      </TableWrapper>

      <DrawerModal open={!!showModal} onClose={() => closeModal()}>
        <ReviewComponent />
      </DrawerModal>
    </>
  );
};

type RevieweeProps = {
  reviewType: string;
  openModal: (id: string) => void;
  user: ResultUserResponse;
};

const Reviewee = ({reviewType, user, openModal}: RevieweeProps) => {
  const {
    usersStore: {users},
  } = useStoreContext();

  const avatarIconColor = () => {
    if (user.reviewSummary.reviewWritten) return '#47B881';

    return '#CECEDE';
  };

  return (
    <>
      <TableRow
        variant="you"
        gridTemplate={
          reviewType === '360' ? '22% 22% 20% 15% 15% ' : '23% 25% 23% 12% '
        }>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <UserListCard
            kind="admin"
            type="secondary"
            avatarIcon={
              <CustomColorIcon
                color={avatarIconColor()}
                width="10px"
                height="10px"
                margin={0}
              />
            }
            textStyle={{fontWeight: 600}}
            tooltip={true}
            avatar={user?.avatar ? user.avatar.url : ''}
            reviewer={users?.find((_user) => user.id)?.reviewer}
            name={user.firstName + ' ' + user.lastName}
            userId={user?.id}
          />
        </div>

        <div>
          <UserListCard
            kind="admin"
            type="secondary"
            avatarIcon={
              <CustomColorIcon
                color={user.manager.reviewWritten ? '#47B881' : '#CECEDE'}
                width="10px"
                height="10px"
                margin={0}
              />
            }
            textStyle={{fontWeight: 600}}
            tooltip={true}
            avatar={user.manager?.avatar ? user.manager.avatar.url : ''}
            reviewer={users?.find((_user) => user.manager.id)?.reviewer}
            name={user.manager.firstName + ' ' + user.manager.lastName}
            userId={user.manager.id}
          />
        </div>

        {reviewType === '360' && (
          <div>
            {user.peers.length ? (
              <Avatars
                items={user.peers.map((peer) => {
                  return {
                    ...peer,
                    name: peer.firstName + peer.lastName,
                    src: peer.avatar?.url,
                    avatarIcon: (
                      <CustomColorIcon
                        color={peer.reviewWritten ? '#47B881' : '#CECEDE'}
                        width="10px"
                        height="10px"
                        margin={0}
                      />
                    ),

                    reviewer: users?.find((_user) => _user.id === peer.id)
                      ?.reviewer,
                  };
                })}
                total={user.peers.length}
              />
            ) : (
              <>
                <Body2 kind="textBody">Not yet added</Body2>
              </>
            )}
          </div>
        )}
        <div>
          <Body2 weight="semibold">
            {capitalize(user.reviewSummary.overallRating || '-')}
          </Body2>
        </div>
        <div>
          <Button
            kind="secondary"
            disabled={
              !user.reviewSummary.reviewWritten || !user.manager.reviewWritten
            }
            style={{padding: '6px 12px'}}
            onClick={() => openModal(user.id)}>
            View
          </Button>
        </div>
      </TableRow>
    </>
  );
};
