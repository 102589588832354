import {useReducer, useCallback, useMemo} from 'react';
import nextId from 'react-id-generator';
import {TKeyResult} from './interface';

interface IResult {
  keyresult: TKeyResult;
  valid: [];
}

function addNewKeyresult(
  keyresults: Map<string, IResult>,
): Map<string, IResult> {
  keyresults.set(nextId('keyresult-id'), {
    keyresult: {
      id: '',
      measurement: {unit: '', symbol: ''},
      name: '',
      range: {start: undefined, target: undefined, current: undefined},
      user: '',
      showSource: false,
      saveSource: false,
      source: null,
    },
    valid: [],
  });

  return new Map(keyresults);
}

function defaultEmails(keyresults?: TKeyResult[]): Map<string, IResult> {
  return new Map(
    (keyresults || []).map((keyresult) => [
      nextId('keyresult-id'),
      {keyresult, valid: []},
    ]),
  );
}

function removeKeyresult(
  keyresults: Map<string, IResult>,
  key: string,
): Map<string, IResult> {
  keyresults.delete(key);
  return new Map(keyresults);
}

const updateKeyresult = (
  keyresults: Map<string, IResult>,
  key: string,
  keyresult: TKeyResult,
): Map<string, IResult> => {
  keyresults.set(key, {
    keyresult,
    valid: [],
  });

  return new Map(keyresults);
};

interface ReducerState {
  keyresults: Map<string, IResult>;
}

export enum ActionType {
  REMOVE_KEYRESULT,
  ADD_KEYRESULT,
  UPDATE_KEYRESULT,
}

type AddNewEmailAction = {type: ActionType.ADD_KEYRESULT};

type UpdateEmailAction = {
  type: ActionType.UPDATE_KEYRESULT;
  payload: {value: TKeyResult; key: string};
};
type RemoveKeyResultsAction = {
  type: ActionType.REMOVE_KEYRESULT;
  payload: {key: string};
};

type ReducerAction =
  | AddNewEmailAction
  | UpdateEmailAction
  | RemoveKeyResultsAction;

const initialState: ReducerState = {
  keyresults: new Map(),
};

function keyresultsReducer(
  state: ReducerState,
  action: ReducerAction,
): ReducerState {
  switch (action.type) {
    case ActionType.ADD_KEYRESULT:
      return {
        keyresults: addNewKeyresult(state.keyresults),
      };

    case ActionType.UPDATE_KEYRESULT:
      return {
        keyresults: updateKeyresult(
          state.keyresults,
          action.payload.key,
          action.payload.value,
        ),
      };
    case ActionType.REMOVE_KEYRESULT:
      return {
        keyresults: removeKeyresult(state.keyresults, action.payload.key),
      };
    default:
      return state;
  }
}

export function useKeyResult(
  keyresults?: TKeyResult[],
  setValue?: (values: TKeyResult[]) => void,
) {
  const [state, dispatch] = useReducer(keyresultsReducer, {
    ...initialState,
    keyresults: defaultEmails(keyresults),
  });

  const updateValue = useCallback(() => {
    if (setValue) {
      const keyresults = Array.from(state.keyresults)
        // .filter(([key, keyresult]) => keyresult && keyresult.valid)
        .map(([key, keyresult]) => keyresult.keyresult);

      setValue(keyresults);
    }
  }, [setValue, state.keyresults]);

  const deleteKeyResult = useCallback(() => {
    if (setValue) {
      const keyresults = Array.from(state.keyresults)
        // .filter(([key, keyresult]) => keyresult && keyresult.valid)
        .map(([key, keyresult]) => keyresult.keyresult);

      setValue(keyresults);
    }
  }, [setValue, state.keyresults]);

  const addKeyresult = useCallback(async () => {
    await dispatch({type: ActionType.ADD_KEYRESULT});
  }, []);

  const removeKeyresult = useCallback(
    async (key: string) => {
      await dispatch({type: ActionType.REMOVE_KEYRESULT, payload: {key}});
      updateValue();
    },
    [updateValue],
  );

  const onChangeKeyResult = useCallback(
    async (key: string, keyresult: TKeyResult) => {
      await dispatch({
        type: ActionType.UPDATE_KEYRESULT,
        payload: {value: keyresult, key},
      });
      deleteKeyResult();
    },
    [deleteKeyResult],
  );

  const disabled = useMemo(() => {
    return state.keyresults.size >= 5;
  }, [state.keyresults.size]);

  return {
    keyresults: state.keyresults,
    addKeyresult,
    removeKeyresult,
    onChangeKeyResult,
    disabled,
  };
}
