import styled, {css} from 'styled-components';
import {motion} from 'framer-motion';
import {useMemo, useState, ReactNode} from 'react';
import {CancelIcon} from '../../atoms/icons/cancel';
import {AlertBadgeSuccessSVG} from '../../atoms/svg/alert-badge-success';
import {AlertBadgeInfoSVG} from '../../atoms/svg/alert-badge-info';
import {AlertBadgeErrorSVG} from '../../atoms/svg/alert-badge-error';
import {AlertBadgeWarningSVG} from '../../atoms/svg/alert-badge-warning';
import {useStoreContext} from '@store/store-context';
import {theme} from '../../style';
import {observer} from 'mobx-react-lite';
import {TNotificationProps} from '../../interface';
import {HorizontalSpacer} from '../../atoms/spacer';
import {Flex} from '@ui/style/styles';
import {Body2} from '@ui/atoms/typography';
import {CTA} from '../../atoms/typography/cta';
import {Button} from '../../atoms/button';
import {VerticalSpacer} from '../../atoms/spacer';
import {down} from 'styled-breakpoints';
interface INotify {
  hide: boolean;
}

const NotificationWrapper = styled.div<INotify>`
  width: 100%;

  height: fit-content;
  ${down('md')} {
    right: 0%;
  }

  ${(props) =>
    props?.hide &&
    css`
      display: none;
    `}
`;

const NotificationContainer = styled(motion.div)<{background: string}>`
  background-color: ${({background}) => background};
  padding: 12px 16px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.05);

  position: relative;
  display: flex;
  pointer-events: none;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const CloseButton = styled.button`
  flex-basis: 16px;
  margin-left: 24px;
  background: none;
  border: none;
  margin-top: 2px;
  cursor: pointer;
  pointer-events: all;

  svg {
    width: 16px;
    height: 16px;
    stroke: ${theme.colors.textBody};
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const IconWrapper = styled.span`
  flex-basis: 20px;
  margin-top: 1px;
  margin-right: 16px;

  svg {
    width: 20px;
    stroke: white;
    height: 20px;
  }
`;

export type NotificationProps = TNotificationProps & {onDismiss?: () => void};

export const Notification = (props: NotificationProps) => {
  const [hide] = useState(false);

  const icon = useMemo(() => {
    switch (props.kind) {
      case 'success':
        return <AlertBadgeSuccessSVG />;
      case 'error':
        return <AlertBadgeErrorSVG />;
      case 'info':
        return <AlertBadgeInfoSVG />;
      case 'warning':
        return <AlertBadgeWarningSVG />;
      default:
        return <AlertBadgeInfoSVG />;
    }
  }, [props.kind]);

  const background = useMemo(() => {
    switch (props.kind) {
      case 'success':
        return theme.colors.green300;
      case 'error':
        return theme.colors.red400;
      case 'info':
        return theme.colors.green300;
      case 'warning':
        return theme.colors.green300;
      default:
        return theme.colors.green300;
    }
  }, [props.kind]);

  return (
    <NotificationWrapper hide={hide}>
      <NotificationContainer
        background={background}
        initial={{opacity: 0, y: 50, scale: 0.3}}
        animate={{opacity: 1, y: 0, scale: 1}}
        exit={{opacity: 0, scale: 0.5, transition: {duration: 0.2}}}>
        <Content>
          <IconWrapper>{icon}</IconWrapper>
          <Flex alignItems="center">
            <CTA kind="white">{props.title ? `${props.title}.` : null}</CTA>
            <HorizontalSpacer size="8px" />
            <CTA kind="white">{props.content}</CTA>
            {props.action && (
              <>
                <VerticalSpacer size="16px" />
                <Button width="sm" type="button" onClick={props.action.onClick}>
                  {props.action.title}
                </Button>
              </>
            )}
          </Flex>
        </Content>
        <CloseButton onClick={props.hideNotification}>
          <CancelIcon color="white" />
        </CloseButton>
      </NotificationContainer>
    </NotificationWrapper>
  );
};

export const NotificationCard = observer(() => {
  const {
    notificationStore: {
      notificationData,
      showNotification,
      appIsOffline,
      handleShowNotification,
    },
  } = useStoreContext();

  if (showNotification) {
    return (
      <Notification
        {...notificationData}
        hideNotification={() =>
          appIsOffline ? null : handleShowNotification(false)
        }
      />
    );
  }

  return <></>;
});
const Wrapper = styled.div<INotify>`
  width: 100vw;
  position: fixed;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  z-index: 2;
  justify-content: center;
  color: white;
  top: 75px;
  left: 0;
  background-color: #585adf;
  .action {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    cursor: pointer;
    margin-left: 5px;
    text-decoration: underline;
  }
  ${(props) =>
    props?.hide &&
    css`
      display: none;
    `}
`;
interface NotifySecondary {
  title: string | ReactNode;
  action?: {
    title: string;
    onClick: () => void;
  };
}

export const NotificationSecondary = observer(
  ({title, action}: NotifySecondary) => {
    const {
      notificationStore: {showNotification},
    } = useStoreContext();
    return (
      <Wrapper hide={showNotification}>
        {typeof title === 'string' ? <Body2>{title}</Body2> : title}
        {action && (
          <div className="action" onClick={action.onClick}>
            {action.title}
          </div>
        )}
      </Wrapper>
    );
  },
);
