import React from 'react';
import styled from 'styled-components';
import {Body2} from '@ui/atoms/typography';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {Button} from '@ui/atoms/button';

const Wrapper = styled.div<{height: any; width?: string}>`
  border-radius: 6px 6px 0px 0px;
  max-height: ${({height}) => (height ? `${height}px` : '428px')};
  z-index: 5;
  background: white;
  border: 1px solid #dcdce2;
  position: relative;
  width: ${({width}) => (width ? width : '400px')};
`;

const CardHeader = styled.div`
  border-bottom: 1px solid rgba(206, 206, 222, 1);
  padding: 16px 10px;
`;
const CardBody = styled.div<{height: any}>`
  padding: 24px 16px;

  max-height: ${({height}: any) => (height === 622 ? `450px` : '250px')};
`;
const Footer = styled.div`
  border-width: 0px 1px 1px 1px;
  border-color: rgba(206, 206, 222, 1);
  border-radius: 0px 0px 6px 6px;

  border-style: solid;
  padding: 12px 10px;
  display: flex;
  z-index: 3;
  background: white;

  justify-content: space-between;
  position: relative;
`;
interface CardProps {
  title: string;

  children: any;
  height?: any;
  loading?: boolean;
  disabled?: any;
  onSubmit?: (e: any) => void;
  handleSearch?: (e: any) => void;
  onClickCancel: (e: any) => void;
  width?: string;
}
export function GroupDropdown({
  title,
  children,
  onSubmit,
  disabled,
  height,
  loading,
  width,
  handleSearch,
  onClickCancel,
}: CardProps) {
  return (
    <>
      <Wrapper height={height} width={width}>
        <CardHeader>
          <Body2
            onClick={onClickCancel}
            style={{
              fontWeight: 500,
              display: 'flex',
              cursor: 'pointer',
              alignItems: 'center',
            }}>
            {title}
          </Body2>
        </CardHeader>

        <VerticalSpacer size="6px" />
        <CardBody height={height}>{children}</CardBody>
      </Wrapper>
      <Footer>
        <Button
          kind="secondary"
          style={{
            width: '79px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
          }}
          onClick={onClickCancel}>
          Cancel
        </Button>
        <Button
          width="sm"
          kind="primary"
          onClick={onSubmit}
          isLoading={loading}
          disabled={disabled}
          style={{
            height: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflowY: 'hidden',
          }}>
          Create
        </Button>
      </Footer>
    </>
  );
}
