import {BorderWrapper2, MemberButton} from './admin-member-style';
import {ButtonNavigation} from '../../../../../../ui/molecules/button-nav';
import {useStoreContext} from '../../../../../../store/store-context';
import {Active} from '../admin-member/admin-member-components/active-member/active';
import {Invite} from '../admin-member/admin-member-components/invited-member/invite';
import {Deactivate} from '../admin-member/admin-member-components/deactivated-member/deactivate';
import {observer} from 'mobx-react';
import {useFirebaseFetch} from '@hooks/query-hook';
import {PlainButton} from '@ui/atoms/plain-button/plain-button';
import {updateTooltipStatus} from '@utils/firebase-request';
import {AlertBadge} from '@ui/atoms/icons/alert-badge';
import {Headline2} from '../../../../../../ui/atoms/typography';
import {useNavigate} from 'react-router';
import {InformationBlock} from '@ui/atoms/hint-block/hint-block';
import {VerticalSpacer} from '@ui/atoms/spacer';

export const AdminMemberPage = observer(() => {
  const {
    storeDashboardRoutes,
    authStore: {auth},
  } = useStoreContext();
  const {memberTab, activateMemberTab} = storeDashboardRoutes;

  const memberNavigations = [
    {
      active: memberTab.active,
      href: '#',
      id: '1',
      onClick: () => activateMemberTab('active'),
      title: 'Active Members',
    },
    {
      active: memberTab.invite,
      href: '#',
      id: '2',
      onClick: () => activateMemberTab('invite'),
      title: 'Invited Members',
    },
    {
      active: memberTab.deactivate,
      href: '#',
      id: '3',
      onClick: () => activateMemberTab('deactivate'),
      title: 'Suspended Members',
    },
  ];

  const navigate = useNavigate();

  const tooltipId = 'admin_people';

  const isFreePlanActive = auth.user.workspace.billing.plan === 'free_tier';

  const {data: tooltipStatus} = useFirebaseFetch(
    `users/${auth.user.id}/onboarding/tooltip/${tooltipId}`,
  );

  return (
    <>
      <BorderWrapper2>
        <Headline2 kind="textDark" weight="semibold">
          Manage Members{' '}
          <PlainButton
            type="button"
            style={{marginLeft: '2px'}}
            onClick={() =>
              updateTooltipStatus(tooltipId, {isDismissed: false})
            }>
            <AlertBadge />
          </PlainButton>
        </Headline2>
        {!isFreePlanActive && (
          <MemberButton width="sm" onClick={() => navigate('/invite-user')}>
            Invite members{' '}
          </MemberButton>
        )}
      </BorderWrapper2>
      <div style={{margin: '0px 16px 16px'}}>
        <InformationBlock id={tooltipId} isDismissed={tooltipStatus}>
          Add, view and manage your workspace members.
        </InformationBlock>
      </div>
      <div className="border-t border-l rounded-t-[10px] flex w-full border-t-[#ededf2]">
        <ButtonNavigation
          navigations={memberNavigations}
          variant="peer"
          type="overlap"
        />
      </div>
      {(memberTab.active ? <Active /> : '') ||
        (memberTab.invite ? <Invite /> : '') ||
        (memberTab.deactivate ? <Deactivate /> : '')}
      <VerticalSpacer size="32px" />{' '}
    </>
  );
});
