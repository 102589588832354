import {FC} from 'react';
import styled from 'styled-components';

const MonthScore = styled.div`
  position: absolute;
  border-radius: 10px;
  padding: 9px 6px 4px 21px;
  & span {
    color: #5f5f8c;
    font-size: 14px;
  }
  & p {
    text-align: right;
  }
  top: 10%;
  right: 7%;
  background: #fff;
`;
export interface IPerformanceIllustrationMonthScoreBox {
  date: string;
  score: number;
}
export const PerformanceIllustrationMonthScoreBox: FC<IPerformanceIllustrationMonthScoreBox> = ({
  date,
  score,
}) => {
  return (
    <MonthScore>
      <span>{date}</span>
      <p>{score}</p>
    </MonthScore>
  );
};
PerformanceIllustrationMonthScoreBox.displayName =
  'PerformanceIllustrationMonthScoreBox';
