import styled from 'styled-components';
import {getColorFromTheme} from '../../ui-utils';
import {Avatar} from '../../atoms/avatar';
import {Body2, Body1} from '../../atoms/typography';
import {YouHightLight} from '../../../pages/dashboard/you/you-components/you-hightligts';
import nextId from 'react-id-generator';
import {NestedView} from '../../../pages/dashboard/reporting/reporting-pages/admin-analytics/analytics-shared-components/nestedview/nestedview';

const Wrapper = styled.section<{goalsLength: number}>`
  border: 1px solid ${getColorFromTheme('borderLight')};
  border-radius: 10px;
  width: 100%;
  @media (min-width: 1500px) {
    width: 100%;
  }
  div.innerDiv {
    border-top: 1px solid ${getColorFromTheme('borderLight')};
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding: ${(props: any) =>
      props.goalsLength > 2 ? '8px 8px 12px 8px' : '8px 8px 5px 8px'};
  }
`;
const EmptyGoal = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 8px;
  border-top: 1px solid ${getColorFromTheme('borderLight')};
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  justify-content: center;
`;
const HeaderDiv = styled.div`
  padding: 15px 28px;
  div.userInfo {
    display: flex;
    align-items: center;
    .textDiv {
      margin-left: 14px;
    }
  }
  @media (max-width: 500px) {
    padding: 16px 16px 20px 16px;
  }
`;

export interface IUsersGoalsLayout {
  userId?: string;
  avatar?: string;
  name?: string;
  title?: string;
  jobDescription?: string;
  goals: any;
  variant?: string;
  tooltip: boolean;
  isFetching: boolean;
  onGoalUpdate?: (id: string) => void;
  onUpdateReverted?: (id: string) => void;
  view?: string;
  allGroups?: any;
}

export const UsersGoalsLayout = function ({
  avatar,
  name = '',
  jobDescription,
  goals,
  variant,
  userId,
  title,
  tooltip,
  onGoalUpdate,
  onUpdateReverted,
  isFetching,
  view,
  allGroups,
}: IUsersGoalsLayout) {
  const transformMap = (data: any) => {
    const res = new Map();

    data.forEach((result: any) => {
      res.set(nextId('goal-'), result);
    });
    return res;
  };

  return (
    <Wrapper goalsLength={goals?.length} className="bg-white">
      <>
        <HeaderDiv>
          <div className="userInfo">
            {userId ? (
              <>
                <Avatar
                  src={avatar}
                  name={name}
                  userId={userId}
                  tooltip={tooltip}
                  size="lg"
                />

                <div className="textDiv">
                  <Body1 kind="textDark" weight="semibold">
                    {name}
                  </Body1>
                  <Body2 kind="textBody">{jobDescription}</Body2>
                </div>
              </>
            ) : (
              title && (
                <Body1 kind="textDark" weight="bold" className="capitalize">
                  {title}
                </Body1>
              )
            )}
          </div>
        </HeaderDiv>
        {goals ? (
          <>
            <div className="innerDiv">
              {(view === 'flat' || view === 'tree') && (
                <YouHightLight
                  goals={transformMap(goals)}
                  isFetching={isFetching}
                  managerEdit={true}
                  onGoalUpdate={onGoalUpdate}
                  onUpdateReverted={onUpdateReverted}
                  variant={variant}
                />
              )}
              {view === 'stacked' && (
                <NestedView
                  goalsData={goals}
                  loading={isFetching}
                  variant={variant}
                  allGroups={allGroups}
                />
              )}
            </div>
          </>
        ) : (
          <EmptyGoal>
            <Body1 kind="textBody">No active goals.</Body1>
          </EmptyGoal>
        )}
      </>
    </Wrapper>
  );
};
