import {array, boolean, object, string} from 'yup';

export class CreateMeetingValidator {
  static get createMeeting() {
    return object().shape({
      participant: array().of(string()).required('Please select a manager'),
      startDate: string().required('Please pick a start date '),
      shareAgenda: boolean().required(''),
      frequency: string().required('Please pick a frequency'),
      reminder: array()
        .of(string())
        .min(1, 'Select at least 1')
        .required('reminder is a required field'),
    });
  }
}
