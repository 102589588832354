import {memo} from 'react';

export const QuestionMarkIcon = memo(() => {
  return (
    <svg
      width="26"
      height="27"
      viewBox="0 0 26 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect y="0.332031" width="26" height="26" rx="13" fill="#585ADF" />
      <path
        d="M9.10156 9.54625C9.32933 8.84458 9.74671 8.21956 10.3076 7.7403C10.8684 7.26105 11.5509 6.94622 12.2795 6.83065C13.0081 6.71507 13.7544 6.80324 14.4361 7.0854C15.1177 7.36757 15.708 7.83274 16.1417 8.42948C16.5755 9.02623 16.8357 9.7313 16.8938 10.4667C16.9518 11.2022 16.8053 11.9393 16.4704 12.5967C16.1356 13.254 15.6255 13.806 14.9966 14.1915C14.3676 14.5771 13.6443 14.7812 12.9066 14.7813V15.7813M12.9566 19.7813V19.8813H12.8566V19.7813H12.9566Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
});
