import {VerticalSpacer} from '@ui/atoms/spacer';
import {Body2} from '@ui/atoms/typography';
import {SelectField} from '@ui/molecules/select/selectfield';
import {FC} from 'react';
import {PlainButton} from '@ui/atoms/plain-button/plain-button';
import {AlertBadge} from '@ui/atoms/icons/alert-badge';
import {updateTooltipStatus} from '@utils/firebase-request';
import {FlexRow} from '@ui/style/styles';
type ReviewTypeProps = {
  handleChange?: (value: string) => void;
  reviewType?: string;
  tooltipId: string;
};

export const ReviewType: FC<ReviewTypeProps> = ({
  handleChange,
  reviewType,
  tooltipId,
}) => {
  const reviewOptions = [
    {
      label: 'Self and manager review',
      value: 'self',
    },
    {
      label: '360 review',
      value: '360',
    },
  ];

  const onChange = (value: string) => {
    if (handleChange) handleChange(value);
  };

  return (
    <div>
      <FlexRow>
        <Body2 weight="bold">What type of review is this? </Body2>
        <PlainButton
          className="flex items-center"
          style={{marginLeft: '5px'}}
          onClick={() => updateTooltipStatus(tooltipId, {isDismissed: false})}>
          <AlertBadge style={{width: '14px', height: '14px'}} />
        </PlainButton>
      </FlexRow>
      <VerticalSpacer size="8px" />
      <SelectField
        borderRadius="10px"
        defaultValue={reviewType}
        placeholder="Select type"
        options={reviewOptions}
        onChange={(data: {value: string; label: string}) => {
          onChange(data?.value || '');
        }}
      />
    </div>
  );
};
