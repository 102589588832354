import {action, makeAutoObservable, observable} from 'mobx';
import {UserAPIController} from '../../api/user-api/user-api-controller';
import {UserAPIRequestImpl} from '../../api/user-api/user-api-request';
import {IAPIUserFormat, IDropdownUser, IMembersFormatFilter} from '../../hooks';

export class UsersStore {
  @observable currentUser: any = {};
  @observable currentUserLoader: boolean = true;
  @observable user: any = {};
  @observable currentUserGroups: any = [];
  @observable usersFormatFilter: IMembersFormatFilter[] = [];

  @observable confirmedUsers: any = {};
  @observable users: IAPIUserFormat[] = [];
  @observable membersInStore: IDropdownUser[] = [];
  @observable uiUsersFormat: IDropdownUser[] = [];
  @observable uiCurrentUserFormat: IDropdownUser | null = null;
  @observable dashboardProfile: any = {
    name: '',
    avatar: '',
    url: '',
    id: '',
    role: '',
  };
  @observable deactivatedUsers: any = [];
  @observable usersGroups: any = {};

  private request = new UserAPIRequestImpl();
  private controller = new UserAPIController(this.request);

  constructor() {
    makeAutoObservable(this);
  }

  @action
  clearUsers = () => {
    this.currentUser = {};
    this.confirmedUsers = {};
    this.users = [];
    this.user = {};
    this.currentUserGroups = [];
    this.membersInStore = [];
    this.uiUsersFormat = [];
    this.usersFormatFilter = [];
    this.uiCurrentUserFormat = null;
    this.dashboardProfile = {
      name: '',
      avatar: '',
      url: '',
      id: '',
      role: '',
    };
    this.deactivatedUsers = [];
  };

  @action
  getUsers = async () => {
    await this.getCurrentUser();
  };

  @action
  async getConfirmedUsers() {
    const response = await this.controller.fetchConfirmedUsers();
    this.confirmedUsers = {...response};
    return response;
  }

  @action
  async getCurrentUser() {
    const response = await this.controller.fetchCurrentUser();
    this.currentUser = {...response};

    if (response) {
      this.dashboardProfile = {
        name: `${response.firstName} ${response.lastName}`,
        avatar: response.avatar ? response.avatar.url : '',
        url: '',
        id: response.id,
        role: response.role,
      };
    }
    this.setCurrentUserUIFormat(response);
    this.currentUserLoader = false;
    return response;
  }

  @action
  getCurrentUserGroups = async () => {
    const response = await this.controller.fetchCurrentUserGroups();

    this.currentUserGroups = response;
  };
  @action
  setCurrentUserGroups = (data: any) => {
    this.currentUserGroups = data;
  };

  @action
  async setCurrentUser(data: any) {
    this.user = data;
  }

  @action
  patchCurrentUser = (data: any) => {
    this.currentUser = data;
  };

  @action setUsersData = (data: IAPIUserFormat[]) => {
    const usersData = [
      ...Array.from(
        new Map(data.map((item: any) => [item['id'], item])).values(),
      ),
    ];

    this.formatUsersForUI(usersData);
    this.formatUsersForFilter(usersData);
    this.users = usersData;
  };
  @action
  private formatUsersForUI(users: IAPIUserFormat[]) {
    this.clearArray(this.uiUsersFormat);
    users.forEach((user) => {
      if (user) {
        this.uiUsersFormat.push({
          label: {
            avatar: {
              name:
                user?.firstName && user?.lastName
                  ? `${user.firstName} ${user.lastName}`
                  : user.email,
              src: user.avatar && user.avatar.url ? user.avatar.url : '',
            },
            id: user.id,
            name:
              user?.firstName && user?.lastName
                ? `${user.firstName} ${user.lastName}`
                : user.email,
          },
          value: user.id,
        });
      }
    });
  }
  @action
  private formatUsersForFilter(users: IAPIUserFormat[]) {
    this.clearArray(this.usersFormatFilter);
    const members = users.map((user) => {
      return {
        label:
          user.firstName && user.lastName
            ? `${user.firstName} ${user.lastName}`
            : user.email,
        value: user.id,
      };
    });
    this.usersFormatFilter = members;
  }

  @action
  setUsersGroups = (id: string, data: any) => {
    this.usersGroups = {
      ...this.usersGroups,
      [id]: data,
    };
  };
  @action
  private formatMembersInStore(users: IDropdownUser[]) {
    this.clearArray(this.membersInStore);
    users
      .filter((person: any) => person.status.includes('on'))
      // eslint-disable-next-line array-callback-return
      .map((member: any) => {
        this.membersInStore.push({
          label: {
            avatar: {
              name:
                member && member.firstName
                  ? member.firstName + ' ' + member.lastName
                  : member.email + ' (pending invitation)',
              src: member.avatar && member.avatar.url ? member.avatar.url : '',
            },
            id: member.id,
            name:
              member && member.firstName
                ? member.firstName + ' ' + member.lastName
                : member.email + ' (pending invitation)',
          },
          value: member.id,
        });
      });
  }

  @action
  setDeactivatedUsers(users: any) {
    this.deactivatedUsers = users;
  }

  @action
  fetchDeactivatedUsers = async () => {
    const deactivatedUsers = await this.controller.fetchDeactivatedUsers();

    deactivatedUsers && this.setDeactivatedUsers(deactivatedUsers);
  };

  @action
  private setCurrentUserUIFormat(user: IAPIUserFormat) {
    this.uiCurrentUserFormat = {
      label: {
        avatar: {
          name:
            user.firstName && user.lastName
              ? `${user.firstName} ${user.lastName}`
              : user.email,
          src: 'https://avatarmaker.net/images/1.png',
        },
        id: user.id,
        name:
          user.firstName && user.lastName
            ? `${user.firstName} ${user.lastName}`
            : user.email,
      },
      value: user.id,
    };
  }

  @action
  private clearArray(array: any[]) {
    while (array.length) {
      array.pop();
    }
  }
}

export const usersStore = new UsersStore();
