import {action} from 'mobx';
import {activateNotification} from '../../../../../ui/molecules/notification/activate-notification';
import {
  WorkspaceInviteCsvData,
  WorkspaceInviteFormData,
} from './workspace-invite-interface';
import {beamMasteryTasks} from '@utils/firebase-request';
import {WorkspaceInviteRequest} from './workspace-invite-request';

export class WorkspaceInviteController {
  constructor(private readonly request: WorkspaceInviteRequest) {}

  @action
  async newWorkspaceInvite(
    data: WorkspaceInviteFormData,
    accessToken: string,
    errorMessages?: string[],
  ) {
    try {
      const response = await this.request.WorkspaceInvite(data, accessToken);
      activateNotification({
        title: 'Success',
        content: 'Invitation sent',
        kind: 'success',
      });
      beamMasteryTasks('invite_members', true);
      return response;
    } catch (e: any) {
      const errorMessage = e?.response?.data?.message?.toLowerCase();

      const isRecognizableError =
        errorMessage &&
        errorMessages?.some((message) =>
          errorMessage.includes(message.toLowerCase()),
        );

      if (!isRecognizableError) {
        activateNotification({
          title: 'Error',
          content: e?.response?.data?.message,
          kind: 'error',
        });
      }
      return e?.response;
    }
  }

  async newWorkspaceInviteCsv(
    data: WorkspaceInviteCsvData,
    accessToken: string,
  ) {
    try {
      const response = await this.request.WorkspaceInviteCsv(data, accessToken);
      activateNotification({
        title: 'Success',
        content: 'Invitation sent',
        kind: 'success',
      });
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e?.response?.data?.message,
        kind: 'error',
      });
    }
  }
}
