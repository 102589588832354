import {Body1, Headline2, Body2} from '@ui/atoms/typography';
import {TextTag2} from '@ui/atoms/tag';
import {UserListCard} from '@ui/molecules/user/list-card';
import React, {useState, useCallback, useMemo} from 'react';
import {DropdownVertical} from '@ui/molecules/dropdown-vertical';
import {Modal} from '@ui/molecules/modal';
import {BackAction} from '@ui/molecules/back-action';
import {ModalCard} from '@ui/layouts/modal-card';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {CancelIcon} from '@ui/atoms/icons';
import {useNavigate} from 'react-router';
import {UserAPIRequestImpl} from '@api/user-api/user-api-request';
import {UserAPIController} from '@api/user-api/user-api-controller';
import {useQuery} from 'react-query';
import {Button} from '@ui/atoms/button';
import {ItemLoaderLine} from '@ui/organisms/item-loader-line';
import {activateNotification} from '@ui/molecules/notification/activate-notification';
import {ArrowHeadLeftIcon, ArrowHeadRightIcon} from '@ui/atoms/icons';

import {
  PaginationLi,
  TableContainer,
  HeadlineWrapperContainer1,
  HeadlineWrapper1,
  ItemWrapperContainer1,
  ItemWrapper1,
  SecondRowWrapper,
  DropdownItem,
  HeadlineWrapperContainer,
  HeadlineWrapper,
  ItemWrapperContainer,
  ItemWrapper,
  JobTitleWrapper,
  DropdownWrapper,
  ItemLoaderWrapper,
  PaginationUl,
  PaginationButton,
  NoItemWrapper,
} from './deactivate.styles';
import {FlexRowCenter} from '@ui/style/styles';

interface IConfirmedUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  reviewer: any;
  jobTitle: string;
  avatar: {
    url: string;
  };
}

const defaultUsers: IConfirmedUser[] = [];

export const Deactivate = () => {
  const [users, setUsers]: [
    IConfirmedUser[],
    (users: IConfirmedUser[]) => void,
  ] = React.useState(defaultUsers);

  const [limit, setLimit] = useState<number>(0);
  const [page, setPage] = useState<any>(1);
  const [totalPages, setTotalPages] = useState<any>(1);
  const [totalResults, setTotalResults] = useState<any>(0);
  const [pageNumberLimit] = useState(2);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(2);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

  const pages = [];
  for (let i = 1; i <= Math.ceil(totalResults / limit); i++) {
    pages.push(i);
  }

  const handleClick = (event: any) => {
    setPage(Number(event.target.id));
  };

  const renderPageNumbers = pages.map((number: any) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <PaginationLi
          key={number}
          id={number}
          onClick={handleClick}
          className={page === number ? 'active' : undefined}>
          {number}
        </PaginationLi>
      );
    } else {
      return null;
    }
  });

  const handleNextbtn = () => {
    setPage(page + 1);

    if (page + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = () => {
    setPage(page - 1);

    if ((page - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = (
      <PaginationLi onClick={handleNextbtn}> ... </PaginationLi>
    );
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = (
      <PaginationLi onClick={handlePrevbtn}> ... </PaginationLi>
    );
  }
  const fetchDeactivatedUsers = useCallback(async () => {
    const request = new UserAPIRequestImpl();
    const controller = new UserAPIController(request);
    const res = await controller.fetchPaginatedDeactivatedUsers(page);

    return res;
  }, [page]);

  const {isLoading, data} = useQuery(['deactivatedUsers', page], () =>
    fetchDeactivatedUsers(),
  );
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalId, setModalId] = useState('');
  const [modalEmail, setModalEmail] = useState('');

  //open and close modal with user data being passed to modal screen
  const openModal = (id: string, email: string) => {
    setModalIsOpen(true);
    setModalId(id);
    setModalEmail(email);
  };

  const hideModal = () => {
    setModalIsOpen(false);
  };

  useMemo(() => {
    if (data) {
      setLimit(data.limit);
      setTotalPages(data.totalPages);
      setTotalResults(data.totalResults);
      setUsers(data.results);
    }
  }, [data]);
  const [modalLoading, setModalLoading] = useState(false);

  //deactivate user function
  const deleteUser = async (
    id: string,

    email: string,
  ) => {
    setModalLoading(true);
    const request = new UserAPIRequestImpl();
    const controller = new UserAPIController(request);
    const response = await controller.deleteUser(id);
    if (response) {
      activateNotification({
        title: 'Success',
        content: `"${email}"  was deleted`,
        kind: 'success',
      });
      navigate('/', {replace: true});
      navigate('/manage-people', {replace: true});
    }
    setModalLoading(false);
  };

  const navigate = useNavigate();

  //prevent button from refreshing page
  const noReload = (e: any) => {
    e.preventDefault();
  };

  //activate users
  const activate = async (id: string, email: string) => {
    const request = new UserAPIRequestImpl();
    const controller = new UserAPIController(request);
    const res = await controller.activateUser(id);
    if (res) {
      activateNotification({
        title: 'Success',
        content: `"${email}" is activated`,
        kind: 'success',
      });
      navigate('/', {replace: true});
      navigate('/manage-people', {replace: true});
    }
  };

  //covert role to title case
  const titleCase = function (string: string) {
    const sentence = string.toLowerCase().split(' ');
    for (let i = 0; i < sentence.length; i++) {
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }
    return sentence.join(' ');
  };

  return (
    <>
      <TableContainer>
        <div style={{display: 'flex'}}>
          <div className="table">
            <HeadlineWrapperContainer1>
              <HeadlineWrapper1>
                <div>
                  <Body2 weight="semibold">Name/e-mail</Body2>
                </div>
              </HeadlineWrapper1>
            </HeadlineWrapperContainer1>
            {!isLoading &&
              users.map((user, key) => (
                <ItemWrapperContainer1 key={key}>
                  <ItemWrapper1>
                    <UserListCard
                      tooltip={true}
                      userId={user?.id}
                      type="secondary"
                      users={users}
                      reviewer={user?.reviewer}
                      avatar={user.avatar ? user.avatar.url : ''}
                      name={
                        !user.firstName
                          ? user?.email
                            ? user?.email
                            : '----- -----'
                          : user.firstName + ' ' + user.lastName
                      }
                    />
                    {user.firstName &&
                      `${user?.firstName} ${user?.lastName}`.length > 20 && (
                        <span style={{marginTop: '-70px'}} className="tooltip">
                          {`${user?.firstName} ${user?.lastName}`}
                        </span>
                      )}
                  </ItemWrapper1>
                </ItemWrapperContainer1>
              ))}
          </div>
          <SecondRowWrapper>
            <HeadlineWrapperContainer>
              <HeadlineWrapper>
                <div style={{maxWidth: '150px', minWidth: '100px'}}>
                  <Body2 weight="semibold">Job Title</Body2>
                </div>
                <div style={{maxWidth: '100px', minWidth: '100px'}}>
                  <Body2 weight="semibold">Role</Body2>
                </div>
                <div style={{maxWidth: '100px', minWidth: '100px'}}>
                  <Body2 weight="semibold">Action</Body2>
                </div>
              </HeadlineWrapper>
            </HeadlineWrapperContainer>
            {!isLoading &&
              users.map((user, key) => (
                <ItemWrapperContainer key={key}>
                  <form onSubmit={noReload}>
                    <ItemWrapper>
                      <JobTitleWrapper>
                        <Body2 kind="textBody" style={{marginRight: '14px'}}>
                          {user.jobTitle ? user.jobTitle : '----'}
                        </Body2>
                        {user.jobTitle && user.jobTitle.length > 20 && (
                          <span className="tooltip">{user.jobTitle}</span>
                        )}
                      </JobTitleWrapper>

                      <TextTag2 style={{width: '70px'}}>
                        {titleCase(user.role)}
                      </TextTag2>
                      <DropdownWrapper>
                        <DropdownVertical
                          menu={(handleClose: () => void) => (
                            <div>
                              <form onSubmit={noReload}>
                                <DropdownItem
                                  onClick={() => {
                                    activate(user.id, user.email);
                                    handleClose();
                                  }}>
                                  Activate user
                                </DropdownItem>
                              </form>
                              <DropdownItem
                                onClick={() => {
                                  openModal(user.id, user.email);
                                  handleClose();
                                }}>
                                Delete user
                              </DropdownItem>
                            </div>
                          )}
                          collapseOnClick
                        />
                      </DropdownWrapper>
                    </ItemWrapper>
                  </form>
                </ItemWrapperContainer>
              ))}
          </SecondRowWrapper>
        </div>
        <Modal open={modalIsOpen} onClose={() => {}}>
          <div style={{width: '580px', margin: 'auto'}}>
            <BackAction
              width="md"
              icon={<CancelIcon />}
              onClick={hideModal}
              title="Close"
            />
            <VerticalSpacer size="16px" />
            <ModalCard align="center" title="">
              <form onSubmit={noReload}>
                <Headline2 align="center">Delete {modalEmail}</Headline2>
                <VerticalSpacer size="24px" />
                <Body1 kind="textBody" align="center">
                  Once deleted, the user can't access their account and any data
                  will be deleted after 30 days. User’s license will also become
                  available.
                </Body1>
                <VerticalSpacer size="24px" />
                <Button
                  kind="secondary"
                  isLoading={modalLoading}
                  onClick={() => {
                    deleteUser(modalId, modalEmail);
                  }}
                  width="full">
                  Delete
                </Button>
                <VerticalSpacer size="30px" />
              </form>
            </ModalCard>
          </div>
        </Modal>
        {isLoading && (
          <ItemWrapperContainer>
            <ItemLoaderWrapper>
              <ItemLoaderLine />
            </ItemLoaderWrapper>
          </ItemWrapperContainer>
        )}
        {users.length === 0 && !isLoading ? (
          <NoItemWrapper>
            <Body1 kind="textBody" align="center">
              There are no suspended members
            </Body1>
          </NoItemWrapper>
        ) : null}
      </TableContainer>
      {totalPages > 1 ? (
        <FlexRowCenter style={{width: '100%'}}>
          <PaginationUl>
            <PaginationLi>
              <PaginationButton
                onClick={handlePrevbtn}
                disabled={page === 1 ? true : false}>
                <ArrowHeadLeftIcon />
              </PaginationButton>
            </PaginationLi>
            {pageDecrementBtn}
            {renderPageNumbers}
            {pageIncrementBtn}
            <PaginationLi>
              <PaginationButton
                onClick={handleNextbtn}
                disabled={page === totalPages ? true : false}>
                <ArrowHeadRightIcon />
              </PaginationButton>
            </PaginationLi>
          </PaginationUl>
        </FlexRowCenter>
      ) : null}
    </>
  );
};
