import ad from './images/flags/ad.svg';
import ae from './images/flags/ae.svg';
import af from './images/flags/af.svg';
import ag from './images/flags/ag.svg';
import ai from './images/flags/ai.svg';
import al from './images/flags/al.svg';
import am from './images/flags/am.svg';
import ao from './images/flags/ao.svg';
import aq from './images/flags/aq.svg';
import ar from './images/flags/ar.svg';
import as from './images/flags/as.svg';
import at from './images/flags/at.svg';
import au from './images/flags/au.svg';
import aw from './images/flags/aw.svg';
import ax from './images/flags/ax.svg';
import az from './images/flags/az.svg';
import ba from './images/flags/ba.svg';
import bb from './images/flags/bb.svg';
import bd from './images/flags/bd.svg';
import be from './images/flags/be.svg';
import bf from './images/flags/bf.svg';
import bg from './images/flags/bg.svg';
import bh from './images/flags/bh.svg';
import bi from './images/flags/bi.svg';
import bj from './images/flags/bj.svg';
import bl from './images/flags/bl.svg';
import bm from './images/flags/bm.svg';
import bn from './images/flags/bn.svg';
import bo from './images/flags/bo.svg';
import bq from './images/flags/bq.svg';
import br from './images/flags/br.svg';
import bs from './images/flags/bs.svg';
import bt from './images/flags/bt.svg';
import bv from './images/flags/bv.svg';
import bw from './images/flags/bw.svg';
import by from './images/flags/by.svg';
import bz from './images/flags/bz.svg';
import ca from './images/flags/ca.svg';
import cc from './images/flags/cc.svg';
import cd from './images/flags/cd.svg';
import cf from './images/flags/cf.svg';
import cg from './images/flags/cg.svg';
import ch from './images/flags/ch.svg';
import ci from './images/flags/ci.svg';
import ck from './images/flags/ck.svg';
import cl from './images/flags/cl.svg';
import cm from './images/flags/cm.svg';
import cn from './images/flags/cn.svg';
import co from './images/flags/co.svg';
import cr from './images/flags/cr.svg';
import cu from './images/flags/cu.svg';
import cv from './images/flags/cv.svg';
import cw from './images/flags/cw.svg';
import cx from './images/flags/cx.svg';
import cy from './images/flags/cy.svg';
import cz from './images/flags/cz.svg';
import de from './images/flags/de.svg';
import dj from './images/flags/dj.svg';
import dk from './images/flags/dk.svg';
import dm from './images/flags/dm.svg';
import doz from './images/flags/do.svg';
import dz from './images/flags/dz.svg';
import ec from './images/flags/ec.svg';
import ee from './images/flags/ee.svg';
import eg from './images/flags/eg.svg';
import eh from './images/flags/eh.svg';
import er from './images/flags/er.svg';
import es from './images/flags/es.svg';
import et from './images/flags/et.svg';
import fi from './images/flags/fi.svg';
import fj from './images/flags/fj.svg';
import fk from './images/flags/fk.svg';
import fm from './images/flags/fm.svg';
import fo from './images/flags/fo.svg';
import fr from './images/flags/fr.svg';
import ga from './images/flags/ga.svg';
import gbEng from './images/flags/gb-eng.svg';
import gbNir from './images/flags/gb-nir.svg';
import gbSct from './images/flags/gb-sct.svg';
import gbWls from './images/flags/gb-wls.svg';
import gb from './images/flags/gb.svg';
import gd from './images/flags/gd.svg';
import ge from './images/flags/ge.svg';
import gf from './images/flags/gf.svg';
import gg from './images/flags/gg.svg';
import gh from './images/flags/gh.svg';
import gi from './images/flags/gi.svg';
import gl from './images/flags/gl.svg';
import gm from './images/flags/gm.svg';
import gn from './images/flags/gn.svg';
import gp from './images/flags/gp.svg';
import gq from './images/flags/gq.svg';
import gr from './images/flags/gr.svg';
import gs from './images/flags/gs.svg';
import gt from './images/flags/gt.svg';
import gu from './images/flags/gu.svg';
import gw from './images/flags/gw.svg';
import gy from './images/flags/gy.svg';
import hk from './images/flags/hk.svg';
import hm from './images/flags/hm.svg';
import hn from './images/flags/hn.svg';
import hr from './images/flags/hr.svg';
import ht from './images/flags/ht.svg';
import hu from './images/flags/hu.svg';
import id from './images/flags/id.svg';
import ie from './images/flags/ie.svg';
import il from './images/flags/il.svg';
import im from './images/flags/im.svg';
import inz from './images/flags/in.svg';
import io from './images/flags/io.svg';
import iq from './images/flags/iq.svg';
import ir from './images/flags/ir.svg';
import is from './images/flags/is.svg';
import it from './images/flags/it.svg';
import je from './images/flags/je.svg';
import jm from './images/flags/jm.svg';
import jo from './images/flags/jo.svg';
import jp from './images/flags/jp.svg';
import ke from './images/flags/ke.svg';
import kg from './images/flags/kg.svg';
import kh from './images/flags/kh.svg';
import ki from './images/flags/ki.svg';
import km from './images/flags/km.svg';
import kn from './images/flags/kn.svg';
import kp from './images/flags/kp.svg';
import kr from './images/flags/kr.svg';
import kw from './images/flags/kw.svg';
import ky from './images/flags/ky.svg';
import kz from './images/flags/kz.svg';
import la from './images/flags/la.svg';
import lb from './images/flags/lb.svg';
import lc from './images/flags/lc.svg';
import li from './images/flags/li.svg';
import lk from './images/flags/lk.svg';
import lr from './images/flags/lr.svg';
import ls from './images/flags/ls.svg';
import lt from './images/flags/lt.svg';
import lu from './images/flags/lu.svg';
import lv from './images/flags/lv.svg';
import ly from './images/flags/ly.svg';
import ma from './images/flags/ma.svg';
import mc from './images/flags/mc.svg';
import md from './images/flags/md.svg';
import me from './images/flags/me.svg';
import mf from './images/flags/mf.svg';
import mg from './images/flags/mg.svg';
import mh from './images/flags/mh.svg';
import mk from './images/flags/mk.svg';
import ml from './images/flags/ml.svg';
import mm from './images/flags/mm.svg';
import mn from './images/flags/mn.svg';
import mo from './images/flags/mo.svg';
import mp from './images/flags/mp.svg';
import mq from './images/flags/mq.svg';
import mr from './images/flags/mr.svg';
import ms from './images/flags/ms.svg';
import mt from './images/flags/mt.svg';
import mu from './images/flags/mu.svg';
import mv from './images/flags/mv.svg';
import mw from './images/flags/mw.svg';
import mx from './images/flags/mx.svg';
import my from './images/flags/my.svg';
import mz from './images/flags/mz.svg';
import na from './images/flags/na.svg';
import nc from './images/flags/nc.svg';
import ne from './images/flags/ne.svg';
import nf from './images/flags/nf.svg';
import ng from './images/flags/ng.svg';
import ni from './images/flags/ni.svg';
import nl from './images/flags/nl.svg';
import no from './images/flags/no.svg';
import np from './images/flags/np.svg';
import nr from './images/flags/nr.svg';
import nu from './images/flags/nu.svg';
import nz from './images/flags/nz.svg';
import om from './images/flags/om.svg';
import pa from './images/flags/pa.svg';
import pe from './images/flags/pe.svg';
import pf from './images/flags/pf.svg';
import pg from './images/flags/pg.svg';
import ph from './images/flags/ph.svg';
import pk from './images/flags/pk.svg';
import pl from './images/flags/pl.svg';
import pm from './images/flags/pm.svg';
import pn from './images/flags/pn.svg';
import pr from './images/flags/pr.svg';
import ps from './images/flags/ps.svg';
import pt from './images/flags/pt.svg';
import pw from './images/flags/pw.svg';
import py from './images/flags/py.svg';
import qa from './images/flags/qa.svg';
import re from './images/flags/re.svg';
import ro from './images/flags/ro.svg';
import rs from './images/flags/rs.svg';
import ru from './images/flags/ru.svg';
import rw from './images/flags/rw.svg';
import sa from './images/flags/sa.svg';
import sb from './images/flags/sb.svg';
import sc from './images/flags/sc.svg';
import sd from './images/flags/sd.svg';
import se from './images/flags/se.svg';
import sg from './images/flags/sg.svg';
import sh from './images/flags/sh.svg';
import si from './images/flags/si.svg';
import sj from './images/flags/sj.svg';
import sk from './images/flags/sk.svg';
import sl from './images/flags/sl.svg';
import sm from './images/flags/sm.svg';
import sn from './images/flags/sn.svg';
import so from './images/flags/so.svg';
import sr from './images/flags/sr.svg';
import ss from './images/flags/ss.svg';
import st from './images/flags/st.svg';
import sv from './images/flags/sv.svg';
import sx from './images/flags/sx.svg';
import sy from './images/flags/sy.svg';
import sz from './images/flags/sz.svg';
import tc from './images/flags/tc.svg';
import td from './images/flags/td.svg';
import tf from './images/flags/tf.svg';
import tg from './images/flags/tg.svg';
import th from './images/flags/th.svg';
import tj from './images/flags/tj.svg';
import tk from './images/flags/tk.svg';
import tl from './images/flags/tl.svg';
import tm from './images/flags/tm.svg';
import tn from './images/flags/tn.svg';
import to from './images/flags/to.svg';
import tr from './images/flags/tr.svg';
import tt from './images/flags/tt.svg';
import tv from './images/flags/tv.svg';
import tw from './images/flags/tw.svg';
import tz from './images/flags/tz.svg';
import ua from './images/flags/ua.svg';
import ug from './images/flags/ug.svg';
import um from './images/flags/um.svg';
import us from './images/flags/us.svg';
import uy from './images/flags/uy.svg';
import uz from './images/flags/uz.svg';
import va from './images/flags/va.svg';
import vc from './images/flags/vc.svg';
import ve from './images/flags/ve.svg';
import vg from './images/flags/vg.svg';
import vi from './images/flags/vi.svg';
import vn from './images/flags/vn.svg';
import vu from './images/flags/vu.svg';
import wf from './images/flags/wf.svg';
import ws from './images/flags/ws.svg';
import xk from './images/flags/xk.svg';
import ye from './images/flags/ye.svg';
import yt from './images/flags/yt.svg';
import za from './images/flags/za.svg';
import zm from './images/flags/zm.svg';
import zw from './images/flags/zw.svg';

export const flagList = {
  ad: {
    src: ad,
    extension: 'png',
    variants: [{src: ad, size: '8x'}],
  },
  ae: {
    src: ae,
    extension: 'png',
    variants: [{src: ae, size: '8x'}],
  },
  af: {
    src: af,
    extension: 'png',
    variants: [{src: af, size: '8x'}],
  },
  ag: {
    src: ag,
    extension: 'png',
    variants: [{src: ag, size: '8x'}],
  },
  ai: {
    src: ai,
    extension: 'png',
    variants: [{src: ai, size: '8x'}],
  },
  al: {
    src: al,
    extension: 'png',
    variants: [{src: al, size: '8x'}],
  },
  am: {
    src: am,
    extension: 'png',
    variants: [{src: am, size: '8x'}],
  },
  ao: {
    src: ao,
    extension: 'png',
    variants: [{src: ao, size: '8x'}],
  },
  aq: {
    src: aq,
    extension: 'png',
    variants: [{src: aq, size: '8x'}],
  },
  ar: {
    src: ar,
    extension: 'png',
    variants: [{src: ar, size: '8x'}],
  },
  as: {
    src: as,
    extension: 'png',
    variants: [{src: as, size: '8x'}],
  },
  at: {
    src: at,
    extension: 'png',
    variants: [{src: at, size: '8x'}],
  },
  au: {
    src: au,
    extension: 'png',
    variants: [{src: au, size: '8x'}],
  },
  aw: {
    src: aw,
    extension: 'png',
    variants: [{src: aw, size: '1x'}],
  },
  ax: {
    src: ax,
    extension: 'png',
    variants: [{src: ax, size: '1x'}],
  },
  az: {
    src: az,
    extension: 'png',
    variants: [{src: az, size: '1x'}],
  },
  ba: {
    src: ba,
    extension: 'png',
    variants: [{src: ba, size: '1x'}],
  },
  bb: {
    src: bb,
    extension: 'png',
    variants: [{src: bb, size: '1x'}],
  },
  bd: {
    src: bd,
    extension: 'png',
    variants: [{src: bd, size: '1x'}],
  },
  be: {
    src: be,
    extension: 'png',
    variants: [{src: be, size: '1x'}],
  },
  bf: {
    src: bf,
    extension: 'png',
    variants: [{src: bf, size: '1x'}],
  },
  bg: {
    src: bg,
    extension: 'png',
    variants: [{src: bg, size: '1x'}],
  },
  bh: {
    src: bh,
    extension: 'png',
    variants: [{src: bh, size: '1x'}],
  },
  bi: {
    src: bi,
    extension: 'png',
    variants: [{src: bi, size: '1x'}],
  },
  bj: {
    src: bj,
    extension: 'png',
    variants: [{src: bj, size: '1x'}],
  },
  bl: {
    src: bl,
    extension: 'png',
    variants: [{src: bl, size: '1x'}],
  },
  bm: {
    src: bm,
    extension: 'png',
    variants: [{src: bm, size: '1x'}],
  },
  bn: {
    src: bn,
    extension: 'png',
    variants: [{src: bn, size: '1x'}],
  },
  bo: {
    src: bo,
    extension: 'png',
    variants: [{src: bo, size: '1x'}],
  },
  bq: {
    src: bq,
    extension: 'png',
    variants: [{src: bq, size: '1x'}],
  },
  br: {
    src: br,
    extension: 'png',
    variants: [{src: br, size: '1x'}],
  },
  bs: {
    src: bs,
    extension: 'png',
    variants: [{src: bs, size: '1x'}],
  },
  bt: {
    src: bt,
    extension: 'png',
    variants: [{src: bt, size: '1x'}],
  },
  bv: {
    src: bv,
    extension: 'png',
    variants: [{src: bv, size: '1x'}],
  },
  bw: {
    src: bw,
    extension: 'png',
    variants: [{src: bw, size: '1x'}],
  },
  by: {
    src: by,
    extension: 'png',
    variants: [{src: by, size: '1x'}],
  },
  bz: {
    src: bz,
    extension: 'png',
    variants: [{src: bz, size: '1x'}],
  },
  ca: {
    src: ca,
    extension: 'png',
    variants: [{src: ca, size: '1x'}],
  },
  cc: {
    src: cc,
    extension: 'png',
    variants: [{src: cc, size: '1x'}],
  },
  cd: {
    src: cd,
    extension: 'png',
    variants: [{src: cd, size: '1x'}],
  },
  cf: {
    src: cf,
    extension: 'png',
    variants: [{src: cf, size: '1x'}],
  },
  cg: {
    src: cg,
    extension: 'png',
    variants: [{src: cg, size: '1x'}],
  },
  ch: {
    src: ch,
    extension: 'png',
    variants: [{src: ch, size: '1x'}],
  },
  ci: {
    src: ci,
    extension: 'png',
    variants: [{src: ci, size: '1x'}],
  },
  ck: {
    src: ck,
    extension: 'png',
    variants: [{src: ck, size: '1x'}],
  },
  cl: {
    src: cl,
    extension: 'png',
    variants: [{src: cl, size: '1x'}],
  },
  cm: {
    src: cm,
    extension: 'png',
    variants: [{src: cm, size: '1x'}],
  },
  cn: {
    src: cn,
    extension: 'png',
    variants: [{src: cn, size: '1x'}],
  },
  co: {
    src: co,
    extension: 'png',
    variants: [{src: co, size: '1x'}],
  },
  cr: {
    src: cr,
    extension: 'png',
    variants: [{src: cr, size: '1x'}],
  },
  cu: {
    src: cu,
    extension: 'png',
    variants: [{src: cu, size: '1x'}],
  },
  cv: {
    src: cv,
    extension: 'png',
    variants: [{src: cv, size: '1x'}],
  },
  cw: {
    src: cw,
    extension: 'png',
    variants: [{src: cw, size: '1x'}],
  },
  cx: {
    src: cx,
    extension: 'png',
    variants: [{src: cx, size: '1x'}],
  },
  cy: {
    src: cy,
    extension: 'png',
    variants: [{src: cy, size: '1x'}],
  },
  cz: {
    src: cz,
    extension: 'png',
    variants: [{src: cz, size: '1x'}],
  },
  de: {
    src: de,
    extension: 'png',
    variants: [{src: de, size: '1x'}],
  },
  dj: {
    src: dj,
    extension: 'png',
    variants: [{src: dj, size: '1x'}],
  },
  dk: {
    src: dk,
    extension: 'png',
    variants: [{src: dk, size: '1x'}],
  },
  dm: {
    src: dm,
    extension: 'png',
    variants: [{src: dm, size: '1x'}],
  },
  do: {
    src: doz,
    extension: 'png',
    variants: [{src: doz, size: '1x'}],
  },
  dz: {
    src: dz,
    extension: 'png',
    variants: [{src: dz, size: '1x'}],
  },
  ec: {
    src: ec,
    extension: 'png',
    variants: [{src: ec, size: '1x'}],
  },
  ee: {
    src: ee,
    extension: 'png',
    variants: [{src: ee, size: '1x'}],
  },
  eg: {
    src: eg,
    extension: 'png',
    variants: [{src: eg, size: '1x'}],
  },
  eh: {
    src: eh,
    extension: 'png',
    variants: [{src: eh, size: '1x'}],
  },
  er: {
    src: er,
    extension: 'png',
    variants: [{src: er, size: '1x'}],
  },
  es: {
    src: es,
    extension: 'png',
    variants: [{src: es, size: '1x'}],
  },
  et: {
    src: et,
    extension: 'png',
    variants: [{src: et, size: '1x'}],
  },
  fi: {
    src: fi,
    extension: 'png',
    variants: [{src: fi, size: '1x'}],
  },
  fj: {
    src: fj,
    extension: 'png',
    variants: [{src: fj, size: '1x'}],
  },
  fk: {
    src: fk,
    extension: 'png',
    variants: [{src: fk, size: '1x'}],
  },
  fm: {
    src: fm,
    extension: 'png',
    variants: [{src: fm, size: '1x'}],
  },
  fo: {
    src: fo,
    extension: 'png',
    variants: [{src: fo, size: '1x'}],
  },
  fr: {
    src: fr,
    extension: 'png',
    variants: [{src: fr, size: '1x'}],
  },
  ga: {
    src: ga,
    extension: 'png',
    variants: [{src: ga, size: '1x'}],
  },
  gbEng: {
    src: gbEng,
    extension: 'png',
    variants: [{src: gbEng, size: '1x'}],
  },
  gbNir: {
    src: gbNir,
    extension: 'png',
    variants: [{src: gbNir, size: '1x'}],
  },
  gbSct: {
    src: gbSct,
    extension: 'png',
    variants: [{src: gbSct, size: '1x'}],
  },
  gbWls: {
    src: gbWls,
    extension: 'png',
    variants: [{src: gbWls, size: '1x'}],
  },
  gb: {
    src: gb,
    extension: 'png',
    variants: [{src: gb, size: '1x'}],
  },
  gd: {
    src: gd,
    extension: 'png',
    variants: [{src: gd, size: '1x'}],
  },
  ge: {
    src: ge,
    extension: 'png',
    variants: [{src: ge, size: '1x'}],
  },
  gf: {
    src: gf,
    extension: 'png',
    variants: [{src: gf, size: '1x'}],
  },
  gg: {
    src: gg,
    extension: 'png',
    variants: [{src: gg, size: '1x'}],
  },
  gh: {
    src: gh,
    extension: 'png',
    variants: [{src: gh, size: '1x'}],
  },
  gi: {
    src: gi,
    extension: 'png',
    variants: [{src: gi, size: '1x'}],
  },
  gl: {
    src: gl,
    extension: 'png',
    variants: [{src: gl, size: '1x'}],
  },
  gm: {
    src: gm,
    extension: 'png',
    variants: [{src: gm, size: '1x'}],
  },
  gn: {
    src: gn,
    extension: 'png',
    variants: [{src: gn, size: '1x'}],
  },
  gp: {
    src: gp,
    extension: 'png',
    variants: [{src: gp, size: '1x'}],
  },
  gq: {
    src: gq,
    extension: 'png',
    variants: [{src: gq, size: '1x'}],
  },
  gr: {
    src: gr,
    extension: 'png',
    variants: [{src: gr, size: '1x'}],
  },
  gs: {
    src: gs,
    extension: 'png',
    variants: [{src: gs, size: '1x'}],
  },
  gt: {
    src: gt,
    extension: 'png',
    variants: [{src: gt, size: '1x'}],
  },
  gu: {
    src: gu,
    extension: 'png',
    variants: [{src: gu, size: '1x'}],
  },
  gw: {
    src: gw,
    extension: 'png',
    variants: [{src: gw, size: '1x'}],
  },
  gy: {
    src: gy,
    extension: 'png',
    variants: [{src: gy, size: '1x'}],
  },
  hk: {
    src: hk,
    extension: 'png',
    variants: [{src: hk, size: '1x'}],
  },
  hm: {
    src: hm,
    extension: 'png',
    variants: [{src: hm, size: '1x'}],
  },
  hn: {
    src: hn,
    extension: 'png',
    variants: [{src: hn, size: '1x'}],
  },
  hr: {
    src: hr,
    extension: 'png',
    variants: [{src: hr, size: '1x'}],
  },
  ht: {
    src: ht,
    extension: 'png',
    variants: [{src: ht, size: '1x'}],
  },
  hu: {
    src: hu,
    extension: 'png',
    variants: [{src: hu, size: '1x'}],
  },
  id: {
    src: id,
    extension: 'png',
    variants: [{src: id, size: '1x'}],
  },
  ie: {
    src: ie,
    extension: 'png',
    variants: [{src: ie, size: '1x'}],
  },
  il: {
    src: il,
    extension: 'png',
    variants: [{src: il, size: '1x'}],
  },
  im: {
    src: im,
    extension: 'png',
    variants: [{src: im, size: '1x'}],
  },
  in: {
    src: inz,
    extension: 'png',
    variants: [{src: inz, size: '1x'}],
  },

  io: {
    src: io,
    extension: 'png',
    variants: [{src: io, size: '1x'}],
  },
  iq: {
    src: iq,
    extension: 'png',
    variants: [{src: iq, size: '1x'}],
  },
  ir: {
    src: ir,
    extension: 'png',
    variants: [{src: ir, size: '1x'}],
  },
  is: {
    src: is,
    extension: 'png',
    variants: [{src: is, size: '1x'}],
  },
  it: {
    src: it,
    extension: 'png',
    variants: [{src: it, size: '1x'}],
  },
  je: {
    src: je,
    extension: 'png',
    variants: [{src: je, size: '1x'}],
  },
  jm: {
    src: jm,
    extension: 'png',
    variants: [{src: jm, size: '1x'}],
  },
  jo: {
    src: jo,
    extension: 'png',
    variants: [{src: jo, size: '1x'}],
  },
  jp: {
    src: jp,
    extension: 'png',
    variants: [{src: jp, size: '1x'}],
  },
  ke: {
    src: ke,
    extension: 'png',
    variants: [{src: ke, size: '1x'}],
  },
  kg: {
    src: kg,
    extension: 'png',
    variants: [{src: kg, size: '1x'}],
  },
  kh: {
    src: kh,
    extension: 'png',
    variants: [{src: kh, size: '1x'}],
  },
  ki: {
    src: ki,
    extension: 'png',
    variants: [{src: ki, size: '1x'}],
  },
  km: {
    src: km,
    extension: 'png',
    variants: [{src: km, size: '1x'}],
  },
  kn: {
    src: kn,
    extension: 'png',
    variants: [{src: kn, size: '1x'}],
  },
  kp: {
    src: kp,
    extension: 'png',
    variants: [{src: kp, size: '1x'}],
  },
  kr: {
    src: kr,
    extension: 'png',
    variants: [{src: kr, size: '1x'}],
  },
  kw: {
    src: kw,
    extension: 'png',
    variants: [{src: kw, size: '1x'}],
  },
  ky: {
    src: ky,
    extension: 'png',
    variants: [{src: ky, size: '1x'}],
  },
  kz: {
    src: kz,
    extension: 'png',
    variants: [{src: kz, size: '1x'}],
  },
  la: {
    src: la,
    extension: 'png',
    variants: [{src: la, size: '1x'}],
  },
  lb: {
    src: lb,
    extension: 'png',
    variants: [{src: lb, size: '1x'}],
  },
  lc: {
    src: lc,
    extension: 'png',
    variants: [{src: lc, size: '1x'}],
  },
  li: {
    src: li,
    extension: 'png',
    variants: [{src: li, size: '1x'}],
  },
  lk: {
    src: lk,
    extension: 'png',
    variants: [{src: lk, size: '1x'}],
  },
  lr: {
    src: lr,
    extension: 'png',
    variants: [{src: lr, size: '1x'}],
  },
  ls: {
    src: ls,
    extension: 'png',
    variants: [{src: ls, size: '1x'}],
  },
  lt: {
    src: lt,
    extension: 'png',
    variants: [{src: lt, size: '1x'}],
  },
  lu: {
    src: lu,
    extension: 'png',
    variants: [{src: lu, size: '1x'}],
  },
  lv: {
    src: lv,
    extension: 'png',
    variants: [{src: lv, size: '1x'}],
  },
  ly: {
    src: ly,
    extension: 'png',
    variants: [{src: ly, size: '1x'}],
  },
  ma: {
    src: ma,
    extension: 'png',
    variants: [{src: ma, size: '1x'}],
  },
  mc: {
    src: mc,
    extension: 'png',
    variants: [{src: mc, size: '1x'}],
  },
  md: {
    src: md,
    extension: 'png',
    variants: [{src: md, size: '1x'}],
  },
  me: {
    src: me,
    extension: 'png',
    variants: [{src: me, size: '1x'}],
  },
  mf: {
    src: mf,
    extension: 'png',
    variants: [{src: mf, size: '1x'}],
  },
  mg: {
    src: mg,
    extension: 'png',
    variants: [{src: mg, size: '1x'}],
  },
  mh: {
    src: mh,
    extension: 'png',
    variants: [{src: mh, size: '1x'}],
  },
  mk: {
    src: mk,
    extension: 'png',
    variants: [{src: mk, size: '1x'}],
  },
  ml: {
    src: ml,
    extension: 'png',
    variants: [{src: ml, size: '1x'}],
  },
  mm: {
    src: mm,
    extension: 'png',
    variants: [{src: mm, size: '1x'}],
  },
  mn: {
    src: mn,
    extension: 'png',
    variants: [{src: mn, size: '1x'}],
  },
  mo: {
    src: mo,
    extension: 'png',
    variants: [{src: mo, size: '1x'}],
  },
  mp: {
    src: mp,
    extension: 'png',
    variants: [{src: mp, size: '1x'}],
  },
  mq: {
    src: mq,
    extension: 'png',
    variants: [{src: mq, size: '1x'}],
  },
  mr: {
    src: mr,
    extension: 'png',
    variants: [{src: mr, size: '1x'}],
  },
  ms: {
    src: ms,
    extension: 'png',
    variants: [{src: ms, size: '1x'}],
  },
  mt: {
    src: mt,
    extension: 'png',
    variants: [{src: mt, size: '1x'}],
  },
  mu: {
    src: mu,
    extension: 'png',
    variants: [{src: mu, size: '1x'}],
  },
  mv: {
    src: mv,
    extension: 'png',
    variants: [{src: mv, size: '1x'}],
  },
  mw: {
    src: mw,
    extension: 'png',
    variants: [{src: mw, size: '1x'}],
  },
  mx: {
    src: mx,
    extension: 'png',
    variants: [{src: mx, size: '1x'}],
  },
  my: {
    src: my,
    extension: 'png',
    variants: [{src: my, size: '1x'}],
  },
  mz: {
    src: mz,
    extension: 'png',
    variants: [{src: mz, size: '1x'}],
  },
  na: {
    src: na,
    extension: 'png',
    variants: [{src: na, size: '1x'}],
  },
  nc: {
    src: nc,
    extension: 'png',
    variants: [{src: nc, size: '1x'}],
  },
  ne: {
    src: ne,
    extension: 'png',
    variants: [{src: ne, size: '1x'}],
  },
  nf: {
    src: nf,
    extension: 'png',
    variants: [{src: nf, size: '1x'}],
  },
  ng: {
    src: ng,
    extension: 'png',
    variants: [{src: ng, size: '1x'}],
  },
  ni: {
    src: ni,
    extension: 'png',
    variants: [{src: ni, size: '1x'}],
  },
  nl: {
    src: nl,
    extension: 'png',
    variants: [{src: nl, size: '1x'}],
  },
  no: {
    src: no,
    extension: 'png',
    variants: [{src: no, size: '1x'}],
  },
  np: {
    src: np,
    extension: 'png',
    variants: [{src: np, size: '1x'}],
  },
  nr: {
    src: nr,
    extension: 'png',
    variants: [{src: nr, size: '1x'}],
  },
  nu: {
    src: nu,
    extension: 'png',
    variants: [{src: nu, size: '1x'}],
  },
  nz: {
    src: nz,
    extension: 'png',
    variants: [{src: nz, size: '1x'}],
  },
  om: {
    src: om,
    extension: 'png',
    variants: [{src: om, size: '1x'}],
  },
  pa: {
    src: pa,
    extension: 'png',
    variants: [{src: pa, size: '1x'}],
  },
  pe: {
    src: pe,
    extension: 'png',
    variants: [{src: pe, size: '1x'}],
  },
  pf: {
    src: pf,
    extension: 'png',
    variants: [{src: pf, size: '1x'}],
  },
  pg: {
    src: pg,
    extension: 'png',
    variants: [{src: pg, size: '1x'}],
  },
  ph: {
    src: ph,
    extension: 'png',
    variants: [{src: ph, size: '1x'}],
  },
  pk: {
    src: pk,
    extension: 'png',
    variants: [{src: pk, size: '1x'}],
  },
  pl: {
    src: pl,
    extension: 'png',
    variants: [{src: pl, size: '1x'}],
  },
  pm: {
    src: pm,
    extension: 'png',
    variants: [{src: pm, size: '1x'}],
  },
  pn: {
    src: pn,
    extension: 'png',
    variants: [{src: pn, size: '1x'}],
  },
  pr: {
    src: pr,
    extension: 'png',
    variants: [{src: pr, size: '1x'}],
  },
  ps: {
    src: ps,
    extension: 'png',
    variants: [{src: ps, size: '1x'}],
  },
  pt: {
    src: pt,
    extension: 'png',
    variants: [{src: pt, size: '1x'}],
  },
  pw: {
    src: pw,
    extension: 'png',
    variants: [{src: pw, size: '1x'}],
  },
  py: {
    src: py,
    extension: 'png',
    variants: [{src: py, size: '1x'}],
  },
  qa: {
    src: qa,
    extension: 'png',
    variants: [{src: qa, size: '1x'}],
  },
  re: {
    src: re,
    extension: 'png',
    variants: [{src: re, size: '1x'}],
  },
  ro: {
    src: ro,
    extension: 'png',
    variants: [{src: ro, size: '1x'}],
  },
  rs: {
    src: rs,
    extension: 'png',
    variants: [{src: rs, size: '1x'}],
  },
  ru: {
    src: ru,
    extension: 'png',
    variants: [{src: ru, size: '1x'}],
  },
  rw: {
    src: rw,
    extension: 'png',
    variants: [{src: rw, size: '1x'}],
  },
  sa: {
    src: sa,
    extension: 'png',
    variants: [{src: sa, size: '1x'}],
  },
  sb: {
    src: sb,
    extension: 'png',
    variants: [{src: sb, size: '1x'}],
  },
  sc: {
    src: sc,
    extension: 'png',
    variants: [{src: sc, size: '1x'}],
  },
  sd: {
    src: sd,
    extension: 'png',
    variants: [{src: sd, size: '1x'}],
  },
  se: {
    src: se,
    extension: 'png',
    variants: [{src: se, size: '1x'}],
  },
  sg: {
    src: sg,
    extension: 'png',
    variants: [{src: sg, size: '1x'}],
  },
  sh: {
    src: sh,
    extension: 'png',
    variants: [{src: sh, size: '1x'}],
  },
  si: {
    src: si,
    extension: 'png',
    variants: [{src: si, size: '1x'}],
  },
  sj: {
    src: sj,
    extension: 'png',
    variants: [{src: sj, size: '1x'}],
  },
  sk: {
    src: sk,
    extension: 'png',
    variants: [{src: sk, size: '1x'}],
  },
  sl: {
    src: sl,
    extension: 'png',
    variants: [{src: sl, size: '1x'}],
  },
  sm: {
    src: sm,
    extension: 'png',
    variants: [{src: sm, size: '1x'}],
  },
  sn: {
    src: sn,
    extension: 'png',
    variants: [{src: sn, size: '1x'}],
  },
  so: {
    src: so,
    extension: 'png',
    variants: [{src: so, size: '1x'}],
  },
  sr: {
    src: sr,
    extension: 'png',
    variants: [{src: sr, size: '1x'}],
  },
  ss: {
    src: ss,
    extension: 'png',
    variants: [{src: ss, size: '1x'}],
  },
  st: {
    src: st,
    extension: 'png',
    variants: [{src: st, size: '1x'}],
  },
  sv: {
    src: sv,
    extension: 'png',
    variants: [{src: sv, size: '1x'}],
  },
  sx: {
    src: sx,
    extension: 'png',
    variants: [{src: sx, size: '1x'}],
  },
  sy: {
    src: sy,
    extension: 'png',
    variants: [{src: sy, size: '1x'}],
  },
  sz: {
    src: sz,
    extension: 'png',
    variants: [{src: sz, size: '1x'}],
  },
  tc: {
    src: tc,
    extension: 'png',
    variants: [{src: tc, size: '1x'}],
  },
  td: {
    src: td,
    extension: 'png',
    variants: [{src: td, size: '1x'}],
  },
  tf: {
    src: tf,
    extension: 'png',
    variants: [{src: tf, size: '1x'}],
  },
  tg: {
    src: tg,
    extension: 'png',
    variants: [{src: tg, size: '1x'}],
  },
  th: {
    src: th,
    extension: 'png',
    variants: [{src: th, size: '1x'}],
  },
  tj: {
    src: tj,
    extension: 'png',
    variants: [{src: tj, size: '1x'}],
  },
  tk: {
    src: tk,
    extension: 'png',
    variants: [{src: tk, size: '1x'}],
  },
  tl: {
    src: tl,
    extension: 'png',
    variants: [{src: tl, size: '1x'}],
  },
  tm: {
    src: tm,
    extension: 'png',
    variants: [{src: tm, size: '1x'}],
  },
  tn: {
    src: tn,
    extension: 'png',
    variants: [{src: tn, size: '1x'}],
  },
  to: {
    src: to,
    extension: 'png',
    variants: [{src: to, size: '1x'}],
  },
  tr: {
    src: tr,
    extension: 'png',
    variants: [{src: tr, size: '1x'}],
  },
  tt: {
    src: tt,
    extension: 'png',
    variants: [{src: tt, size: '1x'}],
  },
  tv: {
    src: tv,
    extension: 'png',
    variants: [{src: tv, size: '1x'}],
  },
  tw: {
    src: tw,
    extension: 'png',
    variants: [{src: tw, size: '1x'}],
  },
  tz: {
    src: tz,
    extension: 'png',
    variants: [{src: tz, size: '1x'}],
  },
  ua: {
    src: ua,
    extension: 'png',
    variants: [{src: ua, size: '1x'}],
  },
  ug: {
    src: ug,
    extension: 'png',
    variants: [{src: ug, size: '1x'}],
  },
  um: {
    src: um,
    extension: 'png',
    variants: [{src: um, size: '1x'}],
  },
  us: {
    src: us,
    extension: 'png',
    variants: [{src: us, size: '1x'}],
  },
  uy: {
    src: uy,
    extension: 'png',
    variants: [{src: uy, size: '1x'}],
  },
  uz: {
    src: uz,
    extension: 'png',
    variants: [{src: uz, size: '1x'}],
  },
  va: {
    src: va,
    extension: 'png',
    variants: [{src: va, size: '1x'}],
  },
  vc: {
    src: vc,
    extension: 'png',
    variants: [{src: vc, size: '1x'}],
  },
  ve: {
    src: ve,
    extension: 'png',
    variants: [{src: ve, size: '1x'}],
  },
  vg: {
    src: vg,
    extension: 'png',
    variants: [{src: vg, size: '1x'}],
  },
  vi: {
    src: vi,
    extension: 'png',
    variants: [{src: vi, size: '1x'}],
  },
  vn: {
    src: vn,
    extension: 'png',
    variants: [{src: vn, size: '1x'}],
  },
  vu: {
    src: vu,
    extension: 'png',
    variants: [{src: vu, size: '1x'}],
  },
  wf: {
    src: wf,
    extension: 'png',
    variants: [{src: wf, size: '1x'}],
  },
  ws: {
    src: ws,
    extension: 'png',
    variants: [{src: ws, size: '1x'}],
  },
  xk: {
    src: xk,
    extension: 'png',
    variants: [{src: xk, size: '1x'}],
  },
  ye: {
    src: ye,
    extension: 'png',
    variants: [{src: ye, size: '1x'}],
  },
  yt: {
    src: yt,
    extension: 'png',
    variants: [{src: yt, size: '1x'}],
  },
  za: {
    src: za,
    extension: 'png',
    variants: [{src: za, size: '1x'}],
  },
  zm: {
    src: zm,
    extension: 'png',
    variants: [{src: zm, size: '1x'}],
  },
  zw: {
    src: zw,
    extension: 'png',
    variants: [{src: zw, size: '1x'}],
  },
};
