import {PlusSVG} from '@ui/atoms/svg';
import {Body2} from '@ui/atoms/typography';
import {DropdownCustom} from '@ui/molecules/dropdown-custom';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {FlexRow} from '@ui/style/styles';
import React, {useMemo} from 'react';
import {CheckinsAdditionalFilterOptions} from '../filter-components/checkins';
import {FeedbackAdditionalFilterOptions} from '../filter-components/feedback';
import {MeetingAdditionalFilterOptions} from '../filter-components/meetings';
import {GoalStatusAdditionalFilter} from '../filter-components/objectives';

interface AddFilterProps {
  disableAdditionalFilter?: boolean;
  filters: string[];
  updateFilters: (value: string) => void;
  type: string;
}
export const AddFilter = ({
  disableAdditionalFilter,
  filters,
  type,
  updateFilters,
}: AddFilterProps) => {
  const computeAdditionalFilters = useMemo(() => {
    if (type === 'feedback') return FeedbackAdditionalFilterOptions;
    if (type === 'checkin') return CheckinsAdditionalFilterOptions;
    if (type === 'meetings') return MeetingAdditionalFilterOptions;
    if (type === 'objectives') return GoalStatusAdditionalFilter;
    return [];
  }, [type]);

  const getDropdownFilters = computeAdditionalFilters?.filter(
    (filter) => !filters.includes(filter.value),
  );

  const filterIsDisabled =
    disableAdditionalFilter ||
    (!getDropdownFilters?.length && filters.includes('breakdown'));

  return (
    <div>
      <div className="p-4 ">
        <DropdownCustom
          collapseOnClick={true}
          menu={(handleClose: () => void) => (
            <div>
              <div>
                {getDropdownFilters?.map((filter) => (
                  <DropdownItem
                    key={filter.value}
                    onClick={() => {
                      handleClose();

                      updateFilters(filter.value);
                    }}>
                    {filter.label}
                  </DropdownItem>
                ))}

                {!filters.includes('breakdown') && (
                  <DropdownItem
                    onClick={() => {
                      handleClose();
                      updateFilters('breakdown');

                      //   setAdditionalFilters([...filters, ]);
                    }}>
                    Breakdown
                  </DropdownItem>
                )}
              </div>
            </div>
          )}
          customComponent={(
            handleOpen: (event: any) => void,
            open: boolean,
          ) => (
            <FlexRow
              className={
                filterIsDisabled ? 'cursor-not-allowed' : 'cursor-pointer'
              }
              onClick={(event) =>
                !filterIsDisabled && (handleOpen(event) as any)
              }>
              <PlusSVG
                fill={filterIsDisabled ? '#BFBFD4' : undefined}
                style={{width: '18px', height: '18px'}}
              />
              <Body2
                weight="bold"
                kind={filterIsDisabled ? 'textMuted' : 'textDark'}
                className="ml-2">
                Add filter
              </Body2>
            </FlexRow>
          )}
          customDropdownWrapperStyles={{
            top: '130%',
            left: 0,
            width: '190px',
          }}
        />
      </div>
    </div>
  );
};
