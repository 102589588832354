import React, {StrictMode} from 'react';
import {observer} from 'mobx-react';
import {QueryClient, QueryClientProvider} from 'react-query';
import {ReactQueryDevtools} from 'react-query/devtools';
import {StyleProvider} from './ui/style/provider';
import {App} from './app';
import {installRoutes} from './routes/install-routes';
import * as Sentry from '@sentry/react';
import {PostHogProvider} from 'posthog-js/react';

const options = {
  api_host:
    process.env.REACT_APP_DEV_ENV === 'staging'
      ? undefined
      : process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  autocapture: false,
};

export function installClient() {
  const {Routes} = installRoutes();
  const AppView = observer(() => <App children={<Routes />} />);
  const queryClient = new QueryClient();

  const Page = (
    <StrictMode>
      <PostHogProvider
        apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
        options={options}>
        <Sentry.ErrorBoundary>
          <QueryClientProvider client={queryClient}>
            <StyleProvider>
              <AppView />
              <ReactQueryDevtools />
            </StyleProvider>
          </QueryClientProvider>
        </Sentry.ErrorBoundary>
      </PostHogProvider>
    </StrictMode>
  );

  return {
    Page,
  };
}
