import {useState, useRef} from 'react';
import {SectionCard} from '../../../../../../ui/layouts/section-card';
import {Button} from '../../../../../../ui/atoms/button';
import {TextField} from '../../../../../../ui/molecules/field/textfield';
import {SelectField} from '../../../../../../ui/molecules/select/selectfield';
import {VerticalSpacer} from '../../../../../../ui/atoms/spacer';
import {EditIcon} from '../../../../../../ui/atoms/icons';
import {Avatar} from '../../../../../../ui/atoms/avatar';
import {Image} from '../../../../../../ui/atoms/image';
import React, {memo} from 'react';
import {useNavigate} from 'react-router';
import {FlexRowEnd, FlexRowSpaceBetween} from '@ui/style/styles';
import {authStore} from '../../../../../../store/stores/auth-store';
import {useAdminWorkspaceHook} from '../admin-workspace-hooks';
import {Controller} from 'react-hook-form';
import {Body1, Body2} from '../../../../../../ui/atoms/typography';
import dayjs from 'dayjs';
import {useStoreContext} from '../../../../../../store/store-context';
import {timezones} from '../../../../../../constants';
import {FlexRow} from '@ui/style/styles';
import {Modal} from '@ui/molecules/modal';
import {useFreeTierHook} from '@hooks/free-tier';
import FreeTierModal from '@ui/organisms/free-tier/free-tier-modal';
import {LockIcon} from '../../../../../../ui/atoms/icons';
import {illustrationList} from '@ui/assets/illustrations';
import {CancelIcon} from '../../../../../../ui/atoms/icons';
import {ModalCard} from '@ui/layouts/modal-card';
import {BackAction} from '@ui/molecules/back-action';
import {SetupWorkspaceController} from '@pages/admin/setup-workspace/setup-workspace-controller';
import {SetupWorkspaceRequestImpl} from '@pages/admin/setup-workspace/setup-workspace-request';
import {useMemo} from 'react';
import {generateRandomDigits} from '@utils/generate-random-numbers';
import {SwitchToggle} from '@ui/molecules/toggle-switch/toggle-switch';
import {activateNotification} from '../../../../../../ui/molecules/notification/activate-notification';
import {ItemLoader} from '../../../../../../ui/organisms/item-loader';
import {BaseURL} from '../../../../../../configs/request';
import {
  PageLayout,
  LeftChildInput,
  LeftChildSpan,
  SectionComponentWrapper,
  PeriodWrapper,
  LeftWrapper,
  RightChildSpan,
  RightChildWrapper,
  RightWrapper,
} from '../admin-workspace-styled';
import {Headline2} from '../../../../../../ui/atoms/typography';
import {getStrBtwCharacters} from '../../../../../../utils/strings';
import {setFirebaseData} from '@utils/firebase-handler';

export const AdminSettings = memo(() => {
  const {
    isSubmitting,
    handleSubmit,
    submitForm,
    register,
    errors,
    control,
    image,
    handleChange,
    isDisabled,
    handleSelectChange,
    base64Upload,
    users,
    isLoading,
  } = useAdminWorkspaceHook();
  const {
    authStore: {setInitResponseAsAuth, setAuthResponse, auth},
  } = useStoreContext();

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const handleClick = (e: any) => {
    hiddenFileInput?.current?.click();
    e.preventDefault();
  };

  const [progress, setProgress] = useState(false);
  const navigate = useNavigate();
  const uploadAvatar = async (e: any) => {
    e.preventDefault();
    let avatarData = {
      avatar: base64Upload,
    };
    setProgress(true);
    await fetch(
      `${BaseURL}/workspaces/${authStore.auth?.user.workspace.id}/upload-avatar`,
      {
        method: 'POST',

        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
        body: JSON.stringify(avatarData),
      },
    )
      .then((res) => {
        if (res.ok) {
          activateNotification({
            title: 'Success',
            content: 'Workspace avatar uploaded Successfully',
            kind: 'success',
          });
        } else {
          activateNotification({
            title: 'Error',
            content: res.statusText,
            kind: 'error',
          });
        }

        return res.json();
      })
      .then((res) => {
        if (localStorage && localStorage.auth && !!localStorage.auth) {
          const storedAuthObject = JSON.parse(localStorage.auth);
          storedAuthObject.user.workspace = res;
          localStorage.setItem('auth', JSON.stringify(storedAuthObject));
          setAuthResponse(storedAuthObject);
        }
        setInitResponseAsAuth();
        navigate('/', {replace: true});
        navigate('/workspace-settings/profile', {replace: true});
      });
  };

  const {
    isAccountFreeTier,
    showFreeTierModal,
    handleShowFreeTierModal,
  } = useFreeTierHook();

  const DisplayFreeTierModal = (
    <FreeTierModal
      open={showFreeTierModal}
      image={illustrationList.time}
      onClose={() => handleShowFreeTierModal(false)}
      description={`Choose custom periods that accurately reflect your business performance within a given time frame, making it simpler to track and analyze your progress towards achieving goals.`}
      title={`Customize the Quarter Periods to Match your Reporting Cycles`}
    />
  );

  const handleEditPeriod = async (period: boolean) => {
    const request = new SetupWorkspaceRequestImpl();
    const controller = new SetupWorkspaceController(request);

    const response = await controller.workspaceEdit(
      auth?.user?.workspace?.id,
      {
        settings: {
          ...auth?.user?.workspace?.settings,
          customPeriod: period,
        },
      },
      auth?.tokens?.access?.token,
    );

    response &&
      period &&
      activateNotification({
        title: 'Custom Period enabled',
        content: `Settings updated`,
        kind: 'success',
      });

    response &&
      !period &&
      activateNotification({
        title: 'Custom period  disabled',
        content: `Settings updated`,
        kind: 'success',
      });

    response && setFirebaseData(`updates/settings`, generateRandomDigits(16));
  };

  const [startMonth, setStartMonth] = useState(
    auth?.user?.workspace?.settings?.customPeriodStartMonth || 'January',
  );

  const addDate = (date: any, period: number) => {
    return dayjs(date).add(period, 'months').format('DD-MMMM-YYYY');
  };

  const periodEnabled = auth?.user?.workspace?.settings?.customPeriod;

  const [periodUpdated, setPeriodUpdated] = useState(false);

  const [showPeriodModal, setShowPeriodModal] = useState<
    'enable' | 'disable' | ''
  >('');

  const [periodIsEditing, setPeriodIsEditing] = useState(false);

  const addRange = (
    dateRange: {starts: string; ends: string},
    startAddValue: number,
    endAddValue: number,
  ) => {
    const starts = dayjs(dateRange.starts)
      .add(startAddValue, 'months')
      .format('DD MMMM');

    const ends = dayjs(dateRange.ends)
      .add(endAddValue, 'months')
      .format('DD MMMM');

    return {starts, ends};
  };

  const periods = useMemo(() => {
    const periodDate = {
      starts: `01-${startMonth}-${new Date().getFullYear()}`,
      ends: addDate(
        `${dayjs(
          `01-${startMonth}-${new Date().getFullYear()}`,
        ).daysInMonth()}-${startMonth}-${new Date().getFullYear()}`,
        2,
      ),
    };

    return [
      {
        item: 'Q1',
        dateRange: periodDate,
      },
      {
        item: 'Q2',
        dateRange: addRange(periodDate, 3, 3),
      },
      {
        item: 'Q3',
        dateRange: addRange(periodDate, 6, 6),
      },
      {
        item: 'Q4',
        dateRange: addRange(periodDate, 9, 9),
      },
    ];
  }, [startMonth]);

  const getOptions = [
    {
      label: '01 January - 31 March',
      value: 'January',
    },
    {
      label: '01 April - 30 June',
      value: 'April',
    },
    {
      label: '01 July - 30 September',
      value: 'July',
    },
    {
      label: '01 October - 31 December',
      value: 'October',
    },
  ];

  const handleSaveCustomPeriod = async () => {
    const request = new SetupWorkspaceRequestImpl();
    const controller = new SetupWorkspaceController(request);

    const response = await controller.workspaceEdit(
      auth?.user?.workspace?.id,
      {
        settings: {
          ...auth?.user?.workspace?.settings,
          customPeriodStartMonth: startMonth,
        },
      },
      auth?.tokens?.access?.token,
    );
    response &&
      activateNotification({
        title: 'Custom Period updated',
        content: `Settings updated`,
        kind: 'success',
      });

    response && setFirebaseData(`updates/settings`, generateRandomDigits(16));
  };

  const selectRef = useRef(null);
  return (
    <>
      {DisplayFreeTierModal}{' '}
      {isLoading && (
        <PageLayout>
          <Headline2>Edit icon</Headline2>
          <LeftWrapper>
            {image.preview ? (
              <Image
                src={image.preview}
                alt="preview"
                width="75"
                height="75"
                style={{borderRadius: '50%', objectFit: 'cover'}}
              />
            ) : (
              <>
                <Avatar
                  alt="Image"
                  name={users.Name}
                  size="xl"
                  src={users.avatar}
                  tooltip={false}
                  userId=""
                />
              </>
            )}
            <LeftChildSpan className="bottom-2">
              {image.preview ? (
                <Button
                  width="xm"
                  kind="primary"
                  onClick={uploadAvatar}
                  isLoading={progress}
                  aria-busy="true">
                  Save
                </Button>
              ) : (
                <Button
                  width="xm"
                  kind="secondary"
                  className="flex items-center"
                  onClick={handleClick}>
                  Edit
                  <div className="flex items-center ml-2">
                    <EditIcon />
                  </div>
                </Button>
              )}
            </LeftChildSpan>
            <LeftChildInput
              type="file"
              ref={hiddenFileInput}
              id="edit"
              accept="image/*"
              onChange={handleChange}
            />
          </LeftWrapper>
          <RightWrapper>
            <form method="patch" onSubmit={handleSubmit(submitForm)}>
              <SectionCard
                headerPadding="small"
                action={
                  <Button
                    type="submit"
                    width="sm"
                    disabled={isDisabled || image.disable}
                    data-form-action={true}
                    isLoading={isSubmitting}
                    aria-busy={isSubmitting}>
                    Save Changes
                  </Button>
                }
                title="Edit profile">
                <RightChildWrapper>
                  <RightChildSpan>
                    <VerticalSpacer size="10px" />
                    <TextField
                      label="Workspace Name"
                      defaultValue={users.Name}
                      placeholder="Workspace Name"
                      autoComplete="text"
                      type="text"
                      ref={register}
                      helper={errors.name?.message || ''}
                      state={!!errors.name ? 'error' : 'default'}
                      name="name"
                    />
                  </RightChildSpan>
                  <RightChildSpan>
                    <VerticalSpacer size="10px" />
                    <TextField
                      label="Contact Info"
                      defaultValue={users.email}
                      placeholder="Enter Email"
                      type="email"
                      ref={register}
                      helper={errors.contactEmail?.message || ''}
                      state={!!errors.contactEmail ? 'error' : 'default'}
                      name="contactEmail"
                    />
                  </RightChildSpan>
                  <RightChildSpan>
                    <VerticalSpacer size="20px" />
                    <Controller
                      control={control}
                      name="timezone"
                      defaultValue={users.Timezone}
                      render={({onBlur, value}) => (
                        <SelectField
                          onBlur={onBlur}
                          name="timezone"
                          selected="first"
                          placeholder="Select a time-zone"
                          value={value}
                          label="Time Zone"
                          options={timezones.map(
                            (timezone: {label: string; value: string}) => ({
                              label: `${timezone?.label} (${getStrBtwCharacters(
                                timezone?.value,
                                '(',
                                ')',
                              )})`,
                              value: timezone?.value,
                            }),
                          )}
                          helper={errors.timezone?.message || ''}
                          state={!!errors.timezone ? 'error' : 'default'}
                          onChange={(data: {value: string}) =>
                            data !== null && handleSelectChange(data.value)
                          }
                        />
                      )}
                    />
                  </RightChildSpan>
                </RightChildWrapper>
              </SectionCard>
            </form>
            <PeriodWrapper className="bg-white">
              <FlexRowSpaceBetween className="space-between">
                <div>
                  <Body1 weight="semibold">
                    {' '}
                    {isAccountFreeTier && (
                      <span style={{marginRight: '3px'}}>
                        <LockIcon />
                      </span>
                    )}
                    Enable periods
                  </Body1>
                  <VerticalSpacer size="8px" />
                  <Body2 kind="textBody">
                    Use preset timelines for goals, reporting and review cycles.{' '}
                  </Body2>
                </div>
                <FlexRowEnd style={{width: '100%'}}>
                  <SwitchToggle
                    onChange={(value) => {
                      if (value.target.checked) {
                        setShowPeriodModal('enable');

                        return;
                      }
                      setShowPeriodModal('disable');
                    }}
                    disabled={isAccountFreeTier}
                    checked={periodEnabled}
                  />
                </FlexRowEnd>
              </FlexRowSpaceBetween>
              <div className="settings-container">
                <div className="periods-header">
                  <FlexRowSpaceBetween>
                    <Body1 weight="semibold">Period settings</Body1>
                    <FlexRow>
                      {!periodIsEditing ? (
                        <Button
                          disabled={!periodEnabled}
                          kind="secondary"
                          onClick={() =>
                            isAccountFreeTier
                              ? handleShowFreeTierModal(true)
                              : setPeriodIsEditing(true)
                          }
                          style={{padding: '8px 16px'}}>
                          Edit
                        </Button>
                      ) : (
                        <FlexRow>
                          <Button
                            disabled={!periodUpdated}
                            onClick={() => {
                              handleSaveCustomPeriod();
                              setPeriodIsEditing(false);
                            }}
                            style={{padding: '8px 16px'}}>
                            Save
                          </Button>
                          <Button
                            kind="secondary"
                            onClick={() => setPeriodIsEditing(false)}
                            style={{padding: '8px 16px', marginLeft: '8px'}}>
                            Cancel
                          </Button>
                        </FlexRow>
                      )}
                    </FlexRow>
                  </FlexRowSpaceBetween>
                </div>
                <div className="periods-select">
                  <SectionComponentWrapper>
                    {periods.map((period, index) => (
                      <div className="periodParentDivs" key={index + 'yyy'}>
                        <div className="period">
                          <Body1
                            kind={!periodEnabled ? 'textMuted' : 'textMedium'}>
                            {period.item}
                          </Body1>
                        </div>
                        <div className="duration">
                          {index === 0 ? (
                            <SelectField
                              defaultValue={`${dayjs(
                                period.dateRange.starts,
                              ).format('MMMM')}`}
                              name={`period.${period.item}`}
                              margin
                              excludeSearchBox
                              ref={selectRef}
                              onChange={(data: {value: string}) => {
                                setStartMonth(data.value);
                                setPeriodUpdated(true);
                              }}
                              placeholder={'Select Date Range'}
                              options={getOptions}
                              disabled={
                                index !== 0 ||
                                !periodEnabled ||
                                !periodIsEditing
                              }
                            />
                          ) : (
                            <div className="date-range">
                              <Body1 kind="textMuted">{`${period.dateRange.starts} - ${period.dateRange.ends}`}</Body1>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </SectionComponentWrapper>
                </div>
              </div>
            </PeriodWrapper>
          </RightWrapper>
        </PageLayout>
      )}
      {!isLoading && <ItemLoader />}
      <Modal open={!!showPeriodModal} onClose={() => {}}>
        <div style={{maxWidth: '560px', margin: 'auto', marginTop: '10%'}}>
          <BackAction
            width="md"
            icon={<CancelIcon />}
            onClick={() => setShowPeriodModal('')}
            title="Close"
          />
          <VerticalSpacer size="16px" />
          <ModalCard align="center" title="">
            <div>
              <Headline2 align="center">
                {showPeriodModal === 'enable' ? 'Enable' : 'Disable'} Periods
              </Headline2>

              <VerticalSpacer size="16px" />

              <Body1 kind="textBody" align="center">
                {showPeriodModal === 'enable'
                  ? `Preset timelines will be used to filter dates for goals, check-ins, reports and review cycles.`
                  : showPeriodModal === 'disable' &&
                    `This will remove preset timelines used to filter dates for goals, check-ins, reports and review cycles.`}
              </Body1>
              <VerticalSpacer size="12px" />
              <Body1 kind="textBody" align="center">
                Click <span style={{fontWeight: 700}}>“confirm”</span> to
                continue.
              </Body1>
              <VerticalSpacer size="24px" />

              <Button
                onClick={() => {
                  handleEditPeriod(showPeriodModal === 'enable' ? true : false);
                  setShowPeriodModal('');
                }}
                width="full">
                Confirm
              </Button>
              <VerticalSpacer size="64px" />
            </div>
          </ModalCard>
        </div>
      </Modal>
    </>
  );
});

AdminSettings.displayName = 'AdminSettings';
