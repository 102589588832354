import {Button} from '@ui/atoms/button';
import {Body2, Body1} from '@ui/atoms/typography';
import {VerticalSpacer} from '@ui/atoms/spacer/spacer';
import {FlexRowCenter} from '@ui/style/styles';
import {goalDueDate} from '@utils/date';
import {getColorFromTheme} from '@ui/ui-utils';
import styled from 'styled-components';
import {useStoreContext} from '@store/store-context';

import {Table, TableHeader, TableRow, TableWrapper} from '../../cc-styles';
import {UserListCard} from '@ui/molecules/user/list-card';
import dayjs from 'dayjs';
import {useNominationHook} from '../../nomination.hook';
import {useFirebaseFetch} from '@hooks/query-hook';
import {useCountdown} from '@utils/countdown';
import {useQuery} from 'react-query';
import {CcRequestImpl} from '@api/cc-api/cc-request';
import {CcController} from '@api/cc-api/cc-controller';

export const Wrapper = styled.div`
  width: 100%;
`;
const Card = styled.div`
  border: 1px solid ${getColorFromTheme('borderDark')};
  border-radius: 8px;
  height: 38px;
  background: white;
  width: 168px;
  padding: 8px 16px;
`;

export default function ManagerReview({
  disabled,
  dueDay,
  reviewType,
  cycleId,
}: {
  disabled: boolean;
  dueDay: string;
  cycleId: string;
  reviewType: string;
}) {
  const {
    authStore: {auth},
    usersStore: {users},
  } = useStoreContext();

  const {sendManagerReminder, reminderLoading} = useNominationHook();

  const reminderId = `users/${auth.user.id}/review_reminder/${cycleId}/${auth.user.reviewer}`;

  const {data: reminderExists} = useFirebaseFetch(`${reminderId}`);

  const {minutes, seconds} = useCountdown(reminderExists || 0);

  const request = new CcRequestImpl();
  const controller = new CcController(request);

  const fetchWrittenReviews = async () => {
    const response = await controller.fetchWrittenReview({
      reviewCycle: cycleId,
      reviewer: auth.user.reviewer,
      reviewerType: 'manager',
    });

    return response;
  };

  const {data: writtenReviews} = useQuery(
    ['written-review', cycleId, 'manager'],
    () => fetchWrittenReviews(),
  );

  // TODO: fix written review logic
  const isReviewWritten = () => {
    return !!writtenReviews?.find(
      (review: {user: string; reviewer: string}) =>
        review.reviewer === auth.user.reviewer,
    );
  };

  const isDisabled =
    dayjs().isAfter(dueDay) || disabled || minutes > 0 || isReviewWritten();

  return (
    <div>
      <Wrapper>
        <Body1 weight="bold">Manager</Body1>

        <VerticalSpacer size="7px" />
        <TableWrapper>
          <Table>
            <TableHeader variant="you">
              <td style={{maxWidth: '150px', minWidth: '150px'}}>
                <Body2 kind="textBody">Name</Body2>
              </td>

              <td style={{maxWidth: '100px', minWidth: '100px'}}>
                <Body2 kind="textBody">Progress</Body2>
              </td>
              <td style={{maxWidth: '100px', minWidth: '100px'}}>
                <Body2 kind="textBody">Actions</Body2>
              </td>
            </TableHeader>
            {[''].map(() => (
              <TableRow variant="you">
                <div style={{display: 'flex', alignItems: 'center'}}>
                  {users
                    .filter((user) => user.id === auth.user.reviewer)
                    .map((user) => (
                      <UserListCard
                        kind="admin"
                        type="secondary"
                        textStyle={{fontWeight: 600}}
                        tooltip={true}
                        avatar={user.firstName ? user.firstName : ''}
                        reviewer={user.reviewer}
                        name={
                          user.firstName && user.lastName
                            ? `${user.firstName} ${user.lastName}`
                            : ` ... (pending)`
                        }
                        userId={user.id}
                      />
                    ))}
                </div>

                <div>
                  <Card>
                    <FlexRowCenter>
                      <Body2
                        weight="semibold"
                        kind={isReviewWritten() ? 'green400' : 'orange300'}>
                        {isReviewWritten()
                          ? 'Completed'
                          : goalDueDate(dueDay, false, false, true)}
                      </Body2>
                    </FlexRowCenter>
                  </Card>
                </div>
                <div>
                  <Button
                    disabled={isDisabled}
                    isLoading={reminderLoading}
                    onClick={() =>
                      sendManagerReminder(
                        auth.user.id,
                        auth.user.reviewer,
                        cycleId,
                      )
                    }
                    kind="secondary"
                    style={{
                      padding: '8px 16px',
                    }}>
                    {minutes > 0
                      ? `Resend  (${minutes}:${seconds})`
                      : 'Send reminder'}
                  </Button>
                </div>
              </TableRow>
            ))}
          </Table>
        </TableWrapper>
      </Wrapper>
    </div>
  );
}
