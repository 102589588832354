import {useMemo, useState, useCallback, useEffect} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm, Resolver} from 'react-hook-form';
import {useNavigate} from 'react-router';
import {groupFormData} from './admin-group-interface';
import {adminGroupValidator} from './admin-group-validator';
import {useQuery} from 'react-query';
import {generateRandomDigits} from '@utils/generate-random-numbers';
import {useSearchParams} from 'react-router-dom';
import {useApiCalls} from '@hooks/init-calls';
import {groupController} from './admin-group-controller';
import {groupRequestImpl} from './admin-group-request';
import {useStoreContext} from '../../../../../../store/store-context';
import {setFirebaseData} from '@utils/firebase-handler';

export const useCreateGroup = () => {
  const request = new groupRequestImpl();
  const controller = new groupController(request);
  const {initGroups} = useApiCalls();

  const [searchParams] = useSearchParams();
  const drawerOpen = searchParams.get('group-drawer');

  const {setInitResponseAsAuth} = useStoreContext().authStore;
  const navigate = useNavigate();

  const submitCreateGroup = async (data: groupFormData) => {
    await controller.newCreateGroup(data);
    setInitResponseAsAuth();
    setFirebaseData(`updates/group`, generateRandomDigits(16));

    initGroups();
    if (!drawerOpen) {
      navigate('/home');
      navigate('/workspace-settings/groups');
    }
  };
  return submitCreateGroup;
};

export function useAdminGroupHook() {
  const [hideCreateGroupModal, showCreateGroupModal] = useState<boolean>(false);
  const [hideEditGroupModal, showEditGroupModal] = useState<boolean>(false);
  const [hideDeleteGroupModal, showDeleteGroupModal] = useState<boolean>(false);
  const submitCreateGroup = useCreateGroup();
  const {
    register,
    handleSubmit,
    errors,
    control,
    formState,
    watch,
    setValue,
  } = useForm<groupFormData>({
    resolver: yupResolver(adminGroupValidator) as Resolver<
      groupFormData,
      object
    >,
    mode: 'onSubmit',
  });

  const {handleSubmit: handleDelete, formState: deleteFormState} = useForm({
    mode: 'onSubmit',
  });
  const {initGroups} = useApiCalls();

  const isSubmittingCreateGroup = useMemo(() => {
    return formState.isSubmitting;
  }, [formState]);

  const isSubmittingDeleteGroup = useMemo(() => {
    return deleteFormState.isSubmitting;
  }, [deleteFormState]);

  const selectGroupAdmin = (value: string) => {
    setValue('groupAdmin', value);
  };
  const selectUsers = (value: any) => {
    setValue('users', value);
  };

  const {setInitResponseAsAuth} = useStoreContext().authStore;
  //const {usersStore} = useStoreContext();
  const request = new groupRequestImpl();
  const controller = new groupController(request);
  //const {uiUsersFormat} = usersStore;
  const navigate = useNavigate();

  const [disabledCreateGroup, setDisabledCreateGroup] = useState(true);
  const [disabledEditGroup, setDisabledEditGroup] = useState(true);
  const {name, groupAdmin, users} = watch();

  useMemo(() => {
    name && groupAdmin && users
      ? setDisabledCreateGroup(false)
      : setDisabledCreateGroup(true);
  }, [groupAdmin, name, users]);

  useMemo(() => {
    name || groupAdmin || users
      ? setDisabledEditGroup(false)
      : setDisabledEditGroup(true);
  }, [groupAdmin, name, users]);

  const [isFetchingGroupMembers, setIsFetchingGroupMembers] = useState(false);
  const {groupTypeIdInStore} = useStoreContext().groupTypeStore;

  const fetchGroup = useCallback(async () => {
    const request = new groupRequestImpl();
    const controller = new groupController(request);

    const response: any = await controller.newfetchGroup(groupTypeIdInStore);

    return response;
  }, [groupTypeIdInStore]);

  const {isLoading: isFetchingGroup} = useQuery(
    ['fetchGroup', groupTypeIdInStore],
    () => fetchGroup(),
  );

  const {group, groupMembers} = useStoreContext().groupStore;
  const [groupName, setGroupName] = useState<string>();
  const [groupId, setGroupId] = useState<string>();
  const [groupAdminId, setGroupAdminId] = useState<string>();

  const submitEditGroup = async (data: groupFormData) => {
    const {users, ...groupData} = data;

    if (users) {
      const defaultData = groupMembers.map((mem: any) => mem.id);

      const selectedData = users;
      const toBeRemoved = defaultData.filter(
        (user: any) => !selectedData.includes(user),
      );
      const toBeAdded = selectedData.filter(
        (user: any) => !defaultData.includes(user),
      );
      if (toBeAdded.length > 0 || toBeRemoved.length > 0) {
        const dt = {
          add: {users: toBeAdded, type: 'add'},
          remove: {users: toBeRemoved},
        };

        await controller.newAddGroupMember(dt, groupId);
      }
    }
    await controller.newEditGroup(groupData, groupId);

    setFirebaseData(`updates/group`, generateRandomDigits(16));

    initGroups();

    setInitResponseAsAuth();
    navigate('/home');
    navigate('/workspace-settings/groups');
  };
  const submitDeleteGroup = async () => {
    await controller.newDeleteGroup(groupId);
    setInitResponseAsAuth();
    showDeleteGroupModal(false);

    setFirebaseData(`updates/group`, generateRandomDigits(16));

    initGroups();

    navigate('/home');
    navigate('/workspace-settings/groups');
  };

  const [searchGroup, setSearchGroup] = useState<string>();
  const groupData = group.filter((group: any) =>
    searchGroup?.length === 0 || searchGroup === undefined
      ? group
      : group.name.toLowerCase().includes(searchGroup),
  );

  const fetchGroupMembers = useCallback(async () => {
    const request = new groupRequestImpl();
    const controller = new groupController(request);
    setIsFetchingGroupMembers(true);
    await controller.newfetchGroupMembers(groupId);
    setIsFetchingGroupMembers(false);
  }, [groupId]);

  useEffect(() => {
    if (groupId !== undefined || '') {
      fetchGroupMembers();
    }
  }, [fetchGroupMembers, groupId]);

  return {
    hideCreateGroupModal,
    showCreateGroupModal,
    hideEditGroupModal,
    showEditGroupModal,
    hideDeleteGroupModal,
    showDeleteGroupModal,
    register,
    errors,
    isSubmittingCreateGroup,
    handleSubmit,
    submitCreateGroup,
    disabledCreateGroup,
    disabledEditGroup,
    isFetchingGroup,
    isFetchingGroupMembers,
    groupData,
    groupName,
    groupId,
    setGroupName,
    setGroupId,
    submitEditGroup,
    submitDeleteGroup,
    handleDelete,
    isSubmittingDeleteGroup,
    setSearchGroup,
    searchGroup,
    control,
    selectGroupAdmin,
    groupAdminId,
    setGroupAdminId,
    selectUsers,
  };
}
