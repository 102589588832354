import {action} from 'mobx';
import {ForgotPasswordFormData} from './forgot-password-interface';
import {ForgotPasswordRequest} from './forgot-password-request';

export class ForgotPasswordController {
  constructor(private readonly request: ForgotPasswordRequest) {}

  @action
  async forgotPassword(data: ForgotPasswordFormData) {
    try {
      await this.request.forgotPassword(data);
      return true;
    } catch (e) {
      return true;
    }
  }
}
