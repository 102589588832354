import {observer} from 'mobx-react';
import {FC, useMemo, useState} from 'react';
import {useStoreContext} from '../../../../../../../store/store-context';
import {ArrowHeadDownIcon} from '../../../../../../../ui/atoms/icons';
import {Body1} from '../../../../../../../ui/atoms/typography';
import {DateRangeInput} from '../../../../../../../ui/molecules/date-range-input';
import {ActionSelectField} from '../../../../../../../ui/molecules/select/action-selectfield';
import {capitalize} from '../../../../../../../utils';
import {cfv} from '../../../../../../../utils/framework';
import {IFilterComponent} from './filterbox.interface';
import {
  FirstWrapperChild,
  FilterWrapper,
  FilterBoxWrapper,
  DateWrapper,
  ApplyFilterButton,
  FirstWrapper,
  FilterDropDownHeader,
  FilterHeaderButton,
} from './filterbox.style';

export const FilterComponent: FC<IFilterComponent> = observer(
  ({
    date,
    onChangeGoalState,
    onChangeDateRange,
    resetDateDisabled,
    onChangeGoalStatus,
    isStatusTabKpi,
    onChangeKPITarget,
    kind,
    goalStateValue,
    resetDisabled,
    setShowApply,
    keyresultCompletionValue,
    objectivesCompletionValue,
    handleClose,
    keyresultStatusValue,
    isTabKeyResult,
    type,
    kpiTargetValue,
    resetFilter,
    children,
    onClickAction,
    title,
    showApplyButton,
    resetDateFilter,
    variant,
    showFilter,
    isStatusTab,
    boxStyle,
    isStatusTabGoal,
    isStatusTabPeople,
  }: IFilterComponent) => {
    const {
      analyticsStore: {resetFilter: resetAnalyticsFilter, searchable},
      usersStore: {currentUser},
    } = useStoreContext();

    const [objectiveValues, setObjectiveValue] = useState({
      objectiveState: goalStateValue,
      objectiveCompletion: objectivesCompletionValue,
    });
    const [keyresultValue, setKeyresultValue] = useState({
      keyresultState: keyresultStatusValue,
      keyresultCompletion: keyresultCompletionValue,
    });

    const goalStateOptions = useMemo(() => {
      const opts = [
        {
          label: `All  ${isStatusTabKpi ? 'KPIs' : capitalize(cfv().g_oals)}`,
          value: '',
        },
        {
          label: `Active `,
          value: 'active',
        },
        {
          label: `Ended `,
          value: 'ended',
        },
      ];

      return opts;
    }, [isStatusTabKpi]);

    const keyResultOptions = useMemo(() => {
      const opts = [
        {
          label: `All ${capitalize(cfv().k_rs)}`,
          value: '',
        },
        {
          label: `Completed`,
          value: 'completed',
        },
        {
          label: `Incompleted`,
          value: 'in_completed',
        },
      ];

      return opts;
    }, []);
    // threshold
    const completionOption = useMemo(() => {
      const options = [
        {
          value: 25,
          label: 'Less than 50% complete',
        },
        {
          value: 50,
          label: '50% to 74% complete',
        },
        {
          value: 75,
          label: '75% to 99% complete',
        },
        {
          value: 100,
          label: '100% complete',
        },
      ];
      return options;
    }, []);

    const isDeactivatedSelected =
      searchable?.deactivatedMembers &&
      searchable?.deactivatedMembers.length > 0;

    const computeGoalStateValue = (goalStateValue?: any) => {
      if (currentUser?.role === 'admin') {
        if (goalStateValue !== '') {
          return {
            label:
              goalStateValue === 'active'
                ? 'Active'
                : goalStateValue === 'past_due'
                ? `Past Due ${capitalize(cfv().g_oals)}`
                : goalStateValue === 'in_progress'
                ? `In Progress ${capitalize(cfv().g_oals)}`
                : capitalize(goalStateValue.replace(/_/g, ' ')),
            value: goalStateValue,
          };
        } else {
          return {
            label: `All ${isStatusTabKpi ? 'KPIs' : capitalize(cfv().g_oals)}`,
            value: '',
          };
        }
      } else {
        if (goalStateValue !== '') {
          return {
            label:
              goalStateValue === 'active'
                ? 'In Progress'
                : goalStateValue === 'past_due'
                ? 'Past Due'
                : capitalize(goalStateValue.replace(/_/g, ' ')),
            value: goalStateValue,
          };
        } else {
          return `${capitalize(cfv().g_oals)} State`;
        }
      }
    };
    const computeKeyresultValue = (value?: any) => {
      if (value === 'completed') {
        return {
          label: `Completed ${capitalize(cfv().k_rs)}`,
          value: 'completed',
        };
      }
      if (value === 'in_completed') {
        return {
          label: `Incompleted ${capitalize(cfv().k_rs)} `,
          value: 'in_completed',
        };
      } else {
        return {
          label: `All ${capitalize(cfv().k_rs)} `,
          value: '',
        };
      }
    };
    const isDisabled = useMemo(() => {
      if (!isTabKeyResult) {
        if (
          objectiveValues.objectiveState === goalStateValue &&
          objectiveValues.objectiveCompletion === objectivesCompletionValue
        ) {
          return true;
        }
        return false;
      } else if (isTabKeyResult) {
        if (
          keyresultValue.keyresultState === keyresultStatusValue &&
          keyresultValue.keyresultCompletion === keyresultCompletionValue
        ) {
          return true;
        }
        return false;
      }
    }, [
      objectiveValues.objectiveState,
      goalStateValue,
      objectivesCompletionValue,
      objectiveValues.objectiveCompletion,
      keyresultCompletionValue,
      keyresultValue.keyresultCompletion,
      isTabKeyResult,
      keyresultValue.keyresultState,
      keyresultStatusValue,
    ]);

    const handleSubmit = () => {
      if (!isTabKeyResult) {
        return {
          objectiveState: objectiveValues.objectiveState,
          completionProgress: objectiveValues.objectiveCompletion,
        };
      } else if (isTabKeyResult) {
        return {
          keyresultState: keyresultValue.keyresultState,
          completionProgress: keyresultValue.keyresultCompletion,
        };
      }
    };

    const handleReset = () => {
      onClickAction && onClickAction();
      if (!isTabKeyResult) {
        setObjectiveValue({
          objectiveState: '',
          objectiveCompletion: null,
        });
      } else if (isTabKeyResult) {
        setKeyresultValue({
          keyresultState: '',
          keyresultCompletion: null,
        });
      }
    };

    return (
      <>
        <FirstWrapper>
          <FirstWrapperChild
            isStatusTab={isStatusTab}
            isStatusTabGoal={isStatusTabGoal}
            isStatusTabPeople={isStatusTabPeople}>
            {variant !== 'companyGoals' && (
              <>
                {showFilter && (
                  <FilterWrapper style={boxStyle}>
                    <FilterDropDownHeader>
                      <Body1 weight="semibold">
                        {title ? title : type === 'date' ? 'Date' : 'Filters'}
                      </Body1>
                      <div>
                        {showApplyButton && showApplyButton.show ? (
                          <ApplyFilterButton
                            disabled={children ? resetDisabled : isDisabled}
                            kind={'primary'}
                            onClick={() =>
                              showApplyButton.action(
                                handleSubmit(),
                                handleClose,
                              )
                            }>
                            Apply
                          </ApplyFilterButton>
                        ) : (
                          <FilterHeaderButton
                            disabled={
                              type === 'date'
                                ? resetDateDisabled
                                : resetDisabled
                            }
                            onClick={() => {
                              if (onClickAction) {
                                handleReset();
                              } else if (type === 'date') {
                                resetDateFilter();
                              } else {
                                resetAnalyticsFilter();
                                resetFilter && resetFilter();
                              }
                            }}>
                            Reset to default
                          </FilterHeaderButton>
                        )}

                        <FilterHeaderButton
                          kind="secondary"
                          onClick={() => {
                            handleClose();
                          }}>
                          Close
                        </FilterHeaderButton>
                      </div>
                    </FilterDropDownHeader>
                    {children && children}
                    {type !== 'date' && !children && (
                      <>
                        {kind !== 'goals' &&
                          kind !== 'goalOwnership' &&
                          !isTabKeyResult &&
                          kind !== 'kpi' &&
                          !!onChangeGoalState && (
                            <FilterBoxWrapper>
                              <ActionSelectField
                                icon={<ArrowHeadDownIcon />}
                                isDisabled={isDeactivatedSelected}
                                value={computeGoalStateValue(
                                  objectiveValues.objectiveState,
                                )}
                                options={goalStateOptions}
                                onChange={(data: {value: string}) => {
                                  setShowApply && setShowApply(true);
                                  setObjectiveValue({
                                    ...objectiveValues,
                                    objectiveState: data?.value,
                                  });
                                  // onChangeGoalState(data);
                                }}
                                placeholder={`${
                                  isStatusTabKpi
                                    ? 'KPIs'
                                    : capitalize(cfv().g_oals)
                                } State`}
                              />
                            </FilterBoxWrapper>
                          )}{' '}
                        {isTabKeyResult && (
                          <FilterBoxWrapper>
                            <ActionSelectField
                              icon={<ArrowHeadDownIcon />}
                              isDisabled={isDeactivatedSelected}
                              value={computeKeyresultValue(
                                keyresultValue.keyresultState,
                              )}
                              options={keyResultOptions}
                              onChange={(data: {value: string}) => {
                                setShowApply && setShowApply(true);
                                setKeyresultValue({
                                  ...keyresultValue,
                                  keyresultState: data?.value,
                                });
                              }}
                              placeholder={`${capitalize(cfv().k_rs)} Status`}
                            />
                          </FilterBoxWrapper>
                        )}
                        {kind === 'kpi' && !!onChangeKPITarget && (
                          <FilterBoxWrapper>
                            <ActionSelectField
                              icon={<ArrowHeadDownIcon />}
                              options={[
                                {
                                  label: 'Under Base',
                                  value: 'under_base',
                                },
                                {
                                  label: 'Within Base',
                                  value: 'within_base',
                                },
                                {
                                  label: 'Within Good',
                                  value: 'within_good',
                                },
                                {
                                  label: 'Stretch',
                                  value: 'stretch',
                                },
                              ]}
                              value={
                                kpiTargetValue !== ''
                                  ? {
                                      label: capitalize(
                                        kpiTargetValue.replace(/_/g, ' '),
                                      ),
                                      value: kpiTargetValue,
                                    }
                                  : 'KPI Target'
                              }
                              onChange={(data: {value: string}) => {
                                onChangeKPITarget(data);
                              }}
                              placeholder="KPI Target"
                            />
                          </FilterBoxWrapper>
                        )}
                        {kind === 'goalStatus' &&
                          !isTabKeyResult &&
                          !isStatusTabKpi &&
                          !!onChangeGoalStatus && (
                            <FilterBoxWrapper>
                              <ActionSelectField
                                icon={<ArrowHeadDownIcon />}
                                options={completionOption}
                                value={completionOption.find(
                                  (option) =>
                                    option.value ===
                                    objectiveValues.objectiveCompletion,
                                )}
                                onChange={(data: {value: number}) => {
                                  setShowApply && setShowApply(true);

                                  setObjectiveValue({
                                    ...objectiveValues,
                                    objectiveCompletion: data?.value,
                                  });
                                }}
                                placeholder={`${capitalize(
                                  cfv().g_oals,
                                )} progress`}
                              />
                            </FilterBoxWrapper>
                          )}{' '}
                        {isTabKeyResult && (
                          <ActionSelectField
                            icon={<ArrowHeadDownIcon />}
                            options={completionOption}
                            value={completionOption.find(
                              (option) =>
                                option.value ===
                                keyresultValue.keyresultCompletion,
                            )}
                            onChange={(data: {value: number}) => {
                              setShowApply && setShowApply(true);

                              setKeyresultValue({
                                ...keyresultValue,
                                keyresultCompletion: data?.value,
                              });
                            }}
                            placeholder={`${capitalize(cfv().k_rs)} progress`}
                          />
                        )}
                      </>
                    )}

                    {type === 'date' &&
                      !children &&
                      date &&
                      !!onChangeDateRange && (
                        <DateWrapper>
                          <DateRangeInput
                            name="date"
                            value={date}
                            block={true}
                            helper={
                              new Date(date.ends) < new Date(date.starts)
                                ? 'Start date must be before end date'
                                : ''
                            }
                            state={
                              new Date(date.ends) < new Date(date.starts)
                                ? 'error'
                                : 'default'
                            }
                            admin={true}
                            placeholder="Select Date"
                            setValue={onChangeDateRange}
                          />
                        </DateWrapper>
                      )}
                  </FilterWrapper>
                )}
              </>
            )}
          </FirstWrapperChild>
        </FirstWrapper>
      </>
    );
  },
);
