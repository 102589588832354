import styled from 'styled-components';
import {Body2} from '@ui/atoms/typography';
import {ToggleSwitch} from '@ui/atoms/toggle-switch';

const SelectionWrapper = styled.div`
  border: 1px solid #cecede;
  border-radius: 8px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  p:first-child {
    margin-right: 7px;
  }
  p:last-child {
    margin-left: 5px;
  }
`;

interface SwitchToggleProps {
  checked: boolean;
  onChange: (event: any) => void;
  disabled?: boolean;
  leftText?: string;
  rightText?: string;
  defaultChecked?: boolean;
}

export const SwitchToggle = ({
  checked,
  onChange,
  disabled,
  leftText,
  rightText,
  defaultChecked,
}: SwitchToggleProps) => {
  return (
    <SelectionWrapper>
      <Body2
        weight="bold"
        kind={checked || disabled ? 'textMuted' : 'textDark'}>
        {leftText || 'Disable'}
      </Body2>
      <ToggleSwitch
        switchId={Math.random()}
        disabled={disabled}
        onChange={onChange}
        defaultChecked={defaultChecked}
        checked={checked}
      />
      <Body2
        weight="bold"
        kind={checked && !disabled ? 'textDark' : 'textMuted'}>
        {rightText || 'Enable'}
      </Body2>{' '}
    </SelectionWrapper>
  );
};
