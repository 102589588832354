import {down} from 'styled-breakpoints';
import styled from 'styled-components';
import {getColorFromTheme} from '../../../../../../../../ui/ui-utils';

export const HeadlineWrapper = styled.div`
  display: grid;
  grid-template-columns: 21% 27% 18% 17% 10%;
  padding-left: 24px;
`;

export const HeadlineWrapperContainer = styled.div`
  display: block;
  padding: 16px 16px 12px 0px;
  background-color: ${getColorFromTheme('backgroundLight')};
  white-space: nowrap;
  height: 52px;
`;

export const TableContainer = styled.div`
  border-right: 1px solid #ededf2;
  border-top: 1px solid #ededf2;
  border-left: 1px solid #ededf2;
  border-bottom: 1px solid #ededf2;
  border-radius: 0px 0px 10px 10px;
`;

export const ItemLoaderWrapper = styled.div`
  padding: 28px 12px 32px 22px;
  justify-content: center;
  width: 100%;
  border-top: 1px solid #ededf2;
`;

export const ItemWrapperContainer = styled.div``;

export const ActionWrapper = styled.div`
  padding: 16px 0px;
  border-top: 1px solid ${getColorFromTheme('borderLight')};
  align-items: center;
  height: 72px;

  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
export const ItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 27% 7% 32% 20% 10%;
  height: 72px;

  padding: 16px;
  border-top: 1px solid ${getColorFromTheme('borderLight')};
  align-items: center;
  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  div.column2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const SecondRowWrapper = styled.div`
  width: 100%;

  max-width: 100%;
  overflow-x: auto;
  display: grid;

  div.header {
    min-width: 1000px;
  }
`;

export const PaginationLi = styled.li`
padding: 8px;
cursor: pointer;
margin: 0 1px;
color: #5f5f8c;

&:hover {
  color: #585adf;
}

  &.active { 
    width: 32px;
    height: 32px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 3px 12px;
    border-radius: 4px;
    box-shadow: 0 2px 5px 0 rgba(172, 172, 190, 0.24);
    background-color: #ffffff;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: normal;
    text-align: left;
    color: #585adf;
    transition: background-color 0.3s ease-in;
    }
  };
`;

export const ModalCardWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  div.child {
    width: 40%;
    ${down('sm')} {
      min-width: 100%;
    }
  }
`;
