import {BaseRequest} from '../../configs/request';
import {authStore} from '../../store/stores/auth-store';

export interface CreateReviewCycleProps {
  cycleName: string;
  reviewType: 'self' | '360';
  period: string;
  excludedGroups?: string[];
  frameworks: string[];
  milestones: {
    startDate: string;
    endDate: string;
    self: string;
    manager: string;
    peer?: string;
    finalize: string;
  };
  nominationSettings?: {
    min: number;
    max: number;
  };
  published?: boolean;
  allowAnonymity?: boolean;
  makePublic: boolean;
}

export interface WriteReviewProps {
  user: string;
  reviewCycle: string;
  reviewerType: string;
  competenceRatings: {
    competenceCategory?: string;
    metric: string;
    score: number;
    note?: string;
  }[];
  valueRatings: {
    metric: string;
    score: number;
    note?: string;
  }[];
  managerQuestionResponse?: {
    question: string;
    response: string;
  };
}

export interface OverviewResponse {
  completed: number;
  id: string;
  inProgress: number;
  self: {
    totalCount: number;
    pending: number;
    completed: number;
  };
  managers: {totalCount: number; pending: number; completed: number};
  peers: {
    totalCount: number;
    pending: number;
    inProgress: number;
    completed: number;
    declined: number;
  };
  pending: number;
  percentages: number;
  period: string;
  reviewType: string;
  totalCount: number;
  users: {
    avatar: {source: string; url: string; meta: any};
    id: string;
    manager: {
      id: string;
      name: string;
      avatar: {source: string; url: string; meta: any};
      reviewWritten: false;
    };
    name: string;
    peers: {
      id: string;
      avatar: {source: string; url: string; meta: any};
      name: string;
      reviewWritten: false;
    }[];
    percentageProgress: number;
    reviewWritten: boolean;
  }[];
}
export interface NominationPost {
  reviewers: string[];
  reviewCycle: string;
  user: string;
}

export interface ResultUserResponse {
  id: string;
  firstName: string;
  lastName: string;
  avatar: any;
  reviewSummary: {
    reviewWritten: boolean;
    overallRating: string;
    score: number;
    valueScore: number;
    competenceScore: number;
  };
  manager: {
    id: string;
    firstName: string;
    lastName: string;
    avatar: any;
    reviewWritten: boolean;
  };
  peers: {
    id: string;
    firstName: string;
    lastName: string;
    avatar: any;
    reviewWritten: boolean;
  }[];
}
export interface ResultsResponse {
  total: number;
  averageScore: number;
  completionRate: string;
  averageOverallRating: string;
  scoresCount: {
    poor: number;
    needsImprovement: number;
    meetsExpectation: number;
    exceedsExpectation: number;
    outstanding: number;
  };
  averageValue: number;
  averageCompetence: number;
  users: ResultUserResponse[];
}
export interface WrittenReviewProps {
  reviewId: string;
  include?: string;
}
export interface ManagerReminderProps {
  user: string;
  reviewer: string;
  cycleId: string;
}
export interface DirectReportResponse {
  summary: {
    totalCount: number;
    reviewed: number;
    pending: number;
  };
  reports: {
    firstName: string;
    lastName: string;
    avatar: any;
    email: string;
    reviewed: boolean;
  }[];
}
export interface ReviewCycleProps extends CreateReviewCycleProps {
  createdAt: string | number | Date;
  id: string;
  workspace: string;
  status: string;
  updatedBy: string;
  createdBy: string;
}

interface ICcRequest extends BaseRequest {
  fetchCcReviewSetup(): Promise<any>;
  updateCcReviewSetup(data: any): Promise<any>;
  fetchCcFramework(pageNumber?: number): Promise<any>;
  updateCcFramework(data: any): Promise<any>;
  updateCcReview(data: CreateReviewCycleProps, id: string): Promise<any>;
  updateReviewNomination(data: NominationPost): Promise<any>;
  fetchCcFrameworkById(id: string): Promise<any>;
  fetchCcResults(
    id: string,
    filters: {group?: string; member?: string},
  ): Promise<ResultsResponse>;
  fetchWrittenReview(): Promise<any>;
  fetchWrittenReviewById(data: WrittenReviewProps): Promise<any>;
  fetchReviewCycleById(id: string): Promise<ReviewCycleProps>;
  createReviewCycle(data: CreateReviewCycleProps): Promise<any>;
  fetchUserReviewCycles({status}: {status?: string}): Promise<any>;
  fetchReviewNomination(user?: string): Promise<any>;
  sendManagerReminder(data: ManagerReminderProps): Promise<any>;
  fetchReviewCycles(): Promise<ReviewCycleProps[]>;
  updateCcFrameworkById(id: string, data: any): Promise<any>;
  updateWrittenReviewCycles(data: any, id: string): Promise<any>;
  deleteCcFramework(id: string): Promise<any>;
  getDirectReport(reviewCycleId: string): Promise<DirectReportResponse>;
  fetchReviewOverview(id: string): Promise<OverviewResponse>;
  deleteCcReview(id: string): Promise<any>;
  deleteNomination(id: string): Promise<any>;
  sendNominationReminder(id: string): Promise<any>;
}

export class CcRequestImpl extends BaseRequest implements ICcRequest {
  url: string = '/cc-review';

  private requestHeader = {
    headers: {
      Authorization: `Bearer ${authStore?.auth?.tokens.access.token}`,
    },
  };

  async fetchCcReviewSetup() {
    const {data: response} = await this.request.get(
      `${this.url}/setup?include=frameworks`,
      this.requestHeader,
    );
    return response;
  }

  async updateCcReviewSetup(data: any) {
    const {data: response} = await this.request.patch(
      `${this.url}/setup`,
      data,
      this.requestHeader,
    );
    return response;
  }

  async createReviewCycle(data: CreateReviewCycleProps) {
    const {data: response} = await this.request.post(
      `${this.url}/review-cycles`,
      data,
      {
        ...this.requestHeader,
        useCache: false,
      },
    );
    return response;
  }

  async writeReviewCycles(data: WriteReviewProps) {
    const {data: response} = await this.request.post(
      `${this.url}/reviews`,
      data,
      {
        ...this.requestHeader,
      },
    );
    return response;
  }

  async updateWrittenReviewCycles(data: any, id: string) {
    const {data: response} = await this.request.patch(
      `${this.url}/reviews/${id}`,
      data,
      {
        ...this.requestHeader,
      },
    );
    return response;
  }

  async fetchReviewCycles(data?: {
    status?: string;
  }): Promise<ReviewCycleProps[]> {
    const {data: response} = await this.request.get(
      `${this.url}/review-cycles?sortBy=createdAt:desc${
        data?.status ? `&status=${data?.status}` : ''
      }`,
      {
        ...this.requestHeader,
        useCache: false,
      },
    );
    return response;
  }

  async fetchUserReviewCycles({
    status,
  }: {
    status?: string;
  }): Promise<ReviewCycleProps[]> {
    const {data: response} = await this.request.get(
      `${this.url}/review-cycles/user-reviews?sortBy=createdAt:asc${
        status ? `&status=${status}` : ''
      }`,
      this.requestHeader,
    );
    return response;
  }

  async deleteCcReview(id: string) {
    const {data: response} = await this.request.delete(
      `${this.url}/review-cycles/${id}`,
      this.requestHeader,
    );
    return response;
  }

  async fetchCcFramework(pageNumber?: number) {
    const {data: response} = await this.request.get(
      `${this.url}/framework?${
        !pageNumber ? '' : `page=${pageNumber}&paginate=true`
      }`,
      {
        ...this.requestHeader,
        useCache: false,
      },
    );
    return response;
  }

  async fetchCcResults(
    reviewCycleId: string,
    {
      group,
      member,
      value,
      user,
      manager,
      keyArea,
    }: {
      group?: string;
      member?: string;
      keyArea?: string;
      value?: string;
      user?: string;
      manager?: string;
    },
  ) {
    const {data: response} = await this.request.get(
      `${this.url}/review-cycles/${reviewCycleId}/summary?${
        group ? `group=${group}` : ''
      }${member ? `&user=${member}` : ''}${
        keyArea ? `keyArea=${keyArea}` : ''
      }${value ? `value=${value}` : ''}${user ? `user=${user}` : ''}${
        manager ? `manager=${manager}` : ''
      }`,
      {
        ...this.requestHeader,
        useCache: false,
      },
    );
    return response;
  }
  async fetchWrittenReview(data?: {
    reviewerType?: string;
    reviewCycle?: string;
    reviewer?: string;
    user?: string;
  }) {
    const {data: response} = await this.request.get(
      `${this.url}/reviews?${
        data?.reviewerType ? `reviewerType=${data?.reviewerType}` : ''
      }${data?.reviewCycle ? `&reviewCycle=${data?.reviewCycle}` : ''}${
        data?.reviewer ? `&reviewer=${data?.reviewer}` : ''
      }${data?.user ? `&user=${data?.user}` : ''}`,
      {
        ...this.requestHeader,
        useCache: false,
      },
    );
    return response;
  }

  async fetchWrittenReviewById(data: WrittenReviewProps) {
    const {data: response} = await this.request.get(
      `${this.url}/reviews/${data.reviewId}?${
        !data.include ? '' : `include=${data.include}`
      }`,
      {
        ...this.requestHeader,
        useCache: false,
      },
    );
    return response;
  }
  async fetchReviewNomination(
    user?: string,
    reviewId?: string,
    reviewer?: string,
  ) {
    const {data: response} = await this.request.get(
      `${this.url}/nominations?${!user ? '' : `user=${user}`}${
        !reviewId ? '' : `&reviewCycle=${reviewId}`
      }${!reviewer ? '' : `&reviewer=${reviewer}`}`,
      {
        ...this.requestHeader,
        useCache: false,
      },
    );
    return response;
  }

  async updateCcFramework(data: any) {
    const {data: response} = await this.request.post(
      `${this.url}/framework`,
      data,
      this.requestHeader,
    );
    return response;
  }

  async updateReviewNomination(data: NominationPost) {
    const {data: response} = await this.request.post(
      `${this.url}/nominations`,
      data,
      this.requestHeader,
    );
    return response;
  }

  async updateCcReview(data: any, id: string) {
    const {data: response} = await this.request.patch(
      `${this.url}/review-cycles/${id}`,
      data,
      this.requestHeader,
    );
    return response;
  }

  async fetchCcFrameworkById(id: string) {
    const {data: response} = await this.request.get(
      `${this.url}/framework/${id}`,
      this.requestHeader,
    );
    return response;
  }
  async fetchReviewOverview(id: string, userId?: string, managerId?: string) {
    const {data: response} = await this.request.get(
      `${this.url}/review-cycles/${id}/overview${
        userId ? `?user=${userId}` : ''
      }${managerId ? `?manager=${managerId}` : ''}`,
      this.requestHeader,
    );
    return response;
  }

  async fetchReviewCycleById(id: string) {
    const {data: response} = await this.request.get(
      `${this.url}/review-cycles/${id}`,
      this.requestHeader,
    );
    return response;
  }

  async sendNominationReminder(id: string) {
    const {data: response} = await this.request.post(
      `${this.url}/nominations/${id}`,
      undefined,
      this.requestHeader,
    );
    return response;
  }

  async getDirectReport(reviewCycleId: string) {
    const {data: response} = await this.request.get(
      `${this.url}/review-cycles/${reviewCycleId}/reports`,

      this.requestHeader,
    );
    return response;
  }

  async sendManagerReminder({user, reviewer, cycleId}: ManagerReminderProps) {
    const {data: response} = await this.request.post(
      `${this.url}/review-cycles/${cycleId}/reminder`,
      {
        user,
        reviewer,
      },
      this.requestHeader,
    );
    return response;
  }

  async updateCcFrameworkById(id: string, data: any) {
    const {data: response} = await this.request.patch(
      `${this.url}/framework/${id}`,
      data,
      this.requestHeader,
    );
    return response;
  }

  async deleteCcFramework(id: string) {
    const {data: response} = await this.request.delete(
      `${this.url}/framework/${id}`,
      this.requestHeader,
    );
    return response;
  }
  async deleteNomination(id: string) {
    const {data: response} = await this.request.delete(
      `${this.url}/nominations/${id}`,
      this.requestHeader,
    );
    return response;
  }
}
