import {memo} from 'react';
import {useBreakpoints} from '@utils/use-breakpoints';
import {GoalKind} from '../../interface';

export interface GoalTextProps {
  kind: GoalKind;
}

export const GoalText = memo(({kind}: GoalTextProps) => {
  const {isXs} = useBreakpoints();
  switch (kind) {
    case GoalKind.COMPANY:
      return (
        <span style={{whiteSpace: 'nowrap'}}>
          {isXs ? 'Company' : 'Company-wide'}
        </span>
      );
    case GoalKind.INDIVIDUAL:
      return <> {isXs ? 'Self' : 'Self-development'} </>;
    case GoalKind.GROUP:
      return <>Group</>;
    default:
      return <>Individual</>;
  }
});

GoalText.displayName = 'GoalText';
