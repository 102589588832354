import {useStoreContext} from '@store/store-context';
import {AnalyticsRequestImpl} from '../../admin-analytics.request';
import {AnalyticsController} from '../../admin-analytics.controller';
import {useDropDown} from '@hooks';
import dayjs from 'dayjs';
import {cfv} from '@utils/framework';
import {useState, useCallback, useMemo} from 'react';

export const useStatusHook = (filterComponentParser: any) => {
  const {
    analyticsStore: {
      filterCompletion,
      breakdownBy,
      viewAnalyticsRange,
      setFilterState,
      kpisData,
      setFilterCompletion,
      goalsData,

      searchable,
      filterState,
    },
    usersStore: {currentUser},
    authStore: {featureLimitStatus},
    storeDashboardRoutes: {analyticsDashboardTabs, statusTabs},
  } = useStoreContext();

  const [openExport, setOpenExport] = useState(false);
  //show and hide keyresults
  const [isloading, setisLoading] = useState(false);

  const [fetchPDF, setFetchPDF] = useState<any>({});

  const fetchSpecifiedGoalsForExport = useCallback(
    async (filters: any, view?: string) => {
      setisLoading(true);
      const request = new AnalyticsRequestImpl();
      const controller = new AnalyticsController(request);
      const specifiedGoalsResponse = await controller.fetchSpecifiedGoalsForExport(
        filters,
        view,
      );
      setFetchPDF(specifiedGoalsResponse);
      setisLoading(false);
    },
    [],
  );

  const isDeactivatedOrArchived =
    (searchable?.deactivatedMembers &&
      searchable?.deactivatedMembers?.length > 0) ||
    filterState?.goals === 'archived';
  const reachedLimit =
    featureLimitStatus?.limited &&
    featureLimitStatus?.currentStatus?.export >=
      featureLimitStatus?.limits?.export;

  const {open, handleOpen, ref, handleClose} = useDropDown();

  const computeGoalStateValue = useCallback(
    (goalStateValue: string) => {
      if (currentUser?.role === 'admin') {
        if (goalStateValue) {
          if (goalStateValue === 'active') {
            return 'Active';
          }
          if (goalStateValue === 'ended') {
            return 'Ended';
          }
          return goalStateValue.replace(/_/g, ' ');
        } else {
          return `All `;
        }
      } else {
        if (goalStateValue !== '') {
          return goalStateValue.replace(/_/g, ' ');
        } else {
          return `${cfv().g_oals} State`;
        }
      }
    },
    [currentUser?.role],
  );

  const computeGoalStatusValue = useCallback((goalStatusValue: string) => {
    if (goalStatusValue !== '') {
      return goalStatusValue.replace(/_/g, ' ');
    } else {
      return 'all status';
    }
  }, []);

  const [openGoal, setOpen] = useState('');

  const computeKeyresultValue = useCallback((value?: any) => {
    if (value === 'completed') {
      return `Completed `;
    }
    if (value === 'in_completed') {
      return `Incompleted  `;
    } else {
      return `All  `;
    }
  }, []);

  const computeFilterValue = useMemo(() => {
    const options = [
      {
        value: 25,
        label: 'Less than 50% complete',
      },
      {
        value: 50,
        label: '50% to 74% complete',
      },
      {
        value: 75,
        label: '75% to 99% complete',
      },
      {
        value: 100,
        label: '100% complete',
      },
    ];

    const completionLabel = (name: string) => {
      return options.find((option) => option.value === filterCompletion[name])
        ?.label;
    };
    if (statusTabs.Kpi) {
      return `${computeGoalStateValue(
        'filterComponentParser?.goalStateValue()',
      )} KPIs  `;
    }
    if (analyticsDashboardTabs.objectives) {
      return `${computeGoalStateValue(
        'filterComponentParser?.goalStateValue()',
      )} ${cfv().g_oals} ${
        !filterCompletion.goals ? '' : ', ' + completionLabel('goals')
      }  `;
    } else if (analyticsDashboardTabs.keyresults) {
      return `${computeKeyresultValue(filterState.kpis)} ${cfv().k_rs} ${
        !filterCompletion.keyresults
          ? ''
          : ',  ' + completionLabel('keyresults')
      }  `;
    }
  }, [
    statusTabs.Kpi,
    analyticsDashboardTabs.objectives,
    analyticsDashboardTabs.keyresults,
    filterCompletion,
    computeGoalStateValue,
    computeKeyresultValue,
    filterState.kpis,
  ]);

  const [showApply, setShowApply] = useState(
    analyticsDashboardTabs.objectives
      ? '' || filterCompletion.goals
        ? false
        : true
      : filterState.kpis || filterCompletion.keyresults
      ? false
      : true,
  );

  const handleApply = (e: any) => {
    setShowApply(false);
    handleClose();

    if (analyticsDashboardTabs.objectives) {
      filterComponentParser?.onChangeGoalState({value: e.objectiveState});
      setFilterCompletion('goals', e.completionProgress);
    } else {
      setFilterState('kpis', e.keyresultState);
      setFilterCompletion('keyresults', e.completionProgress);
    }
  };

  const onClickAction = () => {
    filterComponentParser?.onChangeGoalState({value: ''});
    setFilterState('kpis', '');
    setFilterCompletion('goals', '');
    setFilterCompletion('keyresults', '');
  };

  const totalCount = useMemo(() => {
    if (analyticsDashboardTabs.objectives) {
      return goalsData?.totalResults;
    } else if (analyticsDashboardTabs.keyresults) {
      return kpisData?.totalResults;
    }
  }, [
    analyticsDashboardTabs.keyresults,
    analyticsDashboardTabs.objectives,
    goalsData?.totalResults,
    kpisData?.totalResults,
  ]);

  const AnalyticsDateRange = useMemo(() => {
    const dates = new Map();

    const sortDates = (
      key: string,
      date: {start: string; isCurrentWeek?: boolean; end?: string},
    ) => {
      if (!dates.has(key)) {
        dates.set(key, date);
      }
    };

    const getDates = (type: 'day' | 'start-end') => {
      goalsData?.goals &&
        !breakdownBy &&
        goalsData?.goals?.map((goal: any) => {
          return (
            goal?.activities?.map((activity: any) => {
              if (type === 'day') {
                sortDates(activity.startDate, {start: activity.startDate});
                return null;
              }

              return sortDates(activity.startDate, {
                start: activity.startDate,
                isCurrentWeek: dayjs(dayjs().format()).isBetween(
                  activity.startDate,
                  activity.endDate,
                  'weeks',
                  '[]',
                ),
                end: activity.endDate,
              });
            }) || []
          );
        });
    };

    switch (viewAnalyticsRange) {
      case 'days':
        getDates('day');
        return Array.from(dates).map((date) => date[1]);
      default:
        getDates('start-end');
        return Array.from(dates).map((date) => date[1]);
    }
  }, [viewAnalyticsRange, goalsData?.goals, breakdownBy]);

  const staticDates = useMemo(() => {
    const addMonths = new Map();
    const lastMonth: any = AnalyticsDateRange[AnalyticsDateRange.length - 1];
    if (AnalyticsDateRange.length < 3 && lastMonth && !!viewAnalyticsRange) {
      let count = 3 - AnalyticsDateRange.length;

      for (let i = 1; i <= count; i++) {
        const monthIncrement = {
          start: dayjs(lastMonth.start).add(i, viewAnalyticsRange).format(),
          end: dayjs(lastMonth.end).add(i, viewAnalyticsRange).format(),
        };
        addMonths.set(monthIncrement.start, monthIncrement);
      }
      return Array.from(addMonths)?.map((month) => month[1]);
    }
    return null;
  }, [viewAnalyticsRange, AnalyticsDateRange]);

  return {
    openGoal,
    setOpen,
    computeGoalStatusValue,
    reachedLimit,
    isDeactivatedOrArchived,
    isloading,
    setisLoading,
    setFetchPDF,
    openExport,
    setOpenExport,
    fetchSpecifiedGoalsForExport,
    computeFilterValue,
    totalCount,
    onClickAction,
    handleApply,
    AnalyticsDateRange,
    fetchPDF,
    showApply,
    ref,
    setShowApply,
    handleClose,
    open,
    handleOpen,
    staticDates,
    computeGoalStateValue,
  };
};
