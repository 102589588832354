import {ComponentPropsWithRef, memo, useMemo} from 'react';
import styled, {css} from 'styled-components';
import styledMap from 'styled-map';
import {ifProp} from 'styled-tools';
import {Status, useImage} from '../../../hooks';
import {Typography} from '../typography/typography';
import {getColorFromTheme} from '../../ui-utils';

interface SharedProps {
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'smd';
}

export type WorkspaceLogoProps = ComponentPropsWithRef<'img'> &
  SharedProps & {
    name: string;
  };

const size = styledMap('size', {
  default: '40px',
  sm: '24px',
  smd: '32px',
  md: '40px',
  lg: '48px',
  xl: '74px',
});

const fontSize = styledMap('size', {
  default: '24px',
  sm: '16px',
  smd: '18px',
  md: '20px',
  lg: '24px',
  xl: '32px',
});

const radius = styledMap('size', {
  default: '5px',
  sm: '3px',
  smd: '7px',
  md: '5px',
  lg: '7px',
  xl: '10px',
});

const appearance = css`
  background-color: ${getColorFromTheme('purple300')};
`;

const Wrapper = styled.span<SharedProps & {hasImage?: boolean}>`
  border-radius: ${radius};
  overflow: hidden;
  width: ${size};
  height: ${size};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  ${ifProp('hasImage', appearance)};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

const Text = styled.span<SharedProps>`
  ${Typography};
  font-weight: bold;
  font-size: ${fontSize};
  color: #fff;
`;

export const WorkspaceLogo = memo(function ({
  size,
  name,
  ...props
}: WorkspaceLogoProps) {
  const [status] = useImage(props.src || '');

  const renderContent = useMemo(() => {
    if (status !== Status.LOADED || !props.src) {
      return <Text size={size}>{name.slice(0, 1)}</Text>;
    }

    return <img alt="name" {...props} />;
  }, [props, status, name, size]);

  return (
    <Wrapper size={size} hasImage={!props.src}>
      {renderContent}
    </Wrapper>
  );
});
WorkspaceLogo.displayName = 'WorkspaceLogo';
