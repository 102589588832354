import {useMemo, useCallback, useState, useEffect} from 'react';
import {WorkspaceAPIController} from '../../../../../../../api/workspaces-api/workspaces-api-controller';
import {WorkspaceAPIRequestImpl} from '../../../../../../../api/workspaces-api/workspaces-api-request';
import {useStoreContext} from '../../../../../../../store/store-context';
import {AnalyticsController} from '../../../../../reporting/reporting-pages/admin-analytics/admin-analytics.controller';
import {AnalyticsRequestImpl} from '../../../../../reporting/reporting-pages/admin-analytics/admin-analytics.request';
import {WorkspaceCheckinsSettingsController} from './admin-checkins.controller';
import {WorkspaceCheckinsSettingsForm} from './admin-checkins.interface';
import {WorkspaceCheckinsSettingsRequestImpl} from './admin-checkins.request';
import {useNavigate} from 'react-router';
import {getWeekOfYear} from '@utils/date';

const PENDING_CHANGES_COUNT_KEY = 'pendingCheckinChangesCount';
const LAST_RESET_WEEK_KEY = 'lastCheckinResetWeek';

type NestedDirtyFields = {
  [K in keyof WorkspaceCheckinsSettingsForm]?: WorkspaceCheckinsSettingsForm[K] extends object
    ? NestedDirtyFields
    : boolean;
};

export function useAdminCheckinsHook() {
  // 01. General ---- Shared functions and constants

  const {
    analyticsStore: {setFilterGroup},
    authStore: {auth, setAuthResponse},
    checkinsStore: {configuration, setCheckinsConfiguration},
  } = useStoreContext();

  const [pendingChangesCount, setPendingChangesCount] = useState<number>(0);

  const [lastResetWeek, setLastResetWeek] = useState<number | null>(null);

  const [loading, setLoading] = useState(false);
  const [isEditingCheckinsFrequency, setIsEditingCheckinsFrequency] = useState(
    false,
  );
  const [disabled, setDisabled] = useState(true);

  const handleEditCheckinsFrequency = () => {
    setIsEditingCheckinsFrequency(true);
  };

  const handleSaveCheckinsFrequency = () => {
    setIsEditingCheckinsFrequency(false);
  };
  const handleEditCheckinsDueDayFrequency = () => {
    setIsEditingCheckinsDueDayFrequency(true);
  };

  const handleSaveCheckinsDueDayFrequency = () => {
    setIsEditingCheckinsDueDayFrequency(false);
  };

  const navigate = useNavigate();

  // Visibility settings
  const [selectedVisibility, setSelectedVisibility] = useState('full');
  //
  const workspaceAPIRequest = useMemo(() => new WorkspaceAPIRequestImpl(), []);
  const workspaceAPIController = useMemo(
    () => new WorkspaceAPIController(workspaceAPIRequest),
    [workspaceAPIRequest],
  );
  const workspaceCheckinsRequest = useMemo(
    () => new WorkspaceCheckinsSettingsRequestImpl(),
    [],
  );
  const workspaceCheckinsController = useMemo(
    () => new WorkspaceCheckinsSettingsController(workspaceCheckinsRequest),
    [workspaceCheckinsRequest],
  );

  const editableCheckinsConfig = Object.keys(configuration).length > 0;

  const [editableConfigData, setEditableConfigData] = useState(configuration);

  // 03. Update workspace setting to enable/disable checkins
  const [isConfiguring, setIsConfiguring] = useState(false);

  const isCheckinsEnabled =
    auth?.user?.workspace?.settings?.checkInStatus === 'enabled';

  const firstTimeEnable =
    Object.keys(configuration)?.length === 0 &&
    auth?.user?.workspace?.settings?.checkInStatus === 'enabled';

  const enableCheckins = useCallback(async () => {
    const response = await workspaceAPIController.updateWorkspace(
      {
        settings: {
          checkInStatus: 'enabled',
        },
      },
      true,
    );

    response &&
      setAuthResponse({
        ...auth,
        user: {
          ...auth?.user,
          workspace: {
            ...auth?.user?.workspace,
            settings: {
              ...auth?.user?.workspace?.settings,
              checkInStatus: 'enabled',
            },
          },
        },
      });
  }, [auth, setAuthResponse, workspaceAPIController]);

  useEffect(() => {
    // Load pending changes count and last reset week from localStorage
    const storedPendingChangesCount = localStorage.getItem(
      PENDING_CHANGES_COUNT_KEY,
    );

    const storedLastResetWeek = localStorage.getItem(LAST_RESET_WEEK_KEY);

    if (storedPendingChangesCount) {
      setPendingChangesCount(parseInt(storedPendingChangesCount, 10));
    }

    if (storedLastResetWeek) {
      setLastResetWeek(parseInt(storedLastResetWeek, 10));
    }
  }, []);

  useEffect(() => {
    // Check if it's a new week and reset pending changes if necessary
    const currentWeek = getWeekOfYear();

    if (lastResetWeek && currentWeek > lastResetWeek) {
      resetPendingChanges();
    }
  }, [lastResetWeek]);

  const resetPendingChanges = () => {
    setPendingChangesCount(0);
    localStorage.removeItem(PENDING_CHANGES_COUNT_KEY);
  };

  const [formState, setFormState] = useState<WorkspaceCheckinsSettingsForm>({
    excludedGroups: editableCheckinsConfig ? configuration?.excludedGroups : [],
    activeCustomFrequency: editableCheckinsConfig
      ? configuration?.activeCustomFrequency
      : false,
    visibility: editableCheckinsConfig ? configuration?.visibility : 'full',
    managersCanAddQuestions: editableCheckinsConfig
      ? configuration?.managersCanAddQuestions
      : true,
    allowLateSubmission: editableCheckinsConfig
      ? configuration?.allowLateSubmission
      : false,
    generalFrequency: editableCheckinsConfig
      ? configuration?.generalFrequency
      : {
          week: editableCheckinsConfig
            ? configuration?.generalFrequency?.week
            : '',
          frequency: editableCheckinsConfig
            ? configuration?.generalFrequency?.frequency
            : 'weekly',
          activeCheckins: editableCheckinsConfig
            ? configuration?.generalFrequency?.activeCheckins
            : [],
        },
  });

  // Tracking changes
  const [dirtyFields, setDirtyFields] = useState<Record<string, boolean>>({});

  const handleFormValueChange = useCallback((value: any, field: string) => {
    const fieldParts = field.split('.');

    const setNestedProperty = (obj: any, path: string[], value: any): any => {
      const [head, ...rest] = path;
      if (rest.length === 0) {
        return {...obj, [head]: value};
      }
      return {
        ...obj,
        [head]: setNestedProperty(obj[head] || {}, rest, value),
      };
    };

    setFormState((prevState) => {
      return setNestedProperty(prevState, fieldParts, value);
    });

    setDirtyFields((prevDirtyFields) => {
      return setNestedProperty(prevDirtyFields, fieldParts, true);
    });

    setDisabled(false);
  }, []);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleActiveCheckins = useCallback((field: string) => {
    setFormState((prevState) => {
      const currentActiveCheckins =
        prevState.generalFrequency?.activeCheckins || [];

      const updatedActiveCheckins = currentActiveCheckins.includes(field)
        ? currentActiveCheckins.filter((checkin) => checkin !== field)
        : [...currentActiveCheckins, field];

      return {
        ...prevState,
        generalFrequency: {
          ...prevState.generalFrequency,
          activeCheckins: updatedActiveCheckins,
        },
      };
    });
    setDirtyFields((prevDirtyFields) => ({
      ...prevDirtyFields,
      'generalFrequency.activeCheckins': true,
    }));
    setDisabled(false);
  }, []);

  // UI 2.0
  /// Custom Frequency
  const [
    isEditingCustomCheckinsFrequency,
    setIsEditingCustomCheckinsFrequency,
  ] = useState(false);

  const [
    isEditingCheckinsDueDayFrequency,
    setIsEditingCheckinsDueDayFrequency,
  ] = useState(false);

  const [togglCustomFrequency, setTogglCustomFrequency] = useState(
    formState.activeCustomFrequency,
  );

  const [advancedSettingsExpanded, setAdvancedSettingsExpanded] = useState(
    true,
  );

  useEffect(() => {
    const activeCustomFrequency = formState.activeCustomFrequency;

    if (activeCustomFrequency !== togglCustomFrequency) {
      setTogglCustomFrequency(activeCustomFrequency);
    }
  }, [formState.activeCustomFrequency, togglCustomFrequency]);

  // 02. Initializers
  //a. Init Checkins Config Data
  const getCheckinsConfigData = useCallback(async () => {
    setLoading(true);
    const response = await workspaceCheckinsController.getCheckinsSettings();

    response && setCheckinsConfiguration(response);
    setLoading(false);
  }, [setCheckinsConfiguration, workspaceCheckinsController]);

  useEffect(() => {
    getCheckinsConfigData();
  }, [getCheckinsConfigData]);

  //b. Init editable data
  useEffect(() => {
    setEditableConfigData(configuration);
  }, [configuration]);

  const [hasUpdatedConfig, setHasUpdatedConfig] = useState(false);

  const insertDefaultData = useCallback(async () => {
    setFormState({
      excludedGroups: editableConfigData?.excludedGroups,
      allowLateSubmission: editableConfigData?.allowLateSubmission,
      activeCustomFrequency: editableConfigData?.activeCustomFrequency,
      visibility: editableConfigData?.visibility,
      managersCanAddQuestions: editableConfigData?.managersCanAddQuestions,
      generalFrequency: {
        week: editableConfigData?.generalFrequency?.week,
        frequency: editableConfigData?.generalFrequency?.frequency || 'weekly',
        dueDay: editableConfigData?.generalFrequency?.dueDay,
        activeCheckins: editableConfigData?.generalFrequency?.activeCheckins,
      },
    });
    setHasUpdatedConfig(true);
  }, [
    editableConfigData?.excludedGroups,
    editableConfigData?.allowLateSubmission,
    editableConfigData?.activeCustomFrequency,
    editableConfigData?.visibility,
    editableConfigData?.managersCanAddQuestions,
    editableConfigData?.generalFrequency?.week,
    editableConfigData?.generalFrequency?.frequency,
    editableConfigData?.generalFrequency?.dueDay,
    editableConfigData?.generalFrequency?.activeCheckins,
  ]);

  useEffect(() => {
    if (!hasUpdatedConfig) insertDefaultData();
  }, [configuration, editableConfigData, insertDefaultData, hasUpdatedConfig]);

  const submitForm = useCallback(async () => {
    setDisabled(true);

    const flattenDirtyFields = (
      obj: NestedDirtyFields,
      prefix = '',
    ): string[] => {
      return Object.entries(obj).flatMap(([key, value]) => {
        const newKey = prefix ? `${prefix}.${key}` : key;

        if (typeof value === 'object' && value !== null) {
          return flattenDirtyFields(value, newKey);
        }
        return value ? [newKey] : [];
      });
    };

    const dirtyFieldsList = flattenDirtyFields(dirtyFields);
    const changedFieldsCount = dirtyFieldsList.length;

    if (changedFieldsCount === 0) {
      return;
    }

    setIsSubmitting(true);

    const newPendingChangesCount = pendingChangesCount + changedFieldsCount;
    setPendingChangesCount(newPendingChangesCount);

    localStorage.setItem(
      PENDING_CHANGES_COUNT_KEY,
      newPendingChangesCount.toString(),
    );

    localStorage.setItem(LAST_RESET_WEEK_KEY, String(getWeekOfYear()));

    const response = await workspaceCheckinsController.updateCheckinsSettings(
      formState as any,
      true,
    );

    if (response) {
      setCheckinsConfiguration(formState);
      setDirtyFields({});
    }

    setIsSubmitting(false);
  }, [
    dirtyFields,
    pendingChangesCount,
    formState,
    workspaceCheckinsController,
    setCheckinsConfiguration,
  ]);

  const submitEditedForm = useCallback(
    (data: WorkspaceCheckinsSettingsForm) => {},
    [],
  );

  const editCheckins = () => {
    setIsConfiguring(true);
    navigate('checkins-configure');
  };

  const handleToggleCheckins = async (e: any, status: string) => {
    const response = await workspaceAPIController.updateWorkspace({
      settings: {
        checkInStatus: status,
      },
    });
    response &&
      setAuthResponse({
        ...auth,
        user: {
          ...auth?.user,
          workspace: {
            ...auth?.user?.workspace,
            settings: {
              ...auth?.user?.workspace?.settings,
              checkInStatus: status,
            },
          },
        },
      });
  };

  // Initialize groups
  const fetchGroups = useCallback(async () => {
    const grpRequest = new AnalyticsRequestImpl();
    const grpController = new AnalyticsController(grpRequest);
    const groupsResponse = await grpController.fetchGroups();

    setFilterGroup(
      groupsResponse &&
        // eslint-disable-next-line array-callback-return
        groupsResponse.results.map((group: any) => {
          if (group) {
            return {
              label:
                group.name === 'General'
                  ? `General (${group.groupType?.name})`
                  : group.name,
              value: group.id,
            };
          }
        }),
    );
  }, [setFilterGroup]);

  useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  return {
    isSubmitting,
    formState,
    handleFormValueChange,
    isEditingCheckinsFrequency,
    setIsEditingCheckinsFrequency,
    handleEditCheckinsFrequency,
    handleSaveCheckinsFrequency,
    isEditingCheckinsDueDayFrequency,
    handleEditCheckinsDueDayFrequency,
    handleSaveCheckinsDueDayFrequency,
    advancedSettingsExpanded,
    setAdvancedSettingsExpanded,
    togglCustomFrequency,
    setTogglCustomFrequency,
    selectedVisibility,
    disabled,
    setSelectedVisibility,
    isConfiguring,
    firstTimeEnable,
    isCheckinsEnabled,
    setIsConfiguring,
    enableCheckins,
    editCheckins,
    handleToggleCheckins,
    submitForm,
    submitEditedForm,
    handleActiveCheckins,
    isEditingCustomCheckinsFrequency,
    setIsEditingCustomCheckinsFrequency,
    loading,
    pendingChangesCount,
    setPendingChangesCount,
    resetPendingChanges,
  };
}
