import React, {FC} from 'react';
import {GoalSection} from '../you/you-components/insights/goal/goal-card';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {KeyResultSection} from '../you/you-components/insights/keyresults/key-results-cards';
import {MemberActivity} from '../you/you-components/insights/activity/activity';
import {IMeetingResponse, TGoalResponse} from '@hooks';
import {MeetingAgenda} from '@pages/1:1s/view-meetings/components/agenda';
import {NextSteps} from '@pages/1:1s/view-meetings/components/next-steps';
import {SectionCard} from '@ui/layouts/section-card';
import {Headline3} from '@ui/atoms/typography';
import {DrawerModal} from '@ui/organisms/modal/drawer-modal';
import {Note} from '@pages/1:1s/view-meetings/styles';
import {authStore} from '@store/stores/auth-store';
import {userName} from '@utils/user-name';

interface ViewSourceProps {
  onClose: () => void;
  meeting?: IMeetingResponse;
  type: string;
  userName: string;
  goal?: TGoalResponse;
}
export const ViewActionItemsSource: FC<ViewSourceProps> = ({
  onClose,
  meeting,
  userName,
  goal,
  type,
}) => {
  return (
    <div>
      <DrawerModal open onClose={onClose}>
        {type !== 'goal' && (
          <Headline3 className="mb-4">
            {type === 'meeting'
              ? meeting?.title || ''
              : `View ${userName}'s ${type}s`}
          </Headline3>
        )}

        {meeting && type === 'meeting' && <MeetingView meeting={meeting} />}
        {goal && type === 'goal' && <ObjectiveView goal={goal} />}
      </DrawerModal>
    </div>
  );
};

interface IMeetingView {
  meeting: IMeetingResponse;
}

const MeetingView: FC<IMeetingView> = ({meeting}) => {
  const ShowHostNote = () => {
    if (
      meeting &&
      (meeting.hostNote || authStore.auth.user.id === meeting.user.id)
    ) {
      return (
        <>
          <SectionCard
            contentStyling={{padding: '24px'}}
            CustomHeaderTitle={
              <Headline3>{userName(meeting.user)}'s notes</Headline3>
            }>
            <div>
              <Note>{meeting.hostNote}</Note>
            </div>
          </SectionCard>
          <VerticalSpacer size="16px" />
        </>
      );
    }
    return null;
  };

  const participant = Array.isArray(meeting.participant)
    ? meeting.participant.map((participant) =>
        typeof participant === 'string' ? participant : participant.id,
      )
    : meeting.participant.id;
  return (
    <>
      <MeetingAgenda
        isReadOnly
        isHostSharing={meeting.shareHostAgenda}
        users={{
          user: meeting.user.id,
          participant: participant,
        }}
        participantSharing={meeting.shareParticipantAgenda}
        updateMeeting={async (value, field) => {}}
        agendas={{
          host: {
            user: meeting.user,
            agenda: meeting.hostAgenda,
          },
          participant: {
            user: meeting.participant,
            agenda: meeting.participantAgenda,
          },
        }}
      />

      <div>
        <VerticalSpacer size="24px" />
        <NextSteps
          isReadOnly
          steps={meeting.nextSteps}
          users={[meeting.user.id, ...participant]}
          updateMeeting={async (data) => {}}
        />
        <VerticalSpacer size="24px" />

        <ShowHostNote />
      </div>
    </>
  );
};

interface ObjectiveViewProps {
  goal: TGoalResponse;
}

const ObjectiveView: FC<ObjectiveViewProps> = ({goal}) => {
  return (
    <>
      <GoalSection goal={goal} excludeCopyLink />

      <VerticalSpacer size="24px" />

      {goal.keyResults.length > 0 && (
        <KeyResultSection keyResult={goal.keyResults} />
      )}
      <VerticalSpacer size="34px" />
      <MemberActivity goal={goal as any} isReadOnly />
    </>
  );
};
