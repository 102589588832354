import {memo} from 'react';
import {useBreakpoints} from '@utils/use-breakpoints';
import {QuestionKind} from '../../../pages/dashboard/company-legacy/company-components/company-checkins/company-checkins.interface';

export interface QuestionTextProps {
  kind: QuestionKind;
}

export const QuestionText = memo(({kind}: QuestionTextProps) => {
  const {isXs} = useBreakpoints();
  switch (kind) {
    case QuestionKind.GENERAL:
      return (
        <span style={{whiteSpace: 'nowrap'}}>
          {isXs ? 'Company' : 'Company-wide'}
        </span>
      );
    case QuestionKind.INDIVIDUAL:
      return <>Individual</>;
    case QuestionKind.GROUP:
      return <>Group</>;
    default:
      return <>Personal</>;
  }
});

QuestionText.displayName = 'QuestionText';
