import {authStore} from '@store/stores/auth-store';
import {Checkbox} from '@ui/atoms/checkbox';
import {CancelIcon} from '@ui/atoms/icons';
import {SecondaryPlusIcon} from '@ui/atoms/icons/plus';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {HorizontalSideRule} from '@ui/atoms/spacer/spacer';
import {Body2} from '@ui/atoms/typography';
import {ModalCard} from '@ui/layouts/modal-card';
import {BackAction} from '@ui/molecules/back-action';
import {Modal} from '@ui/molecules/modal';
import {FlexRow} from '@ui/style/styles';
import {writeFirebaseData, updateFirebaseData} from '@utils/firebase-handler';
import {db} from '@utils/firebase-request';
import {get, ref} from 'firebase/database';

import {FC, memo, useState} from 'react';
import nextId from 'react-id-generator';
import {EditInsight} from './edit-insight/edit-insight';
import {InsightTable} from './insight-table';
import {Insight} from './interface';
import {ModalWrapper} from './styles';

interface InsightsOverviewProps {
  insights: Insight[];
  filterActive?: boolean;
}

export const InsightsOverview: FC<InsightsOverviewProps> = memo(
  ({insights, filterActive}) => {
    const [selectedInsights, setSelectedInsights] = useState<string[]>([]);

    const hasSelectedInsights = selectedInsights.length;

    const [editInsight, setEditInsight] = useState('');

    const editedInsight = (id: string) =>
      insights.find((insight) => insight.firebaseId === id);

    const insight = editedInsight(editInsight);

    const checkInsight = (id: string) =>
      selectedInsights.includes(id)
        ? setSelectedInsights((prev) =>
            prev.filter((insight) => insight !== id),
          )
        : setSelectedInsights((prev) => [...prev, id]);

    const handleToggleAll = () => {
      if (selectedInsights.length) {
        setSelectedInsights([]);
        return;
      }

      setSelectedInsights(insights.map((insight) => insight.id));
    };

    const handleAddToDashboard = (selectedInsights: string[]) => {
      get(
        ref(
          db,
          `${process.env.REACT_APP_DEV_ENV}/${authStore.auth.user.workspace.id}/dashboard`,
        ),
      ).then((snapshot) => {
        if (!snapshot.exists()) {
          writeFirebaseData(`dashboard`, {
            name: 'Dashboard',
            slug: 'default',
            order: 1,
            id: nextId('dashboard'),
          });
        }
      });

      selectedInsights.forEach((id) => {
        const insight = insights.find((_insight) => _insight.firebaseId === id);

        updateFirebaseData(`insights/${insight?.firebaseId}`, {
          ...insight,
          section: 'default',
        });
      });

      setSelectedInsights([]);
    };

    return (
      <div className="border border-borderLight rounded-[10px] bg-white mt-6">
        <FlexRow className="px-6 py-4 gap-4">
          <FlexRow className="gap-1">
            <Checkbox
              checked={!!hasSelectedInsights}
              kind={!hasSelectedInsights ? 'import' : 'indeterminate'}
              state={hasSelectedInsights ? 'error' : undefined}
              onChange={handleToggleAll}
            />
            {hasSelectedInsights ? (
              <Body2>Deselect</Body2>
            ) : (
              <Body2 kind="textBody">Select all</Body2>
            )}
          </FlexRow>
          <HorizontalSideRule size="22px" />
          {hasSelectedInsights ? (
            <button onClick={() => handleAddToDashboard(selectedInsights)}>
              <FlexRow className="gap-1">
                <SecondaryPlusIcon />
                <Body2 kind="purple300">Add to dashboard</Body2>
              </FlexRow>
            </button>
          ) : (
            <Body2 kind="textBody">
              Showing <span className="font-semibold">{insights.length}</span>{' '}
              of <span className="font-semibold">{insights.length}</span>
            </Body2>
          )}
        </FlexRow>

        <InsightTable
          data={insights as any}
          filterActive={filterActive}
          onEdit={setEditInsight}
          handleAddToDashboard={handleAddToDashboard}
          selectedInsights={selectedInsights}
          onCheck={checkInsight}
        />

        <Modal open={!!editInsight} onClose={() => setEditInsight('')}>
          <ModalWrapper>
            <BackAction
              icon={<CancelIcon />}
              title="Close"
              onClick={() => setEditInsight('')}
              width="md"
            />
            <VerticalSpacer size="16px" />

            <ModalCard title="">
              <div>
                {!!insight && (
                  <EditInsight
                    {...insight}
                    updateEditInsight={setEditInsight}
                    handleAddToDashboard={handleAddToDashboard}
                    onClose={() => setEditInsight('')}
                    onDuplicate={() => {}}
                    firebaseId={(insight as any).firebaseId}
                  />
                )}
              </div>
            </ModalCard>
          </ModalWrapper>
        </Modal>
      </div>
    );
  },
);
