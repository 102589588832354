export function loadImage(url: string, crossOrigin?: string) {
  const image = new Image();

  if (crossOrigin) image.crossOrigin = crossOrigin;

  return new Promise((resolve, reject) => {
    const loaded = (event: Event) => {
      unbindEvents(image);
      resolve(event?.target || event?.srcElement);
    };

    const errored = (error: Event | string) => {
      unbindEvents(image);
      reject(error);
    };

    image.onload = loaded;
    image.onerror = errored;
    image.onabort = errored;

    image.src = url;
  });
}

function unbindEvents(image: HTMLImageElement) {
  image.onload = null;
  image.onerror = null;
  image.onabort = null;
  try {
    // remove the image object from memory to garbage collect for some browser
    // @ts-ignore
    delete image.src;
  } catch (e) {
    // Safari's strict mode throws, ignore
  }
}
