import {observer} from 'mobx-react';
import UserDateRangeFilter from '@pages/dashboard/goals/objective-filter';
import {usePublicWallHook} from './public-wall.hook';
import {useFetchHook} from '@hooks/query-hook';
import FeedbackSummarySection from '../summary-section';
import {useCallback} from 'react';

const PublicWall = () => {
  const {controller, dateRange, setDateRange, filters} = usePublicWallHook();

  const fetchFeedback = useCallback(async () => {
    const response = await controller.fetchPublicFeedback(filters);
    return response.results;
  }, [filters, controller]);

  const {data = [], isLoading} = useFetchHook(
    ['public-wall-feedback', filters],
    () => fetchFeedback(),
  );

  return (
    <div>
      <div className="bg-white rounded-[10px]">
        <FeedbackSummarySection
          variant="columnStart"
          empty={{
            title: 'No public feedback',
            subText: 'Feedback & praise will appear here.',
          }}
          isFetching={isLoading}
          filter={
            <div className="flex sm:flex-col flex-row sm:items-start items-center ">
              <UserDateRangeFilter
                removeUserFilter
                showDateRange
                _height="36px"
                dateRange={dateRange}
                canFilterAll
                setDateRange={setDateRange}
              />
            </div>
          }
          data={(data as any[]) || []}
        />
      </div>
    </div>
  );
};

export default observer(PublicWall);
