import {
  Wrapper,
  OverviewWrapper,
  FilterContainer,
  DropdownWrapper,
} from './styles';
import {Button} from '@ui/atoms/button';
import {FlexRowSpaceBetween} from '@ui/style/styles';
import {ItemLoader} from '@ui/organisms/item-loader';
import {Body1, Body2, CTA, Headline4} from '@ui/atoms/typography';
import ObjectiveFilter from '@pages/dashboard/goals/objective-filter';
import {Image} from '@ui/atoms/image';
import {DropdownCustom} from '@ui/molecules/dropdown-custom';
import {CheckinsTeamSummary} from './direct-reports-summary';
import {capitalize} from '@utils';
import {observer} from 'mobx-react-lite';
import {YouCalendar} from '@pages/dashboard/company-legacy/shared-components/calendars/calendar-you-variant';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {ArrowHeadDownIcon, CancelIcon} from '@ui/atoms/icons';
import {useCheckinsQuestions} from '@hooks/useCheckinsQuestions';
import {useCheckinsDirectReports} from '@hooks/useCheckinsDirectReports';
import {useQuery} from 'react-query';
import {MultiOptionsSelectField} from '@ui/molecules/select/multi-options';
import {CheckinsQuestionsDirectReports} from './checkins_questions_direct_reports';
import {useMemo} from 'react';

export const CheckinsDirectReports = observer(() => {
  const {
    userFilter,
    updateUserFilter,
    clearSelection,
    loading,
    setClearSelection,
    usersCheckins,
    checkinsDates,
    configuration,
    setCalendarView,
    setCheckinsDate,
    loader,
    moods,
    activeCalWeeks,
    dateOptions,
    selectedDate,
    setSelectedDate,
    handleCalendarWeekClick,
    fetchReportCheckinsQuestions,
    selectedQuestions,
    setSelectedQuestions,
    months,
    setShowQuestionSelectApply,
    showQuestionSelectApply,
    setIsQuestionSelect,
    isQuestionSelectDisabled,
  } = useCheckinsDirectReports();

  const {getCheckinsQuestions} = useCheckinsQuestions();

  const {data: questions, isLoading: questionLoading} = useQuery(
    ['questions-direct-reports'],
    () => getCheckinsQuestions(),
  );

  const isPulseActive = useMemo(() => {
    return configuration?.generalFrequency?.activeCheckins?.includes('pulse');
  }, [configuration?.generalFrequency?.activeCheckins]);

  const {data: questionsData = [], isLoading: questionsLoading} = useQuery(
    ['qa-direct-report', selectedQuestions],
    () =>
      fetchReportCheckinsQuestions({
        startDate: checkinsDates.starts,
        endDate: checkinsDates.ends,
        questionId: selectedQuestions.join(','),
      }),
    {
      enabled: !!selectedQuestions.length && !showQuestionSelectApply,
    },
  );

  if (loading) {
    return <ItemLoader />;
  }

  return (
    <Wrapper>
      <FilterContainer className="bg-white">
        <FlexRowSpaceBetween>
          <div>
            <ObjectiveFilter
              userFilter={userFilter}
              setUserFilter={updateUserFilter}
              directReportFilter
              clearSelection={clearSelection}
              setClearSelection={setClearSelection}
            />
          </div>
          <div>
            <DropdownCustom
              collapseOnClick={true}
              menu={(handleClose: () => void) => (
                <>
                  <DropdownWrapper>
                    <YouCalendar
                      historicalCheckinsCloseModal={handleClose}
                      startYear={
                        configuration?.createdAt
                          ? new Date(configuration?.createdAt).getFullYear()
                          : new Date().getFullYear()
                      }
                      variant="directReports"
                      onClickWeek={handleCalendarWeekClick}
                    />
                  </DropdownWrapper>
                </>
              )}
              shouldNotIncludeWrapper={true}
              customComponent={(
                handleOpen: (event: any) => void,
                open: boolean,
              ) => (
                <>
                  <Button
                    kind="secondary"
                    onClick={(event) => {
                      setCalendarView('year');
                      handleOpen(event as any);
                    }}
                    style={{padding: '8px 16px'}}>
                    <FlexRowSpaceBetween style={{width: '100%'}}>
                      <Body2 weight="bold">
                        {' '}
                        {months[checkinsDates.month]} {' ' + checkinsDates.year}
                      </Body2>

                      <ArrowHeadDownIcon
                        style={{
                          marginLeft: 14,
                          stroke: 'black',
                          transform: !open ? 'rotate(0deg)' : 'rotate(180deg)',
                        }}
                      />
                    </FlexRowSpaceBetween>
                  </Button>
                </>
              )}
              customDropdownWrapperStyles={{
                top: '130%',
                boxShadow: '0px',
                right: 0,
                padding: 0,
                overflowY: 'auto',
                background: 'none',
                border: 0,
              }}
            />
          </div>
        </FlexRowSpaceBetween>
        <div className="border-t border-t-borderLight mt-4 pt-4 flex gap-3 flex-wrap">
          {dateOptions.map((option) => (
            <button
              key={option.value}
              onClick={() => {
                setSelectedDate(option.value);

                const week = activeCalWeeks.find(
                  (_week) => _week.weekOfYear === Number(option.value),
                );

                if (week && option.value) {
                  setCheckinsDate({
                    weekOfYear: week.weekOfYear,
                    starts: week.dateStart,
                    ends: week.dateEnd,
                  });
                }
              }}
              className={`py-1 px-2 ${
                selectedDate !== option.value ? 'bg-[#F6F6F8]' : 'bg-[#585ADF]'
              }  rounded-lg flex items-center gap-1 cursor-pointer`}>
              <Body2
                kind={selectedDate !== option.value ? 'textBody' : 'white'}>
                {option.label}
              </Body2>
              {(option as any).count !== undefined && (
                <div className="border border-borderLight bg-white ml-1 rounded-md py-[3px] px-[6px]">
                  <Body2 size="9px" weight="bold">
                    {(option as any).count}
                  </Body2>
                </div>
              )}
            </button>
          ))}
        </div>
      </FilterContainer>

      <VerticalSpacer size="24px" />

      {!selectedDate && (
        <>
          <OverviewWrapper className="bg-white">
            <Body1 weight="bold">Overview</Body1>

            {!loader &&
              !!usersCheckins?.summary &&
              Object.keys(usersCheckins?.summary).length > 0 && (
                <div>
                  <div className="summary">
                    {isPulseActive && (
                      <div className="box">
                        <div>
                          <Headline4 kind="textDark" style={{fontWeight: 500}}>
                            Average pulse
                          </Headline4>
                          {!!usersCheckins?.summary?.averagePulse ? (
                            <CTA kind="green400">
                              {capitalize(usersCheckins?.summary?.averagePulse)}
                            </CTA>
                          ) : (
                            <CTA kind="textMuted">--/--</CTA>
                          )}
                        </div>
                        <div>
                          {!!usersCheckins?.summary?.averagePulse && (
                            <Image
                              {...moods.filter(
                                (mood) =>
                                  mood.text ===
                                  capitalize(
                                    usersCheckins?.summary?.averagePulse,
                                  ),
                              )[0]?.emoji}
                              width="60px"
                              height="60px"
                            />
                          )}
                        </div>
                      </div>
                    )}

                    <div className="box">
                      <div>
                        <Headline4 kind="textDark" style={{fontWeight: 500}}>
                          Submitted
                        </Headline4>
                      </div>
                      <div className="numbers">
                        {usersCheckins?.summary?.submitted}
                      </div>
                    </div>
                    <div className="box">
                      <div>
                        <Headline4 kind="textDark" style={{fontWeight: 500}}>
                          Reviewed
                        </Headline4>
                      </div>
                      <div className="numbers">
                        {usersCheckins?.summary?.reviewed}
                      </div>
                    </div>
                  </div>
                </div>
              )}

            <div className="border-t border-t-borderLight pt-6  mt-2 flex flex-row items-center gap-2">
              <div className="w-[95%]">
                <MultiOptionsSelectField
                  options={questions}
                  performance
                  searchPlaceholder={`Search question`}
                  groupDisplayLength={3}
                  margin
                  showSearchIcon
                  isLoading={questionLoading}
                  defaultValue={questions?.filter((question) =>
                    selectedQuestions.includes(question.value),
                  )}
                  noOptionsMessage={`Select question not found`}
                  placeholder={`Select question`}
                  onChange={(data: {value: string}[]) => {
                    if (isQuestionSelectDisabled) setIsQuestionSelect(false);
                    setSelectedQuestions(data.map((option) => option.value));
                  }}
                />
              </div>

              {showQuestionSelectApply ? (
                <Button
                  style={{padding: '12px 24px'}}
                  disabled={isQuestionSelectDisabled}
                  onClick={() => {
                    setShowQuestionSelectApply(false);
                  }}>
                  Apply
                </Button>
              ) : (
                <Button
                  style={{padding: '12px 24px'}}
                  className="flex items-center"
                  onClick={() => {
                    setSelectedQuestions([]);

                    setShowQuestionSelectApply(true);
                    setIsQuestionSelect(true);
                  }}
                  kind="secondary">
                  Clear <CancelIcon style={{strokeWidth: 1.5}} />
                </Button>
              )}
            </div>
          </OverviewWrapper>

          <CheckinsQuestionsDirectReports
            data={questionsData || []}
            isLoading={questionsLoading}
          />
        </>
      )}

      {!!selectedDate && (
        <>
          <VerticalSpacer size="16px" />
          <CheckinsTeamSummary
            loader={loader}
            loading={loading}
            activeWeeks={activeCalWeeks}
          />
        </>
      )}
    </Wrapper>
  );
});
