import {Button} from '@ui/atoms/button';
import {Headline4, CTA} from '@ui/atoms/typography';
import {SectionCard} from '@ui/layouts/section-card';
import {SelectField} from '@ui/molecules/select/selectfield';
import {capitalize} from '@utils';
import {checkinName} from '@utils/checkin-name';
import React, {FC, useState} from 'react';
import {SectionComponentWrapper} from '../admin-checkins.styles';
import {useBreakpoints} from '@utils/use-breakpoints';

interface CheckinFrequencyProps {
  value?: {
    frequency?: string;
    week?: string | null;
  };

  errors?: any;
  handleFormValueChange: (data: any, value: string) => void;
}
export const CheckinFrequency: FC<CheckinFrequencyProps> = ({
  value = {
    week: '',
    frequency: '',
  },
  handleFormValueChange,
  errors,
}) => {
  const [isEditingCheckinsFrequency, setIsEditingCheckinsFrequency] = useState(
    false,
  );

  const {isXs} = useBreakpoints();

  return (
    <div>
      <SectionCard
        title={`${capitalize(checkinName())} frequency`}
        action={
          <Button
            kind={isEditingCheckinsFrequency ? 'primary' : 'secondary'}
            style={{padding: '8px 16px'}}
            type={!isEditingCheckinsFrequency ? 'submit' : 'button'}
            data-form-action={true}
            onClick={() => {
              setIsEditingCheckinsFrequency(!isEditingCheckinsFrequency);
            }}>
            {isEditingCheckinsFrequency ? 'Save' : 'Edit'}
          </Button>
        }
        contentStyling={{
          padding: isXs ? '0px' : '0px 0px 10px 0px',
        }}
        CustomHeaderTitle={
          <div className="flex items-center">
            <Headline4 kind="textDark">
              {capitalize(checkinName())} frequency
            </Headline4>
          </div>
        }>
        <SectionComponentWrapper>
          <>
            <CTA kind="textDark" className="mb-1">
              How often should users submit their {checkinName()}s?
            </CTA>

            <>
              <SelectField
                value={value.frequency}
                margin
                helper={(errors?.generalFrequency as any)?.message || ''}
                state={
                  !!(errors as any)?.generalFrequency ? 'error' : 'default'
                }
                onChange={(data: {value: string}) => {
                  handleFormValueChange(
                    data?.value,
                    `generalFrequency.frequency`,
                  );
                }}
                placeholder={'Weekly'}
                borderRadius="10px"
                options={['weekly', 'monthly', 'quarterly'].map((day) => ({
                  label: capitalize(day),
                  value: day,
                }))}
                disabled={!isEditingCheckinsFrequency}
              />

              {(value.frequency === 'monthly' ||
                value.frequency === 'quarterly') && (
                <>
                  <CTA kind="textDark" className="mb-1 mt-2">
                    How often should users submit their {checkinName()}s?
                  </CTA>

                  <SelectField
                    margin
                    value={value.week}
                    helper={
                      (errors?.generalFrequency as any)?.week?.message || ''
                    }
                    state={
                      !!(errors as any)?.generalFrequency?.week
                        ? 'error'
                        : 'default'
                    }
                    onChange={(data: {value: string}) => {
                      handleFormValueChange(
                        data?.value,
                        `generalFrequency.week`,
                      );
                    }}
                    borderRadius="10px"
                    placeholder={'Select run time'}
                    options={
                      value.frequency === 'monthly'
                        ? [
                            {
                              label: 'First week of the month',
                              value: 'first',
                            },
                            {
                              label: 'Last week of the month',
                              value: 'last',
                            },
                          ]
                        : [
                            {
                              label: 'First week of the quarter',
                              value: 'first',
                            },
                            {
                              label: 'Mid-Quarter (the week of the 15th)',
                              value: 'mid',
                            },
                            {
                              label: 'Last week of the quarter',
                              value: 'last',
                            },
                          ]
                    }
                    disabled={!isEditingCheckinsFrequency}
                  />
                </>
              )}
            </>
          </>
        </SectionComponentWrapper>
      </SectionCard>
    </div>
  );
};
