import {TKeyResult} from '@hooks';
import styled from 'styled-components';
import {cfv} from '@utils/framework';
import {capitalize} from '@utils';

import {VerticalSpacer} from '@ui/atoms/spacer';
import {Body2} from '@ui/atoms/typography';
import {KeyResultValues} from '../../../../reporting/reporting-pages/admin-analytics/analytics-pages/analytics-status/analytics--status-graph';

const Wrapper = styled.div`
  border-radius: 10px;
  border: 1px solid #ededf2;
  padding: 16px;
`;
const HorizontalLine = styled.div`
  width: 100%;
  border-top: 1px solid #ededf2;
`;
export const KeyResultSection = ({keyResult}: {keyResult: TKeyResult[]}) => {
  return (
    <Wrapper>
      <Body2 weight="bold">{capitalize(cfv(undefined, true).k_rs)}</Body2>
      <VerticalSpacer size="16px" />

      <HorizontalLine />
      <div>
        {keyResult &&
          keyResult.map((result, index) => (
            <KeyResultValues
              keyResult={result}
              index={index}
              variant="member"
            />
          ))}
      </div>
    </Wrapper>
  );
};
