import {ComponentPropsWithoutRef, memo, ReactElement, RefObject} from 'react';
import styled, {CSSProperties} from 'styled-components';
import {Input} from '../../../atoms/textArea';
import {Label} from '../../../atoms/label';
import {FieldWrapper} from '../../../atoms/field-wrapper';
import {Helper} from '../../../atoms/helper';
import {InputState} from '../../../interface';

export interface TextFieldProps extends ComponentPropsWithoutRef<'input'> {
  label?: string;
  state?: InputState;
  helper?: string | ReactElement;
  action?: (ref: RefObject<HTMLInputElement>) => ReactElement;
  margin?: boolean;
  inputStyle?: CSSProperties;
  actionStyle?: CSSProperties;
  onChange?: (e: any) => void;
}

const Wrapper = styled.div`
  position: relative;
`;

const InputWrapper = styled.div<{hasLabel?: boolean}>`
  padding-top: ${({hasLabel}) => (hasLabel ? '30px' : '0')};
  position: relative;
  display: flex;
  align-items: center;
`;

const ActionWrapper = styled.span`
  position: absolute;
  right: 16px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const TextArea = memo(
  ({
    label,
    placeholder,
    state,
    helper,
    action,
    margin,
    inputStyle,
    style,
    actionStyle,
    value,
    onChange,
  }: TextFieldProps) => {
    return (
      <FieldWrapper helper={!!helper} margin={margin} style={style}>
        <Wrapper>
          <InputWrapper hasLabel={!!label}>
            <Input
              placeholder={placeholder}
              value={value}
              style={inputStyle}
              onChange={onChange}
              state={!!helper ? 'error' : 'default'}
            />
            {action && <ActionWrapper style={actionStyle}></ActionWrapper>}
            <Label aria-label="label">{label}</Label>
          </InputWrapper>
          <Helper
            aria-label="helper"
            state={!!helper ? 'error' : 'default'}
            children={helper}
          />
        </Wrapper>
      </FieldWrapper>
    );
  },
);
