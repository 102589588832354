import styled from 'styled-components';
import {memo, ReactNode} from 'react';
import {ifProp} from 'styled-tools';
import {getColorFromTheme} from '../../ui-utils';
import {TChildProps} from '../../interface';

const Wrapper = styled.section<{errors?: boolean}>`
  border: 1px solid;
  border-color: ${ifProp(
    'errors',
    getColorFromTheme('red400'),
    getColorFromTheme('borderLight'),
  )};
  border-radius: 10px;
`;

const FooterWrapper = styled.div<{errors?: boolean}>`
  border-top: 1px solid;

  border-color: ${ifProp(
    'errors',
    getColorFromTheme('red400'),
    getColorFromTheme('borderLight'),
  )};
  border-radius: 0px 0px 10px 10px;
  padding: 16px 12px;
`;

const Container = styled.div`
  padding: 24px 12px 16px;
`;

export interface TActionCardProps extends TChildProps {
  footer: ReactNode;
  errors?: boolean;
}

export const ActionCard = memo(function ({
  footer,
  children,
  errors,
}: TActionCardProps) {
  return (
    <Wrapper errors={errors}>
      <Container>{children}</Container>
      <FooterWrapper errors={errors}>{footer}</FooterWrapper>
    </Wrapper>
  );
});

ActionCard.displayName = 'ActionCard';
