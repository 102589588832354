import {Button} from '../../../../../ui/atoms/button';
import {VerticalSpacer} from '../../../../../ui/atoms/spacer';
import {EmailsInvitation} from '../../../../../ui/organisms/emails-invitation';
import PageLayout from '@ui/templates/page-template/page-layout';
import {useNewEmailHook} from './workspace-invite-hooks';
import {Controller} from 'react-hook-form';
import {
  HorizontalHr,
  InvitationSent,
  ErrorInvite,
} from './workspace-invite.style';
import {observer} from 'mobx-react-lite';
import {GroupedInvite, ManualWrapper} from './workspace-invite.style';
import {CSVInvitation} from '../../../../../ui/organisms/csv-invitation';
import {PageSideLayout} from '@ui/layouts/page-size';
import {SideNavigation} from '@ui/molecules/side-nav';
import {FlexRow, FlexRowCenter, FlexRowSpaceBetween} from '@ui/style/styles';
import {useNavigate} from 'react-router';
import {useMemo} from 'react';

import {useStoreContext} from '../../../../../store/store-context';
import {Headline3, Body1, Body2} from '@ui/atoms/typography';
import {UserListCard} from '@ui/molecules/user/list-card';
import {PlainButton} from '@ui/atoms/plain-button/plain-button';

interface IWorkspaceInviteForm {
  global?: boolean;
}
export const WorkspaceInviteForm = observer(
  ({global}: IWorkspaceInviteForm) => {
    const {
      updateEmails,
      isSubmitting,
      handleSubmit,
      submitInvitations,
      control,
      errors,
      disabledWorkspaceInvite,
      setInvitedPersons,
      handleCSVUpload,
      csvFile,
      clearCSVFile,
      submitInvitationsCsv,
      invitedPersons,
      csvHandleSubmit,
      csvControl,
    } = useNewEmailHook();

    const navigate = useNavigate();
    const {
      groupTypeStore: {groupType},
      storeDashboardRoutes: {inviteTabs, activateInviteTabs},
      groupStore: {groups},
      usersStore: {users},
    } = useStoreContext();

    const formatOptionsTypes = useMemo(() => {
      return groupType?.map((grpType) => {
        return {
          ...grpType,
          data: groups
            .filter((group) => group.groupType === grpType.id)
            ?.map((group) => {
              return {
                label: group.name,
                value: group.id,
              };
            }),
        };
      });
    }, [groups, groupType]);

    const navigations = useMemo(() => {
      const nav = [
        {
          active: inviteTabs.manual,
          href: '#',
          id: '1',
          type: 'status',
          onClick: () => {
            activateInviteTabs('manual');
          },
          title: 'Manual invite',
        },
        {
          active: inviteTabs.csv,
          href: '#',
          id: '2',
          type: 'status',

          onClick: () => {
            activateInviteTabs('csv');
          },
          title: 'Bulk invite via .csv',
        },
      ];
      return nav;
    }, [inviteTabs.manual, inviteTabs.csv, activateInviteTabs]);

    const groupedEmails = invitedPersons?.emails?.reduce(
      (groups: any, emailObj: any) => {
        emailObj.group.forEach((groupId: string) => {
          if (!groups[groupId]) {
            groups[groupId] = {
              emails: [],
              managers: [],
            };
          }
          groups[groupId].emails.push(emailObj.email);
          groups[groupId].managers.push(emailObj.manager);
        });
        return groups;
      },
      {},
    );

    return (
      <>
        <PageLayout title="Invite members" onClick={() => navigate(-1)}>
          {invitedPersons ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <div style={{width: '45%'}}>
                {invitedPersons?.emails?.length > 0 && (
                  <InvitationSent>
                    <Headline3>Invitation sent</Headline3>
                    <VerticalSpacer size="16px" />

                    {groupedEmails &&
                      Object.entries(groupedEmails)?.map((email: any) => (
                        <GroupedInvite>
                          <Body1 weight="bold">
                            {' '}
                            {
                              groups.find((grp: any) => grp.id === email[0])
                                ?.name
                            }
                          </Body1>
                          <VerticalSpacer size="16px" />
                          <FlexRow style={{flexWrap: 'wrap'}}>
                            {email[1].emails.map((mail: any) => (
                              <div className="emails">{mail}</div>
                            ))}
                          </FlexRow>
                          <VerticalSpacer size="16px" />
                          <HorizontalHr />
                          <VerticalSpacer size="16px" />
                          <FlexRowSpaceBetween>
                            <Body2 kind="textBody" weight="semibold">
                              Manager
                            </Body2>
                            {users
                              .filter(
                                (user) => user.id === email[1].managers[0],
                              )
                              .map((user) => (
                                <UserListCard
                                  tooltip={true}
                                  userId={user?.id}
                                  type="secondary"
                                  avatar={user.avatar ? user.avatar.url : ''}
                                  name={
                                    user.firstName && user.lastName
                                      ? `${user.firstName} ${user.lastName}`
                                      : '----- -----'
                                  }
                                />
                              ))}
                          </FlexRowSpaceBetween>
                        </GroupedInvite>
                      ))}
                  </InvitationSent>
                )}

                <VerticalSpacer size="24px" />
                {invitedPersons?.existingEmails?.length > 0 && (
                  <ErrorInvite>
                    <div className="error-body">
                      <div className="error-wrapper">
                        <Body1 weight="bold" kind="red500">
                          Error!
                        </Body1>
                        <VerticalSpacer size="16px" />
                        <Body2 kind="red500">
                          Email(s) exists on another workspace or has already
                          been invited.
                        </Body2>
                      </div>
                    </div>
                    <VerticalSpacer size="16px" />

                    <FlexRow style={{flexWrap: 'wrap'}}>
                      {invitedPersons?.existingEmails?.map((mail: any) => (
                        <div className="emails">{mail}</div>
                      ))}
                    </FlexRow>
                  </ErrorInvite>
                )}
                {invitedPersons?.error?.length > 0 && (
                  <ErrorInvite>
                    <div className="error-body">
                      <div className="error-wrapper">
                        <Body1 weight="bold" kind="red500">
                          Error!
                        </Body1>
                        <VerticalSpacer size="10px" />
                        <Body2 kind="red500">{invitedPersons?.error}</Body2>
                      </div>
                    </div>
                    <VerticalSpacer size="16px" />

                    <FlexRow style={{flexWrap: 'wrap'}}>
                      {invitedPersons?.allEmails?.map((mail: any) => (
                        <div className="emails">{mail.email}</div>
                      ))}
                    </FlexRow>
                  </ErrorInvite>
                )}
                <VerticalSpacer size="24px" />

                <Button
                  width="full"
                  onClick={() => {
                    setInvitedPersons(undefined);
                    navigate('/home', {replace: true});
                    navigate('/invite-user', {replace: true});
                  }}>
                  Invite more members
                </Button>
                <VerticalSpacer size="24px" />
                <FlexRowCenter>
                  <PlainButton onClick={() => navigate(-1)}>
                    <Body2 weight="bold" kind="purple300" align="center">
                      Close
                    </Body2>
                  </PlainButton>
                </FlexRowCenter>
              </div>
            </div>
          ) : (
            <PageSideLayout
              content={
                <>
                  {inviteTabs.manual && (
                    <ManualWrapper>
                      <form
                        key="form2"
                        method="post"
                        onKeyDown={(event) => {
                          if (event.key === 'Enter') {
                            event.preventDefault();
                          }
                        }}
                        onSubmit={
                          Object.keys(csvFile).length > 0
                            ? csvHandleSubmit(submitInvitationsCsv)
                            : handleSubmit(submitInvitations)
                        }>
                        <Controller
                          control={control}
                          name="emails"
                          defaultValue={[]}
                          disable
                          render={() => (
                            <EmailsInvitation
                              defaultValue={['']}
                              setValue={updateEmails}
                              control={control}
                              group={formatOptionsTypes}
                              errors={errors.emails}
                              disabled={Object.keys(csvFile).length > 0}
                            />
                          )}
                        />
                        <VerticalSpacer size="16px" />
                        <Button
                          width="full"
                          type="submit"
                          disabled={disabledWorkspaceInvite}
                          data-form-action={true}
                          isLoading={isSubmitting}
                          aria-busy={isSubmitting}>
                          Send invitations
                        </Button>
                      </form>
                    </ManualWrapper>
                  )}

                  {inviteTabs.csv && (
                    <ManualWrapper>
                      <form
                        key="form2"
                        method="post"
                        onSubmit={
                          Object.keys(csvFile).length > 0
                            ? csvHandleSubmit(submitInvitationsCsv)
                            : handleSubmit(submitInvitations)
                        }>
                        <Controller
                          control={csvControl}
                          name="inviteFile"
                          defaultValue=""
                          render={() => (
                            <CSVInvitation
                              handleCSVUpload={handleCSVUpload}
                              csvFile={csvFile}
                              clearCSVFile={clearCSVFile}
                            />
                          )}
                        />

                        <Button
                          width="full"
                          type="submit"
                          disabled={disabledWorkspaceInvite}
                          data-form-action={true}
                          isLoading={isSubmitting}
                          aria-busy={isSubmitting}>
                          Send invitations
                        </Button>
                      </form>
                    </ManualWrapper>
                  )}
                </>
              }
              navigation={<SideNavigation navigations={navigations} />}
            />
          )}
        </PageLayout>
      </>
    );
  },
);

WorkspaceInviteForm.displayName = 'WorkspaceInvite';
