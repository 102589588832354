import styled from 'styled-components';

interface Props {
  size: string;
  color?: string;
}

export const HorizontalSpacer = styled.div<Props>`
  width: ${({size}) => size};
  display: inline-block;
`;

export const VerticalSpacer = styled.div<Props>`
  height: ${({size}) => size};
  display: block;
`;
export const HorizontalSideRule = styled.div<Props>`
  border-left: 1px solid
    ${({color}) => (color ? color : 'rgba(237, 237, 242, 1)')};
  height: ${({size}) => size};
  margin: 0px 5px;
`;
