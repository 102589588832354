import {ModalCard} from '@ui/layouts/modal-card';
import {Modal} from '@ui/molecules/modal';
import React, {useState} from 'react';
import {ModalWrapper} from './styles';
import {Body2, Headline2} from '@ui/atoms/typography';
import {TextField} from '@ui/molecules/field/textfield';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {Button} from '@ui/atoms/button';
import {ToggleSwitch} from '@ui/atoms/toggle-switch';
import {BackAction} from '@ui/molecules/back-action';
import {CancelIcon} from '@ui/atoms/icons';
import {CustomIconModal} from '@ui/organisms/modal/custom-icon-modal';

interface ICreateNoteTopic {
  handleCreate: (data: {title: string; private: boolean}) => void;
  onClose: () => void;
}
export const CreateNoteTopic = ({handleCreate, onClose}: ICreateNoteTopic) => {
  const [formFields, setFormFields] = useState({
    title: '',
    private: false,
  });

  const handleUpdate = (field: keyof typeof formFields, value: any) => {
    setFormFields((prev) => ({...prev, [field]: value}));
  };

  return (
    <div>
      <Modal open={true} onClose={() => onClose()}>
        <ModalWrapper>
          <div className="flex justify-end mb-3">
            <BackAction
              icon={<CancelIcon />}
              title="Close"
              onClick={() => onClose()}
              width="md"
            />
          </div>
          <ModalCard title="">
            <div>
              <Headline2 className="mb-3">Add note to topic</Headline2>
              <Body2 weight="bold">Title</Body2>
              <TextField
                placeholder="Enter title (max 25 characters)"
                value={formFields.title}
                onChange={(event) => handleUpdate('title', event.target.value)}
                inputStyle={{borderRadius: '10px'}}
              />
              <VerticalSpacer size="10px" />
              <Body2 weight="bold">Visibility settings</Body2>
              <div className="flex justify-between items-center border border-[#CECEDE] p-4 rounded-[10px]">
                <Body2 weight="semibold">
                  Make topic & its contents private
                </Body2>
                <ToggleSwitch
                  switchId={23445}
                  checked={formFields.private}
                  onChange={() => handleUpdate('private', !formFields.private)}
                />
              </div>
              <VerticalSpacer size="32px" />
              <Button
                width="full"
                onClick={() => handleCreate(formFields)}
                disabled={!formFields.title || formFields.title.length > 25}>
                Create
              </Button>
            </div>
          </ModalCard>
        </ModalWrapper>
      </Modal>
    </div>
  );
};

interface IDeleteTopic {
  onClose: () => void;
  handleDelete: () => void;
  topic: string;
}
export const DeleteTopic = ({handleDelete, onClose, topic}: IDeleteTopic) => {
  return (
    <div>
      <CustomIconModal
        open
        onClose={onClose}
        customButton={
          <Button
            kind="secondary"
            width="full"
            onClick={handleDelete}
            type="button">
            Delete
          </Button>
        }
        body={<></>}
        onClick={() => {}}
        title={'Delete Note?'}
        description={
          <div>
            <Body2 align="center">
              Are you sure you want to delete{' '}
              <span className="text-[#585ADF] font-medium text-[16px]">
                {topic}
              </span>
              ? This action cannot be undone.
            </Body2>
          </div>
        }
      />
    </div>
  );
};

interface IEditTopic {
  handleEdit: (field: {title: string}) => void;
  onClose: () => void;
}
export const EditTopic = ({handleEdit, onClose}: IEditTopic) => {
  const [formFields, setFormFields] = useState({
    title: '',
  });

  const handleUpdate = (field: keyof typeof formFields, value: any) => {
    setFormFields((prev) => ({...prev, [field]: value}));
  };

  return (
    <div>
      <Modal open={true} onClose={() => onClose()}>
        <ModalWrapper>
          <div className="flex justify-end mb-3">
            <BackAction
              icon={<CancelIcon />}
              title="Close"
              onClick={() => onClose()}
              width="md"
            />
          </div>
          <ModalCard title="">
            <div>
              <Headline2 className="mb-3">Edit topic</Headline2>
              <Body2 weight="bold">Title</Body2>
              <TextField
                placeholder="Enter title (max 25 characters)"
                value={formFields.title}
                onChange={(event) => handleUpdate('title', event.target.value)}
                inputStyle={{borderRadius: '10px'}}
              />

              <VerticalSpacer size="32px" />
              <Button
                width="full"
                onClick={() => handleEdit(formFields)}
                disabled={!formFields.title || formFields.title.length > 25}>
                Create
              </Button>
            </div>
          </ModalCard>
        </ModalWrapper>
      </Modal>
    </div>
  );
};
