import {Headline3, Body2} from '@ui/atoms/typography';
import {
  CardInfoDiv,
  ResponsiveFlexRowBetween,
} from '../../billing-not-active/billing-not-active.styles';

import {Label} from '@ui/atoms/label';
import {Stripe, StripeElements} from '@stripe/stripe-js/types/stripe-js';
import {Helper} from '@ui/atoms/helper';
import {SelectField} from '@ui/molecules/select/selectfield';
import {useBillingHook} from '../../../admin-billing.hooks';
import {useState, useEffect} from 'react';
import {CARD_OPTIONS} from '../../billing-not-active/components/billng-cycle';
import {CardElement} from '@stripe/react-stripe-js';
import {FlexRowSpaceBetween, FlexRowEnd} from '@ui/style/styles';
import styled from 'styled-components';
import {Country, City} from 'country-state-city';
import {ICity, ICountry, IState} from 'country-state-city/dist/lib/interface';
import {getStatesOfCountry} from 'country-state-city/dist/lib/state';
import {Controller} from 'react-hook-form';
import {TextField} from '@ui/molecules/field/textfield';
import {Button} from '@ui/atoms/button';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {DrawerModal} from '@ui/organisms/modal/drawer-modal';

import {NoBorderButton} from '@pages/dashboard/reporting/reporting-pages/reporting-overview/styles';

const Address = styled.div`
  padding: 16px;
  border: 1px solid #ededf2;
  border-radius: 10px;
`;
interface IUpdate {
  open: boolean;
  onClose: () => void;
  stripe: Stripe | null;
  elements: StripeElements | null;
}
export const UpdateCard = ({open, onClose, stripe, elements}: IUpdate) => {
  const {
    errorsCard,
    updateAddressDetails,
    addressDetails,
    controlCard,
    handleFormValueChangeCard,
    stripeError,
    updatingCard,
    setStripeError,
    // cardComplete,
    setSelectedCountry,
    selectedCountry,
    setSelectedState,
    selectedState,
    addressSaved,
    setSaveAddress,
    setCardComplete,
    // processing,
    // setProcessing,
    // paymentMethod,
    // setPaymentMethod,
    handleUpdatePaymentMethod,
  } = useBillingHook();

  const [countries, setCountries] = useState<ICountry[]>([]);
  const [states, setStates] = useState<IState[]>([]);

  const disabledPaymentButton = !stripe;

  const disableSavedButton =
    !addressDetails.country || !addressDetails.line1 || !addressDetails.state;

  useEffect(() => {
    setCountries(Country.getAllCountries());
  }, []);

  useEffect(() => {
    if (selectedCountry.length > 0) {
      setStates(getStatesOfCountry(selectedCountry));
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (
      selectedState &&
      selectedState?.length > 0 &&
      selectedCountry &&
      selectedCountry?.length > 0
    ) {
      const getIsoCode =
        selectedState.length > 3
          ? states.find((state) => state.name === selectedState)?.isoCode ||
            selectedState
          : selectedState;

      setCities(City.getCitiesOfState(selectedCountry, getIsoCode));
    }
  }, [selectedCountry, selectedState, states]);

  const [cities, setCities] = useState<ICity[]>([]);

  return (
    <div>
      <DrawerModal open={open} onClose={onClose}>
        <div>
          <VerticalSpacer size="16px" />
          <FlexRowSpaceBetween>
            <Headline3>Billing information</Headline3>
            {addressSaved && (
              <NoBorderButton onClick={() => setSaveAddress(false)}>
                <Body2 kind="purple300" weight="bold">
                  {' '}
                  Edit
                </Body2>
              </NoBorderButton>
            )}
          </FlexRowSpaceBetween>

          <VerticalSpacer size="8px" />
          <Address>
            {!addressSaved ? (
              <>
                <Label>Address</Label>
                <VerticalSpacer size="8px" />
                <Controller
                  control={controlCard}
                  name="billing_details.address.line1"
                  defaultValue={addressDetails.line1}
                  render={({onBlur, value}) => {
                    return <></>;
                  }}
                />
                <TextField
                  placeholder="Enter here"
                  type="text"
                  value={addressDetails.line1}
                  inputStyle={{borderRadius: '10px'}}
                  onChange={(event) => {
                    updateAddressDetails('line1', event.target.value);

                    handleFormValueChangeCard(
                      event.target.value,
                      'billing_details.address.line1',
                    );
                  }}
                  helper={
                    errorsCard.billing_details?.address?.line1?.message || ''
                  }
                  state={
                    !!errorsCard.billing_details?.address?.line1
                      ? 'error'
                      : 'default'
                  }
                />
                <Label>Select country</Label>
                <VerticalSpacer size="8px" />
                <Controller
                  control={controlCard}
                  name="billing_details.address.country"
                  render={({onBlur, value}) => {
                    return (
                      <SelectField
                        onBlur={onBlur}
                        borderRadius="10px"
                        name="billing_details.address.country"
                        defaultValue={addressDetails.country}
                        helper={
                          errorsCard.billing_details?.address?.country
                            ?.message || ''
                        }
                        state={
                          !!errorsCard.billing_details?.address?.country
                            ? 'error'
                            : 'default'
                        }
                        onChange={(data: {value: string; isoCode: string}) => {
                          if (data?.value) {
                            handleFormValueChangeCard(
                              data?.value,
                              'billing_details.address.country',
                            );
                            setSelectedCountry(data.isoCode);
                            updateAddressDetails('country', data?.value);
                          } else {
                            handleFormValueChangeCard(
                              null,
                              'billing_details.address.country',
                            );
                            updateAddressDetails('country', null);
                          }
                        }}
                        placeholder="Select / search a country"
                        options={countries?.map((country: ICountry) => ({
                          value: country?.isoCode,
                          label: country?.name,
                          isoCode: country?.isoCode,
                        }))}
                      />
                    );
                  }}
                />

                <ResponsiveFlexRowBetween>
                  <div className="first-div">
                    <Label>State / Province / Region</Label>
                    <VerticalSpacer size="8px" />
                    <Controller
                      control={controlCard}
                      name="billing_details.address.state"
                      render={({onBlur, value}) => {
                        return (
                          <SelectField
                            onBlur={onBlur}
                            borderRadius="10px"
                            name="billing_details.address.state"
                            value={value}
                            defaultValue={addressDetails.state}
                            helper={
                              errorsCard.billing_details?.address?.state
                                ?.message || ''
                            }
                            state={
                              !!errorsCard.billing_details?.address?.state
                                ? 'error'
                                : 'default'
                            }
                            onChange={(data: {
                              value: string;
                              isoCode: string;
                            }) => {
                              if (data?.value) {
                                handleFormValueChangeCard(
                                  data?.value,
                                  'billing_details.address.state',
                                );
                                setSelectedState(data.isoCode);
                                updateAddressDetails('state', data?.value);
                              } else {
                                handleFormValueChangeCard(
                                  null,
                                  'billing_details.address.state',
                                );
                                updateAddressDetails('state', null);
                              }
                            }}
                            placeholder="Select / search a state"
                            options={states?.map((state: IState) => ({
                              label: state.name,
                              value: state.name,
                              isoCode: state.isoCode,
                            }))}
                          />
                        );
                      }}
                    />
                  </div>
                  <div className="s-div">
                    <Label>Select city / Town / Village</Label>
                    <VerticalSpacer size="8px" />
                    <Controller
                      control={controlCard}
                      name="billing_details.address.city"
                      render={({onBlur, value}) => {
                        return (
                          <SelectField
                            onBlur={onBlur}
                            borderRadius="10px"
                            name="billing_details.address.city"
                            value={value}
                            defaultValue={addressDetails.city}
                            helper={
                              errorsCard.billing_details?.address?.city
                                ?.message || ''
                            }
                            state={
                              !!errorsCard.billing_details?.address?.city
                                ? 'error'
                                : 'default'
                            }
                            onChange={(data: {value: string}) => {
                              handleFormValueChangeCard(
                                data?.value,
                                'billing_details.address.city',
                              );
                              updateAddressDetails('city', data?.value);
                            }}
                            placeholder="Select / search a city"
                            options={cities?.map((city: ICity) => ({
                              label: city.name,
                              value: city.name,
                            }))}
                          />
                        );
                      }}
                    />
                  </div>
                </ResponsiveFlexRowBetween>
                <VerticalSpacer size="16px" />
                <FlexRowEnd>
                  <Button
                    style={{padding: '8px 16px'}}
                    disabled={disableSavedButton}
                    onClick={() => {
                      setSaveAddress(true);
                    }}>
                    Save
                  </Button>
                </FlexRowEnd>
              </>
            ) : (
              <>
                <Body2 weight="bold" style={{textTransform: 'capitalize'}}>
                  {addressDetails.line1}, {addressDetails.city},{' '}
                  {addressDetails.state}, {addressDetails.country}
                </Body2>
              </>
            )}
          </Address>

          <VerticalSpacer size="32px" />
          <Headline3 kind="textDark">Enter card details</Headline3>
          <VerticalSpacer size="16px" />
          <CardInfoDiv>
            <TextField
              placeholder="Name on Card"
              type="text"
              // ref={register}
              // helper={errors.name?.message || ''}
              // state={!!errors.name ? 'error' : 'default'}
              name="name"
            />
            <div className="stripeCard">
              <CardElement
                options={CARD_OPTIONS}
                onChange={(e) => {
                  setStripeError(e.error?.message);
                  setCardComplete(e.complete);
                }}
              />
            </div>
            <Helper
              aria-label="helper"
              state={!!stripeError ? 'error' : 'default'}
              children={stripeError || ''}
            />
          </CardInfoDiv>
          <VerticalSpacer size="54px" />

          <Button
            width="full"
            onClick={() => {
              handleUpdatePaymentMethod(stripe, elements, onClose);
            }}
            disabled={disabledPaymentButton || !addressSaved}
            isLoading={updatingCard}
            style={{
              cursor: updatingCard ? 'not-allowed' : undefined,
            }}>
            Update card
          </Button>
        </div>
      </DrawerModal>
    </div>
  );
};
