import {ComponentPropsWithoutRef, forwardRef, Ref} from 'react';
import styled, {css, CSSProperties} from 'styled-components';
import {switchProp} from 'styled-tools';
import {getColorFromTheme} from '../../ui-utils';
import {InputState} from '../../interface';

interface TProps {
  state?: InputState;
  bg?: string;
  style?: CSSProperties;
}

export const RadioInput = styled.input<TProps>`
  top: 0;
  left: 0;
  width: 100%;
  cursor: inherit;
  height: 100%;
  margin: 0;
  opacity: 0;
  padding: 0;
  z-index: 1;
  position: absolute;

  &:disabled + span {
    border-color: transparent;
    background-color: transparent;
    box-shadow: 0px 0px 0px 2px ${getColorFromTheme('borderLight')};
  }

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  &:invalid:not(:focus) + span {
    border-color: ${getColorFromTheme('white')};
    background-color: transparent;
    box-shadow: 0px 0px 0px 2px ${getColorFromTheme('red400')};
  }

  &:checked + span,
  &:focus + span,
  &:active + span &:not(:disabled) + span {
    border-color: ${getColorFromTheme('white')};
    background-color: ${getColorFromTheme('warmBlue')};
    box-shadow: 0px 0px 0px 2px ${getColorFromTheme('warmBlue')};
  }

  &:disabled:checked + span {
    border-color: ${getColorFromTheme('white')};
    background-color: ${getColorFromTheme('borderLight')};
    box-shadow: 0px 0px 0px 2px ${getColorFromTheme('borderLight')};
  }

  ${switchProp('state', {
    error: css`
      + span {
        border-color: ${getColorFromTheme('white')};
        background-color: transparent;
        box-shadow: 0px 0px 0px 2px ${getColorFromTheme('red400')};
      }

      &:checked + span,
      &:focus + span,
      &:active + span &:not(:disabled) + span {
        border-color: ${getColorFromTheme('white')};
        background-color: ${getColorFromTheme('red400')};
        box-shadow: 0px 0px 0px 2px ${getColorFromTheme('red400')};
      }
    `,
  })}
`;
const RadioChildInput = styled.input<TProps>`
  top: 0;
  left: 0;
  width: 100%;
  cursor: inherit;
  height: 100%;
  margin: 0;
  opacity: 0;
  padding: 0;
  z-index: 1;
  position: absolute;

  &:disabled + span {
    border-color: transparent;
    background-color: transparent;
    box-shadow: 0px 0px 0px 2px ${getColorFromTheme('borderLight')};
  }

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  &:invalid:not(:focus) + span {
    border-color: ${getColorFromTheme('white')};
    background-color: transparent;
    box-shadow: 0px 0px 0px 2px ${getColorFromTheme('red400')};
  }

  &:checked + span,
  &:focus + span,
  &:active + span &:not(:disabled) + span {
    border-color: ${getColorFromTheme('white')};
    background-color: ${({bg}) => bg || '#e6821d'};
    box-shadow: ${({bg}) => `0px 0px 0px 2px ${bg || '#e6821d'}`};
  }

  &:disabled:checked + span {
    border-color: ${getColorFromTheme('white')};
    background-color: ${getColorFromTheme('borderLight')};
    box-shadow: 0px 0px 0px 2px ${getColorFromTheme('borderLight')};
  }

  ${switchProp('state', {
    error: css`
      + span {
        border-color: ${getColorFromTheme('white')};
        background-color: transparent;
        box-shadow: 0px 0px 0px 2px ${getColorFromTheme('red400')};
      }

      &:checked + span,
      &:focus + span,
      &:active + span &:not(:disabled) + span {
        border-color: ${getColorFromTheme('white')};
        background-color: ${getColorFromTheme('red400')};
        box-shadow: 0px 0px 0px 2px ${getColorFromTheme('red400')};
      }
    `,
  })}
`;

export const Wrapper = styled.span`
  padding: 5px;
  overflow: visible;
  text-align: center;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;
  background: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
`;

export const InnerWrapper = styled.span`
  padding: 6px;
  border-radius: 100%;
  border: 3px solid;
  background-color: transparent;
  border-color: transparent;
  box-shadow: 0px 0px 0px 2px ${getColorFromTheme('borderDark')};
  transition: all 300ms ease-in-out;
`;

export const InnerWrapper2 = styled.span`
  padding: 4px;
  border-radius: 100%;
  border: 3px solid;
  background-color: transparent;
  border-color: transparent;
  box-shadow: 0px 0px 0px 2px ${getColorFromTheme('borderDark')};
  transition: all 300ms ease-in-out;
`;

export interface RadioProps extends ComponentPropsWithoutRef<'input'>, TProps {}

export const Radio = forwardRef(
  (props: Omit<RadioProps, 'type'>, ref: Ref<HTMLInputElement>) => (
    <Wrapper aria-label="radio-input">
      <RadioInput {...props} type="radio" ref={ref} />
      <InnerWrapper />
    </Wrapper>
  ),
);

export const RadioSm = forwardRef(
  (props: Omit<RadioProps, 'type'>, ref: Ref<HTMLInputElement>) => (
    <Wrapper aria-label="radio-input">
      <RadioInput {...props} type="radio" ref={ref} />
      <InnerWrapper2 />
    </Wrapper>
  ),
);
export const RadioChildSm = forwardRef(
  (props: Omit<RadioProps, 'type'>, ref: Ref<HTMLInputElement>) => (
    <Wrapper aria-label="radio-input">
      <RadioChildInput {...props} type="radio" ref={ref} />
      <InnerWrapper2 style={props.style} />
    </Wrapper>
  ),
);
