import {useCallback, useState} from 'react';
import axios from 'axios';

import {useLocation, useNavigate} from 'react-router';
import {useSearchParams} from 'react-router-dom';
import {CcRequestImpl} from '@api/cc-api/cc-request';
import {CcController} from '@api/cc-api/cc-controller';

import {parseErrorContent} from '../../../../../../../utils/error-handling';
import {activateNotification} from '../../../../../../../ui/molecules/notification/activate-notification';
import {useStoreContext} from '../../../../../../../store/store-context';
import {ValueProps} from './create-review-interface';
import dayjs from 'dayjs';
import {updateReviewFramework} from '@utils/firebase-request';

type StateType = {
  data?: string;
};

export const useReviewCycleHook = () => {
  const {state} = useLocation() as {state: StateType};
  const [searchParams] = useSearchParams();
  const paramsStep = searchParams.get('step');

  const [step, setStep] = useState<number>(paramsStep ? Number(paramsStep) : 1);
  const [openhint, setOpenHint] = useState(true);

  const navigate = useNavigate();
  const [error, setError] = useState('');

  const [cycleError, setCycleError] = useState({
    name: '',
  });

  const {
    authStore: {auth},
  } = useStoreContext();

  const [loading, setLoading] = useState('');

  const [allTemplates] = useState([]);
  const [editSelfType] = useState(false);
  const [disableEditButton, setDisableEditButton] = useState(false);
  const [isDisable] = useState(true);

  const [exceptGroups] = useState([]);
  const [overallError, setOverallError] = useState('');

  const [values, setValues] = useState<ValueProps>(
    state?.data
      ? JSON.parse(state.data)
      : {
          makePublic: false,

          milestones: {
            startDate: '',
            endDate: '',
            self: '',
            manager: '',
            finalize: '',
          },
          period: {starts: '', ends: ''},
          frameworks: [],
          reviewType: '',
          cycleName: '',
        },
  );

  const validateValues = useCallback(
    (values: {[key: string]: any}): boolean => {
      let formHasError = false;

      for (let value in values) {
        const obj = values[value];

        if (!obj) {
          formHasError = true;
        }

        if (Array.isArray(obj)) {
          if (!obj.length) formHasError = true;
        }

        if (typeof obj === 'object') {
          const nestedError = validateValues(obj);
          if (nestedError) {
            formHasError = true;
          }
        }
      }

      return formHasError;
    },
    [],
  );

  const formValidation = useCallback(() => {
    const fieldMapping: Record<number, string[]> = {
      1: ['cycleName', 'period', 'reviewType', 'frameworks'],
      2: ['milestones'],
      3: [],
    };

    const fields = fieldMapping[step];
    const fieldValues: {[key: string]: any} = {};

    for (const field of fields) {
      fieldValues[field] = values[field as keyof typeof values];
    }
    return validateValues(fieldValues);
  }, [step, validateValues, values]);

  const [milestoneError, setMilestoneError] = useState<{
    self?: string;
    manager?: string;
    finalize?: string;
    startDate?: string;
    endDate?: string;
  }>();

  const handleChange = (field: keyof typeof values, value: any) => {
    setValues((prev) => ({...prev, [field]: value}));

    if (field === 'milestones') {
      const {self, manager, finalize, peer, startDate, endDate} = value;

      const managerValidation = [self, peer];
      const finalizeValidation = [self, peer, manager];
      const overallValidation = [self, peer, manager, finalize];

      const isDateWithinRange = (date: string, start: string, end: string) =>
        dayjs(date).isBetween(dayjs(start), dayjs(end), 'day', '[]');

      if (
        overallValidation.some(
          (date) => date && !isDateWithinRange(date, startDate, endDate),
        )
      ) {
        setMilestoneError(() => ({
          startDate: 'Reviews must be within the overall timeline',
        }));
      } else if (peer && dayjs(self).isAfter(dayjs(peer))) {
        setMilestoneError(() => ({
          self: 'Self review date cannot be before peer review ',
        }));
      } else if (
        managerValidation.some((date) => dayjs(date).isAfter(dayjs(manager)))
      ) {
        setMilestoneError(() => ({
          manager: `Self-review is required before manager review.`,
        }));
      } else if (
        finalizeValidation.some((date) => dayjs(date).isAfter(dayjs(finalize)))
      ) {
        setMilestoneError(() => ({
          finalize: `Manager review is required before Finalized review.`,
        }));
      } else {
        setMilestoneError(undefined);
        // No error, you can reset or handle accordingly
      }
    }
  };

  const stepIncrement = () => {
    setStep((prev) => ++prev);
  };

  const handleSubmit = () => {
    if (step < 3) return stepIncrement();

    navigate('/preview-review-cycle', {state: {data: JSON.stringify(values)}});
  };

  const handleDeleteCycle = async (id: any) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/performance-reviews/review-cycle/${id}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.tokens?.access.token}`,
          },
        },
      );
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
    }
  };

  function replaceEmptyStringsWithUndefined(obj: Record<string, any>): any {
    const result: Record<string, any> = {};

    for (const key in obj) {
      if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
        result[key] = replaceEmptyStringsWithUndefined(obj[key]);
      } else {
        result[key] = obj[key] === '' ? undefined : obj[key];
      }
    }

    return result;
  }

  const handleCreateReview = async (_values: ValueProps, publish?: boolean) => {
    setLoading(publish ? 'publish' : 'draft');
    try {
      const request = new CcRequestImpl();
      const controller = new CcController(request);

      const excludedFields: string[] = [
        'createdAt',
        'status',
        'createdBy',
        'updatedAt',
        'updatedBy',
        'workspace',
      ];

      const _data: any = {
        ..._values,
        reviewType: _values.reviewType,
        published: publish,

        period: _values.period?.period
          ? `${_values.period?.period}(${_values.period?.starts} - ${_values.period?.ends})`
          : `${_values.period?.starts} - ${_values.period?.ends}`,
        nominationSettings: {
          min: 1,
          max: 3,
        },
      };
      excludedFields.forEach((field) => {
        if (_data[field]) delete _data[field];
      });

      const computeData = replaceEmptyStringsWithUndefined(_data);

      const response = _values.id
        ? await controller.updateReviewCycle(
            {...computeData, id: undefined},
            _values?.id || '',
          )
        : await controller.createReviewCycle(computeData);

      if (response) {
        const workspaceFrameworks = await controller.fetchCcFramework();
        const reviewFrameworks = workspaceFrameworks?.filter((framework) =>
          _values.frameworks.includes(framework.id),
        );

        updateReviewFramework(_values?.id || response.id, reviewFrameworks);

        activateNotification({
          title: 'Success',
          content: _values.id
            ? 'Review cycle updated'
            : 'Review cycle successfully created',
          kind: 'success',
        });

        setLoading('');

        navigate('/culture-competence-review/review-cycles');
      }
    } catch (e: any) {
      setLoading('');
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
    }
  };

  return {
    step,
    setStep,
    values,
    stepIncrement,
    error,
    setError,
    allTemplates,
    loading,
    handleDeleteCycle,
    cycleError,
    editSelfType,
    formValidation,
    isDisable,
    exceptGroups,
    milestoneError,
    setCycleError,
    overallError,
    setOverallError,
    disableEditButton,
    setDisableEditButton,
    handleSubmit,
    handleCreateReview,
    handleChange,
    setValues,
    openhint,
    setOpenHint,
  };
};
