import {memo} from 'react';

export const HubspotIcon = memo(() => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.1653 7.93041V5.08641C18.5424 4.91023 18.8616 4.63048 19.0858 4.27977C19.3099 3.92907 19.4298 3.52187 19.4313 3.10566V3.03891C19.4297 2.45817 19.1983 1.90168 18.7877 1.49103C18.377 1.08039 17.8205 0.848993 17.2398 0.847409H17.1731C16.5917 0.8482 16.0344 1.07922 15.623 1.48993C15.2116 1.90064 14.9796 2.45759 14.9778 3.03891V3.10566C14.98 3.51914 15.0989 3.9236 15.3207 4.27253C15.5425 4.62147 15.8583 4.90072 16.2318 5.07816L16.2438 5.08566V7.93716C15.1557 8.10232 14.1312 8.55402 13.2753 9.24591L13.2873 9.23841L5.45881 3.14091C6.16606 0.500159 2.72056 -1.19109 1.06456 0.98466C-0.595945 3.15666 1.93981 6.03141 4.30306 4.65666L4.29106 4.66416L11.9861 10.6522C11.3059 11.6737 10.9444 12.8742 10.9473 14.1014C10.9473 15.4409 11.3733 16.6874 12.0956 17.7067L12.0836 17.6872L9.73981 20.0272C9.55231 19.9687 9.35731 19.9372 9.16156 19.9334H9.15781C7.34956 19.9334 6.43906 22.1249 7.72006 23.4059C9.00106 24.6832 11.1926 23.7772 11.1926 21.9682C11.1884 21.765 11.1543 21.5636 11.0913 21.3704L11.0951 21.3862L13.4118 19.0694C14.1676 19.6459 15.0448 20.0427 15.9768 20.2296C16.9088 20.4165 17.8712 20.3885 18.7908 20.1479C19.7093 19.9059 20.5606 19.4578 21.2801 18.8376C21.9995 18.2174 22.5681 17.4414 22.9427 16.5686C23.3174 15.6957 23.4882 14.749 23.4422 13.8002C23.3962 12.8515 23.1346 11.9257 22.6773 11.0932C22.22 10.2607 21.5786 9.54375 20.8022 8.99681C20.0257 8.44988 19.1346 8.08745 18.1968 7.93716L18.1578 7.92966L18.1653 7.93041ZM17.2001 17.3092C14.3448 17.3017 12.9228 13.8479 14.9463 11.8327C16.9661 9.81666 20.4153 11.2469 20.4153 14.1022V14.1059C20.4153 14.5269 20.3323 14.9437 20.1711 15.3326C20.0099 15.7215 19.7736 16.0748 19.4758 16.3723C19.1779 16.6698 18.8244 16.9056 18.4353 17.0664C18.0462 17.2272 17.6293 17.3097 17.2083 17.3092H17.2001Z"
        fill="#F8761F"
      />
    </svg>
  );
});
