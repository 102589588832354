import {forwardRef, Ref} from 'react';
import styledMap from 'styled-map';
import styled, {DefaultTheme, StyledComponentProps} from 'styled-components';
import {getColorFromTheme} from '../../ui-utils';
import {checkAppearance, checkIconAppearance} from './style';
import {CheckKind, InputState} from '../../interface';
import {ifProp} from 'styled-tools';

interface TProps {
  state?: InputState;
  kind?: CheckKind;
  customBg?: string;
}

const CheckInput = styled.input<TProps>`
  top: 0;
  left: 0;
  width: 100%;
  cursor: inherit;
  height: 100%;
  margin: 0;
  opacity: 0;
  padding: 0;
  z-index: 1;
  position: absolute;
  ${checkAppearance};
`;

const Wrapper = styled.span<{readOnly?: boolean}>`
  padding: 5px;
  overflow: visible;
  text-align: center;
  width: 20px;
  height: 20px;
  border: 0;
  cursor: ${ifProp('readOnly', 'not-allowed', 'pointer')};
  margin: 0;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  justify-content: center;
  user-select: none;
  vertical-align: middle;
  visibility: visible;
  text-decoration: none;
  background: transparent;
`;

const InnerWrapper = styled.span<{kind?: CheckKind}>`
  padding: ${styledMap('kind', {
    import: '6px',
    indeterminate: '6px',
    rounded: '6px',
    default: '8px',
  })};
  border-radius: ${styledMap('kind', {
    default: '5px',
    rounded: '20px',
  })};
  border: 2px solid;
  background-color: transparent;
  border-color: ${getColorFromTheme('borderDark')};
  transition: all 300ms ease-in-out;
  vertical-align: text-top;

  &::after {
    transition: all 0.2s ease-in-out;
    content: '';
    display: block;
    ${checkIconAppearance};
    position: absolute;
  }
`;

export type CheckboxProps = StyledComponentProps<
  'input',
  DefaultTheme,
  {
    state?: InputState;
    customBg?: string;
    kind?: CheckKind;
  },
  never
>;

export const Checkbox = forwardRef(
  (
    {kind, customBg, readOnly, ...props}: Omit<CheckboxProps, 'type'>,
    ref: Ref<HTMLInputElement>,
  ) => (
    <Wrapper readOnly={readOnly} aria-label="checkbox-input">
      <CheckInput
        {...props}
        kind={kind}
        customBg={customBg}
        type="checkbox"
        ref={ref}
      />
      <InnerWrapper kind={kind} />
    </Wrapper>
  ),
);
