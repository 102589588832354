import {activateNotification} from '../../ui/molecules/notification/activate-notification';
import {parseErrorContent} from '../../utils/error-handling';

import {
  CcRequestImpl,
  CreateReviewCycleProps,
  DirectReportResponse,
  ManagerReminderProps,
  NominationPost,
  OverviewResponse,
  ResultsResponse,
  ReviewCycleProps,
  WriteReviewProps,
  WrittenReviewProps,
} from './cc-request';

export type ReviewFrameworkProps =
  | {
      weighing: {
        value: number;
        competence: number;
      };
      name: string;
      allGroups: boolean;
      groups: string[];
      competenceValue: number;
      competenceMetrics: {
        name: string;
        totalValue: number;
        components: {
          name: string;
          parameter?: 'goals' | 'default';
          score: number;
        }[];
      }[];
      workspace: string;
      createdAt: string;
      managerQuestion?: string;
      managerQuestionRequired?: boolean;
      updatedAt: string;
      updatedBy: string;
      id: string;
    }[]
  | undefined;
export class CcController {
  constructor(private readonly request: CcRequestImpl) {}

  async fetchCcReviewSetup() {
    try {
      const response = await this.request.fetchCcReviewSetup();
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }

  async updateCcReviewSetup(data: any) {
    try {
      const response = await this.request.updateCcReviewSetup(data);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }
  async fetchCcFramework(pageNumber?: number): Promise<ReviewFrameworkProps> {
    try {
      const response = await this.request.fetchCcFramework(pageNumber);

      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
    }
  }
  async fetchCcResults(
    id: string,
    filters: {
      group?: string;
      member?: string;
      keyArea?: string;
      user?: string;
      manager?: string;
      value?: string;
    },
  ): Promise<ResultsResponse | undefined> {
    try {
      const response = await this.request.fetchCcResults(id, filters);

      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
    }
  }
  async updateCcFramework(data: any) {
    try {
      const response = await this.request.updateCcFramework(data);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }
  async createReviewCycle(data: CreateReviewCycleProps) {
    try {
      const response = await this.request.createReviewCycle(data);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }
  async writeReviewCycles(data: WriteReviewProps) {
    try {
      const response = await this.request.writeReviewCycles(data);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }
  async updateWrittenReviewCycles(data: any, id: string) {
    try {
      const response = await this.request.updateWrittenReviewCycles(data, id);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }
  async fetchWrittenReviewById(data: WrittenReviewProps) {
    try {
      const response = await this.request.fetchWrittenReviewById(data);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }
  async fetchWrittenReview(data?: {
    reviewerType?: string;
    reviewCycle?: string;
    reviewer?: string;
    user?: string;
  }) {
    try {
      const response = await this.request.fetchWrittenReview(data);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }
  async deleteReviewCycle(id: string) {
    try {
      await this.request.deleteCcReview(id);
      return true;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }
  async getDirectReport(
    reviewCycleId: string,
  ): Promise<DirectReportResponse | false> {
    try {
      const response = await this.request.getDirectReport(reviewCycleId);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }
  async fetchReviewOverview(
    id: string,
    userId?: string,
    managerId?: string,
  ): Promise<OverviewResponse | false> {
    try {
      const response = await this.request.fetchReviewOverview(
        id,
        userId,
        managerId,
      );
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }

  async updateReviewCycle(data: CreateReviewCycleProps, id: string) {
    try {
      const response = await this.request.updateCcReview(data, id);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }
  async fetchReviewCycle(data?: {status?: string}) {
    try {
      const response = await this.request.fetchReviewCycles(data);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
    }
  }

  async sendNominationReminder(id: string) {
    try {
      await this.request.sendNominationReminder(id);
      activateNotification({
        title: 'Success',
        content: 'Reminder sent successfully',
        kind: 'success',
      });

      return true;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
    }
  }
  async sendManagerReminder(data: ManagerReminderProps) {
    try {
      await this.request.sendManagerReminder(data);
      return true;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
    }
  }

  async fetchReviewNomination(
    user?: string,
    reviewId?: string,
    reviewer?: string,
  ) {
    try {
      const response = await this.request.fetchReviewNomination(
        user,
        reviewId,
        reviewer,
      );
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
    }
  }
  async updateReviewNomination(data: NominationPost) {
    try {
      const response = await this.request.updateReviewNomination(data);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
    }
  }
  async fetchUserReviewCycle({status}: {status?: string}) {
    try {
      const response = await this.request.fetchUserReviewCycles({status});
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
    }
  }

  async fetchCcFrameworkById(id: string) {
    try {
      const response = await this.request.fetchCcFrameworkById(id);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }
  async fetchReviewCycleById(
    id: string,
  ): Promise<ReviewCycleProps | undefined> {
    try {
      const response = await this.request.fetchReviewCycleById(id);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return;
    }
  }
  async updateCcFrameworkById(id: string, data: any) {
    try {
      const response = await this.request.updateCcFrameworkById(id, data);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }
  async deleteCcFramework(id: string) {
    try {
      await this.request.deleteCcFramework(id);
      return true;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }
  async deleteNomination(id: string) {
    try {
      await this.request.deleteNomination(id);
      return true;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }
}
