import React from 'react';
import {ReportingBaseFilter} from './base-filter';
import {BaseFilterProps} from './interface';
import {GoalStatusToggles} from '../filter-components/objectives';

interface ObjectiveFilterProps extends BaseFilterProps {}

export const ReportingObjectiveFilter = ({
  showObjectiveStatus,
  toggleObjectiveStatus,
  ...props
}: ObjectiveFilterProps) => {
  return (
    <ReportingBaseFilter {...props}>
      {showObjectiveStatus && (
        <div className="p-4 border-b border-b-[#ededf2]">
          {' '}
          <GoalStatusToggles
            showObjectiveStatus={showObjectiveStatus}
            onHide={() => toggleObjectiveStatus?.(false)}
            onChangeStatus={props.onChangeStatus}
            status={props.goalStatus}
          />
        </div>
      )}
    </ReportingBaseFilter>
  );
};
