import {useState, useCallback} from 'react';

import {useStoreContext} from '@store/store-context';

export const useFreeTierHook = () => {
  const {
    authStore: {auth, featureLimitStatus},
  } = useStoreContext();

  const isAccountFreeTier = auth?.user.workspace.billing.plan === 'free_tier';

  const reachedSeatsLimit =
    featureLimitStatus?.limited &&
    featureLimitStatus?.currentStatus?.seats >=
      featureLimitStatus?.limits?.seats;

  const [showFreeTierModal, setFreeTierModal] = useState(false);

  const handleShowFreeTierModal = useCallback((value: boolean) => {
    setFreeTierModal(value);
  }, []);

  return {
    isAccountFreeTier,
    showFreeTierModal,
    reachedSeatsLimit,
    handleShowFreeTierModal,
  };
};
