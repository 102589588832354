import {Button} from '@ui/atoms/button';
import {Body2} from '@ui/atoms/typography';
import {DropdownCustom} from '@ui/molecules/dropdown-custom';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {FlexRowSpaceBetween} from '@ui/style/styles';
import {FiChevronDown} from 'react-icons/fi';

interface ActionItemsSortByFilterProps {
  updateStatus: (value: string) => void;

  status: string;
}
export const QuestionStatusFilter = ({
  updateStatus,
  status,
}: ActionItemsSortByFilterProps) => {
  return (
    <DropdownCustom
      collapseOnClick={true}
      menu={(handleClose: () => void) => (
        <div>
          <DropdownItem
            onClick={() => {
              updateStatus('');
              handleClose();
            }}>
            All
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              updateStatus('active');
              handleClose();
            }}>
            Active
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              updateStatus('inactive');
              handleClose();
            }}>
            Inactive
          </DropdownItem>{' '}
          <DropdownItem
            onClick={() => {
              updateStatus('upcoming');
              handleClose();
            }}>
            Upcoming
          </DropdownItem>{' '}
        </div>
      )}
      customComponent={(handleOpen: (event: any) => void, open: boolean) => (
        <Button
          kind="secondary"
          type="button"
          onClick={handleOpen}
          className="flex items-center justify-between"
          style={{
            minWidth: '78px',

            padding: '8px 16px',
          }}>
          <FlexRowSpaceBetween>
            <Body2 weight="bold" className="capitalize">
              {status ? status : 'All'}
            </Body2>
            <FiChevronDown
              style={{margin: '0px 5px 0px 0px'}}
              aria-hidden="true"
            />
          </FlexRowSpaceBetween>
        </Button>
      )}
      customDropdownWrapperStyles={{
        top: '130%',
        width: '160px',
        padding: '8px',
        right: 0,
      }}
    />
  );
};
