import styled from 'styled-components';
import {ifProp} from 'styled-tools';
import {memo, PropsWithChildren, SyntheticEvent} from 'react';
import {Typography} from '../typography/typography';
import {getColorFromTheme} from '../../ui-utils';
import {Link, LinkProps} from 'react-router-dom';
import styledMap from 'styled-map';

interface INavItem extends LinkProps {
  active?: boolean;
  variant?: any;
  type?: 'overlap';
  $theme?: 'darkText' | 'purpleText';
  removeLastIndex?: boolean;
}

const NavItem = styled(Link)<INavItem>`
  ${Typography};

  font-weight: ${styledMap('$theme', {
    purpleText: ifProp('active', 500, 400),
    default: 500,
  })};
  font-size: 14px;
  line-height: 1.7;
  letter-spacing: normal;

  color: ${styledMap('$theme', {
    purpleText: ifProp(
      'active',
      getColorFromTheme('purple300'),
      getColorFromTheme('textBody'),
    ),
    default: ifProp(
      'active',
      getColorFromTheme('textDark'),
      getColorFromTheme('textMuted'),
    ),
  })};

  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  border-top: ${styledMap('variant', {
    addBorderTop: '1px solid #ededf2',
    default: undefined,
  })};

  position: relative;
  border-right: ${ifProp('removeLastIndex', '', '1px solid #ededf2')};
  border-radius: ${styledMap('variant', {
    addBorderTop: '10px 10px 0 0',
    default: '0 10px 0 0',
  })};
  cursor: pointer;
  width: 100%;
  height: ${styledMap('variant', {
    peer: '46px',
    default: '56px',
  })};
  flex-grow: 0;
  font-stretch: normal;
  font-style: normal;
  text-align: center;

  &:last-of-type {
    margin-right: 0;
  }

  @media (max-width: 500px) {
    ${({type, active}) => {
      if (type === 'overlap') {
        return `
       justify-content: flex-start;

        overflow: hidden;
        width: ${active ? '103%' : '70%'}
        `;
      }
    }}
  }

  &:hover {
    color: ${getColorFromTheme('textDark')};
  }
`;

const NavItemContent = styled.span<{
  active?: boolean;
  type?: 'overlap';
  activeIdx?: number;
  idx?: number;
}>`
  z-index: 1;
  position: relative;
  display: flex;
  white-space: nowrap;
  align-items: center;

  @media (max-width: 500px) {
    ${({type, active, idx, activeIdx}) => {
      if (type === 'overlap') {
        return `
          left: ${
            active ? '6%' : (idx || 0) < (activeIdx || 0) ? '15%' : '-12%'
          };
        `;
      }
    }}
  }
`;

export interface ButtonNavProps
  extends PropsWithChildren<{
    active: boolean;
    href: string;
    type?: 'overlap';
    idx?: number;
    activeIdx?: number;
    removeLastIndex?: boolean;
    theme?: 'purpleText' | 'darkText';
    variant?: any;
    onClick?: (e: SyntheticEvent<HTMLAnchorElement>) => void;
  }> {}

export const ButtonNav = memo(function ({
  href,
  onClick,
  removeLastIndex,
  active,
  type,
  theme,
  variant,
  activeIdx,
  idx,
  children,
}: ButtonNavProps) {
  return (
    <NavItem
      to={href}
      onClick={onClick}
      active={active}
      type={type}
      $theme={theme}
      variant={variant}
      removeLastIndex={removeLastIndex}>
      <NavItemContent
        tabIndex={-1}
        active={active}
        type={type}
        activeIdx={activeIdx}
        idx={idx}>
        {children}
      </NavItemContent>
    </NavItem>
  );
});
ButtonNav.displayName = 'ButtonNav';
