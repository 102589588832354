import {memo, SyntheticEvent} from 'react';
import styled from 'styled-components';
import {motion} from 'framer-motion';
import {Logo} from '../../../atoms/logo';
import {up, down} from 'styled-breakpoints';
import {getColorFromTheme} from '../../../ui-utils';
import {WorkspaceLogo} from '../../../atoms/workspace-logo';
import {ifProp} from 'styled-tools';
import {ArrowHeadDownIcon} from '@ui/atoms/icons';
import {FlexRow} from '@ui/style/styles';

export const MobileWrapper = styled.div`
  display: none;
  ${down('sm')} {
    display: block;
  }
`;

export const DesktopWrapper = styled.div`
  display: none;
  ${up('md')} {
    display: block;
  }
`;

const Toggle = styled.div`
  margin-right: 5px;
  border-radius: 10px;
  padding: 6px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  transition: 200ms all ease-in-out;

  @media (max-width: 1200px) {
    margin-right: 0px;
  }
`;

const Wrapper = styled.button<{disabled?: boolean}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  outline: none;
  border: none;
  background: none;
  width: 100%;
  height: 100%;

  ${up('md')} {
    svg {
      stroke: ${ifProp(
        'disabled',
        getColorFromTheme('textMuted'),
        getColorFromTheme('textBody'),
      )};

      margin-bottom: 2.5px;
    }
  }

  &:hover,
  &:active,
  &:focus {
    ${Toggle} {
      background-color: ${getColorFromTheme('paleLilacTwo')};
    }
  }
`;

const WorkspaceText = styled.div`
  @media (max-width: 1200px) {
    display: none;
  }
`;
export interface WorkspaceLogoToggleProps {
  workspace: string;
  logo?: string;
  disabled?: boolean;
  onClick: (e: SyntheticEvent<HTMLButtonElement>) => void;
  open: boolean;
}

export const WorkspaceLogoToggle = memo(function ({
  workspace,
  disabled,
  logo,
  onClick,
  open,
}: WorkspaceLogoToggleProps) {
  return (
    <Wrapper
      type="button"
      disabled={disabled}
      aria-label="switch company"
      onClick={onClick}
      onKeyDown={onClick}>
      <FlexRow>
        <Toggle>
          <WorkspaceLogo name={workspace} size="smd" src={logo} />
        </Toggle>
        <WorkspaceText>
          <Logo title={workspace} />
        </WorkspaceText>
      </FlexRow>

      <motion.span
        initial={{rotate: 0}}
        animate={{rotate: open ? -180 : 0}}
        transition={{
          duration: 0.1,
        }}
        key="closed"
        style={{marginRight: 3}}>
        <ArrowHeadDownIcon
          style={{
            stroke: disabled ? '#BFBFD4' : '#5F5F8C',
          }}
        />
      </motion.span>
    </Wrapper>
  );
});
WorkspaceLogoToggle.displayName = 'WorkspaceLogoToggle';
