import {activateNotification} from '../../ui/molecules/notification/activate-notification';
import {parseErrorContent} from '../../utils/error-handling';

import {FeedbackRequestImpl} from './feedback-requests';
export class FeedbackController {
  constructor(private readonly request: FeedbackRequestImpl) {}

  async postFeedback(data: any, userId: string) {
    try {
      const response = await this.request.postFeedback(data, userId);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }
  async postPraise(data: any) {
    try {
      const response = await this.request.postPraise(data);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }

  async getImpressionAnalysis(text: string) {
    try {
      const response = await this.request.getSentimentAnalytics(text);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }

  async patchFeedback(data: any, userId: string) {
    try {
      const response = await this.request.patchFeedback(data, userId);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }

  async deleteFeedbackRequest(requestId: string) {
    try {
      await this.request.deleteFeedbackRequest(requestId);
      return true;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }

  async getFeedback(data: any, userId: string) {
    try {
      const response = await this.request.getFeedback(data, userId);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return [];
    }
  }
  async getFeedbackReporting(data: any) {
    try {
      const response = await this.request.fetchFeedbackReporting(data);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return [];
    }
  }

  async getRequestFeedback(userId: string) {
    try {
      const response = await this.request.fetchRequestFeedback(userId);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }

  async postRequestFeedback(data: any, userId: string) {
    try {
      const response = await this.request.postRequestFeedback(data, userId);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }

  async fetchPublicFeedback(data: any) {
    try {
      const response = await this.request.fetchPublicFeedback(data);

      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return [];
    }
  }
  async fetchDirectReportsFeedback(data: any) {
    try {
      const response = await this.request.fetchDirectReportsFeedback(data);

      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return [];
    }
  }
}
