import {makeAutoObservable, observable, action} from 'mobx';

export class FeedBackStore {
  @observable dateRange: any = null;
  @observable managerFeedbackRequests: any[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  @action updateFeedbackRequest = (data: any[]) => {
    this.managerFeedbackRequests = data;
  };

  @action
  setDateRange = (state: any) => {
    this.dateRange = state;
  };
}

export const feedbackStore = new FeedBackStore();
