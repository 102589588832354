import {CompanyCheckinsController} from '@pages/dashboard/company-legacy/company-components/company-checkins/company-checkins.controller';
import {CompanyCheckinsRequestImpl} from '@pages/dashboard/company-legacy/company-components/company-checkins/company-checkins.request';
import {useStoreContext} from '@store/store-context';
import {emojiList} from '@ui/assets/emojis';
import {Image} from '@ui/atoms/image';
import {Body2, Headline3} from '@ui/atoms/typography';
import {ItemLoader} from '@ui/organisms/item-loader';
import {FlexRowCenter} from '@ui/style/styles';
import {capitalize} from '@utils';
import dayjs from 'dayjs';
import React, {useCallback, useMemo} from 'react';
import {useQuery} from 'react-query';

interface IAveragePulse {
  userId: string;
}
export const AveragePulse = ({userId}: IAveragePulse) => {
  const {
    checkinsStore: {calendarWeeks},
  } = useStoreContext();

  const initFilter = useMemo(() => {
    return {
      weekOfYear: calendarWeeks?.[calendarWeeks?.length - 1]?.weekOfYear,
      user: userId,
      year: calendarWeeks?.[calendarWeeks?.length - 1]?.year,
      paginate: false,
    };
  }, [calendarWeeks, userId]);

  const request = useMemo(() => new CompanyCheckinsRequestImpl(), []);
  const controller = useMemo(() => new CompanyCheckinsController(request), [
    request,
  ]);

  const fetchListOfCheckins = useCallback(
    async (filter?: {
      weekOfYear?: number;
      year?: number;
      isActive?: boolean;
      isSubmitted?: boolean;
      isReviewed?: boolean;
      reviewer?: string;
      manager?: string;
      user?: string;
      page?: number;
    }) => {
      const pulses = await controller.getPulses(filter);

      return pulses as any;
    },

    [controller],
  );

  const {data, isLoading} = useQuery(['meetings-pulse'], () =>
    fetchListOfCheckins(initFilter),
  );

  const moods = [
    {
      emoji: emojiList.drained,
      text: 'Drained',
    },
    {
      emoji: emojiList.sad,
      text: 'Sad',
    },
    {
      emoji: emojiList.meh,
      text: 'Meh!',
    },
    {
      emoji: emojiList.happy,
      text: 'Happy',
    },
    {
      emoji: emojiList.elated,
      text: 'Elated',
    },
  ];

  const pulses = data?.data;
  const latestUpdatedAt = pulses?.reduce(
    (
      latest: string | number | Date,
      pulse: {updatedAt: string | number | Date},
    ) => {
      // Parse the date strings to compare
      const currentUpdatedAt = new Date(pulse.updatedAt);
      const latestUpdatedAt = new Date(latest);

      // If the current goal's updatedAt is later than the latest one found, update the latestUpdatedAt
      if (currentUpdatedAt > latestUpdatedAt) {
        return pulse.updatedAt;
      } else {
        return latest;
      }
    },
    pulses?.[0]?.updatedAt,
  );

  const pulse = data ? data?.summary?.averagePulse : '';

  return (
    <div>
      <div className="border border-borderLight rounded-[10px]">
        <div className="py-4 px-6">
          <Headline3 kind="textDark">Average pulse</Headline3>
        </div>
        <div className="border-t border-t-[#ededf2] rounded-t-[10px] py-6 px-10 flex items-center ">
          {isLoading && (
            <FlexRowCenter className="w-full">
              <ItemLoader />
            </FlexRowCenter>
          )}

          {!isLoading && !pulse && (
            <>
              <FlexRowCenter>
                <Body2 kind="textBody">No moods submission yet.</Body2>
              </FlexRowCenter>
            </>
          )}

          {pulse && !isLoading && (
            <Image
              {...moods.filter((mood) => mood.text === capitalize(pulse))[0]
                ?.emoji}
              width="60px"
              height="60px"
            />
          )}

          {pulse && !isLoading ? (
            <Headline3 className="ml-3">{capitalize(pulse)}</Headline3>
          ) : null}
        </div>

        <div className="border-t border-t-[#ededf2] py-3 px-6">
          <Body2 weight="semibold" kind="textBody">
            Last submitted:{' '}
            {latestUpdatedAt && !!pulse
              ? dayjs(latestUpdatedAt).format('MMMM DD, YYYY')
              : '-'}
          </Body2>
        </div>
      </div>
    </div>
  );
};
