import {action} from 'mobx';
import {usersStore} from '../../../store/stores/users-store';
import {activateNotification} from '../../../ui/molecules/notification/activate-notification';
import {
  MemberProfileFormData,
  MemberEmailData,
  MemberPasswordData,
  MemberReviewerData,
} from './member-profile-interface';
import {MemberProfileRequest} from './member-profile-request';

export class MemberProfileController {
  constructor(private readonly request: MemberProfileRequest) {}

  @action
  async newMemberProfileForm(
    data: MemberProfileFormData,
    accessToken: string,
    hideNotif?: boolean,
  ) {
    try {
      const responseData = await this.request.memberForm(data, accessToken);
      !hideNotif &&
        activateNotification({
          title: 'Success',
          content: 'Profile has been updated',
          kind: 'success',
        });
      return responseData;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e?.response?.data?.message,
        kind: 'error',
      });
    }
  }

  @action
  async newMemberEmail(data: MemberEmailData, accessToken: string) {
    try {
      await this.request.memberEmail(data, accessToken);
      activateNotification({
        title: 'Success',
        content: 'Email changed succesfully',
        kind: 'success',
      });
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e?.response?.data?.message,
        kind: 'error',
      });
    }
  }

  @action
  async newMemberPassword(data: MemberPasswordData, accessToken: string) {
    try {
      await this.request.memberPassword(data, accessToken);
      activateNotification({
        title: 'Success',
        content: 'Password Changed',
        kind: 'success',
      });
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e?.response?.data?.message,
        kind: 'error',
      });
    }
  }

  @action
  async newfetchCurrentUser() {
    try {
      const response = await this.request.fetchUser();
      usersStore.setCurrentUser(response);

      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e?.response?.data?.message,
        kind: 'error',
      });
    }
  }

  @action
  async newMemberReviewer(data: MemberReviewerData, accessToken: string) {
    try {
      await this.request.memberReviewer(data, accessToken);
      activateNotification({
        title: 'Success',
        content: 'Changes Saved',
        kind: 'success',
      });
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e?.response?.data?.message,
        kind: 'error',
      });
    }
  }
}
