import {notificationStore} from '../../../store/stores/notification-store';
import {TNotificationProps} from '../../interface';

export const activateNotification = (props: TNotificationProps) => {
  if (notificationStore.appIsOffline && props.kind === 'error') {
  } else {
    notificationStore.handleShowNotification(true);
    notificationStore.setNotificationData(props);
    if (!props.timeout) {
      setTimeout(() => {
        notificationStore.handleShowNotification(false);
      }, 2000);
    }
  }
};
