import {Body1, Body2, Headline2} from '@ui/atoms/typography';
import {SecondaryPlusIcon} from '@ui/atoms/icons/plus';
import {
  SecondRowWrapper,
  HeadlineWrapper,
  HeadlineWrapperContainer,
  EmptyWrapper,
  ItemWrapper,
  TableContainer,
} from '../performance-settings-style';
import {FlexRow, FlexRowSpaceBetween, Flex} from '@ui/style/styles';
import {MoreVerticalIcon} from '@ui/atoms/icons';
import {capitalize} from '@utils';
import {CcRequestImpl} from '@api/cc-api/cc-request';
import {CcController} from '@api/cc-api/cc-controller';
import {useStoreContext} from '@store/store-context';
import {activateNotification} from '@ui/molecules/notification/activate-notification';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {BackAction} from '@ui/molecules/back-action';
import {Button} from '@ui/atoms/button';
import {CancelIcon} from '@ui/atoms/icons';
import {ModalCard} from '@ui/layouts/modal-card';
import {CustomColorIcon} from '@ui/atoms/color-icons';
import {DropdownVerticalSecondary} from '@ui/molecules/dropdown-vertical';
import {useNavigate} from 'react-router';
import {Modal} from '@ui/molecules/modal';
import {NoBorderButton} from '@pages/dashboard/reporting/reporting-pages/reporting-overview/styles';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {FC, useState, useMemo} from 'react';
interface ICompetence {
  categories: Array<string>;
  frameworks: Array<any>;
}

export const CompetenceCategories: FC<ICompetence> = ({
  categories: allCategories,
  frameworks,
}) => {
  const [categories, setCategories] = useState(allCategories);

  useMemo(() => {
    setCategories(allCategories);
  }, [allCategories]);

  const [showDeleteModal, setShowDeleteModal] = useState('');

  const categoriesExists = categories.length > 0;

  const {
    ccStore: {workspaceImpressionDetails},
  } = useStoreContext();

  const keyAreaValue = useMemo(() => {
    return (
      frameworks?.flatMap((category: any) =>
        category.competenceMetrics.map((metric: any) => metric.name),
      ) || []
    );
  }, [frameworks]);

  const navigate = useNavigate();

  const handleDelete = async (category: string) => {
    const request = new CcRequestImpl();
    const controller = new CcController(request);

    const filterCategories = categories.filter((value) => value !== category);

    const response = await controller.updateCcReviewSetup({
      rankings: workspaceImpressionDetails,
      competenceCategories: filterCategories.map((category) => {
        return {
          name: category,
        };
      }),
    });

    setShowDeleteModal('');
    setCategories(filterCategories);

    response &&
      activateNotification({
        title: `Success`,
        content: `Categories deleted`,
        kind: 'success',
      });
  };

  const addCategory = (
    <NoBorderButton onClick={() => navigate('/create-review-categories')}>
      <FlexRow>
        <SecondaryPlusIcon />
        <Body2 weight="bold" kind="purple300" style={{marginLeft: '4px'}}>
          Add competence
        </Body2>
      </FlexRow>
    </NoBorderButton>
  );

  const EmptyCategories = () => {
    return (
      <>
        <Body1 weight="bold">Add competencies </Body1>
        <VerticalSpacer size="10px" />
        <Body2 kind="textBody" align="center">
          Add umbrella classifications for attributes, skills, and knowledge{' '}
          <br />
          that are required for putting in suitable performance.
        </Body2>
      </>
    );
  };

  return (
    <div>
      <FlexRowSpaceBetween>
        <Body1 weight="bold">
          Competence categories ({categories.length}){' '}
        </Body1>
        {addCategory}
      </FlexRowSpaceBetween>
      <VerticalSpacer size="12px" />
      <TableContainer>
        <div>
          <SecondRowWrapper>
            <HeadlineWrapperContainer className="header">
              <HeadlineWrapper>
                <Body2 kind="textBody">Type</Body2>
                <Body2 kind="textBody">Actions</Body2>
              </HeadlineWrapper>
            </HeadlineWrapperContainer>
            {!categoriesExists && (
              <EmptyWrapper>
                <EmptyCategories />
              </EmptyWrapper>
            )}
            {categoriesExists &&
              categories.map((category, index) => (
                <ItemWrapper key={index} addBorderRadius={!index}>
                  <Body2 weight="semibold">{capitalize(category)}</Body2>
                  <DropdownVerticalSecondary
                    customIcon={<MoreVerticalIcon />}
                    menu={
                      <div>
                        <DropdownItem
                          disabled={keyAreaValue.includes(category)}
                          onClick={() => setShowDeleteModal(category)}>
                          Delete
                        </DropdownItem>
                      </div>
                    }
                  />
                </ItemWrapper>
              ))}
          </SecondRowWrapper>
        </div>
      </TableContainer>
      <Modal open={!!showDeleteModal} onClose={() => {}}>
        <div style={{maxWidth: '500px', margin: 'auto'}}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}>
            <BackAction
              width="md"
              icon={<CancelIcon />}
              onClick={() => {
                setShowDeleteModal('');
              }}
              title="Close"
            />
          </div>
          <VerticalSpacer size="16px" />
          <ModalCard align="center" title={``}>
            <Flex alignItems="center" justifyContent="center" flexDir="column">
              <VerticalSpacer size="16px" />
              <Flex alignItems="center" justifyContent="center">
                <CustomColorIcon
                  color="#F7E4E4"
                  width="80px"
                  margin={0}
                  height="80px"
                />
              </Flex>
              <VerticalSpacer size="20px" />
              <span>
                <Headline2 align="center">Delete category?</Headline2>
              </span>
              <VerticalSpacer size="16px" />

              <Body1 align="center" kind="textBody">
                Are you sure you want to delete{' '}
                <span style={{color: '#585ADF', fontWeight: 500}}>
                  {showDeleteModal}
                </span>
                ? This cannot be undone.
              </Body1>
              <VerticalSpacer size="32px" />
              <Button
                onClick={() => handleDelete(showDeleteModal)}
                width="full"
                kind="secondary">
                Delete
              </Button>
            </Flex>
          </ModalCard>
        </div>
      </Modal>
    </div>
  );
};
