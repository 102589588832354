import {observer} from 'mobx-react';
import {lazy} from 'react';
import {SetupWorkspaceSlugs} from './setup-workspace-page';

const SetupWorkspacePage = lazy(() =>
  import('./setup-workspace-page').then((module) => ({
    default: module.SetupWorkspacePage,
  })),
);

const SetupWorkspaceForm = lazy(() =>
  import('./setup-workspace-form').then((module) => ({
    default: module.SetupWorkspaceForm,
  })),
);

export const installSetupWorkspacePage = () => {
  const FormComponent = observer(() => {
    return <SetupWorkspaceForm />;
  });

  const Page = observer(({slug}: {slug: SetupWorkspaceSlugs}) => (
    <SetupWorkspacePage FormComponent={FormComponent} slug={slug} />
  ));

  return {
    Page,
  };
};
