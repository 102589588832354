import {TooltipWrapper, IconWrapper} from './styles';
import {useStoreContext} from '@store/store-context';
import {Button} from '../button';
import {Flex} from '@ui/style/styles';
import {cfv} from '@utils/framework';
import {capitalize} from '@utils';
import dayjs from 'dayjs';
import {Body1, Body2} from '../typography';
import {HubspotIcon} from '../icons/apps/hubspot';

interface IProps {
  showIconOnly?: boolean;
  keyResult: any;
}
export const HubSpotKeyResults = ({showIconOnly, keyResult}: IProps) => {
  const {
    goalsState: {apps},
  } = useStoreContext();
  const hubspot = apps?.find((app: any) => app.app === 'Hubspot');

  switch (keyResult?.source?.app) {
    case 'Hubspot':
      return (
        <TooltipWrapper>
          <div className="tooltip">
            {showIconOnly ? (
              <div
                style={{
                  width: '36px',
                  height: '28px',
                  display: 'flex',
                  borderRadius: '10px',
                  padding: '4px 8px',
                  border:
                    keyResult?.source?.error !== null || hubspot?.error
                      ? '1px solid #D52A2A'
                      : undefined,
                  color:
                    keyResult?.source?.error !== null || hubspot?.error
                      ? '#D52A2A'
                      : '#5F5F8C',

                  background:
                    keyResult?.source?.error !== null || hubspot?.error
                      ? 'white'
                      : '#F6F6FE',
                  alignItems: 'center',

                  justifyContent: 'center',
                }}>
                <IconWrapper showIconOnly={showIconOnly}>
                  <HubspotIcon />
                </IconWrapper>
              </div>
            ) : (
              <Button
                disabled={true}
                style={{
                  width: '82px',
                  height: '38px',
                  display: 'flex',

                  border:
                    keyResult?.source?.error !== null || hubspot?.error
                      ? '1px solid #D52A2A'
                      : undefined,
                  color:
                    keyResult?.source?.error !== null || hubspot?.error
                      ? '#D52A2A'
                      : '#5F5F8C',
                  alignItems: 'center',
                  overflow: 'hidden',
                  justifyContent: 'center',
                }}>
                View
                <IconWrapper>
                  <HubspotIcon />
                </IconWrapper>
              </Button>
            )}
            <div className="tooltiptext">
              <Flex>
                <HubspotIcon />{' '}
                <Body1 weight="semibold" style={{marginLeft: '10px'}}>
                  Hubspot
                </Body1>
              </Flex>
              <div style={{marginTop: '10px'}}>
                {keyResult?.source?.error !== null || hubspot?.error ? (
                  <Body2 style={{fontSize: '12px'}} kind="textBody">
                    There was an error updating this {cfv(undefined, true).k_r}.
                    Please reconnect and we’ll try again.
                  </Body2>
                ) : (
                  <Body2 style={{fontSize: '12px'}} kind="textBody">
                    {capitalize(cfv(undefined, true).k_r)} is updated
                    automatically.
                    <br />
                    {keyResult.updatedAt === null
                      ? 'No Sync records'
                      : ` Last sync on ${dayjs(keyResult.updatedAt).format(
                          `DD MMM. YYYY`,
                        )} at ${dayjs(keyResult.updatedAt).format(`hh:mma`)}  `}
                  </Body2>
                )}
              </div>
            </div>
          </div>
        </TooltipWrapper>
      );

    default:
      return <></>;
  }
};
