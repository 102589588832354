import styled, {DefaultTheme, StyledComponentProps} from 'styled-components';
import {ITypography, Typography} from './typography';
import {getFontSizeFromTheme, getLineHeightFromTheme} from '../../ui-utils';

export type Body1Props = StyledComponentProps<
  'p',
  DefaultTheme,
  ITypography,
  'kind' | 'align'
>;

/**
 Used for multiline pieces of content.
 **/
export const Body1 = styled.p<ITypography>`
  ${Typography};

  font-size: ${getFontSizeFromTheme('body1')};
  line-height: ${getLineHeightFromTheme('body1')};
`;
