import styled from 'styled-components';
import {ifProp} from 'styled-tools';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .form {
    width: 35%;

    margin-bottom: 30px;
    @media (min-width: 1100px) and (max-width: 1320px) {
      width: 40%;
    }
    @media (min-width: 768px) and (max-width: 1100px) {
      width: 70%;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .body-wrapper {
    border: 1px solid #ededf2;
    border-radius: 10px;
    background-color: white;
    padding: 16px;
    @media (max-width: 768px) {
      padding: 16px 14px;
    }
  }
`;

export const AdditionalOptionsWrapper = styled.div<{
  display?: boolean;
  disabled?: boolean;
}>`
  display: ${(props: any) => (props.display === true ? 'block' : 'none')};
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='rgba(206, 206, 222, 1)' stroke-width='2' stroke-dasharray='14%2c 8' stroke-dashoffset='0' strokeLinecap='round'/%3e%3c/svg%3e");
  border-radius: 10px;
  margin: 8px 0;
  width: 100%;
  display: flex;
  cursor: ${ifProp('disabled', 'not-allowed', 'pointer')};
  align-items: center;
  justify-content: center;
  height: 50px;
  padding: 14px 16px;
  background-color: #fafafa;
`;

export const CcTips = styled.div`
  border-radius: 10px;
  background: var(--background-light, white);
  padding: 16px 24px;
`;
