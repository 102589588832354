import {memo} from 'react';

export const EditTwoIcon = memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16">
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8 13.334h6M8 13.334h6M11 2.333c.265-.265.625-.414 1-.414.186 0 .37.037.541.108.172.07.328.175.459.306s.236.287.307.459c.07.172.107.355.107.541 0 .186-.036.37-.107.541-.072.172-.176.328-.307.46l-8.333 8.332L2 13.333l.667-2.667L11 2.333z"
    />
  </svg>
));

EditTwoIcon.displayName = 'EditTwoIcon';
