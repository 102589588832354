import {GoalsController} from '@api/goals-api/goals-controller';
import {GoalsRequestImpl} from '@api/goals-api/goals-request';
import {usePostHogHook} from '@hooks/post-hog';
import {useStoreContext} from '@store/store-context';
import {Button} from '@ui/atoms/button';
import {CustomColorIcon} from '@ui/atoms/color-icons';
import {CancelIcon} from '@ui/atoms/icons';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {Headline2, Body1} from '@ui/atoms/typography';
import {ModalCard} from '@ui/layouts/modal-card';
import {BackAction} from '@ui/molecules/back-action';
import {Modal} from '@ui/molecules/modal';
import {Flex} from '@ui/style/styles';
import {useBreakpoints} from '@utils/use-breakpoints';

export const EndGoal = ({
  endGoal,
  endGoalInAllObjectiveStore,
  endCheckinGoal,
}: {
  endGoal?: (goalId: string, data: any) => void;
  endGoalInAllObjectiveStore?: (goalId: string, data: any) => void;
  endCheckinGoal?: (data: any, goalId: string) => void;
}) => {
  const {goalsState} = useStoreContext();

  const {postHogCapture} = usePostHogHook();
  const screenSize = useBreakpoints();
  return (
    <Modal open={true} onClose={() => {}}>
      <div
        style={{
          maxWidth: screenSize.isXs ? '90%' : '500px',
          margin: ' auto',
        }}>
        {screenSize.isXs ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
            }}>
            <Button
              kind="secondary"
              onClick={() => {
                goalsState.setEndModal('', '', false, '');
              }}
              style={{
                display: 'flex',
                alignItems: 'center',
                overflow: 'hidden',
                justifyContent: 'center',
                height: '38px',
              }}>
              <CancelIcon />
              <span style={{marginLeft: '5px'}}>Cancel</span>
            </Button>
          </div>
        ) : (
          <div style={{width: '100%'}}>
            <BackAction
              width="md"
              icon={<CancelIcon />}
              onClick={() => {
                goalsState.setEndModal('', '', false, '');
              }}
              title="Close"
            />
          </div>
        )}

        <VerticalSpacer size="16px" />
        <ModalCard align="center" title={''}>
          <Flex alignItems="center" justifyContent="center" flexDir="column">
            <VerticalSpacer size="16px" />
            <Flex alignItems="center" justifyContent="center">
              <CustomColorIcon
                color="#F7E4E4"
                width="80px"
                margin={0}
                height="80px"
              />
            </Flex>
            <VerticalSpacer size="20px" />
            <Headline2 align="center">
              End this {'  '}
              <span> {goalsState.goalFramework}?</span>
            </Headline2>

            <VerticalSpacer size="20px" />

            <Body1 align="center" kind="textBody">
              You are about to end{' '}
              <span style={{color: '#585adf', fontWeight: 500}}>
                {goalsState.endName}
              </span>
              .
            </Body1>
            <VerticalSpacer size="16px" />

            <Button
              width="full"
              onClick={() => {
                try {
                  const request = new GoalsRequestImpl();
                  const controller = new GoalsController(request);
                  controller.endGoal(
                    {completed: true},
                    goalsState.endId,
                    goalsState.goalFramework,
                  );

                  endGoal && endGoal(goalsState.endId, {completed: true});

                  endCheckinGoal &&
                    endCheckinGoal({completed: true}, goalsState.endId);

                  postHogCapture('frontend - Goal completion', undefined, {
                    goalName: goalsState.endName,
                  });

                  endGoalInAllObjectiveStore &&
                    endGoalInAllObjectiveStore(goalsState.endId, {
                      completed: true,
                    });

                  goalsState.setEndModal('', '', false, '');
                } catch {}
              }}>
              Confirm
            </Button>
          </Flex>
        </ModalCard>
      </div>
    </Modal>
  );
};
