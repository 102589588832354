import {action} from 'mobx';
import {activateNotification} from '../../ui/molecules/notification/activate-notification';
import {parseErrorContent} from '../../utils/error-handling';
import {IUserAPIRequest} from './user-api-request';

export class UserAPIController {
  constructor(private readonly request: IUserAPIRequest) {}

  @action
  async fetchAllUsers() {
    try {
      const response = await this.request.fetchAllUsers();
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }

  @action
  async fetchUserByID(userId: string, query?: string) {
    try {
      const response = await this.request.fetchUserByID(userId, query);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }

  @action
  async fetchCurrentUser() {
    try {
      const response = await this.request.fetchCurrentUser();
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }
  @action
  async activateUser(userId: string) {
    try {
      const response = await this.request.activateUser(userId);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }

  @action
  async requestUserNotification(data: {
    notification: string;
    subscribers: string[];
  }) {
    try {
      const response = await this.request.requestUserNotification(data);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }

  @action
  async fetchCurrentUserGroups() {
    try {
      const response = await this.request.fetchCurrentUserGroups();
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }
  @action
  async fetchSpecificUserGroups(id: string) {
    try {
      const response = await this.request.fetchSpecificUserGroups(id);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }

  @action
  async fetchUserGroups(id: string) {
    try {
      const response = await this.request.fetchUserGroups(id);
      return response;
    } catch (e: any) {
      // DO NOT DELETE...TODO: INVESTIGATE AVATARS BUG
      // activateNotification({
      //   title: 'Error',
      //   content: e,
      //   kind: 'error',
      // });
      return false;
    }
  }

  @action
  async fetchConfirmedUsers() {
    try {
      const response = await this.request.fetchConfirmedUsers();
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }
  @action
  async fetchPaginatedConfirmedUsers(page: number) {
    try {
      const response = await this.request.fetchPaginatedConfirmedUsers(page);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }
  @action
  async fetchPaginatedInviteUsers(page: number) {
    try {
      const response = await this.request.fetchPaginatedInvitedUsers(page);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }

  @action async fetchInvitedUsers() {
    try {
      const response = await this.request.fetchInvitedUsers();
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }

  @action
  async updateUser(userData: any, userId: string) {
    try {
      const response = await this.request.updateUser(userData, userId);
      // Todo update userStore with the response
      response &&
        activateNotification({
          title: 'Success',
          content: 'update successful',
          kind: 'success',
        });
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }

  @action
  async fetchDeactivatedUsers() {
    try {
      const response = await this.request.fetchDeactivatedUsers();
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }

  @action
  async deleteUser(userId: string) {
    try {
      await this.request.deleteUser(userId);
      return true;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }

  @action
  async fetchPaginatedDeactivatedUsers(page: number) {
    try {
      const response = await this.request.fetchPaginatedDeactivatedUsers(page);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }
}
