import styled, {CSSProperties} from 'styled-components';
import {Fragment, memo, SyntheticEvent} from 'react';
import {getColorFromTheme} from '../../ui-utils';
import {ArrowHeadRightIcon} from '../../atoms/icons/arrow-head-right';
import {Body2} from '../../atoms/typography/body2';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 16px;
  margin-bottom: 24px;
  border-bottom: solid 1px ${getColorFromTheme('borderLight')};
`;

const SecondaryWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
`;

const IconWrapper = styled.span`
  flex-basis: 16px;
  margin-right: 4px;
  margin-left: 4px;

  svg {
    stroke: ${getColorFromTheme('purple300')};
    position: relative;
    top: 2px;
  }
`;

export interface BreadcrumbProps {
  navigation: {
    title: string;
    isCurrent?: boolean;
    href: string;
    id: string;
    onClick: (e: SyntheticEvent<HTMLAnchorElement>) => void;
  }[];
  wrapperStyles?: CSSProperties;
}

export const Breadcrumb = memo(function ({navigation}: BreadcrumbProps) {
  return (
    <Wrapper>
      {navigation.map((item, index) => (
        <Fragment key={item.id}>
          <a href={item.href} onClick={item.onClick}>
            <Body2 kind={item.isCurrent ? 'textDark' : 'textBody'}>
              {item.title}
            </Body2>
          </a>
          {index !== navigation.length - 1 && (
            <IconWrapper>
              <ArrowHeadRightIcon />
            </IconWrapper>
          )}
        </Fragment>
      ))}
    </Wrapper>
  );
});
Breadcrumb.displayName = 'Breadcrumb';

export const SecondaryBreadcrumb = memo(function ({
  navigation,
  wrapperStyles,
}: BreadcrumbProps) {
  return (
    <SecondaryWrapper style={wrapperStyles}>
      {navigation.map((item, index) => (
        <Fragment key={item.id}>
          <a href={item.href} onClick={item.onClick}>
            <Body2
              kind={item.isCurrent ? 'textDark' : 'textBody'}
              style={{fontWeight: item.isCurrent ? 500 : 400}}>
              {item.title}
            </Body2>
          </a>
          {index !== navigation.length - 1 && (
            <IconWrapper>
              <ArrowHeadRightIcon />
            </IconWrapper>
          )}
        </Fragment>
      ))}
    </SecondaryWrapper>
  );
});
SecondaryBreadcrumb.displayName = 'SecondaryBreadcrumb';
