import styled from 'styled-components';
import styledMap from 'styled-map';
import {CSSProperties, memo} from 'react';
import {NewOnTrackIcon, BehindIcon, AtRiskIcon} from '@ui/atoms/icons';
import {Typography} from '@ui/atoms/typography/typography';
import {ProgressType} from '../../interface';
import {theme} from '../../style';
import '@formatjs/intl-numberformat/polyfill';
import {Body2} from '@ui/atoms/typography';
import {DotIcon} from '@ui/atoms/icons/dots';
import {getCurrencySymbol} from '@constants';
import {FlexRow, Flex} from '@ui/style/styles';
import {StatusType} from '../../../hooks';

const commaNumber = require('comma-number');

const UpcomingWrapper = styled.div`
  border: 1px solid #ededf2;
  padding: 10px 12px;
  border-radius: 8px;
`;

const Wrapper = styled.div<{
  progress?: ProgressType;
  percent?: number;
  status?: string;
  treeView?: boolean;
  variant?: string;
  view?: string;
  tabKind?: string;
}>`
  min-width: ${styledMap('variant', {
    companyTeams: '166px',
    performance: '129px',
    performanceOverview: '166px',
    youcheckins: '200px',
    insight: '200px',
    default: '188px',
  })};

  max-width: ${styledMap('variant', {
    companyTeams: '230px',
    performance: '180px',
    default: undefined,
  })};

  max-height: ${styledMap('variant', {
    companyTeams: '32px',
    performance: '38px',
    default: undefined,
  })};

  z-index: 0;
  white-space: nowrap;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;

  display: flex;
  align-items: center;
  position: relative;
  flex-direction: row;
  justify-content: ${styledMap('variant', {
    companyReportPDF: 'flex-start',
    companyReportPDFTotal: 'flex-start',
    performance: 'flex-start',
    default: 'space-between',
  })};

  padding: ${(props: any) =>
    props.treeView
      ? '3.2px 16px'
      : props.variant === 'companyTeams' && props.view === 'stacked'
      ? '0px 16px'
      : props.variant === 'performance'
      ? '10px 12px'
      : '9px 12px'};

  overflow: hidden;
  border: 1px solid #ededf2;

  @media (max-width: 500px) {
    width: 100%;
    min-width: 100%;
    padding: ${(props: any) =>
      props.treeView
        ? '3.2px 16px'
        : props.variant === 'companyTeams' && props.view === 'stacked'
        ? '0px 16px'
        : props.variant === 'performance'
        ? '10px 12px'
        : '8px  12px'};
  }
`;

const Text = styled(Body2)`
  ${Typography};
  font-size: 12px;

  position: relative;
  z-index: 1;

  &:first-of-type {
    margin-left: 6px;
  }
`;
export const PillDiv = styled.div<{
  progress?: ProgressType;
  percent?: number;
  status?: string;
  treeView?: boolean;
}>`
  width: 24px;
  height: 8px;
  border-radius: 10px;

  color: ${styledMap('progress', {
    [ProgressType.OFFTRACK]: theme.colors.red500,
    [ProgressType.ONTRACK]: theme.colors.green500,
    [ProgressType.PROGRESSING]: theme.colors.orange400,
    [ProgressType.NOSTATUS]: theme.colors.textBody,
    default: theme.colors.green500,
  })};

  background: ${styledMap('progress', {
    [ProgressType.OFFTRACK]: theme.colors.red200,
    [ProgressType.ONTRACK]: theme.colors.green200,
    [ProgressType.PROGRESSING]: theme.colors.orange200,
    [ProgressType.NOSTATUS]: theme.colors.purple200,
  })};
  z-index: 1;

  position: relative;

  &:after {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 0;
    top: 0;
    height: 100%;
    content: '';
    display: block;
    background-color: ${styledMap('progress', {
      [ProgressType.OFFTRACK]: theme.colors.red400,
      [ProgressType.ONTRACK]: theme.colors.green300,
      [ProgressType.PROGRESSING]: theme.colors.orange300,
      [ProgressType.NOSTATUS]: theme.colors.textBody,
      default: '',
    })};
    background-color: ${styledMap('status', {
      [StatusType.COMPLETED]: theme.colors.textBody,
      [StatusType.INCOMPLETED]: theme.colors.textBody,
      default: '',
    })};
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: ${({percent}) =>
      percent === 100 ? '10px' : undefined};
    border-bottom-right-radius: ${({percent}) =>
      percent === 100 ? '10px' : undefined};

    width: ${({percent}) => `${percent}%`};
  }
`;

const TypeText: Record<ProgressType, string> = {
  [ProgressType.OFFTRACK]: 'At-risk',
  [ProgressType.ONTRACK]: 'On-track',
  [ProgressType.PROGRESSING]: 'Behind',
  [ProgressType.NOSTATUS]: 'No-Status',
};

export interface ProgressTrackerProps {
  state?: string;
  type: ProgressType;
  percent: number;
  due?: string;
  status?: string;
  treeView?: boolean;
  archived?: boolean;
  showFullValue?: boolean;
  removePill?: boolean;
  upcoming?: boolean;
  targetValue?: string;
  percentStyle?: CSSProperties;
  currency?: string;
  isGoalKpi?: boolean;
  unit?: string;
  variant?: string;
  view?: string;
  endDate?: string;
  tabKind?: string;
}

export const ProgressTracker = memo(function ({
  due,
  type,
  percent,
  showFullValue,
  isGoalKpi,
  status,
  archived,
  targetValue,
  unit,
  currency,
  percentStyle,
  removePill,
  upcoming,
}: ProgressTrackerProps) {
  let formatter = Intl.NumberFormat('en', {notation: 'compact'});

  const _percent = Math.round(percent);

  const goalCurrency = currency ? getCurrencySymbol.get(currency as any) : '';

  const percentage = unit?.toLowerCase() === 'percentage' ? '%' : '';

  const handleType = (type: string, status?: string) => {
    if (status === StatusType.COMPLETED || status === StatusType.INCOMPLETED) {
      return (
        <FlexRow>
          <DotIcon />{' '}
          <Text kind="textBody" weight="semibold">
            Ended
          </Text>
        </FlexRow>
      );
    }
    if (due === 'Past due') {
      return (
        <FlexRow>
          <AtRiskIcon />{' '}
          <Text kind="red500" weight="semibold">
            {due}
          </Text>
        </FlexRow>
      );
    }

    switch (type) {
      case 'At-risk':
        return (
          <FlexRow>
            <AtRiskIcon />{' '}
            <Text kind="red500" weight="semibold">
              {due}
            </Text>
          </FlexRow>
        );
      case 'Behind':
        return (
          <FlexRow>
            <BehindIcon />{' '}
            <Text kind="orange300" weight="semibold">
              {due}
            </Text>
          </FlexRow>
        );
      case 'On-track':
        return (
          <FlexRow>
            <NewOnTrackIcon />{' '}
            <Text kind="green400" weight="semibold">
              {due}
            </Text>
          </FlexRow>
        );
      case 'No-Status':
        return (
          <FlexRow>
            <DotIcon />{' '}
            <Text kind="textBody" weight="semibold">
              {due}
            </Text>
          </FlexRow>
        );
      default:
        break;
    }
  };

  if (upcoming) {
    return (
      <UpcomingWrapper>
        <Flex alignItems="center" justifyContent="center">
          <DotIcon />{' '}
          <Text weight="semibold" kind="textBody">
            Upcoming
          </Text>
        </Flex>
      </UpcomingWrapper>
    );
  }

  if (archived) {
    return (
      <UpcomingWrapper>
        <Flex alignItems="center" justifyContent="center">
          <DotIcon />{' '}
          <Text weight="semibold" kind="textBody">
            Archived
          </Text>
        </Flex>
      </UpcomingWrapper>
    );
  }
  return (
    <>
      <Wrapper>
        {handleType(TypeText[type], status)}{' '}
        <FlexRow>
          {!isGoalKpi && !removePill && (
            <PillDiv
              percent={percent}
              progress={
                status === StatusType.COMPLETED
                  ? ProgressType.NOSTATUS
                  : due === 'Past due'
                  ? ProgressType.OFFTRACK
                  : type
              }
              status={status}
            />
          )}
          {isGoalKpi ? (
            <Body2
              weight={targetValue ? 'bold' : 'regular'}
              style={percentStyle || {fontSize: '12px', marginLeft: '5px'}}>
              {goalCurrency}{' '}
              {showFullValue ? commaNumber(percent) : formatter.format(percent)}
              {percentage}
              {targetValue ? (
                <span className="text-[#5F5F8C]">
                  {'  '}of {goalCurrency}
                  {targetValue}
                  {percentage}
                </span>
              ) : null}
            </Body2>
          ) : (
            <Body2
              style={percentStyle || {fontSize: '10px', marginLeft: '5px'}}>
              {_percent}%
            </Body2>
          )}
        </FlexRow>
      </Wrapper>
    </>
  );
});
ProgressTracker.displayName = 'ProgressTracker';
