import styled from 'styled-components';
import {useState} from 'react';
import dayjs from 'dayjs';
import {observer} from 'mobx-react-lite';
import {ButtonNavigation} from '@ui/molecules/button-nav';
import {CycleHeader} from '../components/cycle-headers';
import SelfReview from './self-review/self-review';
import {getColorFromTheme} from '@ui/ui-utils';
import {Body1} from '@ui/atoms/typography';
import PeerReviews from './peers/peers-review';
import DirectReports from './direct-reports/direct-reports';
import {VerticalSpacer} from '@ui/atoms/spacer';
import EmptyReview from './empty-review';
import {RecieveReview} from './receive-review';
import {authStore} from '@store/stores/auth-store';
import {useReviewsHook} from './reviews-hooks';
import {useCycleOverview} from '../overview.hook';

const Wrapper = styled.div`
  border-style: solid;
  border-width: 1px;
  margin-top: 24px;
  border-radius: 10px;
  border-color: ${getColorFromTheme('borderLight')};
  .heading {
    padding: 16px 24px 24px;
  }
  .button-wrapper {
    display: flex;
    border-radius: 10px 10px 0px 0px;
    width: 40%;
  }
  .body {
    border-top: 1px solid #ededf2;
    border-radius: 10px;
    padding: 16px;
  }
`;

export const YourReview = observer(() => {
  const {isLoading, reviewCycleOptions, currentReviewCycle} = useReviewsHook();

  const [buttonNav, setNavigation] = useState({
    writeReview: true,
    receiveReview: false,
  });

  useCycleOverview({userId: authStore.auth.user.id});

  const navigations = [
    {
      active: buttonNav.writeReview,
      href: '#',
      id: '1',
      onClick: () => setNavigation({writeReview: true, receiveReview: false}),
      title: 'Write review',
    },
    {
      active: buttonNav.receiveReview,
      href: '#',
      id: '2',
      onClick: () => setNavigation({writeReview: false, receiveReview: true}),
      title: 'Receive reviews',
    },
  ];

  const formatPeriod = () => {
    const period = currentReviewCycle.period;

    const dates = period.match(/\d{2}\/\d{2}\/\d{4}/g);

    if (dates) {
      // Parse the dates using dayjs
      const startDate = dayjs(dates[0], 'MM/DD/YYYY');
      const endDate = dayjs(dates[1], 'MM/DD/YYYY');

      // Format the dates into the desired format
      const formattedStartDate = startDate.format('DD MMM. YYYY');
      const formattedEndDate = endDate.format('DD MMM. YYYY');

      // Construct the output string
      const output = `${period.slice(
        0,
        2,
      )}(${formattedStartDate} - ${formattedEndDate})`;
      return output;
    }
    return '';
  };
  return (
    <div>
      <CycleHeader
        reviewOptions={reviewCycleOptions()}
        progress={currentReviewCycle?.progress || 0}
        disabled={!reviewCycleOptions().length}
        dateRange={
          currentReviewCycle.milestones.endDate
            ? `${dayjs(currentReviewCycle.milestones.startDate).format(
                'DD MMM. YYYY',
              )} -  ${dayjs(currentReviewCycle.milestones.endDate).format(
                'DD MMM. YYYY',
              )}`
            : ''
        }
        isLoading={isLoading}
        dueDay={currentReviewCycle.milestones.endDate || ''}
        reviewTimeline={formatPeriod()}
      />
      <Wrapper className="bg-white">
        <div className="heading">
          <Body1 weight="bold">Manage reviews</Body1>
        </div>
        <div className="button-wrapper">
          <ButtonNavigation
            navigations={navigations}
            variant="addBorderTop"
            theme="purpleText"
          />
        </div>
        <div className="body">
          {buttonNav.writeReview && (
            <>
              {currentReviewCycle.status === 'upcoming' ? (
                <EmptyReview />
              ) : (
                <>
                  <SelfReview
                    cycleId={currentReviewCycle.id}
                    disabled={false}
                    dueDay={currentReviewCycle.milestones.self}
                    reviewType={currentReviewCycle.reviewType}
                  />

                  {currentReviewCycle.reviewType === '360' && (
                    <>
                      <VerticalSpacer size="16px" />
                      <PeerReviews
                        cycleId={currentReviewCycle.id}
                        disabled={false}
                        dueDay={currentReviewCycle.milestones.peer}
                      />
                    </>
                  )}
                  {authStore.auth.user.isReviewer && (
                    <>
                      <VerticalSpacer size="16px" />

                      <DirectReports
                        cycleId={currentReviewCycle.id}
                        disabled={false}
                        dueDay={currentReviewCycle.milestones.manager}
                        finalizeDueDay={currentReviewCycle.milestones.finalize}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
          {buttonNav.receiveReview && (
            <>
              {currentReviewCycle.status === 'upcoming' ? (
                <EmptyReview />
              ) : (
                <RecieveReview
                  cycleId={currentReviewCycle.id}
                  reviewType={currentReviewCycle.reviewType}
                  peerDueDay={currentReviewCycle.milestones.peer}
                  disabled={false}
                  dueDay={currentReviewCycle.milestones.manager}
                />
              )}
            </>
          )}
        </div>
      </Wrapper>
    </div>
  );
});
