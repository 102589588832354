import {SectionCard} from '@ui/layouts/section-card';
import {MultiField} from '@ui/molecules/field/multifield';
import {Dropdown} from '@ui/molecules/dropdown';
import {FlexRow} from '@ui/style/styles';
import {HorizontalSpacer} from '@ui/atoms/spacer';
import {useStoreContext} from '@store/store-context';
import {PlainButton} from '@ui/atoms/plain-button/plain-button';
import {ToggleSwitch} from '@ui/atoms/toggle-switch';
import {MeetingComments} from './meeting-comments';
import CommentIcon from '@ui/atoms/icons/comment-icon';
import {Body2, Headline3} from '@ui/atoms/typography';
import {IAPIUserFormat} from '@hooks';
import {CommentNotif} from '../styles';
import {useCallback, useMemo, useState} from 'react';
import {useBreakpoints} from '@utils/use-breakpoints';
import {observer} from 'mobx-react';
import {useDebouncedCallback} from 'use-debounce';
import {generateRandomDigits} from '@utils/generate-random-numbers';

interface AgendaProps {
  isHostSharing: boolean;
  participantSharing: string[];
  excludeComments?: boolean;
  meetingId?: string;
  isReadOnly?: boolean;
  handleUseTemplates?: () => void;
  users: {
    user: string;
    participant: string[];
  };
  isMeetingActive?: boolean;
  updateMeeting: (
    value: {text: string; completed: boolean; [x: string]: any}[] | any,
    field:
      | 'hostAgenda'
      | 'participantAgenda'
      | 'shareHostAgenda'
      | 'shareParticipantAgenda',
  ) => Promise<void>;
  agendas: {
    host: {
      user: IAPIUserFormat;
      agenda: {text: string; completed: boolean; _id?: string}[];
    };
    participant: {
      user: IAPIUserFormat[];
      agenda: {
        text: string;
        completed: boolean;
        _id?: string;
        participant?: string;
      }[];
    };
  };
}
export const MeetingAgenda = observer(
  ({
    agendas,
    isHostSharing,
    isMeetingActive,
    meetingId,
    excludeComments,
    participantSharing,
    updateMeeting,
    isReadOnly,
    users,
  }: AgendaProps) => {
    const {
      authStore: {auth},
      usersStore: {users: allUsers},
    } = useStoreContext();

    const findParticipant = useCallback(
      (id: string) => allUsers.find((user) => user.id === id),
      [allUsers],
    );

    const userIsSharing = participantSharing.includes(auth.user.id);

    const formatAgenda = useMemo(() => {
      const agenda = [];

      if (isHostSharing || auth.user.id === users.user) {
        agenda.push(
          ...agendas.host.agenda.map((agenda) => {
            return {
              ...agenda,
              key: agenda._id || generateRandomDigits(24),
              assignee: agendas.host.user,
            };
          }),
        );
      }

      if (userIsSharing || users.participant.includes(auth.user.id)) {
        agenda.push(
          ...agendas.participant.agenda.map((agenda) => {
            return {
              ...agenda,
              key: agenda._id || generateRandomDigits(24),
              assignee: findParticipant(agenda.participant || ''),
            };
          }),
        );
      }

      if (!agenda.length) {
        agenda.push({text: '', completed: false, key: '', assignee: auth.user});
      }

      return agenda;
    }, [
      isHostSharing,
      auth.user,
      users.user,
      users.participant,
      userIsSharing,
      agendas.host.agenda,
      agendas.host.user,
      agendas.participant.agenda,
      findParticipant,
    ]);

    const _updateAgenda = (agendas_: any[]) => {
      const formattedAgenda = agendas_.map(
        (agenda: {text: any; completed: any; assignee: any; _id?: string}) => ({
          text: agenda.text,
          completed: agenda.completed,
          _id: agenda._id,
        }),
      );

      if (auth.user.id === agendas.host.user.id) {
        return formattedAgenda;
      } else {
        return agendas_.map(
          (agenda: {
            text: any;
            completed: any;
            assignee: any;
            _id?: string;
          }) => ({
            text: agenda.text,
            completed: agenda.completed,
            participant: agenda.assignee?.id,
            _id: agenda._id,
          }),
        );
      }
    };

    const updateAgenda = useDebouncedCallback(async (agendas_) => {
      const formattedAgenda = _updateAgenda(agendas_);

      if (auth.user.id === agendas.host.user.id) {
        return await updateMeeting(formattedAgenda, 'hostAgenda');
      }
      return await updateMeeting(formattedAgenda, 'participantAgenda');
    }, 2000);

    const isParticipantViewing = users.participant.includes(auth.user.id);

    const [openComment, setOpenComment] = useState<{
      id: string;
      content: {text: string; completed: boolean};
      userId: string;
    } | null>();

    const findAgenda = (fieldId: string) => {
      return formatAgenda?.find((priority) => priority?.key === fieldId);
    };

    const {isXs} = useBreakpoints();

    return (
      <div>
        {' '}
        <SectionCard
          title="Talking point"
          headerPadding={isXs ? 'small' : undefined}
          contentStyling={{padding: '0px'}}
          CustomHeaderTitle={
            <div>
              <Headline3 kind="textDark">Talking points</Headline3>
            </div>
          }>
          <div>
            <div style={{padding: isXs ? '12px' : '24px 24px 16px 16px'}}>
              <MultiField
                checkedIndexes={[0]}
                label=""
                value={formatAgenda}
                placeholder="Add an agenda"
                updateValue={async (value_) => {
                  const formattedValue =
                    value_ &&
                    value_.map((val) => {
                      return {
                        ...val.val,
                      };
                    });

                  const formatAgenda = () => {
                    return formattedValue
                      ?.filter((value) => value.assignee.id === auth.user.id)
                      .map((agenda) => {
                        return {
                          ...agenda,
                          key: undefined,
                          saved: undefined,
                        };
                      });
                  };

                  const getHostAgenda = formatAgenda();

                  return await updateAgenda(getHostAgenda);
                }}
                id=""
                returnValueKey
                fontWeight={400}
                margin
                showCheckbox={true}
                disableCheckbox={!isMeetingActive}
                disabled={isReadOnly}
                readonly={isReadOnly}
                userCanReorder
                removeAddToList={isReadOnly}
                userId={auth.user.id}
                updateValueInstantly
                addAvatar
                colorString="textMedium"
                variant="youcheckins"
                dropdownComponent={(
                  onHandleRemove: () => void,
                  fieldId: string,
                  indexOfFieldId: number,
                ) => (
                  <FlexRow>
                    {findAgenda(fieldId) && !excludeComments && (
                      <PlainButton
                        onClick={() => {
                          setOpenComment({
                            id: fieldId,
                            userId:
                              // To be reworked
                              // auth.user.id === users.user
                              //   ? users.participant
                              //   :
                              users.user,
                            content: {
                              text: findAgenda(fieldId)?.text || '',
                              completed:
                                findAgenda(fieldId)?.completed || false,
                            },
                          });
                        }}>
                        <CommentNotif>
                          <CommentIcon />
                        </CommentNotif>
                      </PlainButton>
                    )}

                    {findAgenda(fieldId)?.assignee?.id === auth.user.id &&
                      !isReadOnly && (
                        <Dropdown
                          dropdownItemStyle={{whiteSpace: 'nowrap'}}
                          menu={[
                            {
                              content: `Delete`,
                              id: 'removeCurrent',
                            },
                          ]}
                          onClick={() => {
                            onHandleRemove();
                            const isHostAgenda = agendas.host.agenda.find(
                              (value) => value._id === fieldId,
                            );

                            updateMeeting(
                              formatAgenda
                                .filter(
                                  (value_) =>
                                    value_.key !== fieldId &&
                                    value_.assignee?.id === auth.user.id,
                                )
                                .map((agenda) => {
                                  return {
                                    ...agenda,
                                    key: undefined,
                                    assignee: undefined,
                                  };
                                }),
                              isHostAgenda ? 'hostAgenda' : 'participantAgenda',
                            );
                          }}
                          specificIndexDropdownItemStyles={[
                            {index: 1, style: {color: '#D52A2A'}},
                          ]}
                        />
                      )}
                  </FlexRow>
                )}
              />
            </div>

            <div className="border-t border-t-[#ededf2] py-4 px-6 flex items-center justify-end">
              {isReadOnly ? (
                <Body2 weight="semibold">
                  {(isParticipantViewing && userIsSharing) ||
                  (!isParticipantViewing && isHostSharing)
                    ? 'Shared'
                    : 'Private'}
                </Body2>
              ) : (
                <FlexRow>
                  <Body2 weight="semibold">Make private</Body2>
                  <HorizontalSpacer size="8px" />

                  <ToggleSwitch
                    checked={
                      isParticipantViewing ? !userIsSharing : !isHostSharing
                    }
                    onChange={(e) => {
                      const value = isParticipantViewing
                        ? userIsSharing
                          ? participantSharing.filter(
                              (participant) => participant !== auth.user.id,
                            )
                          : [...participantSharing, auth.user.id]
                        : isHostSharing;

                      updateMeeting(
                        isParticipantViewing ? value : !value,
                        isParticipantViewing
                          ? 'shareParticipantAgenda'
                          : 'shareHostAgenda',
                      );
                    }}
                  />
                </FlexRow>
              )}
            </div>
          </div>
        </SectionCard>
        {!!openComment && (
          <MeetingComments
            meetingId={meetingId || ''}
            participants={users.participant}
            {...openComment}
            onClose={() => setOpenComment(null)}
          />
        )}
      </div>
    );
  },
);
