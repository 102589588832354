import {useDropDown} from '../../../../hooks';
import {ComponentType, memo} from 'react';
import styled from 'styled-components';
import {WorkspaceLogoToggle} from '../logo-toggle';

const Wrapper = styled.div`
  position: relative;
  height: 100%;
`;

export interface WorkspaceHeaderProps {
  workspace: {avatar?: string; name: string};
  disabled?: boolean;
  Dropdown: ComponentType<{onClose: () => void}>;
}

export const WorkspaceHeader = memo(function ({
  workspace,
  disabled,
  Dropdown,
}: WorkspaceHeaderProps) {
  const {handleOpen, handleClose, ref, open} = useDropDown();

  return (
    <Wrapper ref={ref}>
      <WorkspaceLogoToggle
        logo={workspace.avatar}
        workspace={workspace.name}
        disabled={disabled}
        onClick={disabled ? () => null : (handleOpen as any)}
        open={open}
      />
      {open && <Dropdown onClose={handleClose} />}
    </Wrapper>
  );
});
WorkspaceHeader.displayName = 'WorkspaceHeader';
