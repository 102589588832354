import React from 'react';

export const CommandIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.6875 9.6875V11.9375C9.6875 13.1801 10.6949 14.1875 11.9375 14.1875C13.1801 14.1875 14.1875 13.1801 14.1875 11.9375C14.1875 10.6949 13.1801 9.6875 11.9375 9.6875H9.6875ZM9.6875 9.6875V6.3125M9.6875 9.6875H6.3125M9.6875 6.3125V4.0625C9.6875 2.81986 10.6949 1.8125 11.9375 1.8125C13.1801 1.8125 14.1875 2.81986 14.1875 4.0625C14.1875 5.30514 13.1801 6.3125 11.9375 6.3125H9.6875ZM9.6875 6.3125H6.3125M4.0625 6.3125L4.05834 6.3125M4.05834 6.3125C2.81761 6.31026 1.8125 5.30376 1.8125 4.0625C1.8125 2.81986 2.81986 1.8125 4.0625 1.8125C5.30514 1.8125 6.3125 2.81986 6.3125 4.0625V6.3125M4.05834 6.3125H6.3125M6.3125 6.3125V9.6875M6.3125 9.6875V11.9375C6.3125 13.1801 5.30514 14.1875 4.0625 14.1875C2.81986 14.1875 1.8125 13.1801 1.8125 11.9375C1.8125 10.6949 2.81986 9.6875 4.0625 9.6875H6.3125Z"
        stroke="#5F5F8C"
        stroke-width="1.8"
      />
    </svg>
  );
};
