import {Feedback} from '@pages/feedback/feedback-items';
import dayjs from 'dayjs';

type FeedbackResponse = {[key: string]: Feedback[]};

const sortFeedbackByCreatedAt = (feedbacks: Feedback[]): Feedback[] => {
  return feedbacks.sort(
    (a, b) => Number(new Date(b.createdAt)) - Number(new Date(a.createdAt)),
  );
};

export const sortFeedback = (feedbacks: Feedback[], sortBy: keyof Feedback) => {
  const sortedFeedback = sortFeedbackByCreatedAt(feedbacks);

  const groupedAndSortedFeedback: FeedbackResponse = {};

  sortedFeedback.forEach((feedback) => {
    feedback.createdAt = dayjs(feedback.createdAt).format('YYYY-MM-DD');

    if (!groupedAndSortedFeedback[feedback[sortBy] as keyof Feedback]) {
      groupedAndSortedFeedback[feedback[sortBy] as keyof Feedback] = [];
    }

    groupedAndSortedFeedback[feedback[sortBy] as keyof Feedback].push(feedback);
  });

  return groupedAndSortedFeedback;
};
