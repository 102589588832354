import {observer} from 'mobx-react';
import {lazy} from 'react';

const VerifyEmailPage = lazy(() =>
  import('./verify-user-page').then((module) => ({
    default: module.VerifyUserPage,
  })),
);

export const installVerifyUser = () => {
  const Page = observer(() => <VerifyEmailPage />);

  return {
    Page,
  };
};
