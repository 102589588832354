import {useState, useCallback, useMemo} from 'react';
import {GoalsController} from '../../../api/goals-api/goals-controller';
import {GoalsRequestImpl} from '../../../api/goals-api/goals-request';
import {useStoreContext} from '../../../store/store-context';
import {useQuery} from 'react-query';
import {useFetchUserGoals} from '@hooks/goals.hook';
import {CompanyCheckinsRequestImpl} from '../company-legacy/company-components/company-checkins/company-checkins.request';
import {CompanyCheckinsController} from '../company-legacy/company-components/company-checkins/company-checkins.controller';
import {getWeekOfYear} from '../../../utils/date';

export function useYouHook() {
  const [showUpdateGoalModal, setShowUpdateGoalModal] = useState(false);
  const {
    data,
    refetch,
    searchValue,
    setSearchValue,
    isLoading,
  } = useFetchUserGoals();

  const {
    storeYouGoals: {fetchedGoals},
    checkinsStore: {
      setWorkspaceCheckins,
      checkinsDates,
      setCalendarWeeks,
      calendarWeeks,
    },
    authStore: {auth},
  } = useStoreContext();

  const handleClose = () => {
    setShowUpdateGoalModal(false);
  };

  const checkinsRequest = useMemo(() => new CompanyCheckinsRequestImpl(), []);
  const checkinsController = useMemo(
    () => new CompanyCheckinsController(checkinsRequest),
    [checkinsRequest],
  );
  const [loadingPage, setLoadingPage] = useState(false);

  const initWorkspaceCheckins = useCallback(
    async (filter?: {weekOfYear?: number; year?: number}) => {
      setLoadingPage(true);

      const response = await checkinsController.getCurrentCheckinsInfo(
        filter,
        true,
      );
      response && setLoadingPage(false);
      response && setWorkspaceCheckins(response as any);
    },
    [checkinsController, setWorkspaceCheckins],
  );

  const initCalendarWeeks = useCallback(
    async (filter?: {manager?: string; user?: string}) => {
      const response = await checkinsController.getCheckinsCalendarWeekList(
        filter,
      );
      setCalendarWeeks(response as any);
      return response;
    },
    [checkinsController, setCalendarWeeks],
  );

  const initFilter = useMemo(() => {
    return {
      weekOfYear:
        checkinsDates?.weekOfYear === getWeekOfYear()
          ? calendarWeeks?.[calendarWeeks?.length - 1]?.weekOfYear
          : checkinsDates?.weekOfYear,
      year:
        checkinsDates?.weekOfYear === getWeekOfYear()
          ? calendarWeeks?.[calendarWeeks?.length - 1]?.year
          : checkinsDates?.year,
    };
  }, [calendarWeeks, checkinsDates.weekOfYear, checkinsDates.year]);

  const {isRefetching} = useQuery(
    ['initWorkspaceCheckins', initFilter],
    () => initWorkspaceCheckins(initFilter),
    {
      refetchOnWindowFocus: false,
    },
  );

  useQuery(
    ['initCalenderWeeks', auth.user.id],
    () =>
      initCalendarWeeks({
        user: auth?.user?.id,
      }),
    {
      retry: false,
    },
  );

  const initKeyResults = useCallback(async () => {
    const request = new GoalsRequestImpl();
    const controller = new GoalsController(request);

    const response = await controller.fetchGoals(1, 'networkAllLevel', 10000);

    return response?.goals;
  }, []);

  return {
    fetchedGoals,
    isFetching: isLoading,
    initKeyResults,
    setSearchValue,
    searchValue,
    data,
    loadingCheckins: loadingPage || isRefetching,
    showUpdateGoalModal,
    refetch,
    handleClose,
  };
}
