import {useStoreContext} from '@store/store-context';
import {useMemo, useEffect} from 'react';
import {observer} from 'mobx-react';
import {Flex, FlexRowSpaceBetween} from '@ui/style/styles';
import {MyCheckins} from './my_checkins/my_checkins';
import {illustrationList} from '@ui/assets/illustrations';
import FreeTierModal from '@ui/organisms/free-tier/free-tier-modal';
import {useFreeTierHook} from '@hooks/free-tier';
import {CheckinsDirectReports} from './direct_reports/checkins_direct_reports';
import {CheckinsQuestion} from './questions/checkins-questions';
import {Body1, Headline2} from '@ui/atoms/typography';
import {useCheckinsTeamsMention} from './direct_reports/checkins-mention-hook';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {useCompanyCheckinsHook} from '../company-legacy/company-components/company-checkins/company-checkins.hooks';
import {getWeekOfYear} from '@utils/date';
import {CustomColorIcon} from '@ui/atoms/color-icons';
import {checkinName} from '@utils/checkin-name';
import {capitalize} from '@utils';
import {TabNavigation} from '@ui/molecules/tab-nav';

interface ICheckinsPage {
  tab?: 'directReports' | 'questions' | 'actionItems';
}
export const CheckinsPage = observer(({tab}: ICheckinsPage) => {
  const {
    storeDashboardRoutes: {
      checkinsTabs,
      activateCheckinsTabs,
      activateDashboardTab,
    },
    checkinsStore: {checkinsDates},
    authStore: {auth, setCalendarView},
  } = useStoreContext();

  const {
    getSubmittedActiveMentions,
    getNotSubmittedActiveMentions,
    getPendingReviews,
  } = useCheckinsTeamsMention();

  const filters = useMemo(() => {
    return {
      year: checkinsDates?.year,
      weekOfYear: getWeekOfYear(),
      manager: auth.user.id,
      page: 1,
    };
  }, [auth.user.id, checkinsDates?.year]);

  const {
    fetchListOfNonSubmittedCheckins,
    fetchListOfSubmittedCheckins,
  } = useCompanyCheckinsHook(true);

  useEffect(() => {
    if (auth.user.isReviewer) {
      fetchListOfNonSubmittedCheckins({...filters, isSubmitted: false});

      fetchListOfSubmittedCheckins({...filters, isSubmitted: true});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.user.isReviewer]);

  const {
    // isAccountFreeTier,
    showFreeTierModal,
    handleShowFreeTierModal,
  } = useFreeTierHook();

  const DisplayFreeTierModal = (
    <FreeTierModal
      open={showFreeTierModal}
      image={illustrationList.pickup}
      onClose={() => handleShowFreeTierModal(false)}
      description={`${capitalize(
        checkinName(),
      )} questions can provide valuable insights into your team's well-being and performance, fostering open communication and improving overall morale.`}
      title={`Utilize  ${checkinName()} questions to gain a comprehensive understanding of your team's current state`}
    />
  );

  const CheckinsNavigation: any = useMemo(() => {
    const nav = [
      {
        active: checkinsTabs.my_checkins,
        href: '/checkins',
        id: '1',
        onClick: () => {
          activateCheckinsTabs('my_checkins');
          setCalendarView('month');
        },
        icon: <></>,
        disableHref: false,
        iconPosition: '',
        iconSpacing: '',
        title: `Submit a ${checkinName()}`,
      },
    ];
    if (auth.user.isReviewer || auth.user.role === 'admin') {
      nav.push(
        {
          active: checkinsTabs.direct_reports,
          href: '/checkins/direct-reports',
          id: '2',
          onClick: () => {
            activateCheckinsTabs('direct_reports');
            setCalendarView('month');
          },
          icon: (
            <>
              {getSubmittedActiveMentions > 0 ||
              getNotSubmittedActiveMentions > 0 ||
              getPendingReviews?.length ? (
                <CustomColorIcon
                  color="#D52A2A"
                  width="8px"
                  height="8px"
                  margin={0}
                />
              ) : null}
            </>
          ),
          disableHref: false,
          iconPosition: '',
          iconSpacing: '',
          title: `Team  ${checkinName()}s`,
        },
        {
          active: checkinsTabs.questions,
          href: '/checkins/questions',
          id: '1',
          onClick: () => {
            activateCheckinsTabs('questions');
          },
          icon: <></>,
          disableHref: false,
          iconPosition: '',
          iconSpacing: '',
          title: `Manage ${checkinName()} questions`,
        },
      );
    }

    return nav;
  }, [
    checkinsTabs.my_checkins,
    checkinsTabs.direct_reports,
    checkinsTabs.questions,
    auth.user.isReviewer,
    auth.user.role,
    activateCheckinsTabs,
    setCalendarView,
    getSubmittedActiveMentions,
    getNotSubmittedActiveMentions,
    getPendingReviews?.length,
  ]);

  useEffect(() => {
    activateDashboardTab('checkins');
    if (tab) {
      switch (tab) {
        case 'directReports':
          activateCheckinsTabs('direct_reports');
          break;
        case 'questions':
          activateCheckinsTabs('questions');
          break;

        default:
          break;
      }
    }
  }, [tab, activateDashboardTab, activateCheckinsTabs]);

  return (
    <>
      <FlexRowSpaceBetween className="mb-10">
        <div className="">
          <Headline2>{capitalize(checkinName())}s</Headline2>
          <div className="w-[80%] mt-1">
            <Body1 kind="textBody">
              Submit {checkinName()} on progress, mood, and priorities for
              manager review.
            </Body1>
          </div>
        </div>
      </FlexRowSpaceBetween>

      <TabNavigation navigations={CheckinsNavigation} />

      <VerticalSpacer size="24px" />

      {checkinsTabs.my_checkins && (
        <>
          <MyCheckins /> {DisplayFreeTierModal}
        </>
      )}

      {checkinsTabs.questions && (
        <>
          <CheckinsQuestion />{' '}
        </>
      )}

      {checkinsTabs.direct_reports && (
        <Flex>
          <CheckinsDirectReports />{' '}
        </Flex>
      )}
    </>
  );
});
