import {SectionCard} from '@ui/layouts/section-card';
import {Headline3} from '@ui/atoms/typography';
import {MeetingComments} from './meeting-comments';
import {useStoreContext} from '@store/store-context';
import {useMemo, useState} from 'react';
import {useBreakpoints} from '@utils/use-breakpoints';
import {ActionItems} from '@ui/organisms/action-items/action-items';
import {userName} from '@utils/user-name';
import {observer} from 'mobx-react';
import {useActionItems} from '@hooks/features-action-items';
import {DropdownVertical} from '@ui/molecules/dropdown-vertical';
import {MoreVerticalIcon} from '@ui/atoms/icons';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';

type steps = {
  completed: boolean;
  text: string;
  updatedBy?: string;
  dueDate?: string;
  id?: string;
};
interface NextStepsProps {
  steps: steps[];
  isReadOnly?: boolean;
  meetingId?: string;
  canCopyItems?: boolean;
  handleCopyActionItems?: (
    steps: {text: string; completed: boolean}[],
    id: string,
    currentSteps: {text: string; completed: boolean}[],
  ) => void;
  type?: 'meeting-notes';
  updateMeeting: (data: any[]) => Promise<void>;
  users: string[];
}
export const NextSteps = observer(
  ({
    meetingId = '',
    users: meetingUsers,
    updateMeeting,
    steps,
    canCopyItems,
    handleCopyActionItems,
    isReadOnly,
    type,
  }: NextStepsProps) => {
    const {
      authStore: {auth},
      usersStore: {users},
    } = useStoreContext();

    const [openComment, setOpenComment] = useState<{
      id: string;
      content: {text: string; completed: boolean};
      userId: string;
    } | null>();

    const {handleChange, tasks} = useActionItems({
      source: 'meeting',
      userId: '',
      itemId: meetingId,
      orderBy: {
        value: meetingId,
        key: 'sourceId',
      },
    });

    const computeSteps = useMemo(() => {
      if (type === 'meeting-notes') {
        return steps.map((step) => ({...step, key: step.id}));
      }
      return tasks;
    }, [steps, tasks, type]);

    const {isXs} = useBreakpoints();

    return (
      <div>
        <SectionCard
          title="What are the next steps?"
          headerPadding={isXs ? 'small' : undefined}
          action={
            <>
              {canCopyItems && (
                <DropdownVertical
                  customIcon={<MoreVerticalIcon />}
                  collapseOnClick
                  dropdownWrapperStyle={{
                    right: '-83%',
                  }}
                  menu={(handleClose: any) => (
                    <div>
                      <DropdownItem
                        onClick={() => {
                          handleCopyActionItems &&
                            handleCopyActionItems(steps, meetingId, tasks);
                          handleClose();
                        }}>
                        Copy previous open items to this list
                      </DropdownItem>
                    </div>
                  )}
                />
              )}
            </>
          }
          contentStyling={{padding: isXs ? '12px' : '24px 24px 24px 16px'}}
          CustomHeaderTitle={
            <div>
              <Headline3 kind="textDark">Action items</Headline3>
            </div>
          }>
          <div>
            <ActionItems
              isReadOnly={isReadOnly}
              handleChange={async (value, actionType, fieldId, item) => {
                if (type === 'meeting-notes') {
                  return await updateMeeting(value);
                }

                await handleChange(value, actionType, fieldId, {
                  ...item,
                  assignee:
                    actionType === 'user'
                      ? item?.assignee
                      : item?.assignee || auth.user.id,
                } as any);

                await updateMeeting(
                  value.map((value) => ({
                    text: value.text,
                    completed: value.completed,
                    // assignee: value.assignee,
                  })),
                );
              }}
              users={users
                .filter((user) => meetingUsers.includes(user.id))
                .map((user) => ({
                  label: userName(user),
                  value: user.id,
                  avatar: user.avatar,
                }))}
              source="meeting"
              sourceId={''}
              canDelete={(fieldId) => {
                const task = tasks.find((task) => task.key === fieldId);

                if (!!task) {
                  // allow admin delete action item
                  if (auth.user.role === 'admin' && !!task) {
                    return true;
                  }

                  // allow manager of task users delete task
                  const usersInvolved = users
                    .filter((user) =>
                      [task?.user, task?.updatedBy, task?.assignee].includes(
                        user.id,
                      ),
                    )
                    .map((user) => user.reviewer.id);

                  if (usersInvolved.includes(auth.user.id)) {
                    return true;
                  }
                }
                return false;
              }}
              value={computeSteps || []}
              userId={auth.user.id}
            />
          </div>
        </SectionCard>
        {!!openComment && (
          <MeetingComments
            meetingId=""
            {...openComment}
            onClose={() => setOpenComment(null)}
          />
        )}
      </div>
    );
  },
);
