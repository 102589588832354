import {useCallback, useState} from 'react';
import {userName} from '@utils/user-name';
import {capitalize} from '@utils';
import dayjs from 'dayjs';
import {cfv} from '@utils/framework';
import {AnalyticsController} from '../admin-analytics/admin-analytics.controller';
import {AnalyticsRequestImpl} from '../admin-analytics/admin-analytics.request';
import {useStoreContext} from '@store/store-context';

export const useCsvHook = (dateRange: any, exportType: string) => {
  const {
    analyticsStore: {
      goalPaginator,
      kpiPaginator,
      viewAnalyticsRange,
      filterCompletion,
      filterState,
      filterStatus,
      goalsDate,
      searchable,
      filterTarget,
    },
    authStore: {auth},
  } = useStoreContext();
  const [data, setData] = useState<any>([]);
  const [isLoading, setLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const goalsType =
    exportType === 'objective'
      ? capitalize(cfv().g_oals)
      : exportType === 'kpi'
      ? 'KPIs'
      : capitalize(cfv().k_rs);

  const updateDate = useCallback(
    (data: any) => {
      setData([
        [goalsType, 'Start date', 'End date', 'Owner(s)', ...dateRange],
        ...data,
      ]);
    },
    [goalsType, dateRange],
  );
  const fetchObjective = useCallback(
    async (fetchKpi?: boolean) => {
      setLoading(true);

      const request = new AnalyticsRequestImpl();
      const controller = new AnalyticsController(request);
      try {
        const {goals} = await controller.fetchSpecifiedGoals(
          {
            goalPage: goalPaginator.page,
            state: filterState.goals,
            status: filterStatus.goals,
            startDate: goalsDate.starts,
            endDate: goalsDate.ends,
            newReport: true,
            filterBy: viewAnalyticsRange,
            goalType: searchable.goalType,
            threshold: filterCompletion.goals,
            member: searchable.members,
            isKpi: fetchKpi,
            manager: searchable.managers
              ? searchable.managers
              : auth?.user?.role !== 'admin' && auth?.user?.isReviewer
              ? auth?.user?.id
              : undefined,
            department: searchable.department,
            departmentMembers: searchable.departmentMembers,
            deactivatedMembers: searchable.deactivatedMembers,
          },
          '',
          true,
        );
        updateDate(
          goals.map((goal: any) => [
            goal?.name,
            dayjs(goal?.startDate).format('DD/MM/YYYY'),
            dayjs(goal?.endDate).format('DD/MM/YYYY'),
            userName(goal?.assignee),
            ...goal?.activities?.map((activity: any) =>
              exportType === 'objective'
                ? `${
                    activity?.progress === '-' ? '' : `${activity?.progress}%`
                  }`
                : activity?.progress,
            ),
          ]),
        );
        setDataLoaded(true);

        setLoading(false);
      } catch {
        setLoading(false);
      }
    },
    [
      goalPaginator.page,
      updateDate,
      auth?.user.role,
      auth?.user.isReviewer,
      auth?.user?.id,
      exportType,
      searchable.members,
      searchable.department,
      filterState.goals,
      filterStatus.goals,
      viewAnalyticsRange,
      filterCompletion.goals,
      goalsDate.starts,
      goalsDate.ends,
      searchable.goalType,
      searchable.managers,
      searchable.departmentMembers,
      searchable.deactivatedMembers,
    ],
  );
  const fetchKeyResult = useCallback(async () => {
    setLoading(true);

    const request = new AnalyticsRequestImpl();
    const controller = new AnalyticsController(request);
    try {
      const response = await controller.fetchSpecifiedKpis(
        {
          kpiPage: kpiPaginator.page,
          state: filterState.kpis,
          status: filterStatus.kpis,
          startDate: goalsDate.starts,
          endDate: goalsDate.ends,
          member: searchable.members,
          threshold: filterCompletion.keyresults,
          newReport: true,
          filterBy: viewAnalyticsRange,
          manager: searchable.managers
            ? searchable.managers
            : auth?.user?.role !== 'admin'
            ? auth?.user?.id
            : undefined,
          department: searchable.department,
          departmentMembers: searchable.departmentMembers,
          deactivatedMembers: searchable.deactivatedMembers,
          target: filterTarget.kpis,
        },
        true,
      );

      updateDate(
        response?.map((kr: any) => [
          kr?.name,
          dayjs(kr?.startDate).format('DD/MM/YYYY'),
          dayjs(kr?.endDate).format('DD/MM/YYYY'),
          userName(kr?.assignee),
          ...kr?.activities?.map((activity: any) => activity?.progress),
        ]),
      );
      setDataLoaded(true);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, [
    filterState.kpis,
    filterStatus.kpis,
    filterTarget.kpis,
    updateDate,
    filterCompletion.keyresults,
    viewAnalyticsRange,
    kpiPaginator.page,
    goalsDate.starts,
    auth?.user?.role,
    auth?.user?.id,
    goalsDate.ends,
    searchable.deactivatedMembers,
    searchable.department,
    searchable.departmentMembers,
    searchable.managers,
    searchable.members,
  ]);

  return {
    fetchKeyResult,
    fetchObjective,
    data,
    isLoading,
    dataLoaded,
    setDataLoaded,
    goalsType,
  };
};
