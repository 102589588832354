import {memo} from 'react';

export const UsersIcon = memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    stroke="rgba(88, 90, 223, 1)"
    stroke-width={'2px'}
    viewBox="0 0 16 16">
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M11.335 14v-1.333c0-.708-.281-1.386-.781-1.886S9.375 10 8.668 10H3.335c-.708 0-1.386.281-1.886.781s-.781 1.178-.781 1.886V14M5.999 7.333c1.472 0 2.666-1.194 2.666-2.666C8.665 3.194 7.471 2 6 2 4.526 2 3.332 3.194 3.332 4.667c0 1.472 1.194 2.666 2.667 2.666zM15.332 14v-1.333c0-.59-.197-1.165-.56-1.632-.361-.467-.868-.8-1.44-.948M10.668 2.087c.574.147 1.082.48 1.445.948.363.468.56 1.043.56 1.635 0 .592-.197 1.168-.56 1.635-.363.468-.871.802-1.445.949"
    />
  </svg>
));
export const UsersIcon2 = memo(() => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_27153_4920)">
      <path
        d="M14.6683 17.5V15.8333C14.6683 14.9493 14.3171 14.1014 13.692 13.4763C13.0669 12.8512 12.219 12.5 11.335 12.5H4.66829C3.78424 12.5 2.93639 12.8512 2.31127 13.4763C1.68615 14.1014 1.33496 14.9493 1.33496 15.8333V17.5"
        stroke="#585ADF"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.99837 9.16667C9.83932 9.16667 11.3317 7.67428 11.3317 5.83333C11.3317 3.99238 9.83932 2.5 7.99837 2.5C6.15742 2.5 4.66504 3.99238 4.66504 5.83333C4.66504 7.67428 6.15742 9.16667 7.99837 9.16667Z"
        stroke="#585ADF"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.665 17.5001V15.8334C19.6645 15.0948 19.4187 14.3774 18.9662 13.7937C18.5137 13.2099 17.8801 12.793 17.165 12.6084"
        stroke="#585ADF"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.835 2.6084C14.552 2.79198 15.1875 3.20898 15.6413 3.79366C16.0952 4.37833 16.3415 5.09742 16.3415 5.83757C16.3415 6.57771 16.0952 7.2968 15.6413 7.88147C15.1875 8.46615 14.552 8.88315 13.835 9.06673"
        stroke="#585ADF"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_27153_4920">
        <rect width="20" height="20" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
));
UsersIcon.displayName = 'UsersIcon';
