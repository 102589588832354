import {useStoreContext} from '@store/store-context';
import {activateNotification} from '@ui/molecules/notification/activate-notification';

export const useNetworkState = () => {
  const {
    notificationStore: {appIsOffline, handleAppNetwork},
  } = useStoreContext();

  const handleOnline = () => {
    if (appIsOffline) {
      activateNotification({
        title: "You're back online",
        content: '',
        kind: 'success',
      });
      handleAppNetwork(false);
    }
  };

  const handleOffline = () => {
    activateNotification({
      timeout: 40000000,
      title: 'You seem to be offline',
      content: 'Changes made now will not be saved.',
      kind: 'error',
    });
    handleAppNetwork(true);
  };

  return {
    handleOnline,
    handleOffline,
  };
};
