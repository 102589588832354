import styled from 'styled-components';
import styledMap from 'styled-map';
import {down, up} from 'styled-breakpoints';

export const AuthFormLayout = styled.div<{
  maxWidthDefault?: string;
  maxWidthDownLg?: string;
  maxWidthDownMd?: string;
  marginTop?: string;
  maxWidthDownSm?: string;
  maxWidthMin1440?: string;
  margin?: string;
}>`
  max-width: ${({maxWidthDefault}) =>
    maxWidthDefault ? maxWidthDefault : '400px'};
  margin: ${({margin}) => (margin ? margin : '0 auto')};
  margin-top: ${({marginTop}) => (marginTop ? marginTop : '100px')};

  ${down('lg')} {
    max-width: ${({maxWidthDownLg}) =>
      maxWidthDownLg ? maxWidthDownLg : '65%'};
  }

  ${down('md')} {
    max-width: ${({maxWidthDownMd}) =>
      maxWidthDownMd ? maxWidthDownMd : '80%'};
  }
  ${down('sm')} {
    max-width: ${({maxWidthDownSm}) =>
      maxWidthDownSm ? maxWidthDownSm : '100%'};
  }

  @media (min-width: 1440px) {
    max-width: ${({maxWidthMin1440}) =>
      maxWidthMin1440 ? maxWidthMin1440 : '48.888%'};
  }
`;

export const AuthFormLayoutMedium = styled.div`
  max-width: 500px;
  margin: 0 auto;
`;

export const AuthFormLayoutWide = styled.div`
  max-width: 55%;
  margin: 0 auto;

  ${down('lg')} {
    max-width: 65%;
  }

  ${down('md')} {
    max-width: 80%;
  }
  ${down('sm')} {
    max-width: 100%;
  }

  @media (min-width: 1440px) {
    max-width: 48.888%;
  }
`;

export const AuthFormLayoutWider = styled.div`
  max-width: 100%;
  height: 100%;
`;
export const Boardinglayout = styled.div`
  max-width: 100%;
`;

export const AuthInputsLayout = styled.div<{feature?: string}>`
  max-width: ${styledMap('feature', {
    welcome: '500px',
    invite: '100%',
    signup: '470px',
    default: '380px',
  })};

  margin: 0 auto;

  ${down('sm')} {
    max-width: ${styledMap('feature', {
      welcome: '100%',
      invite: '',
      signup: '470px',
      default: '380px',
    })};

    padding: ${styledMap('feature', {
      welcome: '16px',
      default: '10px',
    })};
  }

  ${up('md')} {
    padding: 0 20px;
  }
`;
