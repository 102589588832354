import {memo, SyntheticEvent} from 'react';
import {AnimateSharedLayout} from 'framer-motion';
import {Wrapper, NavWrapper} from './style';
import {ButtonNav} from '../../atoms/button-nav';

interface NavigationOption {
  href: string;
  title: string;
  active: boolean;
  id: string;
  icon?: any;
  iconPosition?: string;
  onClick: (e: SyntheticEvent<HTMLAnchorElement>) => void;
}

export interface BProps {
  navigations: NavigationOption[];
  variant?: 'peer' | 'addBorderTop';
  type?: 'overlap';
  theme?: 'purpleText' | 'darkText';
  removeLastBorder?: boolean;
}

export const ButtonNavigation = memo(function ({
  navigations,
  variant,
  removeLastBorder,
  theme,
  type,
}: BProps) {
  return (
    <Wrapper>
      <AnimateSharedLayout>
        <NavWrapper>
          {navigations.map((item, idx, navs) => (
            <ButtonNav
              href={item.href}
              type={type}
              theme={theme}
              idx={idx}
              activeIdx={navs.findIndex((value) => value.active)}
              active={item.active}
              key={item.id}
              removeLastIndex={removeLastBorder && !navigations[idx + 1]}
              variant={variant}
              onClick={item.onClick}>
              {item?.iconPosition === 'left' && (
                <span style={{marginRight: '6px'}}>{item.icon}</span>
              )}
              {item.title}
              {item?.iconPosition !== 'left' && (
                <span style={{marginLeft: '6px'}}>{item.icon}</span>
              )}
            </ButtonNav>
          ))}
        </NavWrapper>
      </AnimateSharedLayout>
    </Wrapper>
  );
});
ButtonNavigation.displayName = 'ButtonNavigation';
