import {observer} from 'mobx-react';
import {Dispatch, SetStateAction} from 'react';
import styled from 'styled-components';
import {useStoreContext} from '../../../../../../store/store-context';
import {AdminBillingActive} from './admin-billing-components/billing-active/billing-active';
import {AdminBillingNotActive} from './admin-billing-components/billing-not-active/billing-not-active';

const Wrapper = styled.div`
  width: 97%;
  @media (max-width: 500px) {
    width: 100%;
  }
`;
interface IAdminBillingSetup {
  setShowStarterPlanCTA: Dispatch<SetStateAction<boolean>>;
}
export const AdminBillingSetup = observer(
  ({setShowStarterPlanCTA}: IAdminBillingSetup) => {
    const {
      authStore: {auth},
      billingsStore: {showNonActiveBilling},
    } = useStoreContext();

    return (
      <Wrapper>
        {auth?.user?.workspace?.billing?.plan !== 'free_tier' &&
        !showNonActiveBilling ? (
          <AdminBillingActive />
        ) : (
          <AdminBillingNotActive
            setShowStarterPlanCTA={setShowStarterPlanCTA}
          />
        )}
      </Wrapper>
    );
  },
);
