import {useCallback, useLayoutEffect, useMemo, useReducer} from 'react';
import {date, object, ref} from 'yup';
import {useMounted} from './mounted';
import {useYup} from './yup';

interface Value {
  starts: string;
  ends: string;
}

type InputState = 'error' | 'default' | 'warning' | 'performance';

interface ReducerState {
  starts: string;
  ends: string;
}

export enum ActionType {
  UPDATE_START,
  UPDATE_ENDS,
}

type UpdateStartAction = {
  type: ActionType.UPDATE_START;
  payload: {value: string};
};

type UpdateTargetAction = {
  type: ActionType.UPDATE_ENDS;
  payload: {value: string};
};

type ReducerAction = UpdateStartAction | UpdateTargetAction;

const initialState: ReducerState = {
  ends: '',
  starts: '',
};

const DateRangeValidator = object().shape({
  starts: date()
    .typeError('Invalid value for start date')
    .required('You must provide start date')
    .max(ref('ends'), 'Your start date cannot be greater than end date'),
  ends: date()
    .typeError('Invalid value for end date')
    .required('You must provide start date')
    .min(ref('starts'), 'Your end date cannot be less than start value'),
});

function rangeInputReducer(
  state: ReducerState,
  action: ReducerAction,
): ReducerState {
  switch (action.type) {
    case ActionType.UPDATE_START:
      return {
        ...state,
        starts: action.payload.value,
      };
    case ActionType.UPDATE_ENDS:
      return {
        ...state,
        ends: action.payload.value,
      };
    default:
      return state;
  }
}

export function useDateRangeInput(
  defaultValue?: Value,
  value?: Value,
  setValue?: (value: Value) => void,
  inputState?: InputState,
) {
  const mounted = useMounted();

  const defStart =
    value?.starts.toString() ?? defaultValue?.starts.toString() ?? '';

  const defEnds = value?.ends.toString() ?? defaultValue?.ends.toString() ?? '';

  const [state, dispatch] = useReducer(rangeInputReducer, {
    ...initialState,
    starts: defStart,
    ends: defEnds,
  });
  const {errors, validate, isValid} = useYup(state, DateRangeValidator);

  const updateValue = useCallback(
    ({starts: newStart, ends: newEnds}: {starts?: string; ends?: string}) => {
      const val = {
        starts: newStart ?? state.starts,
        ends: newEnds ?? state.ends,
      };
      validate(val);
      if (setValue) {
        setValue(val);
      }
    },
    [setValue, state.starts, state.ends, validate],
  );

  const onUpdateStart = useCallback(
    (value: string) => {
      dispatch({
        type: ActionType.UPDATE_START,
        payload: {value},
      });
      updateValue({starts: value});
    },
    [updateValue],
  );

  const onUpdateEnds = useCallback(
    (value: string) => {
      dispatch({
        type: ActionType.UPDATE_ENDS,
        payload: {value},
      });
      updateValue({ends: value});
    },
    [updateValue],
  );

  const errorType: InputState = useMemo(() => {
    if (!isValid) {
      return 'warning';
    }

    if (inputState) {
      return inputState;
    }

    return 'default';
  }, [inputState, isValid]);

  useLayoutEffect(() => {
    if (mounted?.current) {
      if (defStart && defEnds) {
        validate();
      }
    }
  }, [defStart, defEnds, mounted, validate]);

  return {
    error: errors.starts || errors.ends,
    starts: state.starts,
    ends: state.ends,
    onUpdateEnds,
    onUpdateStart,
    errorType,
  };
}
