import React from 'react';

export const HamBurgerIcon = () => {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.75 1H17.25M0.75 7H17.25M0.75 13H17.25"
        stroke="#5F5F8C"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
