import {makeAutoObservable, observable, action} from 'mobx';
import {IGroup, IAPIUserFormat} from '../../hooks';

export class GroupStore {
  @observable group: IGroup[] = [];
  @observable groups: IGroup[] = [];
  @observable groupMembers: IAPIUserFormat[] = [];
  @observable groupTypeNameInStore: string = '';
  @observable showGroupDrawer = false;
  @observable isGroup: boolean = false;
  @observable groupTypeAndGroup: any = [];
  @observable allGroups: any = new Map();

  constructor() {
    makeAutoObservable(this);
  }
  @action
  clearGoalsStore = () => {
    this.group = [];
    this.groupMembers = [];
    this.groupTypeNameInStore = '';
    this.isGroup = false;
  };

  @action
  setGroupInStore = (data: any) => {
    this.group = data?.groups;
  };
  @action
  updateGroupDrawer = (data: boolean) => {
    this.showGroupDrawer = data;
  };

  @action setGroup = (data: any) => {
    this.groups = data;
  };
  @action
  setGroupTypeandGroup = (data: any) => {
    this.groupTypeAndGroup = data;
  };

  @action
  setGroupTypeNameInStore = (data: any) => {
    this.groupTypeNameInStore = data?.name;
  };

  @action
  setIsGroup = (state: boolean) => {
    this.isGroup = state;
  };

  @action
  setGroupMembers = (data: any) => {
    if (data && data[0] && data[0].members) {
      this.groupMembers = data[0].members;
    } else {
      this.groupMembers = [];
    }
  };

  @action
  setAllGroups = (data: any, groupType: string) => {
    data.forEach((result: any) => {
      if (!this.allGroups.has(result.id))
        this.allGroups.set(result.id, {...result, groupTypeName: groupType});
    });
  };
}

export const groupStore = new GroupStore();
