import styled from 'styled-components';
import {Styles} from 'react-select';
import {getColorFromTheme} from '../../../ui-utils';
import {theme} from '../../../style';

export const IndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  svg {
    stroke: ${getColorFromTheme('textBody')};
    width: 16px;
    height: 16px;
  }
`;

export const GoalTypeTag = styled.div`
  height: 26px;
  padding: 4px 8px;
  background: #f6f6f8;
  border-radius: 8px;
  font-size: 12px;
  color: #5f5f8c;
  font-weight: 500;
  line-height: 18px;
  width: fit-content;
`;

export const AvatarWrapper = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const RadioWrapper = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const RadioBoardingWrapper = styled.a`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
`;

export const AdditionalOptionsWrapper = styled.div<{display?: boolean}>`
  display: ${(props: any) => (props.display === true ? 'block' : 'none')};
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23585ADFFF' stroke-width='2' stroke-dasharray='14%2c 8' stroke-dashoffset='0' strokeLinecap='round'/%3e%3c/svg%3e");
  border-radius: 10px;
  margin: 8px 0;
  padding: 12px 16px;
`;

export const getStyles = <T, V extends boolean>(): Partial<Styles<T, V>> => ({
  valueContainer: (base) => ({
    ...base,
    color: theme.colors.textBody,
    width: '100%',
    height: '44px',
    paddingLeft: '14px',
    paddingRight: '14px',
  }),
  indicatorsContainer: (base) => ({
    ...base,
    paddingLeft: '2px',
    paddingRight: '2px',
  }),
  menu: (base) => ({
    ...base,
    marginTop: '0px',
    marginBottom: '0px',
    border: 'none',
    borderRadius: 7,
    boxShadow: 'none',
    position: 'relative',
    zIndex: 11,
  }),
  menuList: (base) => ({
    ...base,
    padding: '8px',
  }),
  option: (base, state) => {
    let borderColor = theme.colors.borderDark;
    if (state.isFocused) {
      borderColor = theme.colors.purple300;
    }
    if (state.isSelected) {
      borderColor = theme.colors.purple300;
    }
    return {
      ...base,
      fontSize: '16px',
      backgroundColor: theme.colors.white,
      borderRadius: 6,
      borderStyle: 'solid',
      borderWidth: '1px',
      padding: '8px 16px',
      cursor: 'pointer',
      color: theme.colors.textDark,
      marginBottom: '8px',
      borderColor,
      ':active': {
        borderColor: theme.colors.purple200,
      },
    };
  },
  control: (base, state) => {
    return {
      ...base,
      color: theme.colors.textDark,
      backgroundColor: theme.colors.white,
      transition: '200ms ease all',
      borderColor: theme.colors.borderDark,
      margin: 8,
      boxShadow: 'none',
      '&:hover': state.isFocused
        ? {
            borderColor: theme.colors.purple300,
            boxShadow: `0 0 0 4px ${theme.colors.purple200}`,
          }
        : {
            borderColor: theme.colors.purple200,
            boxShadow: `0 0 0 2px ${theme.colors.purple200}`,
          },
      ...(state.isFocused
        ? {
            borderColor: theme.colors.purple300,
            boxShadow: `0 0 0 4px ${theme.colors.purple200}`,
          }
        : {}),
    };
  },
});
