import {array, object, string} from 'yup';
import {regexTwoMinCharacters} from '../../../constants';
import {passwordRegex, passwordPhrase} from '../../../constants';

export const memberProfileValidator = object().shape({
  firstName: string()
    .required('Fill in your first name')
    .matches(regexTwoMinCharacters, 'Special characters are not allowed'),
  lastName: string()
    .required('Fill in your last name')
    .matches(regexTwoMinCharacters, 'Special characters are not allowed'),
  phoneNumber: string(),
  jobTitle: string().required('Fill in your job title'),
  timezone: string().required('Fill in your time zone'),
  countryCode: string(),
});

export const memberEmailValidator = object().shape({
  email: string().email().required('Fill in your email'),
});

export const memberPasswordValidator = object().shape({
  oldPassword: string()
    .matches(passwordRegex, passwordPhrase)
    .required('Fill in your current password'),
  newPassword: string()
    .matches(passwordRegex, passwordPhrase)
    .required('Fill in your new password'),
});

export const memberReviewerValidator = object().shape({
  reporting: object().shape({
    weekDay: string().required('Fill in Weekdays'),
    schedule: string().required('Fill in schedule'),
  }),
  reviewer: string().required('Fill in Manager'),
});
export const groupsValidator = array();
