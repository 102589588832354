import {memo} from 'react';

export const OffTrackIcon = memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16">
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M14.665 8H12l-2 6-4-12-2 6H1.332"
    />
  </svg>
));
OffTrackIcon.displayName = 'OffTrackIcon';
