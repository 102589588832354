import {AxiosResponse} from 'axios';

import {MemberSignupFormData} from './member-signup-interface';
import {BaseRequest} from '../../configs/request';
import {SignInResponse} from '../sign-in/sign-in-interface';
export interface MemberSignupRequest extends BaseRequest {
  url: string;
  memberSignup(
    formData: MemberSignupFormData,
    token: string,
  ): Promise<SignInResponse>;
}

export class MemberSignupRequestImpl
  extends BaseRequest
  implements MemberSignupRequest {
  url: string = '/auth/user/sign-up';

  async memberSignup<SignInResponse>(
    formData: MemberSignupFormData,
    token: string,
  ) {
    const {data: responseData} = await this.request.post<
      MemberSignupFormData,
      AxiosResponse<SignInResponse>
    >(this.url, {...formData, token});
    return responseData;
  }
}
