import {CSSProperties, memo} from 'react';

export const CompanyIcon = memo(({style}: {style?: CSSProperties}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    style={style}
    viewBox="0 0 16 16">
    <path d="M3.5 3c0-.828.672-1.5 1.5-1.5h6c.828 0 1.5.672 1.5 1.5v11.5h-9V3z" />
    <path
      strokeLinejoin="round"
      d="M7 10.5c-.276 0-.5.224-.5.5v3c0 .276.224.5.5.5h2c.276 0 .5-.224.5-.5v-3c0-.276-.224-.5-.5-.5H7z"
    />
    <path
      strokeLinecap="round"
      d="M13.5 14.5L2.5 14.5M9.5 7.5L6.5 7.5M9.5 4.5L6.5 4.5"
    />
  </svg>
));

CompanyIcon.displayName = 'CompanyIcon';
