import {yupResolver} from '@hookform/resolvers/yup';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {Resolver, useForm} from 'react-hook-form';
import {useStoreContext} from '../../../../../store/store-context';
import {get, onValue, ref} from 'firebase/database';
import {
  FIREBASE_BASE_URL,
  database,
} from '../../../../../utils/firebase-request';
import {useDebounce} from '../../../../../utils/debounce';
import {useQuery} from 'react-query';
import {CompanyCheckinsController} from '../../../company-legacy/company-components/company-checkins/company-checkins.controller';
import {ICurrentCheckinsForm} from '../../../company-legacy/company-components/company-checkins/company-checkins.interface';
import {CompanyCheckinsRequestImpl} from '../../../company-legacy/company-components/company-checkins/company-checkins.request';
import {YouCheckinsValidatorSchema} from './you-checkins.validator';
import {beamMasteryTasks} from '../../../../../utils/firebase-request';
import {useFirebaseFetch} from '@hooks/query-hook';
import {useWorkspaceUseCase} from '@hooks/workspace-usecase';
import {
  dateParamsToday,
  getDayOfWeekIndexFromString,
  getWeekOfYear,
  getMonthFromWeek,
  getWeekRange,
} from '../../../../../utils/date';
import dayjs from 'dayjs';
import {activateNotification} from '../../../../../ui/molecules/notification/activate-notification';
import {usePostHogHook} from '@hooks/post-hog';

export function useYouCheckinsHook() {
  const request = useMemo(() => new CompanyCheckinsRequestImpl(), []);
  const controller = useMemo(() => new CompanyCheckinsController(request), [
    request,
  ]);

  const {isPerformanceActive} = useWorkspaceUseCase();

  const {
    authStore: {setCalendarView, auth},
    usersStore: {currentUser},
    checkinsStore: {
      workspaceCheckins,
      workspaceCheckinsGoals,
      userGoals,
      setUserGoals,
      updateUserGoalsError,
      setWorkspaceCheckins,
      checkinsDates,
      setCheckinsPulse,
      configuration,
      setUpdatedGoalsFirebase,
      updatedGoalsFirebase,
      requiredUserQuestions,
      checkinsPulse,
      updateRequiredCurrentPriorityError,
      setRequiredUserQuestions,
      updateRequiredUserQuestionsError,
      updatePulseValidationError,
      setCheckinsDate,
      calendarWeeks,
    },
  } = useStoreContext();

  ///// Init Checkins
  const checkinsRequest = useMemo(() => new CompanyCheckinsRequestImpl(), []);
  const checkinsController = useMemo(
    () => new CompanyCheckinsController(checkinsRequest),
    [checkinsRequest],
  );

  const initWorkspaceCheckins = useCallback(
    async (filter?: {weekOfYear?: number; year?: number}) => {
      const response = await checkinsController.getCurrentCheckinsInfo(filter);
      response && setWorkspaceCheckins(response as any);

      return response;
    },
    [checkinsController, setWorkspaceCheckins],
  );

  const initPulseCheckins = useCallback(
    async (filter?: {weekOfYear?: number; year?: number}) => {
      const response = await checkinsController.getCurrentPulseInfo(filter);
      response && setCheckinsPulse(response as any);

      return response;
    },
    [checkinsController, setCheckinsPulse],
  );

  /// Init Firebase Checkins Goal Update Status
  const fetchFirebaseCheckinsGoalsData = useCallback(
    (userId: string) => {
      onValue(
        ref(
          database,
          `${FIREBASE_BASE_URL}/${auth.user.workspace.id}/users/${userId}/checkin`,
        ),
        (snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot?.val();

            const specificWeekData: any = Object.values(
              data,
            ).find((item: any) =>
              item.weekOfYear === checkinsDates?.weekOfYear && item?.year
                ? item?.year === checkinsDates?.year
                : true,
            );

            setUpdatedGoalsFirebase(
              !specificWeekData?.weekOfYear
                ? {
                    weekOfYear: checkinsDates.weekOfYear,
                    updatedGoals: specificWeekData,
                  }
                : specificWeekData,
            );
          }
        },
      );
    },
    [
      auth.user.workspace.id,
      checkinsDates?.weekOfYear,
      checkinsDates?.year,
      setUpdatedGoalsFirebase,
    ],
  );

  useEffect(() => {
    if (isPerformanceActive) fetchFirebaseCheckinsGoalsData(currentUser?.id);
  }, [currentUser?.id, fetchFirebaseCheckinsGoalsData, isPerformanceActive]);

  useEffect(() => {
    if (
      requiredUserQuestions.length === 0 &&
      workspaceCheckins?.responses?.questions?.questions &&
      workspaceCheckins?.responses?.questions?.questions?.length > 0
    ) {
      setRequiredUserQuestions(
        new Map(
          workspaceCheckins?.responses?.questions?.questions
            ?.filter((item: any) => item?.optional === false)
            ?.map((item: any) => [item?.questionId, item]),
        ),
      );
    }
  }, [
    setRequiredUserQuestions,
    requiredUserQuestions,
    workspaceCheckins?.responses?.questions?.questions,
  ]);

  const initFilter = useMemo(() => {
    return {
      weekOfYear:
        checkinsDates?.weekOfYear === getWeekOfYear() + 1
          ? calendarWeeks?.[calendarWeeks?.length - 1]?.weekOfYear
          : checkinsDates?.weekOfYear,
      year:
        checkinsDates?.weekOfYear === getWeekOfYear() + 1
          ? calendarWeeks?.[calendarWeeks?.length - 1]?.year
          : checkinsDates?.year,
    };
  }, [checkinsDates.weekOfYear, checkinsDates.year, calendarWeeks]);

  useEffect(() => {
    return () => {
      updatePulseValidationError(null);
    };
  }, [updatePulseValidationError]);

  const {isLoading, data: workspaceData} = useQuery(
    ['initWorkspaceCheckins', initFilter],
    () => initWorkspaceCheckins(initFilter),
    {
      refetchOnWindowFocus: false,
    },
  );

  useQuery(
    ['initWorkspacePulse', initFilter],
    () => initPulseCheckins(initFilter),
    {
      refetchOnWindowFocus: false,
    },
  );

  useMemo(() => {
    if (workspaceData) {
      setWorkspaceCheckins(workspaceData as any);
    }
  }, [workspaceData, setWorkspaceCheckins]);

  useEffect(() => {
    if (calendarWeeks.length > 0) {
      setCheckinsDate({
        starts: dayjs(
          getWeekRange(getWeekOfYear(), new Date().getFullYear()).starts,
        ).format('MM/DD/YYYY'),
        ends: dayjs(
          getWeekRange(getWeekOfYear(), new Date().getFullYear()).ends,
        ).format('MM/DD/YYYY'),
        weekOfYear: calendarWeeks[calendarWeeks.length - 1].weekOfYear,
        month: getMonthFromWeek(
          calendarWeeks[calendarWeeks.length - 1].year,
          calendarWeeks[calendarWeeks.length - 1].weekOfYear,
        ),
        year: calendarWeeks[calendarWeeks.length - 1].year,
      });
    }

    return () => {
      setCalendarView('month');
      setCheckinsDate({
        starts: dayjs(
          getWeekRange(getWeekOfYear(), new Date().getFullYear()).starts,
        ).format('MM/DD/YYYY'),
        ends: dayjs(
          getWeekRange(getWeekOfYear(), new Date().getFullYear()).ends,
        ).format('MM/DD/YYYY'),
        weekOfYear: getWeekOfYear(),
        year: new Date().getFullYear(),
        date: new Date(),
        month: new Date().getMonth(),
      });
    };
  }, [setCalendarView, setCheckinsDate, calendarWeeks]);

  const isEditable = Object.keys(workspaceCheckins).length > 0;

  const parseEditValues = useCallback(() => {
    if (isEditable) {
      return {
        pulse: {
          mood: checkinsPulse?.mood,
          feedback: checkinsPulse?.feedback,
          id: checkinsPulse?.id,
        },
        priorities: {
          currentCheckin:
            workspaceCheckins?.responses?.priorities?.currentCheckin,
          nextCheckin: workspaceCheckins?.responses?.priorities?.nextCheckin,
          id: workspaceCheckins?.responses?.priorities?.id,
        },
        questions: {
          questions: workspaceCheckins?.responses?.questions?.questions,
          id: workspaceCheckins?.responses?.questions?.id,
        },
        feedback: workspaceCheckins?.feedback,
      };
    }
  }, [
    checkinsPulse?.feedback,
    checkinsPulse?.id,
    checkinsPulse?.mood,
    isEditable,
    workspaceCheckins?.feedback,
    workspaceCheckins?.responses?.priorities?.currentCheckin,
    workspaceCheckins?.responses?.priorities?.id,
    workspaceCheckins?.responses?.priorities?.nextCheckin,
    workspaceCheckins?.responses?.questions?.id,
    workspaceCheckins?.responses?.questions?.questions,
  ]);

  const {
    register,
    handleSubmit,
    errors,
    formState,
    setValue,
    control,
    watch,
    clearErrors,
    reset,
  } = useForm<ICurrentCheckinsForm>({
    resolver: yupResolver(YouCheckinsValidatorSchema.youCheckins) as Resolver<
      ICurrentCheckinsForm,
      object
    >,
    mode: 'all',
    defaultValues: {
      pulse: {
        mood: isEditable ? parseEditValues()?.pulse?.mood : null,
        feedback: isEditable ? parseEditValues()?.pulse?.feedback : null,
        id: isEditable ? parseEditValues()?.pulse?.id : '',
      },
      priorities: {
        currentCheckin:
          isEditable && !!parseEditValues()?.priorities?.currentCheckin
            ? parseEditValues()?.priorities?.currentCheckin
            : [],
        nextCheckin:
          isEditable && !!parseEditValues()?.priorities?.nextCheckin
            ? parseEditValues()?.priorities?.nextCheckin
            : [],
        id: isEditable ? parseEditValues()?.priorities?.id : '',
      },
      questions: {
        questions: isEditable ? parseEditValues()?.questions?.questions : [],
        id: isEditable ? parseEditValues()?.questions?.id : '',
      },
      feedback: isEditable ? (parseEditValues()?.feedback as any) : '',
    },
  });

  const isSubmitting = useMemo(() => {
    return formState.isSubmitting;
  }, [formState]);

  const hasError = useMemo(() => {
    return formState.submitCount > 1 && formState.errors;
  }, [formState.errors, formState.submitCount]);

  const handleFormValueChange = useCallback(
    (value: any, name: string) => {
      setValue(name, value);
    },
    [setValue],
  );

  useEffect(() => {
    if (
      userGoals?.length === 0 &&
      workspaceCheckinsGoals?.length > 0 &&
      isPerformanceActive
    ) {
      setUserGoals(
        new Map(
          workspaceCheckinsGoals
            .filter(
              (item: any) =>
                item.assignee.id === currentUser?.id && !item.isKpi,
            )
            .map((item: any) => [
              item.id,
              {
                updated:
                  updatedGoalsFirebase?.updatedGoals?.includes(item?.id) &&
                  updatedGoalsFirebase?.weekOfYear === checkinsDates?.weekOfYear
                    ? true
                    : false,
                error: '',
              },
            ]),
        ),
      );
    }
  }, [
    workspaceCheckinsGoals,
    currentUser?.id,
    setUserGoals,
    updatedGoalsFirebase?.updatedGoals,
    isPerformanceActive,
    userGoals?.length,
    updatedGoalsFirebase?.weekOfYear,
    checkinsDates?.weekOfYear,
  ]);

  const [isReadOnly, setIsReadOnly] = useState(false);

  const allowsLateSubmission = configuration?.allowLateSubmission;

  useEffect(() => {
    if (
      workspaceCheckins?.year === new Date().getFullYear() &&
      workspaceCheckins?.weekOfYear === getWeekOfYear()
    ) {
      if (allowsLateSubmission) return;

      if (
        dateParamsToday().dayOfWeekIndex <=
        getDayOfWeekIndexFromString(workspaceCheckins?.dueDay)
      ) {
        setIsReadOnly(!!workspaceCheckins?.isSubmitted);
      } else {
        setIsReadOnly(true);
      }
    } else {
      setIsReadOnly(true);
    }
  }, [
    workspaceCheckins?.dueDay,
    workspaceCheckins.isReviewed,
    allowsLateSubmission,
    workspaceCheckins?.isSubmitted,
    workspaceCheckins?.weekOfYear,
    workspaceCheckins?.year,
  ]);

  const {data: priorityData} = useFirebaseFetch(
    `checkins/${process.env.REACT_APP_DEV_ENV}/${auth.user.id}/yr-${checkinsDates?.year}/wk-${checkinsDates?.weekOfYear}`,
  );

  const {postHogCapture} = usePostHogHook();

  const submitForm = useCallback(
    async (data: any) => {
      const getArray = (source: any) => {
        return source
          ? Object.values(source).map((value: any) => {
              return {
                ...value.content,
                key: value.key || value?.content?.key,
              };
            })
          : null;
      };

      const getPriority = getArray(priorityData);

      const currentCheckin = getPriority || data?.priorities?.currentCheckin;

      if (
        (workspaceCheckins?.responses?.goals &&
          isPerformanceActive &&
          Array.from(userGoals).some(
            (goal: any) => goal?.[1].updated === false,
          )) ||
        (workspaceCheckins?.responses?.questions !== null &&
          Array.from(requiredUserQuestions).some(
            (q: any) => !q?.[1].response,
          )) ||
        (workspaceCheckins?.responses?.pulse !== null &&
          !(data?.pulse?.mood?.length > 1)) ||
        currentCheckin?.some(
          (checkin: {completed: boolean}) => !checkin.completed,
        )
      ) {
        activateNotification({
          title: 'Error',
          content: 'Kindly complete required fields',
          kind: 'error',
        });

        if (
          Array.from(userGoals).some((goal: any) => !goal?.[1].updated) &&
          isPerformanceActive
        ) {
          Array.from(userGoals).map((goal: any) =>
            !goal?.[1].updated ? updateUserGoalsError(goal[0]) : goal,
          );
        }
        if (
          Array.from(requiredUserQuestions).some(
            (q: any) => q?.[1].response?.length < 1 || !q?.[1].response,
          )
        ) {
          Array.from(requiredUserQuestions).map((q: any) =>
            q?.[1].response?.length > 0
              ? q
              : updateRequiredUserQuestionsError(q[0]),
          );
        }
        if (
          currentCheckin?.some(
            (checkin: {completed: boolean}) => !checkin.completed,
          )
        ) {
          currentCheckin.map((checkin: {key: boolean; completed: boolean}) =>
            !checkin.completed
              ? updateRequiredCurrentPriorityError(checkin['key'], true)
              : updateRequiredCurrentPriorityError(checkin['key'], false),
          );
        }

        if (!(data?.pulse?.mood?.length > 1)) {
          updatePulseValidationError('Kindly select a pulse');
        }
      } else {
        setIsReadOnly(true);
        Array.from(requiredUserQuestions).map((q: any) =>
          q?.[1].response ? q : updateRequiredUserQuestionsError(q[0], true),
        );
        if (
          !Array.from(requiredUserQuestions).some(
            (q: any) => !q?.[1].response,
          ) ||
          requiredUserQuestions.size === 0
        ) {
          await controller.submitSpecificCheckin(
            {},
            workspaceCheckins.id as string,
            workspaceCheckins?.isSubmitted ? 'update' : 'submit',
          );
          if (auth.user.role === 'user' && !auth.user.isReviewer) {
            beamMasteryTasks('complete-checkin', true);
          }

          postHogCapture('frontend - Checkins submission');

          get(ref(database, `onboarding/${currentUser?.id}/step/checkins`))
            .then((snapshot: any) => {
              const val = snapshot?.val();

              if (val !== true) {
                postHogCapture(
                  'frontend_onboarding - Checkins submitted (2 of 3)',
                );
              }
            })
            .catch((error) => {
              console.error('Error getting onboarding checkins step:', error);
            });
        }
      }
    },
    [
      workspaceCheckins?.responses?.goals,
      workspaceCheckins?.responses?.questions,
      postHogCapture,
      priorityData,
      auth,
      isPerformanceActive,
      updateRequiredCurrentPriorityError,
      workspaceCheckins?.responses?.pulse,
      workspaceCheckins.id,
      workspaceCheckins?.isSubmitted,
      userGoals,
      requiredUserQuestions,
      updateUserGoalsError,
      updateRequiredUserQuestionsError,
      updatePulseValidationError,
      controller,
      currentUser?.id,
    ],
  );

  const insertDefaultCheckinsData = useCallback(async () => {
    reset({
      pulse: {
        mood: checkinsPulse?.mood,
        feedback: checkinsPulse?.feedback,
        id: checkinsPulse?.id,
      },
      priorities: {
        currentCheckin: !!workspaceCheckins?.responses?.priorities
          ?.currentCheckin
          ? workspaceCheckins?.responses?.priorities?.currentCheckin
          : [],
        nextCheckin: !!workspaceCheckins?.responses?.priorities?.nextCheckin
          ? workspaceCheckins?.responses?.priorities?.nextCheckin
          : [],
        id: workspaceCheckins?.responses?.priorities?.id,
      },
      questions: {
        questions: workspaceCheckins?.responses?.questions?.questions,
        id: workspaceCheckins?.responses?.questions?.id,
      },
      feedback: !!parseEditValues()?.feedback
        ? (parseEditValues()?.feedback as any)
        : '',
    });
  }, [
    checkinsPulse?.feedback,
    checkinsPulse?.id,
    checkinsPulse?.mood,
    parseEditValues,
    reset,
    workspaceCheckins?.responses?.priorities?.currentCheckin,
    workspaceCheckins?.responses?.priorities?.id,
    workspaceCheckins?.responses?.priorities?.nextCheckin,
    workspaceCheckins?.responses?.questions?.id,
    workspaceCheckins?.responses?.questions?.questions,
  ]);

  useEffect(() => {
    insertDefaultCheckinsData();
  }, [insertDefaultCheckinsData]);

  ////
  const updatePulse = useCallback(
    async (data: {
      pulse: {mood: string; feedback: string};
      pulseId: string;
    }) => {
      await controller.updateCheckinsPulseResponse(
        {
          feedback:
            data?.pulse?.feedback !== null && data?.pulse?.feedback !== ''
              ? data.pulse.feedback
              : undefined,
          mood: data.pulse.mood !== null ? data.pulse.mood : undefined,
        },
        data.pulseId,
      );
    },
    [controller],
  );
  const debounceUpdatePulse = useDebounce(updatePulse, 500);

  const updatePriorities = useCallback(
    async (data: {
      priorities: {
        currentCheckin?: {
          text?: string;
          completed?: boolean;
        }[];
        nextCheckin?: {
          text?: string;
          completed?: boolean;
        }[];
      };
      prioritiesId: string;
    }) => {
      await controller.updateCheckinsPrioritiesResponse(
        {
          currentCheckin: data.priorities?.currentCheckin?.filter(
            (checkin: {text?: string; completed?: boolean}) =>
              checkin?.text !== '',
          ),
          nextCheckin: data.priorities?.nextCheckin
            ?.filter(
              (checkin: {text?: string; completed?: boolean}) =>
                checkin?.text !== '',
            )
            .map((checkin: {text?: string; completed?: boolean}) => ({
              text: checkin?.text,
              completed: checkin?.completed,
            })),
        },
        data.prioritiesId,
      );
    },
    [controller],
  );

  const debounceUpdatePriorities = useDebounce(updatePriorities, 2000);

  const updateQuestions = useCallback(
    async (data: {
      questions: {
        questions: {
          question: string;
          response?: null | string;
          target: {type: string; ids: string[]};
        }[];
      };
      questionId: string;
    }) => {
      await controller.updateCheckinsQuestionsResponse(
        {
          questions: data.questions.questions.map(
            (question: {
              question: string;
              response?: null | string;
              target: {type: string; ids: string[]};
            }) => ({
              ...question,
              _id: undefined,
              error: undefined,

              response:
                question.response === null ? undefined : question.response,
            }),
          ),
        },
        data.questionId,
      );
    },
    [controller],
  );

  const debounceUpdateQuestions = useDebounce(updateQuestions, 1000);

  return {
    register,
    handleSubmit,
    errors,
    formState,
    setValue,
    control,
    watch,
    clearErrors,
    reset,
    isSubmitting,
    allowsLateSubmission,
    hasError,
    handleFormValueChange,
    submitForm,
    updatePulse,
    debounceUpdatePulse,
    debounceUpdatePriorities,
    updatePriorities,
    debounceUpdateQuestions,
    loadingPage: isLoading,
    setIsReadOnly,
    isReadOnly,
    userGoals,
    setUserGoals,
  };
}
