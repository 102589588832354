import {
  forwardRef,
  ReactElement,
  Ref,
  useCallback,
  useState,
  useEffect,
} from 'react';
import ReactSelect, {ValueType, ActionMeta} from 'react-select';
import {useDropDown} from '../../../../hooks';
import {motion} from 'framer-motion';
import {Label} from '../../../atoms/label';
import {FieldWrapper} from '../../../atoms/field-wrapper';

import {Helper} from '../../../atoms/helper';

import {
  Wrapper,
  DropdownWrapper,
  DropWrapper,
  DropdownHandler,
  HandlerWrapper,
  ArrowIconWrapper,
} from './styled';
import {ArrowHeadDownIcon} from '../../../atoms/icons/arrow-head-down';
import {VerticalSpacer} from '../../../atoms/spacer';
import {Body2, Body1} from '../../../atoms/typography';

import {InputState} from '../../../interface';
import {OptionSelect} from '../option-select';
import {SelectFieldProps, TOption} from '../option-select/option-select';

export interface TUserSelectFieldProps extends SelectFieldProps {
  label?: string;
  placeholder?: string;
  state?: InputState;
  helper?: string | ReactElement;
  optionsSelectType?: string;
}

export const DateSelectField = forwardRef(
  (
    {
      label,
      state,
      helper,
      placeholder,
      options,
      onChange,
      inputStyle,
      value,
      margin,
      defaultValue,
      fieldNotFoundPlaceHolder,
      optionsSelectType,
      ...rest
    }: SelectFieldProps,
    ref: Ref<ReactSelect<TOption>>,
  ) => {
    const getValue = options.find(
      // @ts-ignore
      (option) => option.value === defaultValue || option.value === value,
    );

    const [innerValue, setInnerValue] = useState<ValueType<TOption, false>>(
      // @ts-ignore
      () => getValue,
    );

    useEffect(() => {
      if (value) {
        // @ts-ignore
        setInnerValue(() => getValue);
      }
    }, [getValue, value]);

    const {handleOpen, ref: dropRef, open, handleClose} = useDropDown();

    const onSelectChange = useCallback(
      (value: ValueType<TOption, false>, action: ActionMeta<TOption>) => {
        if (onChange) {
          onChange(value, action);
        }
        setInnerValue(value);
        handleClose();
      },
      [handleClose, onChange],
    );

    return (
      <FieldWrapper margin={margin}>
        <Wrapper>
          {label && (
            <>
              <Label aria-label="label">{label}</Label>
              <VerticalSpacer size="8px" />
            </>
          )}
          <DropWrapper ref={dropRef}>
            <DropdownHandler
              open={open}
              onClick={handleOpen}
              style={inputStyle}>
              <HandlerWrapper>
                {innerValue ? (
                  <>
                    <Body2>{(innerValue as TOption).label}</Body2>
                  </>
                ) : (
                  <Body1 kind="textBody">{placeholder}</Body1>
                )}

                <ArrowIconWrapper>
                  <motion.span
                    initial={{rotate: 0}}
                    animate={{
                      rotate: open ? 180 : 0,
                      marginTop: open ? '-6px' : '0px',
                    }}
                    transition={{
                      duration: 0.3,
                    }}
                    key="user-select-toggled">
                    <ArrowHeadDownIcon />
                  </motion.span>
                </ArrowIconWrapper>
              </HandlerWrapper>
            </DropdownHandler>
            {open && (
              <DropdownWrapper style={{zIndex: 99999}}>
                <OptionSelect
                  {...rest}
                  onChange={onSelectChange}
                  value={innerValue}
                  defaultValue={defaultValue}
                  ref={ref as any}
                  options={options}
                  fieldNotFoundPlaceHolder={fieldNotFoundPlaceHolder}
                  optionsSelectType={optionsSelectType}
                />
              </DropdownWrapper>
            )}
          </DropWrapper>
          <Helper aria-label="helper" state={state} children={helper} />
        </Wrapper>
      </FieldWrapper>
    );
  },
);
