import {MeetingBotResponse} from '@hooks';
import {useActionItems} from '@hooks/features-action-items';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {useStoreContext} from '@store/store-context';
import {authStore} from '@store/stores/auth-store';
import {MoreVerticalIcon} from '@ui/atoms/icons';
import {DropdownVertical} from '@ui/molecules/dropdown-vertical';
import {activateNotification} from '@ui/molecules/notification/activate-notification';
import {ActionItems} from '@ui/organisms/action-items/action-items';
import {writeFirebaseData} from '@utils/firebase-handler';
import {userName} from '@utils/user-name';
import {useState} from 'react';
import nextId from 'react-id-generator';
import {SectionMenu} from '@ui/layouts/section-card/section-toggle';
import dayjs from 'dayjs';

interface MeetingActionItemsProps {
  _actionItems: MeetingBotResponse['actionItems'];
  meetingId: string;
  participants: string[];
  updateMeetingResponse: (actionItems: {text: string}[]) => void;
}

export const MeetingAiActionItems = ({
  _actionItems,
  updateMeetingResponse,
  meetingId,
  participants,
}: MeetingActionItemsProps) => {
  const {
    usersStore: {users},
  } = useStoreContext();

  const {tasks} = useActionItems({
    source: 'meeting',
    userId: '',
    itemId: meetingId,
    orderBy: {
      value: meetingId,
      key: 'sourceId',
    },
  });

  const texts = tasks.map((task) => task.text);

  const [actionItems, setActionItems] = useState(
    _actionItems.map((_agenda) => ({
      text: _agenda.text,
      completed: false,
      dueDate: '',
      key: nextId(),
      assignee: '',
    })),
  );

  const formatActionItems = actionItems
    .filter((item) => !texts.includes(item.text))
    .map((actionItem) => ({
      ...actionItem,
      assignee:
        typeof actionItem.assignee === 'string'
          ? users.find((user) => user.id === actionItem.assignee)
          : actionItem.assignee,
    }));

  return (
    <div>
      <SectionMenu
        title="Action items"
        count={formatActionItems.length}
        disabled={!formatActionItems.length}
        action={
          <DropdownVertical
            disabled={!formatActionItems.length}
            customIcon={
              <MoreVerticalIcon
                style={{stroke: !formatActionItems.length ? '#bfbfd4' : ''}}
              />
            }
            collapseOnClick
            dropdownWrapperStyle={{right: 0}}
            menu={(handleClose: () => void) => (
              <div>
                <DropdownItem
                  onClick={() => {
                    handleClose();
                    actionItems.forEach((item) => {
                      writeFirebaseData(`action_items`, {
                        text: item.text,
                        completed: item.completed,
                        dueDate: item.dueDate || null,
                        assignee:
                          typeof item.assignee === 'string'
                            ? item.assignee
                            : (item.assignee as any)?.id || null,
                        updatedBy: authStore.auth.user.id,
                        createdBy: authStore.auth.user.id,
                        source: 'meeting',
                        sourceId: meetingId,
                        section: '',
                        createdAt: dayjs().format(),
                        user: authStore.auth.user.id,
                      });
                    });

                    setActionItems([]);
                    updateMeetingResponse([]);

                    activateNotification({
                      title: 'Success',
                      content: 'Added to note succesfully',
                      kind: 'success',
                    });
                  }}>
                  Add all to notes
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    updateMeetingResponse([]);
                    setActionItems([]);
                    handleClose();
                  }}>
                  Delete all
                </DropdownItem>
              </div>
            )}
          />
        }>
        <div className="py-6 pr-5">
          <ActionItems
            handleCustomAdd={(item) => {
              delete item.key;

              const updatedItems = actionItems.filter(
                (actionItem) => actionItem.text !== item.text,
              );

              setActionItems(updatedItems);

              writeFirebaseData(`action_items`, {
                ...item,
                assignee:
                  typeof item.assignee === 'string'
                    ? item.assignee
                    : item.assignee?.id || null,
                updatedBy: authStore.auth.user.id,
                key: nextId(),
                createdBy: authStore.auth.user.id,
                source: 'meeting',
                sourceId: meetingId,
                section: '',
                createdAt: dayjs().format(),
                user: authStore.auth.user.id,
              }).then(() => {
                updateMeetingResponse(updatedItems);
              });

              activateNotification({
                title: 'Success',
                content: 'Added to note succesfully',
                kind: 'success',
              });
            }}
            value={formatActionItems}
            removeAddToList
            users={users
              .filter((user) => participants.includes(user.id))
              .map((user) => ({
                label: userName(user),
                value: user.id,
                avatar: user.avatar,
              }))}
            excludeCheckBox
            handleChange={async (value) => {
              setActionItems(value as any);
              updateMeetingResponse(value as any);
            }}
            source="meeting"
            sourceId={meetingId}
          />
        </div>
      </SectionMenu>
    </div>
  );
};
