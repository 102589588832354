import {ComponentPropsWithoutRef, forwardRef, Ref} from 'react';
import styled, {css} from 'styled-components';
import {motion} from 'framer-motion';
import {ifProp} from 'styled-tools';
import {useIsChecked} from '../../../hooks';
import {Body2} from '../typography/body2';
import {getColorFromTheme} from '../../ui-utils';

export interface BinaryToggleProps extends ComponentPropsWithoutRef<'input'> {
  id: string;
  name?: string;
  title?: string;
}

const Wrapper = styled.label`
  cursor: pointer;
  position: relative;
  background: transparent;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  -moz-appearance: none;

  input {
    top: 0;
    left: 0;
    width: 100%;
    cursor: inherit;
    height: 100%;
    margin: 0;
    opacity: 0;
    padding: 0;
    z-index: 5;
    position: absolute;

    &:checked,
    &:focus,
    &:active {
      > ${Body2} {
        color: ${getColorFromTheme('textDark')};
      }
    }
  }

  &:hover {
    ${Body2} {
      color: ${getColorFromTheme('textDark')};
    }
  }
`;

const Content = styled.span<{checked?: boolean}>`
  display: inline-flex;
  padding: 6px 12px;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;

  ${Body2} {
    color: ${getColorFromTheme('textBody')};
    transition: all 300ms ease-in-out;
    z-index: 2;
  }

  ${ifProp(
    'checked',
    css`
      ${Body2} {
        color: ${getColorFromTheme('textDark')};
      }
    `,
  )}
`;

const Background = styled(motion.span)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${getColorFromTheme('white')};
  z-index: 1;
  border-radius: 7px;
  width: 100%;
  height: 100%;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px ${getColorFromTheme('borderLight')};
`;

export const BinaryToggle = forwardRef(
  ({title, ...props}: BinaryToggleProps, ref: Ref<HTMLInputElement>) => {
    const checked = useIsChecked(props);

    return (
      <Wrapper htmlFor={props.id}>
        <input {...props} type="radio" name={props.name} ref={ref} />
        <Content checked={checked}>
          <Body2>{title}</Body2>
          {checked && (
            <Background
              layoutId="binary-toggle-radio"
              initial={true}
              transition={{
                duration: 0.3,
                layoutX: {duration: 0.3},
                layoutY: {delay: 0, duration: 0},
              }}
            />
          )}
        </Content>
      </Wrapper>
    );
  },
);

BinaryToggle.displayName = 'BinaryToggle';
