import {useMemo, useState, useCallback, useEffect} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm, Resolver} from 'react-hook-form';
import {useLocation, useNavigate} from 'react-router';
import {
  MemberProfileFormData,
  MemberEmailData,
  MemberPasswordData,
  MemberReviewerData,
  GroupsData,
} from './member-profile-interface';
import {useQuery} from 'react-query';
import {
  memberProfileValidator,
  memberEmailValidator,
  memberPasswordValidator,
  memberReviewerValidator,
  groupsValidator,
} from './member-profile-validator';
import {MemberProfileController} from './member-profile-controller';
import {MemberProfileRequestImpl} from './member-profile-request';
import {useStoreContext} from '../../../store/store-context';
import {resizeFile} from '../../../utils/compress-images';
import {groupRequestImpl} from '../admin/admin-components/admin-people/admin-group/admin-group-request';
import {groupController} from '../admin/admin-components/admin-people/admin-group/admin-group-controller';
import {UserAPIRequestImpl} from '../../../api/user-api/user-api-request';
import {UserAPIController} from '../../../api/user-api/user-api-controller';
import {AnalyticsRequestImpl} from '../reporting/reporting-pages/admin-analytics/admin-analytics.request';
import {AnalyticsController} from '../reporting/reporting-pages/admin-analytics/admin-analytics.controller';
import {generateRandomDigits} from '@utils/generate-random-numbers';
import {setFirebaseData} from '@utils/firebase-handler';

export function useMemberProfileHook() {
  const {
    usersStore: {
      currentUserGroups,
      setCurrentUserGroups,
      currentUser,
      uiUsersFormat,
      patchCurrentUser,
      user,
    },
    analyticsStore: {setFilterGroup, filterGroup: group},
    authStore: {setInitResponseAsAuth, auth, setInitResponse},
  } = useStoreContext();
  const {
    register,
    handleSubmit,
    errors,
    formState,
    setValue,
    control,
    watch,
  } = useForm<MemberProfileFormData>({
    resolver: yupResolver(memberProfileValidator) as Resolver<
      MemberProfileFormData,
      object
    >,
    mode: 'onSubmit',
  });

  const {
    register: emailRegister,
    errors: emailErrors,
    formState: emailFormState,
    handleSubmit: emailHandleSubmit,
    control: emailControl,
  } = useForm<MemberEmailData>({
    resolver: yupResolver(memberEmailValidator) as Resolver<
      MemberEmailData,
      object
    >,
    mode: 'onSubmit',
  });

  const {
    register: passwordRegister,
    errors: passwordErrors,
    formState: passwordFormState,
    handleSubmit: passwordHandleSubmit,
    watch: watchPassword,
  } = useForm<MemberPasswordData>({
    resolver: yupResolver(memberPasswordValidator) as Resolver<
      MemberPasswordData,
      object
    >,
    mode: 'onSubmit',
    defaultValues: {
      oldPassword: '',
      newPassword: '',
    },
  });

  const {
    register: reviewerRegister,
    errors: reviewerErrors,
    formState: reviewerFormState,
    handleSubmit: reviewerHandleSubmit,
    control: reviewerControl,
    setValue: setValueReviewer,
    setValue: setValueWeekDay,
    setValue: setValueSchedule,
  } = useForm<MemberReviewerData>({
    resolver: yupResolver(memberReviewerValidator) as Resolver<
      MemberReviewerData,
      object
    >,
    mode: 'onSubmit',
  });

  const {
    control: groupsControl,
    handleSubmit: groupsHandleSubmit,
  } = useForm<GroupsData>({
    resolver: yupResolver(groupsValidator) as Resolver<GroupsData, object>,
    mode: 'onSubmit',
  });

  const isSubmitting = useMemo(() => {
    return formState.isSubmitting;
  }, [formState]);

  const isSubmittingEmail = useMemo(() => {
    return emailFormState.isSubmitting;
  }, [emailFormState]);

  const isSubmittingPassword = useMemo(() => {
    return passwordFormState.isSubmitting;
  }, [passwordFormState]);

  const isSubmittingReviewer = useMemo(() => {
    return reviewerFormState.isSubmitting;
  }, [reviewerFormState]);

  const hasError = useMemo(() => {
    return formState.submitCount > 0 && formState.errors;
  }, [formState.errors, formState.submitCount]);

  const handleSelectChange = (value: string) => {
    setValue('timezone', value);
  };

  const handleSelectChangeGroups = (data: {value: string; label: string}[]) => {
    setValue('groups', data);
  };
  const handleSetPrefix = (prefix: string) => {
    setValue('countryCode', prefix);
  };

  const handleSelectChangeReviewer = (value: string) => {
    setValueReviewer('reviewer', value);
  };

  const handleSelectChangeWeekday = (weekDay: string) => {
    setValueWeekDay('reporting.weekDay', weekDay);
  };
  const handleSelectChangeSchedule = (schedule: string) => {
    setValueSchedule('reporting.schedule', schedule);
  };

  // Initialize groups
  const fetchGroups = useCallback(async () => {
    const request = new AnalyticsRequestImpl();
    const controller = new AnalyticsController(request);
    const groupsResponse = await controller.fetchGroups();
    setFilterGroup(
      groupsResponse &&
        // eslint-disable-next-line array-callback-return
        groupsResponse.results.map((group: any) => {
          if (group) {
            return {
              label:
                group.name === 'General'
                  ? `General (${group.groupType?.name})`
                  : group.name,
              value: group.id,
            };
          }
        }),
    );
  }, [setFilterGroup]);
  useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  const request = new MemberProfileRequestImpl();
  const controller = new MemberProfileController(request);
  const groupsRequest = new groupRequestImpl();
  const groupsController = new groupController(groupsRequest);
  const navigate = useNavigate();
  const {search} = useLocation();
  const accessToken = search.slice(search.indexOf('=') + 1);

  const submitForm = async (data: MemberProfileFormData) => {
    const {groups, ...profileData} = data;
    if (groups) {
      const defaultData = currentUserGroups.map((grp: any) => grp.id);
      const selectedData = groups.map((grp: any) => grp.value);
      const toBeRemoved = defaultData.filter(
        (grp: any) => !selectedData.includes(grp),
      );
      const toBeAdded = selectedData.filter(
        (grp: any) => !defaultData.includes(grp),
      );

      if (toBeAdded.length > 0) {
        const addedData = groups.map((grp: any) =>
          toBeAdded.includes(grp.value),
        );
        setCurrentUserGroups([...currentUserGroups, ...addedData]);
        await Promise.all(
          toBeAdded.map(async (group: any) => {
            const dt = {
              add: {
                users: [currentUser?.id ? currentUser?.id : auth?.user?.id],
                type: 'add',
              },
            };
            await groupsController.newAddGroupMember(dt, group);
          }),
        );
      }
      if (toBeRemoved.length > 0) {
        setCurrentUserGroups(
          currentUserGroups.filter((grp: any) => !toBeRemoved.includes(grp.id)),
        );
        await Promise.all(
          toBeRemoved.map(async (group: any) => {
            const dt = {
              remove: {
                users: [currentUser?.id ? currentUser?.id : auth?.user?.id],
              },
            };
            await groupsController.newAddGroupMember(dt, group);
          }),
        );
      }
    }
    const response = await controller.newMemberProfileForm(
      profileData,
      accessToken,
    );

    response && setInitResponseAsAuth();
    response && setFirebaseData(`updates/user`, generateRandomDigits(16));

    response && patchCurrentUser(response);
    response && navigate(-1);
  };

  const submitEmail = async (data: MemberEmailData) => {
    await controller.newMemberEmail(data, accessToken);
    setInitResponseAsAuth();

    navigate('/edit');
  };

  const submitPassword = async (data: MemberPasswordData) => {
    await controller.newMemberPassword(data, accessToken);
    setInitResponseAsAuth();

    navigate('/edit');
  };

  const submitReviewer = async (data: MemberReviewerData) => {
    await controller.newMemberReviewer(data, accessToken);

    const storedAuthObject = JSON.parse(localStorage.auth);

    storedAuthObject.user.reviewer = data?.reviewer;
    localStorage.setItem('auth', JSON.stringify(storedAuthObject));

    setInitResponse(storedAuthObject);
    setInitResponseAsAuth();

    navigate('/edit');
  };

  const submitGroups = async (data: GroupsData | undefined) => {
    if (data) {
      await Promise.all(
        data.groups.map(async (group: any) => {
          const dt = {
            id: group.value,
            user: currentUser?.id,
          };
          await groupsController.newAddGroupMember(dt, group.value);
        }),
      );
    }
  };

  const [isDisabled, setIsDisabled] = useState(true);
  const {
    firstName,
    lastName,
    phoneNumber,
    jobTitle,
    timezone,
    groups,
    countryCode,
  } = watch();

  const [isDisabledPassword, setIsDisabledPassword] = useState(true);
  const {oldPassword, newPassword} = watchPassword();
  const [image, setImage] = useState({preview: '', raw: '', disable: false});
  const [base64Upload, setbase64Upload] = useState<any | null>(null);
  const handleChange = async (e: any) => {
    const file = e.target.files[0];
    const base64 = await resizeFile(file);
    setbase64Upload(base64);
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
        disable: true,
      });
    }
  };

  useMemo(() => {
    if (!!user) {
      (firstName && firstName !== user.firstName) ||
      (timezone && timezone !== user.timezone) ||
      (lastName && lastName !== user.lastName) ||
      (jobTitle && jobTitle !== user.jobTitle) ||
      (countryCode && countryCode !== user?.countryCode) ||
      (phoneNumber && phoneNumber !== user.phoneNumber) ||
      (groups && JSON.stringify(groups) !== JSON.stringify(currentUserGroups))
        ? setIsDisabled(false)
        : setIsDisabled(true);
    }
  }, [
    countryCode,
    currentUserGroups,
    firstName,
    groups,
    jobTitle,
    lastName,
    phoneNumber,
    timezone,
    user,
  ]);

  useMemo(() => {
    oldPassword || newPassword
      ? setIsDisabledPassword(false)
      : setIsDisabledPassword(true);
  }, [oldPassword, newPassword]);

  const fetchCurrentUser = useCallback(async () => {
    const request = new MemberProfileRequestImpl();
    const controller = new MemberProfileController(request);

    await controller.newfetchCurrentUser();
  }, []);

  const {isLoading: isFetchingCurrentUser} = useQuery('fetchCurrentUser', () =>
    fetchCurrentUser(),
  );

  const fetchCurrentUserGroups = useCallback(async () => {
    const request = new UserAPIRequestImpl();
    const controller = new UserAPIController(request);
    const response: any = await controller.fetchCurrentUserGroups();

    response && setCurrentUserGroups(response);
  }, [setCurrentUserGroups]);
  useEffect(() => {
    fetchCurrentUserGroups();
  }, [fetchCurrentUserGroups]);

  // Fetch all groups

  return {
    register,
    errors,
    isSubmitting,
    isSubmittingEmail,
    isSubmittingPassword,
    isSubmittingReviewer,
    submitForm,
    submitEmail,
    submitPassword,
    submitReviewer,
    handleSelectChange,
    handleSelectChangeGroups,
    handleSelectChangeReviewer,
    handleSelectChangeWeekday,
    handleSelectChangeSchedule,
    handleSubmit,
    control,
    emailControl,
    reviewerControl,
    image,
    base64Upload,
    handleChange,
    hasError,
    isDisabled,
    isDisabledPassword,
    emailRegister,
    passwordRegister,
    reviewerRegister,
    emailErrors,
    passwordErrors,
    reviewerErrors,
    emailHandleSubmit,
    passwordHandleSubmit,
    reviewerHandleSubmit,
    isFetchingCurrentUser,
    currentUser,
    submitGroups,
    users: uiUsersFormat.map((user: any) => {
      if (
        user &&
        user.label &&
        user.label.name &&
        user.label.name.includes('@')
      ) {
        return {
          ...user,
          label: {
            ...user.label,
            name: `${user.label.name} (pending invitation)`,
          },
        };
      } else {
        return user;
      }
    }),
    groupsControl,
    groupsHandleSubmit,
    group,
    currentUserGroups,
    handleSetPrefix,
  };
}
