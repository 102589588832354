import {BaseRequest} from '../configs/request';

interface ILogoutRequest extends BaseRequest {
  logout(refrestToken: string): Promise<any>;
}

export class LogoutRequest extends BaseRequest implements ILogoutRequest {
  url: string = '/auth/logout';

  async logout(refreshToken: string) {
    await this.request.post<string, any>(this.url, {refreshToken});
  }
}
