import {useCallback} from 'react';
import {WorkspaceAPIController} from '../api/workspaces-api/workspaces-api-controller';
import {WorkspaceAPIRequestImpl} from '../api/workspaces-api/workspaces-api-request';
import {set} from 'idb-keyval';
import {UserAPIController} from '../api/user-api/user-api-controller';
import {UserAPIRequestImpl} from '../api/user-api/user-api-request';
import {groupController} from '@pages/dashboard/admin/admin-components/admin-people/admin-group/admin-group-controller';
import {groupRequestImpl} from '@pages/dashboard/admin/admin-components/admin-people/admin-group/admin-group-request';
import {groupTypeController} from '@pages/dashboard/admin/admin-components/admin-people/admin-group-type/admin-group-type-controller';
import {useStoreContext} from '@store/store-context';
import {MemberProfileController} from '@pages/dashboard/member-profile/member-profile-controller';
import {MemberProfileRequestImpl} from '@pages/dashboard/member-profile/member-profile-request';
import {groupTypeRequestImpl} from '@pages/dashboard/admin/admin-components/admin-people/admin-group-type/admin-group-type-request';

export const useApiCalls = () => {
  const {
    groupTypeStore: {setGroupTypeInStore},
    usersStore: {setUsersData},
    groupStore: {setGroup},

    authStore: {auth, setInitResponseAsAuth, setInitResponse},
  } = useStoreContext();

  const initGroupType = useCallback(async () => {
    const grpTypeRequest = new groupTypeRequestImpl();
    const grpTypeController = new groupTypeController(grpTypeRequest);

    const grpTypeResponse: any = await grpTypeController.newfetchGroupType();
    grpTypeResponse && setGroupTypeInStore(grpTypeResponse);
    grpTypeResponse &&
      set(`${auth.user.workspace.id}/groupsType`, grpTypeResponse);

    return grpTypeResponse;
  }, [setGroupTypeInStore, auth.user.workspace.id]);

  const initGroups = useCallback(async () => {
    const grpRequest = new groupRequestImpl();
    const grpController = new groupController(grpRequest);
    const grpResponses: any = await grpController.fetchGroups();
    const excludeGeneralGroup = grpResponses?.filter(
      (group: {name: string}) => group.name !== 'General',
    );

    grpResponses &&
      set(`${auth.user.workspace.id}/groups`, excludeGeneralGroup);
    setGroup(excludeGeneralGroup);
    return excludeGeneralGroup;
  }, [auth.user.workspace.id, setGroup]);

  const initGroupMembers = useCallback(async () => {
    const grpRequest = new groupRequestImpl();
    const grpController = new groupController(grpRequest);

    const grpResponsesMembers: any = await grpController.fetchGroupsMembers();

    const res = new Map();

    grpResponsesMembers.forEach((result: any) => {
      if (res.has(result.user)) {
        res.set(result.user, [...res.get(result.user), result]);
      } else res.set(result.user, [result]);
    });

    return res;
  }, []);

  const initUsers = useCallback(async () => {
    const request = new UserAPIRequestImpl();
    const controller = new UserAPIController(request);
    const response = await controller.fetchAllUsers();

    if (response) {
      const getMembersGroups = await initGroupMembers();
      const includeUserGroups = response.map((user: any) => {
        const userGroup = getMembersGroups.get(user.id);
        return {
          ...user,
          groups: userGroup,
        };
      });
      const invitedUserResponse = await controller.fetchInvitedUsers();

      invitedUserResponse &&
        set(`${auth.user.workspace.id}/invited-users`, invitedUserResponse);

      response && set(`${auth.user.workspace.id}/users`, includeUserGroups);

      response && invitedUserResponse
        ? setUsersData([...includeUserGroups, ...invitedUserResponse])
        : setUsersData(includeUserGroups);
    }
    return response;
  }, [auth.user.workspace.id, setUsersData, initGroupMembers]);

  const fetchWorkspaceData = useCallback(async () => {
    const request = new WorkspaceAPIRequestImpl();
    const controller = new WorkspaceAPIController(request);
    const response = await controller.fetchWorkspaceData(
      auth.user.workspace.id,
    );

    if (response) {
      const storedAuthObject = JSON.parse(localStorage.auth);
      storedAuthObject.user.workspace = response;
      localStorage.setItem('auth', JSON.stringify(storedAuthObject));

      setInitResponse(storedAuthObject);

      setInitResponseAsAuth();
    }
  }, [auth.user.workspace.id, setInitResponse, setInitResponseAsAuth]);

  const fetchUserData = useCallback(async () => {
    const request = new MemberProfileRequestImpl();
    const controller = new MemberProfileController(request);

    const response = await controller.newfetchCurrentUser();

    if (response) {
      const storedAuthObject = JSON.parse(localStorage.auth);
      const workspace = storedAuthObject.user.workspace;

      storedAuthObject.user = response;
      storedAuthObject.user.workspace = workspace;

      localStorage.setItem('auth', JSON.stringify(storedAuthObject));

      setInitResponse(storedAuthObject);

      setInitResponseAsAuth();
    }
  }, [setInitResponse, setInitResponseAsAuth]);

  return {
    initGroupType,
    initGroups,
    fetchWorkspaceData,
    initGroupMembers,
    fetchUserData,
    initUsers,
  };
};
