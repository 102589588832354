import React from 'react';

export const PowerPointVector = () => {
  return (
    <svg
      width="849"
      height="406"
      viewBox="0 0 849 406"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M509.4 57.9999C509.4 25.9675 482.249 0 448.756 0H400.243C366.751 0 339.6 25.9675 339.6 57.9999V69.9412C339.6 121.614 274.279 147.491 236.076 110.953L227.248 102.51C203.565 79.8594 165.168 79.8594 141.485 102.51L107.181 135.319C83.4983 157.97 83.4983 194.693 107.181 217.344L116.009 225.788C154.212 262.326 127.156 324.8 73.1286 324.8H60.6428C27.1507 324.8 0 350.767 0 382.8V429.199C0 461.232 27.1507 487.2 60.6428 487.2H73.1282C127.156 487.2 154.212 549.675 116.009 586.211L107.181 594.656C83.4983 617.307 83.4983 654.029 107.181 676.68L141.485 709.489C165.168 732.14 203.565 732.14 227.248 709.489L236.076 701.044C274.279 664.508 339.6 690.387 339.6 742.058V753.999C339.6 786.032 366.751 812 400.243 812H448.756C482.249 812 509.4 786.032 509.4 753.999V742.058C509.4 690.387 574.722 664.508 612.923 701.044L621.752 709.489C645.435 732.14 683.831 732.14 707.514 709.489L741.818 676.68C765.501 654.029 765.501 617.307 741.818 594.656L732.993 586.211C694.788 549.675 721.845 487.2 775.871 487.2H788.356C821.849 487.2 849 461.232 849 429.199V382.8C849 350.767 821.849 324.8 788.356 324.8H775.871C721.845 324.8 694.788 262.326 732.988 225.788L741.818 217.344C765.501 194.693 765.501 157.97 741.818 135.319L707.514 102.51C683.831 79.859 645.435 79.859 621.752 102.51L612.923 110.953C574.722 147.491 509.4 121.614 509.4 69.9412V57.9999Z"
        fill="white"
      />
    </svg>
  );
};
