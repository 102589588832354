import {memo} from 'react';

interface ISheetsIcon {
  size?: string;
}
export const SheetsIcon = memo(({size}: ISheetsIcon) => (
  <>
    {size === 'md' ? (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.3335 1.3335C6.22893 1.3335 5.3335 2.22893 5.3335 3.3335V28.6668C5.3335 29.7714 6.22893 30.6668 7.3335 30.6668H24.6668C25.7714 30.6668 26.6668 29.7714 26.6668 28.6668V9.3335L18.6668 1.3335H7.3335Z"
          fill="#20A464"
        />
        <path
          d="M7.3335 30.6668C6.22893 30.6668 5.3335 29.7714 5.3335 28.6668V28.3335C5.3335 29.4381 6.22893 30.3335 7.3335 30.3335H24.6668C25.7714 30.3335 26.6668 29.4381 26.6668 28.3335V28.6668C26.6668 29.7714 25.7714 30.6668 24.6668 30.6668H7.3335Z"
          fill="#149456"
        />
        <path
          d="M7.3335 1.3335C6.22893 1.3335 5.3335 2.22893 5.3335 3.3335V3.66683C5.3335 2.56226 6.22893 1.66683 7.3335 1.66683H19.0002L18.6668 1.3335H7.3335Z"
          fill="#38AE74"
        />
        <path
          d="M26.6665 16.1665V9.33317L25.4998 8.1665H19.8736C19.4281 8.1665 19.205 8.70507 19.52 9.02006L26.6665 16.1665Z"
          fill="url(#paint0_linear_12336_55805)"
        />
        <path
          d="M18.6665 1.3335L26.6665 9.3335H20.6665C19.5619 9.3335 18.6665 8.43806 18.6665 7.3335V1.3335Z"
          fill="#8ED1B1"
        />
        <path
          fillRule="evenodd"
          clip-rule="evenodd"
          d="M21.3332 15.6665V25.3332H10.6665V15.6665H21.3332ZM15.3332 16.9998H11.9998V18.6665H15.3332V16.9998ZM16.6665 18.6665V16.9998H19.9998V18.6665H16.6665ZM15.3332 19.6665H11.9998V21.3332H15.3332V19.6665ZM16.6665 21.3332V19.6665H19.9998V21.3332H16.6665ZM15.3332 22.3332H11.9998V23.9998H15.3332V22.3332ZM16.6665 23.9998V22.3332H19.9998V23.9998H16.6665Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_12336_55805"
            x1="23.0195"
            y1="16.1665"
            x2="23.0195"
            y2="7.99984"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#20A464" />
            <stop offset="1" stop-color="#207E55" />
          </linearGradient>
        </defs>
      </svg>
    ) : (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.5835 0.833496C3.89314 0.833496 3.3335 1.39314 3.3335 2.0835V17.9168C3.3335 18.6072 3.89314 19.1668 4.5835 19.1668H15.4168C16.1072 19.1668 16.6668 18.6072 16.6668 17.9168V5.8335L11.6668 0.833496H4.5835Z"
          fill="#20A464"
        />
        <path
          d="M4.5835 19.1668C3.89314 19.1668 3.3335 18.6072 3.3335 17.9168V17.7085C3.3335 18.3989 3.89314 18.9585 4.5835 18.9585H15.4168C16.1072 18.9585 16.6668 18.3989 16.6668 17.7085V17.9168C16.6668 18.6072 16.1072 19.1668 15.4168 19.1668H4.5835Z"
          fill="#149456"
        />
        <path
          d="M4.5835 0.833496C3.89314 0.833496 3.3335 1.39314 3.3335 2.0835V2.29183C3.3335 1.60147 3.89314 1.04183 4.5835 1.04183H11.8752L11.6668 0.833496H4.5835Z"
          fill="#38AE74"
        />
        <path
          d="M16.6666 10.104V5.83317L15.9374 5.104H12.421C12.1426 5.104 12.0032 5.44061 12.2001 5.63747L16.6666 10.104Z"
          fill="url(#paint0_linear_12259_57660)"
        />
        <path
          d="M11.6665 0.833496L16.6665 5.8335H12.9165C12.2261 5.8335 11.6665 5.27385 11.6665 4.5835V0.833496Z"
          fill="#8ED1B1"
        />
        <path
          fillRule="evenodd"
          clip-rule="evenodd"
          d="M13.3332 9.7915V15.8332H6.6665V9.7915H13.3332ZM9.58317 10.6248H7.49984V11.6665H9.58317V10.6248ZM10.4165 11.6665V10.6248H12.4998V11.6665H10.4165ZM9.58317 12.2915H7.49984V13.3332H9.58317V12.2915ZM10.4165 13.3332V12.2915H12.4998V13.3332H10.4165ZM9.58317 13.9582H7.49984V14.9998H9.58317V13.9582ZM10.4165 14.9998V13.9582H12.4998V14.9998H10.4165Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_12259_57660"
            x1="14.3873"
            y1="10.104"
            x2="14.3873"
            y2="4.99984"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#20A464" />
            <stop offset="1" stop-color="#207E55" />
          </linearGradient>
        </defs>
      </svg>
    )}
  </>
));

SheetsIcon.displayName = 'SheetsIcon';
