import UserDateRangeFilter from '@pages/dashboard/goals/objective-filter';
import {useMeetingReportingHook} from './meeting-hook';
import {Body1, Body2, CTA, Headline3} from '@ui/atoms/typography';
import {UserListCard} from '@ui/molecules/user/list-card';
import {useQuery} from 'react-query';
import {EmptyPlaceholder} from '@ui/atoms/empty-placeholder';
import {ItemLoader} from '@ui/organisms/item-loader';
import {cloudinary} from '@assets/images/cloudinary';
import {
  Overview,
  Wrapper,
  TableContainer,
  ItemWrapper,
  ItemWrapperContainer,
  LoadingWrapper,
  SecondRowWrapper,
  HeadlineWrapper,
  HeadlineWrapperContainer,
} from './styles';
import {FC, useMemo} from 'react';
const {emptyGoals} = cloudinary;

type Option = {label: string; value: string};

type Meeting = {
  meetingType: string;
  count: string;
};

interface AnalyticsMeetingProps {
  selectedGroups: Option[];
  selectedTeams: Option[];
  selectedMembers: Option[];
  breakdownBy: string;
  meetingFilter: Meeting;
}

export const AnalyticsMeeting: FC<AnalyticsMeetingProps> = ({
  selectedGroups,
  selectedMembers,
  selectedTeams,
  breakdownBy,
  meetingFilter,
}) => {
  const selectedGroupsId = selectedGroups.map((group) => group.value);

  const selectedTeamsId = selectedTeams.map((team) => team.value);

  const selectedMembersId = selectedMembers.map((member) => member.value);

  const computeFilters = useMemo(() => {
    return {
      groupBy: breakdownBy,
      sortByCount:
        meetingFilter.meetingType && meetingFilter.count
          ? `${meetingFilter.meetingType}:${meetingFilter.count}`
          : '',
      manager: selectedTeamsId.length ? selectedTeamsId.join(',') : '',
      user: selectedMembersId.length ? selectedMembersId.join(',') : '',
      group: selectedGroupsId.length ? selectedGroupsId.join(',') : '',
    };
  }, [
    breakdownBy,
    meetingFilter.count,
    meetingFilter.meetingType,
    selectedGroupsId,
    selectedMembersId,
    selectedTeamsId,
  ]);

  const {
    dateRange,
    setDateRange,
    filters,
    getMeetingReporting,
  } = useMeetingReportingHook(computeFilters);

  const {data, isLoading} = useQuery(['one-on-one-reporting', filters], () =>
    getMeetingReporting(filters),
  );

  const emptyPlaceholder = {
    image: {
      alt: 'alt text',
      shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
      src: emptyGoals,
      style: {height: '30%', width: '30%'},
    },
    imports: true,
    subtitle: 'Refine your search or try something new.',
    title: 'No results found',
  };

  const cardOptions = [
    {
      title: 'Concluded meetings',
      value: data?.completedMeetings || '-',
    },
    {
      title: 'Missed meetings',
      value: data?.missedMeetings || '-',
    },
  ];

  const breakdownIsGroup = breakdownBy === 'group';
  const breakdownIsTeam = breakdownBy === 'team';

  return (
    <Wrapper className="sm:mt-4 mt-0">
      <div className="body">
        <Overview>
          <div className="header flex sm:flex-col flex-row  justify-between sm:items-start items-center border-b pb-4  border-b-[#ededf2] mb-4">
            <Body2 weight="bold">Overview</Body2>
            <div>
              <UserDateRangeFilter
                showDateRange
                removeUserFilter
                dateRange={dateRange}
                _height="36px"
                canFilterAll
                setDateRange={setDateRange}
              />
            </div>
          </div>

          {isLoading && (
            <LoadingWrapper className="my-10">
              <ItemLoader />
            </LoadingWrapper>
          )}

          {!isLoading && (
            <div className="cards">
              {cardOptions.map((card) => (
                <div className="card">
                  <Body1 weight="bold">{card.title}</Body1>
                  <div className="number">
                    {' '}
                    <Headline3>{card.value}</Headline3>{' '}
                  </div>
                </div>
              ))}
            </div>
          )}
        </Overview>
        <div className="table">
          <div className="table-desc">
            <Body2 weight="semibold">Summary ({data?.users.length})</Body2>
          </div>
          <TableContainer>
            <div style={{display: 'flex', overflowX: 'scroll'}}>
              <SecondRowWrapper>
                <HeadlineWrapperContainer className="header">
                  <HeadlineWrapper>
                    <Body2 weight="semibold">Name</Body2>
                    <Body2 weight="semibold">
                      {' '}
                      {breakdownIsGroup ? 'Group' : ''} Manager
                    </Body2>
                    <Body2 weight="semibold">Concluded</Body2>
                    <Body2 weight="semibold">Missed</Body2>
                  </HeadlineWrapper>
                </HeadlineWrapperContainer>
                {isLoading && (
                  <LoadingWrapper className="min-h-[200px]">
                    <ItemLoader />
                  </LoadingWrapper>
                )}
                {!isLoading && (
                  <>
                    {data?.users?.length ? (
                      data?.users.map((user: any, index: number) => (
                        <ItemWrapperContainer key={index}>
                          <ItemWrapper>
                            <div>
                              {breakdownIsGroup || breakdownIsTeam ? (
                                <Body2 weight="semibold">
                                  {user.firstName}
                                </Body2>
                              ) : (
                                <UserListCard
                                  tooltip={false}
                                  userId={user?.id}
                                  type="secondary"
                                  reviewer={''}
                                  avatar={user?.avatar?.url}
                                  name={
                                    user.firstName + ' ' + user?.lastName || ''
                                  }
                                  TextComponent={CTA}
                                  textKind="textDark"
                                  tooltipText
                                />
                              )}
                            </div>
                            <div>
                              {breakdownIsGroup ? (
                                <UserListCard
                                  tooltip={false}
                                  userId={user?.groupAdmin.id}
                                  type="secondary"
                                  reviewer={''}
                                  avatar={user?.groupAdmin?.avatar?.url}
                                  name={
                                    user.groupAdmin.firstName +
                                      ' ' +
                                      user?.groupAdmin.lastName || ''
                                  }
                                  TextComponent={CTA}
                                  textKind="textDark"
                                  tooltipText
                                />
                              ) : (
                                <UserListCard
                                  tooltip={false}
                                  userId={user?.manager.id}
                                  type="secondary"
                                  reviewer={''}
                                  avatar={user?.manager.avatar?.url}
                                  name={
                                    user.manager.firstName +
                                      ' ' +
                                      user?.manager.lastName || ''
                                  }
                                  TextComponent={CTA}
                                  textKind="textDark"
                                  tooltipText
                                />
                              )}
                            </div>
                            <div>
                              <Body1>{user.completedMeetings}</Body1>
                            </div>

                            <div>
                              <Body1>{user.missedMeetings}</Body1>
                            </div>
                          </ItemWrapper>
                        </ItemWrapperContainer>
                      ))
                    ) : (
                      <EmptyPlaceholder {...emptyPlaceholder} />
                    )}
                  </>
                )}
              </SecondRowWrapper>
            </div>
          </TableContainer>
        </div>
      </div>
    </Wrapper>
  );
};
