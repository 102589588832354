import {Button} from '../../../../../../ui/atoms/button';
import {CancelIcon} from '../../../../../../ui/atoms/icons';
import {LockIcon} from '../../../../../../ui/atoms/icons/lock';
import {VerticalSpacer} from '../../../../../../ui/atoms/spacer';
import {Body2, Headline2} from '../../../../../../ui/atoms/typography';
import {BackAction} from '../../../../../../ui/molecules/back-action';
import {DropdownVerticalSecondary} from '../../../../../../ui/molecules/dropdown-vertical';
import {TextField} from '../../../../../../ui/molecules/field/textfield';
import {HorizontalDots} from '@ui/atoms/icons/horizontal-dots';
import {Modal} from '../../../../../../ui/molecules/modal';
import {SearchBox} from '../../../../../../ui/molecules/search-box';
import {ItemLoaderLine} from '../../../../../../ui/organisms/item-loader-line';
import {capitalize} from '../../../../../../utils';
import {useAdminGroupHook} from './admin-group-hooks';
import {
  DropdownItem,
  FirstWrapper,
  ModalWrapper,
  SecondWrapper,
  TableHeader,
  TableHeaderChild,
  ModalCard,
  LoaderWrapper,
  PrimaryWrapper,
} from './admin-group-style';
import {useStoreContext} from '../../../../../../store/store-context';
import {Controller} from 'react-hook-form';
import {useWorkspaceUseCase} from '@hooks/workspace-usecase';
import {UserSelectField} from '../../../../../../ui/molecules/select/user';
import {MultiUserSelectField} from '../../../../../../ui/molecules/select/multi-user';
import {TLabel} from '../../../../../../ui/molecules/select/user-multi-select/user-multi-select';
import {useMemo} from 'react';

export const AdminGroupPage = () => {
  const {
    hideCreateGroupModal,
    showCreateGroupModal,
    hideEditGroupModal,
    showEditGroupModal,
    hideDeleteGroupModal,
    showDeleteGroupModal,
    register,
    errors,
    isSubmittingCreateGroup,
    handleSubmit,
    submitCreateGroup,
    disabledCreateGroup,
    disabledEditGroup,
    isFetchingGroup,
    groupData,
    groupName,
    setGroupName,
    setGroupId,
    submitEditGroup,
    submitDeleteGroup,
    handleDelete,
    isSubmittingDeleteGroup,
    setSearchGroup,
    searchGroup,
    control,
    selectGroupAdmin,
    groupAdminId,
    setGroupAdminId,
    selectUsers,
    isFetchingGroupMembers,
  } = useAdminGroupHook();
  const {isPerformanceActive} = useWorkspaceUseCase();
  const {
    groupTypeStore: {groupTypeIdInStore},
    usersStore: {users},
    groupStore: {groupTypeNameInStore, group, groupMembers},
    authStore: {featureLimitStatus},
  } = useStoreContext();

  const reachedLimit =
    featureLimitStatus?.limited &&
    featureLimitStatus?.currentStatus?.groupsCount >=
      featureLimitStatus?.limits?.groups;

  const membersInStore = useMemo(() => {
    return users.map((member) => {
      return {
        label: {
          avatar: {
            name:
              member && member.firstName
                ? member.firstName + ' ' + member.lastName
                : member.email + ' (pending invitation)',
            src: member.avatar && member.avatar.url ? member.avatar.url : '',
          },
          id: member.id,
          name:
            member && member.firstName
              ? member.firstName + ' ' + member.lastName
              : member.email + ' (pending invitation)',
        },
        value: member.id,
      };
    });
  }, [users]);
  const groupMembersId = groupMembers.map((mem: any) => mem.id);

  return (
    <>
      <>
        <PrimaryWrapper>
          <Headline2 kind="textDark" weight="semibold">
            {groupTypeNameInStore && `${groupTypeNameInStore}`}
          </Headline2>

          <Button
            kind="primary"
            width="sm"
            style={{padding: '12px 24px'}}
            onClick={() => {
              showCreateGroupModal(true);
              setGroupAdminId('');
            }}
            disabled={reachedLimit}>
            Create group
          </Button>
        </PrimaryWrapper>
        <Modal
          open={hideCreateGroupModal}
          onClose={() => showCreateGroupModal(false)}>
          <ModalWrapper>
            <BackAction
              icon={<CancelIcon />}
              title="Close"
              onClick={() => showCreateGroupModal(false)}
              width="md"
            />
            <VerticalSpacer size="16px" />
            <ModalCard>
              <form method="post" onSubmit={handleSubmit(submitCreateGroup)}>
                <Headline2>New group</Headline2>

                <VerticalSpacer size="20px" />
                <TextField
                  label="What's the name of this group?"
                  placeholder="Enter group name here"
                  ref={register}
                  helper={errors.name?.message || ''}
                  state={!!errors.name ? 'error' : 'default'}
                  name="name"
                />
                <Controller
                  control={control}
                  name="groupType"
                  defaultValue={groupTypeIdInStore}
                  render={({onBlur, value}) => <></>}
                />
                <Controller
                  control={control}
                  name="groupAdmin"
                  render={({onBlur, value}) => (
                    <UserSelectField
                      onBlur={onBlur}
                      name="groupAdmin"
                      value={value}
                      helper={errors.groupAdmin?.message || ''}
                      state={!!errors.groupAdmin ? 'error' : 'default'}
                      onChange={(data: {value: string}) =>
                        selectGroupAdmin(data.value)
                      }
                      label="Group Admin"
                      placeholder="Select member"
                      options={membersInStore}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="users"
                  render={({onBlur, value}) => (
                    <MultiUserSelectField
                      onBlur={onBlur}
                      name="users"
                      value={value}
                      onChange={(data: {value: string; label: TLabel}[]) => {
                        selectUsers(
                          data.map(
                            (obj: {value: string; label: TLabel}) => obj.value,
                          ),
                        );
                      }}
                      helper={errors.users?.message || ''}
                      state={!!errors.users ? 'error' : 'default'}
                      label="Add team members"
                      placeholder="Select member"
                      options={membersInStore}
                    />
                  )}
                />
                <VerticalSpacer size="14px" />

                <Button
                  kind="primary"
                  width="full"
                  disabled={disabledCreateGroup}
                  data-form-action={true}
                  aria-busy={isSubmittingCreateGroup}
                  isLoading={isSubmittingCreateGroup}>
                  Create
                </Button>
              </form>
            </ModalCard>
          </ModalWrapper>
        </Modal>
        <Modal
          open={hideEditGroupModal}
          onClose={() => showEditGroupModal(false)}>
          <ModalWrapper>
            <BackAction
              icon={<CancelIcon />}
              title="Close"
              onClick={() => showEditGroupModal(false)}
              width="md"
            />
            <VerticalSpacer size="16px" />
            <ModalCard>
              <form method="patch" onSubmit={handleSubmit(submitEditGroup)}>
                <Headline2>Edit group</Headline2>

                <VerticalSpacer size="20px" />
                <TextField
                  label="What's the name of this group type?"
                  defaultValue={groupName}
                  ref={register}
                  helper={errors.name?.message || ''}
                  state={!!errors.name ? 'error' : 'default'}
                  name="name"
                />
                <Controller
                  control={control}
                  name="groupType"
                  render={({onBlur, value}) => <></>}
                />
                <Controller
                  control={control}
                  name="groupAdmin"
                  defaultValue={groupAdminId}
                  render={({onBlur, value}) => (
                    <UserSelectField
                      onBlur={onBlur}
                      name="groupAdmin"
                      value={value}
                      helper={errors.groupAdmin?.message || ''}
                      state={!!errors.groupAdmin ? 'error' : 'default'}
                      onChange={(data: {value: string}) =>
                        selectGroupAdmin(data.value)
                      }
                      label="Group Admin"
                      placeholder="Select member"
                      options={membersInStore}
                    />
                  )}
                />
                {isFetchingGroupMembers ? (
                  <ItemLoaderLine />
                ) : (
                  <Controller
                    control={control}
                    name="users"
                    render={({
                      onBlur,
                      value,
                      onChange,
                    }: {
                      onBlur: () => void;
                      value: Array<string>;
                      onChange: (...event: any[]) => void;
                    }) => (
                      <MultiUserSelectField
                        onBlur={onBlur}
                        defaultValue={groupMembersId}
                        name="users"
                        value={value}
                        onChange={(data: {value: string; label: TLabel}[]) => {
                          selectUsers(
                            data.map(
                              (obj: {value: string; label: TLabel}) =>
                                obj.value,
                            ),
                          );
                        }}
                        loading={isFetchingGroupMembers}
                        helper={errors.users?.message || ''}
                        state={!!errors.users ? 'error' : 'default'}
                        label="Add team members"
                        placeholder="Select member"
                        options={membersInStore}
                      />
                    )}
                  />
                )}
                <VerticalSpacer size="14px" />

                <Button
                  kind="primary"
                  width="full"
                  disabled={disabledEditGroup}
                  data-form-action={true}
                  aria-busy={isSubmittingCreateGroup}
                  isLoading={isSubmittingCreateGroup}>
                  Save
                </Button>
              </form>
            </ModalCard>
          </ModalWrapper>
        </Modal>
        <Modal
          open={hideDeleteGroupModal}
          onClose={() => showDeleteGroupModal(false)}>
          <ModalWrapper>
            <BackAction
              icon={<CancelIcon />}
              title="Close"
              onClick={() => showDeleteGroupModal(false)}
              width="md"
            />
            <VerticalSpacer size="16px" />
            <ModalCard>
              <form method="delete" onSubmit={handleDelete(submitDeleteGroup)}>
                <FirstWrapper>
                  <Headline2>Delete group?</Headline2>
                </FirstWrapper>
                <VerticalSpacer size="8px" />
                <Body2 kind="textBody">
                  Are you sure you want to delete{' '}
                  <strong style={{color: '#1e1e2f'}}>{groupName}</strong> from
                  your list of groups?
                </Body2>
                <VerticalSpacer size="30px" />
                <Button
                  kind="primary"
                  width="full"
                  data-form-action={true}
                  aria-busy={isSubmittingDeleteGroup}
                  isLoading={isSubmittingDeleteGroup}>
                  Delete
                </Button>
              </form>
            </ModalCard>
          </ModalWrapper>
        </Modal>
        <SecondWrapper>
          <div className="searchBox">
            <SearchBox
              placeholder="Search by group"
              state="performance"
              onChange={(e) => setSearchGroup(e.target.value.toLowerCase())}
            />
          </div>
          <div className="groupsCount">
            <Body2 kind="textBody">
              Showing <strong>{groupData.length}</strong> of{' '}
              <strong>{group.length}</strong> groups
            </Body2>
          </div>

          <div className="table-container">
            <TableHeader>
              <Body2 weight="semibold">Group(s)</Body2>

              <Body2 weight="semibold">
                {' '}
                {isPerformanceActive ? 'Goals' : ''}
              </Body2>

              <Body2 weight="semibold">Members</Body2>
              <Body2 weight="semibold">Action</Body2>
            </TableHeader>
            {isFetchingGroup && (
              <LoaderWrapper>
                <ItemLoaderLine />
              </LoaderWrapper>
            )}
            {!isFetchingGroup && groupData.length === 0 && (
              <LoaderWrapper>
                <Body2 kind="textDark" align="center">
                  There are no results for group <strong>{searchGroup}</strong>
                </Body2>
              </LoaderWrapper>
            )}
            {!isFetchingGroup &&
              groupData.map((group: any, index: number) => (
                <TableHeaderChild key={index}>
                  <Body2 kind="textDark">
                    {group.isDefault && <LockIcon />} {capitalize(group.name)}
                  </Body2>
                  <Body2 kind="textDark">
                    {isPerformanceActive ? group.goalsCount : ''}
                  </Body2>
                  <Body2 kind="textDark">{group.membersCount}</Body2>
                  {!group.isDefault ? (
                    <DropdownVerticalSecondary
                      customIcon={<HorizontalDots />}
                      menu={
                        <div>
                          <DropdownItem
                            onClick={() => {
                              showEditGroupModal(true);
                              setGroupName(group.name);
                              setGroupId(group.id);
                              setGroupAdminId(group.groupAdmin);
                            }}>
                            Edit group
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              showDeleteGroupModal(true);
                              setGroupName(group.name);
                              setGroupId(group.id);
                            }}>
                            Delete group
                          </DropdownItem>
                        </div>
                      }
                    />
                  ) : (
                    <div></div>
                  )}
                </TableHeaderChild>
              ))}
          </div>
        </SecondWrapper>
      </>
    </>
  );
};
