import {Body1, Body2} from '@ui/atoms/typography';
import {FC, PropsWithChildren, memo, useMemo, useState} from 'react';
import {SecondaryNavigation} from '../nav/navigation';
import {FlexRowCenter} from '@ui/style/styles';
import {ItemLoader} from '@ui/organisms/item-loader';
import {PlainButton} from '@ui/atoms/plain-button/plain-button';

type NavType = 'you' | 'team' | 'company';
export interface AnalyticsSectionNavProps {
  showNav?: boolean;
  showTeam?: boolean;
  updateActiveTab?: (value: NavType) => void;
  showCompany?: boolean;
  isLoading?: boolean;
  activeTab?: 'you' | 'team' | 'company';
  handleContinue?: () => void;
  removeFooter?: boolean;
  viewFooterText?: string;
}

interface AnalyticsSectionProps
  extends PropsWithChildren<{
    title: string;
  }> {
  removeFooter?: boolean;
  showNav?: boolean;
  showTeam?: boolean;
  showCompany?: boolean;
  updateActiveTab?: (value: NavType) => void;
  isLoading?: boolean;
  handleContinue?: () => void;
  viewFooterText?: string;
}

export const AnalyticsSection: FC<AnalyticsSectionProps> = memo(
  ({
    title,
    removeFooter,
    showNav,
    showTeam,
    showCompany,
    isLoading,
    viewFooterText,
    handleContinue,
    updateActiveTab,
    children,
  }) => {
    const [selectedNav, setSelectedNav] = useState<NavType>('you');

    const navigations = useMemo(() => {
      const nav = [
        {
          active: selectedNav === 'you',
          href: '#',
          id: '2',
          onClick: () => {
            setSelectedNav('you');
            updateActiveTab && updateActiveTab('you');
          },
          title: 'You',
        },
      ];
      if (showTeam) {
        nav.push({
          active: selectedNav === 'team',
          href: '#',
          id: '2',
          onClick: () => {
            setSelectedNav('team');
            updateActiveTab && updateActiveTab('team');
          },
          title: 'Your Team',
        });
      }
      if (showCompany) {
        nav.push({
          active: selectedNav === 'company',
          href: '#',
          id: '2',
          onClick: () => {
            setSelectedNav('company');
            updateActiveTab && updateActiveTab('company');
          },
          title: 'Company-wide',
        });
      }
      return nav;
    }, [selectedNav, showCompany, showTeam, updateActiveTab]);

    return (
      <div className="bg-white p-4 border border-borderLight rounded-[10px]">
        <div className="flex justify-between items-center ">
          <Body1 weight="bold">{title}</Body1>
          {showNav && (
            <SecondaryNavigation
              kind="details"
              navStyle={{
                width: 'max-content',
                padding: '4px 8px',
                height: '38px',
              }}
              navigations={navigations}
            />
          )}
        </div>
        <div className="border-t border-t-[#ededf2] mt-4 pt-3">
          {isLoading ? (
            <FlexRowCenter className="h-[200px]">
              <ItemLoader />
            </FlexRowCenter>
          ) : (
            children
          )}
        </div>
        {!removeFooter && (
          <div className="flex justify-between items-center mt-3">
            <Body2 kind="textBody">This quarter</Body2>

            <PlainButton onClick={handleContinue}>
              <Body2 kind="purple300" weight="bold" className="cursor">
                View {viewFooterText}
              </Body2>
            </PlainButton>
          </div>
        )}
      </div>
    );
  },
);
