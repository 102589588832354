import {useStoreContext} from '@store/store-context';
import {useState, useCallback, useEffect} from 'react';
import {useDebounce} from '@utils/debounce';
import {Body2} from '@ui/atoms/typography';
import {BillingType} from '@hooks';
import {AddMentions, AddSubscribers} from '@utils/firebase-request';
import {getFromBetween} from '@utils/get-between';
import {CancelIcon} from '@ui/atoms/icons';
import {ItemLoader} from '@ui/organisms/item-loader';
import styled from 'styled-components';
import {GoalsController} from '../../../../../../../api/goals-api/goals-controller';
import {GoalsRequestImpl} from '../../../../../../../api/goals-api/goals-request';
import {observer} from 'mobx-react-lite';
import {ActionItems} from '@ui/organisms/action-items/action-items';
import {userName} from '@utils/user-name';
import {useFirebaseFetch} from '@hooks/query-hook';
import {useActionItems} from '@hooks/features-action-items';

const ItemLoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const AdditionalOptionsWrapper = styled.div<{display?: boolean}>`
  display: ${(props: any) => (props.display ? 'block' : 'none')};
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='rgba(213, 42, 42, 1)' stroke-width='2' stroke-dasharray='14%2c 8' stroke-dashoffset='0' strokeLinecap='round'/%3e%3c/svg%3e");
  border-radius: 10px;
  margin: 8px 0;
  width: 100%;

  padding: 2px 2px;
  .wrapper {
    background: #fef6f6;
    width: 100%;
    display: flex;
    padding: 12px;
    height: 100%;
    justify-content: space-between;
    text-align: left;
    align-items: flex-start;
    .wrapper-div {
      padding-left: 24px;
      span {
        color: #661515;
        font-weight: 600;
        text-decoration: underline;
        white-space: no-wrap;
      }
    }

    .cancel-div {
      padding-right: 24px;
    }
  }
`;

const Wrapper = styled.div`
  margin-top: 24px;
`;
export const useGoalTasksHook = (
  goalId: string,
  mentions: any[],
  defaultGoalSubscribers: any[],
  allGoalSubscribers: any[],
) => {
  const [loading, setLoading] = useState(true);
  const {
    authStore: {auth},
    usersStore: {users},
  } = useStoreContext();

  const goalPath = `action-items/${process.env.REACT_APP_DEV_ENV}/${goalId}/tasks`;

  const {data: goalFirebaseTasks} = useFirebaseFetch(goalPath);

  const [tasks, setTasks] = useState([
    {
      text: '',
      completed: false,
    },
  ]);

  const fetchActivities = useCallback(async () => {
    const request = new GoalsRequestImpl();
    const controller = new GoalsController(request);

    const data = await controller.fetchGoalTasksById(goalId);

    if (data && data?.tasks.length > 0) {
      const computeTasks = data.tasks.map((task: {_id: string}) => {
        return {
          ...task,
          user: goalFirebaseTasks?.[task?._id]
            ? users.find(
                (user) => user.id === goalFirebaseTasks?.[task?._id]?.user,
              )
            : undefined,
        };
      });

      setTasks(computeTasks);
    }

    setLoading(false);
  }, [goalFirebaseTasks, goalId, users]);

  useEffect(() => {
    if (goalId) {
      fetchActivities();
    }
  }, [fetchActivities, goalId]);

  const [getMentions] = useState(mentions);
  const [getGoalSubscribers] = useState(allGoalSubscribers);
  const [getdefaultGoalSubscribers] = useState(defaultGoalSubscribers);

  const updateTasks = useCallback(
    async (data: {
      tasks?: {text?: string; completed?: boolean}[];
      goalId: string;
    }) => {
      const getTasksMentions: any = [];

      const request = new GoalsRequestImpl();
      const controller = new GoalsController(request);

      const response = await controller.updateGoalTasksById(data.goalId, {
        tasks: data.tasks,
      });

      if (response) {
        const computeTasks = response.tasks.map((task: {_id: string}) => {
          return {
            ...task,
            user: goalFirebaseTasks?.[task?._id]
              ? users.find(
                  (user) => user.id === goalFirebaseTasks?.[task?._id]?.user,
                )
              : undefined,
          };
        });

        setTasks(computeTasks);
      }

      data.tasks?.forEach((task) => {
        const mentionedUsers = getFromBetween.get(task.text, '_(', ')_');

        if (mentionedUsers.length > 0) getTasksMentions.push(...mentionedUsers);
      });

      const handleNewAddMentions = (data: any) => {
        data.forEach((id: string) => {
          AddMentions(
            id,
            goalId,
            {
              unreadTasks: getTasksMentions.includes(id) ? 1 : 0,
              tasksMentionedBy: getTasksMentions.includes(id)
                ? [auth.user.id]
                : [],
              mentionedBy: [],
              unreadComment: 0,
            },
            'goals',
          );
        });
      };
      if (getGoalSubscribers.length > 0) {
        const users = [
          ...new Set([...getGoalSubscribers, ...getTasksMentions]),
        ];
        AddSubscribers(goalId, users);
        if (getMentions.length > 0) {
          getMentions.forEach((user: any) => {
            const id: any = Object.keys(user)[0];
            const tasksMentionedBy = user[id]?.tasksMentionedBy || [];

            AddMentions(
              id,
              goalId,
              {
                unreadTasks:
                  user[id]?.unreadTasks && getTasksMentions.includes(id)
                    ? user[id]?.unreadTasks + 1
                    : getTasksMentions.includes(id)
                    ? 1
                    : 0,
                tasksMentionedBy: getTasksMentions.includes(id)
                  ? [...new Set([...tasksMentionedBy, auth.user.id])]
                  : [...new Set([...tasksMentionedBy])],
                mentionedBy: user[id]?.mentionedBy || [],
                unreadComment: user[id]?.unreadComment || 0,
              },
              'goals',
            );
          });
        } else {
          handleNewAddMentions(users);
        }
      } else {
        const users = [
          ...new Set([...getdefaultGoalSubscribers, ...getTasksMentions]),
        ];
        AddSubscribers(goalId, users);
        handleNewAddMentions(users);
      }
    },
    [
      getGoalSubscribers,
      goalFirebaseTasks,
      users,
      goalId,
      auth.user.id,
      getMentions,
      getdefaultGoalSubscribers,
    ],
  );

  const debounceUpdateTasks = useDebounce(updateTasks, 1000);

  return {
    loading,
    debounceUpdateTasks,
    updateTasks,
    setTasks,
    tasks,
  };
};

export const GoalTasks = observer(
  ({
    goalId,
  }: {
    goalId: string;
    unreadTasks: number;
    isReadOnly?: boolean;
    currentUserMentions: any;
    userId: string;
    mentions: any[];
    defaultGoalSubscribers: any[];
    allGoalSubscribers: any[];
  }) => {
    const {
      authStore: {auth},
      usersStore: {users},
    } = useStoreContext();

    const {handleChange, isLoading, tasks} = useActionItems({
      source: 'goal',
      itemId: goalId,
      userId: '',
      orderBy: {
        key: 'sourceId',
        value: goalId,
      },
    });

    const [showPaidPlanModal, setShowPaidPlanModal] = useState(true);

    if (isLoading) {
      return (
        <ItemLoaderWrapper>
          <ItemLoader />
        </ItemLoaderWrapper>
      );
    }

    return (
      <Wrapper>
        <AdditionalOptionsWrapper
          display={
            showPaidPlanModal &&
            auth.user.workspace.billing.plan === BillingType.FREE
          }>
          <div className="wrapper">
            <div className="wrapper-div">
              <Body2>
                This feature isn’t available for your current account plan, to
                access this feature{' '}
                <span
                  style={{
                    color: '#661515',
                    fontWeight: 600,
                    textDecoration: 'underline',
                    whiteSpace: 'nowrap',
                  }}>
                  {' '}
                  upgrade your account
                </span>
              </Body2>
            </div>
            <div
              onClick={() => setShowPaidPlanModal(false)}
              className="cancel-div">
              <CancelIcon />
            </div>
          </div>
        </AdditionalOptionsWrapper>
        <ActionItems
          handleChange={handleChange}
          canDelete={(fieldId) => {
            const task = tasks.find((task) => task.key === fieldId);

            if (!!task) {
              // allow admin delete action item
              if (auth.user.role === 'admin' && !!task) {
                return true;
              }

              // allow manager of task users delete task
              const usersInvolved = users
                .filter((user) =>
                  [task?.user, task?.updatedBy, task?.assignee].includes(
                    user.id,
                  ),
                )
                .map((user) => user.reviewer.id);

              if (usersInvolved.includes(auth.user.id)) {
                return true;
              }
            }
            return false;
          }}
          users={users.map((user) => ({
            label: userName(user),
            value: user.id,
            avatar: user.avatar,
          }))}
          source="goal"
          sourceId={goalId}
          value={(tasks as any) || []}
          userId={auth.user.id}
        />
      </Wrapper>
    );
  },
);
