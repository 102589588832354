import {storeDashboardRoutesInit} from './stores/store-dashboard-route';
import {IStore} from './store-interface';
import {storeYouGoals} from './stores/store-you-goals';
import {goalsState} from './stores/goals-state';
import {authStore} from './stores/auth-store';
import {usersStore} from './stores/users-store';
import {performanceStore} from './stores/performance-store';
import {storeAllObjectives} from './stores/store-all-objectives';
import {integrationStore} from './stores/integration-store';
import {analyticsState} from './stores/analytics-store';
import {notificationStore} from './stores/notification-store';
import {taskStore} from './stores/task-store';
import {groupTypeStore} from './stores/group-type-store';
import {ccStore} from './stores/cc-store';
import {feedbackStore} from './stores/feedback-store';
import {groupStore} from './stores/group-store';
import {companyStore} from './stores/company-store';
import {goalTemplatesStore} from './stores/goal-templates-store';
import {billingsStore} from './stores/billings.store';
import {billingRoutesStore} from './stores/billing-routes.store';
import {checkinsStore} from './stores/checkins.store';

export const StoresArchive: IStore = {
  storeDashboardRoutes: storeDashboardRoutesInit,
  storeYouGoals: storeYouGoals,
  storeAllObjectives: storeAllObjectives,
  goalsState: goalsState,
  authStore: authStore,
  feedbackStore: feedbackStore,
  usersStore: usersStore,
  taskStore: taskStore,
  ccStore: ccStore,
  performanceStore: performanceStore,
  integrationStore: integrationStore,
  analyticsStore: analyticsState,
  groupTypeStore: groupTypeStore,
  groupStore: groupStore,
  notificationStore: notificationStore,
  companyStore: companyStore,
  goalTemplatesStore: goalTemplatesStore,
  billingsStore: billingsStore,
  billingRoutesStore: billingRoutesStore,
  checkinsStore: checkinsStore,
};
