import {IAPIUserFormat, IMeetingResponse} from '@hooks';
import {useGCalenderHook} from '@hooks/gcalender';
import {PlainButton} from '@ui/atoms/plain-button/plain-button';
import {Body1, Body2, CTA, Headline3} from '@ui/atoms/typography';
import {Calendar} from '@ui/molecules/calendar';
import {DrawerModal} from '@ui/organisms/modal/drawer-modal';
import {FlexRow, FlexRowCenter, FlexRowSpaceBetween} from '@ui/style/styles';
import dayjs from 'dayjs';
import {cloudinary} from '@assets/images/cloudinary';
import React, {memo, useCallback, useMemo, useState} from 'react';
import {useQueries} from 'react-query';
import {Tooltip} from '@ui/molecules/tooltip';
import {ArrowRightIcon, CalendarIcon} from '@ui/atoms/icons';
import {HorizontalSideRule} from '@ui/atoms/spacer/spacer';
import {Button} from '@ui/atoms/button';
import styled from 'styled-components';
import {useNavigate} from 'react-router';
import {userName} from '@utils/user-name';
import {authStore} from '@store/stores/auth-store';
import {useViewMeetingHook} from '../view-meetings/view-meeting-hook';
import {useStoreContext} from '@store/store-context';
import {ItemLoader} from '@ui/organisms/item-loader';
import {groupMeetingsByDate} from '@utils/group-meetings';
import {get} from 'idb-keyval';
import {ToggleSwitch} from '@ui/atoms/toggle-switch';
import nextId from 'react-id-generator';
import {capitalize} from '@utils';
import {Image} from '@ui/atoms/image';
import {getTime} from '@utils/date';
import {timezones} from '@constants';
import {RotatingLines} from 'react-loader-spinner';
import {IntegrationsController} from '@pages/dashboard/admin/admin-components/admin-integrations/components/app.controller';
import {CustomColorIcon} from '@ui/atoms/color-icons';
import {MeetingController} from '../meeting-controller';
import {MeetingRequestImpl} from '../meeting.request';

const {emptyGoals} = cloudinary;

interface ICalendarView {
  open: boolean;
  onClose: () => void;
  type?: string;
}
export const CalendarIconWrapper = styled.span`
  stroke: white;
`;

export const PlusIconWrapper = styled.button`
  svg {
    width: 20px;
    height: 20px;

    circle {
      fill: #cecede;
    }

    path {
      stroke: white;
    }

    &:hover {
      circle {
        fill: #47b881;

        &:disabled {
          fill: #cecede;
        }
      }
    }
  }
`;

export const CalendarView = memo(({open, onClose}: ICalendarView) => {
  const {listCalendarEvents, isSigned, getEmail} = useGCalenderHook();

  const navigate = useNavigate();

  const [events, setEvents] = useState<IMeetingResponse[]>([]);

  const {
    authStore: {auth},
  } = useStoreContext();

  const [calendarValue, setCalendarValue] = useState(
    dayjs().format('MM/DD/YYYY'),
  );

  const [dateRange, setDateRange] = useState({
    starts: dayjs(calendarValue).format('MM/DD/YYYY'),
    ends: dayjs(calendarValue).add(1, 'week').format('MM/DD/YYYY'),
  });

  const filter = useMemo(
    () => ({
      startDate: dateRange.starts,
      paginate: false,
      endDate: dateRange.ends,
      excludeGoogle: true,
    }),
    [dateRange],
  );

  const handleGetMeetings = useCallback(async (): Promise<
    IMeetingResponse[]
  > => {
    const request = new MeetingRequestImpl();
    const controller = new MeetingController(request);
    const response = await controller.getMeetings(filter);

    if (response) {
      const users: IAPIUserFormat[] | undefined = await get(
        `${auth.user.workspace.id}/users`,
      );

      return response.map((meeting: any) => ({
        ...meeting,
        participant: users?.filter((user) =>
          meeting.participant.includes(user.id),
        ),
        user: users?.find((user) => user.id === meeting.user),
      }));
    }
    return [];
  }, [auth.user.workspace.id, filter]);

  const getActiveIntegrations = useCallback(async () => {
    const controller = new IntegrationsController();
    const response = await controller.getIntegrations();

    if (response) {
      const googleCalenderApp = response?.find(
        (app: {app: string}) => app.app === 'Google Calendar',
      );

      return googleCalenderApp;
    }
  }, []);

  // 2. Implement parallel data fetching
  const queries = useQueries([
    {
      queryKey: ['calendar-view-meetings', dateRange.starts],
      queryFn: () => {
        return handleGetMeetings();
      },
      refetchOnWindowFocus: false,
      enabled: open,
    },
    {
      queryKey: ['google-events', dateRange.starts],
      queryFn: () => getCalenderEvents(),
      refetchOnWindowFocus: false,
      enabled: isSigned && open,
    },
    {
      queryKey: 'google-email',
      queryFn: getEmail,
      enabled: isSigned && open,
    },
    {
      queryKey: 'activeApps-Integrations',
      queryFn: getActiveIntegrations,
    },
  ]);

  const [meetings, googleEvents, email, calendar] = queries;

  const calendarConfig = calendar.data;

  const connectedEmail = email.data || '';

  // 3. Implement memoized data processing
  const processedMeetings = useMemo(() => {
    if (!meetings.data) return [];

    return meetings.data.map((meeting) => ({
      ...meeting,
      title: meeting.title || `1:1 with ${userName(meeting.participant[0])}`,
      isUserMeeting: meeting.participant?.some(
        (_participant: {id: string}) =>
          _participant.id === authStore.auth.user.id,
      ),
    }));
  }, [meetings.data]);

  // 4. Implement windowed rendering for meeting list
  const groupedEvents = useMemo(() => {
    const meetingCalendarEvents = processedMeetings
      ? processedMeetings.map((meeting) => meeting.meta?.calendarEventId || '')
      : [];

    const allEvents = [
      ...processedMeetings,
      ...(googleEvents.data || []).filter(
        (event) => !meetingCalendarEvents.includes(event.eventId),
      ),
    ];

    return Object.entries(groupMeetingsByDate(allEvents, calendarValue));
  }, [calendarValue, processedMeetings, googleEvents.data]);

  // 5. Implement loading states optimization
  const isLoading = queries[1].isLoading || queries[0].isLoading;

  const isRefetching = queries.some((query) => query.isRefetching);

  // 6. Implement batch updates
  const handleRefresh = useCallback(() => {
    const refreshPromises = queries.map((query) => (query as any).refetch());
    return Promise.all(refreshPromises);
  }, [queries]);

  const getCalenderEvents = useCallback(async () => {
    let results: any[] = [];

    await listCalendarEvents(
      100,
      dayjs(calendarValue).format(),
      dayjs(calendarValue).add(1, 'week').format(),
      'primary',
    ).then(
      async function (resp: any) {
        const allGoogleEvents = resp.result.items;

        if (allGoogleEvents) {
          const users: IAPIUserFormat[] | undefined = await get(
            `${auth.user.workspace.id}/users`,
          );

          const computeEvents = allGoogleEvents
            .filter((event: {summary: any; id: string}) => !!event.summary)
            .map(
              (event: {
                id: string;
                attendees: any;
                creator: any;
                organizer: any;
                summary: any;
                hangoutLink?: string;
                start: {dateTime: dayjs.ConfigType | undefined};
                end: {dateTime: dayjs.ConfigType | undefined};
              }) => ({
                title: event.summary,
                time: dayjs(event.start?.dateTime).format('hh:mm a'),
                nextSteps: [],
                meetingLink: event?.hangoutLink,
                googleEvent: true,
                eventId: event.id,
                user: event.creator,
                organizer: event.organizer,
                participant:
                  users?.filter((user) =>
                    event?.attendees
                      ?.map((attendee: {email: string}) => attendee.email)
                      .includes(user.email),
                  ) || [],
                label: [],
                startDate: dayjs(event.start?.dateTime).format(),
                endDate: dayjs(event.end?.dateTime).format(),
                reminder: [],
              }),
            );

          setEvents(computeEvents);
        }
      },
      function (reason: any) {},
    );
    return results;
  }, [auth.user.workspace.id, calendarValue, listCalendarEvents]);

  const showLoader = isLoading;

  return (
    <div>
      <DrawerModal
        open={open}
        onClose={onClose}
        widthType={'quarter'}
        header={
          !isSigned && (
            <div className="px-4 pt-2 pb-3 bg-[#585ADF] w-full flex items-center justify-between mb-4 ">
              <CalendarIconWrapper className="">
                <CalendarIcon />
              </CalendarIconWrapper>
              <Body2
                weight="semibold"
                kind="white"
                className=" whitespace-nowrap">
                Sync with your calendar
              </Body2>
              <Button
                style={{padding: '4px 16px'}}
                kind="secondary"
                onClick={() => navigate('/edit/apps')}>
                Connect
              </Button>
            </div>
          )
        }
        title="">
        <div>
          <FlexRowSpaceBetween className="mb-3">
            <FlexRow className="gap-2">
              <Headline3>Calendar</Headline3>
              <button
                className="py-[2px] px-2 rounded-lg border border-[#CECEDE]"
                onClick={() => {
                  const today = dayjs().format('MM/DD/YYYY');
                  setCalendarValue(today);

                  setDateRange({
                    starts: today,
                    ends: dayjs(today).add(1, 'week').format('MM/DD/YYYY'),
                  });
                }}>
                <Body2>Today</Body2>
              </button>
            </FlexRow>

            <PlainButton
              onClick={() => {
                handleRefresh();
              }}>
              <FlexRow className="gap-2">
                {isRefetching && (
                  <RotatingLines width="16" strokeColor="#585adf" />
                )}

                <Body2 weight="bold" kind="purple300">
                  Refresh
                </Body2>
              </FlexRow>
            </PlainButton>
          </FlexRowSpaceBetween>

          <Calendar
            style={{width: '100%'}}
            value={calendarValue}
            useValue
            onChange={(value) => {
              const date = dayjs(value).format('MM/DD/YYYY');

              setCalendarValue(date);
              setDateRange({
                starts: date,
                ends: dayjs(value).add(1, 'week').format('MM/DD/YYYY'),
              });
            }}
          />

          <div className="border-t border-t-[#EDEDF2] mt-4 pt-4 overflow-auto">
            <div className="max-h-[400px]">
              {showLoader && (
                <div className="w-full h-full min-h-[200px] flex items-center justify-center">
                  <ItemLoader />
                </div>
              )}
              {groupedEvents &&
                !showLoader &&
                groupedEvents.map(([key, meetings]) => (
                  <MeetingView
                    key={key}
                    calendarConfig={calendarConfig}
                    connectedEmail={connectedEmail}
                    title={key}
                    calendarEvents={events}
                    meetings={meetings as any}
                    refetch={handleRefresh as any}
                  />
                ))}
            </div>
          </div>
        </div>
      </DrawerModal>
    </div>
  );
});

interface MeetingViewProps {
  title: string;
  meetings: IMeetingResponse[];
  connectedEmail: string;
  calendarEvents: {eventId: string; meetingLink: string}[];
  calendarConfig?: {
    data: {
      beamAI: string[];
    };
  };
  refetch: () => Promise<void>;
}
const MeetingView = memo(
  ({
    title,
    meetings,
    connectedEmail,
    refetch,
    calendarEvents,
    calendarConfig,
  }: MeetingViewProps) => {
    const emptyPlaceholder = {
      image: {
        alt: 'alt text',
        shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
        src: emptyGoals,
        pictureStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        style: {
          height: '60px',
          width: '60px',
        },
      },
      imports: true,
      // action: <
      subtitle: 'No Meetings!',
      title: '',
    };

    return (
      <div className="p-4 rounded-[10px] border border-borderLight  mb-4 ">
        <Body1 weight="bold">{title}</Body1>
        <div className="">
          {meetings.length ? (
            meetings.map((meeting) => (
              <MeetingCard
                meeting={meeting}
                key={meeting.id}
                connectedEmail={connectedEmail}
                calendarConfig={calendarConfig}
                meetingLink={
                  calendarEvents.find(
                    (event) => event.eventId === meeting?.meta?.calendarEventId,
                  )?.meetingLink
                }
                refetch={refetch}
              />
            ))
          ) : (
            <div>
              <FlexRowCenter
                className="min-h-[170px] "
                style={{flexDirection: 'column'}}>
                <FlexRowCenter>
                  <Image {...emptyPlaceholder.image} />
                </FlexRowCenter>
                <Body2 kind="textBody">No Meetings!</Body2>
              </FlexRowCenter>
            </div>
          )}
        </div>
      </div>
    );
  },
);

interface Meeting {
  meeting: IMeetingResponse;
  meetingLink?: string;
  refetch: () => Promise<void>;
  connectedEmail: string;
  calendarConfig?: {
    data: {
      beamAI: string[];
    };
  };
}

const MeetingCard = memo(
  ({
    meeting,
    refetch,
    meetingLink,
    calendarConfig,
    connectedEmail,
  }: Meeting) => {
    const name = meeting.participant?.some(
      (_participant: {id: string}) =>
        _participant.id === authStore.auth.user.id,
    )
      ? userName(meeting.user)
      : userName(meeting.participant[0]);

    const [isBotChecked, setIsBotChecked] = useState(!!meeting.meta?.botId);

    const [isProcessing, setIsProcessing] = useState(false);

    const {targetHour, targetMinute} = getTime(meeting?.time || '');

    const getTimeZone = timezones.find(
      (timezone) => timezone.value === authStore.auth.user.timezone,
    );

    const updatedDate = dayjs(meeting?.startDate)
      .set('hour', targetHour)
      .set('minute', targetMinute)
      .tz(getTimeZone?.label || '', true);

    const isDisabled = dayjs().isAfter(dayjs(updatedDate), 'day');

    const meeting_link = meeting?.meetingLink || meetingLink;

    const disableUpdateBeamAI = isDisabled || !meeting_link;

    const {handleUpdateMeeting, controller} = useViewMeetingHook();

    // Check if user is the organizer
    const isUserOrganizer = meeting.googleEvent
      ? meeting.user?.email === connectedEmail &&
        meeting.organizer?.email === connectedEmail
      : meeting.user?.email === authStore.auth.user.id;

    // Get BeamAI configuration settings
    const beamAiSettings = calendarConfig?.data?.beamAI || [];

    const hasAutoForVideoLinks = beamAiSettings.includes(
      'all-meetings-with-video-link',
    );

    const hasAutoForUser = beamAiSettings.includes('organized-by-me');

    // Determine if BeamAI should be auto-enabled and toggle disabled
    const shouldAutoEnableAndDisable =
      (hasAutoForVideoLinks && meeting_link) || // Video link meetings
      (isUserOrganizer && meeting_link); // User-organized meetings with video link
    // Determine if toggling is allowed

    const isTogglingDisabled =
      disableUpdateBeamAI ||
      shouldAutoEnableAndDisable ||
      (isUserOrganizer && hasAutoForUser);

    const createMeeting = async () => {
      const response = await controller.postMeeting({
        participant: meeting.participant.map(
          (participant: {id: string}) => participant.id,
        ),
        title: meeting.title,
        label: [],
        hostAgenda: [],
        startDate: updatedDate,
        endDate: meeting.endDate,
        time: meeting.time,
        frequency: 'once',
        reminder: [],
      });

      if (response) {
        await controller.patchMeeting(
          {
            addBeamAI: true,
            meetingLink: meeting.meetingLink,
            meta: {
              calendarEventId: meeting.eventId,
            },
          },
          response.id,
        );
        return response.id;
      }
      return null;
    };

    const deleteMeeting = async (meetingId: string) => {
      await controller.deleteMeeting(meetingId);
    };

    const handleToggleSwitch = async () => {
      const botValue = isBotChecked;
      setIsProcessing(true);

      try {
        if (!meeting?.id) {
          // Create new meeting when toggling on
          if (!botValue) {
            const newMeetingId = await createMeeting();

            if (newMeetingId) {
              setIsBotChecked(true);
              await refetch();
            }
          }
        } else {
          // For existing meetings, toggle BeamAI or delete if it was just created
          if (!botValue) {
            // Turning on BeamAI
            const response = await handleUpdateMeeting(meeting.id, {
              addBeamAI: true,
              meetingLink: meeting_link,
            } as any);
            if (response) {
              setIsBotChecked(true);
              await refetch();
            }
          } else {
            // Turning off BeamAI
            if (!meeting.googleEvent) {
              // For Google Calendar events, just remove BeamAI
              const response = await handleUpdateMeeting(meeting.id, {
                addBeamAI: false,
                meetingLink: meeting_link,
              } as any);
              if (response) {
                setIsBotChecked(false);
                await refetch();
              }
            } else {
              // For meetings we created, delete the meeting
              await deleteMeeting(meeting.id);
              setIsBotChecked(false);
              await refetch();
            }
          }
        }
      } catch (error) {
        console.error('Error handling toggle:', error);
        setIsBotChecked(botValue); // Revert on error
      } finally {
        setIsProcessing(false);
      }
    };

    const navigate = useNavigate();

    const isChecked = shouldAutoEnableAndDisable || isBotChecked;

    return (
      <div
        key={meeting?.id}
        className="rounded-[10px] p-4 border border-borderLight mt-4 flex items-center justify-between">
        <div>
          <FlexRow>
            <Body2 weight="semibold" kind="textBody" className="lowercase">
              {' '}
              {meeting.time}{' '}
              {meeting?.endDate
                ? `- ${dayjs(meeting.endDate).format('hh:mm a')}`
                : null}
            </Body2>

            {meeting.label?.length > 0 && (
              <div className="flex items-center gap-2 ">
                <HorizontalSideRule size="16px" />
                {meeting.label.slice(0, 3).map((_label) => (
                  <div
                    className="bg-[#F6F6F8] py-1 px-2 rounded-lg flex gap-1 items-center cursor-pointer"
                    onClick={() => {}}>
                    <Tooltip
                      text={`${_label}`}
                      bodyStyle={{fontWeight: 600, fontSize: '12px'}}
                      withEllipsis
                      BodyTextNodeType={Body2}
                      bodyTextNodeKind="textBody"
                      maxLength={11}
                      tooltipBody={<span>{_label}</span>}
                    />

                    <ArrowRightIcon style={{stroke: '#5F5F8C'}} />
                  </div>
                ))}
              </div>
            )}
          </FlexRow>

          <div className="mt-3">
            <Tooltip
              text={capitalize(
                `${meeting.title || `1:1 with ${capitalize(name || '--')}`}`,
              )}
              bodyStyle={{fontWeight: 600}}
              BodyTextNodeType={Body1}
              withEllipsis
              maxLength={20}
              tooltipBody={
                <span>{meeting.title || `1:1 with ${name || '--'}`}</span>
              }
            />
          </div>

          <FlexRow className="gap-2 mt-3">
            {isProcessing && <RotatingLines width="16" strokeColor="#47B881" />}
            {shouldAutoEnableAndDisable ? (
              <CustomColorIcon
                color="#47B881"
                width="12.8px"
                height="12.8px"
                margin={0}
              />
            ) : (
              <ToggleSwitch
                checked={isChecked}
                onChange={handleToggleSwitch}
                switchId={meeting?.id || (nextId() as any)}
                disabled={isTogglingDisabled || isProcessing}
              />
            )}

            <CTA
              kind={
                isTogglingDisabled && !isChecked ? 'textMuted' : 'textDark'
              }>
              {isChecked ? 'BeamAI will join' : 'Invite BeamAI to join'}
            </CTA>
          </FlexRow>
        </div>

        {!meeting.googleEvent && meeting?.id && (
          <Button
            kind="secondary"
            style={{padding: '8px 16px'}}
            onClick={() => {
              navigate(`/view-meetings/${meeting.id}`);
            }}>
            View
          </Button>
        )}
      </div>
    );
  },
);
