import {BaseRequest} from '@configs/request';
import {authStore} from '@store/stores/auth-store';

export interface IntegrationRequest extends BaseRequest {
  url: string;
  getIntegrations(): Promise<any>;
  patchIntegrations(data: {
    app: string;
    token?: string;
    data?: any;
  }): Promise<any>;
  postIntegrations(data: {app: string; token: string}): Promise<any>;
  deleteIntegrations(app: string): Promise<any>;
}

export class IntegrationRequestImpl
  extends BaseRequest
  implements IntegrationRequest {
  url: string = '/integrations';

  private requestHeader = {
    headers: {
      Authorization: `Bearer ${authStore?.auth?.tokens.access.token}`,
    },
  };

  async getIntegrations() {
    const {data: response} = await this.request.get(`${this.url}`, {
      headers: {
        Authorization: `Bearer ${authStore?.auth?.tokens.access.token}`,
      },
      useCache: false,
    });

    return response;
  }

  async postIntegrations(data: {app: string; token: string}) {
    const {data: response} = await this.request.post(`${this.url}/`, data, {
      headers: {
        Authorization: `Bearer ${authStore?.auth?.tokens.access.token}`,
      },
    });
    return response;
  }

  async patchIntegrations(data: {app: string; token?: string; data?: any}) {
    const {data: response} = await this.request.patch(`${this.url}/`, data, {
      headers: {
        Authorization: `Bearer ${authStore?.auth?.tokens.access.token}`,
      },
    });
    return response;
  }

  async editAppIntegrations(data: any, app: string) {
    const {data: response} = await this.request.patch(
      `${this.url}/${app}`,
      {data},
      {
        headers: {
          Authorization: `Bearer ${authStore?.auth?.tokens.access.token}`,
        },
      },
    );
    return response;
  }

  async getAppReport(app: string) {
    const {data: response} = await this.request.get(
      `${this.url}/${app}/reports`,
      {
        headers: {
          Authorization: `Bearer ${authStore?.auth?.tokens.access.token}`,
        },
      },
    );
    return response;
  }

  async deleteIntegrations(app: string) {
    const {data: response} = await this.request.delete(
      `${this.url}/${app}`,

      {
        headers: {
          Authorization: `Bearer ${authStore?.auth?.tokens.access.token}`,
        },
      },
    );
    return response;
  }
}

export const integrationRequest = new IntegrationRequestImpl();
