import {memo, useMemo} from 'react';
import styled from 'styled-components';
import {Microcopy} from '../../atoms/typography/microcopy';
import {Avatar} from '../../atoms/avatar';
import {UserAvatar} from '../../interface';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

const Text = styled(Microcopy)`
  margin-left: 5px;
`;

const Container = styled.div`
  display: inline-flex;
  flex-direction: row-reverse;
`;

const Item = styled.div`
  display: inline-block;

  &:not(:last-of-type) {
    margin-left: -8px;
  }
`;

export interface AvatarsProps {
  items: UserAvatar[];
  total: number;
  tooltip?: boolean;
  showCount?: number;
  size?: 'sm' | 'md' | 'lg' | 'xl';
}

const AvatarItem = memo(function ({
  name,
  src,
  id,
  size,
  tooltip,
  avatarIcon,
  active,
  isUserDeleted,
}: UserAvatar) {
  return (
    <Item>
      <Avatar
        tooltip={tooltip ?? true}
        src={src}
        alt={name}
        active={active}
        avatarIcon={avatarIcon}
        isUserDeleted={isUserDeleted}
        name={name}
        userId={id}
        size={size}
      />
    </Item>
  );
});

export const Avatars = memo(function ({
  total,
  items,
  size,
  tooltip,
  showCount = 3,
}: AvatarsProps) {
  const renderMore = useMemo(() => {
    if (total > showCount) {
      return <Text kind="purple300">+{total - showCount}</Text>;
    }

    return null;
  }, [showCount, total]);

  const item = items?.slice(0, showCount);

  return (
    <Wrapper>
      <Container>
        {item?.map((avatar, idx) => (
          <AvatarItem
            {...avatar}
            tooltip={tooltip}
            size={size}
            key={`${idx}-${avatar.src}`}
          />
        ))}
      </Container>
      {renderMore}
    </Wrapper>
  );
});
Avatars.displayName = 'Avatars';
