import React, {useMemo, useState} from 'react';
import {useStoreContext} from '../../store/store-context';
import {ImportHooks} from './import-hooks';
import {CreateGoalModal} from '../../ui/organisms/create-goal-modal';
import {useNavigate} from 'react-router';
import {TabNavigation} from '@ui/molecules/tab-nav';
import {SideNavigation} from '@ui/molecules/side-nav';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {cfv} from '@utils/framework';
import {PageSideLayout} from '@ui/layouts/page-size';
import {cloudinary} from '../../assets/images/cloudinary';
import BulkImport from './bulk-import';
import BulkHistory from './bulk-history';
export default function ImportPage({type}: any) {
  const {storeDashboardRoutes} = useStoreContext();
  const {importGoalTabs, activateImportGoalTab} = storeDashboardRoutes;
  const {history} = ImportHooks();
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const navigate = useNavigate();
  const {calender, calender2x, calender3x} = cloudinary;

  const CompanyHighlight = useMemo(() => {
    const navigations = [];

    navigations.push({
      active: importGoalTabs.import,
      href: '#',
      id: '2',
      onClick: () => activateImportGoalTab('import'),
      title: `Import`,
    });

    navigations.push({
      active: importGoalTabs.history,
      href: '#',
      id: '3',
      onClick: () => {
        activateImportGoalTab('history');
      },
      title: 'History',
    });

    return navigations;
  }, [activateImportGoalTab, importGoalTabs.import, importGoalTabs.history]);

  return (
    <>
      {type === 'admin' ? (
        <>
          <TabNavigation navigations={CompanyHighlight} type="button" />
          <VerticalSpacer size="20px" />
          {!uploadSuccess ? (
            <>
              {importGoalTabs.import && (
                <BulkImport setUploadSuccess={setUploadSuccess} />
              )}
              {importGoalTabs.history && (
                <BulkHistory type={type} history={history} />
              )}
            </>
          ) : (
            <>
              <CreateGoalModal
                image={{
                  extension: 'png',
                  src: calender,
                  variants: [
                    {src: calender, size: '1x'},
                    {src: calender2x, size: '2x'},
                    {src: calender3x, size: '3x'},
                  ],
                }}
                goalFramework={cfv().g_oal}
                imports={true}
                onExit={function noRefCheck() {
                  navigate('/objectives', {replace: true});
                }}
              />
            </>
          )}
        </>
      ) : (
        <>
          <PageSideLayout
            content={
              <>
                <div>
                  {!uploadSuccess ? (
                    <>
                      {importGoalTabs.import && (
                        <BulkImport setUploadSuccess={setUploadSuccess} />
                      )}
                      {importGoalTabs.history && (
                        <BulkHistory type={type} history={history} />
                      )}
                    </>
                  ) : (
                    <>
                      <CreateGoalModal
                        image={{
                          extension: 'png',
                          src: calender,
                          variants: [
                            {src: calender, size: '1x'},
                            {src: calender2x, size: '2x'},
                            {src: calender3x, size: '3x'},
                          ],
                        }}
                        goalFramework={cfv().g_oal}
                        imports={true}
                        onExit={function noRefCheck() {
                          navigate('../', {replace: true});
                        }}
                      />
                    </>
                  )}
                </div>
              </>
            }
            navigation={<SideNavigation navigations={CompanyHighlight} />}
          />
        </>
      )}
    </>
  );
}
