import {useStoreContext} from '@store/store-context';
import {FeedBackType} from '@pages/feedback/components/feedback-type';
import {Label} from '@ui/atoms/label';
import FeedBackInput from '@pages/feedback/components/feedback';
import {VerticalSpacer} from '@ui/atoms/spacer';
import SelectCompanyValues from '@pages/feedback/components/select-values';

interface IFeedback {
  handleFeedbackFormValueChange: (value: any, field: string) => void;
  feedbackError: {
    feedbackValue: string;
    impression: string;
    feedbackSharingType: string;
  };
  feedbackValues: {
    feedbackSharingType: string;
  };
}
export const Feedback = ({
  handleFeedbackFormValueChange,
  feedbackError,
  feedbackValues,
}: IFeedback) => {
  const {
    authStore: {auth},
  } = useStoreContext();

  return (
    <div>
      <FeedBackInput
        error={feedbackError.feedbackValue || feedbackError.impression}
        updateImpression={(value) => {
          handleFeedbackFormValueChange(value, 'impression');
        }}
        updateFeedbackInput={(value) => {
          handleFeedbackFormValueChange(value, 'feedbackValue');
        }}
      />
      <VerticalSpacer size="32px" />
      {auth.user.workspace.settings.companyValues.values?.length > 0 && (
        <>
          <Label>
            Give praise based on values{' '}
            <span
              style={{
                fontWeight: 400,
                color: '#5F5F8C',
              }}>
              (optional){' '}
            </span>
          </Label>
          <VerticalSpacer size="8px" />
          <SelectCompanyValues
            updateValue={(value) =>
              handleFeedbackFormValueChange(value, 'values')
            }
          />
          <Label>Sharing</Label>
          <VerticalSpacer size="8px" />
        </>
      )}

      <FeedBackType
        error={feedbackError.feedbackSharingType || ''}
        value={feedbackValues.feedbackSharingType}
        updateValue={(value) =>
          handleFeedbackFormValueChange(value, 'feedbackSharingType')
        }
      />
    </div>
  );
};
