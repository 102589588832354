const CommentIcon = () => {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5732 13.61H16.4772C17.5524 13.61 18.3972 12.7544 18.3972 11.7308V3.67881C18.3972 2.65521 17.5524 1.79961 16.4772 1.79961H3.51724C2.44204 1.79961 1.59724 2.65521 1.59724 3.67881V11.7308C1.59724 12.7544 2.44204 13.61 3.51724 13.61H5.43724V17.3996H5.44084L5.44324 17.3984L10.5744 13.61H10.5732ZM6.15724 18.3632C5.90178 18.5515 5.58261 18.632 5.26841 18.5873C4.95421 18.5427 4.6701 18.3764 4.47724 18.1244C4.32263 17.9207 4.23878 17.6721 4.23844 17.4164V14.81H3.51844C1.79524 14.81 0.398438 13.4312 0.398438 11.7308V3.67881C0.397238 1.97841 1.79404 0.599609 3.51724 0.599609H16.4772C18.2004 0.599609 19.5972 1.97841 19.5972 3.67881V11.7308C19.5972 13.4324 18.2004 14.81 16.4772 14.81H10.9692L6.15604 18.3632H6.15724Z"
        fill="#5F5F8C"
      />
    </svg>
  );
};

export default CommentIcon;
