import {observer} from 'mobx-react-lite';
import {useStoreContext} from '../../../../../../../store/store-context';
import {Body2} from '../../../../../../../ui/atoms/typography';
import {EmptyPlaceholder} from '@ui/atoms/empty-placeholder';
import {ObjectiveAnalytics} from './insights-objectives';
import {NestedView} from '../../analytics-shared-components/nestedview/nestedview';

import {StatusType} from '@hooks';
import {useStatusHook} from './status-hook';
import {KeyResultAnalytics} from './insights-keyresults';
import {AnaylyticsDateView} from '../../analytics-shared-components/filterbox/dropdown';
import {FlexRowSpaceBetween, FlexRow} from '@ui/style/styles';
import {SortByFilter} from './status-component/sort-by-filter';

import {Pagination} from '@ui/molecules/pagination/pagination';
import {
  FirstWrapper,
  PaginationWrapper,
  TableContainer,
  TableWrapper,
  ThirdWrapperHeaderRight,
  SecondFlexHeader,
  SecondFlexItem,
  SummaryWrapper,
  SecondWrapperHeader2,
  ItemLoaderWrapper,
  SecondMainChildHeader,
} from './analytics-status.styles';
import {capitalize} from '../../../../../../../utils';
import {cfv} from '../../../../../../../utils/framework';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import ExportCsv from '../../../reporting-export/export-csv';
import {useRef, useEffect, useMemo, useState} from 'react';
import {AnalyticsGrid} from './status-component/analytics-grid';
import {cloudinary} from '@assets/images/cloudinary';
import AnalyticsDetails from '../../analytics-shared-components/analyticsDetails/admin-analytics-details';
import {SecondaryNavigation} from '@ui/molecules/nav/navigation';
import {GroupOwnership, PeopleOwnership} from './ownership';
import {SearchBox2} from '@ui/molecules/search-box';
import {useDebouncedCallback} from 'use-debounce';
import {ItemLoader} from '@ui/organisms/item-loader';

const {emptyGoals: emptyGoal} = cloudinary;

dayjs.extend(utc);

interface AnalyticsStatusProps {
  showObjectiveStatus?: boolean;
}
export const AnalyticsStatus = observer(
  ({showObjectiveStatus}: AnalyticsStatusProps) => {
    const {storeDashboardRoutes} = useStoreContext();

    const {
      statusTabs,
      GoalViewTabs,
      analyticsDashboardTabs,
      activateAnalyticsDashboardTab,
    } = storeDashboardRoutes;

    const {
      analyticsStore: {
        goalPaginator,
        setGoalPaginator,
        handleGoalPageClick,
        goalPages,
        goalsData,
        handleGoalNextbtn,
        handleKpiPageClick,
        handleKpiNextbtn,
        setKpiPaginator,
        handleKpiPrevbtn,
        kpiPages,
        goalsDate,
        updateSearchValue,
        kpiPaginator,
        modifyGoalsDate,
        groups,
        peopleDataForStatus,
        setSortBy,
        sortBy,
        breakdownBy,
        handleGoalPrevbtn,
        viewAnalyticsRange,
        kpisData,
        loading,
      },
      groupStore: {allGroups},
    } = useStoreContext();

    const [searchValue, setSearchValue] = useState('');

    const debounceValue = useDebouncedCallback(
      (value) => updateSearchValue(value),
      2000,
    );

    const {
      setOpen,
      isDeactivatedOrArchived,
      totalCount,
      staticDates,
      AnalyticsDateRange,
    } = useStatusHook({});

    const currentWeekRef: any = useRef();
    const getDateRef: any = useRef();

    useEffect(() => {
      if (
        currentWeekRef &&
        viewAnalyticsRange === 'weeks' &&
        getDateRef.current &&
        !loading.goals &&
        goalsData?.goals?.length > 0
      ) {
        getDateRef.current.scrollLeft =
          currentWeekRef?.current?.getBoundingClientRect()?.left + 50;
      }
    }, [
      viewAnalyticsRange,
      loading.goals,
      goalsData?.goals,
      analyticsDashboardTabs,
    ]);

    const renderDates = useMemo(
      () => (
        <SecondFlexHeader>
          {AnalyticsDateRange?.map((value, index) => (
            <SecondFlexItem
              key={index}
              ref={value.isCurrentWeek ? currentWeekRef : null}>
              <Body2 weight={'semibold'} align="center">
                {`${dayjs(value.start).format('D MMM')} ${
                  value.end
                    ? `-  ${dayjs(value.end).utc().format('D MMM')}`
                    : ''
                } `}
              </Body2>
            </SecondFlexItem>
          ))}
          {staticDates &&
            staticDates?.map((value, index) => (
              <SecondFlexItem key={index}>
                <Body2 weight={'semibold'} align="center" kind="textMuted">
                  {`${dayjs(value.start).format('D MMM')} ${
                    viewAnalyticsRange !== 'days'
                      ? `-  ${dayjs(value.end).utc().format('D MMM')}`
                      : ''
                  } `}
                </Body2>
              </SecondFlexItem>
            ))}
        </SecondFlexHeader>
      ),
      [AnalyticsDateRange, staticDates, viewAnalyticsRange],
    );

    const [ownershipType, setOwnershipType] = useState<
      'groups' | 'people' | ''
    >('groups');

    const objectivesNavigation = useMemo(() => {
      const nav = [
        {
          active: analyticsDashboardTabs.objectives,
          href: '#',
          id: '1',
          onClick: () => activateAnalyticsDashboardTab('objectives'),
          title: capitalize(cfv(undefined, true).g_oals),
        },
        {
          active: analyticsDashboardTabs.keyresults,
          href: '#',
          id: '2',
          onClick: () => activateAnalyticsDashboardTab('keyresults'),
          title: capitalize(cfv(undefined, true).k_rs),
        },
      ];
      return nav;
    }, [
      activateAnalyticsDashboardTab,
      analyticsDashboardTabs.keyresults,
      analyticsDashboardTabs.objectives,
    ]);

    const ownershipNavigation = useMemo(() => {
      const nav = [
        {
          active: ownershipType === 'groups',
          href: '#',
          id: '1',
          onClick: () => setOwnershipType('groups'),
          title: 'Groups',
        },
        {
          active: ownershipType === 'people',
          href: '#',
          id: '2',
          onClick: () => setOwnershipType('people'),
          title: 'People',
        },
      ];
      return nav;
    }, [ownershipType]);

    const UserGoalHighlight = {
      image: {
        alt: 'alt text',
        shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
        src: emptyGoal,
        style: {height: '30%', width: '30%'},
      },
      imports: true,
      subtitle: 'Refine your search or try something new.',
      title: 'No results found',
    };

    const goalsViewName = statusTabs.goal
      ? capitalize(cfv().g_oals)
      : statusTabs.Kpi
      ? 'KPIs'
      : '';

    const ownership = breakdownBy === 'ownership';

    const getTableHeaders = useMemo(() => {
      if (breakdownBy === 'group')
        return {
          first: 'Groups',
          second: 'Group manager',
          third: 'Avg. progress',
        };
      if (breakdownBy === 'team')
        return {
          first: 'Teams',
          second: 'Manager',
          third: 'Avg. progress',
        };
      return {
        first: goalsViewName,
        second: 'Owner(s)',
        third: 'Progress',
      };
    }, [breakdownBy, goalsViewName]);

    return (
      <>
        <AnalyticsDetails
          showObjectiveStatus={showObjectiveStatus}
          date={{
            starts: goalsDate.starts,
            ends: goalsDate.ends,
            period: goalsDate?.period,
          }}
          summary={goalsData?.summary}
          onChangeDateRange={(date) =>
            modifyGoalsDate(date.starts, date.ends, date.period)
          }
        />

        {(GoalViewTabs.flat ||
          GoalViewTabs.nested ||
          (GoalViewTabs.tree && statusTabs.people)) && (
          <FirstWrapper className="bg-white">
            <SummaryWrapper>
              <FlexRowSpaceBetween
                className="flex-wrap"
                style={{
                  paddingBottom: '16px',
                  borderBottom: '1px solid #EDEDF2',
                }}>
                <Body2 weight="bold">
                  Summary ({totalCount || goalsData?.goals.length})
                </Body2>

                {(!breakdownBy || ownership) && statusTabs.goal && (
                  <SecondaryNavigation
                    kind="details"
                    navStyle={{
                      width: 'max-content',
                      padding: '4px 8px',
                      height: '38px',
                    }}
                    navigations={
                      ownership ? ownershipNavigation : objectivesNavigation
                    }
                  />
                )}

                <AnaylyticsDateView disabled={!!breakdownBy} />
              </FlexRowSpaceBetween>

              <SecondWrapperHeader2>
                <SortByFilter
                  disabled={!!breakdownBy}
                  onChange={(value) => setSortBy(value)}
                  value={sortBy}
                />

                <ThirdWrapperHeaderRight>
                  <ExportCsv
                    exportType={
                      statusTabs.Kpi
                        ? 'kpis'
                        : analyticsDashboardTabs.objectives
                        ? 'objectives'
                        : 'keyresult'
                    }
                    goals={goalsData?.goals || []}
                    summary={goalsData?.summary}
                    dateRange={AnalyticsDateRange?.map(
                      (value) =>
                        `${dayjs(value.start).format('D MMM')} ${
                          value.end
                            ? `-  ${dayjs(value.end).format('D MMM')}`
                            : ''
                        } `,
                    )}
                  />
                </ThirdWrapperHeaderRight>
              </SecondWrapperHeader2>
              {!breakdownBy && (
                <SearchBox2
                  showCancel
                  value={searchValue}
                  onChange={(event) => {
                    debounceValue(event.target.value);
                    setSearchValue(event.target.value);
                  }}
                  handleCancel={() => {
                    updateSearchValue('');
                    setSearchValue('');
                  }}
                  placeholder="Search"
                  inputStyle={{height: '44px'}}
                />
              )}
            </SummaryWrapper>
            <>
              <TableContainer
                style={{
                  borderTop: '1px solid #ededf2',
                  borderRadius: '10px',
                }}>
                <TableWrapper>
                  {GoalViewTabs.flat &&
                  (analyticsDashboardTabs.objectives || statusTabs.Kpi) ? (
                    <>
                      {loading.goals && (
                        <ItemLoaderWrapper>
                          <ItemLoader />
                        </ItemLoaderWrapper>
                      )}
                      {goalsData &&
                        goalsData.goals?.length === 0 &&
                        !loading.goals && (
                          <div>
                            <EmptyPlaceholder {...UserGoalHighlight} />
                          </div>
                        )}

                      {ownership && (
                        <>
                          {ownershipType === 'groups' && (
                            <GroupOwnership groups={groups?.groups || ''} />
                          )}{' '}
                          {ownershipType === 'people' && (
                            <PeopleOwnership people={peopleDataForStatus} />
                          )}
                        </>
                      )}
                      {!loading.goals &&
                        !ownership &&
                        goalsData &&
                        goalsData.goals?.length > 0 && (
                          <div
                            style={{
                              display: 'flex',
                              height: '110%',
                            }}>
                            <div
                              style={{
                                boxShadow:
                                  '3px 0px 2px rgba(172, 172, 190, 0.2)',
                                width: viewAnalyticsRange ? undefined : '100%',
                                paddingBottom: '32px',
                              }}>
                              <FlexRow>
                                <SecondMainChildHeader
                                  includeProgress={!viewAnalyticsRange}>
                                  <span>{getTableHeaders.first}</span>
                                  <span>{getTableHeaders.second}</span>
                                  {!viewAnalyticsRange && (
                                    <>
                                      <span />
                                      <span>{getTableHeaders.third}</span>
                                    </>
                                  )}
                                </SecondMainChildHeader>
                              </FlexRow>
                              {goalsData?.goals.map(
                                (goal: any, index: number) => (
                                  <ObjectiveAnalytics
                                    goal={goal}
                                    index={index}
                                    groupBy={breakdownBy}
                                    open={false}
                                    showProgress={!viewAnalyticsRange}
                                    setOpen={setOpen}
                                    isDeactivatedOrArchived={
                                      isDeactivatedOrArchived
                                    }
                                    key={index}
                                  />
                                ),
                              )}
                            </div>
                            {!!viewAnalyticsRange && (
                              <div
                                ref={getDateRef}
                                style={{
                                  overflowX: 'auto',
                                  overflowY: 'hidden',
                                  marginLeft: '3.5px',
                                }}>
                                <div
                                  style={{
                                    minWidth: 'fit-content',
                                  }}>
                                  {renderDates}
                                  {goalsData?.goals.map((goal: any) => (
                                    <>
                                      {' '}
                                      <AnalyticsGrid
                                        activities={goal.activities}
                                        isKeyResultValue={statusTabs.Kpi}
                                        showPercentageInValue={statusTabs.Kpi}
                                        completion={
                                          goal?.progress === 100 ||
                                          goal?.status ===
                                            StatusType.COMPLETED ||
                                          goal?.status ===
                                            StatusType.INCOMPLETED
                                        }
                                        staticDates={staticDates}
                                      />
                                    </>
                                  ))}
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                    </>
                  ) : (
                    analyticsDashboardTabs.keyresults &&
                    statusTabs.goal && (
                      <>
                        {!loading.kpis && (
                          <div style={{display: 'flex'}}>
                            <div
                              style={{
                                boxShadow:
                                  '3px 0px 2px rgba(172, 172, 190, 0.2)',
                                width: viewAnalyticsRange ? '45%' : '100%',
                                paddingBottom: '32px',
                              }}>
                              <FlexRow>
                                <SecondMainChildHeader
                                  includeProgress={!viewAnalyticsRange}>
                                  <span>{capitalize(cfv().k_rs)}</span>
                                  <span>Owner</span>
                                  {!viewAnalyticsRange && (
                                    <>
                                      <span />
                                      <span>Progress</span>
                                    </>
                                  )}
                                </SecondMainChildHeader>
                              </FlexRow>

                              {kpisData?.kpis.length > 0 &&
                                kpisData?.kpis?.map(
                                  (keyresult: any, index: number) => (
                                    <KeyResultAnalytics
                                      keyresult={keyresult}
                                      showProgress={!viewAnalyticsRange}
                                      key={keyresult.id}
                                      index={index}
                                    />
                                  ),
                                )}
                            </div>
                            {!!viewAnalyticsRange && (
                              <div
                                ref={getDateRef}
                                style={{
                                  overflowX: 'auto',
                                  overflowY: 'hidden',
                                  marginLeft: '3.5px',
                                }}>
                                <div
                                  style={{
                                    minWidth: 'fit-content',
                                  }}>
                                  <SecondFlexHeader>
                                    {renderDates}
                                  </SecondFlexHeader>
                                  {kpisData?.kpis.length > 0 &&
                                    kpisData?.kpis?.map(
                                      (keyresult: any, index: number) => (
                                        <>
                                          <AnalyticsGrid
                                            key={index}
                                            activities={keyresult.activities}
                                            showPercentageInValue={true}
                                            isKeyResultValue={true}
                                            completion={
                                              keyresult?.status === 'freezed'
                                            }
                                            height={'110px'}
                                            staticDates={staticDates}
                                          />
                                        </>
                                      ),
                                    )}
                                </div>
                              </div>
                            )}
                          </div>
                        )}

                        {kpisData && kpisData?.kpis?.length === 0 && (
                          <div>
                            <EmptyPlaceholder {...UserGoalHighlight} />
                          </div>
                        )}
                        {loading.kpis && (
                          <ItemLoaderWrapper>
                            <ItemLoader />
                          </ItemLoaderWrapper>
                        )}
                      </>
                    )
                  )}
                </TableWrapper>
              </TableContainer>
              {!loading.goals && GoalViewTabs.nested && (
                <NestedView
                  goalsData={goalsData?.goals?.filter(
                    (goal: any) => goal.alignedGoals?.length > 0,
                  )}
                  loading={loading.goals}
                  allGroups={allGroups}
                />
              )}
            </>
          </FirstWrapper>
        )}

        <PaginationWrapper>
          {goalPaginator.totalResults > 10 &&
            analyticsDashboardTabs.objectives && (
              <Pagination
                totalPages={goalPaginator.totalPages}
                page={goalPaginator.page}
                totalResults={goalPaginator.totalResults}
                maxPageNumberLimit={goalPaginator.maxPageNumberLimit}
                limit={goalPaginator.limit}
                updateLimit={(value) => setGoalPaginator('limit', value)}
                minPageNumberLimit={goalPaginator.minPageNumberLimit}
                goToPage={handleGoalPageClick}
                pages={goalPages}
                nextBtn={handleGoalNextbtn}
                prevBtn={handleGoalPrevbtn}
              />
            )}

          {kpiPaginator.totalResults > 10 &&
            analyticsDashboardTabs.keyresults && (
              <Pagination
                totalPages={kpiPaginator.totalPages}
                page={kpiPaginator.page}
                totalResults={kpiPaginator.totalResults}
                maxPageNumberLimit={kpiPaginator.maxPageNumberLimit}
                limit={kpiPaginator.limit}
                updateLimit={(value) => setKpiPaginator('limit', value)}
                minPageNumberLimit={kpiPaginator.minPageNumberLimit}
                goToPage={handleKpiPageClick}
                pages={kpiPages}
                nextBtn={handleKpiNextbtn}
                prevBtn={handleKpiPrevbtn}
              />
            )}
        </PaginationWrapper>
      </>
    );
  },
);
