import {DropdownCustom} from '@ui/molecules/dropdown-custom';
import {FilterIcon} from '@ui/atoms/icons/filter-icon';
import {FlexRow} from '@ui/style/styles';
import {useState} from 'react';
import {Body2} from '@ui/atoms/typography';
import {NoBorderButton} from '../../../../reporting-overview/styles';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';

export const SortByFilter = ({
  onChange,
  excludeIcon,
  disabled,
  value,
}: {
  onChange: (value: string) => void;
  disabled?: boolean;
  excludeIcon?: boolean;
  value: string;
}) => {
  const [selectedValue, setSelectedValue] = useState(value);

  const options = [
    {
      label: 'Newest to oldest',
      value: 'desc',
    },
    {
      label: 'Oldest to newest',
      value: 'asc',
    },
  ];
  const findValue = options.find((option) => option.value === selectedValue);

  return (
    <DropdownCustom
      collapseOnClick={true}
      menu={(handleClose: () => void) => (
        <div style={{width: '180px'}}>
          {options.map((option, index) => (
            <DropdownItem
              onClick={() => {
                setSelectedValue(option.value);
                onChange(option.value);
                handleClose();
              }}
              key={index}>
              {option.label}
            </DropdownItem>
          ))}
        </div>
      )}
      customComponent={(handleOpen: (event: any) => void, open: boolean) => (
        <>
          <NoBorderButton onClick={handleOpen} disabled={disabled}>
            <FlexRow style={{width: '100%'}}>
              <Body2
                weight="bold"
                kind={disabled ? 'textMuted' : 'purple300'}
                style={{marginRight: '8px'}}>
                Sort by: {findValue?.label}
              </Body2>

              {!excludeIcon && (
                <div>
                  <FilterIcon stroke={disabled ? '#bfbfd4' : undefined} />
                </div>
              )}
            </FlexRow>
          </NoBorderButton>
        </>
      )}
      customDropdownWrapperStyles={{top: '130%', right: 0}}
    />
  );
};
