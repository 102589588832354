import {memo, useState} from 'react';
import {Body2} from '../../../../../../../ui/atoms/typography';
import {cfv} from '../../../../../../../utils/framework';
import {INestedView} from './nestedview.interface';
import {NestedViewGoal} from './nestedviewgoal';

export const NestedView = memo(
  ({goalsData, loading, variant, allGroups}: INestedView) => {
    const [
      isExpand,
      // setIsExpand
    ] = useState<any>(false);
    const [seeMore, setSeeMore] = useState<boolean>(false);
    const goalsData_ =
      seeMore || variant !== 'companyTeams' ? goalsData : goalsData.slice(0, 2);
    return (
      <>
        {goalsData_?.map((goal: any, index: number) => (
          <NestedViewGoal
            goal={goal}
            isExpand={isExpand}
            loading={loading}
            variant={variant}
            allGroups={allGroups}
          />
        ))}
        {goalsData.length > 2 && variant === 'companyTeams' && (
          <Body2
            kind="purple300"
            onClick={() => setSeeMore(!seeMore)}
            style={{cursor: 'pointer'}}>
            {!seeMore
              ? `+ ${goalsData.length - 2} more ${cfv().g_oals}`
              : `See less ${cfv().g_oals}`}
          </Body2>
        )}
        {goalsData && goalsData.goals && goalsData.goals.length === 0 && (
          <div style={{margin: 20, textAlign: 'center'}}>
            No {cfv().g_oals} found.
          </div>
        )}
      </>
    );
  },
);
// NestedView.displayName = 'Nested View';
