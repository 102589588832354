import React, {memo} from 'react';
import {Label} from '../../../../ui/atoms/label';
import {VerticalSpacer} from '../../../../ui/atoms/spacer';
import {Headline2} from '../../../../ui/atoms/typography';
import {TextField} from '../../../../ui/molecules/field/textfield';
import {PasswordInputEyeToggle} from '../../../../ui/molecules/password-input-eye-toggle';
import {useMemberProfileHook} from '../member-profile-hooks';
import {
  RightChildSpan,
  RightChildWrapperSecurity,
  CustomButton,
  PageLayout,
} from '../member-profile-style';

export const Security = memo(() => {
  const {
    submitPassword,
    passwordRegister,
    isDisabledPassword,
    isSubmittingPassword,
    passwordErrors,
    passwordHandleSubmit,
  } = useMemberProfileHook();

  return (
    <PageLayout>
      <Headline2>Update your password</Headline2>
      <VerticalSpacer size="22px" />
      <form method="patch" onSubmit={passwordHandleSubmit(submitPassword)}>
        <RightChildWrapperSecurity>
          <RightChildSpan>
            <VerticalSpacer size="10px" />
            <Label>Currrent Password</Label>
            <TextField
              defaultValue=""
              placeholder="Current Password"
              type="password"
              ref={passwordRegister}
              helper={passwordErrors.oldPassword?.message || ''}
              state={!!passwordErrors.oldPassword ? 'error' : 'default'}
              name="oldPassword"
              action={(ref) => {
                return <PasswordInputEyeToggle inputRef={ref} />;
              }}
            />
          </RightChildSpan>
          <RightChildSpan>
            <VerticalSpacer size="10px" />
            <Label>New Password</Label>
            <TextField
              defaultValue=""
              placeholder="New password"
              type="password"
              ref={passwordRegister}
              helper={passwordErrors.newPassword?.message || ''}
              state={!!passwordErrors.newPassword ? 'error' : 'default'}
              name="newPassword"
              action={(ref) => {
                return <PasswordInputEyeToggle inputRef={ref} />;
              }}
            />
          </RightChildSpan>
          <CustomButton
            type="submit"
            width="full"
            disabled={isDisabledPassword}
            data-form-action={true}
            isLoading={isSubmittingPassword}
            aria-busy={isSubmittingPassword}>
            Save password
          </CustomButton>
        </RightChildWrapperSecurity>
      </form>
      <VerticalSpacer size="84px" />
    </PageLayout>
  );
});
