import {cloudinary} from '@assets/images/cloudinary';
import {IAPIUserFormat} from '@hooks';
import {useFirebaseFetch} from '@hooks/query-hook';
import {useStoreContext} from '@store/store-context';
import {Avatar} from '@ui/atoms/avatar';
import {EmptyPlaceholder} from '@ui/atoms/empty-placeholder';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {Body1, Body2, Headline3} from '@ui/atoms/typography';
import {DrawerModal} from '@ui/organisms/modal/drawer-modal';
import {FlexRow} from '@ui/style/styles';
import {getFromBetween} from '@utils/get-between';
import {userName} from '@utils/user-name';
import dayjs from 'dayjs';
import React, {useMemo} from 'react';

const {emptyGoals} = cloudinary;

const emptyPlaceholder = {
  image: {
    alt: 'alt text',
    shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
    src: emptyGoals,
    style: {height: '30%', width: '30%'},
  },
  imports: true,
  subtitle: '',
  title: 'No activity found',
};

interface ActionItemActivitiesProps {
  showActivities: boolean;
  toggleActivities: () => void;
}
interface ActionItemActivity {
  createdBy: IAPIUserFormat;
  action: string;
  createdAt: string;
}

export const ActionItemActivities = ({
  showActivities,
  toggleActivities,
}: ActionItemActivitiesProps) => {
  const {data: actionItemActivities} = useFirebaseFetch(
    `activities/action-items`,
  );
  const {
    usersStore: {users},
  } = useStoreContext();

  const formattedActionItems: ActionItemActivity[] = useMemo(() => {
    if (actionItemActivities)
      return Object.values(actionItemActivities)
        .map((activity: any) => {
          const createdBy = activity.createdBy;

          const updatedCreatedBy = users.find((user) => user.id === createdBy);

          return {...activity, createdBy: updatedCreatedBy};
        })
        .sort(
          (a, b) =>
            new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf(),
        );

    return [];
  }, [actionItemActivities, users]);

  const handleUserName = (id: string) => {
    const user = users.find((user) => user.id === id);

    return userName(user);
  };

  const handleDescription = (text = '') => {
    let _text = text;

    // eslint-disable-next-line no-useless-escape
    const getTitle = getFromBetween.get(text, `_(`, `)_`);
    const getUsers = getFromBetween.get(text, `[`, `]`);

    getUsers.forEach((user: string) => {
      if (user) {
        _text = _text.replaceAll(
          `[${user}]`,
          `<span>${handleUserName(user)}</span>`,
        );
      } else {
        _text = _text.replaceAll(`[]`, ``);
      }
    });

    getTitle.forEach((title: string) => {
      _text = _text.replace(
        // eslint-disable-next-line no-useless-escape
        `_(${title})_`,
        `<span style="font-weight: 500">${title}</span>`,
      );
    });

    return _text;
  };

  return (
    <div>
      {' '}
      <DrawerModal
        style={{maxWidth: '400px'}}
        open={showActivities}
        onClose={toggleActivities}
        widthType={'quarter'}
        title="">
        <div>
          <Headline3>Activity</Headline3>
          <VerticalSpacer size="24px" />
          <div className="px-4 py-6 border border-borderLight rounded-[10px] flex flex-col gap-4 overflow-hidden ">
            <div className="max-h-[70vh] overflow-scroll">
              <div className="flex flex-col gap-4">
                {formattedActionItems.map(({createdBy, action, createdAt}) => (
                  <FlexRow className="gap-4">
                    <Avatar
                      src={createdBy?.avatar?.url || ''}
                      userId={createdBy?.id || ''}
                      name={userName(createdBy)}
                      tooltip
                      size="md"
                    />
                    <div>
                      <Body1
                        dangerouslySetInnerHTML={{
                          __html: handleDescription(action),
                        }}
                      />

                      <Body2 style={{fontSize: '12px'}} kind="textBody">
                        {dayjs(createdAt).format(`DD MMM. YYYY`)} at {'  '}
                        {dayjs(createdAt).format(`hh:mma`)}
                      </Body2>
                    </div>
                  </FlexRow>
                ))}
              </div>

              {!formattedActionItems.length && (
                <EmptyPlaceholder {...emptyPlaceholder} />
              )}
            </div>
          </div>
        </div>
      </DrawerModal>
    </div>
  );
};
