import {Button} from '../../../../../../ui/atoms/button';
import {CancelIcon} from '../../../../../../ui/atoms/icons';
import {LockIcon} from '../../../../../../ui/atoms/icons/lock';
import {VerticalSpacer} from '../../../../../../ui/atoms/spacer';
import {Body2, Headline2} from '../../../../../../ui/atoms/typography';
import {BackAction} from '../../../../../../ui/molecules/back-action';
import {DropdownVertical} from '../../../../../../ui/molecules/dropdown-vertical';
import {TextField} from '../../../../../../ui/molecules/field/textfield';
import {Modal} from '../../../../../../ui/molecules/modal';
import {SearchBox} from '../../../../../../ui/molecules/search-box';
import {ItemLoaderLine} from '../../../../../../ui/organisms/item-loader-line';
import {capitalize} from '../../../../../../utils';
import {useFirebaseFetch} from '@hooks/query-hook';
import {PlainButton} from '@ui/atoms/plain-button/plain-button';
import {updateTooltipStatus} from '@utils/firebase-request';
import {InformationBlock} from '@ui/atoms/hint-block/hint-block';
import {AlertBadge} from '@ui/atoms/icons/alert-badge';
import {useAdminGroupHook} from './admin-group-type-hooks';
import {
  DropdownItem,
  FirstWrapper,
  ModalWrapper,
  SecondWrapper,
  CustomButton,
  TableHeader,
  TableHeaderChild,
  ModalCard,
  LoaderWrapper,
} from './admin-group-type-style';
import {useStoreContext} from '../../../../../../store/store-context';

import {LockIcon as AccountLock} from '../../../../../../ui/atoms/icons';
import {FlexRow} from '@ui/style/styles';

export const AdminGroupTypePage = () => {
  const {
    hideCreateGroupModal,
    showCreateGroupModal,
    hideEditGroupModal,
    showEditGroupModal,
    hideDeleteGroupModal,
    showDeleteGroupModal,
    register,
    errors,
    isSubmittingCreateGroupType,
    handleSubmit,
    submitCreateGroupType,
    disabledCreateGroupType,
    isFetchingGroupType,
    groupTypeData,
    groupTypeName,
    setGroupTypeName,
    setGroupTypeId,
    submitEditGroupType,
    submitDeleteGroupType,
    handleDelete,
    isSubmittingDeleteGroupType,
    setSearchGroupType,
    DisplayFreeTierModal,
    isAccountFreeTier,
    handleShowFreeTierModal,
    searchGroupType,
  } = useAdminGroupHook();
  const {
    groupTypeStore: {setGroupTypeIDInStore},
    groupStore: {setIsGroup},
    authStore: {auth},
  } = useStoreContext();

  const tooltipId = 'admin_group_type';

  const {data: tooltipStatus} = useFirebaseFetch(
    `users/${auth.user.id}/onboarding/tooltip/${tooltipId}`,
  );

  return (
    <>
      {DisplayFreeTierModal}
      <FirstWrapper>
        <span>
          <FlexRow>
            <Headline2 kind="textDark" weight="semibold">
              {isAccountFreeTier && (
                <span>
                  <AccountLock />
                </span>
              )}{' '}
              Manage group type
            </Headline2>
            <PlainButton
              type="button"
              style={{marginLeft: '2px'}}
              onClick={() =>
                updateTooltipStatus(tooltipId, {isDismissed: false})
              }>
              <AlertBadge />
            </PlainButton>
          </FlexRow>
        </span>
        <div className="flex justify-end  sm:mt-2 mt-0">
          <CustomButton
            kind="primary"
            width="sm"
            onClick={() =>
              isAccountFreeTier
                ? handleShowFreeTierModal(true)
                : showCreateGroupModal(true)
            }>
            Create group type
          </CustomButton>
        </div>
      </FirstWrapper>
      <div style={{padding: '0px 16px 16px'}}>
        <InformationBlock id={tooltipId} isDismissed={tooltipStatus}>
          {' '}
          Create and manage groups within your workspace
        </InformationBlock>
      </div>
      <Modal
        open={hideCreateGroupModal}
        onClose={() => showCreateGroupModal(false)}>
        <ModalWrapper>
          <BackAction
            icon={<CancelIcon />}
            title="Close"
            onClick={() => showCreateGroupModal(false)}
            width="md"
          />
          <VerticalSpacer size="16px" />
          <ModalCard>
            <form method="post" onSubmit={handleSubmit(submitCreateGroupType)}>
              <Headline2>New group type</Headline2>

              <VerticalSpacer size="24px" />
              <TextField
                label="What's the name of this group type?"
                placeholder="Enter group type here"
                ref={register}
                helper={errors.name?.message || ''}
                state={!!errors.name ? 'error' : 'default'}
                name="name"
              />
              <VerticalSpacer size="14px" />

              <Button
                kind="primary"
                width="full"
                disabled={disabledCreateGroupType}
                data-form-action={true}
                aria-busy={isSubmittingCreateGroupType}
                isLoading={isSubmittingCreateGroupType}>
                Create
              </Button>
            </form>
          </ModalCard>
        </ModalWrapper>
      </Modal>
      <Modal
        open={hideEditGroupModal}
        onClose={() => showEditGroupModal(false)}>
        <ModalWrapper>
          <BackAction
            icon={<CancelIcon />}
            title="Close"
            onClick={() => showEditGroupModal(false)}
            width="md"
          />
          <VerticalSpacer size="16px" />
          <ModalCard>
            <form method="patch" onSubmit={handleSubmit(submitEditGroupType)}>
              <Headline2>Edit group type</Headline2>

              <VerticalSpacer size="14px" />
              <TextField
                label="What's the name of this group type?"
                defaultValue={groupTypeName}
                ref={register}
                helper={errors.name?.message || ''}
                state={!!errors.name ? 'error' : 'default'}
                name="name"
              />
              <VerticalSpacer size="14px" />

              <Button
                kind="primary"
                width="full"
                disabled={disabledCreateGroupType}
                data-form-action={true}
                aria-busy={isSubmittingCreateGroupType}
                isLoading={isSubmittingCreateGroupType}>
                Save
              </Button>
            </form>
          </ModalCard>
        </ModalWrapper>
      </Modal>
      <Modal
        open={hideDeleteGroupModal}
        onClose={() => showDeleteGroupModal(false)}>
        <ModalWrapper>
          <BackAction
            icon={<CancelIcon />}
            title="Close"
            onClick={() => showDeleteGroupModal(false)}
            width="md"
          />
          <VerticalSpacer size="16px" />
          <ModalCard>
            <form
              method="delete"
              onSubmit={handleDelete(submitDeleteGroupType)}>
              <FirstWrapper>
                <Headline2>Delete group-type?</Headline2>
              </FirstWrapper>
              <VerticalSpacer size="8px" />
              <Body2 kind="textBody">
                Are you sure you want to delete{' '}
                <strong style={{color: '#1e1e2f'}}>{groupTypeName}</strong> from
                your list of group-types?
              </Body2>
              <VerticalSpacer size="30px" />
              <Button
                kind="primary"
                width="full"
                data-form-action={true}
                aria-busy={isSubmittingDeleteGroupType}
                isLoading={isSubmittingDeleteGroupType}>
                Delete
              </Button>
            </form>
          </ModalCard>
        </ModalWrapper>
      </Modal>
      <SecondWrapper>
        <div className="searchBox">
          <SearchBox
            state="performance"
            placeholder="Search by group type"
            onChange={(e) => setSearchGroupType(e.target.value.toLowerCase())}
          />
        </div>
        <div className="row-container">
          <div className="table-container">
            <TableHeader>
              <Body2 weight="semibold">Group type(s)</Body2>
              <Body2 weight="semibold">Number of groups</Body2>
              <Body2 weight="semibold">Action</Body2>
            </TableHeader>
            {isFetchingGroupType && (
              <LoaderWrapper>
                <ItemLoaderLine />
              </LoaderWrapper>
            )}
            {!isFetchingGroupType && groupTypeData.length === 0 && (
              <LoaderWrapper>
                <Body2 kind="textDark" align="center">
                  There are no results for group type{' '}
                  <strong>{searchGroupType}</strong>
                </Body2>
              </LoaderWrapper>
            )}
            {!isFetchingGroupType &&
              groupTypeData.map((groupType: any, index: number) => (
                <TableHeaderChild key={index}>
                  <FlexRow>
                    {groupType.isDefault && (
                      <div className="flex items-center mr-1">
                        <LockIcon />
                      </div>
                    )}{' '}
                    <Body2 kind="textDark">{capitalize(groupType.name)}</Body2>
                  </FlexRow>

                  <Body2 kind="textDark">{groupType.groupsCount}</Body2>
                  <div>
                    <CustomButton
                      kind="secondary"
                      onClick={() => {
                        setGroupTypeIDInStore(groupType.id);
                        setIsGroup(true);
                      }}>
                      View
                    </CustomButton>
                  </div>
                  {!groupType.isDefault ? (
                    <DropdownVertical
                      dropdownWrapperStyle={{
                        padding: 0,
                        right: '-83%',
                        minWidth: 214,
                        maxWidth: 214,
                      }}
                      menu={
                        <div>
                          <DropdownItem
                            onClick={() => {
                              showEditGroupModal(true);
                              setGroupTypeName(groupType.name);
                              setGroupTypeId(groupType.id);
                            }}>
                            Edit group type
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              showDeleteGroupModal(true);
                              setGroupTypeName(groupType.name);
                              setGroupTypeId(groupType.id);
                            }}>
                            Delete group type
                          </DropdownItem>
                        </div>
                      }
                    />
                  ) : (
                    <div></div>
                  )}
                </TableHeaderChild>
              ))}
          </div>
        </div>
      </SecondWrapper>
    </>
  );
};
