import {memo} from 'react';

export const OnTrackIcon = memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16">
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M15.335 4L9 10.333 5.668 7l-5 5"
    />
    <path strokeLinecap="round" strokeLinejoin="round" d="M11.332 4h4v4" />
  </svg>
));

OnTrackIcon.displayName = 'OnTrackIcon';

export const OnTrackingIcon = memo(() => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.3346 4L9.0013 10.3333L5.66797 7L0.667969 12"
      stroke="#1A9E68"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.332 4H15.332V8"
      stroke="#1A9E68"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));
