import {KPIBoardWrapper, KPISaveButton} from '../styles';
import {Body1, Body2} from '@ui/atoms/typography';
import {getGroupTypeAndGroup} from '@utils/get-group-grouptype';
import dayjs from 'dayjs';
import {AnalyticsRequestImpl} from '../../admin-analytics/admin-analytics.request';
import {AnalyticsController} from '../../admin-analytics/admin-analytics.controller';
import {useCallback, useState, useEffect, useMemo, useRef} from 'react';
import {observer} from 'mobx-react-lite';
import {AnaylyticsDateView} from '../../admin-analytics/analytics-shared-components/filterbox/dropdown';
import {AnalyticsGrid} from '../../admin-analytics/analytics-pages/analytics-status/status-component/analytics-grid';
import {StatusType} from '@hooks';
import {
  TableContainer,
  TableWrapper,
  SecondFlexHeader,
  SecondFlexItem,
  SecondMainChildHeader,
} from '../../admin-analytics/analytics-pages/analytics-status/analytics-status.styles';
import {ObjectiveAnalytics} from '../../admin-analytics/analytics-pages/analytics-status/insights-objectives';
import {SummaryWrapper} from '../../admin-analytics/analytics-pages/analytics-status/analytics-status.styles';
import {ItemLoader} from '@ui/organisms/item-loader';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {goalDueDate} from '@utils/date';
import {useStoreContext} from '@store/store-context';
import {GoalsMultiOptions} from '@ui/molecules/select/goals-multi-options/goals-multi-options';
import {FlexRowSpaceBetween, FlexRow} from '@ui/style/styles';

export const transformOptions = (
  goal: any,
  groups: any[],
  allGroupTypes: any[],
) => ({
  label: {
    goalType:
      goal.goalType === 'group'
        ? 'group'
        : goal.goalType === 'company'
        ? 'Company-wide'
        : goal.goalType,
    goalName: goal.name,
    startDate: goal.startDate,
    endDate: goal.endDate,
    group: groups.filter((group) => goal.group.includes(group.id)),
    isKpi: goal?.isKpi,
    progress: {
      due: goalDueDate(goal?.endDate),
      percent: goal?.isKpi ? goal?.currentValue : goal?.percentageProgress,
      state: `${goal?.percentageProgress}%`,
      // @ts-ignore
      type: goal?.performance,
      status: goal?.status,
      endDate: goal?.endDate,
    },
    assigneeName:
      goal.assignee &&
      goal.assignee.hasOwnProperty('firstName') &&
      goal.assignee.hasOwnProperty('lastName')
        ? `${goal.assignee?.firstName} ${goal.assignee?.lastName}`
        : goal.assignee?.email,
    assigneeAvatar: {
      src:
        goal.assignee?.avatar && goal.assignee?.avatar.url
          ? goal.assignee?.avatar.url
          : '',
      name:
        goal.assignee &&
        goal.assignee.hasOwnProperty('firstName') &&
        goal.assignee.hasOwnProperty('lastName')
          ? `${goal.assignee?.firstName} ${goal.assignee?.lastName}`
          : goal.assignee?.email,
      id: goal.assignee?.id,
    },
    date: dayjs(goal.createdAt).format('DD/MM/YYYY'),
    id: goal.id,
  },
  value: goal.id,
});
export const transformKeyResults = (goal: any) => ({
  label: {
    goalName: goal.name,
    assigneeName:
      goal.assignee &&
      goal.assignee.hasOwnProperty('firstName') &&
      goal.assignee.hasOwnProperty('lastName')
        ? `${goal.assignee?.firstName} ${goal.assignee?.lastName}`
        : goal.assignee?.email,
    assigneeAvatar: {
      src:
        goal.assignee?.avatar && goal.assignee?.avatar.url
          ? goal.assignee?.avatar.url
          : '',
      name:
        goal.assignee &&
        goal.assignee.hasOwnProperty('firstName') &&
        goal.assignee.hasOwnProperty('lastName')
          ? `${goal.assignee?.firstName} ${goal.assignee?.lastName}`
          : goal.assignee?.email,
      id: goal.assignee?.id,
    },
    id: goal.id,
  },
  value: goal.id,
});

export const KpiBoard = observer(({id}: {id?: string}) => {
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState([]);
  const [isUpdated, setUpdated] = useState(false);
  const [data, setData] = useState<any>();
  const [, setSelectedGoals] = useState([]);
  const [editing, setEditing] = useState(false);

  const {
    analyticsStore: {
      goalsDate,
      viewAnalyticsRange,
      searchable,

      kpiBoardGoals,
    },
    groupTypeStore: {groupType},
    groupStore: {groups},
    authStore: {auth},
  } = useStoreContext();

  const getGoals = useCallback(
    async (filters: any, view: string) => {
      setLoading(true);
      const request = new AnalyticsRequestImpl();
      const controller = new AnalyticsController(request);
      const response = await controller.fetchSpecifiedGoals(
        filters,
        view,
        true,
      );

      const transformGoalOptions = response?.goals.map((goal: any) =>
        transformOptions(goal, groups, groupType),
      );

      setOptions(transformGoalOptions);

      setLoading(false);
    },
    [groups, groupType],
  );
  const fetchGoals = useCallback(
    async (filters: any, view: string) => {
      setLoading(true);
      const request = new AnalyticsRequestImpl();
      const controller = new AnalyticsController(request);
      const response = await controller.fetchSpecifiedGoals(
        filters,
        view,
        true,
      );

      setData({
        ...response,
        goals: response?.goals.map((goal: any) => {
          if (goal.goalType === 'group') {
            const getGroup = getGroupTypeAndGroup(
              groups,
              groupType,
              goal.group,
            );

            return {
              ...goal,
              ...getGroup,
              groupType: {name: getGroup?.groupType},
            };
          }
          return goal;
        }),
      });
      setLoading(false);
    },
    [groups, groupType],
  );

  useEffect(() => {
    fetchGoals(
      {
        goalPage: 1,
        startDate: goalsDate.starts,
        endDate: goalsDate.ends,
        newReport: true,
        isKpi: true,
        goalIds: kpiBoardGoals?.map((goal: any) => goal?.value)?.join(','),
        filterBy: viewAnalyticsRange,
      },
      '',
    );
  }, [
    kpiBoardGoals,
    viewAnalyticsRange,
    goalsDate.starts,
    goalsDate.ends,
    fetchGoals,
  ]);
  useEffect(() => {
    getGoals(
      {
        goalPage: 1,
        startDate: goalsDate.starts,
        endDate: goalsDate.ends,
        member: searchable.members,
        newReport: true,
        isKpi: true,
        manager: searchable.managers
          ? searchable.managers
          : auth?.user?.role !== 'admin' && auth?.user?.isReviewer === true
          ? auth?.user?.id
          : undefined,
        department: searchable.department,
        departmentMembers: searchable.departmentMembers,
      },
      '',
    );
  }, [
    auth,
    searchable.department,
    searchable.departmentMembers,
    getGoals,
    goalsDate.starts,
    searchable.goalType,
    searchable.members,
    searchable.managers,
    goalsDate.ends,
  ]);
  const saveBoard = () => {
    setEditing(false);
  };

  const AnalyticsDateRange = useMemo(() => {
    const dates = new Map();

    const sortDates = (
      key: string,
      date: {start: string; isCurrentWeek?: boolean; end?: string},
    ) => {
      if (!dates.has(key)) {
        dates.set(key, date);
      }
    };

    const getDates = (type: 'day' | 'start-end') => {
      data?.goals?.map((goal: any) => {
        return goal.activities.map((activity: any) => {
          if (type === 'day') {
            sortDates(activity.startDate, {start: activity.startDate});
            return null;
          }

          return sortDates(activity.startDate, {
            start: activity.startDate,
            isCurrentWeek: dayjs(dayjs().format()).isBetween(
              activity.startDate,
              activity.endDate,
              'weeks',
              '[]',
            ),
            end: activity.endDate,
          });
        });
      });
    };

    switch (viewAnalyticsRange) {
      case 'days':
        getDates('day');
        return Array.from(dates).map((date) => date[1]);
      default:
        getDates('start-end');
        return Array.from(dates).map((date) => date[1]);
    }
  }, [viewAnalyticsRange, data?.goals]);

  const staticDates = useMemo(() => {
    const addMonths = new Map();
    const lastMonth: any = AnalyticsDateRange[AnalyticsDateRange.length - 1];
    if (AnalyticsDateRange.length < 3 && lastMonth && !!viewAnalyticsRange) {
      let count = 3 - AnalyticsDateRange.length;

      for (let i = 1; i <= count; i++) {
        const monthIncrement = {
          start: dayjs(lastMonth.start).add(i, viewAnalyticsRange).format(),
          end: dayjs(lastMonth.end).add(i, viewAnalyticsRange).format(),
        };
        addMonths.set(monthIncrement.start, monthIncrement);
      }
      return Array.from(addMonths).map((month) => month[1]);
    }
    return null;
  }, [viewAnalyticsRange, AnalyticsDateRange]);

  const currentWeekRef: any = useRef();
  const getDateRef: any = useRef();

  useEffect(() => {
    if (
      currentWeekRef &&
      viewAnalyticsRange === 'weeks' &&
      getDateRef.current &&
      !loading &&
      data?.goals?.length > 0
    ) {
      getDateRef.current.scrollLeft =
        currentWeekRef?.current?.getBoundingClientRect()?.left + 50;
    }
  }, [viewAnalyticsRange, loading, data?.goals]);

  const renderDates = (
    <SecondFlexHeader>
      {AnalyticsDateRange.map((value, index) => (
        <SecondFlexItem
          key={index}
          ref={value.isCurrentWeek ? currentWeekRef : null}>
          <Body2 weight={'semibold'} align="center">
            {`${dayjs(value.start).format('D MMM')} ${
              value.end ? `-  ${dayjs(value.end).format('D MMM')}` : ''
            } `}
          </Body2>
        </SecondFlexItem>
      ))}
      {staticDates &&
        staticDates.map((value, index) => (
          <SecondFlexItem key={index}>
            <Body2 weight={'semibold'} align="center" kind="textMuted">
              {`${dayjs(value.start).format('D MMM')} ${
                viewAnalyticsRange !== 'days'
                  ? `-  ${dayjs(value.end).format('D MMM')}`
                  : ''
              } `}
            </Body2>
          </SecondFlexItem>
        ))}
    </SecondFlexHeader>
  );

  if (loading) {
    return (
      <KPIBoardWrapper>
        <ItemLoader />
      </KPIBoardWrapper>
    );
  }
  return (
    <KPIBoardWrapper>
      <FlexRowSpaceBetween>
        <Body1 weight={'semibold'}>KPI Board</Body1>
        {kpiBoardGoals?.length > 0 && !editing ? (
          <KPISaveButton kind="secondary" onClick={() => setEditing(true)}>
            Edit
          </KPISaveButton>
        ) : (
          <KPISaveButton
            kind="secondary"
            onClick={saveBoard}
            disabled={!isUpdated}>
            Save
          </KPISaveButton>
        )}
      </FlexRowSpaceBetween>
      <VerticalSpacer size="10px" />
      <GoalsMultiOptions
        options={options}
        defaultValue={kpiBoardGoals}
        disabled={kpiBoardGoals?.length > 0 && !editing}
        onChange={(data: any) => {
          !isUpdated && setUpdated(true);
          setSelectedGoals(data);
        }}
        placeholder="+ Add KPIs"
      />
      {kpiBoardGoals?.length > 0 && data?.goals?.length > 0 && (
        <>
          <SummaryWrapper>
            <FlexRowSpaceBetween
              style={{
                paddingBottom: '16px',
              }}>
              <Body2 weight="bold">Summary ({data?.summary?.kpis})</Body2>

              <AnaylyticsDateView />
            </FlexRowSpaceBetween>
          </SummaryWrapper>
          <>
            <TableContainer
              style={{
                border: '1px solid #ededf2',
                borderRadius: '10px',
              }}>
              <TableWrapper>
                {
                  <>
                    <div style={{display: 'flex', height: '110%'}}>
                      <div
                        style={{
                          boxShadow: '3px 0px 2px rgba(172, 172, 190, 0.2)',
                          paddingBottom: '32px',
                        }}>
                        <FlexRow>
                          <SecondMainChildHeader>
                            <span>KPIs</span>
                          </SecondMainChildHeader>
                        </FlexRow>
                        {data?.goals.map((goal: any, index: number) => (
                          <ObjectiveAnalytics
                            goal={goal}
                            removeAvatar
                            style={{minWidth: '450px'}}
                            index={index}
                            open={false}
                            key={index}
                          />
                        ))}
                      </div>
                      <div
                        ref={getDateRef}
                        style={{
                          overflowX: 'auto',
                          overflowY: 'hidden',
                          marginLeft: '3.5px',
                        }}>
                        <div
                          style={{
                            minWidth: 'fit-content',
                          }}>
                          {renderDates}
                          {data?.goals.map((goal: any) => (
                            <>
                              <AnalyticsGrid
                                activities={goal.activities}
                                isKeyResultValue
                                showPercentageInValue
                                completion={
                                  goal?.progress === 100 ||
                                  goal?.status === StatusType.COMPLETED ||
                                  goal?.status === StatusType.INCOMPLETED
                                }
                                staticDates={staticDates}
                              />
                            </>
                          ))}
                        </div>
                      </div>
                    </div>
                  </>
                }
              </TableWrapper>
            </TableContainer>
          </>
        </>
      )}
    </KPIBoardWrapper>
  );
});
