import styled from 'styled-components';
import {AnimateSharedLayout} from 'framer-motion';
import {ChangeEvent, memo, ReactElement, useCallback} from 'react';
import {Label} from '../../../atoms/label';
import {FieldWrapper} from '../../../atoms/field-wrapper';
import {Helper} from '../../../atoms/helper';
import {GoalKind, InputState} from '../../../interface';
import {getColorFromTheme} from '../../../ui-utils';
import {GoalType} from '../../../atoms/goal-type';
import {VerticalSpacer} from '../../../atoms/spacer';

export interface TGoalTypeFieldProps {
  label?: string;
  name?: string;
  value: GoalKind;
  state?: InputState;
  helper?: string | ReactElement;
  setValue: (value: GoalKind) => void;
  onClick?: (data: any) => void;
}

const Wrapper = styled.div`
  position: relative;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 8px 16px 8px 8px;
  border-radius: 10px;
  border: 1px solid ${getColorFromTheme('borderLight')};
  background-color: ${getColorFromTheme('backgroundLight')};
  @media (max-width: 499px) {
    padding: 8px;
    justify-content: space-between;
  }
`;

export const GoalTypeField = memo(function ({
  state,
  helper,
  label,
  name,
  setValue,
  onClick,
  value,
  ...rest
}: TGoalTypeFieldProps) {
  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const {value} = e.target;
      setValue(value as GoalKind);
    },
    [setValue],
  );

  return (
    <FieldWrapper>
      <Wrapper>
        <Label aria-label="label">{label}</Label>
        <VerticalSpacer size="8px" />
        <AnimateSharedLayout>
          <Container>
            <GoalType
              id={`${name}-goal-personal`}
              kind={GoalKind.INDIVIDUAL}
              value={GoalKind.INDIVIDUAL}
              name={name}
              checked={value === GoalKind.INDIVIDUAL}
              onChange={onChange}
              onClick={onClick}
              {...rest}
            />
            <GoalType
              id={`${name}-goal-group`}
              kind={GoalKind.GROUP}
              name={name}
              value={GoalKind.GROUP}
              checked={value === GoalKind.GROUP}
              onChange={onChange}
              onClick={onClick}
              {...rest}
            />
            <GoalType
              id={`${name}-goal-company`}
              kind={GoalKind.COMPANY}
              value={GoalKind.COMPANY}
              checked={value === GoalKind.COMPANY}
              onChange={onChange}
              onClick={onClick}
              name={name}
              {...rest}
            />
          </Container>
        </AnimateSharedLayout>
        <Helper aria-label="helper" state={state} children={helper} />
      </Wrapper>
    </FieldWrapper>
  );
});

GoalTypeField.displayName = 'GoalTypeField';
