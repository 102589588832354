import styled from 'styled-components';
import {Typography} from '../typography/typography';
import {
  getColorFromTheme,
  getFontSizeFromTheme,
  getFontWeightFromTheme,
  getLineHeightFromTheme,
} from '../../ui-utils';
import {memo} from 'react';
import {HorizontalSpacer} from '../spacer';
import {Microcopy} from '../typography';
import {Tooltip} from '../../molecules/tooltip';

export const Tag = styled.span`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  ${Typography};

  color: ${getColorFromTheme('white')};
  font-size: ${getFontSizeFromTheme('microcopy')};
  font-weight: ${getFontWeightFromTheme('semibold')};
  line-height: ${getLineHeightFromTheme('microcopy')};
  border-radius: 100%;
  background-color: ${getColorFromTheme('orange300')};
`;

export const TextTag = styled.span`
  border: solid 1px ${getColorFromTheme('borderDark')};
  background-color: ${getColorFromTheme('backgroundLight')};
  border-radius: 5px;
  ${Typography};

  font-size: ${getFontSizeFromTheme('microcopy')};
  font-weight: ${getFontWeightFromTheme('semibold')};
  line-height: ${getLineHeightFromTheme('microcopy')};
  color: ${getColorFromTheme('textMedium')};
  padding: 4px 8px;
`;

export const TextTag2 = styled.span`
  background-color: ${getColorFromTheme('backgroundDark')};
  border-radius: 8px;
  ${Typography};

  font-size: ${getFontSizeFromTheme('microcopy')};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: ${getFontWeightFromTheme('semibold')};
  line-height: ${getLineHeightFromTheme('microcopy')};
  color: ${getColorFromTheme('textDark')};
  font-weight: 500;

  padding: 8px 14px;
  max-height: 36px;
  width: fit-content;
`;

const TextTag2GroupWrapper = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-right: 8px;
  }
  .inner {
    display: flex;
    align-items: center;
  }
`;

export const TextTag2Group = memo(({list = []}: {list?: string[]}) => {
  return (
    <TextTag2GroupWrapper>
      {list.length <= 2 ? (
        list?.map((item, key) => (
          <>
            <Tooltip
              key={key + item}
              BodyTextNodeType={TextTag2}
              text={item.slice(0, 9) + ' ...'}
              tooltipBody={<span>{item}</span>}
            />
          </>
        ))
      ) : (
        <div className="inner">
          {list.slice(0, 2)?.map((item, key) => (
            <Tooltip
              key={key + item}
              BodyTextNodeType={TextTag2}
              text={item.slice(0, 7) + ' ...'}
              tooltipBody={<span>{item}</span>}
            />
          ))}
          <HorizontalSpacer size="8px" />
          <Tooltip
            BodyTextNodeType={Microcopy}
            text={`+${list.length - 2}`}
            bodyStyle={{fontWeight: 500, fontSize: 12}}
            tooltipBody={
              <span>
                {list.map((item: string, index: number) => (
                  <span>
                    {`${item}${index === list.length - 1 ? '' : ', '}`}
                  </span>
                ))}
              </span>
            }
          />
        </div>
      )}
    </TextTag2GroupWrapper>
  );
});
