import {Body1, Body2} from '@ui/atoms/typography';
import {useStoreContext} from '@store/store-context';
import {capitalize} from '@utils';
import {useState} from 'react';
import {GroupDropdown} from './group-dropdown';
import {LockIcon} from '@ui/atoms/icons/lock';
import {LockIcon as AccountLock} from '@ui/atoms/icons';
import {TextField} from '@ui/molecules/field/textfield';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {QuestionIconWrapper} from '@ui/molecules/tooltip/tooltip';
import {AlertBadge} from '@ui/atoms/icons/alert-badge';
import {Label} from '@ui/atoms/label';
import {useAdminGroupHook} from '../admin/admin-components/admin-people/admin-group-type/admin-group-type-hooks';
import {useDropDown} from '@hooks';
import {
  GroupTypeWrapper,
  FirstWrapper,
  SecondWrapper,
  CustomButton,
  ViewButton,
  DropdownWrapper,
  TableHeader,
  TableHeaderChild,
  LoaderWrapper,
} from './styles';

export const GroupType = ({viewGroup}: {viewGroup: (e: string) => void}) => {
  const {
    groupTypeStore: {groupType},
  } = useStoreContext();

  const [searchGroupType] = useState('');

  const [newGroupTypeName, setNewGroupTypeName] = useState('');

  const {open, handleOpen, handleClose, ref} = useDropDown();

  const {
    submitCreateGroupType,
    DisplayFreeTierModal,
    isAccountFreeTier,
    handleShowFreeTierModal,
  } = useAdminGroupHook();

  return (
    <GroupTypeWrapper>
      <FirstWrapper>
        <span>
          <Body1 kind="textDark" weight="semibold">
            {isAccountFreeTier && (
              <span>
                <AccountLock />
              </span>
            )}{' '}
            Manage groups
            <QuestionIconWrapper tooltip={true}>
              <AlertBadge />
              <span className="tooltip">
                To edit and delete group, go to <strong>Manage People</strong>.
              </span>
            </QuestionIconWrapper>
          </Body1>
        </span>
        {DisplayFreeTierModal}
        <div style={{position: 'relative'}}>
          <CustomButton
            kind="primary"
            width="sm"
            onClick={(event) =>
              isAccountFreeTier
                ? handleShowFreeTierModal(true)
                : handleOpen(event as any)
            }>
            Create group type
          </CustomButton>
          {open && (
            <DropdownWrapper ref={ref}>
              <GroupDropdown
                title="New group type"
                onClickCancel={handleClose}
                disabled={!newGroupTypeName}
                onSubmit={() => {
                  submitCreateGroupType({name: newGroupTypeName});
                  handleClose();
                  setNewGroupTypeName('');
                }}>
                <div>
                  <Label>What’s the name of the group type?</Label>
                  <VerticalSpacer size="8px" />
                  <TextField
                    placeholder="Enter group type here "
                    margin
                    onChange={(e) =>
                      setNewGroupTypeName(e.target.value.toLowerCase())
                    }
                    value={newGroupTypeName}
                  />
                </div>
              </GroupDropdown>
            </DropdownWrapper>
          )}
        </div>
      </FirstWrapper>
      <SecondWrapper>
        <TableHeader>
          <Body2 weight="semibold">Group type(s)</Body2>
          <Body2 weight="semibold">Number of groups</Body2>
          <Body2 weight="semibold">Action</Body2>
        </TableHeader>

        {groupType.length === 0 && (
          <LoaderWrapper>
            <Body2 kind="textDark" align="center">
              There are no results for group type{' '}
              <strong>{searchGroupType}</strong>
            </Body2>
          </LoaderWrapper>
        )}
        {groupType.map((groupType: any, index: number) => (
          <TableHeaderChild key={index}>
            <button onClick={() => null}>
              <Body2 kind="textDark">
                {groupType.isDefault && <LockIcon />}{' '}
                {capitalize(groupType.name)}
              </Body2>
            </button>
            <Body2 kind="textDark" style={{marginTop: '8px'}}>
              {groupType.groupsCount}
            </Body2>
            <ViewButton
              kind="secondary"
              onClick={() => viewGroup(groupType.id)}>
              View
            </ViewButton>
          </TableHeaderChild>
        ))}
      </SecondWrapper>
    </GroupTypeWrapper>
  );
};
