import React from 'react';
import styled from 'styled-components';
import {Body1} from '@ui/atoms/typography';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {Button} from '@ui/atoms/button';

const Wrapper = styled.div<{height: any; width?: string}>`
  border-radius: 6px 6px 0px 0px;
  max-height: ${({height}) => (height ? `${height}px` : '428px')};
  z-index: 5;
  background: white;
  overflow: auto;
  border: 1px solid #dcdce2;
  position: relative;
  width: ${({width}) => (width ? width : '400px')};
`;

const CardHeader = styled.div`
  padding: 16px 16px 4px;
`;

const CardBody = styled.div<{height: any}>`
  padding: 0px 16px 16px;
  overflow: auto;
`;

const Footer = styled.div`
  border-width: 0px 1px 1px 1px;
  border-color: rgba(206, 206, 222, 1);
  border-radius: 0px 0px 6px 6px;

  border-style: solid;
  padding: 12px 16px;
  display: flex;
  z-index: 3;
  background: white;

  justify-content: flex-end;
  position: relative;
`;
interface CardProps {
  title: string;
  children: any;
  height?: any;
  disabled?: any;
  onClickCancel: (e: any) => void;
  width?: string;
}
export function RequestDropdown({
  title,
  children,
  height,

  width,
  onClickCancel,
}: CardProps) {
  return (
    <>
      <Wrapper height={height} width={width}>
        <CardHeader>
          <Body1 weight="bold">{title}</Body1>
        </CardHeader>
        <VerticalSpacer size="6px" />

        <CardBody height={height}>{children}</CardBody>
      </Wrapper>
      <Footer>
        <Button
          kind="secondary"
          style={{
            width: '79px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
          }}
          onClick={onClickCancel}>
          Close
        </Button>
      </Footer>
    </>
  );
}
