import {Button} from '@ui/atoms/button';
import {CustomColorIcon} from '@ui/atoms/color-icons';
import {CancelIcon} from '@ui/atoms/icons';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {Headline2, Body1} from '@ui/atoms/typography';
import {ModalCard} from '@ui/layouts/modal-card';
import {BackAction} from '@ui/molecules/back-action';
import {Modal} from '@ui/molecules/modal';
import {Flex} from '@ui/style/styles';
import React from 'react';

import {useBreakpoints} from '@utils/use-breakpoints';
import {useStoreContext} from '@store/store-context';

export const DeleteGoal = ({handleContinue}: {handleContinue: () => void}) => {
  const screenSize = useBreakpoints();

  const {goalsState} = useStoreContext();

  return (
    <Modal open={true} onClose={() => {}}>
      <div
        style={{
          maxWidth: screenSize.isXs ? '90%' : '500px',
          margin: 'auto',
        }}>
        {screenSize.isXs ? (
          <BackAction
            width="md"
            icon={<CancelIcon />}
            onClick={() => {
              goalsState.setDeleteModal('', false, '', '', []);
            }}
            title="Close"
          />
        ) : (
          <div style={{width: '100%'}}>
            <BackAction
              width="md"
              icon={<CancelIcon />}
              onClick={() => {
                goalsState.setDeleteModal('', false, '', '', []);
              }}
              title="Close"
            />
          </div>
        )}

        <VerticalSpacer size="16px" />
        <ModalCard align="center" title={``}>
          <Flex flexDir="column" justifyContent="center" alignItems="center">
            <VerticalSpacer size="16px" />
            <Flex alignItems="center" justifyContent="center">
              <CustomColorIcon
                color="#F7E4E4"
                width="80px"
                margin={0}
                height="80px"
              />
            </Flex>
            <VerticalSpacer size="20px" />

            <span>
              <Headline2 align="center">
                Delete {goalsState.goalFramework}?
              </Headline2>
            </span>
            <VerticalSpacer size="20px" />

            <Body1 align="center" kind="textBody">
              Are you sure you want to delete your {goalsState.goalFramework}{' '}
              <span style={{color: '#585ADF', fontWeight: 500}}>
                {goalsState.deleteName}
              </span>
              . This cannot be undone.
            </Body1>
            <VerticalSpacer size="34px" />
            <Button
              kind="secondary"
              style={{
                padding: '12px 24px',
                width: '200px',
              }}
              onClick={() => {
                handleContinue();

                goalsState.setDeleteModal('', false, '', '', []);
              }}>
              Delete {goalsState.goalFramework}
            </Button>
          </Flex>
        </ModalCard>
      </div>
    </Modal>
  );
};
