import styled from 'styled-components';
import {down} from 'styled-breakpoints';
import {Body1} from '@ui/atoms/typography';
import {Button} from '@ui/atoms/button';
import {FlexRow} from '@ui/style/styles';

const HeaderWrapper = styled.div<{secondStepActive?: boolean}>`
  background: #fafafa;
  position: relative;
  border-bottom: 1px solid #ededf2;
  height: 68px;
  display: flex;

  justify-content: center;
  align-items: center;
  .container {
    min-width: 620px;
    display: flex;

    align-items: center;
    justify-content: center;
  }
  .rule {
    width: 45px;
    height: 1px;
  }
  .fade-out {
    opacity: 0.3;
  }
  .disabled {
    background: transparent;
  }
  .g-border {
    border: 1px solid #47b881;
  }
  .b-border {
    border: 1px solid #cecede;
  }
  button {
    width: 180px;
    overflow: hidden;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    box-shadow: 0px 3px 24px rgba(208, 208, 221, 0.16);
    p {
      font-size: 16px;
      ${down('md')} {
        font-size: 14px;
      }
    }
    ${down('md')} {
      width: 155px;
    }
  }
`;

interface PageStepProps {
  steps: {
    title: string;
  }[];
  activeStep: number;
  onChange?: (value: number) => void;
}

export const PageStep = ({steps, activeStep, onChange}: PageStepProps) => {
  const computeWeight = (idx: number) => {
    if (activeStep > idx) return 'semibold';
    if (activeStep === idx) return 'bold';
    return 'regular';
  };

  const computeKind = (idx: number) => {
    if (activeStep > idx) return 'green300';
    if (activeStep === idx) return 'textDark';
    return 'textBody';
  };

  const computeButtonClassName = (idx: number) => {
    if (activeStep > idx) return ' g-border';
    if (activeStep === idx) return 'b-border';
    return 'disabled b-border';
  };
  const handleClick = (step: number) => {
    if (onChange) onChange(step);
  };
  return (
    <div>
      {' '}
      <HeaderWrapper secondStepActive={activeStep === 2}>
        <div className="container">
          {steps.map((step, idx) => (
            <FlexRow key={step.title}>
              {idx !== 0 && (
                <div
                  className={`rule ${activeStep > idx + 1 ? 'fade-out ' : ''}`}
                  style={{
                    background: activeStep > idx + 1 ? '#47B881' : '#cecede',
                  }}></div>
              )}
              <Button
                kind="secondary"
                style={{margin: 0}}
                onClick={() => activeStep > idx + 1 && handleClick(idx + 1)}
                className={computeButtonClassName(idx + 1)}>
                <Body1
                  kind={computeKind(idx + 1)}
                  weight={computeWeight(idx + 1)}>
                  {step.title}
                </Body1>
              </Button>
            </FlexRow>
          ))}
        </div>
      </HeaderWrapper>
    </div>
  );
};
