import styled from 'styled-components';
import {Typography} from '../../../../../../../../ui/atoms/typography';
import {getColorFromTheme} from '../../../../../../../../ui/ui-utils';

export const DropdownWrapper = styled.div`
  text-align: right;
  margin-left: 145px;
`;

export const HeadlineWrapper = styled.div`
  display: flex;
`;

export const HeadlineWrapper2 = styled.div`
  display: grid;
  grid-template-columns: 61% 30%;
  padding-left: 16px;
  grid-column-gap: 12px;
  width: 100%;
`;

export const HeadlineWrapperContainer = styled.div`
  display: block;
  border-radius: 0 0 0px 0px;
  padding: 16px 16px 12px 16px;
  background-color: ${getColorFromTheme('backgroundLight')};
  height: 52px;
`;

export const HeadlineWrapperContainer2 = styled.div`
  display: block;
  border-radius: 0 10px 0px 0px;
  padding: 16px 16px 12px 10px;
  background-color: ${getColorFromTheme('backgroundLight')};
  height: 52px;
`;

export const TableContainer = styled.div`
  border-right: 1px solid #ededf2;
  border-top: 1px solid #ededf2;
  border-left: 1px solid #ededf2;
  border-bottom: 1px solid #ededf2;
  border-radius: 10px;
`;

export const ItemLoaderWrapper = styled.div`
  padding: 28px 12px 32px 22px;
  justify-content: center;
  width: 100%;
  border-top: 1px solid #ededf2;
`;

export const ItemWrapperContainer = styled.div`
  display: block;
  border-radius: 10px
  box-shadow: 0px 0px 0px 1px ${getColorFromTheme('borderLight')};
`;

export const ItemWrapperContainer2 = styled.div`
  display: block;
  border-radius: 10px
  box-shadow: 0px 0px 0px 1px ${getColorFromTheme('borderLight')};
`;

export const ItemWrapper = styled.div`
  height: 64px;
  display: flex;
  padding-left: 16px;
  border-top: 1px solid ${getColorFromTheme('borderLight')};
  align-items: center;
  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const ItemWrapper2 = styled.div`
  height: 64px;
  display: grid;
  grid-template-columns: 35% 30%;
  grid-column-gap: 12px;
  padding: 10px 12px 10px 10px;
  border-top: 1px solid ${getColorFromTheme('borderLight')};
  align-items: center;
  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const TagWrapper = styled.div`
  padding: 4px 7px;
  text-align: center;
  font-size: '14px';
`;

export const DropdownItem = styled.button`
  ${Typography};
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  cursor: pointer;
  border: none;
  background: none;
  display: block;
  outline: none;
  line-height: 1.7;
  padding: 6px 16px;
  transition: all ease-in-out 200ms;
  color: ${getColorFromTheme('textBody')};

  &:hover {
    background-color: ${getColorFromTheme('backgroundDark')};
    color: ${getColorFromTheme('textDark')};
  }
`;

export const NoItemWrapper = styled.div`
  display: grid;
  text-align: center;
  justify-content: center;
  padding: 16px;
  border-radius: 0px 0px 10px 10px;
  border-top: 1px solid #ededf2;
`;

export const SecondRowWrapper = styled.div`
  width: 100%;

  @media (max-width: 800px) {
    max-width: 100%;
    overflow-x: auto;
    display: grid;
  }
`;

export const PaginationUl = styled.ul`
  list-style: none;
  width: 239px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 32px 201px 0 315px;
  padding: 4px;
  border-radius: 5px;
  border: solid 1px #cecede;
  background-color: #f6f6f8;

  @media (max-width: 500px) {
    margin: 32px 0px 0px;
  }
`;

export const PaginationLi = styled.li`
padding: 8px;
cursor: pointer;
margin: 0 1px;
color: #5f5f8c;

&:hover {
  color: #585adf;
}

  &.active { 
    width: 32px;
    height: 32px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 3px 12px;
    border-radius: 4px;
    box-shadow: 0 2px 5px 0 rgba(172, 172, 190, 0.24);
    background-color: #ffffff;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: normal;
    text-align: left;
    color: #585adf;
    transition: background-color 0.3s ease-in;
    }
  };
`;

export const PaginationButton = styled.button`
  border: none;
  stroke: #5f5f8c;
  stroke-width: 1.5px;
  fill: none;
  width: 100%;
  height: 100%;
  padding-top: 4px;

  &:hover {
    stroke: black;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    stroke: #5f5f8c;
  }
};
`;
