import PageLayoutTemplate from '@ui/templates/page-template/page-layout';
import {useNavigate} from 'react-router';
import {Body2} from '@ui/atoms/typography';
import {ThickPlusIcon} from '@ui/atoms/icons/thick-plus';
import {Button} from '@ui/atoms/button';
import {Wrapper, AdditionalOptionsWrapper} from './styles';
import {MoreVerticalIcon} from '@ui/atoms/icons';
import {activateNotification} from '@ui/molecules/notification/activate-notification';
import nextId from 'react-id-generator';
import {DropdownVertical} from '@ui/molecules/dropdown-vertical';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {TextField} from '@ui/molecules/field/textfield';
import {useStoreContext} from '@store/store-context';
import {useState, useMemo} from 'react';
import {useFetchHook} from '@hooks/query-hook';
import {CcController} from '@api/cc-api/cc-controller';
import {CcRequestImpl} from '@api/cc-api/cc-request';
import {FlexRowSpaceBetween, FlexRow} from '@ui/style/styles';

interface ICategory {
  value: string;
  id: string;
  error?: string;
}

const CreateCategories = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [categories, setCategories] = useState<ICategory[]>([
    {
      value: '',
      id: nextId('categ'),
    },
  ]);

  const request = new CcRequestImpl();
  const controller = new CcController(request);

  const {data} = useFetchHook('cc-review-setup', () =>
    controller.fetchCcReviewSetup(),
  );

  const requestData: any = data;

  const {
    ccStore: {workspaceImpressionDetails},
  } = useStoreContext();

  const updateCategory = (id: string, value: string) => {
    const categ = categories.map((category) =>
      id === category.id ? {...category, value, error: ''} : category,
    );

    setCategories(categ);
  };

  const deleteCategory = (id: string) => {
    const categ = categories.filter((category) => id !== category.id);
    setCategories(categ);
  };

  const categoriesCount = useMemo(() => {
    return requestData?.competenceCategories?.length || 0;
  }, [requestData]);

  const addCategory = () => {
    if (categoriesCount < 49) {
      setCategories((prev) => [
        ...prev,
        {
          value: '',
          id: nextId('categ'),
        },
      ]);
    }
  };

  const isValid = categories.every((category) => category.value);

  const handleSubmit = async () => {
    const duplicateTextValues = categories.map((category, index, categories) =>
      categories.findIndex(
        (t) =>
          t.value?.trim().toLowerCase() ===
          category.value?.trim().toLowerCase(),
      ) !== index
        ? {
            ...category,
            error: 'Name already exist',
          }
        : category,
    );

    if (duplicateTextValues.some((error) => error.error)) {
      setCategories(duplicateTextValues);
      return;
    }
    setLoading(true);

    const formattedCategories = [
      ...requestData?.competenceCategories,
      ...categories.map((category) => {
        return {
          name: category.value,
        };
      }),
    ];

    const response = await controller.updateCcReviewSetup({
      rankings: workspaceImpressionDetails,
      competenceCategories: formattedCategories,
    });
    setLoading(false);

    response &&
      activateNotification({
        title: `Success`,
        content: `Categories added`,
        kind: 'success',
      });
    response && navigate(-1);
  };

  return (
    <PageLayoutTemplate
      title={'Create competence categories'}
      onClick={() => navigate(-1)}>
      <Wrapper style={{}}>
        <div className="form">
          <VerticalSpacer size="24px" />

          <FlexRowSpaceBetween>
            <Body2 weight="bold">
              Add company, group or individual categories{' '}
            </Body2>
            <Body2 weight="bold">
              {categoriesCount}
              <span style={{color: '#5F5F8C'}}>/50</span>
            </Body2>
          </FlexRowSpaceBetween>
          <VerticalSpacer size="12px" />
          <div className="body-wrapper">
            {categories.map((category, index) => (
              <>
                <FlexRow style={{width: '100%'}} key={index}>
                  <TextField
                    style={{width: '100%'}}
                    margin
                    value={category.value}
                    helper={category?.error}
                    state={category?.error ? 'error' : 'default'}
                    onChange={(event) =>
                      updateCategory(category.id, event.target.value)
                    }
                    inputStyle={{borderRadius: '10px'}}
                    placeholder="Enter category here"
                  />
                  {(category.value || index !== 0) && (
                    <div style={{marginLeft: '6px'}}>
                      <DropdownVertical
                        dropdownWrapperStyle={{}}
                        customIcon={<MoreVerticalIcon />}
                        menu={
                          <div>
                            <DropdownItem
                              onClick={() => {
                                deleteCategory(category.id);
                              }}>
                              Delete
                            </DropdownItem>
                          </div>
                        }
                      />
                    </div>
                  )}
                </FlexRow>
                <VerticalSpacer size="12px" />
              </>
            ))}
            <AdditionalOptionsWrapper
              display
              onClick={addCategory}
              disabled={categories?.length >= 50}>
              <ThickPlusIcon color="#47B881" />
              <Body2 weight="bold" kind="textBody" style={{marginLeft: '4px'}}>
                Add category
              </Body2>
            </AdditionalOptionsWrapper>
          </div>
          <VerticalSpacer size="24px" />
          <Button
            style={{padding: '16px 32px'}}
            width="full"
            isLoading={loading}
            disabled={!isValid}
            onClick={handleSubmit}>
            Publish categories
          </Button>
        </div>
      </Wrapper>
    </PageLayoutTemplate>
  );
};

export default CreateCategories;
