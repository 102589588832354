import {Headline2, Body1, Body2} from '@ui/atoms/typography';
import {
  FlexRow,
  FlexRowCenter,
  FlexRowEnd,
  FlexRowSpaceBetween,
} from '@ui/style/styles';
import {memo, useState} from 'react';
import {SecondaryPlusIcon} from '@ui/atoms/icons/plus';
import {PadIcon} from '@ui/atoms/icons/pad';
import {HorizontalSideRule, VerticalSpacer} from '@ui/atoms/spacer/spacer';
import {removeFirebaseData, updateFirebaseData} from '@utils/firebase-handler';
import {PenEditIcon} from '@ui/atoms/icons/pen-edit';
import {ArrowDownSecondary} from '@ui/atoms/icons/arrow-down';
import {ArrowUpSecondary} from '@ui/atoms/icons/arrow-up';
import {TrashIcon} from '@ui/atoms/icons/trash';
import {useDashboardHook} from './dashboard-hook';
import {RenameSection} from './rename-section';
import {CreateSection} from './create-section';
import {DeleteSection} from './delete-section';
import {ItemLoader} from '@ui/organisms/item-loader';
import {WidgetLayout} from './widget-layout';
import {EmptyDashboardState} from './empty-dashboard';
import {CancelIcon} from '@ui/atoms/icons';
import {ModalCard} from '@ui/layouts/modal-card';
import {BackAction} from '@ui/molecules/back-action';
import {Modal} from '@ui/molecules/modal';
import {EditInsight} from '../insights/edit-insight/edit-insight';
import {ModalWrapper} from '../insights/styles';

const ReportingDashboard = memo(() => {
  const {
    allSections,
    updateOrder,
    insights,
    data,
    isLoading,
  } = useDashboardHook();

  const [editInsight, setEditInsight] = useState('');

  const editedInsight = (id: string) => insights?.[id];

  const insight = editedInsight(editInsight);

  const [showNewSectionModal, setNewSectionModal] = useState(false);

  const [renameSectionId, setRenameSectionModal] = useState('');

  const [deleteSectionid, setDeleteSectionId] = useState('');

  const [isEditingLayout, setIsEditingLayout] = useState(false);

  const isEmpty =
    !allSections.length ||
    allSections.every((section) => !section.widget.length);

  return (
    <div>
      <FlexRowSpaceBetween className="mb-6">
        <div className="">
          <Headline2>Dashboard</Headline2>
          <div className="w-[80%] mt-1">
            <Body1 kind="textBody">
              Customise this space to focus on what matters most to you, and
              easily navigate to detailed reports and features.
            </Body1>
          </div>
        </div>
      </FlexRowSpaceBetween>
      <div className="bg-white rounded-[10px] mt-6 p-6 border border-borderLight h-full  gap-4">
        {isEmpty && !isLoading ? <EmptyDashboardState /> : null}

        {!isEmpty ? (
          <FlexRowEnd className="pb-6 gap-3 mr-3">
            <button onClick={() => setNewSectionModal(true)}>
              <FlexRow className="gap-3">
                <SecondaryPlusIcon />
                <Body2 weight="semibold" kind="purple300">
                  New section
                </Body2>
              </FlexRow>
            </button>

            <HorizontalSideRule size="20px" />

            <button onClick={() => setIsEditingLayout(!isEditingLayout)}>
              <FlexRow className="gap-2">
                <PadIcon />
                <Body2 weight="semibold" kind="purple300">
                  {isEditingLayout ? 'Exit layout edit' : '  Edit layout'}
                </Body2>
              </FlexRow>
            </button>
          </FlexRowEnd>
        ) : null}

        {!allSections.length && isLoading && (
          <>
            <FlexRowCenter className="min-h-[200px]">
              <ItemLoader />
            </FlexRowCenter>
          </>
        )}

        {!isLoading &&
          !isEmpty &&
          allSections.map((section) => (
            <div>
              <div
                key={section.name}
                className="shadow-[0_3px_24px_0_rgba(208,208,221,0.16)] bg-white border border-borderLight pt-4 pb-6 px-6 mb-6  rounded-[10px]">
                <FlexRowSpaceBetween>
                  <FlexRow className="mb-4 gap-2">
                    <Body1 weight="bold">{section.name}</Body1>
                    <button
                      className="mb-1"
                      onClick={() => setRenameSectionModal(section.firebaseId)}>
                      <PenEditIcon />
                    </button>
                  </FlexRow>

                  {isEditingLayout && (
                    <>
                      <FlexRow className="gap-4">
                        <FlexRow className="gap-1">
                          <button
                            onClick={() =>
                              updateOrder(section.order + 1, section.firebaseId)
                            }>
                            <ArrowDownSecondary />
                          </button>

                          <button
                            onClick={() =>
                              updateOrder(section.order - 1, section.firebaseId)
                            }>
                            <ArrowUpSecondary />
                          </button>
                        </FlexRow>

                        <button
                          disabled={!section.canDelete}
                          onClick={() =>
                            setDeleteSectionId(section.firebaseId)
                          }>
                          <TrashIcon
                            fill={section.canDelete ? '#D52A2A' : '#BFBFD4'}
                          />
                        </button>
                      </FlexRow>
                    </>
                  )}
                </FlexRowSpaceBetween>

                <div>
                  <WidgetLayout
                    handleEditLayout={setEditInsight}
                    isEditing={isEditingLayout}
                    onAdd={(widgets) => {
                      widgets.forEach(
                        (widget: {firebaseId: string}, index: any) => {
                          if (widget.firebaseId) {
                            const firebaseId = widget.firebaseId;

                            const insight = insights[firebaseId];

                            if (insight && insight.section !== section.slug) {
                              updateFirebaseData(`insights/${firebaseId}`, {
                                ...insight,
                                orderIndex: index,
                                section: section.slug,
                              });
                            } else {
                              updateFirebaseData(`insights/${firebaseId}`, {
                                ...insight,
                                orderIndex: index,
                              });
                            }
                          }
                        },
                      );
                    }}
                    onRemove={(widget) => {}}
                    sectionId={section.firebaseId}
                    widget={section.widget as any}
                  />
                </div>
              </div>
            </div>
          ))}
      </div>

      <Modal open={!!editInsight} onClose={() => setEditInsight('')}>
        <ModalWrapper>
          <BackAction
            icon={<CancelIcon />}
            title="Close"
            onClick={() => setEditInsight('')}
            width="md"
          />
          <VerticalSpacer size="16px" />

          <ModalCard title="">
            <div>
              {insight && (
                <EditInsight
                  {...insight}
                  handleAddToDashboard={() => {}}
                  onClose={() => setEditInsight('')}
                  onDuplicate={() => {}}
                  firebaseId={insight.firebaseId || ''}
                />
              )}
            </div>
          </ModalCard>
        </ModalWrapper>
      </Modal>

      <RenameSection
        allSections={allSections}
        renameSectionId={renameSectionId}
        setRenameSectionModal={setRenameSectionModal}
      />

      <CreateSection
        allSections={allSections}
        setNewSectionModal={setNewSectionModal}
        showNewSectionModal={showNewSectionModal}
      />

      {deleteSectionid && (
        <DeleteSection
          onClose={() => setDeleteSectionId('')}
          topic={data[deleteSectionid]?.name}
          handleDelete={() => {
            removeFirebaseData(`dashboard/${deleteSectionid}`);
            setDeleteSectionId('');
          }}
        />
      )}
    </div>
  );
});

export default ReportingDashboard;
