export const initializePendo = (data: {
  userId: string;
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  workspaceId: string;
  workspaceName: string;
  billingStatus: string;
}) => {
  const {
    userId,
    email,
    firstName,
    lastName,
    role,
    workspaceId,
    workspaceName,
    billingStatus,
  } = data;

  window.pendo.initialize({
    visitor: {
      id: userId,
      email,
      firstName,
      lastName,
      role,
    },

    account: {
      id: workspaceId,
      name: workspaceName,
      payingStatus: billingStatus,
    },
  });

  return;
};
