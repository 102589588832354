import {AnalyticsController} from '../../../reporting/reporting-pages/admin-analytics/admin-analytics.controller';
import {AnalyticsRequestImpl} from '../../../reporting/reporting-pages/admin-analytics/admin-analytics.request';

import {GoalsRequestImpl} from '../../../../../api/goals-api/goals-request';
import {getWeekOfYear} from '@utils/date';
import {dateParamsToday} from '@utils/date';
import {useQuery} from 'react-query';
import {getDayOfWeekIndexFromString} from '@utils/date';
import {useWorkspaceUseCase} from '@hooks/workspace-usecase';
import {GoalsController} from '../../../../../api/goals-api/goals-controller';
import {useStoreContext} from '@store/store-context';

import {useCallback, useMemo} from 'react';
export const UseCheckinGoals = () => {
  const {
    authStore: {auth},

    groupStore: {groups},
    checkinsStore: {
      workspaceCheckins,
      setWorkspaceCheckinsGoals,
      checkinsDates,
    },
  } = useStoreContext();

  const {isPerformanceActive} = useWorkspaceUseCase();

  const initGoalsCurrentWeek = useCallback(
    async (page: number, filter?: any, load?: boolean, reports?: boolean) => {
      const request = new GoalsRequestImpl();
      const controller = new GoalsController(request);

      const response = await controller.fetchGoals(page, filter);
      const includeGroups = async (goalsResponse: any) => {
        const groupsIncluded =
          goalsResponse?.results &&
          goalsResponse?.results?.map((goal: any) => {
            if (goal.goalType === 'group') {
              const group = goal.group.map((grp: string) => {
                return groups.find((_group) => _group.id === grp);
              });

              return {
                ...goal,
                group,
              };
            } else
              return {
                ...goal,
              };
          });
        return groupsIncluded;
      };

      if (filter === 'activeCheckins') {
        const unfurledData = await includeGroups(response);

        return unfurledData;
      }
    },
    [groups],
  );

  const initGoalsOtherWeeks = useCallback(
    async (filter?: any) => {
      const request = new AnalyticsRequestImpl();
      const controller = new AnalyticsController(request);

      const response = await controller.fetchSpecifiedGoals(filter, '', true);

      const includeGroups = async (goalsResponse: any) => {
        const groupsIncluded =
          goalsResponse &&
          goalsResponse?.map((goal: any) => {
            if (goal.goalType === 'group') {
              const group = goal.group.map((grp: string) => {
                return groups.find((_group) => _group.id === grp);
              });

              return {
                ...goal,
                group,
              };
            } else
              return {
                ...goal,
              };
          });
        return groupsIncluded;
      };

      if (!!response.goals) {
        const unfurledData = await includeGroups(response?.goals);

        return unfurledData;
      }
    },
    [groups],
  );

  const workspaceCheckinForCurrentWeek = useMemo(
    () =>
      Object.keys(workspaceCheckins).length > 0 &&
      workspaceCheckins?.year === new Date().getFullYear() &&
      checkinsDates?.weekOfYear === getWeekOfYear() &&
      dateParamsToday().dayOfWeekIndex !== 0 &&
      dateParamsToday().dayOfWeekIndex <=
        getDayOfWeekIndexFromString(workspaceCheckins?.dueDay)
        ? true
        : false,
    [workspaceCheckins, checkinsDates],
  );

  const {isLoading, data: currentWeekData, refetch} = useQuery(
    ['currentWeekData'],
    () => initGoalsCurrentWeek(1, 'activeCheckins', true),
    {
      enabled: workspaceCheckinForCurrentWeek && isPerformanceActive,
    },
  );

  useMemo(() => {
    if (currentWeekData && workspaceCheckinForCurrentWeek)
      setWorkspaceCheckinsGoals(currentWeekData);
  }, [
    setWorkspaceCheckinsGoals,
    currentWeekData,
    workspaceCheckinForCurrentWeek,
  ]);

  const workspaceForOtherWeeks = useMemo(
    () =>
      Object.keys(workspaceCheckins).length > 0 &&
      workspaceCheckins?.weekOfYear !== getWeekOfYear() &&
      !workspaceCheckinForCurrentWeek
        ? true
        : false,
    [workspaceCheckinForCurrentWeek, workspaceCheckins],
  );
  const filters = useMemo(() => {
    return {
      startDate: checkinsDates?.starts,
      endDate: checkinsDates?.ends,
      removeKpiFilter: true,
      member: auth?.user?.id,
    };
  }, [auth.user.id, checkinsDates.ends, checkinsDates.starts]);

  const {isLoading: othersLoading, data: OtherGoalsData} = useQuery(
    ['initOtherWeeksGoal', filters],
    () => initGoalsOtherWeeks(filters),
    {
      enabled: workspaceForOtherWeeks && isPerformanceActive,
    },
  );

  useMemo(() => {
    if (OtherGoalsData && workspaceForOtherWeeks) {
      setWorkspaceCheckinsGoals(OtherGoalsData);
    }
  }, [setWorkspaceCheckinsGoals, OtherGoalsData, workspaceForOtherWeeks]);
  return {
    loadingGoals: isLoading || othersLoading,
    refetch,
  };
};
