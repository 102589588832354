import {CommentDetails} from '@ui/layouts/users-goals/comments';
import styled from 'styled-components';
import {AddMentions} from '@utils/firebase-request';
import {useStoreContext} from '@store/store-context';
import {useMemo} from 'react';
import {useFirebaseFetch} from '@hooks/query-hook';

const Wrapper = styled.div`
  margin-top: 18px;
`;

export const CommentTab = ({
  id,
  defaultGoalSubscribers,
  allGoalSubscribers,
  mentions,
  unreadComment,
  isReadOnly,
  handleMentionUser,
}: {
  id: string;
  defaultGoalSubscribers: string[];
  mentions: any[];
  isReadOnly?: boolean;
  unreadComment: number;
  allGoalSubscribers: string[];
  handleMentionUser: () => void;
}) => {
  const {
    authStore: {auth},
  } = useStoreContext();

  useMemo(() => {
    if (unreadComment > 0)
      AddMentions(
        auth.user.id,
        id,
        {
          unreadComment: 0,
          mentionedBy: [],
        },
        'goals',
      );
  }, [unreadComment, auth.user.id, id]);

  const {data: comments = []} = useFirebaseFetch(`goals/${id}/comments`);

  return (
    <Wrapper>
      <CommentDetails
        comments={{comments}}
        path={`goals/${id}`}
        pathId={id}
        mentionsPath="goals"
        isReadOnly={isReadOnly}
        mentions={mentions}
        allGoalSubscribers={allGoalSubscribers}
        defaultSubscribers={defaultGoalSubscribers}
      />
    </Wrapper>
  );
};
