import styled, {DefaultTheme, StyledComponentProps} from 'styled-components';
import {ITypography, Typography} from './typography';
import {
  getFontSizeFromTheme,
  getFontWeightFromTheme,
  getLineHeightFromTheme,
} from '../../ui-utils';

export type MicrocopyProps = StyledComponentProps<
  'span',
  DefaultTheme,
  ITypography,
  'kind' | 'align'
>;

/**
 Used for multiline pieces of content.
 **/
export const Microcopy = styled.span<ITypography>`
  ${Typography};

  font-size: ${getFontSizeFromTheme('microcopy')};
  font-weight: ${getFontWeightFromTheme('semibold')};
  line-height: ${getLineHeightFromTheme('microcopy')};
`;
