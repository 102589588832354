import styled, {DefaultTheme, StyledComponentProps} from 'styled-components';
import {ITypography, Typography} from './typography';
import {
  getFontSizeFromTheme,
  getFontWeightFromTheme,
  getLineHeightFromTheme,
} from '../../ui-utils';

export type CTAProps = StyledComponentProps<
  'p',
  DefaultTheme,
  ITypography,
  'kind' | 'align'
>;

/**
 Used for multiline pieces of content.
 **/
export const CTA = styled.p<ITypography>`
  ${Typography};

  font-size: ${getFontSizeFromTheme('cta')};
  font-weight: ${getFontWeightFromTheme('semibold')};
  line-height: ${getLineHeightFromTheme('cta')};
`;
