import {observer} from 'mobx-react';
import {useNavigate} from 'react-router';
import {useStoreContext} from '../../../../../../store/store-context';
import {Body1, Body2} from '../../../../../../ui/atoms/typography';
import {useCompanyCheckinsHook} from '../company-checkins.hooks';
// import {CollapsibleSectionNotification} from '../../../../../../ui/molecules/collapsible-section-notification/notification';

import {
  Wrapper,
  BorderWrapper2,
  MemberButton,
  NavBorderWrapper,
} from './company-checkins-questions.styles';
import {GeneralQuestions} from './components/general-questions/general-questions';
import {useState} from 'react';
import {QuestionStatusFilter} from './question-status';

export const CompanyCheckinsQuestions = observer(() => {
  const {
    // authStore: {featureLimitStatus},
    checkinsStore: {questions},
  } = useStoreContext();

  const [status, setStatus] = useState('');

  useCompanyCheckinsHook();

  const navigate = useNavigate();

  return (
    <>
      <Wrapper className="bg-white">
        <BorderWrapper2>
          <Body1 kind="textDark" weight="bold">
            Manage questions{' '}
          </Body1>
          <MemberButton width="sm" onClick={() => navigate('/create-question')}>
            Create question
          </MemberButton>
        </BorderWrapper2>
        <NavBorderWrapper style={{padding: '16px 24px '}}>
          <Body2 weight="bold">Summary ({questions.length || 0})</Body2>

          <QuestionStatusFilter status={status} updateStatus={setStatus} />
        </NavBorderWrapper>
      </Wrapper>
      <div className="bg-white">
        <GeneralQuestions status={status} />
      </div>
    </>
  );
});
