import {object, string} from 'yup';
import {regexTwoMinCharacters} from '../../../../../../../../constants';
// import {regexSpecialCharacters} from '../../../../../../../../constants';
export const adminSettingsValidator = object().shape({
  firstName: string()
    .required('Fill in your first name')
    .matches(regexTwoMinCharacters, 'Special Characters are not allowed'),
  lastName: string()
    .required('Fill in your last name')
    .matches(regexTwoMinCharacters, 'Special Characters are not allowed'),
  phoneNumber: string(),
  jobTitle: string().required('Fill in your job title'),
  timezone: string().required('Provide a time zone'),
  countryCode: string(),
});

export const adminEmailValidator = object().shape({
  email: string().email().required('Fill in your email'),
});

export const adminReviewerValidator = object().shape({
  reporting: object().shape({
    weekDay: string().required('Fill in Weekdays'),
    schedule: string().required('Fill in schedule'),
  }),
  reviewer: string().required('Fill in reviewer'),
});
