import styled from 'styled-components';
import {GetImpression} from './impression';
import {Body1, Body2} from '@ui/atoms/typography';
import {useStoreContext} from '@store/store-context';
import {Tooltip} from '@ui/molecules/tooltip';
import {FlexRow} from '@ui/style/styles';
import {userName} from '@utils/user-name';
import {VerticalSpacer} from '@ui/atoms/spacer';

const Wrapper = styled.div`
  border: 1px solid #ededf2;
  border-radius: 10px;
  .main {
    padding: 16px;
  }
  .footer {
    padding: 12px 16px;
    border-top: 1px solid #ededf2;
  }
`;
const ValueItem = styled.div`
  padding: 4px 8px;
  background: #f6f6f8;
  margin-left: 5px;
  border-radius: 8px;
`;

export const ViewFeedbackCheckins = ({
  impression,
  feedback,
  values,
  reviewer,
  currentUser,
}: any) => {
  const {
    usersStore: {users},
  } = useStoreContext();

  return (
    <Wrapper>
      <div className="main">
        <GetImpression impressionValue={impression} />

        <VerticalSpacer size="24px" />
        <Body1>{feedback}</Body1>
        <VerticalSpacer size="24px" />
        {values.length > 0 ? <FeedbackValues values={values} /> : null}
      </div>
      <div className="footer">
        <FlexRow>
          <Body2 weight="semibold" kind="textBody">
            From:{' '}
          </Body2>{' '}
          <Body2 style={{marginLeft: '4px'}} weight="semibold">
            {reviewer === currentUser
              ? 'You'
              : userName(users.find((user) => user.id === reviewer))}
          </Body2>
        </FlexRow>
      </div>
    </Wrapper>
  );
};

export const FeedbackValues = ({values}: {values: string[]}) => {
  const displayedValues = values?.slice(0, 2);
  const tooltipValue = values?.slice(2);
  return (
    <FlexRow>
      <Body2 weight="semibold">Values: </Body2>{' '}
      {displayedValues.map((value: string) => (
        <ValueItem key={value}>
          <Body2 kind="textBody">#{value}</Body2>
        </ValueItem>
      ))}
      {tooltipValue?.length > 0 && (
        <ValueItem>
          <Tooltip
            text={`+${tooltipValue.length} more`}
            BodyTextNodeType="p"
            bodyStyle={{
              color: 'rgb(88, 90, 223)',
              fontSize: '14px',
              fontWeight: 500,
            }}
            tooltipBody={
              <span>
                {tooltipValue.map((name: any, index: number) => (
                  <span key={index}>{`${name}${index + 1 ? ',' : ''}`}</span>
                ))}
              </span>
            }
          />
        </ValueItem>
      )}
    </FlexRow>
  );
};
