import styled from 'styled-components';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {DataLinks} from './links-data';
import {Flex, FlexRow, FlexRowSpaceBetween} from '@ui/style/styles';
import {EmptyPlaceholder} from '@ui/atoms/empty-placeholder';
import {ItemLoader} from '@ui/organisms/item-loader';
import {observer} from 'mobx-react';
// import {useBreakpoints} from '@utils/use-breakpoints';
import {useStoreContext} from '@store/store-context';
import {TaskList} from './task';
import {AlertBadge} from '@ui/atoms/icons/alert-badge';
import {down} from 'styled-breakpoints';
import {Image} from '@ui/atoms/image';
import {QuestionIconWrapper} from '@ui/molecules/tooltip/tooltip';
import {cloudinary} from '@assets/images/cloudinary';
import {useTaskHook} from './tasks-hook';
import {Body1, Body2, Headline3} from '@ui/atoms/typography';
import {ifProp} from 'styled-tools';
import {illustrationList} from '@ui/assets/illustrations';
import {Button} from '@ui/atoms/button';
import {useNavigate} from 'react-router';

const AlertsIndicator = styled.div`
  background: #d52a2a;
  color: white;
  width: 18px;
  display: flex;
  font-size: 11px;
  font-weight: 500;
  padding: 0px 5px 5px 5px;
  margin-left: 6px;
  align-items: center;
  justify-content: center;
  height: 18px;
  border-radius: 20px;
`;

const Wrapper = styled.div<{fullHeight?: boolean}>`
  border: 1px solid #ededf2;
  border-radius: 10px;
  height: ${ifProp('fullHeight', '70%', undefined)};
  width: 100%;
  .heading {
    display: flex;
    padding: 16px;
    align-items: center;
    justify-content: space-between;
  }

  .mainBody {
    height: ${ifProp('fullHeight', '37.4rem', '420px')};
  }

  .mainTaskBody {
    border-top: 1px solid #ededf2;
    height: ${ifProp('fullHeight', '25.4rem', '434px')};
    overflow-y: auto;
    overflow-x: hidden;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    &::-webkit-scrollbar {
      width: 2.1em;
    }

    &::-webkit-scrollbar-track {
      background: #ededf2;
      border: 0.8em solid rgba(0, 0, 0, 0);
      padding: 10px;
      background-clip: padding-box;

      -webkit-border-radius: 1.5em;

      border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb {
      height: 10em;
      border: 0.94em solid rgba(0, 0, 0, 0);
      background: #cecede;
      width: 1em;
      margin: 10px;
      border-radius: 10px;
      background-clip: padding-box;
      -webkit-border-radius: 1.5em;

      -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.025);
    }

    &::-webkit-scrollbar-button {
      width: 0;
      height: 0;
      display: none;
    }

    &::-webkit-scrollbar-corner {
      background-color: transparent;
    }
  }
`;

const LinkWrapper = styled.div`
  border: 1px solid #ededf2;
  margin-top: 24px;
  padding: 16px 16px 20px 16px;
  border-radius: 10px;
`;

const LinkCard = styled.div`
  background: #fafafa;
  border-radius: 10px;
  width: 48%;
  padding: 16px;
  ${down('sm')} {
    width: 100%;
    margin-top: 10px;
  }
`;

const {emptyGoals: emptyGoal} = cloudinary;

const PaymentEndedComponent = (props: {
  title: string;
  description: string;
  subDescription?: string;
  action: {text: string; click: () => void};
}) => {
  return (
    <div className="border-t border-t-[#ededf2] rounded-[10px] pt-[60px] pb-14 px-10 h-full">
      <div className="rounded-[10px] border border-borderLight sm:p-5 p-10 flex flex-col items-center justify-center sm:min-h-[380px] min-h-[500px]">
        <FlexRowSpaceBetween>
          <div className="sm:w-full w-[40%]">
            <Headline3>{props.title}</Headline3>
            <Body2 className="my-4" kind="textBody">
              {props.description}
            </Body2>
            {props.subDescription && (
              <Body2 className="my-4" kind="textBody">
                {props.subDescription}
              </Body2>
            )}
            <Button onClick={props.action.click} style={{padding: '8px 16px'}}>
              {props.action.text}
            </Button>
          </div>
          <div className="b sm:hidden block">
            <Image {...illustrationList.painting} alt="image" width={'310px'} />
          </div>
        </FlexRowSpaceBetween>
      </div>
    </div>
  );
};
const Empty = {
  image: {
    alt: 'alt text',
    shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
    src: emptyGoal,
    style: {height: '30%', width: '30%'},
  },
  imports: true,
  subtitle: '',
  title: `You currently have no pending alert`,
};

function Tasks({
  masteryExist,
  hasSubscriptionEnded,
  hasTrailEnded,
}: {
  masteryExist?: boolean;
  hasTrailEnded?: boolean;
  hasSubscriptionEnded?: boolean;
}) {
  const {
    taskStore: {taskData: tasks, taskListCount},
    authStore: {auth},
    billingsStore: {updateShowNonActiveBilling},
  } = useStoreContext();

  const navigate = useNavigate();

  const showAlertWidget =
    taskListCount > 0 || hasSubscriptionEnded || hasTrailEnded;

  const {taskLoader, handleDismissAll} = useTaskHook();

  return (
    <Wrapper fullHeight={masteryExist} className={`bg-white `}>
      {showAlertWidget && (
        <div className="heading">
          {' '}
          <FlexRow>
            <Body1 weight="bold">Alerts</Body1>{' '}
            {taskListCount ? (
              <AlertsIndicator>{taskListCount}</AlertsIndicator>
            ) : null}
            <QuestionIconWrapper tooltip={true}>
              <AlertBadge />
              <span
                className="tooltip"
                style={{
                  width: '300px',
                  padding: '16px',
                }}>
                Here are all pending alerts, notifications, and tasks.
              </span>
            </QuestionIconWrapper>
          </FlexRow>
          <Body2
            kind={!taskListCount ? 'textMuted' : 'purple300'}
            weight="bold"
            style={{cursor: 'pointer'}}
            onClick={() => (!taskListCount ? null : handleDismissAll())}>
            Clear all
          </Body2>{' '}
        </div>
      )}
      {taskLoader && !taskListCount && <ItemLoader />}

      {hasTrailEnded && (
        <PaymentEndedComponent
          action={{
            click: () => {
              navigate('/workspace-settings/billing');
              updateShowNonActiveBilling(true);
            },
            text: 'Set up Billing',
          }}
          title="Your free trial has ended"
          description={`The free trial for ${auth.user.workspace.name} has ended, but your data is safe. Join the many managers who've made Beam their new performance system. We'd love to have you too.`}
        />
      )}
      {hasSubscriptionEnded && (
        <PaymentEndedComponent
          action={{
            click: () => {
              navigate('/workspace-settings/billing');
              updateShowNonActiveBilling(true);
            },
            text: 'Renew',
          }}
          title="Subscription Expired"
          subDescription="To get everything back on track, simply update your payment details or add a new payment method."
          description={
            "Your subscription has been suspended because we couldn't process your payment for the next billing cycle."
          }
        />
      )}
      {taskListCount > 0 && !hasSubscriptionEnded && !hasTrailEnded && (
        <div className="mainTaskBody">
          <TaskList tasks={tasks} />
        </div>
      )}
      {!taskLoader && !showAlertWidget && (
        <div className="mainBody">
          <EmptyPlaceholder {...Empty} />
        </div>
      )}
    </Wrapper>
  );
}
export default observer(Tasks);
export const Links = () => (
  <LinkWrapper>
    <Body1 weight="semibold">Useful links</Body1>
    <VerticalSpacer size="16px" />
    <Flex justifyContent="space-between" sm={true}>
      {DataLinks.map((data) => (
        <LinkCard>
          <Body1 weight="semibold">{data.title}</Body1>
          <VerticalSpacer size="12px" />
          <Body2 kind="textBody">{data.description}</Body2>
          <VerticalSpacer size="12px" />

          <Body2
            kind="purple300"
            weight="bold"
            onClick={() => window.open(data.href)}
            style={{
              textDecoration: 'underline',
              cursor: 'pointer',
              width: 'fit-content',
            }}>
            {data.link}
          </Body2>
        </LinkCard>
      ))}
    </Flex>
  </LinkWrapper>
);
