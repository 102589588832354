import {
  memo,
  ReactNode,
  SyntheticEvent,
  useRef,
  useState,
  useMemo,
  useEffect,
} from 'react';
import {AnimateSharedLayout} from 'framer-motion';
import {Wrapper, NavWrapper} from './style';
import {TabNav} from '../../atoms/tab-nav';
import {ArrowHeadLeftIcon, ArrowHeadRightIcon} from '@ui/atoms/icons';
import {FlexRowSpaceBetween} from '@ui/style/styles';

interface NavigationOption {
  href: string;
  title: string;
  active: boolean;
  id: string;
  iconPosition?: string;
  icon?: ReactNode;
  onClick: (e: SyntheticEvent<HTMLAnchorElement>) => void;
  customtitle?: ReactNode;
}
export interface TProps {
  navigations: NavigationOption[];
  enableScroll?: boolean;
  type?: 'button' | 'link';
}

export const TabNavigation = memo(function ({
  navigations,
  type,
  enableScroll,
}: TProps) {
  const myRef = useRef<HTMLDivElement>(null);

  const [scrollLeft, setScrollLeft] = useState(0);

  const [hasRightOverflow, setHasRightOverflow] = useState(false);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === myRef.current) {
          myRef.current.scrollLeft = scrollLeft;
          if (scrollLeft + entry.contentRect.width < entry.target.scrollWidth) {
            setHasRightOverflow(true);
          } else {
            setHasRightOverflow(false);
          }
        }
      }
    });

    const currentRef = myRef.current; // Store the current ref value

    if (currentRef) {
      resizeObserver.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        resizeObserver.unobserve(currentRef);
      }
    };
  }, [scrollLeft]);

  const hasLeftOverflow = useMemo(() => {
    if (myRef.current) {
      return scrollLeft > 0;
    }
  }, [myRef, scrollLeft]);

  const handleScrollRight = () => {
    if (myRef.current) setScrollLeft((prev) => prev + 200);
  };

  const handleScrollLeft = () => {
    setScrollLeft((prev) => (prev - 100 > 0 ? prev - 100 : 0));
  };

  return (
    <Wrapper>
      <AnimateSharedLayout>
        <FlexRowSpaceBetween>
          {hasLeftOverflow && enableScroll && (
            <div className="leftOverflow" onClick={() => handleScrollLeft()}>
              <ArrowHeadLeftIcon
                style={{
                  stroke: '#242424',
                  width: '24px',
                  height: '24px',
                  marginLeft: '5px',
                  cursor: 'pointer',
                }}
              />
            </div>
          )}
          <NavWrapper enableScroll={enableScroll} ref={myRef}>
            {navigations.map((item) => (
              <TabNav
                href={item.href}
                active={item.active}
                type={type}
                key={item.id}
                onClick={(e) => {
                  item.onClick(e);
                }}>
                {item?.iconPosition === 'left' && <span>{item.icon}</span>}
                {item.title}
                {item.customtitle}
              </TabNav>
            ))}
          </NavWrapper>
          {hasRightOverflow && enableScroll && (
            <div className="rightOverflow" onClick={() => handleScrollRight()}>
              <ArrowHeadRightIcon
                style={{
                  stroke: '#242424',
                  width: '24px',
                  height: '24px',
                  marginLeft: '5px',
                  cursor: 'pointer',
                }}
              />
            </div>
          )}
        </FlexRowSpaceBetween>
      </AnimateSharedLayout>
    </Wrapper>
  );
});

// TabNavigation.displayName = 'TabNavigation';
