import styled from 'styled-components';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {FlexRowSpaceBetween} from '@ui/style/styles';
import {Body1, Body2} from '@ui/atoms/typography';

const Wrapper = styled.div`
  border: 1px solid #ededf2;
  border-radius: 10px;
  padding: 16px 24px;
  width: 408px;
`;

const Card = ({
  title,
  data,
}: {
  title: string;
  data: {label: string; value: number | string}[];
}) => {
  return (
    <Wrapper>
      <Body1 style={{fontWeight: 700, fontSize: '13pt'}}>{title}</Body1>
      <VerticalSpacer size="16px" />
      {data.map((info) => (
        <div>
          <FlexRowSpaceBetween>
            <Body2 kind="textBody" style={{fontSize: '12pt'}}>
              {info.label}
            </Body2>
            <Body2 style={{fontWeight: 700, fontSize: '12pt'}}>
              {info.value}
            </Body2>
          </FlexRowSpaceBetween>
          <VerticalSpacer size="10px" />
        </div>
      ))}
    </Wrapper>
  );
};
export const ObjectiveProgress = ({
  summary,
}: {
  summary: {
    total: number;
    activeObjectives: number;
    endedObjectives: number;
    completion: number;
  };
}) => {
  const data = [
    {
      label: 'Total objectives',
      value: summary.total,
    },
    {
      label: 'Active',
      value: summary.activeObjectives,
    },
    {
      label: 'Ended',
      value: summary.endedObjectives,
    },
    {
      label: 'Average completion',
      value: summary.completion + '%',
    },
  ];
  return <Card title="Objective progress" data={data} />;
};

export const KeyResultProgress = ({
  summary,
}: {
  summary: {
    total: number;
    completion: number;
  };
}) => {
  const data = [
    {
      label: 'Total key results',
      value: summary.total,
    },
    {
      label: 'Average completion',
      value: summary.completion + '%',
    },
  ];
  return <Card title="Key result progress" data={data} />;
};

export const KPIProgress = ({
  summary,
}: {
  summary: {
    kpis: number;
    activeKpis: number;
    endedKpis: number;
  };
}) => {
  const data = [
    {
      label: 'Total KPIs',
      value: summary.kpis,
    },
    {
      label: 'Active',
      value: summary.activeKpis,
    },
    {
      label: 'Ended',
      value: summary.endedKpis,
    },
  ];
  return <Card title="KPIs progress" data={data} />;
};

export const CompetenceProgress = ({
  summary,
}: {
  summary: {keyAreasCount: number; objectivesCount: number};
}) => {
  const data = [
    {
      label: 'Key areas',
      value: summary.keyAreasCount,
    },
    {
      label: 'Objectives',
      value: summary.objectivesCount,
    },
  ];
  return <Card title="Overview" data={data} />;
};
