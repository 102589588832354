import dayjs from 'dayjs';
import {makeAutoObservable, observable, action} from 'mobx';
import {
  IMonthPerformanceResponse,
  IQuadrantData,
} from '../../pages/dashboard/you/you-interface';

export class PerformanceStore {
  @observable year: number = Number(dayjs().format('YYYY'));
  @observable currentMonth: number = Number(dayjs().format('M'));
  @observable selectedMonths: number[] = [this.currentMonth];
  @observable eligibleUsers: any = [];
  @observable eligibleRequestUsers: any = [];
  @observable reviewCycle: any = [];
  @observable reloadRequests: any = false;
  @observable requests: any[] = [];
  @observable selectedUser: any = '';
  @observable
  selectedMonthsData: IMonthPerformanceResponse[] = this.selectedMonths.map(
    (month: number) => ({
      dateNum: month,
      average: 0,
      rate: 0,
      values: 0,
      productivity: 0,
      reviewer: {},
      reviewedOn: '',
      feedbacks: [{title: '', comment: '', id: '', remark: ''}],
    }),
  );
  @observable directReportUsers: any = [];
  @observable selfReviewLoading: boolean = false;
  @observable quadrantData: IQuadrantData | null = null;
  // Admin Performance framework observables
  @observable workspaceImpressionDetails = [
    {
      name: 'Unsatisfactory',
      description:
        'Fails to achieve the objectives and outcomes expected of the role and does not consistently exhibit  the values and competencies required.',
      min: 0,
      max: 39,
      weight: 1,
    },
    {
      name: 'Average',
      description:
        'Achieves some objectives and displaying some competencies, there is potential for greater impact by consistently demonstrating values and exceeding outcomes.',
      min: 40,
      max: 59,
      weight: 2,
    },
    {
      name: 'Satisfactory',
      description:
        'Performs well in terms of accomplishing most objectives and outcomes of the role, and frequently demonstrating values and competencies, but there is still potential to increase their impact further.',
      min: 60,
      max: 79,
      weight: 3,
    },
    {
      name: 'Exceptional',
      description:
        'Exceeds all objectives and outcomes, exceptional impact. Consistently demonstrates values and competencies.',
      min: 80,
      max: 100,
      weight: 4,
    },
  ];

  @observable defaultWorkspaceCriteriaMetrics: any = [
    {
      category: 'productivity',
      name: 'Quality of Work',
      impression: '',
      description: 'Description of the criteria goes here',
      allowComments: true,
      includeGoals: true,
    },

    {
      category: 'values',
      name: 'Attitude to Work',
      impression: '',
      description: 'Description of the criteria goes here',
      allowComments: true,
      includeGoals: true,
    },
    {
      category: 'values',
      name: 'Communication',
      impression: '',
      description: 'Description of the criteria goes here',
      allowComments: true,
      includeGoals: true,
    },
    {
      category: 'productivity',
      name: 'Target Output',
      impression: '',
      description: 'Description of the criteria goes here',
      allowComments: true,
      includeGoals: true,
    },
  ];

  @observable performanceframeworks: any = [];
  @observable tasksCount = 0;
  constructor() {
    makeAutoObservable(this);
  }
  @action setSelectedUser = (data: any) => {
    this.selectedUser = data;
  };

  @action handleSelfReview = (status: boolean) => {
    this.selfReviewLoading = status;
  };
  @action setEligibleUser = (data: any) => {
    this.eligibleUsers = data;
  };
  @action handleReview = (data: any) => {
    this.handleReview = data;
  };
  @action taskIncrement = () => {
    this.tasksCount = 1;
  };
  @action setReloadRequest = (status: boolean) => {
    this.reloadRequests = status;
  };
  @action setRequests = (data: any) => {
    this.requests = data;
  };
  @action setEligibleRequestUser = (data: any) => {
    this.eligibleRequestUsers = data;
  };
  @action
  clearPerformanceStore = () => {
    this.year = Number(dayjs().format('YYYY'));
    this.currentMonth = Number(dayjs().format('M'));
    this.selectedMonths = [this.currentMonth];
    this.selectedMonthsData = this.selectedMonths.map((month: number) => ({
      dateNum: month,
      average: 0,
      rate: 0,
      values: 0,
      productivity: 0,
      reviewer: {},
      reviewedOn: '',
      feedbacks: [{title: '', comment: '', id: '', remark: ''}],
    }));
    this.quadrantData = null;
  };

  @action
  prevYear = () => {
    this.year = this.year - 1;
  };
  @action
  nextYear = () => {
    this.year = this.year + 1;
  };
  @action
  selectMonth = (index: number) => {
    if (this.selectedMonths.includes(index + 1)) {
      this.selectedMonths = this.selectedMonths.filter(
        (month: number) => month !== index + 1,
      );
      delete this.selectedMonthsData[index];
    } else {
      this.selectedMonths.push(index + 1);
    }
  };
  @action
  setSelectedMonthData = (index: number, data: any) => {
    if (!this.selectedMonths.includes(index + 1)) {
      this.selectedMonthsData[index] = data;
    }
  };

  @action handleDirectReports = (data: any) => {
    this.directReportUsers = data;
  };

  @action
  setPerformanceFrameworks = (data: any) => {
    this.performanceframeworks = data;
  };

  @action
  setQuadrantData = (data: any) => {
    this.quadrantData = data;
  };
}

// Admin performance framework actions

export const performanceStore = new PerformanceStore();
