import {FC, useState, useMemo, ReactNode} from 'react';
import {sortFeedback} from '@utils/sortFeedback';
import {FeedbackItem} from './feedback-item';
export interface Feedback {
  group: string[];
  createdAt: string;
  feedback: string;
  id: string;
  impression: string;
  visibility: 'public' | 'manager' | 'private';
  reviewer: string;
  subjectId: string;
  subjectName: string;
  subjectType: string;
  updatedAt: string;
  user: string;
  values: string[];
  workspace: string;
}

interface IItems {
  data: Array<Feedback>;
  myFeedback?: boolean;
  dropdown?: (data: Feedback) => ReactNode;
  sortFeedbackBy: keyof Feedback;
}

export const FeedbackItems: FC<IItems> = ({
  data,
  sortFeedbackBy = 'user',
  dropdown,
  myFeedback,
}) => {
  const sortData = Object.values(sortFeedback(data, sortFeedbackBy));

  return (
    <>
      {sortData.map((data, index) => (
        <GroupedFeedBack
          data={data.sort(
            (a, b) =>
              Number(new Date(b.updatedAt)) - Number(new Date(a.updatedAt)),
          )}
          key={index}
          myFeedback={myFeedback}
          dropdown={dropdown}
        />
      ))}
    </>
  );
};

const GroupedFeedBack = ({
  data,
  dropdown,
  myFeedback,
}: {
  data: Array<Feedback>;
  dropdown?: (data: Feedback) => ReactNode;
  myFeedback?: boolean;
}) => {
  const [dataDisplayCount, setDataDisplayCount] = useState(1);

  const totalCount = data.length;

  const showMore = dataDisplayCount !== totalCount;

  const showLess = totalCount > 1 && !showMore;

  const showMoreCount = totalCount - 1;

  const filterData = useMemo(() => data.slice(0, dataDisplayCount), [
    data,
    dataDisplayCount,
  ]);

  return (
    <>
      {filterData.map((feedbacks, index) => (
        <FeedbackItem
          {...feedbacks}
          key={index}
          dropdown={dropdown}
          handleShowMore={() => setDataDisplayCount(showLess ? 1 : totalCount)}
          showReviewer={
            !myFeedback &&
            (filterData[index + 1]?.reviewer !== feedbacks.reviewer ||
              index + 1 === totalCount)
          }
          myFeedback={myFeedback}
          showMore={showMore}
          showLess={index === totalCount - 1 && showLess}
          feedbackCount={showMoreCount}
        />
      ))}
    </>
  );
};
