import {observer} from 'mobx-react';
import {useStoreContext} from '../../../store/store-context';
import PageLayout from '@ui/templates/page-template/page-layout';
import {SideNavigation} from '../../../ui/molecules/side-nav';
import {
  WorkspaceWrapper,
  WorkspaceWrapperChild,
} from '../admin/admin-components/admin-workspace/admin-workspace-styled';
import {Personal} from './member-profile-component/personal';
import {Security} from './member-profile-component/security';
import NotificationSettings from './member-profile-component/notification';
import {Wrapper} from './member-profile-style';
import {Route, Routes, useNavigate} from 'react-router-dom';
import {AppIntegrationContainer} from '../admin/admin-components/admin-integrations/apps/apps';
import {useIsRouteActive} from '@utils/route-href';
import {useMemo, useState} from 'react';

export const PROFILE_ROUTES = {
  PERSONAL: '',
  APPS: 'apps',
  NOTIFICATIONS: 'notifications',
  SECURITY: 'security',
};

export const MemberProfilePageElement = observer(() => {
  const {storeDashboardRoutes} = useStoreContext();
  const {activateUserProfileTab} = storeDashboardRoutes;
  const [prevPathName] = useState<any>(sessionStorage.getItem('prevurl'));

  const {isRouteActive, isStrictRouteActive} = useIsRouteActive();

  const userProfileNavigations = [
    {
      active: isStrictRouteActive('/edit'),
      href: PROFILE_ROUTES.PERSONAL,
      id: '1',
      type: 'workspace',
      onClick: () => activateUserProfileTab('personal'),
      title: 'Personal',
    },
    {
      active: isRouteActive(PROFILE_ROUTES.APPS),
      href: PROFILE_ROUTES.APPS,
      id: '1',
      type: 'workspace',
      onClick: () => activateUserProfileTab('apps'),
      title: 'Apps',
    },

    {
      active: isRouteActive(PROFILE_ROUTES.NOTIFICATIONS),
      href: PROFILE_ROUTES.NOTIFICATIONS,
      id: '1',
      type: 'workspace',
      onClick: () => activateUserProfileTab('notification'),
      title: 'Notifications',
    },
    {
      active: isRouteActive(PROFILE_ROUTES.SECURITY),
      href: PROFILE_ROUTES.SECURITY,
      id: '2',
      type: 'workspace',
      onClick: () => activateUserProfileTab('security'),
      title: 'Security',
    },
  ];

  const navigate = useNavigate();

  const pageTitle = useMemo(() => {
    if (isRouteActive(PROFILE_ROUTES.APPS)) return 'Apps';
    if (isRouteActive(PROFILE_ROUTES.SECURITY)) return 'Security Settings';
    if (isRouteActive(PROFILE_ROUTES.NOTIFICATIONS)) return 'Notifications';
    return 'Edit Profile';
  }, [isRouteActive]);

  return (
    <>
      <PageLayout
        bodyStyle={{padding: 0, margin: 0, marginTop: '5px'}}
        title={pageTitle}
        onClick={() => navigate(prevPathName || -1)}>
        <Wrapper>
          <WorkspaceWrapper>
            <WorkspaceWrapperChild>
              <SideNavigation
                navigations={userProfileNavigations}
                type="workspace"
              />
              <div>
                <Routes>
                  <Route
                    path={`${PROFILE_ROUTES.PERSONAL}/*`}
                    element={<Personal />}
                  />
                  <Route
                    path={`${PROFILE_ROUTES.NOTIFICATIONS}/*`}
                    element={<NotificationSettings />}
                  />
                  <Route
                    path={`${PROFILE_ROUTES.APPS}/*`}
                    element={<AppIntegrationContainer />}
                  />
                  <Route
                    path={`${PROFILE_ROUTES.SECURITY}/*`}
                    element={<Security />}
                  />
                </Routes>
              </div>
            </WorkspaceWrapperChild>
          </WorkspaceWrapper>
        </Wrapper>
      </PageLayout>
    </>
  );
});

export const memberprofilePage = () => {
  const Page = observer(() => <MemberProfilePageElement />);
  return {
    Page,
  };
};
