import styled from 'styled-components';
import styledMap from 'styled-map';
import {Button} from '@ui/atoms/button';
import {Flex} from '@ui/style/styles';
import {FiChevronDown} from 'react-icons/fi';
import {useStoreContext} from '@store/store-context';
import {HorizontalSpacer} from '@ui/atoms/spacer';
import dayjs from 'dayjs';
import {useMemo} from 'react';
import {HorizontalSideRule} from '@ui/atoms/spacer/spacer';
import {formatToMonthAndDay} from '@utils/date';
import {CalenderIcon} from '@ui/atoms/icons/calender-icon';
import {observer} from 'mobx-react';
// import {IMembersFormatFilter} from '@hooks';
import {CancelIcon} from '@ui/atoms/icons';
import {DropdownCustom} from '@ui/molecules/dropdown-custom';
import {UserFilters} from '@ui/organisms/users/users-filter';
import {DateFilters} from '@ui/organisms/dates/date-filter';
import {Body2} from '@ui/atoms/typography';
import {ifProp} from 'styled-tools';

const SvgWrapper = styled.div<{disabled?: boolean}>`
  svg {
    path {
      stroke: ${ifProp('disabled', '#bfbfd4', 'black')};
    }
  }
`;
const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 500px) {
    align-items: flex-start;
    flex-direction: column;
  }
`;
const FlexWrapper = styled(Flex)`
  @media (max-width: 500px) {
    align-items: flex-start;
  }
`;
export const CustomButton = styled(Button)<{
  _width?: string;
  variant?: string;
  _height?: string;
}>`
  width: ${({_width}) => (_width ? _width : '125px')};
  height: ${({_height}) => (_height ? _height : '46px')};
  padding: 8px 16px;

  display: flex;
  align-items: center;
  overflow: hidden;
  @media (max-width: 800px) {
    margin-left: 0px;
    padding: ${styledMap('variant', {
      users: '8px 12px',
      default: '8px 16px',
    })};
    height: ${styledMap('variant', {
      users: '36px',
      default: '46px',
    })};

    margin-top: 10px;
  }
`;

const Filter = ({
  userFilter,
  setDateRange,
  clearSelection,
  filterOnlyPeople,
  filterOnlyTeams,
  disableUserReset,
  dateRange,
  removeUserFilter,
  defaultUserFilter,
  _height,
  _width,
  canFilterAll,
  defaultUserId,
  buttonStyle,
  disablePeriods,
  useSingleSelect,
  disabled,
  reduceOnWindowSize,
  directReportFilter,
  variant,
  showDateRange,
  setClearSelection,
  setUserFilter,
}: any) => {
  const {
    authStore: {auth},
    usersStore: {users},
  } = useStoreContext();

  const _dateRange = useMemo(() => {
    return {
      ...dateRange,
      starts: dateRange?.starts.replaceAll('-', '/'),
      ends: dateRange?.ends.replaceAll('-', '/'),
    };
  }, [dateRange]);

  return (
    <Wrapper data-testid="date-filter">
      {!removeUserFilter && (
        <FlexWrapper alignItems="center" sm>
          <Body2 weight="semibold" className="mr-2">
            Sort by
          </Body2>
          <DropdownCustom
            collapseOnClick={true}
            menu={(handleClose: () => void) => (
              <>
                <UserFilters
                  handleClose={handleClose}
                  variant={variant}
                  selectedValues={userFilter}
                  useSingleSelect={useSingleSelect}
                  defaultUserFilter={defaultUserFilter}
                  disableUserReset={disableUserReset}
                  filterOnlyPeople={filterOnlyPeople}
                  filterOnlyTeams={filterOnlyTeams}
                  directReportFilter={directReportFilter}
                  canFilterAll={canFilterAll}
                  clearSelection={clearSelection}
                  setClearSelection={setClearSelection}
                  onChange={(e: any) => setUserFilter && setUserFilter(e)}
                />
              </>
            )}
            customComponent={(
              handleOpen: (event: any) => void,
              open: boolean,
            ) => (
              <Flex alignItems="center" style={{width: '100%'}}>
                <CustomButton
                  kind="secondary"
                  onClick={handleOpen}
                  variant="users"
                  style={{minWidth: '125px', height: '38px'}}
                  _width="100%">
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    style={{width: '100%'}}>
                    <Body2 weight="bold">
                      {userFilter?.filterBy.value === 'team'
                        ? userFilter.selectedTeam.label
                        : userFilter.filterBy.value === ''
                        ? directReportFilter
                          ? 'Direct reports'
                          : 'My Team'
                        : userFilter.filterBy.label}
                    </Body2>
                    <FiChevronDown
                      style={{margin: '0px 5px 0px 0px'}}
                      aria-hidden="true"
                    />
                  </Flex>
                </CustomButton>

                {userFilter.members?.length > 0 && (
                  <>
                    <HorizontalSpacer size="16px" />
                    <HorizontalSideRule size="35px" />

                    <HorizontalSpacer size="10px" />
                    {userFilter.members.length === 1 ? (
                      <Body2 weight="bold" style={{whiteSpace: 'nowrap'}}>
                        {userFilter.members[0]?.label as any}
                      </Body2>
                    ) : (
                      <Body2 weight="bold" style={{whiteSpace: 'nowrap'}}>
                        {userFilter.members.length} people selected
                      </Body2>
                    )}

                    {((variant === 'objectives' &&
                      defaultUserId !== userFilter?.members?.[0]?.value &&
                      userFilter.members.length !== users.length) ||
                      variant !== 'objectives') && (
                      <div
                        style={{
                          marginLeft: '4px',
                          marginTop: '2px',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setClearSelection && setClearSelection(true);
                          if (filterOnlyPeople || canFilterAll) {
                            if (defaultUserFilter) {
                              return setUserFilter(defaultUserFilter);
                            }
                            setUserFilter &&
                              setUserFilter({
                                filterBy: {value: 'people', label: 'People'},
                                selectedTeam: {
                                  value: '',
                                  label: '',
                                },
                                members: [],
                              });
                          } else {
                            if (defaultUserFilter) {
                              return setUserFilter(defaultUserFilter);
                            }
                            setUserFilter &&
                              setUserFilter({
                                filterBy: {value: 'team', label: 'Teams'},
                                selectedTeam: {
                                  value: directReportFilter
                                    ? auth.user.reviewer
                                    : auth.user.id,
                                  label: directReportFilter
                                    ? 'Direct reports'
                                    : 'My Team',
                                },
                                members: [],
                              });
                          }
                        }}>
                        <CancelIcon />
                      </div>
                    )}
                  </>
                )}
              </Flex>
            )}
            shouldNotIncludeWrapper={true}
            customDropdownWrapperStyles={{
              top: '130%',
              boxShadow: '0px',
              left: 0,
              padding: 0,
              overflowY: 'auto',
              background: 'none',
              border: 0,
            }}
          />
        </FlexWrapper>
      )}

      {showDateRange && (
        <DropdownCustom
          collapseOnClick={true}
          wrapperStyles={buttonStyle}
          menu={(handleClose: () => void) => (
            <DateFilters
              onChangeDateRange={(e) => setDateRange(e)}
              handleClose={handleClose}
              disablePeriods={disablePeriods}
              date={_dateRange}
            />
          )}
          shouldNotIncludeWrapper={true}
          customComponent={(
            handleOpen: (event: any) => void,
            open: boolean,
          ) => (
            <>
              <CustomButton
                kind="secondary"
                width="full"
                onClick={handleOpen}
                disabled={disabled}
                variant={variant}
                _height={_height}
                _width={_width || '260px'}>
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  style={{width: '100%'}}>
                  <Body2
                    weight="bold"
                    kind={disabled ? 'textMuted' : 'textDark'}>
                    {dateRange?.period ? (
                      <>
                        <span
                          className={
                            reduceOnWindowSize ? 'laptop:block hidden' : ''
                          }>
                          {dateRange?.period} (
                          {formatToMonthAndDay(_dateRange.starts, 'DD MMM. ')} -{' '}
                          {formatToMonthAndDay(_dateRange.ends, 'DD MMM. ')})
                        </span>
                        {reduceOnWindowSize && (
                          <span className={'laptop:hidden block'}>
                            {dateRange?.period}
                          </span>
                        )}
                      </>
                    ) : (
                      <>
                        <span
                          className={
                            reduceOnWindowSize ? 'laptop:block hidden' : ''
                          }>
                          {dayjs(dateRange.starts).format('DD MMM. YYYY')} -
                          {dayjs(dateRange.ends).format('DD MMM. YYYY')}
                        </span>
                        {reduceOnWindowSize && (
                          <span className={'laptop:hidden block'}>Custom</span>
                        )}
                      </>
                    )}
                  </Body2>
                  <SvgWrapper disabled={disabled}>
                    <CalenderIcon />
                  </SvgWrapper>
                </Flex>
              </CustomButton>
            </>
          )}
          customDropdownWrapperStyles={{
            top: '130%',
            boxShadow: '0px',
            left: 0,
            right: 0,
            padding: 0,
            overflowY: 'auto',
            background: 'none',
            border: 0,
          }}
        />
      )}
    </Wrapper>
  );
};

export default observer(Filter);
