import styled from 'styled-components';
import {Typography} from '../typography/typography';
import {getColorFromTheme, getFontWeightFromTheme} from '../../ui-utils';
import {memo} from 'react';

const Title = styled.div`
  ${Typography};

  font-weight: ${getFontWeightFromTheme('semibold')};
  line-height: 1.5;
  letter-spacing: normal;
  color: ${getColorFromTheme('black')};
  text-decoration: none;
  &:hover {
    color: ${getColorFromTheme('purple300')};
  }
`;

const Wrapper = styled.div`
  display: inline-flex;

  span {
    ${Typography};

    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    flex: 1;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
`;

export interface LogoProps {
  title: string;
}

export const Logo = memo(function ({title}: LogoProps) {
  return (
    <Wrapper>
      <Title>
        <span>{title}</span>
      </Title>
    </Wrapper>
  );
});
Logo.displayName = 'Logo';
