import dayjs from 'dayjs';
import {observer} from 'mobx-react';
import {useMemo, useState} from 'react';
import {down} from 'styled-breakpoints';
import styled from 'styled-components';
import {useCheckinsCalendar} from '../../../../../hooks/checkins-calendar';
import {useStoreContext} from '../../../../../store/store-context';
import {authStore} from '../../../../../store/stores/auth-store';
import {Body2, Microcopy} from '../../../../../ui/atoms/typography';
import {getColorFromTheme} from '../../../../../ui/ui-utils';
import {
  getWeekRange,
  getDayOfWeekIndexFromString,
} from '../../../../../utils/date';
import {ArrowHeadDownIcon, ArrowHeadLeftIcon} from '@ui/atoms/icons';
import {ICheckinsCalendarWeekListResponseData} from '../../company-components/company-checkins/company-checkins.interface';
import {checkinName} from '@utils/checkin-name';

// Styled Components
const Wrapper = styled.div`
  box-shadow: 0px 0px 0px 1px #ededf2;
  border-radius: 10px;
  padding: 0;
`;

export const QuestionIconWrapper = styled.button<{tooltip?: boolean}>`
  margin-left: 8px;
  outline: none;
  position: relative;
  // z-index: 99999999;

  svg {
    width: 16px;
    height: 16px;
    stroke: ${getColorFromTheme('purple300')};
    margin-bottom: -2px;
  }
  @media (max-width: 500px) {
    display: none;
  }
  .tooltip {
    visibility: hidden;
    width: 399px;
    background-color: #fafafa;
    font-size: 14px;
    color: #5f5f8c;
    text-align: center;
    padding: 16px 16px 16px 16px;
    border: 1px solid #e4e5fb;
    border-radius: 5px;
    position: absolute;
    opacity: 0;
    transition: opacity 0.3s;
    // z-index: 99999999;

    margin-top: -100px;
  }
  &:hover .tooltip {
    visibility: ${(props: any) => (props.tooltip ? 'visible' : '')};
    opacity: 1;
    position: absolute;
    bottom: 150%;
    left: -245%;
    max-width: 300px;
  }

  .tooltip::before {
    content: ' ';
    position: absolute;
    top: 100%;
    right: 11.5%;
    margin-left: -5px;
    border-width: 11px;
    border-style: solid;
    border-color: #e4e5fb transparent transparent transparent;
  }

  .tooltip::after {
    content: ' ';
    position: absolute;
    top: 100%;
    right: 11.7%;
    margin-left: -5px;
    border-width: 10px;
    border-style: solid;
    border-color: #fafafa transparent transparent transparent;
  }
`;

const YearWrapper = styled.div`
  box-shadow: 0px 0px 0px 1px #ededf2;
  border-radius: 10px;
  padding: 0;
`;

const MonthYear = styled.div<{active?: boolean}>`
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;

  border-radius: 10px 10px 0px 0px;
  & span.nav {
    &:hover {
      cursor: pointer;
    }
  }
  & p {
    font-weight: 500;
  }
  .content {
    background: ${({active}) =>
      active ? '' : getColorFromTheme('backgroundDark')};
    box-shadow: ${({active}) => (active ? '0px 0px 0px 1px #CECEDE' : '')};
    border-radius: 6px;
    width: 100%;
    padding: 4px;
    margin: 8px 0px;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
const Year = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .content {
    box-shadow: 0px 0px 0px 1px ${getColorFromTheme('borderDark')};
    border-radius: 6px;
    width: fit-content;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  & span.nav {
    cursor: pointer;
  }
  & p {
    font-weight: 500;
  }
`;
const WeekBox = styled.div<{systemType?: 'mobile' | 'desktop'}>`
  box-shadow: 0px 0px 0px 1px #ededf2;
  border-radius: 10px;
  background: #fafafa;
  padding-top: 0.1px;
  padding-bottom: 0.1px;
  display: ${({systemType}) => (systemType === 'mobile' ? 'grid' : undefined)};
  ${down('md')} {
    grid-template-columns: ${({systemType}) =>
      systemType === 'mobile' ? '33% 33% 33% 33% 33%' : undefined};
  }
  ${down('sm')} {
    grid-template-columns: ${({systemType}) =>
      systemType === 'mobile' ? '50% 50% 50% 50% 50%' : undefined};
  }
  overflow-x: ${({systemType}) =>
    systemType === 'mobile' ? 'scroll' : undefined};
`;
const WeekCell = styled.div<{
  selected?: boolean;
  systemType?: 'mobile' | 'desktop';
}>`
  box-shadow: 0px 0px 0px 1px #ededf2;
  padding: 16px;

  margin: 8px;
  border-radius: 7px;
  cursor: pointer;
  background-color: ${({selected}) => (selected ? '#ffffff' : '#fafafa')};
  &:hover,
  :focus,
  :active {
    background-color: #ffffff;
  }
  & p.highlighted {
    font-weight: 500;
    margin-bottom: 8px;
  }
  &.currentWeek {
    background-color: #ffffff;
  }
  &.btn {
    &:active {
      background: #ffffff;
    }
  }
`;
const MonthBox = styled.div<{systemType?: 'mobile' | 'desktop'}>`
  box-shadow: 0px 0px 0px 1px #ededf2;
  display: ${({systemType}) => (systemType === 'mobile' ? 'grid' : undefined)};
  ${down('md')} {
    grid-template-columns: ${({systemType}) =>
      systemType === 'mobile'
        ? '33% 33% 33% 33% 33% 33% 33% 33% 33% 33% 33% 33%'
        : undefined};
  }
  ${down('sm')} {
    grid-template-columns: ${({systemType}) =>
      systemType === 'mobile'
        ? '50% 50% 50% 50% 50% 50% 50% 50% 50% 50% 50% 50%'
        : undefined};
  }
  border-radius: 10px;
  background: #fafafa;
  padding-top: 8px;
  padding-bottom: 8px;
  max-height: 300px;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const MonthCell = styled.div`
  box-shadow: 0px 0px 0px 1px #ededf2;
  padding: 16px;
  margin: 8px;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background-color: #ffffff;
    border: 1px solid ${getColorFromTheme('purple300')};
    & p {
      color: ${getColorFromTheme('purple300')} !important;
    }
  }
  & p.highlighted {
    font-weight: 500;
    margin-bottom: 8px;
  }
  &.currentMonth {
    background-color: #ffffff;
  }
`;

const FooterBox = styled.div`
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const YearNavigatorHeader = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  .content {
    width: fit-content;
    padding: 4px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${getColorFromTheme('backgroundDark')};
    border-radius: 6px;
  }
  & span.nav {
    cursor: pointer;
  }
  & p {
    font-weight: 500;
  }
`;

const YearNavigatorBox = styled.div<{systemType?: 'mobile' | 'desktop'}>`
  box-shadow: 0px 0px 0px 1px #ededf2;
  display: ${({systemType}) => (systemType === 'mobile' ? 'grid' : undefined)};
  ${down('md')} {
    grid-template-columns: ${({systemType}) =>
      systemType === 'mobile'
        ? '33% 33% 33% 33% 33% 33% 33% 33% 33% 33% 33% 33%'
        : undefined};
  }
  ${down('sm')} {
    grid-template-columns: ${({systemType}) =>
      systemType === 'mobile'
        ? '50% 50% 50% 50% 50% 50% 50% 50% 50% 50% 50% 50%'
        : undefined};
  }
  border-radius: 10px;
  background: #fafafa;
  padding-top: 8px;
  padding-bottom: 8px;
  max-height: 300px;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const YearNavigatorCell = styled.div`
  box-shadow: 0px 0px 0px 1px #ededf2;
  padding: 16px;
  margin: 8px;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background-color: #ffffff;
    border: 1px solid ${getColorFromTheme('purple300')};
    & p {
      color: ${getColorFromTheme('purple300')} !important;
    }
  }
  & p.highlighted {
    font-weight: 500;
    margin-bottom: 8px;
  }
  &.currentMonth {
    background-color: #ffffff;
  }
`;

interface IMonthView {
  onClickWeek?: ({
    start,
    end,
    weekOfYear,
  }: {
    start: string;
    end: string;
    weekOfYear: number;
  }) => void;
  view?: 'dates';
  disabled?: boolean;
  systemType?: 'mobile' | 'desktop';
}

// Month View of Calendar

const MonthView = observer(
  ({onClickWeek, systemType, disabled, view}: IMonthView) => {
    const {
      authStore: {
        setCalendarView,
        auth,
        // nextMonth, prevMonth,
      },
      checkinsStore: {checkinsDates, calendarWeeks},
    } = useStoreContext();

    const calendarData = useCheckinsCalendar(
      checkinsDates?.year,
      checkinsDates?.month,
      checkinsDates?.date,
    );

    const changeCalendarView = (view: string) => {
      setCalendarView(view);
      localStorage.setItem('calendarView', view);
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isActive, setActive] = useState(null);

    const toggleClass = (id: any) => {
      setActive(id);
    };

    const activeCalWeeks = useMemo(() => {
      return calendarData.currentMonthWeeks
        .filter(
          (week) =>
            (week.month === checkinsDates?.month ||
              week.monthEnd === checkinsDates?.month) &&
            new Date(week.dateStart) <= new Date(Date.now()),
        )
        .filter(
          (week) =>
            calendarWeeks?.map((calWk) => calWk.year).includes(week?.year) &&
            calendarWeeks
              ?.map((calWk) => calWk.weekOfYear)
              .includes(week?.weekOfYear),
        );
    }, [calendarData.currentMonthWeeks, calendarWeeks, checkinsDates?.month]);

    const parseWeekStatus = (week_: any, isCurrentWeek?: boolean) => {
      if (!!week_?.reviewedAt) {
        return 'Reviewed';
      } else {
        if (week_?.isSubmitted) {
          return 'Submitted';
        } else {
          if (isCurrentWeek) {
            if (
              getDayOfWeekIndexFromString(dayjs().format('dddd')) >
              getDayOfWeekIndexFromString(week_?.dueDay)
            ) {
              return 'Past due';
            } else {
              return week_?.dueDay ? `Due this ${week_?.dueDay || ''}` : '';
            }
          } else {
            return 'Past due date';
          }
        }
      }
    };

    return (
      <YearWrapper className="test">
        <MonthYear active={activeCalWeeks?.length !== 0}>
          <div
            className="content"
            onClick={() =>
              calendarWeeks?.length !== 0 ? changeCalendarView('year') : null
            }>
            <Body2
              kind={calendarWeeks?.length !== 0 ? 'textDark' : 'textMuted'}
              style={{
                cursor: calendarWeeks?.length !== 0 ? 'pointer' : 'not-allowed',
              }}>
              {calendarData.calendarTitle}
            </Body2>
            <ArrowHeadDownIcon
              style={{
                marginLeft: 14,
                stroke: 'black',
                transform: 'rotate(0deg)',
              }}
            />
          </div>
        </MonthYear>
        <WeekBox id="myDiv" systemType={systemType}>
          {activeCalWeeks?.length > 0 &&
            activeCalWeeks?.reverse()?.map((week: any, index: number) => {
              return week.isCurrentWeek ? (
                <WeekCell
                  selected={checkinsDates?.weekOfYear === week.weekOfYear}
                  systemType={systemType}
                  key={index}
                  onClick={() => {
                    !!onClickWeek &&
                      onClickWeek({
                        start: week.dateStart,
                        end: week.dateEnd,
                        weekOfYear: week.weekOfYear,
                      });
                  }}>
                  <Body2 kind="textDark" style={{fontWeight: 500}}>
                    This week’s {checkinName()}
                  </Body2>
                  {calendarWeeks?.length > 0 && (
                    <Body2
                      kind={
                        parseWeekStatus(
                          calendarWeeks?.filter(
                            (calWks: ICheckinsCalendarWeekListResponseData) =>
                              calWks?.weekOfYear === week?.weekOfYear &&
                              calWks?.year === week?.year,
                          )[0],
                          true,
                        ).includes('Due this')
                          ? 'orange300'
                          : 'textBody'
                      }>
                      {parseWeekStatus(
                        calendarWeeks?.filter(
                          (calWks: ICheckinsCalendarWeekListResponseData) =>
                            calWks?.weekOfYear === week?.weekOfYear &&
                            calWks?.year === week?.year,
                        )[0],
                        true,
                      )}
                    </Body2>
                  )}
                </WeekCell>
              ) : (
                <WeekCell
                  key={index}
                  selected={checkinsDates?.weekOfYear === week.weekOfYear}
                  onClick={() => {
                    !!onClickWeek &&
                      dayjs(week.dateStart).isAfter(
                        auth?.user?.workspace?.createdAt,
                      ) &&
                      dayjs(week.today).isAfter(week.dateStart) &&
                      onClickWeek({
                        start: week.dateStart,
                        end: week.dateEnd,
                        weekOfYear: week.weekOfYear,
                      });
                    toggleClass(index);
                  }}
                  style={{
                    cursor:
                      dayjs(week.dateStart).isAfter(
                        auth?.user?.workspace?.createdAt,
                      ) && dayjs(week.today).isAfter(week.dateStart)
                        ? 'pointer'
                        : 'not-allowed',
                    color: '#5e5e73',
                  }}>
                  <Body2
                    kind={
                      checkinsDates.weekOfYear === week.weekOfYear
                        ? 'textDark'
                        : 'textBody'
                    }
                    style={{fontWeight: 500}}>
                    {week.title}
                  </Body2>
                  {calendarWeeks?.length > 0 &&
                    calendarWeeks?.filter(
                      (calWks: ICheckinsCalendarWeekListResponseData) =>
                        calWks?.weekOfYear === week?.weekOfYear &&
                        calWks?.year === week?.year,
                    )?.length > 0 && (
                      <>
                        {
                          <Body2 kind="textBody">
                            {parseWeekStatus(
                              calendarWeeks?.filter(
                                (
                                  calWks: ICheckinsCalendarWeekListResponseData,
                                ) =>
                                  calWks?.weekOfYear === week?.weekOfYear &&
                                  calWks?.year === week?.year,
                              )[0],
                              false,
                            )}
                          </Body2>
                        }
                      </>
                    )}
                </WeekCell>
              );
            })}
          {activeCalWeeks?.length === 0 && (
            <Body2 align="center" kind="textMuted" style={{margin: '16px 8px'}}>
              No {checkinName()}s <br /> available
            </Body2>
          )}
        </WeekBox>
      </YearWrapper>
    );
  },
);

// Year view of calendar
const YearView = observer(
  ({
    systemType,

    variant,
    historicalCheckinsCloseModal,
    onClickWeek,
  }: {
    systemType?: 'mobile' | 'desktop';
    variant?: 'directReports' | '';
    historicalCheckinsCloseModal?: () => void;
    onClickWeek?: ({
      start,
      end,
      weekOfYear,
      month,
      year,
      date,
    }: {
      start: string;
      end: string;
      weekOfYear: number;
      month?: number;

      year?: number;
      date?: any;
    }) => void;
  }) => {
    const {
      authStore: {setCalendarView},
      checkinsStore: {
        setCheckinsDate,
        initializeMonthInStore,
        selectedYear,
        checkinsDates,
        calendarWeeks,
        calendarWeeksDirectReports,
      },
    } = useStoreContext();
    const getWeeks =
      variant === 'directReports' ? calendarWeeksDirectReports : calendarWeeks;

    const calendarData = useCheckinsCalendar(
      checkinsDates?.year,
      checkinsDates?.month,
      checkinsDates?.date,
    );

    const initializeMonth = (month: number, year: number) => {
      initializeMonthInStore(month, year);

      setCheckinsDate({
        month: month - 1,
        year,
        date: new Date(dayjs(`${year}-${month}-01`).format()),
      });

      if (variant === 'directReports') {
        const getMonth = month - 1;
        const findMonth = calendarData.currentMonthWeeks.filter(
          (date) => date.month === getMonth,
        );
        const computeWeekOfYear = findMonth[findMonth.length - 1];

        setCheckinsDate({
          weekOfYear: computeWeekOfYear.weekOfYear,
          starts: computeWeekOfYear.dateStart,
          ends: computeWeekOfYear.dateEnd,
        });
      }

      if (!!historicalCheckinsCloseModal) {
        historicalCheckinsCloseModal();
      } else {
        setCalendarView('month');
        localStorage.setItem('calendarView', 'month');
      }
    };

    const changeCalendarView = (view: string) => {
      setCalendarView(view);
      localStorage.setItem('calendarView', view);
    };

    const stringOfMonth = (month: number) => {
      switch (month) {
        case 1:
          return 'January';
        case 2:
          return 'February';
        case 3:
          return 'March';
        case 4:
          return 'April';
        case 5:
          return 'May';
        case 6:
          return 'June';
        case 7:
          return 'July';
        case 8:
          return 'August';
        case 9:
          return 'September';
        case 10:
          return 'October';
        case 11:
          return 'November';
        case 12:
          return 'December';
        default:
          return 'Not specified';
      }
    };

    const advCalMonths = useMemo(() => {
      const __date1 = getWeeks
        ?.map((week: any) => ({
          year: week?.year,
          weekOfYear: week?.weekOfYear,
          weekRange: getWeekRange(week?.weekOfYear, week?.year),
        }))
        .filter((week) => week?.weekOfYear <= 52)
        .map((week) => ({
          year: week?.year,
          months: [
            new Date(week?.weekRange?.starts).getMonth() + 1,
            new Date(week?.weekRange?.ends).getMonth() + 1,
          ],
        }))
        .filter((week) => week?.year === selectedYear);

      let arrMonths: number[] = [];
      __date1?.forEach((date, __index) => {
        if (date?.year === selectedYear) {
          arrMonths.push(...date?.months);
        }
      });
      return Array.from(new Set(arrMonths));
    }, [getWeeks, selectedYear]);

    return (
      <Wrapper className="test">
        <Year>
          <span
            className="nav"
            onClick={() => {
              if (!!historicalCheckinsCloseModal) {
                historicalCheckinsCloseModal();
              } else {
                changeCalendarView('month');
              }
            }}>
            <ArrowHeadLeftIcon style={{stroke: 'black'}} />
          </span>

          <div
            className="content"
            onClick={() => changeCalendarView('yearNavigator')}>
            <p>{selectedYear}</p>
          </div>
        </Year>
        <MonthBox systemType={systemType}>
          {advCalMonths?.reverse().map((calMonth: any, index: number) => {
            return calendarData.month === calMonth &&
              calendarData.thisCurrentPresentYear === selectedYear ? (
              <MonthCell
                key={index}
                onClick={() => {
                  initializeMonth(calMonth, selectedYear);
                }}>
                <p>{`${stringOfMonth(calMonth)}`}</p>
              </MonthCell>
            ) : (
              <MonthCell
                key={index}
                onClick={() => {
                  initializeMonth(calMonth, selectedYear);
                }}>
                <p>{`${stringOfMonth(calMonth)}`}</p>
              </MonthCell>
            );
          })}
        </MonthBox>

        <FooterBox>
          <Microcopy
            kind="textBody"
            onClick={() => {
              !!onClickWeek &&
                onClickWeek({
                  start: dayjs(
                    getWeekRange(
                      getWeeks?.[getWeeks?.length - 1]?.weekOfYear as number,
                      getWeeks?.[getWeeks?.length - 1]?.year as number,
                    ).starts,
                  ).format('MM/DD/YYYY'),
                  end: dayjs(
                    getWeekRange(
                      getWeeks?.[getWeeks?.length - 1]?.weekOfYear as number,
                      getWeeks?.[getWeeks?.length - 1]?.year as number,
                    ).ends,
                  ).format('MM/DD/YYYY'),
                  weekOfYear: getWeeks?.[getWeeks?.length - 1]
                    ?.weekOfYear as number,
                  month: new Date().getMonth(),
                  year: new Date().getFullYear(),
                  date: dayjs(new Date()).format(),
                });

              initializeMonthInStore(
                new Date().getMonth(),
                new Date().getFullYear(),
              );

              if (!!historicalCheckinsCloseModal) {
                historicalCheckinsCloseModal();
              } else {
                setCalendarView('month');
                localStorage.setItem('calendarView', 'month');
              }
            }}>
            Reset to default
          </Microcopy>
        </FooterBox>
      </Wrapper>
    );
  },
);

// Year navigator
const YearNavigator = observer(
  ({
    systemType,
    startYear,
    onClickWeek,
  }: {
    systemType?: 'mobile' | 'desktop';
    startYear: number;
    onClickWeek?: ({
      start,
      end,
      weekOfYear,
      month,
      year,
      date,
    }: {
      start: string;
      end: string;
      weekOfYear: number;
      month?: number;
      year?: number;
      date?: any;
    }) => void;
  }) => {
    const {
      authStore: {setCalendarView},
      checkinsStore: {
        setYear,

        checkinsDates,
        calendarWeeks,
      },
    } = useStoreContext();

    /// DO NOT DELETE COMMENT
    // const initializeMonth = (month: number, year: number) => {
    //   setCalendarView('month');
    //   localStorage.setItem('calendarView', 'month');
    //   initializeMonthInStore(month, year);
    //   setCheckinsDate({
    //     month: month - 1,
    //     weekOfYear: calendarWeeks?.[calendarWeeks?.length - 1]?.weekOfYear,
    //     year,
    //     date: new Date(dayjs(`${year}-${month}-01`).format()),
    //   });
    // };
    const changeCalendarView = (view: string) => {
      setCalendarView(view);
      localStorage.setItem('calendarView', view);
    };

    const generatedArrayOfLastYearsFromPresentYear = () => {
      const currentYear = new Date().getFullYear();
      const prev50Years = startYear;
      let genArr = [];
      for (let i = prev50Years; i <= currentYear; i++) {
        genArr.push(i);
      }
      return genArr;
    };
    return (
      <Wrapper className="test">
        <YearNavigatorHeader>
          {/* DO NOT DELETE */}
          <span className="nav" onClick={() => changeCalendarView('year')}>
            {/* {lessThanSvg} */}
          </span>

          <div className="content">
            <Body2 kind="textBody" style={{fontWeight: 500}}>
              {checkinsDates.year}
            </Body2>
          </div>
        </YearNavigatorHeader>
        <YearNavigatorBox systemType={systemType}>
          {generatedArrayOfLastYearsFromPresentYear()
            .reverse()
            .map((year: number, index: number) => {
              return (
                <YearNavigatorCell
                  key={index + 'yyy'}
                  onClick={() => {
                    setYear(year);
                    changeCalendarView('year');
                  }}>
                  <Microcopy kind="textDark" style={{fontWeight: 500}}>
                    {year}
                  </Microcopy>
                </YearNavigatorCell>
              );
            })}
        </YearNavigatorBox>
        <FooterBox
          onClick={() => {
            !!onClickWeek &&
              onClickWeek({
                start: dayjs(
                  getWeekRange(
                    calendarWeeks?.[calendarWeeks?.length - 1]
                      ?.weekOfYear as number,
                    calendarWeeks?.[calendarWeeks?.length - 1]?.year as number,
                  ).starts,
                ).format('MM/DD/YYYY'),
                end: dayjs(
                  getWeekRange(
                    calendarWeeks?.[calendarWeeks?.length - 1]
                      ?.weekOfYear as number,
                    calendarWeeks?.[calendarWeeks?.length - 1]?.year as number,
                  ).ends,
                ).format('MM/DD/YYYY'),
                weekOfYear: calendarWeeks?.[calendarWeeks?.length - 1]
                  ?.weekOfYear as number,
                month: new Date().getMonth(),
                year: new Date().getFullYear(),
                date: dayjs(new Date()).format(),
              });

            setCalendarView('month');
            localStorage.setItem('calendarView', 'month');
          }}>
          <Microcopy kind="textBody">Reset to default</Microcopy>
        </FooterBox>
      </Wrapper>
    );
  },
);

interface IYouCalendar {
  onClickWeek?: ({
    start,
    end,
    weekOfYear,
    month,
    year,
    date,
  }: {
    start: string;
    end: string;
    weekOfYear: number;
    month?: number;
    year?: number;
    date?: any;
  }) => void;
  systemType?: 'mobile' | 'desktop';
  view?: 'dates';
  variant?: 'directReports' | '';
  disabled?: boolean;
  historicalCheckinsCloseModal?: () => void;
  startYear: number;
}
export const YouCalendar = observer(
  ({
    onClickWeek,
    systemType,
    view,
    variant,
    disabled,
    historicalCheckinsCloseModal,
    startYear,
  }: IYouCalendar) => {
    const {calendarView} = authStore;

    return (
      <>
        {calendarView === 'month' && (
          <MonthView
            onClickWeek={onClickWeek}
            systemType={systemType}
            disabled={disabled}
          />
        )}
        {calendarView === 'year' && (
          <YearView
            onClickWeek={onClickWeek}
            variant={variant}
            systemType={systemType}
            historicalCheckinsCloseModal={historicalCheckinsCloseModal}
          />
        )}
        {calendarView === 'yearNavigator' && (
          <YearNavigator startYear={startYear} onClickWeek={onClickWeek} />
        )}
      </>
    );
  },
);
