import {VerticalSpacer} from '@ui/atoms/spacer';
import {Body1, Body2} from '@ui/atoms/typography';
import {FlexRow, FlexRowSpaceBetween} from '@ui/style/styles';
import React, {FC, memo, useMemo, useState} from 'react';
import {Insight} from '../interface';
import {ChevronDownIcon} from '@ui/atoms/icons';
import {Calendar} from '@ui/molecules/calendar';
import {DropdownCustom} from '@ui/molecules/dropdown-custom';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import dayjs from 'dayjs';
import {AddFilter} from './add-filter';
import {SecondaryMinusSVG} from '@ui/atoms/svg/minus';
import {CustomFilterMultiSelect} from '../../reporting-filter/custom-multi-select';
import {useSections} from '@hooks/action-items-sections';
import {useStoreContext} from '@store/store-context';
import {userName} from '@utils/user-name';
import {ObjectiveFilter} from '../objectives/objective-filter';
import {formatConfigDate} from '../insight-options';

interface Option {
  value: string;
  label: string;
}

interface Filter {
  selected: {value: string}[];
  options: {value: any; label: any}[];
  onChange: (value: string[]) => void;
}

interface FilterDetails {
  list: Filter;
  people: Filter;
}

interface EditInsightFilterProps {
  dateRangeType: Option[];
  isCustomDateRange: boolean;
  insightConfig: Insight['config'];
  updateInsight: (type: any, value: any) => void;
  dateRangeOptions: Option[];
  comparisonOptions: Option[];
  setCustomDateRange: (value: string, field: string) => void;
  canAddFilter: boolean;
  configIsChanged: boolean;
  isKeyResults?: boolean;
  handleReset: () => void;
  type: string;
  hasComparison?: boolean;
  canSelectObjective?: boolean;
  selectedLists?: string[];
  onChangeList?: (list: string[]) => void;
  onChangeUsers: (user: string[]) => void;
  selectedUsers?: string[];
  customDateRange: {
    starts: string;
    ends: string;
  };
}
export function getInitialAdditionalFilters(
  selectedLists: string[] = [],
  selectedUsers: string[] = [],
): string[] {
  if (selectedLists.length && selectedUsers.length) {
    return ['list', 'people'];
  } else if (selectedUsers.length) {
    return ['people'];
  } else if (selectedLists.length) {
    return ['list'];
  } else {
    return [];
  }
}

export const EditInsightFilter: FC<EditInsightFilterProps> = ({
  dateRangeType,
  isCustomDateRange,
  updateInsight,
  canSelectObjective,
  customDateRange,
  isKeyResults,
  handleReset,
  hasComparison = true,
  configIsChanged,
  type,
  setCustomDateRange,
  dateRangeOptions,
  canAddFilter = false,
  onChangeList,
  onChangeUsers,
  selectedLists = [],
  selectedUsers = [],
  comparisonOptions,
  insightConfig,
}) => {
  const [additionalFilters, setAdditionalFilters] = useState<string[]>(
    getInitialAdditionalFilters(selectedLists, selectedUsers),
  );

  const [_selectedUsers, setSelectedUsers] = useState(selectedUsers);

  const [_selectedList, setSelectedList] = useState(selectedLists);

  const {computeSections} = useSections();

  const {
    usersStore: {users},
  } = useStoreContext();

  const sections = computeSections.map((section) => ({
    value: section.slug,
    label: section.name,
  }));

  const allUsers = users.map((user) => ({
    value: user.id,
    label: userName(user),
  }));

  const dateRange = useMemo(() => formatConfigDate(insightConfig), [
    insightConfig,
  ]);

  const filterDetails: FilterDetails = useMemo(
    () => ({
      list: {
        selected: sections.filter((section) =>
          _selectedList.includes(section.value),
        ),
        options: sections,
        onChange: (value: string[]) => {
          setSelectedList(value);
          onChangeList?.(value);
        },
      },
      people: {
        selected: allUsers.filter((user) =>
          _selectedUsers.includes(user.value),
        ),
        options: allUsers,
        onChange: (value: string[]) => {
          setSelectedUsers(value);
          onChangeUsers?.(value);
        },
      },
    }),
    [
      _selectedList,
      _selectedUsers,
      allUsers,
      onChangeList,
      onChangeUsers,
      sections,
    ],
  );

  return (
    <div className="bg-[#F6F6F8] min-h-[95vh] h-full py-4 px-6 rounded-[10px] border border-[#E1E1EA] min-w-[30%]">
      <FlexRowSpaceBetween>
        <Body1 weight="bold">Filters</Body1>

        <button type="button" disabled={!configIsChanged} onClick={handleReset}>
          <Body2
            weight="semibold"
            kind={configIsChanged ? 'purple300' : 'textMuted'}>
            Reset
          </Body2>
        </button>
      </FlexRowSpaceBetween>
      <VerticalSpacer size="24px" />
      <Body1 weight="bold" className="mt-4">
        Date range
      </Body1>
      <VerticalSpacer size="16px" />
      <CustomDropDown
        options={dateRangeType}
        value={insightConfig.type}
        onChange={(value) => {
          updateInsight('type', value);
        }}
      />
      <VerticalSpacer size="8px" />
      {isCustomDateRange && (
        <FlexRowSpaceBetween>
          <CustomDateSelect
            value={customDateRange.starts}
            onChange={(value) => {
              setCustomDateRange(value, 'starts');

              if (
                dayjs(dayjs(value, 'DD-MM-YY').format()).isAfter(
                  dayjs(customDateRange.ends, 'DD-MM-YY'),
                )
              ) {
                setCustomDateRange('', 'ends');
              }
            }}
          />
          <Body2 weight="bold">to</Body2>

          <CustomDateSelect
            value={customDateRange.ends}
            disabledBefore={customDateRange.starts}
            onChange={(value) => {
              setCustomDateRange(value, 'ends');
            }}
          />
        </FlexRowSpaceBetween>
      )}

      <VerticalSpacer size="8px" />

      {!isCustomDateRange && (
        <>
          <CustomDropDown
            options={dateRangeOptions}
            value={String(insightConfig.range.value)}
            onChange={(value) => {
              if (value.toLowerCase() === 'custom') {
                return;
              }

              const isPeriod = insightConfig.type === 'periods';

              if (!['action-items', 'objectives'].includes(type)) {
                updateInsight('comparison', {
                  type: 'day',
                  value,
                });
              }

              updateInsight('range', {
                type: isPeriod
                  ? 'periods'
                  : insightConfig.type === 'custom'
                  ? 'custom'
                  : 'day',

                value,
              });
            }}
          />
          <VerticalSpacer size="24px" />
        </>
      )}
      {hasComparison && (
        <div className="pb-6 border-b border-b-borderLight">
          <Body1 weight="bold" className="mt-4">
            Compared to
          </Body1>
          <VerticalSpacer size="8px" />

          <CustomDropDown
            options={comparisonOptions}
            onChange={(value) =>
              updateInsight('comparison', {
                type: 'day',
                value,
              })
            }
            value={String(insightConfig.comparison.value)}
          />
        </div>
      )}
      <VerticalSpacer size="24px" />
      {additionalFilters.map((filter) => (
        <div key={filter} className="border-b border-b-borderLight pb-6 mb-4">
          <FlexRowSpaceBetween className="mb-4">
            <Body2 className="capitalize" weight="bold">
              {filter}
            </Body2>

            <button
              onClick={() => {
                setAdditionalFilters((prev) =>
                  prev.filter((_filter) => _filter !== filter),
                );

                filterDetails[filter as 'list'].onChange([]);
              }}>
              <FlexRow>
                <SecondaryMinusSVG />
                <Body2 kind="red400" weight="bold" className="">
                  remove
                </Body2>
              </FlexRow>
            </button>
          </FlexRowSpaceBetween>

          <CustomFilterMultiSelect
            selectedOptionStyle={{background: 'white'}}
            options={filterDetails[filter as 'list'].options}
            onChange={(value: Option[]) =>
              filterDetails[filter as 'list'].onChange(
                value.map((option) => option.value),
              )
            }
            // disabled={!!breakdownBy}
            placeholderTerm={filter}
            selectedOptions={(filterDetails as any)[filter].selected}
          />
        </div>
      ))}

      {type === 'objectives' && (
        <ObjectiveFilter
          {...dateRange}
          isKeyResults={isKeyResults}
          canAddObjective={canSelectObjective}
          handleChange={(field, value) => {
            if (field === 'goal') {
              updateInsight('objectives', value);
            }
            updateInsight(field, value);
          }}
          groups={insightConfig.groups}
          objectives={insightConfig.objectives}
          keyResult={insightConfig.key_result}
          objectiveType={insightConfig.objectiveType}
        />
      )}

      {canAddFilter && (
        <AddFilter
          filters={additionalFilters}
          updateFilters={(value) =>
            setAdditionalFilters((prev) => [...prev, value])
          }
        />
      )}
    </div>
  );
};

interface CustomDropDownProps {
  options: {value: string; label: string}[];
  value: string;
  onChange: (value: string) => void;
}

interface CustomDateSelectProps {
  value: string;
  onChange: (value: string) => void;
  disabledBefore?: string;
  disabledAfter?: string;
}
const CustomDateSelect = ({
  value,
  onChange,
  disabledAfter,
  disabledBefore,
}: CustomDateSelectProps) => {
  return (
    <DropdownCustom
      collapseOnClick={true}
      menu={(handleClose: () => void) => (
        <div>
          <Calendar
            value={dayjs(value, 'DD-MM-YY').format()}
            disabledAfter={dayjs(disabledAfter, 'DD-MM-YY').format()}
            disabledBefore={dayjs(disabledBefore, 'DD-MM-YY').format()}
            onChange={(value) => {
              onChange(dayjs(value).format('DD-MM-YY'));
              handleClose();
            }}
          />
        </div>
      )}
      customComponent={(handleOpen: (event: any) => void, open: boolean) => (
        <>
          <button
            onClick={handleOpen}
            className="rounded-[10px] min-h-[46px] min-w-[90px] border bg-white w-full border-borderDark py-3 px-4 flex items-center justify-between">
            <Body2>
              {' '}
              {value ? dayjs(value, 'DD-MM-YY').format('DD-MM-YY') : ''}
            </Body2>
          </button>
        </>
      )}
      customDropdownWrapperStyles={{
        top: '130%',
        right: '0px',
        background: 'none',
        padding: 0,
        boxShadow: '0px',
        border: 'none',
        width: '140%',
        minWidth: '260px',
      }}
    />
  );
};

export const CustomDropDown = memo(
  ({options, value, onChange}: CustomDropDownProps) => {
    const label = options.find((option) => option.value === value)?.label;

    return (
      <>
        <DropdownCustom
          collapseOnClick
          customComponent={(
            handleOpen: (event: any) => void,
            open: boolean,
          ) => (
            <button
              onClick={handleOpen}
              className="rounded-[10px] min-h-[46px] border bg-white w-full border-borderDark py-3 px-4 flex items-center justify-between">
              <Body2>{label}</Body2>

              <ChevronDownIcon />
            </button>
          )}
          menu={(handleClose: () => void) => (
            <div>
              {options.map((option) => (
                <DropdownItem
                  onClick={() => {
                    handleClose();
                    onChange(option.value);
                  }}>
                  {option.label}
                </DropdownItem>
              ))}
            </div>
          )}
          customDropdownWrapperStyles={{
            top: '130%',
            right: '0px',
            width: '100%',
          }}
        />
      </>
    );
  },
);
