import {Body1, Body2} from '@ui/atoms/typography';
import React from 'react';

export const NextSubmission = ({dueDay}: {dueDay: number}) => {
  return (
    <div className="py-6 px-10 rounded-[10px] border border-borderLight flex items-center justify-center flex-col">
      <Body1 weight="bold">You've completed your most recent mood check.</Body1>
      <Body2 kind="textBody">
        Your next mood check is coming up in{' '}
        <span className="font-semibold">{dueDay} day(s).</span>
      </Body2>
    </div>
  );
};
