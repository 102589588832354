import {Country, City} from 'country-state-city';
import {ICountry, IState, ICity} from 'country-state-city/dist/lib/interface';
import {getStatesOfCountry} from 'country-state-city/dist/lib/state';
import {useState, useEffect} from 'react';

export const useBillingCycleState = () => {
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const [states, setStates] = useState<IState[]>([]);
  const [selectedState, setSelectedState] = useState<string>('');
  const [cities, setCities] = useState<ICity[]>([]);
  const [addressSaved, setAddressSaved] = useState(false);
  const [line1, setLine1] = useState('');
  const [openDrawer, setOpenDrawer] = useState(false);
  const [coupon, setCoupon] = useState<string>('');

  useEffect(() => {
    setCountries(Country.getAllCountries());
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      setStates(getStatesOfCountry(selectedCountry));
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState && selectedCountry) {
      setCities(City.getCitiesOfState(selectedCountry, selectedState));
    }
  }, [selectedCountry, selectedState]);

  useEffect(() => {
    if (
      selectedState &&
      selectedState?.length > 0 &&
      selectedCountry &&
      selectedCountry?.length > 0
    ) {
      setCities(City.getCitiesOfState(selectedCountry, selectedState));
    }
  }, [selectedCountry, selectedState, setCities]);

  return {
    countries,
    selectedCountry,
    setSelectedCountry,
    states,
    selectedState,
    setSelectedState,
    cities,
    addressSaved,
    setAddressSaved,
    line1,
    setLine1,
    openDrawer,
    setOpenDrawer,
    coupon,
    setCities,
    setCoupon,
  };
};
