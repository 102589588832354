import {useStoreContext} from '@store/store-context';
import {useMemo} from 'react';

export const useDirectOptionHook = () => {
  const {
    usersStore: {users},
    authStore: {auth},
  } = useStoreContext();
  const teamLabel = (user: {firstName: string; email: string}) =>
    user.firstName ? `${user.firstName}'s Team` : `${user.email}'s Team`;

  const directReportsTeamOption = useMemo(() => {
    const directReports = {value: auth?.user?.id, label: 'Direct reports'};

    if (auth.user.role !== 'admin')
      return [
        directReports,
        {
          value:
            typeof auth.user.reviewer === 'string'
              ? auth.user.reviewer
              : auth.user.reviewer?.id,
          label: 'My Team',
        },
      ];

    const managers = users
      .filter((user) => user.isReviewer && user.id !== auth.user.id)
      .map((user) => ({value: user.id, label: teamLabel(user)}));

    const options = [
      {
        value:
          typeof auth.user.reviewer === 'string'
            ? auth.user.reviewer
            : auth.user.reviewer?.id,
        label: 'My Team',
      },
      ...managers,
    ];

    const doesDirectReportExist = options.find(
      (manager) => manager.value === directReports.value,
    );

    if (!doesDirectReportExist) {
      options.unshift(directReports);
    }

    return options;
  }, [users, auth?.user?.role, auth?.user?.id, auth?.user?.reviewer]);
  return {
    directReportsTeamOption,
  };
};
