import {usePeriodHook} from '@hooks/period-hook';
import {useWorkspaceUseCase} from '@hooks/workspace-usecase';
import {AnalyticsController} from '@pages/dashboard/reporting/reporting-pages/admin-analytics/admin-analytics.controller';
import {AnalyticsRequestImpl} from '@pages/dashboard/reporting/reporting-pages/admin-analytics/admin-analytics.request';
import {YouHightLight} from '@pages/dashboard/you/you-components/you-hightligts';
import {useStoreContext} from '@store/store-context';
import {Body2, Headline3} from '@ui/atoms/typography';
import {AnalyticsProgress} from '@ui/molecules/analytics-progress';
import {SecondaryNavigation} from '@ui/molecules/nav/navigation';
import {ItemLoader} from '@ui/organisms/item-loader';
import {FlexRowCenter} from '@ui/style/styles';
import {AnalyticsTodayDate} from '@utils/date';
import dayjs from 'dayjs';
import {observer} from 'mobx-react';
import {useCallback, useMemo, useState} from 'react';
import {useQuery} from 'react-query';

interface IGoalsProgress {
  userId: string;
}

export const GoalsProgress = observer(({userId}: IGoalsProgress) => {
  const [selectedNav, setSelectedNav] = useState<'summary' | 'details'>(
    'summary',
  );
  const {
    groupStore: {groups},
  } = useStoreContext();

  const {currentMonthRange, isPeriodEnabled} = usePeriodHook();

  const {isPerformanceActive} = useWorkspaceUseCase();

  const getPeriod = useMemo(
    () =>
      isPeriodEnabled
        ? {
            starts: currentMonthRange().starts,
            ends: currentMonthRange().ends,
            period: currentMonthRange().period,
          }
        : {
            starts: AnalyticsTodayDate().AnalyticsDefaultStartDate,
            ends: AnalyticsTodayDate().AnalyticsDefaultEndDate,
          },
    [isPeriodEnabled, currentMonthRange],
  );

  const fetchSpecifiedGoals = useCallback(
    async (filters: any, view?: string) => {
      const request = new AnalyticsRequestImpl();
      const controller = new AnalyticsController(request);

      const response = await controller.fetchSpecifiedGoals(
        filters,
        view,
        true,
      );

      const includeGroups = async (goalsResponse: any) => {
        const groupsIncluded = await Promise.all(
          goalsResponse &&
            goalsResponse?.map(async (goal: any) => {
              if (goal.goalType === 'group') {
                const group = goal.group.map((grp: string) => {
                  return groups.find((_group) => _group.id === grp);
                });

                return {
                  ...goal,
                  group,
                };
              } else
                return {
                  ...goal,
                };
            }),
        );
        return groupsIncluded;
      };

      if (!!response.goals) {
        delete filters.member;

        const periodRange = {
          ...filters,
          startDate: getPeriod.starts,
          endDate: getPeriod.ends,
        };

        const keyresults = await controller.fetchSpecifiedKpis(
          periodRange,
          true,
        );

        const unfurledData = await includeGroups(response?.goals);

        const prevResponse = await controller.fetchSpecifiedGoals(
          {
            ...filters,
            endDate: dayjs().subtract(14, 'days').format('MM/DD/YYYY'),
          },
          view,
          true,
        );

        const computeResponse = {
          ...response.summary,
          onTrackProgress:
            response.summary?.onTrackPercent -
            prevResponse.summary.onTrackPercent,
          fulfillmentProgress:
            response.summary.goals?.fulfillment -
            prevResponse.summary.goals?.fulfillment,
          behindProgress:
            response.summary?.behindPercent -
            prevResponse.summary.behindPercent,
          atRiskProgress:
            response.summary.atRiskPercent - prevResponse.summary.atRiskPercent,
        };

        const computeGoalsWithKeyResults = unfurledData.map((goal: any) => {
          const __goal = response.goals?.find(
            (_goal: any) => _goal.id === goal.id,
          );

          return {
            ...goal,
            activities: __goal?.activities || goal.activites,
            keyResults: goal.keyResults?.map((keyresult: any) => {
              const getKeyResult = keyresults.find(
                (kr: any) => kr.id === keyresult.id,
              );

              return {
                ...keyresult,
                activities: getKeyResult?.activities,
              };
            }),
          };
        });

        return {
          goals: computeGoalsWithKeyResults,
          summary: response.summary,
          computedSummary: [
            {
              name: 'Progress',
              percentageIncrease:
                computeResponse?.goals?.fulfillmentProgress || 0,
              progress: computeResponse?.goals?.fulfillment || 0,
            },
            {
              name: 'On-track',
              progress: computeResponse?.onTrackPercent,
            },
            {
              name: 'Behind',
              progress: computeResponse?.behindPercent,
            },
            {
              name: 'At risk',
              progress: computeResponse?.atRiskPercent,
            },
          ],
        };
      }
    },
    [getPeriod.ends, getPeriod.starts, groups],
  );

  const {data, isLoading} = useQuery(
    ['meetings-goals', getPeriod, userId],
    () =>
      fetchSpecifiedGoals({
        startDate: getPeriod?.starts,
        endDate: getPeriod?.ends,
        filterBy: 'weeks',
        removeKpiFilter: true,
        // newReport: true,
        member: userId,
      }),
    {
      enabled: isPerformanceActive,
    },
  );

  const goalActivities = data?.goals?.flatMap((goal) => goal.activities);

  const latestUpdatedAt = goalActivities?.reduce((latest, goal) => {
    // Parse the date strings to compare
    const currentUpdatedAt = new Date(goal.updatedAt);
    const latestUpdatedAt = new Date(latest);

    // If the current goal's updatedAt is later than the latest one found, update the latestUpdatedAt
    if (currentUpdatedAt > latestUpdatedAt) {
      return goal.updatedAt;
    } else {
      return latest;
    }
  }, goalActivities?.[0]?.updatedAt);

  const navigations = useMemo(() => {
    const nav = [
      {
        active: selectedNav === 'summary',
        href: '#',
        id: '2',
        onClick: () => {
          setSelectedNav('summary');
        },
        title: 'Summary',
      },
      {
        active: selectedNav === 'details',
        href: '#',
        id: '2',
        onClick: () => {
          setSelectedNav('details');
        },
        title: 'Details',
      },
    ];

    return nav;
  }, [selectedNav]);

  return (
    <div>
      <div className="border border-borderLight rounded-[10px]">
        <div className="py-4 px-6 flex items-center justify-between">
          <Headline3 kind="textDark">Goals</Headline3>
          <SecondaryNavigation
            kind="details"
            type="button"
            navStyle={{
              width: 'max-content',
              padding: '4px 8px',
              height: '38px',
            }}
            navigations={navigations}
          />
        </div>
        <div
          className={`border-t border-t-[#ededf2] rounded-t-[10px] ${
            selectedNav === 'details' ? 'p-2' : 'px-10 py-6 `'
          }`}>
          {isLoading && (
            <FlexRowCenter className="">
              <ItemLoader />
            </FlexRowCenter>
          )}

          {!isLoading && (!data || !data.goals.length) && (
            <>
              <FlexRowCenter
                className={
                  selectedNav === 'details' ? 'min-h-[62px]' : 'min-h-[30px]'
                }>
                <Body2 kind="textBody">No goals created yet.</Body2>
              </FlexRowCenter>
            </>
          )}

          {selectedNav === 'summary' && data && !!data.goals?.length && (
            <>
              <div className="grid xl:grid-cols-4 grid-cols-2 sm:grid-cols-1 g gap-4 grid-flow-rows summary-grid">
                {data &&
                  !isLoading &&
                  data.computedSummary?.map((option, idx) => (
                    <AnalyticsProgress
                      key={idx}
                      className="w-full"
                      title={option.name}
                      percentage={option.progress}
                      percentageIncrease={option.percentageIncrease}
                    />
                  ))}
              </div>
            </>
          )}

          {selectedNav === 'details' &&
            !isLoading &&
            data &&
            !!data.goals.length && (
              <YouHightLight
                goals={
                  new Map(data.goals?.map((item: any) => [item?.id, item]))
                }
                kind="youcheckins"
                youCheckinsKind="viewuser"
                isFetching={isLoading}
              />
            )}
        </div>
        <div className="border-t border-t-[#ededf2] py-3 px-6">
          <Body2 weight="semibold" kind="textBody">
            Last updated:{' '}
            {latestUpdatedAt
              ? dayjs(latestUpdatedAt).format('MMMM DD, YYYY')
              : '-'}
          </Body2>
        </div>
      </div>
    </div>
  );
});
