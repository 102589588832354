import {CSSProperties, memo} from 'react';

export const ArrowRightIcon = memo(({style}: {style?: CSSProperties}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16">
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      style={style}
      d="M3.332 8h9.333M8 3.333L12.667 8 8 12.667"
    />
  </svg>
));

ArrowRightIcon.displayName = 'ArrowRightIcon';
