import {Fragment, memo, ReactNode, useEffect} from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import {down, up} from 'styled-breakpoints';
import {Overlay} from './overlay';

export interface ModalProps {
  children: ReactNode;
  open: boolean;
  onClose: () => void;
  fullHeight?: boolean;
}

const ModalLayer = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 5;
`;

const Container = styled.div`
  display: flex;
  flex: 0 1 auto;
  justify-content: center;
  min-height: 1px;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const ContentContainer = styled.div<{fullHeight?: boolean}>`
  margin: 0 auto;
  pointer-events: auto;
  width: 100%;
  display: block;
  flex-direction: column;
  min-height: 1px;
  height: 100%;

  ${down('sm')} {
    padding-top: ${({fullHeight}) => (fullHeight ? 0 : '20px')};
  }

  ${up('sm')} {
    padding-top: ${({fullHeight}) => (fullHeight ? 0 : '40px')};
  }

  ${up('lg')} {
    padding: ${({fullHeight}) => (fullHeight ? 0 : '60px 0')};
  }

  ${up('xl')} {
    padding: ${({fullHeight}) => (fullHeight ? 0 : '60px 0')};
  }
`;

const stopPropagation = (e: any) => e.stopPropagation();

export const openSpring = {type: 'spring', stiffness: 200, damping: 30};
export const closeSpring = {type: 'spring', stiffness: 300, damping: 35};

export const Modal = memo(function ({
  children,
  open,
  onClose,
  fullHeight,
}: ModalProps) {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [open]);

  return (
    <Fragment>
      {open
        ? ReactDOM.createPortal(
            <ModalLayer tabIndex={-1}>
              <Overlay>
                <Container key="modal-item" onClick={onClose}>
                  <ContentContainer
                    onClick={stopPropagation}
                    fullHeight={fullHeight}>
                    {children}
                  </ContentContainer>
                </Container>
              </Overlay>
            </ModalLayer>,
            document.body,
          )
        : null}
    </Fragment>
  );
});

Modal.displayName = 'Modal';
