import {memo} from 'react';
import {CompanyIcon} from '../icons/company';
import {UsersIcon} from '../icons/users';
import {UserIcon} from '../icons/user';
import {QuestionKind} from '../../../pages/dashboard/company-legacy/company-components/company-checkins/company-checkins.interface';

export interface QuestionIconProps {
  kind: QuestionKind;
}

export const QuestionIcon = memo(({kind}: QuestionIconProps) => {
  switch (kind) {
    case QuestionKind.GENERAL:
      return <CompanyIcon />;
    case QuestionKind.INDIVIDUAL:
      return <UserIcon />;
    case QuestionKind.GROUP:
      return <UsersIcon />;
    // no-default
    default:
      return <UsersIcon />;
  }
});

QuestionIcon.displayName = 'QuestionIcon';
