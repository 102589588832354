import React, {SVGProps} from 'react';

export const PenEditIcon = (props?: SVGProps<any>) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.195262 8.47166L7 1.66692C7.92047 0.746444 9.41286 0.746443 10.3333 1.66692C11.2538 2.58739 11.2538 4.07978 10.3333 5.00025L3.5286 11.805C3.40357 11.93 3.234 12.0003 3.05719 12.0003H0.666667C0.298477 12.0003 0 11.7018 0 11.3336V8.94306C0 8.76625 0.070238 8.59668 0.195262 8.47166ZM9.62623 4.29315L2.91912 11.0003H1V9.08113L7.70711 2.37403C8.23706 1.84407 9.09628 1.84407 9.62623 2.37403C10.1562 2.90398 10.1562 3.7632 9.62623 4.29315Z"
        fill="#BFBFD4"
        {...props}
      />
    </svg>
  );
};
