import {motion} from 'framer-motion';
import {useState} from 'react';
import styled from 'styled-components';
import {useAnimation} from 'framer-motion';
import {Body2} from '../typography';
import {ColorIconRed} from '../color-icons';
import {EyeIcon} from '../icons';
export const ViewDetailsAnimation = styled.div<{open?: boolean}>``;

export const ViewDetailsWrapper = styled(motion.div)<{
  open: boolean;
  variant?: string;
}>`
  background: #f6f6fe;
  border-radius: ${({open}) => (open ? '10px' : '100px')};
  padding: ${({open}) => (open ? '7px' : '4px')};
  height: 24px;
  display: flex;
  cursor: pointer;
  align-items: center;
  position: relative;
  margin-right: ${({variant}) => (variant === 'youcheckins' ? '5px' : '10px')};
  margin-left: 5px;
  justify-content: center;
  .notification {
    position: absolute;
    top: -28%;
    right: ${({open}) => (open ? '-5%' : '-10%')};
  }
  @media (max-width: 1285px) {
    margin-top: 14px;
  }
`;
export const GoalViewInsights = ({
  onClick,
  userIsMentioned,
  onHover,
  variant,
}: {
  onClick: () => void;
  userIsMentioned?: boolean;
  onHover?: boolean;
  variant?: string;
}) => {
  const divAnimationVariants = {
    init: {
      width: '24px',
    },
    anim: {
      width: '65px',
      duration: 5,
    },
  };

  const [viewDetails, setViewDetails] = useState(false);
  const divAnimationControls = useAnimation();

  return (
    <ViewDetailsAnimation open={viewDetails ? viewDetails : undefined}>
      <ViewDetailsWrapper
        onMouseOver={() => onHover && setViewDetails(true)}
        onMouseLeave={() => onHover && setViewDetails(false)}
        className={'fadeIn'}
        animate={divAnimationControls}
        onHoverStart={() =>
          onHover && divAnimationControls.start(divAnimationVariants.anim)
        }
        onHoverEnd={() =>
          onHover && divAnimationControls.start(divAnimationVariants.init)
        }
        variant={variant}
        onClick={onClick}
        open={viewDetails}>
        <EyeIcon />{' '}
        {viewDetails && (
          <span>
            <Body2
              kind="purple300"
              weight={'semibold'}
              className="fadeIn"
              style={{marginLeft: '4px', fontSize: '12px'}}>
              View
            </Body2>
          </span>
        )}
        {userIsMentioned && (
          <div className="notification">
            <ColorIconRed width="8px" height="8px" />
          </div>
        )}
      </ViewDetailsWrapper>
    </ViewDetailsAnimation>
  );
};
