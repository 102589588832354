import {memo} from 'react';

export const NairaIcon = memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16">
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M2.668 6h10.667M2.668 10h10.667M6 14V2l4 12M10 2v12"
    />
  </svg>
));

NairaIcon.displayName = 'NairaIcon';
