import {memo} from 'react';

export const PercentIcon = memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16">
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12.665 3.333l-9.333 9.334M4.335 6C5.255 6 6 5.254 6 4.333c0-.92-.746-1.667-1.666-1.667-.92 0-1.667.747-1.667 1.667S3.414 6 4.335 6zM11.667 13.333c.92 0 1.666-.746 1.666-1.666 0-.92-.746-1.667-1.666-1.667-.92 0-1.667.746-1.667 1.667 0 .92.746 1.666 1.667 1.666z"
    />
  </svg>
));

PercentIcon.displayName = 'PercentIcon';
