import {useLocation} from 'react-router';
import {useCallback} from 'react';

export const useIsRouteActive = () => {
  const location = useLocation();

  const isRouteActive = useCallback(
    (route: string) => location.pathname.includes(route),
    [location.pathname],
  );

  const isStrictRouteActive = useCallback(
    (route: string) => location.pathname === route,
    [location.pathname],
  );

  return {isRouteActive, isStrictRouteActive};
};
