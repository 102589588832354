import {DrawerModal} from '@ui/organisms/modal/drawer-modal';

import {Headline3} from '@ui/atoms/typography';

import styled from 'styled-components';

import {VerticalSpacer} from '@ui/atoms/spacer';

import {
  SearchCheckin,
  SearchObjective,
} from '@pages/1:1s/view-meetings/components/view-user';
import {ReviewComponent} from '../make-review/make-review';

export const DateWrapper = styled.div`
  margin-top: 12px;
`;
interface ViewUserProps {
  open: boolean;
  onClose: () => void;
  name: string;
  user: string;
  type: 'check-in' | 'objective' | 'feedback' | '1:1' | 'self-review';
}

export const ViewUser = ({open, type, onClose, name, user}: ViewUserProps) => {
  return (
    <div>
      <DrawerModal open={open} onClose={onClose}>
        <div>
          <Headline3>
            {' '}
            {type === 'self-review'
              ? `${name}’s Self Review`
              : `Choose from ${name.split(' ')[0]}’s ${
                  type === 'objective' ? ' objectives' : 'checkins'
                }`}{' '}
          </Headline3>
          <VerticalSpacer size="16px" />

          {type === 'objective' && (
            <SearchObjective user={user} name={name} useInnerDate />
          )}
          {type === 'self-review' && (
            <ReviewComponent _viewResult _finalizeReview removeTitle />
          )}
          {type === 'check-in' && (
            <SearchCheckin user={user} name={name} useInnerDate />
          )}
        </div>
      </DrawerModal>
    </div>
  );
};
