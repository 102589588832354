import {observer} from 'mobx-react';
import {Button} from '../../../ui/atoms/button';
import {PlusIcon} from '../../../ui/atoms/icons';
import {QuestionIconWrapper} from '@ui/molecules/tooltip/tooltip';
import {AlertBadge} from '@ui/atoms/icons/alert-badge';
import {DropdownCustom} from '@ui/molecules/dropdown-custom';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {VerticalSpacer} from '../../../ui/atoms/spacer';
import {Body1, Body2, Headline3} from '../../../ui/atoms/typography';
import {ArrowHeadDownIcon} from '../../../ui/atoms/icons';
import {ItemLoader} from '../../../ui/organisms/item-loader';
import {useBreakpoints} from '@utils/use-breakpoints';
import {
  CustomGoal,
  Hr,
  PaginationWrapper,
  ResponsiveFlexRowSpaceBetween,
  ObjectivesWrapper,
  Wrapper,
} from './styles';
import {FlexRowSpaceBetween} from '@ui/style/styles';
import {FC, useMemo, useState} from 'react';

interface ITemplate {
  id: string;
  title: string;
  children: string[];
}
interface ITemplatesProps {
  templates: ITemplate[];
  onClose: () => void;
  title: string;
  isLoading?: boolean;
  limit: number;
  totalResults: number;
  updateLimit: (value: number) => void;
  templatesType: {value: string; label: string}[];
  handleClick: (value: ITemplate) => void;
  templateName: string;
}

export const Templates: FC<ITemplatesProps> = observer(
  ({
    templates,
    onClose,
    totalResults,
    title,
    isLoading,
    limit,
    templatesType,
    handleClick,
    templateName,
    updateLimit,
  }) => {
    const computeTypes = useMemo(() => {
      return templatesType;
    }, [templatesType]);

    const [activeTab, setActiveTab] = useState('');

    const selectedOption =
      computeTypes.find((option) => option.value === activeTab) ||
      computeTypes[0];

    const computeTemplates = useMemo(() => {
      // const computeAllTemplates = templates.filter(
      //   (template: any) => template.type === activeTab,
      // );
      return templates;
    }, [templates]);

    const {isSm} = useBreakpoints();

    return (
      <>
        <Wrapper>
          <FlexRowSpaceBetween>
            <Headline3 kind="textDark">
              {title}
              <QuestionIconWrapper tooltip={true}>
                <AlertBadge />
                <span
                  className="tooltip"
                  style={{
                    top: '-40px',
                    zIndex: 9999,
                    width: '300px',
                    padding: '16px',
                  }}></span>
              </QuestionIconWrapper>
            </Headline3>
            <Button
              style={{padding: '8px 16px'}}
              kind="secondary"
              onClick={() => {
                onClose();
              }}>
              Dismiss
            </Button>
          </FlexRowSpaceBetween>
          <Hr />
          <ResponsiveFlexRowSpaceBetween>
            <div className="desc">
              <Body1 kind="textBody">
                Choose a category that best suits your team or organizations
                needs and stage.
              </Body1>
            </div>
            <DropdownCustom
              collapseOnClick={true}
              menu={(handleClose: () => void) => (
                <div>
                  <div>
                    {computeTypes.map((option, idx) => (
                      <DropdownItem
                        key={idx}
                        onClick={() => {
                          handleClose();
                          setActiveTab(option.value);
                        }}>
                        {option.label}
                      </DropdownItem>
                    ))}
                  </div>
                </div>
              )}
              customComponent={(
                handleOpen: (event: any) => void,
                open: boolean,
              ) => (
                <Button
                  onClick={handleOpen}
                  width={isSm ? 'full' : 'sm'}
                  kind="secondary"
                  style={{
                    padding: '14px 16px',
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <Body2 weight="semibold"> {selectedOption?.label}</Body2>
                  <ArrowHeadDownIcon
                    style={{
                      marginLeft: 14,
                      stroke: '#1E1E2F',
                      transform: !open ? 'rotate(0deg)' : 'rotate(180deg)',
                    }}
                  />
                </Button>
              )}
              customDropdownWrapperStyles={{
                top: '130%',
                right: 0,
                width: '210px',
              }}
            />
          </ResponsiveFlexRowSpaceBetween>
          <VerticalSpacer size="24px" />

          <VerticalSpacer size="24px" />
          <ObjectivesWrapper>
            {computeTemplates?.length > 0 ? (
              computeTemplates?.map((template, index: number) => (
                <div
                  key={template?.id}
                  style={{
                    marginBottom:
                      index !== computeTemplates?.length - 1
                        ? '16px'
                        : undefined,
                  }}>
                  <CustomGoal>
                    <div className="exploreTemplatesHeader">
                      <div>
                        <VerticalSpacer size="12px" />
                        <Body1 weight="bold">{template.title}</Body1>
                      </div>
                      <Button
                        kind="secondary"
                        width="sm"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '38px',
                          overflow: 'hidden',
                        }}
                        onClick={() => {
                          handleClick(template);
                        }}>
                        <p
                          style={{
                            marginRight: '0.75rem',
                            marginBottom: -4,
                          }}>
                          Use this
                        </p>
                        <PlusIcon
                          styles={{stroke: '#000000', strokeWidth: '1.5px'}}
                        />
                      </Button>
                    </div>

                    <div className="exploreTemplatesKeyresult">
                      <Body2 weight="semibold" kind="textBody">
                        {templateName}
                      </Body2>
                      <VerticalSpacer size="12px" />

                      {template?.children?.map((child, index: number) => (
                        <div
                          style={{
                            marginBottom:
                              index !== template?.children?.length - 1
                                ? '16px'
                                : undefined,
                          }}>
                          <Body2 weight="semibold">{child}</Body2>
                        </div>
                      ))}
                    </div>
                  </CustomGoal>
                </div>
              ))
            ) : (
              <div style={{padding: '1.625px'}}>
                <p>No template predefined for this type.</p>
              </div>
            )}
          </ObjectivesWrapper>

          {isLoading && <ItemLoader />}

          {!isLoading && limit < totalResults ? (
            <PaginationWrapper>
              <Button
                kind={'secondary'}
                style={{padding: '8px 16px'}}
                onClick={() => {
                  updateLimit(limit + 10);
                }}>
                See more
              </Button>
            </PaginationWrapper>
          ) : null}
        </Wrapper>
      </>
    );
  },
);
