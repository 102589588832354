import {useStoreContext} from '@store/store-context';
import {Button} from '@ui/atoms/button';
import {CancelIcon} from '@ui/atoms/icons';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {Headline2, Body1} from '@ui/atoms/typography';
import {ModalCard} from '@ui/layouts/modal-card';
import {BackAction} from '@ui/molecules/back-action';
import {Modal} from '@ui/molecules/modal';
import React from 'react';

export const ReactivateGoal = ({
  reOpenGoalLoader,
  reActivateGoal,
}: {
  reActivateGoal: () => void;
  reOpenGoalLoader: boolean;
}) => {
  const {goalsState} = useStoreContext();

  return (
    <Modal open={true} onClose={() => {}}>
      <div style={{maxWidth: '500px', margin: 'auto'}}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}>
          <BackAction
            width="md"
            icon={<CancelIcon />}
            onClick={() => {
              goalsState.setReactivateGoal({
                reOpen: false,
                id: '',
                name: '',
              });
            }}
            title="Close"
          />
        </div>
        <VerticalSpacer size="16px" />
        <ModalCard align="center" title={``}>
          <div>
            <VerticalSpacer size="16px" />
            <span>
              <Headline2 align="center">
                Re-open this {goalsState.goalFramework}?
              </Headline2>
            </span>
            <VerticalSpacer size="16px" />
            <Body1 kind="textBody" align="center">
              <span style={{color: '#585ADF', fontWeight: 500}}>
                {goalsState.reactivate_goal.name}
              </span>
              {'  '}
              will be opened to updates until 11:59pm today.
            </Body1>
            <VerticalSpacer size="16px" />
            <Body1 kind="textBody" align="center">
              Click{' '}
              <span style={{fontWeight: 500, color: 'black'}}>"confirm"</span>
              to continue.
            </Body1>
            <VerticalSpacer size="16px" />

            <Button
              isLoading={reOpenGoalLoader}
              onClick={reActivateGoal}
              width="full">
              Confirm
            </Button>
          </div>
        </ModalCard>
      </div>
    </Modal>
  );
};
