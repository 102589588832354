import {memo} from 'react';

export const LockIcon = memo(() => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_32077_79812)">
        <path
          d="M10.9375 5.25H10.5V3.5C10.5 1.56975 8.93025 0 7 0C5.06975 0 3.5 1.56975 3.5 3.5V5.25H3.0625C2.33917 5.25 1.75 5.83858 1.75 6.5625V12.6875C1.75 13.4114 2.33917 14 3.0625 14H10.9375C11.6608 14 12.25 13.4114 12.25 12.6875V6.5625C12.25 5.83858 11.6608 5.25 10.9375 5.25ZM4.66667 3.5C4.66667 2.21317 5.71317 1.16667 7 1.16667C8.28683 1.16667 9.33333 2.21317 9.33333 3.5V5.25H4.66667V3.5Z"
          fill="#BFBFD4"
        />
      </g>
      <defs>
        <clipPath id="clip0_32077_79812">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
});
