import {Button} from '@ui/atoms/button';
import {Body2, Body1} from '@ui/atoms/typography';
import {VerticalSpacer} from '@ui/atoms/spacer/spacer';
import {FlexRowCenter, FlexRowSpaceBetween} from '@ui/style/styles';
import {goalDueDate} from '@utils/date';
import {getColorFromTheme} from '@ui/ui-utils';
import {EmptyPlaceholder} from '@ui/atoms/empty-placeholder';
import {useNavigate} from 'react-router';
import styled from 'styled-components';
import {useStoreContext} from '@store/store-context';
import {cloudinary} from '@assets/images/cloudinary';
import {Table, TableHeader, TableRow, TableWrapper} from '../../cc-styles';
import {useQuery} from 'react-query';
import {CcRequestImpl} from '@api/cc-api/cc-request';
import {CcController} from '@api/cc-api/cc-controller';
import {ItemLoader} from '@ui/organisms/item-loader';
import {UserListCard} from '@ui/molecules/user/list-card';
import {useFirebaseFetch} from '@hooks/query-hook';
import {useCountdown} from '@utils/countdown';
import {ccFirebaseReminderTracker} from '@utils/firebase-request';
import {useState} from 'react';

const {emptyGoals} = cloudinary;

export const Wrapper = styled.div`
  width: 100%;
`;

const Card = styled.div`
  border: 1px solid ${getColorFromTheme('borderDark')};
  border-radius: 8px;
  height: 38px;
  background: white;
  width: 168px;
  padding: 8px 16px;
`;
export default function PeerReview({
  disabled,
  dueDay,
  reviewType,
  cycleId,
}: {
  disabled: boolean;
  dueDay: string;
  cycleId: string;
  reviewType: string;
}) {
  const navigate = useNavigate();

  const {
    authStore: {auth},
  } = useStoreContext();

  const request = new CcRequestImpl();
  const controller = new CcController(request);

  const fetchUserNomination = async () => {
    const response = await controller.fetchReviewNomination(
      auth.user.id,
      cycleId,
    );

    if (response) {
      return response;
    }

    return [];
  };

  const {data = [], isLoading} = useQuery(
    ['nomination', cycleId, auth.user.id],
    () => fetchUserNomination(),
  );

  const emptyPlaceholder = {
    image: {
      alt: 'alt text',
      shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
      src: emptyGoals,
      style: {height: '30%', width: '30%'},
    },
    imports: true,
    subtitle: '',
    variant: 'default' as 'default',
    action: (
      <Button
        style={{padding: '8px 16px '}}
        onClick={() =>
          navigate({
            search: `?openNomination=true&userId=${auth.user.id}&reviewId=${cycleId}`,
          })
        }>
        Nominate peer
      </Button>
    ),
    title: 'Choose at least 1 peer (Max 3.)',
  };

  const EmptyReview = () => {
    return (
      <FlexRowCenter>
        <div>
          <EmptyPlaceholder {...emptyPlaceholder} />
        </div>
      </FlexRowCenter>
    );
  };
  return (
    <div>
      <Wrapper>
        <FlexRowSpaceBetween>
          <Body1 weight="bold">Peer reviews</Body1>

          {data?.length > 0 && (
            <Button
              style={{padding: '8px 16px'}}
              onClick={() =>
                navigate({
                  search: `?openNomination=true&userId=${auth.user.id}&reviewId=${cycleId}`,
                })
              }
              disabled={data.length >= 3}>
              Nominate Peer
            </Button>
          )}
        </FlexRowSpaceBetween>

        <VerticalSpacer size="7px" />
        <TableWrapper>
          <Table>
            <TableHeader variant="you">
              <td style={{maxWidth: '150px', minWidth: '150px'}}>
                <Body2 kind="textBody">Name</Body2>
              </td>

              <td style={{maxWidth: '100px', minWidth: '100px'}}>
                <Body2 kind="textBody">Progress</Body2>
              </td>
              <td style={{maxWidth: '100px', minWidth: '100px'}}>
                <Body2 kind="textBody">Actions</Body2>
              </td>
            </TableHeader>
            {!isLoading && !data?.length && <EmptyReview />}
            {isLoading && (
              <FlexRowCenter style={{height: '200px'}}>
                <ItemLoader />
              </FlexRowCenter>
            )}
            {data &&
              data.map((reviewer: NominationReview) => (
                <Nomination
                  nomination={reviewer}
                  dueDay={dueDay}
                  reviewId={cycleId}
                />
              ))}
          </Table>
        </TableWrapper>
      </Wrapper>
    </div>
  );
}

type NominationReview = {
  reviewer: string;
  createdBy: string;
  id: string;
  reviewWritten: boolean;
};
type NominationProps = {
  nomination: NominationReview;
  dueDay: string;
  reviewId: string | null;
};

const Nomination = ({nomination, reviewId, dueDay}: NominationProps) => {
  const request = new CcRequestImpl();
  const controller = new CcController(request);

  const {
    usersStore: {users},
    authStore: {auth},
  } = useStoreContext();

  const reminderId = `users/${auth.user.id}/review_reminder/${reviewId}/${nomination.reviewer}`;

  const {data: reminderExists} = useFirebaseFetch(`${reminderId}`);

  const {minutes, seconds} = useCountdown(reminderExists || 0);

  const isDisabled = nomination.reviewWritten || minutes > 0;

  const fetchWrittenReviews = async () => {
    const response = await controller.fetchWrittenReview({
      reviewCycle: reviewId || '',
      reviewerType: 'peer',
    });

    return response;
  };

  const {data: writtenReviews} = useQuery(
    ['written-review', reviewId, 'peer'],
    () => fetchWrittenReviews(),
  );

  const isReviewWritten = () => {
    return !!writtenReviews?.find(
      (review: {reviewer: string; user: string}) =>
        review.reviewer === nomination.reviewer && review.user === auth.user.id,
    );
  };

  const [reminderLoader, setReminderLoader] = useState(false);

  const sendReminder = async (nominationId: string) => {
    setReminderLoader(true);

    const response = await controller.sendNominationReminder(nominationId);

    setReminderLoader(false);
    if (response) {
      ccFirebaseReminderTracker(nomination.reviewer, reviewId || '');
    }
  };

  return (
    <>
      <TableRow variant="you">
        <td style={{maxWidth: '200px', minWidth: '200px'}}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            {users
              .filter((user) => user.id === nomination.reviewer)
              .map((user) => (
                <UserListCard
                  kind="admin"
                  type="secondary"
                  textStyle={{fontWeight: 600}}
                  tooltip={true}
                  avatar={user.firstName ? user.firstName : ''}
                  reviewer={user.reviewer}
                  name={
                    user.firstName && user.lastName
                      ? `${user.firstName} ${user.lastName}`
                      : ` ... (pending)`
                  }
                  userId={user.id}
                />
              ))}
          </div>
        </td>

        <td>
          <div>
            <Card>
              <FlexRowCenter>
                <Body2
                  weight="semibold"
                  kind={isReviewWritten() ? 'green400' : 'orange300'}>
                  {isReviewWritten()
                    ? 'Completed'
                    : goalDueDate(dueDay, false, false, true)}
                </Body2>
              </FlexRowCenter>
            </Card>
          </div>
        </td>
        <td>
          <Button
            disabled={isDisabled || isReviewWritten()}
            onClick={() => sendReminder(nomination.id)}
            isLoading={reminderLoader}
            kind="secondary"
            style={{
              padding: '8px 16px',
            }}>
            {minutes > 0 ? `Resend  (${minutes}:${seconds})` : 'Send reminder'}
          </Button>
        </td>
      </TableRow>
    </>
  );
};
