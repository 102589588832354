import {authStore} from '@store/stores/auth-store';
import {BaseRequest} from '@configs/request';

interface ICompanyGoalsRequest extends BaseRequest {
  fetchTeamMemberGoals(filters: IFiltersTeamMemberGoals): Promise<any>;
}
export interface IFiltersTeamMemberGoals {
  manager?: string;
  member?: string;
  group?: string;
  starts?: string;
  name?: string;
  ends?: string;
}

export class CompanyGoalsRequestImpl
  extends BaseRequest
  implements ICompanyGoalsRequest {
  url: string = '/goals/active?include=assignee';

  async fetchTeamMemberGoals(filters: IFiltersTeamMemberGoals) {
    const {manager, member, group, starts, ends, name} = filters;
    const accessUrl = `${this.url}&${manager ? `manager=${manager}` : ''}${
      member ? `&user=${member}` : ''
    }${member || manager ? '&' : ''}${
      group ? `group=${group}` : '&sortByUsers=true&deep_fetch=true'
    }&paginate=false${starts ? `&startDate=${starts}` : ''}${
      ends ? `&endDate=${ends}` : ''
    }${name ? `&name=${name}` : ''}`;
    const {data: response} = await this.request.get(accessUrl, {
      headers: {
        Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
      },
      useCache: false,
    });

    return response;
  }
}
