import {Metric} from '@ui/organisms/stack-view/stack-view';
import {UserListCard} from '@ui/molecules/user/list-card';
import {Tooltip} from '@ui/molecules/tooltip';
import {Body2} from '@ui/atoms/typography';
import {
  TreeViewKeyResultsWrapper,
  Rule,
  TreeViewGoalsCardButton,
} from './treeview.styles';
import {GreenLine} from '@ui/atoms/icons/align-line';
import {getPercentCompletedKeyResult} from '@utils/proportions';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {FiChevronRight} from 'react-icons/fi';
import {ProgressBar} from '@ui/molecules/progress-bar';
import {FC} from 'react';

interface IKeyResult {
  type?: string;
  kind?: string;
  goalIndex: number;
  getIndex: (keyResult: Metric) => number;
  calcLineHeight: (id: string) => {kr: number; goal: number};
  doShowAlignee: () => void;
  isGoalCascaded: boolean;
  isKeyResultCascaded: boolean;
  genShowAlignee: () => boolean;
  getChildIndex: (keyResult: Metric) => number;
}

interface TreeViewBoxKeyResultsProps extends IKeyResult {
  keyResults: Metric[];
}

export const TreeViewBoxKeyResults = ({
  keyResults,
  ...rest
}: TreeViewBoxKeyResultsProps) => {
  return (
    <div>
      {keyResults.map((keyResult, index) => (
        <TreeViewBoxKeyResult
          key={keyResult.id}
          keyResult={keyResult}
          {...rest}
          index={index}
        />
      ))}
    </div>
  );
};

interface TreeViewBoxKeyResultProps extends IKeyResult {
  keyResult: Metric;
  index: number;
}

const TreeViewBoxKeyResult: FC<TreeViewBoxKeyResultProps> = ({
  keyResult,
  kind,
  goalIndex,
  type,
  getIndex,
  index,
  doShowAlignee,
  isGoalCascaded,
  isKeyResultCascaded,
  calcLineHeight,
  genShowAlignee,
  getChildIndex,
}) => {
  return (
    <div>
      <div style={{position: 'relative'}}>
        {!!keyResult?.keyResultAlignment?.keyResult &&
          type === 'child' &&
          kind === 'cascade' && (
            <span
              style={{
                position: 'absolute',
                bottom: 65.2,
                height: '100%',
                left: -32,
              }}>
              {goalIndex === 0 && index === getIndex(keyResult) && (
                <Rule
                  bg={'#084B8A'}
                  width="20px"
                  height="2px"
                  style={{left: 0}}
                  top={'117.5px'}
                />
              )}

              {goalIndex !== 0 && (
                <span
                  style={{
                    position: 'absolute',
                    left: '-4.3%',
                    height: '100%',
                    bottom: '90px',
                  }}>
                  <div
                    style={{
                      position: 'relative',
                      height: '100%',
                    }}></div>
                </span>
              )}

              <div
                style={{
                  position: 'relative',
                  height: '100%',
                }}>
                <div
                  style={{
                    position: 'absolute',
                    left: 5,
                    bottom: '-10px',
                  }}>
                  <GreenLine color={'#084B8A'} />
                </div>
              </div>

              {getIndex(keyResult) !== index && (
                <Rule
                  bg={'#084B8A'}
                  // style={{hei}}
                  height={`${
                    (calcLineHeight(keyResult.id).goal *
                      (calcLineHeight(keyResult.id).kr || 1) +
                      (getIndex(keyResult) > index && goalIndex === 0
                        ? getIndex(keyResult) - index
                        : 0)) *
                      (index === 0
                        ? 110.5
                        : goalIndex === 0 && getIndex(keyResult) < index
                        ? 131
                        : getIndex(keyResult) > index
                        ? 118.5
                        : 113) -
                    (index && index < getIndex(keyResult)
                      ? getIndex(keyResult) * 31
                      : 0)
                  }px`}
                  left="4px"
                  style={{
                    bottom:
                      getIndex(keyResult) < index || goalIndex !== 0
                        ? 0
                        : undefined,
                  }}
                />
              )}
            </span>
          )}
        <TreeViewKeyResultsWrapper
          key={index}
          bg={
            keyResult?.alignedKeyResults?.length > 0 ||
            (isGoalCascaded && keyResult?.keyResultAlignment?.keyResult)
              ? '084B8A'
              : undefined
          }>
          <Tooltip
            text={keyResult.name}
            BodyTextNodeType={Body2}
            maxLength={37}
            withEllipsis
            bodyTextNodeKind="textBody"
            tooltipBody={keyResult.name}
          />

          <div style={{position: 'absolute', right: -5}}>
            {
              <TreeViewGoalsCardButton
                bg={'#084B8A'}
                onClick={() => {
                  doShowAlignee();
                }}>
                {keyResult?.alignedKeyResults?.length > 0 && (
                  <>
                    <div className="arrow">
                      {!genShowAlignee() && isKeyResultCascaded ? (
                        keyResult.alignedKeyResults?.length
                      ) : (
                        <FiChevronRight size="10" />
                      )}
                    </div>

                    {getChildIndex(keyResult) < index && genShowAlignee() && (
                      <span
                        style={{
                          position: 'absolute',
                          bottom: '-8px',
                          height: '100%',
                          left: '35px',
                        }}>
                        <div
                          style={{
                            position: 'relative',
                            height: '100%',
                            width: '100%',
                          }}>
                          <Rule
                            bg={
                              isGoalCascaded || isKeyResultCascaded
                                ? '#084B8A'
                                : '#1A9E68'
                            }
                            width="2px"
                            height="200px"
                            style={{left: '-20px'}}
                            top={'2px'}
                          />
                        </div>
                      </span>
                    )}

                    {genShowAlignee() && (
                      <Rule
                        bg={'#084B8A'}
                        left="15px"
                        top={'10px'}
                        width="15px"
                      />
                    )}
                  </>
                )}
              </TreeViewGoalsCardButton>
            }
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              margin: '10px 0px',
              width: '100%',
            }}>
            <ProgressBar
              type="admin-plus"
              pStyle={{width: 250}}
              wStyle={{gridTemplateColumns: '85% auto'}}
              kpi={{
                current: keyResult?.currentValue,
                base: keyResult?.targetValue,
                good: keyResult?.goodValue,
                stretch: keyResult?.stretchValue,
              }}
              state={
                getPercentCompletedKeyResult(
                  keyResult.startValue,
                  keyResult.targetValue,
                  keyResult.currentValue,
                ) + '%'
              }
              percent={getPercentCompletedKeyResult(
                keyResult.startValue,
                keyResult.targetValue,
                keyResult.currentValue,
              )}
              measurement={keyResult.measurement as any}
            />
          </div>
          <UserListCard
            kind="admin"
            type="secondary"
            reviewer={keyResult?.assignee.reviewer}
            avatar={
              keyResult?.assignee.avatar ? keyResult?.assignee.avatar.url : ''
            }
            name={
              keyResult?.assignee.firstName && keyResult?.assignee.lastName
                ? `${keyResult?.assignee.firstName} ${keyResult?.assignee.lastName}`
                : `${keyResult?.assignee.email.substr(0, 5)}... (pending)`
            }
            tooltip={true}
            userId={keyResult?.assignee.id}
          />
          <VerticalSpacer size="6px" />
        </TreeViewKeyResultsWrapper>
      </div>
    </div>
  );
};
