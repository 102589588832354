import styled, {DefaultTheme, StyledComponentProps} from 'styled-components';
import {ITypography, Typography} from './typography';
import {
  getFontSizeFromTheme,
  getFontWeightFromTheme,
  getLineHeightFromTheme,
} from '../../ui-utils';

export type Headline4Props = StyledComponentProps<
  'h4',
  DefaultTheme,
  ITypography,
  'kind' | 'align'
>;

/**
 Use to display higher hierarchical content
 **/
export const Headline4 = styled.h4<ITypography>`
  ${Typography};

  font-size: ${getFontSizeFromTheme('headline4')};
  font-weight: ${getFontWeightFromTheme('bold')};
  line-height: ${getLineHeightFromTheme('headline4')};
`;
