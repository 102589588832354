import styled, {CSSObject} from 'styled-components';
import styledMap from 'styled-map';
import {getColorFromTheme} from '../../ui-utils';
import {TChildProps} from '../../interface';
import {Headline4} from '../../atoms/typography/headline4';
import {theme} from '../../style';
import {memo, ReactNode} from 'react';

type CardKind = 'dark' | 'white';
type Padding = 'small' | 'normal' | 'none';
type Display = 'none' | 'flex' | 'grid';

const Wrapper = styled.section<{variant?: string}>`
  border-width: 1px;
  border-style: solid;
  background-color: white;
  border-color: ${getColorFromTheme('borderLight')};
  border-radius: 10px;
  padding: ${styledMap('variant', {
    exploreTemplates: '16px',
    default: '0px',
  })};
`;

const HeaderWrapper = styled.div<{
  padding?: Padding;
  display?: Display;
  variant?: string;
}>`
  width: 100%;
  padding: ${styledMap('padding', {
    small: '16px',
    normal: '24px',
    default: '16px 24px',
    none: '0px',
  })};
  display: ${styledMap('display', {
    flex: 'flex',
    grid: 'grid',
    default: 'flex',
    none: 'none',
  })};
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  @media (max-width: 500px) {
    flex-direction: ${styledMap('variant', {
      exploreTemplatesHeader: 'column',
      columnEnd: 'column',
      columnStart: 'column',
      default: 'row',
    })};

    align-items: ${styledMap('variant', {
      exploreTemplatesHeader: 'flex-end',
      columnStart: 'flex-start',
      columnEnd: 'flex-start',
      default: 'center',
    })};

    .action {
      margin-top: ${styledMap('variant', {
        exploreTemplatesHeader: '',
        columnStart: '',
        columnEnd: '20px',
        default: '',
      })};
      display: flex;

      width: ${styledMap('variant', {
        exploreTemplatesHeader: '',
        columnStart: '',
        columnEnd: '100%',
        default: '',
      })};

      justify-content: ${styledMap('variant', {
        exploreTemplatesHeader: '',
        columnStart: '',
        columnEnd: 'flex-end',
        default: '',
      })};
    }
  }
`;

const ContentWrapper = styled.div<{
  kind?: CardKind;
  padding?: Padding;
  variant?: string;
}>`
  padding: ${styledMap('padding', {
    small: '4px',
    normal: '8px',
    default: '8px',
    none: '0px',
  })};
  width: 100%;
  box-shadow: 0px 0px 0px 1px ${getColorFromTheme('borderLight')};
  border-radius: ${styledMap('variant', {
    exploreTemplates: '0px',
    default: '10px',
  })};
  background-color: ${styledMap('kind', {
    dark: theme.colors.backgroundDark,
    default: theme.colors.white,
    white: theme.colors.white,
  })};
`;

export interface SectionCardProps extends TChildProps {
  title?: ReactNode | string;
  secondaryTitle?: ReactNode | string;
  action?: ReactNode;
  kind?: CardKind;
  headerPadding?: Padding;
  extraHeader?: ReactNode;
  variant?: string;
  contentPadding?: Padding;
  displayHeader?: Display;
  CustomHeaderTitle?: JSX.Element;
  contentStyling?: CSSObject;
  notificationheader?: ReactNode;
}

export const SectionCard = memo(function ({
  children,
  title,
  secondaryTitle,
  action,
  kind,
  headerPadding,
  variant,
  displayHeader,
  contentPadding,
  extraHeader,
  CustomHeaderTitle,
  contentStyling,
  notificationheader,
}: SectionCardProps) {
  return (
    <Wrapper variant={variant} data-testid="section-card">
      {notificationheader}
      <HeaderWrapper
        className="headerClassName"
        padding={headerPadding}
        display={displayHeader}
        variant={variant}>
        {CustomHeaderTitle ? (
          CustomHeaderTitle
        ) : (
          <>{title && <Headline4>{title}</Headline4>}</>
        )}
        {secondaryTitle && <div style={{width: '175px'}}>{secondaryTitle}</div>}
        {action && <div className="action">{action}</div>}
      </HeaderWrapper>
      {extraHeader && extraHeader}
      <ContentWrapper
        padding={contentPadding}
        kind={kind}
        style={contentStyling}>
        {children}
      </ContentWrapper>
    </Wrapper>
  );
});
SectionCard.displayName = 'SectionCard';
