import {memo} from 'react';

export const AlertBadgeInfoSVG = memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20">
    <path
      stroke="#585ADF"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M10.001 18.333c4.603 0 8.334-3.73 8.334-8.333S14.604 1.667 10 1.667C5.4 1.667 1.668 5.397 1.668 10c0 4.602 3.73 8.333 8.333 8.333zM10 13.333V10M10 6.667h.009"
    />
  </svg>
));

AlertBadgeInfoSVG.displayName = 'AlertBadgeInfoSVG';
