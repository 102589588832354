import {Body1, Body2} from '@ui/atoms/typography';
import {FlexRowSpaceBetween} from '@ui/style/styles';
import React, {memo} from 'react';

interface AnalyticsProgressProps {
  title: string;
  percentage: number;
  className?: string;
  useInverse?: boolean;

  percentageIncrease?: number;
}

export const AnalyticsProgress = memo(
  ({
    title,
    percentage,
    className,
    useInverse,
    percentageIncrease,
  }: AnalyticsProgressProps) => {
    const isPositive =
      useInverse && percentageIncrease
        ? percentageIncrease < 0
        : percentageIncrease && percentageIncrease > 0;

    return (
      <div
        className={`border border-borderLight rounded-lg ${className} ${
          !percentageIncrease ? 'flex flex-col justify-center' : ''
        }`}>
        <FlexRowSpaceBetween className="py-2 px-4">
          <Body1 weight="bold" className="mr-2 break-all">
            {title}
          </Body1>
          <div className="py-1 px-3 bg-[#EDEDF2] flex items-center justify-center  rounded-lg">
            <Body1 weight="bold">{percentage}%</Body1>
          </div>
        </FlexRowSpaceBetween>
        {typeof percentageIncrease !== 'undefined' ? (
          <div className="border-t border-t-[#ededf2]">
            <FlexRowSpaceBetween className="py-1 px-3">
              <Body2
                weight="semibold"
                kind="textBody"
                className="mr-2"
                size="12px">
                from last 14 days
              </Body2>
              <Body2
                weight="semibold"
                kind={
                  isPositive
                    ? 'green400'
                    : percentageIncrease === 0
                    ? 'textMedium'
                    : 'red400'
                }
                size="12px">
                {percentageIncrease > 0
                  ? `+${percentageIncrease}`
                  : percentageIncrease}
                %
              </Body2>
            </FlexRowSpaceBetween>
          </div>
        ) : null}
      </div>
    );
  },
);
