import styled, {keyframes} from 'styled-components';

const easeInOut = keyframes`
  0% {
    left: 0;
  }
  50% {
    left: 60px;
  }
  100% {
    left: 0;
  }
`;

const Wrapper = styled.div`
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
`;

const LoaderWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100px;
  height: 6px;
  background-color: #f6f6f8;
  margin: auto;
  border-radius: 30px;
`;

const Svg = styled.div`
  position: absolute;
  left: 0;
  background-color: #585adf;
  width: 40px;
  height: 6px;
  display: block;
  top: 0;
  border-radius: 30px;
  x-transition: all 2s ease-in-out;
  animation: ${easeInOut} 1.5s infinite;
`;

export const ItemLoaderLine = () => {
  return (
    <Wrapper>
      <LoaderWrapper>
        <Svg></Svg>
      </LoaderWrapper>
    </Wrapper>
  );
};
