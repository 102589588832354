import React from 'react';

export const BehindIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_26597_2816)">
        <path
          d="M7.9987 10.6668C9.47146 10.6668 10.6654 9.47292 10.6654 8.00016C10.6654 6.5274 9.47146 5.3335 7.9987 5.3335C6.52594 5.3335 5.33203 6.5274 5.33203 8.00016C5.33203 9.47292 6.52594 10.6668 7.9987 10.6668Z"
          stroke="#E6821D"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M0.700195 8H4.66686"
          stroke="#E6821D"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.3398 8H15.3065"
          stroke="#E6821D"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_26597_2816">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
