import {action} from 'mobx';
import {activateNotification} from '../../../ui/molecules/notification/activate-notification';
import {AdminProfileFormData} from './admin-profile-interface';
import {AdminProfileRequest, IGoogleAuthRequest} from './admin-profile-request';
import {authStore} from '../../../store/stores/auth-store';
import {parseErrorContent} from '../../../utils/error-handling';

export class AdminProfileController {
  constructor(private readonly request: AdminProfileRequest) {}

  @action
  async adminProfile(data: AdminProfileFormData) {
    try {
      const response = await this.request.adminProfile(data);
      localStorage.setItem('email', JSON.stringify(response.email));
      return response;
    } catch (e: any) {
      // Todo: handle error

      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }
}
export class GoogleAuthController {
  constructor(private readonly request: IGoogleAuthRequest) {}

  async googleAuth(token: string, isAdmin: boolean) {
    try {
      if (token) {
        const {data: response} = await this.request.googleAuth(token, isAdmin);
        const {tokens, user, redirectUrl} = response;
        if (redirectUrl) {
          authStore.setInitResponse({tokens, user});
          return {redirectUrl};
        } else {
          return {tokens, user};
        }
      }
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }
}
