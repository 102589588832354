import {BackAction} from '@ui/molecules/back-action';
import {CancelIcon} from '@ui/atoms/icons';
import styled, {CSSProperties} from 'styled-components';
import {ModalCard} from '@ui/layouts/modal-card';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {Modal} from '@ui/molecules/modal';
import {ReactNode} from 'react';
import {FlexRowSpaceBetween} from '@ui/style/styles';
import {Headline2} from '@ui/atoms/typography';
import {switchProp} from 'styled-tools';

const CancelWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 800px) {
    margin-top: 8%;
    margin-right: 9%;
  }
`;

export const ModalCardWrapper = styled.div<{widthType?: 'quarter'}>`
  display: flex;
  justify-content: flex-end;

  div.child {
    width: ${switchProp(
      'widthType',
      {
        quarter: '30vw',
      },
      '40vw',
    )};

    @media (min-width: 1440px) {
      width: ${switchProp(
        'widthType',
        {
          quarter: '30vw',
        },
        '40vw',
      )};
    }

    @media (min-width: 2000px) {
      width: ${switchProp(
        'widthType',
        {
          quarter: '25vw',
        },
        '40vw',
      )};
    }

    @media (min-width: 1100px) and (max-width: 1240px) {
      width: ${switchProp('widthType', {
        quarter: '40vw',
        default: '400px',
      })};
    }

    @media (min-width: 800px) and (max-width: 1100px) {
      min-width: ${switchProp(
        'widthType',
        {
          quarter: '50vw',
          default: '',
        },
        '30vw',
      )};
    }

    @media (max-width: 800px) {
      min-width: 90%;
      margin: 10% 0px;
    }
  }

  @media (max-width: 800px) {
    justify-content: center;
  }
`;
export const DesktopWrapper = styled.div`
  display: none;
  @media (min-width: 800px) {
    display: block;
  }
`;
export const MobileWrapper = styled.div`
  display: none;
  @media (max-width: 800px) {
    display: block;
  }
`;

interface VDrawer {
  open: boolean;
  onClose: () => void;
  widthType?: 'quarter';
  children: ReactNode;
  title?: string;
  style?: CSSProperties;
  header?: any;
}
export const DrawerModal = ({
  open,
  onClose,
  widthType,
  children,
  header,
  title,
  style,
}: VDrawer) => {
  return (
    <>
      <Modal open={open} fullHeight onClose={() => null}>
        <MobileWrapper>
          <CancelWrapper>
            <BackAction
              icon={<CancelIcon />}
              title="Close"
              onClick={onClose}
              width="xs"
            />
          </CancelWrapper>
        </MobileWrapper>
        <ModalCardWrapper widthType={widthType}>
          <div className="child" style={style}>
            <ModalCard title="" fullHeight onBoarding>
              <div>
                <DesktopWrapper>
                  {header && header}
                  <FlexRowSpaceBetween style={{width: '100%'}}>
                    <Headline2>{title}</Headline2>
                    <CancelWrapper>
                      <BackAction
                        icon={<CancelIcon />}
                        title="Close"
                        style={{
                          height: '42px',
                          display: 'flex',
                          alignItems: 'center',
                          overflow: 'hidden',
                          justifyContent: 'center',
                        }}
                        onClick={onClose}
                        width="xs"
                      />
                    </CancelWrapper>
                  </FlexRowSpaceBetween>
                </DesktopWrapper>
                <VerticalSpacer size="32px" />
                {children}
              </div>
            </ModalCard>
          </div>
        </ModalCardWrapper>
      </Modal>
    </>
  );
};
