import styled from 'styled-components';
import {down} from 'styled-breakpoints';
import {getColorFromTheme} from '@ui/ui-utils';
import {Button} from '@ui/atoms/button';
import {FlexRowCenter, FlexRowSpaceBetween} from '@ui/style/styles';

export const MemberButton = styled(Button)`
  padding: 8px 16px;
  overflow-y: hidden;
  display: flex;
  align-items: center;
`;

export const SecondWrapperChildHeader = styled.div`
  display: grid;
  grid-template-columns: 40% 15% 15% 15% 15%;
  grid-gap: 1%;
  font-size: 14px;
  align-items: center;

  color: ${getColorFromTheme('textBody')};
  border-top: 1px solid ${getColorFromTheme('borderLight')};

  padding: 16px;

  > span {
    display: flex;
    align-items: center;
  }
`;

export const SecondMainChildHeader = styled.div`
  display: grid;
  border-radius: 10px 10px 0px 0px;

  width: 100%;
  min-width: 400px;

  grid-template-columns: 40% 15% 15% 15% 15%;
  grid-gap: 1%;

  font-size: 14px;

  color: #5f5f8c;
  padding: 16px;
  background: #fafafa;
  height: 52px;
  > span {
    display: flex;
    align-items: center;
  }
`;

export const EmptyReviewWrapper = styled(FlexRowCenter)`
  min-height: 200px;
`;

export const TableContainer = styled.div`
  border: 1px solid #ededf2;
  margin-top: 24px;
  border-radius: 10px;
`;

export const TableWrapper = styled.div``;

export const SecondWrapperHeader = styled.div`
  padding: 16px 16px;

  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  border: 1px solid ${getColorFromTheme('borderLight')};
  border-radius: 10px;
  .heading-top {
    padding: 16px 24px 28px;
  }
`;

export const BorderWrapper = styled.div`
  display: flex;
  width: 100%;
  border-radius: 10px 10px 10px 10px;
`;

export const NavBorderWrapper = styled.div`
  display: flex;

  width: 40%;

  ${down('md')} {
    width: auto;
  }
`;

export const BodyWrapper = styled.div`
  border-top: 1px solid #ededf2;
  .add-p {
    padding: 16px;
  }
`;

export const HelpWidget = styled.div`
  border-radius: 8px;
  background: var(--background-dark, #f6f6f8);
  padding: 8px;
  justify-content: space-between;
  display: flex;
  margin-top: 24px;
`;

export const ReviewCycleHeader = styled(FlexRowSpaceBetween)`
  .search {
    width: 80%;

    @media (max-width: 1350px) {
      width: 76%;
    }
    @media (max-width: 1250px) {
      width: 70%;
    }
  }
`;
