import {AxiosResponse} from 'axios';
import {SignInFormData, SignInResponse} from './sign-in-interface';
import {BaseRequest} from '../../configs/request';

export interface AdminSignInRequest extends BaseRequest {
  url: string;
  adminSignIn(data: SignInFormData): Promise<SignInResponse>;
}

export class AdminSignInRequestImpl
  extends BaseRequest
  implements AdminSignInRequest {
  url: string = '/auth/login';
  async adminSignIn<AdminSignInResponse>(data: SignInFormData) {
    const {data: responseData} = await this.request.post<
      SignInFormData,
      AxiosResponse<AdminSignInResponse>
    >(this.url, data);
    return responseData;
  }
}
