import {createContext, FC, useContext} from 'react';
import {StoresArchive} from './store-archive';
import {IStore} from './store-interface';

export const StoreProvider = createContext<IStore>({} as IStore);
export const useStoreContext = () => useContext(StoreProvider);

export const StoreContextProvider: FC<{}> = ({children}) => {
  return (
    <StoreProvider.Provider value={StoresArchive}>
      {children}
    </StoreProvider.Provider>
  );
};
