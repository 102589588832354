import React, {useState, useMemo, useCallback} from 'react';
import {useNavigate} from 'react-router';
import {authStore} from '../../../../../../../../store/stores/auth-store';
import {DropdownVertical} from '../../../../../../../../ui/molecules/dropdown-vertical';
import {Body1} from '../../../../../../../../ui/atoms/typography/body1';
import {Headline2} from '../../../../../../../../ui/atoms/typography/headline2';
import {Body2} from '../../../../../../../../ui/atoms/typography/body2';
import {TextTag2} from '../../../../../../../../ui/atoms/tag';
import {Button} from '../../../../../../../../ui/atoms/button';
import {ItemLoaderLine} from '../../../../../../../../ui/organisms/item-loader-line';
import {VerticalSpacer} from '../../../../../../../../ui/atoms/spacer';
import {Modal} from '../../../../../../../../ui/molecules/modal';
import {useQuery} from 'react-query';
import {UserAPIController} from '../../../../../../../../api/user-api/user-api-controller';
import {UserAPIRequestImpl} from '../../../../../../../../api/user-api/user-api-request';
import {ModalCard} from '../../../../../../../../ui/layouts/modal-card';
import {BackAction} from '../../../../../../../../ui/molecules/back-action';
import {
  ArrowHeadLeftIcon,
  ArrowHeadRightIcon,
  CancelIcon,
} from '../../../../../../../../ui/atoms/icons';
import {activateNotification} from '../../../../../../../../ui/molecules/notification/activate-notification';
import {BaseURL} from '../../../../../../../../configs/request';
import {
  HeadlineWrapperContainer2,
  HeadlineWrapper2,
  ItemWrapperContainer2,
  ItemWrapper2,
  NoItemWrapper,
  PaginationLi,
  TableContainer,
  HeadlineWrapperContainer,
  HeadlineWrapper,
  ItemWrapperContainer,
  ItemWrapper,
  SecondRowWrapper,
  TagWrapper,
  DropdownWrapper,
  DropdownItem,
  ItemLoaderWrapper,
  PaginationUl,
  PaginationButton,
} from './invite.styles';
import {FlexRowCenter} from '@ui/style/styles';

interface IInvitedUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  jobTitle: string;
}

const defaultUsers: IInvitedUser[] = [];

export const Invite = () => {
  const [users, setUsers]: [
    IInvitedUser[],
    (users: IInvitedUser[]) => void,
  ] = React.useState(defaultUsers);

  const [limit, setLimit] = useState<number>(0);
  const [page, setPage] = useState<any>(1);
  const [totalPages, setTotalPages] = useState<any>(1);
  const [totalResults, setTotalResults] = useState<any>(0);
  const [pageNumberLimit] = useState(2);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(2);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

  const pages = [];
  for (let i = 1; i <= Math.ceil(totalResults / limit); i++) {
    pages.push(i);
  }

  const handleClick = (event: any) => {
    setPage(Number(event.target.id));
  };

  const renderPageNumbers = pages.map((number: any) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <PaginationLi
          key={number}
          id={number}
          onClick={handleClick}
          className={page === number ? 'active' : undefined}>
          {number}
        </PaginationLi>
      );
    } else {
      return null;
    }
  });

  const handleNextbtn = () => {
    setPage(page + 1);

    if (page + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = () => {
    setPage(page - 1);

    if ((page - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = (
      <PaginationLi onClick={handleNextbtn}> ... </PaginationLi>
    );
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = (
      <PaginationLi onClick={handlePrevbtn}> ... </PaginationLi>
    );
  }

  const fetchInviteMembers = useCallback(async () => {
    const request = new UserAPIRequestImpl();
    const controller = new UserAPIController(request);
    const res = await controller.fetchPaginatedInviteUsers(page);

    return res;
  }, [page]);

  const {isLoading, data} = useQuery(['inviteMembers', page], () =>
    fetchInviteMembers(),
  );

  useMemo(() => {
    if (data) {
      setLimit(data.limit);
      setTotalPages(data.totalPages);
      setTotalResults(data.totalResults);
      setUsers(data.results);
    }
  }, [data]);

  const navigate = useNavigate();

  //prevent button from refreshin page on submit
  const noReload = (e: any) => {
    e.preventDefault();
  };

  const [modalLoading, setModalLoading] = useState(false);

  //deactivate user function
  const deleteUser = async (
    id: string,

    email: string,
  ) => {
    setModalLoading(true);
    const request = new UserAPIRequestImpl();
    const controller = new UserAPIController(request);
    const response = await controller.deleteUser(id);
    if (response) {
      activateNotification({
        title: 'Success',
        content: `"${email}"  was deleted`,
        kind: 'success',
      });
      navigate('/', {replace: true});
      navigate('/manage-people', {replace: true});
    }
    setModalLoading(false);
  };

  //resend invite function
  const resendInvite = async (email: string) => {
    let resendInviteData = {email: email};

    await fetch(`${BaseURL}/auth/resend-user-invitation`, {
      mode: 'cors',
      method: 'POST',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
      },
      body: JSON.stringify(resendInviteData),
    })
      .then((res) => {
        if (res.ok) {
          activateNotification({
            title: 'Invite Sent',
            content: `An email has been sent to "${email}"`,
            kind: 'success',
          });
        } else {
          activateNotification({
            title: 'Error',
            content: res.statusText,
            kind: 'error',
          });
        }

        return res.json();
      })
      .then(() => {
        navigate('/', {replace: true});

        navigate('/manage-people', {replace: true});
      });
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalId, setModalId] = useState('');
  const [modalEmail, setModalEmail] = useState('');

  //open and close modal with user data being passed to modal screen
  const openModal = (id: string, email: string) => {
    setModalIsOpen(true);
    setModalId(id);
    setModalEmail(email);
  };

  const hideModal = () => {
    setModalIsOpen(false);
  };

  //covert role to title case
  const titleCase = function (string: string) {
    const sentence = string.toLowerCase().split(' ');
    for (let i = 0; i < sentence.length; i++) {
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }
    return sentence.join(' ');
  };

  return (
    <>
      <TableContainer>
        <div style={{display: 'flex'}}>
          <div style={{width: '100%'}}>
            <HeadlineWrapperContainer>
              <HeadlineWrapper>
                <div>
                  <Body2 weight="semibold">Email Address</Body2>
                </div>
              </HeadlineWrapper>
            </HeadlineWrapperContainer>

            {!isLoading &&
              users.map((user) => (
                <ItemWrapperContainer>
                  <ItemWrapper>
                    <Body2
                      kind="textBody"
                      style={{wordWrap: 'break-word', marginRight: '5px'}}>
                      {user.email}
                    </Body2>
                  </ItemWrapper>
                </ItemWrapperContainer>
              ))}
          </div>
          <SecondRowWrapper>
            <HeadlineWrapperContainer2>
              <HeadlineWrapper2>
                <div>
                  <Body2 weight="semibold">Role</Body2>
                </div>
                <div>
                  <Body2 weight="semibold">Action</Body2>
                </div>
              </HeadlineWrapper2>
            </HeadlineWrapperContainer2>

            {!isLoading &&
              users.map((user) => (
                <ItemWrapperContainer2>
                  <ItemWrapper2>
                    <TextTag2 style={{width: '80px', padding: '6px 12px'}}>
                      <TagWrapper>{titleCase(user.role)}</TagWrapper>
                    </TextTag2>
                    <DropdownWrapper>
                      <DropdownVertical
                        menu={(handleClose: () => void) => (
                          <div>
                            <form onSubmit={noReload}>
                              <DropdownItem
                                onClick={() => {
                                  resendInvite(user.email);
                                  handleClose();
                                }}>
                                Resend Invite
                              </DropdownItem>
                            </form>
                            <DropdownItem
                              onClick={() => {
                                openModal(user.id, user.email);
                                handleClose();
                              }}>
                              Delete user
                            </DropdownItem>
                          </div>
                        )}
                        collapseOnClick
                      />
                    </DropdownWrapper>
                  </ItemWrapper2>
                </ItemWrapperContainer2>
              ))}
          </SecondRowWrapper>
        </div>
        {isLoading && (
          <ItemWrapperContainer>
            <ItemLoaderWrapper>
              <ItemLoaderLine />
            </ItemLoaderWrapper>
          </ItemWrapperContainer>
        )}
        {users.length === 0 && !isLoading ? (
          <NoItemWrapper>
            <Body1 kind="textBody" align="center">
              There are no Invited Members
            </Body1>
          </NoItemWrapper>
        ) : null}
      </TableContainer>
      <Modal open={modalIsOpen} onClose={() => {}}>
        <div style={{maxWidth: '550px', margin: 'auto'}}>
          <BackAction
            width="md"
            icon={<CancelIcon />}
            onClick={hideModal}
            title="Close"
          />
          <VerticalSpacer size="16px" />
          <ModalCard align="center" title="">
            <form onSubmit={noReload}>
              <Headline2 align="center">Delete {modalEmail}</Headline2>
              <VerticalSpacer size="16px" />
              <Body1 kind="textBody" align="center">
                Please click “Delete” to proceed.
              </Body1>
              <VerticalSpacer size="16px" />
              <Button
                kind="secondary"
                isLoading={modalLoading}
                onClick={() => {
                  deleteUser(modalId, modalEmail);
                }}
                width="full">
                Delete
              </Button>
              <VerticalSpacer size="30px" />
            </form>
          </ModalCard>
        </div>
      </Modal>
      {totalPages > 1 ? (
        <FlexRowCenter style={{width: '100%'}}>
          <PaginationUl>
            <PaginationLi>
              <PaginationButton
                onClick={handlePrevbtn}
                disabled={page === 1 ? true : false}>
                <ArrowHeadLeftIcon />
              </PaginationButton>
            </PaginationLi>
            {pageDecrementBtn}
            {renderPageNumbers}
            {pageIncrementBtn}
            <PaginationLi>
              <PaginationButton
                onClick={handleNextbtn}
                disabled={page === totalPages ? true : false}>
                <ArrowHeadRightIcon />
              </PaginationButton>
            </PaginationLi>
          </PaginationUl>
        </FlexRowCenter>
      ) : null}
    </>
  );
};
