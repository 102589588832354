import {observer} from 'mobx-react';
import {lazy} from 'react';
import {getParameterByName} from '../../utils/get-query-params';
import {MemberSignupController} from './member-signup-controller';
import {MemberSignupFormData} from './member-signup-interface';
import {MemberSignupRequestImpl} from './member-signup-request';
import {timezones} from '@constants';
import dayjs from 'dayjs';
import Timezone from 'dayjs/plugin/timezone';

dayjs.extend(Timezone);

const SetupMemberSignupPage = lazy(() =>
  import('./member-signup-page').then((module) => ({
    default: module.MemberSignupPage,
  })),
);

const SetupWorkspaceForm = lazy(() =>
  import('./member-signup-form').then((module) => ({
    default: module.MemberSignupForm,
  })),
);

export const installMemberSignupPage = () => {
  const request = new MemberSignupRequestImpl();
  const controller = new MemberSignupController(request);

  const FormComponent = observer(() => {
    const token = getParameterByName('token');

    const userTimezone = timezones.find(
      (timezone) => timezone.label === dayjs.tz.guess(),
    );

    const onSubmit = async (data: MemberSignupFormData) => {
      if (token) {
        const response: any = await controller.memberSignup(
          {...data, timezone: userTimezone?.value || '(GMT+01:00) Lagos'},
          token,
        );
        response && window.location.assign('/members/you');
      }
    };

    return <SetupWorkspaceForm onSubmit={onSubmit} />;
  });

  const Page = observer(() => (
    <SetupMemberSignupPage FormComponent={FormComponent} />
  ));

  return {
    Page,
  };
};
