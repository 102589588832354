import {observer} from 'mobx-react';
import {lazy} from 'react';
import {useNavigate} from 'react-router';
import {CompanyCheckinsController} from '../../../company-checkins.controller';
import {FREQUENCY_TYPE} from './create-question.hooks';
import {QuestionKind} from '../../../company-checkins.interface';
import {CompanyCheckinsRequestImpl} from '../../../company-checkins.request';

export const parseQuestionFormData = (data: any, editMode?: boolean) => {
  switch (data?.target?.type) {
    case QuestionKind.INDIVIDUAL:
      return {
        ...data,
        frequency: editMode ? undefined : FREQUENCY_TYPE.ONCE_PER_USER,
        target: {
          type: 'individual',
          ids: data?.target?.ids?.individual,
        },
        questions: editMode
          ? data?.questions.map((question: any) => ({
              ...question,
              type: undefined,
              frequency:
                editMode && data?.frequency
                  ? data?.frequency
                  : question?.frequency
                  ? question?.frequency
                  : FREQUENCY_TYPE.ONCE_PER_USER,
            }))
          : data?.questions.map((question: any) => ({
              ...question,
              type: undefined,
            })),
      };
    case QuestionKind.GROUP:
      return {
        ...data,
        frequency: editMode
          ? undefined
          : data?.frequency
          ? data?.frequency
          : undefined,
        target: {
          type: 'group',
          ids: data?.target?.ids?.group,
        },
        questions: data?.questions.map((question: any) => ({
          ...question,
          type: undefined,
          frequency:
            editMode && data?.frequency
              ? data?.frequency
              : question?.frequency
              ? question?.frequency
              : !editMode
              ? undefined
              : FREQUENCY_TYPE.ONCE_PER_USER,
        })),
      };
    default:
      return {
        ...data,
        questions: data?.questions.map((question: any) => ({
          ...question,
          type: undefined,
          frequency: !editMode
            ? undefined
            : question?.frequency
            ? question?.frequency
            : undefined,
        })),
        frequency: editMode ? undefined : FREQUENCY_TYPE.ONCE_PER_USER,
        target: {
          type: 'general',
          ids: [],
        },
      };
  }
};
const CreateQuestionPage = lazy(() =>
  import('./create-question').then((module) => ({
    default: module.CreateQuestionPage,
  })),
);

const CreateQuestionForm = lazy(() =>
  import('./create-question.form').then((module) => ({
    default: module.CreateQuestionForm,
  })),
);

export const installCreateQuestionPage = () => {
  const request = new CompanyCheckinsRequestImpl();
  const controller = new CompanyCheckinsController(request);
  const FormComponent = observer(() => {
    const navigate = useNavigate();

    const onSubmit = async (data: any) => {
      const data_ = parseQuestionFormData(data);
      const response = await controller.createCheckinsQuestion(data_);
      response && navigate('/checkins/questions');
    };

    return <CreateQuestionForm onSubmit={onSubmit} />;
  });

  const Page = observer(({exploreTemplates}: {exploreTemplates?: boolean}) => (
    <CreateQuestionPage
      FormComponent={FormComponent}
      exploreTemplates={exploreTemplates}
    />
  ));

  return {
    Page,
  };
};
