import styled from 'styled-components';
import {getColorFromTheme} from '../../../../../../../ui/ui-utils';
import {down, up} from 'styled-breakpoints';

export const MainWrapper = styled.div`
  padding: 12px 16px 11px 16px;
  background-color: white;
  border: 1px solid;
  border-radius: 10px;
  border-color: ${getColorFromTheme('borderLight')};
  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
export const FlexWrapper1 = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 900px) {
    width: 100%;
    flex-direction: column;
    height: 100%;
  }
`;
export const BoxWrapper = styled.div`
  background-color: white;
  width: 100%;
  padding: 8px 16px 8px 16px;
  height: 50%;
  border: 1px solid;
  border-color: ${getColorFromTheme('borderLight')};
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1338px) {
    padding: 8px 12px 8px 16px;
  }
  .aligned {
    margin-top: 10px;
    @media (max-width: 1200px) {
      margin-top: 1px;
    }
  }
`;
export const CardWrapper = styled.div`
  display: block;
  width: 29%;
  height: 50%;
  @media (max-width: 1200px) {
    width: 35%;
  }
`;
export const NumberWrapper = styled.div`
  font-size: 25px;
  line-height: 130%;
  text-align: center;
  align-items: center;
  font-weight: 600;
  padding: 4px;
  width: 21%;
  background-color: #ededf2;
  border-radius: 4px;
  @media (min-width: 1440px) {
    width: 19%;
  }
  @media (max-width: 900px) {
    width: 25%;
  }
  .alignedNumber {
    padding: 6px;
  }
`;
export const GoalStatusWrapper = styled.div`
  background-color: white;
  width: 100%;
  height: 100%;

  padding: 16px 24px 10px 24px;
  border: 1px solid;
  border-color: ${getColorFromTheme('borderLight')};
  border-radius: 10px 0px 0px 10px;
  @media (max-width: 900px) {
    border-radius: 10px 10px 0px 0px;
    border-width: 1px 1px 0px 1px;
  }
`;
export const GoalStateWrapper = styled.div`
  background-color: white;
  width: 50%;
  height: 100%;
  padding: 16px 24px 10px 24px;
  border: 1px solid;
  border-width: 1px 1px 1px 0px;
  border-color: ${getColorFromTheme('borderLight')};
  border-radius: 0px 10px 10px 0px;
  .pieDiv {
    padding-top: 20px;
  }
  h2 {
    padding-left: 0;
    @media (max-width: 1200px) {
      padding-left: 10px;
    }
  }
  @media (max-width: 900px) {
    border-radius: 0px 0px 10px 10px;
    border-width: 0px 1px 1px 1px;

    width: 100%;
  }
`;
export const OnwershipWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  text-align: center;
  padding-right: 20px;
  justify-content: space-between;
  @media (max-width: 1400px) {
    flex-direction: column;
    align-content: flex-start;
    text-align: left;
  }
  .header {
    text-align: center;
    @media (max-width: 1400px) {
      text-align: left;
    }
  }
`;
export const PieChartWrapper = styled.div`
  .pie {
    width: 150px;
    margin: 2px 0px 0px 0px;
    @media (min-width: 1440px) {
      width: 120px;
      margin: 5px 10px 0px 5px;
    }

    @media (max-width: 1220px) {
      margin: 5px 20px 0px 0px;
    }
  }

  .StatusPie {
    width: 110px;
    margin: 6px 0px 0px 0px;
    @media (min-width: 1440px) {
      width: 100px;
      margin: 3px 15px 0px 0px;
    }
    @media (min-width: 1220px) and (max-width: 1290px) {
      width: 85px;
      margin: 3px 5px 0px 0px;
    }
    @media (min-width: 1290px) and (max-width: 1400px) {
      width: 100px;
      margin: 3px 8px 0px 0px;
    }

    @media (max-width: 1220px) {
      margin: 5px 20px 0px 0px;
    }
  }
`;
export const ColorIcons = styled.span``;

export const ModalWrapper = styled.div`
  max-width: 930px;
  width: 80vw;
  margin: 0 auto;
  ${down('sm')} {
    width: 92vw;
  }

  ${up('sm')} {
    width: 95vw;
  }

  ${up('lg')} {
    width: 88vw;
  }

  ${up('xl')} {
    width: 78vw;
  }
`;
